function getSearchHistory() {
  let historys = localStorage.getItem("BUSINESS_SEARCH_HISTORY");
  let temp = [];
  if (historys) {
    try {
      historys = JSON.parse(historys);
      if (historys.length > 0) {
        temp = historys;
      }
    } catch (error) {
      console.log(error);
    }
  }
  return temp;
}
export default {
  namespaced: true,
  state: {
    pathTs: {
      searchHistory: getSearchHistory()
    }
  },
  mutations: {
    store_addHistory(state, item) {
      state.pathTs.searchHistory.unshift(item);
      // 只保存5条
      let historys = state.pathTs.searchHistory.slice(0, 5);
      state.pathTs.searchHistory = historys;
      localStorage.setItem("BUSINESS_SEARCH_HISTORY", JSON.stringify(historys));
    },
    store_clearHistory(state) {
      console.log(666);
      state.pathTs.searchHistory = [];
      localStorage.setItem("BUSINESS_SEARCH_HISTORY", JSON.stringify([]));
    }
  },
  getters: {
    searchHistory: state => state.pathTs.searchHistory
  },
  actions: {

  }
};