import Vue from "vue";
import VueI18n from "vue-i18n";
import elementUI from "element-ui";
import KiwiIntl from "kiwi-intl";
import enUsLangs from "../.kiwi/en-US/index.ts";
import zhCNLangs from "../.kiwi/zh-CN/index.ts";
import thLangs from "../.kiwi/th/index.ts";

// 当前环境的语言状态
const iocWebLang = localStorage.getItem("IOC_WEB_LANG") || "zh_CN";
const langObj = {
  zh_CN: "zh",
  en_US: "en",
  th_TH: "th"
};

const curLang = langObj[iocWebLang];

const langs = {
  "en": enUsLangs,
  "zh": zhCNLangs,
  "th": thLangs
};
const I18N = KiwiIntl.init(curLang, langs);
// const I18NENTER = KiwiIntl.init(curLang, langs);

// i18n 配置
// 项目中的语言配置，.kiwi 文件中，内容包含了，模块名称
// 项目中使用： vue-i18n: $t("common.newCreate")   kiwi-I18N: I18N.common.a
const langMessage = {
  en: {
    ...enUsLangs.langData,
    ...enUsLangs.enterprise,
    ...enUsLangs.common,
    ...elementUI.lang.en // 或者用 Object.assign({ message: 'hello' }, enLocale)
  },
  zh: {
    ...zhCNLangs.langData,
    ...zhCNLangs.enterprise,
    ...zhCNLangs.common,
    ...elementUI.lang.zhCN // 或者用 Object.assign({ message: '你好' }, zhLocale)
  },
  th: {
    ...thLangs.langData,
    ...thLangs.enterprise,
    ...thLangs.common,
    ...elementUI.lang.th
  }
};
// Create VueI18n instance with options
const vueI18n = new VueI18n({
  locale: curLang, // set locale
  messages: langMessage // set locale messages
});

Vue.use(elementUI, {
  i18n: (key, value) => vueI18n.t(key, value)
});

Vue.prototype.I18N = I18N;
// Vue.prototype.I18NENTER = I18NENTER;

export {
  I18N,
  vueI18n,
  curLang

};