// import { I18N } from "@/I18N.js";
export const RES_CODE_SUCCESS = 100000; // 成功
export const RES_CODE_NOAUTH = 101002; // 无权限
export const RES_CODE_UNLOGIN = 101003; // 未登录
export const RES_CODE_REPASSWORD = 101022; // 修改密码
export const RES_CODE_BIM_NOT_MODEL = 200024; // BIM此园区未挂载模型，请联系管理员挂载模型
export const RES_CODE_BIM_NOT_PARJING_MODEL = 200126; // BIM停车场未挂载模型，请联系管理员挂载模型！
export const RES_CODE_BIM_NOT_PERSON_PATH = 200127; // BIM模型上未挂载设备节点，请联系管理员进行设备挂载！
export const RES_CODE_CHECK_IMPORT_EXCEL = 100009; // 检测导入excel报错错误码

// 等级
const levelCode = {
  提示告警: "TIPS",
  不确定告警: "UNDEFINE",
  一般告警: "NORMAL",
  重要告警: "HIGH",
  紧急告警: "SERIOUS",
  TIPS: {
    color: "#3a7aea",
    levelNameCn: "提示告警",
    levelNameEn: "Tips"
  },
  UNDEFINE: {
    color: "#3a7aea",
    levelNameCn: "不确定告警",
    levelNameEn: "Undefine"
  },
  NORMAL: {
    color: "#3a7aea",
    levelNameCn: "一般告警",
    levelNameEn: "Normal"
  },
  HIGH: {
    color: "#e43f40",
    levelNameCn: "重要告警",
    levelNameEn: "High"
  },
  SERIOUS: {
    color: "#e43f40",
    levelNameCn: "紧急告警",
    levelNameEn: "Serious"
  },
  20081001: {
    color: "#e43f40",
    levelNameCn: "紧急告警",
    levelNameEn: "Serious"
  },
  20081002: {
    color: "#e43f40",
    levelNameCn: "重要告警",
    levelNameEn: "High"
  },
  20081003: {
    color: "#3a7aea",
    levelNameCn: "一般告警",
    levelNameEn: "Normal"
  }
};
// 状态码
const statusCode = {
  待处理: 10,
  处理中: 20,
  已挂起: 30,
  已取消: 40,
  已关闭: 50,
  10: {
    color: "#5CBCF9",
    statusNameCn: "待处理",
    statusNameEn: "Open"
  },
  20: {
    color: "#FB4853",
    statusNameCn: "处理中",
    statusNameEn: "Handing"
  },
  30: {
    color: "#55C061",
    statusNameCn: "已挂起",
    statusNameEn: "Pending"
  },
  40: {
    color: "#55C061",
    statusNameCn: "已取消",
    statusNameEn: "Cancel"
  },
  50: {
    color: "#55C061",
    statusNameCn: "已关闭",
    statusNameEn: "Close"
  }
};
// 任务状态码
const taskStatus = {
  TIPS: {
    levelCode: 10,
    levelNameCn: "低",
    levelNameEn: "low"
  },
  UNDEFINE: {
    levelCode: 20,
    levelNameCn: "中",
    levelNameEn: "zhong"
  },
  NORMAL: {
    levelCode: 20,
    levelNameCn: "中",
    levelNameEn: "zhong"
  },
  HIGH: {
    levelCode: 30,
    levelNameCn: "高",
    levelNameEn: "High"
  },
  SERIOUS: {
    levelCode: 30,
    levelNameCn: "高",
    levelNameEn: "High"
  }
};

export default {
  levelCode,
  statusCode,
  taskStatus
};
