import Ajax from "@/js/api.js";
export default {
  namespaced: true,
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    // 导出表格
    visitorFormData({
      commit
    }, params) {
      Ajax({
        url: "/ioc/guest/iocguest/exportExcel",
        method: "post",
        data: params,
        responseType: "blob"
      }, { handleErr: false }).then((res) => {
        // 处理返回的文件流;
        const content = res;
        const blob = new Blob([content], {
          type: "application/vnd.ms-excel"
        });
        const fileName = "访客管理报表.xlsx";
        if ("download" in document.createElement("a")) {
          // 非IE下载
          const elink = document.createElement("a");
          elink.download = fileName;
          elink.style.display = "none";
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href); // 释放URL 对象
          document.body.removeChild(elink);
        } else {
          // IE10+下载
          navigator.msSaveBlob(blob, fileName);
        }
      });
    }
  }
};
