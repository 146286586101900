export default {
  i18nKey_1: "Last Modified",
  i18nKey_2: "Automatic meter reading details list",
  i18nKey_3: "Subsystem",
  i18nKey_4: "Asset type",
  i18nKey_5: "Status",
  i18nKey_6: "Registration time",
  i18nKey_7: "Reset",
  i18nKey_8: "Normal",
  i18nKey_9: "Account name/received phone no.",
  i18nKey_10: "account name",
  i18nKey_11: "Display mode",
  i18nKey_12: "Cloud type",
  i18nKey_13: "Park name",
  i18nKey_14: "Park code",
  i18nKey_15: "passed",
  i18nKey_16: "Undeleted",
  i18nKey_17: "Enabled",
  i18nKey_18: "Disabled",
  i18nKey_19: "Unfrozen",
  i18nKey_20: "Frozen",
  i18nKey_21: "Select the VIP arrival reminder type, the role is not filled in. Non-vip arrival reminder type, role is a required option.",
  i18nKey_22: "select",
  i18nKey_23: "S/N",
  i18nKey_24: "fail to edit",
  i18nKey_25: "Modify hybrid cloud configuration",
  i18nKey_26: "Successfully modified",
  i18nKey_27: "modify",
  i18nKey_28: "signal type",
  i18nKey_29: "Signal label",
  i18nKey_30: "New hybrid cloud configuration",
  i18nKey_31: "New",
  i18nKey_32: "Details",
  i18nKey_33: "Download template",
  i18nKey_34: "Property announcement",
  i18nKey_35: "No data that meets the query conditions",
  i18nKey_36: "The file format is incorrect, only excel files are supported",
  i18nKey_37: "Text",
  i18nKey_38: "Position",
  i18nKey_39: "Did not pass",
  i18nKey_40: "Not Enabled",
  i18nKey_41: "Network unavailable, please try again later.",
  i18nKey_42: "Push target",
  i18nKey_43: "Push alert type",
  i18nKey_44: "Push APP service",
  i18nKey_45: "Article",
  i18nKey_46: "Add time",
  i18nKey_47: "Add",
  i18nKey_48: "Tips",
  i18nKey_49: "Submit failed",
  i18nKey_50: "Submit successfully",
  i18nKey_51: "Submit",
  i18nKey_52: "Search",
  i18nKey_53: "The dead zone can only be composed of numbers and four arithmetic symbols",
  i18nKey_54: "Private Cloud",
  i18nKey_55: "Note: 'Mains', the first level, this level: take the data of the 'high-voltage metering cabinet'; lower level accumulation: take the data of the 'high-voltage outlet cabinet'; power loss rate exceeding >5% is abnormal.",
  i18nKey_56: "data",
  i18nKey_57: "Authorization",
  i18nKey_58: "Collection account name",
  i18nKey_59: "Incorrect phone number format",
  i18nKey_60: "Collect phone number",
  i18nKey_61: "Remaining",
  i18nKey_62: "Facilities sub-categories",
  i18nKey_63: "Facility system",
  i18nKey_64: "Facility name can't be null",
  i18nKey_65: "Facility name",
  i18nKey_66: "Facility classification modification",
  i18nKey_67: "Facility category name",
  i18nKey_68: "Facility classification code",
  i18nKey_69: "Facility level",
  i18nKey_70: "The facility alias can't be null",
  i18nKey_71: "Facility number",
  i18nKey_72: "Equipment classification details",
  i18nKey_73: "Merchant",
  i18nKey_74: "failed to delete!",
  i18nKey_75: "successfully deleted",
  i18nKey_76: "delete",
  i18nKey_77: "Are you sure you want to cancel?",
  i18nKey_78: "confirm delete?",
  i18nKey_79: "determine",
  i18nKey_80: "All",
  i18nKey_81: "Authority",
  i18nKey_82: "cancel",
  i18nKey_83: "Blockchain account",
  i18nKey_84: "Please select at least one alarm type",
  i18nKey_85: "Please select status",
  i18nKey_86: "Please select registration time range",
  i18nKey_87: "Please select the park",
  i18nKey_88: "Please select an application system",
  i18nKey_89: "Please select the facility category to be modified",
  i18nKey_90: "Please select signal type",
  i18nKey_91: "Please choose a location",
  i18nKey_92: "Please select push target",
  i18nKey_93: "Please select a facility system",
  i18nKey_94: "Please select the facility category",
  i18nKey_95: "Please select permission",
  i18nKey_96: "Please choose the type",
  i18nKey_97: "Please select the point type",
  i18nKey_98: "Please select the alarm type",
  i18nKey_99: "please select a type",
  i18nKey_100: "Please select the release time range",
  i18nKey_101: "Please enter your account name/receiving phone number",
  i18nKey_102: "Please enter the park name",
  i18nKey_103: "Please enter your phone number to search",
  i18nKey_104: "Please enter the credit account name",
  i18nKey_105: "Please enter your mobile phone number",
  i18nKey_106: "Please enter the facility name",
  i18nKey_107: "Please enter the facility number",
  i18nKey_108: "Please enter the contact name",
  i18nKey_109: "Please enter the contact phone number",
  i18nKey_110: "Please enter a contact person/phone number",
  i18nKey_111: "Please enter the points wallet",
  i18nKey_112: "Please enter company name/address",
  i18nKey_113: "Please enter company name",
  i18nKey_114: "Please enter company address",
  i18nKey_115: "Please enter the current account login password",
  i18nKey_116: "Please enter a note",
  i18nKey_117: "Please click OK, download the file, modify it and import it again.",
  i18nKey_118: "Please fill in as required",
  i18nKey_119: "Enable logo",
  i18nKey_120: "Enable/disable",
  i18nKey_121: "Enable",
  i18nKey_122: "Target type",
  i18nKey_123: "Contact name",
  i18nKey_124: "Contact phone number format is wrong",
  i18nKey_125: "Contact phone number",
  i18nKey_126: "Contact/Mobile Number",
  i18nKey_127: "Types of",
  i18nKey_128: "card",
  i18nKey_129: "Longitude and latitude can't be filled in",
  i18nKey_130: "thaw",
  i18nKey_131: "Role type",
  i18nKey_132: "Character",
  i18nKey_133: "Points wallet",
  i18nKey_134: "Points type",
  i18nKey_135: "Points reward",
  i18nKey_136: "Point value (yuan/min)",
  i18nKey_137: "Public cloud",
  i18nKey_138: "company information",
  i18nKey_139: "Company name/address",
  i18nKey_140: "Company Name",
  i18nKey_141: "company address",
  i18nKey_142: "personal",
  i18nKey_143: "The alarm name can only consist of letters, numbers, and Chinese",
  i18nKey_144: "The alarm name can't be null",
  i18nKey_145: "The alarm description can only consist of letters, numbers, and Chinese",
  i18nKey_146: "Alarm description can't be null",
  i18nKey_147: "Alarm type",
  i18nKey_148: "The alarm label can only consist of letters and numbers",
  i18nKey_149: "Alarm label can't be null",
  i18nKey_150: "Itemized dimension",
  i18nKey_151: "Analysis index",
  i18nKey_152: "Stage name",
  i18nKey_153: "Total issued quantity",
  i18nKey_154: "Total value of issuance (yuan)",
  i18nKey_155: "publish time",
  i18nKey_156: "Issuer",
  i18nKey_157: "Release record",
  i18nKey_158: "freeze",
  i18nKey_159: "Import failed, only the new version of excel file is supported",
  i18nKey_160: "Import failed",
  i18nKey_161: "Imported successfully",
  i18nKey_162: "Import",
  i18nKey_163: "Export failed, support maximum export data 10000",
  i18nKey_164: "Export",
  i18nKey_165: "Pending",
  i18nKey_166: "error",
  i18nKey_167: "This operation will permanently delete the file. Do you want to continue?",
  i18nKey_168: "Creation failed",
  i18nKey_169: "Create facility classification",
  i18nKey_170: "Created successfully",
  i18nKey_171: "Imported successfully",
  i18nKey_172: "Inquire",
  i18nKey_173: "operating",
  i18nKey_174: "edit",
  i18nKey_175: "Numbering",
  i18nKey_176: "Remarks",
  i18nKey_177: "Successfully saved",
  i18nKey_178: "save",
  i18nKey_179: "VCN configuration",
  i18nKey_180: "VCM configuration",
  i18nKey_181: "L can only be composed of numbers and four arithmetic symbols",
  i18nKey_182: "L can't be null",
  i18nKey_183: "LL can only be composed of numbers and four arithmetic symbols",
  i18nKey_184: "H can only be composed of numbers and four arithmetic symbols",
  i18nKey_185: "H can't be null",
  i18nKey_186: "HH can only be composed of numbers and four arithmetic symbols",
  i18nKey_187: "Saved yesterday",
  i18nKey_188: "Total discharge yesterday",
  i18nKey_189: "Total charge yesterday",
  i18nKey_190: "yesterday",
  i18nKey_191: "Energy used yesterday",
  i18nKey_192: "Energy consumption per square meter yesterday",
  i18nKey_193: "Repeat customers yesterday",
  i18nKey_194: "Yesterday",
  i18nKey_195: "Earliest admission time",
  i18nKey_196: "Latest execution time",
  i18nKey_197: "Latest execution scenarios",
  i18nKey_198: "Minimum",
  i18nKey_199: "Latest time to leave the park",
  i18nKey_200: "Leave the park at the latest $",
  i18nKey_201: "Minimum rent (hours)",
  i18nKey_202: "Minimum rent",
  i18nKey_203: "Last week",
  i18nKey_204: "Last month",
  i18nKey_205: "Last parking time (hours)",
  i18nKey_206: "Last opened",
  i18nKey_207: "Last payment time",
  i18nKey_208: "Last stop time",
  i18nKey_209: "Last three months",
  i18nKey_210: "Last update date: December 07, 2019",
  i18nKey_211: "Last visit time",
  i18nKey_212: "Recently visited",
  i18nKey_213: "No stopping record for the parking space in the last 30 days",
  i18nKey_214: "Last update time",
  i18nKey_215: "Last communication time",
  i18nKey_216: "Last upload time",
  i18nKey_217: "Last update time",
  i18nKey_218: "Can only choose 4 cameras at most",
  i18nKey_219: "Can only choose 5",
  i18nKey_220: "Can only add up to 5 message pushers",
  i18nKey_221: "Only upload up to 4 pictures",
  i18nKey_222: "Only 1 picture can be uploaded!",
  i18nKey_223: "Add up to 5 approvers",
  i18nKey_224: "Up to five",
  i18nKey_225: "Up to 4 strategies can be created",
  i18nKey_226: "Connect up to 3 cameras",
  i18nKey_227: "Up to 30 words",
  i18nKey_228: "Max",
  i18nKey_229: "Maximum alarms no less than or equal to the minimum alarms",
  i18nKey_230: "Lease details",
  i18nKey_231: "Lease start and end dates",
  i18nKey_232: "Lease start date",
  i18nKey_233: "Lease end date",
  i18nKey_234: "Tenant water consumption",
  i18nKey_235: "Tenant energy consumption",
  i18nKey_236: "Tenant",
  i18nKey_237: "Total electricity",
  i18nKey_238: "Total water energy consumption (t)",
  i18nKey_239: "Total",
  i18nKey_240: "Total events",
  i18nKey_241: "Total energy consumption proportion",
  i18nKey_242: "Total energy consumption target",
  i18nKey_243: "Total energy consumption (last month)",
  i18nKey_244: "Total energy consumption (this year)",
  i18nKey_245: "Total energy consumption",
  i18nKey_246: "Total switch amperage",
  i18nKey_247: "Main switch:",
  i18nKey_248: "Total savings",
  i18nKey_249: "Total value (yuan)",
  i18nKey_250: "Total quantity",
  i18nKey_251: "total",
  i18nKey_252: "Total charge and discharge chart",
  i18nKey_253: "Total table",
  i18nKey_254: "Brown",
  i18nKey_255: "Comprehensive passenger flow",
  i18nKey_256: "The number of characters can't exceed 10!",
  i18nKey_257: "Self-created",
  i18nKey_258: "Automated execution strategy",
  i18nKey_259: "Automatic re-recording",
  i18nKey_260: "You can only add up to 3 custom time ranges",
  i18nKey_261: "You can only add up to 3 custom amount ranges",
  i18nKey_262: "You can only add up to 3 custom times",
  i18nKey_263: "Customize",
  i18nKey_264: "Purple",
  i18nKey_265: "Sub-area energy consumption",
  i18nKey_266: "Energy consumption of sub-items",
  i18nKey_267: "Sub-level name",
  i18nKey_268: "Total original value of assets (yuan)",
  i18nKey_269: "Total assets statistics (by park)",
  i18nKey_270: "Total assets statistics (by type)",
  i18nKey_271: "Total assets",
  i18nKey_272: "Statistics of original value of assets (by park)",
  i18nKey_273: "Original asset value statistics (by type)",
  i18nKey_274: "Original value of assets (yuan)",
  i18nKey_275: "Original value of assets (USD)",
  i18nKey_276: "Asset details",
  i18nKey_277: "Asset location information",
  i18nKey_278: "Asset Statistics",
  i18nKey_279: "Asset description",
  i18nKey_280: "Asset type",
  i18nKey_281: "Basic asset information",
  i18nKey_282: "Asset trajectory",
  i18nKey_283: "Asset Number",
  i18nKey_284: "Asset RFID information",
  i18nKey_285: "support hotline",
  i18nKey_286: "Following",
  i18nKey_287: "Tracking task",
  i18nKey_288: "Track",
  i18nKey_289: "Status can't be null",
  i18nKey_290: "Status:",
  i18nKey_291: "Status",
  i18nKey_292: "Transfer facility warning failure",
  i18nKey_293: "Successful transfer facility warning",
  i18nKey_294: "Transfer to refund",
  i18nKey_295: "Conversion situation",
  i18nKey_296: "Conversion rate",
  i18nKey_297: "Forwarding failed",
  i18nKey_298: "Forward successfully",
  i18nKey_299: "Snap a picture",
  i18nKey_300: "Snapshoot",
  i18nKey_301: "Histogram",
  i18nKey_302: "Note: The actual electricity consumption on a certain day compared with the average electricity consumption of the last week increases or decreases to reach the set threshold, it is defined as electricity consumption exception",
  i18nKey_303: "Registration time",
  i18nKey_304: "registration failed",
  i18nKey_305: "Note: Confirm to delete? Operation will not restored",
  i18nKey_306: "Note: Confirm to delete? Operation will not recoverable!",
  i18nKey_307: "Note: Recommend to enter the target value of total energy consumption and public area energy consumption. The system can automatically calculate other values. After the monthly target value is generated, it can be edited by moving the mouse.",
  i18nKey_308: "Master bedroom",
  i18nKey_309: "Theme",
  i18nKey_310: "Main floor",
  i18nKey_311: "Monday",
  i18nKey_312: "Friday",
  i18nKey_313: "Weekly",
  i18nKey_314: "Thursday",
  i18nKey_315: "Wednesday",
  i18nKey_316: "Sunday",
  i18nKey_317: "Weekend passenger flow",
  i18nKey_318: "Saturday",
  i18nKey_319: "Tuesday",
  i18nKey_320: "Week",
  i18nKey_321: "Important warning",
  i18nKey_322: "Importance level",
  i18nKey_323: "Re-delegation",
  i18nKey_324: "Re-log in",
  i18nKey_325: "Redeploy",
  i18nKey_326: "Repeat type",
  i18nKey_327: "Repeat method",
  i18nKey_328: "Heavy pollution",
  i18nKey_329: "Key area to",
  i18nKey_330: "Major event",
  i18nKey_331: "Major",
  i18nKey_332: "Species",
  i18nKey_333: "Terminal",
  i18nKey_334: "Number of finishers",
  i18nKey_335: "The end point can't be the same as the start point",
  i18nKey_336: "End point can't be null",
  i18nKey_337: "End",
  i18nKey_338: "Zhongliang",
  i18nKey_339: "China",
  i18nKey_340: "Moderately polluted",
  i18nKey_341: "Smart water meter number",
  i18nKey_342: "Intelligent system",
  i18nKey_343: "Smart meter number",
  i18nKey_344: "Smart table code can't be null",
  i18nKey_345: "Smart table coding",
  i18nKey_346: "Manufacturer only composed of Chinese and English, numbers and _",
  i18nKey_347: "Manufacturer length can't exceed 50 characters",
  i18nKey_348: "Manufacturer",
  i18nKey_349: "Select at least one device as the execution action",
  i18nKey_350: "Check at least one time period",
  i18nKey_351: "Till now",
  i18nKey_352: "Instruction issuee cycle",
  i18nKey_353: "Designated days",
  i18nKey_354: "Designated time",
  i18nKey_355: "Designated company",
  i18nKey_356: "Designated times",
  i18nKey_357: "Indicator name",
  i18nKey_358: "indicator anaysis",
  i18nKey_359: "Special characters are entered in the position info",
  i18nKey_360: "Job information",
  i18nKey_361: "Total execution time (minutes)",
  i18nKey_362: "Execution time",
  i18nKey_363: "Perform actions:",
  i18nKey_364: "Implementation (times)",
  i18nKey_365: "Carried out",
  i18nKey_366: "Only support Chinese and English case input",
  i18nKey_367: "Only support Chinese and English case",
  i18nKey_368: "Only support Chinese and English, number, space and punctuation input, can wrap",
  i18nKey_369: "There is only an unpleasant axe, no firewood that can't be split.",
  i18nKey_370: "Only positive numbers can be input, and the maximum is 10 integer digits and 2 decimal places",
  i18nKey_371: "Only upload pictures in jpg/png format",
  i18nKey_372: "Only jpg, jpeg, png image files can be uploaded!",
  i18nKey_373: "Only excel files can be uploaded, and the suffix is ​​.xlsx",
  i18nKey_374: "Branch energy consumption statistics",
  i18nKey_375: "Branch power consumption analysis",
  i18nKey_376: "Branch selection",
  i18nKey_377: "Branch energy consumption flow diagram _",
  i18nKey_378: "Branch energy consumption alarms (times)",
  i18nKey_379: "Payment completion time",
  i18nKey_380: "Payment channel",
  i18nKey_381: "Payment start time",
  i18nKey_382: "Payment amount (yuan)",
  i18nKey_383: "payment method",
  i18nKey_384: "Payment number",
  i18nKey_385: "Alipay",
  i18nKey_386: "Expense/revenue",
  i18nKey_387: "Loading video component, please wait",
  i18nKey_388: "Exporting, please wait",
  i18nKey_389: "Normal",
  i18nKey_390: "Diagnostic measures (species)",
  i18nKey_391: "Diagnostic measures",
  i18nKey_392: "Diagnosis",
  i18nKey_393: "Line chart",
  i18nKey_394: "The discount factor must be between 0-1 and 1-2 decimal places can be reserved",
  i18nKey_395: "Discount factor",
  i18nKey_396: "Discount settings",
  i18nKey_397: "Discount description",
  i18nKey_398: "Discount",
  i18nKey_399: "Discounted price details",
  i18nKey_400: "Price after discount",
  i18nKey_401: "Photo generation time",
  i18nKey_402: "Picture type description",
  i18nKey_403: "Photo size",
  i18nKey_404: "Photo can't be null, please upload a picture",
  i18nKey_405: "Photo URL",
  i18nKey_406: "Lighting mode",
  i18nKey_407: "Electricity for lighting and sockets",
  i18nKey_408: "Lighting plan issued",
  i18nKey_409: "Lighting solution management",
  i18nKey_410: "Account login",
  i18nKey_411: "Account number",
  i18nKey_412: "Total bill electricity consumption is $kWh",
  i18nKey_413: "Bill consumption statistics from the beginning to today",
  i18nKey_414: "15 or 18 characters in length",
  i18nKey_415: "Cards",
  i18nKey_416: "Occupy parking space",
  i18nKey_417: "Unfold",
  i18nKey_418: "Viscosity",
  i18nKey_419: "Value-added service details",
  i18nKey_420: "Responsible manager",
  i18nKey_421: "Early train",
  i18nKey_422: "No park map",
  i18nKey_423: "No personnel track",
  i18nKey_424: "No data to export",
  i18nKey_425: "Suspension time",
  i18nKey_426: "Pause",
  i18nKey_427: "Not enabled",
  i18nKey_428: "Online gateway",
  i18nKey_429: "Online password",
  i18nKey_430: "Online check-in",
  i18nKey_431: "Online (a)",
  i18nKey_432: "Online",
  i18nKey_433: "On duty",
  i18nKey_434: "Operator’s phone number needs to be registered in the Yuncheng app",
  i18nKey_435: "Operation and maintenance information",
  i18nKey_436: "Running",
  i18nKey_437: "Operation strategy",
  i18nKey_438: "Operating parameters",
  i18nKey_439: "Allowed visit period",
  i18nKey_440: "Allowed visit period",
  i18nKey_441: "Cloud type",
  i18nKey_442: "Yuncheng Payment",
  i18nKey_443: "Yuncheng APP News",
  i18nKey_444: "Yuncheng APP",
  i18nKey_445: "Pass thousands of miles away and overcome thousands of difficulties.",
  i18nKey_446: "Monthly forecast",
  i18nKey_447: "Monthly consumption amount (yuan)",
  i18nKey_448: "Actual monthly electricity consumption (kWh)",
  i18nKey_449: "Monthly remaining amount (yuan)",
  i18nKey_450: "Monthly card limit",
  i18nKey_451: "Monthly card reviewer",
  i18nKey_452: "Monthly card expiration date",
  i18nKey_453: "Monthly truck",
  i18nKey_454: "Monthly card",
  i18nKey_455: "Monthly water consumption (tons)",
  i18nKey_456: "Monthly power consumption (kWh)",
  i18nKey_457: "Monthly recharge amount (yuan)",
  i18nKey_458: "Month",
  i18nKey_459: "Remote opening time",
  i18nKey_460: "Remote door open successfully",
  i18nKey_461: "Open the door remotely",
  i18nKey_462: "Source system personnel code input has special characters",
  i18nKey_463: "Source system name has special characters",
  i18nKey_464: "Source data encoding input has special characters",
  i18nKey_465: "Source data encoding",
  i18nKey_466: "Staff",
  i18nKey_467: "Total  square meters of the park greater than 0",
  i18nKey_468: "Total square meters of the park",
  i18nKey_469: "Park owns",
  i18nKey_470: "No personnel thermal data in the park",
  i18nKey_471: "No corresponding device data in the park",
  i18nKey_472: "No cleaning staff data in the park",
  i18nKey_473: "No security personnel data in the park",
  i18nKey_474: "Enterprises in the park",
  i18nKey_475: "Park blacklist",
  i18nKey_476: "Park visitors",
  i18nKey_477: "Park order",
  i18nKey_478: "Enterprise employees in the park",
  i18nKey_479: "Park vehicle color ranking",
  i18nKey_480: "Park vehicle brand ranking",
  i18nKey_481: "Park code",
  i18nKey_482: "Park cleaning",
  i18nKey_483: "Park bus",
  i18nKey_484: "yuan",
  i18nKey_485: "Threshold type",
  i18nKey_486: "Threshold incorrect! Please verify and confirm",
  i18nKey_487: "Threshold",
  i18nKey_488: "Domain account",
  i18nKey_489: "Reservation status",
  i18nKey_490: "Preview",
  i18nKey_491: "Minimum warning value is 0 and the maximum is an integer of 999",
  i18nKey_492: "Warning value status:",
  i18nKey_493: "Expected to leave",
  i18nKey_494: "Estimated time of departure",
  i18nKey_495: "Estimated visit time",
  i18nKey_496: "Prepaid",
  i18nKey_497: "Booking deadline",
  i18nKey_498: "Booking date",
  i18nKey_499: "Booker",
  i18nKey_500: "Booking",
  i18nKey_501: "Predictive value",
  i18nKey_502: "Predicted energy consumption value (kWh)",
  i18nKey_503: "Validity indicator",
  i18nKey_504: "Effective",
  i18nKey_505: "Return from key areas:",
  i18nKey_506: "Preferential package:",
  i18nKey_507: "Preferential enterprise",
  i18nKey_508: "Preferential strategy",
  i18nKey_509: "Optimization suggestions",
  i18nKey_510: "Excellent",
  i18nKey_511: "Water consumption statistics (t)",
  i18nKey_512: "Statistics of water consumption m³ cubic meters",
  i18nKey_513: "Water type",
  i18nKey_514: "Time (minutes)",
  i18nKey_515: "Energy use trend",
  i18nKey_516: "Energy consumption (kWh)",
  i18nKey_517: "Use energy",
  i18nKey_518: "Dosage",
  i18nKey_519: "User information confirmation",
  i18nKey_520: "User/group",
  i18nKey_521: "Electricity warning settings",
  i18nKey_522: "Abnormal percentage of electricity",
  i18nKey_523: "Abnormal power usage",
  i18nKey_524: "Electricity consumption statistics",
  i18nKey_525: "Power consumption statistics (kWh)",
  i18nKey_526: "Power consumption statistics kWh Kilowatt hour",
  i18nKey_527: "Electricity ranking",
  i18nKey_528: "Power consumption (kWh)",
  i18nKey_529: "Type of electricity",
  i18nKey_530: "Electricity profile",
  i18nKey_531: "Electricity load",
  i18nKey_532: "Electricity breakdown",
  i18nKey_533: "Electricity",
  i18nKey_534: "Application system code",
  i18nKey_535: "Application system number can't be null",
  i18nKey_536: "operating system",
  i18nKey_537: "User-oriented success",
  i18nKey_538: "User-oriented",
  i18nKey_539: "Successful application scenario",
  i18nKey_540: "Application scenario",
  i18nKey_541: "business license",
  i18nKey_542: "Business hours can't be less than one hour!",
  i18nKey_543: "Business hours",
  i18nKey_544: "Business start time",
  i18nKey_545: "Business end time",
  i18nKey_546: "Revenue",
  i18nKey_547: "English name",
  i18nKey_548: "Privacy Policy",
  i18nKey_549: "Silver",
  i18nKey_550: "UnionPay payment",
  i18nKey_551: "volume control:",
  i18nKey_552: "Since customer privacy is involved, identity verification is required",
  i18nKey_553: "Epidemic report record",
  i18nKey_554: "Epidemic prevention and control records",
  i18nKey_555: "Total number of exceptions (a)",
  i18nKey_556: "abnormal",
  i18nKey_557: "Search people with pictures",
  i18nKey_558: "Paid",
  i18nKey_559: "Existing record exists, whether to overwrite",
  i18nKey_560: "Already",
  i18nKey_561: "Invited",
  i18nKey_562: "Selected branch",
  i18nKey_563: "Device selected",
  i18nKey_564: "File selected for upload:",
  i18nKey_565: "Selected camera",
  i18nKey_566: "Selected business/tenant",
  i18nKey_567: "Selected building",
  i18nKey_568: "selected",
  i18nKey_569: "completed",
  i18nKey_570: "refunded",
  i18nKey_571: "stopped",
  i18nKey_572: "Route added",
  i18nKey_573: "This table has already been added!",
  i18nKey_574: "Submitted",
  i18nKey_575: "Locked",
  i18nKey_576: "Used",
  i18nKey_577: "Audited",
  i18nKey_578: "Cancelled",
  i18nKey_579: "Activated deployment control can't be deleted",
  i18nKey_580: "Activated",
  i18nKey_581: "The configured network device can't be deleted",
  i18nKey_582: "Already equipped",
  i18nKey_583: "$ Opened",
  i18nKey_584: "Turned on",
  i18nKey_585: "Checked out",
  i18nKey_586: "Over",
  i18nKey_587: "paid",
  i18nKey_588: "Written off",
  i18nKey_589: "Expired",
  i18nKey_590: "No more than three connected cameras",
  i18nKey_591: "0 cameras have been linked",
  i18nKey_592: "Camera linked",
  i18nKey_593: "$ Closed",
  i18nKey_594: "Number of visitors (person)",
  i18nKey_595: "Arrived",
  i18nKey_596: "Existed",
  i18nKey_597: "Processed",
  i18nKey_598: "Undeducted amount of bills issued (yuan)",
  i18nKey_599: "The bill has not been deducted",
  i18nKey_600: "Cumulative bill amount",
  i18nKey_601: "Timed out",
  i18nKey_602: "Move failed",
  i18nKey_603: "Mobile",
  i18nKey_604: "Move successfully",
  i18nKey_605: "The removed device will be restored to the 'device to be allocated' and can be added again if necessary. Do you want to remove the device from this area?",
  i18nKey_606: "Move out",
  i18nKey_607: "Instrument belongs to",
  i18nKey_608: "Instrument brand",
  i18nKey_609: "Instrument name",
  i18nKey_610: "Instrument category",
  i18nKey_611: "The instrument code can't be null",
  i18nKey_612: "Instrument code/number/name",
  i18nKey_613: "Meter number/building unit",
  i18nKey_614: "Meter number",
  i18nKey_615: "One Code",
  i18nKey_616: "One type",
  i18nKey_617: "One key reset",
  i18nKey_618: "One-click deactivation",
  i18nKey_619: "One key to open",
  i18nKey_620: "First-level department",
  i18nKey_621: "First level",
  i18nKey_622: "General warning",
  i18nKey_623: "One",
  i18nKey_624: "Owner",
  i18nKey_625: "Business event",
  i18nKey_626: "Business code",
  i18nKey_627: "The format name can't be null",
  i18nKey_628: "Business name",
  i18nKey_629: "Format",
  i18nKey_630: "Find a way for success, not a reason for failure.",
  i18nKey_631: "Invitation information",
  i18nKey_632: "Invitation time",
  i18nKey_633: "Invite visitors",
  i18nKey_634: "Invite information",
  i18nKey_635: "Invitation time",
  i18nKey_636: "More than 6 options of colors have been displayed optimally for you",
  i18nKey_637: "Serious pollution",
  i18nKey_638: "Critical alarm threshold",
  i18nKey_639: "Cycle Time",
  i18nKey_640: "Cycle",
  i18nKey_641: "Patrol group name",
  i18nKey_642: "Overview of patrol issues",
  i18nKey_643: "Patrol person",
  i18nKey_644: "Patrol name can't be null",
  i18nKey_645: "Patrol name can't exceed 200 characters",
  i18nKey_646: "Patrol route description",
  i18nKey_647: "Patrol route name",
  i18nKey_648: "Patrol route",
  i18nKey_649: "Patrol plan name",
  i18nKey_650: "Patrol schedule",
  i18nKey_651: "Patrol warning",
  i18nKey_652: "Patrol",
  i18nKey_653: "Select fire zone",
  i18nKey_654: "Choose a replacement device",
  i18nKey_655: "Selection period",
  i18nKey_656: "Choose street light",
  i18nKey_657: "Select point type",
  i18nKey_658: "Choose House Type",
  i18nKey_659: "Select management department",
  i18nKey_660: "Select association",
  i18nKey_661: "No traffic data in selected location, please reselect the area and try again",
  i18nKey_662: "Selected date range greater than 190 days, please reduce the selection and try again",
  i18nKey_663: "Select the level at least to the park",
  i18nKey_664: "Select label",
  i18nKey_665: "Entrance",
  i18nKey_666: "The serial number can only consist of Chinese, English and numbers",
  i18nKey_667: "The length of the serial number can't exceed 100 characters",
  i18nKey_668: "serial number",
  i18nKey_669: "Modify value-added services",
  i18nKey_670: "Successfully modify the early warning threshold",
  i18nKey_671: "Successfully revised the patrol plan",
  i18nKey_672: "The price must be disabled first to modify the information!",
  i18nKey_673: "To modify information, you need to stop the station first!",
  i18nKey_674: "Modify limited time discount",
  i18nKey_675: "Modify restricted vehicles",
  i18nKey_676: "Modify device",
  i18nKey_677: "Reading before modification",
  i18nKey_678: "Change the password successfully, please log in again",
  i18nKey_679: "Failed to modify spatial basic data",
  i18nKey_680: "Successfully modify the space basic data",
  i18nKey_681: "Modify the plan",
  i18nKey_682: "Modify the hybrid cloud configuration successfully",
  i18nKey_683: "Modify the data of the hybrid cloud",
  i18nKey_684: "Modify conference room resource maintenance",
  i18nKey_685: "Modify meeting room order",
  i18nKey_686: "Modify meeting room",
  i18nKey_687: "Modify the bus schedule",
  i18nKey_688: "Modify the remark name",
  i18nKey_689: "Modify VCM configuration",
  i18nKey_690: "Rest",
  i18nKey_691: "Sleep brightness",
  i18nKey_692: "Dormant",
  i18nKey_693: "Special characters in the name, please re-enter",
  i18nKey_694: "Name or phone number",
  i18nKey_695: "Name",
  i18nKey_696: "Gender can't be null",
  i18nKey_697: "Gender",
  i18nKey_698: "Model",
  i18nKey_699: "Monday",
  i18nKey_700: "Friday",
  i18nKey_701: "Sunday",
  i18nKey_702: "Thursday",
  i18nKey_703: "Wednesday",
  i18nKey_704: "Saturday",
  i18nKey_705: "Tuesday",
  i18nKey_706: "New subcategory",
  i18nKey_707: "Add sub-category",
  i18nKey_708: "Added value-added services",
  i18nKey_709: "Successfully added an early warning threshold",
  i18nKey_710: "New warning threshold",
  i18nKey_711: "New first-level format",
  i18nKey_712: "Add team members",
  i18nKey_713: "Add fire zone",
  i18nKey_714: "Add location",
  i18nKey_715: "Adding failed, please try again later",
  i18nKey_716: "New equipment",
  i18nKey_717: "New configuration",
  i18nKey_718: "New role",
  i18nKey_719: "Newly added additional facilities menu path: corporate services -> meeting room management -> value-added services!",
  i18nKey_720: "New plan",
  i18nKey_721: "Add conference room resource maintenance",
  i18nKey_722: "Add meeting room order",
  i18nKey_723: "Add meeting room",
  i18nKey_724: "New apartment",
  i18nKey_725: "Add additional facilities",
  i18nKey_726: "New house",
  i18nKey_727: "added successfully",
  i18nKey_728: "New product",
  i18nKey_729: "New policy effective time (up to 4 can be created)",
  i18nKey_730: "New label information",
  i18nKey_731: "New password can't same as the old password",
  i18nKey_732: "New password",
  i18nKey_733: "New customers",
  i18nKey_734: "New customer ratio",
  i18nKey_735: "Successful new patrol plan",
  i18nKey_736: "New restricted vehicles",
  i18nKey_737: "New face tracking",
  i18nKey_738: "New route",
  i18nKey_739: "Failed to create new space basic data",
  i18nKey_740: "The basic data of the new space was successfully created",
  i18nKey_741: "New plan",
  i18nKey_742: "New hybrid cloud configuration is successful",
  i18nKey_743: "Create a new ticket successfully",
  i18nKey_744: "New deployment control",
  i18nKey_745: "New VCM configuration",
  i18nKey_746: "Check result",
  i18nKey_747: "Less than or equal to",
  i18nKey_748: "Less than",
  i18nKey_749: "Hours cancellation, refund",
  i18nKey_750: "Hours to cancel",
  i18nKey_751: "The hour/day can't be less than 4 hours!",
  i18nKey_752: "Hour/day",
  i18nKey_753: "Hour",
  i18nKey_754: "Sales",
  i18nKey_755: "Push notification displayed in Yuncheng app message list",
  i18nKey_756: "Dispel the alarm successfully",
  i18nKey_757: "Water consumption",
  i18nKey_758: "Consumption amount",
  i18nKey_759: "Power consumption",
  i18nKey_760: "General consumption, continuing to pay attention",
  i18nKey_761: "Spending power",
  i18nKey_762: "Consumption Type",
  i18nKey_763: "Consumer purchase",
  i18nKey_764: "Fire fighting system/fire automatic alarm and linkage system/manual alarm button",
  i18nKey_765: "Fire fighting system/fire automatic alarm and linkage system/fire alarm host",
  i18nKey_766: "Fire fighting system/automatic fire alarm and linkage system/smoke fire detector",
  i18nKey_767: "Fire fighting system/fire automatic alarm and linkage system/heat detector",
  i18nKey_768: "Fire Fighting System",
  i18nKey_769: "Fire incident trends",
  i18nKey_770: "Fire district name",
  i18nKey_771: "Fire partition import template",
  i18nKey_772: "Fire zone id",
  i18nKey_773: "Fire Division",
  i18nKey_774: "The project site is located in the underground garages B1 and B2 of Jinqiao Wanchuang Center, equipped with three sets of 250kW/924.9kWh energy storage systems. The outlets are located on the sides of the 400V busbar of the 10kV line A 2# transformer (1000kVA), and the 10kV line B# 5# transformer (1000kVA)  and 6# transformer (2000kVA) ",
  i18nKey_775: "Project name",
  i18nKey_776: "Project Introduction",
  i18nKey_777: "Address",
  i18nKey_778: "Box size (inch)",
  i18nKey_779: "Adjacent areas can't be at the same location as the facility",
  i18nKey_780: "Adjacent area",
  i18nKey_781: "Offline payments",
  i18nKey_782: "Offline payment staff",
  i18nKey_783: "Offline payment",
  i18nKey_784: "The online refund input format is incorrect!",
  i18nKey_785: "Online refund",
  i18nKey_786: "Cancel online",
  i18nKey_787: "limitation factor",
  i18nKey_788: "The limited time discount is on the shelves, please do it after you leave the shelves!",
  i18nKey_789: "Limited time period",
  i18nKey_790: "Restricted vehicles",
  i18nKey_791: "Live picture",
  i18nKey_792: "Live video",
  i18nKey_793: "Display price",
  i18nKey_794: "display",
  i18nKey_795: "Download template (public area)",
  i18nKey_796: "Download template (room)",
  i18nKey_797: "Download template",
  i18nKey_798: "Download form template",
  i18nKey_799: "download",
  i18nKey_800: "Move down",
  i18nKey_801: "Next step",
  i18nKey_802: "Afternoon",
  i18nKey_803: "Decline",
  i18nKey_804: "Off shelf",
  i18nKey_805: "Lower level cumulative",
  i18nKey_806: "Down",
  i18nKey_807: "Recommended operating parameters for the next hour",
  i18nKey_808: "Command issued successfully",
  i18nKey_809: "Issue plan",
  i18nKey_810: "Order time",
  i18nKey_811: "Orderer",
  i18nKey_812: "System automatically rejects",
  i18nKey_813: "System predicts other",
  i18nKey_814: "System predicts permanent personnel",
  i18nKey_815: "System administrator",
  i18nKey_816: "The system code can only be Chinese, letters and underscores within 50 characters",
  i18nKey_817: "System code can't be null",
  i18nKey_818: "System 2",
  i18nKey_819: "System 1",
  i18nKey_820: "System",
  i18nKey_821: "Restroom monitoring strategy configuration",
  i18nKey_822: "Southwest",
  i18nKey_823: "False positive",
  i18nKey_824: "Property staff",
  i18nKey_825: "Property",
  i18nKey_826: "Five categories",
  i18nKey_827: "Five-level department",
  i18nKey_828: "Level 5",
  i18nKey_829: "5",
  i18nKey_830: "No show",
  i18nKey_831: "No record",
  i18nKey_832: "Unrelated event",
  i18nKey_833: "Unlinked camera",
  i18nKey_834: "No data that meets the query conditions",
  i18nKey_835: "Unable to get in-line data",
  i18nKey_836: "No such superior device",
  i18nKey_837: "No",
  i18nKey_838: "File only supports excel",
  i18nKey_839: "File size can't exceed 20M",
  i18nKey_840: "temperature",
  i18nKey_841: "Location full path",
  i18nKey_842: "Location ID",
  i18nKey_843: "Position",
  i18nKey_844: "Unknown",
  i18nKey_845: "No pusher is set, please add pusher!",
  i18nKey_846: "not initiated",
  i18nKey_847: "Not equipped",
  i18nKey_848: "Not entered",
  i18nKey_849: "Not separated",
  i18nKey_850: "has not started",
  i18nKey_851: "Not written off",
  i18nKey_852: "Undeducted amount",
  i18nKey_853: "Non-visitors (persons)",
  i18nKey_854: "No violations have occurred",
  i18nKey_855: "Total number not copied (a)",
  i18nKey_856: "The integer part of the latitude is 0-90",
  i18nKey_857: "latitude",
  i18nKey_858: "Repair content",
  i18nKey_859: "Repair result",
  i18nKey_860: "Maintenance person",
  i18nKey_861: "Maintain",
  i18nKey_862: "Maintenance status",
  i18nKey_863: "Maintenance business can only be composed of Chinese and English, numbers and _",
  i18nKey_864: "The length of the maintenance provider can't exceed 50 characters",
  i18nKey_865: "Maintainer",
  i18nKey_866: "Evidence of violation",
  i18nKey_867: "Reason for violation",
  i18nKey_868: "Violation details",
  i18nKey_869: "Stop time",
  i18nKey_870: "Parking place",
  i18nKey_871: "Number of violations",
  i18nKey_872: "Slightly bright",
  i18nKey_873: "Forgot password",
  i18nKey_874: "The network load is abnormal, please try again later",
  i18nKey_875: "Network loading abnormal, please try again later",
  i18nKey_876: "Gateway name",
  i18nKey_877: "The gateway is not online, and the plan delivery failed",
  i18nKey_878: "Vanke Park",
  i18nKey_879: "Vanke Xicheng District Park",
  i18nKey_880: "Vanke Haiding District Park",
  i18nKey_881: "Vanke Dongcheng District",
  i18nKey_882: "Vanke Chaoyang District Park",
  i18nKey_883: "Vanke Binhai New Area Park",
  i18nKey_884: "Van",
  i18nKey_885: "Late bus",
  i18nKey_886: "External location name",
  i18nKey_887: "External position code",
  i18nKey_888: "External company",
  i18nKey_889: "Refund status",
  i18nKey_890: "Refund requested",
  i18nKey_891: "Refund failed",
  i18nKey_892: "Refund processing",
  i18nKey_893: "Refund processing time",
  i18nKey_894: "Refund successful time",
  i18nKey_895: "Refund successfully",
  i18nKey_896: "Return",
  i18nKey_897: "Unsubscribe",
  i18nKey_898: "Recommend Signature",
  i18nKey_899: "Unity and cooperation, go all out.",
  i18nKey_900: "The way point can't be the same as the end point",
  i18nKey_901: "The way point can't be the same as the starting point",
  i18nKey_902: "Way",
  i18nKey_903: "The picture size can't exceed 3M!",
  i18nKey_904: "Picture size can't exceed 3M",
  i18nKey_905: "Picture View",
  i18nKey_906: "Picture cropping",
  i18nKey_907: "image",
  i18nKey_908: "Chart, report",
  i18nKey_909: "Chart",
  i18nKey_910: "Unified setting quota",
  i18nKey_911: "Statistical period (days)",
  i18nKey_912: "Statistics in detail",
  i18nKey_913: "Chart name",
  i18nKey_914: "Statistical days can only be positive integers",
  i18nKey_915: "Statistics Time:",
  i18nKey_916: "Statistics card configuration",
  i18nKey_917: "Statistics from the beginning to yesterday, the recharge amount is subject to the arrival of the account",
  i18nKey_918: "agree",
  i18nKey_919: "Only one cancellation rule can be set on the same day!",
  i18nKey_920: "Can't select more than 4 cameras at the same time",
  i18nKey_921: "Same period (kWh)",
  i18nKey_922: "Companion information settings",
  i18nKey_923: "Number of companions",
  i18nKey_924: "Year-on-year",
  i18nKey_925: "Year-on-year time",
  i18nKey_926: "YoY",
  i18nKey_927: "Access floor",
  i18nKey_928: "Pass",
  i18nKey_929: "Stop",
  i18nKey_930: "Deactivate",
  i18nKey_931: "The length of stay can only be a positive integer",
  i18nKey_932: "Length of stay (hours)",
  i18nKey_933: "Parking floor",
  i18nKey_934: "Docked",
  i18nKey_935: "Parking time",
  i18nKey_936: "Parking time",
  i18nKey_937: "Parking fee (within 24 hours)",
  i18nKey_938: "Parking lot charging mode",
  i18nKey_939: "Parking lot name",
  i18nKey_940: "Parking lot address",
  i18nKey_941: "Parking lot can't be null",
  i18nKey_942: "Parking strategy configuration",
  i18nKey_943: "Debugging equipment",
  i18nKey_944: "Add subclass",
  i18nKey_945: "Adding news pushers can only be used by registered users of the Yuncheng app.",
  i18nKey_946: "Add offline payment staff",
  i18nKey_947: "Add a limited time discount",
  i18nKey_948: "Add reminder",
  i18nKey_949: "Add package",
  i18nKey_950: "Add applicable enterprise",
  i18nKey_951: "Add face",
  i18nKey_952: "Add lighting rules",
  i18nKey_953: "Add company",
  i18nKey_954: "Add ride route",
  i18nKey_955: "Add a ride",
  i18nKey_956: "Day push event",
  i18nKey_957: "Days ago",
  i18nKey_958: "Day",
  i18nKey_959: "Replace the earliest",
  i18nKey_960: "Replace selected",
  i18nKey_961: "Replacement relationship",
  i18nKey_962: "Replace basemap",
  i18nKey_963: "body temperature",
  i18nKey_964: "Reminder",
  i18nKey_965: "Remind",
  i18nKey_966: "Prompt threshold",
  i18nKey_967: "Prompt text",
  i18nKey_968: "Prompt warning",
  i18nKey_969: "Tip: Only support jpg, png format picture upload, upload picture size doesn't exceed 3M",
  i18nKey_970: "Reminder: Due to customer privacy, you need to enter the password for second confirmation",
  i18nKey_971: "Tip: The end time must be greater than the start time",
  i18nKey_972: "Tips: The video picture delay is about 5~10 seconds, please wait patiently",
  i18nKey_973: "booked in advance",
  i18nKey_974: "The number of days in advance is limited to within 1-30 days",
  i18nKey_975: "Special",
  i18nKey_976: "Number of packages",
  i18nKey_977: "Package usage",
  i18nKey_978: "The package is being activated, please pause and operate!",
  i18nKey_979: "Package name",
  i18nKey_980: "Package Type",
  i18nKey_981: "Package card users",
  i18nKey_982: "Package card count",
  i18nKey_983: "Package card processing",
  i18nKey_984: "Package card",
  i18nKey_985: "Package payment amount (yuan)",
  i18nKey_986: "Package payment",
  i18nKey_987: "Locking",
  i18nKey_988: "Ownership (owner)",
  i18nKey_989: "All companies",
  i18nKey_990: "The selected time period can't be null",
  i18nKey_991: "The selected time period can't exceed two days",
  i18nKey_992: "The selected year must be between the start date and the end date",
  i18nKey_993: "Owned Park",
  i18nKey_994: "Affiliated company",
  i18nKey_995: "You can't choose your own directory",
  i18nKey_996: "Owning directory can't be null",
  i18nKey_997: "Owned Directory",
  i18nKey_998: "Belonging to",
  i18nKey_999: "Your company has entered special characters, please re-enter",
  i18nKey_1000: "Special characters entered by the company",
  i18nKey_1001: "Owning company can't be null",
  i18nKey_1002: "Company",
  i18nKey_1003: "Sub-item",
  i18nKey_1004: "Affiliation",
  i18nKey_1005: "Department",
  i18nKey_1006: "search history",
  i18nKey_1007: "Four categories",
  i18nKey_1008: "Four-level department",
  i18nKey_1009: "Level 4",
  i18nKey_1010: "4",
  i18nKey_1011: "Private Cloud",
  i18nKey_1012: "private",
  i18nKey_1013: "Note: The added approver will serve as the approver of property management, and up to 5 approvers can be added",
  i18nKey_1014: "Note: The energy consumption ratio of this level to the upper level exceeds >10%, which is abnormal. The total energy consumption of this level is the low-voltage incoming cabinet P11, P21, P31, P41;",
  i18nKey_1015: "Cumulative amount of billed water bill",
  i18nKey_1016: "Merchants who owe water charges",
  i18nKey_1017: "Undeducted amount of water recharge",
  i18nKey_1018: "Accumulated water recharge amount",
  i18nKey_1019: "Overview of utility bills",
  i18nKey_1020: "Hydropower details",
  i18nKey_1021: "Hydropower detailed list",
  i18nKey_1022: "Utility bill payment order",
  i18nKey_1023: "Water and electricity meter details data query",
  i18nKey_1024: "Water meter warning threshold:",
  i18nKey_1025: "Water meter serial number",
  i18nKey_1026: "Water meter number",
  i18nKey_1027: "Refresh",
  i18nKey_1028: "Brush cartoon line",
  i18nKey_1029: "Value is 0-60",
  i18nKey_1030: "Value is 0-120",
  i18nKey_1031: "Data resource",
  i18nKey_1032: "data source",
  i18nKey_1033: "Data anomaly monitoring details",
  i18nKey_1034: "Data abnormal (a)",
  i18nKey_1035: "Abnormal data",
  i18nKey_1036: "The data was not loaded successfully, please try again",
  i18nKey_1037: "Data time",
  i18nKey_1038: "The data can't be retrieved after deletion, confirm the deletion?",
  i18nKey_1039: "The data can't be restored after deletion, confirm the deletion?",
  i18nKey_1040: "Data Sources",
  i18nKey_1041: "Data summary_",
  i18nKey_1042: "Data loss (a)",
  i18nKey_1043: "The input length is not allowed to exceed 50 characters",
  i18nKey_1044: "The input length is not allowed to exceed 30 characters (letters,-and numbers are allowed)",
  i18nKey_1045: "The input length is not allowed to exceed 30 characters (Chinese characters, letters,-and numbers are allowed)",
  i18nKey_1046: "The input length is not allowed to exceed 2000 characters",
  i18nKey_1047: "The input length is not allowed to exceed 100 characters",
  i18nKey_1048: "Enter event title or device name",
  i18nKey_1049: "Enter camera alias",
  i18nKey_1050: "Enter keywords to filter",
  i18nKey_1051: "Enter the station number and press Enter",
  i18nKey_1052: "The number entered can't exceed the maximum value of 30",
  i18nKey_1053: "The number entered can't exceed the maximum value of 24",
  i18nKey_1054: "The number entered can't exceed business hours",
  i18nKey_1055: "Comfort",
  i18nKey_1056: "study",
  i18nKey_1057: "Authorized issuance",
  i18nKey_1058: "Authorization succeeded",
  i18nKey_1059: "Authorization",
  i18nKey_1060: "Interviewee's phone number:",
  i18nKey_1061: "Respondents:",
  i18nKey_1062: "Interviewed company information",
  i18nKey_1063: "The interviewed company can't be null",
  i18nKey_1064: "Interviewed company:",
  i18nKey_1065: "Interviewed company",
  i18nKey_1066: "The interviewed address can't be null",
  i18nKey_1067: "First entry time",
  i18nKey_1068: "Handling fee",
  i18nKey_1069: "Mobile phone number needs to be registered in the Yuncheng app",
  i18nKey_1070: "phone number",
  i18nKey_1071: "Enter data manually",
  i18nKey_1072: "Manual meter reading details",
  i18nKey_1073: "Create manually",
  i18nKey_1074: "Manual trigger",
  i18nKey_1075: "Manual editing",
  i18nKey_1076: "Manual:",
  i18nKey_1077: "Put away",
  i18nKey_1078: "Collection account name",
  i18nKey_1079: "Collection account",
  i18nKey_1080: "Collect phone number",
  i18nKey_1081: "Charge notice",
  i18nKey_1082: "There can be no duplicate options in paid facilities and free facilities!",
  i18nKey_1083: "Toll facilities",
  i18nKey_1084: "Charge amount",
  i18nKey_1085: "Paraphrase",
  i18nKey_1086: "Room temperature",
  i18nKey_1087: "Indoor humidity",
  i18nKey_1088: "Indoor",
  i18nKey_1089: "Applicable enterprise",
  i18nKey_1090: "Is it abnormal",
  i18nKey_1091: "Whether to loop",
  i18nKey_1092: "Whether to modify",
  i18nKey_1093: "Whether to complete the whole process",
  i18nKey_1094: "Whether to delete data",
  i18nKey_1095: "Whether to delete the checked data",
  i18nKey_1096: "Are you sure you want to download the chart image?",
  i18nKey_1097: "Are you sure to download the excel report?",
  i18nKey_1098: "Are you sure to delete the conference room and price information? It can't be restored after deletion!",
  i18nKey_1099: "Confirm to delete the station? It can't be restored after deletion!",
  i18nKey_1100: "Whether to enable monthly card limit",
  i18nKey_1101: "Whether to enable reminder policy",
  i18nKey_1102: "Whether to enable parking lot restrictions",
  i18nKey_1103: "Whether to enable",
  i18nKey_1104: "Have you left",
  i18nKey_1105: "Whether to make up",
  i18nKey_1106: "Video patrol statistics",
  i18nKey_1107: "Video patrol",
  i18nKey_1108: "The video was successfully intercepted, please go to the associated video in the event details on the left to view",
  i18nKey_1109: "Video capture",
  i18nKey_1110: "Video playback",
  i18nKey_1111: "The video picture delay is about 5-10 seconds, please be patient",
  i18nKey_1112: "Video assist",
  i18nKey_1113: "Video punch rate",
  i18nKey_1114: "Video",
  i18nKey_1115: "Event status statistics",
  i18nKey_1116: "Event status",
  i18nKey_1117: "Event reported successfully",
  i18nKey_1118: "Incident report",
  i18nKey_1119: "Event description can't exceed 1000 characters",
  i18nKey_1120: "Event description",
  i18nKey_1121: "Event type",
  i18nKey_1122: "Event level",
  i18nKey_1123: "Event level",
  i18nKey_1124: "Event processing time",
  i18nKey_1125: "Event handling",
  i18nKey_1126: "Event timeout processing configuration",
  i18nKey_1127: "Event title",
  i18nKey_1128: "Event number",
  i18nKey_1129: "Since October 1, 2019",
  i18nKey_1130: "Since October 01, 2019",
  i18nKey_1131: "status of use",
  i18nKey_1132: "Use domain account verification",
  i18nKey_1133: "Use mobile phone number verification",
  i18nKey_1134: "Use time",
  i18nKey_1135: "Utilization rate (%)",
  i18nKey_1136: "Usage rate",
  i18nKey_1137: "Use address:",
  i18nKey_1138: "Real-time video __",
  i18nKey_1139: "Real-time Air Quality Index",
  i18nKey_1140: "Real-time power (kWh)",
  i18nKey_1141: "Real-time meter reading",
  i18nKey_1142: "Actual value",
  i18nKey_1143: "The amount actually paid",
  i18nKey_1144: "The duration should not be less than 30 seconds",
  i18nKey_1145: "don't exceed one hour",
  i18nKey_1146: "Set at least one time",
  i18nKey_1147: "time setting",
  i18nKey_1148: "The time interval can't exceed 6 months",
  i18nKey_1149: "time interval",
  i18nKey_1150: "time limit",
  i18nKey_1151: "period",
  i18nKey_1152: "Point in time",
  i18nKey_1153: "Time can't be null",
  i18nKey_1154: "Time must be a non-zero positive integer",
  i18nKey_1155: "Time passenger flow statistics",
  i18nKey_1156: "Time",
  i18nKey_1157: "List of dishonest",
  i18nKey_1158: "Expiration time",
  i18nKey_1159: "Failure",
  i18nKey_1160: "Total remaining issuable",
  i18nKey_1161: "The remaining amount is less than the threshold",
  i18nKey_1162: "balance",
  i18nKey_1163: "Remaining degree",
  i18nKey_1164: "Remaining parking spaces",
  i18nKey_1165: "Remaining parking spaces",
  i18nKey_1166: "Province Abbreviation",
  i18nKey_1167: "Effective time",
  i18nKey_1168: "Effective date: December 07, 2019",
  i18nKey_1169: "Effective history",
  i18nKey_1170: "Generate QR code",
  i18nKey_1171: "Generate security alarms",
  i18nKey_1172: "Lift type",
  i18nKey_1173: "Shenzhen",
  i18nKey_1174: "Dark blue",
  i18nKey_1175: "Dark gray",
  i18nKey_1176: "ID number and passport number must be filled in",
  i18nKey_1177: "ID number",
  i18nKey_1178: "ID card:",
  i18nKey_1179: "Authentication",
  i18nKey_1180: "application status",
  i18nKey_1181: "Refund time",
  i18nKey_1182: "Applicant's company",
  i18nKey_1183: "Apply for cancellation",
  i18nKey_1184: "Apply for freight elevator",
  i18nKey_1185: "Camera monitoring",
  i18nKey_1186: "The camera is loading, please wait...",
  i18nKey_1187: "Number of cameras",
  i18nKey_1188: "Camera (a)",
  i18nKey_1189: "Camera",
  i18nKey_1190: "Camera number",
  i18nKey_1191: "Video camera",
  i18nKey_1192: "Set the warning value (days):",
  i18nKey_1193: "Set warning value",
  i18nKey_1194: "Setting business hours can effectively exclude passenger flow information during closing hours",
  i18nKey_1195: "Set the message pusher:",
  i18nKey_1196: "Set as main floor",
  i18nKey_1197: "Set successfully",
  i18nKey_1198: "Total number of facilities",
  i18nKey_1199: "Facilities sub-categories",
  i18nKey_1200: "Facility asset number",
  i18nKey_1201: "Facility status",
  i18nKey_1202: "Facility operating data",
  i18nKey_1203: "Facility validity period",
  i18nKey_1204: "Facility location",
  i18nKey_1205: "Facility topology",
  i18nKey_1206: "Facility statistics",
  i18nKey_1207: "Statistics of the number of facilities",
  i18nKey_1208: "Facility data preview",
  i18nKey_1209: "Facility data synchronization",
  i18nKey_1210: "Facility data export",
  i18nKey_1211: "Facility event statistics",
  i18nKey_1212: "Facility event alarm statistics (by type)",
  i18nKey_1213: "Facility event alarm statistics (by level)",
  i18nKey_1214: "Statistics of facility event alarm handling rate",
  i18nKey_1215: "Facility event alert",
  i18nKey_1216: "Facility event",
  i18nKey_1217: "Facilities schematic data",
  i18nKey_1218: "Facilities diagram",
  i18nKey_1219: "The length of the facility name can't exceed 50 characters",
  i18nKey_1220: "The facility name can't be null",
  i18nKey_1221: "Facility name",
  i18nKey_1222: "The facility description can only consist of Chinese and English, numbers and _",
  i18nKey_1223: "The length of the facility description can't exceed 200 characters",
  i18nKey_1224: "Facility description",
  i18nKey_1225: "Statistics of facility availability",
  i18nKey_1226: "Facility availability",
  i18nKey_1227: "Infrastructure basic data information",
  i18nKey_1228: "Facility basic data coding",
  i18nKey_1229: "Facilities image status",
  i18nKey_1230: "Facility image data",
  i18nKey_1231: "When the facility image is activated, the name can't be null",
  i18nKey_1232: "Facility image name",
  i18nKey_1233: "Facilities portrait",
  i18nKey_1234: "Number of facility failures",
  i18nKey_1235: "Facility Work Order",
  i18nKey_1236: "Facility alarm statistics",
  i18nKey_1237: "Facility alias",
  i18nKey_1238: "Overview of facility repair",
  i18nKey_1239: "Facilities repair",
  i18nKey_1240: "Design capacity",
  i18nKey_1241: "Equipment energy ranking",
  i18nKey_1242: "Equipment abnormality monitoring details",
  i18nKey_1243: "Equipment abnormal alarm-fresh fan failure!",
  i18nKey_1244: "Equipment abnormal",
  i18nKey_1245: "Device Information",
  i18nKey_1246: "Device pass record",
  i18nKey_1247: "Device data import template",
  i18nKey_1248: "Number of devices",
  i18nKey_1249: "Equipment sorting",
  i18nKey_1250: "Equipment name",
  i18nKey_1251: "Equipment health",
  i18nKey_1252: "Device alias",
  i18nKey_1253: "Equipment remark name",
  i18nKey_1254: "Device IP",
  i18nKey_1255: "Device ID",
  i18nKey_1256: "Device",
  i18nKey_1257: "Actual electricity consumption last week (kWh)",
  i18nKey_1258: "Last week",
  i18nKey_1259: "Energy used last month",
  i18nKey_1260: "Same period last month",
  i18nKey_1261: "Actual electricity consumption last month (KWh)",
  i18nKey_1262: "Last month average",
  i18nKey_1263: "Energy consumption per square meter last month",
  i18nKey_1264: "Cumulative energy consumption last month",
  i18nKey_1265: "last month",
  i18nKey_1266: "Move up",
  i18nKey_1267: "Energy used in the previous year",
  i18nKey_1268: "Electricity consumption in the same period of the previous year",
  i18nKey_1269: "The same period last year",
  i18nKey_1270: "Energy consumption per square meter last year",
  i18nKey_1271: "last year",
  i18nKey_1272: "Drop off",
  i18nKey_1273: "Morning",
  i18nKey_1274: "Rise",
  i18nKey_1275: "Put on the shelf",
  i18nKey_1276: "Up",
  i18nKey_1277: "Shanghai Jinqiao Wanchuang Center System 2",
  i18nKey_1278: "Shanghai Jinqiao Wanchuang Center System 1",
  i18nKey_1279: "Last position",
  i18nKey_1280: "Last reported time",
  i18nKey_1281: "Last meter reading date",
  i18nKey_1282: "Last meter reading",
  i18nKey_1283: "File upload failed",
  i18nKey_1284: "The uploaded image format is incorrect, please upload again",
  i18nKey_1285: "Upload picture can't be larger than 3M, please re-upload",
  i18nKey_1286: "upload image",
  i18nKey_1287: "Upload diagram",
  i18nKey_1288: "Upload failed, please upload again",
  i18nKey_1289: "upload failed",
  i18nKey_1290: "Uploader",
  i18nKey_1291: "Upload image",
  i18nKey_1292: "Upload housing information form",
  i18nKey_1293: "The name of the uploaded picture is not standardized, only numbers, letters, underscores, and horizontal lines are allowed",
  i18nKey_1294: "Reporting time",
  i18nKey_1295: "Reporter phone",
  i18nKey_1296: "Reporter",
  i18nKey_1297: "Energy consumption analysis of commercial tenants",
  i18nKey_1298: "Business points",
  i18nKey_1299: "Business",
  i18nKey_1300: "Store status",
  i18nKey_1301: "Shop location",
  i18nKey_1302: "Shop number",
  i18nKey_1303: "Product description",
  i18nKey_1304: "Business Name:",
  i18nKey_1305: "Mall traffic",
  i18nKey_1306: "Merchant/tenant selection",
  i18nKey_1307: "Business/Tenant Name",
  i18nKey_1308: "Business/tenant ranking of energy consumption per square meter",
  i18nKey_1309: "The price must be disabled first to delete the information!",
  i18nKey_1310: "To delete information, you need to stop the station first!",
  i18nKey_1311: "All device detailes delete after deletion, confirm to delete?",
  i18nKey_1312: "Node content delete after deletion. Suggest to move first and delete. Confirm to delete?",
  i18nKey_1313: "Can't be restored after deletion, are you sure to delete?",
  i18nKey_1314: "Failed to delete association",
  i18nKey_1315: "After deleting this warning threshold, the default threshold is set to 0. Confirm to delete it?",
  i18nKey_1316: "Deelete successfullyl, all shops under the node delete from the lease list",
  i18nKey_1317: "Delete",
  i18nKey_1318: "Scan to download Yuncheng APP",
  i18nKey_1319: "Scan QR code",
  i18nKey_1320: "Scan code equipment",
  i18nKey_1321: "Scan code login",
  i18nKey_1322: "Ternary lithium",
  i18nKey_1323: "Three categories",
  i18nKey_1324: "Tertiary department",
  i18nKey_1325: "Level 3",
  i18nKey_1326: "three",
  i18nKey_1327: "If there is no ID number, the passport number is required",
  i18nKey_1328: "Type of admission",
  i18nKey_1329: "If there are multiple stops, please enter in the following format: Vanke Yuncheng-Bao'an Center-Qianhai Bay-Bao'an Airport",
  i18nKey_1330: "Capacity",
  i18nKey_1331: "YoY",
  i18nKey_1332: "Day",
  i18nKey_1333: "There is a long way to go, and the ambition must be won.",
  i18nKey_1334: "Task status statistics",
  i18nKey_1335: "Task status",
  i18nKey_1336: "The task is being tracked. Jumping to the page will cause the tracking task to stop. Are you sure you need to jump to the page?",
  i18nKey_1337: "Task owner",
  i18nKey_1338: "Task delegator",
  i18nKey_1339: "Task number",
  i18nKey_1340: "Task starting, please don't delete",
  i18nKey_1341: "Task name can't be null",
  i18nKey_1342: "mission name",
  i18nKey_1343: "Task number",
  i18nKey_1344: "Certified company",
  i18nKey_1345: "Personnel overview (day)",
  i18nKey_1346: "Personnel tracking",
  i18nKey_1347: "Statistics on the proportion of personnel (on the day)",
  i18nKey_1348: "Personnel English name input with special characters",
  i18nKey_1349: "Personnel moved to",
  i18nKey_1350: "Person’s name entered with special characters",
  i18nKey_1351: "Personnel name can't be null",
  i18nKey_1352: "Person's name",
  i18nKey_1353: "Personnel information",
  i18nKey_1354: "Staff picture",
  i18nKey_1355: "Personnel statistics (by park)",
  i18nKey_1356: "Personnel statistics (by type)",
  i18nKey_1357: "People Statistics",
  i18nKey_1358: "Personnel ranking (yesterday/previous day)",
  i18nKey_1359: "Personnel mobile phone number can't be null",
  i18nKey_1360: "Staff heat",
  i18nKey_1361: "Person Type",
  i18nKey_1362: "Personnel pool selection",
  i18nKey_1363: "Personnel basic data information",
  i18nKey_1364: "Personnel basic data created successfully",
  i18nKey_1365: "Personnel basic data coding",
  i18nKey_1366: "There are special characters in the personnel basic data number input, please re-enter",
  i18nKey_1367: "Personnel basic data number",
  i18nKey_1368: "People track",
  i18nKey_1369: "Personnel management",
  i18nKey_1370: "Personnel comparison trend (near two weeks)",
  i18nKey_1371: "Person tag",
  i18nKey_1372: "Person ID",
  i18nKey_1373: "Personnel (last 12 weeks)",
  i18nKey_1374: "Personnel (last 12 months)",
  i18nKey_1375: "Overview of people",
  i18nKey_1376: "People flow statistics",
  i18nKey_1377: "Face tracking failed to start",
  i18nKey_1378: "Face tracking",
  i18nKey_1379: "Face pass",
  i18nKey_1380: "Face ID",
  i18nKey_1381: "Human face",
  i18nKey_1382: "Person times",
  i18nKey_1383: "People",
  i18nKey_1384: "Total passenger flow ranking in popular areas",
  i18nKey_1385: "Heat spread range",
  i18nKey_1386: "Hot spot",
  i18nKey_1387: "Confirm the changes",
  i18nKey_1388: "Confirm the new password",
  i18nKey_1389: "Confirm to add the user",
  i18nKey_1390: "Confirm to give up?",
  i18nKey_1391: "Confirm that this alarm is a misjudgment, this alarm information is invalid",
  i18nKey_1392: "Are you sure to reset this house",
  i18nKey_1393: "Are you sure about one-click reset",
  i18nKey_1394: "Are you sure you want to set the main floor?",
  i18nKey_1395: "Are you sure you want to delete this address?",
  i18nKey_1396: "Are you sure you want to open all $ circuits",
  i18nKey_1397: "Are you sure you want to close all $ circuits?",
  i18nKey_1398: "Are you sure you want to open this $ loop?",
  i18nKey_1399: "Are you sure you want to close these $ loops?",
  i18nKey_1400: "Are you sure to delete the device?",
  i18nKey_1401: "Sure to delete this user",
  i18nKey_1402: "Are you sure to delete the room?",
  i18nKey_1403: "Are you sure to delete these devices in bulk?",
  i18nKey_1404: "Are you sure to delete these apartment types in bulk?",
  i18nKey_1405: "Are you sure to remove the blacklist?",
  i18nKey_1406: "Determine that this alarm is a false positive",
  i18nKey_1407: "All day (24 hours)",
  i18nKey_1408: "Full screen playback",
  i18nKey_1409: "Full control switch",
  i18nKey_1410: "Open all",
  i18nKey_1411: "All meeting rooms",
  i18nKey_1412: "Close all",
  i18nKey_1413: "The responsibility month can't be null",
  i18nKey_1414: "Power month",
  i18nKey_1415: "Permission resources",
  i18nKey_1416: "same time last year",
  i18nKey_1417: "The cancellation refund limit can't exceed 100%!",
  i18nKey_1418: "No more than 10 cancellation rules!",
  i18nKey_1419: "Regional heat",
  i18nKey_1420: "Area name",
  i18nKey_1421: "Regional energy consumption per square meter ranking",
  i18nKey_1422: "Area type can't be null",
  i18nKey_1423: "Area type",
  i18nKey_1424: "Regional passenger flow",
  i18nKey_1425: "Regional visit ranking",
  i18nKey_1426: "area",
  i18nKey_1427: "Block",
  i18nKey_1428: "Please select at least one camera",
  i18nKey_1429: "Please select at least one person",
  i18nKey_1430: "Please add at least one person to save",
  i18nKey_1431: "Please enter the new password again",
  i18nKey_1432: "Please enter the password again",
  i18nKey_1433: "Please select the lease date range",
  i18nKey_1434: "Please select asset type",
  i18nKey_1435: "Please select a theme",
  i18nKey_1436: "Please select an important level",
  i18nKey_1437: "Please select repeat method",
  i18nKey_1438: "Please select lighting mode",
  i18nKey_1439: "Please choose the responsible manager",
  i18nKey_1440: "Please select the time of suspension",
  i18nKey_1441: "Please select the allowed visit period",
  i18nKey_1442: "Please select the booking start time",
  i18nKey_1443: "Please select the end time of the booking",
  i18nKey_1444: "Please choose preferential strategy",
  i18nKey_1445: "Please select an application system",
  i18nKey_1446: "Please select the business start time",
  i18nKey_1447: "Please select the closing time",
  i18nKey_1448: "Please select the exception type",
  i18nKey_1449: "Please select the instrument belongs to",
  i18nKey_1450: "Please select the instrument brand",
  i18nKey_1451: "Please select a category",
  i18nKey_1452: "Please select a department",
  i18nKey_1453: "Please select a format",
  i18nKey_1454: "Please select the vehicle to delete",
  i18nKey_1455: "Please select the facilities to be synchronized",
  i18nKey_1456: "Please select the file to upload",
  i18nKey_1457: "Please select the first picture of the meeting room to upload!",
  i18nKey_1458: "Please select the first image of the workstation to be uploaded!",
  i18nKey_1459: "Please select the banner picture to upload!",
  i18nKey_1460: "Please select the strategy to delete",
  i18nKey_1461: "Please select sleep brightness",
  i18nKey_1462: "Please select the order time",
  i18nKey_1463: "Please select a five-level classification",
  i18nKey_1464: "Please select a five-level department",
  i18nKey_1465: "Please choose a location",
  i18nKey_1466: "Please select the stop time",
  i18nKey_1467: "Please choose a way",
  i18nKey_1468: "Please select a parking lot",
  i18nKey_1469: "Please select package type",
  i18nKey_1470: "Please select four levels",
  i18nKey_1471: "Please select a four-level department",
  i18nKey_1472: "Please select to enter the actual payment amount",
  i18nKey_1473: "Please choose to enter the order amount",
  i18nKey_1474: "Please select the authorization start and end time",
  i18nKey_1475: "Please select whether it is a debugging device",
  i18nKey_1476: "Please choose whether to list",
  i18nKey_1477: "Please select event status",
  i18nKey_1478: "Please select event type",
  i18nKey_1479: "Please select event level",
  i18nKey_1480: "Please select event level",
  i18nKey_1481: "Please select time period",
  i18nKey_1482: "Please choose time",
  i18nKey_1483: "Please select the lift type",
  i18nKey_1484: "Please select application status",
  i18nKey_1485: "Please select camera",
  i18nKey_1486: "Please select facility status",
  i18nKey_1487: "Please select facility location",
  i18nKey_1488: "Please select facility image status",
  i18nKey_1489: "Please select the name of the facility image",
  i18nKey_1490: "Please select equipment",
  i18nKey_1491: "Please select store status",
  i18nKey_1492: "Please select shop location",
  i18nKey_1493: "Please select a merchant",
  i18nKey_1494: "Please select to delete data",
  i18nKey_1495: "Please select filter criteria",
  i18nKey_1496: "Please select scan code device",
  i18nKey_1497: "Please select a three-level classification",
  i18nKey_1498: "Please select a three-level department",
  i18nKey_1499: "Please select a date range",
  i18nKey_1500: "Please select task status",
  i18nKey_1501: "Please select the type of personnel",
  i18nKey_1502: "Please select personnel",
  i18nKey_1503: "Please select the responsibility month",
  i18nKey_1504: "Please select area type",
  i18nKey_1505: "Please select a region",
  i18nKey_1506: "Please select the type of arrears merchant",
  i18nKey_1507: "Please select the enabled state",
  i18nKey_1508: "Please select a brand",
  i18nKey_1509: "Please select the dispatch time",
  i18nKey_1510: "Please select age range",
  i18nKey_1511: "Please select the year",
  i18nKey_1512: "Please select the lock number",
  i18nKey_1513: "Please choose a route",
  i18nKey_1514: "Please select a building",
  i18nKey_1515: "Please select a building unit",
  i18nKey_1516: "Please select building",
  i18nKey_1517: "Please select floor space",
  i18nKey_1518: "Please select floor",
  i18nKey_1519: "Please select a six-level classification",
  i18nKey_1520: "Please choose the type",
  i18nKey_1521: "Please select the source system code",
  i18nKey_1522: "Please select start time",
  i18nKey_1523: "Please select a start date",
  i18nKey_1524: "Please select the parking time",
  i18nKey_1525: "Please select an entry time",
  i18nKey_1526: "Please select the end time",
  i18nKey_1527: "Please select the type of role",
  i18nKey_1528: "Please select the role type",
  i18nKey_1529: "Please select the billing type",
  i18nKey_1530: "Please select level",
  i18nKey_1531: "Please choose freight elevator",
  i18nKey_1532: "Please select activity status",
  i18nKey_1533: "Please select a circuit!",
  i18nKey_1534: "Please select the consequences",
  i18nKey_1535: "Please select contract",
  i18nKey_1536: "Please select a country",
  i18nKey_1537: "Please select the associated face library",
  i18nKey_1538: "Please select the fault level",
  i18nKey_1539: "Please select public area type",
  i18nKey_1540: "Please select station status",
  i18nKey_1541: "Please select the type of workstation!",
  i18nKey_1542: "Please select ticket status",
  i18nKey_1543: "Please select sensing brightness",
  i18nKey_1544: "Please select the type of the house",
  i18nKey_1545: "Please select a payment method",
  i18nKey_1546: "Please select a subdivision",
  i18nKey_1547: "Please select installment",
  i18nKey_1548: "Please select visitor status",
  i18nKey_1549: "Please select room location",
  i18nKey_1550: "Please select the time range for the invitation",
  i18nKey_1551: "Please select issue type",
  i18nKey_1552: "Please select a secondary classification",
  i18nKey_1553: "Please select a secondary department",
  i18nKey_1554: "Please select order status",
  i18nKey_1555: "Please select the point type",
  i18nKey_1556: "Please select a location",
  i18nKey_1557: "Please choose to the park",
  i18nKey_1558: "Please select the region",
  i18nKey_1559: "Please select the city",
  i18nKey_1560: "Please select a unit",
  i18nKey_1561: "Please select the handling mechanism",
  i18nKey_1562: "Please select the initialization time",
  i18nKey_1563: "Please select a parking time",
  i18nKey_1564: "Please select recharge type",
  i18nKey_1565: "Please select the ride time",
  i18nKey_1566: "Please select city",
  i18nKey_1567: "Please select the owner type",
  i18nKey_1568: "Please select a vehicle tag",
  i18nKey_1569: "Please select the depot name",
  i18nKey_1570: "Please select a parking lot",
  i18nKey_1571: "Please select the scene icon",
  i18nKey_1572: "Please choose a scenario strategy",
  i18nKey_1573: "Please select a scene",
  i18nKey_1574: "Please select a product",
  i18nKey_1575: "Please select a strategically associated park",
  i18nKey_1576: "Please select the street light associated with the strategy",
  i18nKey_1577: "Please select deployment status",
  i18nKey_1578: "Please select the deployment camera",
  i18nKey_1579: "Please select the type of deployment",
  i18nKey_1580: "Please select at least 1 card",
  i18nKey_1581: "Please select no more than $ cards",
  i18nKey_1582: "Please select no more than",
  i18nKey_1583: "Please select the bus schedule",
  i18nKey_1584: "Please select installation time",
  i18nKey_1585: "Please select GIS coordinate type",
  i18nKey_1586: "please choose",
  i18nKey_1587: "Please select the meeting room name first",
  i18nKey_1588: "Please select associated data first",
  i18nKey_1589: "Please save the changes first",
  i18nKey_1590: "don't add people repeatedly",
  i18nKey_1591: "don't add repeatedly",
  i18nKey_1592: "don't enter special characters!",
  i18nKey_1593: "don't enter special characters",
  i18nKey_1594: "No more than 6",
  i18nKey_1595: "Please complete the device information",
  i18nKey_1596: "Please complete the required fields",
  i18nKey_1597: "Please fill in the event description",
  i18nKey_1598: "Please fill in the certified company",
  i18nKey_1599: "Please fill in the license plate number",
  i18nKey_1600: "Please fill in the vehicle color",
  i18nKey_1601: "Please fill in the vehicle brand",
  i18nKey_1602: "Please fill in the strategy name",
  i18nKey_1603: "Please add applicable companies!",
  i18nKey_1604: "Please add a person tag",
  i18nKey_1605: "Please add a route",
  i18nKey_1606: "Please search or select a company",
  i18nKey_1607: "Please enter the minimum number of alarms",
  i18nKey_1608: "Please enter the minimum rent",
  i18nKey_1609: "Please enter the maximum number of alarms",
  i18nKey_1610: "Please enter at most 2 ~ 50 characters, one Chinese character counts as two characters.",
  i18nKey_1611: "Please enter at most 2 ~ 10 characters, one Chinese character counts as two characters. ",
  i18nKey_1612: "Please enter at most 1 ~ 50 characters, one Chinese character counts as two characters, and no spaces are allowed!",
  i18nKey_1613: "Please enter the total switch amperage",
  i18nKey_1614: "Please enter the custom QR code refresh time",
  i18nKey_1615: "Please enter asset description",
  i18nKey_1616: "Please enter asset number",
  i18nKey_1617: "Please enter the consultation phone",
  i18nKey_1618: "Please enter the terminal",
  i18nKey_1619: "Please enter the instruction issuing cycle",
  i18nKey_1620: "Please enter the branch name",
  i18nKey_1621: "Please enter payment order number",
  i18nKey_1622: "Please enter expenditure/income",
  i18nKey_1623: "Please enter a positive integer",
  i18nKey_1624: "Please enter a positive number (up to two decimal places)!",
  i18nKey_1625: "Please enter the correct format mobile phone number to search",
  i18nKey_1626: "Please enter the phone number in the correct format",
  i18nKey_1627: "Please enter correct verify code",
  i18nKey_1628: "Please enter the correct name and phone number",
  i18nKey_1629: "Please enter the correct phone number",
  i18nKey_1630: "please enter a valid phone number",
  i18nKey_1631: "Please enter the correct ID number",
  i18nKey_1632: "Please enter the correct passport number",
  i18nKey_1633: "Please enter the correct phone number",
  i18nKey_1634: "Please enter the correct number of times",
  i18nKey_1635: "Please enter the correct license plate number or mobile phone number",
  i18nKey_1636: "Please enter the correct license plate number",
  i18nKey_1637: "Please enter the correct remark name",
  i18nKey_1638: "Please enter the correct office phone",
  i18nKey_1639: "Please enter account or mobile number",
  i18nKey_1640: "Please enter the percentage",
  i18nKey_1641: "Please enter the original password",
  i18nKey_1642: "Please enter the park name",
  i18nKey_1643: "Please enter domain account",
  i18nKey_1644: "Please enter the warning value",
  i18nKey_1645: "Please enter the scheduled start time",
  i18nKey_1646: "Please enter the name of the booker",
  i18nKey_1647: "Please enter the instrument brand",
  i18nKey_1648: "Please enter the meter name",
  i18nKey_1649: "Please enter the meter number, equipment name, business/tenant name",
  i18nKey_1650: "Please enter the meter code/room number/floor",
  i18nKey_1651: "Please enter the meter code/number/name",
  i18nKey_1652: "Please enter the meter number/building unit",
  i18nKey_1653: "Please enter the meter number",
  i18nKey_1654: "Please enter the business name",
  i18nKey_1655: "please enter verification code",
  i18nKey_1656: "Please enter the patrol route description",
  i18nKey_1657: "Please enter patrol time",
  i18nKey_1658: "Please enter patrol person",
  i18nKey_1659: "Please enter the patrol route description",
  i18nKey_1660: "Please enter the name of the patrol route",
  i18nKey_1661: "Please enter the number of days to be counted",
  i18nKey_1662: "Please enter the total number of days to be monitored",
  i18nKey_1663: "Please enter a new password",
  i18nKey_1664: "Please enter a number less than 10",
  i18nKey_1665: "Please enter a price less than 1~20000",
  i18nKey_1666: "Please enter hour/day",
  i18nKey_1667: "Please enter the fire zone name",
  i18nKey_1668: "Please enter the detailed address (accurate to the number of the province, city and district)",
  i18nKey_1669: "Please enter the detailed address",
  i18nKey_1670: "Please enter the display price",
  i18nKey_1671: "Please enter the number of violations",
  i18nKey_1672: "Please enter your full name or phone number",
  i18nKey_1673: "Please enter an external location name",
  i18nKey_1674: "Please enter the parking lot name",
  i18nKey_1675: "Please enter the parking lot address",
  i18nKey_1676: "Please enter the number of packages",
  i18nKey_1677: "Please enter the number of times the package has been used",
  i18nKey_1678: "Please enter the package name",
  i18nKey_1679: "Please enter the company",
  i18nKey_1680: "Please enter search term",
  i18nKey_1681: "Please enter a numeric value",
  i18nKey_1682: "Please key in numbers",
  i18nKey_1683: "Please enter the quantity",
  i18nKey_1684: "Please enter the interviewed company",
  i18nKey_1685: "Please enter the interviewed address",
  i18nKey_1686: "Please enter the phone to search for a match",
  i18nKey_1687: "Please enter your phone number to search",
  i18nKey_1688: "Please enter your mobile phone number to search",
  i18nKey_1689: "Please enter your mobile phone number or domain account",
  i18nKey_1690: "Please enter phone number",
  i18nKey_1691: "Please enter the credit account name",
  i18nKey_1692: "Please enter your mobile phone number",
  i18nKey_1693: "Please enter the charge instructions",
  i18nKey_1694: "Please enter event number",
  i18nKey_1695: "Please enter a facility alias",
  i18nKey_1696: "Please enter the facility number",
  i18nKey_1697: "Please enter a device alias",
  i18nKey_1698: "Please enter the reporter phone number",
  i18nKey_1699: "Please enter the reporter",
  i18nKey_1700: "Please enter shop number",
  i18nKey_1701: "Please enter the business name",
  i18nKey_1702: "Please enter the business/tenant name",
  i18nKey_1703: "Please enter the capacity, it must be an integer",
  i18nKey_1704: "Please enter the capacity",
  i18nKey_1705: "Please enter the task owner",
  i18nKey_1706: "Please enter the task delegate",
  i18nKey_1707: "Please enter the task name",
  i18nKey_1708: "Please enter a task description",
  i18nKey_1709: "Please enter task or person name",
  i18nKey_1710: "Please enter the task number",
  i18nKey_1711: "Please enter the personnel basic data number",
  i18nKey_1712: "Please enter the heat range",
  i18nKey_1713: "Please enter block information",
  i18nKey_1714: "Please enter the starting point",
  i18nKey_1715: "Please enter the Chinese name of the company",
  i18nKey_1716: "Please enter the business email suffix",
  i18nKey_1717: "Please enter the company website",
  i18nKey_1718: "Please enter company name",
  i18nKey_1719: "Please enter the company's phone number",
  i18nKey_1720: "Please enter company introduction",
  i18nKey_1721: "Please enter ticket/unit price",
  i18nKey_1722: "Please enter the batch number",
  i18nKey_1723: "Please enter a nickname or phone search",
  i18nKey_1724: "Please enter a nickname",
  i18nKey_1725: "Please enter content",
  i18nKey_1726: "Please enter the Morgan platform meeting room ID",
  i18nKey_1727: "Please enter the pattern name",
  i18nKey_1728: "Please enter the area, it must be an integer",
  i18nKey_1729: "Please enter area",
  i18nKey_1730: "Please enter password",
  i18nKey_1731: "Please enter the door name",
  i18nKey_1732: "Please enter the effective length of stay to be analyzed per day",
  i18nKey_1733: "Please enter a route description",
  i18nKey_1734: "Please enter the street light name or address",
  i18nKey_1735: "Please enter the range",
  i18nKey_1736: "Please type your phone number",
  i18nKey_1737: "Please enter the accumulated rentable time",
  i18nKey_1738: "Please enter the space name/work station number",
  i18nKey_1739: "Please enter the name of the person who can book",
  i18nKey_1740: "Please enter a distance range",
  i18nKey_1741: "Please enter the old password",
  i18nKey_1742: "Please enter the amount",
  i18nKey_1743: "Please enter the host’s name/phone number",
  i18nKey_1744: "Please enter the role name",
  i18nKey_1745: "Please enter a role description",
  i18nKey_1746: "Please enter the reward quantity",
  i18nKey_1747: "Please enter the reward object/account number",
  i18nKey_1748: "Please enter the building name",
  i18nKey_1749: "Please enter a simple route",
  i18nKey_1750: "Please enter the price",
  i18nKey_1751: "Please enter the meter name",
  i18nKey_1752: "Please enter the meter number",
  i18nKey_1753: "Please enter the plan name",
  i18nKey_1754: "Please enter the name of the point type",
  i18nKey_1755: "Please enter the value of points",
  i18nKey_1756: "Please enter event name",
  i18nKey_1757: "Please enter an event",
  i18nKey_1758: "Please enter the name of the meeting room/booking company/meeting status",
  i18nKey_1759: "Please enter the meeting room name",
  i18nKey_1760: "Please enter a description of the meeting room",
  i18nKey_1761: "Please enter the house name",
  i18nKey_1762: "Please enter the number of people",
  i18nKey_1763: "Please enter a valid MAC address, such as 00-01-6c-06-A6-29 or 00:01:6c:06:A6:29",
  i18nKey_1764: "Please enter the name of the administrator",
  i18nKey_1765: "Please enter the name of the public area",
  i18nKey_1766: "Please enter the staff phone number",
  i18nKey_1767: "Please enter staff name",
  i18nKey_1768: "Please enter the number of stations",
  i18nKey_1769: "Please enter a profile",
  i18nKey_1770: "Please enter the price",
  i18nKey_1771: "Please enter the business license registration number",
  i18nKey_1772: "Please enter the person responsible for the ticket",
  i18nKey_1773: "Please enter the ticket number",
  i18nKey_1774: "Please enter the duration of keeping brightness after induction",
  i18nKey_1775: "Please enter the number of additional facilities",
  i18nKey_1776: "Please enter the name of the additional facility",
  i18nKey_1777: "Please enter the server IP or domain name",
  i18nKey_1778: "Please enter the item name",
  i18nKey_1779: "Please enter the room name",
  i18nKey_1780: "Please enter the legal person ID",
  i18nKey_1781: "Please enter the legal representative",
  i18nKey_1782: "Please enter the issued quantity",
  i18nKey_1783: "Please enter the quota, which must be an integer",
  i18nKey_1784: "Please enter the amount",
  i18nKey_1785: "Please enter how many minutes",
  i18nKey_1786: "Please enter order number/space name",
  i18nKey_1787: "Please enter the order number/ conference room name",
  i18nKey_1788: "Please enter order number",
  i18nKey_1789: "Please enter the cable square number",
  i18nKey_1790: "Please enter phone search",
  i18nKey_1791: "Please enter the meter number, equipment name, merchant/tenant name",
  i18nKey_1792: "Please enter the meter number",
  i18nKey_1793: "Please enter the third party ID",
  i18nKey_1794: "Please enter the address latitude and longitude",
  i18nKey_1795: "Please enter the address",
  i18nKey_1796: "Please enter the current account login password",
  i18nKey_1797: "Please enter the unit price (yuan/hour)",
  i18nKey_1798: "Please enter the processor",
  i18nKey_1799: "Please enter the tenant",
  i18nKey_1800: "Please enter the owner's name",
  i18nKey_1801: "Please enter the meter reader",
  i18nKey_1802: "Please enter the scene name",
  i18nKey_1803: "Please enter the name of the scene, no more than ten characters",
  i18nKey_1804: "Please enter a strategy name",
  i18nKey_1805: "Please enter the bottom of the form",
  i18nKey_1806: "Please enter the magnification",
  i18nKey_1807: "Please enter the half-day unit price (yuan/4 hours)",
  i18nKey_1808: "Please enter the shift number",
  i18nKey_1809: "Please enter the installation location",
  i18nKey_1810: "Please enter a decimal between 0-1",
  i18nKey_1811: "Please enter $ table number, equipment name, business/tenant name",
  i18nKey_1812: "please enter",
  i18nKey_1813: "Please upload a new version of excel file",
  i18nKey_1814: "Please upload an image",
  i18nKey_1815: "Please make sure that you have permission to access this information. All your operations will be recorded. If you don't operate for more than 30 minutes, you will need to re-verify",
  i18nKey_1816: "Request association list exception",
  i18nKey_1817: "Please contact the administrator to obtain related service permissions.",
  i18nKey_1818: "Please fill in the IT code, business code and space level name of the current level completely!",
  i18nKey_1819: "Please check the link in the address bar and visit again",
  i18nKey_1820: "Please log in to your account",
  i18nKey_1821: "please sign in",
  i18nKey_1822: "Please integrate the same formula",
  i18nKey_1823: "Clear",
  i18nKey_1824: "Light pollution",
  i18nKey_1825: "blue",
  i18nKey_1826: "Switch to full view",
  i18nKey_1827: "Switch to default scale",
  i18nKey_1828: "Switch image",
  i18nKey_1829: "Switch list",
  i18nKey_1830: "Merchants in arrears",
  i18nKey_1831: "the day before",
  i18nKey_1832: "Go to IOC login page",
  i18nKey_1833: "The start time can't be greater than or equal to the end time",
  i18nKey_1834: "The start or end duration can't be null",
  i18nKey_1835: "The starting or ending amount can't be null",
  i18nKey_1836: "The start or end times can't be null",
  i18nKey_1837: "Starting point",
  i18nKey_1838: "Starting number",
  i18nKey_1839: "Starting point can't be null",
  i18nKey_1840: "Activation date",
  i18nKey_1841: "Enable",
  i18nKey_1842: "Enterprise Chinese name",
  i18nKey_1843: "Corporate employees",
  i18nKey_1844: "Enterprise mailbox suffix",
  i18nKey_1845: "Enterprise English name",
  i18nKey_1846: "Enterprise address",
  i18nKey_1847: "Enterprise URL",
  i18nKey_1848: "Enterprise Merchant",
  i18nKey_1849: "Enterprise energy consumption ranking",
  i18nKey_1850: "Company Name",
  i18nKey_1851: "Business Daily Check-in_",
  i18nKey_1852: "Corporate Contact Number",
  i18nKey_1853: "Enterprise Contact Number",
  i18nKey_1854: "Enterprise type",
  i18nKey_1855: "Company Profile",
  i18nKey_1856: "Business account",
  i18nKey_1857: "Corporate visitors",
  i18nKey_1858: "Corporate",
  i18nKey_1859: "company address",
  i18nKey_1860: "Corporate logo",
  i18nKey_1861: "Enterprise",
  i18nKey_1862: "Energy consumption of its sub-items",
  i18nKey_1863: "Other",
  i18nKey_1864: "Other electricity",
  i18nKey_1865: "Other information",
  i18nKey_1866: "Other",
  i18nKey_1867: "Seven categories",
  i18nKey_1868: "Ordinary event",
  i18nKey_1869: "Ordinary",
  i18nKey_1870: "Weekday passenger flow",
  i18nKey_1871: "Average conversion time",
  i18nKey_1872: "Average length of stay",
  i18nKey_1873: "Average value",
  i18nKey_1874: "Average daily savings",
  i18nKey_1875: "Maximum number of issues per company",
  i18nKey_1876: "Average processing time:",
  i18nKey_1877: "Average processing time (minutes)",
  i18nKey_1878: "Leveling",
  i18nKey_1879: "Brands can only be composed of Chinese and English, numbers and _",
  i18nKey_1880: "Brand length can't exceed 100 characters",
  i18nKey_1881: "More than 6 options of brand and color have been displayed for you according to the best",
  i18nKey_1882: "Brand Analysis",
  i18nKey_1883: "Brand more than 6 options have been displayed for you according to the best",
  i18nKey_1884: "Brand:",
  i18nKey_1885: "Brand",
  i18nKey_1886: "Frequency",
  i18nKey_1887: "Frequency",
  i18nKey_1888: "Ticket/unit price",
  i18nKey_1889: "Batch modify templates based on exported data",
  i18nKey_1890: "Batch Edit",
  i18nKey_1891: "Batch creation",
  i18nKey_1892: "Configuration details",
  i18nKey_1893: "Distribution switch name",
  i18nKey_1894: "Distribution time",
  i18nKey_1895: "Successfully distributed",
  i18nKey_1896: "Rank",
  i18nKey_1897: "Female",
  i18nKey_1898: "You have a lot of tasks to be queried, please wait for the tasks to be executed before querying.",
  i18nKey_1899: "You have successfully changed your password, please log in to the platform again.",
  i18nKey_1900: "Do you want to suspend the selected package?",
  i18nKey_1901: "Do you want to suspend the selected allowance?",
  i18nKey_1902: "Do you want to suspend the selected meeting room?",
  i18nKey_1903: "Do you want to suspend the selected station?",
  i18nKey_1904: "Do you want to suspend the selected bus schedule?",
  i18nKey_1905: "Do you want to remove the selected limited time discount?",
  i18nKey_1906: "Do you want to list the selected limited time discount?",
  i18nKey_1907: "Do you want to activate the selected package?",
  i18nKey_1908: "Do you want to activate the selected allowance?",
  i18nKey_1909: "Do you want to activate the selected meeting room?",
  i18nKey_1910: "Do you want to activate the selected station?",
  i18nKey_1911: "Do you want to activate the selected bus schedule?",
  i18nKey_1912: "Do you want to clear all query history?",
  i18nKey_1913: "There is an overlap in the time you set",
  i18nKey_1914: "Are you sure you want to execute?",
  i18nKey_1915: "Are you sure you want to deactivate?",
  i18nKey_1916: "Are you sure you want to delete?",
  i18nKey_1917: "Are you sure you want to enable it?",
  i18nKey_1918: "Are you sure you want to delete the limited time discount?",
  i18nKey_1919: "Are you sure you want to delete the package?",
  i18nKey_1920: "You have not selected any data items!",
  i18nKey_1921: "You have not selected any data items",
  i18nKey_1922: "Hello and welcome to IOC!",
  i18nKey_1923: "You don’t have any company visitor data yet, you can",
  i18nKey_1924: "The page you visited doesn't exist",
  i18nKey_1925: "Your browser doesn't support HTML5",
  i18nKey_1926: "There is a fault in the device you currently selected, please arrange for someone to handle it first!",
  i18nKey_1927: "The large screen you currently selected is offline and the command is unreachable!",
  i18nKey_1928: "age range",
  i18nKey_1929: "year",
  i18nKey_1930: "year",
  i18nKey_1931: "Nickname can't exceed 12 characters in length",
  i18nKey_1932: "nickname",
  i18nKey_1933: "You have chosen the package to pay",
  i18nKey_1934: "You have chosen to suspend payment",
  i18nKey_1935: "You have entered $/$, compared with the data in the previous period, there are $ data abnormal ($), are you sure to submit",
  i18nKey_1936: "You have imported $ data, there are $ data abnormal, are you sure to submit",
  i18nKey_1937: "The strategy you edited has not been saved yet, are you sure to give up?",
  i18nKey_1938: "Are you sure you need to delete this policy? After deleting, you need to manually control the light switch.",
  i18nKey_1939: "Energy efficiency ratio COP (kW/kW)",
  i18nKey_1940: "Energy efficiency AI diagnosis",
  i18nKey_1941: "Energy consumption statistics",
  i18nKey_1942: "Waste of energy",
  i18nKey_1943: "Energy consumption monitoring",
  i18nKey_1944: "Energy consumption comparison",
  i18nKey_1945: "Energy consumption (kWh)",
  i18nKey_1946: "Internal business",
  i18nKey_1947: "male",
  i18nKey_1948: "Target value setting",
  i18nKey_1949: "Target value",
  i18nKey_1950: "Default (refresh at 00:00 the next day)",
  i18nKey_1951: "default",
  i18nKey_1952: "Morgan Platform Meeting Room ID",
  i18nKey_1953: "Pattern name",
  i18nKey_1954: "mode",
  i18nKey_1955: "Fuzzy query",
  i18nKey_1956: "Template download",
  i18nKey_1957: "Detail",
  i18nKey_1958: "Resend in seconds",
  i18nKey_1959: "After seconds, it will automatically jump to the IOC login page",
  i18nKey_1960: "second",
  i18nKey_1961: "Description can't be null",
  i18nKey_1962: "Interview",
  i18nKey_1963: "Area (m²)",
  i18nKey_1964: "Free facilities",
  i18nKey_1965: "Free quota (hour/month)",
  i18nKey_1966: "The allowance is being activated and no modification is allowed!",
  i18nKey_1967: "Password status",
  i18nKey_1968: "The password length is 8-20 characters!",
  i18nKey_1969: "The password must be 8-20 digits long and contain numbers and letters",
  i18nKey_1970: "If the password is entered incorrectly more than 5 times, you will log out after 10 seconds",
  i18nKey_1971: "The password strength doesn't match, at least 8 digits, including at least three types of numbers, uppercase and lowercase letters, and special characters",
  i18nKey_1972: "Incorrect password, please try again",
  i18nKey_1973: "Password can't contain spaces",
  i18nKey_1974: "Password must be 8-20 digits and letters",
  i18nKey_1975: "The password must include at least 2 of letters, numbers, and symbols",
  i18nKey_1976: "Meter",
  i18nKey_1977: "Door lock number",
  i18nKey_1978: "Access control limit",
  i18nKey_1979: "Access control method",
  i18nKey_1980: "Door name",
  i18nKey_1981: "Access control QR code reader",
  i18nKey_1982: "Access control PAD",
  i18nKey_1983: "Up to 6 lighting rules can be added to each strategy",
  i18nKey_1984: "Energy consumption per square meter (t/m²)",
  i18nKey_1985: "Energy consumption per square meter (kWh/m²)",
  i18nKey_1986: "Energy consumption per square meter",
  i18nKey_1987: "You can only track one task at a time. Are you sure you need to abandon the current tracking task and start a new tracking task?",
  i18nKey_1988: "No data, please select the building from the space tree below",
  i18nKey_1989: "No data!",
  i18nKey_1990: "No meeting schedule",
  i18nKey_1991: "User not found",
  i18nKey_1992: "Green and energy saving",
  i18nKey_1993: "green",
  i18nKey_1994: "Number of patrol aircraft",
  i18nKey_1995: "Route description",
  i18nKey_1996: "Route list",
  i18nKey_1997: "Route (article)",
  i18nKey_1998: "Route",
  i18nKey_1999: "Street lamp selection",
  i18nKey_2000: "Building Automation",
  i18nKey_2001: "Building unit",
  i18nKey_2002: "Building/floor/room",
  i18nKey_2003: "Building",
  i18nKey_2004: "Floor space",
  i18nKey_2005: "Floor",
  i18nKey_2006: "Six categories",
  i18nKey_2007: "Level 6",
  i18nKey_2008: "Temporary suspension fee",
  i18nKey_2009: "Temporary suspension payment amount (yuan)",
  i18nKey_2010: "Stop time",
  i18nKey_2011: "Pro parking",
  i18nKey_2012: "Stop",
  i18nKey_2013: "Temporary visitor",
  i18nKey_2014: "temporary",
  i18nKey_2015: "Range",
  i18nKey_2016: "Vehicles",
  i18nKey_2017: "Brightness display",
  i18nKey_2018: "Brightness setting",
  i18nKey_2019: "brightness",
  i18nKey_2020: "Lighting rules",
  i18nKey_2021: "The two passwords entered are inconsistent",
  i18nKey_2022: "Two charge and two discharge",
  i18nKey_2023: "good",
  i18nKey_2024: "contact number",
  i18nKey_2025: "Townhouse",
  i18nKey_2026: "Example: Ping An XXXXXX parking lot, 15 yuan for the first hour (free for the first 15 minutes), 10 yuan per hour thereafter, 60 capped. Futian District, Shenzhen City, Guangdong Province XXXXX",
  i18nKey_2027: "For example: 15 yuan for the first hour (free for the first 15 minutes), 10 yuan per hour thereafter, 60 capped.",
  i18nKey_2028: "Sign up now",
  i18nKey_2029: "log in immediately",
  i18nKey_2030: "Historical diagnosis",
  i18nKey_2031: "Historical statistics",
  i18nKey_2032: "End of historical video playback",
  i18nKey_2033: "Historical video",
  i18nKey_2034: "Historical curve",
  i18nKey_2035: "Historical track",
  i18nKey_2036: "historic version",
  i18nKey_2037: "Offline ",
  i18nKey_2038: "Offline",
  i18nKey_2039: "Leaving the comfort zone is the first step forward.",
  i18nKey_2040: "Outlet temperature of chiller",
  i18nKey_2041: "Cumulative consumption amount",
  i18nKey_2042: "Accumulated parking fee (CNY)",
  i18nKey_2043: "Accumulative temporary suspension of payment $ yuan, you can recommend the monthly card to him",
  i18nKey_2044: "Cumulative rentable time",
  i18nKey_2045: "The accumulative rent can often not be less than the minimum rent!",
  i18nKey_2046: "Cumulative payment>¥400",
  i18nKey_2047: "Cumulative visits",
  i18nKey_2048: "Cumulative visits",
  i18nKey_2049: "Cumulative energy saving potential started from October 1, 2019 to present",
  i18nKey_2050: "Cumulative energy saving potential (kWh)",
  i18nKey_2051: "Cumulative energy saving potential",
  i18nKey_2052: "Type can't be null",
  i18nKey_2053: "Types of",
  i18nKey_2054: "Category can't be null",
  i18nKey_2055: "Blue",
  i18nKey_2056: "From province",
  i18nKey_2057: "Source system device number",
  i18nKey_2058: "Source system",
  i18nKey_2059: "source",
  i18nKey_2060: "Reason for visiting",
  i18nKey_2061: "The start time of the visit period can't be greater than or equal to the end time",
  i18nKey_2062: "Type of visit",
  i18nKey_2063: "Idle state",
  i18nKey_2064: "Air conditioning electricity",
  i18nKey_2065: "Satisfactory air quality, basically no air pollution",
  i18nKey_2066: "Spatial location",
  i18nKey_2067: "Space name/work station number",
  i18nKey_2068: "Space name",
  i18nKey_2069: "Space type",
  i18nKey_2070: "Space basic data service coding",
  i18nKey_2071: "Spatial basic data modification",
  i18nKey_2072: "Spatial basic data details",
  i18nKey_2073: "Spatial basic data location",
  i18nKey_2074: "Spatial basic data creation",
  i18nKey_2075: "Spatial basic data coding",
  i18nKey_2076: "Space level name",
  i18nKey_2077: "Spatial hierarchy",
  i18nKey_2078: "living room",
  i18nKey_2079: "Total passenger flow (person-time)",
  i18nKey_2080: "Sex ratio of passenger flow",
  i18nKey_2081: "Availability range",
  i18nKey_2082: "Optional unit",
  i18nKey_2083: "Can be booked in advance from 0 to 30",
  i18nKey_2084: "Unlock type",
  i18nKey_2085: "The start time and end time can't be less than 30 minutes apart",
  i18nKey_2086: "The difference between the start time and the end time can't exceed 7 days",
  i18nKey_2087: "Start time can't be null",
  i18nKey_2088: "The start time can't be greater than the end time",
  i18nKey_2089: "Starting time",
  i18nKey_2090: "start installation",
  i18nKey_2091: "Tracking successfully turned on",
  i18nKey_2092: "Turn on tracking",
  i18nKey_2093: "Turn on selected",
  i18nKey_2094: "Open time",
  i18nKey_2095: "Open the door",
  i18nKey_2096: "After opening, the smart door will be updated according to the set time frequency",
  i18nKey_2097: "Passable after opening",
  i18nKey_2098: "After opening, visitors can view their own historical registration records",
  i18nKey_2099: "After it is turned on, mobile users can use this function, and it will not be displayed when it is turned off.",
  i18nKey_2100: "After it’s turned on, mobile users can use this function, but it’s not available after it’s turned off.",
  i18nKey_2101: "Door open state",
  i18nKey_2102: "Door opening frequency",
  i18nKey_2103: "Door opening record",
  i18nKey_2104: "Meeting mode",
  i18nKey_2105: "On/Off",
  i18nKey_2106: "On",
  i18nKey_2107: "Specific spatial position coding",
  i18nKey_2108: "Refuse",
  i18nKey_2109: "Residential Address",
  i18nKey_2110: "old password",
  i18nKey_2111: "The integer part of longitude is 0-180",
  i18nKey_2112: "longitude",
  i18nKey_2113: "Beijing, Tianjin, Shanghai, Chongqing, Hebei, Henan, Yunnan, Liaoning, Heilongjiang, Hunan, Anhui, Shandong, Xinjiang, Jiangsu, Zhejiang, Jiangxi, Hubei, Guangxi, Gansu, Shanxi, Menggu, Shaanxi, Jilin, Fujian, Guangdong, Qinghai, Tibet, Sichuan, Ningxia, Hainan,",
  i18nKey_2114: "No entry to the parking lot",
  i18nKey_2115: "Prohibition of monthly pass",
  i18nKey_2116: "Disable failed",
  i18nKey_2117: "Number of admissions in the past month",
  i18nKey_2118: "Average passenger flow in the past month",
  i18nKey_2119: "Number of days visited in the past month",
  i18nKey_2120: "Nearly one month",
  i18nKey_2121: "Nearly a year",
  i18nKey_2122: "Enter the park at the earliest $ in the past month",
  i18nKey_2123: "Haven't left in the past month",
  i18nKey_2124: "Haven't visited in the past month",
  i18nKey_2125: "Number of repeat customer visits in the past month",
  i18nKey_2126: "More than 400 in the past month",
  i18nKey_2127: "Nearly 7 days",
  i18nKey_2128: "Number of departures from the parking lot in the past 30 days",
  i18nKey_2129: "Number of parking lots in the past 30 days",
  i18nKey_2130: "Major event alarm in the past 24 hours",
  i18nKey_2131: "Nearly 24 hours",
  i18nKey_2132: "In progress",
  i18nKey_2133: "Parking time",
  i18nKey_2134: "Entry time",
  i18nKey_2135: "Emergency alert",
  i18nKey_2136: "Only once",
  i18nKey_2137: "Only show the number of video patrol alarms",
  i18nKey_2138: "Only planned patrols participate in statistics",
  i18nKey_2139: "Finance",
  i18nKey_2140: "Maximum length of the amount is 6 digits",
  i18nKey_2141: "Amount can only be an integer or a decimal within 2 digits",
  i18nKey_2142: "Pedestrian flow today",
  i18nKey_2143: "Traffic flow today",
  i18nKey_2144: "Actual electricity consumption today (kWh)",
  i18nKey_2145: "Today's passenger flow",
  i18nKey_2146: "Today's door opening frequency ranking",
  i18nKey_2147: "Number of repeat customers today",
  i18nKey_2148: "Successfully remove the blacklist",
  i18nKey_2149: "Remove blacklist",
  i18nKey_2150: "Screenshot failed",
  i18nKey_2151: "End duration can't be less than the start duration",
  i18nKey_2152: "End time needs to be greater than the start time",
  i18nKey_2153: "End time can't be null",
  i18nKey_2154: "End Time",
  i18nKey_2155: "End mode",
  i18nKey_2156: "Ending amount can't be less than the starting amount",
  i18nKey_2157: "End times can't be less than start times",
  i18nKey_2158: "Cost savings (yuan)",
  i18nKey_2159: "Cost savings",
  i18nKey_2160: "Holiday name can't be null",
  i18nKey_2161: "Festival",
  i18nKey_2162: "Energy saving potential (kWh)",
  i18nKey_2163: "Energy saving potential",
  i18nKey_2164: "Energy-saving type (species)",
  i18nKey_2165: "Energy saving type",
  i18nKey_2166: "Program Title:",
  i18nKey_2167: "Access voltage level (V)",
  i18nKey_2168: "Interface request failed",
  i18nKey_2169: "Host name",
  i18nKey_2170: "Host information",
  i18nKey_2171: "Receptionist phone",
  i18nKey_2172: "Shuttle bus occupancy rate",
  i18nKey_2173: "Than yesterday",
  i18nKey_2174: "Compared to yesterday",
  i18nKey_2175: "Compared to last week",
  i18nKey_2176: "Month-on-month",
  i18nKey_2177: "Compared to last month",
  i18nKey_2178: "Compared to last year",
  i18nKey_2179: "Compared with the actual value of the previous year",
  i18nKey_2180: "Year-on-year",
  i18nKey_2181: "Month-on-month",
  i18nKey_2182: "The day before",
  i18nKey_2183: "Payment details",
  i18nKey_2184: "Payment type",
  i18nKey_2185: "Role status",
  i18nKey_2186: "Role Name",
  i18nKey_2187: "Role description",
  i18nKey_2188: "Role list",
  i18nKey_2189: "Role price name",
  i18nKey_2190: "The reward quantity can't be less than zero",
  i18nKey_2191: "The reward quantity can't be zero",
  i18nKey_2192: "The reward quantity can't have decimals",
  i18nKey_2193: "Number of rewards",
  i18nKey_2194: "Number of reward points (a)",
  i18nKey_2195: "Reward points name",
  i18nKey_2196: "Reward sponsor",
  i18nKey_2197: "Reward selection",
  i18nKey_2198: "Reward type",
  i18nKey_2199: "Reward object/account number",
  i18nKey_2200: "Reward",
  i18nKey_2201: "Drag the file here, or click upload",
  i18nKey_2202: "Building selection",
  i18nKey_2203: "Building electrical system",
  i18nKey_2204: "Suggested value",
  i18nKey_2205: "Recommended executor",
  i18nKey_2206: "Recommended operating parameters",
  i18nKey_2207: "Easy route",
  i18nKey_2208: "Under repair",
  i18nKey_2209: "Surveillance cameras",
  i18nKey_2210: "Monitor screenshot",
  i18nKey_2211: "monitor:",
  i18nKey_2212: "Monitoring period (days)",
  i18nKey_2213: "Monitoring days can only be a positive integer",
  i18nKey_2214: "Monitor",
  i18nKey_2215: "Interval time range is within 1-60",
  i18nKey_2216: "Interval time (seconds)",
  i18nKey_2217: "Driver's license number has special characters",
  i18nKey_2218: "Driver's license number",
  i18nKey_2219: "Driver license:",
  i18nKey_2220: "Price setting",
  i18nKey_2221: "Price (/remaining times)",
  i18nKey_2222: "Many overtime work, leaving the park after 20:00 in the evening for 10 days in the past month",
  i18nKey_2223: "Relay status",
  i18nKey_2224: "Remember current account",
  i18nKey_2225: "Meter name",
  i18nKey_2226: "Abbreviation of meter",
  i18nKey_2227: "Meter number",
  i18nKey_2228: "Meter",
  i18nKey_2229: "Plan details",
  i18nKey_2230: "Plan name",
  i18nKey_2231: "Billing type",
  i18nKey_2232: "Hometown",
  i18nKey_2233: "Basic Information",
  i18nKey_2234: "Total value of points (CNY)",
  i18nKey_2235: "Total value of points",
  i18nKey_2236: "Points validity period (start)",
  i18nKey_2237: "Points validity period (end)",
  i18nKey_2238: "Points validity period",
  i18nKey_2239: "Points wallet",
  i18nKey_2240: "Point value (CNY/min)",
  i18nKey_2241: "Mechanical watch",
  i18nKey_2242: "Engine room power (kWh)",
  i18nKey_2243: "Obtain verification code",
  i18nKey_2244: "Failed to obtain video resource",
  i18nKey_2245: "Get time",
  i18nKey_2246: "Failed to obtain camera resource...",
  i18nKey_2247: "Failed to obtain device information!",
  i18nKey_2248: "Failed to obtain spatial data!",
  i18nKey_2249: "Get address latitude and longitude",
  i18nKey_2250: "Get table list exception",
  i18nKey_2251: "Freight elevator selection",
  i18nKey_2252: "Active status",
  i18nKey_2253: "Event details",
  i18nKey_2254: "Event name",
  i18nKey_2255: "Minimum event station rent no less than 30 days!",
  i18nKey_2256: "Event station",
  i18nKey_2257: "Event can't be null",
  i18nKey_2258: "Event",
  i18nKey_2259: "Member name",
  i18nKey_2260: "Member code",
  i18nKey_2261: "Meeting status",
  i18nKey_2262: "Meeting information",
  i18nKey_2263: "Meeting room details",
  i18nKey_2264: "First picture of meeting room",
  i18nKey_2265: "Meeting room use",
  i18nKey_2266: "Conference room name no special characters (Chinese characters, letters and numbers allowed)",
  i18nKey_2267: "Meeting room name",
  i18nKey_2268: "Meeting room description",
  i18nKey_2269: "Conference room service staff",
  i18nKey_2270: "Meeting room order details",
  i18nKey_2271: "Before the meeting: not started",
  i18nKey_2272: "Before the meeting:",
  i18nKey_2273: "Before the meeting",
  i18nKey_2274: "After the meeting:",
  i18nKey_2275: "Number of returning customers",
  i18nKey_2276: "Percentage of repeat customers",
  i18nKey_2277: "Repeat customers",
  i18nKey_2278: "Loop state",
  i18nKey_2279: "Circuit number/service area",
  i18nKey_2280: "Circuit number",
  i18nKey_2281: "Replay",
  i18nKey_2282: "Gray",
  i18nKey_2283: "Yellow",
  i18nKey_2284: "Environmental monitoring equipment",
  i18nKey_2285: "Environmental monitoring",
  i18nKey_2286: "Environmental alarm policy configuration",
  i18nKey_2287: "Chain energy consumption",
  i18nKey_2288: "Chain electricity consumption over the same period",
  i18nKey_2289: "Same period",
  i18nKey_2290: "Chain time",
  i18nKey_2291: "Chain energy consumption per square meter",
  i18nKey_2292: "Chain ratio",
  i18nKey_2293: "Welcome to IOC platform, please reset your password",
  i18nKey_2294: "Welcome to use the IOC platform, please log in to your account after changing your password",
  i18nKey_2295: "Welcome to IOC platform, please register",
  i18nKey_2296: "Central China",
  i18nKey_2297: "West China",
  i18nKey_2298: "South China",
  i18nKey_2299: "East China",
  i18nKey_2300: "North China Park",
  i18nKey_2301: "North China",
  i18nKey_2302: "Underline",
  i18nKey_2303: "Passport No.",
  i18nKey_2304: "Example of a unit name format: A1-3 room 2 hall",
  i18nKey_2305: "House name",
  i18nKey_2306: "Household number",
  i18nKey_2307: "Internet",
  i18nKey_2308: "Arc chart",
  i18nKey_2309: "Month forecast",
  i18nKey_2310: "Result",
  i18nKey_2311: "Postpaid",
  i18nKey_2312: "Red",
  i18nKey_2313: "Started working early and entered the park before 8:00 am for 10 days past month",
  i18nKey_2314: "Black",
  i18nKey_2315: "Blacklist events numbers",
  i18nKey_2316: "Blacklist event",
  i18nKey_2317: "Blacklist overview",
  i18nKey_2318: "blacklist",
  i18nKey_2319: "Number of people approved",
  i18nKey_2320: "Contract period:",
  i18nKey_2321: "Contract Number:",
  i18nKey_2322: "Contract",
  i18nKey_2323: "Struggle in partnership.",
  i18nKey_2324: "Merge",
  i18nKey_2325: "Water Consumption",
  i18nKey_2326: "Longest time-consuming Top3",
  i18nKey_2327: "Shortest time-consuming Top3",
  i18nKey_2328: "Power consumption (degrees)",
  i18nKey_2329: "Power consumption",
  i18nKey_2330: "Luxury cars",
  i18nKey_2331: "Behavioral characteristics",
  i18nKey_2332: "Driving license:",
  i18nKey_2333: "country",
  i18nKey_2334: "Country of Citizenship",
  i18nKey_2335: "Track time period",
  i18nKey_2336: "Trajectory exported successfully",
  i18nKey_2337: "Track view",
  i18nKey_2338: "Track",
  i18nKey_2339: "Rule",
  i18nKey_2340: "Guangzhou",
  i18nKey_2341: "Mangement head be Chinese, letters and underscores within 20 characters",
  i18nKey_2342: "Management head",
  i18nKey_2343: "Administrator proactively refund",
  i18nKey_2344: "Administrator name",
  i18nKey_2345: "Manage ownership",
  i18nKey_2346: "Management Approver",
  i18nKey_2347: "Management Department",
  i18nKey_2348: "Association abnormal",
  i18nKey_2349: "Related events",
  i18nKey_2350: "Associated camera successfully",
  i18nKey_2351: "Associated camera",
  i18nKey_2352: "Related facility events",
  i18nKey_2353: "Associate historical video __",
  i18nKey_2354: "Linked successfully",
  i18nKey_2355: "Associated license plate number",
  i18nKey_2356: "Related",
  i18nKey_2357: "Key words",
  i18nKey_2358: "Turn off tracking exception",
  i18nKey_2359: "Turn off tracking",
  i18nKey_2360: "Close selected",
  i18nKey_2361: "Turn off the alert",
  i18nKey_2362: "Turn off",
  i18nKey_2363: "Off",
  i18nKey_2364: "Account holder",
  i18nKey_2365: "Total frequency of failure",
  i18nKey_2366: "Failure details",
  i18nKey_2367: "Number of failed facilities",
  i18nKey_2368: "Statistics of failure facilities",
  i18nKey_2369: "Fault description",
  i18nKey_2370: "Source of failure",
  i18nKey_2371: "malfunction",
  i18nKey_2372: "The minimum rent for a fixed station should be no less than 3 months!",
  i18nKey_2373: "Fixed station",
  i18nKey_2374: "After the field is checked, the field is visible in the applet and APP users",
  i18nKey_2375: "supplier",
  i18nKey_2376: "Supply and distribution system",
  i18nKey_2377: "Power supply and distribution",
  i18nKey_2378: "Total lighting circuit $",
  i18nKey_2379: "Shared meeting room",
  i18nKey_2380: "Shared office order report_",
  i18nKey_2381: "Consensus, co-creation, sharing, sharing.",
  i18nKey_2382: "Open $ times",
  i18nKey_2383: "A total of $, successful $, error $",
  i18nKey_2384: "Congratulations, registration is successful!",
  i18nKey_2385: "Public cloud",
  i18nKey_2386: "Company Booking Top5",
  i18nKey_2387: "Public area electricity",
  i18nKey_2388: "Public area meter",
  i18nKey_2389: "Public district energy consumption ranking",
  i18nKey_2390: "The target value of public area energy consumption can't be greater than the target value of total energy consumption",
  i18nKey_2391: "Public area energy consumption (last month)",
  i18nKey_2392: "Public district name",
  i18nKey_2393: "Public area type",
  i18nKey_2394: "Public area",
  i18nKey_2395: "The public area can't be deleted",
  i18nKey_2396: "Common configuration",
  i18nKey_2397: "Public security blacklist",
  i18nKey_2398: "public security",
  i18nKey_2399: "Working day",
  i18nKey_2400: "Staff name",
  i18nKey_2401: "Staff phone",
  i18nKey_2402: "staff member",
  i18nKey_2403: "employer",
  i18nKey_2404: "Type of work",
  i18nKey_2405: "Station status",
  i18nKey_2406: "Number of stations",
  i18nKey_2407: "First Picture of Workstation",
  i18nKey_2408: "Station type",
  i18nKey_2409: "Introduction",
  i18nKey_2410: "The unit price of the workstation must be consistent with the unit with the least rent!",
  i18nKey_2411: "Station price",
  i18nKey_2412: "The number of station numbers must be consistent with the number of stations!",
  i18nKey_2413: "Station number",
  i18nKey_2414: "Business license registration number / unified social credit code",
  i18nKey_2415: "Job number",
  i18nKey_2416: "Ticket status",
  i18nKey_2417: "Work order owner",
  i18nKey_2418: "Work order delegate",
  i18nKey_2419: "Work order dispatched successfully",
  i18nKey_2420: "Work order number",
  i18nKey_2421: "Update time is yesterday's statistical time",
  i18nKey_2422: "Update time",
  i18nKey_2423: "Update failed!",
  i18nKey_2424: "update completed",
  i18nKey_2425: "More layers",
  i18nKey_2426: "More tasks",
  i18nKey_2427: "Drainage",
  i18nKey_2428: "Normal activities for all kinds of people",
  i18nKey_2429: "Personal points",
  i18nKey_2430: "personal",
  i18nKey_2431: "Per company",
  i18nKey_2432: "A",
  i18nKey_2433: "Alarm threshold",
  i18nKey_2434: "Alarm events and follow-up status",
  i18nKey_2435: "The device associated with the alarm event doesn't exist",
  i18nKey_2436: "Alarm event overview",
  i18nKey_2437: "Alarm event",
  i18nKey_2438: "Alarm description",
  i18nKey_2439: "Alarm list",
  i18nKey_2440: "Alarm type",
  i18nKey_2441: "Source of alarm",
  i18nKey_2442: "Alarm classification statistics",
  i18nKey_2443: "Number of alarms",
  i18nKey_2444: "Alarm view",
  i18nKey_2445: "Highlight",
  i18nKey_2446: "advanced",
  i18nKey_2447: "Induction dimly bright",
  i18nKey_2448: "Sensing brightness",
  i18nKey_2449: "sensory switch",
  i18nKey_2450: "Keep brightness time after induction",
  i18nKey_2451: "Sensing lighting time",
  i18nKey_2452: "The event status is pending and can't be reported temporarily",
  i18nKey_2453: "The event status is pending and can't be reported temporarily",
  i18nKey_2454: "The event status is processing and can't be reported temporarily",
  i18nKey_2455: "The task has unprocessed events and can't be reported temporarily",
  i18nKey_2456: "The person has no movement track during this time period",
  i18nKey_2457: "The person has no image and can't be deployed",
  i18nKey_2458: "The meeting room has been disabled and can't be executed",
  i18nKey_2459: "There are no dates available for this meeting room",
  i18nKey_2460: "No parking lot in this area",
  i18nKey_2461: "This ride has been activated, please pause it before operating!",
  i18nKey_2462: "The scene has been disabled and can't be executed",
  i18nKey_2463: "The deployment has been stopped and can't be modified",
  i18nKey_2464: "The deployment control has been activated and can't be modified",
  i18nKey_2465: "The control vehicle data has been deleted, it doesn't support modification or re-arrangement, please re-create the deployment control",
  i18nKey_2466: "The attachment only supports the new version of excel, PDF, jpg, rar, zip format",
  i18nKey_2467: "annex",
  i18nKey_2468: "Number of additional facilities",
  i18nKey_2469: "Additional facility name already exists!",
  i18nKey_2470: "Additional facility name",
  i18nKey_2471: "Load condition",
  i18nKey_2472: "payment method",
  i18nKey_2473: "Payment model",
  i18nKey_2474: "Payment methods",
  i18nKey_2475: "Secondary video settings",
  i18nKey_2476: "Service resources",
  i18nKey_2477: "Service providers can only be composed of Chinese and English, numbers and _",
  i18nKey_2478: "The length of the service provider can't exceed 50 characters",
  i18nKey_2479: "Service provider",
  i18nKey_2480: "Service area can't be null",
  i18nKey_2481: "Service Area",
  i18nKey_2482: "server information",
  i18nKey_2483: "Service Vendor",
  i18nKey_2484: "Number of copies",
  i18nKey_2485: "Share",
  i18nKey_2486: "Pink",
  i18nKey_2487: "Minutes can't be null",
  i18nKey_2488: "The minutes can't be less than 2 minutes and can't be greater than 10 minutes, and there can be no spaces!",
  i18nKey_2489: "minute",
  i18nKey_2490: "The proportion of sub-items can't be greater than 1",
  i18nKey_2491: "Percentage of sub-items",
  i18nKey_2492: "Itemized maintenance",
  i18nKey_2493: "Itemized statistics",
  i18nKey_2494: "Accumulated energy consumption by item (kWh)",
  i18nKey_2495: "Itemized meter data",
  i18nKey_2496: "Analysis index:",
  i18nKey_2497: "analysis",
  i18nKey_2498: "Passenger flow",
  i18nKey_2499: "Partition location",
  i18nKey_2500: "Partition",
  i18nKey_2501: "Staging code",
  i18nKey_2502: "Diversion situation",
  i18nKey_2503: "Category can't be null",
  i18nKey_2504: "classification",
  i18nKey_2505: "Minute",
  i18nKey_2506: "Non-public area",
  i18nKey_2507: "Non-working day",
  i18nKey_2508: "Non-Parking Model",
  i18nKey_2509: "Not required",
  i18nKey_2510: "Total discharge",
  i18nKey_2511: "Discharge capacity (kWh)",
  i18nKey_2512: "Visit source",
  i18nKey_2513: "Visitor status",
  i18nKey_2514: "Guest appointment",
  i18nKey_2515: "Visitor invitation information entry display content",
  i18nKey_2516: "Guest invitation",
  i18nKey_2517: "Visitor invitation statistics (nearly 30 days)",
  i18nKey_2518: "The visitor name can't be a special character",
  i18nKey_2519: "Visitor name can't be null",
  i18nKey_2520: "Visitor name",
  i18nKey_2521: "Visitor information can't be special characters",
  i18nKey_2522: "Visitor access",
  i18nKey_2523: "Number of visitors",
  i18nKey_2524: "Guest staff don't have this operation authority",
  i18nKey_2525: "Visitor statistics (by park)",
  i18nKey_2526: "Number of visitors-company ranking",
  i18nKey_2527: "Number of visitors (person)",
  i18nKey_2528: "Number of visitors",
  i18nKey_2529: "Number of visitors",
  i18nKey_2530: "Visitor management",
  i18nKey_2531: "Guest phone can't be null",
  i18nKey_2532: "Visitor phone",
  i18nKey_2533: "Visitor arrival time",
  i18nKey_2534: "Visitor reaching reminder",
  i18nKey_2535: "Reasons for visits",
  i18nKey_2536: "Visitors",
  i18nKey_2537: "Housing information",
  i18nKey_2538: "Property Type",
  i18nKey_2539: "House address",
  i18nKey_2540: "House ID",
  i18nKey_2541: "Room electricity",
  i18nKey_2542: "Room Meter",
  i18nKey_2543: "Room location",
  i18nKey_2544: "number of rooms",
  i18nKey_2545: "Room name",
  i18nKey_2546: "room number",
  i18nKey_2547: "real estate",
  i18nKey_2548: "Fire district",
  i18nKey_2549: "Back to login",
  i18nKey_2550: "return",
  i18nKey_2551: "Corporate identity card",
  i18nKey_2552: "Legal representative",
  i18nKey_2553: "Location",
  i18nKey_2554: "Number of occurrences",
  i18nKey_2555: "Time of occurrence",
  i18nKey_2556: "Number of issues (a)",
  i18nKey_2557: "Issue points",
  i18nKey_2558: "issued",
  i18nKey_2559: "QR code has expired",
  i18nKey_2560: "QR code access",
  i18nKey_2561: "QR code refresh time must be between 1 and 1439 minutes",
  i18nKey_2562: "QR code refresh time",
  i18nKey_2563: "QR code",
  i18nKey_2564: "Two on and one cycle on",
  i18nKey_2565: "Second category",
  i18nKey_2566: "Secondary department",
  i18nKey_2567: "Level 2",
  i18nKey_2568: "two",
  i18nKey_2569: "Children's room",
  i18nKey_2570: "Rated capacity (kWh)",
  i18nKey_2571: "Rated power (kW)",
  i18nKey_2572: "Ton",
  i18nKey_2573: "exchange",
  i18nKey_2574: "Object name",
  i18nKey_2575: "The external model can only be Chinese, letters and underscores within 50 characters",
  i18nKey_2576: "External model can't be null",
  i18nKey_2577: "External model",
  i18nKey_2578: "Sorry, you don’t have any permission, please contact the administrator",
  i18nKey_2579: "Circuit breaker configuration",
  i18nKey_2580: "Circuit breaker switching time configuration rules",
  i18nKey_2581: "SMS",
  i18nKey_2582: "The port number",
  i18nKey_2583: "Reading time point",
  i18nKey_2584: "Reading (kWh)",
  i18nKey_2585: "reading",
  i18nKey_2586: "Read plan",
  i18nKey_2587: "There is no retreat behind the fighter, and no danger ahead of courage.",
  i18nKey_2588: "freeze",
  i18nKey_2589: "dynamic password",
  i18nKey_2590: "Power consumption",
  i18nKey_2591: "Animation failed to load",
  i18nKey_2592: "northeast",
  i18nKey_2593: "Order Status",
  i18nKey_2594: "Order payment status",
  i18nKey_2595: "Order time range",
  i18nKey_2596: "order amount",
  i18nKey_2597: "Order number/space name",
  i18nKey_2598: "Order number / meeting room name",
  i18nKey_2599: "Order number",
  i18nKey_2600: "Order revenue",
  i18nKey_2601: "Store sales top5",
  i18nKey_2602: "digital watch",
  i18nKey_2603: "Total number of elevators",
  i18nKey_2604: "Elevator operation record",
  i18nKey_2605: "Elevator information",
  i18nKey_2606: "Elevator pictures",
  i18nKey_2607: "Elevator statistics",
  i18nKey_2608: "Elevator name:",
  i18nKey_2609: "Elevator name",
  i18nKey_2610: "Elevator type:",
  i18nKey_2611: "Elevator type",
  i18nKey_2612: "Elevator address",
  i18nKey_2613: "Elevator ID:",
  i18nKey_2614: "elevator",
  i18nKey_2615: "Total loss of transformer (kWh)",
  i18nKey_2616: "Power loss",
  i18nKey_2617: "Class 1 electrical appliances",
  i18nKey_2618: "Electric energy consumption (kWh)",
  i18nKey_2619: "Cable square number",
  i18nKey_2620: "phone",
  i18nKey_2621: "Accumulated electricity bills",
  i18nKey_2622: "Merchants who owe electricity bills",
  i18nKey_2623: "Undeducted amount of electricity recharge",
  i18nKey_2624: "Accumulated electricity recharge amount",
  i18nKey_2625: "Battery Type",
  i18nKey_2626: "Electric meter warning threshold:",
  i18nKey_2627: "Meter serial number",
  i18nKey_2628: "The meter name can't be null",
  i18nKey_2629: "Meter name",
  i18nKey_2630: "Sub-item maintenance of the electric meter is successful!",
  i18nKey_2631: "Detailed record of the power meter",
  i18nKey_2632: "The meter number or sub-item accounted for can't be null",
  i18nKey_2633: "Meter number",
  i18nKey_2634: "Point type",
  i18nKey_2635: "Click retry",
  i18nKey_2636: "Modification is allowed only when you click Stop, the policy will take effect within half an hour after you click Enable",
  i18nKey_2637: "Click to view",
  i18nKey_2638: "Third-party payment service fee (yuan)",
  i18nKey_2639: "Third party user",
  i18nKey_2640: "Third-party visitors",
  i18nKey_2641: "Third party ID",
  i18nKey_2642: "The first...week",
  i18nKey_2643: "Week $",
  i18nKey_2644: "The opening time of the lighting rule $ in the $ strategy can't be null",
  i18nKey_2645: "The effective time of the $th strategy can't be null",
  i18nKey_2646: "Address longitude and latitude input must be separated by English commas and can't be greater than 20 characters",
  i18nKey_2647: "Address latitude and longitude",
  i18nKey_2648: "Address management",
  i18nKey_2649: "Address doesn't support special characters (Chinese characters, letters and numbers are allowed)",
  i18nKey_2650: "Low voltage warning",
  i18nKey_2651: "Equal",
  i18nKey_2652: "Grade",
  i18nKey_2653: "Check-in time",
  i18nKey_2654: "Light switch",
  i18nKey_2655: "Number of lamps",
  i18nKey_2656: "Light sensor switch",
  i18nKey_2657: "Barrier QR Code Reader",
  i18nKey_2658: "PAD",
  i18nKey_2659: "Arrival situation",
  i18nKey_2660: "Amount to the account",
  i18nKey_2661: "To visitor flow",
  i18nKey_2662: "Import fire zone",
  i18nKey_2663: "Import failed, there is an error in the data in row $, please check and submit the import again later",
  i18nKey_2664: "Export conditions",
  i18nKey_2665: "Export failed, support maximum export data 10000",
  i18nKey_2666: "Export failed, there is no data to export",
  i18nKey_2667: "Exporting failed, there is no access control record on the current page!",
  i18nKey_2668: "Export failed, there is no order record on the current page!",
  i18nKey_2669: "The export failed, there is no travel record on the current page!",
  i18nKey_2670: "Export failed, there is no utility payment record on the current page",
  i18nKey_2671: "Export failed, there is no visitor record on the current page",
  i18nKey_2672: "Export failed",
  i18nKey_2673: "Successfully exported track",
  i18nKey_2674: "Package fee of the month",
  i18nKey_2675: "Income analysis of the month",
  i18nKey_2676: "Energy consumption of the month (kWh)",
  i18nKey_2677: "Public area energy consumption of the month (kWh)",
  i18nKey_2678: "Number of alarms in the month (times)",
  i18nKey_2679: "Current month",
  i18nKey_2680: "That day",
  i18nKey_2681: "New alarm on the day",
  i18nKey_2682: "Energy consumption of the day (kWh)",
  i18nKey_2683: "Temporary suspension fee",
  i18nKey_2684: "Energy consumption in public area on the day (kWh)",
  i18nKey_2685: "Number of check-in on the day",
  i18nKey_2686: "That day",
  i18nKey_2687: "Current state",
  i18nKey_2688: "There is currently no program, please set the program first!",
  i18nKey_2689: "Current operating parameters",
  i18nKey_2690: "Currently moved to",
  i18nKey_2691: "There is no order record on the current page and no export operation is required!",
  i18nKey_2692: "The current page is",
  i18nKey_2693: "There is no room at the current location, please select a room!",
  i18nKey_2694: "No approver is currently added, please add an approver",
  i18nKey_2695: "Current data resources",
  i18nKey_2696: "current time",
  i18nKey_2697: "The current browser doesn't support websocket",
  i18nKey_2698: "Current power",
  i18nKey_2699: "Current service resources",
  i18nKey_2700: "The current import file is checked incorrectly, please modify it locally and import it again!",
  i18nKey_2701: "Current parking space utilization rate",
  i18nKey_2702: "Current field",
  i18nKey_2703: "Current operating resources",
  i18nKey_2704: "Current bottom",
  i18nKey_2705: "The current Menu menu resource",
  i18nKey_2706: "That time",
  i18nKey_2707: "Unit:",
  i18nKey_2708: "Unit",
  i18nKey_2709: "Energy consumption per square meter",
  i18nKey_2710: "Unit: yuan",
  i18nKey_2711: "Unit: person",
  i18nKey_2712: "Unit: Times",
  i18nKey_2713: "Unit: kWh",
  i18nKey_2714: "Unit: hour",
  i18nKey_2715: "Unit: copies",
  i18nKey_2716: "Unit: minutes",
  i18nKey_2717: "Unit price/time",
  i18nKey_2718: "Unit price (yuan/hour)",
  i18nKey_2719: "Single tour time",
  i18nKey_2720: "Maximum length and date of single stay",
  i18nKey_2721: "Single deduction",
  i18nKey_2722: "To be paid",
  i18nKey_2723: "To be received",
  i18nKey_2724: "Standby brightness",
  i18nKey_2725: "Number of pending alarms",
  i18nKey_2726: "To be processed",
  i18nKey_2727: "Pending",
  i18nKey_2728: "To be installed",
  i18nKey_2729: "Represents abnormal",
  i18nKey_2730: "Represents abnormal data",
  i18nKey_2731: "greater than or equal to",
  i18nKey_2732: "More than 400",
  i18nKey_2733: "Greater than 2",
  i18nKey_2734: "more than the",
  i18nKey_2735: "Big business",
  i18nKey_2736: "Big screen position",
  i18nKey_2737: "Big screen name",
  i18nKey_2738: "Big screen playback settings",
  i18nKey_2739: "Dadao, of course, struggle in partnership.",
  i18nKey_2740: "Open alert",
  i18nKey_2741: "Punch card classification",
  i18nKey_2742: "Check in failed",
  i18nKey_2743: "Check in successfully",
  i18nKey_2744: "Top 5 ride companies",
  i18nKey_2745: "From $ to $, the failure occurred repeatedly once, the accumulated time is $ hours, which can cause waste throughout the year",
  i18nKey_2746: "Second bedroom",
  i18nKey_2747: "This operation will permanently delete the file. Do you want to continue?",
  i18nKey_2748: "Creation time",
  i18nKey_2749: "founder",
  i18nKey_2750: "Creation mode selection",
  i18nKey_2751: "Create the space level at least to the building!",
  i18nKey_2752: "Create points",
  i18nKey_2753: "Create room",
  i18nKey_2754: "Create scene",
  i18nKey_2755: "create",
  i18nKey_2756: "Triggering conditions:",
  i18nKey_2757: "Processing quantity",
  i18nKey_2758: "Processing time",
  i18nKey_2759: "Processor",
  i18nKey_2760: "Processing mechanism",
  i18nKey_2761: "Storage Room",
  i18nKey_2762: "Energy storage statistics",
  i18nKey_2763: "Kitchen",
  i18nKey_2764: "Initialization time",
  i18nKey_2765: "There have been $ violations",
  i18nKey_2766: "Entrance and exit busyness",
  i18nKey_2767: "Parking time",
  i18nKey_2768: "Playing time",
  i18nKey_2769: "Date of manufacture",
  i18nKey_2770: "Recharge type",
  i18nKey_2771: "Accumulated recharge amount",
  i18nKey_2772: "Top-up company",
  i18nKey_2773: "Recharge",
  i18nKey_2774: "Total charge",
  i18nKey_2775: "Charging capacity (kWh)",
  i18nKey_2776: "Ride payment status",
  i18nKey_2777: "Ride statistics",
  i18nKey_2778: "Travel time",
  i18nKey_2779: "city",
  i18nKey_2780: "Renting industry",
  i18nKey_2781: "The tenant name can't be null",
  i18nKey_2782: "Name of Lessee",
  i18nKey_2783: "Lessee industry",
  i18nKey_2784: "Lessee",
  i18nKey_2785: "Time for successful refund",
  i18nKey_2786: "Chengdu",
  i18nKey_2787: "Success is not about the strength, but how long it can last.",
  i18nKey_2788: "Owner's name",
  i18nKey_2789: "Owner type",
  i18nKey_2790: "Car owner phone",
  i18nKey_2791: "Total number of parking spaces",
  i18nKey_2792: "Number of parking spaces occupied",
  i18nKey_2793: "Parking space usage statistics",
  i18nKey_2794: "Statistics of parking space utilization rate",
  i18nKey_2795: "Parking space inquiry",
  i18nKey_2796: "License plate index",
  i18nKey_2797: "There are more than 6 options for license plate and color",
  i18nKey_2798: "More than 6 options for license plates and brands have been displayed for you according to the best",
  i18nKey_2799: "Can the license plate number be null",
  i18nKey_2800: "License plate number can't be null",
  i18nKey_2801: "License plate number",
  i18nKey_2802: "The license plate has more than 6 options, which have been displayed for you according to the best",
  i18nKey_2803: "License plate query",
  i18nKey_2804: "There are more than 6 options for license plate, brand and color",
  i18nKey_2805: "Traffic statistics",
  i18nKey_2806: "Vehicle color",
  i18nKey_2807: "Vehicle restriction",
  i18nKey_2808: "Number of vehicle packages and parking fees",
  i18nKey_2809: "Number of vehicle packages (cars)",
  i18nKey_2810: "Vehicle data information",
  i18nKey_2811: "Vehicle territory",
  i18nKey_2812: "Vehicle exit record",
  i18nKey_2813: "Vehicle color",
  i18nKey_2814: "Vehicle certification",
  i18nKey_2815: "Vehicle Brands",
  i18nKey_2816: "Vehicle analysis",
  i18nKey_2817: "Vehicle classification full path",
  i18nKey_2818: "Vehicle arrival reminder",
  i18nKey_2819: "Vehicle label",
  i18nKey_2820: "Vehicle",
  i18nKey_2821: "Train number",
  i18nKey_2822: "Parking lot restrictions",
  i18nKey_2823: "Depot location:",
  i18nKey_2824: "Depot location",
  i18nKey_2825: "Depot name:",
  i18nKey_2826: "Depot name",
  i18nKey_2827: "Parking lot statistics",
  i18nKey_2828: "Parking lot",
  i18nKey_2829: "More than $ times, monthly pass can't be processed",
  i18nKey_2830: "More than $ times, can't enter the parking lot",
  i18nKey_2831: "Exceeding the maximum export limit of 2000",
  i18nKey_2832: "Exceeding 10 maximum export limits",
  i18nKey_2833: "Successful reading",
  i18nKey_2834: "Copy",
  i18nKey_2835: "The meter reading date and meter reading must be filled in at the same time",
  i18nKey_2836: "Meter reading date",
  i18nKey_2837: "Meter reader",
  i18nKey_2838: "It can't be null during meter reading",
  i18nKey_2839: "During meter reading",
  i18nKey_2840: "Copy and record",
  i18nKey_2841: "Copy and show fine",
  i18nKey_2842: "Meter reading import",
  i18nKey_2843: "Number of people in the venue",
  i18nKey_2844: "Scene icon",
  i18nKey_2845: "Scene lock switch:",
  i18nKey_2846: "Number of scenes",
  i18nKey_2847: "Scene name",
  i18nKey_2848: "Scene ID",
  i18nKey_2849: "The vendor name can't be null",
  i18nKey_2850: "Trade Names",
  i18nKey_2851: "factory",
  i18nKey_2852: "Resident staff",
  i18nKey_2853: "conventional",
  i18nKey_2854: "product name",
  i18nKey_2855: "product",
  i18nKey_2856: "Remove alarm",
  i18nKey_2857: "Please enter your full name or mobile phone number for registered users",
  i18nKey_2858: "Query the number of ineffective interface data is wrong, please try again later",
  i18nKey_2859: "Please limit the query time range to 90 days",
  i18nKey_2860: "Query list failed",
  i18nKey_2861: "Check the discounted price",
  i18nKey_2862: "View the warning threshold",
  i18nKey_2863: "View limited time discounts",
  i18nKey_2864: "view image",
  i18nKey_2865: "View video",
  i18nKey_2866: "View camera",
  i18nKey_2867: "View device",
  i18nKey_2868: "View track",
  i18nKey_2869: "Check the bus schedule",
  i18nKey_2870: "View",
  i18nKey_2871: "Policy name can't be null",
  i18nKey_2872: "The policy name can't contain special characters",
  i18nKey_2873: "The policy name can't exceed 20 characters",
  i18nKey_2874: "Strategy name",
  i18nKey_2875: "measure temperature",
  i18nKey_2876: "measure time",
  i18nKey_2877: "WC",
  i18nKey_2878: "operator",
  i18nKey_2879: "Operation failed, please try again later",
  i18nKey_2880: "operation failed",
  i18nKey_2881: "Operation suggestions",
  i18nKey_2882: "restaurant",
  i18nKey_2883: "parameter",
  i18nKey_2884: "Reference data period",
  i18nKey_2885: "Top 5 Dishes Sales",
  i18nKey_2886: "Acquisition frequency",
  i18nKey_2887: "department:",
  i18nKey_2888: "Control status",
  i18nKey_2889: "The deployment time can't be null",
  i18nKey_2890: "The deployment time must be greater than or equal to the current time",
  i18nKey_2891: "Control period",
  i18nKey_2892: "Control camera",
  i18nKey_2893: "Control matching",
  i18nKey_2894: "Deployment control",
  i18nKey_2895: "Not repeating",
  i18nKey_2896: "Special characters are not supported, only Chinese and English numbers are supported",
  i18nKey_2897: "Unlimited time",
  i18nKey_2898: "Unlimited",
  i18nKey_2899: "Uncertain alert",
  i18nKey_2900: "The level can't be changed, the current level is",
  i18nKey_2901: "Can't export null data",
  i18nKey_2902: "Can't export more than 10,000 data",
  i18nKey_2903: "Unmovable",
  i18nKey_2904: "Can't add children",
  i18nKey_2905: "Can't be deleted",
  i18nKey_2906: "Not editable",
  i18nKey_2907: "not equal to",
  i18nKey_2908: "Supplementary recording period",
  i18nKey_2909: "Supplementary reading",
  i18nKey_2910: "BoYu Health Check-in_",
  i18nKey_2911: "Berth",
  i18nKey_2912: "Play settings:",
  i18nKey_2913: "Play window replacement mode",
  i18nKey_2914: "Play",
  i18nKey_2915: "Villa",
  i18nKey_2916: "Form template.xlsx",
  i18nKey_2917: "Bottom",
  i18nKey_2918: "Tag filtering",
  i18nKey_2919: "Transformer capacity (kVA)",
  i18nKey_2920: "Change time",
  i18nKey_2921: "Edit warning threshold",
  i18nKey_2922: "Edit",
  i18nKey_2923: "Numbering",
  i18nKey_2924: "Must be an integer",
  i18nKey_2925: "Must be a number or two significant decimals!",
  i18nKey_2926: "This week",
  i18nKey_2927: "Actual electricity consumption this month (KWh)",
  i18nKey_2928: "Cumulative energy consumption this month",
  i18nKey_2929: "Overview of passenger flow this month",
  i18nKey_2930: "Meeting room reservation this month",
  i18nKey_2931: "this month",
  i18nKey_2932: "Energy for this item",
  i18nKey_2933: "Water consumption in this period (t)",
  i18nKey_2934: "Energy consumption in this period",
  i18nKey_2935: "Current electricity consumption (kWh)",
  i18nKey_2936: "Current electricity consumption",
  i18nKey_2937: "Not copied/copied in this period (a)",
  i18nKey_2938: "Current time",
  i18nKey_2939: "Energy consumption per square meter in this period",
  i18nKey_2940: "This period",
  i18nKey_2941: "This year",
  i18nKey_2942: "Energy consumption at this level",
  i18nKey_2943: "Local temperature",
  i18nKey_2944: "Local humidity",
  i18nKey_2945: "Upload locally, upload photos no more than 3M",
  i18nKey_2946: "Local server",
  i18nKey_2947: "local",
  i18nKey_2948: "There are still {number} tables that have not been entered this time. Do you want to continue to take effect?",
  i18nKey_2949: "The meter reading time can't be null",
  i18nKey_2950: "Date of this meter reading",
  i18nKey_2951: "The reading consumption of this meter reading is much larger than that of the last reading. Are you confirming the modification?",
  i18nKey_2952: "The reading of this meter reading can't be null",
  i18nKey_2953: "This meter reading",
  i18nKey_2954: "Total number of interviewed companies (persons)",
  i18nKey_2955: "Magnification",
  i18nKey_2956: "The remark name can only be Chinese, letters and underscores within 20 characters",
  i18nKey_2957: "Remark name",
  i18nKey_2958: "Spare (a)",
  i18nKey_2959: "Beijing",
  i18nKey_2960: "Repair type",
  i18nKey_2961: "Repair work order",
  i18nKey_2962: "Call the police",
  i18nKey_2963: "Scrap date",
  i18nKey_2964: "scrapped",
  i18nKey_2965: "Report",
  i18nKey_2966: "Warranty date",
  i18nKey_2967: "Cleaning",
  i18nKey_2968: "Half-day unit price (yuan/4 hours)",
  i18nKey_2969: "Office space order details",
  i18nKey_2970: "Office Phone",
  i18nKey_2971: "Shift number",
  i18nKey_2972: "Shuttle bus",
  i18nKey_2973: "Reason for the visit:",
  i18nKey_2974: "white",
  i18nKey_2975: "whitelist",
  i18nKey_2976: "Bus order report_",
  i18nKey_2977: "Bus ride record report",
  i18nKey_2978: "Bus schedule",
  i18nKey_2979: "Weekly",
  i18nKey_2980: "Monthly",
  i18nKey_2981: "By similarity",
  i18nKey_2982: "By time",
  i18nKey_2983: "On time",
  i18nKey_2984: "By day",
  i18nKey_2985: "Button resources",
  i18nKey_2986: "By year",
  i18nKey_2987: "View by item",
  i18nKey_2988: "installing",
  i18nKey_2989: "Installation location",
  i18nKey_2990: "Installation time",
  i18nKey_2991: "Installer",
  i18nKey_2992: "Number of RFID assets installed",
  i18nKey_2993: "Security Incident Trend",
  i18nKey_2994: "Security situation",
  i18nKey_2995: "Proportion of types of security incidents",
  i18nKey_2996: "Security personnel",
  i18nKey_2997: "VCM type",
  i18nKey_2998: "URL type",
  i18nKey_2999: "RFID usage rate (by type)",
  i18nKey_3000: "RFID usage rate",
  i18nKey_3001: "RFID number",
  i18nKey_3002: "PID number",
  i18nKey_3003: "Menu resources",
  i18nKey_3004: "MENU",
  i18nKey_3005: "MAC address",
  i18nKey_3006: "KPI upper limit",
  i18nKey_3007: "IT code and business code can only be a combination of letters, numbers and underscores!",
  i18nKey_3008: "IT coding",
  i18nKey_3009: "IOC Class 1 Equipment Classification Name",
  i18nKey_3010: "IOC Class I Equipment Classification Code",
  i18nKey_3011: "IOC Class III Equipment Classification Name",
  i18nKey_3012: "IOC Class III Equipment Classification Code",
  i18nKey_3013: "IOC spatial model coding",
  i18nKey_3014: "IOC secondary equipment classification name",
  i18nKey_3015: "IOC secondary equipment classification code",
  i18nKey_3016: "IOC (Intelligence Operation Center or Intelligent Operation Center) service agreement",
  i18nKey_3017: "GIS coordinate type",
  i18nKey_3018: "GIS coordinates",
  i18nKey_3019: "GIS synchronization",
  i18nKey_3020: "GIS synchronization",
  i18nKey_3021: "Echarts chart",
  i18nKey_3022: "C-phase current (A)",
  i18nKey_3023: "Phase B voltage (V)",
  i18nKey_3024: "Banner map",
  i18nKey_3025: "A phase current (A)",
  i18nKey_3026: "AI diagnosis processing",
  i18nKey_3027: "9 months",
  i18nKey_3028: "A combination of at least 2 of 8-20 letters, numbers and special characters",
  i18nKey_3029: "8-20 digits, letters, some special characters",
  i18nKey_3030: "6 months discount",
  i18nKey_3031: "6 months",
  i18nKey_3032: "3 months discount",
  i18nKey_3033: "3 months",
  i18nKey_3034: "1 year and above",
  i18nKey_3035: "0 point reading (kWh)",
  i18nKey_3036: "0 point reading (kWh)",
  i18nKey_3037: "Privacy Policy",
  i18nKey_3038: "Service Agreement",
  i18nKey_3039: "*Only supports png, gif, jpg format pictures and not larger than 3M",
  i18nKey_3040: "(Total energy consumption kWh)",
  i18nKey_3041: "(Automatically converted price)",
  i18nKey_3042: "(yuan)",
  i18nKey_3043: "(Quantity)",
  i18nKey_3044: "(Example: P85)",
  i18nKey_3045: "(Proportion of power loss)",
  i18nKey_3046: "(Required)",
  i18nKey_3047: "In the entire project, you have found a total of",
  i18nKey_3048: "$year actual value",
  i18nKey_3049: "$year target value",
  i18nKey_3050: "$ Loops failed to open",
  i18nKey_3051: "$ Loops opened successfully",
  i18nKey_3052: "January",
  i18nKey_3053: "May",
  i18nKey_3054: "April",
  i18nKey_3055: "October",
  i18nKey_3056: "November",
  i18nKey_3057: "December",
  i18nKey_3058: "March",
  i18nKey_3059: "Please enter the legal GIS coordinates, such as (20.17N, 134.53W)",
  i18nKey_3060: "July",
  i18nKey_3061: "June",
  i18nKey_3062: "September",
  i18nKey_3063: "February",
  i18nKey_3064: "August",
  i18nKey_3065: "GIS coordinates already exist, please re-enter",
};
