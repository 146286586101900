<template>
    <div class="page-box">
      <div v-if="hasLayoutMounted" class="dark page-wrapper">
        <ViewWebsocket />
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive"></router-view>
      </div>
    </div>
</template>
<script>
import ViewWebsocket from "@/components/view-websocket";
// import Ajax from "@/common/dataApi/navigation.js";
// import "@ioc-web/layout/dist/index.css";
// import comLayout from "@ioc-web/layout";
import { mapState, mapMutations, mapGetters } from "vuex";
import handleErr from "@/js/handle-err.js";
// import { RES_CODE_UNLOGIN, RES_CODE_SUCCESS } from "@/common/utils/constant";
// import Vue from "vue";

// Vue.use(window.libs.default);
// let isFirst = true;
let isLayoutMounted = false;

export default {
  name: "EnterpriseApp",
  components: {
    ViewWebsocket
    // comLayout
  },
  data() {
    return {
      websession: sessionStorage.getItem("IOC_WEB_SESSION"),
      BIMType: "2",
      hasLayoutMounted: false
    };
  },
  computed: {
    ...mapState(["loading", "errStatus", "XHRTimestamp", "routePath", "isBIMMap"]),
    ...mapGetters("baseUser", ["getDecItem"])
  },
  watch: {
    errStatus({ code }) {
      handleErr(code);
    },
    $route: {
      immediate: true,
      handler(to) {
        // this.postBehavior(to.path);
        // if (isLayoutMounted) {
        //   this.$nextTick(() => {
        //     setTimeout(() => {
        //       this.handlePositionCasc();
        //     }, 0);
        //   });
        // }
        this.bimPath();
      }
    },
    XHRTimestamp(v) {
      window.GLOBAL_OBJ.eventHub.publish("XHRTimestampChange", v);
    }
  },
  methods: {
    ...mapMutations(["fillErrStatus", "fillBtnAuth", "fillIsBIMMap", "fillBIMType",
      "module_fillUser", "fillUser", "module_fillAllPosLeafnodeCodeArr", "module_fillPositionArr"
    ]),
    ...mapMutations("basePosition", ["fillPositionInfo", "fillPositionList"]),
    handleErr,
    changeRoute(v) {
      window.history.pushState(null, null, v);
    },
    bimPath() {
      let pathName = location.pathname.split("/")[1];
      if (
        this.isBIMMap === "1" &&
        (location.pathname === "/globalStatus" ||
        location.pathname === "/facilityOperateStatistics" ||
        pathName === "facilityEventCenter" ||
        pathName === "sanitationEventCenter" ||
        location.pathname === "/lightControlMap"
        )
      ) {
        window.GLOBAL_OBJ.eventHub.publish("bimSelectChange", true);
        this.fillBIMType(this.BIMType);
      } else if (location.pathname === "/facilityIntelligentLighting") {
        window.GLOBAL_OBJ.eventHub.publish("bimSelectChange", false);
        this.fillBIMType("3");
      } else {
        window.GLOBAL_OBJ.eventHub.publish("bimSelectChange", false);
        this.fillBIMType("2");
      }
    },
    handleLayoutMounted({node = {}, routeMap = {}, parkConfigList = {}, userPerm = {}, positionList = [], corporationVO = {}, parkList = []} = {}) {
      console.log("layoutMounted", node);
      this.hasLayoutMounted = true;
      let {mapConfig = ""} = parkConfigList;
      let userInfo = {
        ...userPerm,
        ...corporationVO
      };
      this.fillUser(userInfo);
      this.module_fillUser(userInfo);
      this.fillPositionInfo(node);
      this.fillPositionList(positionList);
      this.fillBtnAuth(routeMap);

      let leafNodePosCodeArr = [];
      parkList = parkList instanceof Array ? parkList : [parkList];
      UtilFn.fillIndexToRecursionData(parkList, {
        handleItem(item) {
          item.label = item.posCn;
          return item;
        },
        fillIndexKey: "value",
        handleIndex(indexArr) {
          return indexArr.join("-");
        },
        handLeafNode(item) {
          item.posCode && leafNodePosCodeArr.push(item.posCode);
          return item;
        }
      });
      this.module_fillAllPosLeafnodeCodeArr(leafNodePosCodeArr);
      this.module_fillPositionArr(parkList);

      // if (sessionStorage.getItem("backUpCardList")) {
      //   let backUpCardList = JSON.parse(sessionStorage.getItem("backUpCardList"));
      //   let posCode = "";
      //   backUpCardList.forEach(item => {
      //     if (item.list.length > 0) {
      //       posCode = item.list[0].posCode;
      //     }
      //   });
      //   if (this.positionInfo.posCode !== posCode) {
      //     sessionStorage.removeItem("backUpCardList");
      //   } else {
      //     this.fillCardList(backUpCardList);
      //   }
      // }
      this.fillIsBIMMap(mapConfig === "bim" ? "1" : "0");
      this.BIMType = node.BIMType;
      this.bimPath();
      if (isLayoutMounted) {
        // location.href = "/";
      } else {
        isLayoutMounted = true;
        let bg = document.createElement("div");
        bg.className = "transparent-mask";
        document.querySelector(".lay-position-box").appendChild(bg);

        // this.handlePositionCasc();
      }
    }
  },
  created() {
    window.GLOBAL_OBJ.eventHub.publish("layoutMounted", data => {
      this.hasLayoutMounted = true;
      this.handleLayoutMounted(data);
    });

    // window.GLOBAL_OBJ.eventHub.$once("layoutMounted", data => {
    //   this.hasLayoutMounted = true;
    //   this.handleLayoutMounted(data);
    // });

    // window.GLOBAL_OBJ.eventHub.$emit("before_layoutMounted");
  },
  mounted() {
    // if (isFirst) {
    //   this.addIntelligentCustomerService();
    // } else {
    //   document.querySelector(".itsm_plugin_dom_logo").style.display = "block";
    // }

    // isFirst = false;
  }
};
</script>
<style lang="less">
  .transparent-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    z-index: 9;
    display: none;
  }
</style>
