import Vue from "vue";
import Router from "vue-router";
// import defaultRoutes from "./default-routes";
import enterpriseRoutes from "./enterprise-routes";
// import customRoutes from "./custom-routes";
// import noLayoutRoutes from "./no-layout-routes";
import layoutComp from "@/layout";
import { RES_CODE_SUCCESS } from "@/common/utils/constant";
import api from "@/js/api";

import store from "../store";
Vue.use(Router);

let commitRoutePathAndName = (path, name) => {
  store.commit("fillRoutePath", path);
  store.commit("fillRouteName", name);
};
/**
 * 路由分为两块，一块需要layout包裹 一块不需要
 */
let isFirst = true;

const routerinstantce = new Router({
  mode: "history",
  base: "/enter-prise/",
  routes: [
    // ...noLayoutRoutes,
    {
      path: "/",
      component: layoutComp,
      children: [
        // ...defaultRoutes,
        ...enterpriseRoutes
        // ...customRoutes
      ]
    }
  ]
});
export default routerinstantce;

const NO_NEED_VERIFY_FIRST = [
  "/noCascadeMenu",
  "/login",
  "/pwRedirect",
  "/checkPrivacy",
  "/serviceAgreement",
  "/boShiYuIframeVehicleStatic"
];
routerinstantce.beforeEach((to, from, next) => {
  store.commit("fillToFromRoute", {
    toPath: to.path,
    toName: to.name,
    fromPath: from.path,
    fromName: from.name
  });

  // 跨项目跳转判断
  // let basePath = to.path.split("/")[1] || "";
  // let fromBasePath = from.path.split("/")[1] || "";
  // if (basePath && fromBasePath) {
  //   let canPush = basePath === fromBasePath || (!needJumpPathList.includes(basePath) && !needJumpPathList.includes(fromBasePath));
  //   if (!canPush) {
  //     location.href = to.fullPath;
  //     return false;
  //   }
  // }
  if (NO_NEED_VERIFY_FIRST.includes(to.path)) {
    next();
  } else {
    if (isFirst) {
      isFirst = false;
      api({url: "/ioc/security/v1/authority/islogin"}, {handleErr: false})
        .then(({code}) => {
          if (code === RES_CODE_SUCCESS) {
            next();
          } else {
            next({ path: "/login" });
          }
        })
        .catch(() => { next(); });
    } else {
      next();
    }
  }
});

routerinstantce.afterEach((to, from) => {
  to.path && commitRoutePathAndName(to.path, to.name);
  let htmlDom = document.getElementsByTagName("html")[0];
  let mainDom = document.getElementsByClassName("main")[0];
  if (htmlDom.scrollTop > 0) {
    htmlDom.scrollTop = 0;
  }
  if (htmlDom.scrollLeft > 0) {
    htmlDom.scrollLeft = 0;
  }
  if (mainDom && mainDom.scrollTop > 0) {
    mainDom.scrollTop = 0;
  }
});
