export default {
  "executionTime": "执行总时长",
  "alarmType": "告警类型",
  i18nKey_1: '最后修改时间',
  i18nKey_2: '自动抄表详情列表',
  i18nKey_3: '子系统',
  i18nKey_4: '资产类型',
  i18nKey_5: '状态',
  i18nKey_6: '注册时间',
  i18nKey_7: '重置',
  i18nKey_8: '正常',
  i18nKey_9: '账号名/收分手机号',
  i18nKey_10: '账号名',
  i18nKey_11: '展示方式',
  i18nKey_12: '云类型',
  i18nKey_13: '园区名称',
  i18nKey_14: '园区编码',
  i18nKey_15: '已通过',
  i18nKey_16: '已取消删除',
  i18nKey_17: '已启用',
  i18nKey_18: '已禁用',
  i18nKey_19: '已解冻',
  i18nKey_20: '已冻结',
  i18nKey_21: '选择vip到达提醒类型，角色为不填选项。非vip到达提醒类型，角色为必填选项。',
  i18nKey_22: '选择',
  i18nKey_23: '序号',
  i18nKey_24: '修改失败',
  i18nKey_25: '修改混合云配置',
  i18nKey_26: '修改成功',
  i18nKey_27: '修改',
  i18nKey_28: '信号类型',
  i18nKey_29: '信号标签',
  i18nKey_30: '新建混合云配置',
  i18nKey_31: '新建',
  i18nKey_32: '详情',
  i18nKey_33: '下载模板',
  i18nKey_34: '物业公告',
  i18nKey_35: '无符合查询条件的数据',
  i18nKey_36: '文件格式不正确，只支持excel文件',
  i18nKey_37: '文本',
  i18nKey_38: '位置',
  i18nKey_39: '未通过',
  i18nKey_40: '未启用',
  i18nKey_41: '网络异常，请稍候重试。',
  i18nKey_42: '推送目标',
  i18nKey_43: '推送的告警类型',
  i18nKey_44: '推送APP服务',
  i18nKey_45: '条',
  i18nKey_46: '添加时间',
  i18nKey_47: '添加',
  i18nKey_48: '提示',
  i18nKey_49: '提交失败',
  i18nKey_50: '提交成功',
  i18nKey_51: '提交',
  i18nKey_52: '搜索',
  i18nKey_53: '死区只能由数字及四则运算符号组成',
  i18nKey_54: '私有云',
  i18nKey_55: '说明："市电"，第一层级，本级：取"高压计量柜"数据；下级累计：取"高压出线柜"数据； 电损率超出 >5%为异常。',
  i18nKey_56: '数据',
  i18nKey_57: '授权',
  i18nKey_58: '收分账号名',
  i18nKey_59: '收分手机号格式错误',
  i18nKey_60: '收分手机号',
  i18nKey_61: '剩余',
  i18nKey_62: '设施子类',
  i18nKey_63: '设施系统',
  i18nKey_64: '设施名称不能为空',
  i18nKey_65: '设施名称',
  i18nKey_66: '设施分类修改',
  i18nKey_67: '设施分类名称',
  i18nKey_68: '设施分类编码',
  i18nKey_69: '设施等级',
  i18nKey_70: '设施别名不能为空',
  i18nKey_71: '设施编号',
  i18nKey_72: '设备分类详情',
  i18nKey_73: '商户',
  i18nKey_74: '删除失败！',
  i18nKey_75: '删除成功',
  i18nKey_76: '删除',
  i18nKey_77: '确定要取消吗？',
  i18nKey_78: '确定删除？',
  i18nKey_79: '确定',
  i18nKey_80: '全部',
  i18nKey_81: '权限',
  i18nKey_82: '取消',
  i18nKey_83: '区块链账号',
  i18nKey_84: '请至少选择一个告警类型',
  i18nKey_85: '请选择状态',
  i18nKey_86: '请选择注册时间范围',
  i18nKey_87: '请选择园区',
  i18nKey_88: '请选择应用系统',
  i18nKey_89: '请选择需要修改的设施分类',
  i18nKey_90: '请选择信号类型',
  i18nKey_91: '请选择位置',
  i18nKey_92: '请选择推送目标',
  i18nKey_93: '请选择设施系统',
  i18nKey_94: '请选择设施分类',
  i18nKey_95: '请选择权限',
  i18nKey_96: '请选择类型',
  i18nKey_97: '请选择积分类型',
  i18nKey_98: '请选择告警类型',
  i18nKey_99: '请选择分类',
  i18nKey_100: '请选择发行时间范围',
  i18nKey_101: '请输入账号名/收分手机号',
  i18nKey_102: '请输入园区名称',
  i18nKey_103: '请输入手机号搜索',
  i18nKey_104: '请输入收分账号名',
  i18nKey_105: '请输入收分手机号',
  i18nKey_106: '请输入设施名称',
  i18nKey_107: '请输入设施编号',
  i18nKey_108: '请输入联系人姓名',
  i18nKey_109: '请输入联系人手机号',
  i18nKey_110: '请输入联系人/手机号码',
  i18nKey_111: '请输入积分钱包',
  i18nKey_112: '请输入公司名称/地址',
  i18nKey_113: '请输入公司名称',
  i18nKey_114: '请输入公司地址',
  i18nKey_115: '请输入当前账号登录密码',
  i18nKey_116: '请输入备注',
  i18nKey_117: '请点击确定，下载文件修改后再次导入。',
  i18nKey_118: '请按要求填写',
  i18nKey_119: '启用标识',
  i18nKey_120: '启用/禁用',
  i18nKey_121: '启用',
  i18nKey_122: '目标类型',
  i18nKey_123: '联系人姓名',
  i18nKey_124: '联系人手机号格式错误',
  i18nKey_125: '联系人手机号',
  i18nKey_126: '联系人/手机号码',
  i18nKey_127: '类型',
  i18nKey_128: '卡片',
  i18nKey_129: '经纬度不能只填写一个',
  i18nKey_130: '解冻',
  i18nKey_131: '角色类型',
  i18nKey_132: '角色',
  i18nKey_133: '积分钱包',
  i18nKey_134: '积分类型',
  i18nKey_135: '积分奖励',
  i18nKey_136: '积分价值（元/分）',
  i18nKey_137: '公有云',
  i18nKey_138: '公司信息',
  i18nKey_139: '公司名称/地址',
  i18nKey_140: '公司名称',
  i18nKey_141: '公司地址',
  i18nKey_142: '个人',
  i18nKey_143: '告警名称只能由字母、数字、中文组成',
  i18nKey_144: '告警名称不能为空',
  i18nKey_145: '告警描述只能由字母、数字、中文组成',
  i18nKey_146: '告警描述不能为空',
  i18nKey_147: '告警类型',
  i18nKey_148: '告警标签只能由字母、数字组成',
  i18nKey_149: '告警标签不能为空',
  i18nKey_150: '分项维度',
  i18nKey_151: '分析指标',
  i18nKey_152: '分期名称',
  i18nKey_153: '发行总数量',
  i18nKey_154: '发行总价值（元）',
  i18nKey_155: '发行时间',
  i18nKey_156: '发行人',
  i18nKey_157: '发行记录',
  i18nKey_158: '冻结',
  i18nKey_159: '导入失败，只支持新版excel文件',
  i18nKey_160: '导入失败',
  i18nKey_161: '导入成功',
  i18nKey_162: '导入',
  i18nKey_163: '导出失败，支持最大导出数据10000条',
  i18nKey_164: '导出',
  i18nKey_165: '待审核',
  i18nKey_166: '错误',
  i18nKey_167: '此操作将永久删除该文件，是否继续？',
  i18nKey_168: '创建失败',
  i18nKey_169: '创建设施分类',
  i18nKey_170: '创建成功',
  i18nKey_171: '成功导入',
  i18nKey_172: '查询',
  i18nKey_173: '操作',
  i18nKey_174: '编辑',
  i18nKey_175: '编号',
  i18nKey_176: '备注',
  i18nKey_177: '保存成功',
  i18nKey_178: '保存',
  i18nKey_179: 'VCN配置',
  i18nKey_180: 'VCM配置',
  i18nKey_181: 'L只能由数字及四则运算符号组成',
  i18nKey_182: 'L不能为空',
  i18nKey_183: 'LL只能由数字及四则运算符号组成',
  i18nKey_184: 'H只能由数字及四则运算符号组成',
  i18nKey_185: 'H不能为空',
  i18nKey_186: 'HH只能由数字及四则运算符号组成',
  i18nKey_187: '昨天节省费用',
  i18nKey_188: '昨天放电总量',
  i18nKey_189: '昨天充电总量',
  i18nKey_190: '昨天',
  i18nKey_191: '昨日用能',
  i18nKey_192: '昨日每平方米能耗',
  i18nKey_193: '昨日回头客',
  i18nKey_194: '昨日',
  i18nKey_195: '最早入园时间',
  i18nKey_196: '最新执行时间',
  i18nKey_197: '最新执行场景情况',
  i18nKey_198: '最小值',
  i18nKey_199: '最晚出园时间',
  i18nKey_200: '最晚$离开园区',
  i18nKey_201: '最少起租(小时)',
  i18nKey_202: '最少起租',
  i18nKey_203: '最近一周',
  i18nKey_204: '最近一个月',
  i18nKey_205: '最近一次停车时长（小时）',
  i18nKey_206: '最近一次开门',
  i18nKey_207: '最近一次缴费时间',
  i18nKey_208: '最近违停时间',
  i18nKey_209: '最近三个月',
  i18nKey_210: '最近更新日期：2021年04月25日',
  i18nKey_211: '最近到访时间',
  i18nKey_212: '最近到访',
  i18nKey_213: '最近30天该车位无停驶记录',
  i18nKey_214: '最后一次更新时间',
  i18nKey_215: '最后通讯时间',
  i18nKey_216: '最后上传时间',
  i18nKey_217: '最后更新时间',
  i18nKey_218: '最多只能选择4个摄像头',
  i18nKey_219: '最多只能选5个',
  i18nKey_220: '最多只能添加5个消息推送人',
  i18nKey_221: '最多只能上传4张图片',
  i18nKey_222: '最多只能上传1张图片！',
  i18nKey_223: '最多添加5个审批人',
  i18nKey_224: '最多可选五个',
  i18nKey_225: '最多可创建4条策略',
  i18nKey_226: '最多关联3个摄像头',
  i18nKey_227: '最多30字',
  i18nKey_228: '最大值',
  i18nKey_229: '最大告警数不能小于或等于最小告警数',
  i18nKey_230: '租约详细信息',
  i18nKey_231: '租约起止日期',
  i18nKey_232: '租约起始日期',
  i18nKey_233: '租约结束日期',
  i18nKey_234: '租户水耗',
  i18nKey_235: '租户能耗',
  i18nKey_236: '租户',
  i18nKey_237: '总用电',
  i18nKey_238: '总水能耗（t）',
  i18nKey_239: '总数',
  i18nKey_240: '总事件数',
  i18nKey_241: '总能耗占比',
  i18nKey_242: '总能耗目标',
  i18nKey_243: '总能耗(上月)',
  i18nKey_244: '总能耗(本年)',
  i18nKey_245: '总能耗',
  i18nKey_246: '总开关安数',
  i18nKey_247: '总开关：',
  i18nKey_248: '总节省费用',
  i18nKey_249: '总价值(元)',
  i18nKey_250: '总计数量',
  i18nKey_251: '总计',
  i18nKey_252: '总充电、放电图表',
  i18nKey_253: '总表(个)',
  i18nKey_254: '棕色',
  i18nKey_255: '综合客流',
  i18nKey_256: '字符位数不能超过10位！',
  i18nKey_257: '自主创建',
  i18nKey_258: '自动执行策略',
  i18nKey_259: '自动补录',
  i18nKey_260: '自定义时间范围最多只能添加3个',
  i18nKey_261: '自定义金额范围最多只能添加3个',
  i18nKey_262: '自定义次数范围最多只能添加3个',
  i18nKey_263: '自定义',
  i18nKey_264: '紫色',
  i18nKey_265: '子公区能耗',
  i18nKey_266: '子分项能耗占比',
  i18nKey_267: '子等级名称',
  i18nKey_268: '资产总原值(元)',
  i18nKey_269: '资产总数统计（按园区）',
  i18nKey_270: '资产总数统计（按类型）',
  i18nKey_271: '资产总数',
  i18nKey_272: '资产原值统计（按园区）',
  i18nKey_273: '资产原值统计（按类型）',
  i18nKey_274: '资产原值(元)',
  i18nKey_275: '资产原值(USD)',
  i18nKey_276: '资产详情',
  i18nKey_277: '资产位置信息',
  i18nKey_278: '资产统计',
  i18nKey_279: '资产描述',
  i18nKey_280: '资产类型',
  i18nKey_281: '资产基本信息',
  i18nKey_282: '资产轨迹',
  i18nKey_283: '资产编号',
  i18nKey_284: '资产RFID信息',
  i18nKey_285: '咨询电话',
  i18nKey_286: '追踪中',
  i18nKey_287: '追踪任务',
  i18nKey_288: '追踪',
  i18nKey_289: '状态不能为空',
  i18nKey_290: '状态：',
  i18nKey_291: '状态',
  i18nKey_292: '转设施告警失败',
  i18nKey_293: '转设施告警成功',
  i18nKey_294: '转入退款',
  i18nKey_295: '转化情况',
  i18nKey_296: '转化率',
  i18nKey_297: '转发失败',
  i18nKey_298: '转发成功',
  i18nKey_299: '抓拍图片',
  i18nKey_300: '抓拍',
  i18nKey_301: '柱状图',
  i18nKey_302: '注意：某一天实际电能耗对比上周平均电能耗上升或下降达到设定的阈值，则定义为用电异常',
  i18nKey_303: '注册时间',
  i18nKey_304: '注册失败',
  i18nKey_305: '注：您确定要删除吗？该操作将无法恢复',
  i18nKey_306: '注：您确定要删除吗？该操作将不可恢复！',
  i18nKey_307: '注：建议输入总能耗和公区能耗目标值，系统可自动计算其他值，月目标值生成后鼠标移上去可编辑。',
  i18nKey_308: '主卧',
  i18nKey_309: '主题',
  i18nKey_310: '主楼层',
  i18nKey_311: '周一',
  i18nKey_312: '周五',
  i18nKey_313: '周同比',
  i18nKey_314: '周四',
  i18nKey_315: '周三',
  i18nKey_316: '周日',
  i18nKey_317: '周末客流',
  i18nKey_318: '周六',
  i18nKey_319: '周二',
  i18nKey_320: '周',
  i18nKey_321: '重要警告',
  i18nKey_322: '重要级别',
  i18nKey_323: '重新委派',
  i18nKey_324: '重新登录',
  i18nKey_325: '重新布控',
  i18nKey_326: '重复类型',
  i18nKey_327: '重复方式',
  i18nKey_328: '重度污染',
  i18nKey_329: '重点地区到',
  i18nKey_330: '重大事件',
  i18nKey_331: '重大',
  i18nKey_332: '种',
  i18nKey_333: '终点站',
  i18nKey_334: '终点人数',
  i18nKey_335: '终点不能与起点相同',
  i18nKey_336: '终点不能为空',
  i18nKey_337: '终点',
  i18nKey_338: '中亮',
  i18nKey_339: '中国',
  i18nKey_340: '中度污染',
  i18nKey_341: '智能水表编号',
  i18nKey_342: '智能化系统',
  i18nKey_343: '智能电表编号',
  i18nKey_344: '智能表编码不能为空',
  i18nKey_345: '智能表编码',
  i18nKey_346: '制造商只能由中英文、数字及_组成',
  i18nKey_347: '制造商长度不能超过50个字符',
  i18nKey_348: '制造商',
  i18nKey_349: '至少选择一个设备作为执行动作',
  i18nKey_350: '至少勾选一个时间段',
  i18nKey_351: '至今',
  i18nKey_352: '指令下发周期',
  i18nKey_353: '指定天数',
  i18nKey_354: '指定时间',
  i18nKey_355: '指定企业',
  i18nKey_356: '指定次数',
  i18nKey_357: '指标名称',
  i18nKey_358: '指标分析',
  i18nKey_359: '职位信息输入有特殊字符',
  i18nKey_360: '职位信息',
  i18nKey_361: '执行总时长（分钟）',
  i18nKey_362: '执行时间',
  i18nKey_363: '执行动作：',
  i18nKey_364: '执行（次）',
  i18nKey_365: '执行',
  i18nKey_366: '只支持中英文大小写输入',
  i18nKey_367: '只支持中英文大小写',
  i18nKey_368: '只支持中英文、数字、空格及标点符号输入，可换行',
  i18nKey_369: '只有不快的斧,没有劈不开的柴。',
  i18nKey_370: '只能输入正数，且最大10位整数位和2位小数位',
  i18nKey_371: '只能上传jpg/png格式图片',
  i18nKey_372: '只能上传jpg, jpeg, png的图片文件！',
  i18nKey_373: '只能上传excel文件，并且后缀是.xlsx',
  i18nKey_374: '支路用能统计',
  i18nKey_375: '支路用电分析',
  i18nKey_376: '支路选择',
  i18nKey_377: '支路能耗流向图_',
  i18nKey_378: '支路能耗报警数(次)',
  i18nKey_379: '支付完成时间',
  i18nKey_380: '支付渠道',
  i18nKey_381: '支付开始时间',
  i18nKey_382: '支付金额(元)',
  i18nKey_383: '支付方式',
  i18nKey_384: '支付单号',
  i18nKey_385: '支付宝',
  i18nKey_386: '支出/收入方',
  i18nKey_387: '正在加载视频组件，请稍候',
  i18nKey_388: '正在导出，请稍候',
  i18nKey_389: '正常',
  i18nKey_390: '诊断措施(种)',
  i18nKey_391: '诊断措施',
  i18nKey_392: '诊断',
  i18nKey_393: '折线图',
  i18nKey_394: '折扣系数须为0-1之间可保留1-2位的小数',
  i18nKey_395: '折扣系数',
  i18nKey_396: '折扣设置',
  i18nKey_397: '折扣描述',
  i18nKey_398: '折扣',
  i18nKey_399: '折后价详情',
  i18nKey_400: '折后价',
  i18nKey_401: '照片生成时间',
  i18nKey_402: '照片类型描述',
  i18nKey_403: '照片大小',
  i18nKey_404: '照片不能为空，请上传图片',
  i18nKey_405: '照片URL',
  i18nKey_406: '照明模式',
  i18nKey_407: '照明及插座用电',
  i18nKey_408: '照明方案下发',
  i18nKey_409: '照明方案管理',
  i18nKey_410: '账号登录',
  i18nKey_411: '账号',
  i18nKey_412: '账单消耗总用电量为 $kWh',
  i18nKey_413: '账单消耗统计从最初开始到今日',
  i18nKey_414: '长度为15或者18位字符',
  i18nKey_415: '张卡片',
  i18nKey_416: '占用车位',
  i18nKey_417: '展开',
  i18nKey_418: '粘性',
  i18nKey_419: '增值服务详情',
  i18nKey_420: '责任管理人',
  i18nKey_421: '早班车',
  i18nKey_422: '暂无园区地图',
  i18nKey_423: '暂无人员轨迹',
  i18nKey_424: '暂无可导出的数据',
  i18nKey_425: '暂停歇业时间',
  i18nKey_426: '暂停',
  i18nKey_427: '暂不启用',
  i18nKey_428: '在线网关',
  i18nKey_429: '在线密码',
  i18nKey_430: '在线打卡',
  i18nKey_431: '在线(个)',
  i18nKey_432: '在线',
  i18nKey_433: '在岗',
  i18nKey_434: '运营者手机号需注册云城app',
  i18nKey_435: '运维信息',
  i18nKey_436: '运行中',
  i18nKey_437: '运行策略',
  i18nKey_438: '运行参数',
  i18nKey_439: '允许来访时段',
  i18nKey_440: '允许来访期限',
  i18nKey_441: '云类型',
  i18nKey_442: '云城支付',
  i18nKey_443: '云城APP消息',
  i18nKey_444: '数智里APP',
  i18nKey_445: '越万里关山,闯千百难关。',
  i18nKey_446: '月预测值',
  i18nKey_447: '月消耗金额(元)',
  i18nKey_448: '月实际电能耗(kWh)',
  i18nKey_449: '月剩余金额(元)',
  i18nKey_450: '月卡限制',
  i18nKey_451: '月卡审核人',
  i18nKey_452: '月卡到期日期',
  i18nKey_453: '月卡车',
  i18nKey_454: '月卡',
  i18nKey_455: '月耗水量（吨）',
  i18nKey_456: '月耗电量（kWh）',
  i18nKey_457: '月充值金额(元)',
  i18nKey_458: '月',
  i18nKey_459: '远程开门时间',
  i18nKey_460: '远程开门成功',
  i18nKey_461: '远程开门',
  i18nKey_462: '源系统人员编码输入有特殊字符',
  i18nKey_463: '源系统名称输入有特殊字符',
  i18nKey_464: '源数据编码输入有特殊字符',
  i18nKey_465: '源数据编码',
  i18nKey_466: '员工',
  i18nKey_467: '园区总平米必须大于0',
  i18nKey_468: '园区总平米',
  i18nKey_469: '园区自有',
  i18nKey_470: '园区无人员热力数据',
  i18nKey_471: '园区无对应设备数据',
  i18nKey_472: '园区无保洁人员数据',
  i18nKey_473: '园区无安保人员数据',
  i18nKey_474: '园区内部企业',
  i18nKey_475: '园区黑名单',
  i18nKey_476: '园区访客',
  i18nKey_477: '园区订餐',
  i18nKey_478: '园区的企业员工',
  i18nKey_479: '园区车辆颜色排行',
  i18nKey_480: '园区车辆品牌排行',
  i18nKey_481: '园区编码',
  i18nKey_482: '园区保洁',
  i18nKey_483: '园区巴士',
  i18nKey_484: '元',
  i18nKey_485: '阈值类型',
  i18nKey_486: '阈值不正确！请核实清楚再确认',
  i18nKey_487: '阈值',
  i18nKey_488: '域账号',
  i18nKey_489: '预约状态',
  i18nKey_490: '预览',
  i18nKey_491: '预警值最小为0，最大为999的整数',
  i18nKey_492: '预警值状态：',
  i18nKey_493: '预计离去',
  i18nKey_494: '预计离开时间',
  i18nKey_495: '预计来访时间',
  i18nKey_496: '预付费',
  i18nKey_497: '预订止时间',
  i18nKey_498: '预订日期',
  i18nKey_499: '预订人',
  i18nKey_500: '预订',
  i18nKey_501: '预测值',
  i18nKey_502: '预测能耗值(kWh)',
  i18nKey_503: '有效性标识',
  i18nKey_504: '有效',
  i18nKey_505: '由重点地区返回：',
  i18nKey_506: '优惠套餐：',
  i18nKey_507: '优惠企业',
  i18nKey_508: '优惠策略',
  i18nKey_509: '优化建议',
  i18nKey_510: '优',
  i18nKey_511: '用水数据统计（t）',
  i18nKey_512: '用水数据统计m³ 立方米',
  i18nKey_513: '用水类型',
  i18nKey_514: '用时(分钟)',
  i18nKey_515: '用能趋势',
  i18nKey_516: '用能(kWh)',
  i18nKey_517: '用能',
  i18nKey_518: '用量',
  i18nKey_519: '用户信息确认',
  i18nKey_520: '用户/群组',
  i18nKey_521: '用电预警设置',
  i18nKey_522: '用电异常百分比',
  i18nKey_523: '用电异常',
  i18nKey_524: '用电统计',
  i18nKey_525: '用电数据统计（kWh）',
  i18nKey_526: '用电数据统计kWh 千瓦小时',
  i18nKey_527: '用电排行',
  i18nKey_528: '用电量（kWh ）',
  i18nKey_529: '用电类型',
  i18nKey_530: '用电概况',
  i18nKey_531: '用电负荷',
  i18nKey_532: '用电分项',
  i18nKey_533: '用电',
  i18nKey_534: '应用系统编码',
  i18nKey_535: '应用系统编号不能为空',
  i18nKey_536: '应用系统',
  i18nKey_537: '应用户型成功',
  i18nKey_538: '应用户型',
  i18nKey_539: '应用场景成功',
  i18nKey_540: '应用场景',
  i18nKey_541: '营业执照',
  i18nKey_542: '营业时间不能少于一小时！',
  i18nKey_543: '营业时间',
  i18nKey_544: '营业开始时间',
  i18nKey_545: '营业结束时间',
  i18nKey_546: '营收',
  i18nKey_547: '英文姓名',
  i18nKey_548: '隐私政策',
  i18nKey_549: '银色',
  i18nKey_550: '银联支付',
  i18nKey_551: '音量控制：',
  i18nKey_552: '因涉及到客户隐私，需进行身份验证',
  i18nKey_553: '疫情上报记录',
  i18nKey_554: '疫情防控记录',
  i18nKey_555: '异常总数(个)',
  i18nKey_556: '异常',
  i18nKey_557: '以图搜人',
  i18nKey_558: '已支付',
  i18nKey_559: '已有记录存在，是否覆盖',
  i18nKey_560: '已有',
  i18nKey_561: '已邀请',
  i18nKey_562: '已选支路',
  i18nKey_563: '已选择设备',
  i18nKey_564: '已选择上传文件：',
  i18nKey_565: '已选摄像机',
  i18nKey_566: '已选商/租户',
  i18nKey_567: '已选建筑',
  i18nKey_568: '已选',
  i18nKey_569: '已完成',
  i18nKey_570: '已退款',
  i18nKey_571: '已停止',
  i18nKey_572: '已添加路线',
  i18nKey_573: '已添加过此表！',
  i18nKey_574: '已提交',
  i18nKey_575: '已锁定',
  i18nKey_576: '已使用',
  i18nKey_577: '已审核',
  i18nKey_578: '已取消',
  i18nKey_579: '已启动布控不能删除',
  i18nKey_580: '已启动',
  i18nKey_581: '已配网设备不能删除',
  i18nKey_582: '已配网',
  i18nKey_583: '已开启 $ 个',
  i18nKey_584: '已开启',
  i18nKey_585: '已结账',
  i18nKey_586: '已结束',
  i18nKey_587: '已交付',
  i18nKey_588: '已核销',
  i18nKey_589: '已过期',
  i18nKey_590: '已关联摄像头不能超出三个',
  i18nKey_591: '已关联摄像头0个',
  i18nKey_592: '已关联摄像头',
  i18nKey_593: '已关闭 $ 个',
  i18nKey_594: '已到访人数(人)',
  i18nKey_595: '已到达',
  i18nKey_596: '已存在',
  i18nKey_597: '已处理',
  i18nKey_598: '已出账单未抵扣金额(元)',
  i18nKey_599: '已出账单未抵扣',
  i18nKey_600: '已出账单金额累计',
  i18nKey_601: '已超时',
  i18nKey_602: '移动失败',
  i18nKey_603: '移动端',
  i18nKey_604: '移动成功',
  i18nKey_605: '移除后的设备将恢复到“待分配设备”中，如有需要可再次添加。是否将该设备从该区域移除？',
  i18nKey_606: '移出',
  i18nKey_607: '仪表所属',
  i18nKey_608: '仪表品牌',
  i18nKey_609: '仪表名称',
  i18nKey_610: '仪表类别',
  i18nKey_611: '仪表代码不能为空',
  i18nKey_612: '仪表代码/编号/名称',
  i18nKey_613: '仪表表号/楼栋单元',
  i18nKey_614: '仪表表号',
  i18nKey_615: '一码通',
  i18nKey_616: '一类',
  i18nKey_617: '一键重置',
  i18nKey_618: '一键停用',
  i18nKey_619: '一键开启',
  i18nKey_620: '一级部门',
  i18nKey_621: '一级',
  i18nKey_622: '一般告警',
  i18nKey_623: '一',
  i18nKey_624: '业主',
  i18nKey_625: '业务事件',
  i18nKey_626: '业务编码',
  i18nKey_627: '业态名称不能为空',
  i18nKey_628: '业态名称',
  i18nKey_629: '业态',
  i18nKey_630: '要为成功找方法,不为失败找理由。',
  i18nKey_631: '邀约信息',
  i18nKey_632: '邀约时间',
  i18nKey_633: '邀约访客',
  i18nKey_634: '邀约对象信息',
  i18nKey_635: '邀请时间',
  i18nKey_636: '颜色超过6个选项 已为您按最优显示',
  i18nKey_637: '严重污染',
  i18nKey_638: '严重告警阈值',
  i18nKey_639: '循环时间',
  i18nKey_640: '循环',
  i18nKey_641: '巡逻组名称',
  i18nKey_642: '巡逻问题概况',
  i18nKey_643: '巡逻人',
  i18nKey_644: '巡逻名称不能为空',
  i18nKey_645: '巡逻名称不能超过200字符',
  i18nKey_646: '巡逻路线说明',
  i18nKey_647: '巡逻路线名称',
  i18nKey_648: '巡逻路线',
  i18nKey_649: '巡逻计划名称',
  i18nKey_650: '巡逻计划表',
  i18nKey_651: '巡逻告警',
  i18nKey_652: '巡逻',
  i18nKey_653: '选择消防分区',
  i18nKey_654: '选择替换设备',
  i18nKey_655: '选择时间',
  i18nKey_656: '选择路灯',
  i18nKey_657: '选择积分类型',
  i18nKey_658: '选择户型',
  i18nKey_659: '选择管理部门',
  i18nKey_660: '选择关联',
  i18nKey_661: '选择的位置没有客流数据，请重新选择区域后再试',
  i18nKey_662: '选择的日期范围大于 190 天，请减少选择再重试',
  i18nKey_663: '选择层级至少要到园区',
  i18nKey_664: '选择标签',
  i18nKey_665: '玄关',
  i18nKey_666: '序列号只能由中英文和数字组成',
  i18nKey_667: '序列号长度不能超过100个字符',
  i18nKey_668: '序列号',
  i18nKey_669: '修改增值服务',
  i18nKey_670: '修改预警阈值成功',
  i18nKey_671: '修改巡逻计划成功',
  i18nKey_672: '修改信息需先停用价格！',
  i18nKey_673: '修改信息需先停用工位！',
  i18nKey_674: '修改限时折扣',
  i18nKey_675: '修改限行车辆',
  i18nKey_676: '修改设备',
  i18nKey_677: '修改前读数',
  i18nKey_678: '修改密码成功，请重新登录',
  i18nKey_679: '修改空间基础数据失败',
  i18nKey_680: '修改空间基础数据成功',
  i18nKey_681: '修改计划',
  i18nKey_682: '修改混合云配置成功',
  i18nKey_683: '修改混合云的数据',
  i18nKey_684: '修改会议室资源维护',
  i18nKey_685: '修改会议室订单',
  i18nKey_686: '修改会议室',
  i18nKey_687: '修改乘车班次',
  i18nKey_688: '修改备注名',
  i18nKey_689: '修改VCM配置',
  i18nKey_690: '休息',
  i18nKey_691: '休眠亮度',
  i18nKey_692: '休眠',
  i18nKey_693: '姓名输入有特殊字符，请重新输入',
  i18nKey_694: '姓名或手机号',
  i18nKey_695: '姓名',
  i18nKey_696: '性别不能为空',
  i18nKey_697: '性别',
  i18nKey_698: '型号',
  i18nKey_699: '星期一',
  i18nKey_700: '星期五',
  i18nKey_701: '星期天',
  i18nKey_702: '星期四',
  i18nKey_703: '星期三',
  i18nKey_704: '星期六',
  i18nKey_705: '星期二',
  i18nKey_706: '新增子类',
  i18nKey_707: '新增子级分类',
  i18nKey_708: '新增增值服务',
  i18nKey_709: '新增预警阈值成功',
  i18nKey_710: '新增预警阈值',
  i18nKey_711: '新增一级业态',
  i18nKey_712: '新增小组人员',
  i18nKey_713: '新增消防分区',
  i18nKey_714: '新增位置',
  i18nKey_715: '新增失败，请稍后再试',
  i18nKey_716: '新增设备',
  i18nKey_717: '新增配置',
  i18nKey_718: '新增角色',
  i18nKey_719: '新增加附加设施菜单路径：企业服务 -> 会议室管理 -> 增值服务！',
  i18nKey_720: '新增计划',
  i18nKey_721: '新增会议室资源维护',
  i18nKey_722: '新增会议室订单',
  i18nKey_723: '新增会议室',
  i18nKey_724: '新增户型',
  i18nKey_725: '新增附加设施',
  i18nKey_726: '新增房屋',
  i18nKey_727: '新增成功',
  i18nKey_728: '新增产品',
  i18nKey_729: '新增策略生效时间（最多可创建4条）',
  i18nKey_730: '新增标签信息',
  i18nKey_731: '新密码不可与旧密码相同',
  i18nKey_732: '新密码',
  i18nKey_733: '新客户数',
  i18nKey_734: '新客户比例',
  i18nKey_735: '新建巡逻计划成功',
  i18nKey_736: '新建限行车辆',
  i18nKey_737: '新建人脸追踪',
  i18nKey_738: '新建路线',
  i18nKey_739: '新建空间基础数据失败',
  i18nKey_740: '新建空间基础数据成功',
  i18nKey_741: '新建计划',
  i18nKey_742: '新建混合云配置成功',
  i18nKey_743: '新建工单成功',
  i18nKey_744: '新建布控',
  i18nKey_745: '新建VCM配置',
  i18nKey_746: '校验结果',
  i18nKey_747: '小于或等于',
  i18nKey_748: '小于',
  i18nKey_749: '小时取消，退款',
  i18nKey_750: '小时取消',
  i18nKey_751: '小时/天不能低于4小时！',
  i18nKey_752: '小时/天',
  i18nKey_753: '小时',
  i18nKey_754: '销量',
  i18nKey_755: '消息推送信息展示在云城app消息列表中',
  i18nKey_756: '消警成功',
  i18nKey_757: '消耗水量',
  i18nKey_758: '消耗金额',
  i18nKey_759: '消耗电量',
  i18nKey_760: '消费一般，持续关注中',
  i18nKey_761: '消费能力',
  i18nKey_762: '消费类型',
  i18nKey_763: '消费购买',
  i18nKey_764: '消防系统/火灾自动报警及联动系统/手动报警按钮',
  i18nKey_765: '消防系统/火灾自动报警及联动系统/火灾报警主机',
  i18nKey_766: '消防系统/火灾自动报警及联动系统/感烟火灾探测器',
  i18nKey_767: '消防系统/火灾自动报警及联动系统/感温火灾探测器',
  i18nKey_768: '消防系统',
  i18nKey_769: '消防事件趋势',
  i18nKey_770: '消防分区名称',
  i18nKey_771: '消防分区导入模板',
  i18nKey_772: '消防分区id',
  i18nKey_773: '消防分区',
  i18nKey_774: '项目选址在金桥万创中心B1，B2地下车库，配置三套，250kW/924.9kWh储能系统，网点分别位于10kV甲线下2#变压器（1000kVA）400V母线侧，10kV乙线下5#变压器（1000kVA）400V母线侧及6#变压器（2000kVA）400V母线侧',
  i18nKey_775: '项目名称',
  i18nKey_776: '项目介绍',
  i18nKey_777: '详细地址',
  i18nKey_778: '箱体尺寸(寸)',
  i18nKey_779: '相邻区域不能与设施位置相同',
  i18nKey_780: '相邻区域',
  i18nKey_781: '线下支付',
  i18nKey_782: '线下付款人员',
  i18nKey_783: '线下付款',
  i18nKey_784: '线上退款输入格式不正确!',
  i18nKey_785: '线上退款',
  i18nKey_786: '线上取消',
  i18nKey_787: '限制条件',
  i18nKey_788: '限时折扣上架中，请下架后操作！',
  i18nKey_789: '限行时间段',
  i18nKey_790: '限行车辆',
  i18nKey_791: '现场图片',
  i18nKey_792: '现场视频',
  i18nKey_793: '显示价格',
  i18nKey_794: '显示',
  i18nKey_795: '下载模板(公区)',
  i18nKey_796: '下载模板(房间)',
  i18nKey_797: '下载模板',
  i18nKey_798: '下载表格模板',
  i18nKey_799: '下载',
  i18nKey_800: '下移',
  i18nKey_801: '下一步',
  i18nKey_802: '下午',
  i18nKey_803: '下降',
  i18nKey_804: '下架',
  i18nKey_805: '下级累计',
  i18nKey_806: '下行',
  i18nKey_807: '下个小时建议运行参数',
  i18nKey_808: '下发指令成功',
  i18nKey_809: '下发方案',
  i18nKey_810: '下单时间',
  i18nKey_811: '下单人',
  i18nKey_812: '系统自动拒绝',
  i18nKey_813: '系统预测其他',
  i18nKey_814: '系统预测常驻人员',
  i18nKey_815: '系统管理员',
  i18nKey_816: '系统编码只能为50个字符以内的中文、字母及下划线',
  i18nKey_817: '系统编码不能为空',
  i18nKey_818: '系统2',
  i18nKey_819: '系统1',
  i18nKey_820: '系统',
  i18nKey_821: '洗手间监测策略配置',
  i18nKey_822: '西南',
  i18nKey_823: '误报',
  i18nKey_824: '物业人员',
  i18nKey_825: '物业',
  i18nKey_826: '五类',
  i18nKey_827: '五级部门',
  i18nKey_828: '五级',
  i18nKey_829: '五',
  i18nKey_830: '无节目',
  i18nKey_831: '无记录',
  i18nKey_832: '无关联事件',
  i18nKey_833: '无关联摄像头',
  i18nKey_834: '无符合查询条件的数据',
  i18nKey_835: '无法获取行内数据',
  i18nKey_836: '无此上级设备',
  i18nKey_837: '无',
  i18nKey_838: '文件仅支持excel',
  i18nKey_839: '文件大小不能超过20M',
  i18nKey_840: '温度',
  i18nKey_841: '位置全路径',
  i18nKey_842: '位置ID',
  i18nKey_843: '位置',
  i18nKey_844: '未知',
  i18nKey_845: '未设置消息推送人，请添加消息推送人！',
  i18nKey_846: '未启动',
  i18nKey_847: '未配网',
  i18nKey_848: '未录入',
  i18nKey_849: '未离',
  i18nKey_850: '未开始',
  i18nKey_851: '未核销',
  i18nKey_852: '未抵扣金额',
  i18nKey_853: '未到访人数(人)',
  i18nKey_854: '未出现过违停现象',
  i18nKey_855: '未抄总数(个)',
  i18nKey_856: '纬度整数部分为0-90',
  i18nKey_857: '纬度',
  i18nKey_858: '维修内容',
  i18nKey_859: '维修结果',
  i18nKey_860: '维护责任人',
  i18nKey_861: '维护',
  i18nKey_862: '维保状态',
  i18nKey_863: '维保商只能由中英文、数字及_组成',
  i18nKey_864: '维保商长度不能超过50个字符',
  i18nKey_865: '维保商',
  i18nKey_866: '违停证据',
  i18nKey_867: '违停原因',
  i18nKey_868: '违停详细信息',
  i18nKey_869: '违停时间',
  i18nKey_870: '违停地点',
  i18nKey_871: '违停次数',
  i18nKey_872: '微亮',
  i18nKey_873: '忘记密码',
  i18nKey_874: '网络加载异常，请稍后重新操作',
  i18nKey_875: '网络加载异常，请稍后重试',
  i18nKey_876: '网关名称',
  i18nKey_877: '网关不在线，下发方案失败',
  i18nKey_878: '万科园区',
  i18nKey_879: '万科西城区园区',
  i18nKey_880: '万科海定区园区',
  i18nKey_881: '万科东城区园区',
  i18nKey_882: '万科朝阳区园区',
  i18nKey_883: '万科滨海新区园区',
  i18nKey_884: '万',
  i18nKey_885: '晚班车',
  i18nKey_886: '外部位置名称',
  i18nKey_887: '外部位置编码',
  i18nKey_888: '外部企业',
  i18nKey_889: '退款状态',
  i18nKey_890: '退款已申请',
  i18nKey_891: '退款失败',
  i18nKey_892: '退款处理中',
  i18nKey_893: '退款处理时间',
  i18nKey_894: '退款成功时间',
  i18nKey_895: '退款成功',
  i18nKey_896: '退回',
  i18nKey_897: '退订',
  i18nKey_898: '推荐标签',
  i18nKey_899: '团结协作,全力以赴。',
  i18nKey_900: '途径点不能与终点相同',
  i18nKey_901: '途径点不能与起点相同',
  i18nKey_902: '途径',
  i18nKey_903: '图片大小不能超过3M！',
  i18nKey_904: '图片大小不能超过3M',
  i18nKey_905: '图片查看',
  i18nKey_906: '图片裁剪',
  i18nKey_907: '图片',
  i18nKey_908: '图表，报表',
  i18nKey_909: '图表',
  i18nKey_910: '统一设置额度',
  i18nKey_911: '统计周期（天）',
  i18nKey_912: '统计详细',
  i18nKey_913: '统计图名称',
  i18nKey_914: '统计天数只能是正整数',
  i18nKey_915: '统计时间：',
  i18nKey_916: '统计卡片配置',
  i18nKey_917: '统计从最初开始到昨天,充值金额以到账为准',
  i18nKey_918: '同意',
  i18nKey_919: '同一天只能设置一种取消规则！',
  i18nKey_920: '同时选择的摄像头不能超过4个',
  i18nKey_921: '同期(kWh)',
  i18nKey_922: '同行人信息设置',
  i18nKey_923: '同行人数',
  i18nKey_924: '同比同期',
  i18nKey_925: '同比时间',
  i18nKey_926: '同比',
  i18nKey_927: '通行楼层',
  i18nKey_928: '通行',
  i18nKey_929: '停止',
  i18nKey_930: '停用',
  i18nKey_931: '停留时长只能是正整数',
  i18nKey_932: '停留时长（小时）',
  i18nKey_933: '停靠楼层',
  i18nKey_934: '停靠',
  i18nKey_935: '停放时间',
  i18nKey_936: '停车时长',
  i18nKey_937: '停车费用(24小时内)',
  i18nKey_938: '停车场收费模式',
  i18nKey_939: '停车场名称',
  i18nKey_940: '停车场地址',
  i18nKey_941: '停车场不能为空',
  i18nKey_942: '停车策略配置',
  i18nKey_943: '调试设备',
  i18nKey_944: '添加子类',
  i18nKey_945: '添加消息推送人仅限云城app注册用户使用，',
  i18nKey_946: '添加线下付款人员',
  i18nKey_947: '添加限时折扣',
  i18nKey_948: '添加提醒人',
  i18nKey_949: '添加套餐',
  i18nKey_950: '添加适用企业',
  i18nKey_951: '添加人脸',
  i18nKey_952: '添加亮灯规则',
  i18nKey_953: '添加公司',
  i18nKey_954: '添加乘车路线',
  i18nKey_955: '添加乘车班次',
  i18nKey_956: '天推送活动',
  i18nKey_957: '天前',
  i18nKey_958: '天',
  i18nKey_959: '替换最早',
  i18nKey_960: '替换选中',
  i18nKey_961: '替换关系',
  i18nKey_962: '替换底图',
  i18nKey_963: '体温',
  i18nKey_964: '提醒人',
  i18nKey_965: '提醒',
  i18nKey_966: '提示阈值',
  i18nKey_967: '提示文字',
  i18nKey_968: '提示告警',
  i18nKey_969: '提示：只支持jpg，png格式的图片上传，上传图片大小不超过3M',
  i18nKey_970: '提示：因涉及客户隐私，需输入密码二次确认',
  i18nKey_971: '提示：结束时间需大于开始时间',
  i18nKey_972: '提示: 视频画面延迟约5~10秒,请耐心等待',
  i18nKey_973: '提前预订',
  i18nKey_974: '提前天数限制为1-30天范围内',
  i18nKey_975: '特殊',
  i18nKey_976: '套餐数量',
  i18nKey_977: '套餐使用次数',
  i18nKey_978: '套餐启用中，请暂停后操作！',
  i18nKey_979: '套餐名称',
  i18nKey_980: '套餐类型',
  i18nKey_981: '套餐卡用户',
  i18nKey_982: '套餐卡计次数',
  i18nKey_983: '套餐卡办理',
  i18nKey_984: '套餐卡',
  i18nKey_985: '套餐缴费金额(元)',
  i18nKey_986: '套餐缴费',
  i18nKey_987: '锁定',
  i18nKey_988: '所有权（业主）',
  i18nKey_989: '所有企业',
  i18nKey_990: '所选时间段不能为空',
  i18nKey_991: '所选时间段不能超过两天',
  i18nKey_992: '所选年份必须处于开始日期和结束日期之间的年份',
  i18nKey_993: '所属园区',
  i18nKey_994: '所属企业',
  i18nKey_995: '所属目录不能选择自己',
  i18nKey_996: '所属目录不能为空',
  i18nKey_997: '所属目录',
  i18nKey_998: '所属类型',
  i18nKey_999: '所属公司输入有特殊字符，请重新输入',
  i18nKey_1000: '所属公司输入有特殊字符',
  i18nKey_1001: '所属公司不能为空',
  i18nKey_1002: '所属公司',
  i18nKey_1003: '所属分项',
  i18nKey_1004: '所属单位',
  i18nKey_1005: '所属部门',
  i18nKey_1006: '搜索历史',
  i18nKey_1007: '四类',
  i18nKey_1008: '四级部门',
  i18nKey_1009: '四级',
  i18nKey_1010: '四',
  i18nKey_1011: '私有云',
  i18nKey_1012: '私人',
  i18nKey_1013: '说明：添加的审批人将作为物业管理的审批人员，最多添加5个审批人',
  i18nKey_1014: '说明：本级占上一级能耗比超出>10%为异常。总能耗本级用能取低压进线柜P11、P21、P31、P41；',
  i18nKey_1015: '水费已出账单金额累计',
  i18nKey_1016: '水费欠费商户',
  i18nKey_1017: '水费充值未抵扣金额',
  i18nKey_1018: '水费充值金额累计',
  i18nKey_1019: '水电账单概览',
  i18nKey_1020: '水电明细详情',
  i18nKey_1021: '水电明细列表',
  i18nKey_1022: '水电缴费订单',
  i18nKey_1023: '水电表详情数据查询',
  i18nKey_1024: '水表预警阈值：',
  i18nKey_1025: '水表序号',
  i18nKey_1026: '水表编号',
  i18nKey_1027: '刷新',
  i18nKey_1028: '刷卡通行',
  i18nKey_1029: '数值在0-60',
  i18nKey_1030: '数值在0-120',
  i18nKey_1031: '数据资源',
  i18nKey_1032: '数据源',
  i18nKey_1033: '数据异常监控详情',
  i18nKey_1034: '数据异常(个)',
  i18nKey_1035: '数据异常',
  i18nKey_1036: '数据未加载成功，请重试',
  i18nKey_1037: '数据时间',
  i18nKey_1038: '数据删除之后不能再找回，确认删除吗？',
  i18nKey_1039: '数据删除后不能再恢复，确认删除吗？',
  i18nKey_1040: '数据来源',
  i18nKey_1041: '数据汇总_',
  i18nKey_1042: '数据丢失(个)',
  i18nKey_1043: '输入长度不允许超过50个字符',
  i18nKey_1044: '输入长度不允许超过30个字符（允许字母、-和数字）',
  i18nKey_1045: '输入长度不允许超过30个字符（允许汉字、字母、-和数字）',
  i18nKey_1046: '输入长度不允许超过2000个字符',
  i18nKey_1047: '输入长度不允许超过100个字符',
  i18nKey_1048: '输入事件标题或设备名称',
  i18nKey_1049: '输入摄像机别名',
  i18nKey_1050: '输入关键字进行过滤',
  i18nKey_1051: '输入工位编号后按回车键',
  i18nKey_1052: '输入的数字不能超过最大值30',
  i18nKey_1053: '输入的数字不能超过最大值24',
  i18nKey_1054: '输入的数字不能超过营业时间',
  i18nKey_1055: '舒适度',
  i18nKey_1056: '书房',
  i18nKey_1057: '授权发行',
  i18nKey_1058: '授权成功',
  i18nKey_1059: '授权',
  i18nKey_1060: '受访者电话：',
  i18nKey_1061: '受访者：',
  i18nKey_1062: '受访公司信息',
  i18nKey_1063: '受访公司不能为空',
  i18nKey_1064: '受访公司：',
  i18nKey_1065: '受访公司',
  i18nKey_1066: '受访地址不能为空',
  i18nKey_1067: '首次入园时间',
  i18nKey_1068: '手续费',
  i18nKey_1069: '手机号需注册云城app',
  i18nKey_1070: '手机号',
  i18nKey_1071: '手工录入数据',
  i18nKey_1072: '手工抄表详情',
  i18nKey_1073: '手动创建',
  i18nKey_1074: '手动触发',
  i18nKey_1075: '手动编辑',
  i18nKey_1076: '手动：',
  i18nKey_1077: '收起',
  i18nKey_1078: '收分账号名',
  i18nKey_1079: '收分账号',
  i18nKey_1080: '收分手机号',
  i18nKey_1081: '收费须知',
  i18nKey_1082: '收费设施和免费设施中不能有重复的选项！',
  i18nKey_1083: '收费设施',
  i18nKey_1084: '收费金额',
  i18nKey_1085: '释义',
  i18nKey_1086: '室内温度',
  i18nKey_1087: '室内湿度',
  i18nKey_1088: '室内',
  i18nKey_1089: '适用企业',
  i18nKey_1090: '是否异常',
  i18nKey_1091: '是否循环播放',
  i18nKey_1092: '是否修改',
  i18nKey_1093: '是否完成全程打卡',
  i18nKey_1094: '是否删除数据',
  i18nKey_1095: '是否删除勾选数据',
  i18nKey_1096: '是否确认下载统计图图片？',
  i18nKey_1097: '是否确认下载excel报表吗',
  i18nKey_1098: '是否确认删除该会议室及价格信息？删除后将不可恢复！',
  i18nKey_1099: '是否确认删除该工位？删除后将不可恢复！',
  i18nKey_1100: '是否启用月卡限制',
  i18nKey_1101: '是否启用提醒策略',
  i18nKey_1102: '是否启用车场限制',
  i18nKey_1103: '是否启用',
  i18nKey_1104: '是否离开过',
  i18nKey_1105: '是否补录',
  i18nKey_1106: '视频巡逻统计',
  i18nKey_1107: '视频巡逻',
  i18nKey_1108: '视频截取成功，请至左侧事件详情的关联视频下查看',
  i18nKey_1109: '视频截取',
  i18nKey_1110: '视频回放',
  i18nKey_1111: '视频画面延迟约5-10秒，请耐心等待',
  i18nKey_1112: '视频辅助',
  i18nKey_1113: '视频打卡率',
  i18nKey_1114: '视频',
  i18nKey_1115: '事件状态统计',
  i18nKey_1116: '事件状态',
  i18nKey_1117: '事件上报成功',
  i18nKey_1118: '事件上报',
  i18nKey_1119: '事件描述不能超过1000个字符',
  i18nKey_1120: '事件描述',
  i18nKey_1121: '事件类型',
  i18nKey_1122: '事件级别',
  i18nKey_1123: '事件等级',
  i18nKey_1124: '事件处理时长',
  i18nKey_1125: '事件处理',
  i18nKey_1126: '事件超时处理配置',
  i18nKey_1127: '事件标题',
  i18nKey_1128: '事件编号',
  i18nKey_1129: '始于2019年10月1日',
  i18nKey_1130: '始于2019年10月01日',
  i18nKey_1131: '使用状态',
  i18nKey_1132: '使用域账号验证',
  i18nKey_1133: '使用手机号验证',
  i18nKey_1134: '使用时长',
  i18nKey_1135: '使用率(%)',
  i18nKey_1136: '使用率',
  i18nKey_1137: '使用地址：',
  i18nKey_1138: '实时视频__',
  i18nKey_1139: '实时空气质量指数',
  i18nKey_1140: '实时功率(kWh)',
  i18nKey_1141: '实时抄读电表',
  i18nKey_1142: '实际值',
  i18nKey_1143: '实付金额',
  i18nKey_1144: '时长请勿小于30秒',
  i18nKey_1145: '时长请勿超过一小时',
  i18nKey_1146: '时间至少设定一个',
  i18nKey_1147: '时间设定',
  i18nKey_1148: '时间区间不能超过6个月',
  i18nKey_1149: '时间间隔',
  i18nKey_1150: '时间范围',
  i18nKey_1151: '时间段',
  i18nKey_1152: '时间点',
  i18nKey_1153: '时间不能为空',
  i18nKey_1154: '时间必须为不为零正整数',
  i18nKey_1155: '时段客流统计',
  i18nKey_1156: '时',
  i18nKey_1157: '失信名单',
  i18nKey_1158: '失效时间',
  i18nKey_1159: '失效',
  i18nKey_1160: '剩余可发行总数',
  i18nKey_1161: '剩余金额小于阈值',
  i18nKey_1162: '剩余金额',
  i18nKey_1163: '剩余度数',
  i18nKey_1164: '剩余车位数',
  i18nKey_1165: '剩余车位',
  i18nKey_1166: '省份简称',
  i18nKey_1167: '生效时间',
  i18nKey_1168: '生效日期：2021年05月14日',
  i18nKey_1169: '生效历史',
  i18nKey_1170: '生成二维码',
  i18nKey_1171: '生成安防告警',
  i18nKey_1172: '升降类型',
  i18nKey_1173: '深圳',
  i18nKey_1174: '深蓝',
  i18nKey_1175: '深灰',
  i18nKey_1176: '身份证号和护照号必须填一项',
  i18nKey_1177: '身份证号',
  i18nKey_1178: '身份证：',
  i18nKey_1179: '身份验证',
  i18nKey_1180: '申请状态',
  i18nKey_1181: '申请退款时间',
  i18nKey_1182: '申请人所属公司',
  i18nKey_1183: '申请取消',
  i18nKey_1184: '申请货梯',
  i18nKey_1185: '摄像头监控',
  i18nKey_1186: '摄像头加载中,请稍后...',
  i18nKey_1187: '摄像头个数',
  i18nKey_1188: '摄像头（个）',
  i18nKey_1189: '摄像头',
  i18nKey_1190: '摄像机号',
  i18nKey_1191: '摄像机',
  i18nKey_1192: '设置预警值（天）：',
  i18nKey_1193: '设置预警值',
  i18nKey_1194: '设置营业时间可以有效排除关闭时间时的客流信息',
  i18nKey_1195: '设置消息推送人：',
  i18nKey_1196: '设置为主楼层',
  i18nKey_1197: '设置成功',
  i18nKey_1198: '设施总数',
  i18nKey_1199: '设施子类',
  i18nKey_1200: '设施资产编号',
  i18nKey_1201: '设施状态',
  i18nKey_1202: '设施运行数据',
  i18nKey_1203: '设施有效期',
  i18nKey_1204: '设施位置',
  i18nKey_1205: '设施拓扑关系',
  i18nKey_1206: '设施统计',
  i18nKey_1207: '设施数量统计',
  i18nKey_1208: '设施数据预览',
  i18nKey_1209: '设施数据同步',
  i18nKey_1210: '设施数据导出',
  i18nKey_1211: '设施事件统计',
  i18nKey_1212: '设施事件告警统计（按类型）',
  i18nKey_1213: '设施事件告警统计（按级别）',
  i18nKey_1214: '设施事件告警处置率统计',
  i18nKey_1215: '设施事件告警',
  i18nKey_1216: '设施事件',
  i18nKey_1217: '设施示意图数据',
  i18nKey_1218: '设施示意图',
  i18nKey_1219: '设施名称长度不能超过50个字符',
  i18nKey_1220: '设施名称不能为空',
  i18nKey_1221: '设施名称',
  i18nKey_1222: '设施描述只能由中英文、数字及_组成',
  i18nKey_1223: '设施描述长度不能超过200个字符',
  i18nKey_1224: '设施描述',
  i18nKey_1225: '设施可用率统计',
  i18nKey_1226: '设施可用率',
  i18nKey_1227: '设施基础数据信息',
  i18nKey_1228: '设施基础数据编码',
  i18nKey_1229: '设施画像状态',
  i18nKey_1230: '设施画像数据',
  i18nKey_1231: '设施画像启用时，名称不能为空',
  i18nKey_1232: '设施画像名称',
  i18nKey_1233: '设施画像',
  i18nKey_1234: '设施故障次数',
  i18nKey_1235: '设施工单',
  i18nKey_1236: '设施告警统计',
  i18nKey_1237: '设施别名',
  i18nKey_1238: '设施报修概况',
  i18nKey_1239: '设施报修',
  i18nKey_1240: '设计容量',
  i18nKey_1241: '设备用能排名',
  i18nKey_1242: '设备异常监控详情',
  i18nKey_1243: '设备异常告警-新风机故障！',
  i18nKey_1244: '设备异常',
  i18nKey_1245: '设备信息',
  i18nKey_1246: '设备通行记录',
  i18nKey_1247: '设备数据导入模板',
  i18nKey_1248: '设备数',
  i18nKey_1249: '设备排序',
  i18nKey_1250: '设备名称',
  i18nKey_1251: '设备健康',
  i18nKey_1252: '设备别名',
  i18nKey_1253: '设备备注名称',
  i18nKey_1254: '设备IP',
  i18nKey_1255: '设备ID',
  i18nKey_1256: '设备',
  i18nKey_1257: '上周实际电能耗（kWh）',
  i18nKey_1258: '上周',
  i18nKey_1259: '上月用能',
  i18nKey_1260: '上月同期',
  i18nKey_1261: '上月实际电能耗（KWh）',
  i18nKey_1262: '上月平均值',
  i18nKey_1263: '上月每平方米能耗',
  i18nKey_1264: '上月累计用能',
  i18nKey_1265: '上月',
  i18nKey_1266: '上移',
  i18nKey_1267: '上一年用能',
  i18nKey_1268: '上一年同期用电量',
  i18nKey_1269: '上一年同期',
  i18nKey_1270: '上一年每平方米能耗',
  i18nKey_1271: '上一年',
  i18nKey_1272: '上下架',
  i18nKey_1273: '上午',
  i18nKey_1274: '上升',
  i18nKey_1275: '上架',
  i18nKey_1276: '上行',
  i18nKey_1277: '上海金桥万创中心系统2',
  i18nKey_1278: '上海金桥万创中心系统1',
  i18nKey_1279: '上次位置',
  i18nKey_1280: '上次上报时间',
  i18nKey_1281: '上次抄表日期',
  i18nKey_1282: '上次抄表读数',
  i18nKey_1283: '上传文件失败',
  i18nKey_1284: '上传图片格式不正确，请重新上传',
  i18nKey_1285: '上传图片不能大于3M, 请重新上传',
  i18nKey_1286: '上传图片',
  i18nKey_1287: '上传示意图',
  i18nKey_1288: '上传失败, 请重新上传',
  i18nKey_1289: '上传失败',
  i18nKey_1290: '上传人',
  i18nKey_1291: '上传画像',
  i18nKey_1292: '上传房屋信息表格',
  i18nKey_1293: '上传的图片名称不规范，只允许包含数字、字母、下划线、横线',
  i18nKey_1294: '上报时间',
  i18nKey_1295: '上报人电话',
  i18nKey_1296: '上报人',
  i18nKey_1297: '商租户用能分析',
  i18nKey_1298: '商业积分',
  i18nKey_1299: '商务',
  i18nKey_1300: '商铺状态',
  i18nKey_1301: '商铺位置',
  i18nKey_1302: '商铺号',
  i18nKey_1303: '商品描述',
  i18nKey_1304: '商户名称：',
  i18nKey_1305: '商场客流',
  i18nKey_1306: '商/租户选择',
  i18nKey_1307: '商/租户名称"',
  i18nKey_1308: '商/租户每平方米能耗排名',
  i18nKey_1309: '删除信息需先停用价格！',
  i18nKey_1310: '删除信息需先停用工位！',
  i18nKey_1311: '删除后该设备的所有明细都将删除，确定要删除?',
  i18nKey_1312: '删除后该节点下的内容也将一并删除，建议您先移动后再删，确定删除吗？',
  i18nKey_1313: '删除后不可恢复，确定删除吗？',
  i18nKey_1314: '删除关联失败',
  i18nKey_1315: '删除该条预警阈值后，将默认阈值为0，确定要删除吗？',
  i18nKey_1316: '删除成功 并且租约列表中也一并删除该节点下的所有店铺',
  i18nKey_1317: '删除',
  i18nKey_1318: '扫描下载数智里APP',
  i18nKey_1319: '扫描二维码',
  i18nKey_1320: '扫码设备',
  i18nKey_1321: '扫码登录',
  i18nKey_1322: '三元锂',
  i18nKey_1323: '三类',
  i18nKey_1324: '三级部门',
  i18nKey_1325: '三级',
  i18nKey_1326: '三',
  i18nKey_1327: '若无身份证号码，护照号为必填',
  i18nKey_1328: '入园类型',
  i18nKey_1329: '如有多个停靠站，请按如下格式录入：万科云城 - 宝安中心 - 前海湾 - 宝安机场',
  i18nKey_1330: '容纳人数',
  i18nKey_1331: '日同比',
  i18nKey_1332: '日',
  i18nKey_1333: '任重道远,志在必得。',
  i18nKey_1334: '任务状态统计',
  i18nKey_1335: '任务状态',
  i18nKey_1336: '任务正在追踪中，跳转页面会导致追踪任务停止，确定需要跳转页面吗？',
  i18nKey_1337: '任务责任人',
  i18nKey_1338: '任务委派人',
  i18nKey_1339: '任务数量',
  i18nKey_1340: '任务启动中，请勿删除',
  i18nKey_1341: '任务名称不能为空',
  i18nKey_1342: '任务名称',
  i18nKey_1343: '任务编号',
  i18nKey_1344: '认证企业',
  i18nKey_1345: '人员总览(当天)',
  i18nKey_1346: '人员追踪',
  i18nKey_1347: '人员占比统计(当天)',
  i18nKey_1348: '人员英文姓名输入有特殊字符',
  i18nKey_1349: '人员移动到',
  i18nKey_1350: '人员姓名输入有特殊字符',
  i18nKey_1351: '人员姓名不能为空',
  i18nKey_1352: '人员姓名',
  i18nKey_1353: '人员信息',
  i18nKey_1354: '人员图片',
  i18nKey_1355: '人员统计（按园区）',
  i18nKey_1356: '人员统计（按类型）',
  i18nKey_1357: '人员统计',
  i18nKey_1358: '人员数排行(昨日/前日)',
  i18nKey_1359: '人员手机号不能为空',
  i18nKey_1360: '人员热力',
  i18nKey_1361: '人员类型',
  i18nKey_1362: '人员库选择',
  i18nKey_1363: '人员基础数据信息',
  i18nKey_1364: '人员基础数据创建成功',
  i18nKey_1365: '人员基础数据编码',
  i18nKey_1366: '人员基础数据编号输入有特殊字符，请重新输入',
  i18nKey_1367: '人员基础数据编号',
  i18nKey_1368: '人员轨迹',
  i18nKey_1369: '人员管理',
  i18nKey_1370: '人员对比趋势(近两周)',
  i18nKey_1371: '人员标签',
  i18nKey_1372: '人员ID',
  i18nKey_1373: '人员(最近12周)',
  i18nKey_1374: '人员(最近12个月)',
  i18nKey_1375: '人数概览',
  i18nKey_1376: '人流统计',
  i18nKey_1377: '人脸追踪启动失败',
  i18nKey_1378: '人脸追踪',
  i18nKey_1379: '人脸通行',
  i18nKey_1380: '人脸ID',
  i18nKey_1381: '人脸',
  i18nKey_1382: '人次',
  i18nKey_1383: '人',
  i18nKey_1384: '热门区域客流总数排行',
  i18nKey_1385: '热力蔓延范围',
  i18nKey_1386: '热力点',
  i18nKey_1387: '确认修改',
  i18nKey_1388: '确认新密码',
  i18nKey_1389: '确认添加该用户',
  i18nKey_1390: '确认放弃？',
  i18nKey_1391: '确认此次告警为误判，此次告警信息失效',
  i18nKey_1392: '确定重置此房屋吗',
  i18nKey_1393: '确定一键重置吗',
  i18nKey_1394: '确定要设置为主楼层吗？',
  i18nKey_1395: '确定要删除此地址？',
  i18nKey_1396: '确定要全部开启$个回路吗',
  i18nKey_1397: '确定要全部关闭$个回路吗',
  i18nKey_1398: '确定要开启这$个回路吗?',
  i18nKey_1399: '确定要关闭这$个回路吗?',
  i18nKey_1400: '确定删除设备?',
  i18nKey_1401: '确定删除该用户',
  i18nKey_1402: '确定删除房间?',
  i18nKey_1403: '确定批量删除这些设备吗？',
  i18nKey_1404: '确定批量删除这些户型吗？',
  i18nKey_1405: '确定解除黑名单吗',
  i18nKey_1406: '确定此次告警为误判，此次告警失效',
  i18nKey_1407: '全天 ( 24小时 )',
  i18nKey_1408: '全屏播放',
  i18nKey_1409: '全控开关',
  i18nKey_1410: '全部开启',
  i18nKey_1411: '全部会议室',
  i18nKey_1412: '全部关闭',
  i18nKey_1413: '权责月不能为空',
  i18nKey_1414: '权责月',
  i18nKey_1415: '权限资源',
  i18nKey_1416: '去年同期',
  i18nKey_1417: '取消退款额度不能超过100%！',
  i18nKey_1418: '取消规则不能超过10条！',
  i18nKey_1419: '区域热度',
  i18nKey_1420: '区域名称',
  i18nKey_1421: '区域每平方米能耗排名',
  i18nKey_1422: '区域类型不能为空',
  i18nKey_1423: '区域类型',
  i18nKey_1424: '区域客流',
  i18nKey_1425: '区域到访排行',
  i18nKey_1426: '区域',
  i18nKey_1427: '区块',
  i18nKey_1428: '请至少选择一个摄像头',
  i18nKey_1429: '请至少选择一个人员',
  i18nKey_1430: '请至少添加一位人员保存',
  i18nKey_1431: '请再次输入新密码',
  i18nKey_1432: '请再次输入密码',
  i18nKey_1433: '请选择租约日期范围',
  i18nKey_1434: '请选择资产类型',
  i18nKey_1435: '请选择主题',
  i18nKey_1436: '请选择重要级别',
  i18nKey_1437: '请选择重复方式',
  i18nKey_1438: '请选择照明模式',
  i18nKey_1439: '请选择责任管理人',
  i18nKey_1440: '请选择暂停歇业时间',
  i18nKey_1441: '请选择允许来访时段',
  i18nKey_1442: '请选择预订起始时间',
  i18nKey_1443: '请选择预订结束时间',
  i18nKey_1444: '请选择优惠策略',
  i18nKey_1445: '请选择应用系统',
  i18nKey_1446: '请选择营业开始时间',
  i18nKey_1447: '请选择营业结束时间',
  i18nKey_1448: '请选择异常类型',
  i18nKey_1449: '请选择仪表所属',
  i18nKey_1450: '请选择仪表品牌',
  i18nKey_1451: '请选择一级分类',
  i18nKey_1452: '请选择一级部门',
  i18nKey_1453: '请选择业态',
  i18nKey_1454: '请选择要删除的车辆',
  i18nKey_1455: '请选择需要同步的设施',
  i18nKey_1456: '请选择需要上传的文件',
  i18nKey_1457: '请选择需要上传的会议室首图！',
  i18nKey_1458: '请选择需要上传的工位首图！',
  i18nKey_1459: '请选择需要上传的Banner图！',
  i18nKey_1460: '请选择需要删除的策略',
  i18nKey_1461: '请选择休眠亮度',
  i18nKey_1462: '请选择下单时间',
  i18nKey_1463: '请选择五级分类',
  i18nKey_1464: '请选择五级部门',
  i18nKey_1465: '请选择位置',
  i18nKey_1466: '请选择违停时间',
  i18nKey_1467: '请选择通行方式',
  i18nKey_1468: '请选择停车场',
  i18nKey_1469: '请选择套餐类型',
  i18nKey_1470: '请选择四级分类',
  i18nKey_1471: '请选择四级部门',
  i18nKey_1472: '请选择输入实付金额',
  i18nKey_1473: '请选择输入订单金额',
  i18nKey_1474: '请选择授权起止时间',
  i18nKey_1475: '请选择是否为调试设备',
  i18nKey_1476: '请选择是否上架',
  i18nKey_1477: '请选择事件状态',
  i18nKey_1478: '请选择事件类型',
  i18nKey_1479: '请选择事件级别',
  i18nKey_1480: '请选择事件等级',
  i18nKey_1481: '请选择时间段',
  i18nKey_1482: '请选择时间',
  i18nKey_1483: '请选择升降类型',
  i18nKey_1484: '请选择申请状态',
  i18nKey_1485: '请选择摄像头',
  i18nKey_1486: '请选择设施状态',
  i18nKey_1487: '请选择设施位置',
  i18nKey_1488: '请选择设施画像状态',
  i18nKey_1489: '请选择设施画像名称',
  i18nKey_1490: '请选择设备',
  i18nKey_1491: '请选择商铺状态',
  i18nKey_1492: '请选择商铺位置',
  i18nKey_1493: '请选择商户',
  i18nKey_1494: '请选择删除数据',
  i18nKey_1495: '请选择筛选条件',
  i18nKey_1496: '请选择扫码设备',
  i18nKey_1497: '请选择三级分类',
  i18nKey_1498: '请选择三级部门',
  i18nKey_1499: '请选择日期范围',
  i18nKey_1500: '请选择任务状态',
  i18nKey_1501: '请选择人员类型',
  i18nKey_1502: '请选择人员',
  i18nKey_1503: '请选择权责月',
  i18nKey_1504: '请选择区域类型',
  i18nKey_1505: '请选择区域',
  i18nKey_1506: '请选择欠费商户类型',
  i18nKey_1507: '请选择启用状态',
  i18nKey_1508: '请选择品牌',
  i18nKey_1509: '请选择派发时间',
  i18nKey_1510: '请选择年龄范围',
  i18nKey_1511: '请选择年份',
  i18nKey_1512: '请选择门锁编号',
  i18nKey_1513: '请选择路线',
  i18nKey_1514: '请选择楼宇',
  i18nKey_1515: '请选择楼栋单元',
  i18nKey_1516: '请选择楼栋',
  i18nKey_1517: '请选择楼层空间',
  i18nKey_1518: '请选择楼层',
  i18nKey_1519: '请选择六级分类',
  i18nKey_1520: '请选择类型',
  i18nKey_1521: '请选择来源系统编码',
  i18nKey_1522: '请选择开始时间',
  i18nKey_1523: '请选择开始日期',
  i18nKey_1524: '请选择进车位时间',
  i18nKey_1525: '请选择进场时间',
  i18nKey_1526: '请选择结束时间',
  i18nKey_1527: '请选择角色所属类型',
  i18nKey_1528: '请选择角色类型',
  i18nKey_1529: '请选择计费类型',
  i18nKey_1530: '请选择级别',
  i18nKey_1531: '请选择货梯',
  i18nKey_1532: '请选择活动状态',
  i18nKey_1533: '请选择回路！',
  i18nKey_1534: '请选择后果',
  i18nKey_1535: '请选择合同',
  i18nKey_1536: '请选择国家',
  i18nKey_1537: '请选择关联的人脸库',
  i18nKey_1538: '请选择故障等级',
  i18nKey_1539: '请选择公区类型',
  i18nKey_1540: '请选择工位状态',
  i18nKey_1541: '请选择工位类型！',
  i18nKey_1542: '请选择工单状态',
  i18nKey_1543: '请选择感应亮度',
  i18nKey_1544: '请选择该房屋的户型',
  i18nKey_1545: '请选择付款方式',
  i18nKey_1546: '请选择分项维度',
  i18nKey_1547: '请选择分期',
  i18nKey_1548: '请选择访客状态',
  i18nKey_1549: '请选择房间位置',
  i18nKey_1550: '请选择发起邀请时间范围',
  i18nKey_1551: '请选择发行类型',
  i18nKey_1552: '请选择二级分类',
  i18nKey_1553: '请选择二级部门',
  i18nKey_1554: '请选择订单状态',
  i18nKey_1555: '请选择点位类型',
  i18nKey_1556: '请选择地理位置',
  i18nKey_1557: '请选择到园区',
  i18nKey_1558: '请选择到大区',
  i18nKey_1559: '请选择到城市',
  i18nKey_1560: '请选择单位',
  i18nKey_1561: '请选择处理机制',
  i18nKey_1562: '请选择初始化时长',
  i18nKey_1563: '请选择出车位时间',
  i18nKey_1564: '请选择充值类型',
  i18nKey_1565: '请选择乘车时间',
  i18nKey_1566: '请选择城市',
  i18nKey_1567: '请选择车主类型',
  i18nKey_1568: '请选择车辆标签',
  i18nKey_1569: '请选择车场名称',
  i18nKey_1570: '请选择车场',
  i18nKey_1571: '请选择场景图标',
  i18nKey_1572: '请选择场景策略',
  i18nKey_1573: '请选择场景',
  i18nKey_1574: '请选择产品',
  i18nKey_1575: '请选择策略关联的园区',
  i18nKey_1576: '请选择策略关联的路灯',
  i18nKey_1577: '请选择布控状态',
  i18nKey_1578: '请选择布控摄像头',
  i18nKey_1579: '请选择布控类型',
  i18nKey_1580: '请选择不少于 1 张卡片',
  i18nKey_1581: '请选择不多于 $张卡片',
  i18nKey_1582: '请选择不多于',
  i18nKey_1583: '请选择巴士班次',
  i18nKey_1584: '请选择安装时间',
  i18nKey_1585: '请选择GIS坐标类型',
  i18nKey_1586: '请选择',
  i18nKey_1587: '请先选择会议室名称',
  i18nKey_1588: '请先选择关联数据',
  i18nKey_1589: '请先保存修改',
  i18nKey_1590: '请勿重复添加人员',
  i18nKey_1591: '请勿重复添加',
  i18nKey_1592: '请勿输入特殊字符！',
  i18nKey_1593: '请勿输入特殊字符',
  i18nKey_1594: '请勿超过6个',
  i18nKey_1595: '请完整填写设备信息',
  i18nKey_1596: '请完整填写必填字段',
  i18nKey_1597: '请填写事件描述',
  i18nKey_1598: '请填写认证企业',
  i18nKey_1599: '请填写车牌号码',
  i18nKey_1600: '请填写车辆颜色',
  i18nKey_1601: '请填写车辆品牌',
  i18nKey_1602: '请填写策略名称',
  i18nKey_1603: '请添加适用企业！',
  i18nKey_1604: '请添加人员标签',
  i18nKey_1605: '请添加路线',
  i18nKey_1606: '请搜索或选择公司',
  i18nKey_1607: '请输入最小告警次数',
  i18nKey_1608: '请输入最少起租',
  i18nKey_1609: '请输入最大告警次数',
  i18nKey_1610: '请输入最2 ~ 50个字符,一个汉字算两个字符。',
  i18nKey_1611: '请输入最2 ~ 10个字符,一个汉字算两个字符。"',
  i18nKey_1612: '请输入最1 ~ 50个字符,一个汉字算两个字符,且不能有空格！',
  i18nKey_1613: '请输入总开关安数',
  i18nKey_1614: '请输入自定义二维码刷新时间',
  i18nKey_1615: '请输入资产描述',
  i18nKey_1616: '请输入资产编号',
  i18nKey_1617: '请输入咨询电话',
  i18nKey_1618: '请输入终点站',
  i18nKey_1619: '请输入指令下发周期',
  i18nKey_1620: '请输入支路名称',
  i18nKey_1621: '请输入支付单号',
  i18nKey_1622: '请输入支出/收入方',
  i18nKey_1623: '请输入正整数',
  i18nKey_1624: '请输入正数(最多保留两位小数)!',
  i18nKey_1625: '请输入正确格式的手机号搜索',
  i18nKey_1626: '请输入正确格式的电话号码',
  i18nKey_1627: '请输入正确的验证码',
  i18nKey_1628: '请输入正确的姓名和手机号',
  i18nKey_1629: '请输入正确的手机号码',
  i18nKey_1630: '请输入正确的手机号',
  i18nKey_1631: '请输入正确的身份证号',
  i18nKey_1632: '请输入正确的护照号',
  i18nKey_1633: '请输入正确的电话号码',
  i18nKey_1634: '请输入正确的次数',
  i18nKey_1635: '请输入正确的车牌号或者手机号',
  i18nKey_1636: '请输入正确的车牌号',
  i18nKey_1637: '请输入正确的备注名',
  i18nKey_1638: '请输入正确的办公电话',
  i18nKey_1639: '请输入账号或手机号',
  i18nKey_1640: '请输入占比',
  i18nKey_1641: '请输入原始密码',
  i18nKey_1642: '请输入园区名称',
  i18nKey_1643: '请输入域账号',
  i18nKey_1644: '请输入预警值',
  i18nKey_1645: '请输入预定起始时间',
  i18nKey_1646: '请输入预订人名称',
  i18nKey_1647: '请输入仪表品牌',
  i18nKey_1648: '请输入仪表名称',
  i18nKey_1649: '请输入仪表号,设备名称，商/租户名称',
  i18nKey_1650: '请输入仪表代码/房间号/楼层',
  i18nKey_1651: '请输入仪表代码/编号/名称',
  i18nKey_1652: '请输入仪表表号/楼栋单元',
  i18nKey_1653: '请输入仪表表号',
  i18nKey_1654: '请输入业态名称',
  i18nKey_1655: '请输入验证码',
  i18nKey_1656: '请输入巡巡逻路线说明',
  i18nKey_1657: '请输入巡逻时间',
  i18nKey_1658: '请输入巡逻人',
  i18nKey_1659: '请输入巡逻路线说明',
  i18nKey_1660: '请输入巡逻路线名称',
  i18nKey_1661: '请输入需统计的天数',
  i18nKey_1662: '请输入需监测的总天数',
  i18nKey_1663: '请输入新密码',
  i18nKey_1664: '请输入小于10的数字',
  i18nKey_1665: '请输入小于1~20000之间的价格',
  i18nKey_1666: '请输入小时/天',
  i18nKey_1667: '请输入消防分区名称',
  i18nKey_1668: '请输入详细地址（精确到省市区门牌号）',
  i18nKey_1669: '请输入详细地址',
  i18nKey_1670: '请输入显示价格',
  i18nKey_1671: '请输入违停次数',
  i18nKey_1672: '请输入完整姓名或手机号',
  i18nKey_1673: '请输入外部位置名称',
  i18nKey_1674: '请输入停车场名称',
  i18nKey_1675: '请输入停车场地址',
  i18nKey_1676: '请输入套餐数量',
  i18nKey_1677: '请输入套餐使用次数',
  i18nKey_1678: '请输入套餐名称',
  i18nKey_1679: '请输入所属公司',
  i18nKey_1680: '请输入搜索词',
  i18nKey_1681: '请输入数字值',
  i18nKey_1682: '请输入数字',
  i18nKey_1683: '请输入数量',
  i18nKey_1684: '请输入受访公司',
  i18nKey_1685: '请输入受访地址',
  i18nKey_1686: '请输入手���精确搜索匹配',
  i18nKey_1687: '请输入手机号搜索',
  i18nKey_1688: '请输入手机号码搜索',
  i18nKey_1689: '请输入手机号码或域账号',
  i18nKey_1690: '请输入手机号',
  i18nKey_1691: '请输入收分账号名',
  i18nKey_1692: '请输入收分手机号',
  i18nKey_1693: '请输入收费须知',
  i18nKey_1694: '请输入事件编号',
  i18nKey_1695: '请输入设施别名',
  i18nKey_1696: '请输入设施编号',
  i18nKey_1697: '请输入设备别名',
  i18nKey_1698: '请输入上报人电话',
  i18nKey_1699: '请输入上报人',
  i18nKey_1700: '请输入商铺号',
  i18nKey_1701: '请输入商家名称',
  i18nKey_1702: '请输入商/租户名称',
  i18nKey_1703: '请输入容纳人数，需为整数',
  i18nKey_1704: '请输入容纳人数',
  i18nKey_1705: '请输入任务责任人',
  i18nKey_1706: '请输入任务委派人',
  i18nKey_1707: '请输入任务名称',
  i18nKey_1708: '请输入任务描述',
  i18nKey_1709: '请输入任务或人员名称',
  i18nKey_1710: '请输入任务编号',
  i18nKey_1711: '请输入人员基础数据编号',
  i18nKey_1712: '请输入热力范围',
  i18nKey_1713: '请输入区块信息',
  i18nKey_1714: '请输入起点站',
  i18nKey_1715: '请输入企业中文名',
  i18nKey_1716: '请输入企业邮箱后缀',
  i18nKey_1717: '请输入企业网址',
  i18nKey_1718: '请输入企业名称',
  i18nKey_1719: '请输入企业联系电话',
  i18nKey_1720: '请输入企业介绍',
  i18nKey_1721: '请输入票/单价',
  i18nKey_1722: '请输入批次号',
  i18nKey_1723: '请输入昵称或电话搜索',
  i18nKey_1724: '请输入昵称',
  i18nKey_1725: '请输入内容',
  i18nKey_1726: '请输入摩根平台会议室ID',
  i18nKey_1727: '请输入模式名称',
  i18nKey_1728: '请输入面积，需为整数',
  i18nKey_1729: '请输入面积',
  i18nKey_1730: '请输入密码',
  i18nKey_1731: '请输入门禁名称',
  i18nKey_1732: '请输入每天需分析的有效停留时长',
  i18nKey_1733: '请输入路线描述',
  i18nKey_1734: '请输入路灯名称或地址',
  i18nKey_1735: '请输入量程',
  i18nKey_1736: '请输入联系电话',
  i18nKey_1737: '请输入累计可租时长',
  i18nKey_1738: '请输入空间名称/工位编号',
  i18nKey_1739: '请输入可预订人名称',
  i18nKey_1740: '请输入距离范围',
  i18nKey_1741: '请输入旧密码',
  i18nKey_1742: '请输入金额',
  i18nKey_1743: '请输入接待人姓名/手机号',
  i18nKey_1744: '请输入角色名称',
  i18nKey_1745: '请输入角色描述',
  i18nKey_1746: '请输入奖励数量',
  i18nKey_1747: '请输入奖励对象/收分账号',
  i18nKey_1748: '请输入建筑名称',
  i18nKey_1749: '请输入简易路线',
  i18nKey_1750: '请输入价格',
  i18nKey_1751: '请输入计量表名称',
  i18nKey_1752: '请输入计量表编号',
  i18nKey_1753: '请输入计划名称',
  i18nKey_1754: '请输入积分类型名称',
  i18nKey_1755: '请输入积分价值',
  i18nKey_1756: '请输入活动名称',
  i18nKey_1757: '请输入活动',
  i18nKey_1758: '请输入会议室名称/预定企业/会议状态',
  i18nKey_1759: '请输入会议室名称',
  i18nKey_1760: '请输入会议室描述',
  i18nKey_1761: '请输入户型名称',
  i18nKey_1762: '请输入核载人数',
  i18nKey_1763: '请输入合法的MAC地址，如00-01-6c-06-A6-29或00:01:6c:06:A6:29',
  i18nKey_1764: '请输入管理员姓名',
  i18nKey_1765: '请输入公区名称',
  i18nKey_1766: '请输入工作人员手机号码',
  i18nKey_1767: '请输入工作人员名称',
  i18nKey_1768: '请输入工位数量',
  i18nKey_1769: '请输入工位简介',
  i18nKey_1770: '请输入工位价格',
  i18nKey_1771: '请输入工商执照注册号',
  i18nKey_1772: '请输入工单责任人',
  i18nKey_1773: '请输入工单编号',
  i18nKey_1774: '请输入感应后保持亮度时长',
  i18nKey_1775: '请输入附加设施数量',
  i18nKey_1776: '请输入附加设施名称',
  i18nKey_1777: '请输入服务器IP或域名',
  i18nKey_1778: '请输入分项名称',
  i18nKey_1779: '请输入房间名称',
  i18nKey_1780: '请输入法人身份证',
  i18nKey_1781: '请输入法定代表人',
  i18nKey_1782: '请输入发行数量',
  i18nKey_1783: '请输入额度，需为整数',
  i18nKey_1784: '请输入额度',
  i18nKey_1785: '请输入多少分钟',
  i18nKey_1786: '请输入订单编号/空间名称',
  i18nKey_1787: '请输入订单编号/会议室名称',
  i18nKey_1788: '请输入订单编号',
  i18nKey_1789: '请输入电缆平方数',
  i18nKey_1790: '请输入电话搜索',
  i18nKey_1791: '请输入电表表号,设备名称,商/租户名称',
  i18nKey_1792: '请输入电表编号',
  i18nKey_1793: '请输入第三方ID',
  i18nKey_1794: '请输入地址经纬度',
  i18nKey_1795: '请输入地址',
  i18nKey_1796: '请输入当前账号登录密码',
  i18nKey_1797: '请输入单价(元/小时)',
  i18nKey_1798: '请输入处理人',
  i18nKey_1799: '请输入承租方',
  i18nKey_1800: '请输入车主姓名',
  i18nKey_1801: '请输入抄表人',
  i18nKey_1802: '请输入场景名称',
  i18nKey_1803: '请输入场景的名称，不超过十个字',
  i18nKey_1804: '请输入策略名称',
  i18nKey_1805: '请输入表底',
  i18nKey_1806: '请输入倍率',
  i18nKey_1807: '请输入半天单价（元/4小时）',
  i18nKey_1808: '请输入班次编号',
  i18nKey_1809: '请输入安装位置',
  i18nKey_1810: '请输入0-1之间的小数',
  i18nKey_1811: '请输入$表号,设备名称,商/租户名称',
  i18nKey_1812: '请输入',
  i18nKey_1813: '请上传新版excel文件',
  i18nKey_1814: '请上传图像',
  i18nKey_1815: '请确保您拥有权限访问这些信息，您所有的操作将被记录，超过30分钟没有操作，将需要重新进行验证',
  i18nKey_1816: '请求关联列表异常',
  i18nKey_1817: '请您联系管理员获取相关服务权限。',
  i18nKey_1818: '请将当前层级的IT编码、业务编码和空间层级名称填写完整！',
  i18nKey_1819: '请检查地址栏链接后，重新访问',
  i18nKey_1820: '请登录您的账号',
  i18nKey_1821: '请登录',
  i18nKey_1822: '请把相同的电表公式整合在一起',
  i18nKey_1823: '清除',
  i18nKey_1824: '轻度污染',
  i18nKey_1825: '青色',
  i18nKey_1826: '切换至全览',
  i18nKey_1827: '切换至默认比例',
  i18nKey_1828: '切换图像',
  i18nKey_1829: '切换列表',
  i18nKey_1830: '欠费商户',
  i18nKey_1831: '前一天',
  i18nKey_1832: '前往智慧园区运营平台登录页',
  i18nKey_1833: '起始时间不能大于等于结束时间',
  i18nKey_1834: '起始或者结束时长不能为空',
  i18nKey_1835: '起始或者结束金额不能为空',
  i18nKey_1836: '起始或者结束次数不能为空',
  i18nKey_1837: '起点站',
  i18nKey_1838: '起点人数',
  i18nKey_1839: '起点不能为空',
  i18nKey_1840: '启用日期',
  i18nKey_1841: '启用',
  i18nKey_1842: '企业中文名',
  i18nKey_1843: '企业员工',
  i18nKey_1844: '企业邮箱后缀',
  i18nKey_1845: '企业英文名',
  i18nKey_1846: '企业详细地址',
  i18nKey_1847: '企业网址',
  i18nKey_1848: '企业商户',
  i18nKey_1849: '企业能耗排名',
  i18nKey_1850: '企业名称',
  i18nKey_1851: '企业每日打卡_',
  i18nKey_1852: '企业联系号码',
  i18nKey_1853: '企业联系电话',
  i18nKey_1854: '企业类型',
  i18nKey_1855: '企业介绍',
  i18nKey_1856: '企业挂账',
  i18nKey_1857: '企业访客',
  i18nKey_1858: '企业法人',
  i18nKey_1859: '企业地址',
  i18nKey_1860: '企业logo',
  i18nKey_1861: '企业',
  i18nKey_1862: '其子分项能耗占比',
  i18nKey_1863: '其它',
  i18nKey_1864: '其他用电',
  i18nKey_1865: '其他信息',
  i18nKey_1866: '其他',
  i18nKey_1867: '七类',
  i18nKey_1868: '普通事件',
  i18nKey_1869: '普通',
  i18nKey_1870: '平日客流',
  i18nKey_1871: '平均转化时间',
  i18nKey_1872: '平均驻留时长',
  i18nKey_1873: '平均值',
  i18nKey_1874: '平均每天节省费用',
  i18nKey_1875: '平均每个企业最多发行数',
  i18nKey_1876: '平均处理时长：',
  i18nKey_1877: '平均处理时长(分钟)',
  i18nKey_1878: '平层',
  i18nKey_1879: '品牌只能由中英文、数字及_组成',
  i18nKey_1880: '品牌长度不能超过100个字符',
  i18nKey_1881: '品牌和颜色超过6个选项 已为您按最优显示',
  i18nKey_1882: '品牌分析',
  i18nKey_1883: '品牌超过6个选项 已为您按最优显示',
  i18nKey_1884: '品牌：',
  i18nKey_1885: '品牌',
  i18nKey_1886: '频率',
  i18nKey_1887: '频次',
  i18nKey_1888: '票/单价',
  i18nKey_1889: '批量修改基于导出数据的模板',
  i18nKey_1890: '批量修改',
  i18nKey_1891: '批量创建',
  i18nKey_1892: '配置详情',
  i18nKey_1893: '配电开关名称',
  i18nKey_1894: '派发时间',
  i18nKey_1895: '派发成功',
  i18nKey_1896: '排名',
  i18nKey_1897: '女性',
  i18nKey_1898: '您有较多的待查询任务，请等待任务执行完毕后再进行查询。',
  i18nKey_1899: '您已成功修改密码，请重新登录平台。',
  i18nKey_1900: '您要暂停所选套餐吗？',
  i18nKey_1901: '您要暂停所选免额吗？',
  i18nKey_1902: '您要暂停所选会议室吗？',
  i18nKey_1903: '您要暂停所选工位吗？',
  i18nKey_1904: '您要暂停所选乘车班次吗？',
  i18nKey_1905: '您要下架所选限时折扣吗？',
  i18nKey_1906: '您要上架所选限时折扣吗？',
  i18nKey_1907: '您要启用所选套餐吗？',
  i18nKey_1908: '您要启用所选免额吗？',
  i18nKey_1909: '您要启用所选会议室吗？',
  i18nKey_1910: '您要启用所选工位吗？',
  i18nKey_1911: '您要启用所选乘车班次吗？',
  i18nKey_1912: '您想清空所有查询历史吗？',
  i18nKey_1913: '您设置的时间存在重叠',
  i18nKey_1914: '您确认要执行吗？',
  i18nKey_1915: '您确认要停用吗？',
  i18nKey_1916: '您确认要删除吗？',
  i18nKey_1917: '您确认要启用吗？',
  i18nKey_1918: '您确定要删除限时折扣吗？',
  i18nKey_1919: '您确定要删除套餐吗？',
  i18nKey_1920: '您没有选中任何数据项！',
  i18nKey_1921: '您没有选中任何数据项',
  i18nKey_1922: '您好，欢迎来到智慧园区运营平台！',
  i18nKey_1923: '您还没有任何公司的访客数据，您可以',
  i18nKey_1924: '您访问的页面不存在',
  i18nKey_1925: '您的浏览器不支持HTML5',
  i18nKey_1926: '您当前选中设备存在故障，请先安排人处理！',
  i18nKey_1927: '您当前选中大屏已离线，指令不可达！',
  i18nKey_1928: '年龄范围',
  i18nKey_1929: '年度',
  i18nKey_1930: '年',
  i18nKey_1931: '昵称不能超过12个字符长度',
  i18nKey_1932: '昵称',
  i18nKey_1933: '你已选择套餐缴费',
  i18nKey_1934: '你已选择临停缴费',
  i18nKey_1935: '你已录入$/$,与上期数据对比，有$条数据异常($),确定提交吗',
  i18nKey_1936: '你已导入$条数据,有$条数据异常,确定提交吗',
  i18nKey_1937: '你所编辑的策略还未保存，确认放弃？',
  i18nKey_1938: '你确定需要删除该策略吗？删除后，需要手动控制灯开关。',
  i18nKey_1939: '能效比COP(kW/kW)',
  i18nKey_1940: '能效AI诊断',
  i18nKey_1941: '能耗统计',
  i18nKey_1942: '能耗浪费',
  i18nKey_1943: '能耗监测',
  i18nKey_1944: '能耗对比',
  i18nKey_1945: '能耗(kWh)',
  i18nKey_1946: '内部企业',
  i18nKey_1947: '男性',
  i18nKey_1948: '目标值设置',
  i18nKey_1949: '目标值',
  i18nKey_1950: '默认（次日00:00刷新）',
  i18nKey_1951: '默认',
  i18nKey_1952: '摩根平台会议室ID',
  i18nKey_1953: '模式名称',
  i18nKey_1954: '模式',
  i18nKey_1955: '模糊查询',
  i18nKey_1956: '模板下载',
  i18nKey_1957: '明细',
  i18nKey_1958: '秒后重发',
  i18nKey_1959: '秒后会自动跳转到智慧园区运营平台登录页',
  i18nKey_1960: '秒',
  i18nKey_1961: '描述不能为空',
  i18nKey_1962: '面试',
  i18nKey_1963: '面积(m²)',
  i18nKey_1964: '免费设施',
  i18nKey_1965: '免费额度(小时/月)',
  i18nKey_1966: '免额启用中，不允许修改！',
  i18nKey_1967: '密码状态',
  i18nKey_1968: '密码长度为8~20位字符!',
  i18nKey_1969: '密码长度必须是8到20位且包含数字和字母',
  i18nKey_1970: '密码输入错误次数超过5次，10秒后将退出登录',
  i18nKey_1971: '密码强度不符合,至少8位,包括数字,大、小写字母,特殊字符中的至少三类',
  i18nKey_1972: '密码错误，请重试',
  i18nKey_1973: '密码不能包含空格',
  i18nKey_1974: '密码必须是8到20位的数字和字母',
  i18nKey_1975: '密码必须包括字母、数字、符号中至少2种',
  i18nKey_1976: '米',
  i18nKey_1977: '门锁编号',
  i18nKey_1978: '门禁通行限次',
  i18nKey_1979: '门禁通行方式',
  i18nKey_1980: '门禁名称',
  i18nKey_1981: '门禁二维码读头',
  i18nKey_1982: '门禁PAD',
  i18nKey_1983: '每条策略最多可添加6种亮灯规则',
  i18nKey_1984: '每平方米能耗(t/m²)',
  i18nKey_1985: '每平方米能耗(kWh/m²)',
  i18nKey_1986: '每平方米能耗',
  i18nKey_1987: '每次只能追踪一个任务，确定需要放弃当前追踪任务并开启新的追踪任务吗？',
  i18nKey_1988: '没有数据，请从下方空间树选中楼栋',
  i18nKey_1989: '没有数据!',
  i18nKey_1990: '没有会议安排的时段',
  i18nKey_1991: '没有查找到该用户',
  i18nKey_1992: '绿色节能',
  i18nKey_1993: '绿色',
  i18nKey_1994: '轮巡机数量',
  i18nKey_1995: '路线描述',
  i18nKey_1996: '路线列表',
  i18nKey_1997: '路线（条）',
  i18nKey_1998: '路线',
  i18nKey_1999: '路灯选择',
  i18nKey_2000: '楼宇自控',
  i18nKey_2001: '楼栋单元',
  i18nKey_2002: '楼栋/楼层/房间',
  i18nKey_2003: '楼栋',
  i18nKey_2004: '楼层空间',
  i18nKey_2005: '楼层',
  i18nKey_2006: '六类',
  i18nKey_2007: '六级',
  i18nKey_2008: '临停收费',
  i18nKey_2009: '临停缴费金额(元)',
  i18nKey_2010: '临停车次',
  i18nKey_2011: '临停车',
  i18nKey_2012: '临停',
  i18nKey_2013: '临时访客',
  i18nKey_2014: '临时',
  i18nKey_2015: '量程',
  i18nKey_2016: '辆',
  i18nKey_2017: '亮度显示',
  i18nKey_2018: '亮度设置',
  i18nKey_2019: '亮度',
  i18nKey_2020: '亮灯规则',
  i18nKey_2021: '两次输入的密码不一致',
  i18nKey_2022: '两充两放',
  i18nKey_2023: '良',
  i18nKey_2024: '联系电话',
  i18nKey_2025: '联排',
  i18nKey_2026: '例子：平安XXXXXX停车场,首小时15元（前15分钟免费），此后每小时10元 ，60封顶。广东省深圳市福田区XXXXX',
  i18nKey_2027: '例如：首小时15元（前15分钟免费），此后每小时10元 ，60封顶。',
  i18nKey_2028: '立即注册',
  i18nKey_2029: '立即登录',
  i18nKey_2030: '历史诊断',
  i18nKey_2031: '历史统计',
  i18nKey_2032: '历史视频播放结束',
  i18nKey_2033: '历史视频',
  i18nKey_2034: '历史曲线',
  i18nKey_2035: '历史轨迹',
  i18nKey_2036: '历史版本',
  i18nKey_2037: '离线(个)',
  i18nKey_2038: '离线',
  i18nKey_2039: '离开舒适区,是前进的第一步。',
  i18nKey_2040: '冷机出水温度',
  i18nKey_2041: '累计消费金额',
  i18nKey_2042: '累计停车缴费(元)',
  i18nKey_2043: '累计临停缴费$元，可以推荐月卡给他哦',
  i18nKey_2044: '累计可租时长',
  i18nKey_2045: '累计可租时常不能小于最小起租！',
  i18nKey_2046: '累计缴费>¥400',
  i18nKey_2047: '累计到访次数',
  i18nKey_2048: '累计到访',
  i18nKey_2049: '累积节能潜力始于2019年10月01日至今',
  i18nKey_2050: '累积节能潜力(kWh)',
  i18nKey_2051: '累积节能潜力',
  i18nKey_2052: '类型不能为空',
  i18nKey_2053: '类型',
  i18nKey_2054: '类别不能为空',
  i18nKey_2055: '蓝色',
  i18nKey_2056: '来自省市',
  i18nKey_2057: '来源系统设备编号',
  i18nKey_2058: '来源系统',
  i18nKey_2059: '来源',
  i18nKey_2060: '来访原因',
  i18nKey_2061: '来访时段的开始时间不能大于或等于结束时间',
  i18nKey_2062: '来访类型',
  i18nKey_2063: '空闲状态',
  i18nKey_2064: '空调用电',
  i18nKey_2065: '空气质量令人满意，基本无空气污染',
  i18nKey_2066: '空间位置',
  i18nKey_2067: '空间名称/工位编号',
  i18nKey_2068: '空间名称',
  i18nKey_2069: '空间类型',
  i18nKey_2070: '空间基础数据业务编码',
  i18nKey_2071: '空间基础数据修改',
  i18nKey_2072: '空间基础数据详情',
  i18nKey_2073: '空间基础数据位置',
  i18nKey_2074: '空间基础数据创建',
  i18nKey_2075: '空间基础数据编码',
  i18nKey_2076: '空间层级名称',
  i18nKey_2077: '空间层级',
  i18nKey_2078: '客厅',
  i18nKey_2079: '客流总数（人次）',
  i18nKey_2080: '客流性别占比',
  i18nKey_2081: '可用率范围',
  i18nKey_2082: '可选单位',
  i18nKey_2083: '可提前预定0到30',
  i18nKey_2084: '开锁类型',
  i18nKey_2085: '开始时间和结束时间相距不能小于30分钟',
  i18nKey_2086: '开始时间和结束时间相差不能超过7天',
  i18nKey_2087: '开始时间不能为空',
  i18nKey_2088: '开始时间不能大于结束时间',
  i18nKey_2089: '开始时间',
  i18nKey_2090: '开始安装',
  i18nKey_2091: '开启追踪成功',
  i18nKey_2092: '开启追踪',
  i18nKey_2093: '开启所选',
  i18nKey_2094: '开启时间',
  i18nKey_2095: '开启门禁',
  i18nKey_2096: '开启后智慧门禁按照设定时间频率更新',
  i18nKey_2097: '开启后可以通行',
  i18nKey_2098: '开启后访客可以查看自己的历史登记记录',
  i18nKey_2099: '开启后，移动端用户可以使用该功能，关闭后则不显示。',
  i18nKey_2100: '开启后，移动端用户可使用该功能，关闭后则不可使用',
  i18nKey_2101: '开门状态',
  i18nKey_2102: '开门频次',
  i18nKey_2103: '开门记录',
  i18nKey_2104: '开会模式',
  i18nKey_2105: '开/关',
  i18nKey_2106: '开',
  i18nKey_2107: '具体空间位置编码',
  i18nKey_2108: '拒绝',
  i18nKey_2109: '居住地址',
  i18nKey_2110: '旧密码',
  i18nKey_2111: '经度整数部分为0-180',
  i18nKey_2112: '经度',
  i18nKey_2113: '京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领',
  i18nKey_2114: '禁止进入车场',
  i18nKey_2115: '禁止办理月卡',
  i18nKey_2116: '禁用失败',
  i18nKey_2117: '近一月入场次数',
  i18nKey_2118: '近一月平均客流',
  i18nKey_2119: '近一月到访天数',
  i18nKey_2120: '近一月',
  i18nKey_2121: '近一年',
  i18nKey_2122: '近一个月最早$进入园区',
  i18nKey_2123: '近一个月未离场',
  i18nKey_2124: '近一个月未到访',
  i18nKey_2125: '近一个月回头客到访次数',
  i18nKey_2126: '近一个月大于400',
  i18nKey_2127: '近7天',
  i18nKey_2128: '近30天离开车场次数',
  i18nKey_2129: '近30天进入车场次数',
  i18nKey_2130: '近24小时重大事件告警',
  i18nKey_2131: '近24小时',
  i18nKey_2132: '进行中',
  i18nKey_2133: '进车位时间',
  i18nKey_2134: '进场时间',
  i18nKey_2135: '紧急告警',
  i18nKey_2136: '仅一次',
  i18nKey_2137: '仅显示视频巡逻告警数量',
  i18nKey_2138: '仅计划内的巡逻参与统计',
  i18nKey_2139: '金融',
  i18nKey_2140: '金额最大长度为6位数',
  i18nKey_2141: '金额只能为整数或2位数以内的小数',
  i18nKey_2142: '今日通行人流',
  i18nKey_2143: '今日通行车流',
  i18nKey_2144: '今日实际电能耗（kWh）',
  i18nKey_2145: '今日客流',
  i18nKey_2146: '今日开门频次排行',
  i18nKey_2147: '今日回头客数',
  i18nKey_2148: '解除黑名单成功',
  i18nKey_2149: '解除黑名单',
  i18nKey_2150: '截图失败',
  i18nKey_2151: '结束时长不能小于起始时长',
  i18nKey_2152: '结束时间需要大于开始时间',
  i18nKey_2153: '结束时间不能为空',
  i18nKey_2154: '结束时间',
  i18nKey_2155: '结束模式',
  i18nKey_2156: '结束金额不能小于起始金额',
  i18nKey_2157: '结束次数不能小于起始次数',
  i18nKey_2158: '节省费用(元)',
  i18nKey_2159: '节省费用',
  i18nKey_2160: '节日名称不允许为空',
  i18nKey_2161: '节日',
  i18nKey_2162: '节能潜力(kWh)',
  i18nKey_2163: '节能潜力',
  i18nKey_2164: '节能类型(种)',
  i18nKey_2165: '节能类型',
  i18nKey_2166: '节目名称：',
  i18nKey_2167: '接入电压等级(V)',
  i18nKey_2168: '接口请求失败',
  i18nKey_2169: '接待人姓名',
  i18nKey_2170: '接待人信息',
  i18nKey_2171: '接待人电话',
  i18nKey_2172: '接驳车满座率',
  i18nKey_2173: '较昨天',
  i18nKey_2174: '较昨日',
  i18nKey_2175: '较上周',
  i18nKey_2176: '较上月环比',
  i18nKey_2177: '较上月',
  i18nKey_2178: '较上一年',
  i18nKey_2179: '较上年实际值',
  i18nKey_2180: '较去年同比',
  i18nKey_2181: '较前一月环比',
  i18nKey_2182: '较前一天',
  i18nKey_2183: '缴费明细',
  i18nKey_2184: '缴费类型',
  i18nKey_2185: '角色状态',
  i18nKey_2186: '角色名称',
  i18nKey_2187: '角色描述',
  i18nKey_2188: '角色列表',
  i18nKey_2189: '角色价格名称',
  i18nKey_2190: '奖励数量不能小于零',
  i18nKey_2191: '奖励数量不能为零',
  i18nKey_2192: '奖励数量不能带有小数',
  i18nKey_2193: '奖励数量',
  i18nKey_2194: '奖励积分数量(个)',
  i18nKey_2195: '奖励积分名称',
  i18nKey_2196: '奖励发起人',
  i18nKey_2197: '奖励对象选择',
  i18nKey_2198: '奖励对象类型',
  i18nKey_2199: '奖励对象/收分账号',
  i18nKey_2200: '奖励',
  i18nKey_2201: '将文件拖到此处，或 点击上传',
  i18nKey_2202: '建筑选择',
  i18nKey_2203: '建筑电器系统',
  i18nKey_2204: '建议值',
  i18nKey_2205: '建议执行人',
  i18nKey_2206: '建议运行参数',
  i18nKey_2207: '简易路线',
  i18nKey_2208: '检修中',
  i18nKey_2209: '监控摄像',
  i18nKey_2210: '监控截图',
  i18nKey_2211: '监控：',
  i18nKey_2212: '监测周期（天）',
  i18nKey_2213: '监测天数只能是正整数',
  i18nKey_2214: '监测',
  i18nKey_2215: '间隔时间范围在1-60之内',
  i18nKey_2216: '间隔时间(秒)',
  i18nKey_2217: '驾驶证号输入有特殊字符',
  i18nKey_2218: '驾驶证号',
  i18nKey_2219: '驾驶证：',
  i18nKey_2220: '价格设置',
  i18nKey_2221: '价格(/剩余次数)',
  i18nKey_2222: '加班比较多，近一个月累计10天晚上20:00后离开园区',
  i18nKey_2223: '继电器状态',
  i18nKey_2224: '记住当前账号',
  i18nKey_2225: '计量表名称',
  i18nKey_2226: '计量表简称',
  i18nKey_2227: '计量表编号',
  i18nKey_2228: '计量表',
  i18nKey_2229: '计划详情',
  i18nKey_2230: '计划名称',
  i18nKey_2231: '计费类型',
  i18nKey_2232: '籍贯',
  i18nKey_2233: '基本信息',
  i18nKey_2234: '积分总价值(元)',
  i18nKey_2235: '积分总价值',
  i18nKey_2236: '积分有效期(开始)',
  i18nKey_2237: '积分有效期(结束)',
  i18nKey_2238: '积分有效期',
  i18nKey_2239: '积分钱包',
  i18nKey_2240: '积分价值(元/分)',
  i18nKey_2241: '机械表',
  i18nKey_2242: '机房功率（kWh）',
  i18nKey_2243: '获取验证码',
  i18nKey_2244: '获取视频资源失败',
  i18nKey_2245: '获取时段',
  i18nKey_2246: '获取摄像头资源失败...',
  i18nKey_2247: '获取设备信息失败！',
  i18nKey_2248: '获取空间数据失败！',
  i18nKey_2249: '获取地址经纬度',
  i18nKey_2250: '获取table列表异常',
  i18nKey_2251: '货梯选择',
  i18nKey_2252: '活动状态',
  i18nKey_2253: '活动详细信息',
  i18nKey_2254: '活动名称',
  i18nKey_2255: '活动工位的最少起租需不小于30天！',
  i18nKey_2256: '活动工位',
  i18nKey_2257: '活动不能为空',
  i18nKey_2258: '活动',
  i18nKey_2259: '会员姓名',
  i18nKey_2260: '会员编码',
  i18nKey_2261: '会议状态',
  i18nKey_2262: '会议信息',
  i18nKey_2263: '会议室详情',
  i18nKey_2264: '会议室首图',
  i18nKey_2265: '会议室使用',
  i18nKey_2266: '会议室名称不支持特殊字符（允许汉字、字母和数字）',
  i18nKey_2267: '会议室名称',
  i18nKey_2268: '会议室描述',
  i18nKey_2269: '会议室服务人员',
  i18nKey_2270: '会议室订单详情',
  i18nKey_2271: '会议开始前：未启动',
  i18nKey_2272: '会议开始前：',
  i18nKey_2273: '会议开始前',
  i18nKey_2274: '会议结束后：',
  i18nKey_2275: '回头客到访人数',
  i18nKey_2276: '回头客比例',
  i18nKey_2277: '回头客',
  i18nKey_2278: '回路状态',
  i18nKey_2279: '回路编号/服务区域',
  i18nKey_2280: '回路编号',
  i18nKey_2281: '回放',
  i18nKey_2282: '灰色',
  i18nKey_2283: '黄色',
  i18nKey_2284: '环境监测设备',
  i18nKey_2285: '环境监测',
  i18nKey_2286: '环境报警策略配置',
  i18nKey_2287: '环比用能',
  i18nKey_2288: '环比同期用电量',
  i18nKey_2289: '环比同期',
  i18nKey_2290: '环比时间',
  i18nKey_2291: '环比每平方米能耗',
  i18nKey_2292: '环比',
  i18nKey_2293: '欢迎使用智慧园区运营平台，请重置密码',
  i18nKey_2294: '欢迎使用智慧园区运营平台，请修改密码后登录您的账号',
  i18nKey_2295: '欢迎使用智慧园区运营平台，请您注册',
  i18nKey_2296: '华中',
  i18nKey_2297: '华西',
  i18nKey_2298: '华南',
  i18nKey_2299: '华东',
  i18nKey_2300: '华北园区',
  i18nKey_2301: '华北',
  i18nKey_2302: '划线',
  i18nKey_2303: '护照号',
  i18nKey_2304: '户型名称格式示例：A1-3房2厅',
  i18nKey_2305: '户型名称',
  i18nKey_2306: '户数',
  i18nKey_2307: '互联网',
  i18nKey_2308: '弧形图',
  i18nKey_2309: '后一天月预测值',
  i18nKey_2310: '后果',
  i18nKey_2311: '后付费',
  i18nKey_2312: '红色',
  i18nKey_2313: '很早投入工作了，近一个月累计10天早上8:00前进入园区',
  i18nKey_2314: '黑色',
  i18nKey_2315: '黑名单事件数',
  i18nKey_2316: '黑名单事件',
  i18nKey_2317: '黑名单概况',
  i18nKey_2318: '黑名单',
  i18nKey_2319: '核载人数',
  i18nKey_2320: '合同期：',
  i18nKey_2321: '合同编号：',
  i18nKey_2322: '合同',
  i18nKey_2323: '合伙奋斗。',
  i18nKey_2324: '合并',
  i18nKey_2325: '耗水量',
  i18nKey_2326: '耗时最长Top3',
  i18nKey_2327: '耗时最短Top3',
  i18nKey_2328: '耗电量（度）',
  i18nKey_2329: '耗电量',
  i18nKey_2330: '豪车',
  i18nKey_2331: '行为特征',
  i18nKey_2332: '行驶证：',
  i18nKey_2333: '国家',
  i18nKey_2334: '国籍',
  i18nKey_2335: '轨迹时间段',
  i18nKey_2336: '轨迹导出成功',
  i18nKey_2337: '轨迹查看',
  i18nKey_2338: '轨迹',
  i18nKey_2339: '规则',
  i18nKey_2340: '广州',
  i18nKey_2341: '管理责任人只能为20个字符以内的中文、字母及下划线',
  i18nKey_2342: '管理责任人',
  i18nKey_2343: '管理员主动退款',
  i18nKey_2344: '管理员姓名',
  i18nKey_2345: '管理所属权',
  i18nKey_2346: '管理审批人',
  i18nKey_2347: '管理部门',
  i18nKey_2348: '关联异常',
  i18nKey_2349: '关联事件',
  i18nKey_2350: '关联摄像头成功',
  i18nKey_2351: '关联摄像头',
  i18nKey_2352: '关联设施事件',
  i18nKey_2353: '关联历史视频__',
  i18nKey_2354: '关联成功',
  i18nKey_2355: '关联车牌号',
  i18nKey_2356: '关联',
  i18nKey_2357: '关键词',
  i18nKey_2358: '关闭追踪异常',
  i18nKey_2359: '关闭追踪',
  i18nKey_2360: '关闭所选',
  i18nKey_2361: '关闭告警',
  i18nKey_2362: '关闭',
  i18nKey_2363: '关',
  i18nKey_2364: '挂账人',
  i18nKey_2365: '故障总频率',
  i18nKey_2366: '故障详情',
  i18nKey_2367: '故障设施数',
  i18nKey_2368: '故障设施类型统计',
  i18nKey_2369: '故障描述',
  i18nKey_2370: '故障来源',
  i18nKey_2371: '故障',
  i18nKey_2372: '固定工位的最少起租需不小于3个月！',
  i18nKey_2373: '固定工位',
  i18nKey_2374: '勾选字段后，该字段在小程序,APP的用户可见',
  i18nKey_2375: '供应商',
  i18nKey_2376: '供配电系统',
  i18nKey_2377: '供配电',
  i18nKey_2378: '共有照明回路 $ 个',
  i18nKey_2379: '共享会议室',
  i18nKey_2380: '共享办公订单报表_',
  i18nKey_2381: '共识、共创、共担、共享。',
  i18nKey_2382: '共开门 $次',
  i18nKey_2383: '共$条，成功$条，错误$条',
  i18nKey_2384: '恭喜您，注册成功！',
  i18nKey_2385: '公有云',
  i18nKey_2386: '公司预订Top5',
  i18nKey_2387: '公区用电',
  i18nKey_2388: '公区仪表',
  i18nKey_2389: '公区能耗排名',
  i18nKey_2390: '公区能耗目标值不能大于总能耗目标值',
  i18nKey_2391: '公区能耗(上月)',
  i18nKey_2392: '公区名字',
  i18nKey_2393: '公区类型',
  i18nKey_2394: '公区',
  i18nKey_2395: '公共区域不可删除',
  i18nKey_2396: '公共配置',
  i18nKey_2397: '公安黑名单',
  i18nKey_2398: '公安',
  i18nKey_2399: '工作日',
  i18nKey_2400: '工作人员名称',
  i18nKey_2401: '工作人员电话',
  i18nKey_2402: '工作人员',
  i18nKey_2403: '工作单位',
  i18nKey_2404: '工种',
  i18nKey_2405: '工位状态',
  i18nKey_2406: '工位数量',
  i18nKey_2407: '工位首图',
  i18nKey_2408: '工位类型',
  i18nKey_2409: '工位简介',
  i18nKey_2410: '工位价格的单位需跟最少起租的单位保持一致！',
  i18nKey_2411: '工位价格',
  i18nKey_2412: '工位编号的个数需与工位数量一致！',
  i18nKey_2413: '工位编号',
  i18nKey_2414: '工商执照注册号/统一社会信用代码',
  i18nKey_2415: '工号',
  i18nKey_2416: '工单状态',
  i18nKey_2417: '工单责任人',
  i18nKey_2418: '工单委派人',
  i18nKey_2419: '工单派发成功',
  i18nKey_2420: '工单编号',
  i18nKey_2421: '更新时间为昨日统计时间',
  i18nKey_2422: '更新时间',
  i18nKey_2423: '更新失败！',
  i18nKey_2424: '更新成功',
  i18nKey_2425: '更多图层',
  i18nKey_2426: '更多任务',
  i18nKey_2427: '给排水',
  i18nKey_2428: '各类人群可正常活动',
  i18nKey_2429: '个人积分',
  i18nKey_2430: '个人',
  i18nKey_2431: '个/每个企业',
  i18nKey_2432: '个',
  i18nKey_2433: '告警阈值',
  i18nKey_2434: '告警事件及跟进状态',
  i18nKey_2435: '告警事件关联设备不存在',
  i18nKey_2436: '告警事件概况',
  i18nKey_2437: '告警事件',
  i18nKey_2438: '告警描述',
  i18nKey_2439: '告警列表',
  i18nKey_2440: '告警类型',
  i18nKey_2441: '告警来源',
  i18nKey_2442: '告警分类统计',
  i18nKey_2443: '告警次数',
  i18nKey_2444: '告警查看',
  i18nKey_2445: '高亮',
  i18nKey_2446: '高级',
  i18nKey_2447: '感应微亮',
  i18nKey_2448: '感应亮度',
  i18nKey_2449: '感应开关',
  i18nKey_2450: '感应后保持亮度时长',
  i18nKey_2451: '感应点亮时间',
  i18nKey_2452: '该事件状态为挂起，暂时不能上报',
  i18nKey_2453: '该事件状态为待处理，暂时不能上报',
  i18nKey_2454: '该事件状态为处理中，暂时不能上报',
  i18nKey_2455: '该任务有未处理事件，暂时无法上报',
  i18nKey_2456: '该人员在这个时间段没有行动轨迹',
  i18nKey_2457: '该人员没有图像，不能进行布控',
  i18nKey_2458: '该会议室已被停用，无法执行',
  i18nKey_2459: '该会议室下没有可预订的日期',
  i18nKey_2460: '该地区暂无停车场',
  i18nKey_2461: '该乘车班次已启用，请先暂停后再操作！',
  i18nKey_2462: '该场景已被停用，无法执行',
  i18nKey_2463: '该布控已停止，不能修改',
  i18nKey_2464: '该布控已启动，不能修改',
  i18nKey_2465: '该布控车辆数据已被删除，不支持修改或重新布控，请重新创建布控',
  i18nKey_2466: '附件仅支持新版excel、PDF、jpg、rar、zip格式',
  i18nKey_2467: '附件',
  i18nKey_2468: '附加设施数量',
  i18nKey_2469: '附加设施名称已存在！',
  i18nKey_2470: '附加设施名称',
  i18nKey_2471: '负荷情况',
  i18nKey_2472: '付款方式',
  i18nKey_2473: '付费模式',
  i18nKey_2474: '付费方式',
  i18nKey_2475: '辅助视频设置',
  i18nKey_2476: '服务资源',
  i18nKey_2477: '服务商只能由中英文、数字及_组成',
  i18nKey_2478: '服务商长度不能超过50个字符',
  i18nKey_2479: '服务商',
  i18nKey_2480: '服务区域 不能为空',
  i18nKey_2481: '服务区域',
  i18nKey_2482: '服务器信息',
  i18nKey_2483: '服务厂商',
  i18nKey_2484: '份数',
  i18nKey_2485: '份',
  i18nKey_2486: '粉色',
  i18nKey_2487: '分钟不能为空',
  i18nKey_2488: '分钟不能低于2分钟且不能大于10分钟,且不能有空格！',
  i18nKey_2489: '分钟',
  i18nKey_2490: '分项占比不能大于1',
  i18nKey_2491: '分项占比',
  i18nKey_2492: '分项维护',
  i18nKey_2493: '分项统计',
  i18nKey_2494: '分项累计能耗(kWh)',
  i18nKey_2495: '分项电表数据',
  i18nKey_2496: '分析指标：',
  i18nKey_2497: '分析',
  i18nKey_2498: '分时段客流',
  i18nKey_2499: '分区位置',
  i18nKey_2500: '分区',
  i18nKey_2501: '分期编码',
  i18nKey_2502: '分流情况',
  i18nKey_2503: '分类不能为空',
  i18nKey_2504: '分类',
  i18nKey_2505: '分',
  i18nKey_2506: '非公区',
  i18nKey_2507: '非工作日',
  i18nKey_2508: '非车位模型',
  i18nKey_2509: '非必填',
  i18nKey_2510: '放电总量',
  i18nKey_2511: '放电量(kWh)',
  i18nKey_2512: '访问来源',
  i18nKey_2513: '访客状态',
  i18nKey_2514: '访客预约',
  i18nKey_2515: '访客邀约信息录入显示内容',
  i18nKey_2516: '访客邀约',
  i18nKey_2517: '访客邀请统计(近30天)',
  i18nKey_2518: '访客姓名不能为特殊字符',
  i18nKey_2519: '访客姓名不能为空',
  i18nKey_2520: '访客姓名',
  i18nKey_2521: '访客信息不能为特殊字符',
  i18nKey_2522: '访客通行方式',
  i18nKey_2523: '访客数量',
  i18nKey_2524: '访客人员无该操作权限',
  i18nKey_2525: '访客人数统计(按园区)',
  i18nKey_2526: '访客人数-公司排行',
  i18nKey_2527: '访客人数(人)',
  i18nKey_2528: '访客人数',
  i18nKey_2529: '访客来访人数',
  i18nKey_2530: '访客管理',
  i18nKey_2531: '访客电话不能为空',
  i18nKey_2532: '访客电话',
  i18nKey_2533: '访客到达时间',
  i18nKey_2534: '访客达到提醒',
  i18nKey_2535: '访客拜访事由',
  i18nKey_2536: '访客',
  i18nKey_2537: '房屋信息',
  i18nKey_2538: '房屋类型',
  i18nKey_2539: '房屋地址',
  i18nKey_2540: '房屋ID',
  i18nKey_2541: '房间用电',
  i18nKey_2542: '商/租户仪表',
  i18nKey_2543: '房间位置',
  i18nKey_2544: '房间数',
  i18nKey_2545: '房间名称',
  i18nKey_2546: '房间号',
  i18nKey_2547: '房地产',
  i18nKey_2548: '防火分区',
  i18nKey_2549: '返回登录',
  i18nKey_2550: '返回',
  i18nKey_2551: '法人身份证',
  i18nKey_2552: '法定代表人',
  i18nKey_2553: '发生位置',
  i18nKey_2554: '发生数量',
  i18nKey_2555: '发生时间',
  i18nKey_2556: '发行数量(个)',
  i18nKey_2557: '发行积分',
  i18nKey_2558: '发行',
  i18nKey_2559: '二维码已失效',
  i18nKey_2560: '二维码通行',
  i18nKey_2561: '二维码刷新时间必须在1至1439分钟之间',
  i18nKey_2562: '二维码刷新时间',
  i18nKey_2563: '二维码',
  i18nKey_2564: '二亮一循环亮',
  i18nKey_2565: '二类',
  i18nKey_2566: '二级部门',
  i18nKey_2567: '二级',
  i18nKey_2568: '二',
  i18nKey_2569: '儿童房',
  i18nKey_2570: '额定容量(kWh)',
  i18nKey_2571: '额定功率(kW)',
  i18nKey_2572: '吨',
  i18nKey_2573: '兑换',
  i18nKey_2574: '对象名称',
  i18nKey_2575: '对外型号只能为50个字符以内的中文、字母及下划线',
  i18nKey_2576: '对外型号不能为空',
  i18nKey_2577: '对外型号',
  i18nKey_2578: '对不起，您暂无任何权限，请联系管理员',
  i18nKey_2579: '断路器配置',
  i18nKey_2580: '断路器开关时间配置规则',
  i18nKey_2581: '短信',
  i18nKey_2582: '端口号',
  i18nKey_2583: '读数时间点',
  i18nKey_2584: '读数(kWh)',
  i18nKey_2585: '读数',
  i18nKey_2586: '读取方案',
  i18nKey_2587: '斗士身后无退路,勇气前方无险途。',
  i18nKey_2588: '冻结',
  i18nKey_2589: '动态密码',
  i18nKey_2590: '动力用电',
  i18nKey_2591: '动画加载失败',
  i18nKey_2592: '东北',
  i18nKey_2593: '订单状态',
  i18nKey_2594: '订单支付状态',
  i18nKey_2595: '订单时间范围',
  i18nKey_2596: '订单金额',
  i18nKey_2597: '订单编号/空间名称',
  i18nKey_2598: '订单编号/会议室名称',
  i18nKey_2599: '订单编号',
  i18nKey_2600: '订餐营收',
  i18nKey_2601: '店铺销量Top5',
  i18nKey_2602: '电子表',
  i18nKey_2603: '电梯总数',
  i18nKey_2604: '电梯运行记录',
  i18nKey_2605: '电梯信息',
  i18nKey_2606: '电梯图片',
  i18nKey_2607: '电梯统计',
  i18nKey_2608: '电梯名称：',
  i18nKey_2609: '电梯名称',
  i18nKey_2610: '电梯类型：',
  i18nKey_2611: '电梯类型',
  i18nKey_2612: '电梯地址',
  i18nKey_2613: '电梯ID：',
  i18nKey_2614: '电梯',
  i18nKey_2615: '电损变压器总耗损(kWh)',
  i18nKey_2616: '电损',
  i18nKey_2617: '电器一级分类',
  i18nKey_2618: '电能耗（kWh）',
  i18nKey_2619: '电缆平方数',
  i18nKey_2620: '电话',
  i18nKey_2621: '电费已出账单金额累计',
  i18nKey_2622: '电费欠费商户',
  i18nKey_2623: '电费充值未抵扣金额',
  i18nKey_2624: '电费充值金额累计',
  i18nKey_2625: '电池类型',
  i18nKey_2626: '电表预警阈值：',
  i18nKey_2627: '电表序号',
  i18nKey_2628: '电表名称不能为空',
  i18nKey_2629: '电表名称',
  i18nKey_2630: '电表分项维护成功！',
  i18nKey_2631: '电表度数详细记录',
  i18nKey_2632: '电表编号或者分项占比不能为空',
  i18nKey_2633: '电表编号',
  i18nKey_2634: '点位类型',
  i18nKey_2635: '点击重试',
  i18nKey_2636: '点击停止时才允许修改，点击启用后策略半小时内生效',
  i18nKey_2637: '点击查看',
  i18nKey_2638: '第三方支付服务费(元)',
  i18nKey_2639: '第三方用户',
  i18nKey_2640: '第三方访客',
  i18nKey_2641: '第三方ID',
  i18nKey_2642: '第....周',
  i18nKey_2643: '第$周',
  i18nKey_2644: '第$个策略中亮灯规则$的开启时间不能为空',
  i18nKey_2645: '第$个策略的生效时间不能为空',
  i18nKey_2646: '地址经纬度输入需用英文逗号分隔,且不能大于20个字符',
  i18nKey_2647: '地址经纬度',
  i18nKey_2648: '地址管理',
  i18nKey_2649: '地址不支持特殊字符（允许汉字、字母和数字）',
  i18nKey_2650: '低电压告警',
  i18nKey_2651: '等于',
  i18nKey_2652: '等级',
  i18nKey_2653: '登记时间',
  i18nKey_2654: '灯控开关',
  i18nKey_2655: '灯具数量',
  i18nKey_2656: '灯感应开关',
  i18nKey_2657: '道闸二维码读头',
  i18nKey_2658: '道闸PAD',
  i18nKey_2659: '到账情况',
  i18nKey_2660: '到账金额',
  i18nKey_2661: '到访客流',
  i18nKey_2662: '导入消防分区',
  i18nKey_2663: '导入失败，第$行数据有错误，请检查，稍后再次提交导入',
  i18nKey_2664: '导出条件',
  i18nKey_2665: '导出失败，支持最大导出数据10000条',
  i18nKey_2666: '导出失败，没有可以导出的数据',
  i18nKey_2667: '导出失败，当前页面无门禁记录！',
  i18nKey_2668: '导出失败，当前页面无订单记录！',
  i18nKey_2669: '导出失败，当前页面无乘车记录！',
  i18nKey_2670: '导出失败，当前页面没有水电缴费记录',
  i18nKey_2671: '导出失败，当前页面没有访客记录',
  i18nKey_2672: '导出失败',
  i18nKey_2673: '导出轨迹成功',
  i18nKey_2674: '当月套餐收费',
  i18nKey_2675: '当月收入分析',
  i18nKey_2676: '当月能耗(kWh)',
  i18nKey_2677: '当月公区能耗(kWh)',
  i18nKey_2678: '当月报警数（次）',
  i18nKey_2679: '当月',
  i18nKey_2680: '当天',
  i18nKey_2681: '当日新增告警',
  i18nKey_2682: '当日能耗(kWh)',
  i18nKey_2683: '当日临停收费',
  i18nKey_2684: '当日公区能耗(kWh)',
  i18nKey_2685: '当日打卡人数',
  i18nKey_2686: '当日',
  i18nKey_2687: '当前状态',
  i18nKey_2688: '当前暂无节目，请先设置节目！',
  i18nKey_2689: '当前运行参数',
  i18nKey_2690: '当前移动到',
  i18nKey_2691: '当前页面无订单记录，不需要执行导出操作！',
  i18nKey_2692: '当前页面为',
  i18nKey_2693: '当前位置没有房间,请选择房间!',
  i18nKey_2694: '当前未添加审批人，请添加审批人',
  i18nKey_2695: '当前数据资源',
  i18nKey_2696: '当前时间',
  i18nKey_2697: '当前浏览器不支持websocket',
  i18nKey_2698: '当前功率',
  i18nKey_2699: '当前服务资源',
  i18nKey_2700: '当前导入文件校验有误，请在本地修改后重新导入！',
  i18nKey_2701: '当前车位使用率',
  i18nKey_2702: '当前场内',
  i18nKey_2703: '当前操作资源',
  i18nKey_2704: '当前表底',
  i18nKey_2705: '当前Menu菜单资源',
  i18nKey_2706: '当年',
  i18nKey_2707: '单元：',
  i18nKey_2708: '单元',
  i18nKey_2709: '单位每平方米用能',
  i18nKey_2710: '单位：元',
  i18nKey_2711: '单位：人',
  i18nKey_2712: '单位：次',
  i18nKey_2713: '单位:kWh',
  i18nKey_2714: '单位: 小时',
  i18nKey_2715: '单位: 份',
  i18nKey_2716: '单位: 分钟',
  i18nKey_2717: '单价/次数',
  i18nKey_2718: '单价(元/小时)',
  i18nKey_2719: '单个轮巡时间',
  i18nKey_2720: '单次停留最长时长及日期',
  i18nKey_2721: '单次扣费',
  i18nKey_2722: '待支付',
  i18nKey_2723: '待接收',
  i18nKey_2724: '待机亮度',
  i18nKey_2725: '待处理告警数',
  i18nKey_2726: '待处理',
  i18nKey_2727: '待操作',
  i18nKey_2728: '待安装',
  i18nKey_2729: '代表异常',
  i18nKey_2730: '代表数据异常',
  i18nKey_2731: '大于或等于',
  i18nKey_2732: '大于400',
  i18nKey_2733: '大于2',
  i18nKey_2734: '大于',
  i18nKey_2735: '大企业',
  i18nKey_2736: '大屏位置',
  i18nKey_2737: '大屏名称',
  i18nKey_2738: '大屏播放设置',
  i18nKey_2739: '大道当然,合伙奋斗。',
  i18nKey_2740: '打开告警',
  i18nKey_2741: '打卡一级分类',
  i18nKey_2742: '打卡失败',
  i18nKey_2743: '打卡成功',
  i18nKey_2744: '搭乘公司Top5',
  i18nKey_2745: '从$到$，该故障重复发生了1次，累积时长$小时，可造成全年浪费',
  i18nKey_2746: '次卧',
  i18nKey_2747: '此操作将永久删除该文件，是否继续？',
  i18nKey_2748: '创建时间',
  i18nKey_2749: '创建人',
  i18nKey_2750: '创建模式选择',
  i18nKey_2751: '创建空间层级应至少到楼栋！',
  i18nKey_2752: '创建积分',
  i18nKey_2753: '创建房间',
  i18nKey_2754: '创建场景',
  i18nKey_2755: '创建',
  i18nKey_2756: '触发条件：',
  i18nKey_2757: '处理数量',
  i18nKey_2758: '处理时间',
  i18nKey_2759: '处理人',
  i18nKey_2760: '处理机制',
  i18nKey_2761: '储物间',
  i18nKey_2762: '储能统计',
  i18nKey_2763: '厨房',
  i18nKey_2764: '初始化时长',
  i18nKey_2765: '出现过$次违停',
  i18nKey_2766: '出入口繁忙程度',
  i18nKey_2767: '出车位时间',
  i18nKey_2768: '出场时间',
  i18nKey_2769: '出厂日期',
  i18nKey_2770: '充值类型',
  i18nKey_2771: '充值金额累计',
  i18nKey_2772: '充值公司',
  i18nKey_2773: '充值',
  i18nKey_2774: '充电总量',
  i18nKey_2775: '充电量(kWh)',
  i18nKey_2776: '乘车支付状态',
  i18nKey_2777: '乘车统计',
  i18nKey_2778: '乘车时间',
  i18nKey_2779: '城市',
  i18nKey_2780: '承租行业',
  i18nKey_2781: '承租方名称不允许为空',
  i18nKey_2782: '承租方名称',
  i18nKey_2783: '承租方行业',
  i18nKey_2784: '承租方',
  i18nKey_2785: '成功退款时间',
  i18nKey_2786: '成都',
  i18nKey_2787: '成大事不在于力量的大小,而在于能坚持多久。',
  i18nKey_2788: '车主姓名',
  i18nKey_2789: '车主类型',
  i18nKey_2790: '车主电话',
  i18nKey_2791: '车位总数',
  i18nKey_2792: '车位占用数',
  i18nKey_2793: '车位使用统计',
  i18nKey_2794: '车位使用率统计',
  i18nKey_2795: '车位查询',
  i18nKey_2796: '车牌指数',
  i18nKey_2797: '车牌和颜色超过6个选项 已为您按最优显示',
  i18nKey_2798: '车牌和品牌超过6个选项 已为您按最优显示',
  i18nKey_2799: '车牌号能不能为空',
  i18nKey_2800: '车牌号码不能为空',
  i18nKey_2801: '车牌号码',
  i18nKey_2802: '车牌超过6个选项 已为您按最优显示',
  i18nKey_2803: '车牌查询',
  i18nKey_2804: '车牌，品牌和颜色超过6个选项 已为您按最优显示',
  i18nKey_2805: '车流统计',
  i18nKey_2806: '车辆颜色',
  i18nKey_2807: '车辆限行',
  i18nKey_2808: '车辆套餐数量和停车费用',
  i18nKey_2809: '车辆套餐数量(辆)',
  i18nKey_2810: '车辆数据信息',
  i18nKey_2811: '车辆属地',
  i18nKey_2812: '车辆驶出记录',
  i18nKey_2813: '车辆色系',
  i18nKey_2814: '车辆认证',
  i18nKey_2815: '车辆品牌',
  i18nKey_2816: '车辆分析',
  i18nKey_2817: '车辆分类全路径',
  i18nKey_2818: '车辆到达提醒',
  i18nKey_2819: '车辆标签',
  i18nKey_2820: '车辆',
  i18nKey_2821: '车次',
  i18nKey_2822: '车场限制',
  i18nKey_2823: '车场位置：',
  i18nKey_2824: '车场位置',
  i18nKey_2825: '车场名称：',
  i18nKey_2826: '车场名称',
  i18nKey_2827: '车场车位统计',
  i18nKey_2828: '车场',
  i18nKey_2829: '超过$次，不能办理月卡',
  i18nKey_2830: '超过 $ 次，不能进入车场',
  i18nKey_2831: '超出2000条最大导出限制',
  i18nKey_2832: '超出10条最大导出限制',
  i18nKey_2833: '抄读成功',
  i18nKey_2834: '抄读',
  i18nKey_2835: '抄表日期和抄表读数必须同时填写',
  i18nKey_2836: '抄表日期',
  i18nKey_2837: '抄表人',
  i18nKey_2838: '抄表期间不能为空',
  i18nKey_2839: '抄表期间',
  i18nKey_2840: '抄表明细记录',
  i18nKey_2841: '抄表明细',
  i18nKey_2842: '抄表导入',
  i18nKey_2843: '场内人数',
  i18nKey_2844: '场景图标',
  i18nKey_2845: '场景锁开关：',
  i18nKey_2846: '场景数',
  i18nKey_2847: '场景名称',
  i18nKey_2848: '场景ID',
  i18nKey_2849: '厂商名称不能为空',
  i18nKey_2850: '厂商名称',
  i18nKey_2851: '厂家',
  i18nKey_2852: '常驻人员',
  i18nKey_2853: '常规',
  i18nKey_2854: '产品名',
  i18nKey_2855: '产品',
  i18nKey_2856: '拆除告警',
  i18nKey_2857: '查询注册用户请输入完整姓名或手机号',
  i18nKey_2858: '查询未生效个数接口数据有误，请稍后再试',
  i18nKey_2859: '查询时间范围请限定在90天内',
  i18nKey_2860: '查询列表失败',
  i18nKey_2861: '查看折后价',
  i18nKey_2862: '查看预警阈值',
  i18nKey_2863: '查看限时折扣',
  i18nKey_2864: '查看图片',
  i18nKey_2865: '查看视频',
  i18nKey_2866: '查看摄像头',
  i18nKey_2867: '查看设备',
  i18nKey_2868: '查看轨迹',
  i18nKey_2869: '查看乘车班次',
  i18nKey_2870: '查看',
  i18nKey_2871: '策略名称不能为空',
  i18nKey_2872: '策略名称不能含有特殊字符',
  i18nKey_2873: '策略名称不能超过20个字符',
  i18nKey_2874: '策略名称',
  i18nKey_2875: '测量温度',
  i18nKey_2876: '测量时间',
  i18nKey_2877: '厕所',
  i18nKey_2878: '操作者',
  i18nKey_2879: '操作失败，请稍后再试',
  i18nKey_2880: '操作失败',
  i18nKey_2881: '操作建议',
  i18nKey_2882: '餐厅',
  i18nKey_2883: '参数',
  i18nKey_2884: '参考数据期间',
  i18nKey_2885: '菜品销量Top5',
  i18nKey_2886: '采集频率',
  i18nKey_2887: '部门：',
  i18nKey_2888: '布控状态',
  i18nKey_2889: '布控时间不能为空',
  i18nKey_2890: '布控时间必须大于等于当前时间',
  i18nKey_2891: '布控时段',
  i18nKey_2892: '布控摄像头',
  i18nKey_2893: '布控匹配',
  i18nKey_2894: '布控',
  i18nKey_2895: '不重复',
  i18nKey_2896: '不支持输入特殊字符，仅支持中英文数字',
  i18nKey_2897: '不限期限',
  i18nKey_2898: '不限次数',
  i18nKey_2899: '不确定告警',
  i18nKey_2900: '不能更改层级，当前层级为',
  i18nKey_2901: '不能导出空数据',
  i18nKey_2902: '不能导出超过10000条数据',
  i18nKey_2903: '不可移动',
  i18nKey_2904: '不可添加子级',
  i18nKey_2905: '不可删除',
  i18nKey_2906: '不可编辑',
  i18nKey_2907: '不等于',
  i18nKey_2908: '补录期间',
  i18nKey_2909: '补录读数',
  i18nKey_2910: '泊寓健康打卡_',
  i18nKey_2911: '泊梯中',
  i18nKey_2912: '播放设置：',
  i18nKey_2913: '播放窗口替换模式',
  i18nKey_2914: '播放',
  i18nKey_2915: '别墅',
  i18nKey_2916: '表格模板.xlsx',
  i18nKey_2917: '表底',
  i18nKey_2918: '标签筛选',
  i18nKey_2919: '变压器容量(kVA)',
  i18nKey_2920: '变更时间',
  i18nKey_2921: '编辑预警阈值',
  i18nKey_2922: '编辑',
  i18nKey_2923: '编号',
  i18nKey_2924: '必须为整数',
  i18nKey_2925: '必须为数字或两位有效小数！',
  i18nKey_2926: '本周',
  i18nKey_2927: '本月实际电能耗（KWh）',
  i18nKey_2928: '本月累计用能',
  i18nKey_2929: '本月客流概况',
  i18nKey_2930: '本月会议室预订',
  i18nKey_2931: '本月',
  i18nKey_2932: '本项用能',
  i18nKey_2933: '本期用水量（t）',
  i18nKey_2934: '本期用能',
  i18nKey_2935: '本期用电量（kWh）',
  i18nKey_2936: '本期用电量',
  i18nKey_2937: '本期未抄/已抄(个)',
  i18nKey_2938: '本期时间',
  i18nKey_2939: '本期单位平方米耗能',
  i18nKey_2940: '本期',
  i18nKey_2941: '本年',
  i18nKey_2942: '本级能耗',
  i18nKey_2943: '本地温度',
  i18nKey_2944: '本地湿度',
  i18nKey_2945: '本地上传，上传照片不超过3M',
  i18nKey_2946: '本地服务器',
  i18nKey_2947: '本地',
  i18nKey_2948: '本次还有$个表未录入, 是否要继续生效?',
  i18nKey_2949: '本次抄表时间不能为空',
  i18nKey_2950: '本次抄表日期',
  i18nKey_2951: '本次抄表读数用量远大于上次抄表读数用量，是否确认修改',
  i18nKey_2952: '本次抄表读数不能为空',
  i18nKey_2953: '本次抄表读数',
  i18nKey_2954: '被访公司总数(人)',
  i18nKey_2955: '倍率',
  i18nKey_2956: '备注名只能为20个字符以内的中文、字母及下划线',
  i18nKey_2957: '备注名',
  i18nKey_2958: '备用(个)',
  i18nKey_2959: '北京',
  i18nKey_2960: '报修类型',
  i18nKey_2961: '报修工单',
  i18nKey_2962: '报警',
  i18nKey_2963: '报废日期',
  i18nKey_2964: '报废',
  i18nKey_2965: '报表',
  i18nKey_2966: '保修日期',
  i18nKey_2967: '保洁',
  i18nKey_2968: '半天单价（元/4小时）',
  i18nKey_2969: '办公空间订单详情',
  i18nKey_2970: '办公电话',
  i18nKey_2971: '班次编号',
  i18nKey_2972: '班车',
  i18nKey_2973: '拜访事由：',
  i18nKey_2974: '白色',
  i18nKey_2975: '白名单',
  i18nKey_2976: '巴士订单报表_',
  i18nKey_2977: '巴士乘车记录报表',
  i18nKey_2978: '巴士班次',
  i18nKey_2979: '按周',
  i18nKey_2980: '按月',
  i18nKey_2981: '按相似度',
  i18nKey_2982: '按时间',
  i18nKey_2983: '按时',
  i18nKey_2984: '按日',
  i18nKey_2985: '按钮资源',
  i18nKey_2986: '按年',
  i18nKey_2987: '按分项查看',
  i18nKey_2988: '安装中',
  i18nKey_2989: '安装位置',
  i18nKey_2990: '安装时间',
  i18nKey_2991: '安装工',
  i18nKey_2992: '安装RFID资产数',
  i18nKey_2993: '安全事件趋势',
  i18nKey_2994: '安防态势',
  i18nKey_2995: '安防事件类型占比',
  i18nKey_2996: '安保人员',
  i18nKey_2997: 'VCM类型',
  i18nKey_2998: 'URL类型',
  i18nKey_2999: 'RFID使用率（按类型）',
  i18nKey_3000: 'RFID使用率',
  i18nKey_3001: 'RFID编号',
  i18nKey_3002: 'PID编号',
  i18nKey_3003: 'Menu资源',
  i18nKey_3004: 'MENU',
  i18nKey_3005: 'MAC地址',
  i18nKey_3006: 'KPI上限',
  i18nKey_3007: 'IT编码和业务编码只能为字母、数字和下划线的组合！',
  i18nKey_3008: 'IT编码',
  i18nKey_3009: '智慧园区运营平台一级设备分类名称',
  i18nKey_3010: '智慧园区运营平台一级设备分类编码',
  i18nKey_3011: '智慧园区运营平台三级设备分类名称',
  i18nKey_3012: '智慧园区运营平台三级设备分类编码',
  i18nKey_3013: '智慧园区运营平台空间模型编码',
  i18nKey_3014: '智慧园区运营平台二级设备分类名称',
  i18nKey_3015: '智慧园区运营平台二级设备分类编码',
  i18nKey_3016: '智慧园区运营平台(Intelligence Operation Center或智慧运营中心)服务协议',
  i18nKey_3017: 'GIS坐标类型',
  i18nKey_3018: 'GIS坐标',
  i18nKey_3019: 'GIS同步中',
  i18nKey_3020: 'GIS同步',
  i18nKey_3021: 'Echarts图表',
  i18nKey_3022: 'C相电流（A）',
  i18nKey_3023: 'B相电压（V）',
  i18nKey_3024: 'Banner图',
  i18nKey_3025: 'A相电流（A）',
  i18nKey_3026: 'AI诊断处理',
  i18nKey_3027: '9个月',
  i18nKey_3028: '8~20位字母、数字和特殊字符中至少2种的组合',
  i18nKey_3029: '8~20位数字、字母、部分特殊字符',
  i18nKey_3030: '6个月折扣',
  i18nKey_3031: '6个月',
  i18nKey_3032: '3个月折扣',
  i18nKey_3033: '3个月',
  i18nKey_3034: '1年及以上',
  i18nKey_3035: '0点读数（kWh）',
  i18nKey_3036: '0点读数(kWh)',
  i18nKey_3037: '《隐私政策》',
  i18nKey_3038: '《服务协议》',
  i18nKey_3039: '*只支持png,gif,jpg格式图片且不大于3M',
  i18nKey_3040: '(总能耗kWh)',
  i18nKey_3041: '（自动换算价格）',
  i18nKey_3042: '(元)',
  i18nKey_3043: '(数量)',
  i18nKey_3044: '(例如:P85)',
  i18nKey_3045: '(电损占比)',
  i18nKey_3046: '（必填）',
  i18nKey_3047: '$年在整个项目中，已累计为您发现了共计',
  i18nKey_3048: '$年实际值',
  i18nKey_3049: '$年目标值',
  i18nKey_3050: '$个回路开启失败',
  i18nKey_3051: '$个回路开启成功',
  i18nKey_3052: '一月',
  i18nKey_3053: '五月',
  i18nKey_3054: '四月',
  i18nKey_3055: '十月',
  i18nKey_3056: '十一月',
  i18nKey_3057: '十二月',
  i18nKey_3058: '三月',
  i18nKey_3059: '请输入合法的GIS坐标，如(20.17N, 134.53W)',
  i18nKey_3060: '七月',
  i18nKey_3061: '六月',
  i18nKey_3062: '九月',
  i18nKey_3063: '二月',
  i18nKey_3064: '八月',
  i18nKey_3065: 'GIS坐标已存在，请重新输入',
  i18nKey_web_1: '示例',
  i18nKey_web_2: '选取文件',
  i18nKey_web_3: '上传所有文件',
  i18nKey_web_4: '点击文件获取下载路径',
  i18nKey_web_5: '卫星效果',
  i18nKey_web_6: '城市效果',
  i18nKey_web_7: '模拟',
  i18nKey_web_8: '事件生成器',
  i18nKey_web_9: '时间：',
  i18nKey_web_10: '事件标题：',
  i18nKey_web_11: '事件描述：',
  i18nKey_web_12: '事件地址：',
  i18nKey_web_13: '告警设备：',
  i18nKey_web_14: '事件来源：',
  i18nKey_web_15: '访客系统',
  i18nKey_web_16: '门禁',
  i18nKey_web_17: '消防',
  i18nKey_web_18: '周界',
  i18nKey_web_19: '事件级别：',
  i18nKey_web_20: '重要告警',
  i18nKey_web_21: '事件类型：',
  i18nKey_web_22: '消防事件',
  i18nKey_web_23: '安全事件',
  i18nKey_web_24: '生成事件',
  i18nKey_web_25: '万科科技公司',
  i18nKey_web_26: '版权所有',
  i18nKey_web_27: '技术支持电话：000-00000000',
  i18nKey_web_28: '第',
  i18nKey_web_29: '当前位置',
  i18nKey_web_30: '资产',
  i18nKey_web_31: '信息',
  i18nKey_web_32: '人员修改取消',
  i18nKey_web_33: '视频加载中',
  i18nKey_web_34: '请稍后',
  i18nKey_web_35: '视频缓冲中',
  i18nKey_web_36: '取',
  i18nKey_web_37: '消',
  i18nKey_web_38: '确认',
  i18nKey_web_39: '注意：适用于列表页无嵌套组件的表格页面',
  i18nKey_web_40: '需要传入类名',
  i18nKey_web_41: '以查找所需',
  i18nKey_web_42: '元素',
  i18nKey_web_43: '原',
  i18nKey_web_44: '需要设置初始值属性',
  i18nKey_web_45: '需要设置最外层标签',
  i18nKey_web_46: '误判',
  i18nKey_web_47: '上报',
  i18nKey_web_48: '时间',
  i18nKey_web_49: '地点',
  i18nKey_web_50: '万科成都园区某某楼',
  i18nKey_web_51: '视频巡逻人工上报',
  i18nKey_web_52: '级别',
  i18nKey_web_53: '红色紧急警告',
  i18nKey_web_54: '橙色紧急警告',
  i18nKey_web_55: '黄色紧急警告',
  i18nKey_web_56: '蓝色紧急警告',
  i18nKey_web_57: '描述',
  i18nKey_web_58: '特别重大事故',
  i18nKey_web_59: '重大事故',
  i18nKey_web_60: '较大事故',
  i18nKey_web_61: '一般事故',
  i18nKey_web_62: '不能为空，请输入内容。',
  i18nKey_web_63: '请输入姓名或工号',
  i18nKey_web_64: '暂无数据',
  i18nKey_web_65: '去修改',
  i18nKey_web_66: '任务',
  i18nKey_web_67: '分项累计能耗',
  i18nKey_web_68: '选择摄像头',
  i18nKey_web_69: '安保岗亭',
  i18nKey_web_70: '单位：',
  i18nKey_web_71: '单位：件',
  i18nKey_web_72: '单位：人次',
  i18nKey_web_73: '相关数据异常，无法勾选',
  i18nKey_web_74: '查看详情',
  i18nKey_web_75: '北门',
  i18nKey_web_76: '东门',
  i18nKey_web_77: '物业保安',
  i18nKey_web_78: '单饼图统计',
  i18nKey_web_79: '最小化',
  i18nKey_web_80: '最大化',
  i18nKey_web_81: '停车场未挂载模型，请联系管理员挂载模型！',
  i18nKey_web_82: '最近一天',
  i18nKey_web_83: '选择关联设备',
  i18nKey_web_84: '设备编号',
  i18nKey_web_85: '公区耗能',
  i18nKey_web_86: '后一天',
  i18nKey_web_87: '去年',
  i18nKey_web_88: '总电耗',
  i18nKey_web_89: '公区电耗',
  i18nKey_web_90: '公区-',
  i18nKey_web_91: '占比',
  i18nKey_web_92: '较',
  i18nKey_web_93: '同期',
  i18nKey_web_94: '单位',
  i18nKey_web_95: '删除图片',
  i18nKey_web_96: '提示：只支持',
  i18nKey_web_97: '格式的图片上传，上传图片大小不超过3',
  i18nKey_web_98: '图片大小',
  i18nKey_web_99: '图片类型',
  i18nKey_web_100: '生成时间',
  i18nKey_web_101: '上传图片不能大于3',
  i18nKey_web_102: '请重新上传',
  i18nKey_web_103: '数量',
  i18nKey_web_104: '累计充电总量',
  i18nKey_web_105: '累计放电总量',
  i18nKey_web_106: '请输入设备名称',
  i18nKey_web_107: '已选分项',
  i18nKey_web_108: '监测区间段',
  i18nKey_web_109: '请输入最小值',
  i18nKey_web_110: '请输入最大值',
  i18nKey_web_111: '保存成功！',
  i18nKey_web_112: '完成',
  i18nKey_web_113: '选择关联监测仪',
  i18nKey_web_114: '选择关联烟感',
  i18nKey_web_115: '业务类型',
  i18nKey_web_116: '电话号码',
  i18nKey_web_117: '请输入电话号码',
  i18nKey_web_118: '新增',
  i18nKey_web_119: '策略名称由20个字符以内、连接线、下划线组成',
  i18nKey_web_120: '监测时间段',
  i18nKey_web_121: '人流量',
  i18nKey_web_122: '吸烟监测',
  i18nKey_web_123: '监测阀值',
  i18nKey_web_124: '关联监测仪',
  i18nKey_web_125: '请选择监测仪',
  i18nKey_web_126: '人数',
  i18nKey_web_127: '人数只能输入大于0小于等于99999的正整数',
  i18nKey_web_128: '烟雾浓度报警阀值',
  i18nKey_web_129: '关联烟感',
  i18nKey_web_130: '请选择烟感',
  i18nKey_web_131: '请输入报警阀值',
  i18nKey_web_132: '阀值只能输入大于0小于等于5000的正整数',
  i18nKey_web_133: '这是一个提示',
  i18nKey_web_134: '温馨提示',
  i18nKey_web_135: '浓度',
  i18nKey_web_136: '中度烟雾',
  i18nKey_web_137: '香烟',
  i18nKey_web_138: '下载图片',
  i18nKey_web_139: '查看告警',
  i18nKey_web_140: '暂时无符合条件的数据',
  i18nKey_web_141: '请输入描述',
  i18nKey_web_142: '视频巡逻手工上传',
  i18nKey_web_143: '工单派发',
  i18nKey_web_144: '选择人员',
  i18nKey_web_145: '派发工单',
  i18nKey_web_146: '输入姓名或工号',
  i18nKey_web_147: '告警名称',
  i18nKey_web_148: '告警状态',
  i18nKey_web_149: '告警等级',
  i18nKey_web_150: '告警时间',
  i18nKey_web_151: '责任人',
  i18nKey_web_152: '计划开始时间',
  i18nKey_web_153: '请选择计划开始时间',
  i18nKey_web_154: '计划结束时间',
  i18nKey_web_155: '请选择计划结束时间',
  i18nKey_web_156: '告警位置',
  i18nKey_web_157: '上一步',
  i18nKey_web_158: '派发任务',
  i18nKey_web_159: '必须选择一个人员',
  i18nKey_web_160: '新建工单',
  i18nKey_web_161: '工单类型',
  i18nKey_web_162: '任务详情描述',
  i18nKey_web_163: '请输入任务详情描述',
  i18nKey_web_164: '关联设备',
  i18nKey_web_165: '请选择关联设备',
  i18nKey_web_166: '任务等级',
  i18nKey_web_167: '请选择任务等级',
  i18nKey_web_168: '请选择责任人',
  i18nKey_web_169: '请选择发生时间',
  i18nKey_web_170: '请选择工单委派人',
  i18nKey_web_171: '现场联系人',
  i18nKey_web_172: '请选择现场联系人',
  i18nKey_web_173: '新建成功',
  i18nKey_web_174: '手工填单',
  i18nKey_web_175: '任务详情描述不能为空',
  i18nKey_web_176: '长度在',
  i18nKey_web_177: '到',
  i18nKey_web_178: '个字符',
  i18nKey_web_179: '关联设施',
  i18nKey_web_180: '新建工单失败',
  i18nKey_web_181: '工单',
  i18nKey_web_182: '工单派发完成',
  i18nKey_web_183: '无数据',
  i18nKey_web_184: '此设备无上级设备',
  i18nKey_web_185: '请选择地点',
  i18nKey_web_186: '请输入工单委派人',
  i18nKey_web_187: '请输入现场联系人',
  i18nKey_web_188: '选项1',
  i18nKey_web_189: '黄金糕',
  i18nKey_web_190: '选项2',
  i18nKey_web_191: '双皮奶',
  i18nKey_web_192: '选项3',
  i18nKey_web_193: '蚵仔煎',
  i18nKey_web_194: '选项4',
  i18nKey_web_195: '龙须面',
  i18nKey_web_196: '实际开始时间',
  i18nKey_web_197: '实际结束时间',
  i18nKey_web_198: '工单处理时长',
  i18nKey_web_199: '巡查人员详情',
  i18nKey_web_200: '职务',
  i18nKey_web_201: '在岗情况',
  i18nKey_web_202: '工单总数',
  i18nKey_web_203: '处理中',
  i18nKey_web_204: '事件动态',
  i18nKey_web_205: '通知人员，疏散楼宇人员',
  i18nKey_web_206: '拨打119，120',
  i18nKey_web_207: '与应急小组取得联系，开始部署撤离',
  i18nKey_web_208: '成都园区1栋1楼烟感器发生报警',
  i18nKey_web_209: '本地上传',
  i18nKey_web_210: '从人员库选择',
  i18nKey_web_211: '上传到服务器',
  i18nKey_web_212: '人员',
  i18nKey_web_213: '高飞',
  i18nKey_web_214: '高正为',
  i18nKey_web_215: '高林飞',
  i18nKey_web_216: '李创业',
  i18nKey_web_217: '姚赟',
  i18nKey_web_218: '的头像',
  i18nKey_web_219: '标签',
  i18nKey_web_220: '输入名称或编号',
  i18nKey_web_221: '烟感',
  i18nKey_web_222: '更多图层后台返回',
  i18nKey_web_223: '码',
  i18nKey_web_224: '后台返回',
  i18nKey_web_225: '成都万科园区-1栋一层',
  i18nKey_web_226: '区空调新风机组',
  i18nKey_web_227: '级',
  i18nKey_web_228: '制冷系统',
  i18nKey_web_229: '所属区域',
  i18nKey_web_230: '成都万科园区',
  i18nKey_web_231: '所属系统',
  i18nKey_web_232: '暖通系统',
  i18nKey_web_233: '林生',
  i18nKey_web_234: '子类',
  i18nKey_web_235: '子类占位符',
  i18nKey_web_236: '设施描述占位符设施描述占位符设施描述占位符设施描述占位符设施描述占位符设施描述占位符设施描述占位符设施描述占位符设施描述占位符设施描述占位符设施描述占位符设施描述占位符设施描述占位符设施描述占位符设施描述占位符设施描述占位符',
  i18nKey_web_237: '新增任务',
  i18nKey_web_238: '任务描述',
  i18nKey_web_239: '米内',
  i18nKey_web_240: '取消关联',
  i18nKey_web_241: '已关联',
  i18nKey_web_242: '事件关联摄像头',
  i18nKey_web_243: '周围摄像头',
  i18nKey_web_244: '关联摄像头（',
  i18nKey_web_245: '处理日志',
  i18nKey_web_246: '通知员工，疏散楼宇人员',
  i18nKey_web_247: '通知员工，疏散楼宇人员详情',
  i18nKey_web_248: '创建人：',
  i18nKey_web_249: '大',
  i18nKey_web_250: '小：',
  i18nKey_web_251: '事件发生时间',
  i18nKey_web_252: '事件发生地点',
  i18nKey_web_253: '已接收',
  i18nKey_web_254: '重新指派',
  i18nKey_web_255: '任务关闭时间',
  i18nKey_web_256: '任务指派时间',
  i18nKey_web_257: '任务指派人',
  i18nKey_web_258: '现场反馈',
  i18nKey_web_259: '任务备注',
  i18nKey_web_260: '任务列表',
  i18nKey_web_261: '查看更多',
  i18nKey_web_262: '综合态势-全国',
  i18nKey_web_263: '张统计卡片',
  i18nKey_web_264: '综合态势',
  i18nKey_web_265: '安防事件统计',
  i18nKey_web_266: '综合态势展示',
  i18nKey_web_267: '请选择不少于',
  i18nKey_web_268: '请输入电表名称',
  i18nKey_web_269: '电表',
  i18nKey_web_270: '水表',
  i18nKey_web_271: '请输入水表名称',
  i18nKey_web_272: '今日用能',
  i18nKey_web_273: '本月环比',
  i18nKey_web_274: '本月同比',
  i18nKey_web_275: '租户用电',
  i18nKey_web_276: '照明用电',
  i18nKey_web_277: '日总能耗',
  i18nKey_web_278: '输入事件编号',
  i18nKey_web_279: '确认密码',
  i18nKey_web_280: '密码',
  i18nKey_web_281: '请选择工单派发人员',
  i18nKey_web_282: '计划开始时间不能为空',
  i18nKey_web_283: '计划结束时间不能为空',
  i18nKey_web_284: '网络加载异常',
  i18nKey_web_285: '手机号码',
  i18nKey_web_286: '邮箱',
  i18nKey_web_287: '年龄',
  i18nKey_web_288: '行业',
  i18nKey_web_289: '迎宾',
  i18nKey_web_290: '迎宾屏',
  i18nKey_web_291: '布控原因不能为空',
  i18nKey_web_292: '请选择推送人',
  i18nKey_web_293: '请选择布控迎宾屏',
  i18nKey_web_294: '人员详情',
  i18nKey_web_295: '图像',
  i18nKey_web_296: '人员位置',
  i18nKey_web_297: '车辆信息',
  i18nKey_web_298: '消息提醒',
  i18nKey_web_299: '跳转到轨迹查看',
  i18nKey_web_300: '园区位置',
  i18nKey_web_301: '画像标签',
  i18nKey_web_302: '删除底图',
  i18nKey_web_303: '次',
  i18nKey_web_304: '标记',
  i18nKey_web_305: '相似度：',
  i18nKey_web_306: '仅展示系统筛选的高相似度人脸',
  i18nKey_web_307: '信息来源：',
  i18nKey_web_308: '暂无嫌疑人信息',
  i18nKey_web_309: '该操作将彻底删除该人脸底图',
  i18nKey_web_310: '进入园区黑名单布控',
  i18nKey_web_311: '园区黑名单布控',
  i18nKey_web_312: '人员画像',
  i18nKey_web_313: '人脸信息',
  i18nKey_web_314: '嫌疑人信息',
  i18nKey_web_315: '取消布控',
  i18nKey_web_316: '取消园区黑名单布控',
  i18nKey_web_317: '接口状态异常',
  i18nKey_web_318: '人员至少有一个标记',
  i18nKey_web_319: '修改失败---',
  i18nKey_web_320: '修改失败，接口状态异常',
  i18nKey_web_321: '替换底图失败',
  i18nKey_web_322: '此人进入黑名单布控',
  i18nKey_web_323: '此人取消黑名单布控',
  i18nKey_web_324: '操作失败---',
  i18nKey_web_325: '操作失败，接口状态异常',
  i18nKey_web_326: '追踪时段',
  i18nKey_web_327: '至',
  i18nKey_web_328: '追踪状态',
  i18nKey_web_329: '请选择追踪状态',
  i18nKey_web_330: '追踪摄像头',
  i18nKey_web_331: '请选择追踪摄像头',
  i18nKey_web_332: '数据删除成功',
  i18nKey_web_333: '操作成功',
  i18nKey_web_334: '网络加载异常，请稍后再试',
  i18nKey_web_335: '暂无告警',
  i18nKey_web_336: '身份证号码',
  i18nKey_web_337: '信息来源',
  i18nKey_web_338: '罗伯特',
  i18nKey_web_339: '凌晨三点起来',
  i18nKey_web_340: '发现海棠花未眠',
  i18nKey_web_341: '明天11点30会议报告',
  i18nKey_web_342: '明天14点30会议报告',
  i18nKey_web_343: '当前浏览器不支持',
  i18nKey_web_344: '到达提醒时段',
  i18nKey_web_345: '到达提醒状态',
  i18nKey_web_346: '到达提醒摄像头',
  i18nKey_web_347: '迎宾时段',
  i18nKey_web_348: '迎宾状态',
  i18nKey_web_349: '请选择迎宾状态',
  i18nKey_web_350: '布控迎宾屏',
  i18nKey_web_351: '进入迎宾大屏',
  i18nKey_web_352: '输入迎宾屏名称',
  i18nKey_web_353: '选择迎宾屏',
  i18nKey_web_354: '车辆信息修改',
  i18nKey_web_355: '车主',
  i18nKey_web_356: '输入的字数不能大于300',
  i18nKey_web_357: '取消这个修改需求',
  i18nKey_web_358: '停驶记录详情',
  i18nKey_web_359: '已驶离',
  i18nKey_web_360: '停车中',
  i18nKey_web_361: '停车时间',
  i18nKey_web_362: '车位信息',
  i18nKey_web_363: '号车位',
  i18nKey_web_364: '播放记录',
  i18nKey_web_365: '打卡记录',
  i18nKey_web_366: '记录详情',
  i18nKey_web_367: '摄像头名称',
  i18nKey_web_368: '应打卡人',
  i18nKey_web_369: '打卡时间',
  i18nKey_web_370: '未打卡',
  i18nKey_web_371: '某某巡逻组',
  i18nKey_web_372: '播放时间',
  i18nKey_web_373: '立即查看',
  i18nKey_web_374: '待执行',
  i18nKey_web_375: '大道当然',
  i18nKey_web_376: '离开舒适区',
  i18nKey_web_377: '是前进的第一步。',
  i18nKey_web_378: '越万里关山',
  i18nKey_web_379: '闯千百难关。',
  i18nKey_web_380: '斗士身后无退路',
  i18nKey_web_381: '勇气前方无险途。',
  i18nKey_web_382: '团结协作',
  i18nKey_web_383: '全力以赴。',
  i18nKey_web_384: '成大事不在于力量的大小',
  i18nKey_web_385: '而在于能坚持多久。',
  i18nKey_web_386: '要为成功找方法',
  i18nKey_web_387: '不为失败找理由。',
  i18nKey_web_388: '任重道远',
  i18nKey_web_389: '志在必得。',
  i18nKey_web_390: '只有不快的斧',
  i18nKey_web_391: '没有劈不开的柴。',
  i18nKey_web_392: '最新上报时间',
  i18nKey_web_393: '资产名称',
  i18nKey_web_394: '今天',
  i18nKey_web_395: '当',
  i18nKey_web_396: '上',
  i18nKey_web_397: '无更多数据了',
  i18nKey_web_398: '第1周',
  i18nKey_web_399: '电',
  i18nKey_web_400: '建筑用能统计',
  i18nKey_web_401: '商租户用能统计',
  i18nKey_web_402: '同期时间',
  i18nKey_web_403: '耗电总量',
  i18nKey_web_404: '数据不存在',
  i18nKey_web_405: '创建日期',
  i18nKey_web_406: '移除',
  i18nKey_web_407: '网络加载异常，请稍后再试！',
  i18nKey_web_408: '取消成功！',
  i18nKey_web_409: '会议室编码',
  i18nKey_web_410: '请输入会议室编码',
  i18nKey_web_411: '操作成功！',
  i18nKey_web_412: '网络加载异常，请稍后重新操作！',
  i18nKey_web_413: '删除成功',
  i18nKey_web_414: '选择日期',
  i18nKey_web_415: '请选择日期',
  i18nKey_web_416: '会议室列表',
  i18nKey_web_417: '星期日',
  i18nKey_web_418: '预定人',
  i18nKey_web_419: '开门',
  i18nKey_web_420: '关门',
  i18nKey_web_421: '开锁时间',
  i18nKey_web_422: '操作失败，当前页面无开门记录！',
  i18nKey_web_423: '配置',
  i18nKey_web_424: '日期',
  i18nKey_web_425: '暂无可导出数据！',
  i18nKey_web_426: '泊寓健康打卡',
  i18nKey_web_427: '疫情上报告警',
  i18nKey_web_428: '企业每日打卡',
  i18nKey_web_429: '确认是否删除？删除后将不可恢复！',
  i18nKey_web_430: '请输入人员姓名',
  i18nKey_web_431: '请输入正确格式的联系电话',
  i18nKey_web_432: '修改小组人员',
  i18nKey_web_433: '只支持中英文大小写及数字',
  i18nKey_web_434: '请选择设施等级',
  i18nKey_web_435: '设施分类',
  i18nKey_web_436: '请输入管理责任人',
  i18nKey_web_437: '请输入设施描述',
  i18nKey_web_438: '设备状态',
  i18nKey_web_439: '火灾自动报警及联动系统',
  i18nKey_web_440: '火灾报警主机',
  i18nKey_web_441: '感烟火灾探测器',
  i18nKey_web_442: '感温火灾探测器',
  i18nKey_web_443: '手动报警按钮',
  i18nKey_web_444: '上限',
  i18nKey_web_445: '可用率',
  i18nKey_web_446: '故障率',
  i18nKey_web_447: '总告警数',
  i18nKey_web_448: '派发',
  i18nKey_web_449: '派发成功！',
  i18nKey_web_450: '道闸',
  i18nKey_web_451: '网络错误',
  i18nKey_web_452: '摄像头001',
  i18nKey_web_453: '启动',
  i18nKey_web_454: '没有关联事件',
  i18nKey_web_455: '误判成功',
  i18nKey_web_456: '事件描述不能超过1000个字符哦',
  i18nKey_web_457: '该事件状态为待处理，暂时不能上报哦',
  i18nKey_web_458: '该事件状态为处理中，暂时不能上报哦',
  i18nKey_web_459: '该事件状态为挂起，暂时不能上报哦',
  i18nKey_web_460: '人脸追踪正在启动中',
  i18nKey_web_461: '新建人脸布控',
  i18nKey_web_462: '新建库',
  i18nKey_web_463: '删除库',
  i18nKey_web_464: '更多',
  i18nKey_web_465: '根据姓名搜索',
  i18nKey_web_466: '全选',
  i18nKey_web_467: '点击节点的时候选中节点',
  i18nKey_web_468: '默认展开所有节点',
  i18nKey_web_469: '只支持',
  i18nKey_web_470: '格式图片且不大于3',
  i18nKey_web_471: '请输入姓名',
  i18nKey_web_472: '名单类型',
  i18nKey_web_473: '请输入身份证号',
  i18nKey_web_474: '男',
  i18nKey_web_475: '女',
  i18nKey_web_476: '请输入居住地址',
  i18nKey_web_477: '默认展开所有节',
  i18nKey_web_478: '请选择名单类型',
  i18nKey_web_479: '图片只支持',
  i18nKey_web_480: '图片不能大于3',
  i18nKey_web_481: '公安黑名单导入模板',
  i18nKey_web_482: '表',
  i18nKey_web_483: '删除失败',
  i18nKey_web_484: '请选择需要删除的对象',
  i18nKey_web_485: '确定删除吗',
  i18nKey_web_486: '请上传图片',
  i18nKey_web_487: '人脸追踪正在关闭中',
  i18nKey_web_488: '人员：',
  i18nKey_web_489: '监控截图（',
  i18nKey_web_490: '追踪时间',
  i18nKey_web_491: '警告次数',
  i18nKey_web_492: '上海区域',
  i18nKey_web_493: '上海市',
  i18nKey_web_494: '金桥万创',
  i18nKey_web_495: '楼',
  i18nKey_web_496: '负二层',
  i18nKey_web_497: '停车场',
  i18nKey_web_498: '请输入巡逻组描述',
  i18nKey_web_499: '关闭时间',
  i18nKey_web_500: '事件总数',
  i18nKey_web_501: '待处理事件数',
  i18nKey_web_502: '任务总数',
  i18nKey_web_503: '待处理任务数',
  i18nKey_web_504: '安全',
  i18nKey_web_505: '总已处理',
  i18nKey_web_506: '总安全事件',
  i18nKey_web_507: '转发',
  i18nKey_web_508: '搭乘公司',
  i18nKey_web_509: '公司预订',
  i18nKey_web_510: '次数',
  i18nKey_web_511: '店铺销量',
  i18nKey_web_512: '菜品销量',
  i18nKey_web_513: '设施异常',
  i18nKey_web_514: '挂起',
  i18nKey_web_515: '上传时间',
  i18nKey_web_516: '事件',
  i18nKey_web_517: '该任务已产生告警',
  i18nKey_web_518: '不能修改！',
  i18nKey_web_519: '该任务启动中',
  i18nKey_web_520: '请暂停后修改！',
  i18nKey_web_521: '确定删除吗？',
  i18nKey_web_522: '启止时间',
  i18nKey_web_523: '巡逻时间',
  i18nKey_web_524: '问题',
  i18nKey_web_525: '是',
  i18nKey_web_526: '否',
  i18nKey_web_527: '已关闭',
  i18nKey_web_528: '巡逻路线历史',
  i18nKey_web_529: '全部巡逻历史',
  i18nKey_web_530: '告警',
  i18nKey_web_531: '事件名称',
  i18nKey_web_532: '能效',
  i18nKey_web_533: '万科智慧展区',
  i18nKey_web_534: '重大事件告警',
  i18nKey_web_535: '园区',
  i18nKey_web_536: '天府云城',
  i18nKey_web_537: '项目选址在金桥万创中心',
  i18nKey_web_538: '地下车库，配置三套，250',
  i18nKey_web_539: '储能系统，网点分别位于10',
  i18nKey_web_540: '甲线下2',
  i18nKey_web_541: '变压器（1000',
  i18nKey_web_542: '母线侧，10',
  i18nKey_web_543: '乙线下5',
  i18nKey_web_544: '母线侧及6',
  i18nKey_web_545: '变压器（2000',
  i18nKey_web_546: '母线侧',
  i18nKey_web_547: '总充电、放电报表',
  i18nKey_web_548: '充电量',
  i18nKey_web_549: '放电量',
  i18nKey_web_550: '提示内容',
  i18nKey_web_551: '箱体尺寸',
  i18nKey_web_552: '寸',
  i18nKey_web_553: '分系统数量',
  i18nKey_web_554: '用户类型',
  i18nKey_web_555: '额定容量',
  i18nKey_web_556: '额定功率',
  i18nKey_web_557: '变压器容量',
  i18nKey_web_558: '接入电压等级',
  i18nKey_web_559: '总充电量',
  i18nKey_web_560: '总放电量',
  i18nKey_web_561: '是否确认下载',
  i18nKey_web_562: '报表吗',
  i18nKey_web_563: '仪表',
  i18nKey_web_564: '点读数',
  i18nKey_web_565: '用电量',
  i18nKey_web_566: '租户电耗',
  i18nKey_web_567: '能耗变化',
  i18nKey_web_568: '实际能耗值',
  i18nKey_web_569: '目标能耗值',
  i18nKey_web_570: '预测能耗值',
  i18nKey_web_571: '预测值：1',
  i18nKey_web_572: '日维度下，总能耗、公区能耗和公区下一级子项。2月维度下，总能耗、公区能耗和公区下一级子项',
  i18nKey_web_573: '目标值：1',
  i18nKey_web_574: '月维度下，总能耗、公区能耗。2',
  i18nKey_web_575: '年维度下，总能耗和公区能耗',
  i18nKey_web_576: '请输入目标值',
  i18nKey_web_577: '请输入值',
  i18nKey_web_578: '年实际值',
  i18nKey_web_579: '月份',
  i18nKey_web_580: '年目标值',
  i18nKey_web_581: '公区能耗目标',
  i18nKey_web_582: '公区能耗',
  i18nKey_web_583: '每平米能耗',
  i18nKey_web_584: '分项用能',
  i18nKey_web_585: '能耗告警',
  i18nKey_web_586: '商',
  i18nKey_web_587: '租户每平方米能耗排名',
  i18nKey_web_588: '水能',
  i18nKey_web_589: '当日能耗',
  i18nKey_web_590: '当月能耗',
  i18nKey_web_591: '当日公区能耗',
  i18nKey_web_592: '当月公区能耗',
  i18nKey_web_593: '人流总数',
  i18nKey_web_594: '今日总能耗值',
  i18nKey_web_595: '租户每平方米用能排名',
  i18nKey_web_596: '全局概览',
  i18nKey_web_597: '园区概览',
  i18nKey_web_598: '请选时间',
  i18nKey_web_599: '上一年同期：',
  i18nKey_web_600: '上一月：',
  i18nKey_web_601: '较上一年同比：',
  i18nKey_web_602: '较上月环比：',
  i18nKey_web_603: '上一月',
  i18nKey_web_604: '能耗变化图',
  i18nKey_web_605: '建议',
  i18nKey_web_606: '仪表代码',
  i18nKey_web_607: '请输入仪表代码',
  i18nKey_web_608: '请输入智能表编码',
  i18nKey_web_609: '请输入类型',
  i18nKey_web_610: '用户名',
  i18nKey_web_611: '园区卡支付',
  i18nKey_web_612: '微信支付',
  i18nKey_web_613: '电费',
  i18nKey_web_614: '水费',
  i18nKey_web_615: '商户类型',
  i18nKey_web_616: '仪表类型',
  i18nKey_web_617: '商户名称',
  i18nKey_web_618: '上次更新时间',
  i18nKey_web_619: '充值金额',
  i18nKey_web_620: '充值时间',
  i18nKey_web_621: '六',
  i18nKey_web_622: '个人商户',
  i18nKey_web_623: '导出成功',
  i18nKey_web_624: '充值未抵扣金额',
  i18nKey_web_625: '已出账单未抵扣金额',
  i18nKey_web_626: '电费已出账单未抵扣金额',
  i18nKey_web_627: '水费已出账单未抵扣金额',
  i18nKey_web_628: '欠费商户概览',
  i18nKey_web_629: '实际消耗用电量',
  i18nKey_web_630: '账单消耗总用电量为',
  i18nKey_web_631: '实际消耗用水量',
  i18nKey_web_632: '账单消耗总用水量为',
  i18nKey_web_633: '用水量',
  i18nKey_web_634: '详',
  i18nKey_web_635: '合同编号',
  i18nKey_web_636: '请选择合同编号',
  i18nKey_web_637: '请输入单元',
  i18nKey_web_638: '阈值金额',
  i18nKey_web_639: '请输入阈值金额',
  i18nKey_web_640: '合同期',
  i18nKey_web_641: '电表阈值金额',
  i18nKey_web_642: '水表阈值金额',
  i18nKey_web_643: '登录',
  i18nKey_web_644: '注册',
  i18nKey_web_645: '验证码',
  i18nKey_web_646: '请输入手机号码',
  i18nKey_web_647: '或智慧运营中心',
  i18nKey_web_648: '服务协议',
  i18nKey_web_649: '请选择会议室名称',
  i18nKey_web_650: '请输入认证企业',
  i18nKey_web_651: '请选择认证企业',
  i18nKey_web_652: '请选择预订日期',
  i18nKey_web_653: '预订起时间',
  i18nKey_web_654: '请输入订单金额',
  i18nKey_web_655: '请输入实付金额',
  i18nKey_web_656: '请输入最2',
  i18nKey_web_657: '一个汉字算两个字符。',
  i18nKey_web_658: '请选择预定结束时间',
  i18nKey_web_659: '旧密码不可与新密码相同',
  i18nKey_web_660: '预定日期',
  i18nKey_web_661: '支付状态',
  i18nKey_web_662: '支付时间',
  i18nKey_web_663: '驳回',
  i18nKey_web_664: '通过',
  i18nKey_web_665: '微信',
  i18nKey_web_666: '支付中',
  i18nKey_web_667: '取消支付',
  i18nKey_web_668: '支付成功',
  i18nKey_web_669: '名称',
  i18nKey_web_670: '请输入名称',
  i18nKey_web_671: '名称不能为空',
  i18nKey_web_672: '类别',
  i18nKey_web_673: '请选择类别',
  i18nKey_web_674: '租户选择',
  i18nKey_web_675: '请输入商',
  i18nKey_web_676: '租户名称',
  i18nKey_web_677: '本期',
  i18nKey_web_678: '电能耗',
  i18nKey_web_679: '环比同期',
  i18nKey_web_680: '上一年同期时间',
  i18nKey_web_681: '租户用能统计',
  i18nKey_web_682: '已选商',
  i18nKey_web_683: '巡逻计划',
  i18nKey_web_684: '关闭视频巡逻',
  i18nKey_web_685: '是否暂停视频巡逻',
  i18nKey_web_686: '是否停止视频巡逻',
  i18nKey_web_687: '园区无摄像头数据',
  i18nKey_web_688: '未处理',
  i18nKey_web_689: '挂起中',
  i18nKey_web_690: '条数据，剩余',
  i18nKey_web_691: '条错误；',
  i18nKey_web_692: '奖励类型',
  i18nKey_web_693: '成功',
  i18nKey_web_694: '失败',
  i18nKey_web_695: '奖励对象',
  i18nKey_web_696: '奖励时间',
  i18nKey_web_697: '奖励对象名称',
  i18nKey_web_698: '列表为空不能导出',
  i18nKey_web_699: '积分名称',
  i18nKey_web_700: '请输入积分名称',
  i18nKey_web_701: '请选择积分名称',
  i18nKey_web_702: '只能输入正整数',
  i18nKey_web_703: '发行成功',
  i18nKey_web_704: '积分类型名称',
  i18nKey_web_705: '发行类型',
  i18nKey_web_706: '请选择创建时间范围',
  i18nKey_web_707: '支出',
  i18nKey_web_708: '收入方',
  i18nKey_web_709: '请输入支出',
  i18nKey_web_710: '交易类型',
  i18nKey_web_711: '收入',
  i18nKey_web_712: '积分',
  i18nKey_web_713: '交易时间',
  i18nKey_web_714: '请选择奖励时间',
  i18nKey_web_715: '交易号',
  i18nKey_web_716: '支出方',
  i18nKey_web_717: '交易积分数量',
  i18nKey_web_718: '交易状态',
  i18nKey_web_719: '交易成功',
  i18nKey_web_720: '交易失败',
  i18nKey_web_721: '请输入收入',
  i18nKey_web_722: '请选择积分',
  i18nKey_web_723: '交易明细',
  i18nKey_web_724: '待开始',
  i18nKey_web_725: '预订时间',
  i18nKey_web_726: '请选择预订时间',
  i18nKey_web_727: '请选择工位类型',
  i18nKey_web_728: '用户手机号',
  i18nKey_web_729: '共享活动室',
  i18nKey_web_730: '退款中',
  i18nKey_web_731: '申请中',
  i18nKey_web_732: '共享办公订单报表',
  i18nKey_web_733: '请输入空间名称',
  i18nKey_web_734: '请选择空间类型',
  i18nKey_web_735: '公司',
  i18nKey_web_736: '请输入公司',
  i18nKey_web_737: '通行方式',
  i18nKey_web_738: '通行时间',
  i18nKey_web_739: '请选择通行时间',
  i18nKey_web_740: '刷卡',
  i18nKey_web_741: '通行记录',
  i18nKey_web_742: '选择摄像机',
  i18nKey_web_743: '选择时段',
  i18nKey_web_744: '中科纳能',
  i18nKey_web_745: '华为',
  i18nKey_web_746: '所有',
  i18nKey_web_747: '查询人员标签异常',
  i18nKey_web_748: '请先选择楼层',
  i18nKey_web_749: '请选择人员标签',
  i18nKey_web_750: '请选择摄像机',
  i18nKey_web_751: '查询时间',
  i18nKey_web_752: '总人数：',
  i18nKey_web_753: '客群分析',
  i18nKey_web_754: '人员列表',
  i18nKey_web_755: '注册用户',
  i18nKey_web_756: '访客人数统计',
  i18nKey_web_757: '按园区',
  i18nKey_web_758: '园区总人数',
  i18nKey_web_759: '访客总数',
  i18nKey_web_760: '总人数',
  i18nKey_web_761: '分项选择',
  i18nKey_web_762: '分项用电分析',
  i18nKey_web_763: '请输入序号',
  i18nKey_web_764: '第三方',
  i18nKey_web_765: '请输入第三方',
  i18nKey_web_766: '摩根平台会议室',
  i18nKey_web_767: '请输入摩根平台会议室',
  i18nKey_web_768: '请选择数据来源',
  i18nKey_web_769: '请输入最1',
  i18nKey_web_770: '一个汉字算两个字符',
  i18nKey_web_771: '且不能有空格！',
  i18nKey_web_772: '分析用能统计',
  i18nKey_web_773: '分项用能统计',
  i18nKey_web_774: '请选择分项',
  i18nKey_web_775: '场景',
  i18nKey_web_776: '请输入场景',
  i18nKey_web_777: '策略设置',
  i18nKey_web_778: '请选择策略设置',
  i18nKey_web_779: '分钟不能低于2分钟且不能大于10分钟',
  i18nKey_web_780: '数据来源类型',
  i18nKey_web_781: '场景策略',
  i18nKey_web_782: '万智汇',
  i18nKey_web_783: '万科产办',
  i18nKey_web_784: '启用成功',
  i18nKey_web_785: '停用成功',
  i18nKey_web_786: '请选择会议状态',
  i18nKey_web_787: '会议开始前：启动',
  i18nKey_web_788: '会议开始后：未启动',
  i18nKey_web_789: '会议开始后：启动',
  i18nKey_web_790: '最后执行时间',
  i18nKey_web_791: '预定企业',
  i18nKey_web_792: '会议结束后',
  i18nKey_web_793: '新增会议室场景策略',
  i18nKey_web_794: '修改会议室场景策略',
  i18nKey_web_795: '请输入车牌号码',
  i18nKey_web_796: '停车位',
  i18nKey_web_797: '请输入停车位',
  i18nKey_web_798: '暂无图片',
  i18nKey_web_799: '车辆管理',
  i18nKey_web_800: '车辆查询',
  i18nKey_web_801: '开始和结束时间相差不能大于30天',
  i18nKey_web_802: '进出车场记录',
  i18nKey_web_803: '车辆违停统计',
  i18nKey_web_804: '购买套餐记录',
  i18nKey_web_805: '查询类型',
  i18nKey_web_806: '车牌号',
  i18nKey_web_807: '车位号',
  i18nKey_web_808: '请输入车牌号',
  i18nKey_web_809: '请输入车位号',
  i18nKey_web_810: '按下',
  i18nKey_web_811: '键',
  i18nKey_web_812: '车辆驶入记录',
  i18nKey_web_813: '总车位',
  i18nKey_web_814: '车辆套餐数量',
  i18nKey_web_815: '停车费用',
  i18nKey_web_816: '小时内',
  i18nKey_web_817: '驶出记录',
  i18nKey_web_818: '驶入记录',
  i18nKey_web_819: '暂无停车场',
  i18nKey_web_820: '发车时间',
  i18nKey_web_821: '票',
  i18nKey_web_822: '单价',
  i18nKey_web_823: '乘车班次已启用，请先暂停后再操作！',
  i18nKey_web_824: '是否确认删除该乘车班次？删除后将不可恢复！',
  i18nKey_web_825: '价格',
  i18nKey_web_826: '折',
  i18nKey_web_827: '有效日期',
  i18nKey_web_828: '请输入商户名称',
  i18nKey_web_829: '请选择订单时间范围',
  i18nKey_web_830: '支付金额',
  i18nKey_web_831: '巴士订单报表',
  i18nKey_web_832: '亿',
  i18nKey_web_833: '千万',
  i18nKey_web_834: '百万',
  i18nKey_web_835: '你还没有安装视频播放器，现在是否下载视频播放插件？下载好请自行安装',
  i18nKey_web_836: '无结果返回',
  i18nKey_web_837: '无结果',
  i18nKey_web_838: '七级',
  i18nKey_web_839: '八级',
  i18nKey_web_840: '九级',
  i18nKey_web_841: '十级',
  i18nKey_web_842: '十一级',
  i18nKey_web_843: '十二级',
  i18nKey_web_844: '设施数据导入',
  i18nKey_web_845: '设备名',
  i18nKey_web_846: '来源系统编码',
  i18nKey_web_847: '来源系统描述',
  i18nKey_web_848: '来源系统设备编码',
  i18nKey_web_849: '一级设备分类名称',
  i18nKey_web_850: '一级设备分类编码',
  i18nKey_web_851: '二级设备分类名称',
  i18nKey_web_852: '二级设备分类编码',
  i18nKey_web_853: '三级设备分类名称',
  i18nKey_web_854: '三级设备分类编码',
  i18nKey_web_855: '空间模型编码',
  i18nKey_web_856: '文件大小不能超过20',
  i18nKey_web_857: '上传成功',
  i18nKey_web_858: '批次号为',
  i18nKey_web_859: '请稍后同步到正表',
  i18nKey_web_860: '上传失败！',
  i18nKey_web_861: '请上传新版',
  i18nKey_web_862: '文件',
  i18nKey_web_863: '导出失败！',
  i18nKey_web_864: '同步的批次号为',
  i18nKey_web_865: '确认同步？',
  i18nKey_web_866: '确认同步全部设施数据？',
  i18nKey_web_867: '同步失败，暂无需要同步的设施数据！',
  i18nKey_web_868: '同步成功！',
  i18nKey_web_869: '同步失败！',
  i18nKey_web_870: '坐标类型',
  i18nKey_web_871: '坐标',
  i18nKey_web_872: '请选择管理责任人',
  i18nKey_web_873: '附件仅支持新版',
  i18nKey_web_874: '格式',
  i18nKey_web_875: '不启用',
  i18nKey_web_876: '地址',
  i18nKey_web_877: '设备编码',
  i18nKey_web_878: '没有数据',
  i18nKey_web_879: '确定删除',
  i18nKey_web_880: '图片大小不能超过3',
  i18nKey_web_881: '只能上传',
  i18nKey_web_882: '格式图片',
  i18nKey_web_883: '分类必须选到第三级',
  i18nKey_web_884: '请输入合法的',
  i18nKey_web_885: '地址，如00-01-6',
  i18nKey_web_886: '或00',
  i18nKey_web_887: '坐标，如',
  i18nKey_web_888: '坐标已存在，请重新输入',
  i18nKey_web_889: '设施别名长度不能超过16个字符',
  i18nKey_web_890: '设施名称长度不能超过64个字符',
  i18nKey_web_891: '来源系统设备编号只能由50个字符的中英文、数字及“-',
  i18nKey_web_892: '组成',
  i18nKey_web_893: '对外型号只能为50个字符以内的中英文、数字及下划线',
  i18nKey_web_894: '经度整数部分为-180到180',
  i18nKey_web_895: '纬度整数部分为-90到90',
  i18nKey_web_896: '设施描述只能由中英文、数字及',
  i18nKey_web_897: '制造商只能由中英文、数字及',
  i18nKey_web_898: '服务商只能由中英文、数字及',
  i18nKey_web_899: '维保商只能由中英文、数字及',
  i18nKey_web_900: '品牌只能由中英文、数字及',
  i18nKey_web_901: '支持格式为',
  i18nKey_web_902: '和',
  i18nKey_web_903: '设施分类层级',
  i18nKey_web_904: '新增层级',
  i18nKey_web_905: '设施重要等级',
  i18nKey_web_906: '设施分类编码只能由数字、字母、下划线组成',
  i18nKey_web_907: '请输入需要增加的层级',
  i18nKey_web_908: '请将数据填写完整，不能跨层级增加数据',
  i18nKey_web_909: '请输入相对应的分类编码或分类名称',
  i18nKey_web_910: '分类编码和分类名称不能为空',
  i18nKey_web_911: '查看企业',
  i18nKey_web_912: '请输入园区编码',
  i18nKey_web_913: '微软雅黑',
  i18nKey_web_914: '创建方式',
  i18nKey_web_915: '手动录入',
  i18nKey_web_916: '批量导入',
  i18nKey_web_917: '请输入企业英文名',
  i18nKey_web_918: '工商执照注册号',
  i18nKey_web_919: '统一社会信用代码',
  i18nKey_web_920: '将文件拖到此处，或',
  i18nKey_web_921: '点击上传',
  i18nKey_web_922: '文件仅支持',
  i18nKey_web_923: '请输入正确格式的身份证号',
  i18nKey_web_924: '长度最多为50个字符',
  i18nKey_web_925: '新建企业',
  i18nKey_web_926: '修改企业',
  i18nKey_web_927: '文件，并且后缀是',
  i18nKey_web_928: '共',
  i18nKey_web_929: '条，成功',
  i18nKey_web_930: '条，错误',
  i18nKey_web_931: '已关联大屏',
  i18nKey_web_932: '可关联大屏',
  i18nKey_web_933: '取消关联成功',
  i18nKey_web_934: '选择分类',
  i18nKey_web_935: '八类',
  i18nKey_web_936: '九类',
  i18nKey_web_937: '请选择一类',
  i18nKey_web_938: '请选择二类',
  i18nKey_web_939: '请选择三类',
  i18nKey_web_940: '请选择四类',
  i18nKey_web_941: '请选择五类',
  i18nKey_web_942: '请选择六类',
  i18nKey_web_943: '选择位置',
  i18nKey_web_944: '查询集团列表失败',
  i18nKey_web_945: '楼宇',
  i18nKey_web_946: '园区不能为空',
  i18nKey_web_947: '楼层不能为空',
  i18nKey_web_948: '文件名',
  i18nKey_web_949: '设施创建方式',
  i18nKey_web_950: '请输入端口号',
  i18nKey_web_951: '请输入账号',
  i18nKey_web_952: '地址不能为空！',
  i18nKey_web_953: '地址格式不对！',
  i18nKey_web_954: '端口号不能为空！',
  i18nKey_web_955: '端口号格式不对！',
  i18nKey_web_956: '请输入圆区名称',
  i18nKey_web_957: '请输入圆区编码',
  i18nKey_web_958: '配置成功',
  i18nKey_web_959: '选择园区',
  i18nKey_web_960: '选择子系统',
  i18nKey_web_961: '预约来访',
  i18nKey_web_962: '请选择园区子系统',
  i18nKey_web_963: '请选择人员预约来访时间',
  i18nKey_web_964: '请选择人员预计离去时间',
  i18nKey_web_965: '请选择预约状态',
  i18nKey_web_966: '请选择人员来访时间',
  i18nKey_web_967: '请选择人员来访原因',
  i18nKey_web_968: '选择部门',
  i18nKey_web_969: '群组名称',
  i18nKey_web_970: '请输入群名称',
  i18nKey_web_971: '群组描述',
  i18nKey_web_972: '可输入100个字符',
  i18nKey_web_973: '新增群组',
  i18nKey_web_974: '群组名不能为空',
  i18nKey_web_975: '群组名已存在',
  i18nKey_web_976: '群组描述不能为空！',
  i18nKey_web_977: '修改群信息',
  i18nKey_web_978: '选择籍贯',
  i18nKey_web_979: '省份',
  i18nKey_web_980: '请选择省份',
  i18nKey_web_981: '群组信息',
  i18nKey_web_982: '用户信息',
  i18nKey_web_983: '用户部门',
  i18nKey_web_984: '用户角色',
  i18nKey_web_985: '用户群组',
  i18nKey_web_986: '修改用户信息',
  i18nKey_web_987: '可输入500个字符',
  i18nKey_web_988: '添加用户',
  i18nKey_web_989: '加载更多',
  i18nKey_web_990: '请选择要添加的用户！',
  i18nKey_web_991: '没有更多了',
  i18nKey_web_992: '选择车辆品牌',
  i18nKey_web_993: '选择车辆',
  i18nKey_web_994: '用户',
  i18nKey_web_995: '群组',
  i18nKey_web_996: '暂无用户',
  i18nKey_web_997: '暂无群组',
  i18nKey_web_998: '查看群名单',
  i18nKey_web_999: '请输入用户名',
  i18nKey_web_1000: '手机号搜索',
  i18nKey_web_1001: '添加群组',
  i18nKey_web_1002: '群名单',
  i18nKey_web_1003: '是否确认删除？',
  i18nKey_web_1004: '暂无角色列表',
  i18nKey_web_1005: '请选择需要删除的用户',
  i18nKey_web_1006: '请选择需要删除的群组',
  i18nKey_web_1007: '请选择需要添加的用户',
  i18nKey_web_1008: '请选择需要添加的群组',
  i18nKey_web_1009: '没有更多数据了',
  i18nKey_web_1010: '选择分类路径',
  i18nKey_web_1011: '车辆基础数据信息',
  i18nKey_web_1012: '车辆分类编码',
  i18nKey_web_1013: '车牌',
  i18nKey_web_1014: '行驶证号',
  i18nKey_web_1015: '车辆分类名称',
  i18nKey_web_1016: '车辆父分类编码',
  i18nKey_web_1017: '所属人员编码',
  i18nKey_web_1018: '标签生效时间',
  i18nKey_web_1019: '标签失效时间',
  i18nKey_web_1020: '有效标识',
  i18nKey_web_1021: '最后更新人',
  i18nKey_web_1022: '请输入车牌',
  i18nKey_web_1023: '请选择车牌',
  i18nKey_web_1024: '请输入车辆颜色',
  i18nKey_web_1025: '请输入行驶证号',
  i18nKey_web_1026: '请输入车辆分类全路径',
  i18nKey_web_1027: '请输入车辆分类名称',
  i18nKey_web_1028: '请输入所属人员编码',
  i18nKey_web_1029: '请输入所属单位',
  i18nKey_web_1030: '无效',
  i18nKey_web_1031: '车牌号不能为空！',
  i18nKey_web_1032: '车辆颜色不能为空！',
  i18nKey_web_1033: '车辆分类全路径不能为空！',
  i18nKey_web_1034: '车辆分类名称不能为空！',
  i18nKey_web_1035: '所属人员名称不能为空！',
  i18nKey_web_1036: '所属单位不能为空！',
  i18nKey_web_1037: '车辆标签不能为空！',
  i18nKey_web_1038: '生效时间不能为空！',
  i18nKey_web_1039: '失效时间不能为空！',
  i18nKey_web_1040: '有效标识不能为空！',
  i18nKey_web_1041: '请输入正确的车牌！',
  i18nKey_web_1042: '行驶证号或者人员编码不能包含中文！',
  i18nKey_web_1043: '蓝',
  i18nKey_web_1044: '计划结束时间应该大于计划开始时间',
  i18nKey_web_1045: '类设施',
  i18nKey_web_1046: '开始日期',
  i18nKey_web_1047: '结束日期',
  i18nKey_web_1048: '设施维修记录',
  i18nKey_web_1049: '历史运行记录',
  i18nKey_web_1050: '设施报警记录',
  i18nKey_web_1051: '维修起止时间',
  i18nKey_web_1052: '维修部门',
  i18nKey_web_1053: '王小虎',
  i18nKey_web_1054: '上海市普陀区金沙江路',
  i18nKey_web_1055: '弄',
  i18nKey_web_1056: '好了',
  i18nKey_web_1057: '工程部',
  i18nKey_web_1058: '查询时间不能为空！！！',
  i18nKey_web_1059: '我选择以后的',
  i18nKey_web_1060: '重大：',
  i18nKey_web_1061: '普通：',
  i18nKey_web_1062: '输入事件标题或设备别名',
  i18nKey_web_1063: '发送短信2',
  i18nKey_web_1064: '启动方式',
  i18nKey_web_1065: '手动',
  i18nKey_web_1066: '自动',
  i18nKey_web_1067: '温度设定',
  i18nKey_web_1068: '预设温度',
  i18nKey_web_1069: '回风温度',
  i18nKey_web_1070: '过滤网压差',
  i18nKey_web_1071: '水阀控制',
  i18nKey_web_1072: '电源监测',
  i18nKey_web_1073: '风机命令',
  i18nKey_web_1074: '告警级别',
  i18nKey_web_1075: '告警操作',
  i18nKey_web_1076: '设备服务商',
  i18nKey_web_1077: '设备描述',
  i18nKey_web_1078: '设备品牌',
  i18nKey_web_1079: '设备制造商',
  i18nKey_web_1080: '设备维保商',
  i18nKey_web_1081: '设施启动时间',
  i18nKey_web_1082: '设施停机时间',
  i18nKey_web_1083: '本次运行时长',
  i18nKey_web_1084: '维保日期',
  i18nKey_web_1085: '维保责任人',
  i18nKey_web_1086: '维保原因',
  i18nKey_web_1087: '维保内容',
  i18nKey_web_1088: '维保结果',
  i18nKey_web_1089: '设施告警记录',
  i18nKey_web_1090: '设施维保记录',
  i18nKey_web_1091: '开关设置',
  i18nKey_web_1092: '开灯',
  i18nKey_web_1093: '关灯',
  i18nKey_web_1094: '应用',
  i18nKey_web_1095: '指令下发成功，状态更新约30秒左右，请耐心等待',
  i18nKey_web_1096: '调光',
  i18nKey_web_1097: '提示：开灯状态下调亮度才会生效',
  i18nKey_web_1098: '调光成功',
  i18nKey_web_1099: '重要事件',
  i18nKey_web_1100: '一般事件',
  i18nKey_web_1101: '立即追踪',
  i18nKey_web_1102: '门禁系统',
  i18nKey_web_1103: '视频图像',
  i18nKey_web_1104: '关联图片',
  i18nKey_web_1105: '上传',
  i18nKey_web_1106: '关联视频',
  i18nKey_web_1107: '关联布控',
  i18nKey_web_1108: '新增布控',
  i18nKey_web_1109: '布控失控',
  i18nKey_web_1110: '布控图片',
  i18nKey_web_1111: '来源系统来源系统来源系统来源系统来源系统来源系统来源系统来源系统来源系统来源系统来源系统来源系统',
  i18nKey_web_1112: '疏散人群',
  i18nKey_web_1113: '待接受',
  i18nKey_web_1114: '关联摄像头后台返回',
  i18nKey_web_1115: '周围摄像头后台返回',
  i18nKey_web_1116: '添加关联摄像头后台返回',
  i18nKey_web_1117: '删除关联摄像头后台返回',
  i18nKey_web_1118: '提示：派发任务处理完成后，事件将自动关闭。',
  i18nKey_web_1119: '分发任务',
  i18nKey_web_1120: '新增任务完成',
  i18nKey_web_1121: '任务分发',
  i18nKey_web_1122: '委派任务',
  i18nKey_web_1123: '距离',
  i18nKey_web_1124: '请选择要指派任务的人',
  i18nKey_web_1125: '请输入搜索关键字',
  i18nKey_web_1126: '请输入任务内容',
  i18nKey_web_1127: '新增任务成功',
  i18nKey_web_1128: '网红诈骗案',
  i18nKey_web_1129: '玄彬',
  i18nKey_web_1130: '摄像头002',
  i18nKey_web_1131: '摄像头003',
  i18nKey_web_1132: '摄像头004',
  i18nKey_web_1133: '摄像头005',
  i18nKey_web_1134: '自动上报事件',
  i18nKey_web_1135: '模型',
  i18nKey_web_1136: '张三',
  i18nKey_web_1137: '北方区域',
  i18nKey_web_1138: '南方区域',
  i18nKey_web_1139: '中西部区域',
  i18nKey_web_1140: '已选迎宾屏',
  i18nKey_web_1141: '已选摄像头',
  i18nKey_web_1142: '在线摄像机数',
  i18nKey_web_1143: '总摄像机数',
  i18nKey_web_1144: '标题',
  i18nKey_web_1145: '必须输入事件标题',
  i18nKey_web_1146: '请输入事件描述',
  i18nKey_web_1147: '万科成都园区',
  i18nKey_web_1148: '栋103',
  i18nKey_web_1149: '事件上报失败',
  i18nKey_web_1150: '图片已保存至',
  i18nKey_web_1151: '图片截取失败',
  i18nKey_web_1152: '错误码：',
  i18nKey_web_1153: '巡逻组数',
  i18nKey_web_1154: '新增巡逻组',
  i18nKey_web_1155: '视频巡逻列表',
  i18nKey_web_1156: '视频巡逻历史',
  i18nKey_web_1157: '结束',
  i18nKey_web_1158: '设备类别',
  i18nKey_web_1159: '事件处理人',
  i18nKey_web_1160: '用户卡号',
  i18nKey_web_1161: '处理人手机',
  i18nKey_web_1162: '解决方案',
  i18nKey_web_1163: '用户账号',
  i18nKey_web_1164: '处理类型',
  i18nKey_web_1165: '处理人固定电话',
  i18nKey_web_1166: '布控类型',
  i18nKey_web_1167: '事件备注',
  i18nKey_web_1168: '最多只能上传5张图片',
  i18nKey_web_1169: '图片大小不能超过2',
  i18nKey_web_1170: '格式的图片',
  i18nKey_web_1171: '任务名称不能超过50个字符',
  i18nKey_web_1172: '人脸追踪-',
  i18nKey_web_1173: '车辆轨迹',
  i18nKey_web_1174: '布控原因',
  i18nKey_web_1175: '请输入布控原因，不超过150个字符',
  i18nKey_web_1176: '姓名：',
  i18nKey_web_1177: '时段',
  i18nKey_web_1178: '相似度',
  i18nKey_web_1179: '图',
  i18nKey_web_1180: '是否推送弹窗',
  i18nKey_web_1181: '请输入物业公告',
  i18nKey_web_1182: '通知人员',
  i18nKey_web_1183: '名字',
  i18nKey_web_1184: '请输入姓名或手机号',
  i18nKey_web_1185: '布控原因不能超过150个字符',
  i18nKey_web_1186: '从车辆库选择',
  i18nKey_web_1187: '车辆库选择',
  i18nKey_web_1188: '查看警告',
  i18nKey_web_1189: '地点：',
  i18nKey_web_1190: '来源：',
  i18nKey_web_1191: '时间程序自动启停（0停止',
  i18nKey_web_1192: '启动）',
  i18nKey_web_1193: '空调系统控制模式（1启动2停止3时间表控制）',
  i18nKey_web_1194: '超出目标值',
  i18nKey_web_1195: '室外',
  i18nKey_web_1196: '湿度',
  i18nKey_web_1197: '环境空间',
  i18nKey_web_1198: '告警总数',
  i18nKey_web_1199: '二氧化碳',
  i18nKey_web_1200: '甲醛',
  i18nKey_web_1201: '框',
  i18nKey_web_1202: '负氧离子',
  i18nKey_web_1203: '噪音',
  i18nKey_web_1204: '有机挥发物',
  i18nKey_web_1205: '环境指数',
  i18nKey_web_1206: '路灯统计',
  i18nKey_web_1207: '任务进度',
  i18nKey_web_1208: '环境空间标题',
  i18nKey_web_1209: '未设置',
  i18nKey_web_1210: '空闲',
  i18nKey_web_1211: '工单描述',
  i18nKey_web_1212: '请输入工单描述',
  i18nKey_web_1213: '派发工单完成',
  i18nKey_web_1214: '请选择要指派工单的人',
  i18nKey_web_1215: '请输入工单内容',
  i18nKey_web_1216: '小李',
  i18nKey_web_1217: '报修用户',
  i18nKey_web_1218: '上门时间',
  i18nKey_web_1219: '已派发',
  i18nKey_web_1220: '最多能输入100字符',
  i18nKey_web_1221: '工单责任人：',
  i18nKey_web_1222: '处理费用：',
  i18nKey_web_1223: '处理时长：',
  i18nKey_web_1224: '处理进度：',
  i18nKey_web_1225: '报修名称',
  i18nKey_web_1226: '报修状态',
  i18nKey_web_1227: '报修等级',
  i18nKey_web_1228: '报修时间',
  i18nKey_web_1229: '报修位置',
  i18nKey_web_1230: '总量',
  i18nKey_web_1231: '同期用能',
  i18nKey_web_1232: '单位平方米耗能',
  i18nKey_web_1233: '禁用',
  i18nKey_web_1234: '全天',
  i18nKey_web_1235: '盏',
  i18nKey_web_1236: '单位：盏',
  i18nKey_web_1237: '今日',
  i18nKey_web_1238: '面积',
  i18nKey_web_1239: '请输入小时',
  i18nKey_web_1240: '付费设施',
  i18nKey_web_1241: '请输入单价',
  i18nKey_web_1242: '半天单价（元',
  i18nKey_web_1243: '小时）',
  i18nKey_web_1244: '请输入半天单价（元',
  i18nKey_web_1245: '请选择结束日期',
  i18nKey_web_1246: '天不能低于4小时！',
  i18nKey_web_1247: '取消退款额度不能超过100',
  i18nKey_web_1248: '线上退款输入格式不正确！',
  i18nKey_web_1249: '预定满几小时按天计费',
  i18nKey_web_1250: '请选择月份',
  i18nKey_web_1251: '请选择企业',
  i18nKey_web_1252: '免费额度',
  i18nKey_web_1253: '修改成功',
  i18nKey_web_1254: '会议名称',
  i18nKey_web_1255: '会议地点',
  i18nKey_web_1256: '预约人',
  i18nKey_web_1257: '实际参会人数',
  i18nKey_web_1258: '选择月',
  i18nKey_web_1259: '月实际电能耗',
  i18nKey_web_1260: '数据汇总',
  i18nKey_web_1261: '耗能总览',
  i18nKey_web_1262: '自动推送',
  i18nKey_web_1263: '东里',
  i18nKey_web_1264: '西里',
  i18nKey_web_1265: '万科里',
  i18nKey_web_1266: '仪表号',
  i18nKey_web_1267: '请输入仪表号',
  i18nKey_web_1268: '请输入配电开关名称',
  i18nKey_web_1269: '分期',
  i18nKey_web_1270: '请选择仪表类型',
  i18nKey_web_1271: '房间',
  i18nKey_web_1272: '生效',
  i18nKey_web_1273: '上次用电量',
  i18nKey_web_1274: '上次用水量',
  i18nKey_web_1275: '本次抄表日期不能为空',
  i18nKey_web_1276: '本次抄表日期格式错误，格式规范为：',
  i18nKey_web_1277: '本次抄表日期数据错误，请输入合理的日期数据',
  i18nKey_web_1278: '本次抄表读数格式错误',
  i18nKey_web_1279: '本次抄表读数小于上次抄表读数，是否确认修改',
  i18nKey_web_1280: '本次还有',
  i18nKey_web_1281: '个表未录入',
  i18nKey_web_1282: '是否要继续生效',
  i18nKey_web_1283: '生效成功',
  i18nKey_web_1284: '请选择要删除数据',
  i18nKey_web_1285: '删除后该设备的所有明细都将删除，确定要删除',
  i18nKey_web_1286: '所选',
  i18nKey_web_1287: '吗？',
  i18nKey_web_1288: '删除失败，请稍后再试',
  i18nKey_web_1289: '同步',
  i18nKey_web_1290: '信号2',
  i18nKey_web_1291: '设施系统2',
  i18nKey_web_1292: '告警标签',
  i18nKey_web_1293: '告警使能',
  i18nKey_web_1294: '紧急',
  i18nKey_web_1295: '重要',
  i18nKey_web_1296: '一般',
  i18nKey_web_1297: '不确定',
  i18nKey_web_1298: '有效时间',
  i18nKey_web_1299: '预案使能',
  i18nKey_web_1300: '死区',
  i18nKey_web_1301: '请输入死区值',
  i18nKey_web_1302: '请输入告警描述',
  i18nKey_web_1303: '信号1',
  i18nKey_web_1304: '导入失败，只支持',
  i18nKey_web_1305: '导入失败，没有有效数据',
  i18nKey_web_1306: '导入失败，点位编码数据重复',
  i18nKey_web_1307: '此操作数据将永久删除，是否确认？',
  i18nKey_web_1308: '不能为空',
  i18nKey_web_1309: '只能由数字及四则运算符号组成',
  i18nKey_web_1310: '恢复',
  i18nKey_web_1311: '保存失败',
  i18nKey_web_1312: '源系统设施编号',
  i18nKey_web_1313: '设施二维码',
  i18nKey_web_1314: '重要等级',
  i18nKey_web_1315: '分类层级数量',
  i18nKey_web_1316: '设施序列号',
  i18nKey_web_1317: '坐标-海拔',
  i18nKey_web_1318: '查看上传设施画像',
  i18nKey_web_1319: '查看上传设施示意图',
  i18nKey_web_1320: '请选择历史版本',
  i18nKey_web_1321: '版本',
  i18nKey_web_1322: '已关联大屏0个',
  i18nKey_web_1323: '保存失败！',
  i18nKey_web_1324: '长度不能超过50个字符',
  i18nKey_web_1325: '增加',
  i18nKey_web_1326: '新增失败',
  i18nKey_web_1327: '确定要删除关联关系吗？',
  i18nKey_web_1328: '删除关联成功',
  i18nKey_web_1329: '确认保存？',
  i18nKey_web_1330: '确认取消？',
  i18nKey_web_1331: '新增失败！',
  i18nKey_web_1332: '请选择房间',
  i18nKey_web_1333: '源系统人员编码',
  i18nKey_web_1334: '源系统名称',
  i18nKey_web_1335: '标识',
  i18nKey_web_1336: '照片',
  i18nKey_web_1337: '照片存储地址',
  i18nKey_web_1338: '照片类型编码',
  i18nKey_web_1339: '人员照片',
  i18nKey_web_1340: '标签信息',
  i18nKey_web_1341: '是否有效',
  i18nKey_web_1342: '请选择部门',
  i18nKey_web_1343: '护照',
  i18nKey_web_1344: '添加成功',
  i18nKey_web_1345: '图片不能为空',
  i18nKey_web_1346: '人员基础数据修改成功',
  i18nKey_web_1347: '确定打开',
  i18nKey_web_1348: '确定关闭',
  i18nKey_web_1349: '全部用户',
  i18nKey_web_1350: '全部群组',
  i18nKey_web_1351: '群信息',
  i18nKey_web_1352: '请输入用户部门',
  i18nKey_web_1353: '确定删除群组',
  i18nKey_web_1354: '确定删除所选用户',
  i18nKey_web_1355: '请选择要删除的用户',
  i18nKey_web_1356: '挂学警港澳',
  i18nKey_web_1357: '开启',
  i18nKey_web_1358: '资源',
  i18nKey_web_1359: '当前',
  i18nKey_web_1360: '菜单资源',
  i18nKey_web_1361: '角色删除成功',
  i18nKey_web_1362: '角色删除失败，请重试',
  i18nKey_web_1363: '请选择所属类型',
  i18nKey_web_1364: '角色创建成功',
  i18nKey_web_1365: '网络异常，请稍后重试',
  i18nKey_web_1366: '角色修改成功',
  i18nKey_web_1367: '修改角色',
  i18nKey_web_1368: '超级管理员',
  i18nKey_web_1369: '平台管理员',
  i18nKey_web_1370: '园区管理员',
  i18nKey_web_1371: '平台普通角色',
  i18nKey_web_1372: '园区普通角色',
  i18nKey_web_1373: '园区安防角色',
  i18nKey_web_1374: '园区物业角色',
  i18nKey_web_1375: '园区保洁角色',
  i18nKey_web_1376: '角色名称已经存在',
  i18nKey_web_1377: '确定要删除吗？',
  i18nKey_web_1378: '运行',
  i18nKey_web_1379: '泊梯',
  i18nKey_web_1380: '检修',
  i18nKey_web_1381: '客梯',
  i18nKey_web_1382: '栋电梯',
  i18nKey_web_1383: '灯控',
  i18nKey_web_1384: '设备类型',
  i18nKey_web_1385: '设备地址',
  i18nKey_web_1386: '加载中',
  i18nKey_web_1387: '更新中',
  i18nKey_web_1388: '输入设备名称',
  i18nKey_web_1389: '播放中',
  i18nKey_web_1390: '每个大屏',
  i18nKey_web_1391: '启用状态',
  i18nKey_web_1392: '删除该策略',
  i18nKey_web_1393: '个策略的生效时间不能为空',
  i18nKey_web_1394: '个策略中亮灯规则',
  i18nKey_web_1395: '的开启时间不能为空',
  i18nKey_web_1396: '添加失败',
  i18nKey_web_1397: '确定是否要删除该方案？',
  i18nKey_web_1398: '下发方案成功',
  i18nKey_web_1399: '保存断路器配置成功！',
  i18nKey_web_1400: '更改成功',
  i18nKey_web_1401: '客户列表',
  i18nKey_web_1402: '人员编号',
  i18nKey_web_1403: '近一周到访园区',
  i18nKey_web_1404: '近一周凌晨到访园区',
  i18nKey_web_1405: '用户周期',
  i18nKey_web_1406: '习惯下单时间',
  i18nKey_web_1407: '耗时最短',
  i18nKey_web_1408: '耗时最长',
  i18nKey_web_1409: '用时',
  i18nKey_web_1410: '件',
  i18nKey_web_1411: '设施',
  i18nKey_web_1412: '设施数量',
  i18nKey_web_1413: '平均处理时长',
  i18nKey_web_1414: '设施编码',
  i18nKey_web_1415: '选择分项维度',
  i18nKey_web_1416: '请选择分项信息',
  i18nKey_web_1417: '级分项',
  i18nKey_web_1418: '请选择等级',
  i18nKey_web_1419: '严重告警',
  i18nKey_web_1420: '仪表表号：',
  i18nKey_web_1421: '严重',
  i18nKey_web_1422: '用水',
  i18nKey_web_1423: '上年',
  i18nKey_web_1424: '能耗',
  i18nKey_web_1425: '自动抄表',
  i18nKey_web_1426: '手工抄表',
  i18nKey_web_1427: '历史热力',
  i18nKey_web_1428: '全屏',
  i18nKey_web_1429: '分项',
  i18nKey_web_1430: '本月用能',
  i18nKey_web_1431: '异常类型',
  i18nKey_web_1432: '用电时间',
  i18nKey_web_1433: '统计时间',
  i18nKey_web_1434: '本月实际电能耗（',
  i18nKey_web_1435: '上月实际电能耗（',
  i18nKey_web_1436: '用电详情',
  i18nKey_web_1437: '故障信息',
  i18nKey_web_1438: '从',
  i18nKey_web_1439: '该故障重复发生了1次，累积时长',
  i18nKey_web_1440: '小时，可造成全年浪费',
  i18nKey_web_1441: '处理状态',
  i18nKey_web_1442: '诊断处理',
  i18nKey_web_1443: '故障名称',
  i18nKey_web_1444: '请输入故障名称',
  i18nKey_web_1445: '故障等级',
  i18nKey_web_1446: '故障统计',
  i18nKey_web_1447: '故障数量',
  i18nKey_web_1448: '故障总数',
  i18nKey_web_1449: '重新上传',
  i18nKey_web_1450: '请输入电表表号',
  i18nKey_web_1451: '请选择所属分项',
  i18nKey_web_1452: '请选择抄表期间',
  i18nKey_web_1453: '请选择是否异常',
  i18nKey_web_1454: '表号',
  i18nKey_web_1455: '点读数（',
  i18nKey_web_1456: '本期用电量（',
  i18nKey_web_1457: '品牌名称',
  i18nKey_web_1458: '异常个数',
  i18nKey_web_1459: '异常总数',
  i18nKey_web_1460: '数据丢失',
  i18nKey_web_1461: '总表',
  i18nKey_web_1462: '备用',
  i18nKey_web_1463: '存在',
  i18nKey_web_1464: '条数据，0条异常数据，请确认提交吗',
  i18nKey_web_1465: '录入',
  i18nKey_web_1466: '数据状态',
  i18nKey_web_1467: '请选择权数据的状态',
  i18nKey_web_1468: '本次抄表时间',
  i18nKey_web_1469: '录入抄表数据',
  i18nKey_web_1470: '你已录入',
  i18nKey_web_1471: '与上期数据对比，有',
  i18nKey_web_1472: '条数据异常',
  i18nKey_web_1473: '确定提交吗',
  i18nKey_web_1474: '你已导入',
  i18nKey_web_1475: '条数据',
  i18nKey_web_1476: '有',
  i18nKey_web_1477: '未抄总数',
  i18nKey_web_1478: '本期未抄',
  i18nKey_web_1479: '已抄',
  i18nKey_web_1480: '本期用水量（',
  i18nKey_web_1481: '导出失败，当前页面无数据',
  i18nKey_web_1482: '黄龙万科',
  i18nKey_web_1483: '用',
  i18nKey_web_1484: '水',
  i18nKey_web_1485: '量',
  i18nKey_web_1486: '总用电量',
  i18nKey_web_1487: '总用',
  i18nKey_web_1488: '点位信息图',
  i18nKey_web_1489: '机房功率（',
  i18nKey_web_1490: '年在整个项目中，已累计为您发现了共计',
  i18nKey_web_1491: '的节能潜力',
  i18nKey_web_1492: '南区',
  i18nKey_web_1493: '机房',
  i18nKey_web_1494: '北区',
  i18nKey_web_1495: '变量',
  i18nKey_web_1496: '能效比',
  i18nKey_web_1497: '实时功率',
  i18nKey_web_1498: '单元号',
  i18nKey_web_1499: '月耗',
  i18nKey_web_1500: '选择开始时间',
  i18nKey_web_1501: '选择结束时间',
  i18nKey_web_1502: '选择图标',
  i18nKey_web_1503: '场景锁开关',
  i18nKey_web_1504: '成功开启',
  i18nKey_web_1505: '成功关闭',
  i18nKey_web_1506: '批量删除',
  i18nKey_web_1507: '确定删除户型',
  i18nKey_web_1508: '户型',
  i18nKey_web_1509: '房号',
  i18nKey_web_1510: '新增楼栋单元及公区',
  i18nKey_web_1511: '请输入楼栋单元',
  i18nKey_web_1512: '新增房号',
  i18nKey_web_1513: '请输入房号',
  i18nKey_web_1514: '请选择单元楼栋添加房号',
  i18nKey_web_1515: '请选择要删除的房间',
  i18nKey_web_1516: '视频暂停中',
  i18nKey_web_1517: '视频巡逻结束',
  i18nKey_web_1518: '播放暂停1-----',
  i18nKey_web_1519: '暂停1---',
  i18nKey_web_1520: '定时器',
  i18nKey_web_1521: '播放暂停3----',
  i18nKey_web_1522: '播放---',
  i18nKey_web_1523: '定时轮流播放巡逻组----',
  i18nKey_web_1524: '当前播放---第',
  i18nKey_web_1525: '个巡逻组-----第',
  i18nKey_web_1526: '个摄像机',
  i18nKey_web_1527: '新增巡逻路线',
  i18nKey_web_1528: '巡逻路线列表',
  i18nKey_web_1529: '实时视频',
  i18nKey_web_1530: '选择开始日期时间',
  i18nKey_web_1531: '选择结束日期时间',
  i18nKey_web_1532: '关联历史视频',
  i18nKey_web_1533: '截取成功',
  i18nKey_web_1534: '视频截取失败',
  i18nKey_web_1535: '基础设置',
  i18nKey_web_1536: '提前',
  i18nKey_web_1537: '个月折扣',
  i18nKey_web_1538: '年及以上',
  i18nKey_web_1539: '个月',
  i18nKey_web_1540: '创建成功',
  i18nKey_web_1541: '请选择时间范围',
  i18nKey_web_1542: '选择企业',
  i18nKey_web_1543: '奖励成功',
  i18nKey_web_1544: '原价',
  i18nKey_web_1545: '管理员',
  i18nKey_web_1546: '暂停歇业开始时间',
  i18nKey_web_1547: '暂停歇业结束时间',
  i18nKey_web_1548: '提前退订',
  i18nKey_web_1549: '可提前多少天退订',
  i18nKey_web_1550: '请输入地址纬度',
  i18nKey_web_1551: '附加设施',
  i18nKey_web_1552: '增加附加设施',
  i18nKey_web_1553: '例如：首小时15元（前15分钟免费），此后每小时10元',
  i18nKey_web_1554: '封顶。',
  i18nKey_web_1555: '附近停车场',
  i18nKey_web_1556: '例子：平安',
  i18nKey_web_1557: '首小时15元（前15分钟免费），此后每小时10元',
  i18nKey_web_1558: '广东省深圳市福田区',
  i18nKey_web_1559: '新增办公空间',
  i18nKey_web_1560: '修改办公空间',
  i18nKey_web_1561: '办公空间详情',
  i18nKey_web_1562: '新增加附加设施菜单路径：企业服务',
  i18nKey_web_1563: '会议室管理',
  i18nKey_web_1564: '增值服务！',
  i18nKey_web_1565: '已存在！',
  i18nKey_web_1566: '地址经纬度输入需用英文逗号分隔',
  i18nKey_web_1567: '且不能大于20个字符',
  i18nKey_web_1568: '请输入工位编号',
  i18nKey_web_1569: '请选择需要上传的',
  i18nKey_web_1570: '图！',
  i18nKey_web_1571: '的图片文件！',
  i18nKey_web_1572: '添加消息推送人仅限云城',
  i18nKey_web_1573: '注册用户使用，',
  i18nKey_web_1574: '消息推送信息展示在云城',
  i18nKey_web_1575: '消息列表中',
  i18nKey_web_1576: '手机号：',
  i18nKey_web_1577: '申请人信息',
  i18nKey_web_1578: '请输入申请人姓名',
  i18nKey_web_1579: '申请时间',
  i18nKey_web_1580: '请选择申请时间',
  i18nKey_web_1581: '已拒绝',
  i18nKey_web_1582: '申请人姓名',
  i18nKey_web_1583: '申请人电话',
  i18nKey_web_1584: '使用时间',
  i18nKey_web_1585: '已驳回',
  i18nKey_web_1586: '审批人',
  i18nKey_web_1587: '审核时间',
  i18nKey_web_1588: '黑名单布控',
  i18nKey_web_1589: '访客邀请',
  i18nKey_web_1590: '访客信息',
  i18nKey_web_1591: '请输入访客姓名',
  i18nKey_web_1592: '请输入工作单位',
  i18nKey_web_1593: '请选择访客',
  i18nKey_web_1594: '上海',
  i18nKey_web_1595: '日期：',
  i18nKey_web_1596: '人员占比统计',
  i18nKey_web_1597: '人员数排行',
  i18nKey_web_1598: '前日',
  i18nKey_web_1599: '请选择更新日期',
  i18nKey_web_1600: '请选择设备状态',
  i18nKey_web_1601: '园区到访',
  i18nKey_web_1602: '已有策略',
  i18nKey_web_1603: '新建策略',
  i18nKey_web_1604: '请选择空间位置',
  i18nKey_web_1605: '策略状态',
  i18nKey_web_1606: '请选择策略状态',
  i18nKey_web_1607: '未开启',
  i18nKey_web_1608: '受访地址',
  i18nKey_web_1609: '请输入访客电话',
  i18nKey_web_1610: '授权时间段',
  i18nKey_web_1611: '拜访事由',
  i18nKey_web_1612: '过',
  i18nKey_web_1613: '通行类型',
  i18nKey_web_1614: '请选择厂商名称',
  i18nKey_web_1615: '是否删除？',
  i18nKey_web_1616: '请输入接待人姓名',
  i18nKey_web_1617: '疫情防控信息',
  i18nKey_web_1618: '邀请成功',
  i18nKey_web_1619: '访客记录',
  i18nKey_web_1620: '访客邀请数',
  i18nKey_web_1621: '访客邀请统计',
  i18nKey_web_1622: '近30天',
  i18nKey_web_1623: '园区访客邀请排行',
  i18nKey_web_1624: '访客统计',
  i18nKey_web_1625: '园区历史总邀请',
  i18nKey_web_1626: '历史总邀请',
  i18nKey_web_1627: '单位（',
  i18nKey_web_1628: '当前占比',
  i18nKey_web_1629: '上一年同期占比',
  i18nKey_web_1630: '用电分项配置',
  i18nKey_web_1631: '说明：本级占上一级能耗比超出',
  i18nKey_web_1632: '为异常。总能耗本级用能取低压进线柜',
  i18nKey_web_1633: '分项能耗流向图',
  i18nKey_web_1634: '请选择设施有效期',
  i18nKey_web_1635: '简称',
  i18nKey_web_1636: '请输入责任人',
  i18nKey_web_1637: '所有权',
  i18nKey_web_1638: '请输入设备编号',
  i18nKey_web_1639: '使用中',
  i18nKey_web_1640: '维修中',
  i18nKey_web_1641: '是否删除',
  i18nKey_web_1642: '请输姓名或手机号',
  i18nKey_web_1643: '乘车路线',
  i18nKey_web_1644: '剩余次数',
  i18nKey_web_1645: '支付失败',
  i18nKey_web_1646: '无乘车记录，不需要执行导出操作！',
  i18nKey_web_1647: '报表？',
  i18nKey_web_1648: '套餐价格',
  i18nKey_web_1649: '缴费时间',
  i18nKey_web_1650: '生效日期',
  i18nKey_web_1651: '到期日期',
  i18nKey_web_1652: '请选择出场时间',
  i18nKey_web_1653: '颜色',
  i18nKey_web_1654: '临停缴费',
  i18nKey_web_1655: '查询列表',
  i18nKey_web_1656: '京',
  i18nKey_web_1657: '津',
  i18nKey_web_1658: '沪',
  i18nKey_web_1659: '渝',
  i18nKey_web_1660: '新',
  i18nKey_web_1661: '苏',
  i18nKey_web_1662: '浙',
  i18nKey_web_1663: '黑',
  i18nKey_web_1664: '湘',
  i18nKey_web_1665: '皖',
  i18nKey_web_1666: '鲁',
  i18nKey_web_1667: '赣',
  i18nKey_web_1668: '冀',
  i18nKey_web_1669: '豫',
  i18nKey_web_1670: '云',
  i18nKey_web_1671: '辽',
  i18nKey_web_1672: '鄂',
  i18nKey_web_1673: '桂',
  i18nKey_web_1674: '甘',
  i18nKey_web_1675: '晋',
  i18nKey_web_1676: '蒙',
  i18nKey_web_1677: '陕',
  i18nKey_web_1678: '吉',
  i18nKey_web_1679: '闽',
  i18nKey_web_1680: '贵',
  i18nKey_web_1681: '粤',
  i18nKey_web_1682: '青',
  i18nKey_web_1683: '藏',
  i18nKey_web_1684: '川',
  i18nKey_web_1685: '宁',
  i18nKey_web_1686: '琼',
  i18nKey_web_1687: '台',
  i18nKey_web_1688: '港',
  i18nKey_web_1689: '澳',
  i18nKey_web_1690: '大于8',
  i18nKey_web_1691: '大于12',
  i18nKey_web_1692: '大于24',
  i18nKey_web_1693: '累计',
  i18nKey_web_1694: '大于1200',
  i18nKey_web_1695: '大于1600',
  i18nKey_web_1696: '累计停车缴费',
  i18nKey_web_1697: '基本属性',
  i18nKey_web_1698: '出现过',
  i18nKey_web_1699: '次违停',
  i18nKey_web_1700: '累计临停缴费',
  i18nKey_web_1701: '元，可以推荐月卡给他哦',
  i18nKey_web_1702: '进场',
  i18nKey_web_1703: '加班比较多，近一个月累计10天晚上20',
  i18nKey_web_1704: '后离开园区',
  i18nKey_web_1705: '很早投入工作了，近一个月累计10天早上8',
  i18nKey_web_1706: '前进入园区',
  i18nKey_web_1707: '近一个月最早',
  i18nKey_web_1708: '进入园区',
  i18nKey_web_1709: '最晚',
  i18nKey_web_1710: '离开园区',
  i18nKey_web_1711: '前一月',
  i18nKey_web_1712: '去年9月',
  i18nKey_web_1713: '在场人数',
  i18nKey_web_1714: '承租人',
  i18nKey_web_1715: '数据类型',
  i18nKey_web_1716: '确',
  i18nKey_web_1717: '定',
  i18nKey_web_1718: '移除成功',
  i18nKey_web_1719: '移除失败',
  i18nKey_web_1720: '修改失败！',
  i18nKey_web_1721: '下载失败',
  i18nKey_web_1722: '年龄占比',
  i18nKey_web_1723: '深圳万科云城',
  i18nKey_web_1724: '产办-分期',
  i18nKey_web_1725: '设计公社-分区',
  i18nKey_web_1726: '云创2-分区',
  i18nKey_web_1727: '泊寓-分期',
  i18nKey_web_1728: '泊寓1-分区',
  i18nKey_web_1729: '商业-分期',
  i18nKey_web_1730: '东里-分区',
  i18nKey_web_1731: '西里-分区',
  i18nKey_web_1732: '深圳云城',
  i18nKey_web_1733: '永庆坊',
  i18nKey_web_1734: '层',
  i18nKey_web_1735: '区',
  i18nKey_web_1736: '客流',
  i18nKey_web_1737: '搜索引擎',
  i18nKey_web_1738: '客流统计',
  i18nKey_web_1739: '请输入票',
  i18nKey_web_1740: '如有多个停靠站，请按如下格式录入：万科云城',
  i18nKey_web_1741: '宝安中心',
  i18nKey_web_1742: '前海湾',
  i18nKey_web_1743: '宝安机场',
  i18nKey_web_1744: '请选择有效日期',
  i18nKey_web_1745: '自动换算价格）',
  i18nKey_web_1746: '请输入小于1',
  i18nKey_web_1747: '之间的价格',
  i18nKey_web_1748: '请选择使用的巴士班次',
  i18nKey_web_1749: '修改套餐',
  i18nKey_web_1750: '查看套餐',
  i18nKey_web_1751: '例如',
  i18nKey_web_1752: '请输入扫码设备',
  i18nKey_web_1753: '例如：大巴限乘50人',
  i18nKey_web_1754: '编码',
  i18nKey_web_1755: '同步中',
  i18nKey_web_1756: '发送短信',
  i18nKey_web_1757: '门禁监控',
  i18nKey_web_1758: '该楼宇全部门禁',
  i18nKey_web_1759: '打开',
  i18nKey_web_1760: '门禁控制',
  i18nKey_web_1761: '布控实况',
  i18nKey_web_1762: '更多动态',
  i18nKey_web_1763: '图片上传',
  i18nKey_web_1764: '新增人脸布控',
  i18nKey_web_1765: '新增车辆布控',
  i18nKey_web_1766: '消警',
  i18nKey_web_1767: '转设施告警',
  i18nKey_web_1768: '非法入侵',
  i18nKey_web_1769: '保安队长-呼叫中',
  i18nKey_web_1770: '挂断',
  i18nKey_web_1771: '电话呼叫',
  i18nKey_web_1772: '拨号',
  i18nKey_web_1773: '常用',
  i18nKey_web_1774: '联系人',
  i18nKey_web_1775: '告警图片',
  i18nKey_web_1776: '告警头像',
  i18nKey_web_1777: '上传列表（',
  i18nKey_web_1778: '查看关联视频',
  i18nKey_web_1779: '只能上传10',
  i18nKey_web_1780: '内的图片',
  i18nKey_web_1781: '格式的视频',
  i18nKey_web_1782: '人员选择',
  i18nKey_web_1783: '人员热力图',
  i18nKey_web_1784: '没有人员',
  i18nKey_web_1785: '发送',
  i18nKey_web_1786: '发送短信内容不能为空',
  i18nKey_web_1787: '短信发送成功',
  i18nKey_web_1788: '发送短信失败人员有',
  i18nKey_web_1789: '请留意以上人员接收不到短信',
  i18nKey_web_1790: '万科智慧园区运营中心提醒】万科智慧园区运营中心提醒您：',
  i18nKey_web_1791: '谢谢！',
  i18nKey_web_1792: '短信接收人员不能为空',
  i18nKey_web_1793: '人员热力后台返回',
  i18nKey_web_1794: '通讯录',
  i18nKey_web_1795: '查找联系人',
  i18nKey_web_1796: '通话中',
  i18nKey_web_1797: '视频通话',
  i18nKey_web_1798: '园区楼栋',
  i18nKey_web_1799: '路灯管理',
  i18nKey_web_1800: '路灯名称',
  i18nKey_web_1801: '路灯状态',
  i18nKey_web_1802: '打开门禁',
  i18nKey_web_1803: '关闭门禁',
  i18nKey_web_1804: '警告',
  i18nKey_web_1805: '有可疑目标人员出现在',
  i18nKey_web_1806: '派人查看',
  i18nKey_web_1807: '设备位置',
  i18nKey_web_1808: '电表编码',
  i18nKey_web_1809: '电表位置',
  i18nKey_web_1810: '告警数',
  i18nKey_web_1811: '电损率',
  i18nKey_web_1812: '分类名称',
  i18nKey_web_1813: '请输入分类名称',
  i18nKey_web_1814: '分类名称不能为空',
  i18nKey_web_1815: '禁止',
  i18nKey_web_1816: '请输入手机号或姓名搜索',
  i18nKey_web_1817: '请选择门锁编号！',
  i18nKey_web_1818: '闭',
  i18nKey_web_1819: '导入文件',
  i18nKey_web_1820: '导入失败，第',
  i18nKey_web_1821: '行数据有错误，请检查，稍后再次提交导入',
  i18nKey_web_1822: '请输入正数',
  i18nKey_web_1823: '最多保留两位小数',
  i18nKey_web_1824: '请选择仪表类别',
  i18nKey_web_1825: '当前位置没有房间',
  i18nKey_web_1826: '用电数据统计',
  i18nKey_web_1827: '千瓦小时',
  i18nKey_web_1828: '用水数据统计',
  i18nKey_web_1829: '立方米',
  i18nKey_web_1830: '手机号码格式不正确',
  i18nKey_web_1831: '证件配置',
  i18nKey_web_1832: '车牌：',
  i18nKey_web_1833: '超过',
  i18nKey_web_1834: '次，不能办理月卡',
  i18nKey_web_1835: '次，不能进入车场',
  i18nKey_web_1836: '车牌号不能为空',
  i18nKey_web_1837: '手机号不能为空',
  i18nKey_web_1838: '备注不能为空',
  i18nKey_web_1839: '共开门',
  i18nKey_web_1840: '提示：视频画面延迟约5-10',
  i18nKey_web_1841: '请耐心等待。',
  i18nKey_web_1842: '运行时间：',
  i18nKey_web_1843: '号梯',
  i18nKey_web_1844: '电机转速：',
  i18nKey_web_1845: '主轴温度：',
  i18nKey_web_1846: '曳引绳拉伸度：',
  i18nKey_web_1847: '音量控制',
  i18nKey_web_1848: '共有照明回路：',
  i18nKey_web_1849: '个，',
  i18nKey_web_1850: '开关',
  i18nKey_web_1851: '暂无',
  i18nKey_web_1852: '确定要全部',
  i18nKey_web_1853: '个回路吗',
  i18nKey_web_1854: '确定要',
  i18nKey_web_1855: '这',
  i18nKey_web_1856: '个回路',
  i18nKey_web_1857: '更改失败，请稍后再试',
  i18nKey_web_1858: '开启成功',
  i18nKey_web_1859: '关闭成功',
  i18nKey_web_1860: '更改失败',
  i18nKey_web_1861: '确认取消吗？',
  i18nKey_web_1862: '二亮一循环',
  i18nKey_web_1863: '网关名称2',
  i18nKey_web_1864: '感应延迟微亮',
  i18nKey_web_1865: '请选择开启时间',
  i18nKey_web_1866: '请输入时',
  i18nKey_web_1867: '请输入分',
  i18nKey_web_1868: '请输入手机精确搜索匹配',
  i18nKey_web_1869: '请选择提醒人',
  i18nKey_web_1870: '已添加',
  i18nKey_web_1871: '今日实际电能耗（',
  i18nKey_web_1872: '上周实际电能耗（',
  i18nKey_web_1873: '补录成功',
  i18nKey_web_1874: '设备名称，商',
  i18nKey_web_1875: '录入文件',
  i18nKey_web_1876: '抄表数据不能为空',
  i18nKey_web_1877: '电表表号',
  i18nKey_web_1878: '上次抄表时间',
  i18nKey_web_1879: '用电数据统计（',
  i18nKey_web_1880: '用水数据统计（',
  i18nKey_web_1881: '自动抄表详情',
  i18nKey_web_1882: '缺失数据',
  i18nKey_web_1883: '异常数据',
  i18nKey_web_1884: '补录',
  i18nKey_web_1885: '用电量（',
  i18nKey_web_1886: '相电压（',
  i18nKey_web_1887: '相电流（',
  i18nKey_web_1888: '选择所属分项',
  i18nKey_web_1889: '请选择抄表日期',
  i18nKey_web_1890: '请输入抄表读数',
  i18nKey_web_1891: '设备：',
  i18nKey_web_1892: '更新失败',
  i18nKey_web_1893: '选择设备',
  i18nKey_web_1894: '栋',
  i18nKey_web_1895: '室',
  i18nKey_web_1896: '表格模板',
  i18nKey_web_1897: '请填写必要信息',
  i18nKey_web_1898: '请输入合法手机号',
  i18nKey_web_1899: '停车场区域',
  i18nKey_web_1900: '解绑',
  i18nKey_web_1901: '新增公区设备',
  i18nKey_web_1902: '设备信息更新成功',
  i18nKey_web_1903: '确定删除设备',
  i18nKey_web_1904: '添加设备成功',
  i18nKey_web_1905: '两室一厅',
  i18nKey_web_1906: '重置成功',
  i18nKey_web_1907: '删除后不可恢复，确定要删除吗？',
  i18nKey_web_1908: '人员总览',
  i18nKey_web_1909: '人员对比趋势',
  i18nKey_web_1910: '近两周',
  i18nKey_web_1911: '最近12周',
  i18nKey_web_1912: '最近12个月',
  i18nKey_web_1913: '视频画面延迟约5',
  i18nKey_web_1914: '请耐心等待',
  i18nKey_web_1915: '摄像头加载中',
  i18nKey_web_1916: '获取摄像头资源失败',
  i18nKey_web_1917: '基础信息',
  i18nKey_web_1918: '默认（次日00',
  i18nKey_web_1919: '刷新）',
  i18nKey_web_1920: '勾选字段后，该字段在小程序',
  i18nKey_web_1921: '的用户可见',
  i18nKey_web_1922: '通知方式',
  i18nKey_web_1923: '云城',
  i18nKey_web_1924: '消息',
  i18nKey_web_1925: '受访人信息',
  i18nKey_web_1926: '受访人姓名',
  i18nKey_web_1927: '受访人地址',
  i18nKey_web_1928: '访问信息',
  i18nKey_web_1929: '来访事由',
  i18nKey_web_1930: '同行人信息采集',
  i18nKey_web_1931: '开启后，用户可以输入通行人人数',
  i18nKey_web_1932: '访客查看登记记录',
  i18nKey_web_1933: '免审批',
  i18nKey_web_1934: '开启后访客可以免除审批流程直接获取通行权限',
  i18nKey_web_1935: '导航',
  i18nKey_web_1936: '开启后访客可以查看园区导航信息',
  i18nKey_web_1937: '未到访人数',
  i18nKey_web_1938: '已到访人数',
  i18nKey_web_1939: '被访公司总数(家)',
  i18nKey_web_1940: '请输入服务器',
  i18nKey_web_1941: '或域名',
  i18nKey_web_1942: '新增成功！',
  i18nKey_web_1943: '您的浏览器不支持',
  i18nKey_web_1944: '临停缴费金额',
  i18nKey_web_1945: '套餐缴费金额',
  i18nKey_web_1946: '请输入汽车品牌',
  i18nKey_web_1947: '奥迪',
  i18nKey_web_1948: '奔驰',
  i18nKey_web_1949: '宝马',
  i18nKey_web_1950: '本田',
  i18nKey_web_1951: '丰田',
  i18nKey_web_1952: '大众',
  i18nKey_web_1953: '福特',
  i18nKey_web_1954: '别克',
  i18nKey_web_1955: '近一个月缴费',
  i18nKey_web_1956: '累计缴费',
  i18nKey_web_1957: '车牌超过6个选项',
  i18nKey_web_1958: '已为您按最优显示',
  i18nKey_web_1959: '品牌超过6个选项',
  i18nKey_web_1960: '颜色超过6个选项',
  i18nKey_web_1961: '车牌和品牌超过6个选项',
  i18nKey_web_1962: '车牌和颜色超过6个选项',
  i18nKey_web_1963: '品牌和颜色超过6个选项',
  i18nKey_web_1964: '车牌，品牌和颜色超过6个选项',
  i18nKey_web_1965: '回头客人数',
  i18nKey_web_1966: '人',
  i18nKey_web_1967: '回头客到访次数',
  i18nKey_web_1968: '人次）',
  i18nKey_web_1969: '直接访问',
  i18nKey_web_1970: '起点',
  i18nKey_web_1971: '近一周',
  i18nKey_web_1972: '开始',
  i18nKey_web_1973: '选择的日期范围大于',
  i18nKey_web_1974: '天，请减少选择再重试',
  i18nKey_web_1975: '时间选择',
  i18nKey_web_1976: '请选择筛选时间',
  i18nKey_web_1977: '密码错误，请重新输入',
  i18nKey_web_1978: '获取人员详情接口出错',
  i18nKey_web_1979: '客流人次',
  i18nKey_web_1980: '岁',
  i18nKey_web_1981: '起始日期',
  i18nKey_web_1982: '天数',
  i18nKey_web_1983: '日历详细信息',
  i18nKey_web_1984: '复制',
  i18nKey_web_1985: '未分配设备',
  i18nKey_web_1986: '复制成功',
  i18nKey_web_1987: '请输入承租方名称',
  i18nKey_web_1988: '请选择店铺状态',
  i18nKey_web_1989: '共计',
  i18nKey_web_1990: '元旦',
  i18nKey_web_1991: '与去年相比',
  i18nKey_web_1992: '暂无设备',
  i18nKey_web_1993: '删除房间',
  i18nKey_web_1994: '确定删除房间',
  i18nKey_web_1995: '场景开关',
  i18nKey_web_1996: '确定批量删除这些场景吗？',
  i18nKey_web_1997: '确定删除场景',
  i18nKey_web_1998: '请选择区域名称',
  i18nKey_web_1999: '选择公司',
  i18nKey_web_2000: '请选择需要添加的公司',
  i18nKey_web_2001: '请选择正确的公司',
  i18nKey_web_2002: '请选择活动',
  i18nKey_web_2003: '请选择起始日期',
  i18nKey_web_2004: '活动不允许为空',
  i18nKey_web_2005: '请选择节日',
  i18nKey_web_2006: '一级标题',
  i18nKey_web_2007: '二级标题1',
  i18nKey_web_2008: '三级标题1',
  i18nKey_web_2009: '二级标题2',
  i18nKey_web_2010: '三级标题2',
  i18nKey_web_2011: '新建',

  "i18nKey_web_2012": '更新门禁权限',
  "i18nKey_web_2013": '公司地址变更后，请务必更新门禁权限，否则影响员工通行。',
  "i18nKey_web_2014": '确认更新门禁权限？',
  "i18nKey_web_2015": '门禁权限更新成功！',
  "i18nKey_web_2016": '委派人',
  "i18nKey_web_2017": '责任人',
  "i18nKey_web_2018": '业务工单',
  "i18nKey_web_2019": '报事工单',
  "i18nKey_web_2020": '最近一年',
  "i18nKey_web_2021": '事件数',
  "i18nKey_web_2022": '工单数',
  "i18nKey_web_2023": '事件占比',
  "i18nKey_web_2024": '工单占比',
  "i18nKey_web_2025": '全部',
  "i18nKey_web_2026": '备注',
  "i18nKey_web_2027": '工单详情',
  "i18nKey_web_2028": '查询',
  "i18nKey_web_2029": '重置',
  "i18nKey_web_2030": '无符合查询条件的数据',
  "i18nKey_web_2031": '报事时间',
  "i18nKey_web_2032": '提示',
  "i18nKey_web_2033": '单位每平方米用能',
  "i18nKey_web_2034": '用能',
  "i18nKey_web_2035": '冷',
  "i18nKey_web_2036": '本期用电量',
  "i18nKey_web_2037": '本期用水量',
  "i18nKey_web_2038": '本期用冷量',
  "i18nKey_web_2039": '环比同期用电量',
  "i18nKey_web_2040": '环比同期用水量',
  "i18nKey_web_2041": '环比同期用冷量',
  "i18nKey_web_2042": '上一年同期用电量',
  "i18nKey_web_2043": '上一年同期用水量',
  "i18nKey_web_2044": '上一年同期用冷量',
  "i18nKey_web_2045": '汇总',
  "i18nKey_web_2046": '用电排行',
  "i18nKey_web_2047": '用水排行',
  "i18nKey_web_2048": '用冷排行',
  "i18nKey_web_2049": '暂无可导出的数据',
  "i18nKey_web_2050": '周',
  "i18nKey_web_2051": '用电',
  "i18nKey_web_2052": '用水',
  "i18nKey_web_2053": '用冷',
  "i18nKey_web_2054": '日',
  "i18nKey_web_2055": '月',
  "i18nKey_web_2056": 'Echarts图表',
  "i18nKey_web_2057": '故障趋势分析',
  "i18nKey_web_2058": '导出数据成功',
  "i18nKey_web_2059": '导出数据失败',
  "i18nKey_web_2060": '万',
  "i18nKey_web_2061": '事件数(个)',
  "i18nKey_web_2062": '导出',
  "i18nKey_web_2063": '导入',
  "i18nKey_web_2064": '关键词',
  "i18nKey_web_2065": '请输入',
  "i18nKey_web_2066": '表号,设备名称,商/租户名称',
  "i18nKey_web_2067": '位置',
  "i18nKey_web_2068": '选择',
  "i18nKey_web_2069": '请选择位置',
  "i18nKey_web_2070": '所属分项',
  "i18nKey_web_2071": '抄表期间',
  "i18nKey_web_2072": '请选择时间',
  "i18nKey_web_2073": '权责月',
  "i18nKey_web_2074": '未录入',
  "i18nKey_web_2075": '已提交',
  "i18nKey_web_2076": '已结账',
  "i18nKey_web_2077": '是否异常',
  "i18nKey_web_2078": '正常',
  "i18nKey_web_2079": '异常',
  "i18nKey_web_2080": '序号',
  "i18nKey_web_2081": '确定',
  "i18nKey_web_2082": '本次抄表读数',
  "i18nKey_web_2083": '抄表期间',
  "i18nKey_web_2084": '采集频率',
  "i18nKey_web_2085": '天',
  "i18nKey_web_2086": '商/租户名称',
  "i18nKey_web_2087": '商铺号',
  "i18nKey_web_2088": '设备名称',
  "i18nKey_web_2089": '详情',
  "i18nKey_web_2090": '退回',
  "i18nKey_web_2091": '操作',
  "i18nKey_web_2092": '手工抄表详情',
  "i18nKey_web_2093": '本次抄表时间不能为空',
  "i18nKey_web_2094": '权责月不能为空',
  "i18nKey_web_2095": '抄表期间不能为空',
  "i18nKey_web_2096": '抄表导入',
  "i18nKey_web_2097": '未抄总数(个)',
  "i18nKey_web_2098": '本期未抄/已抄(个)',
  "i18nKey_web_2099": '数据异常(个)',
  "i18nKey_web_3000": '提交成功',
  "i18nKey_web_3001": '正在导出，请稍候...',
  "i18nKey_web_3002": '刷新',
  "i18nKey_web_3003": '冷量',
  "i18nKey_web_3004": '请输入电表表号、智能表表号、电表名称、商/租户名称',
  "i18nKey_web_3005": '年',
  "i18nKey_web_3006": '自定义',
  "i18nKey_web_3007": '请输入水表表号、智能表表号、水表名称、商/租户名称',
  "i18nKey_web_3008": '请输入冷量表表号、智能表表号、冷量表名称、商/租户名称',
  "i18nKey_web_3009": '点读数（kWh）',
  "i18nKey_web_3010": '水表表号',
  "i18nKey_web_3011": '水表序号',
  "i18nKey_web_3012": '倍率',
  "i18nKey_web_3013": '冷量表表号',
  "i18nKey_web_3014": '冷量表序号',
  "i18nKey_web_3015": '用冷量',
  "i18nKey_web_3016": '异常总数(个)',
  "i18nKey_web_3017": '数据丢失(个)',
  "i18nKey_web_3018": '数据异常(个)',
  "i18nKey_web_3019": '总表(个)',
  "i18nKey_web_3020": '在线(个)',
  "i18nKey_web_3021": '离线(个)',
  "i18nKey_web_3022": '备用(个)',
  "i18nKey_web_3023": '自动抄表详情列表',
  "i18nKey_web_3024": '总体概况',
  "i18nKey_web_3025": '告警工单',
  "i18nKey_web_3026": '关联摄像头',
  "i18nKey_web_3027": '电梯故障分析',
  "i18nKey_web_3028": '电梯位置',
  "i18nKey_web_3029": '电梯状态',
  "i18nKey_web_3030": '消防返回',
  "i18nKey_web_3031": '消防专业',
  "i18nKey_web_3032": '故障',
  "i18nKey_web_3033": '检修中',
  "i18nKey_web_3034": '泊梯中',
  "i18nKey_web_3035": '上行',
  "i18nKey_web_3036": '下行',
  "i18nKey_web_3037": '运行中',
  "i18nKey_web_3038": '电梯名称',
  "i18nKey_web_3039": '事件描述',
  "i18nKey_web_3040": '事件状态',
  "i18nKey_web_3041": '发生位置',
  "i18nKey_web_3042": '发生时间',
  "i18nKey_web_3043": '子系统',
  "i18nKey_web_3044": '事件详情',
  "i18nKey_web_3045": '提示: 视频画面延迟约5~10秒,请耐心等待',
  "i18nKey_web_3046": '摄像头加载中,请稍后...',
  "i18nKey_web_3047": '当前时间:',
  "i18nKey_web_3048": '无',
  "i18nKey_web_3049": '视频画面延迟约5-10秒，请耐心等待',
  "i18nKey_web_3050": '输入摄像机别名',
  "i18nKey_web_3051": '查看摄像头',
  "i18nKey_web_3052": '已关联摄像头不能超出三个!',
  "i18nKey_web_3053": '摄像头',
  "i18nKey_web_3054": '告警列表',
  "i18nKey_web_3055": '工单列表',
  "i18nKey_web_3056": '已完成',
  "i18nKey_web_3057": '取消',
  "i18nKey_web_3058": '工单状态',
  "i18nKey_web_3059": '最多关联3个摄像头',
  "i18nKey_web_3060": '修改时间',
  "i18nKey_web_3061": '编辑',
  "i18nKey_web_3062": '电梯总数',
  "i18nKey_web_3063": '载货电梯',
  "i18nKey_web_3064": '乘客电梯',
  "i18nKey_web_3065": '其他',
  "i18nKey_web_3066": '请输入电梯名称',
  "i18nKey_web_3067": '全部位置',
  "i18nKey_web_3068": '请选择电梯状态',
  "i18nKey_web_3069": '当前楼层',
  "i18nKey_web_3070": '故障代码',
  "i18nKey_web_3071": '更新时间',
  "i18nKey_web_3072": '紧急告警',
  "i18nKey_web_3073": '重要告警',
  "i18nKey_web_3074": '一般告警',
  "i18nKey_web_3075": '提示事件',
  "i18nKey_web_3076": '请选择告警等级',
  "i18nKey_web_3077": '待处理',
  "i18nKey_web_3078": '请选择事件状态',
  "i18nKey_web_3079": '请选择工单状态',
  "i18nKey_web_3080": '工单编号',
  "i18nKey_web_3081": '请输入工单编号',
  "i18nKey_web_3082": '请输入工单责任人',
  "i18nKey_web_3083": '派发时间',
  "i18nKey_web_3084": '请输入派发时间',
  "i18nKey_web_3085": '任务委派人',
  "i18nKey_web_3086": '状态',
  "i18nKey_web_3087": '已挂起',
  "i18nKey_web_3088": '已取消',
  "i18nKey_web_3089": '事件编号/报警编号',
  "i18nKey_web_3090": '事件名称/报事类型',
  "i18nKey_web_3091": '告警时间/上报时间',
  "i18nKey_web_3092": '关联系统详情',
  "i18nKey_web_3093": '关联工单编号',
  "i18nKey_web_3094": '完工描述',
  "i18nKey_web_3095": '图片详情',
  "i18nKey_web_3096": '安防态势',
  "i18nKey_web_3097": '重大事件',
  "i18nKey_web_3098": '设备分类',
  "i18nKey_web_3099": '请输入设备分类',
  "i18nKey_web_3100": '设施总分类',
  "i18nKey_web_3101": '个',
  "i18nKey_web_3102": '在线',
  "i18nKey_web_3103": '离线',
  "i18nKey_web_3104": '设施等级',
  "i18nKey_web_3105": '设施系统',
  "i18nKey_web_3106": '故障描述',
  "i18nKey_web_3107": '时',
  "i18nKey_web_3108": '分',
  "i18nKey_web_3109": '秒',
  "i18nKey_web_3110": '查看',
  "i18nKey_web_3111": '设施工单',
  "i18nKey_web_3112": '人员库选择',
  "i18nKey_web_3113": '工种',
  "i18nKey_web_3114": '工号',
  "i18nKey_web_3115": '电话',
  "i18nKey_web_3116": '不能导出空数据',
  "i18nKey_web_3117": '导出数据不能超过200条,请先输入条件筛选',
  "i18nKey_web_3118": '物业',
  "i18nKey_web_3119": '新建工单成功',
  "i18nKey_web_3120": '总数',
  "i18nKey_web_3121": '报修事件',
  "i18nKey_web_3122": '同步数据',
  "i18nKey_web_3123": '请选择',
  "i18nKey_web_3124": '楼栋',
  "i18nKey_web_3125": '季',
  "i18nKey_web_3126": '7天',
  "i18nKey_web_3127": '30天',
  "i18nKey_web_3128": '总计',
  "i18nKey_web_3129": '电表详情',
  "i18nKey_web_3130": '报表',
  "i18nKey_web_3131": '图表',
  "i18nKey_web_3132": '读数',
  "i18nKey_web_3133": '用电量（kWh ）',
  "i18nKey_web_3134": '用冷量（kWh ）',
  "i18nKey_web_3135": '用冷数据统计 （kW ）',
  "i18nKey_web_3136": '电表详情列表',
  "i18nKey_web_3137": '仪表序号',
  "i18nKey_web_3138": '用水数据统计 （m³ ）',
  "i18nKey_web_3139": '冷量表',
  "i18nKey_web_3140": '业务事件',
  "i18nKey_web_3141": '设施事件',
  "i18nKey_web_3142": '报事事件',
  "i18nKey_web_3143": '推送方式',
  "i18nKey_web_3144": '选择日期时间',
  "i18nKey_web_3145": '派发类型',
  "i18nKey_web_3146": '请输入内容',
  "i18nKey_web_3147": '请选择派发类型',
  "i18nKey_web_3148": '请选择开始时间',
  "i18nKey_web_3149": '请选择结束时间',
  "i18nKey_web_3150": '工单派发成功',
  "i18nKey_web_3151": 'MQDCapp任务',
  "i18nKey_web_3152": '推送人员',
  "i18nKey_web_3153": '请输入姓名和手机号',
  "i18nKey_web_3154": '头像',
  "i18nKey_web_3155": '安防',
  "i18nKey_web_3156": '物业',
  "i18nKey_web_3157": '保洁',
  "i18nKey_web_3158": '提示文字',
  "i18nKey_web_3159": '导出',
  "i18nKey_web_3160": '注册时间',
  "i18nKey_web_3161": '确定删除吗？',
  "i18nKey_web_3162": '请输入冷量表名称',
  "i18nKey_web_3163": '设备运行参数',
  "i18nKey_web_3164": '历史设备运行参数',
  "i18nKey_web_3165": '流量',
  "security_4_gis": "确定",
  "security_12_gis": "取消",
  "i18nKey_web_3166": '请输入区域或设备名称',
  "i18nKey_web_3167": '进入（人次）',
  "i18nKey_web_3168": '离开（人次）',
  "i18nKey_web_3169": '入口',
  "i18nKey_web_3170": '中部',
  "i18nKey_web_3171": '出口',
  "i18nKey_web_3172": '区域交界',
  "i18nKey_web_3173": '受访楼栋',
  "i18nKey_web_3174": '请输入受访楼栋',
  "i18nKey_web_3175": '洗手间',
  "i18nKey_web_3176": '请先选择洗手间',
  "i18nKey_web_3177": '报事类型',
  "i18nKey_web_3178": '请选择报事类型',
  "i18nKey_web_3179": '洗手间名称',
  "i18nKey_web_3180": '报事事项',
  "i18nKey_web_3181": '报事中心',
  "i18nKey_web_3182": '告警中心',
  "i18nKey_web_3183": '每次最多导出1万条记录',
  "i18nKey_web_3184": '车辆统计',
  "i18nKey_web_3185": '电梯已到达',
  "i18nKey_web_3186": '电梯已关闭',
  "i18nKey_web_3187": '电梯关闭中',
  "i18nKey_web_3188": '电梯开门中',
  "security_0_gis": "您访问的页面不存在，请检查地址栏链接后，重新访问",
  "security_1_gis": "对不起，您暂无任何权限，请联系管理员",
  "security_2_gis": "视频缓冲中",
  "security_3_gis": "获取视频资源失败",
  "security_5_gis": "注意：适用于列表页无嵌套组件的表格页面",
  "security_6_gis": "需要传入类名",
  "security_7_gis": "以查找所需",
  "security_8_gis": "元素",
  "security_9_gis": "原",
  "security_10_gis": "需要设置初始值属性",
  "security_11_gis": "需要设置最外层标签",
  "security_13_gis": "确认",
  "security_14_gis": "输入关键字进行过滤",
  "security_15_gis": "选择摄像机",
  "security_16_gis": "摄像机",
  "security_17_gis": "人员热力",
  "security_18_gis": "安保人员",
  "security_19_gis": "安保岗亭",
  "security_20_gis": "数据异常",
  "security_21_gis": "无符合查询条件的数据",
  "security_22_gis": "编辑",
  "security_23_gis": "最近一天",
  "security_24_gis": "重大黑名单访客人员警告",
  "security_25_gis": "点击查看",
  "security_26_gis": "选择关联设备",
  "security_27_gis": "搜索",
  "security_28_gis": "设备名称",
  "security_29_gis": "设备编号",
  "security_30_gis": "车辆驶出记录",
  "security_31_gis": "辆",
  "security_32_gis": "消防事件趋势",
  "security_33_gis": "累计充电总量",
  "security_34_gis": "累计放电总量",
  "security_35_gis": "巡查人员详情",
  "security_36_gis": "姓名",
  "security_37_gis": "工号",
  "security_38_gis": "电话",
  "security_39_gis": "职务",
  "security_40_gis": "在岗情况",
  "security_41_gis": "工单总数",
  "security_42_gis": "处理中",
  "security_43_gis": "待处理",
  "security_44_gis": "保存",
  "security_45_gis": "综合态势-全国",
  "security_46_gis": "任务描述",
  "security_47_gis": "责任人",
  "security_48_gis": "状态",
  "security_49_gis": "操作",
  "security_50_gis": "统计卡片配置",
  "security_51_gis": "当前页面为",
  "security_52_gis": "请选择不多于",
  "security_53_gis": "张统计卡片",
  "security_54_gis": "播放记录",
  "security_55_gis": "打卡记录",
  "security_56_gis": "导出",
  "security_57_gis": "记录详情",
  "security_58_gis": "摄像机名称",
  "security_59_gis": "应打卡人",
  "security_60_gis": "打卡时间",
  "security_61_gis": "未打卡",
  "security_62_gis": "某某巡逻组",
  "security_63_gis": "播放时间",
  "security_64_gis": "结束时间",
  "security_65_gis": "时间",
  "security_66_gis": "立即查看",
  "security_67_gis": "新建",
  "security_68_gis": "告警列表",
  "security_69_gis": "按时间",
  "security_70_gis": "按相似度",
  "security_71_gis": "摄像机001",
  "security_72_gis": "布控摄像机",
  "security_73_gis": "欢迎使用",
  "security_74_gis": "平台，请修改密码后登录您的账号",
  "security_75_gis": "手机号",
  "security_76_gis": "请输入手机号",
  "security_77_gis": "验证码",
  "security_78_gis": "请输入验证码",
  "security_79_gis": "秒",
  "security_80_gis": "获取验证码",
  "security_81_gis": "密码",
  "security_82_gis": "位数字和字母的组合",
  "security_83_gis": "提交",
  "security_84_gis": "返回登录",
  "security_85_gis": "请输入正确的手机号码",
  "security_86_gis": "请输入正确的验证码",
  "security_87_gis": "请输入密码",
  "security_88_gis": "密码不能包含空格",
  "security_89_gis": "密码强度不符合",
  "security_90_gis": "至少8位",
  "security_91_gis": "包括数字",
  "security_92_gis": "大、小写字母",
  "security_93_gis": "特殊字符中的至少三类",
  "security_94_gis": "请输入手机号！",
  "security_95_gis": "请输入正确的手机号！",
  "security_96_gis": "修改密码成功，请重新登录",
  "security_97_gis": "您已成功修改密码，请重新登录平台。",
  "security_98_gis": "秒后会自动跳转到",
  "security_99_gis": "登录页",
  "security_100_gis": "前往",
  "security_101_gis": "恭喜您，注册成功！",
  "security_102_gis": "请您联系管理员获取相关服务权限。",
  "security_103_gis": "平台，请重置密码",
  "security_104_gis": "使用手机号验证",
  "security_105_gis": "使用域账号验证",
  "security_106_gis": "域账号",
  "security_107_gis": "请输入域账号",
  "security_108_gis": "旧密码",
  "security_109_gis": "请输入旧密码",
  "security_110_gis": "新密码",
  "security_111_gis": "请输入新密码",
  "security_112_gis": "确认新密码",
  "security_113_gis": "请再次输入新密码",
  "security_114_gis": "确认修改",
  "security_115_gis": "请输入手机号码",
  "security_116_gis": "请输入原始密码",
  "security_117_gis": "新密码不可与旧密码相同",
  "security_118_gis": "请再次输入密码",
  "security_119_gis": "两次输入的密码不一致",
  "security_120_gis": "密码长度必须是8到20位且包含数字和字母",
  "security_121_gis": "请输入手机号码或域账号",
  "security_122_gis": "请输入姓名或工号",
  "security_123_gis": "人员库选择",
  "security_124_gis": "工种",
  "security_125_gis": "实时空气质量指数",
  "security_126_gis": "优",
  "security_127_gis": "良",
  "security_128_gis": "轻度污染",
  "security_129_gis": "中度污染",
  "security_130_gis": "重度污染",
  "security_131_gis": "严重污染",
  "security_132_gis": "提示",
  "security_133_gis": "任务",
  "security_134_gis": "亿",
  "security_135_gis": "千万",
  "security_136_gis": "百万",
  "security_137_gis": "万",
  "security_138_gis": "最小化",
  "security_139_gis": "最大化",
  "security_140_gis": "上传图片",
  "security_141_gis": "删除图片",
  "security_142_gis": "提示：只支持",
  "security_143_gis": "格式的图片上传，上传图片大小不超过3",
  "security_144_gis": "图片大小",
  "security_145_gis": "图片类型",
  "security_146_gis": "生成时间",
  "security_147_gis": "提示文字",
  "security_148_gis": "上传图片格式不正确，请重新上传",
  "security_149_gis": "上传的图片名称不规范，只允许包含数字、字母、下划线、横线",
  "security_150_gis": "上传图片不能大于3",
  "security_151_gis": "请重新上传",
  "security_152_gis": "上传失败",
  "security_153_gis": "公区耗能",
  "security_154_gis": "子公区能耗",
  "security_155_gis": "前一天",
  "security_156_gis": "后一天",
  "security_157_gis": "去年",
  "security_158_gis": "上月",
  "security_159_gis": "月",
  "security_160_gis": "上一年",
  "security_161_gis": "年",
  "security_162_gis": "总电耗",
  "security_163_gis": "公区电耗",
  "security_164_gis": "公区-",
  "security_165_gis": "预测值",
  "security_166_gis": "目标值",
  "security_167_gis": "占比",
  "security_168_gis": "较",
  "security_169_gis": "同比",
  "security_170_gis": "同期",
  "security_171_gis": "环比",
  "security_172_gis": "查看详情",
  "security_173_gis": "昨天放电总量",
  "security_174_gis": "代表异常",
  "security_175_gis": "昨天充电总量",
  "security_176_gis": "昨天节省费用",
  "security_177_gis": "元",
  "security_178_gis": "储能统计",
  "security_179_gis": "全部",
  "security_180_gis": "北门",
  "security_181_gis": "东门",
  "security_182_gis": "日",
  "security_183_gis": "周",
  "security_184_gis": "数量",
  "security_185_gis": "开关设置",
  "security_186_gis": "开灯",
  "security_187_gis": "关灯",
  "security_188_gis": "亮度显示",
  "security_189_gis": "应用",
  "security_190_gis": "离线",
  "security_191_gis": "指令下发成功，状态更新约30秒左右，请耐心等待",
  "security_192_gis": "微亮",
  "security_193_gis": "中亮",
  "security_194_gis": "高亮",
  "security_195_gis": "已选迎宾屏",
  "security_196_gis": "全选",
  "security_197_gis": "上移",
  "security_198_gis": "下移",
  "security_199_gis": "移出",
  "security_200_gis": "已选摄像机",
  "security_201_gis": "图片已保存至",
  "security_202_gis": "图片截取失败",
  "security_203_gis": "错误码：",
  "security_204_gis": "车牌号码",
  "security_205_gis": "车主类型",
  "security_206_gis": "车主",
  "security_207_gis": "车主姓名",
  "security_208_gis": "布控时段",
  "security_209_gis": "至",
  "security_210_gis": "车辆轨迹",
  "security_211_gis": "查看",
  "security_212_gis": "关联事件",
  "security_213_gis": "修改",
  "security_214_gis": "重新布控",
  "security_215_gis": "该任务已产生告警",
  "security_216_gis": "不能修改！",
  "security_217_gis": "该任务启动中",
  "security_218_gis": "请暂停后修改！",
  "security_219_gis": "未启动",
  "security_220_gis": "已启动",
  "security_221_gis": "已停止",
  "security_222_gis": "启动",
  "security_223_gis": "停止",
  "security_224_gis": "查看警告",
  "security_225_gis": "误判",
  "security_226_gis": "事件上报",
  "security_227_gis": "时间：",
  "security_228_gis": "地点：",
  "security_229_gis": "来源：",
  "security_230_gis": "级别",
  "security_231_gis": "请选择级别",
  "security_232_gis": "类型",
  "security_233_gis": "请选择类型",
  "security_234_gis": "描述",
  "security_235_gis": "上报",
  "security_236_gis": "确认此次告警为误判，此次告警信息失效",
  "security_237_gis": "视频巡逻人工上报",
  "security_238_gis": "温度",
  "security_239_gis": "湿度",
  "security_240_gis": "甲醛",
  "security_241_gis": "二氧化碳",
  "security_242_gis": "负氧离子",
  "security_243_gis": "个",
  "security_244_gis": "噪音",
  "security_245_gis": "有机挥发物",
  "security_246_gis": "室外",
  "security_247_gis": "室内",
  "security_248_gis": "环境空间",
  "security_249_gis": "条",
  "security_250_gis": "框",
  "security_251_gis": "环境指数",
  "security_252_gis": "路灯统计",
  "security_253_gis": "资产统计",
  "security_254_gis": "使用率",
  "security_255_gis": "任务进度",
  "security_256_gis": "环境空间标题",
  "security_257_gis": "布控时间不能为空",
  "security_258_gis": "布控时间必须大于等于当前时间",
  "security_259_gis": "任务名称不能为空",
  "security_260_gis": "请上传图像",
  "security_261_gis": "请选择人员",
  "security_262_gis": "该人员没有图像，不能进行布控",
  "security_263_gis": "请选择关联的人脸库",
  "security_264_gis": "描述不能为空",
  "security_265_gis": "请选择布控类型",
  "security_266_gis": "请选择布控摄像机",
  "security_267_gis": "创建成功",
  "security_268_gis": "修改成功",
  "security_269_gis": "确认放弃？",
  "security_270_gis": "物业公告",
  "security_271_gis": "大道当然",
  "security_272_gis": "合伙奋斗。",
  "security_273_gis": "离开舒适区",
  "security_274_gis": "是前进的第一步。",
  "security_275_gis": "越万里关山",
  "security_276_gis": "闯千百难关。",
  "security_277_gis": "斗士身后无退路",
  "security_278_gis": "勇气前方无险途。",
  "security_279_gis": "团结协作",
  "security_280_gis": "全力以赴。",
  "security_281_gis": "成大事不在于力量的大小",
  "security_282_gis": "而在于能坚持多久。",
  "security_283_gis": "要为成功找方法",
  "security_284_gis": "不为失败找理由。",
  "security_285_gis": "任重道远",
  "security_286_gis": "志在必得。",
  "security_287_gis": "共识、共创、共担、共享。",
  "security_288_gis": "只有不快的斧",
  "security_289_gis": "没有劈不开的柴。",
  "security_290_gis": "当前浏览器不支持",
  "security_291_gis": "该布控车辆数据已被删除，不支持修改或重新布控，请重新创建布控",
  "security_292_gis": "车牌号码不能为空",
  "security_293_gis": "新建库",
  "security_294_gis": "删除库",
  "security_295_gis": "公安黑名单",
  "security_296_gis": "更多",
  "security_297_gis": "常驻人员",
  "security_298_gis": "工作人员",
  "security_299_gis": "黑名单",
  "security_300_gis": "临时",
  "security_301_gis": "面试",
  "security_302_gis": "其它",
  "security_303_gis": "追踪任务",
  "security_304_gis": "请输入任务或人员名称",
  "security_305_gis": "追踪时间",
  "security_306_gis": "告警次数",
  "security_307_gis": "请输入最小告警次数",
  "security_308_gis": "请输入最大告警次数",
  "security_309_gis": "查询",
  "security_310_gis": "重置",
  "security_311_gis": "开启时间",
  "security_312_gis": "警告次数",
  "security_313_gis": "查看轨迹",
  "security_314_gis": "中国",
  "security_315_gis": "上海区域",
  "security_316_gis": "上海市",
  "security_317_gis": "金桥万创",
  "security_318_gis": "楼",
  "security_319_gis": "负二层",
  "security_320_gis": "停车场",
  "security_321_gis": "人员移动到",
  "security_322_gis": "最大告警数不能小于或等于最小告警数",
  "security_323_gis": "事件",
  "security_324_gis": "刷新",
  "security_325_gis": "开",
  "security_326_gis": "关",
  "security_327_gis": "事件处理时长",
  "security_328_gis": "平均处理时长：",
  "security_329_gis": "耗时最短",
  "security_330_gis": "耗时最长",
  "security_331_gis": "排名",
  "security_332_gis": "用时",
  "security_333_gis": "分钟",
  "security_334_gis": "分",
  "security_335_gis": "天",
  "security_336_gis": "时",
  "security_337_gis": "公安",
  "security_338_gis": "园区自有",
  "security_339_gis": "件",
  "security_340_gis": "总计数量",
  "security_341_gis": "较昨天",
  "security_342_gis": "昨天",
  "security_343_gis": "较上周",
  "security_344_gis": "上周",
  "security_345_gis": "较上月",
  "security_346_gis": "访问来源",
  "security_347_gis": "故障与运行优化",
  "security_348_gis": "故障类型",
  "security_349_gis": "种",
  "security_350_gis": "故障个数",
  "security_351_gis": "节能潜力",
  "security_352_gis": "告警分类统计",
  "security_353_gis": "告警类型",
  "security_354_gis": "告警来源",
  "security_355_gis": "告警级别",
  "security_356_gis": "发生数量",
  "security_357_gis": "处理数量",
  "security_358_gis": "平均处理时长",
  "security_359_gis": "视频巡逻统计",
  "security_360_gis": "请登录您的账号",
  "security_361_gis": "登录",
  "security_362_gis": "注册",
  "security_363_gis": "账号登录",
  "security_364_gis": "扫码登录",
  "security_365_gis": "账号",
  "security_366_gis": "请输入账号或者手机号",
  "security_367_gis": "记住当前账号",
  "security_368_gis": "忘记密码",
  "security_369_gis": "立即登录",
  "security_370_gis": "二维码已失效",
  "security_371_gis": "下载",
  "security_372_gis": "云城",
  "security_373_gis": "扫描二维码",
  "security_374_gis": "扫描下载云城",
  "security_375_gis": "安卓版）",
  "security_376_gis": "请输入账号或手机号",
  "security_377_gis": "平台，请您注册",
  "security_378_gis": "位字母、数字和特殊字符中至少2种的组合",
  "security_379_gis": "昵称",
  "security_380_gis": "请输入昵称",
  "security_381_gis": "立即注册",
  "security_382_gis": "密码长度为8",
  "security_383_gis": "位字符",
  "security_384_gis": "密码必须包括字母、数字、符号中至少2种",
  "security_385_gis": "昵称不能超过12个字符长度",
  "security_386_gis": "注册失败",
  "security_387_gis": "操作人",
  "security_388_gis": "请选择操作人",
  "security_389_gis": "操作时间",
  "security_390_gis": "请选择开始日期",
  "security_391_gis": "结束日期",
  "security_392_gis": "功能",
  "security_393_gis": "请选择功能",
  "security_394_gis": "请选择操作",
  "security_395_gis": "序号",
  "security_396_gis": "内容",
  "security_397_gis": "微软雅黑",
  "security_398_gis": "实时视频",
  "security_399_gis": "查看摄像机",
  "security_400_gis": "出生",
  "security_401_gis": "身份证号码：",
  "security_402_gis": "户籍地：",
  "security_403_gis": "信息来源：",
  "security_404_gis": "关闭",
  "security_405_gis": "布控任务已删除",
  "security_406_gis": "告警详情”中查看告警关联的历史视频、实时视频、图片等，地图上查看位置信息",
  "security_407_gis": "告警详情”的快捷操作栏，点击跳转到“历史轨迹”，查看所有历史",
  "security_408_gis": "派发告警的工单任务到巡逻保安的移动",
  "security_409_gis": "巡逻保安在移动",
  "security_410_gis": "查看任务及关联告警的详情信息：历史视频、实时视频、图片等",
  "security_411_gis": "巡逻保安根据告警的位置信息到事件现场实地查看",
  "security_412_gis": "处理工单任务，上传现场拍照，输入处理结果的文字信息",
  "security_413_gis": "归档告警的历史视频、抓拍图片，以及派发任务反馈的文字、图片，长期归档保存，以便后期回溯。",
  "security_414_gis": "告警图片",
  "security_415_gis": "暂无图片",
  "security_416_gis": "匹配",
  "security_417_gis": "发生时间",
  "security_418_gis": "事件等级",
  "security_419_gis": "发生地点",
  "security_420_gis": "无位置信息",
  "security_421_gis": "事件编码",
  "security_422_gis": "无设备信息",
  "security_423_gis": "事件来源",
  "security_424_gis": "设备类别",
  "security_425_gis": "持续时间",
  "security_426_gis": "设备编码",
  "security_427_gis": "处理人",
  "security_428_gis": "事件描述",
  "security_429_gis": "处理人手机",
  "security_430_gis": "分发任务",
  "security_431_gis": "共",
  "security_432_gis": "条任务",
  "security_433_gis": "新增",
  "security_434_gis": "第",
  "security_435_gis": "组",
  "security_436_gis": "图片裁剪",
  "security_437_gis": "取",
  "security_438_gis": "消",
  "security_439_gis": "告警编号",
  "security_440_gis": "告警状态",
  "security_441_gis": "告警等级",
  "security_442_gis": "发生位置",
  "security_443_gis": "告警处理人",
  "security_444_gis": "告警标题",
  "security_445_gis": "用户卡号",
  "security_446_gis": "关闭时间",
  "security_447_gis": "解决方案",
  "security_448_gis": "用户账号",
  "security_449_gis": "处理类型",
  "security_450_gis": "处理人固定电话",
  "security_451_gis": "资产编号",
  "security_452_gis": "告警描述",
  "security_453_gis": "事件动态",
  "security_454_gis": "通知人员，疏散楼宇人员",
  "security_455_gis": "拨打119，120",
  "security_456_gis": "与应急小组取得联系，开始部署撤离",
  "security_457_gis": "成都园区1栋1楼烟感器发生报警",
  "security_458_gis": "图片",
  "security_459_gis": "创建人：",
  "security_460_gis": "大",
  "security_461_gis": "小：",
  "security_462_gis": "视频",
  "security_463_gis": "完成",
  "security_464_gis": "请选择摄像机",
  "security_465_gis": "查看实时监控",
  "security_466_gis": "更多动态",
  "security_467_gis": "抓拍",
  "security_468_gis": "全屏播放",
  "security_469_gis": "历史视频",
  "security_470_gis": "历史视频播放结束",
  "security_471_gis": "布控匹配",
  "security_472_gis": "下载图片",
  "security_473_gis": "查看告警",
  "security_474_gis": "新增任务",
  "security_475_gis": "更多任务",
  "security_476_gis": "地点",
  "security_477_gis": "来源",
  "security_478_gis": "请输入描述",
  "security_479_gis": "视频巡逻手工上传",
  "security_480_gis": "罗伯特",
  "security_481_gis": "凌晨三点起来",
  "security_482_gis": "发现海棠花未眠",
  "security_483_gis": "明天11点30会议报告",
  "security_484_gis": "明天14点30会议报告",
  "security_485_gis": "任务列表",
  "security_486_gis": "重新委派",
  "security_487_gis": "查看更多",
  "security_488_gis": "告警",
  "security_489_gis": "员工",
  "security_490_gis": "访客",
  "security_491_gis": "物业保安",
  "security_492_gis": "单饼图统计",
  "security_493_gis": "人",
  "security_494_gis": "批量移出",
  "security_495_gis": "模型",
  "security_496_gis": "张三",
  "security_497_gis": "北方区域",
  "security_498_gis": "南方区域",
  "security_499_gis": "中西部区域",
  "security_500_gis": "路灯管理",
  "security_501_gis": "路灯名称",
  "security_502_gis": "路灯状态",
  "security_503_gis": "打开门禁",
  "security_504_gis": "关闭门禁",
  "security_505_gis": "警告",
  "security_506_gis": "相似度：",
  "security_507_gis": "有可疑目标人员出现在",
  "security_508_gis": "区域",
  "security_509_gis": "派人查看",
  "security_510_gis": "在线摄像机数",
  "security_511_gis": "输入摄像机别名",
  "security_512_gis": "在线打卡",
  "security_513_gis": "打卡成功",
  "security_514_gis": "打卡失败",
  "security_515_gis": "请选择状态",
  "security_516_gis": "标题",
  "security_517_gis": "必须输入事件标题",
  "security_518_gis": "请输入事件描述",
  "security_519_gis": "万科成都园区",
  "security_520_gis": "栋103",
  "security_521_gis": "事件上报成功",
  "security_522_gis": "事件上报失败",
  "security_523_gis": "巡逻组数",
  "security_524_gis": "新增巡逻组",
  "security_525_gis": "视频巡逻列表",
  "security_526_gis": "视频巡逻历史",
  "security_527_gis": "已加载完成",
  "security_528_gis": "选择标签",
  "security_529_gis": "请输入内容",
  "security_530_gis": "推荐标签",
  "security_531_gis": "客群分析",
  "security_532_gis": "客户列表",
  "security_533_gis": "人员编号",
  "security_534_gis": "人员类型",
  "security_535_gis": "近一周到访园区",
  "security_536_gis": "近一周凌晨到访园区",
  "security_537_gis": "用户周期",
  "security_538_gis": "习惯下单时间",
  "security_539_gis": "公司",
  "security_540_gis": "人脸追踪正在关闭中",
  "security_541_gis": "人员：",
  "security_542_gis": "追踪中",
  "security_543_gis": "关闭追踪",
  "security_544_gis": "监控截图（",
  "security_545_gis": "任务正在追踪中，跳转页面会导致追踪任务停止，确定需要跳转页面吗？",
  "security_546_gis": "每次只能追踪一个任务，确定需要放弃当前追踪任务并开启新的追踪任务吗？",
  "security_547_gis": "关闭追踪异常",
  "security_548_gis": "告警统计",
  "security_549_gis": "次",
  "security_550_gis": "设备告警排行",
  "security_551_gis": "处理日志",
  "security_552_gis": "暂时无符合条件的数据",
  "security_553_gis": "通知员工，疏散楼宇人员",
  "security_554_gis": "通知员工，疏散楼宇人员详情",
  "security_555_gis": "任务编号",
  "security_556_gis": "告警发生时间",
  "security_557_gis": "告警发生地点",
  "security_558_gis": "任务责任人",
  "security_559_gis": "任务状态",
  "security_560_gis": "待接收",
  "security_561_gis": "已接收",
  "security_562_gis": "已处理",
  "security_563_gis": "任务关闭时间",
  "security_564_gis": "任务委派时间",
  "security_565_gis": "任务委派人",
  "security_566_gis": "现场反馈",
  "security_567_gis": "任务备注",
  "security_568_gis": "现场图片",
  "security_569_gis": "现场视频",
  "security_570_gis": "对象名称",
  "security_571_gis": "上传时间",
  "security_572_gis": "上传人",
  "security_573_gis": "下一步",
  "security_574_gis": "转发成功",
  "security_575_gis": "转发失败",
  "security_576_gis": "米",
  "security_577_gis": "单位",
  "security_578_gis": "故障设备类型统计",
  "security_579_gis": "设备",
  "security_580_gis": "设备数量",
  "security_581_gis": "设备故障次数",
  "security_582_gis": "故障设备数",
  "security_583_gis": "今日",
  "security_584_gis": "昨日",
  "security_585_gis": "近一月",
  "security_586_gis": "近一年",
  "security_587_gis": "通行",
  "security_588_gis": "人次",
  "security_589_gis": "车次",
  "security_590_gis": "任务名称",
  "security_591_gis": "请输入任务名称",
  "security_592_gis": "布控状态",
  "security_593_gis": "请选择布控状态",
  "security_594_gis": "请先选择园区",
  "security_595_gis": "任务启动中，请勿删除",
  "security_596_gis": "确定删除吗？",
  "security_597_gis": "删除成功",
  "security_598_gis": "网络错误",
  "security_599_gis": "操作成功",
  "security_600_gis": "视频巡逻结束",
  "security_601_gis": "视频暂停中",
  "security_602_gis": "删除",
  "security_603_gis": "巡逻路线名称",
  "security_604_gis": "请输入巡逻路线名称",
  "security_605_gis": "巡逻路线说明",
  "security_606_gis": "请输入巡逻路线说明",
  "security_607_gis": "摄像机个数",
  "security_608_gis": "巡逻时间",
  "security_609_gis": "是否删除数据",
  "security_610_gis": "请选择删除数据",
  "security_611_gis": "是否删除勾选数据",
  "security_612_gis": "本周",
  "security_613_gis": "本月",
  "security_614_gis": "执行",
  "security_615_gis": "路线",
  "security_616_gis": "问题",
  "security_617_gis": "创建",
  "security_618_gis": "执行时间",
  "security_619_gis": "重复方式",
  "security_620_gis": "巡逻计划名称",
  "security_621_gis": "提醒",
  "security_622_gis": "已启用",
  "security_623_gis": "停用",
  "security_624_gis": "是否循环播放",
  "security_625_gis": "是",
  "security_626_gis": "否",
  "security_627_gis": "启用",
  "security_628_gis": "请选择园区",
  "security_629_gis": "输入名称或编号",
  "security_630_gis": "烟感",
  "security_631_gis": "门禁",
  "security_632_gis": "更多图层后台返回",
  "security_633_gis": "码",
  "security_634_gis": "后台返回",
  "security_635_gis": "请选择",
  "security_636_gis": "处理",
  "security_637_gis": "请选择处理方式",
  "security_638_gis": "屏蔽该设备的安防事件",
  "security_639_gis": "转设施告警",
  "security_640_gis": "更多图层",
  "security_641_gis": "短信",
  "security_642_gis": "事件播报",
  "security_643_gis": "条记录",
  "security_644_gis": "下载高清大图",
  "security_645_gis": "企业",
  "security_646_gis": "同行人",
  "security_647_gis": "前往布控",
  "security_648_gis": "人脸图片",
  "security_649_gis": "推送方式",
  "security_650_gis": "推送内容",
  "security_651_gis": "确定推送吗？",
  "security_652_gis": "推送成功",
  "security_653_gis": "服务器错误！",
  "security_654_gis": "派发方式",
  "security_655_gis": "派发人员",
  "security_656_gis": "派发人数不能超过10人！",
  "security_657_gis": "事件处理",
  "security_658_gis": "任务派发成功",
  "security_659_gis": "抓拍图片",
  "security_660_gis": "暂无告警图片",
  "security_661_gis": "暂无抓拍图片",
  "security_662_gis": "布控图片",
  "security_663_gis": "任务数量",
  "security_664_gis": "关联告警",
  "security_665_gis": "创建时间",
  "security_666_gis": "地图",
  "security_667_gis": "总数",
  "security_668_gis": "已关闭",
  "security_669_gis": "请输入告警编号",
  "security_670_gis": "请选择告警等级",
  "security_671_gis": "请选择告警状态",
  "security_672_gis": "请选择告警类型",
  "security_673_gis": "来源系统",
  "security_674_gis": "详情",
  "security_675_gis": "等级",
  "security_676_gis": "不能导出空数据",
  "security_677_gis": "消防事件列表",
  "security_678_gis": "已选",
  "security_679_gis": "请输入任务描述",
  "security_680_gis": "提示：派发任务处理完成后，事件将自动关闭。",
  "security_681_gis": "上一步",
  "security_682_gis": "新增任务完成",
  "security_683_gis": "任务分发",
  "security_684_gis": "选择人员",
  "security_685_gis": "委派任务",
  "security_686_gis": "距离",
  "security_687_gis": "请选择要指派任务的人",
  "security_688_gis": "请输入搜索关键字",
  "security_689_gis": "请输入任务内容",
  "security_690_gis": "新增任务成功",
  "security_691_gis": "操作失败",
  "security_692_gis": "基本信息",
  "security_693_gis": "是否启动",
  "security_694_gis": "通知人员",
  "security_695_gis": "新增人员",
  "security_696_gis": "提醒摄像机",
  "security_697_gis": "开启后将进行布控，如需关闭可以手动关闭",
  "security_698_gis": "关闭后不再布控，如需启用可以手动再次开启",
  "security_699_gis": "成功",
  "security_700_gis": "请输入任务名称并选择摄像机",
  "security_701_gis": "该人员在这个时间段没有行动轨迹",
  "security_702_gis": "导出轨迹成功",
  "security_703_gis": "轨迹",
  "security_704_gis": "请输入姓名或手机号",
  "security_705_gis": "轨迹查看",
  "security_706_gis": "轨迹时间段",
  "security_707_gis": "开始时间",
  "security_708_gis": "人员修改取消",
  "security_709_gis": "你还没有安装视频播放器，现在是否下载视频播放插件？下载好请自行安装",
  "security_710_gis": "无结果返回",
  "security_711_gis": "无结果",
  "security_712_gis": "轮巡机数量",
  "security_713_gis": "播放",
  "security_714_gis": "暂停",
  "security_715_gis": "展开",
  "security_716_gis": "结束",
  "security_717_gis": "摄像机号",
  "security_718_gis": "统计图名称",
  "security_719_gis": "充电总量",
  "security_720_gis": "放电总量",
  "security_721_gis": "节省费用",
  "security_722_gis": "超出目标值",
  "security_723_gis": "安防态势",
  "security_724_gis": "北京",
  "security_725_gis": "成都",
  "security_726_gis": "广州",
  "security_727_gis": "深圳",
  "security_728_gis": "告警总数",
  "security_729_gis": "重大事件",
  "security_730_gis": "待执行",
  "security_731_gis": "人脸追踪启动失败",
  "security_732_gis": "分析指标",
  "security_733_gis": "企业员工",
  "security_734_gis": "返回",
  "security_735_gis": "根据姓名搜索",
  "security_736_gis": "失信名单",
  "security_737_gis": "添加",
  "security_738_gis": "导入",
  "security_739_gis": "下载模板",
  "security_740_gis": "点击节点的时候选中节点",
  "security_741_gis": "默认展开所有节点",
  "security_742_gis": "添加人脸",
  "security_743_gis": "只支持",
  "security_744_gis": "格式图片且不大于3",
  "security_745_gis": "请输入姓名",
  "security_746_gis": "名单类型",
  "security_747_gis": "身份证号",
  "security_748_gis": "请输入身份证号",
  "security_749_gis": "性别",
  "security_750_gis": "男",
  "security_751_gis": "女",
  "security_752_gis": "居住地址",
  "security_753_gis": "请输入居住地址",
  "security_754_gis": "默认展开所有节",
  "security_755_gis": "请选择名单类型",
  "security_756_gis": "请输入正确的身份证号",
  "security_757_gis": "图片只支持",
  "security_758_gis": "图片不能大于3",
  "security_759_gis": "公安黑名单导入模板",
  "security_760_gis": "导入成功",
  "security_761_gis": "导入失败",
  "security_762_gis": "表",
  "security_763_gis": "删除失败",
  "security_764_gis": "请选择需要删除的对象",
  "security_765_gis": "确定删除吗",
  "security_766_gis": "请上传图片",
  "security_767_gis": "操作成功！",
  "security_768_gis": "告警统计趋势",
  "security_769_gis": "总趋势",
  "security_770_gis": "烟感告警",
  "security_771_gis": "温感告警",
  "security_772_gis": "手动报警器",
  "security_773_gis": "未处理火警",
  "security_774_gis": "真实火警",
  "security_775_gis": "误报",
  "security_776_gis": "设备统计",
  "security_777_gis": "近一周",
  "security_778_gis": "近半年",
  "security_779_gis": "暂无统计数据",
  "security_780_gis": "通讯主机",
  "security_781_gis": "离线（台）",
  "security_782_gis": "启用（台）",
  "security_783_gis": "总数量（台）",
  "security_784_gis": "感烟设备",
  "security_785_gis": "故障（台）",
  "security_786_gis": "无故障（台）",
  "security_787_gis": "感温设备",
  "security_788_gis": "上一周",
  "security_789_gis": "上一月",
  "security_790_gis": "上半年",
  "security_791_gis": "安防事件统计",
  "security_792_gis": "消防告警趋势",
  "security_793_gis": "安防告警类型占比",
  "security_794_gis": "事件状态统计",
  "security_795_gis": "安全告警趋势",
  "security_796_gis": "任务状态统计",
  "security_797_gis": "待处理告警数",
  "security_798_gis": "任务总数",
  "security_799_gis": "待处理任务数",
  "security_800_gis": "最近一周",
  "security_801_gis": "最近一个月",
  "security_802_gis": "最近三个月",
  "security_803_gis": "安全",
  "security_804_gis": "消防",
  "security_805_gis": "无数据",
  "security_806_gis": "总处理数",
  "security_807_gis": "总安全告警",
  "security_808_gis": "安全告警",
  "security_809_gis": "消防告警",
  "security_810_gis": "开启追踪",
  "security_811_gis": "开启追踪成功",
  "security_812_gis": "实时监控手工上传",
  "security_813_gis": "播放窗口替换模式",
  "security_814_gis": "替换选中",
  "security_815_gis": "替换最早",
  "security_816_gis": "截图上传失败！请重试",
  "security_817_gis": "单个轮巡时间",
  "security_818_gis": "新增计划",
  "security_819_gis": "巡逻计划表",
  "security_820_gis": "历史统计",
  "security_821_gis": "巡逻人",
  "security_822_gis": "请输入巡逻人",
  "security_823_gis": "启止时间",
  "security_824_gis": "是否完成全程打卡",
  "security_825_gis": "万科园区",
  "security_826_gis": "华北园区",
  "security_827_gis": "请选择到大区",
  "security_828_gis": "请选择到城市",
  "security_829_gis": "请选择到园区",
  "security_830_gis": "请选择时间段",
  "security_831_gis": "门禁监控",
  "security_832_gis": "该楼宇全部门禁",
  "security_833_gis": "打开",
  "security_834_gis": "发送短信",
  "security_835_gis": "门禁控制",
  "security_836_gis": "图片上传",
  "security_837_gis": "上传",
  "security_838_gis": "关联布控",
  "security_839_gis": "新增布控",
  "security_840_gis": "新增人脸布控",
  "security_841_gis": "新增车辆布控",
  "security_842_gis": "布控实况",
  "security_843_gis": "立即追踪",
  "security_844_gis": "提示告警",
  "security_845_gis": "不确定告警",
  "security_846_gis": "一般告警",
  "security_847_gis": "重要告警",
  "security_848_gis": "紧急告警",
  "security_849_gis": "电话呼叫",
  "security_850_gis": "拨号",
  "security_851_gis": "常用",
  "security_852_gis": "联系人",
  "security_853_gis": "消警",
  "security_854_gis": "事件推送",
  "security_855_gis": "类型为必选项",
  "security_856_gis": "保安队长-呼叫中",
  "security_857_gis": "挂断",
  "security_858_gis": "人员选择",
  "security_859_gis": "人员热力图",
  "security_860_gis": "没有人员",
  "security_861_gis": "发送",
  "security_862_gis": "发送短信内容不能为空",
  "security_863_gis": "短信发送成功",
  "security_864_gis": "发送短信失败人员有",
  "security_865_gis": "请留意以上人员接收不到短信",
  "security_866_gis": "万科智慧园区运营中心提醒】万科智慧园区运营中心提醒您：",
  "security_867_gis": "谢谢！",
  "security_868_gis": "短信接收人员不能为空",
  "security_869_gis": "人员热力后台返回",
  "security_870_gis": "保洁",
  "security_871_gis": "物业",
  "security_872_gis": "安保",
  "security_873_gis": "只看已选",
  "security_874_gis": "搜索内容",
  "security_875_gis": "收起",
  "security_876_gis": "头像",
  "security_877_gis": "推送人员",
  "security_878_gis": "请输入任务编号",
  "security_879_gis": "请输入任务责任人",
  "security_880_gis": "请选择任务状态",
  "security_881_gis": "请输入任务委派人",
  "security_882_gis": "处理结果",
  "security_883_gis": "转发",
  "security_884_gis": "业务事件",
  "security_885_gis": "设施事件",
  "security_886_gis": "请至少选择一个人员",
  "security_887_gis": "请勿输入特殊字符",
  "security_888_gis": "迎宾",
  "security_889_gis": "布控",
  "security_890_gis": "获取注册用户信息失败，请联系管理员！",
  "security_891_gis": "获取注册用户图片信息失败，请联系管理员！",
  "security_892_gis": "布控原因不能为空",
  "security_893_gis": "上传的第",
  "security_894_gis": "张图片姓名不能为空且姓名只能由长度在32位以内的汉字、字母或数字组成",
  "security_895_gis": "请选择通知人",
  "security_896_gis": "未启用",
  "security_897_gis": "已停用",
  "security_898_gis": "关闭成功",
  "security_899_gis": "启用成功",
  "security_900_gis": "停用成功",
  "security_901_gis": "确认停用",
  "security_902_gis": "身份证号码",
  "security_903_gis": "信息来源",
  "security_904_gis": "网络加载异常，请稍后重新操作",
  "security_905_gis": "网络加载异常",
  "security_906_gis": "暂无告警",
  "security_907_gis": "截图失败",
  "security_908_gis": "新增巡逻路线",
  "security_909_gis": "巡逻路线列表",
  "security_910_gis": "巡逻计划",
  "security_911_gis": "巡逻路线",
  "security_912_gis": "关闭视频巡逻",
  "security_913_gis": "是否暂停视频巡逻",
  "security_914_gis": "是否停止视频巡逻",
  "security_915_gis": "获取视频资源的请求失败",
  "security_916_gis": "从文件服务器上读取视频失败",
  "security_917_gis": "相似度",
  "security_918_gis": "关联摄像机",
  "security_919_gis": "米内",
  "security_920_gis": "已关联",
  "security_921_gis": "关联",
  "security_922_gis": "事件关联摄像机",
  "security_923_gis": "周围摄像机",
  "security_924_gis": "关联摄像机（",
  "security_925_gis": "选择开始日期时间",
  "security_926_gis": "选择结束日期时间",
  "security_927_gis": "视频截取",
  "security_928_gis": "结束时间需要大于开始时间",
  "security_929_gis": "视频回放",
  "security_930_gis": "回放",
  "security_931_gis": "请选择时间",
  "security_932_gis": "时长请勿小于30秒",
  "security_933_gis": "时长请勿超过一小时",
  "security_934_gis": "截取成功",
  "security_935_gis": "视频截取成功，请至左侧事件详情的关联视频下查看",
  "security_936_gis": "视频截取失败",
  "security_937_gis": "失败",
  "security_938_gis": "重大",
  "security_939_gis": "普通",
  "security_940_gis": "输入事件标题或设备名称或事件编号",
  "security_941_gis": "输入告警标题或设施别名或告警编号",
  "security_942_gis": "人脸追踪正在启动中",
  "security_943_gis": "新建人脸追踪",
  "security_944_gis": "新建人脸布控",
  "security_945_gis": "事件类型",
  "security_946_gis": "请选择事件类型",
  "security_947_gis": "事件级别",
  "security_948_gis": "请选择事件级别",
  "security_949_gis": "追踪",
  "security_950_gis": "人脸追踪",
  "security_951_gis": "白名单",
  "security_952_gis": "数据删除后不能再恢复，确认删除吗？",
  "security_953_gis": "数据删除成功",
  "security_954_gis": "网络加载异常，请稍后再试",
  "security_955_gis": "追踪时段",
  "security_956_gis": "追踪状态",
  "security_957_gis": "请选择追踪状态",
  "security_958_gis": "追踪摄像机",
  "security_959_gis": "请选择追踪摄像机",
  "security_960_gis": "是否删除布控任务所产生的的告警和任务",
  "security_961_gis": "人脸",
  "security_962_gis": "请筛选关联任务",
  "security_963_gis": "提醒时间段",
  "security_964_gis": "VIP到达提醒时段",
  "security_965_gis": "VIP到达提醒状态",
  "security_966_gis": "VIP到达提醒摄像机",
  "security_967_gis": "迎宾时段",
  "security_968_gis": "迎宾状态",
  "security_969_gis": "请选择迎宾状态",
  "security_970_gis": "进入迎宾大屏",
  "security_971_gis": "告警查看",
  "security_972_gis": "确定此次告警为误判，此次告警失效",
  "security_973_gis": "万科成都园区某某楼",
  "security_974_gis": "红色紧急警告",
  "security_975_gis": "橙色紧急警告",
  "security_976_gis": "黄色紧急警告",
  "security_977_gis": "蓝色紧急警告",
  "security_978_gis": "特别重大事故",
  "security_979_gis": "重大事故",
  "security_980_gis": "较大事故",
  "security_981_gis": "一般事故",
  "security_982_gis": "不能为空，请输入内容。",
  "security_983_gis": "图像",
  "security_984_gis": "本地上传",
  "security_985_gis": "从人员库选择",
  "security_986_gis": "告警阈值",
  "security_987_gis": "自动上报事件",
  "security_988_gis": "人员标签",
  "security_989_gis": "人员",
  "security_990_gis": "人员姓名",
  "security_991_gis": "布控类型",
  "security_992_gis": "事件备注",
  "security_993_gis": "标签",
  "security_994_gis": "时间不能为空",
  "security_995_gis": "最多只能上传5张图片",
  "security_996_gis": "图片大小不能超过2",
  "security_997_gis": "只能上传",
  "security_998_gis": "格式的图片",
  "security_999_gis": "任务名称不能超过50个字符",
  "security_1000_gis": "消防事件",
  "security_1001_gis": "安全事件",
  "security_1002_gis": "人脸追踪-",
  "security_1003_gis": "从车辆库选择",
  "security_1004_gis": "选择车辆",
  "security_1005_gis": "请输入车牌号码",
  "security_1006_gis": "车辆标签",
  "security_1007_gis": "请输入备注",
  "security_1008_gis": "车辆库选择",
  "security_1009_gis": "人员轨迹",
  "security_1010_gis": "该布控已启动，不能修改",
  "security_1011_gis": "该布控已停止，不能修改",
  "security_1012_gis": "没有关联事件",
  "security_1013_gis": "访客人员无该操作权限",
  "security_1014_gis": "暂无人员轨迹",
  "security_1015_gis": "网络加载异常，请稍后重试",
  "security_1016_gis": "误判成功",
  "security_1017_gis": "请选择事件等级",
  "security_1018_gis": "请填写事件描述",
  "security_1019_gis": "事件描述不能超过1000个字符哦",
  "security_1020_gis": "该事件状态为待处理，暂时不能上报哦",
  "security_1021_gis": "该事件状态为处理中，暂时不能上报哦",
  "security_1022_gis": "该事件状态为挂起，暂时不能上报哦",
  "security_1023_gis": "请输入巡逻时间",
  "security_1024_gis": "备注",
  "security_1025_gis": "请输入巡逻组描述",
  "security_1026_gis": "巡逻名称不能为空",
  "security_1027_gis": "巡逻名称不能超过200字符",
  "security_1028_gis": "时间不能为0",
  "security_1029_gis": "时间必须为不为零正整数",
  "security_1030_gis": "请至少选择一个摄像机",
  "security_1031_gis": "新建成功",
  "security_1032_gis": "预览",
  "security_1033_gis": "统计时间：",
  "security_1034_gis": "请选择日期范围",
  "security_1035_gis": "仅计划内的巡逻参与统计",
  "security_1036_gis": "执行（次）",
  "security_1037_gis": "摄像机（个）",
  "security_1038_gis": "视频打卡率",
  "security_1039_gis": "执行总时长（分钟）",
  "security_1040_gis": "路线（条）",
  "security_1041_gis": "告警事件及跟进状态",
  "security_1042_gis": "仅显示视频巡逻告警数量",
  "security_1043_gis": "巡逻计划历史",
  "security_1044_gis": "巡逻路线历史",
  "security_1045_gis": "全部巡逻历史",
  "security_1046_gis": "事件名称",
  "security_1047_gis": "事件状态",
  "security_1048_gis": "时间段",
  "security_1049_gis": "摄像机抓拍",
  "security_1050_gis": "历史轨迹",
  "security_1051_gis": "人员正在追踪中，跳转页面会导致追踪任务停止，确定需要跳转页面吗？",
  "security_1052_gis": "选择位置",
  "security_1053_gis": "国家",
  "security_1054_gis": "请选择国家",
  "security_1055_gis": "请选择区域",
  "security_1056_gis": "城市",
  "security_1057_gis": "请选择城市",
  "security_1058_gis": "园区",
  "security_1059_gis": "楼宇",
  "security_1060_gis": "请选择楼宇",
  "security_1061_gis": "楼层",
  "security_1062_gis": "请选择楼层",
  "security_1063_gis": "楼层空间",
  "security_1064_gis": "请选择楼层空间",
  "security_1065_gis": "房间位置",
  "security_1066_gis": "请选择房间位置",
  "security_1067_gis": "位置",
  "security_1068_gis": "园区不能为空",
  "security_1069_gis": "楼层不能为空",
  "security_1070_gis": "播放暂停1-----",
  "security_1071_gis": "暂停1---",
  "security_1072_gis": "定时器",
  "security_1073_gis": "播放暂停3----",
  "security_1074_gis": "播放---",
  "security_1075_gis": "定时轮流播放巡逻组----",
  "security_1076_gis": "当前播放---第",
  "security_1077_gis": "个巡逻组-----第",
  "security_1078_gis": "个摄像机",
  "security_1079_gis": "巡逻",
  "security_1080_gis": "视频图像",
  "security_1081_gis": "告警头像",
  "security_1082_gis": "关联视频",
  "security_1083_gis": "上传列表（",
  "security_1084_gis": "查看关联视频",
  "security_1085_gis": "只能上传10",
  "security_1086_gis": "内的图片",
  "security_1087_gis": "和",
  "security_1088_gis": "格式的视频",
  "security_1089_gis": "已完成",
  "security_1090_gis": "基础信息",
  "security_1091_gis": "告警时间",
  "security_1092_gis": "告警地点",
  "security_1093_gis": "告警关联信息",
  "security_1094_gis": "公司名称",
  "security_1095_gis": "位置信息",
  "security_1096_gis": "关联图片及视频",
  "security_1097_gis": "抓拍大图",
  "security_1098_gis": "被通知人员",
  "security_1099_gis": "事件提醒",
  "security_1100_gis": "告警处理",
  "security_1101_gis": "请选择处理状态",
  "security_1102_gis": "备注信息",
  "security_1103_gis": "信息发送成功",
  "security_1104_gis": "发送失败",
  "security_1105_gis": "提交成功",
  "security_1106_gis": "总能耗",
  "security_1107_gis": "用电",
  "security_1108_gis": "动力用电",
  "security_1109_gis": "其他用电",
  "security_1110_gis": "日总能耗",
  "security_1111_gis": "您当前选中设备存在故障，请先安排人处理！",
  "security_1112_gis": "您当前选中大屏已离线，指令不可达！",
  "security_1113_gis": "当前暂无节目，请先设置节目！",
  "security_1114_gis": "修改失败",
  "security_1115_gis": "消警成功",
  "security_1116_gis": "转设施告警成功",
  "security_1117_gis": "转设施告警失败",
  "security_1118_gis": "请选择推送人",
  "security_1119_gis": "派发任务",
  "security_1120_gis": "应急预案",
  "security_1121_gis": "轨迹追踪",
  "security_1122_gis": "历史事件",
  "security_1123_gis": "其他",
  "security_1124_gis": "该事件不存在或已删除",
  "security_1125_gis": "布控原因",
  "security_1126_gis": "请输入布控原因，不超过150个字符",
  "security_1127_gis": "注册用户",
  "security_1128_gis": "时段",
  "security_1129_gis": "图",
  "security_1130_gis": "是否推送弹窗",
  "security_1131_gis": "请输入物业公告",
  "security_1132_gis": "名字",
  "security_1133_gis": "手机号码",
  "security_1134_gis": "上传失败！",
  "security_1135_gis": "布控原因不能超过150个字符",
  "security_1136_gis": "提醒人",
  "security_1137_gis": "计划名称",
  "security_1138_gis": "请输入计划名称",
  "security_1139_gis": "请输入时",
  "security_1140_gis": "请输入分",
  "security_1141_gis": "请选择重复方式",
  "security_1142_gis": "添加提醒人",
  "security_1143_gis": "请输入手机号精确搜索匹配",
  "security_1144_gis": "请选择提醒人",
  "security_1145_gis": "已添加路线",
  "security_1146_gis": "请添加路线",
  "security_1147_gis": "移除",
  "security_1148_gis": "路线列表",
  "security_1149_gis": "新建路线",
  "security_1150_gis": "已添加",
  "security_1151_gis": "周一",
  "security_1152_gis": "周二",
  "security_1153_gis": "周三",
  "security_1154_gis": "周四",
  "security_1155_gis": "周五",
  "security_1156_gis": "周六",
  "security_1157_gis": "周日",
  "security_1158_gis": "计划详情",
  "security_1159_gis": "新建计划",
  "security_1160_gis": "修改计划",
  "security_1161_gis": "修改巡逻计划成功",
  "security_1162_gis": "新建巡逻计划成功",
  "security_1163_gis": "时间不能为0或为空！",
  "security_1164_gis": "时间必须为数字",
  "security_1165_gis": "重要警告",
  "security_1166_gis": "挂起",
  "security_1167_gis": "园区无对应设备数据",
  "security_1168_gis": "园区无安保人员数据",
  "security_1169_gis": "已挂起",
  "security_1170_gis": "提示事件",
  "security_1171_gis": "已取消",
  "security_1172_gis": "事件标题",
  "security_1173_gis": "报事事件",
  "security_1174_gis": "事件编号",
  "security_1175_gis": "派发",
  "security_1176_gis": "工单派发",
  "security_1177_gis": "工单状态",
  "security_1178_gis": "计划开始时间",
  "security_1179_gis": "请选择计划开始时间",
  "security_1180_gis": "计划结束时间",
  "security_1181_gis": "请选择计划结束时间",
  "security_1182_gis": "告警位置",

  "security_1183_gis": "工单详情",
  "security_1184_gis": "实际开始时间",
  "security_1185_gis": "实际结束时间",
  "security_1186_gis": "工单处理时长",
  "security_1187_gis": "关联设备",
  "security_1188_gis": "工单描述",
  "security_1189_gis": "故障描述",
  "security_1190_gis": "关联系统详情",
  "security_1191_gis": "关联工单编号",
  "security_1192_gis": "完工描述",
  "security_1193_gis": "图片详情",
  "security_1194_gis": "报事标题",
  "security_1195_gis": "请输入标题",
  "security_1196_gis": "报事编号",
  "security_1197_gis": "请选择事件状态",
  "security_1198_gis": "工单编号",
  "security_1199_gis": "请输入工单编号",
  "security_1200_gis": "派发时间",
  "security_1201_gis": "请输入派发时间",
  "security_1202_gis": "工单责任人",
  "security_1203_gis": "请输入工单责任人",
  "security_1204_gis": "委派人",
  "security_1205_gis": "业务工单",
  "security_1206_gis": "设施工单",
  "security_1207_gis": "报事工单",
  "security_1208_gis": "告警名称",
  "security_1209_gis": "设施等级",
  "security_1210_gis": "设施系统",
  "security_1211_gis": "子系统",
  "security_1212_gis": "事件详情",
  "security_1213_gis": "事件编号/报警编号",
  "security_1214_gis": "事件名称/报事类型",
  "security_1215_gis": "告警时间/上报时间",
  "security_1216_gis": "报修用户",
  "security_1217_gis": "子类",
  "security_1218_gis": "工单",
  "security_1219_gis": "导出数据不能超过20000条,请先输入条件筛选",
  "security_1220_gis": "请输入报事标题",
  "security_1221_gis": "请输入报事编号",
  "security_1222_gis": "报事状态",
  "security_1223_gis": "请输入报事状态",
  "security_1224_gis": "报事等级",
  "security_1225_gis": "请选择报事等级",
  "security_1226_gis": "报事类型",
  "security_1227_gis": "请选择报事类型",
  "security_1228_gis": "报事描述",
  "security_1229_gis": "报事时间",
  "security_1230_gis": "请输入事件编号",
  "security_1231_gis": "该事件还没有工单",
  "security_1232_gis": "报修事件",
  "security_1233_gis": "客流告警",
  "security_1234_gis": "在线",
  "security_1235_gis": "异常",
  "security_1236_gis": "选择日期时间",
  "security_1237_gis": "派发类型",
  "security_1238_gis": "请选择派发类型",
  "security_1239_gis": "请选择开始时间",
  "security_1240_gis": "请选择结束时间",
  "security_1241_gis": "工单派发成功",
  "security_1242_gis": "MQDCapp任务",
  "security_1243_gis": "请输入姓名和手机号",
  "security_1244_gis": "请输入事件名称",
  "security_1245_gis": "请输入发生位置",
  "security_1246_gis": "请输入处理人",
  "security_1247_gis": "请选择日期",
  "security_1248_gis": "最近一年",
  i18nKey_cockpit_1: '万',
  i18nKey_cockpit_2: '亿',
  i18nKey_cockpit_3: '千万',
  i18nKey_cockpit_4: '百万',
  i18nKey_cockpit_5: '分',
  i18nKey_cockpit_6: '分钟',
  i18nKey_cockpit_7: '天',
  i18nKey_cockpit_8: '时',
  i18nKey_cockpit_9: '小时',
  i18nKey_cockpit_10: '本周',
  i18nKey_cockpit_11: '近三个月',
  i18nKey_cockpit_12: '本月',
  i18nKey_cockpit_13: '确定',
  i18nKey_cockpit_14: '去修改',
  i18nKey_cockpit_15: '提示',
  i18nKey_cockpit_16: '任务',
  i18nKey_cockpit_17: '取消',
  i18nKey_cockpit_18: '全部',
  i18nKey_cockpit_19: '类型',
  i18nKey_cockpit_20: '地图',
  i18nKey_cockpit_21: '图层配置',
  i18nKey_cockpit_22: '图层',
  i18nKey_cockpit_23: '地图操作',
  i18nKey_cockpit_24: '常规配置',
  i18nKey_cockpit_25: '保存',
  i18nKey_cockpit_26: '配置成功',
  i18nKey_cockpit_27: '配置失败',
  i18nKey_cockpit_28: '您未做任何修改',
  i18nKey_cockpit_29: '紧急',
  i18nKey_cockpit_30: '实景',
  i18nKey_cockpit_31: '详情',
  i18nKey_cockpit_32: '查看详情',
  i18nKey_cockpit_33: '关闭门禁',
  i18nKey_cockpit_34: '时间流动',
  i18nKey_cockpit_35: '配置',
  i18nKey_cockpit_36: '设置',
  i18nKey_cockpit_37: '全屏',
  i18nKey_cockpit_38: '晴天',
  i18nKey_cockpit_39: '晴',
  i18nKey_cockpit_40: '雨',
  i18nKey_cockpit_41: '雾',
  i18nKey_cockpit_42: '雪',
  i18nKey_cockpit_43: '降雨',
  i18nKey_cockpit_44: '大雾',
  i18nKey_cockpit_45: '降雪',
  i18nKey_cockpit_46: '全局态势-2D版',
  i18nKey_cockpit_47: '版',
  i18nKey_cockpit_48: '全局态势',
  i18nKey_cockpit_49: '安全态势',
  i18nKey_cockpit_50: '视频巡逻统计',
  i18nKey_cockpit_51: '巡逻告警',
  i18nKey_cockpit_52: '安防告警状态',
  i18nKey_cockpit_53: '设备告警统计',
  i18nKey_cockpit_54: '安防告警统计',
  i18nKey_cockpit_55: '安防告警趋势',
  i18nKey_cockpit_56: '安防工单趋势',
  i18nKey_cockpit_57: '设备统计',
  i18nKey_cockpit_58: '事件中心',
  i18nKey_cockpit_59: '能耗态势',
  i18nKey_cockpit_60: '用电概况',
  i18nKey_cockpit_61: '用电统计',
  i18nKey_cockpit_62: '能效AI诊断',
  i18nKey_cockpit_63: '诊断',
  i18nKey_cockpit_64: '分项用能',
  i18nKey_cockpit_65: '能耗告警',
  i18nKey_cockpit_66: '能耗监测',
  i18nKey_cockpit_67: '环境监测',
  i18nKey_cockpit_68: '设备用能排名',
  i18nKey_cockpit_69: '区域每平方米能耗排名',
  i18nKey_cockpit_70: '商/租户每平方米用能排名',
  i18nKey_cockpit_71: '租户每平方米用能排名',
  i18nKey_cockpit_72: '水能',
  i18nKey_cockpit_73: '设施态势',
  i18nKey_cockpit_74: '故障统计',
  i18nKey_cockpit_75: '设施事件状态统计',
  i18nKey_cockpit_76: '设施工单',
  i18nKey_cockpit_77: '通行态势',
  i18nKey_cockpit_78: '人员通行概况',
  i18nKey_cockpit_79: '员工通行统计',
  i18nKey_cockpit_80: '访客通行统计',
  i18nKey_cockpit_81: '通行方式统计',
  i18nKey_cockpit_82: '车流统计',
  i18nKey_cockpit_83: '车位统计',
  i18nKey_cockpit_84: '临停收费',
  i18nKey_cockpit_85: '停车时长',
  i18nKey_cockpit_86: '智慧商业',
  i18nKey_cockpit_87: '到访客流趋势',
  i18nKey_cockpit_88: '驻留情况',
  i18nKey_cockpit_89: '热门区域',
  i18nKey_cockpit_90: '商业事件统计',
  i18nKey_cockpit_91: '客流/场内人数',
  i18nKey_cockpit_92: '场内人数',
  i18nKey_cockpit_93: '年龄性别',
  i18nKey_cockpit_94: '回头客',
  i18nKey_cockpit_95: '节日客流统计',
  i18nKey_cockpit_96: '组件中未找到',
  i18nKey_cockpit_97: '方法',
  i18nKey_cockpit_98: '综合态势',
  i18nKey_cockpit_99: '运营报表',
  i18nKey_cockpit_100: '参数错误',
  i18nKey_cockpit_101: '应为',
  i18nKey_cockpit_102: '实为',
  i18nKey_cockpit_103: '暂时无符合条件的数据',
  i18nKey_cockpit_104: '缩放',
  i18nKey_cockpit_105: '鼠标滚轮缩放',
  i18nKey_cockpit_106: '移动',
  i18nKey_cockpit_107: '旋转',
  i18nKey_cockpit_108: '鼠标左键旋转地图',
  i18nKey_cockpit_109: '右键移动地图',
  i18nKey_cockpit_110: '鼠标右键旋转地图',
  i18nKey_cockpit_111: '左键移动地图',
  i18nKey_cockpit_112: '模式图片',
  i18nKey_cockpit_113: '全局态势图片：',
  i18nKey_cockpit_114: '产品态势图片：',
  i18nKey_cockpit_115: '图片大小不能超过20',
  i18nKey_cockpit_116: '上传失败',
  i18nKey_cockpit_117: '只能上传',
  i18nKey_cockpit_118: '格式的图片',
  i18nKey_cockpit_119: '上传失败！',
  i18nKey_cockpit_120: '返回',
  i18nKey_cockpit_121: '切换场景',
  i18nKey_cockpit_122: '自动漫游',
  i18nKey_cockpit_123: '继续漫游',
  i18nKey_cockpit_124: '暂停',
  i18nKey_cockpit_125: '退出播放',
  i18nKey_cockpit_126: '图表设置',
  i18nKey_cockpit_127: '白天',
  i18nKey_cockpit_128: '夜间',
  i18nKey_cockpit_129: '手动模式',
  i18nKey_cockpit_130: '自动模式',
  i18nKey_cockpit_131: '选择图表',
  i18nKey_cockpit_132: '请选择不多于',
  i18nKey_cockpit_133: '张卡片',
  i18nKey_cockpit_134: '请选择不少于',
  i18nKey_cockpit_135: '图表设置成功',
  i18nKey_cockpit_136: '选择场景',
  i18nKey_cockpit_137: '滨河路',
  i18nKey_cockpit_138: '元和街',
  i18nKey_cockpit_139: '永庆坊',
  i18nKey_cockpit_140: '绿色节能',
  i18nKey_cockpit_141: '电表统计',
  i18nKey_cockpit_142: '设施数',
  i18nKey_cockpit_143: '个',
  i18nKey_cockpit_144: '电表在线率',
  i18nKey_cockpit_145: '本月节能潜力',
  i18nKey_cockpit_146: '设施异常',
  i18nKey_cockpit_147: '设施统计',
  i18nKey_cockpit_148: '设施在线率',
  i18nKey_cockpit_149: '本月设施异常',
  i18nKey_cockpit_150: '件',
  i18nKey_cockpit_151: '重要',
  i18nKey_cockpit_152: '本月设施异常处理',
  i18nKey_cockpit_153: '处理率',
  i18nKey_cockpit_154: '平均处理时长',
  i18nKey_cockpit_155: '物业管理',
  i18nKey_cockpit_156: '说明：系统1小时数据刷新，人流车流10分钟刷新',
  i18nKey_cockpit_157: '综合安防',
  i18nKey_cockpit_158: '便捷通行',
  i18nKey_cockpit_159: '设施管理',
  i18nKey_cockpit_160: '配套服务',
  i18nKey_cockpit_161: '告警事件',
  i18nKey_cockpit_162: '视频巡逻',
  i18nKey_cockpit_163: '黑名单',
  i18nKey_cockpit_164: '本周告警事件',
  i18nKey_cockpit_165: '累计',
  i18nKey_cockpit_166: '本周告警事件处理',
  i18nKey_cockpit_167: '已处理',
  i18nKey_cockpit_168: '本周视频巡逻',
  i18nKey_cockpit_169: '次',
  i18nKey_cockpit_170: '打卡率',
  i18nKey_cockpit_171: '本周黑名单事件数',
  i18nKey_cockpit_172: '共享会议室',
  i18nKey_cockpit_173: '园区巴士',
  i18nKey_cockpit_174: '园区订餐',
  i18nKey_cockpit_175: '本月会议室预订',
  i18nKey_cockpit_176: '营收',
  i18nKey_cockpit_177: '元',
  i18nKey_cockpit_178: '本月会议室使用',
  i18nKey_cockpit_179: '使用时长',
  i18nKey_cockpit_180: '使用率',
  i18nKey_cockpit_181: '会议室服务企业',
  i18nKey_cockpit_182: '家',
  i18nKey_cockpit_183: '本月园区订餐',
  i18nKey_cockpit_184: '人数',
  i18nKey_cockpit_185: '本月巴士乘车',
  i18nKey_cockpit_186: '产办',
  i18nKey_cockpit_187: '人次',
  i18nKey_cockpit_188: '泊寓',
  i18nKey_cockpit_189: '本月巴士满座率',
  i18nKey_cockpit_190: '人流统计',
  i18nKey_cockpit_191: '门禁通行',
  i18nKey_cockpit_192: '商场客流',
  i18nKey_cockpit_193: '今日园区人流',
  i18nKey_cockpit_194: '人',
  i18nKey_cockpit_195: '今日停车缴费',
  i18nKey_cockpit_196: '临停',
  i18nKey_cockpit_197: '套餐卡',
  i18nKey_cockpit_198: '今日园区停车',
  i18nKey_cockpit_199: '辆',
  i18nKey_cockpit_200: '车次',
  i18nKey_cockpit_201: '本周停车场使用率',
  i18nKey_cockpit_202: '月卡',
  i18nKey_cockpit_203: '园区',
  i18nKey_cockpit_204: '告警统计',
  i18nKey_cockpit_205: '紧急告警',
  i18nKey_cockpit_206: '重要告警',
  i18nKey_cockpit_207: '一般告警',
  i18nKey_cockpit_208: '工单总数',
  i18nKey_cockpit_209: '待接收',
  i18nKey_cockpit_210: '已接收',
  i18nKey_cockpit_211: '业务事件',
  i18nKey_cockpit_212: '输入告警标题',
  i18nKey_cockpit_213: '等级',
  i18nKey_cockpit_214: '状态',
  i18nKey_cockpit_215: '查看更多',
  i18nKey_cockpit_216: '设施事件',
  i18nKey_cockpit_217: '一般',
  i18nKey_cockpit_218: '实时画面',
  i18nKey_cockpit_219: '至',
  i18nKey_cockpit_220: '开始日期',
  i18nKey_cockpit_221: '结束日期',
  i18nKey_cockpit_222: '日',
  i18nKey_cockpit_223: '今日',
  i18nKey_cockpit_224: '周',
  i18nKey_cockpit_225: '月',
  i18nKey_cockpit_226: '年',
  i18nKey_cockpit_227: '本年',
  i18nKey_cockpit_228: '今天',
  i18nKey_cockpit_229: '执行(次)',
  i18nKey_cockpit_230: '总计数量',
  i18nKey_cockpit_231: '事件动态',
  i18nKey_cockpit_232: '暂无数据',
  i18nKey_cockpit_233: '当前浏览器不支持',
  i18nKey_cockpit_234: '扫码报事',
  i18nKey_cockpit_235: '待处理',
  i18nKey_cockpit_236: '消防山',
  i18nKey_cockpit_237: '身高120',
  i18nKey_cockpit_238: '脚穿深蓝紫色凉鞋，背黄色书包，走丢时拿着一把风扇，请附近看到的人联系我脚穿深蓝紫色凉鞋脚穿深蓝紫色凉鞋脚穿深蓝紫色凉鞋脚穿深蓝紫色凉鞋脚穿深蓝紫色凉鞋身高120',
  i18nKey_cockpit_239: '脚穿深蓝紫色凉鞋，背黄色书包，走丢时拿着一把风扇，请附近看到的人联系我脚穿深蓝紫色凉鞋脚穿深蓝紫色凉鞋脚穿深蓝紫色凉鞋脚穿深蓝紫色凉鞋脚穿深蓝紫色凉鞋',
  i18nKey_cockpit_240: '设备名称',
  i18nKey_cockpit_241: '商业',
  i18nKey_cockpit_242: '消防通道',
  i18nKey_cockpit_243: '工单处理',
  i18nKey_cockpit_244: '立即追踪',
  i18nKey_cockpit_245: '级别',
  i18nKey_cockpit_246: '故障设施类型统计',
  i18nKey_cockpit_247: '无符合查询条件的数据',
  i18nKey_cockpit_248: '设施名称',
  i18nKey_cockpit_249: '设施数量',
  i18nKey_cockpit_250: '设施故障次数',
  i18nKey_cockpit_251: '故障设施数',
  i18nKey_cockpit_252: '累计发生',
  i18nKey_cockpit_253: '累计处理',
  i18nKey_cockpit_254: '件）',
  i18nKey_cockpit_255: '分钟）',
  i18nKey_cockpit_256: '设施工单概览',
  i18nKey_cockpit_257: '处理中',
  i18nKey_cockpit_258: '已关闭',
  i18nKey_cockpit_259: '时段平均用电',
  i18nKey_cockpit_260: '总数',
  i18nKey_cockpit_261: '用电概览',
  i18nKey_cockpit_262: '总用电',
  i18nKey_cockpit_263: '较昨天',
  i18nKey_cockpit_264: '昨天',
  i18nKey_cockpit_265: '较上周',
  i18nKey_cockpit_266: '上周',
  i18nKey_cockpit_267: '较上月',
  i18nKey_cockpit_268: '上月',
  i18nKey_cockpit_269: '总能耗',
  i18nKey_cockpit_270: '分项累计能耗',
  i18nKey_cockpit_271: '租户用电',
  i18nKey_cockpit_272: '公区用电',
  i18nKey_cockpit_273: '总用电（',
  i18nKey_cockpit_274: '工单统计',
  i18nKey_cockpit_275: '概览',
  i18nKey_cockpit_276: '已挂起',
  i18nKey_cockpit_277: '已取消',
  i18nKey_cockpit_278: '巡逻告警事件',
  i18nKey_cockpit_279: '处理完成率',
  i18nKey_cockpit_280: '处理及时率',
  i18nKey_cockpit_281: '告警事件趋势',
  i18nKey_cockpit_282: '告警分类统计',
  i18nKey_cockpit_283: '发生数量',
  i18nKey_cockpit_284: '处理数量',
  i18nKey_cockpit_285: '平均处理时长（分钟）',
  i18nKey_cockpit_286: '告警类型',
  i18nKey_cockpit_287: '告警来源',
  i18nKey_cockpit_288: '告警级别',
  i18nKey_cockpit_289: '问题概览',
  i18nKey_cockpit_290: '黑名单事件处理时长',
  i18nKey_cockpit_291: '耗时最短',
  i18nKey_cockpit_292: '排名',
  i18nKey_cockpit_293: '事件',
  i18nKey_cockpit_294: '用时',
  i18nKey_cockpit_295: '耗时最长',
  i18nKey_cockpit_296: '摄像头',
  i18nKey_cockpit_297: '视频打卡率',
  i18nKey_cockpit_298: '执行总时长',
  i18nKey_cockpit_299: '路线',
  i18nKey_cockpit_300: '条',
  i18nKey_cockpit_301: '乘车统计',
  i18nKey_cockpit_302: '早班车',
  i18nKey_cockpit_303: '晚班车',
  i18nKey_cockpit_304: '套餐卡用户',
  i18nKey_cockpit_305: '人）',
  i18nKey_cockpit_306: '套餐卡用户（人）',
  i18nKey_cockpit_307: '搭乘企业',
  i18nKey_cockpit_308: '企业名称',
  i18nKey_cockpit_309: '搭乘人次',
  i18nKey_cockpit_310: '菜品销量',
  i18nKey_cockpit_311: '菜品名',
  i18nKey_cockpit_312: '订单数',
  i18nKey_cockpit_313: '份',
  i18nKey_cockpit_314: '会议室预订',
  i18nKey_cockpit_315: '预订',
  i18nKey_cockpit_316: '退订',
  i18nKey_cockpit_317: '次）',
  i18nKey_cockpit_318: '元）',
  i18nKey_cockpit_319: '会议室使用',
  i18nKey_cockpit_320: '全部会议室',
  i18nKey_cockpit_321: '订餐营收',
  i18nKey_cockpit_322: '销量',
  i18nKey_cockpit_323: '份）',
  i18nKey_cockpit_324: '企业预订次数',
  i18nKey_cockpit_325: '预订次数',
  i18nKey_cockpit_326: '接驳车满座率',
  i18nKey_cockpit_327: '店铺销量',
  i18nKey_cockpit_328: '店铺名称',
  i18nKey_cockpit_329: '门禁通行统计',
  i18nKey_cockpit_330: '人次）',
  i18nKey_cockpit_331: '门禁通行方式',
  i18nKey_cockpit_332: '商场客流热度',
  i18nKey_cockpit_333: '园区车流',
  i18nKey_cockpit_334: '辆）',
  i18nKey_cockpit_335: '车次）',
  i18nKey_cockpit_336: '员工通行',
  i18nKey_cockpit_337: '热度',
  i18nKey_cockpit_338: '客流概况',
  i18nKey_cockpit_339: '平日客流',
  i18nKey_cockpit_340: '周末客流',
  i18nKey_cockpit_341: '时段客流统计',
  i18nKey_cockpit_342: '今日客流',
  i18nKey_cockpit_343: '近一月平均客流',
  i18nKey_cockpit_344: '区域热度',
  i18nKey_cockpit_345: '区域',
  i18nKey_cockpit_346: '园区人流',
  i18nKey_cockpit_347: '临停车次',
  i18nKey_cockpit_348: '收费金额',
  i18nKey_cockpit_349: '洗手间人流',

  "i18nKey_cockpit_350": "物业工单",
  "i18nKey_cockpit_351": "用水统计",
  "i18nKey_cockpit_352": "冷量统计",
  "i18nKey_cockpit_353": "项目任务工单",
  "i18nKey_cockpit_354": "总工单数(个)",
  "i18nKey_cockpit_355": "项目任务处理情况",
  "i18nKey_cockpit_356": "已完成",
  "i18nKey_cockpit_357": "项目任务及时处理率",
  "i18nKey_cockpit_358": "及时接单率",
  "i18nKey_cockpit_359": "及时完工率",
  "i18nKey_cockpit_360": "任务工单超时统计",
  "i18nKey_cockpit_361": "超时未完成",
  "i18nKey_cockpit_362": "超时未接单",
  "i18nKey_cockpit_363": "工单分类统计",
  "i18nKey_cockpit_364": "新增总数",
  "i18nKey_cockpit_365": "超时已完成",
  "i18nKey_cockpit_366": "超时已接单",
  "i18nKey_cockpit_367": "工单类型",
  "i18nKey_cockpit_368": "超出2000条最大导出限制",
  "i18nKey_cockpit_369": "导出内容为空",
  "i18nKey_cockpit_370": "导出失败",
  "i18nKey_cockpit_371": "总工单数",
  "i18nKey_cockpit_372": "工单趋势",
  "i18nKey_cockpit_373": "工单分类详情",
  "i18nKey_cockpit_374": "请选择",
  "i18nKey_cockpit_375": "报事原因",
  "i18nKey_cockpit_376": "位置",
  "i18nKey_cockpit_377": "派发时间",
  "i18nKey_cockpit_378": "处理人",
  "i18nKey_cockpit_379": "导出",
  "i18nKey_cockpit_380": "导出时间段",
  "i18nKey_cockpit_381": "请选择导出时间段",
  "i18nKey_cockpit_382": "工单编号",
  "i18nKey_cockpit_383": "请选择事发时间",
  "i18nKey_cockpit_384": "日期区间内无数据无法导出",
  "i18nKey_cockpit_385": "提示事件",
  "i18nKey_cockpit_386": "总冷量",
  "i18nKey_cockpit_387": "租户用冷量",
  "i18nKey_cockpit_389": "员工",
  "i18nKey_cockpit_390": "物业",
  "i18nKey_cockpit_391": "安全",
  "i18nKey_cockpit_392": "声音",
  "i18nKey_cockpit_393": "空调用电",
  "i18nKey_cockpit_394": "动力用电",
  "i18nKey_cockpit_395": "照明及插座",
  "i18nKey_cockpit_396": "商业用电",
  "i18nKey_cockpit_397": "安全告警",
  "i18nKey_cockpit_398": "客流告警",
  "i18nKey_cockpit_399": "消防告警",
  i18nKey_components_1: '万',
  i18nKey_components_2: '你好',
  i18nKey_components_3: '世界',
  i18nKey_components_4: '在线摄像机数',
  i18nKey_components_5: '输入摄像机别名',
  i18nKey_components_6: '选择摄像机',
  i18nKey_components_7: '确定',
  i18nKey_components_8: '图层',
  i18nKey_components_9: '更多图层',
  i18nKey_components_10: '电话',
  i18nKey_components_11: '短信',
  i18nKey_components_12: '查看摄像头',
  i18nKey_components_13: '提示',
  i18nKey_components_14: '开始时间',
  i18nKey_components_15: '结束时间',
  i18nKey_components_16: '选择开始日期时间',
  i18nKey_components_17: '选择结束日期时间',
  i18nKey_components_18: '视频截取',
  i18nKey_components_19: '结束时间需要大于开始时间',
  i18nKey_components_20: '提交',
  i18nKey_components_21: '取消',
  i18nKey_components_22: '实时视频',
  i18nKey_components_23: '历史视频',
  i18nKey_components_24: '视频回放',
  i18nKey_components_25: '回放',
  i18nKey_components_26: '停止',
  i18nKey_components_27: '请选择时间',
  i18nKey_components_28: '时长请勿小于30秒',
  i18nKey_components_29: '时长请勿超过一小时',
  i18nKey_components_30: '截取成功',
  i18nKey_components_31: '视频截取成功，请至左侧事件详情的关联视频下查看',
  i18nKey_components_32: '视频截取失败',
  i18nKey_components_33: '微软雅黑',
  i18nKey_components_34: '摄像机',
  i18nKey_components_35: '时',
  i18nKey_components_36: '日',
  i18nKey_components_37: '周',
  i18nKey_components_38: '月',
  i18nKey_components_39: '年',
  i18nKey_components_40: '年龄',
  i18nKey_components_41: '设备统计',
  i18nKey_components_42: '统计时间截止到',
  i18nKey_components_43: '设备总数',
  i18nKey_components_44: '设备在线率',
  i18nKey_components_45: '台',
  i18nKey_components_46: '设备告警统计',
  i18nKey_components_47: '告警总数',
  i18nKey_components_48: '未处理',
  i18nKey_components_49: '关闭率',
  i18nKey_components_50: '紧急',
  i18nKey_components_51: '严重',
  i18nKey_components_52: '一般',
  i18nKey_components_53: '热门区域',
  i18nKey_components_54: '客流',
  i18nKey_components_55: '场内人数',
  i18nKey_components_56: '进入人数',
  i18nKey_components_57: '离开人数',
  i18nKey_components_58: '在场人数',
  i18nKey_components_59: '人',
  i18nKey_components_60: '巡逻告警',
  i18nKey_components_61: '累计发生',
  i18nKey_components_62: '累计处理',
  i18nKey_components_63: '件',
  i18nKey_components_64: '单位',
  i18nKey_components_65: '能耗统计',
  i18nKey_components_66: '人流总数',
  i18nKey_components_67: '园区',
  i18nKey_components_68: '人次',
  i18nKey_components_69: '安防告警统计',
  i18nKey_components_70: '告警总数（个）',
  i18nKey_components_71: '重要告警',
  i18nKey_components_72: '一般告警',
  i18nKey_components_73: '紧急告警',
  i18nKey_components_74: '安防告警状态',
  i18nKey_components_75: '待处理',
  i18nKey_components_76: '处理中',
  i18nKey_components_77: '已挂起',
  i18nKey_components_78: '已关闭',
  i18nKey_components_79: '已取消',
  i18nKey_components_80: '事件总数',
  i18nKey_components_81: '安防告警趋势',
  i18nKey_components_82: '告警发生数量',
  i18nKey_components_83: '告警处理数量',
  i18nKey_components_84: '安防工单趋势',
  i18nKey_components_85: '工单总数（个）',
  i18nKey_components_86: '发生数',
  i18nKey_components_87: '处理数',
  i18nKey_components_88: '视频巡逻统计',
  i18nKey_components_89: '执行次数',
  i18nKey_components_90: '次',
  i18nKey_components_91: '视频打卡率',
  i18nKey_components_92: '路线',
  i18nKey_components_93: '条',
  i18nKey_components_94: '摄像头',
  i18nKey_components_95: '个',
  i18nKey_components_96: '执行总时长',
  i18nKey_components_97: '分钟',
  i18nKey_components_98: '任务',
  i18nKey_components_99: '最小化',
  i18nKey_components_100: '最大化',
  i18nKey_components_101: '视频缓冲中...',
  i18nKey_components_102: '获取视频资源失败...',
  i18nKey_components_103: '取消截图',
  i18nKey_components_104: '图片裁剪',
  i18nKey_components_105: '取',
  i18nKey_components_106: '消',
  i18nKey_components_107: '确认',
  i18nKey_components_108: '男',
  i18nKey_components_109: '较',
  i18nKey_components_110: '环比',
  i18nKey_components_111: '人数',
  i18nKey_components_112: '较上一年同比',
  i18nKey_components_113: '上一年同期',
  i18nKey_components_114: '女',
  i18nKey_components_115: '昨日',
  i18nKey_components_116: '上周',
  i18nKey_components_117: '上月',
  i18nKey_components_118: '上一年',
  i18nKey_components_119: '在线',
  i18nKey_components_120: '休眠',
  i18nKey_components_121: '异常',
  i18nKey_components_122: '停用',
  i18nKey_components_123: '离线',
  i18nKey_components_124: '总计数量',
  i18nKey_components_125: '用电',
  i18nKey_components_126: '用水',
  i18nKey_components_127: '排名',
  i18nKey_components_128: '每平方米能耗',
  i18nKey_components_129: '较昨日',
  i18nKey_components_130: '上年',
  i18nKey_components_131: '设备',
  i18nKey_components_132: '区域',
  i18nKey_components_133: '商',
  i18nKey_components_134: '租户',
  i18nKey_components_135: '能耗',
  i18nKey_components_136: '节能类型',
  i18nKey_components_137: '种',
  i18nKey_components_138: '诊断措施',
  i18nKey_components_139: '节能潜力',
  i18nKey_components_140: '累计节能潜力',
  i18nKey_components_141: '无符合查询条件的数据',
  i18nKey_components_142: '总面积',
  i18nKey_components_143: '总企业数',
  i18nKey_components_144: '昨日用电量',
  i18nKey_components_145: '上周用电量',
  i18nKey_components_146: '上月用电量',
  i18nKey_components_147: '客流（人次）',
  i18nKey_components_148: '能效',
  i18nKey_components_149: '诊断',
  i18nKey_components_150: '公区能耗',
  i18nKey_components_151: '租户能耗',
  i18nKey_components_152: '总能耗',
  i18nKey_components_153: '单位：',
  i18nKey_components_154: '本地',
  i18nKey_components_155: '室内',
  i18nKey_components_156: '本地温度',
  i18nKey_components_157: '本地湿度',
  i18nKey_components_158: '室内温度',
  i18nKey_components_159: '室内湿度',
  i18nKey_components_160: '租户水耗',
  i18nKey_components_161: '用电概况',
  i18nKey_components_162: '用电量',
  i18nKey_components_163: '总用电',
  i18nKey_components_164: '用电统计',
  i18nKey_components_165: '租户用电',
  i18nKey_components_166: '公区用电',
  i18nKey_components_167: '环境监测',
  i18nKey_components_168: '能耗告警',
  i18nKey_components_169: '设备异常',
  i18nKey_components_170: '用电异常',
  i18nKey_components_171: '数据异常',
  i18nKey_components_172: '能耗监测',
  i18nKey_components_173: '公区',
  i18nKey_components_174: '同比',
  i18nKey_components_175: '分项用能',
  i18nKey_components_176: '分项累计能耗',
  i18nKey_components_177: '设备用能排名',
  i18nKey_components_178: '区域每平方米能耗排名',
  i18nKey_components_179: '水能',
  i18nKey_components_180: '设施事件状态统计',
  i18nKey_components_181: '设施报修',
  i18nKey_components_182: '平均处理时长',
  i18nKey_components_183: '故障统计',
  i18nKey_components_184: '故障数',
  i18nKey_components_185: '故障率',
  i18nKey_components_186: '通行方式统计',
  i18nKey_components_187: '门禁通行人数',
  i18nKey_components_188: '员工通行统计',
  i18nKey_components_189: '通行人次',
  i18nKey_components_190: '临停收费',
  i18nKey_components_191: '临停车次',
  i18nKey_components_192: '收费金额',
  i18nKey_components_193: '元',
  i18nKey_components_194: '车位统计',
  i18nKey_components_195: '总车流数',
  i18nKey_components_196: '车位使用数',
  i18nKey_components_197: '车位使用率',
  i18nKey_components_198: '停车时长',
  i18nKey_components_199: '数量',
  i18nKey_components_200: '辆',
  i18nKey_components_201: '人员通行概况',
  i18nKey_components_202: '车流统计',
  i18nKey_components_203: '驶入',
  i18nKey_components_204: '驶出',
  i18nKey_components_205: '访客通行统计',
  i18nKey_components_206: '商业事件统计',
  i18nKey_components_207: '类型',
  i18nKey_components_208: '事件描述',
  i18nKey_components_209: '时间',
  i18nKey_components_210: '回头客',
  i18nKey_components_211: '回头客总数（人）',
  i18nKey_components_212: '首次到访（人）',
  i18nKey_components_213: '上一周',
  i18nKey_components_214: '上一月',
  i18nKey_components_215: '到访客流趋势',
  i18nKey_components_216: '到访总人数（人次）',
  i18nKey_components_217: '当前客流情况',
  i18nKey_components_218: '暂无停车场',
  i18nKey_components_219: '空闲状态',
  i18nKey_components_220: '使用状态',
  i18nKey_components_221: '使用',
  i18nKey_components_222: '进来了！！！！',
  i18nKey_components_223: '执行了打点方法',
  i18nKey_components_224: '有的数据',
  i18nKey_components_225: '点击了楼栋',
  i18nKey_components_226: '新的轨迹方法执行了',
  i18nKey_components_227: '清除轨迹',
  i18nKey_components_228: '沙盘',
  i18nKey_components_229: '水晶',
  i18nKey_components_230: '楼栋',
  i18nKey_components_231: '实景',
  i18nKey_components_232: '街景',
  i18nKey_components_233: '主视图',
  i18nKey_components_234: '视图',
  i18nKey_components_235: '俯视视图',
  i18nKey_components_236: '设为主视图',
  i18nKey_components_237: '重置主视图',
  i18nKey_components_238: '执行了恢复',
  i18nKey_components_239: '节日客流统计',
  i18nKey_components_240: '客流总数',
  i18nKey_components_241: '搜索引擎',
  i18nKey_components_242: '驻留情况',
  i18nKey_components_243: '平均驻留时长',
  i18nKey_components_244: '线上服务已暂停，恢复时间请等待',

  "i18nKey_components_245": "季",
  "i18nKey_components_246": "今天",
  "i18nKey_components_247": "7天",
  "i18nKey_components_248": "30天",
  "i18nKey_components_249": "请选择",
  "i18nKey_components_250": "高清监控摄像头",
  "i18nKey_components_251": "人脸识别摄像头",
  "i18nKey_components_252": "空调用电",
  "i18nKey_components_253": "动力用电",
  "i18nKey_components_254": "照明及插座",
  "i18nKey_components_255": "商业用电",
  "i18nKey_components_256": "员工",
  "i18nKey_components_257": "刷卡",

  "i18nKey_components_258": '告警总数\n（件）',
  "i18nKey_components_259": '商/租户每平方米用能排名',
  i18nKey_layout_1: '您的登录信息已失效，请重新登录',
  i18nKey_layout_2: '综合态势',
  i18nKey_layout_3: '综合态势展示',
  i18nKey_layout_4: '全局态势',
  i18nKey_layout_5: '安全态势',
  i18nKey_layout_6: '能耗态势',
  i18nKey_layout_7: '设施态势',
  i18nKey_layout_8: '通行态势',
  i18nKey_layout_9: '创建空间基础数据',
  i18nKey_layout_10: '修改空间基础数据',
  i18nKey_layout_11: '安防告警详情',
  i18nKey_layout_12: '新建基础数据',
  i18nKey_layout_13: '基础数据详情',
  i18nKey_layout_14: '修改基础数据',
  i18nKey_layout_15: '基础数据历史版本',
  i18nKey_layout_16: '人脸追踪',
  i18nKey_layout_17: '新建追踪',
  i18nKey_layout_18: '新建人脸布控',
  i18nKey_layout_19: '修改布控',
  i18nKey_layout_20: '重新布控',
  i18nKey_layout_21: '查看详情',
  i18nKey_layout_22: '新建车辆布控',
  i18nKey_layout_23: '车辆布控详情',
  i18nKey_layout_24: '车辆基础数据详情',
  i18nKey_layout_25: '车辆基础数据修改',
  i18nKey_layout_26: '车辆基础数据创建',
  i18nKey_layout_27: '配置',
  i18nKey_layout_28: '新建人员基础数据',
  i18nKey_layout_29: '修改人员基础数据',
  i18nKey_layout_30: '查看人员基础数据',
  i18nKey_layout_31: '储能详情',
  i18nKey_layout_32: '修改规则',
  i18nKey_layout_33: '新增规则',
  i18nKey_layout_34: '能耗详情',
  i18nKey_layout_35: '设施画像',
  i18nKey_layout_36: '新建策略',
  i18nKey_layout_37: '修改策略',
  i18nKey_layout_38: '策略详情',
  i18nKey_layout_39: '追踪轨迹',
  i18nKey_layout_40: '历史事件',
  i18nKey_layout_41: '事件详情',
  i18nKey_layout_42: '新建巡逻路线',
  i18nKey_layout_43: '巡逻路线修改',
  i18nKey_layout_44: '巡逻路线列表',
  i18nKey_layout_45: '巡逻计划列表',
  i18nKey_layout_46: '视频巡逻统计',
  i18nKey_layout_47: '视频巡逻历史',
  i18nKey_layout_48: '巡逻路线详情',
  i18nKey_layout_49: '新建计划',
  i18nKey_layout_50: '修改计划',
  i18nKey_layout_51: '计划详情',
  i18nKey_layout_52: '地址管理',
  i18nKey_layout_53: '人脸库详情',
  i18nKey_layout_54: '人脸追踪详情',
  i18nKey_layout_55: '人脸追踪事件管理',
  i18nKey_layout_56: '新建',
  i18nKey_layout_57: '修改',
  i18nKey_layout_58: '展示迎宾屏',
  i18nKey_layout_59: '告警列表',
  i18nKey_layout_60: '查看',
  i18nKey_layout_61: '新增',
  i18nKey_layout_62: '编辑',
  i18nKey_layout_63: '单价配置',
  i18nKey_layout_64: '变压器变损（线损）配置',
  i18nKey_layout_65: '户型模板详情',
  i18nKey_layout_66: '场景模板详情',
  i18nKey_layout_67: '场景详情',
  i18nKey_layout_68: '关联摄像头',
  i18nKey_layout_69: '车辆详情',
  i18nKey_layout_70: '人员详情',
  i18nKey_layout_71: '活动列表',
  i18nKey_layout_72: '通知',
  i18nKey_layout_73: '个人中心',
  i18nKey_layout_74: '修改密码',
  i18nKey_layout_75: '退出',
  i18nKey_layout_76: '提示',
  i18nKey_layout_77: '确定要退出？',
  i18nKey_layout_78: '全景模式',
  i18nKey_layout_79: '全景',
  i18nKey_layout_80: '模式',
  i18nKey_layout_81: '中国',
  i18nKey_layout_82: '年',
  i18nKey_layout_83: '月',
  i18nKey_layout_84: '日',
  i18nKey_layout_85: '取消',
  i18nKey_layout_86: '确认',
  i18nKey_layout_87: '原密码',
  i18nKey_layout_88: '新密码',
  i18nKey_layout_89: '新密码确认',
  i18nKey_layout_90: '请输入当前密码',
  i18nKey_layout_91: '请输入新密码',
  i18nKey_layout_92: '请再次输入新密码',
  i18nKey_layout_93: '新密码不能与旧密码相同',
  i18nKey_layout_94: '新密码前后输入不一致',
  i18nKey_layout_95: '请输入密码',
  i18nKey_layout_96: '密码不能包含空格',
  i18nKey_layout_97: '密码强度不符合',
  i18nKey_layout_98: '至少8位',
  i18nKey_layout_99: '包括数字',
  i18nKey_layout_100: '大、小写字母',
  i18nKey_layout_101: '特殊字符中的至少三类',
  i18nKey_layout_102: '密码修改后，需要重新登录',
  i18nKey_layout_103: '修改成功',
  i18nKey_layout_104: '去登录',
  i18nKey_layout_105: '头像',
  i18nKey_layout_106: '姓名',
  i18nKey_layout_107: '手机',
  i18nKey_layout_108: '昵称',
  i18nKey_layout_109: '性别',
  i18nKey_layout_110: '上传头像',
  i18nKey_layout_111: '请输入昵称',
  i18nKey_layout_112: '男',
  i18nKey_layout_113: '女',
  i18nKey_layout_114: '图片大小不能超过1',
  i18nKey_layout_115: '只能上传',
  i18nKey_layout_116: '格式图片',
  i18nKey_layout_117: '上传失败',
  i18nKey_layout_118: '保存',
  i18nKey_layout_119: "高德",
  i18nKey_layout_120: "平面",
  i18nKey_enterprise_1: '标题',
  i18nKey_enterprise_2: '粗体',
  i18nKey_enterprise_3: '字号',
  i18nKey_enterprise_4: '字体',
  i18nKey_enterprise_5: '斜体',
  i18nKey_enterprise_6: '下划线',
  i18nKey_enterprise_7: '删除线',
  i18nKey_enterprise_8: '文字颜色',
  i18nKey_enterprise_9: '背景颜色',
  i18nKey_enterprise_10: '插入链接',
  i18nKey_enterprise_11: '列表',
  i18nKey_enterprise_12: '对齐方式',
  i18nKey_enterprise_13: '插入表格',
  i18nKey_enterprise_14: '插入代码',
  i18nKey_enterprise_15: '撤销',
  i18nKey_enterprise_16: '重复',
  i18nKey_enterprise_17: '上传图片失败',
  i18nKey_enterprise_18: '模拟',
  i18nKey_enterprise_19: '事件生成器',
  i18nKey_enterprise_20: '时间：',
  i18nKey_enterprise_21: '事件标题：',
  i18nKey_enterprise_22: '事件描述：',
  i18nKey_enterprise_23: '事件地址：',
  i18nKey_enterprise_24: '告警设备：',
  i18nKey_enterprise_25: '事件来源：',
  i18nKey_enterprise_26: '访客系统',
  i18nKey_enterprise_27: '门禁',
  i18nKey_enterprise_28: '消防',
  i18nKey_enterprise_29: '周界',
  i18nKey_enterprise_30: '事件级别：',
  i18nKey_enterprise_31: '重要告警',
  i18nKey_enterprise_32: '事件类型：',
  i18nKey_enterprise_33: '消防事件',
  i18nKey_enterprise_34: '安全事件',
  i18nKey_enterprise_35: '生成事件',
  i18nKey_enterprise_36: '万科科技公司',
  i18nKey_enterprise_37: '版权所有',
  i18nKey_enterprise_38: '技术支持电话：000-00000000',
  i18nKey_enterprise_39: '第',
  i18nKey_enterprise_40: '当前位置',
  i18nKey_enterprise_41: '资产',
  i18nKey_enterprise_42: '信息',
  i18nKey_enterprise_43: '人员修改取消',
  i18nKey_enterprise_44: '视频缓冲中',
  i18nKey_enterprise_45: '误判',
  i18nKey_enterprise_46: '上报',
  i18nKey_enterprise_47: '时间',
  i18nKey_enterprise_48: '地点',
  i18nKey_enterprise_49: '万科成都园区某某楼',
  i18nKey_enterprise_50: '视频巡逻人工上报',
  i18nKey_enterprise_51: '级别',
  i18nKey_enterprise_52: '红色紧急警告',
  i18nKey_enterprise_53: '橙色紧急警告',
  i18nKey_enterprise_54: '黄色紧急警告',
  i18nKey_enterprise_55: '蓝色紧急警告',
  i18nKey_enterprise_56: '描述',
  i18nKey_enterprise_57: '特别重大事故',
  i18nKey_enterprise_58: '重大事故',
  i18nKey_enterprise_59: '较大事故',
  i18nKey_enterprise_60: '一般事故',
  i18nKey_enterprise_61: '不能为空，请输入内容。',
  i18nKey_enterprise_62: '注意：适用于列表页无嵌套组件的表格页面',
  i18nKey_enterprise_63: '需要传入类名',
  i18nKey_enterprise_64: '以查找所需',
  i18nKey_enterprise_65: '元素',
  i18nKey_enterprise_66: '原',
  i18nKey_enterprise_67: '需要设置初始值属性',
  i18nKey_enterprise_68: '需要设置最外层标签',
  i18nKey_enterprise_69: '确认',
  i18nKey_enterprise_70: '请输入姓名或工号',
  i18nKey_enterprise_71: '任务',
  i18nKey_enterprise_72: '选择摄像头',
  i18nKey_enterprise_73: '安保岗亭',
  i18nKey_enterprise_74: '单位：',
  i18nKey_enterprise_75: '单位：件',
  i18nKey_enterprise_76: '单位：人次',
  i18nKey_enterprise_77: '相关数据异常，无法勾选',
  i18nKey_enterprise_78: '查看详情',
  i18nKey_enterprise_79: '北门',
  i18nKey_enterprise_80: '东门',
  i18nKey_enterprise_81: '物业保安',
  i18nKey_enterprise_82: '单饼图统计',
  i18nKey_enterprise_83: '最小化',
  i18nKey_enterprise_84: '最大化',
  i18nKey_enterprise_85: '停车场未挂载模型，请联系管理员挂载模型！',
  i18nKey_enterprise_86: '最近一天',
  i18nKey_enterprise_87: '选择关联设备',
  i18nKey_enterprise_88: '设备编号',
  i18nKey_enterprise_89: '公区耗能',
  i18nKey_enterprise_90: '后一天',
  i18nKey_enterprise_91: '去年',
  i18nKey_enterprise_92: '总电耗',
  i18nKey_enterprise_93: '公区电耗',
  i18nKey_enterprise_94: '公区-',
  i18nKey_enterprise_95: '占比',
  i18nKey_enterprise_96: '较',
  i18nKey_enterprise_97: '同期',
  i18nKey_enterprise_98: '单位',
  i18nKey_enterprise_99: '删除图片',
  i18nKey_enterprise_100: '提示：只支持',
  i18nKey_enterprise_101: '格式的图片上传，上传图片大小不超过3',
  i18nKey_enterprise_102: '图片大小',
  i18nKey_enterprise_103: '图片类型',
  i18nKey_enterprise_104: '生成时间',
  i18nKey_enterprise_105: '上传图片不能大于3',
  i18nKey_enterprise_106: '请重新上传',
  i18nKey_enterprise_107: '数量',
  i18nKey_enterprise_108: '累计充电总量',
  i18nKey_enterprise_109: '累计放电总量',
  i18nKey_enterprise_110: '监测区间段',
  i18nKey_enterprise_111: '请输入最小值',
  i18nKey_enterprise_112: '请输入最大值',
  i18nKey_enterprise_113: '保存成功！',
  i18nKey_enterprise_114: '暂无数据',
  i18nKey_enterprise_115: '完成',
  i18nKey_enterprise_116: '选择关联监测仪',
  i18nKey_enterprise_117: '选择关联烟感',
  i18nKey_enterprise_118: '业务类型',
  i18nKey_enterprise_119: '电话号码',
  i18nKey_enterprise_120: '请输入电话号码',
  i18nKey_enterprise_121: '新增',
  i18nKey_enterprise_122: '策略名称由20个字符以内、连接线、下划线组成',
  i18nKey_enterprise_123: '监测时间段',
  i18nKey_enterprise_124: '人流量',
  i18nKey_enterprise_125: '吸烟监测',
  i18nKey_enterprise_126: '监测阀值',
  i18nKey_enterprise_127: '关联监测仪',
  i18nKey_enterprise_128: '请选择监测仪',
  i18nKey_enterprise_129: '人数',
  i18nKey_enterprise_130: '人数只能输入大于0小于等于99999的正整数',
  i18nKey_enterprise_131: '烟雾浓度报警阀值',
  i18nKey_enterprise_132: '关联烟感',
  i18nKey_enterprise_133: '请选择烟感',
  i18nKey_enterprise_134: '请输入报警阀值',
  i18nKey_enterprise_135: '阀值只能输入大于0小于等于5000的正整数',
  i18nKey_enterprise_136: '这是一个提示',
  i18nKey_enterprise_137: '温馨提示',
  i18nKey_enterprise_138: '浓度',
  i18nKey_enterprise_139: '中度烟雾',
  i18nKey_enterprise_140: '香烟',
  i18nKey_enterprise_141: '下载图片',
  i18nKey_enterprise_142: '查看告警',
  i18nKey_enterprise_143: '暂时无符合条件的数据',
  i18nKey_enterprise_144: '请输入描述',
  i18nKey_enterprise_145: '视频巡逻手工上传',
  i18nKey_enterprise_146: '工单派发',
  i18nKey_enterprise_147: '选择人员',
  i18nKey_enterprise_148: '派发工单',
  i18nKey_enterprise_149: '输入姓名或工号',
  i18nKey_enterprise_150: '告警名称',
  i18nKey_enterprise_151: '告警状态',
  i18nKey_enterprise_152: '告警等级',
  i18nKey_enterprise_153: '告警时间',
  i18nKey_enterprise_154: '责任人',
  i18nKey_enterprise_155: '计划开始时间',
  i18nKey_enterprise_156: '请选择计划开始时间',
  i18nKey_enterprise_157: '计划结束时间',
  i18nKey_enterprise_158: '请选择计划结束时间',
  i18nKey_enterprise_159: '告警位置',
  i18nKey_enterprise_160: '上一步',
  i18nKey_enterprise_161: '派发任务',
  i18nKey_enterprise_162: '必须选择一个人员',
  i18nKey_enterprise_163: '新建工单',
  i18nKey_enterprise_164: '工单类型',
  i18nKey_enterprise_165: '任务详情描述',
  i18nKey_enterprise_166: '请输入任务详情描述',
  i18nKey_enterprise_167: '关联设备',
  i18nKey_enterprise_168: '请选择关联设备',
  i18nKey_enterprise_169: '任务等级',
  i18nKey_enterprise_170: '请选择任务等级',
  i18nKey_enterprise_171: '请选择责任人',
  i18nKey_enterprise_172: '请选择发生时间',
  i18nKey_enterprise_173: '请选择工单委派人',
  i18nKey_enterprise_174: '现场联系人',
  i18nKey_enterprise_175: '请选择现场联系人',
  i18nKey_enterprise_176: '请输入设备名称',
  i18nKey_enterprise_177: '新建成功',
  i18nKey_enterprise_178: '手工填单',
  i18nKey_enterprise_179: '任务详情描述不能为空',
  i18nKey_enterprise_180: '长度在',
  i18nKey_enterprise_181: '到',
  i18nKey_enterprise_182: '个字符',
  i18nKey_enterprise_183: '新建工单失败',
  i18nKey_enterprise_184: '工单',
  i18nKey_enterprise_185: '工单派发完成',
  i18nKey_enterprise_186: '无数据',
  i18nKey_enterprise_187: '此设备无上级设备',
  i18nKey_enterprise_188: '请选择地点',
  i18nKey_enterprise_189: '请输入工单委派人',
  i18nKey_enterprise_190: '请输入现场联系人',
  i18nKey_enterprise_191: '选项1',
  i18nKey_enterprise_192: '黄金糕',
  i18nKey_enterprise_193: '选项2',
  i18nKey_enterprise_194: '双皮奶',
  i18nKey_enterprise_195: '选项3',
  i18nKey_enterprise_196: '蚵仔煎',
  i18nKey_enterprise_197: '选项4',
  i18nKey_enterprise_198: '龙须面',
  i18nKey_enterprise_199: '实际开始时间',
  i18nKey_enterprise_200: '实际结束时间',
  i18nKey_enterprise_201: '工单处理时长',
  i18nKey_enterprise_202: '巡查人员详情',
  i18nKey_enterprise_203: '职务',
  i18nKey_enterprise_204: '在岗情况',
  i18nKey_enterprise_205: '工单总数',
  i18nKey_enterprise_206: '处理中',
  i18nKey_enterprise_207: '事件动态',
  i18nKey_enterprise_208: '通知人员，疏散楼宇人员',
  i18nKey_enterprise_209: '拨打119，120',
  i18nKey_enterprise_210: '与应急小组取得联系，开始部署撤离',
  i18nKey_enterprise_211: '成都园区1栋1楼烟感器发生报警',
  i18nKey_enterprise_212: '本地上传',
  i18nKey_enterprise_213: '从人员库选择',
  i18nKey_enterprise_214: '上传到服务器',
  i18nKey_enterprise_215: '人员',
  i18nKey_enterprise_216: '高飞',
  i18nKey_enterprise_217: '高正为',
  i18nKey_enterprise_218: '高林飞',
  i18nKey_enterprise_219: '李创业',
  i18nKey_enterprise_220: '姚赟',
  i18nKey_enterprise_221: '的头像',
  i18nKey_enterprise_222: '标签',
  i18nKey_enterprise_223: '输入名称或编号',
  i18nKey_enterprise_224: '烟感',
  i18nKey_enterprise_225: '更多图层后台返回',
  i18nKey_enterprise_226: '码',
  i18nKey_enterprise_227: '后台返回',
  i18nKey_enterprise_228: '成都万科园区-1栋一层',
  i18nKey_enterprise_229: '区空调新风机组',
  i18nKey_enterprise_230: '级',
  i18nKey_enterprise_231: '制冷系统',
  i18nKey_enterprise_232: '所属区域',
  i18nKey_enterprise_233: '成都万科园区',
  i18nKey_enterprise_234: '所属系统',
  i18nKey_enterprise_235: '暖通系统',
  i18nKey_enterprise_236: '林生',
  i18nKey_enterprise_237: '子类',
  i18nKey_enterprise_238: '子类占位符',
  i18nKey_enterprise_239: '设施描述占位符设施描述占位符设施描述占位符设施描述占位符设施描述占位符设施描述占位符设施描述占位符设施描述占位符设施描述占位符设施描述占位符设施描述占位符设施描述占位符设施描述占位符设施描述占位符设施描述占位符设施描述占位符',
  i18nKey_enterprise_240: '新增任务',
  i18nKey_enterprise_241: '任务描述',
  i18nKey_enterprise_242: '米内',
  i18nKey_enterprise_243: '取消关联',
  i18nKey_enterprise_244: '已关联',
  i18nKey_enterprise_245: '事件关联摄像头',
  i18nKey_enterprise_246: '周围摄像头',
  i18nKey_enterprise_247: '关联摄像头（',
  i18nKey_enterprise_248: '处理日志',
  i18nKey_enterprise_249: '通知员工，疏散楼宇人员',
  i18nKey_enterprise_250: '通知员工，疏散楼宇人员详情',
  i18nKey_enterprise_251: '事件发生时间',
  i18nKey_enterprise_252: '事件发生地点',
  i18nKey_enterprise_253: '已接收',
  i18nKey_enterprise_254: '重新指派',
  i18nKey_enterprise_255: '任务关闭时间',
  i18nKey_enterprise_256: '任务指派时间',
  i18nKey_enterprise_257: '任务指派人',
  i18nKey_enterprise_258: '现场反馈',
  i18nKey_enterprise_259: '任务备注',
  i18nKey_enterprise_260: '创建人：',
  i18nKey_enterprise_261: '大',
  i18nKey_enterprise_262: '小：',
  i18nKey_enterprise_263: '任务列表',
  i18nKey_enterprise_264: '查看更多',
  i18nKey_enterprise_265: '综合态势-全国',
  i18nKey_enterprise_266: '张统计卡片',
  i18nKey_enterprise_267: '综合态势',
  i18nKey_enterprise_268: '安防事件统计',
  i18nKey_enterprise_269: '综合态势展示',
  i18nKey_enterprise_270: '请选择不少于',
  i18nKey_enterprise_271: '请输入电表名称',
  i18nKey_enterprise_272: '电表',
  i18nKey_enterprise_273: '水表',
  i18nKey_enterprise_274: '请输入水表名称',
  i18nKey_enterprise_275: '今日用能',
  i18nKey_enterprise_276: '本月环比',
  i18nKey_enterprise_277: '本月同比',
  i18nKey_enterprise_278: '租户用电',
  i18nKey_enterprise_279: '照明用电',
  i18nKey_enterprise_280: '日总能耗',
  i18nKey_enterprise_281: '输入事件编号',
  i18nKey_enterprise_282: '请选择工单派发人员',
  i18nKey_enterprise_283: '计划开始时间不能为空',
  i18nKey_enterprise_284: '计划结束时间不能为空',
  i18nKey_enterprise_285: '网络加载异常',
  i18nKey_enterprise_286: '确认密码',
  i18nKey_enterprise_287: '密码',
  i18nKey_enterprise_288: '手机号码',
  i18nKey_enterprise_289: '邮箱',
  i18nKey_enterprise_290: '年龄',
  i18nKey_enterprise_291: '行业',
  i18nKey_enterprise_292: '人员详情',
  i18nKey_enterprise_293: '图像',
  i18nKey_enterprise_294: '人员位置',
  i18nKey_enterprise_295: '车辆信息',
  i18nKey_enterprise_296: '消息提醒',
  i18nKey_enterprise_297: '跳转到轨迹查看',
  i18nKey_enterprise_298: '画像标签',
  i18nKey_enterprise_299: '删除底图',
  i18nKey_enterprise_300: '次',
  i18nKey_enterprise_301: '标记',
  i18nKey_enterprise_302: '相似度：',
  i18nKey_enterprise_303: '仅展示系统筛选的高相似度人脸',
  i18nKey_enterprise_304: '信息来源：',
  i18nKey_enterprise_305: '暂无嫌疑人信息',
  i18nKey_enterprise_306: '该操作将彻底删除该人脸底图',
  i18nKey_enterprise_307: '进入园区黑名单布控',
  i18nKey_enterprise_308: '园区黑名单布控',
  i18nKey_enterprise_309: '人员画像',
  i18nKey_enterprise_310: '人脸信息',
  i18nKey_enterprise_311: '嫌疑人信息',
  i18nKey_enterprise_312: '取消布控',
  i18nKey_enterprise_313: '取消园区黑名单布控',
  i18nKey_enterprise_314: '接口状态异常',
  i18nKey_enterprise_315: '人员至少有一个标记',
  i18nKey_enterprise_316: '修改失败---',
  i18nKey_enterprise_317: '修改失败，接口状态异常',
  i18nKey_enterprise_318: '替换底图失败',
  i18nKey_enterprise_319: '此人进入黑名单布控',
  i18nKey_enterprise_320: '此人取消黑名单布控',
  i18nKey_enterprise_321: '操作失败---',
  i18nKey_enterprise_322: '操作失败，接口状态异常',
  i18nKey_enterprise_323: '园区位置',
  i18nKey_enterprise_324: '迎宾',
  i18nKey_enterprise_325: '迎宾屏',
  i18nKey_enterprise_326: '布控原因不能为空',
  i18nKey_enterprise_327: '请选择推送人',
  i18nKey_enterprise_328: '请选择布控迎宾屏',
  i18nKey_enterprise_329: '追踪时段',
  i18nKey_enterprise_330: '至',
  i18nKey_enterprise_331: '追踪状态',
  i18nKey_enterprise_332: '请选择追踪状态',
  i18nKey_enterprise_333: '追踪摄像头',
  i18nKey_enterprise_334: '请选择追踪摄像头',
  i18nKey_enterprise_335: '数据删除成功',
  i18nKey_enterprise_336: '操作成功',
  i18nKey_enterprise_337: '网络加载异常，请稍后再试',
  i18nKey_enterprise_338: '暂无告警',
  i18nKey_enterprise_339: '身份证号码',
  i18nKey_enterprise_340: '信息来源',
  i18nKey_enterprise_341: '罗伯特',
  i18nKey_enterprise_342: '凌晨三点起来',
  i18nKey_enterprise_343: '发现海棠花未眠',
  i18nKey_enterprise_344: '明天11点30会议报告',
  i18nKey_enterprise_345: '明天14点30会议报告',
  i18nKey_enterprise_346: '当前浏览器不支持',
  i18nKey_enterprise_347: '到达提醒时段',
  i18nKey_enterprise_348: '到达提醒状态',
  i18nKey_enterprise_349: '到达提醒摄像头',
  i18nKey_enterprise_350: '迎宾时段',
  i18nKey_enterprise_351: '迎宾状态',
  i18nKey_enterprise_352: '请选择迎宾状态',
  i18nKey_enterprise_353: '布控迎宾屏',
  i18nKey_enterprise_354: '进入迎宾大屏',
  i18nKey_enterprise_355: '输入迎宾屏名称',
  i18nKey_enterprise_356: '选择迎宾屏',
  i18nKey_enterprise_357: '车辆信息修改',
  i18nKey_enterprise_358: '车主',
  i18nKey_enterprise_359: '输入的字数不能大于300',
  i18nKey_enterprise_360: '取消这个修改需求',
  i18nKey_enterprise_361: '停驶记录详情',
  i18nKey_enterprise_362: '已驶离',
  i18nKey_enterprise_363: '停车中',
  i18nKey_enterprise_364: '停车时间',
  i18nKey_enterprise_365: '车位信息',
  i18nKey_enterprise_366: '号车位',
  i18nKey_enterprise_367: '播放记录',
  i18nKey_enterprise_368: '打卡记录',
  i18nKey_enterprise_369: '记录详情',
  i18nKey_enterprise_370: '摄像头名称',
  i18nKey_enterprise_371: '应打卡人',
  i18nKey_enterprise_372: '打卡时间',
  i18nKey_enterprise_373: '未打卡',
  i18nKey_enterprise_374: '某某巡逻组',
  i18nKey_enterprise_375: '播放时间',
  i18nKey_enterprise_376: '立即查看',
  i18nKey_enterprise_377: '待执行',
  i18nKey_enterprise_378: '大道当然',
  i18nKey_enterprise_379: '离开舒适区',
  i18nKey_enterprise_380: '是前进的第一步。',
  i18nKey_enterprise_381: '越万里关山',
  i18nKey_enterprise_382: '闯千百难关。',
  i18nKey_enterprise_383: '斗士身后无退路',
  i18nKey_enterprise_384: '勇气前方无险途。',
  i18nKey_enterprise_385: '团结协作',
  i18nKey_enterprise_386: '全力以赴。',
  i18nKey_enterprise_387: '成大事不在于力量的大小',
  i18nKey_enterprise_388: '而在于能坚持多久。',
  i18nKey_enterprise_389: '要为成功找方法',
  i18nKey_enterprise_390: '不为失败找理由。',
  i18nKey_enterprise_391: '任重道远',
  i18nKey_enterprise_392: '志在必得。',
  i18nKey_enterprise_393: '只有不快的斧',
  i18nKey_enterprise_394: '没有劈不开的柴。',
  i18nKey_enterprise_395: '资产原值',
  i18nKey_enterprise_396: '最新上报时间',
  i18nKey_enterprise_397: '使用率（按类型）',
  i18nKey_enterprise_398: '安装',
  i18nKey_enterprise_399: '资产数',
  i18nKey_enterprise_400: '资产总原值',
  i18nKey_enterprise_401: '资产名称',
  i18nKey_enterprise_402: '耗电总量',
  i18nKey_enterprise_403: '数据不存在',
  i18nKey_enterprise_404: '押金设置',
  i18nKey_enterprise_405: '缴纳押金日期',
  i18nKey_enterprise_406: '请选择缴纳押金日期',
  i18nKey_enterprise_407: '退款日期',
  i18nKey_enterprise_408: '请选择退款日期',
  i18nKey_enterprise_409: '申请人',
  i18nKey_enterprise_410: '支付成功日期',
  i18nKey_enterprise_411: '申请退款日期',
  i18nKey_enterprise_412: '押金金额',
  i18nKey_enterprise_413: '退款金额',
  i18nKey_enterprise_414: '审核中',
  i18nKey_enterprise_415: '申请退款',
  i18nKey_enterprise_416: '退押金',
  i18nKey_enterprise_417: '操作成功！',
  i18nKey_enterprise_418: '网络加载异常，请稍后重新操作！',
  i18nKey_enterprise_419: '押金设置成功！',
  i18nKey_enterprise_420: '退款中',
  i18nKey_enterprise_421: '审核通过',
  i18nKey_enterprise_422: '客户与管理员均可发起押金退款',
  i18nKey_enterprise_423: '管理员发起押金退款',
  i18nKey_enterprise_424: '创建日期',
  i18nKey_enterprise_425: '移除',
  i18nKey_enterprise_426: '网络加载异常，请稍后再试！',
  i18nKey_enterprise_427: '取消成功！',
  i18nKey_enterprise_428: '园区空间名称',
  i18nKey_enterprise_429: '请输入园区空间名称',
  i18nKey_enterprise_430: '会议室编码',
  i18nKey_enterprise_431: '请输入会议室编码',
  i18nKey_enterprise_432: '删除成功！',
  i18nKey_enterprise_433: '请输入空间名称',
  i18nKey_enterprise_434: '下载二维码',
  i18nKey_enterprise_435: '选择日期',
  i18nKey_enterprise_436: '请选择日期',
  i18nKey_enterprise_437: '没有安排的时段',
  i18nKey_enterprise_438: '园区空间列表',
  i18nKey_enterprise_439: '星期日',
  i18nKey_enterprise_440: '预定人',
  i18nKey_enterprise_441: '开门',
  i18nKey_enterprise_442: '关门',
  i18nKey_enterprise_443: '开锁时间',
  i18nKey_enterprise_444: '操作失败，当前页面无开门记录！',
  i18nKey_enterprise_445: '配置',
  i18nKey_enterprise_446: '变压器配置',
  i18nKey_enterprise_447: '当前还没有变压器！',
  i18nKey_enterprise_448: '添加变压器',
  i18nKey_enterprise_449: '修改后，系统从第二天以修改过的单价来计算用电金额',
  i18nKey_enterprise_450: '变压器名称',
  i18nKey_enterprise_451: '分摊总额',
  i18nKey_enterprise_452: '是否开关',
  i18nKey_enterprise_453: '修改记录',
  i18nKey_enterprise_454: '暂无修改记录',
  i18nKey_enterprise_455: '修改时间',
  i18nKey_enterprise_456: '修改内容',
  i18nKey_enterprise_457: '操作人',
  i18nKey_enterprise_458: '请填写变压器信息',
  i18nKey_enterprise_459: '单价配置',
  i18nKey_enterprise_460: '当前还没有设置单价！',
  i18nKey_enterprise_461: '设置单价',
  i18nKey_enterprise_462: '数值需为正数，且最多保留两位小数',
  i18nKey_enterprise_463: '分摊消费金额',
  i18nKey_enterprise_464: '修改金额（元',
  i18nKey_enterprise_465: '变压器变损（线损）配置',
  i18nKey_enterprise_466: '商',
  i18nKey_enterprise_467: '租户类型',
  i18nKey_enterprise_468: '请选择商',
  i18nKey_enterprise_469: '请选择或输入商',
  i18nKey_enterprise_470: '租户名称',
  i18nKey_enterprise_471: '耗电量（',
  i18nKey_enterprise_472: '消费金额',
  i18nKey_enterprise_473: '分摊耗电量（',
  i18nKey_enterprise_474: '分摊百分比',
  i18nKey_enterprise_475: '分摊详情',
  i18nKey_enterprise_476: '禁用',
  i18nKey_enterprise_477: '分摊管理',
  i18nKey_enterprise_478: '日期',
  i18nKey_enterprise_479: '暂无可导出数据！',
  i18nKey_enterprise_480: '泊寓健康打卡',
  i18nKey_enterprise_481: '企业每日打卡',
  i18nKey_enterprise_482: '疫情上报告警',
  i18nKey_enterprise_483: '确认是否删除？删除后将不可恢复！',
  i18nKey_enterprise_484: '请输入人员姓名',
  i18nKey_enterprise_485: '请输入正确格式的联系电话',
  i18nKey_enterprise_486: '修改小组人员',
  i18nKey_enterprise_487: '只支持中英文大小写及数字',
  i18nKey_enterprise_488: '分组编码',
  i18nKey_enterprise_489: '请输入分组编码',
  i18nKey_enterprise_490: '字典名称',
  i18nKey_enterprise_491: '请输入字典名称',
  i18nKey_enterprise_492: '字典编号',
  i18nKey_enterprise_493: '请输入字典编号',
  i18nKey_enterprise_494: '字典内容',
  i18nKey_enterprise_495: '排序',
  i18nKey_enterprise_496: '扩展字段',
  i18nKey_enterprise_497: '万智汇',
  i18nKey_enterprise_498: '万科产办',
  i18nKey_enterprise_499: '新增数据字典',
  i18nKey_enterprise_500: '数据字典详情',
  i18nKey_enterprise_501: '修改数据字典',
  i18nKey_enterprise_502: '请输入序号',
  i18nKey_enterprise_503: '请输入字典内容',
  i18nKey_enterprise_504: '请输入排序',
  i18nKey_enterprise_505: '请输入扩展字段',
  i18nKey_enterprise_506: '不能输入表情',
  i18nKey_enterprise_507: '请选择设施等级',
  i18nKey_enterprise_508: '设施分类',
  i18nKey_enterprise_509: '请输入管理责任人',
  i18nKey_enterprise_510: '请输入设施描述',
  i18nKey_enterprise_511: '设备状态',
  i18nKey_enterprise_512: '火灾自动报警及联动系统',
  i18nKey_enterprise_513: '火灾报警主机',
  i18nKey_enterprise_514: '感烟火灾探测器',
  i18nKey_enterprise_515: '感温火灾探测器',
  i18nKey_enterprise_516: '手动报警按钮',
  i18nKey_enterprise_517: '上限',
  i18nKey_enterprise_518: '可用率',
  i18nKey_enterprise_519: '故障率',
  i18nKey_enterprise_520: '总告警数',
  i18nKey_enterprise_521: '道闸',
  i18nKey_enterprise_522: '派发',
  i18nKey_enterprise_523: '派发成功！',
  i18nKey_enterprise_524: '事件描述不能超过1000个字符哦',
  i18nKey_enterprise_525: '网络错误',
  i18nKey_enterprise_526: '摄像头001',
  i18nKey_enterprise_527: '启动',
  i18nKey_enterprise_528: '没有关联事件',
  i18nKey_enterprise_529: '误判成功',
  i18nKey_enterprise_530: '该事件状态为待处理，暂时不能上报哦',
  i18nKey_enterprise_531: '该事件状态为处理中，暂时不能上报哦',
  i18nKey_enterprise_532: '该事件状态为挂起，暂时不能上报哦',
  i18nKey_enterprise_533: '人脸追踪正在启动中',
  i18nKey_enterprise_534: '新建人脸布控',
  i18nKey_enterprise_535: '根据姓名搜索',
  i18nKey_enterprise_536: '全选',
  i18nKey_enterprise_537: '点击节点的时候选中节点',
  i18nKey_enterprise_538: '默认展开所有节点',
  i18nKey_enterprise_539: '只支持',
  i18nKey_enterprise_540: '格式图片且不大于3',
  i18nKey_enterprise_541: '请输入姓名',
  i18nKey_enterprise_542: '名单类型',
  i18nKey_enterprise_543: '请输入身份证号',
  i18nKey_enterprise_544: '男',
  i18nKey_enterprise_545: '女',
  i18nKey_enterprise_546: '请输入居住地址',
  i18nKey_enterprise_547: '默认展开所有节',
  i18nKey_enterprise_548: '请选择名单类型',
  i18nKey_enterprise_549: '图片只支持',
  i18nKey_enterprise_550: '图片不能大于3',
  i18nKey_enterprise_551: '公安黑名单导入模板',
  i18nKey_enterprise_552: '表',
  i18nKey_enterprise_553: '删除失败',
  i18nKey_enterprise_554: '请选择需要删除的对象',
  i18nKey_enterprise_555: '确定删除吗',
  i18nKey_enterprise_556: '请上传图片',
  i18nKey_enterprise_557: '新建库',
  i18nKey_enterprise_558: '删除库',
  i18nKey_enterprise_559: '更多',
  i18nKey_enterprise_560: '人脸追踪正在关闭中',
  i18nKey_enterprise_561: '人员：',
  i18nKey_enterprise_562: '监控截图（',
  i18nKey_enterprise_563: '追踪时间',
  i18nKey_enterprise_564: '警告次数',
  i18nKey_enterprise_565: '上海区域',
  i18nKey_enterprise_566: '上海市',
  i18nKey_enterprise_567: '金桥万创',
  i18nKey_enterprise_568: '楼',
  i18nKey_enterprise_569: '负二层',
  i18nKey_enterprise_570: '停车场',
  i18nKey_enterprise_571: '请输入巡逻组描述',
  i18nKey_enterprise_572: '挂起',
  i18nKey_enterprise_573: '事件总数',
  i18nKey_enterprise_574: '待处理事件数',
  i18nKey_enterprise_575: '任务总数',
  i18nKey_enterprise_576: '待处理任务数',
  i18nKey_enterprise_577: '安全',
  i18nKey_enterprise_578: '总已处理',
  i18nKey_enterprise_579: '总安全事件',
  i18nKey_enterprise_580: '搭乘公司',
  i18nKey_enterprise_581: '公司预订',
  i18nKey_enterprise_582: '次数',
  i18nKey_enterprise_583: '店铺销量',
  i18nKey_enterprise_584: '菜品销量',
  i18nKey_enterprise_585: '设施异常',
  i18nKey_enterprise_586: '转发',
  i18nKey_enterprise_587: '上传时间',
  i18nKey_enterprise_588: '事件',
  i18nKey_enterprise_589: '关闭时间',
  i18nKey_enterprise_590: '该任务已产生告警',
  i18nKey_enterprise_591: '不能修改！',
  i18nKey_enterprise_592: '该任务启动中',
  i18nKey_enterprise_593: '请暂停后修改！',
  i18nKey_enterprise_594: '确定删除吗？',
  i18nKey_enterprise_595: '启止时间',
  i18nKey_enterprise_596: '巡逻时间',
  i18nKey_enterprise_597: '问题',
  i18nKey_enterprise_598: '是',
  i18nKey_enterprise_599: '否',
  i18nKey_enterprise_600: '已关闭',
  i18nKey_enterprise_601: '巡逻路线历史',
  i18nKey_enterprise_602: '全部巡逻历史',
  i18nKey_enterprise_603: '告警',
  i18nKey_enterprise_604: '事件名称',
  i18nKey_enterprise_605: '能效',
  i18nKey_enterprise_606: '万科智慧展区',
  i18nKey_enterprise_607: '重大事件告警',
  i18nKey_enterprise_608: '园区',
  i18nKey_enterprise_609: '天府云城',
  i18nKey_enterprise_610: '项目选址在金桥万创中心',
  i18nKey_enterprise_611: '地下车库，配置三套，250',
  i18nKey_enterprise_612: '储能系统，网点分别位于10',
  i18nKey_enterprise_613: '甲线下2',
  i18nKey_enterprise_614: '变压器（1000',
  i18nKey_enterprise_615: '母线侧，10',
  i18nKey_enterprise_616: '乙线下5',
  i18nKey_enterprise_617: '母线侧及6',
  i18nKey_enterprise_618: '变压器（2000',
  i18nKey_enterprise_619: '母线侧',
  i18nKey_enterprise_620: '总充电、放电报表',
  i18nKey_enterprise_621: '充电量',
  i18nKey_enterprise_622: '放电量',
  i18nKey_enterprise_623: '提示内容',
  i18nKey_enterprise_624: '箱体尺寸',
  i18nKey_enterprise_625: '寸',
  i18nKey_enterprise_626: '分系统数量',
  i18nKey_enterprise_627: '用户类型',
  i18nKey_enterprise_628: '额定容量',
  i18nKey_enterprise_629: '额定功率',
  i18nKey_enterprise_630: '变压器容量',
  i18nKey_enterprise_631: '接入电压等级',
  i18nKey_enterprise_632: '总充电量',
  i18nKey_enterprise_633: '总放电量',
  i18nKey_enterprise_634: '是否确认下载',
  i18nKey_enterprise_635: '报表吗',
  i18nKey_enterprise_636: '仪表',
  i18nKey_enterprise_637: '点读数',
  i18nKey_enterprise_638: '用电量',
  i18nKey_enterprise_639: '租户电耗',
  i18nKey_enterprise_640: '能耗变化',
  i18nKey_enterprise_641: '实际能耗值',
  i18nKey_enterprise_642: '目标能耗值',
  i18nKey_enterprise_643: '预测能耗值',
  i18nKey_enterprise_644: '预测值：1',
  i18nKey_enterprise_645: '日维度下，总能耗、公区能耗和公区下一级子项。2月维度下，总能耗、公区能耗和公区下一级子项',
  i18nKey_enterprise_646: '目标值：1',
  i18nKey_enterprise_647: '月维度下，总能耗、公区能耗。2',
  i18nKey_enterprise_648: '年维度下，总能耗和公区能耗',
  i18nKey_enterprise_649: '请输入目标值',
  i18nKey_enterprise_650: '请输入值',
  i18nKey_enterprise_651: '年实际值',
  i18nKey_enterprise_652: '月份',
  i18nKey_enterprise_653: '年目标值',
  i18nKey_enterprise_654: '公区能耗目标',
  i18nKey_enterprise_655: '公区能耗',
  i18nKey_enterprise_656: '每平米能耗',
  i18nKey_enterprise_657: '园区概览',
  i18nKey_enterprise_658: '请选时间',
  i18nKey_enterprise_659: '上一年同期：',
  i18nKey_enterprise_660: '上一月：',
  i18nKey_enterprise_661: '较上一年同比：',
  i18nKey_enterprise_662: '较上月环比：',
  i18nKey_enterprise_663: '上一月',
  i18nKey_enterprise_664: '能耗变化图',
  i18nKey_enterprise_665: '建议',
  i18nKey_enterprise_666: '变更',
  i18nKey_enterprise_667: '仪表类型',
  i18nKey_enterprise_668: '请选择仪表类型',
  i18nKey_enterprise_669: '仪表分项',
  i18nKey_enterprise_670: '请选择仪表分项',
  i18nKey_enterprise_671: '仪表状态',
  i18nKey_enterprise_672: '智能表编号',
  i18nKey_enterprise_673: '设备编码',
  i18nKey_enterprise_674: '选择分向维度',
  i18nKey_enterprise_675: '已停用',
  i18nKey_enterprise_676: '房间',
  i18nKey_enterprise_677: '请先选择一个仪表',
  i18nKey_enterprise_678: '水电表维护',
  i18nKey_enterprise_679: '未开启',
  i18nKey_enterprise_680: '合同号',
  i18nKey_enterprise_681: '仪表序号',
  i18nKey_enterprise_682: '总开关数',
  i18nKey_enterprise_683: '仪表表底',
  i18nKey_enterprise_684: '有效期',
  i18nKey_enterprise_685: '电缆平方',
  i18nKey_enterprise_686: '位置信息',
  i18nKey_enterprise_687: '商铺',
  i18nKey_enterprise_688: '单元号',
  i18nKey_enterprise_689: '租户信息',
  i18nKey_enterprise_690: '读数情况',
  i18nKey_enterprise_691: '表底会跟随抄表读数变化，为最新一次抄表日期读数',
  i18nKey_enterprise_692: '当前读数',
  i18nKey_enterprise_693: '上次抄表时间',
  i18nKey_enterprise_694: '变更记录',
  i18nKey_enterprise_695: '最后变更时间',
  i18nKey_enterprise_696: '变更前仪表编号',
  i18nKey_enterprise_697: '最后读数',
  i18nKey_enterprise_698: '变更前仪表表底',
  i18nKey_enterprise_699: '最后抄表时间',
  i18nKey_enterprise_700: '当前表底读数',
  i18nKey_enterprise_701: '删除后仪表的关联关系也将一并解除，是否要删除？',
  i18nKey_enterprise_702: '请选择仪表类别',
  i18nKey_enterprise_703: '仪表信息',
  i18nKey_enterprise_704: '请选择仪表表号',
  i18nKey_enterprise_705: '请先选择仪表表号',
  i18nKey_enterprise_706: '数值需为正数且是整数',
  i18nKey_enterprise_707: '请输入仪表序号',
  i18nKey_enterprise_708: '请输入总开关数',
  i18nKey_enterprise_709: '表底一旦填写不允许更改',
  i18nKey_enterprise_710: '请输入仪表表底',
  i18nKey_enterprise_711: '请选择有效期',
  i18nKey_enterprise_712: '请输入管理所属权',
  i18nKey_enterprise_713: '请输入电缆平方',
  i18nKey_enterprise_714: '所有权',
  i18nKey_enterprise_715: '请输入所有权',
  i18nKey_enterprise_716: '请搜索或选择设备编码',
  i18nKey_enterprise_717: '请先选择设备编码',
  i18nKey_enterprise_718: '请输入商铺',
  i18nKey_enterprise_719: '请选择合同号',
  i18nKey_enterprise_720: '请搜索或选择商',
  i18nKey_enterprise_721: '选择设备',
  i18nKey_enterprise_722: '选择仪表',
  i18nKey_enterprise_723: '该仪表无仪表分项',
  i18nKey_enterprise_724: '编辑成功',
  i18nKey_enterprise_725: '仪表代码',
  i18nKey_enterprise_726: '请输入仪表代码',
  i18nKey_enterprise_727: '请输入智能表编码',
  i18nKey_enterprise_728: '请输入类型',
  i18nKey_enterprise_729: '用户名',
  i18nKey_enterprise_730: '支付金额',
  i18nKey_enterprise_731: '园区卡支付',
  i18nKey_enterprise_732: '微信支付',
  i18nKey_enterprise_733: '电费',
  i18nKey_enterprise_734: '水费',
  i18nKey_enterprise_735: '实时',
  i18nKey_enterprise_736: '日结',
  i18nKey_enterprise_737: '实时：实时同步',
  i18nKey_enterprise_738: '数据，商户充值或',
  i18nKey_enterprise_739: '预存，剩余金额实时变化；同',
  i18nKey_enterprise_740: '商户查看的剩余金额；',
  i18nKey_enterprise_741: '日结：每日更新一次，为',
  i18nKey_enterprise_742: '昨日）数据；统计剩余金额小于阈值和已出账单未抵扣的商户；',
  i18nKey_enterprise_743: '商户名称',
  i18nKey_enterprise_744: '商户类型',
  i18nKey_enterprise_745: '金额范围',
  i18nKey_enterprise_746: '预存款余额',
  i18nKey_enterprise_747: '已出账单未抵扣金额',
  i18nKey_enterprise_748: '六',
  i18nKey_enterprise_749: '个人商户',
  i18nKey_enterprise_750: '导出成功',
  i18nKey_enterprise_751: '断电',
  i18nKey_enterprise_752: '恢复',
  i18nKey_enterprise_753: '工单时间',
  i18nKey_enterprise_754: '请选择工单时间',
  i18nKey_enterprise_755: '欠费金额区间',
  i18nKey_enterprise_756: '请输入单元号',
  i18nKey_enterprise_757: '欠费时长',
  i18nKey_enterprise_758: '欠费金额',
  i18nKey_enterprise_759: '合同编号',
  i18nKey_enterprise_760: '仪表号',
  i18nKey_enterprise_761: '处理失败',
  i18nKey_enterprise_762: '远程断电',
  i18nKey_enterprise_763: '重新断电',
  i18nKey_enterprise_764: '关闭工单',
  i18nKey_enterprise_765: '手工恢复',
  i18nKey_enterprise_766: '充值金额',
  i18nKey_enterprise_767: '充值时间',
  i18nKey_enterprise_768: '重新恢复',
  i18nKey_enterprise_769: '合闸完成',
  i18nKey_enterprise_770: '断电恢复',
  i18nKey_enterprise_771: '注：手工合闸、远程合闸的工单都需要先下发断电恢复指令！',
  i18nKey_enterprise_772: '远程合闸手工合闸都恢复后需要点击单击“合闸完成”',
  i18nKey_enterprise_773: '来最终完成工单的处理',
  i18nKey_enterprise_774: '欠费金额最大值输入不正确',
  i18nKey_enterprise_775: '欠费金额最小值输入不正确',
  i18nKey_enterprise_776: '欠费金额范围输入不正确',
  i18nKey_enterprise_777: '关闭工单成功',
  i18nKey_enterprise_778: '创建恢复工单成功',
  i18nKey_enterprise_779: '远程断电下发指令成功',
  i18nKey_enterprise_780: '工单已完成',
  i18nKey_enterprise_781: '工单详情',
  i18nKey_enterprise_782: '统计从最初开始到昨天',
  i18nKey_enterprise_783: '充值金额以到账为准',
  i18nKey_enterprise_784: '电费-云城充值',
  i18nKey_enterprise_785: '水费-云城充值',
  i18nKey_enterprise_786: '电费-',
  i18nKey_enterprise_787: '预存',
  i18nKey_enterprise_788: '水费-',
  i18nKey_enterprise_789: '充值未抵扣金额',
  i18nKey_enterprise_790: '电费已出账单未抵扣金额',
  i18nKey_enterprise_791: '水费已出账单未抵扣金额',
  i18nKey_enterprise_792: '欠费商户概览',
  i18nKey_enterprise_793: '实际消耗用电量',
  i18nKey_enterprise_794: '账单消耗总用电量为',
  i18nKey_enterprise_795: '实际消耗用水量',
  i18nKey_enterprise_796: '账单消耗总用水量为',
  i18nKey_enterprise_797: '账单消耗统计从最初开始到昨日',
  i18nKey_enterprise_798: '用水量',
  i18nKey_enterprise_799: '详',
  i18nKey_enterprise_800: '请选择合同编号',
  i18nKey_enterprise_801: '请输入单元',
  i18nKey_enterprise_802: '阈值金额',
  i18nKey_enterprise_803: '合同期',
  i18nKey_enterprise_804: '断电阈值（元）',
  i18nKey_enterprise_805: '电表阈值（元）',
  i18nKey_enterprise_806: '水表阈值（元）',
  i18nKey_enterprise_807: '删除该条预警设置后将不会产生新工单，确定要删除吗？',
  i18nKey_enterprise_808: '您好，欢迎来到',
  i18nKey_enterprise_809: '云城',
  i18nKey_enterprise_810: '扫描下载云城',
  i18nKey_enterprise_811: '服务协议》',
  i18nKey_enterprise_812: '隐私政策》',
  i18nKey_enterprise_813: '欢迎使用',
  i18nKey_enterprise_814: '平台，请修改密码后登录您的账号',
  i18nKey_enterprise_815: '位数字、字母、部分特殊字符',
  i18nKey_enterprise_816: '密码强度不符合',
  i18nKey_enterprise_817: '至少8位',
  i18nKey_enterprise_818: '包括数字',
  i18nKey_enterprise_819: '大、小写字母',
  i18nKey_enterprise_820: '特殊字符中的至少三类',
  i18nKey_enterprise_821: '请输入手机号！',
  i18nKey_enterprise_822: '请输入正确的手机号！',
  i18nKey_enterprise_823: '秒后会自动跳转到',
  i18nKey_enterprise_824: '登录页',
  i18nKey_enterprise_825: '前往',
  i18nKey_enterprise_826: '平台，请您注册',
  i18nKey_enterprise_827: '登录',
  i18nKey_enterprise_828: '注册',
  i18nKey_enterprise_829: '验证码',
  i18nKey_enterprise_830: '位字母、数字和特殊字符中至少2种的组合',
  i18nKey_enterprise_831: '请输入手机号码',
  i18nKey_enterprise_832: '密码长度为8',
  i18nKey_enterprise_833: '位字符',
  i18nKey_enterprise_834: '平台，请重置密码',
  i18nKey_enterprise_835: '或智慧运营中心',
  i18nKey_enterprise_836: '服务协议',
  i18nKey_enterprise_837: '加粗',
  i18nKey_enterprise_838: '颜色',
  i18nKey_enterprise_839: '添加链接',
  i18nKey_enterprise_840: '字体大小',
  i18nKey_enterprise_841: '上标',
  i18nKey_enterprise_842: '下标',
  i18nKey_enterprise_843: '引用',
  i18nKey_enterprise_844: '缩进',
  i18nKey_enterprise_845: '文本对齐',
  i18nKey_enterprise_846: '文本方向',
  i18nKey_enterprise_847: '代码块',
  i18nKey_enterprise_848: '公式',
  i18nKey_enterprise_849: '清除字体样式',
  i18nKey_enterprise_850: '文件',
  i18nKey_enterprise_851: '会议室迁出',
  i18nKey_enterprise_852: '请在这里输入',
  i18nKey_enterprise_853: '向右缩进',
  i18nKey_enterprise_854: '向左缩进',
  i18nKey_enterprise_855: '图片大小不能超过3',
  i18nKey_enterprise_856: '只能上传',
  i18nKey_enterprise_857: '格式图片',
  i18nKey_enterprise_858: '请在下面填写迁入信息',
  i18nKey_enterprise_859: '请在下面填写迁出信息',
  i18nKey_enterprise_860: '请选择会议室名称',
  i18nKey_enterprise_861: '请输入认证企业',
  i18nKey_enterprise_862: '请选择认证企业',
  i18nKey_enterprise_863: '请选择预订日期',
  i18nKey_enterprise_864: '预订起时间',
  i18nKey_enterprise_865: '会议议题',
  i18nKey_enterprise_866: '请输入会议议题',
  i18nKey_enterprise_867: '请输入订单金额',
  i18nKey_enterprise_868: '请输入实付金额',
  i18nKey_enterprise_869: '请输入最2',
  i18nKey_enterprise_870: '一个汉字算两个字符。',
  i18nKey_enterprise_871: '只能输入0',
  i18nKey_enterprise_872: '且一个汉字为2个字符',
  i18nKey_enterprise_873: '请选择预定结束时间',
  i18nKey_enterprise_874: '预定日期',
  i18nKey_enterprise_875: '微信',
  i18nKey_enterprise_876: '支付中',
  i18nKey_enterprise_877: '取消支付',
  i18nKey_enterprise_878: '支付成功',
  i18nKey_enterprise_879: '支付状态',
  i18nKey_enterprise_880: '支付时间',
  i18nKey_enterprise_881: '指令状态',
  i18nKey_enterprise_882: '参会人信息',
  i18nKey_enterprise_883: '待清算',
  i18nKey_enterprise_884: '已清算',
  i18nKey_enterprise_885: '已提现',
  i18nKey_enterprise_886: '提现失败',
  i18nKey_enterprise_887: '驳回',
  i18nKey_enterprise_888: '通过',
  i18nKey_enterprise_889: '名称',
  i18nKey_enterprise_890: '请输入名称',
  i18nKey_enterprise_891: '类别',
  i18nKey_enterprise_892: '请选择类别',
  i18nKey_enterprise_893: '名称不能为空',
  i18nKey_enterprise_894: '园区无摄像头数据',
  i18nKey_enterprise_895: '巡逻计划',
  i18nKey_enterprise_896: '关闭视频巡逻',
  i18nKey_enterprise_897: '是否暂停视频巡逻',
  i18nKey_enterprise_898: '是否停止视频巡逻',
  i18nKey_enterprise_899: '未处理',
  i18nKey_enterprise_900: '挂起中',
  i18nKey_enterprise_901: '待开始',
  i18nKey_enterprise_902: '请选择空间类型',
  i18nKey_enterprise_903: '共享活动室',
  i18nKey_enterprise_904: '修改信息需先停用！',
  i18nKey_enterprise_905: '预订时间',
  i18nKey_enterprise_906: '请选择预订时间',
  i18nKey_enterprise_907: '用户手机号',
  i18nKey_enterprise_908: '申请中',
  i18nKey_enterprise_909: '共享办公订单报表',
  i18nKey_enterprise_910: '通行方式',
  i18nKey_enterprise_911: '通行时间',
  i18nKey_enterprise_912: '请选择通行时间',
  i18nKey_enterprise_913: '刷卡',
  i18nKey_enterprise_914: '蓝牙',
  i18nKey_enterprise_915: '远程',
  i18nKey_enterprise_916: '对讲',
  i18nKey_enterprise_917: '选择摄像机',
  i18nKey_enterprise_918: '选择时段',
  i18nKey_enterprise_919: '中科纳能',
  i18nKey_enterprise_920: '华为',
  i18nKey_enterprise_921: '所有',
  i18nKey_enterprise_922: '查询人员标签异常',
  i18nKey_enterprise_923: '请先选择楼层',
  i18nKey_enterprise_924: '人员列表',
  i18nKey_enterprise_925: '客群分析',
  i18nKey_enterprise_926: '请选择人员标签',
  i18nKey_enterprise_927: '请选择摄像机',
  i18nKey_enterprise_928: '查询时间',
  i18nKey_enterprise_929: '公司',
  i18nKey_enterprise_930: '请输入公司',
  i18nKey_enterprise_931: '总人数：',
  i18nKey_enterprise_932: '注册用户',
  i18nKey_enterprise_933: '访客人数统计',
  i18nKey_enterprise_934: '按园区',
  i18nKey_enterprise_935: '园区总人数',
  i18nKey_enterprise_936: '访客总数',
  i18nKey_enterprise_937: '总人数',
  i18nKey_enterprise_938: '服务类型',
  i18nKey_enterprise_939: '请选择服务类型',
  i18nKey_enterprise_940: '申请时间',
  i18nKey_enterprise_941: '请选择申请时间',
  i18nKey_enterprise_942: '申请人手机号',
  i18nKey_enterprise_943: '请输入申请人手机号',
  i18nKey_enterprise_944: '申请人所属企业',
  i18nKey_enterprise_945: '请输入申请人所属企业',
  i18nKey_enterprise_946: '事项说明',
  i18nKey_enterprise_947: '服务编号',
  i18nKey_enterprise_948: '第三方',
  i18nKey_enterprise_949: '请输入第三方',
  i18nKey_enterprise_950: '摩根平台会议室',
  i18nKey_enterprise_951: '请输入摩根平台会议室',
  i18nKey_enterprise_952: '请选择数据来源',
  i18nKey_enterprise_953: '请输入最1',
  i18nKey_enterprise_954: '一个汉字算两个字符',
  i18nKey_enterprise_955: '且不能有空格！',
  i18nKey_enterprise_956: '场景',
  i18nKey_enterprise_957: '请输入场景',
  i18nKey_enterprise_958: '策略设置',
  i18nKey_enterprise_959: '请选择策略设置',
  i18nKey_enterprise_960: '分钟不能低于2分钟且不能大于10分钟',
  i18nKey_enterprise_961: '数据来源类型',
  i18nKey_enterprise_962: '场景策略',
  i18nKey_enterprise_963: '启用成功',
  i18nKey_enterprise_964: '停用成功',
  i18nKey_enterprise_965: '请选择会议状态',
  i18nKey_enterprise_966: '会议开始前：启动',
  i18nKey_enterprise_967: '会议开始后：未启动',
  i18nKey_enterprise_968: '会议开始后：启动',
  i18nKey_enterprise_969: '最后执行时间',
  i18nKey_enterprise_970: '预定企业',
  i18nKey_enterprise_971: '会议结束后',
  i18nKey_enterprise_972: '新增会议室场景策略',
  i18nKey_enterprise_973: '修改会议室场景策略',
  i18nKey_enterprise_974: '请输入车牌号码',
  i18nKey_enterprise_975: '停车位',
  i18nKey_enterprise_976: '请输入停车位',
  i18nKey_enterprise_977: '暂无图片',
  i18nKey_enterprise_978: '车辆管理',
  i18nKey_enterprise_979: '车辆查询',
  i18nKey_enterprise_980: '开始和结束时间相差不能大于30天',
  i18nKey_enterprise_981: '车辆驶入记录',
  i18nKey_enterprise_982: '总车位',
  i18nKey_enterprise_983: '车辆套餐数量',
  i18nKey_enterprise_984: '停车费用',
  i18nKey_enterprise_985: '小时内',
  i18nKey_enterprise_986: '驶出记录',
  i18nKey_enterprise_987: '驶入记录',
  i18nKey_enterprise_988: '暂无停车场',
  i18nKey_enterprise_989: '查询类型',
  i18nKey_enterprise_990: '车牌号',
  i18nKey_enterprise_991: '车位号',
  i18nKey_enterprise_992: '请输入车牌号',
  i18nKey_enterprise_993: '请输入车位号',
  i18nKey_enterprise_994: '按下',
  i18nKey_enterprise_995: '键',
  i18nKey_enterprise_996: '发车时间',
  i18nKey_enterprise_997: '票',
  i18nKey_enterprise_998: '单价',
  i18nKey_enterprise_999: '是否多段收费',
  i18nKey_enterprise_1000: '乘车班次已启用，请先暂停后再操作！',
  i18nKey_enterprise_1001: '是否确认删除该乘车班次？删除后将不可恢复！',
  i18nKey_enterprise_1002: '价格',
  i18nKey_enterprise_1003: '折',
  i18nKey_enterprise_1004: '有效日期',
  i18nKey_enterprise_1005: '请输入商户名称',
  i18nKey_enterprise_1006: '请选择订单时间范围',
  i18nKey_enterprise_1007: '巴士订单报表',
  i18nKey_enterprise_1008: '亿',
  i18nKey_enterprise_1009: '千万',
  i18nKey_enterprise_1010: '百万',
  i18nKey_enterprise_1011: '你还没有安装视频播放器，现在是否下载视频播放插件？下载好请自行安装',
  i18nKey_enterprise_1012: '无结果返回',
  i18nKey_enterprise_1013: '无结果',
  i18nKey_enterprise_1014: '七级',
  i18nKey_enterprise_1015: '八级',
  i18nKey_enterprise_1016: '九级',
  i18nKey_enterprise_1017: '十级',
  i18nKey_enterprise_1018: '十一级',
  i18nKey_enterprise_1019: '十二级',
  i18nKey_enterprise_1020: '应用系统编号',
  i18nKey_enterprise_1021: '请选择应用系统编号',
  i18nKey_enterprise_1022: '坐标类型',
  i18nKey_enterprise_1023: '坐标',
  i18nKey_enterprise_1024: '请选择管理责任人',
  i18nKey_enterprise_1025: '附件仅支持新版',
  i18nKey_enterprise_1026: '格式',
  i18nKey_enterprise_1027: '不启用',
  i18nKey_enterprise_1028: '地址',
  i18nKey_enterprise_1029: '没有数据',
  i18nKey_enterprise_1030: '确定删除',
  i18nKey_enterprise_1031: '文件大小不能超过20',
  i18nKey_enterprise_1032: '分类必须选到第三级',
  i18nKey_enterprise_1033: '请输入合法的',
  i18nKey_enterprise_1034: '地址，如00-01-6',
  i18nKey_enterprise_1035: '或00',
  i18nKey_enterprise_1036: '坐标，如',
  i18nKey_enterprise_1037: '坐标已存在，请重新输入',
  i18nKey_enterprise_1038: '来源系统设备编号只能由50个字符的中英文、数字及“-',
  i18nKey_enterprise_1039: '组成',
  i18nKey_enterprise_1040: '对外型号只能为50个字符以内的中英文、数字及下划线',
  i18nKey_enterprise_1041: '经度整数部分为-180到180',
  i18nKey_enterprise_1042: '纬度整数部分为-90到90',
  i18nKey_enterprise_1043: '设施描述只能由中英文、数字及',
  i18nKey_enterprise_1044: '制造商只能由中英文、数字及',
  i18nKey_enterprise_1045: '服务商只能由中英文、数字及',
  i18nKey_enterprise_1046: '维保商只能由中英文、数字及',
  i18nKey_enterprise_1047: '品牌只能由中英文、数字及',
  i18nKey_enterprise_1048: '支持格式为',
  i18nKey_enterprise_1049: '和',
  i18nKey_enterprise_1050: '设施数据导入',
  i18nKey_enterprise_1051: '设备名',
  i18nKey_enterprise_1052: '来源系统编码',
  i18nKey_enterprise_1053: '来源系统描述',
  i18nKey_enterprise_1054: '来源系统设备编码',
  i18nKey_enterprise_1055: '一级设备分类名称',
  i18nKey_enterprise_1056: '一级设备分类编码',
  i18nKey_enterprise_1057: '二级设备分类名称',
  i18nKey_enterprise_1058: '二级设备分类编码',
  i18nKey_enterprise_1059: '三级设备分类名称',
  i18nKey_enterprise_1060: '三级设备分类编码',
  i18nKey_enterprise_1061: '空间模型编码',
  i18nKey_enterprise_1062: '上传成功',
  i18nKey_enterprise_1063: '批次号为',
  i18nKey_enterprise_1064: '请稍后同步到正表',
  i18nKey_enterprise_1065: '上传失败！',
  i18nKey_enterprise_1066: '请上传新版',
  i18nKey_enterprise_1067: '导出失败！',
  i18nKey_enterprise_1068: '同步的批次号为',
  i18nKey_enterprise_1069: '确认同步？',
  i18nKey_enterprise_1070: '确认同步全部设施数据？',
  i18nKey_enterprise_1071: '同步失败，暂无需要同步的设施数据！',
  i18nKey_enterprise_1072: '同步成功！',
  i18nKey_enterprise_1073: '同步失败！',
  i18nKey_enterprise_1074: '设施分类层级',
  i18nKey_enterprise_1075: '新增层级',
  i18nKey_enterprise_1076: '设施重要等级',
  i18nKey_enterprise_1077: '设施分类编码只能由数字、字母、下划线组成',
  i18nKey_enterprise_1078: '请输入需要增加的层级',
  i18nKey_enterprise_1079: '请将数据填写完整，不能跨层级增加数据',
  i18nKey_enterprise_1080: '请输入相对应的分类编码或分类名称',
  i18nKey_enterprise_1081: '分类编码和分类名称不能为空',
  i18nKey_enterprise_1082: '请输入园区编码',
  i18nKey_enterprise_1083: '微软雅黑',
  i18nKey_enterprise_1084: '查看企业',
  i18nKey_enterprise_1085: '创建方式',
  i18nKey_enterprise_1086: '手动录入',
  i18nKey_enterprise_1087: '批量导入',
  i18nKey_enterprise_1088: '请输入企业英文名',
  i18nKey_enterprise_1089: '工商执照注册号',
  i18nKey_enterprise_1090: '统一社会信用代码',
  i18nKey_enterprise_1091: '将文件拖到此处，或',
  i18nKey_enterprise_1092: '点击上传',
  i18nKey_enterprise_1093: '文件仅支持',
  i18nKey_enterprise_1094: '请输入正确格式的身份证号',
  i18nKey_enterprise_1095: '长度最多为50个字符',
  i18nKey_enterprise_1096: '新建企业',
  i18nKey_enterprise_1097: '修改企业',
  i18nKey_enterprise_1098: '文件，并且后缀是',
  i18nKey_enterprise_1099: '共',
  i18nKey_enterprise_1100: '条，成功',
  i18nKey_enterprise_1101: '条，错误',
  i18nKey_enterprise_1102: '已关联大屏',
  i18nKey_enterprise_1103: '可关联大屏',
  i18nKey_enterprise_1104: '取消关联成功',
  i18nKey_enterprise_1105: '选择分类',
  i18nKey_enterprise_1106: '八类',
  i18nKey_enterprise_1107: '九类',
  i18nKey_enterprise_1108: '请选择一类',
  i18nKey_enterprise_1109: '请选择二类',
  i18nKey_enterprise_1110: '请选择三类',
  i18nKey_enterprise_1111: '请选择四类',
  i18nKey_enterprise_1112: '请选择五类',
  i18nKey_enterprise_1113: '请选择六类',
  i18nKey_enterprise_1114: '选择位置',
  i18nKey_enterprise_1115: '查询集团列表失败',
  i18nKey_enterprise_1116: '楼宇',
  i18nKey_enterprise_1117: '园区不能为空',
  i18nKey_enterprise_1118: '楼层不能为空',
  i18nKey_enterprise_1119: '文件名',
  i18nKey_enterprise_1120: '设施创建方式',
  i18nKey_enterprise_1121: '请输入端口号',
  i18nKey_enterprise_1122: '请输入账号',
  i18nKey_enterprise_1123: '地址不能为空！',
  i18nKey_enterprise_1124: '地址格式不对！',
  i18nKey_enterprise_1125: '端口号不能为空！',
  i18nKey_enterprise_1126: '端口号格式不对！',
  i18nKey_enterprise_1127: '请输入圆区名称',
  i18nKey_enterprise_1128: '请输入圆区编码',
  i18nKey_enterprise_1129: '配置成功',
  i18nKey_enterprise_1130: '选择园区',
  i18nKey_enterprise_1131: '选择子系统',
  i18nKey_enterprise_1132: '预约来访',
  i18nKey_enterprise_1133: '请选择园区子系统',
  i18nKey_enterprise_1134: '请选择人员预约来访时间',
  i18nKey_enterprise_1135: '请选择人员预计离去时间',
  i18nKey_enterprise_1136: '请选择预约状态',
  i18nKey_enterprise_1137: '请选择人员来访时间',
  i18nKey_enterprise_1138: '请选择人员来访原因',
  i18nKey_enterprise_1139: '选择籍贯',
  i18nKey_enterprise_1140: '省份',
  i18nKey_enterprise_1141: '请选择省份',
  i18nKey_enterprise_1142: '选择部门',
  i18nKey_enterprise_1143: '群组名称',
  i18nKey_enterprise_1144: '请输入群名称',
  i18nKey_enterprise_1145: '群组描述',
  i18nKey_enterprise_1146: '可输入100个字符',
  i18nKey_enterprise_1147: '新增群组',
  i18nKey_enterprise_1148: '群组名不能为空',
  i18nKey_enterprise_1149: '群组名已存在',
  i18nKey_enterprise_1150: '群组描述不能为空！',
  i18nKey_enterprise_1151: '修改群信息',
  i18nKey_enterprise_1152: '群组信息',
  i18nKey_enterprise_1153: '用户信息',
  i18nKey_enterprise_1154: '用户部门',
  i18nKey_enterprise_1155: '用户角色',
  i18nKey_enterprise_1156: '用户群组',
  i18nKey_enterprise_1157: '修改用户信息',
  i18nKey_enterprise_1158: '可输入500个字符',
  i18nKey_enterprise_1159: '添加用户',
  i18nKey_enterprise_1160: '加载更多',
  i18nKey_enterprise_1161: '请选择要添加的用户！',
  i18nKey_enterprise_1162: '没有更多了',
  i18nKey_enterprise_1163: '用户',
  i18nKey_enterprise_1164: '群组',
  i18nKey_enterprise_1165: '暂无用户',
  i18nKey_enterprise_1166: '暂无群组',
  i18nKey_enterprise_1167: '查看群名单',
  i18nKey_enterprise_1168: '请输入用户名',
  i18nKey_enterprise_1169: '手机号搜索',
  i18nKey_enterprise_1170: '添加群组',
  i18nKey_enterprise_1171: '群名单',
  i18nKey_enterprise_1172: '是否确认删除？',
  i18nKey_enterprise_1173: '暂无角色列表',
  i18nKey_enterprise_1174: '请选择需要删除的用户',
  i18nKey_enterprise_1175: '请选择需要删除的群组',
  i18nKey_enterprise_1176: '请选择需要添加的用户',
  i18nKey_enterprise_1177: '请选择需要添加的群组',
  i18nKey_enterprise_1178: '没有更多数据了',
  i18nKey_enterprise_1179: '选择车辆品牌',
  i18nKey_enterprise_1180: '选择车辆',
  i18nKey_enterprise_1181: '选择分类路径',
  i18nKey_enterprise_1182: '车辆基础数据信息',
  i18nKey_enterprise_1183: '车辆分类编码',
  i18nKey_enterprise_1184: '车牌',
  i18nKey_enterprise_1185: '行驶证号',
  i18nKey_enterprise_1186: '车辆分类名称',
  i18nKey_enterprise_1187: '车辆父分类编码',
  i18nKey_enterprise_1188: '所属人员编码',
  i18nKey_enterprise_1189: '标签生效时间',
  i18nKey_enterprise_1190: '标签失效时间',
  i18nKey_enterprise_1191: '有效标识',
  i18nKey_enterprise_1192: '最后更新人',
  i18nKey_enterprise_1193: '请输入车牌',
  i18nKey_enterprise_1194: '请选择车牌',
  i18nKey_enterprise_1195: '请输入车辆颜色',
  i18nKey_enterprise_1196: '请输入行驶证号',
  i18nKey_enterprise_1197: '请输入车辆分类全路径',
  i18nKey_enterprise_1198: '请输入车辆分类名称',
  i18nKey_enterprise_1199: '请输入所属人员编码',
  i18nKey_enterprise_1200: '请输入所属单位',
  i18nKey_enterprise_1201: '无效',
  i18nKey_enterprise_1202: '车牌号不能为空！',
  i18nKey_enterprise_1203: '车辆颜色不能为空！',
  i18nKey_enterprise_1204: '车辆分类全路径不能为空！',
  i18nKey_enterprise_1205: '车辆分类名称不能为空！',
  i18nKey_enterprise_1206: '所属人员名称不能为空！',
  i18nKey_enterprise_1207: '所属单位不能为空！',
  i18nKey_enterprise_1208: '车辆标签不能为空！',
  i18nKey_enterprise_1209: '生效时间不能为空！',
  i18nKey_enterprise_1210: '失效时间不能为空！',
  i18nKey_enterprise_1211: '有效标识不能为空！',
  i18nKey_enterprise_1212: '请输入正确的车牌！',
  i18nKey_enterprise_1213: '行驶证号或者人员编码不能包含中文！',
  i18nKey_enterprise_1214: '蓝',
  i18nKey_enterprise_1215: '计划结束时间222',
  i18nKey_enterprise_1216: '计划结束时间应该大于计划开始时间',
  i18nKey_enterprise_1217: '我选择以后的',
  i18nKey_enterprise_1218: '开始日期',
  i18nKey_enterprise_1219: '结束日期',
  i18nKey_enterprise_1220: '设施维修记录',
  i18nKey_enterprise_1221: '历史运行记录',
  i18nKey_enterprise_1222: '设施报警记录',
  i18nKey_enterprise_1223: '维修起止时间',
  i18nKey_enterprise_1224: '维修部门',
  i18nKey_enterprise_1225: '王小虎',
  i18nKey_enterprise_1226: '上海市普陀区金沙江路',
  i18nKey_enterprise_1227: '弄',
  i18nKey_enterprise_1228: '好了',
  i18nKey_enterprise_1229: '工程部',
  i18nKey_enterprise_1230: '查询时间不能为空！！！',
  i18nKey_enterprise_1231: '类设施',
  i18nKey_enterprise_1232: '重大：',
  i18nKey_enterprise_1233: '普通：',
  i18nKey_enterprise_1234: '发送短信2',
  i18nKey_enterprise_1235: '启动方式',
  i18nKey_enterprise_1236: '手动',
  i18nKey_enterprise_1237: '自动',
  i18nKey_enterprise_1238: '温度设定',
  i18nKey_enterprise_1239: '预设温度',
  i18nKey_enterprise_1240: '回风温度',
  i18nKey_enterprise_1241: '过滤网压差',
  i18nKey_enterprise_1242: '水阀控制',
  i18nKey_enterprise_1243: '电源监测',
  i18nKey_enterprise_1244: '风机命令',
  i18nKey_enterprise_1245: '告警级别',
  i18nKey_enterprise_1246: '告警操作',
  i18nKey_enterprise_1247: '设备服务商',
  i18nKey_enterprise_1248: '设备描述',
  i18nKey_enterprise_1249: '设备品牌',
  i18nKey_enterprise_1250: '设备制造商',
  i18nKey_enterprise_1251: '设备维保商',
  i18nKey_enterprise_1252: '设施启动时间',
  i18nKey_enterprise_1253: '设施停机时间',
  i18nKey_enterprise_1254: '本次运行时长',
  i18nKey_enterprise_1255: '维保日期',
  i18nKey_enterprise_1256: '维保责任人',
  i18nKey_enterprise_1257: '维保原因',
  i18nKey_enterprise_1258: '维保内容',
  i18nKey_enterprise_1259: '维保结果',
  i18nKey_enterprise_1260: '设施告警记录',
  i18nKey_enterprise_1261: '设施维保记录',
  i18nKey_enterprise_1262: '开关设置',
  i18nKey_enterprise_1263: '开灯',
  i18nKey_enterprise_1264: '关灯',
  i18nKey_enterprise_1265: '应用',
  i18nKey_enterprise_1266: '指令下发成功，状态更新约30秒左右，请耐心等待',
  i18nKey_enterprise_1267: '调光',
  i18nKey_enterprise_1268: '提示：开灯状态下调亮度才会生效',
  i18nKey_enterprise_1269: '重要事件',
  i18nKey_enterprise_1270: '一般事件',
  i18nKey_enterprise_1271: '立即追踪',
  i18nKey_enterprise_1272: '门禁系统',
  i18nKey_enterprise_1273: '视频图像',
  i18nKey_enterprise_1274: '关联图片',
  i18nKey_enterprise_1275: '上传',
  i18nKey_enterprise_1276: '关联视频',
  i18nKey_enterprise_1277: '关联布控',
  i18nKey_enterprise_1278: '新增布控',
  i18nKey_enterprise_1279: '布控失控',
  i18nKey_enterprise_1280: '布控图片',
  i18nKey_enterprise_1281: '来源系统来源系统来源系统来源系统来源系统来源系统来源系统来源系统来源系统来源系统来源系统来源系统',
  i18nKey_enterprise_1282: '疏散人群',
  i18nKey_enterprise_1283: '待接受',
  i18nKey_enterprise_1284: '关联摄像头后台返回',
  i18nKey_enterprise_1285: '周围摄像头后台返回',
  i18nKey_enterprise_1286: '添加关联摄像头后台返回',
  i18nKey_enterprise_1287: '删除关联摄像头后台返回',
  i18nKey_enterprise_1288: '提示：派发任务处理完成后，事件将自动关闭。',
  i18nKey_enterprise_1289: '分发任务',
  i18nKey_enterprise_1290: '新增任务完成',
  i18nKey_enterprise_1291: '任务分发',
  i18nKey_enterprise_1292: '委派任务',
  i18nKey_enterprise_1293: '距离',
  i18nKey_enterprise_1294: '请选择要指派任务的人',
  i18nKey_enterprise_1295: '请输入搜索关键字',
  i18nKey_enterprise_1296: '请输入任务内容',
  i18nKey_enterprise_1297: '新增任务成功',
  i18nKey_enterprise_1298: '网红诈骗案',
  i18nKey_enterprise_1299: '玄彬',
  i18nKey_enterprise_1300: '摄像头002',
  i18nKey_enterprise_1301: '摄像头003',
  i18nKey_enterprise_1302: '摄像头004',
  i18nKey_enterprise_1303: '摄像头005',
  i18nKey_enterprise_1304: '自动上报事件',
  i18nKey_enterprise_1305: '模型',
  i18nKey_enterprise_1306: '张三',
  i18nKey_enterprise_1307: '北方区域',
  i18nKey_enterprise_1308: '南方区域',
  i18nKey_enterprise_1309: '中西部区域',
  i18nKey_enterprise_1310: '已选迎宾屏',
  i18nKey_enterprise_1311: '已选摄像头',
  i18nKey_enterprise_1312: '在线摄像机数',
  i18nKey_enterprise_1313: '总摄像机数',
  i18nKey_enterprise_1314: '输入摄像机名称',
  i18nKey_enterprise_1315: '必须输入事件标题',
  i18nKey_enterprise_1316: '请输入事件描述',
  i18nKey_enterprise_1317: '万科成都园区',
  i18nKey_enterprise_1318: '栋103',
  i18nKey_enterprise_1319: '事件上报失败',
  i18nKey_enterprise_1320: '图片已保存至',
  i18nKey_enterprise_1321: '图片截取失败',
  i18nKey_enterprise_1322: '错误码：',
  i18nKey_enterprise_1323: '巡逻组数',
  i18nKey_enterprise_1324: '新增巡逻组',
  i18nKey_enterprise_1325: '视频巡逻列表',
  i18nKey_enterprise_1326: '视频巡逻历史',
  i18nKey_enterprise_1327: '结束',
  i18nKey_enterprise_1328: '设备类别',
  i18nKey_enterprise_1329: '事件处理人',
  i18nKey_enterprise_1330: '用户卡号',
  i18nKey_enterprise_1331: '处理人手机',
  i18nKey_enterprise_1332: '解决方案',
  i18nKey_enterprise_1333: '用户账号',
  i18nKey_enterprise_1334: '处理类型',
  i18nKey_enterprise_1335: '处理人固定电话',
  i18nKey_enterprise_1336: '布控类型',
  i18nKey_enterprise_1337: '事件备注',
  i18nKey_enterprise_1338: '最多只能上传5张图片',
  i18nKey_enterprise_1339: '图片大小不能超过2',
  i18nKey_enterprise_1340: '格式的图片',
  i18nKey_enterprise_1341: '任务名称不能超过50个字符',
  i18nKey_enterprise_1342: '人脸追踪-',
  i18nKey_enterprise_1343: '布控原因',
  i18nKey_enterprise_1344: '请输入布控原因，不超过150个字符',
  i18nKey_enterprise_1345: '姓名：',
  i18nKey_enterprise_1346: '时段',
  i18nKey_enterprise_1347: '相似度',
  i18nKey_enterprise_1348: '图',
  i18nKey_enterprise_1349: '是否推送弹窗',
  i18nKey_enterprise_1350: '请输入物业公告',
  i18nKey_enterprise_1351: '通知人员',
  i18nKey_enterprise_1352: '名字',
  i18nKey_enterprise_1353: '请输入姓名或手机号',
  i18nKey_enterprise_1354: '布控原因不能超过150个字符',
  i18nKey_enterprise_1355: '从车辆库选择',
  i18nKey_enterprise_1356: '车辆库选择',
  i18nKey_enterprise_1357: '车辆轨迹',
  i18nKey_enterprise_1358: '查看警告',
  i18nKey_enterprise_1359: '地点：',
  i18nKey_enterprise_1360: '来源：',
  i18nKey_enterprise_1361: '超出目标值',
  i18nKey_enterprise_1362: '告警总数',
  i18nKey_enterprise_1363: '室外',
  i18nKey_enterprise_1364: '湿度',
  i18nKey_enterprise_1365: '环境空间',
  i18nKey_enterprise_1366: '二氧化碳',
  i18nKey_enterprise_1367: '甲醛',
  i18nKey_enterprise_1368: '框',
  i18nKey_enterprise_1369: '负氧离子',
  i18nKey_enterprise_1370: '噪音',
  i18nKey_enterprise_1371: '有机挥发物',
  i18nKey_enterprise_1372: '环境指数',
  i18nKey_enterprise_1373: '路灯统计',
  i18nKey_enterprise_1374: '任务进度',
  i18nKey_enterprise_1375: '环境空间标题',
  i18nKey_enterprise_1376: '未设置',
  i18nKey_enterprise_1377: '空闲',
  i18nKey_enterprise_1378: '工单描述',
  i18nKey_enterprise_1379: '请输入工单描述',
  i18nKey_enterprise_1380: '派发工单完成',
  i18nKey_enterprise_1381: '请选择要指派工单的人',
  i18nKey_enterprise_1382: '请输入工单内容',
  i18nKey_enterprise_1383: '小李',
  i18nKey_enterprise_1384: '工单责任人：',
  i18nKey_enterprise_1385: '处理费用：',
  i18nKey_enterprise_1386: '处理时长：',
  i18nKey_enterprise_1387: '处理进度：',
  i18nKey_enterprise_1388: '报修用户',
  i18nKey_enterprise_1389: '上门时间',
  i18nKey_enterprise_1390: '已派发',
  i18nKey_enterprise_1391: '最多能输入100字符',
  i18nKey_enterprise_1392: '报修名称',
  i18nKey_enterprise_1393: '报修状态',
  i18nKey_enterprise_1394: '报修等级',
  i18nKey_enterprise_1395: '报修时间',
  i18nKey_enterprise_1396: '报修位置',
  i18nKey_enterprise_1397: '并且租约列表中也一并删除该节点下的所有店铺',
  i18nKey_enterprise_1398: '全天',
  i18nKey_enterprise_1399: '盏',
  i18nKey_enterprise_1400: '单位：盏',
  i18nKey_enterprise_1401: '今日',
  i18nKey_enterprise_1402: '请输入押金金额',
  i18nKey_enterprise_1403: '押金金额为0元时会把当前园区会议室下的押金入口关闭',
  i18nKey_enterprise_1404: '押金单位',
  i18nKey_enterprise_1405: '押金退款规则',
  i18nKey_enterprise_1406: '押金描述',
  i18nKey_enterprise_1407: '请输入押金描述',
  i18nKey_enterprise_1408: '请确认退款金额：0元',
  i18nKey_enterprise_1409: '取',
  i18nKey_enterprise_1410: '消',
  i18nKey_enterprise_1411: '确',
  i18nKey_enterprise_1412: '定',
  i18nKey_enterprise_1413: '押金金额的最大值为9999',
  i18nKey_enterprise_1414: '请选择用户类型',
  i18nKey_enterprise_1415: '请选择押金退款规则',
  i18nKey_enterprise_1416: '请输入退款金额',
  i18nKey_enterprise_1417: '退款金额不能大于押金金额！',
  i18nKey_enterprise_1418: '押金退款',
  i18nKey_enterprise_1419: '已付',
  i18nKey_enterprise_1420: '面积',
  i18nKey_enterprise_1421: '请输入小时',
  i18nKey_enterprise_1422: '付费设施',
  i18nKey_enterprise_1423: '请输入单价',
  i18nKey_enterprise_1424: '半天单价（元',
  i18nKey_enterprise_1425: '小时）',
  i18nKey_enterprise_1426: '请输入半天单价（元',
  i18nKey_enterprise_1427: '请选择结束日期',
  i18nKey_enterprise_1428: '退款',
  i18nKey_enterprise_1429: '自定义退款策略',
  i18nKey_enterprise_1430: '全额退款',
  i18nKey_enterprise_1431: '不退款',
  i18nKey_enterprise_1432: '审核退款',
  i18nKey_enterprise_1433: '退款策略',
  i18nKey_enterprise_1434: '时间开始前',
  i18nKey_enterprise_1435: '押金',
  i18nKey_enterprise_1436: '天不能低于4小时！',
  i18nKey_enterprise_1437: '请配置退款选项！',
  i18nKey_enterprise_1438: '自定义退款时，至少输入一条完整的退款策略！',
  i18nKey_enterprise_1439: '取消退款额度不能超过100',
  i18nKey_enterprise_1440: '退款策略输入的格式不正确！',
  i18nKey_enterprise_1441: '请配置押金选项！',
  i18nKey_enterprise_1442: '取消规则不能超过5条！',
  i18nKey_enterprise_1443: '押金订单详情',
  i18nKey_enterprise_1444: '预定满几小时按天计费',
  i18nKey_enterprise_1445: '请选择月份',
  i18nKey_enterprise_1446: '请选择企业',
  i18nKey_enterprise_1447: '免费额度',
  i18nKey_enterprise_1448: '修改成功！',
  i18nKey_enterprise_1449: '会议名称',
  i18nKey_enterprise_1450: '会议地点',
  i18nKey_enterprise_1451: '预约人',
  i18nKey_enterprise_1452: '实际参会人数',
  i18nKey_enterprise_1453: '选择月',
  i18nKey_enterprise_1454: '月实际电能耗',
  i18nKey_enterprise_1455: '数据汇总',
  i18nKey_enterprise_1456: '耗能总览',
  i18nKey_enterprise_1457: '东里',
  i18nKey_enterprise_1458: '西里',
  i18nKey_enterprise_1459: '万科里',
  i18nKey_enterprise_1460: '请输入仪表号',
  i18nKey_enterprise_1461: '请输入配电开关名称',
  i18nKey_enterprise_1462: '分期',
  i18nKey_enterprise_1463: '生效',
  i18nKey_enterprise_1464: '上次用电量',
  i18nKey_enterprise_1465: '上次用水量',
  i18nKey_enterprise_1466: '本次抄表日期不能为空',
  i18nKey_enterprise_1467: '本次抄表日期格式错误，格式规范为：',
  i18nKey_enterprise_1468: '本次抄表日期数据错误，请输入合理的日期数据',
  i18nKey_enterprise_1469: '本次抄表读数格式错误',
  i18nKey_enterprise_1470: '本次抄表读数小于上次抄表读数，是否确认修改',
  i18nKey_enterprise_1471: '本次还有',
  i18nKey_enterprise_1472: '个表未录入',
  i18nKey_enterprise_1473: '是否要继续生效',
  i18nKey_enterprise_1474: '生效成功',
  i18nKey_enterprise_1475: '请选择要删除数据',
  i18nKey_enterprise_1476: '删除后该设备的所有明细都将删除，确定要删除',
  i18nKey_enterprise_1477: '所选',
  i18nKey_enterprise_1478: '吗？',
  i18nKey_enterprise_1479: '删除失败，请稍后再试',
  i18nKey_enterprise_1480: '到达提醒类型，角色为非必填选择。非',
  i18nKey_enterprise_1481: '到达提醒类型，角色为必填。',
  i18nKey_enterprise_1482: '同步',
  i18nKey_enterprise_1483: '推送',
  i18nKey_enterprise_1484: '服务',
  i18nKey_enterprise_1485: '提交成功！',
  i18nKey_enterprise_1486: '提交失败！',
  i18nKey_enterprise_1487: '信号2',
  i18nKey_enterprise_1488: '设施系统2',
  i18nKey_enterprise_1489: '告警标签',
  i18nKey_enterprise_1490: '告警使能',
  i18nKey_enterprise_1491: '紧急',
  i18nKey_enterprise_1492: '重要',
  i18nKey_enterprise_1493: '一般',
  i18nKey_enterprise_1494: '不确定',
  i18nKey_enterprise_1495: '有效时间',
  i18nKey_enterprise_1496: '预案使能',
  i18nKey_enterprise_1497: '死区',
  i18nKey_enterprise_1498: '请输入死区值',
  i18nKey_enterprise_1499: '请输入告警描述',
  i18nKey_enterprise_1500: '信号1',
  i18nKey_enterprise_1501: '导入失败，只支持',
  i18nKey_enterprise_1502: '导入失败，没有有效数据',
  i18nKey_enterprise_1503: '导入失败，点位编码数据重复',
  i18nKey_enterprise_1504: '此操作数据将永久删除，是否确认？',
  i18nKey_enterprise_1505: '不能为空',
  i18nKey_enterprise_1506: '只能由数字及四则运算符号组成',
  i18nKey_enterprise_1507: '导入失败，只支持新版',
  i18nKey_enterprise_1508: '确认删除？',
  i18nKey_enterprise_1509: '同步中',
  i18nKey_enterprise_1510: '保存失败！',
  i18nKey_enterprise_1511: '源系统设施编号',
  i18nKey_enterprise_1512: '设施二维码',
  i18nKey_enterprise_1513: '重要等级',
  i18nKey_enterprise_1514: '分类层级数量',
  i18nKey_enterprise_1515: '设施序列号',
  i18nKey_enterprise_1516: '坐标-经度',
  i18nKey_enterprise_1517: '坐标-纬度',
  i18nKey_enterprise_1518: '坐标-海拔',
  i18nKey_enterprise_1519: '查看上传设施画像',
  i18nKey_enterprise_1520: '查看上传设施示意图',
  i18nKey_enterprise_1521: '请选择历史版本',
  i18nKey_enterprise_1522: '版本',
  i18nKey_enterprise_1523: '已关联大屏0个',
  i18nKey_enterprise_1524: '长度不能超过50个字符',
  i18nKey_enterprise_1525: '新增失败',
  i18nKey_enterprise_1526: '确定要删除关联关系吗？',
  i18nKey_enterprise_1527: '删除关联成功',
  i18nKey_enterprise_1528: '编码',
  i18nKey_enterprise_1529: '确认保存？',
  i18nKey_enterprise_1530: '确认取消？',
  i18nKey_enterprise_1531: '请将当前层级的',
  i18nKey_enterprise_1532: '编码、业务编码和空间层级名称填写完整！',
  i18nKey_enterprise_1533: '编码和业务编码只能为字母、数字和下划线的组合！',
  i18nKey_enterprise_1534: '新增成功！',
  i18nKey_enterprise_1535: '新增失败！',
  i18nKey_enterprise_1536: '请选择房间',
  i18nKey_enterprise_1537: '人员标签失效时间',
  i18nKey_enterprise_1538: '标签信息',
  i18nKey_enterprise_1539: '是否有效',
  i18nKey_enterprise_1540: '源系统人员编码',
  i18nKey_enterprise_1541: '源系统名称',
  i18nKey_enterprise_1542: '人员标签生效时间',
  i18nKey_enterprise_1543: '照片',
  i18nKey_enterprise_1544: '照片存储地址',
  i18nKey_enterprise_1545: '照片类型编码',
  i18nKey_enterprise_1546: '本地上传，上传照片不超过3',
  i18nKey_enterprise_1547: '人员照片',
  i18nKey_enterprise_1548: '请选择部门',
  i18nKey_enterprise_1549: '选择框',
  i18nKey_enterprise_1550: '护照',
  i18nKey_enterprise_1551: '图片不能为空',
  i18nKey_enterprise_1552: '人员基础数据修改成功',
  i18nKey_enterprise_1553: '手机号：',
  i18nKey_enterprise_1554: '编号：',
  i18nKey_enterprise_1555: '手机号需注册云城',
  i18nKey_enterprise_1556: '添加成功',
  i18nKey_enterprise_1557: '确定打开',
  i18nKey_enterprise_1558: '确定关闭',
  i18nKey_enterprise_1559: '间隔时间',
  i18nKey_enterprise_1560: '全部用户',
  i18nKey_enterprise_1561: '全部群组',
  i18nKey_enterprise_1562: '群信息',
  i18nKey_enterprise_1563: '请输入用户部门',
  i18nKey_enterprise_1564: '确定删除群组',
  i18nKey_enterprise_1565: '确定删除所选用户',
  i18nKey_enterprise_1566: '请选择要删除的用户',
  i18nKey_enterprise_1567: '开启',
  i18nKey_enterprise_1568: '资源',
  i18nKey_enterprise_1569: '当前',
  i18nKey_enterprise_1570: '菜单资源',
  i18nKey_enterprise_1571: '角色删除成功',
  i18nKey_enterprise_1572: '角色删除失败，请重试',
  i18nKey_enterprise_1573: '请选择所属类型',
  i18nKey_enterprise_1574: '角色创建成功',
  i18nKey_enterprise_1575: '网络异常，请稍后重试',
  i18nKey_enterprise_1576: '角色修改成功',
  i18nKey_enterprise_1577: '修改角色',
  i18nKey_enterprise_1578: '超级管理员',
  i18nKey_enterprise_1579: '平台管理员',
  i18nKey_enterprise_1580: '园区管理员',
  i18nKey_enterprise_1581: '平台普通角色',
  i18nKey_enterprise_1582: '园区普通角色',
  i18nKey_enterprise_1583: '园区安防角色',
  i18nKey_enterprise_1584: '园区物业角色',
  i18nKey_enterprise_1585: '园区保洁角色',
  i18nKey_enterprise_1586: '角色名称已经存在',
  i18nKey_enterprise_1587: '挂学警港澳',
  i18nKey_enterprise_1588: '确定要删除吗？',
  i18nKey_enterprise_1589: '月耗电量',
  i18nKey_enterprise_1590: '千瓦小时',
  i18nKey_enterprise_1591: '月分摊耗电量',
  i18nKey_enterprise_1592: '月消费金额',
  i18nKey_enterprise_1593: '月分摊金额',
  i18nKey_enterprise_1594: '分摊电量',
  i18nKey_enterprise_1595: '分摊比例',
  i18nKey_enterprise_1596: '分摊金额',
  i18nKey_enterprise_1597: '实际耗电量（',
  i18nKey_enterprise_1598: '实际消费金额',
  i18nKey_enterprise_1599: '运行',
  i18nKey_enterprise_1600: '泊梯',
  i18nKey_enterprise_1601: '检修',
  i18nKey_enterprise_1602: '客梯',
  i18nKey_enterprise_1603: '栋电梯',
  i18nKey_enterprise_1604: '灯控',
  i18nKey_enterprise_1605: '设备类型',
  i18nKey_enterprise_1606: '设备地址',
  i18nKey_enterprise_1607: '加载中',
  i18nKey_enterprise_1608: '更新中',
  i18nKey_enterprise_1609: '输入设备名称',
  i18nKey_enterprise_1610: '播放中',
  i18nKey_enterprise_1611: '每个大屏',
  i18nKey_enterprise_1612: '添加失败',
  i18nKey_enterprise_1613: '确定是否要删除该方案？',
  i18nKey_enterprise_1614: '下发方案成功',
  i18nKey_enterprise_1615: '启用状态',
  i18nKey_enterprise_1616: '删除该策略',
  i18nKey_enterprise_1617: '个策略的生效时间不能为空',
  i18nKey_enterprise_1618: '个策略中亮灯规则',
  i18nKey_enterprise_1619: '的开启时间不能为空',
  i18nKey_enterprise_1620: '保存断路器配置成功！',
  i18nKey_enterprise_1621: '更改成功',
  i18nKey_enterprise_1622: '客户列表',
  i18nKey_enterprise_1623: '人员编号',
  i18nKey_enterprise_1624: '近一周到访园区',
  i18nKey_enterprise_1625: '近一周凌晨到访园区',
  i18nKey_enterprise_1626: '用户周期',
  i18nKey_enterprise_1627: '习惯下单时间',
  i18nKey_enterprise_1628: '耗时最短',
  i18nKey_enterprise_1629: '耗时最长',
  i18nKey_enterprise_1630: '用时',
  i18nKey_enterprise_1631: '件',
  i18nKey_enterprise_1632: '设施',
  i18nKey_enterprise_1633: '设施数量',
  i18nKey_enterprise_1634: '平均处理时长',
  i18nKey_enterprise_1635: '设施编码',
  i18nKey_enterprise_1636: '分向维度',
  i18nKey_enterprise_1637: '请选择分向维度',
  i18nKey_enterprise_1638: '分向维护',
  i18nKey_enterprise_1639: '请选择分向信息',
  i18nKey_enterprise_1640: '电表分向维护成功！',
  i18nKey_enterprise_1641: '级分项',
  i18nKey_enterprise_1642: '请选择分项',
  i18nKey_enterprise_1643: '异常类型',
  i18nKey_enterprise_1644: '请选择等级',
  i18nKey_enterprise_1645: '今日实际电能耗（',
  i18nKey_enterprise_1646: '上周实际电能耗（',
  i18nKey_enterprise_1647: '用电详情',
  i18nKey_enterprise_1648: '严重告警',
  i18nKey_enterprise_1649: '保存失败',
  i18nKey_enterprise_1650: '用电时间',
  i18nKey_enterprise_1651: '统计时间',
  i18nKey_enterprise_1652: '本月实际电能耗（',
  i18nKey_enterprise_1653: '上月实际电能耗（',
  i18nKey_enterprise_1654: '故障信息',
  i18nKey_enterprise_1655: '从',
  i18nKey_enterprise_1656: '该故障重复发生了1次，累积时长',
  i18nKey_enterprise_1657: '小时，可造成全年浪费',
  i18nKey_enterprise_1658: '处理状态',
  i18nKey_enterprise_1659: '诊断处理',
  i18nKey_enterprise_1660: '故障名称',
  i18nKey_enterprise_1661: '请输入故障名称',
  i18nKey_enterprise_1662: '故障等级',
  i18nKey_enterprise_1663: '故障统计',
  i18nKey_enterprise_1664: '故障数量',
  i18nKey_enterprise_1665: '故障总数',
  i18nKey_enterprise_1666: '黄龙万科',
  i18nKey_enterprise_1667: '用',
  i18nKey_enterprise_1668: '电',
  i18nKey_enterprise_1669: '水',
  i18nKey_enterprise_1670: '量',
  i18nKey_enterprise_1671: '总用电量',
  i18nKey_enterprise_1672: '总用',
  i18nKey_enterprise_1673: '点位信息设备报表--',
  i18nKey_enterprise_1674: '机房功率（',
  i18nKey_enterprise_1675: '峰值功率（',
  i18nKey_enterprise_1676: '环图',
  i18nKey_enterprise_1677: '年在整个项目中，已累计为您发现了共计',
  i18nKey_enterprise_1678: '的节能潜力',
  i18nKey_enterprise_1679: '南区',
  i18nKey_enterprise_1680: '机房',
  i18nKey_enterprise_1681: '北区',
  i18nKey_enterprise_1682: '今天',
  i18nKey_enterprise_1683: '变量',
  i18nKey_enterprise_1684: '能效比',
  i18nKey_enterprise_1685: '实时功率',
  i18nKey_enterprise_1686: '充值金额（元）',
  i18nKey_enterprise_1687: '消耗金额（元）',
  i18nKey_enterprise_1688: '月耗',
  i18nKey_enterprise_1689: '月充值金额',
  i18nKey_enterprise_1690: '月消耗金额',
  i18nKey_enterprise_1691: '月剩余金额',
  i18nKey_enterprise_1692: '温馨提示：工单关闭后将不再对该工单进行操作，是否要关闭？',
  i18nKey_enterprise_1693: '点击确定创建恢复工单后',
  i18nKey_enterprise_1694: '您可以在断电恢复工单列表中查看进度！',
  i18nKey_enterprise_1695: '温馨提示：您可以选择手工恢复断电工单后',
  i18nKey_enterprise_1696: '几天内不产生断电工单，不选择默认达到断电阈值后会继续产生断电工单',
  i18nKey_enterprise_1697: '表号',
  i18nKey_enterprise_1698: '断电处理中',
  i18nKey_enterprise_1699: '断电失败',
  i18nKey_enterprise_1700: '已断电',
  i18nKey_enterprise_1701: '完成时间',
  i18nKey_enterprise_1702: '你可以尝试重新断电，或者关闭工单前往现场处理',
  i18nKey_enterprise_1703: '合闸方式',
  i18nKey_enterprise_1704: '现场手工合闸',
  i18nKey_enterprise_1705: '远程合闸',
  i18nKey_enterprise_1706: '断电恢复中',
  i18nKey_enterprise_1707: '恢复失败',
  i18nKey_enterprise_1708: '已恢复',
  i18nKey_enterprise_1709: '你可以尝试重新恢复，或者关闭工单前往现场处理',
  i18nKey_enterprise_1710: '温馨提示：该合同中部分仪表无法通过远程恢复断电，需要前往现场恢复合闸',
  i18nKey_enterprise_1711: '处理完成',
  i18nKey_enterprise_1712: '选择商户：',
  i18nKey_enterprise_1713: '选择品牌：',
  i18nKey_enterprise_1714: '电表阈值',
  i18nKey_enterprise_1715: '水表阈值',
  i18nKey_enterprise_1716: '断电阈值',
  i18nKey_enterprise_1717: '暂无合同数据，请先选择商户和品牌',
  i18nKey_enterprise_1718: '电表断电阈值设定后，达到阈值后产生处理工单',
  i18nKey_enterprise_1719: '是否启动断电',
  i18nKey_enterprise_1720: '已启用断电',
  i18nKey_enterprise_1721: '未启用断电',
  i18nKey_enterprise_1722: '选择开始时间',
  i18nKey_enterprise_1723: '选择结束时间',
  i18nKey_enterprise_1724: '选择图标',
  i18nKey_enterprise_1725: '场景锁开关',
  i18nKey_enterprise_1726: '成功开启',
  i18nKey_enterprise_1727: '成功关闭',
  i18nKey_enterprise_1728: '批量删除',
  i18nKey_enterprise_1729: '户型名称格式示例：',
  i18nKey_enterprise_1730: '房2厅',
  i18nKey_enterprise_1731: '确定删除户型',
  i18nKey_enterprise_1732: '户型',
  i18nKey_enterprise_1733: '成功',
  i18nKey_enterprise_1734: '房号',
  i18nKey_enterprise_1735: '新增楼栋单元及公区',
  i18nKey_enterprise_1736: '请输入楼栋单元',
  i18nKey_enterprise_1737: '新增房号',
  i18nKey_enterprise_1738: '请输入房号',
  i18nKey_enterprise_1739: '请选择单元楼栋添加房号',
  i18nKey_enterprise_1740: '请选择要删除的房间',
  i18nKey_enterprise_1741: '播放暂停1-----',
  i18nKey_enterprise_1742: '暂停1---',
  i18nKey_enterprise_1743: '定时器',
  i18nKey_enterprise_1744: '播放暂停3----',
  i18nKey_enterprise_1745: '播放---',
  i18nKey_enterprise_1746: '定时轮流播放巡逻组----',
  i18nKey_enterprise_1747: '当前播放---第',
  i18nKey_enterprise_1748: '个巡逻组-----第',
  i18nKey_enterprise_1749: '个摄像机',
  i18nKey_enterprise_1750: '视频暂停中',
  i18nKey_enterprise_1751: '视频巡逻结束',
  i18nKey_enterprise_1752: '新增巡逻路线',
  i18nKey_enterprise_1753: '巡逻路线列表',
  i18nKey_enterprise_1754: '实时视频',
  i18nKey_enterprise_1755: '选择开始日期时间',
  i18nKey_enterprise_1756: '选择结束日期时间',
  i18nKey_enterprise_1757: '关联历史视频',
  i18nKey_enterprise_1758: '截取成功',
  i18nKey_enterprise_1759: '视频截取失败',
  i18nKey_enterprise_1760: '请选择工位类型',
  i18nKey_enterprise_1761: '基础设置',
  i18nKey_enterprise_1762: '提前',
  i18nKey_enterprise_1763: '个月折扣',
  i18nKey_enterprise_1764: '年及以上',
  i18nKey_enterprise_1765: '个月',
  i18nKey_enterprise_1766: '创建成功！',
  i18nKey_enterprise_1767: '原价',
  i18nKey_enterprise_1768: '管理员',
  i18nKey_enterprise_1769: '暂停歇业开始时间',
  i18nKey_enterprise_1770: '暂停歇业结束时间',
  i18nKey_enterprise_1771: '提前退订',
  i18nKey_enterprise_1772: '可提前多少天退订',
  i18nKey_enterprise_1773: '请输入地址纬度',
  i18nKey_enterprise_1774: '附加设施',
  i18nKey_enterprise_1775: '增加附加设施',
  i18nKey_enterprise_1776: '例如：首小时15元（前15分钟免费），此后每小时10元',
  i18nKey_enterprise_1777: '封顶。',
  i18nKey_enterprise_1778: '附近停车场',
  i18nKey_enterprise_1779: '例子：平安',
  i18nKey_enterprise_1780: '首小时15元（前15分钟免费），此后每小时10元',
  i18nKey_enterprise_1781: '广东省深圳市福田区',
  i18nKey_enterprise_1782: '新增办公空间',
  i18nKey_enterprise_1783: '修改办公空间',
  i18nKey_enterprise_1784: '办公空间详情',
  i18nKey_enterprise_1785: '新增加附加设施菜单路径：企业服务',
  i18nKey_enterprise_1786: '会议室管理',
  i18nKey_enterprise_1787: '增值服务！',
  i18nKey_enterprise_1788: '已存在！',
  i18nKey_enterprise_1789: '地址经纬度输入需用英文逗号分隔',
  i18nKey_enterprise_1790: '且不能大于20个字符',
  i18nKey_enterprise_1791: '请输入工位编号',
  i18nKey_enterprise_1792: '请选择需要上传的',
  i18nKey_enterprise_1793: '图！',
  i18nKey_enterprise_1794: '的图片文件！',
  i18nKey_enterprise_1795: '黑名单布控',
  i18nKey_enterprise_1796: '访客邀请',
  i18nKey_enterprise_1797: '访客信息',
  i18nKey_enterprise_1798: '请输入访客姓名',
  i18nKey_enterprise_1799: '请输入工作单位',
  i18nKey_enterprise_1800: '请选择访客',
  i18nKey_enterprise_1801: '上海',
  i18nKey_enterprise_1802: '日期：',
  i18nKey_enterprise_1803: '人员占比统计',
  i18nKey_enterprise_1804: '人员数排行',
  i18nKey_enterprise_1805: '前日',
  i18nKey_enterprise_1806: '请选择更新日期',
  i18nKey_enterprise_1807: '位置类型',
  i18nKey_enterprise_1808: '请选择位置类型',
  i18nKey_enterprise_1809: '请选择设备状态',
  i18nKey_enterprise_1810: '园区到访',
  i18nKey_enterprise_1811: '过',
  i18nKey_enterprise_1812: '受访地址',
  i18nKey_enterprise_1813: '请输入访客电话',
  i18nKey_enterprise_1814: '授权时间段',
  i18nKey_enterprise_1815: '拜访事由',
  i18nKey_enterprise_1816: '通行类型',
  i18nKey_enterprise_1817: '请选择厂商名称',
  i18nKey_enterprise_1818: '是否删除？',
  i18nKey_enterprise_1819: '导出表格',
  i18nKey_enterprise_1820: '疫情防控信息',
  i18nKey_enterprise_1821: '邀请成功',
  i18nKey_enterprise_1822: '访客邀请数',
  i18nKey_enterprise_1823: '访客邀请统计',
  i18nKey_enterprise_1824: '近30天',
  i18nKey_enterprise_1825: '园区访客邀请排行',
  i18nKey_enterprise_1826: '访客统计',
  i18nKey_enterprise_1827: '园区历史总邀请',
  i18nKey_enterprise_1828: '历史总邀请',
  i18nKey_enterprise_1829: '请输入设备编号',
  i18nKey_enterprise_1830: '使用中',
  i18nKey_enterprise_1831: '备用',
  i18nKey_enterprise_1832: '维修中',
  i18nKey_enterprise_1833: '通行记录',
  i18nKey_enterprise_1834: '是否删除',
  i18nKey_enterprise_1835: '新增路线',
  i18nKey_enterprise_1836: '路线名称',
  i18nKey_enterprise_1837: '请输入路线名称',
  i18nKey_enterprise_1838: '是否企业认证',
  i18nKey_enterprise_1839: '是否泊寓租户',
  i18nKey_enterprise_1840: '请输姓名或手机号',
  i18nKey_enterprise_1841: '乘车路线',
  i18nKey_enterprise_1842: '剩余次数',
  i18nKey_enterprise_1843: '支付失败',
  i18nKey_enterprise_1844: '无乘车记录，不需要执行导出操作！',
  i18nKey_enterprise_1845: '报表？',
  i18nKey_enterprise_1846: '临停缴费',
  i18nKey_enterprise_1847: '查询列表',
  i18nKey_enterprise_1848: '京',
  i18nKey_enterprise_1849: '津',
  i18nKey_enterprise_1850: '沪',
  i18nKey_enterprise_1851: '渝',
  i18nKey_enterprise_1852: '新',
  i18nKey_enterprise_1853: '苏',
  i18nKey_enterprise_1854: '浙',
  i18nKey_enterprise_1855: '黑',
  i18nKey_enterprise_1856: '湘',
  i18nKey_enterprise_1857: '皖',
  i18nKey_enterprise_1858: '鲁',
  i18nKey_enterprise_1859: '赣',
  i18nKey_enterprise_1860: '冀',
  i18nKey_enterprise_1861: '豫',
  i18nKey_enterprise_1862: '云',
  i18nKey_enterprise_1863: '辽',
  i18nKey_enterprise_1864: '鄂',
  i18nKey_enterprise_1865: '桂',
  i18nKey_enterprise_1866: '甘',
  i18nKey_enterprise_1867: '晋',
  i18nKey_enterprise_1868: '蒙',
  i18nKey_enterprise_1869: '陕',
  i18nKey_enterprise_1870: '吉',
  i18nKey_enterprise_1871: '闽',
  i18nKey_enterprise_1872: '贵',
  i18nKey_enterprise_1873: '粤',
  i18nKey_enterprise_1874: '青',
  i18nKey_enterprise_1875: '藏',
  i18nKey_enterprise_1876: '川',
  i18nKey_enterprise_1877: '宁',
  i18nKey_enterprise_1878: '琼',
  i18nKey_enterprise_1879: '台',
  i18nKey_enterprise_1880: '港',
  i18nKey_enterprise_1881: '澳',
  i18nKey_enterprise_1882: '大于8',
  i18nKey_enterprise_1883: '大于12',
  i18nKey_enterprise_1884: '大于24',
  i18nKey_enterprise_1885: '累计',
  i18nKey_enterprise_1886: '大于1200',
  i18nKey_enterprise_1887: '大于1600',
  i18nKey_enterprise_1888: '小于5',
  i18nKey_enterprise_1889: '累计停车缴费',
  i18nKey_enterprise_1890: '基本属性',
  i18nKey_enterprise_1891: '标签预设',
  i18nKey_enterprise_1892: '近一周入车场频次5次',
  i18nKey_enterprise_1893: '近一月入车场次数21次',
  i18nKey_enterprise_1894: '近一周最长停留时长5小时',
  i18nKey_enterprise_1895: '出入频繁南大门停车场',
  i18nKey_enterprise_1896: '进出时间段',
  i18nKey_enterprise_1897: '使用套餐200',
  i18nKey_enterprise_1898: '近一年购买套餐2次',
  i18nKey_enterprise_1899: '累计消费',
  i18nKey_enterprise_1900: '以上',
  i18nKey_enterprise_1901: '电商平台的消费金额500元',
  i18nKey_enterprise_1902: '进场',
  i18nKey_enterprise_1903: '前一月',
  i18nKey_enterprise_1904: '去年9月',
  i18nKey_enterprise_1905: '在场人数',
  i18nKey_enterprise_1906: '年龄占比',
  i18nKey_enterprise_1907: '深圳云城',
  i18nKey_enterprise_1908: '永庆坊',
  i18nKey_enterprise_1909: '层',
  i18nKey_enterprise_1910: '区',
  i18nKey_enterprise_1911: '请输入票',
  i18nKey_enterprise_1912: '如有多个停靠站，请按如下格式录入：万科云城',
  i18nKey_enterprise_1913: '宝安中心',
  i18nKey_enterprise_1914: '前海湾',
  i18nKey_enterprise_1915: '宝安机场',
  i18nKey_enterprise_1916: '乘车条件',
  i18nKey_enterprise_1917: '路线图',
  i18nKey_enterprise_1918: '是否要企业认证',
  i18nKey_enterprise_1919: '是否是泊寓租户',
  i18nKey_enterprise_1920: '修改路线',
  i18nKey_enterprise_1921: '查看路线',
  i18nKey_enterprise_1922: '例如',
  i18nKey_enterprise_1923: '请输入扫码设备',
  i18nKey_enterprise_1924: '请选择多段收费',
  i18nKey_enterprise_1925: '例如：大巴限乘50人',
  i18nKey_enterprise_1926: '请选择是否多段收费',
  i18nKey_enterprise_1927: '请选择有效日期',
  i18nKey_enterprise_1928: '自动换算价格）',
  i18nKey_enterprise_1929: '请输入小于1',
  i18nKey_enterprise_1930: '之间的价格',
  i18nKey_enterprise_1931: '请选择使用的巴士班次',
  i18nKey_enterprise_1932: '修改套餐',
  i18nKey_enterprise_1933: '查看套餐',
  i18nKey_enterprise_1934: '发送短信',
  i18nKey_enterprise_1935: '门禁监控',
  i18nKey_enterprise_1936: '该楼宇全部门禁',
  i18nKey_enterprise_1937: '打开',
  i18nKey_enterprise_1938: '门禁控制',
  i18nKey_enterprise_1939: '布控实况',
  i18nKey_enterprise_1940: '更多动态',
  i18nKey_enterprise_1941: '图片上传',
  i18nKey_enterprise_1942: '新增人脸布控',
  i18nKey_enterprise_1943: '新增车辆布控',
  i18nKey_enterprise_1944: '消警',
  i18nKey_enterprise_1945: '转设施告警',
  i18nKey_enterprise_1946: '非法入侵',
  i18nKey_enterprise_1947: '电话呼叫',
  i18nKey_enterprise_1948: '拨号',
  i18nKey_enterprise_1949: '常用',
  i18nKey_enterprise_1950: '联系人',
  i18nKey_enterprise_1951: '保安队长-呼叫中',
  i18nKey_enterprise_1952: '挂断',
  i18nKey_enterprise_1953: '告警图片',
  i18nKey_enterprise_1954: '告警头像',
  i18nKey_enterprise_1955: '上传列表（',
  i18nKey_enterprise_1956: '查看关联视频',
  i18nKey_enterprise_1957: '只能上传10',
  i18nKey_enterprise_1958: '内的图片',
  i18nKey_enterprise_1959: '格式的视频',
  i18nKey_enterprise_1960: '人员选择',
  i18nKey_enterprise_1961: '人员热力图',
  i18nKey_enterprise_1962: '没有人员',
  i18nKey_enterprise_1963: '发送',
  i18nKey_enterprise_1964: '发送短信内容不能为空',
  i18nKey_enterprise_1965: '短信发送成功',
  i18nKey_enterprise_1966: '发送短信失败人员有',
  i18nKey_enterprise_1967: '请留意以上人员接收不到短信',
  i18nKey_enterprise_1968: '万科智慧园区运营中心提醒】万科智慧园区运营中心提醒您：',
  i18nKey_enterprise_1969: '谢谢！',
  i18nKey_enterprise_1970: '短信接收人员不能为空',
  i18nKey_enterprise_1971: '人员热力后台返回',
  i18nKey_enterprise_1972: '通讯录',
  i18nKey_enterprise_1973: '查找联系人',
  i18nKey_enterprise_1974: '通话中',
  i18nKey_enterprise_1975: '视频通话',
  i18nKey_enterprise_1976: '路灯管理',
  i18nKey_enterprise_1977: '路灯名称',
  i18nKey_enterprise_1978: '路灯状态',
  i18nKey_enterprise_1979: '园区楼栋',
  i18nKey_enterprise_1980: '打开门禁',
  i18nKey_enterprise_1981: '关闭门禁',
  i18nKey_enterprise_1982: '警告',
  i18nKey_enterprise_1983: '有可疑目标人员出现在',
  i18nKey_enterprise_1984: '派人查看',
  i18nKey_enterprise_1985: '分类名称',
  i18nKey_enterprise_1986: '请输入分类名称',
  i18nKey_enterprise_1987: '分类名称不能为空',
  i18nKey_enterprise_1988: '园区空间类型',
  i18nKey_enterprise_1989: '园区空间信息',
  i18nKey_enterprise_1990: '开门方式',
  i18nKey_enterprise_1991: '空间服务人员',
  i18nKey_enterprise_1992: '空间描述',
  i18nKey_enterprise_1993: '空间首图',
  i18nKey_enterprise_1994: '密码开门',
  i18nKey_enterprise_1995: '二维码开门',
  i18nKey_enterprise_1996: '人脸开门',
  i18nKey_enterprise_1997: '空间编号',
  i18nKey_enterprise_1998: '二维码平台',
  i18nKey_enterprise_1999: '请选择二维码平台',
  i18nKey_enterprise_2000: '二维码下载',
  i18nKey_enterprise_2001: '平台',
  i18nKey_enterprise_2002: '小程序',
  i18nKey_enterprise_2003: '请选择二维码类型',
  i18nKey_enterprise_2004: '请选择园区空间类型',
  i18nKey_enterprise_2005: '请选择在线密码',
  i18nKey_enterprise_2006: '请选择门锁类型',
  i18nKey_enterprise_2007: '门锁类型',
  i18nKey_enterprise_2008: '新增园区空间类型',
  i18nKey_enterprise_2009: '请输入空间描述',
  i18nKey_enterprise_2010: '请选择门锁编号！',
  i18nKey_enterprise_2011: '新增园区空间',
  i18nKey_enterprise_2012: '修改园区空间',
  i18nKey_enterprise_2013: '园区空间详情',
  i18nKey_enterprise_2014: '闭',
  i18nKey_enterprise_2015: '用水',
  i18nKey_enterprise_2016: '请输入正数',
  i18nKey_enterprise_2017: '最多保留两位小数',
  i18nKey_enterprise_2018: '当前位置没有房间',
  i18nKey_enterprise_2019: '导入文件',
  i18nKey_enterprise_2020: '导入失败，第',
  i18nKey_enterprise_2021: '行数据有错误，请检查，稍后再次提交导入',
  i18nKey_enterprise_2022: '用电数据统计',
  i18nKey_enterprise_2023: '生效日期',
  i18nKey_enterprise_2024: '用水数据统计',
  i18nKey_enterprise_2025: '立方米',
  i18nKey_enterprise_2026: '运营者手机号需注册云城',
  i18nKey_enterprise_2027: '手机号码格式不正确',
  i18nKey_enterprise_2028: '请输入车场名称',
  i18nKey_enterprise_2029: '超过',
  i18nKey_enterprise_2030: '次，不能办理月卡',
  i18nKey_enterprise_2031: '次，不能进入车场',
  i18nKey_enterprise_2032: '共开门',
  i18nKey_enterprise_2033: '提示：视频画面延迟约5-10',
  i18nKey_enterprise_2034: '请耐心等待。',
  i18nKey_enterprise_2035: '运行时间：',
  i18nKey_enterprise_2036: '号梯',
  i18nKey_enterprise_2037: '电机转速：',
  i18nKey_enterprise_2038: '主轴温度：',
  i18nKey_enterprise_2039: '曳引绳拉伸度：',
  i18nKey_enterprise_2040: '音量控制',
  i18nKey_enterprise_2041: '共有照明回路：',
  i18nKey_enterprise_2042: '个，',
  i18nKey_enterprise_2043: '开关',
  i18nKey_enterprise_2044: '暂无',
  i18nKey_enterprise_2045: '确定要全部',
  i18nKey_enterprise_2046: '个回路吗',
  i18nKey_enterprise_2047: '确定要',
  i18nKey_enterprise_2048: '这',
  i18nKey_enterprise_2049: '个回路',
  i18nKey_enterprise_2050: '更改失败，请稍后再试',
  i18nKey_enterprise_2051: '开启成功',
  i18nKey_enterprise_2052: '关闭成功',
  i18nKey_enterprise_2053: '更改失败',
  i18nKey_enterprise_2054: '二亮一循环',
  i18nKey_enterprise_2055: '感应延迟微亮',
  i18nKey_enterprise_2056: '网关名称2',
  i18nKey_enterprise_2057: '请选择开启时间',
  i18nKey_enterprise_2058: '请输入时',
  i18nKey_enterprise_2059: '请输入分',
  i18nKey_enterprise_2060: '请输入手机精确搜索匹配',
  i18nKey_enterprise_2061: '请选择提醒人',
  i18nKey_enterprise_2062: '已添加',
  i18nKey_enterprise_2063: '请录入新仪表信息',
  i18nKey_enterprise_2064: '提交变更',
  i18nKey_enterprise_2065: '变更成功！',
  i18nKey_enterprise_2066: '下载电表模版',
  i18nKey_enterprise_2067: '下载水表模版',
  i18nKey_enterprise_2068: '请填写仪表类别',
  i18nKey_enterprise_2069: '请填写仪表表号',
  i18nKey_enterprise_2070: '号码为',
  i18nKey_enterprise_2071: '个数字组成！',
  i18nKey_enterprise_2072: '请正确填写有效期，格式为：',
  i18nKey_enterprise_2073: '请填写状态',
  i18nKey_enterprise_2074: '当前导入文件校验有误请在本地修改后重新导入！',
  i18nKey_enterprise_2075: '已有仪表存在，是否覆盖？',
  i18nKey_enterprise_2076: '设备：',
  i18nKey_enterprise_2077: '更新失败',
  i18nKey_enterprise_2078: '栋',
  i18nKey_enterprise_2079: '室',
  i18nKey_enterprise_2080: '表格模板',
  i18nKey_enterprise_2081: '请填写必要信息',
  i18nKey_enterprise_2082: '请输入合法手机号',
  i18nKey_enterprise_2083: '房屋',
  i18nKey_enterprise_2084: '两室一厅',
  i18nKey_enterprise_2085: '重置成功',
  i18nKey_enterprise_2086: '停车场区域',
  i18nKey_enterprise_2087: '解绑',
  i18nKey_enterprise_2088: '新增公区设备',
  i18nKey_enterprise_2089: '设备信息更新成功',
  i18nKey_enterprise_2090: '确定删除设备',
  i18nKey_enterprise_2091: '添加设备成功',
  i18nKey_enterprise_2092: '删除后不可恢复，确定要删除吗？',
  i18nKey_enterprise_2093: '人员总览',
  i18nKey_enterprise_2094: '人员对比趋势',
  i18nKey_enterprise_2095: '近两周',
  i18nKey_enterprise_2096: '最近12周',
  i18nKey_enterprise_2097: '最近12个月',
  i18nKey_enterprise_2098: '视频画面延迟约5',
  i18nKey_enterprise_2099: '请耐心等待',
  i18nKey_enterprise_2100: '视频加载中',
  i18nKey_enterprise_2101: '请稍后',
  i18nKey_enterprise_2102: '失败',
  i18nKey_enterprise_2103: '请输入服务器',
  i18nKey_enterprise_2104: '或域名',
  i18nKey_enterprise_2105: '您的浏览器不支持',
  i18nKey_enterprise_2106: '临停缴费金额',
  i18nKey_enterprise_2107: '套餐缴费金额',
  i18nKey_enterprise_2108: '请输入汽车品牌',
  i18nKey_enterprise_2109: '奥迪',
  i18nKey_enterprise_2110: '奔驰',
  i18nKey_enterprise_2111: '宝马',
  i18nKey_enterprise_2112: '本田',
  i18nKey_enterprise_2113: '丰田',
  i18nKey_enterprise_2114: '大众',
  i18nKey_enterprise_2115: '福特',
  i18nKey_enterprise_2116: '别克',
  i18nKey_enterprise_2117: '近一个月缴费',
  i18nKey_enterprise_2118: '累计缴费',
  i18nKey_enterprise_2119: '车牌超过6个选项',
  i18nKey_enterprise_2120: '已为您按最优显示',
  i18nKey_enterprise_2121: '品牌超过6个选项',
  i18nKey_enterprise_2122: '颜色超过6个选项',
  i18nKey_enterprise_2123: '车牌和品牌超过6个选项',
  i18nKey_enterprise_2124: '车牌和颜色超过6个选项',
  i18nKey_enterprise_2125: '品牌和颜色超过6个选项',
  i18nKey_enterprise_2126: '车牌，品牌和颜色超过6个选项',
  i18nKey_enterprise_2127: '回头客人数',
  i18nKey_enterprise_2128: '人）',
  i18nKey_enterprise_2129: '回头客到访次数',
  i18nKey_enterprise_2130: '人次）',
  i18nKey_enterprise_2131: '直接访问',
  i18nKey_enterprise_2132: '时间选择',
  i18nKey_enterprise_2133: '请选择筛选时间',
  i18nKey_enterprise_2134: '起点',
  i18nKey_enterprise_2135: '近一周',
  i18nKey_enterprise_2136: '客流人次',
  i18nKey_enterprise_2137: '岁',
  i18nKey_enterprise_2138: '起始日期',
  i18nKey_enterprise_2139: '天数',
  i18nKey_enterprise_2140: '节日名称',
  i18nKey_enterprise_2141: '日历详细信息',
  i18nKey_enterprise_2142: '承租人',
  i18nKey_enterprise_2143: '移除成功',
  i18nKey_enterprise_2144: '移除失败',
  i18nKey_enterprise_2145: '修改失败！',
  i18nKey_enterprise_2146: '未分配设备',
  i18nKey_enterprise_2147: '下载失败',
  i18nKey_enterprise_2148: '请输入承租方名称',
  i18nKey_enterprise_2149: '请选择店铺状态',
  i18nKey_enterprise_2150: '暂无设备',
  i18nKey_enterprise_2151: '房间数：',
  i18nKey_enterprise_2152: '删除房间',
  i18nKey_enterprise_2153: '确定删除房间',
  i18nKey_enterprise_2154: '场景数：',
  i18nKey_enterprise_2155: '场景开关',
  i18nKey_enterprise_2156: '确定批量删除这些场景吗？',
  i18nKey_enterprise_2157: '确定删除场景',
  i18nKey_enterprise_2158: '请选择区域名称',
  i18nKey_enterprise_2159: '请选择起始日期',
  i18nKey_enterprise_2160: '活动名称不允许为空',
  i18nKey_enterprise_2161: '请输入节日名称',
  i18nKey_enterprise_2162: '一级标题',
  i18nKey_enterprise_2163: '二级标题1',
  i18nKey_enterprise_2164: '三级标题1',
  i18nKey_enterprise_2165: '二级标题2',
  i18nKey_enterprise_2166: '三级标题2',
  i18nKey_enterprise_2167: "冷量表",
  i18nKey_enterprise_2168: "下载冷表模版",
  i18nKey_enterprise_2169: "号码为××个数字组成！",
  i18nKey_enterprise_2170: "商/租户类型",
  i18nKey_enterprise_2171: "商/租户名称",
  i18nKey_3066: "离场时间",
  i18nKey_3067: "公式配置",
  i18nKey_3068: "分项配置",
  i18nKey_3069: "支路配置",
  i18nKey_3070: "建筑区域配置",
  i18nKey_3071: "租商户配置",
  i18nKey_3072: "冷冻站",
  i18nKey_3073: "制冷主机",
  i18nKey_3074: "冷却塔",
  i18nKey_3075: "冷却泵",
  i18nKey_3076: "热力站",
  i18nKey_3077: "制热主机",
  i18nKey_3078: "采暖泵",
  i18nKey_3079: "空调末端",
  i18nKey_3080: "空调箱",
  i18nKey_3081: "新风机组",
  i18nKey_3082: "其他末端",
  i18nKey_3083: "扶梯",
  i18nKey_3084: "直梯",
  i18nKey_3085: "观光直梯",
  i18nKey_3086: "风机",
  i18nKey_3087: "送风机",
  i18nKey_3088: "排风机",
  i18nKey_3089: "排烟机",
  i18nKey_3090: "送排风机",
  i18nKey_3091: "泵",
  i18nKey_3092: "生活水泵",
  i18nKey_3093: "排污泵",
  i18nKey_3094: "中水系统",
  i18nKey_3095: "应急照明",
  i18nKey_3096: "公共照明",
  i18nKey_3097: "泛光照明",
  i18nKey_3098: "景观照明",
  i18nKey_3099: "充电桩",
  i18nKey_3100: "机械车位",
  i18nKey_3101: "防火卷帘",
  i18nKey_3102: "强弱电机房",
  i18nKey_3103: "强电机房",
  i18nKey_3104: "弱电机房",
  i18nKey_3105: "公寓用电",
  i18nKey_3106: "主力店",
  i18nKey_3107: "次主力店",
  i18nKey_3108: "选择要拉取下级设备",
  i18nKey_3109: "仪表编号",
  i18nKey_3110: "请选择仪表编号",
  i18nKey_3111: "请输入仪表编号",
  i18nKey_3112: "名称：总电耗",
  i18nKey_3113: "租户用水",
  i18nKey_3114: "公区用水",
  i18nKey_3115: "名称：水表",
  i18nKey_3116: "租户用冷",
  i18nKey_3117: "新增分项",
  i18nKey_3118: "分项名称",
  i18nKey_3119: "新增一级分项",
  i18nKey_3120: "设备简称",
  i18nKey_3121: "设备所属权",
  i18nKey_3122: "请输入设备所属权",
  i18nKey_3123: "设备有效期",
  i18nKey_3124: "请输入设备品牌",
  i18nKey_3125: "用电范围",
  i18nKey_3126: "请选择用电范围",
  i18nKey_3127: "建筑区域配置",
  i18nKey_3128: "查询租商户信息",
  i18nKey_3129: "请输入租商户或品牌名称",
  i18nKey_3130: "租商户名称",
  i18nKey_3131: "仪表搜索",
  i18nKey_3132: "请输入仪表表号、智能表编号、仪表名称",
  i18nKey_3133: "车位总数",
  i18nKey_3134: "在场车辆",
  i18nKey_3135: "套餐车",
  i18nKey_3136: "临停车",
  i18nKey_3137: "白名单车",
  i18nKey_3138: "其他车辆",
  i18nKey_3139: "其他车辆：非套餐车、临停车和白名单的车辆",
  i18nKey_3140: "车牌类型",
  i18nKey_3141: "请选择车牌类型",
  i18nKey_3142: "统计事件数",
  i18nKey_3143: "工单数",
  i18nKey_3144: "商/租户",
  i18nKey_3145: "是否自动表",
  i18nKey_3146: "当前未有任何分项",
  i18nKey_3147: "采用模板创建",
  i18nKey_3148: "预览模板",
  i18nKey_3149: "数值为大于0的数字类型",
  i18nKey_3150: "选择计量表配置",
  i18nKey_3151: "选择分项配置",
  i18nKey_3152: "备注：在下一级分项中新增或删除仪表，需要手动“选择要拉取的下级设备”更新上一级分项",
  i18nKey_3153: "备注：①末级分项通过选择计量表的方式进行配置;当级分项如有计量表，则通过选择计量表的方式进行配置;<br />②若在下一级分项中新增或删除仪表，上一级是通过分项配置的，则系统自动更新上级分项的公示。",
  i18nKey_3154: "数值为0~1数字类型",
  i18nKey_3155: "数值为数字类型",
  i18nKey_3156: "请选择分项名称",
  i18nKey_3157: "能耗公式",
  i18nKey_3158: "每平米公式",
  i18nKey_3159: "选择分项",
  i18nKey_3160: "保存失败,请输入面积",
  i18nKey_3161: "电表编号不能为空",
  i18nKey_3162: "分项id不能为空",
  i18nKey_3163: "仪表位置",
  i18nKey_3164: "分项简称",
  i18nKey_3165: "保存失败,电表编号不能为空",
  i18nKey_3166: "请输入一级设备名称",
  i18nKey_3167: "请输入一级设备简称",
  i18nKey_3168: "下载导入模板",
  i18nKey_3169: "支路配置导入模板",
  i18nKey_3170: "商业用电",
  i18nKey_3171: "居民生活用电",
  i18nKey_3172: "农业用电",
  i18nKey_3173: "工业用电",
  i18nKey_3174: "市电10KV",
  i18nKey_3175: "拉取下级设备",
};
