export default {
  executionTime: "ความยาวรวมของการดำเนินการ",
  i18nKey_1: "วันที่แก้ไขล่าสุด",
  i18nKey_2: "รายการ การอ่านมิเตอร์แบบอัตโนมัติโดยรายละเอียด",
  i18nKey_3: "ระบบย่อย",
  i18nKey_4: "ประเภทสินทรัพย์",
  i18nKey_5: "สถานะ",
  i18nKey_6: "เวลาลงทะเบียน",
  i18nKey_7: "ตั้งค่าใหม่",
  i18nKey_8: "ปกติ",
  i18nKey_9: "ชื่อบัญชี / หมายเลขโทรศัพท์มือถือที่ได้รับ",
  i18nKey_10: "ชื่อบัญชี",
  i18nKey_11: "โหมดการแสดงผล",
  i18nKey_12: "ประเภทคลาวด์",
  i18nKey_13: "ชื่อสวนสาธารณะ",
  i18nKey_14: "รหัสสวนสาธารณะ",
  i18nKey_15: "ผ่าน",
  i18nKey_16: "ยกเลิกการลบ",
  i18nKey_17: "เปิดใช้งาน",
  i18nKey_18: "ปิดการใช้งาน",
  i18nKey_19: "ทำให้ละลาย",
  i18nKey_20: "แช่แข็ง",
  i18nKey_21:
    "บทบาทเป็นตัวเลือกที่ไม่ได้ โปรดเลือกประเภทการแจ้งเตือนการมาถึงของ VIP หรือประเภทการแจ้งเตือนการมาถึงที่ไม่ใช่วีไอพี",
  i18nKey_22: "เลือก",
  i18nKey_23: "S/N",
  i18nKey_24: "ไม่สามารถแก้ไขได้",
  i18nKey_25: "แก้ไขการกำหนดค่าระบบคลาวด์แบบไฮบริด",
  i18nKey_26: "แก้ไขเรียบร้อยแล้ว",
  i18nKey_27: "แก้ไข",
  i18nKey_28: "ประเภทสัญญาณ",
  i18nKey_29: "ฉลากสัญญาณ",
  i18nKey_30: "การกำหนดค่าระบบคลาวด์แบบไฮบริดใหม่",
  i18nKey_31: "ใหม่",
  i18nKey_32: "รายละเอียด",
  i18nKey_33: "ดาวน์โหลดเทมเพลต",
  i18nKey_34: "การแจ้งเรื่องทรัพย์สิน",
  i18nKey_35: "ไม่มีข้อมูลที่ตรงตามเงื่อนไขการสืบค้น",
  i18nKey_36: "รูปแบบไฟล์ไม่ถูกต้อง รองรับเฉพาะไฟล์ excel เท่านั้น",
  i18nKey_37: "ข้อความ",
  i18nKey_38: "ตำแหน่ง",
  i18nKey_39: "ไม่ผ่าน",
  i18nKey_40: "ไม่ได้เปิดใช้งาน",
  i18nKey_41: "เครือข่ายผิดปกติ โปรดลองอีกครั้งในภายหลัง",
  i18nKey_42: "ผลักดันเป้าหมาย",
  i18nKey_43: "กดประเภทการแจ้งเตือน",
  i18nKey_44: "กดบริการ APP",
  i18nKey_45: "บทความ",
  i18nKey_46: "เพิ่มเวลา",
  i18nKey_47: "เพิ่ม",
  i18nKey_48: "เคล็ดลับ",
  i18nKey_49: "การส่งล้มเหลว",
  i18nKey_50: "ส่งเรียบร้อยแล้ว",
  i18nKey_51: "ส่ง",
  i18nKey_52: "ค้นหา",
  i18nKey_53: "โซนอันตรายต้องประกอบด้วยตัวเลขและเลขคณิตสี่ตัวเท่านั้น",
  i18nKey_54: "คลาวด์ส่วนตัว",
  i18nKey_55:
    "หมายเหตุ: 'ตัวหลัก' ระดับแรกใช้ข้อมูลของ 'ตู้วัดแรงดันไฟฟ้าสูง' ส่วนระดับล่าง: ใช้ข้อมูลของ 'ตู้เต้าเสียบไฟฟ้าแรงสูง' ที่มีอัตราการสูญเสียพลังงานเกิน หากมากกว่า 5% ถือว่าผิดปกติ",
  i18nKey_56: "ข้อมูล",
  i18nKey_57: "การอนุญาต",
  i18nKey_58: "ชื่อบัญชีรวบรวม",
  i18nKey_59: "รูปแบบหมายเลขโทรศัพท์ไม่ถูกต้อง",
  i18nKey_60: "รวบรวมหมายเลขโทรศัพท์",
  i18nKey_61: "ที่เหลืออยู่",
  i18nKey_62: "หมวดย่อยสถานที่",
  i18nKey_63: "ระบบสถานที่",
  i18nKey_64: "ชื่อสถานที่ต้องไม่ว่างเปล่า",
  i18nKey_65: "ชื่อสถานที่",
  i18nKey_66: "การปรับเปลี่ยนการจัดประเภทสถานที่",
  i18nKey_67: "ชื่อหมวดสถานที่",
  i18nKey_68: "รหัสการจัดประเภทสถานที่",
  i18nKey_69: "ระดับสถานที่",
  i18nKey_70: "นามสมมติของสถานที่ต้องไม่ว่างเปล่า",
  i18nKey_71: "หมายเลขสถานที่",
  i18nKey_72: "รายละเอียดการจำแนกประเภทอุปกรณ์",
  i18nKey_73: "พ่อค้า",
  i18nKey_74: "ลบไม่สำเร็จ!",
  i18nKey_75: "ลบสำเร็จแล้ว",
  i18nKey_76: "ลบ",
  i18nKey_77: "แน่ใจไหมว่าต้องการยกเลิก",
  i18nKey_78: "ยืนยันการลบ?",
  i18nKey_79: "กำหนด",
  i18nKey_80: "ทั้งหมด",
  i18nKey_81: "ผู้มีอำนาจ",
  i18nKey_82: "ยกเลิก",
  i18nKey_83: "บัญชี Blockchain",
  i18nKey_84: "โปรดเลือกประเภทการเตือนอย่างน้อยหนึ่งประเภท",
  i18nKey_85: "กรุณาเลือกสถานะ",
  i18nKey_86: "โปรดเลือกช่วงเวลาการลงทะเบียน",
  i18nKey_87: "กรุณาเลือกสวนสาธารณะ",
  i18nKey_88: "กรุณาเลือกระบบการสมัคร",
  i18nKey_89: "โปรดเลือกประเภทสถานที่ที่จะแก้ไข",
  i18nKey_90: "โปรดเลือกประเภทสัญญาณ",
  i18nKey_91: "โปรดเลือกสถานที่",
  i18nKey_92: "โปรดเลือกเป้าหมายการผลักดัน",
  i18nKey_93: "โปรดเลือกระบบสถานที่",
  i18nKey_94: "โปรดเลือกหมวดหมู่สถานที่",
  i18nKey_95: "โปรดเลือกการอนุญาต",
  i18nKey_96: "กรุณาเลือกประเภท",
  i18nKey_97: "โปรดเลือกประเภทหัวข้อ",
  i18nKey_98: "โปรดเลือกประเภทการเตือนภัย",
  i18nKey_99: "โปรดเลือกประเภท",
  i18nKey_100: "โปรดเลือกช่วงเวลาเผยแพร่",
  i18nKey_101: "กรุณากรอกชื่อบัญชีของคุณ / หมายเลขโทรศัพท์ที่รับ",
  i18nKey_102: "กรุณากรอกชื่อสวนสาธารณะ",
  i18nKey_103: "กรุณากรอกหมายเลขโทรศัพท์ของคุณ เพื่อสะดวกในการค้นหา",
  i18nKey_104: "กรุณากรอกชื่อบัญชีเครดิต",
  i18nKey_105: "กรุณากรอกหมายเลขโทรศัพท์มือถือของคุณ",
  i18nKey_106: "กรุณากรอกชื่อสถานที่",
  i18nKey_107: "กรุณากรอกหมายเลขสถานที่",
  i18nKey_108: "กรุณากรอกชื่อผู้ติดต่อ",
  i18nKey_109: "กรุณากรอกหมายเลขโทรศัพท์ติดต่อ",
  i18nKey_110: "กรุณากรอกผู้ติดต่อ / หมายเลขโทรศัพท์",
  i18nKey_111: "กรุณาใส่แต้มลงในกระเป๋าเงิน",
  i18nKey_112: "กรุณากรอกชื่อบริษัท / ที่อยู่",
  i18nKey_113: "กรุณากรอกชื่อบริษัท",
  i18nKey_114: "โปรดกรอกที่อยู่บริษัท",
  i18nKey_115: "กรุณากรอกรหัสผ่านเข้าสู่ระบบบัญชีปัจจุบัน",
  i18nKey_116: "กรุณาใส่หมายเหตุ",
  i18nKey_117: "กรุณาคลิกตกลงดาวน์โหลดไฟล์แก้ไขและนำเข้าอีกครั้ง",
  i18nKey_118: "กรุณากรอกข้อมูลตามความจำเป็น",
  i18nKey_119: "เปิดใช้งานโลโก้",
  i18nKey_120: "เปิด/ปิด",
  i18nKey_121: "เปิดใช้งาน",
  i18nKey_122: "ประเภทเป้าหมาย",
  i18nKey_123: "ชื่อผู้ติดต่อ",
  i18nKey_124: "รูปแบบหมายเลขโทรศัพท์ใช้สำหรับติดต่อไม่ถูกต้อง",
  i18nKey_125: "หมายเลขโทรศัพท์ติดต่อ",
  i18nKey_126: "เบอร์ติดต่อ / มือถือ",
  i18nKey_127: "ชนิดของ",
  i18nKey_128: "บัตร",
  i18nKey_129: "ไม่สามารถกรอกลองจิจูดและละติจูดได้",
  i18nKey_130: "ลดถอยลง",
  i18nKey_131: "ประเภทบทบาท",
  i18nKey_132: "ตัวละคร",
  i18nKey_133: "แต้มกระเป๋าเงิน",
  i18nKey_134: "ประเภทคะแนน",
  i18nKey_135: "รางวัลคะแนน",
  i18nKey_136: "มูลค่าคะแนน (หยวน / นาที)",
  i18nKey_137: "คลาวด์สาธารณะ",
  i18nKey_138: "ข้อมูล บริษัท",
  i18nKey_139: "ชื่อ บริษัท / ที่อยู่",
  i18nKey_140: "ชื่อ บริษัท",
  i18nKey_141: "ที่อยู่ บริษัท",
  i18nKey_142: "ส่วนตัว",
  i18nKey_143: "ชื่อปลุกต้องประกอบด้วยตัวเลขและภาษาจีนเท่านั้น",
  i18nKey_144: "ชื่อการเตือนต้องไม่ว่างเปล่า",
  i18nKey_145: "คำอธิบายการเตือนต้องประกอบด้วยตัวอักษรตัวเลขและภาษาจีนเท่านั้น",
  i18nKey_146: "คำอธิบายการเตือนต้องไม่ว่างเปล่า",
  i18nKey_147: "ประเภทการเตือนภัย",
  i18nKey_148: "ป้ายเตือนต้องประกอบด้วยตัวอักษรและตัวเลขเท่านั้น",
  i18nKey_149: "ป้ายกำกับการเตือนต้องไม่ว่างเปล่า",
  i18nKey_150: "ขนาดการระบุรายการ",
  i18nKey_151: "ดัชนีการวิเคราะห์",
  i18nKey_152: "ชื่อเวที",
  i18nKey_153: "ปริมาณที่ส่งออกทั้งหมด",
  i18nKey_154: "มูลค่ารวมของการส่งออก (หยวน)",
  i18nKey_155: "เผยแพร่เวลา",
  i18nKey_156: "ผู้ส่งออก",
  i18nKey_157: "บันทึกการเปิดตัว",
  i18nKey_158: "แช่แข็ง",
  i18nKey_159: "การนำเข้าล้มเหลวรองรับเฉพาะไฟล์ excel เวอร์ชันใหม่เท่านั้น",
  i18nKey_160: "การนำเข้าล้มเหลว",
  i18nKey_161: "นำเข้าสำเร็จ",
  i18nKey_162: "นำเข้า",
  i18nKey_163: "การส่งออกล้มเหลว รองรับข้อมูลการส่งออกสูงสุด 10,000",
  i18nKey_164: "ส่งออก",
  i18nKey_165: "รอดำเนินการ",
  i18nKey_166: "ข้อผิดพลาด",
  i18nKey_167: "การดำเนินการนี้จะลบไฟล์อย่างถาวร คุณต้องการดำเนินการต่อหรือไม่",
  i18nKey_168: "การสร้างล้มเหลว",
  i18nKey_169: "สร้างการจัดประเภทสถานที่",
  i18nKey_170: "สร้างสำเร็จแล้ว",
  i18nKey_171: "นำเข้าสำเร็จ",
  i18nKey_172: "สอบถาม",
  i18nKey_173: "ปฏิบัติการ",
  i18nKey_174: "แก้ไข",
  i18nKey_175: "การกำหนดหมายเลข",
  i18nKey_176: "หมายเหตุ",
  i18nKey_177: "บันทึกสำเร็จแล้ว",
  i18nKey_178: "บันทึก",
  i18nKey_179: "การกำหนดค่า VCN",
  i18nKey_180: "การกำหนดค่า VCM",
  i18nKey_181: "L สามารถประกอบด้วยตัวเลขและสัญลักษณ์ทางคณิตสี่ตัวเท่านั้น",
  i18nKey_182: "L ต้องไม่ว่างเปล่า",
  i18nKey_183: "LL สามารถประกอบด้วยตัวเลขและสัญลักษณ์ทางคณิตสี่ตัวเท่านั้น",
  i18nKey_184: "H สามารถประกอบด้วยตัวเลขและสัญลักษณ์ทางคณิตสี่ตัวเท่านั้น",
  i18nKey_185: "H ต้องไม่ว่างเปล่า",
  i18nKey_186: "HH สามารถประกอบด้วยตัวเลขและสัญลักษณ์ทางคณิตสี่ตัวเท่านั้น",
  i18nKey_187: "บันทึกเมื่อวานนี้",
  i18nKey_188: "ถูกจำหน่ายไปทั้งหมดเมื่อวานนี้",
  i18nKey_189: "ยอดเรียกเก็บของเมื่อวาน",
  i18nKey_190: "เมื่อวานนี้",
  i18nKey_191: "พลังงานที่ใช้ไปเมื่อวานนี้",
  i18nKey_192: "การใช้พลังงานต่อตารางเมตรในส่วนของเมื่อวาน",
  i18nKey_193: "ลูกค้าคนเดิมเหมือนเมื่อวาน",
  i18nKey_194: "เมื่อวานนี้",
  i18nKey_195: "เวลาเข้าชมเร็วที่สุด",
  i18nKey_196: "เวลาดำเนินการล่าสุด",
  i18nKey_197: "สถานการณ์การดำเนินการล่าสุด",
  i18nKey_198: "ขั้นต่ำ",
  i18nKey_199: "เวลาออกจากสวนสาธารณะล่าสุด",
  i18nKey_200: "ออกจากสวนสาธารณะที่ล่าสุด",
  i18nKey_201: "ค่าเช่าขั้นต่ำ (ชั่วโมง)",
  i18nKey_202: "ค่าเช่าขั้นต่ำ",
  i18nKey_203: "อาทิตย์ที่แล้ว",
  i18nKey_204: "เดือนที่แล้ว",
  i18nKey_205: "เวลาจอดรถล่าสุด (ชั่วโมง)",
  i18nKey_206: "ช่วงเวลาเปิดล่าสุด",
  i18nKey_207: "เวลาชำระเงินล่าสุด",
  i18nKey_208: "เวลาหยุดล่าสุด",
  i18nKey_209: "ในช่วงสามเดือนล่าสุด",
  i18nKey_210: "วันที่ปรับปรุงข้อมูลล่าสุด: เดือนวันเดือน",
  i18nKey_211: "ครั้งล่าสุด",
  i18nKey_212: "การเยี่ยมชมครั้งล่าสุด",
  i18nKey_213: "ไม่มีบันทึกการหยุดสำหรับพื้นที่จอดรถในช่วง 30 วันที่ผ่านมา",
  i18nKey_214: "เวลาอัปเดตล่าสุด",
  i18nKey_215: "เวลาสื่อสารล่าสุด",
  i18nKey_216: "เวลาอัปโหลดล่าสุด",
  i18nKey_217: "เวลาอัปเดตล่าสุด",
  i18nKey_218: "สามารถเลือกกล้องได้สูงสุด 4 ตัวเท่านั้น",
  i18nKey_219: "คุณสามารถเลือกได้ 5 เท่านั้น",
  i18nKey_220: "คุณสามารถเพิ่มข้อความได้สูงสุด 5 ข้อความ",
  i18nKey_221: "อัปโหลดได้สูงสุด 4 ภาพ",
  i18nKey_222: "อัพโหลดได้ 1 ภาพเท่านั้น!",
  i18nKey_223: "เพิ่มผู้อนุมัติได้สูงสุด 5 คน",
  i18nKey_224: "มากกว่าห้าคน",
  i18nKey_225: "สามารถสร้างได้สูงสุด 4 กลยุทธ์",
  i18nKey_226: "เชื่อมต่อกล้องได้สูงสุด 3 ตัว",
  i18nKey_227: "มากถึง 30 คำ",
  i18nKey_228: "สูงสุด",
  i18nKey_229:
    "จำนวนการเตือนสูงสุดต้องไม่น้อยกว่าหรือเท่ากับจำนวนการเตือนขั้นต่ำ",
  i18nKey_230: "รายละเอียดการเช่า",
  i18nKey_231: "ระบุการเช่าวันที่เริ่มต้นจนถึงสิ้นสุด",
  i18nKey_232: "วันที่เริ่มต้นการเช่า",
  i18nKey_233: "วันที่สิ้นสุดสัญญาเช่า",
  i18nKey_234: "ปริมาณการใช้น้ำของผู้เช่า",
  i18nKey_235: "การใช้พลังงานของผู้เช่า",
  i18nKey_236: "ผู้เช่า",
  i18nKey_237: "ไฟฟ้าทั้งหมด",
  i18nKey_238: "การใช้พลังงานน้ำทั้งหมด (t)",
  i18nKey_239: "ผลรวม",
  i18nKey_240: "จำนวนเหตุการณ์ทั้งหมด",
  i18nKey_241: "สัดส่วนการใช้พลังงานทั้งหมด",
  i18nKey_242: "เป้าหมายในการใช้พลังงานทั้งหมด",
  i18nKey_243: "การใช้พลังงานทั้งหมด (เดือนที่แล้ว)",
  i18nKey_244: "การใช้พลังงานทั้งหมด (ปีนี้)",
  i18nKey_245: "การใช้พลังงานทั้งหมด",
  i18nKey_246: "สวิตช์แอมแปร์ทั้งหมด",
  i18nKey_247: "สวิตช์หลัก:",
  i18nKey_248: "เงินออมทั้งหมด",
  i18nKey_249: "มูลค่ารวม (หยวน)",
  i18nKey_250: "ปริมาณรวม",
  i18nKey_251: "รวม",
  i18nKey_252: "ตารางค่าธรรมเนียมและการชำระทั้งหมด",
  i18nKey_253: "ตารางรวม ",
  i18nKey_254: "สีน้ำตาล",
  i18nKey_255: "การหลั่งไหลของผู้โดยสารที่กินวงกว้าง",
  i18nKey_256: "จำนวนอักษรต้องไม่เกิน 10 ตัว!",
  i18nKey_257: "สร้างขึ้นเอง",
  i18nKey_258: "การดำเนินการด้านกลยุทธ์โดยอัตโนมัติ",
  i18nKey_259: "บันทึกซ้ำโดยอัตโนมัติ",
  i18nKey_260: "คุณสามารถเพิ่มช่วงเวลาที่กำหนดเองได้สูงสุด 3 ช่วง",
  i18nKey_261: "คุณสามารถเพิ่มช่วงจำนวนที่กำหนดเองได้สูงสุด 3 ช่วง",
  i18nKey_262: "คุณสามารถเพิ่มเวลาที่กำหนดเองได้สูงสุด 3 ครั้งเท่านั้น",
  i18nKey_263: "ปรับแต่ง",
  i18nKey_264: "สีม่วง",
  i18nKey_265: "การใช้พลังงานในพื้นที่ย่อย",
  i18nKey_266: "การใช้พลังงานของรายการย่อย",
  i18nKey_267: "ชื่อระดับย่อย",
  i18nKey_268: "มูลค่าทรัพย์สินเดิมทั้งหมด (หยวน)",
  i18nKey_269: "สถิติทางสินทรัพย์ทั้งหมด (โดยสวนสาธารณะ)",
  i18nKey_270: "สถิติทางสินทรัพย์รวม (ตามประเภท)",
  i18nKey_271: "สินทรัพย์รวม",
  i18nKey_272: "สถิติมูลค่าเดิมของสินทรัพย์ (โดยสวนสาธารณะ)",
  i18nKey_273: "สถิติมูลค่าเดิมของสินทรัพย์ (ตามประเภท)",
  i18nKey_274: "มูลค่าเดิมของสินทรัพย์ (หยวน)",
  i18nKey_275: "มูลค่าเดิมของสินทรัพย์ (USD)",
  i18nKey_276: "รายละเอียดสินทรัพย์",
  i18nKey_277: "ข้อมูลตำแหน่งของสินทรัพย์",
  i18nKey_278: "สถิติสินทรัพย์",
  i18nKey_279: "คำอธิบายเนื้อหา",
  i18nKey_280: "ประเภทสินทรัพย์",
  i18nKey_281: "ข้อมูลพื้นฐานของสินทรัพย์",
  i18nKey_282: "วิถีทางของสินทรัพย์",
  i18nKey_283: "หมายเลขสินทรัพย์",
  i18nKey_284: "ข้อมูล RFID ของสินทรัพย์",
  i18nKey_285: "สายด่วนสนับสนุน",
  i18nKey_286: "กำลังติดตาม",
  i18nKey_287: "งานติดตาม",
  i18nKey_288: "ติดตาม",
  i18nKey_289: "สถานะต้องไม่ว่างเปล่า",
  i18nKey_290: "สถานะ:",
  i18nKey_291: "สถานะ",
  i18nKey_292: "คำเตือนการถ่ายโอนสถานที่ล้มเหลว",
  i18nKey_293: "คำเตือนการถ่ายโอนสถานที่สำเร็จ",
  i18nKey_294: "ถ่ายโอนเพื่อคืนเงิน",
  i18nKey_295: "สถานการณ์การแปลง",
  i18nKey_296: "อัตราการแปลง",
  i18nKey_297: "การส่งต่อล้มเหลว",
  i18nKey_298: "ส่งต่อสำเร็จ",
  i18nKey_299: "ถ่ายภาพ",
  i18nKey_300: "กดกล้องถ่ายรูป",
  i18nKey_301: "กราฟแสดงค่าของสถิติความถี่",
  i18nKey_302:
    "หมายเหตุ: ปริมาณการใช้ไฟฟ้าจริงในวันใดวันหนึ่งเทียบกับปริมาณการใช้ไฟฟ้าเฉลี่ยของสัปดาห์ที่แล้วมีการเพิ่มขึ้นหรือลดลงจนถึงเกณฑ์ที่ตั้งไว้ซึ่งหมายถึงการใช้ไฟฟ้าที่ผิดปกติ",
  i18nKey_303: "เวลาลงทะเบียน",
  i18nKey_304: "การลงทะเบียนไม่สำเร็จ",
  i18nKey_305:
    "หมายเหตุ: แน่ใจไหมว่าต้องการลบ หารการดำเนินการแล้วจะไม่สามรถเรียกคืนได้",
  i18nKey_306:
    "หมายเหตุ: แน่ใจไหมว่าต้องการลบ การดำเนินการนี้จะไม่สามารถกู้คืนได้!",
  i18nKey_307:
    "หมายเหตุ: ขอแนะนำให้ป้อนค่าเป้าหมายของการใช้พลังงานทั้งหมดและการใช้พลังงานในพื้นที่สาธารณะ ระบบจึงจะสามารถคำนวณค่าอื่น ๆ โดยอัตโนมัติได้หลังจากสร้างค่าเป้าหมายรายเดือนแล้ว สามารถแก้ไขได้โดยเลื่อนเมาส์",
  i18nKey_308: "ห้องนอนใหญ่",
  i18nKey_309: "ธีม",
  i18nKey_310: "ชั้นหลัก",
  i18nKey_311: "วันจันทร์",
  i18nKey_312: "วันศุกร์",
  i18nKey_313: "รายสัปดาห์",
  i18nKey_314: "วันพฤหัสบดี",
  i18nKey_315: "วันพุธ",
  i18nKey_316: "วันอาทิตย์",
  i18nKey_317: "การหลั่งไหลของผู้คนในช่วงสุดสัปดาห์",
  i18nKey_318: "วันเสาร์",
  i18nKey_319: "วันอังคาร",
  i18nKey_320: "สัปดาห์",
  i18nKey_321: "คำเตือนที่สำคัญ",
  i18nKey_322: "ระดับความสำคัญ",
  i18nKey_323: "การมอบหมายใหม่",
  i18nKey_324: "ลงทะเบียนใหม่",
  i18nKey_325: "ทำให้ใช้งานได้อีกครั้ง",
  i18nKey_326: "ประเภทการทำซ้ำ",
  i18nKey_327: "ทำวิธีการซ้ำ",
  i18nKey_328: "มลภาวะรุนแรง",
  i18nKey_329: "พื้นที่สำคัญ",
  i18nKey_330: "เหตุการณ์สำคัญ",
  i18nKey_331: "รายใหญ่",
  i18nKey_332: "สายพันธุ์",
  i18nKey_333: "ปัจจัย",
  i18nKey_334: "จำนวนผู้เข้าสำเร็จ",
  i18nKey_335: "จุดสิ้นสุดต้องไม่เหมือนกับจุดเริ่มต้น",
  i18nKey_336: "จุดสิ้นสุดต้องไม่ว่างเปล่า",
  i18nKey_337: "จบ",
  i18nKey_338: "Zhongliang",
  i18nKey_339: "ประเทศจีน",
  i18nKey_340: "มลพิษปานกลาง",
  i18nKey_341: "หมายเลขมาตรวัดน้ำอัจฉริยะ",
  i18nKey_342: "ระบบอัจฉริยะ",
  i18nKey_343: "หมายเลขมิเตอร์อัจฉริยะ",
  i18nKey_344: "รหัสตารางอัจฉริยะต้องไม่ว่างเปล่า",
  i18nKey_345: "การเข้ารหัสตารางอัจฉริยะ",
  i18nKey_346: "ผู้ผลิตต้องกรอกชื่อด้วยภาษาจีน ภาษาอังกฤษ ตัวเลขและ _ เท่านั้น",
  i18nKey_347: "ความยาวของชื่อผู้ผลิตต้องไม่เกิน 50 อักษร",
  i18nKey_348: "ผู้ผลิต",
  i18nKey_349: "เลือกอุปกรณ์อย่างน้อยหนึ่งเครื่องเป็นตัวดำเนินการ",
  i18nKey_350: "ตรวจสอบอย่างน้อยหนึ่งช่วงเวลา",
  i18nKey_351: "ระบุวันที่",
  i18nKey_352: "รอบการออกคำสั่ง",
  i18nKey_353: "ระบุจำนวนวัน",
  i18nKey_354: "เวลาที่กำหนด",
  i18nKey_355: "บริษัทที่กำหนด",
  i18nKey_356: "เวลาที่กำหนด",
  i18nKey_357: "ชื่อตัวบ่งชี้",
  i18nKey_358: "ตัวบ่งชี้ในการวิเคราะห์",
  i18nKey_359: "มีการป้อนอักษรพิเศษในข้อมูลตำแหน่ง",
  i18nKey_360: "ข้อมูลงาน",
  i18nKey_361: "เวลาดำเนินการทั้งหมด (นาที)",
  i18nKey_362: "เวลาดำเนินการ",
  i18nKey_363: "ดำเนินการ:",
  i18nKey_364: "การนำไปใช้งาน (ครั้ง)",
  i18nKey_365: "ดำเนินการ",
  i18nKey_366: "รองรับการป้อนข้อมูลกรณีภาษาจีนและอังกฤษเท่านั้น",
  i18nKey_367: "รองรับเฉพาะกรณีภาษาจีนและภาษาอังกฤษ",
  i18nKey_368:
    "รองรับการใส่ช่องว่าง เครื่องหมายวรรคตอน การป้อนตัวเลข ภาษาจีนและภาษาอังกฤษเท่านั้น",
  i18nKey_369: "ฝืนจะไม่สามารถผ่าออกได้ หากไม่ได้ขวานที่เหมาะสม",
  i18nKey_370:
    "ป้อนได้เฉพาะตัวเลขบวกและค่าสูงสุดคือ 10 หลักและทศนิยม 2 ตำแหน่ง",
  i18nKey_371: "อัปโหลดรูปภาพในรูปแบบ jpg / png เท่านั้น",
  i18nKey_372: "สามารถอัพโหลดไฟล์รูปภาพ jpg, jpeg, png ได้เท่านั้น!",
  i18nKey_373: "สามารถอัปโหลดได้เฉพาะไฟล์ excel และไฟล์ที่ต่อท้ายคือ. xlsx",
  i18nKey_374: "สถิติการใช้พลังงานในแต่ละสาขา",
  i18nKey_375: "การวิเคราะห์การใช้พลังงานของสาขา",
  i18nKey_376: "การเลือกสาขา",
  i18nKey_377: "แผนภาพการไหลของวงจรสาขาในการใช้พลังงาน _",
  i18nKey_378: "จำนวนการเตือนการใช้พลังงานของสาขา (ครั้ง)",
  i18nKey_379: "เวลาเสร็จสิ้นการชำระเงิน",
  i18nKey_380: "ช่องทางการชำระเงิน",
  i18nKey_381: "เวลาเริ่มต้นการชำระเงิน",
  i18nKey_382: "จำนวนเงินที่ชำระ (หยวน)",
  i18nKey_383: "วิธีการชำระเงิน",
  i18nKey_384: "หมายเลขการชำระเงิน",
  i18nKey_385: "Alipay",
  i18nKey_386: "รายจ่าย / รายได้",
  i18nKey_387: "โปรดรอสักครู่กำลังโหลดส่วนประกอบวิดีโอ",
  i18nKey_388: "โปรดรอสักครู่ กำลังส่งออก",
  i18nKey_389: "ปกติ",
  i18nKey_390: "มาตรการวินิจฉัย (สายพันธุ์)",
  i18nKey_391: "มาตรการวินิจฉัย",
  i18nKey_392: "การวินิจฉัย",
  i18nKey_393: "แผนภูมิเส้น",
  i18nKey_394:
    "อัตราส่วนลดต้องอยู่ระหว่าง 0-1 ถึง 1-2 ของทศนิยมถึงสามารถจองได้",
  i18nKey_395: "ปัจจัยด้านส่วนลด",
  i18nKey_396: "การตั้งค่าส่วนลด",
  i18nKey_397: "คำอธิบายส่วนลด",
  i18nKey_398: "ส่วนลด",
  i18nKey_399: "รายละเอียดราคาลด",
  i18nKey_400: "ราคาหลังหักส่วนลด",
  i18nKey_401: "เวลาในการสร้างภาพ",
  i18nKey_402: "คำอธิบายประเภทรูปภาพ",
  i18nKey_403: "ขนาดรูปภาพ",
  i18nKey_404: "รูปภาพต้องไม่ว่างเปล่าโปรดอัปโหลดรูปภาพ",
  i18nKey_405: "URL ของรูปภาพ",
  i18nKey_406: "โหมดแสงสว่าง",
  i18nKey_407: "ไฟฟ้าสำหรับแสงสว่างและซ็อกเก็ต",
  i18nKey_408: "ออกแผนแสงสว่าง",
  i18nKey_409: "การจัดการโซลูชันแสงสว่าง",
  i18nKey_410: "เข้าสู้ระบบบัญชี",
  i18nKey_411: "หมายเลขบัญชี",
  i18nKey_412: "ปริมาณการใช้ไฟฟ้าทั้งหมดของบิลคือ $ kWh",
  i18nKey_413: "สถิติการใช้บิลตั้งแต่ต้นจนถึงวันนี้",
  i18nKey_414: "ความยาว 15 หรือ 18 อักษร",
  i18nKey_415: "การ์ด",
  i18nKey_416: "ครอบครองพื้นที่จอดรถ",
  i18nKey_417: "แฉ",
  i18nKey_418: "ความหนืด",
  i18nKey_419: "รายละเอียดบริการเสริม",
  i18nKey_420: "ผู้จัดการที่รับผิดชอบ",
  i18nKey_421: "รถไฟตอนต้น",
  i18nKey_422: "ไม่มีแผนที่อุทยาน",
  i18nKey_423: "ไม่มีบุคลากรติดตาม",
  i18nKey_424: "ไม่มีข้อมูลที่จะส่งออก",
  i18nKey_425: "เวลาระงับ",
  i18nKey_426: "หมดเวลา",
  i18nKey_427: "ไม่ได้เปิดใช้งาน",
  i18nKey_428: "เกตเวย์ออนไลน์",
  i18nKey_429: "รหัสผ่านออนไลน์",
  i18nKey_430: "เช็คอินออนไลน์",
  i18nKey_431: "ออนไลน์ (ก)",
  i18nKey_432: "ออนไลน์",
  i18nKey_433: "กำลังปฏิบัติหน้าที่",
  i18nKey_434:
    "หมายเลขโทรศัพท์มือถือของผู้ให้บริการต้องลงทะเบียนในแอป Yuncheng",
  i18nKey_435: "ข้อมูลการดำเนินงานและการบำรุงรักษา",
  i18nKey_436: "วิ่ง",
  i18nKey_437: "กลยุทธ์การดำเนินงาน",
  i18nKey_438: "พารามิเตอร์การทำงาน",
  i18nKey_439: "ระยะเวลาการเยี่ยมชมที่อนุญาต",
  i18nKey_440: "ระยะเวลาการเยี่ยมชมที่อนุญาต",
  i18nKey_441: "ประเภทคลาวด์",
  i18nKey_442: "การชำระเงินของ Yuncheng",
  i18nKey_443: "ข่าวแอพ Yuncheng",
  i18nKey_444: "แอพ Shuzhili",
  i18nKey_445: "ผ่านไปหลายพันไมล์และเอาชนะความยากลำบากนับพัน",
  i18nKey_446: "พยากรณ์รายเดือน",
  i18nKey_447: "ปริมาณการบริโภคต่อเดือน (หยวน)",
  i18nKey_448: "ปริมาณการใช้ไฟฟ้าจริงต่อเดือน (กิโลวัตต์ชั่วโมง)",
  i18nKey_449: "จำนวนเงินที่เหลือต่อเดือน (หยวน)",
  i18nKey_450: "วงเงินบัตรรายเดือน",
  i18nKey_451: "ผู้ตรวจสอบบัตรรายเดือน",
  i18nKey_452: "วันหมดอายุของบัตรรายเดือน",
  i18nKey_453: "รถบรรทุกดวงจันทร์",
  i18nKey_454: "บัตรรายเดือน",
  i18nKey_455: "ปริมาณการใช้น้ำรายเดือน (ตัน)",
  i18nKey_456: "การใช้พลังงานรายเดือน (กิโลวัตต์ชั่วโมง)",
  i18nKey_457: "จำนวนเงินที่เติมรายเดือน (หยวน)",
  i18nKey_458: "เดือน",
  i18nKey_459: "เวลาเปิดรีโมท",
  i18nKey_460: "ประตูรีโมทเปิดสำเร็จ",
  i18nKey_461: "เปิดประตูจากระยะไกล",
  i18nKey_462: "การป้อนรหัสบุคลากรของระบบต้นทางมีอักษรพิเศษ",
  i18nKey_463: "ชื่อระบบต้นทางมีอักษรพิเศษ",
  i18nKey_464: "อินพุตการเข้ารหัสข้อมูลต้นทางมีอักษรพิเศษ",
  i18nKey_465: "การเข้ารหัสข้อมูลต้นทาง",
  i18nKey_466: "เจ้าหน้าที่",
  i18nKey_467: "ตารางเมตรทั้งหมดของสวนสาธารณะต้องมากกว่า 0",
  i18nKey_468: "ตารางเมตรรวมของสวนสาธารณะ",
  i18nKey_469: "ปาร์คเป็นเจ้าของ",
  i18nKey_470: "ไม่มีข้อมูลความร้อนของบุคลากรในสวนสาธารณะ",
  i18nKey_471: "ไม่มีข้อมูลอุปกรณ์ที่เกี่ยวข้องในสวนสาธารณะ",
  i18nKey_472: "ไม่มีข้อมูลพนักงานทำความสะอาดในสวนสาธารณะ",
  i18nKey_473: "ไม่มีข้อมูลเจ้าหน้าที่รักษาความปลอดภัยในสวนสาธารณะ",
  i18nKey_474: "วิสาหกิจในสวนสาธารณะ",
  i18nKey_475: "บัญชีดำของอุทยาน",
  i18nKey_476: "ผู้เยี่ยมชมสวน",
  i18nKey_477: "คำสั่งจอด",
  i18nKey_478: "พนักงานองค์กรในสวนสาธารณะ",
  i18nKey_479: "อันดับสีของรถจอด",
  i18nKey_480: "การจัดอันดับแบรนด์รถจอด",
  i18nKey_481: "รหัสสวนสาธารณะ",
  i18nKey_482: "การทำความสะอาดสวนสาธารณะ",
  i18nKey_483: "รถบัสจอด",
  i18nKey_484: "หยวน",
  i18nKey_485: "ประเภทเกณฑ์",
  i18nKey_486: "เกณฑ์ไม่ถูกต้อง! โปรดตรวจสอบและยืนยัน",
  i18nKey_487: "เกณฑ์",
  i18nKey_488: "บัญชีโดเมน",
  i18nKey_489: "สถานะการจอง",
  i18nKey_490: "ดูตัวอย่าง",
  i18nKey_491: "ค่าคำเตือนต่ำสุดคือ 0 และค่าสูงสุดคือจำนวนเต็ม 999",
  i18nKey_492: "สถานะค่าคำเตือน:",
  i18nKey_493: "คาดว่าจะออก",
  i18nKey_494: "เวลาออกเดินทางโดยประมาณ",
  i18nKey_495: "เวลาเยี่ยมชมโดยประมาณ",
  i18nKey_496: "จ่ายล่วงหน้า",
  i18nKey_497: "หมดเขตจอง",
  i18nKey_498: "วันที่จอง",
  i18nKey_499: "บุ๊คเกอร์",
  i18nKey_500: "การจอง",
  i18nKey_501: "ค่าทำนาย",
  i18nKey_502: "ค่าการใช้พลังงานที่คาดการณ์ไว้ (กิโลวัตต์ชั่วโมง)",
  i18nKey_503: "ตัวบ่งชี้ความถูกต้อง",
  i18nKey_504: "มีประสิทธิภาพ",
  i18nKey_505: "กลับสู่พื้นที่สำคัญ:",
  i18nKey_506: "แพ็คเกจพิเศษ:",
  i18nKey_507: "องค์กรพิเศษ",
  i18nKey_508: "กลยุทธ์พิเศษ",
  i18nKey_509: "คำแนะนำในการเพิ่มประสิทธิภาพ",
  i18nKey_510: "ยอดเยี่ยม",
  i18nKey_511: "สถิติการใช้น้ำ (t)",
  i18nKey_512: "สถิติปริมาณการใช้น้ำเป็นหน่วยลูกบาศก์เมตร(m³)",
  i18nKey_513: "ประเภทน้ำ",
  i18nKey_514: "เวลา (นาที)",
  i18nKey_515: "แนวโน้มการใช้พลังงาน",
  i18nKey_516: "การใช้พลังงาน (กิโลวัตต์/ชั่วโมง)",
  i18nKey_517: "ใช้พลังงาน",
  i18nKey_518: "ปริมาณ",
  i18nKey_519: "การยืนยันข้อมูลผู้ใช้",
  i18nKey_520: "ผู้ใช้ / กลุ่ม",
  i18nKey_521: "การตั้งค่าคำเตือนไฟฟ้า",
  i18nKey_522: "เปอร์เซ็นต์ไฟฟ้าผิดปกติ",
  i18nKey_523: "การใช้พลังงานผิดปกติ",
  i18nKey_524: "สถิติการใช้ไฟฟ้า",
  i18nKey_525: "สถิติการใช้พลังงาน (กิโลวัตต์-ชั่วโมง)",
  i18nKey_526: "สถิติการใช้พลังงาน (กิโลวัตต์-ชั่วโมง)",
  i18nKey_527: "อันดับไฟฟ้า",
  i18nKey_528: "การใช้พลังงาน (กิโลวัตต์-ชั่วโมง)",
  i18nKey_529: "ประเภทไฟฟ้า",
  i18nKey_530: "รายละเอียดไฟฟ้า",
  i18nKey_531: "โหลดไฟฟ้า",
  i18nKey_532: "ไฟฟ้าเสีย",
  i18nKey_533: "ไฟฟ้า",
  i18nKey_534: "รหัสของระบบแอปพลิเคชัน",
  i18nKey_535: "หมายเลขระบบแอปพลิเคชันต้องไม่ว่างเปล่า",
  i18nKey_536: "ระบบปฏิบัติการ",
  i18nKey_537: "ความสำเร็จที่มุ่งเน้นผู้ใช้",
  i18nKey_538: "มุ่งเน้นผู้ใช้",
  i18nKey_539: "สถานการณ์การใช้งานที่ประสบความสำเร็จ",
  i18nKey_540: "สถานการณ์การใช้งาน",
  i18nKey_541: "ใบอนุญาตประกอบธุรกิจ",
  i18nKey_542: "เวลาทำการต้องไม่น้อยกว่าหนึ่งชั่วโมง!",
  i18nKey_543: "เวลาทำการ",
  i18nKey_544: "เวลาเริ่มต้นธุรกิจ",
  i18nKey_545: "เวลาสิ้นสุดของธุรกิจ",
  i18nKey_546: "รายได้",
  i18nKey_547: "ชื่อภาษาอังกฤษ",
  i18nKey_548: "นโยบายความเป็นส่วนตัว",
  i18nKey_549: "เงิน",
  i18nKey_550: "การชำระเงิน UnionPay",
  i18nKey_551: "การควบคุมระดับเสียง:",
  i18nKey_552:
    "เนื่องจากความเป็นส่วนตัวของลูกค้ามีส่วนเกี่ยวข้องจึงจำเป็นต้องมีการยืนยันตัวตน",
  i18nKey_553: "บันทึกรายงานการแพร่ระบาด",
  i18nKey_554: "บันทึกการป้องกันและควบคุมการแพร่ระบาด",
  i18nKey_555: "จำนวนข้อยกเว้นทั้งหมด (A)",
  i18nKey_556: "ผิดปกติ",
  i18nKey_557: "ค้นหาผู้คนด้วยรูปภาพ",
  i18nKey_558: "จ่าย",
  i18nKey_559: "มีบันทึกอยู่แล้วไม่ว่าจะเขียนทับ",
  i18nKey_560: "เรียบร้อยแล้ว",
  i18nKey_561: "เชิญ",
  i18nKey_562: "สาขาที่เลือก",
  i18nKey_563: "เลือกอุปกรณ์แล้ว",
  i18nKey_564: "ไฟล์ที่เลือกสำหรับการอัปโหลด:",
  i18nKey_565: "กล้องที่เลือก",
  i18nKey_566: "การเลือกธุรกิจ / ผู้เช่าที่เลือก",
  i18nKey_567: "อาคารที่เลือก",
  i18nKey_568: "เลือกแล้ว",
  i18nKey_569: "เสร็จสมบูรณ์",
  i18nKey_570: "คืนเงินเรียบร้อยแล้ว",
  i18nKey_571: "หยุด",
  i18nKey_572: "เพิ่มเส้นทาง",
  i18nKey_573: "เพิ่มตารางนี้!",
  i18nKey_574: "ส่งแล้ว",
  i18nKey_575: "ล็อคแล้ว",
  i18nKey_576: "ใช้แล้ว",
  i18nKey_577: "ตรวจสอบแล้ว",
  i18nKey_578: "ยกเลิก",
  i18nKey_579: "ไม่สามารถลบการควบคุมการปรับใช้ที่เปิดใช้งานได้",
  i18nKey_580: "เปิดใช้งานแล้ว",
  i18nKey_581: "ไม่สามารถลบเครือข่ายอุปกรณ์ที่กำหนดค่าไว้ได้",
  i18nKey_582: "ติดตั้งแล้ว",
  i18nKey_583: "$ เปิด",
  i18nKey_584: "เปิด",
  i18nKey_585: "ตรวจสอบแล้ว",
  i18nKey_586: "เกิน",
  i18nKey_587: "จ่าย",
  i18nKey_588: "เขียนออก",
  i18nKey_589: "หมดอายุ",
  i18nKey_590: "กล้องที่เชื่อมต่อต้องไม่เกินสามตัว",
  i18nKey_591: "ไม่มีการเชื่อมต่อกล้อง",
  i18nKey_592: "การเชื่อมต่อกล้องแล้ว",
  i18nKey_593: "$ ปิด",
  i18nKey_594: "จำนวนผู้เยี่ยมชม (คน)",
  i18nKey_595: "มาถึงแล้ว",
  i18nKey_596: "มีอยู่",
  i18nKey_597: "ประมวลผล",
  i18nKey_598: "จำนวนตั๋วเงินที่ออกโดยไม่ได้หัก (หยวน)",
  i18nKey_599: "บิลยังไม่ถูกหัก",
  i18nKey_600: "ยอดบิลสะสม",
  i18nKey_601: "หมดเวลา",
  i18nKey_602: "การย้ายล้มเหลว",
  i18nKey_603: "มือถือ",
  i18nKey_604: "ย้ายสำเร็จ",
  i18nKey_605:
    "อุปกรณ์ที่นำออกจะถูกคืนค่าเป็น 'อุปกรณ์ที่จะจัดสรร' และสามารถเพิ่มได้อีกครั้งหากจำเป็น คุณต้องการนำอุปกรณ์ออกจากพื้นที่นี้หรือไม่",
  i18nKey_606: "ย้ายออก",
  i18nKey_607: "เครื่องที่เป็นของ",
  i18nKey_608: "แบรนด์เครื่องมือ",
  i18nKey_609: "ชื่อเครื่องมือ",
  i18nKey_610: "หมวดเครื่องมือ",
  i18nKey_611: "รหัสเครื่องต้องไม่ว่างเปล่า",
  i18nKey_612: "รหัสเครื่องมือ / หมายเลข / ชื่อ",
  i18nKey_613: "หมายเลขมิเตอร์ / หน่วยอาคาร",
  i18nKey_614: "หมายเลขมิเตอร์",
  i18nKey_615: "รหัสเดียว",
  i18nKey_616: "ประเภทหนึ่ง",
  i18nKey_617: "รีเซ็ตปุ่มเดียว",
  i18nKey_618: "ปิดใช้งานด้วยการคลิกครั้งเดียว",
  i18nKey_619: "หนึ่งปุ่มเพื่อเปิด",
  i18nKey_620: "แผนกแรก",
  i18nKey_621: "ระดับแรก",
  i18nKey_622: "คำเตือนทั่วไป",
  i18nKey_623: "หนึ่ง",
  i18nKey_624: "เจ้าของ",
  i18nKey_625: "งานธุรกิจ",
  i18nKey_626: "รหัสธุรกิจ",
  i18nKey_627: "ชื่อรูปแบบต้องไม่ว่างเปล่า",
  i18nKey_628: "ชื่อธุรกิจ",
  i18nKey_629: "รูปแบบ",
  i18nKey_630: "ค้นหาหนทางสู่ความสำเร็จ ไม่ใช่เหตุผลของความล้มเหลว",
  i18nKey_631: "ข้อมูลคำเชิญ",
  i18nKey_632: "เวลาเชิญ",
  i18nKey_633: "เชิญชวนผู้เยี่ยมชม",
  i18nKey_634: "ข้อมูลในการเชิญ",
  i18nKey_635: "เวลาเชิญ",
  i18nKey_636: "มีการแสดงสีให้คุณเลือกมากกว่า 6 อย่าง",
  i18nKey_637: "มลภาวะที่ร้ายแรง",
  i18nKey_638: "เกณฑ์การเตือนภัยที่สำคัญ",
  i18nKey_639: "วงจรของ",
  i18nKey_640: "วงจร",
  i18nKey_641: "ชื่อกลุ่มตรวจตรา",
  i18nKey_642: "ภาพรวมของปัญหาการตรวจตรา",
  i18nKey_643: "เจ้าหน้าที่ตรวจตรา",
  i18nKey_644: "ต้องระบุชื่อการตรวจตรา",
  i18nKey_645: "ชื่อการตรวจตราต้องมีความยาวไม่เกิน 200 อักษร",
  i18nKey_646: "คำอธิบายเส้นทางการตรวจตรา",
  i18nKey_647: "ชื่อเส้นทางตรวจตรา",
  i18nKey_648: "เส้นทางตรวจตรา",
  i18nKey_649: "ชื่อแผนการตรวจตรา",
  i18nKey_650: "กำหนดการตรวจตรา",
  i18nKey_651: "คำเตือนการตรวจตรา",
  i18nKey_652: "การตรวจตรา",
  i18nKey_653: "การเลือกโซนอัคคีภัย",
  i18nKey_654: "เลือกอุปกรณ์ทดแทน",
  i18nKey_655: "ระยะเวลาคัดเลือก",
  i18nKey_656: "เลือกไฟถนน",
  i18nKey_657: "เลือกประเภทสถานที่",
  i18nKey_658: "เลือกประเภทบ้าน",
  i18nKey_659: "เลือกแผนกการจัดการ",
  i18nKey_660: "เลือกการเชื่อมโยง",
  i18nKey_661:
    "ไม่มีข้อมูลการจราจรในตำแหน่งที่เลือกโปรดเลือกพื้นที่ใหม่และลองอีกครั้ง",
  i18nKey_662: "ช่วงจำวันที่เลือกมากกว่า 190 วันโปรดลดจำนวนวันและลองอีกครั้ง",
  i18nKey_663: "เลือกระดับอย่างน้อยไปยังสวนสาธารณะ",
  i18nKey_664: "เลือกป้ายกำกับ",
  i18nKey_665: "ทางเข้า",
  i18nKey_666:
    "หมายเลขซีเรียลต้องประกอบด้วยอักษรจีน ภาษาอังกฤษและตัวเลขเท่านั้น",
  i18nKey_667: "ความยาวของหมายเลขซีเรียลต้องไม่เกิน 100 อักษร",
  i18nKey_668: "หมายเลขซีเรียล",
  i18nKey_669: "ปรับเปลี่ยนบริการเสริม",
  i18nKey_670: "แก้ไขการเตือนล่วงหน้าสำเร็จแล้ว",
  i18nKey_671: "แก้ไขแผนตรวจตราเรียบร้อยแล้ว",
  i18nKey_672: "ต้องปิดราคาก่อนเพื่อแก้ไขข้อมูล!",
  i18nKey_673: "หากต้องการแก้ไขข้อมูลคุณต้องหยุดตำแหน่งก่อน!",
  i18nKey_674: "การแก้ไขส่วนลดมีเวลาจำกัด",
  i18nKey_675: "การจำกัดการปรับเปลี่ยนยานพาหนะ",
  i18nKey_676: "ปรับเปลี่ยนอุปกรณ์",
  i18nKey_677: "อ่านก่อนแก้ไข",
  i18nKey_678: "เปลี่ยนรหัสผ่านเรียบร้อยแล้วโปรดเข้าสู่ระบบอีกครั้ง",
  i18nKey_679: "แก้ไขข้อมูลพื้นฐานไม่สำเร็จ",
  i18nKey_680: "แก้ไขข้อมูลพื้นฐานของพื้นที่สำเร็จแล้ว",
  i18nKey_681: "ปรับเปลี่ยนแผน",
  i18nKey_682: "แก้ไขการกำหนดค่าคลาวด์แบบไฮบริดสำเร็จ",
  i18nKey_683: "แก้ไขข้อมูลของคลาวด์แบบไฮบริด",
  i18nKey_684: "แก้ไขการบำรุงรักษาทรัพยากรห้องประชุม",
  i18nKey_685: "แก้ไขลำดับห้องประชุม",
  i18nKey_686: "ปรับเปลี่ยนห้องประชุม",
  i18nKey_687: "แก้ไขตารางเดินรถ",
  i18nKey_688: "แก้ไขชื่อหมายเหตุ",
  i18nKey_689: "แก้ไขการกำหนดค่า VCM",
  i18nKey_690: "พักผ่อน",
  i18nKey_691: "ความสว่างของการนอนหลับ",
  i18nKey_692: "เฉยๆ",
  i18nKey_693: "มีอักษรพิเศษในชื่อกรุณากรอกใหม่",
  i18nKey_694: "ชื่อหรือหมายเลขโทรศัพท์",
  i18nKey_695: "ชื่อ",
  i18nKey_696: "ต้องระบุเพศ",
  i18nKey_697: "เพศ",
  i18nKey_698: "แบบ",
  i18nKey_699: "วันจันทร์",
  i18nKey_700: "วันศุกร์",
  i18nKey_701: "วันอาทิตย์",
  i18nKey_702: "วันพฤหัสบดี",
  i18nKey_703: "วันพุธ",
  i18nKey_704: "วันเสาร์",
  i18nKey_705: "วันอังคาร",
  i18nKey_706: "หมวดหมู่ย่อยใหม่",
  i18nKey_707: "เพิ่มหมวดหมู่ย่อย",
  i18nKey_708: "เพิ่มบริการเสริม",
  i18nKey_709: "เพิ่มเกณฑ์การเตือนภัยล่วงหน้าเรียบร้อยแล้ว",
  i18nKey_710: "เกณฑ์การเตือนใหม่",
  i18nKey_711: "รูปแบบใหม่ระดับแรก",
  i18nKey_712: "เพิ่มสมาชิกในทีม",
  i18nKey_713: "เพิ่มโซนไฟ",
  i18nKey_714: "เพิ่มสถานที่",
  i18nKey_715: "การเพิ่มล้มเหลวโปรดลองอีกครั้งในภายหลัง",
  i18nKey_716: "อุปกรณ์ใหม่",
  i18nKey_717: "การกำหนดค่าใหม่",
  i18nKey_718: "บทบาทใหม่",
  i18nKey_719:
    "เส้นทางเมนูสถานที่ที่เพิ่มเข้ามาใหม่: บริการขององค์กร -> การจัดการห้องประชุม -> บริการเสริม!",
  i18nKey_720: "แผนใหม่",
  i18nKey_721: "เพิ่มการบำรุงรักษาทรัพยากรห้องประชุม",
  i18nKey_722: "เพิ่มลำดับห้องประชุม",
  i18nKey_723: "เพิ่มห้องประชุม",
  i18nKey_724: "อพาร์ทเมนท์ใหม่",
  i18nKey_725: "เพิ่มสถานที่เพิ่มเติม",
  i18nKey_726: "บ้านใหม่",
  i18nKey_727: "เพิ่มเรียบร้อยแล้ว",
  i18nKey_728: "ผลิตภัณฑ์ใหม่",
  i18nKey_729: "ช่วงผลเวลาของนโยบายใหม่ (สามารถสร้างได้สูงสุด 4 รายการ)",
  i18nKey_730: "ข้อมูลฉลากใหม่",
  i18nKey_731: "รหัสผ่านใหม่ต้องไม่เหมือนกับรหัสผ่านเดิม",
  i18nKey_732: "รหัสผ่านใหม่",
  i18nKey_733: "จำนวนลูกค้าใหม่",
  i18nKey_734: "อัตราส่วนลูกค้าใหม่",
  i18nKey_735: "แผนการตรวจตราใหม่ที่ประสบความสำเร็จ",
  i18nKey_736: "รถใหม่ที่ถูกจำกัด",
  i18nKey_737: "การติดตามใบหน้าใหม่",
  i18nKey_738: "เส้นทางใหม่",
  i18nKey_739: "สร้างข้อมูลพื้นฐานของพื้นที่ใหม่ไม่สำเร็จ",
  i18nKey_740: "สร้างข้อมูลพื้นฐานของพื้นที่ใหม่สำเร็จแล้ว",
  i18nKey_741: "แผนใหม่",
  i18nKey_742: "การกำหนดค่าคลาวด์แบบไฮบริดใหม่สำเร็จแล้ว",
  i18nKey_743: "สร้างตั๋วใหม่สำเร็จ",
  i18nKey_744: "การควบคุมการปรับใช้ใหม่",
  i18nKey_745: "การกำหนดค่า VCM ใหม่",
  i18nKey_746: "ตรวจสอบผลลัพธ์",
  i18nKey_747: "น้อยกว่าหรือเท่ากับ",
  i18nKey_748: "น้อยกว่า",
  i18nKey_749: "ยกเลิกชั่วโมง คืนเงิน",
  i18nKey_750: "ชั่วโมงในการยกเลิก",
  i18nKey_751: "ชั่วโมง / วันต้องไม่น้อยกว่า 4 ชั่วโมง!",
  i18nKey_752: "ชั่วโมง / วัน",
  i18nKey_753: "ชั่วโมง",
  i18nKey_754: "ฝ่ายขาย",
  i18nKey_755:
    "ข้อความจะมีการส่งข้อมูลที่แสดงผลของรายการข้อความของแอพเมืองคลาวด์",
  i18nKey_756: "ขจัดสัญญาณเตือนสำเร็จ",
  i18nKey_757: "ปริมาณการใช้น้ำ",
  i18nKey_758: "ปริมาณการบริโภค",
  i18nKey_759: "การใช้พลังงาน",
  i18nKey_760: "การบริโภคทั่วไปยังคงเป็นเรื่องน่าสนใจ",
  i18nKey_761: "การใช้จ่ายพลังงาน",
  i18nKey_762: "ประเภทการบริโภค",
  i18nKey_763: "การซื้อของผู้บริโภค",
  i18nKey_764:
    "ระบบดับเพลิง / สัญญาณเตือนไฟอัตโนมัติและระบบเชื่อมโยง / ปุ่มเตือนด้วยตนเอง",
  i18nKey_765:
    "ระบบดับเพลิง / สัญญาณเตือนอัคคีภัยอัตโนมัติและระบบเชื่อมโยง / สัญญาณเตือนไฟไหม้ให้เจ้าของบ้าน",
  i18nKey_766:
    "ระบบดับเพลิง / สัญญาณเตือนไฟไหม้อัตโนมัติและระบบเชื่อมโยง / เครื่องตรวจจับควันไฟ",
  i18nKey_767:
    "ระบบดับเพลิง / สัญญาณเตือนอัคคีภัยอัตโนมัติและระบบเชื่อมโยง / เครื่องตรวจจับความร้อน",
  i18nKey_768: "ระบบดับเพลิง",
  i18nKey_769: "แนวโน้มเหตุการณ์ไฟไหม้",
  i18nKey_770: "ชื่อตำบลที่เกิดไฟไหม้",
  i18nKey_771: "เทมเพลตสำหรับกรอกการนำเข้าเกี่ยวกับเรื่องไฟไหม้",
  i18nKey_772: "รหัสโซนของการเกิดไฟไหม้",
  i18nKey_773: "กองดับเพลิง",
  i18nKey_774:
    "พื้นที่โครงการตั้งอยู่ในโรงรถใต้ดินชั้น B1 และ B2 ของ Jinqiao Wanchuang Center ซึ่งติดตั้งระบบกักเก็บพลังงาน 250kW / 924.9kWh 3 ชุด เต้ารับตั้งอยู่ที่ด้านข้างของบัสบาร์ 400V ของหม้อแปลง 10kV สาย A 2 # (1000kVA) และหม้อแปลง 10kV สาย B # 5 # (1000kVA) 400V ด้านบัสและหม้อแปลง 6 # (2000kVA) 400V",
  i18nKey_775: "ชื่อโครงการ",
  i18nKey_776: "แนะนำโครงการ",
  i18nKey_777: "ที่อยู่",
  i18nKey_778: "ขนาดกล่อง (นิ้ว)",
  i18nKey_779: "พื้นที่ที่อยู่ติดกันไม่สามารถอยู่ในตำแหน่งเดียวกับสถานที่",
  i18nKey_780: "พื้นที่ติดกัน",
  i18nKey_781: "การชำระเงินแบบออฟไลน์",
  i18nKey_782: "พนักงานชำระเงินออฟไลน์",
  i18nKey_783: "การชำระเงินแบบออฟไลน์",
  i18nKey_784: "รูปแบบการป้อนเงินคืนออนไลน์ไม่ถูกต้อง!",
  i18nKey_785: "คืนเงินออนไลน์",
  i18nKey_786: "ยกเลิกออนไลน์",
  i18nKey_787: "ปัจจัยจำกัด",
  i18nKey_788:
    "มีช่วงเวลาจำกัดในการลดราคากับสิ่งของที่ตั้งอยู่บนชั้นวาง โปรดซื้อหลังจากที่คุณหยิบออกจากชั้นวาง!",
  i18nKey_789: "ช่วงเวลาจำกัด",
  i18nKey_790: "ยานพาหนะที่ถูก จำกัด",
  i18nKey_791: "ภาพสด",
  i18nKey_792: "วิดีโอสด",
  i18nKey_793: "ราคาที่แสดง",
  i18nKey_794: "แสดง",
  i18nKey_795: "ดาวน์โหลดเทมเพลต (พื้นที่สาธารณะ)",
  i18nKey_796: "ดาวน์โหลดเทมเพลต (ห้อง)",
  i18nKey_797: "ดาวน์โหลดเทมเพลต",
  i18nKey_798: "ดาวน์โหลดเทมเพลตฟอร์ม",
  i18nKey_799: "ดาวน์โหลด",
  i18nKey_800: "เคลื่อนที่ลงมาด้านล่าง",
  i18nKey_801: "ขั้นตอนต่อไป",
  i18nKey_802: "ในช่วงบ่าย",
  i18nKey_803: "ลดลง",
  i18nKey_804: "ชั้นวางปิด",
  i18nKey_805: "ระดับล่างเพิ่มขึ้น",
  i18nKey_806: "ลง",
  i18nKey_807: "แนะนำการทำงานของพารามิเตอร์สำหรับชั่วโมงถัดไป",
  i18nKey_808: "ออกคำสั่งเรียบร้อยแล้ว",
  i18nKey_809: "แผนการส่งออก",
  i18nKey_810: "เวลาสั่งซื้อ",
  i18nKey_811: "ผู้สั่งซื้อ",
  i18nKey_812: "ระบบจะปฏิเสธโดยอัตโนมัติ",
  i18nKey_813: "ระบบคาดการณ์อื่น ๆ",
  i18nKey_814: "ระบบคาดการณ์บุคลากรแบบถาวร",
  i18nKey_815: "ผู้ดูแลระบบ",
  i18nKey_816:
    "รหัสระบบต้องเป็นภาษาจีนตัว อักษรและขีดล่างภายใน 50 อักษรเท่านั้น",
  i18nKey_817: "รหัสระบบต้องไม่ว่างเปล่า",
  i18nKey_818: "ระบบ 2",
  i18nKey_819: "ระบบ 1",
  i18nKey_820: "ระบบ",
  i18nKey_821: "การกำหนดค่ากลยุทธ์ของการตรวจสอบห้องน้ำ",
  i18nKey_822: "ตะวันตกเฉียงใต้",
  i18nKey_823: "บวกเท็จ",
  i18nKey_824: "พนักงานด้านสินทรัพย์",
  i18nKey_825: "สินทรัพย์",
  i18nKey_826: "ห้าหมวดหมู่",
  i18nKey_827: "แผนกระดับ 5",
  i18nKey_828: "ระดับ 5",
  i18nKey_829: "5",
  i18nKey_830: "ไม่แสดง",
  i18nKey_831: "ไม่มีบันทึก",
  i18nKey_832: "เหตุการณ์ที่ไม่เกี่ยวข้อง",
  i18nKey_833: "กล้องที่ไม่ได้เชื่อมต่อ",
  i18nKey_834: "ไม่มีข้อมูลที่ตรงตามเงื่อนไขการสืบค้น",
  i18nKey_835: "ไม่สามารถรับข้อมูลในบรรทัด",
  i18nKey_836: "ไม่มีอุปกรณ์ที่เหนือกว่า",
  i18nKey_837: "ไม่",
  i18nKey_838: "ไฟล์รองรับเฉพาะ excel",
  i18nKey_839: "ขนาดไฟล์ต้องไม่เกิน 20M",
  i18nKey_840: "อุณหภูมิ",
  i18nKey_841: "ตำแหน่งเส้นทางแบบสมบูรณ์",
  i18nKey_842: "รหัสสถานที่",
  i18nKey_843: "ตำแหน่ง",
  i18nKey_844: "ไม่ทราบ",
  i18nKey_845: "ไม่ได้ตั้งค่าตัวเร่งโปรดเพิ่มตัวดัน!",
  i18nKey_846: "ไม่ได้เริ่มต้น",
  i18nKey_847: "ไม่มีอุปกรณ์",
  i18nKey_848: "ไม่ได้ป้อน",
  i18nKey_849: "ไม่แยกจากกัน",
  i18nKey_850: "ยังไม่เริ่ม",
  i18nKey_851: "ไม่ตัดบัญชี",
  i18nKey_852: "จำนวนเงินที่ไม่ได้หัก",
  i18nKey_853: "จำนวนผู้ไม่มาเยี่ยม (คน)",
  i18nKey_854: "ไม่มีการละเมิดใด ๆ เกิดขึ้น",
  i18nKey_855: "ไม่ได้คัดลอกจำนวนทั้งหมด (a)",
  i18nKey_856: "ส่วนจำนวนของละติจูดคือ 0-90",
  i18nKey_857: "ละติจูด",
  i18nKey_858: "ซ่อมแซมเนื้อหา",
  i18nKey_859: "ผลการซ่อม",
  i18nKey_860: "คนซ่อมบำรุง",
  i18nKey_861: "รักษา",
  i18nKey_862: "สถานะการบำรุงรักษา",
  i18nKey_863:
    "ธุรกิจซ่อมบำรุงต้องประกอบด้วยภาษาจีนและภาษาอังกฤษตัวเลขและ _ เท่านั้น",
  i18nKey_864: "ความยาวของชื่อผู้ให้บริการบำรุงรักษาต้องไม่เกิน 50 อักษร",
  i18nKey_865: "ผู้ดูแล",
  i18nKey_866: "หลักฐานการละเมิด",
  i18nKey_867: "เหตุผลในการละเมิด",
  i18nKey_868: "รายละเอียดการละเมิด",
  i18nKey_869: "หยุดเวลา",
  i18nKey_870: "สถานที่จอดรถ",
  i18nKey_871: "จำนวนการละเมิด",
  i18nKey_872: "สว่างเล็กน้อย",
  i18nKey_873: "ลืมรหัสผ่าน",
  i18nKey_874: "การโหลดเครือข่ายผิดปกติ โปรดลองอีกครั้งในภายหลัง",
  i18nKey_875: "การโหลดเครือข่ายผิดปกติ โปรดลองอีกครั้งในภายหลัง",
  i18nKey_876: "ชื่อเกตเวย์",
  i18nKey_877: "เกตเวย์ไม่ออนไลน์และการส่วนของการจัดส่งแผนล้มเหลว",
  i18nKey_878: "สวน Vanke",
  i18nKey_879: "สวนสาธารณะ Vanke Xicheng",
  i18nKey_880: "สวนสาธารณะ Vanke Haiding District",
  i18nKey_881: "อำเภอ Vanke Dongcheng",
  i18nKey_882: "สวนสาธารณะ Vanke Chaoyang",
  i18nKey_883: "Vanke Binhai New Area Park",
  i18nKey_884: "Van",
  i18nKey_885: "รถบัสมาสาย",
  i18nKey_886: "ชื่อสถานที่ภายนอก",
  i18nKey_887: "รหัสตำแหน่งภายนอก",
  i18nKey_888: "บริษัท ภายนอก",
  i18nKey_889: "สถานะการคืนเงิน",
  i18nKey_890: "ขอเงินคืน",
  i18nKey_891: "การคืนเงินล้มเหลว",
  i18nKey_892: "การดำเนินการคืนเงิน",
  i18nKey_893: "ระยะเวลาดำเนินการคืนเงิน",
  i18nKey_894: "เวลาคืนเงินสำเร็จ",
  i18nKey_895: "คืนเงินเรียบร้อย",
  i18nKey_896: "กลับ",
  i18nKey_897: "ยกเลิกการสมัคร",
  i18nKey_898: "แนะนำลายเซ็น",
  i18nKey_899: "นำความสามัคคีและความร่วมมือออกไปทั้งหมด",
  i18nKey_900: "จุดเป้าหมายไม่สามารถเหมือนกับจุดสิ้นสุด",
  i18nKey_901: "จุดเป้าหมายไม่สามารถเหมือนกับจุดเริ่มต้น",
  i18nKey_902: "ทาง",
  i18nKey_903: "ขนาดภาพต้องไม่เกิน 3 เมตร!",
  i18nKey_904: "ขนาดภาพต้องไม่เกิน 3 เมตร",
  i18nKey_905: "มุมมองภาพ",
  i18nKey_906: "การตัดรูปภาพ",
  i18nKey_907: "ภาพ",
  i18nKey_908: "แผนภูมิรายงาน",
  i18nKey_909: "แผนภูมิ",
  i18nKey_910: "โควต้าการตั้งค่าแบบรวม",
  i18nKey_911: "ระยะเวลาทางสถิติ (วัน)",
  i18nKey_912: "สถิติโดยละเอียด",
  i18nKey_913: "ชื่อแผนภูมิ",
  i18nKey_914: "วันทางสถิติสามารถเป็นจำนวนเต็มบวกเท่านั้น",
  i18nKey_915: "เวลาสถิติ:",
  i18nKey_916: "การกำหนดค่าบัตรสถิติ",
  i18nKey_917:
    "สถิติตั้งการเริ่มต้นตั้งแต่เมื่อวานนี้ จำนวนเงินที่เติมจะขึ้นอยู่กับการมาถึงของบัญชี",
  i18nKey_918: "ตกลง",
  i18nKey_919: "สามารถตั้งกฎการยกเลิกได้เพียงข้อเดียวในวันเดียวกัน!",
  i18nKey_920: "ไม่สามารถเลือกกล้องมากกว่า 4 ตัวในเวลาเดียวกัน",
  i18nKey_921: "ช่วงเวลาเดียวกัน (กิโลวัตต์ - ชั่วโมง)",
  i18nKey_922: "การตั้งค่าข้อมูลที่แสดงร่วม",
  i18nKey_923: "จำนวนเพื่อน",
  i18nKey_924: "ปีต่อปี",
  i18nKey_925: "ปีต่อปี",
  i18nKey_926: "YoY",
  i18nKey_927: "ชั้นทางเข้า",
  i18nKey_928: "ผ่าน",
  i18nKey_929: "หยุด",
  i18nKey_930: "ปิดใช้งาน",
  i18nKey_931: "ระยะเวลาการเข้าพักต้องเป็นจำนวนเต็มบวกเท่านั้น",
  i18nKey_932: "ระยะเวลาเข้าพัก (ชั่วโมง)",
  i18nKey_933: "ชั้นจอดรถ",
  i18nKey_934: "เชื่อมต่อ",
  i18nKey_935: "เวลาจอดรถ",
  i18nKey_936: "เวลาจอดรถ",
  i18nKey_937: "ค่าจอดรถ (ภายใน 24 ชั่วโมง)",
  i18nKey_938: "โหมดการชาร์จที่จอดรถ",
  i18nKey_939: "ชื่อที่จอดรถ",
  i18nKey_940: "ที่อยู่ลานจอดรถ",
  i18nKey_941: "ที่จอดรถต้องไม่ว่างเปล่า",
  i18nKey_942: "การกำหนดค่ากลยุทธ์การจอดรถ",
  i18nKey_943: "อุปกรณ์แก้จุดบกพร่อง",
  i18nKey_944: "เพิ่มคลาสย่อย",
  i18nKey_945:
    "การเพิ่มตัวผลักดันข่าวสารสามารถใช้ได้โดยผู้ใช้ที่ลงทะเบียนของแอป Yuncheng เท่านั้น",
  i18nKey_946: "เพิ่มพนักงานชำระเงินออฟไลน์",
  i18nKey_947: "เพิ่มส่วนลดในเวลาจำกัด",
  i18nKey_948: "เพิ่มการแจ้งเตือน",
  i18nKey_949: "เพิ่มแพ็คเกจ",
  i18nKey_950: "เพิ่มองค์กรที่เกี่ยวข้อง",
  i18nKey_951: "เพิ่มใบหน้า",
  i18nKey_952: "เพิ่มกฎการจัดแสง",
  i18nKey_953: "เพิ่ม บริษัท",
  i18nKey_954: "เพิ่มเส้นทางการเดินทาง",
  i18nKey_955: "เพิ่มรถ",
  i18nKey_956: "วันผลักดันเหตุการณ์",
  i18nKey_957: "วันที่ผ่านมา",
  i18nKey_958: "วัน",
  i18nKey_959: "การแทนที่ที่เร็วที่สุด",
  i18nKey_960: "แทนที่รายการที่เลือก",
  i18nKey_961: "ความสัมพันธ์ทดแทน",
  i18nKey_962: "แทนที่แผนที่ฐาน",
  i18nKey_963: "อุณหภูมิร่างกาย",
  i18nKey_964: "การแจ้งเตือน",
  i18nKey_965: "เตือน",
  i18nKey_966: "เกณฑ์แบบรวดเร็ว",
  i18nKey_967: "ข้อความแจ้งแบบรวดเร็ว",
  i18nKey_968: "พร้อมรับคำเตือน",
  i18nKey_969:
    "เคล็ดลับ: รองรับเฉพาะการอัปโหลดรูปภาพในรูปแบบ jpg, png, ขนาดภาพที่อัปโหลดไม่เกิน 3 เมตร",
  i18nKey_970:
    "คำเตือน: เนื่องจากความเป็นส่วนตัวของลูกค้า กรุณากรอกรหัสผ่านเพื่อยืนยันครั้งที่สอง",
  i18nKey_971: "เคล็ดลับ: เวลาสิ้นสุดต้องมากกว่าเวลาเริ่มต้น",
  i18nKey_972:
    "เคล็ดลับ: ความล่าช้าของภาพวิดีโออยู่ที่ประมาณ 5 ~ 10 วินาทีโปรดอดทนรอ",
  i18nKey_973: "จองล่วงหน้า",
  i18nKey_974: "จำนวนวันล่วงหน้า จำกัด ภายใน 1-30 วัน",
  i18nKey_975: "พิเศษ",
  i18nKey_976: "จำนวนแพ็คเกจ",
  i18nKey_977: "การใช้งานแพ็คเกจ",
  i18nKey_978: "กำลังเปิดใช้งานแพ็กเกจ โปรดหยุดและใช้งาน!",
  i18nKey_979: "ชื่อแพ็กเกจ",
  i18nKey_980: "ประเภทแพ็คเกจ",
  i18nKey_981: "ผู้ใช้บัตรแพ็คเกจ",
  i18nKey_982: "จำนวนบัตรแพคเกจ",
  i18nKey_983: "การประมวลผลบัตรแพ็คเกจ",
  i18nKey_984: "การ์ดแพ็คเกจ",
  i18nKey_985: "จำนวนเงินที่ชำระในแพ็คเกจ (หยวน)",
  i18nKey_986: "การชำระเงินแบบแพ็คเกจ",
  i18nKey_987: "ล็อค",
  i18nKey_988: "ความเป็นเจ้าของ (เจ้าของ)",
  i18nKey_989: "บริษัท ทั้งหมด",
  i18nKey_990: "ช่วงเวลาที่เลือกต้องไม่ว่างเปล่า",
  i18nKey_991: "ช่วงเวลาที่เลือกต้องไม่เกินสองวัน",
  i18nKey_992: "ปีที่เลือกต้องอยู่ระหว่างวันที่เริ่มต้นและวันที่สิ้นสุด",
  i18nKey_993: "เจ้าของลานจอดรถ",
  i18nKey_994: "บริษัท ในเครือ",
  i18nKey_995: "คุณไม่สามารถเลือกผู้ควบคุมของคุณเอง",
  i18nKey_996: "ผู้ควบคุมที่เป็นเจ้าของต้องไม่ว่างเปล่า",
  i18nKey_997: "ผู้ควบคุมที่เป็นเจ้าของ",
  i18nKey_998: "เป็นของ",
  i18nKey_999: "บริษัท ของคุณได้ป้อนอักษรพิเศษกรุณากรอกอีกครั้ง",
  i18nKey_1000: "อักษรพิเศษที่บริษัทป้อน",
  i18nKey_1001: "บริษัทที่มีเจ้าของต้องไม่ว่างเปล่า",
  i18nKey_1002: "บริษัท",
  i18nKey_1003: "รายการย่อย",
  i18nKey_1004: "สังกัด",
  i18nKey_1005: "สาขา",
  i18nKey_1006: "ประวัติการค้นหา",
  i18nKey_1007: "สี่ประเภท",
  i18nKey_1008: "แผนกระดับ 4",
  i18nKey_1009: "ระดับ 4",
  i18nKey_1010: "4",
  i18nKey_1011: "คลาวด์ส่วนตัว",
  i18nKey_1012: "เอกชน",
  i18nKey_1013:
    "หมายเหตุ: ผู้อนุมัติที่เพิ่มเข้ามาจะทำหน้าที่เป็นผู้อนุมัติการจัดการทรัพย์สินและสามารถเพิ่มผู้อนุมัติได้สูงสุด 5 คน",
  i18nKey_1014:
    "หมายเหตุ: อัตราส่วนการใช้พลังงานของระดับนี้ถึงระดับบนเกิน> 10% ซึ่งผิดปกติ การใช้พลังงานทั้งหมดของระดับนี้คือตู้ขาเข้าแรงดันต่ำ P11, P21, P31, P41;",
  i18nKey_1015: "ยอดสะสมค่าน้ำ",
  i18nKey_1016: "พ่อค้าที่ค้างค่าน้ำ",
  i18nKey_1017: "ปริมาณการเติมน้ำที่ไม่ได้หัก",
  i18nKey_1018: "ปริมาณการเติมน้ำสะสม",
  i18nKey_1019: "ภาพรวมของค่าสาธารณูปโภค",
  i18nKey_1020: "รายละเอียดไฟฟ้าพลังน้ำ",
  i18nKey_1021: "รายการรายละเอียดไฟฟ้าพลังน้ำ",
  i18nKey_1022: "ใบสั่งจ่ายบิลค่าสาธารณูปโภค",
  i18nKey_1023: "แบบสอบถามข้อมูลรายละเอียดมาตรวัดน้ำและไฟฟ้า",
  i18nKey_1024: "เกณฑ์การเตือนมาตรวัดน้ำ:",
  i18nKey_1025: "หมายเลขซีเรียลของมาตรวัดน้ำ",
  i18nKey_1026: "เลขที่มาตรวัดน้ำ",
  i18nKey_1027: "ทำให้มีชีวิตชีวา",
  i18nKey_1028: "ระบายสีเส้นการ์ตูน",
  i18nKey_1029: "ค่าคือ 0-60",
  i18nKey_1030: "ค่าคือ 0-120",
  i18nKey_1031: "ทรัพยากรข้อมูล",
  i18nKey_1032: "แหล่งข้อมูล",
  i18nKey_1033: "รายละเอียดการตรวจสอบความผิดปกติของข้อมูล",
  i18nKey_1034: "ข้อมูลผิดปกติ (a)",
  i18nKey_1035: "ข้อมูลผิดปกติ",
  i18nKey_1036: "โหลดข้อมูลไม่สำเร็จ โปรดลองอีกครั้ง",
  i18nKey_1037: "เวลาข้อมูล",
  i18nKey_1038: "ไม่สามารถดึงข้อมูลกลับได้หลังจากการลบ ยืนยันการลบ?",
  i18nKey_1039: "ไม่สามารถกู้คืนข้อมูลได้หลังจากการลบ ยืนยันการลบ?",
  i18nKey_1040: "แหล่งข้อมูล",
  i18nKey_1041: "สรุปข้อมูล _",
  i18nKey_1042: "ข้อมูลสูญหาย (a)",
  i18nKey_1043: "ไม่อนุญาตให้ป้อนความยาวเกิน 50 อักษร",
  i18nKey_1044:
    "ไม่อนุญาตให้ป้อนความยาวเกิน 30 อักษร (อนุญาตให้ใช้ตัวอักษร - และตัวเลข)",
  i18nKey_1045:
    "ไม่อนุญาตให้ป้อนความยาวเกิน 30 อักษร (อนุญาตให้ใช้ตัวอักษรจีน ตัวอักษร - และตัวเลขได้)",
  i18nKey_1046: "ไม่อนุญาตป้อนความยาวให้เกิน 2,000 อักษร",
  i18nKey_1047: "ไม่อนุญาตให้ป้อนความยาวเกิน 100 อักษร",
  i18nKey_1048: "ป้อนชื่อเหตุการณ์หรือชื่ออุปกรณ์",
  i18nKey_1049: "ป้อนชื่อแทนกล้อง",
  i18nKey_1050: "ป้อนคำหลักเพื่อกรอง",
  i18nKey_1051: "ป้อนหมายเลขสถานีและกด Enter",
  i18nKey_1052: "ตัวเลขที่ป้อนต้องไม่เกินค่าสูงสุด 30",
  i18nKey_1053: "ตัวเลขที่ป้อนต้องไม่เกินค่าสูงสุด 24",
  i18nKey_1054: "หมายเลขที่ป้อนต้องไม่เกินเวลาทำการ",
  i18nKey_1055: "ความสบายใจ",
  i18nKey_1056: "ศึกษา",
  i18nKey_1057: "การออกใบอนุญาต",
  i18nKey_1058: "การอนุมัติสำเร็จแล้ว",
  i18nKey_1059: "การอนุญาต",
  i18nKey_1060: "หมายเลขโทรศัพท์ของผู้ให้สัมภาษณ์:",
  i18nKey_1061: "ผู้ตอบ:",
  i18nKey_1062: "ข้อมูล บริษัท ที่สัมภาษณ์",
  i18nKey_1063: "บริษัท ที่สัมภาษณ์ต้องไม่ว่างเปล่า",
  i18nKey_1064: "บริษัท ที่สัมภาษณ์:",
  i18nKey_1065: "บริษัท ที่สัมภาษณ์",
  i18nKey_1066: "ที่อยู่ที่ให้สัมภาษณ์ต้องไม่ว่างเปล่า",
  i18nKey_1067: "เวลาเข้าครั้งแรก",
  i18nKey_1068: "ค่าธรรมเนียมการจัดการ",
  i18nKey_1069: "ต้องลงทะเบียนหมายเลขโทรศัพท์มือถือในแอป Yuncheng",
  i18nKey_1070: "หมายเลขโทรศัพท์",
  i18nKey_1071: "ป้อนข้อมูลด้วยตนเอง",
  i18nKey_1072: "รายละเอียดการอ่านมิเตอร์ด้วยตนเอง",
  i18nKey_1073: "สร้างด้วยตนเอง",
  i18nKey_1074: "สิ่งกระตุ้นด้วยตนเอง",
  i18nKey_1075: "การแก้ไขด้วยตนเอง",
  i18nKey_1076: "คู่มือ:",
  i18nKey_1077: "เอาออกไป",
  i18nKey_1078: "ชื่อบัญชีรวบรวม",
  i18nKey_1079: "บัญชีเก็บเงิน",
  i18nKey_1080: "รวบรวมหมายเลขโทรศัพท์",
  i18nKey_1081: "แจ้งข้อหา",
  i18nKey_1082: "ไม่มีตัวเลือกที่ซ้ำกันสำหรับสถานที่แบบชำระเงินและสถานที่ฟรี!",
  i18nKey_1083: "ค่าผ่านทางสำหรับสถานที่",
  i18nKey_1084: "จำนวนเงินที่เรียกเก็บ",
  i18nKey_1085: "ถอดความ",
  i18nKey_1086: "อุณหภูมิห้อง",
  i18nKey_1087: "ความชื้นในร่ม",
  i18nKey_1088: "ในร่ม",
  i18nKey_1089: "องค์กรที่เกี่ยวข้อง",
  i18nKey_1090: "ผิดปกติหรือเปล่า",
  i18nKey_1091: "ไม่ว่าจะวนซ้ำ",
  i18nKey_1092: "ไม่ว่าจะแก้ไข",
  i18nKey_1093: "ไม่ว่าจะทำตามกระบวนการทั้งหมดหรือไม่",
  i18nKey_1094: "ไม่ว่าจะลบข้อมูล",
  i18nKey_1095: "จะลบข้อมูลที่ตรวจสอบหรือไม่",
  i18nKey_1096: "แน่ใจไหมว่าต้องการดาวน์โหลดภาพแผนภูมิ",
  i18nKey_1097: "คุณแน่ใจหรือไม่ว่าจะดาวน์โหลดรายงาน excel",
  i18nKey_1098:
    "คุณแน่ใจหรือไม่ว่าจะลบข้อมูลห้องประชุมและราคา เพราะไม่สามารถกู้คืนได้หลังจากลบ!",
  i18nKey_1099: "แน่ใจหรือว่าลบสถานี ไม่สามารถกู้คืนได้หลังจากลบ!",
  i18nKey_1100: "เปิดใช้วงเงินบัตรรายเดือนหรือไม่",
  i18nKey_1101: "เปิดใช้งานนโยบายการแจ้งเตือนหรือไม่",
  i18nKey_1102: "เปิดใช้งานข้อจำกัดที่จอดรถหรือไม่",
  i18nKey_1103: "ไม่ว่าจะเปิดใช้งาน",
  i18nKey_1104: "คุณออกไปแล้ว",
  i18nKey_1105: "ไม่ว่าจะแต่งหน้า",
  i18nKey_1106: "สถิติการตรวจตราวิดีโอ",
  i18nKey_1107: "ตรวจตราวิดีโอ",
  i18nKey_1108:
    "วิดีโอถูกดักจับสำเร็จแล้ว โปรดไปที่รายละเอียดกิจกรรมทางวิดีโอที่เกี่ยวข้องทางด้านซ้ายเพื่อดู",
  i18nKey_1109: "การจับภาพวิดีโอ",
  i18nKey_1110: "การเล่นวิดีโอ",
  i18nKey_1111: "ความล่าช้าของภาพวิดีโอประมาณ 5-10 วินาทีโปรดอดใจรอ",
  i18nKey_1112: "ตัวช่วยเหลือวิดีโอ",
  i18nKey_1113: "อัตราการเจาะวิดีโอ",
  i18nKey_1114: "วิดีโอ",
  i18nKey_1115: "สถิติสถานะเหตุการณ์",
  i18nKey_1116: "สถานะเหตุการณ์",
  i18nKey_1117: "รายงานเหตุการณ์เรียบร้อยแล้ว",
  i18nKey_1118: "รายงานเหตุการณ์",
  i18nKey_1119: "คำอธิบายเหตุการณ์ต้องไม่เกิน 1,000 อักษร",
  i18nKey_1120: "คำอธิบายเหตุการณ์",
  i18nKey_1121: "ประเภทเหตุการณ์",
  i18nKey_1122: "ระดับเหตุการณ์",
  i18nKey_1123: "ระดับเหตุการณ์",
  i18nKey_1124: "เวลาประมวลผลเหตุการณ์",
  i18nKey_1125: "การจัดการเหตุการณ์",
  i18nKey_1126: "การกำหนดค่าการประมวลผลการหมดเวลาของเหตุการณ์",
  i18nKey_1127: "ชื่อกิจกรรม",
  i18nKey_1128: "หมายเลขเหตุการณ์",
  i18nKey_1129: "ตั้งแต่วันที่ 1 ตุลาคม 2019",
  i18nKey_1130: "ตั้งแต่วันที่ 1 ตุลาคม 2562",
  i18nKey_1131: "สถานะการใช้งาน",
  i18nKey_1132: "ใช้การยืนยันบัญชีโดเมน",
  i18nKey_1133: "ใช้การยืนยันหมายเลขโทรศัพท์มือถือ",
  i18nKey_1134: "ใช้เวลา",
  i18nKey_1135: "อัตราการใช้ (%)",
  i18nKey_1136: "อัตราการใช้งาน",
  i18nKey_1137: "ใช้ที่อยู่:",
  i18nKey_1138: "วิดีโอเรียลไทม์ __",
  i18nKey_1139: "ดัชนีคุณภาพอากาศแบบเรียลไทม์",
  i18nKey_1140: "กำลังไฟฟ้าแบบเรียลไทม์ (กิโลวัตต์ชั่วโมง)",
  i18nKey_1141: "การอ่านมิเตอร์แบบเรียลไทม์",
  i18nKey_1142: "มูลค่าที่แท้จริง",
  i18nKey_1143: "จำนวนเงินที่จ่ายจริง",
  i18nKey_1144: "ระยะเวลาไม่ควรน้อยกว่า 30 วินาที",
  i18nKey_1145: "อย่าเกินหนึ่งชั่วโมง",
  i18nKey_1146: "ตั้งค่าอย่างน้อยหนึ่งครั้ง",
  i18nKey_1147: "ตั้งเวลา",
  i18nKey_1148: "ช่วงเวลาต้องไม่เกิน 6 เดือน",
  i18nKey_1149: "ช่วงเวลา",
  i18nKey_1150: "เวลาที่จำกัด",
  i18nKey_1151: "งวด",
  i18nKey_1152: "เจาะจงเวลา",
  i18nKey_1153: "เวลาต้องไม่ว่างเปล่า",
  i18nKey_1154: "เวลาต้องเป็นจำนวนเต็มบวกที่ไม่ใช่ศูนย์",
  i18nKey_1155: "สถิติการหลั่งไหลของผู้โดยสารตามเวลา",
  i18nKey_1156: "เวลา",
  i18nKey_1157: "รายชื่อไม่น่าไว้ใจ",
  i18nKey_1158: "เวลาหมดอายุ",
  i18nKey_1159: "ความล้มเหลว",
  i18nKey_1160: "ที่เหลือทั้งหมดที่สามารถสั่งออกได้",
  i18nKey_1161: "จำนวนเงินที่เหลือน้อยกว่าเกณฑ์",
  i18nKey_1162: "สมดุล",
  i18nKey_1163: "ลำดับที่เหลือ",
  i18nKey_1164: "ที่จอดรถที่เหลือ",
  i18nKey_1165: "ที่จอดรถที่เหลือ",
  i18nKey_1166: "ชื่อย่อจังหวัด",
  i18nKey_1167: "เวลาที่มีประสิทธิภาพ",
  i18nKey_1168: "วันที่มีผลใช้บังคับ: วันเดือน",
  i18nKey_1169: "เรื่องราวที่มีประสิทธิภาพ",
  i18nKey_1170: "สร้างรหัส QR",
  i18nKey_1171: "สร้างสัญญาณเตือนความปลอดภัย",
  i18nKey_1172: "ประเภทลิฟท์",
  i18nKey_1173: "เซินเจิ้น",
  i18nKey_1174: "น้ำเงินเข้ม",
  i18nKey_1175: "สีเทาเข้ม",
  i18nKey_1176: "ต้องกรอกหมายเลขประจำตัวประชาชนและหมายเลขหนังสือเดินทาง",
  i18nKey_1177: "เลขประจำตัว",
  i18nKey_1178: "บัตรประชาชน:",
  i18nKey_1179: "การรับรองความถูกต้อง",
  i18nKey_1180: "สถานะการสมัคร",
  i18nKey_1181: "เวลาคืนเงิน",
  i18nKey_1182: "บริษัทของผู้สมัคร",
  i18nKey_1183: "ใช้สำหรับการยกเลิก",
  i18nKey_1184: "สมัครลิฟต์ขนส่งสินค้า",
  i18nKey_1185: "การตรวจสอบกล้อง",
  i18nKey_1186: "กำลังโหลดกล้องโปรดรอสักครู่ ...",
  i18nKey_1187: "จำนวนกล้อง",
  i18nKey_1188: "กล้อง (a)",
  i18nKey_1189: "กล้อง",
  i18nKey_1190: "หมายเลขกล้อง",
  i18nKey_1191: "กล้องวิดีโอ",
  i18nKey_1192: "ตั้งค่าคำเตือน (วัน):",
  i18nKey_1193: "ตั้งค่าคำเตือน",
  i18nKey_1194:
    "การตั้งเวลาทำการสามารถยกเว้นข้อมูลการหลั่งไหลของผู้โดยสารในช่วงเวลาปิดทำการได้อย่างมีประสิทธิภาพ",
  i18nKey_1195: "ตั้งค่าตัวกดข้อความ:",
  i18nKey_1196: "ตั้งเป็นชั้นหลัก",
  i18nKey_1197: "ตั้งค่าเรียบร้อยแล้ว",
  i18nKey_1198: "จำนวนสถานที่ทั้งหมด",
  i18nKey_1199: "หมวดย่อยสถานที่",
  i18nKey_1200: "หมายเลขสินทรัพย์สถานที่",
  i18nKey_1201: "สถานะสถานที่",
  i18nKey_1202: "ข้อมูลการดำเนินงานของสถานที่",
  i18nKey_1203: "ระยะเวลาการใช้งานสถานที่",
  i18nKey_1204: "ที่ตั้งสถานที่",
  i18nKey_1205: "โทโพโลยีสถานที่",
  i18nKey_1206: "สถิติสถานที่",
  i18nKey_1207: "สถิติจำนวนสถานที่",
  i18nKey_1208: "ดูตัวอย่างข้อมูลสถานที่",
  i18nKey_1209: "การประสานข้อมูลสถานที่",
  i18nKey_1210: "การส่งออกข้อมูลสถานที่",
  i18nKey_1211: "สถิติเหตุการณ์สถานที่",
  i18nKey_1212: "สถิติการแจ้งเตือนเหตุการณ์สถานที่ (ตามประเภท)",
  i18nKey_1213: "สถิติการแจ้งเตือนเหตุการณ์สถานที่ (ตามระดับ)",
  i18nKey_1214: "สถิติของอัตราการจัดการสัญญาณเตือนเหตุการณ์สถานที่",
  i18nKey_1215: "การแจ้งเตือนเหตุการณ์สถานที่",
  i18nKey_1216: "เหตุการณ์สถานที่",
  i18nKey_1217: "ข้อมูลแผนผังสถานที่",
  i18nKey_1218: "แผนภาพสถานที่",
  i18nKey_1219: "ความยาวของชื่อสถานที่ต้องไม่เกิน 50 อักษร",
  i18nKey_1220: "ชื่อสถานที่ต้องไม่ว่างเปล่า",
  i18nKey_1221: "ชื่อสถานที่",
  i18nKey_1222:
    "คำอธิบายสถานที่ประกอบด้วยภาษาจีนและอักษรอังกฤษ ตัวเลขและ _ เท่านั้น",
  i18nKey_1223: "ความยาวของคำอธิบายสถานที่ต้องไม่เกิน 200 อักษร",
  i18nKey_1224: "คำอธิบายสถานที่",
  i18nKey_1225: "สถิติความพร้อมใช้งานของสถานที่",
  i18nKey_1226: "ความพร้อมของสถานที่",
  i18nKey_1227: "โครงสร้างพื้นฐานข้อมูลข้อมูลพื้นฐาน",
  i18nKey_1228: "การเข้ารหัสข้อมูลพื้นฐานของสถานที่",
  i18nKey_1229: "สถานะภาพสถานที่",
  i18nKey_1230: "ข้อมูลภาพสถานที่",
  i18nKey_1231: "เมื่อเปิดใช้งานภาพสถานที่ ชื่อจะต้องไม่ว่างเปล่า",
  i18nKey_1232: "ชื่อภาพสถานที่",
  i18nKey_1233: "ภาพสถานที่",
  i18nKey_1234: "จำนวนความล้มเหลวของสถานที่",
  i18nKey_1235: "ใบสั่งงานสถานที่",
  i18nKey_1236: "สถิติการเตือนภัยสถานที่",
  i18nKey_1237: "นามแฝงของสถานที่",
  i18nKey_1238: "ภาพรวมของการซ่อมแซมสถานที่",
  i18nKey_1239: "ซ่อมแซมสถานที่",
  i18nKey_1240: "ความสามารถในการออกแบบ",
  i18nKey_1241: "อันดับพลังงานของอุปกรณ์",
  i18nKey_1242: "รายละเอียดการตรวจสอบความผิดปกติของอุปกรณ์",
  i18nKey_1243: "อุปกรณ์แจ้งเตือนใหม่ผิดปกติ มีพัดลมดับ!",
  i18nKey_1244: "อุปกรณ์ผิดปกติ",
  i18nKey_1245: "ข้อมูลอุปกรณ์",
  i18nKey_1246: "บันทึกการส่งอุปกรณ์",
  i18nKey_1247: "เทมเพลตการนำเข้าข้อมูลอุปกรณ์",
  i18nKey_1248: "จำนวนอุปกรณ์",
  i18nKey_1249: "การจัดเรียงอุปกรณ์",
  i18nKey_1250: "ชื่ออุปกรณ์",
  i18nKey_1251: "สุขภาพของอุปกรณ์",
  i18nKey_1252: "นามแฝงของอุปกรณ์",
  i18nKey_1253: "ชื่อข้อสังเกตอุปกรณ์",
  i18nKey_1254: "IP ของอุปกรณ์",
  i18nKey_1255: "ID อุปกรณ์",
  i18nKey_1256: "อุปกรณ์",
  i18nKey_1257: "ปริมาณการใช้ไฟฟ้าจริงของสัปดาห์ที่แล้ว (กิโลวัตต์ - ชั่วโมง)",
  i18nKey_1258: "อาทิตย์ที่แล้ว",
  i18nKey_1259: "พลังงานที่ใช้เมื่อเดือนที่แล้ว",
  i18nKey_1260: "ช่วงเดียวกันของเดือนที่แล้ว",
  i18nKey_1261: "ปริมาณการใช้ไฟฟ้าจริงของเดือนที่แล้ว (KWh)",
  i18nKey_1262: "ค่าเฉลี่ยของเดือนที่แล้ว",
  i18nKey_1263: "การใช้พลังงานต่อตารางเมตรเมื่อเดือนที่แล้ว",
  i18nKey_1264: "การใช้พลังงานสะสมเมื่อเดือนที่แล้ว",
  i18nKey_1265: "เดือนที่แล้ว",
  i18nKey_1266: "ขยับขึ้น",
  i18nKey_1267: "พลังงานที่ใช้ในปีที่แล้ว",
  i18nKey_1268: "ปริมาณการใช้ไฟฟ้าในช่วงเดียวกันของปีก่อน",
  i18nKey_1269: "ช่วงเดียวกันของปีที่แล้ว",
  i18nKey_1270: "การใช้พลังงานต่อตารางเมตรเมื่อปีที่แล้ว",
  i18nKey_1271: "ปีที่แล้ว",
  i18nKey_1272: "ทิ้งลงไป",
  i18nKey_1273: "ตอนเช้า",
  i18nKey_1274: "ลุกขึ้น",
  i18nKey_1275: "วางบนหิ้ง",
  i18nKey_1276: "ขึ้น",
  i18nKey_1277: "Shanghai Jinqiao Wanchuang Center System 2",
  i18nKey_1278: "Shanghai Jinqiao Wanchuang Center System 1",
  i18nKey_1279: "ตำแหน่งสุดท้าย",
  i18nKey_1280: "เวลาที่รายงานล่าสุด",
  i18nKey_1281: "วันที่อ่านมิเตอร์ล่าสุด",
  i18nKey_1282: "การอ่านมิเตอร์ล่าสุด",
  i18nKey_1283: "อัปโหลดไฟล์ไม่สำเร็จ",
  i18nKey_1284: "รูปแบบภาพที่อัปโหลดไม่ถูกต้อง โปรดอัปโหลดอีกครั้ง",
  i18nKey_1285: "การอัปโหลดรูปภาพต้องมีขนาดไม่เกิน 3M โปรดอัปโหลดใหม่",
  i18nKey_1286: "อัปโหลดภาพ",
  i18nKey_1287: "อัปโหลดไดอะแกรม",
  i18nKey_1288: "อัปโหลดไม่สำเร็จ โปรดอัปโหลดอีกครั้ง",
  i18nKey_1289: "การอัพโหลดล้มเหลว",
  i18nKey_1290: "ผู้อัปโหลด",
  i18nKey_1291: "อัปโหลดรูปภาพ",
  i18nKey_1292: "อัปโหลดแบบฟอร์มข้อมูลที่อยู่อาศัย",
  i18nKey_1293:
    "ชื่อของรูปภาพที่อัปโหลดไม่เป็นมาตรฐาน อนุญาตให้ใช้เฉพาะตัวเลข ตัวอักษร ขีดล่างและเส้นแนวนอนเท่านั้น",
  i18nKey_1294: "เวลาในการรายงาน",
  i18nKey_1295: "โทรศัพท์ผู้สื่อข่าว",
  i18nKey_1296: "ผู้สื่อข่าว",
  i18nKey_1297: "การวิเคราะห์การใช้พลังงานของผู้เช่าเชิงพาณิชย์",
  i18nKey_1298: "เป้าหมายธุรกิจ",
  i18nKey_1299: "ธุรกิจ",
  i18nKey_1300: "สถานะร้านค้า",
  i18nKey_1301: "ที่ตั้งร้าน",
  i18nKey_1302: "เบอร์ร้าน",
  i18nKey_1303: "รายละเอียดสินค้า",
  i18nKey_1304: "ชื่อธุรกิจ:",
  i18nKey_1305: "การจราจรในห้างสรรพสินค้า",
  i18nKey_1306: "การเลือกผู้ค้า / ผู้เช่า",
  i18nKey_1307: "ชื่อธุรกิจ / ผู้เช่า ",
  i18nKey_1308: "การจัดอันดับการใช้พลังงานต่อตารางเมตรของธุรกิจผู้เช่า/ธุรกิจ",
  i18nKey_1309: "ต้องปิดราคาก่อนจึงจะลบข้อมูลได้!",
  i18nKey_1310: "ในการลบข้อมูลคุณต้องหยุดสถานีก่อน!",
  i18nKey_1311:
    "รายละเอียดทั้งหมดของอุปกรณ์จะถูกลบออกหลังจากทำการลบ คุณแน่ใจหรือว่าต้องการลบ?",
  i18nKey_1312:
    "คุณแน่ใจหรือว่าต้องการลบ? เนื้อหาภายใต้โหนดนี้จะถูกลบหลังจากการลบ ขอแนะนำให้คุณย้ายข้อมูลก่อนแล้วจึงลบ",
  i18nKey_1313: "ไม่สามารถกู้คืนได้หลังจากลบ คุณแน่ใจหรือไม่ว่าจะลบ?",
  i18nKey_1314: "ลบการเชื่อมโยงไม่สำเร็จ",
  i18nKey_1315: "หลังจากลบ thi",
  i18nKey_1316:
    "การลบสำเร็จและร้านค้าทั้งหมดภายใต้โหนดจะถูกลบออกจากรายการเช่าด้วย",
  i18nKey_1317: "ลบ",
  i18nKey_1318: "สแกนเพื่อดาวน์โหลดแอพ Yuncheng",
  i18nKey_1319: "สแกนรหัส QR",
  i18nKey_1320: "สแกนรหัสอุปกรณ์",
  i18nKey_1321: "สแกนรหัสเข้าสู่ระบบ",
  i18nKey_1322: "ประกอบไปด้วยลิเธียม",
  i18nKey_1323: "สามประเภท",
  i18nKey_1324: "แผนกตติยภูมิ",
  i18nKey_1325: "ระดับ 3",
  i18nKey_1326: "สาม",
  i18nKey_1327: "หากไม่มีหมายเลขประจำตัวต้องใช้หมายเลขหนังสือเดินทาง",
  i18nKey_1328: "ประเภทการรับเข้า",
  i18nKey_1329:
    "หากมีจุดแวะพักหลายจุดกรุณากรอกรูปแบบต่อไปนี้: Vanke Yuncheng-Bao'an Center-Qianhai Bay-Bao'an Airport",
  i18nKey_1330: "ความจุ",
  i18nKey_1331: "YoY",
  i18nKey_1332: "วัน",
  i18nKey_1333: "มีหนทางอีกยาวไกลและความทะเยอทะยานจะต้องได้รับชัยชนะ",
  i18nKey_1334: "สถิติสถานะงาน",
  i18nKey_1335: "สถานะงาน",
  i18nKey_1336:
    "กำลังติดตามงาน การข้ามไปที่หน้าอื่นจะทำให้งานการติดตามหยุดลง แน่ใจไหมว่าต้องข้ามไปที่หน้านี้",
  i18nKey_1337: "เจ้าของงาน",
  i18nKey_1338: "ผู้มอบหมายงาน",
  i18nKey_1339: "จำนวนงาน",
  i18nKey_1340: "งานกำลังเริ่มโปรดอย่าลบ",
  i18nKey_1341: "ชื่องานต้องไม่ว่างเปล่า",
  i18nKey_1342: "ชื่อภารกิจ",
  i18nKey_1343: "หมายเลขงาน",
  i18nKey_1344: "บริษัทที่ได้รับการรับรอง",
  i18nKey_1345: "ภาพรวมบุคลากร (วัน)",
  i18nKey_1346: "การติดตามบุคลากร",
  i18nKey_1347: "สถิติสัดส่วนบุคลากร (ในวันนั้น)",
  i18nKey_1348: "การป้อนชื่อภาษาอังกฤษของบุคลากรด้วยอักษรพิเศษ",
  i18nKey_1349: "บุคลากรย้ายไป",
  i18nKey_1350: "ป้อนชื่อบุคคลด้วยอักษรพิเศษ",
  i18nKey_1351: "ชื่อบุคลากรต้องไม่ว่างเปล่า",
  i18nKey_1352: "ชื่อบุคคล",
  i18nKey_1353: "ข้อมูลบุคลากร",
  i18nKey_1354: "ภาพพนักงาน",
  i18nKey_1355: "สถิติบุคลากร (โดยสวนสาธารณะ)",
  i18nKey_1356: "สถิติบุคลากร (ตามประเภท)",
  i18nKey_1357: "สถิติผู้คน",
  i18nKey_1358: "การจัดอันดับบุคลากร (เมื่อวาน / วันก่อนหน้า)",
  i18nKey_1359: "ต้องระบุหมายเลขโทรศัพท์มือถือของบุคลากร",
  i18nKey_1360: "อุณหภูมิของพนักงาน",
  i18nKey_1361: "ประเภทบุคคล",
  i18nKey_1362: "การเลือกกลุ่มบุคลากร",
  i18nKey_1363: "ข้อมูลรายละเอียดพื้นฐานของบุคลากร",
  i18nKey_1364: "สร้างข้อมูลพื้นฐานของบุคลากรสำเร็จแล้ว",
  i18nKey_1365: "การเข้ารหัสข้อมูลพื้นฐานของบุคลากร",
  i18nKey_1366:
    "มีตักอักษรพิเศษในการป้อนหมายเลขข้อมูลพื้นฐานของบุคลากร กรุณากรอกใหม่",
  i18nKey_1367: "หมายเลขข้อมูลพื้นฐานของบุคลากร",
  i18nKey_1368: "ติดตามผู้คน",
  i18nKey_1369: "การบริหารงานบุคคล",
  i18nKey_1370: "แนวโน้มการเปรียบเทียบบุคลากร (ใกล้สองสัปดาห์)",
  i18nKey_1371: "แท็กบุคคล",
  i18nKey_1372: "รหัสบุคคล",
  i18nKey_1373: "บุคลากร (12 สัปดาห์ล่าสุด)",
  i18nKey_1374: "บุคลากร (12 เดือนล่าสุด)",
  i18nKey_1375: "ภาพรวมของผู้คน",
  i18nKey_1376: "สถิติการหลั่งไหลของผู้คน",
  i18nKey_1377: "เริ่มการติดตามใบหน้าไม่สำเร็จ",
  i18nKey_1378: "การติดตามใบหน้า",
  i18nKey_1379: "ใบหน้าผ่าน",
  i18nKey_1380: "Face ID",
  i18nKey_1381: "ใบหน้าของมนุษย์",
  i18nKey_1382: "ครั้งบุคคล",
  i18nKey_1383: "คน",
  i18nKey_1384: "การจัดอันดับจำนวนผู้โดยสารทั้งหมดในพื้นที่ยอดนิยม",
  i18nKey_1385: "ช่วงการแพร่กระจายความร้อน",
  i18nKey_1386: "จุดร้อน",
  i18nKey_1387: "ยืนยันการเปลี่ยนแปลง",
  i18nKey_1388: "ยืนยันรหัสผ่านใหม่",
  i18nKey_1389: "ยืนยันเพื่อเพิ่มผู้ใช้",
  i18nKey_1390: "ยืนยันที่จะยอมแพ้?",
  i18nKey_1391:
    "ยืนยันว่าสัญญาณเตือนนี้เป็นการพิจารณาที่ไม่ถูกต้อง ข้อมูลการเตือนนี้ไม่ถูกต้อง",
  i18nKey_1392: "แน่ใจหรือว่าจะรีเซ็ตบ้านหลังนี้",
  i18nKey_1393: "คุณแน่ใจหรือที่จะรีเซ็ตด้วยคลิกเดียว",
  i18nKey_1394: "แน่ใจหรือว่าต้องการตั้งพื้นหลัก",
  i18nKey_1395: "แน่ใจไหมว่าต้องการลบที่อยู่นี้",
  i18nKey_1396: "แน่ใจไหมว่าต้องการเปิดวงจร $ ทั้งหมด",
  i18nKey_1397: "แน่ใจไหมว่าต้องการปิดวงจร $ ทั้งหมด",
  i18nKey_1398: "แน่ใจไหมว่าต้องการเปิดลูป $ นี้",
  i18nKey_1399: "แน่ใจไหมว่าต้องการปิดลูป $ เหล่านี้",
  i18nKey_1400: "แน่ใจหรือไม่ว่าจะลบอุปกรณ์",
  i18nKey_1401: "ลบผู้ใช้นี้แน่นอน",
  i18nKey_1402: "แน่ใจไหมว่าจะลบห้อง",
  i18nKey_1403: "คุณแน่ใจหรือไม่ว่าจะลบอุปกรณ์จำนวนมากเหล่านี้",
  i18nKey_1404: "คุณแน่ใจหรือไม่ว่าจะลบประเภทอพาร์ทเมนต์จำนวนมากเหล่านี้",
  i18nKey_1405: "คุณแน่ใจหรือไม่ว่าจะลบบัญชีดำ",
  i18nKey_1406: "ตรวจสอบว่าสัญญาณเตือนนี้เป็นผลบวกลวง",
  i18nKey_1407: "ตลอดทั้งวัน (24 ชั่วโมง)",
  i18nKey_1408: "เล่นแบบเต็มหน้าจอ",
  i18nKey_1409: "สวิตช์ควบคุมแบบเต็ม",
  i18nKey_1410: "เปิดทั้งหมด",
  i18nKey_1411: "ห้องประชุมทั้งหมด",
  i18nKey_1412: "ปิดทั้งหมด",
  i18nKey_1413: "เดือนที่รับผิดชอบต้องไม่ว่างเปล่า",
  i18nKey_1414: "เดือนแห่งอำนาจ",
  i18nKey_1415: "การอนุญาตของทรัพยากร",
  i18nKey_1416: "เวลาเดียวกันของปีที่แล้ว",
  i18nKey_1417: "ขีดจำกัดการขอคืนเงินต้องไม่เกิน 100%!",
  i18nKey_1418: "กฎการยกเลิกไม่เกิน 10 ข้อ!",
  i18nKey_1419: "ความร้อนในภูมิภาค",
  i18nKey_1420: "ชื่อพื้นที่",
  i18nKey_1421: "การจัดอันดับการใช้พลังงานในภูมิภาคต่อตารางเมตร",
  i18nKey_1422: "ประเภทพื้นที่ต้องไม่ว่างเปล่า",
  i18nKey_1423: "ประเภทพื้นที่",
  i18nKey_1424: "การหลั่งไหลของผู้โดยสารในภูมิภาค",
  i18nKey_1425: "การจัดอันดับการเยี่ยมชมระดับภูมิภาค",
  i18nKey_1426: "พื้นที่",
  i18nKey_1427: "บล็อก",
  i18nKey_1428: "โปรดเลือกกล้องอย่างน้อยหนึ่งตัว",
  i18nKey_1429: "โปรดเลือกคนอย่างน้อยหนึ่งคน",
  i18nKey_1430: "โปรดเพิ่มอย่างน้อยหนึ่งคนเพื่อบันทึก",
  i18nKey_1431: "กรุณากรอกรหัสผ่านใหม่อีกครั้ง",
  i18nKey_1432: "กรุณากรอกรหัสผ่านอีกครั้ง",
  i18nKey_1433: "โปรดเลือกช่วงวันที่เช่า",
  i18nKey_1434: "โปรดเลือกประเภทเนื้อหา",
  i18nKey_1435: "โปรดเลือกธีม",
  i18nKey_1436: "โปรดเลือกระดับที่สำคัญ",
  i18nKey_1437: "โปรดเลือกวิธีการทำซ้ำ",
  i18nKey_1438: "โปรดเลือกโหมดแสงสว่าง",
  i18nKey_1439: "โปรดเลือกผู้จัดการที่รับผิดชอบ",
  i18nKey_1440: "โปรดเลือกเวลาที่จะระงับ",
  i18nKey_1441: "โปรดเลือกช่วงเวลาการเยี่ยมชมที่อนุญาต",
  i18nKey_1442: "โปรดเลือกเวลาการเริ่มต้นการจอง",
  i18nKey_1443: "โปรดเลือกเวลาสิ้นสุดของการจอง",
  i18nKey_1444: "โปรดเลือกกลยุทธ์พิเศษ",
  i18nKey_1445: "กรุณาเลือกระบบการสมัคร",
  i18nKey_1446: "โปรดเลือกเวลาเริ่มต้นธุรกิจ",
  i18nKey_1447: "กรุณาเลือกเวลาปิดทำการ",
  i18nKey_1448: "โปรดเลือกประเภทข้อยกเว้น",
  i18nKey_1449: "โปรดเลือกเครื่องมือที่เป็นของ",
  i18nKey_1450: "โปรดเลือกยี่ห้อเครื่องมือ",
  i18nKey_1451: "โปรดเลือกหมวดหมู่",
  i18nKey_1452: "โปรดเลือกแผนก",
  i18nKey_1453: "โปรดเลือกรูปแบบ",
  i18nKey_1454: "โปรดเลือกรถที่จะทำการลบ",
  i18nKey_1455: "โปรดเลือกสถานที่ที่จะเชื่อมต่อ",
  i18nKey_1456: "โปรดเลือกไฟล์ที่จะอัปโหลด",
  i18nKey_1457: "โปรดเลือกภาพแรกของห้องประชุมที่จะอัปโหลด!",
  i18nKey_1458: "โปรดเลือกภาพแรกของสถานที่ทำงานที่จะอัปโหลด!",
  i18nKey_1459: "โปรดเลือกรูปภาพแบนเนอร์เพื่ออัปโหลด!",
  i18nKey_1460: "โปรดเลือกกลยุทธ์ที่จะลบ",
  i18nKey_1461: "โปรดเลือกความสว่างของการนอนหลับ",
  i18nKey_1462: "กรุณาเลือกเวลาสั่งซื้อ",
  i18nKey_1463: "โปรดเลือกการจัดประเภทมีให้เลือกห้าระดับ",
  i18nKey_1464: "โปรดเลือกแผนกมีให้เลือกห้าระดับ",
  i18nKey_1465: "โปรดเลือกสถานที่",
  i18nKey_1466: "โปรดเลือกเวลาหยุด",
  i18nKey_1467: "โปรดเลือกทาง",
  i18nKey_1468: "กรุณาเลือกที่จอดรถ",
  i18nKey_1469: "กรุณาเลือกประเภทแพ็กเกจ",
  i18nKey_1470: "โปรดเลือกระดับสี่",
  i18nKey_1471: "โปรดเลือกแผนกระดับสี่",
  i18nKey_1472: "กรุณาเลือกการกรอกจำนวนเงินที่ชำระจริง",
  i18nKey_1473: "กรุณาป้อนจำนวนการสั่งซื้อ",
  i18nKey_1474: "โปรดเลือกเวลาเริ่มต้นและเวลาสิ้นสุดในการให้สิทธิ์",
  i18nKey_1475: "โปรดเลือกว่าเป็นอุปกรณ์ดีบักหรือไม่",
  i18nKey_1476: "โปรดเลือกว่าจะแสดงรายการหรือไม่",
  i18nKey_1477: "โปรดเลือกสถานะเหตุการณ์",
  i18nKey_1478: "โปรดเลือกประเภทเหตุการณ์",
  i18nKey_1479: "โปรดเลือกระดับเหตุการณ์",
  i18nKey_1480: "โปรดเลือกระดับเหตุการณ์",
  i18nKey_1481: "โปรดเลือกช่วงเวลา",
  i18nKey_1482: "กรุณาเลือกเวลา",
  i18nKey_1483: "โปรดเลือกประเภทลิฟต์",
  i18nKey_1484: "กรุณาเลือกสถานะการสมัคร",
  i18nKey_1485: "โปรดเลือกกล้อง",
  i18nKey_1486: "โปรดเลือกสถานะสิ่งอำนวยความสะดวก",
  i18nKey_1487: "โปรดเลือกสถานที่ตั้ง",
  i18nKey_1488: "โปรดเลือกสถานะภาพสถานที่",
  i18nKey_1489: "โปรดเลือกชื่อภาพสถานที่",
  i18nKey_1490: "กรุณาเลือกอุปกรณ์",
  i18nKey_1491: "โปรดเลือกสถานะร้านค้า",
  i18nKey_1492: "กรุณาเลือกที่ตั้งร้าน",
  i18nKey_1493: "โปรดเลือกผู้ขาย",
  i18nKey_1494: "โปรดเลือกที่จะลบข้อมูล",
  i18nKey_1495: "โปรดเลือกเกณฑ์การกรอง",
  i18nKey_1496: "โปรดเลือกอุปกรณ์สแกนรหัส",
  i18nKey_1497: "โปรดเลือกการจัดประเภทระดับสาม",
  i18nKey_1498: "โปรดเลือกแผนกระดับสาม",
  i18nKey_1499: "โปรดเลือกช่วงวันที่",
  i18nKey_1500: "โปรดเลือกสถานะงาน",
  i18nKey_1501: "กรุณาเลือกประเภทบุคลากร",
  i18nKey_1502: "กรุณาเลือกบุคลากร",
  i18nKey_1503: "กรุณาเลือกเดือนที่รับผิดชอบ",
  i18nKey_1504: "โปรดเลือกประเภทพื้นที่",
  i18nKey_1505: "โปรดเลือกภูมิภาค",
  i18nKey_1506: "โปรดเลือกประเภทของผู้ขายที่ค้างชำระ",
  i18nKey_1507: "โปรดเลือกสถานะที่เปิดใช้งาน",
  i18nKey_1508: "โปรดเลือกยี่ห้อ",
  i18nKey_1509: "โปรดเลือกเวลาจัดส่ง",
  i18nKey_1510: "โปรดเลือกช่วงอายุ",
  i18nKey_1511: "โปรดเลือกปี",
  i18nKey_1512: "กรุณาเลือกหมายเลขที่ต้องการล็อค",
  i18nKey_1513: "โปรดเลือกเส้นทาง",
  i18nKey_1514: "โปรดเลือกอาคาร",
  i18nKey_1515: "โปรดเลือกหน่วยอาคาร",
  i18nKey_1516: "โปรดเลือกอาคาร",
  i18nKey_1517: "โปรดเลือกพื้นที่ชั้น",
  i18nKey_1518: "กรุณาเลือกชั้น",
  i18nKey_1519: "โปรดเลือกการจัดประเภทระดับ ุ6",
  i18nKey_1520: "กรุณาเลือกประเภท",
  i18nKey_1521: "โปรดเลือกรหัสระบบต้นทาง",
  i18nKey_1522: "โปรดเลือกเวลาเริ่มต้น",
  i18nKey_1523: "โปรดเลือกวันที่เริ่มต้น",
  i18nKey_1524: "กรุณาเลือกเวลาจอดรถ",
  i18nKey_1525: "โปรดเลือกเวลาเข้า",
  i18nKey_1526: "โปรดเลือกเวลาสิ้นสุด",
  i18nKey_1527: "โปรดเลือกประเภทของบทบาท",
  i18nKey_1528: "โปรดเลือกประเภทบทบาท",
  i18nKey_1529: "โปรดเลือกประเภทการเรียกเก็บเงิน",
  i18nKey_1530: "โปรดเลือกระดับ",
  i18nKey_1531: "โปรดเลือกลิฟต์ขนส่ง",
  i18nKey_1532: "โปรดเลือกสถานะกิจกรรม",
  i18nKey_1533: "โปรดเลือกวงจร!",
  i18nKey_1534: "โปรดเลือกผลลัพธ์",
  i18nKey_1535: "โปรดเลือกสัญญา",
  i18nKey_1536: "โปรดเลือกประเทศ",
  i18nKey_1537: "โปรดเลือกใบหน้าที่เกี่ยวข้องกับห้องสมุด",
  i18nKey_1538: "โปรดเลือกระดับความผิดปกติ",
  i18nKey_1539: "โปรดเลือกประเภทพื้นที่สาธารณะ",
  i18nKey_1540: "โปรดเลือกสถานะสถานี",
  i18nKey_1541: "โปรดเลือกประเภทสถานที่ทำงาน!",
  i18nKey_1542: "โปรดเลือกสถานะตั๋ว",
  i18nKey_1543: "โปรดเลือกการตรวจจับความสว่าง",
  i18nKey_1544: "กรุณาเลือกประเภทของบ้าน",
  i18nKey_1545: "โปรดเลือกวิธีการชำระเงิน",
  i18nKey_1546: "โปรดเลือกแผนกย่อย",
  i18nKey_1547: "กรุณาเลือกงวดผ่อน",
  i18nKey_1548: "โปรดเลือกสถานะผู้เยี่ยมชม",
  i18nKey_1549: "โปรดเลือกตำแหน่งห้อง",
  i18nKey_1550: "โปรดเลือกช่วงเวลาสำหรับคำเชิญ",
  i18nKey_1551: "โปรดเลือกประเภทปัญหา",
  i18nKey_1552: "โปรดเลือกการจัดการประเภทรอง",
  i18nKey_1553: "โปรดเลือกแผนกมัธยมศึกษา",
  i18nKey_1554: "โปรดเลือกสถานะการสั่งซื้อ",
  i18nKey_1555: "โปรดเลือกประเภทเป้าหมาย",
  i18nKey_1556: "โปรดเลือกสถานที่",
  i18nKey_1557: "โปรดเลือกที่่จอดรถ",
  i18nKey_1558: "กรุณาเลือกภูมิภาค",
  i18nKey_1559: "โปรดเลือกเมือง",
  i18nKey_1560: "โปรดเลือกหน่วย",
  i18nKey_1561: "โปรดเลือกกลไกการจัดการ",
  i18nKey_1562: "โปรดเลือกเวลาเริ่มต้น",
  i18nKey_1563: "กรุณาเลือกเวลาจอดรถ",
  i18nKey_1564: "กรุณาเลือกประเภทการเติมเงิน",
  i18nKey_1565: "โปรดเลือกเวลาเดินทาง",
  i18nKey_1566: "โปรดเลือกเมือง",
  i18nKey_1567: "โปรดเลือกประเภทเจ้าของ",
  i18nKey_1568: "โปรดเลือกแท็กที่ติดตามรถ",
  i18nKey_1569: "โปรดเลือกชื่อคลัง",
  i18nKey_1570: "กรุณาเลือกที่จอดรถ",
  i18nKey_1571: "โปรดเลือกไอคอนฉาก",
  i18nKey_1572: "โปรดเลือกกลยุทธ์สถานการณ์",
  i18nKey_1573: "โปรดเลือกสัญลักษณ์ของฉาก",
  i18nKey_1574: "โปรดเลือกผลิตภัณฑ์",
  i18nKey_1575: "โปรดเลือกกลยุทธ์ที่เกี่ยวข้องกับการจอดรถ",
  i18nKey_1576: "โปรดเลือกกลยุทธ์ที่เกี่ยวข้องไฟถนน",
  i18nKey_1577: "โปรดเลือกสถานะการปรับใช้",
  i18nKey_1578: "โปรดเลือกกล้องสำหรับการปรับใช้",
  i18nKey_1579: "โปรดเลือกประเภทการปรับใช้",
  i18nKey_1580: "โปรดเลือกอย่างน้อย 1 ใบ",
  i18nKey_1581: "โปรดเลือกบัตรไม่เกิน $",
  i18nKey_1582: "โปรดเลือกไม่เกิน",
  i18nKey_1583: "กรุณาเลือกตารางเดินรถ",
  i18nKey_1584: "กรุณาเลือกเวลาในการติดตั้ง",
  i18nKey_1585: "โปรดเลือกประเภทพิกัด GIS",
  i18nKey_1586: "โปรดเลือก",
  i18nKey_1587: "โปรดเลือกชื่อห้องประชุมก่อน",
  i18nKey_1588: "โปรดเลือกข้อมูลที่เกี่ยวข้องก่อน",
  i18nKey_1589: "โปรดบันทึกการเปลี่ยนแปลงก่อน",
  i18nKey_1590: "อย่าเพิ่มคนซ้ำ ๆ",
  i18nKey_1591: "อย่าเติมซ้ำ ๆ",
  i18nKey_1592: "ห้ามใส่อักษรพิเศษ!",
  i18nKey_1593: "ห้ามใส่อักษรพิเศษ",
  i18nKey_1594: "ไม่เกิน 6",
  i18nKey_1595: "กรุณากรอกข้อมูลอุปกรณ์",
  i18nKey_1596: "กรุณากรอกข้อมูลในช่องที่จำเป็น",
  i18nKey_1597: "กรุณากรอกรายละเอียดกิจกรรม",
  i18nKey_1598: "โปรดกรอก บริษัท ที่ได้รับการรับรอง",
  i18nKey_1599: "กรุณากรอกหมายเลขป้ายทะเบียน",
  i18nKey_1600: "กรุณากรอกสีรถ",
  i18nKey_1601: "กรุณากรอกยี่ห้อรถ",
  i18nKey_1602: "กรุณากรอกชื่อกลยุทธ์",
  i18nKey_1603: "โปรดเพิ่ม บริษัท ที่เกี่ยวข้อง!",
  i18nKey_1604: "โปรดเพิ่มแท็กบุคคล",
  i18nKey_1605: "กรุณาเพิ่มเส้นทาง",
  i18nKey_1606: "โปรดค้นหาหรือเลือก บริษัท",
  i18nKey_1607: "กรุณากรอกจำนวนการเตือนขั้นต่ำ",
  i18nKey_1608: "กรุณากรอกค่าเช่าขั้นต่ำ",
  i18nKey_1609: "กรุณากรอกจำนวนการเตือนสูงสุด",
  i18nKey_1610:
    "กรุณากรอกอักษรไม่เกิน 2 ~ 50 ตัว อักษรจีนหนึ่งตัวนับเป็นสองอักษร",
  i18nKey_1611:
    "กรุณากรอกอักษรไม่เกิน 2 ~ 10 ตัวอักษรจีนหนึ่งตัวนับเป็นสองอักษร",
  i18nKey_1612:
    "กรุณากรอกอักษรไม่เกิน 1 ~ 50 ตัวอักษรจีนหนึ่งตัวนับเป็นสองอักษรและไม่อนุญาตให้เว้นวรรค!",
  i18nKey_1613: "กรุณากรอกค่าแอมแปร์สวิตช์ทั้งหมด",
  i18nKey_1614: "กรุณากรอกการกำหนดเวลารีเฟรช QR โค้ด",
  i18nKey_1615: "กรุณากรอกคำอธิบายเนื้อหา",
  i18nKey_1616: "กรุณากรอกหมายเลขสินทรัพย์",
  i18nKey_1617: "กรุณาใส่หมายเลขโทรศัพท์เพื่อขอคำปรึกษา",
  i18nKey_1618: "กรุณาเข้าสู่เทอร์มินัล",
  i18nKey_1619: "กรุณาเข้าสู่รอบการออกคำสั่ง",
  i18nKey_1620: "กรุณากรอกชื่อสาขา",
  i18nKey_1621: "กรุณากรอกหมายเลขคำสั่งชำระเงิน",
  i18nKey_1622: "กรุณากรอกรายจ่าย / รายรับ",
  i18nKey_1623: "กรุณากรอกจำนวนเต็มบวก",
  i18nKey_1624: "กรุณากรอกจำนวนบวก (ทศนิยมไม่เกินสองตำแหน่ง)!",
  i18nKey_1625: "กรุณากรอกหมายเลขโทรศัพท์มือถือรูปแบบที่ถูกต้องเพื่อค้นหา",
  i18nKey_1626: "กรุณากรอกหมายเลขโทรศัพท์ในรูปแบบที่ถูกต้อง",
  i18nKey_1627: "กรุณากรอกรหัสยืนยันที่ถูกต้อง",
  i18nKey_1628: "กรุณากรอกชื่อและหมายเลขโทรศัพท์ที่ถูกต้อง",
  i18nKey_1629: "กรุณากรอกหมายเลขโทรศัพท์ที่ถูกต้อง",
  i18nKey_1630: "กรุณากรอกหมายเลขโทรศัพท์ที่ถูกต้อง",
  i18nKey_1631: "กรุณากรอกหมายเลขประจำตัวที่ถูกต้อง",
  i18nKey_1632: "กรุณากรอกหมายเลขหนังสือเดินทางที่ถูกต้อง",
  i18nKey_1633: "กรุณากรอกหมายเลขโทรศัพท์ที่ถูกต้อง",
  i18nKey_1634: "กรุณากรอกจำนวนครั้งที่ถูกต้อง",
  i18nKey_1635:
    "กรุณากรอกหมายเลขป้ายทะเบียนหรือหมายเลขโทรศัพท์มือถือที่ถูกต้อง",
  i18nKey_1636: "กรุณากรอกหมายเลขป้ายทะเบียนที่ถูกต้อง",
  i18nKey_1637: "กรุณากรอกชื่อข้อสังเกตที่ถูกต้อง",
  i18nKey_1638: "กรุณาใส่เบอร์โทรศัพท์สำนักงานที่ถูกต้อง",
  i18nKey_1639: "กรุณากรอกบัญชีหรือหมายเลขโทรศัพท์มือถือ",
  i18nKey_1640: "กรุณากรอกเปอร์เซ็นต์",
  i18nKey_1641: "กรุณากรอกรหัสผ่านเดิม",
  i18nKey_1642: "กรุณากรอกชื่อที่ี่จอดรถ",
  i18nKey_1643: "กรุณากรอกบัญชีโดเมน",
  i18nKey_1644: "กรุณากรอกค่าคำเตือน",
  i18nKey_1645: "กรุณากรอกเวลาเริ่มต้นที่กำหนดไว้",
  i18nKey_1646: "กรุณากรอกชื่อผู้จอง",
  i18nKey_1647: "กรุณากรอกยี่ห้อเครื่องมือ",
  i18nKey_1648: "กรุณากรอกชื่อมิเตอร์",
  i18nKey_1649: "กรุณากรอกหมายเลขมิเตอร์ ชื่ออุปกรณ์ ชื่อธุรกิจ / ชื่อผู้เช่า",
  i18nKey_1650: "กรุณากรอกรหัสมิเตอร์ / เลขที่ห้อง / ชั้น",
  i18nKey_1651: "กรุณากรอกรหัสมิเตอร์ / หมายเลข / ชื่อ",
  i18nKey_1652: "กรุณากรอกหมายเลขมิเตอร์ / หน่วยอาคาร",
  i18nKey_1653: "กรุณากรอกหมายเลขมิเตอร์",
  i18nKey_1654: "กรุณากรอกชื่อธุรกิจ",
  i18nKey_1655: "กรุณากรอกรหัสยืนยัน",
  i18nKey_1656: "กรุณากรอกคำอธิบายเส้นทางตรวจตรา",
  i18nKey_1657: "กรุณากรอกเวลาตรวจตรา",
  i18nKey_1658: "กรุณาใส่ผู้ตรวจตรา",
  i18nKey_1659: "กรุณากรอกคำอธิบายเส้นทางตรวจตรา",
  i18nKey_1660: "กรุณากรอกชื่อเส้นทางตรวจตรา",
  i18nKey_1661: "กรุณากรอกจำนวนวันที่จะนับ",
  i18nKey_1662: "กรุณากรอกจำนวนวันทั้งหมดที่จะตรวจสอบ",
  i18nKey_1663: "กรุณากรอกรหัสผ่านใหม่",
  i18nKey_1664: "กรุณากรอกตัวเลขที่น้อยกว่า 10",
  i18nKey_1665: "กรุณากรอกราคาที่น้อยกว่า 1 ~ 20000",
  i18nKey_1666: "กรุณากรอกชั่วโมง / วัน",
  i18nKey_1667: "กรุณากรอกชื่อเขตเพลิงไหม้",
  i18nKey_1668:
    "กรุณากรอกที่อยู่โดยละเอียด (ให้ถูกต้องตามหมายเลขจังหวัด เมืองและอำเภอ)",
  i18nKey_1669: "กรุณากรอกที่อยู่โดยละเอียด",
  i18nKey_1670: "กรุณากรอกราคาที่แสดง",
  i18nKey_1671: "กรุณากรอกจำนวนการละเมิด",
  i18nKey_1672: "กรุณากรอกชื่อนามสกุลหรือหมายเลขโทรศัพท์ของคุณ",
  i18nKey_1673: "กรุณากรอกชื่อสถานที่ภายนอก",
  i18nKey_1674: "กรุณากรอกชื่อที่จอดรถ",
  i18nKey_1675: "กรุณากรอกที่อยู่ที่จอดรถ",
  i18nKey_1676: "กรุณากรอกจำนวนแพ็คเกจ",
  i18nKey_1677: "กรุณากรอกจำนวนครั้งที่ใช้แพ็กเกจ",
  i18nKey_1678: "กรุณากรอกชื่อแพ็กเกจ",
  i18nKey_1679: "กรุณาเข้าสู่ บริษัท",
  i18nKey_1680: "กรุณาใส่คำค้นหา",
  i18nKey_1681: "กรุณากรอกค่าตัวเลข",
  i18nKey_1682: "กรุณากรอกตัวเลข",
  i18nKey_1683: "กรุณากรอกจำนวน",
  i18nKey_1684: "กรุณากรอก บริษัท ที่สัมภาษณ์",
  i18nKey_1685: "กรุณากรอกที่อยู่ที่สัมภาษณ์",
  i18nKey_1686: "กรุณากรอกโทรศัพท์เพื่อค้นหาคู่",
  i18nKey_1687: "กรุณากรอกหมายเลขโทรศัพท์ของคุณเพื่อค้นหา",
  i18nKey_1688: "กรุณากรอกหมายเลขโทรศัพท์มือถือของคุณเพื่อค้นหา",
  i18nKey_1689: "กรุณากรอกหมายเลขโทรศัพท์มือถือหรือบัญชีโดเมนของคุณ",
  i18nKey_1690: "กรุณากรอกหมายเลขโทรศัพท์",
  i18nKey_1691: "กรุณากรอกชื่อบัญชีเครดิต",
  i18nKey_1692: "กรุณากรอกหมายเลขโทรศัพท์มือถือของคุณ",
  i18nKey_1693: "กรุณากรอกคำแนะนำการเรียกเก็บเงิน",
  i18nKey_1694: "กรุณากรอกหมายเลขเหตุการณ์",
  i18nKey_1695: "กรุณากรอกนามแฝงสถานที่",
  i18nKey_1696: "กรุณากรอกหมายเลขสถานที่",
  i18nKey_1697: "กรุณากรอกนามแฝงอุปกรณ์",
  i18nKey_1698: "กรุณากรอกหมายเลขโทรศัพท์ของผู้สื่อข่าว",
  i18nKey_1699: "กรุณาชื่อนักข่าว",
  i18nKey_1700: "กรุณาใส่หมายเลขร้านค้า",
  i18nKey_1701: "กรุณากรอกชื่อธุรกิจ",
  i18nKey_1702: "กรุณากรอกชื่อธุรกิจ / ชื่อผู้เช่า",
  i18nKey_1703: "กรุณากรอกความจุต้องเป็นจำนวนเต็ม",
  i18nKey_1704: "กรุณากรอกความจุ",
  i18nKey_1705: "กรุณากรอกเจ้าของงาน",
  i18nKey_1706: "กรุณากรอกผู้รับมอบหมายงาน",
  i18nKey_1707: "กรุณากรอกชื่องาน",
  i18nKey_1708: "กรุณากรอกคำอธิบายงาน",
  i18nKey_1709: "กรุณากรอกชื่องานหรือบุคคล",
  i18nKey_1710: "กรุณากรอกหมายเลขงาน",
  i18nKey_1711: "กรุณากรอกหมายเลขข้อมูลพื้นฐานของบุคลากร",
  i18nKey_1712: "กรุณากรอกช่วงความร้อน",
  i18nKey_1713: "กรุณากรอกข้อมูลที่ต้องการบล็อก",
  i18nKey_1714: "กรุณากรอกจุดเริ่มต้น",
  i18nKey_1715: "กรุณากรอกชื่อจีนของ บริษัท",
  i18nKey_1716: "กรุณากรอกคำต่อท้ายอีเมลธุรกิจ",
  i18nKey_1717: "กรุณาเข้าสู่เว็บไซต์ของ บริษัท",
  i18nKey_1718: "กรุณากรอกชื่อ บริษัท",
  i18nKey_1719: "กรุณากรอกหมายเลขโทรศัพท์ของ บริษัท",
  i18nKey_1720: "กรุณากรอกข้อมูลแนะนำ บริษัท",
  i18nKey_1721: "กรุณากรอกตั๋ว / ราคาต่อหน่วย",
  i18nKey_1722: "กรุณากรอกหมายเลขแบทช์",
  i18nKey_1723: "กรุณากรอกชื่อเล่นหรือค้นหาหมายเลขโทรศัพท์",
  i18nKey_1724: "กรุณากรอกชื่อเล่น",
  i18nKey_1725: "กรุณาใส่เนื้อหา",
  i18nKey_1726: "กรุณากรอก ID ห้องประชุมของแพลตฟอร์ม Morgan",
  i18nKey_1727: "กรุณากรอกชื่อรูปแบบ",
  i18nKey_1728: "กรุณาใส่พื้นที่ให้เป็นจำนวนเต็ม",
  i18nKey_1729: "กรุณาใส่พื้นที่",
  i18nKey_1730: "กรุณาใส่รหัสผ่าน",
  i18nKey_1731: "กรุณากรอกชื่อประตู",
  i18nKey_1732:
    "กรุณากรอกระยะเวลาการเข้าพักที่มีประสิทธิผลเพื่อวิเคราะห์ต่อวัน",
  i18nKey_1733: "กรุณากรอกคำอธิบายเส้นทาง",
  i18nKey_1734: "กรุณากรอกชื่อหรือที่อยู่ไฟถนน",
  i18nKey_1735: "กรุณากรอกช่วงระยะ",
  i18nKey_1736: "กรุณาพิมพ์หมายเลขโทรศัพท์ของคุณ",
  i18nKey_1737: "กรุณากรอกเวลาเช่าสะสม",
  i18nKey_1738: "กรุณากรอกชื่อพื้นที่ / หมายเลขสถานีงาน",
  i18nKey_1739: "กรุณากรอกชื่อผู้ที่จองได้",
  i18nKey_1740: "กรุณากรอกช่วงระยะทาง",
  i18nKey_1741: "กรุณากรอกรหัสผ่านเดิม",
  i18nKey_1742: "กรุณากรอกจำนวนเงิน",
  i18nKey_1743: "กรุณากรอกชื่อ / หมายเลขโทรศัพท์ของโฮสต์",
  i18nKey_1744: "กรุณากรอกชื่อบทบาท",
  i18nKey_1745: "กรุณากรอกคำอธิบายบทบาท",
  i18nKey_1746: "กรุณากรอกจำนวนรางวัล",
  i18nKey_1747: "กรุณากรอกรางวัล / หมายเลขบัญชี",
  i18nKey_1748: "กรุณากรอกชื่ออาคาร",
  i18nKey_1749: "กรุณากรอกเส้นทางง่ายๆ",
  i18nKey_1750: "กรุณากรอกราคา",
  i18nKey_1751: "กรุณากรอกชื่อมิเตอร์",
  i18nKey_1752: "กรุณากรอกหมายเลขมิเตอร์",
  i18nKey_1753: "กรุณากรอกชื่อแผน",
  i18nKey_1754: "กรุณากรอกชื่อประเภทเป้าหมาย",
  i18nKey_1755: "กรุณากรอกค่าของคะแนน",
  i18nKey_1756: "กรุณากรอกชื่อกิจกรรม",
  i18nKey_1757: "กรุณากรอกเหตุการณ์",
  i18nKey_1758: "กรุณากรอกชื่อห้องประชุม / บริษัท ที่จอง / สถานะการประชุม",
  i18nKey_1759: "กรุณากรอกชื่อห้องประชุม",
  i18nKey_1760: "กรุณากรอกรายละเอียดของห้องประชุม",
  i18nKey_1761: "กรุณากรอกชื่อบ้าน",
  i18nKey_1762: "กรุณากรอกจำนวนคน",
  i18nKey_1763:
    "กรุณากรอกที่อยู่ MAC ที่ถูกต้องเช่น 00-01-6c-06-A6-29 หรือ 00: 01: 6c: 06: A6: 29",
  i18nKey_1764: "กรุณากรอกชื่อผู้ดูแลระบบ",
  i18nKey_1765: "กรุณากรอกชื่อพื้นที่สาธารณะ",
  i18nKey_1766: "กรุณากรอกหมายเลขโทรศัพท์ของเจ้าหน้าที่",
  i18nKey_1767: "กรุณากรอกชื่อพนักงาน",
  i18nKey_1768: "กรุณากรอกจำนวนสถานี",
  i18nKey_1769: "กรุณากรอกโปรไฟล์",
  i18nKey_1770: "กรุณากรอกราคา",
  i18nKey_1771: "กรุณากรอกหมายเลขทะเบียนใบอนุญาตประกอบธุรกิจ",
  i18nKey_1772: "กรุณาใส่ชื่อผู้รับผิดชอบตั๋ว",
  i18nKey_1773: "กรุณากรอกหมายเลขตั๋ว",
  i18nKey_1774: "กรุณากรอกระยะเวลาในการรักษาความสว่างหลังจากการชักนำ",
  i18nKey_1775: "กรุณากรอกจำนวนสถานที่เพิ่มเติม",
  i18nKey_1776: "กรุณากรอกชื่อของสถานที่เพิ่มเติม",
  i18nKey_1777: "กรุณากรอก IP ของเซิร์ฟเวอร์หรือชื่อโดเมน",
  i18nKey_1778: "กรุณากรอกชื่อรายการ",
  i18nKey_1779: "กรุณากรอกชื่อห้อง",
  i18nKey_1780: "กรุณากรอกรหัสบุคคลตามกฎหมาย",
  i18nKey_1781: "กรุณากรอกตัวแทนทางกฎหมาย",
  i18nKey_1782: "กรุณากรอกจำนวนที่ออก",
  i18nKey_1783: "กรุณากรอกโควต้าซึ่งต้องเป็นจำนวนเต็ม",
  i18nKey_1784: "กรุณากรอกจำนวนเงิน",
  i18nKey_1785: "กรุณากรอกจำนวนนาที",
  i18nKey_1786: "กรุณากรอกหมายเลขคำสั่งซื้อ / ชื่อช่องว่าง",
  i18nKey_1787: "กรุณากรอกหมายเลขคำสั่งซื้อ / ชื่อห้องประชุม",
  i18nKey_1788: "กรุณากรอกหมายเลขคำสั่งซื้อ",
  i18nKey_1789: "กรุณากรอกหมายเลขเคเบิลกว้าง ๆ",
  i18nKey_1790: "กรุณากรอกการค้นหาโทรศัพท์",
  i18nKey_1791: "กรุณากรอกหมายเลขมิเตอร์ ชื่ออุปกรณ์ชื่อผู้ขาย / ผู้เช่า",
  i18nKey_1792: "กรุณากรอกหมายเลขมิเตอร์",
  i18nKey_1793: "กรุณากรอกรหัสบุคคลที่สาม",
  i18nKey_1794: "กรุณากรอกที่อยู่ละติจูดและลองจิจูด",
  i18nKey_1795: "กรุณากรอกที่อยู่",
  i18nKey_1796: "กรุณากรอกรหัสผ่านเข้าสู่ระบบของบัญชีปัจจุบัน",
  i18nKey_1797: "กรุณากรอกราคาต่อหน่วย (หยวน / ชั่วโมง)",
  i18nKey_1798: "กรุณาใส่กระบวนการ",
  i18nKey_1799: "กรุณากรอกผู้เช่า",
  i18nKey_1800: "กรุณากรอกชื่อเจ้าของ",
  i18nKey_1801: "กรุณากรอกเครื่องอ่านมิเตอร์",
  i18nKey_1802: "กรุณากรอกชื่อฉาก",
  i18nKey_1803: "กรุณาใส่ชื่อฉากไม่เกินสิบตัวอักษร",
  i18nKey_1804: "กรุณากรอกชื่อกลยุทธ์",
  i18nKey_1805: "กรุณากรอกด้านล่างของแบบฟอร์ม",
  i18nKey_1806: "กรุณากรอกการขยาย",
  i18nKey_1807: "กรุณากรอกราคาต่อหน่วยครึ่งวัน (หยวน / 4 ชั่วโมง)",
  i18nKey_1808: "กรุณากรอกเปลี่ยนหมายเลข",
  i18nKey_1809: "กรุณากรอกตำแหน่งการติดตั้ง",
  i18nKey_1810: "กรุณากรอกทศนิยมระหว่าง 0-1",
  i18nKey_1811: "กรุณาใส่หมายเลขตาราง $ ชื่ออุปกรณ์ ชื่อธุรกิจ / ชื่อผู้เช่า",
  i18nKey_1812: "กรุณาใส่",
  i18nKey_1813: "โปรดอัปโหลดไฟล์ excel เวอร์ชันใหม่",
  i18nKey_1814: "โปรดอัปโหลดภาพ",
  i18nKey_1815:
    "โปรดตรวจสอบให้แน่ใจว่าคุณได้รับอนุญาตให้เข้าถึงข้อมูลนี้ การดำเนินการทั้งหมดของคุณจะถูกบันทึกไว้ หากคุณไม่ได้ใช้งานนานกว่า 30 นาที คุณจะต้องทำการยืนยันอีกครั้ง",
  i18nKey_1816: "ขอยกเว้นรายการการเชื่อมโยง",
  i18nKey_1817: "โปรดติดต่อผู้ดูแลระบบเพื่อขอรับสิทธิ์บริการที่เกี่ยวข้อง",
  i18nKey_1818:
    "กรุณากรอกรหัสไอที รหัสธุรกิจและชื่อระดับพื้นที่ของระดับปัจจุบันให้ครบถ้วน!",
  i18nKey_1819: "โปรดตรวจสอบลิงก์ในแถบที่อยู่และไปเข้าชมครั้ง",
  i18nKey_1820: "กรุณาเข้าสู่ระบบบัญชีของคุณ",
  i18nKey_1821: "กรุณาเข้าสู่ระบบ",
  i18nKey_1822: "โปรดรวมสูตรเดียวกัน",
  i18nKey_1823: "ชัดเจน",
  i18nKey_1824: "มลพิษทางแสง",
  i18nKey_1825: "สีฟ้า",
  i18nKey_1826: "เปลี่ยนเป็นมุมมองแบบเต็ม",
  i18nKey_1827: "เปลี่ยนเป็นมาตราส่วนเริ่มต้น",
  i18nKey_1828: "สลับรูปภาพ",
  i18nKey_1829: "สลับรายการ",
  i18nKey_1830: "พ่อค้าที่ค้างชำระ",
  i18nKey_1831: "วันก่อน",
  i18nKey_1832: "ไปที่หน้าเข้าสู่ระบบ IOC",
  i18nKey_1833: "เวลาเริ่มต้นต้องไม่มากกว่าหรือเท่ากับเวลาสิ้นสุด",
  i18nKey_1834: "ระยะเวลาเริ่มต้นหรือสิ้นสุดต้องไม่ว่างเปล่า",
  i18nKey_1835: "จำนวนเริ่มต้นหรือสิ้นสุดต้องไม่ว่างเปล่า",
  i18nKey_1836: "เวลาเริ่มต้นหรือสิ้นสุดต้องไม่ว่างเปล่า",
  i18nKey_1837: "จุดเริ่ม",
  i18nKey_1838: "หมายเลขเริ่มต้น",
  i18nKey_1839: "จุดเริ่มต้นต้องไม่ว่างเปล่า",
  i18nKey_1840: "วันที่เปิดใช้งาน",
  i18nKey_1841: "เปิดใช้งาน",
  i18nKey_1842: "ชื่อองค์กรภาษาจีน",
  i18nKey_1843: "พนักงานองค์กร",
  i18nKey_1844: "คำต่อท้ายกล่องจดหมายขององค์กร",
  i18nKey_1845: "ชื่อภาษาอังกฤษขององค์กร",
  i18nKey_1846: "ที่อยู่องค์กร",
  i18nKey_1847: "URL ขององค์กร",
  i18nKey_1848: "ผู้ค้าขององค์กร",
  i18nKey_1849: "การจัดอันดับการใช้พลังงานขององค์กร",
  i18nKey_1850: "ชื่อ บริษัท",
  i18nKey_1851: "เช็คอินธุรกิจรายวัน _",
  i18nKey_1852: "เบอร์ติดต่อ บริษัท",
  i18nKey_1853: "เบอร์ติดต่อองค์กร",
  i18nKey_1854: "ประเภทขององค์กร",
  i18nKey_1855: "ประวัติ บริษัท",
  i18nKey_1856: "บัญชีธุรกิจ",
  i18nKey_1857: "ผู้เยี่ยมชมองค์กร",
  i18nKey_1858: "องค์กร",
  i18nKey_1859: "ที่อยู่บริษัท",
  i18nKey_1860: "โลโก้องค์กร",
  i18nKey_1861: "องค์กร",
  i18nKey_1862: "การใช้พลังงานของรายการย่อย",
  i18nKey_1863: "อื่น ๆ",
  i18nKey_1864: "ไฟฟ้าอื่น ๆ",
  i18nKey_1865: "ข้อมูลอื่น ๆ",
  i18nKey_1866: "อื่น ๆ",
  i18nKey_1867: "เจ็ดหมวดหมู่",
  i18nKey_1868: "เหตุการณ์ธรรมดา",
  i18nKey_1869: "ธรรมดา",
  i18nKey_1870: "การหลั่งไหลของผู้โดยสารในวันธรรมดา",
  i18nKey_1871: "เวลาเฉลี่ยของการสนทนา",
  i18nKey_1872: "ระยะเวลาเข้าพักโดยเฉลี่ย",
  i18nKey_1873: "ค่าเฉลี่ย",
  i18nKey_1874: "เงินออมเฉลี่ยต่อวัน",
  i18nKey_1875: "จำนวนปัญหาสูงสุดต่อบริษัท",
  i18nKey_1876: "เวลาในการประมวลผลเฉลี่ย:",
  i18nKey_1877: "เวลาในการประมวลผลเฉลี่ย (นาที)",
  i18nKey_1878: "การปรับระดับ",
  i18nKey_1879:
    "แบรนด์ต้องประกอบด้วยอักษรจีนและอักษรอังกฤษตัวเลขและ _ เท่านั้น",
  i18nKey_1880: "ความยาวของตราสินค้าต้องไม่เกิน 100 อักษร",
  i18nKey_1881: "มีมากกว่า 6 ตัวเลือกของตราสินค้าและสีที่สอดคล้องที่ดีที่สุด",
  i18nKey_1882: "การวิเคราะห์แบรนด์",
  i18nKey_1883: "มีมากกว่า 6 ตัวเลือกของตราสินค้าและสีที่สอดคล้องที่ดีที่สุด",
  i18nKey_1884: "ยี่ห้อ:",
  i18nKey_1885: "ยี่ห้อ",
  i18nKey_1886: "ความถี่",
  i18nKey_1887: "ความถี่",
  i18nKey_1888: "ตั๋ว / ราคาต่อหน่วย",
  i18nKey_1889: "แบทช์แก้ไขเทมเพลตตามข้อมูลที่ส่งออก",
  i18nKey_1890: "แก้ไขแบทช์",
  i18nKey_1891: "การสร้างแบทช์",
  i18nKey_1892: "รายละเอียดการกำหนดค่า",
  i18nKey_1893: "ชื่อสวิตช์การแจกจ่าย",
  i18nKey_1894: "เวลาจำหน่าย",
  i18nKey_1895: "แจกจ่ายเรียบร้อยแล้ว",
  i18nKey_1896: "อันดับ",
  i18nKey_1897: "หญิง",
  i18nKey_1898:
    "คุณมีงานมากมายที่ต้องสอบถามโปรดรอให้งานถูกดำเนินการก่อนที่จะสอบถาม",
  i18nKey_1899:
    "คุณเปลี่ยนรหัสผ่านสำเร็จแล้วโปรดลงชื่อเข้าใช้แพลตฟอร์มอีกครั้ง",
  i18nKey_1900: "คุณต้องการระงับแพ็กเกจที่เลือกหรือไม่?",
  i18nKey_1901: "คุณต้องการระงับค่าเบี้ยเลี้ยงที่เลือกไว้หรือไม่?",
  i18nKey_1902: "คุณต้องการระงับห้องประชุมที่เลือกหรือไม่",
  i18nKey_1903: "คุณต้องการระงับสถานีที่เลือกหรือไม่",
  i18nKey_1904: "คุณต้องการระงับตารางเดินรถที่เลือกไว้หรือไม่?",
  i18nKey_1905: "คุณต้องการลบส่วนลดแบบจำกัดเวลาที่เลือกไว้หรือไม่?",
  i18nKey_1906: "คุณต้องการแสดงรายการส่วนลดเวลาจำกัดที่เลือกไว้หรือไม่?",
  i18nKey_1907: "คุณต้องการเปิดใช้งานแพ็คเกจที่เลือกหรือไม่?",
  i18nKey_1908: "คุณต้องการเปิดใช้งานค่าเบี้ยเลี้ยงที่เลือกไว้หรือไม่?",
  i18nKey_1909: "คุณต้องการเปิดใช้งานห้องประชุมที่เลือกหรือไม่",
  i18nKey_1910: "คุณต้องการเปิดใช้งานสถานีที่เลือกหรือไม่?",
  i18nKey_1911: "คุณต้องการเปิดใช้งานตารางเวลารถบัสที่เลือกไว้หรือไม่?",
  i18nKey_1912: "คุณต้องการล้างประวัติการสืบค้นทั้งหมดหรือไม่",
  i18nKey_1913: "มีการเหลื่อมในเวลาที่คุณกำหนด",
  i18nKey_1914: "แน่ใจไหมว่าต้องการดำเนินการ",
  i18nKey_1915: "แน่ใจไหมว่าต้องการปิดใช้งาน",
  i18nKey_1916: "คุณแน่ใจหรือว่าต้องการลบ?",
  i18nKey_1917: "แน่ใจไหมว่าต้องการเปิดใช้งาน",
  i18nKey_1918: "แน่ใจไหมว่าต้องการลบส่วนลดแบบจำกัดเวลา",
  i18nKey_1919: "แน่ใจไหมว่าต้องการลบแพ็กเกจ",
  i18nKey_1920: "คุณยังไม่ได้เลือกรายการข้อมูลใด ๆ !",
  i18nKey_1921: "คุณยังไม่ได้เลือกรายการข้อมูลใด ๆ",
  i18nKey_1922: "สวัสดีและยินดีต้อนรับสู่ IOC!",
  i18nKey_1923: "คุณยังไม่มีข้อมูลผู้เยี่ยมชมของบริษัท คุณสามารถทำได้",
  i18nKey_1924: "ไม่มีหน้าที่คุณการเยี่ยมชม",
  i18nKey_1925: "เบราว์เซอร์ของคุณไม่รองรับ HTML5",
  i18nKey_1926: "เกิดข้อผิดพลาดในอุปกรณ์ที่คุณเลือกในขณะนี้โปรดจัดการก่อน!",
  i18nKey_1927:
    "หน้าจอขนาดใหญ่ที่คุณเลือกในขณะนี้ออฟไลน์และไม่สามารถเข้าถึงคำสั่งได้!",
  i18nKey_1928: "ช่วงอายุ",
  i18nKey_1929: "ปี",
  i18nKey_1930: "ปี",
  i18nKey_1931: "ชื่อเล่นต้องมีความยาวไม่เกิน 12 อักษร",
  i18nKey_1932: "ชื่อเล่น",
  i18nKey_1933: "คุณได้เลือกแพ็คเกจที่จะจ่าย",
  i18nKey_1934: "คุณได้เลือกที่จะระงับการชำระเงิน",
  i18nKey_1935:
    "คุณได้ป้อน $ / $ เมื่อเทียบกับข้อมูลในช่วงก่อนหน้านี้มีข้อมูล $ ผิดปกติ ($) คุณแน่ใจหรือไม่ว่าจะส่ง",
  i18nKey_1936:
    "คุณได้นำเข้าข้อมูล $ มีข้อมูล $ ผิดปกติคุณแน่ใจหรือไม่ว่าจะส่ง",
  i18nKey_1937: "กลยุทธ์ที่คุณแก้ไข ยังไม่ได้รับการบันทึกคุณจะยอมแพ้หรือไม่?",
  i18nKey_1938:
    "แน่ใจไหมว่าต้องการลบนโยบายนี้ หลังจากลบคุณต้องควบคุมสวิตช์ไฟด้วยตนเอง",
  i18nKey_1939: "อัตราส่วนประสิทธิภาพการใช้พลังงาน COP (กิโลวัตต์ / กิโลวัตต์)",
  i18nKey_1940: "การวินิจฉัย ประสิทธิภาพการใช้พลังงาน AI",
  i18nKey_1941: "สถิติการใช้พลังงาน",
  i18nKey_1942: "การเสียพลังงาน",
  i18nKey_1943: "การตรวจสอบการใช้พลังงาน",
  i18nKey_1944: "การเปรียบเทียบการใช้พลังงาน",
  i18nKey_1945: "การใช้พลังงาน (กิโลวัตต์ชั่วโมง)",
  i18nKey_1946: "ธุรกิจภายใน",
  i18nKey_1947: "ชาย",
  i18nKey_1948: "การตั้งค่าเป้าหมาย",
  i18nKey_1949: "มูลค่าเป้าหมาย",
  i18nKey_1950: "ค่าเริ่มต้น (รีเฟรชเวลา 00:00 น. ของวันถัดไป)",
  i18nKey_1951: "ค่าเริ่มต้น",
  i18nKey_1952: "รหัสห้องประชุม Morgan Platform",
  i18nKey_1953: "ชื่อรูปแบบ",
  i18nKey_1954: "โหมด",
  i18nKey_1955: "ข้อความค้นหาที่ไม่ชัดเจน",
  i18nKey_1956: "ดาวน์โหลดเทมเพลต",
  i18nKey_1957: "รายละเอียด",
  i18nKey_1958: "ส่งใหม่ในไม่กี่วินาที",
  i18nKey_1959:
    "หลังจากนี้ไม่กี่วินาทีระบบจะข้ามไปยังหน้าล็อกอิน IOC โดยอัตโนมัติ",
  i18nKey_1960: "วินาที",
  i18nKey_1961: "คำอธิบายต้องไม่ว่างเปล่า",
  i18nKey_1962: "สัมภาษณ์",
  i18nKey_1963: "พื้นที่ (ตร.ม. )",
  i18nKey_1964: "สถานที่ฟรี",
  i18nKey_1965: "โควต้าฟรี (ชั่วโมง / เดือน)",
  i18nKey_1966: "การอนุญาตกำลังเปิดใช้และไม่อนุญาตให้แก้ไข!",
  i18nKey_1967: "สถานะรหัสผ่าน",
  i18nKey_1968: "รหัสผ่านยาว 8-20 ตัวอักษร!",
  i18nKey_1969: "รหัสผ่านต้องมีความยาว 8-20 หลักและประกอบด้วยตัวเลขและตัวอักษร",
  i18nKey_1970:
    "หากป้อนรหัสผ่านไม่ถูกต้องเกิน 5 ครั้งคุณจะออกจากระบบหลังจาก 10 วินาที",
  i18nKey_1971:
    "ความปลอดภัยของรหัสผ่านไม่ตรงกันอย่างน้อย 8 หลัก รวมถึงอย่างน้อยสามประเภทตัวต้องมีอักษรตัวพิมพ์ใหญ่และตัวพิมพ์เล็กและอักขระพิเศษ",
  i18nKey_1972: "รหัสผ่านไม่ถูกต้อง โปรดลองอีกครั้ง",
  i18nKey_1973: "รหัสผ่านต้องไม่มีช่องว่าง",
  i18nKey_1974: "รหัสผ่านต้องมี 8-20 หลักและตัวอักษร",
  i18nKey_1975: "รหัสผ่านต้องมีตัวอักษรตัวเลขและสัญลักษณ์อย่างน้อย 2 ตัว",
  i18nKey_1976: "เมตร",
  i18nKey_1977: "หมายเลขล็อคประตู",
  i18nKey_1978: "ขีด จำกัด การควบคุมการเข้าถึง",
  i18nKey_1979: "วิธีการควบคุมการเข้าถึง",
  i18nKey_1980: "ชื่อประตู",
  i18nKey_1981: "การควบคุมการเข้าถึงเครื่องอ่านรหัส QR",
  i18nKey_1982: "การควบคุมการเข้าถึง PAD",
  i18nKey_1983: "สามารถเพิ่มกฎการจัดแสงได้ถึง 6 กฎในแต่ละกลยุทธ์",
  i18nKey_1984: "การใช้พลังงานต่อตารางเมตร (t / m²)",
  i18nKey_1985: "การใช้พลังงานต่อตารางเมตร (กิโลวัตต์ชั่วโมง / ตร.ม. )",
  i18nKey_1986: "การใช้พลังงานต่อตารางเมตร",
  i18nKey_1987:
    "คุณสามารถติดตามงานได้ครั้งละหนึ่งงานเท่านั้น คุณแน่ใจหรือไม่ว่าคุณจำเป็นต้องละทิ้งงานการติดตามปัจจุบันและเริ่มงานการติดตามใหม่",
  i18nKey_1988: "ไม่มีข้อมูลโปรดเลือกสิ่งปลูกสร้างจากต้นไม้อวกาศด้านล่าง",
  i18nKey_1989: "ไม่มีข้อมูล!",
  i18nKey_1990: "ไม่มีกำหนดการประชุม",
  i18nKey_1991: "ไม่พบผู้ใช้",
  i18nKey_1992: "energy saving",
  i18nKey_1993: "เขียว",
  i18nKey_1994: "จำนวนเครื่องบินตรวจตรา",
  i18nKey_1995: "คำอธิบายเส้นทาง",
  i18nKey_1996: "รายการเส้นทาง",
  i18nKey_1997: "เส้นทาง (บทความ)",
  i18nKey_1998: "เส้นทาง",
  i18nKey_1999: "การเลือกโคมไฟถนน",
  i18nKey_2000: "การสร้างระบบอัตโนมัติ",
  i18nKey_2001: "หน่วยอาคาร",
  i18nKey_2002: "อาคาร / ชั้น / ห้อง",
  i18nKey_2003: "อาคาร",
  i18nKey_2004: "พื้นที่ชั้น",
  i18nKey_2005: "ชั้น",
  i18nKey_2006: "หกหมวดหมู่",
  i18nKey_2007: "ระดับ 6",
  i18nKey_2008: "ค่าธรรมเนียมการระงับชั่วคราว",
  i18nKey_2009: "จำนวนเงินที่ระงับชั่วคราว (หยวน)",
  i18nKey_2010: "หยุดเวลา",
  i18nKey_2011: "ที่จอดรถโปร",
  i18nKey_2012: "หยุด",
  i18nKey_2013: "ผู้เยี่ยมชมชั่วคราว",
  i18nKey_2014: "ชั่วคราว",
  i18nKey_2015: "แนว",
  i18nKey_2016: "ยานพาหนะ",
  i18nKey_2017: "การแสดงความสว่าง",
  i18nKey_2018: "การตั้งค่าความสว่าง",
  i18nKey_2019: "ความสว่าง",
  i18nKey_2020: "กฎการส่องสว่าง",
  i18nKey_2021: "รหัสผ่านสองรหัสที่ป้อนไม่สอดคล้องกัน",
  i18nKey_2022: "การชาร์จสองครั้งและการปลดปล่อยสองครั้ง",
  i18nKey_2023: "ดี",
  i18nKey_2024: "เบอร์ติดต่อ",
  i18nKey_2025: "ทาวน์เฮาส์",
  i18nKey_2026:
    "ตัวอย่าง: ที่จอดรถ Ping An XXXXXX ชั่วโมงแรก 15 หยวน (ฟรี 15 นาทีแรก) 10 หยวนต่อชั่วโมงหลังจากนั้น 60 ต่อยอด เขต Futian เมืองเซินเจิ้นมณฑลกวางตุ้ง XXXXX",
  i18nKey_2027:
    "ตัวอย่างเช่น 15 หยวนสำหรับชั่วโมงแรก (ฟรี 15 นาทีแรก) 10 หยวนต่อชั่วโมงหลังจากนั้น 60 ต่อยอด",
  i18nKey_2028: "สมัครเลย",
  i18nKey_2029: "เข้าสู่ระบบทันที",
  i18nKey_2030: "การวินิจฉัยทางประวัติศาสตร์",
  i18nKey_2031: "สถิติในอดีต",
  i18nKey_2032: "สิ้นสุดการเล่นวิดีโอย้อนหลัง",
  i18nKey_2033: "วิดีโอประวัติศาสตร์",
  i18nKey_2034: "เส้นโค้งประวัติศาสตร์",
  i18nKey_2035: "ติดตามประวัติศาสตร์",
  i18nKey_2036: "รุ่นประวัติศาสตร์",
  i18nKey_2037: "ออฟไลน์ ",
  i18nKey_2038: "ออฟไลน์",
  i18nKey_2039: "การออกจากจุดที่สบายคือก้าวแรก",
  i18nKey_2040: "อุณหภูมิเวลาอากาศเย็นออกของเครื่องทำความเย็น",
  i18nKey_2041: "ปริมาณการบริโภคสะสม",
  i18nKey_2042: "ค่าจอดรถสะสม (CNY)",
  i18nKey_2043:
    "การระงับการชำระเงินสะสมชั่วคราว $ หยวน คุณสามารถแนะนำบัตรรายเดือนให้เขา",
  i18nKey_2044: "ระยะเวลาเช่าสะสม",
  i18nKey_2045: "ค่าเช่าสะสมมักไม่ต่ำกว่าค่าเช่าขั้นต่ำ!",
  i18nKey_2046: "ยอดชำระสะสม> 400 เยน",
  i18nKey_2047: "การเข้าชมสะสม",
  i18nKey_2048: "การเข้าชมสะสม",
  i18nKey_2049:
    "ศักยภาพการประหยัดพลังงานสะสมเริ่มตั้งแต่วันที่ 1 ตุลาคม 2019 ถึงปัจจุบัน",
  i18nKey_2050: "ศักยภาพการประหยัดพลังงานสะสม (กิโลวัตต์ชั่วโมง)",
  i18nKey_2051: "ศักยภาพในการประหยัดพลังงานสะสม",
  i18nKey_2052: "ประเภทต้องไม่ว่างเปล่า",
  i18nKey_2053: "ชนิดของ",
  i18nKey_2054: "ต้องระบุหมวดหมู่",
  i18nKey_2055: "สีฟ้า",
  i18nKey_2056: "จากจังหวัด",
  i18nKey_2057: "หมายเลขอุปกรณ์ระบบต้นทาง",
  i18nKey_2058: "ระบบต้นทาง",
  i18nKey_2059: "แหล่งที่มา",
  i18nKey_2060: "เหตุผลในการเยี่ยมชม",
  i18nKey_2061:
    "เวลาเริ่มต้นของช่วงเวลาการเยี่ยมชมต้องไม่มากกว่าหรือเท่ากับเวลาสิ้นสุด",
  i18nKey_2062: "ประเภทการเยี่ยมชม",
  i18nKey_2063: "สถานะไม่ได้ใช้งาน",
  i18nKey_2064: "เครื่องปรับอากาศไฟฟ้า",
  i18nKey_2065: "สภาพอากาศน่าพอใจ โดยทั่วไปไม่มีมลพิษทางอากาศ",
  i18nKey_2066: "ตำแหน่งเชิงพื้นที่",
  i18nKey_2067: "ชื่อพื้นที่ / หมายเลขสถานีงาน",
  i18nKey_2068: "ชื่อพื้นที่",
  i18nKey_2069: "ประเภทพื้นที่",
  i18nKey_2070: "การเข้ารหัสบริการข้อมูลพื้นฐานของ Space",
  i18nKey_2071: "การปรับเปลี่ยนข้อมูลพื้นฐานเชิงพื้นที่",
  i18nKey_2072: "รายละเอียดข้อมูลพื้นฐานเชิงพื้นที่",
  i18nKey_2073: "ตำแหน่งข้อมูลพื้นฐานเชิงพื้นที่",
  i18nKey_2074: "การสร้างข้อมูลพื้นฐานเชิงพื้นที่",
  i18nKey_2075: "การเข้ารหัสข้อมูลพื้นฐานเชิงพื้นที่",
  i18nKey_2076: "ชื่อระดับพื้นที่",
  i18nKey_2077: "ลำดับชั้นเชิงพื้นที่",
  i18nKey_2078: "ห้องนั่งเล่น",
  i18nKey_2079: "จำนวนผู้โดยสารทั้งหมด (คนต่อเวลา)",
  i18nKey_2080: "อัตราส่วนเพศของการหลั่งไหลของผู้โดยสาร",
  i18nKey_2081: "ช่วงที่มีจำหน่าย",
  i18nKey_2082: "หน่วยเสริม",
  i18nKey_2083: "สามารถจองล่วงหน้าได้ตั้งแต่ 0 ถึง 30",
  i18nKey_2084: "ประเภทการปลดล็อก",
  i18nKey_2085: "เวลาเริ่มต้นและเวลาสิ้นสุดต้องห่างกันไม่น้อยกว่า 30 นาที",
  i18nKey_2086: "ความแตกต่างระหว่างเวลาเริ่มต้นและเวลาสิ้นสุดต้องไม่เกิน 7 วัน",
  i18nKey_2087: "เวลาเริ่มต้นต้องไม่ว่างเปล่า",
  i18nKey_2088: "เวลาเริ่มต้นต้องไม่มากกว่าเวลาสิ้นสุด",
  i18nKey_2089: "เวลาเริ่มต้น",
  i18nKey_2090: "เริ่มการติดตั้ง",
  i18nKey_2091: "เปิดการติดตามสำเร็จแล้ว",
  i18nKey_2092: "เปิดการติดตาม",
  i18nKey_2093: "เปิดสิ่งที่เลือก",
  i18nKey_2094: "เวลาเปิดทำการ",
  i18nKey_2095: "เปิดประตู",
  i18nKey_2096:
    "หลังจากเปิดประตูอัจฉริยะจะได้รับการอัปเดตเกี่ยวกับตามความถี่ของเวลาที่ตั้งไว้",
  i18nKey_2097: "ผ่านได้หลังจากเปิด",
  i18nKey_2098:
    "หลังจากเปิดให้เข้าชมสามารถดูบันทึกการลงทะเบียนทางประวัติศาสตร์ของตนเองได้",
  i18nKey_2099:
    "หลังจากเปิดใช้งานแล้วผู้ใช้มือถือจะสามารถใช้ฟังก์ชันนี้ได้และจะไม่แสดงเมื่อปิดอยู่",
  i18nKey_2100:
    "หลังจากเปิดใช้งานแล้วผู้ใช้อุปกรณ์เคลื่อนที่จะสามารถใช้ฟังก์ชันนี้ได้ แต่จะใช้ไม่ได้หลังจากปิดไปแล้ว",
  i18nKey_2101: "สถานะเปิดประตู",
  i18nKey_2102: "ความถี่ในการเปิดประตู",
  i18nKey_2103: "บันทึกการเปิดประตู",
  i18nKey_2104: "โหมดการประชุม",
  i18nKey_2105: "เปิด/ปิด",
  i18nKey_2106: "เปิด",
  i18nKey_2107: "การเข้ารหัสตำแหน่งเชิงพื้นที่เฉพาะ",
  i18nKey_2108: "ปฏิเสธ",
  i18nKey_2109: "ที่อยู่อาศัย",
  i18nKey_2110: "รหัสผ่านเก่า",
  i18nKey_2111: "ส่วนจำนวนเต็มของลองจิจูดคือ 0-180",
  i18nKey_2112: "ลองจิจูด",
  i18nKey_2113:
    "Beijing, Tianjin, Shanghai, Chongqing, Hebei, Henan, Yunnan, Liaoning, Heilongjiang, Hunan, Anhui, Shandong, Xinjiang, Jiangsu, Zhejiang, Jiangxi, Hubei, Guangxi, Gansu, Shanxi, Menggu, Shaanxi, Jilin, Fujian, Guangdong, Qinghai, Tibet, Sichuan, Ningxia, Hainan,",
  i18nKey_2114: "ห้ามเข้าที่จอดรถ",
  i18nKey_2115: "ข้อห้ามของบัตรรายเดือน",
  i18nKey_2116: "การปิดใช้งานล้มเหลว",
  i18nKey_2117: "จำนวนการรับสมัครในเดือนที่ผ่านมา",
  i18nKey_2118: "จำนวนผู้โดยสารเฉลี่ยในเดือนที่ผ่านมา",
  i18nKey_2119: "จำนวนวันที่เยี่ยมชมในเดือนที่ผ่านมา",
  i18nKey_2120: "เกือบหนึ่งเดือน",
  i18nKey_2121: "เกือบหนึ่งปี",
  i18nKey_2122: "เข้าสวนจอดรถอย่างน้อยที่สุดในเดือนที่ผ่านมา",
  i18nKey_2123: "ไม่เหลือเลยในเดือนที่ผ่านมา",
  i18nKey_2124: "ยังไม่ได้เข้าชมในเดือนที่ผ่านมา",
  i18nKey_2125: "จำนวนการเข้าชมของลูกค้าซ้ำในเดือนที่ผ่านมา",
  i18nKey_2126: "มากกว่า 400 ในเดือนที่ผ่านมา",
  i18nKey_2127: "เกือบ 7 วัน",
  i18nKey_2128: "จำนวนการออกจากที่จอดรถใน 30 วันที่ผ่านมา",
  i18nKey_2129: "จำนวนที่จอดรถใน 30 วันที่ผ่านมา",
  i18nKey_2130: "สัญญาณเตือนเหตุการณ์สำคัญใน 24 ชั่วโมงที่",
  i18nKey_2131: "เกือบ 24 ชั่วโมง",
  i18nKey_2132: "กำลังดำเนินการ",
  i18nKey_2133: "เวลาจอดรถ",
  i18nKey_2134: "เวลาเข้า",
  i18nKey_2135: "การแจ้งเตือนฉุกเฉิน",
  i18nKey_2136: "ครั้งเดียวเท่านั้น",
  i18nKey_2137: "แสดงสัญญาณการเตือนจำนวนการตรวจตราวิดีโอเท่านั้น",
  i18nKey_2138: "การแผนตรวจตราเท่านั้นที่มีส่วนร่วมในสถิติ",
  i18nKey_2139: "การเงิน",
  i18nKey_2140: "ความยาวสูงสุดของจำนวนคือ 6 หลัก",
  i18nKey_2141: "จำนวนต้องเป็นจำนวนเต็มหรือทศนิยมภายใน 2 หลักเท่านั้น",
  i18nKey_2142: "วันนี้คนเดินเท้าหลั่งไหล",
  i18nKey_2143: "กระแสการจราจรวันนี้",
  i18nKey_2144: "ปริมาณการใช้ไฟฟ้าจริงในปัจจุบัน (kWh)",
  i18nKey_2145: "การหลั่งของผู้โดยสารวันนี้",
  i18nKey_2146: "การจัดอันดับความถี่ในการเปิดประตูวันนี้",
  i18nKey_2147: "จำนวนลูกค้าที่ซื้อซ้ำในวันนี้",
  i18nKey_2148: "ลบบัญชีดำเรียบร้อยแล้ว",
  i18nKey_2149: "ลบบัญชีดำ",
  i18nKey_2150: "ภาพหน้าจอล้มเหลว",
  i18nKey_2151: "ระยะเวลาสิ้นสุดต้องไม่น้อยกว่าระยะเวลาเริ่มต้น",
  i18nKey_2152: "เวลาสิ้นสุดต้องมากกว่าเวลาเริ่มต้น",
  i18nKey_2153: "เวลาสิ้นสุดต้องไม่ว่างเปล่า",
  i18nKey_2154: "เวลาสิ้นสุด",
  i18nKey_2155: "โหมดสิ้นสุด",
  i18nKey_2156: "จำนวนเงินสิ้นสุดต้องไม่น้อยกว่าจำนวนเริ่มต้น",
  i18nKey_2157: "เวลาสิ้นสุดต้องไม่น้อยกว่าเวลาเริ่มต้น",
  i18nKey_2158: "ประหยัดค่าใช้จ่าย (หยวน)",
  i18nKey_2159: "ประหยัดค่าใช้จ่าย",
  i18nKey_2160: "ชื่อวันหยุดต้องไม่ว่างเปล่า",
  i18nKey_2161: "งานเทศกาล",
  i18nKey_2162: "ศักยภาพในการประหยัดพลังงาน (กิโลวัตต์ชั่วโมง)",
  i18nKey_2163: "ศักยภาพในการประหยัดพลังงาน",
  i18nKey_2164: "ประเภทประหยัดพลังงาน (สายพันธุ์)",
  i18nKey_2165: "ประเภทประหยัดพลังงาน",
  i18nKey_2166: "ชื่อโปรแกรม:",
  i18nKey_2167: "เข้าถึงระดับแรงดันไฟฟ้า (V)",
  i18nKey_2168: "คำขออินเทอร์เฟซล้มเหลว",
  i18nKey_2169: "ชื่อโฮสต์",
  i18nKey_2170: "ข้อมูลโฮสต์",
  i18nKey_2171: "โทรศัพท์ของพนักงานต้อนรับ",
  i18nKey_2172: "อัตราการพักของรถรับส่ง",
  i18nKey_2173: "กว่าเมื่อวาน",
  i18nKey_2174: "เมื่อเทียบกับเมื่อวาน",
  i18nKey_2175: "เมื่อเทียบกับสัปดาห์ที่แล้ว",
  i18nKey_2176: "เดือนต่อเดือน",
  i18nKey_2177: "เมื่อเทียบกับเดือนที่แล้ว",
  i18nKey_2178: "เมื่อเทียบกับปีที่แล้ว",
  i18nKey_2179: "เมื่อเทียบกับมูลค่าที่แท้จริงของปีก่อน",
  i18nKey_2180: "ปีต่อปี",
  i18nKey_2181: "เดือนต่อเดือน",
  i18nKey_2182: "วันก่อน",
  i18nKey_2183: "รายละเอียดการจ่ายเงิน",
  i18nKey_2184: "ประเภทการชำระเงิน",
  i18nKey_2185: "สถานะบทบาท",
  i18nKey_2186: "ชื่อบทบาท",
  i18nKey_2187: "คำอธิบายบทบาท",
  i18nKey_2188: "รายการบทบาท",
  i18nKey_2189: "ราคาของชื่อบทบาท",
  i18nKey_2190: "จำนวนรางวัลต้องไม่น้อยกว่าศูนย์",
  i18nKey_2191: "จำนวนรางวัลต้องไม่เป็นศูนย์",
  i18nKey_2192: "ปริมาณรางวัลต้องไม่มีทศนิยม",
  i18nKey_2193: "จำนวนรางวัล",
  i18nKey_2194: "จำนวนคะแนนสะสม (a)",
  i18nKey_2195: "ชื่อคะแนนรางวัล",
  i18nKey_2196: "รางวัลผู้สนับสนุน",
  i18nKey_2197: "การเลือกรางวัล",
  i18nKey_2198: "ประเภทรางวัล",
  i18nKey_2199: "รางวัลวัตถุ / หมายเลขบัญชี",
  i18nKey_2200: "รางวัล",
  i18nKey_2201: "ลากไฟล์มาที่นี่หรือคลิกอัปโหลด",
  i18nKey_2202: "การเลือกอาคาร",
  i18nKey_2203: "ระบบไฟฟ้าอาคาร",
  i18nKey_2204: "ค่าที่แนะนำ",
  i18nKey_2205: "ตัวดำเนินการที่แนะนำ",
  i18nKey_2206: "พารามิเตอร์การทำงานที่แนะนำ",
  i18nKey_2207: "เส้นทางง่าย",
  i18nKey_2208: "อยู่ระหว่างการซ่อมแซม",
  i18nKey_2209: "กล้องวงจรปิด",
  i18nKey_2210: "ตรวจสอบภาพหน้าจอ",
  i18nKey_2211: "มอนิเตอร์:",
  i18nKey_2212: "ระยะเวลาการตรวจสอบ (วัน)",
  i18nKey_2213: "จำนวนวันที่ตรวจสอบสามารถเป็นจำนวนเต็มบวกเท่านั้น",
  i18nKey_2214: "มอนิเตอร์",
  i18nKey_2215: "ช่วงระยะเวลาอยู่ระหว่าง 1-60",
  i18nKey_2216: "ช่วงเวลา (วินาที)",
  i18nKey_2217: "หมายเลขใบขับขี่มีอักษรพิเศษ",
  i18nKey_2218: "เลขที่ใบขับขี่",
  i18nKey_2219: "ใบอนุญาตขับรถ:",
  i18nKey_2220: "การตั้งราคา",
  i18nKey_2221: "ราคา (/ ครั้งที่เหลือ)",
  i18nKey_2222:
    "มีการทำงานล่วงเวลาจำนวนมาก โดยออกจากที่จอดรถหลัง 20.00 น. ในตอนเย็นเป็นเวลา 10 วันในเดือนที่ผ่านมา",
  i18nKey_2223: "สถานะถ่ายถอด",
  i18nKey_2224: "จำบัญชีปัจจุบัน",
  i18nKey_2225: "ชื่อมิเตอร์",
  i18nKey_2226: "อักษรย่อเมตร",
  i18nKey_2227: "หมายเลขมิเตอร์",
  i18nKey_2228: "เมตร",
  i18nKey_2229: "รายละเอียดแผน",
  i18nKey_2230: "ชื่อแผน",
  i18nKey_2231: "ประเภทการเรียกเก็บเงิน",
  i18nKey_2232: "บ้านเกิด",
  i18nKey_2233: "ข้อมูลพื้นฐาน",
  i18nKey_2234: "มูลค่ารวมของคะแนน (หยวน)",
  i18nKey_2235: "มูลค่ารวมของคะแนน",
  i18nKey_2236: "ระยะเวลาการใช้คะแนน (เริ่มต้น)",
  i18nKey_2237: "ระยะเวลาที่ใช้ได้ของคะแนน (สิ้นสุด)",
  i18nKey_2238: "ระยะเวลาการใช้คะแนน",
  i18nKey_2239: "แต้มกระเป๋าเงิน",
  i18nKey_2240: "มูลค่าคะแนน (หยวน / นาที)",
  i18nKey_2241: "นาฬิกากลไก",
  i18nKey_2242: "กำลังห้องเครื่องยนต์ (กิโลวัตต์ชั่วโมง)",
  i18nKey_2243: "รับรหัสยืนยัน",
  i18nKey_2244: "ไม่สามารถรับแหล่งที่มาวิดีโอ",
  i18nKey_2245: "รับเวลา",
  i18nKey_2246: "ไม่สามารถรับทรัพยากรกล้อง ...",
  i18nKey_2247: "ไม่สามารถรับข้อมูลอุปกรณ์!",
  i18nKey_2248: "ไม่สามารถรับข้อมูลเชิงพื้นที่!",
  i18nKey_2249: "รับที่อยู่ละติจูดและลองจิจูด",
  i18nKey_2250: "รับข้อยกเว้นรายการตาราง",
  i18nKey_2251: "การเลือกลิฟต์ขนส่งสินค้า",
  i18nKey_2252: "สถานะใช้งาน",
  i18nKey_2253: "รายละเอียดกิจกรรม",
  i18nKey_2254: "ชื่อเหตุการณ์",
  i18nKey_2255: "เช่าสถานีจัดงานขั้นต่ำไม่น้อยกว่า 30 วัน!",
  i18nKey_2256: "สถานีกิจกรรม",
  i18nKey_2257: "กิจกรรมต้องไม่ว่างเปล่า",
  i18nKey_2258: "กิจกรรม",
  i18nKey_2259: "ชื่อสมาชิก",
  i18nKey_2260: "รหัสสมาชิก",
  i18nKey_2261: "สถานะการประชุม",
  i18nKey_2262: "ข้อมูลการประชุม",
  i18nKey_2263: "รายละเอียดห้องประชุม",
  i18nKey_2264: "ภาพแรกของห้องประชุม",
  i18nKey_2265: "การใช้ห้องประชุม",
  i18nKey_2266:
    "ชื่อห้องประชุมไม่รองรับอักขระพิเศษ (อนุญาตให้ใช้อักษรจีนตัวอักษรและตัวเลข)",
  i18nKey_2267: "ชื่อห้องประชุม",
  i18nKey_2268: "คำอธิบายห้องประชุม",
  i18nKey_2269: "พนักงานบริการห้องประชุม",
  i18nKey_2270: "รายละเอียดการสั่งซื้อของห้องประชุม",
  i18nKey_2271: "ก่อนการประชุม: ยังไม่เริ่ม",
  i18nKey_2272: "ก่อนการประชุม:",
  i18nKey_2273: "ก่อนการประชุม",
  i18nKey_2274: "หลังการประชุม:",
  i18nKey_2275: "จำนวนลูกค้าที่กลับมา",
  i18nKey_2276: "เปอร์เซ็นต์ของลูกค้าที่ซื้อซ้ำ",
  i18nKey_2277: "ลูกค้าซ้ำ",
  i18nKey_2278: "สถานะการวนซ้ำ",
  i18nKey_2279: "หมายเลขวงจร / พื้นที่ให้บริการ",
  i18nKey_2280: "หมายเลขวงจร",
  i18nKey_2281: "เล่นซ้ำ",
  i18nKey_2282: "สีเทา",
  i18nKey_2283: "สีเหลือง",
  i18nKey_2284: "อุปกรณ์ตรวจสอบสิ่งแวดล้อม",
  i18nKey_2285: "การตรวจสอบสิ่งแวดล้อม",
  i18nKey_2286: "การกำหนดค่านโยบายการเตือนภัยด้านสิ่งแวดล้อม",
  i18nKey_2287: "การใช้วงจรพลังงาน",
  i18nKey_2288: "ปริมาณการใช้ไฟฟ้าของวงจรช่วงเวลาเดียวกัน",
  i18nKey_2289: "ช่วงเวลาเดียวกัน",
  i18nKey_2290: "เวลาวงจร",
  i18nKey_2291: "การใช้พลังงานวงจรต่อตารางเมตร",
  i18nKey_2292: "อัตราส่วนวงจร",
  i18nKey_2293: "ยินดีต้อนรับสู่แพลตฟอร์ม IOC โปรดรีเซ็ตรหัสผ่านของคุณ",
  i18nKey_2294:
    "ยินดีต้อนรับสู่การใช้แพลตฟอร์ม IOC โปรดลงชื่อเข้าใช้บัญชีของคุณหลังจากเปลี่ยนรหัสผ่าน",
  i18nKey_2295: "ยินดีต้อนรับสู่แพลตฟอร์ม IOC โปรดลงทะเบียน",
  i18nKey_2296: "จีนตอนกลาง",
  i18nKey_2297: "West China",
  i18nKey_2298: "จีนตอนใต้",
  i18nKey_2299: "จีนตะวันออก",
  i18nKey_2300: "สวน North China",
  i18nKey_2301: "จีนตอนเหนือ",
  i18nKey_2302: "ขีดเส้นใต้",
  i18nKey_2303: "หมายเลขหนังสือเดินทาง",
  i18nKey_2304: "ตัวอย่างรูปแบบชื่อหน่วย: ห้อง A1-3 ห้องโถง 2",
  i18nKey_2305: "ชื่อบ้าน",
  i18nKey_2306: "จำนวนครัวเรือน",
  i18nKey_2307: "อินเตอร์เนต",
  i18nKey_2308: "แผนภูมิโค้ง",
  i18nKey_2309: "พยากรณ์เดือน",
  i18nKey_2310: "ผลลัพธ์",
  i18nKey_2311: "รายเดือน",
  i18nKey_2312: "สีแดง",
  i18nKey_2313:
    "ฉันเริ่มทำงานเร็วมากและเข้าที่จอดรถก่อน 8.00 น. เป็นเวลา 10 วันในวันจันทร์ที่ผ่านมา",
  i18nKey_2314: "สีดำ",
  i18nKey_2315: "จำนวนเหตุการณ์บัญชีดำ",
  i18nKey_2316: "เหตุการณ์บัญชีดำ",
  i18nKey_2317: "ภาพรวมบัญชีดำ",
  i18nKey_2318: "บัญชีดำ",
  i18nKey_2319: "จำนวนคนอนุมัติ",
  i18nKey_2320: "ระยะเวลาสัญญา:",
  i18nKey_2321: "เลขที่สัญญา:",
  i18nKey_2322: "สัญญา",
  i18nKey_2323: "การต่อสู้ในการเป็นหุ้นส่วน",
  i18nKey_2324: "ผสาน",
  i18nKey_2325: "การใช้น้ำ",
  i18nKey_2326: "สามอันดับ ที่กินเวลายาวนานที่สุด",
  i18nKey_2327: "สามอันดับ ที่ใช้เวลาสั้นที่สุด",
  i18nKey_2328: "การใช้พลังงาน (องศา)",
  i18nKey_2329: "การใช้พลังงาน",
  i18nKey_2330: "รถหรู",
  i18nKey_2331: "ลักษณะพฤติกรรม",
  i18nKey_2332: "ใบขับขี่:",
  i18nKey_2333: "ประเทศ",
  i18nKey_2334: "ประเทศที่คุณถือสัญชาติอยู่",
  i18nKey_2335: "ติดตามช่วงเวลา",
  i18nKey_2336: "ส่งออกสำเร็จแล้ว",
  i18nKey_2337: "ติดตามดู",
  i18nKey_2338: "ติดตาม",
  i18nKey_2339: "กฎ",
  i18nKey_2340: "กว่างโจว",
  i18nKey_2341:
    "หัวหน้าฝ่ายบริหารเป็นภาษาจีนตัวอักษรและขีดล่างไม่เกิน 20 ตัวอักษร",
  i18nKey_2342: "ผู้รับผิดชอบการจัดการ",
  i18nKey_2343: "ผู้ดูแลระบบคืนเงินในเชิงรุก",
  i18nKey_2344: "ชื่อผู้ดูแลระบบ",
  i18nKey_2345: "จัดการความเป็นเจ้าของ",
  i18nKey_2346: "ผู้อนุมัติการจัดการ",
  i18nKey_2347: "ฝ่ายบริหาร",
  i18nKey_2348: "ความสัมพันธ์ผิดปกติ",
  i18nKey_2349: "เหตุการณ์ที่เกี่ยวข้อง",
  i18nKey_2350: "กล้องที่เกี่ยวข้องสำเร็จแล้ว",
  i18nKey_2351: "กล้องที่เกี่ยวข้อง",
  i18nKey_2352: "สถานที่จัดกิจกรรมที่เกี่ยวข้อง",
  i18nKey_2353: "เชื่อมโยงข้อมูลที่ผ่านมาของวิดีโอ __",
  i18nKey_2354: "เชื่อมโยงเรียบร้อยแล้ว",
  i18nKey_2355: "หมายเลขป้ายทะเบียนที่เกี่ยวข้อง",
  i18nKey_2356: "ที่เกี่ยวข้อง",
  i18nKey_2357: "คำสำคัญ",
  i18nKey_2358: "ปิดข้อยกเว้นการติดตาม",
  i18nKey_2359: "ปิดการติดตาม",
  i18nKey_2360: "ปิดรายการที่เลือก",
  i18nKey_2361: "ปิดการแจ้งเตือน",
  i18nKey_2362: "ปิดตัวลง",
  i18nKey_2363: "ปิด",
  i18nKey_2364: "ผู้ถือบัญชี",
  i18nKey_2365: "รวมความถี่ของความล้มเหลว",
  i18nKey_2366: "รายละเอียดความล้มเหลว",
  i18nKey_2367: "จำนวนสถานที่ที่ล้มเหลว",
  i18nKey_2368: "สถิติของสถานที่ล้มเหลว",
  i18nKey_2369: "คำอธิบายข้อบกพร่อง",
  i18nKey_2370: "แหล่งที่มาของความล้มเหลว",
  i18nKey_2371: "ความผิดปกติ",
  i18nKey_2372: "ค่าเช่าขั้นต่ำสำหรับสถานีประจำไม่น้อยกว่า 3 เดือน!",
  i18nKey_2373: "สถานีประจำ",
  i18nKey_2374: "หลังจากตรวจสนามแล้ว สนามจะปรากฏในแอพเพล็ตและผู้ใช้ APP",
  i18nKey_2375: "ผู้ผลิต",
  i18nKey_2376: "ระบบจัดหาและจำหน่าย",
  i18nKey_2377: "แหล่งจ่ายไฟและการจัดจำหน่าย",
  i18nKey_2378: "รวมวงจรไฟ $",
  i18nKey_2379: "ห้องประชุมรวม",
  i18nKey_2380: "รายงานคำสั่งสำนักงานที่ใช้ร่วมกัน _",
  i18nKey_2381: "ฉันทามติร่วมสร้างสรรค์ แบ่งปัน แบ่งปัน",
  i18nKey_2382: "เปิด $ ครั้ง",
  i18nKey_2383: "รวมเป็น $ สำเร็จ $ ข้อผิดพลาด $",
  i18nKey_2384: "ขอแสดงความยินดีการลงทะเบียนสำเร็จ!",
  i18nKey_2385: "คลาวด์สาธารณะ",
  i18nKey_2386: "ยอดจอง บริษัท 5 อันดับ",
  i18nKey_2387: "ไฟฟ้าในพื้นที่สาธารณะ",
  i18nKey_2388: "มิเตอร์พื้นที่สาธารณะ",
  i18nKey_2389: "การจัดอันดับการใช้พลังงานของเขตสาธารณะ",
  i18nKey_2390:
    "ค่าเป้าหมายของการใช้พลังงานในพื้นที่สาธารณะต้องไม่เกินมูลค่าเป้าหมายของการใช้พลังงานทั้งหมด",
  i18nKey_2391: "การใช้พลังงานในพื้นที่สาธารณะ (เดือนที่แล้ว)",
  i18nKey_2392: "ชื่อตำบลสาธารณะ",
  i18nKey_2393: "ประเภทพื้นที่สาธารณะ",
  i18nKey_2394: "พื้นที่สาธารณะ",
  i18nKey_2395: "ไม่สามารถลบพื้นที่สาธารณะได้",
  i18nKey_2396: "การกำหนดค่าทั่วไป",
  i18nKey_2397: "บัญชีดำด้านความปลอดภัยสาธารณะ",
  i18nKey_2398: "ความปลอดภัยสาธารณะ",
  i18nKey_2399: "วันทำงาน",
  i18nKey_2400: "ชื่อพนักงาน",
  i18nKey_2401: "โทรศัพท์เจ้าหน้าที่",
  i18nKey_2402: "เจ้าหน้าที่",
  i18nKey_2403: "นายจ้าง",
  i18nKey_2404: "ประเภทของงาน",
  i18nKey_2405: "สถานะสถานี",
  i18nKey_2406: "จำนวนสถานี",
  i18nKey_2407: "ภาพแรกของสถานที่",
  i18nKey_2408: "ประเภทสถานี",
  i18nKey_2409: "บทนำ",
  i18nKey_2410:
    "ราคาต่อหน่วยของสถานที่ทำงานต้องสอดคล้องกับยูนิตที่มีค่าเช่าน้อยที่สุด!",
  i18nKey_2411: "ราคาสถานี",
  i18nKey_2412: "จำนวนหมายเลขสถานีต้องสอดคล้องกับจำนวนสถานี!",
  i18nKey_2413: "หมายเลขสถานี",
  i18nKey_2414: "หมายเลขทะเบียนใบอนุญาตธุรกิจ / รหัสเครดิตโซเชียลแบบรวม",
  i18nKey_2415: "หมายเลขงาน",
  i18nKey_2416: "สถานะตั๋ว",
  i18nKey_2417: "เจ้าของใบสั่งงาน",
  i18nKey_2418: "ผู้รับมอบสิทธิ์ใบสั่งงาน",
  i18nKey_2419: "ส่งใบสั่งงานเรียบร้อยแล้ว",
  i18nKey_2420: "เลขที่ใบสั่งงาน",
  i18nKey_2421: "เวลาอัปเดตคือเวลาทางสถิติของเมื่อวาน",
  i18nKey_2422: "เวลาอัปเดต",
  i18nKey_2423: "การอัพเดทล้มเหลว!",
  i18nKey_2424: "การอัปเดตเสร็จสมบูรณ์",
  i18nKey_2425: "เลเยอร์เพิ่มเติม",
  i18nKey_2426: "งานอื่น ๆ",
  i18nKey_2427: "การระบายน้ำ",
  i18nKey_2428: "กิจกรรมปกติสำหรับคนทุกประเภท",
  i18nKey_2429: "คะแนนส่วนบุคคล",
  i18nKey_2430: "ส่วนตัว",
  i18nKey_2431: "ต่อ บริษัท",
  i18nKey_2432: "A",
  i18nKey_2433: "เกณฑ์การเตือนภัย",
  i18nKey_2434: "เหตุการณ์การเตือนภัยและสถานะการติดตาม",
  i18nKey_2435: "ไม่มีอุปกรณ์ที่เกี่ยวข้องกับเหตุการณ์การเตือนภัย",
  i18nKey_2436: "ภาพรวมเหตุการณ์การเตือนภัย",
  i18nKey_2437: "การเตือนเหตุการณ์",
  i18nKey_2438: "คำอธิบายการเตือนภัย",
  i18nKey_2439: "รายการปลุก",
  i18nKey_2440: "ประเภทการเตือนภัย",
  i18nKey_2441: "แหล่งที่มาของการเตือนภัย",
  i18nKey_2442: "สถิติการจำแนกสัญญาณเตือน",
  i18nKey_2443: "จำนวนการเตือน",
  i18nKey_2444: "มุมมองการเตือนภัย",
  i18nKey_2445: "ไฮไลต์",
  i18nKey_2446: "ขั้นสูง",
  i18nKey_2447: "การเหนี่ยวนำสว่างสลัว",
  i18nKey_2448: "การตรวจจับความสว่าง",
  i18nKey_2449: "สวิตช์ประสาทสัมผัส",
  i18nKey_2450: "รักษาเวลาความสว่างหลังจากการเหนี่ยวนำ",
  i18nKey_2451: "การตรวจจับเวลาแสง",
  i18nKey_2452:
    "สถานะกิจกรรมอยู่ระหว่างรอดำเนินการและไม่สามารถรายงานได้ชั่วคราว",
  i18nKey_2453:
    "สถานะกิจกรรมอยู่ระหว่างรอดำเนินการและไม่สามารถรายงานได้ชั่วคราว",
  i18nKey_2454: "สถานะคือเหตุการณ์กำลังประมวลผลและไม่สามารถรายงานได้ชั่วคราว",
  i18nKey_2455:
    "งานรับผิดชอบมีเหตุการณ์ที่ยังไม่ได้ประมวลผลและไม่สามารถรายงานได้ชั่วคราว",
  i18nKey_2456: "บุคคลนี้ไม่มีการติดตามการเคลื่อนไหวในช่วงเวลานี้",
  i18nKey_2457: "บุคคลนี้ไม่มีภาพและไม่สามารถนำไปใช้งานได้",
  i18nKey_2458: "ห้องประชุมถูกปิดใช้งานและไม่สามารถดำเนินการได้",
  i18nKey_2459: "ไม่มีวันที่สำหรับห้องประชุมนี้",
  i18nKey_2460: "บริเวณนี้ไม่มีที่จอดรถ",
  i18nKey_2461: "เปิดใช้งานการขับขี่นี้แล้วโปรดหยุดชั่วคราวก่อนออกเดินทาง!",
  i18nKey_2462: "ฉากถูกปิดใช้งานและไม่สามารถดำเนินการได้",
  i18nKey_2463: "การปรับใช้ถูกหยุดและไม่สามารถแก้ไขได้",
  i18nKey_2464: "เปิดใช้งานการควบคุมการปรับใช้แล้วและไม่สามารถแก้ไขได้",
  i18nKey_2465:
    "ข้อมูลรถควบคุมถูกลบไปแล้วไม่รองรับการแก้ไขหรือจัดเรียงใหม่โปรดสร้างการควบคุมการปรับใช้ใหม่",
  i18nKey_2466:
    "ไฟล์แนบรองรับรูปแบบ excel, PDF, jpg, rar, zip เวอร์ชันใหม่เท่านั้น",
  i18nKey_2467: "ภาคผนวก",
  i18nKey_2468: "จำนวนสถานที่เพิ่มเติม",
  i18nKey_2469: "มีชื่อสถานที่เพิ่มเติมอยู่แล้ว!",
  i18nKey_2470: "ชื่อสถานที่เพิ่มเติม",
  i18nKey_2471: "โหลดเงื่อนไข",
  i18nKey_2472: "วิธีการชำระเงิน",
  i18nKey_2473: "รูปแบบการชำระเงิน",
  i18nKey_2474: "วิธีการชำระเงิน",
  i18nKey_2475: "การตั้งค่าวิดีโอรอง",
  i18nKey_2476: "ทรัพยากรบริการ",
  i18nKey_2477:
    "ผู้ให้บริการต้องประกอบด้วยภาษาจีนและภาษาอังกฤษเท่านั้นตัวเลขและ _",
  i18nKey_2478: "ความยาวของผู้ให้บริการต้องไม่เกิน 50 อักษร",
  i18nKey_2479: "ผู้ให้บริการ",
  i18nKey_2480: "พื้นที่ให้บริการต้องไม่ว่างเปล่า",
  i18nKey_2481: "พื้นที่ให้บริการ",
  i18nKey_2482: "ข้อมูลเซิร์ฟเวอร์",
  i18nKey_2483: "ผู้ให้บริการ",
  i18nKey_2484: "จำนวนสำเนา",
  i18nKey_2485: "แบ่งปัน",
  i18nKey_2486: "สีชมพู",
  i18nKey_2487: "นาทีต้องไม่ว่างเปล่า",
  i18nKey_2488:
    "นาทีต้องไม่น้อยกว่า 2 นาทีและต้องไม่เกิน 10 นาทีและต้องไม่มีช่องว่าง!",
  i18nKey_2489: "นาที",
  i18nKey_2490: "สัดส่วนของรายการย่อยต้องไม่เกิน 1",
  i18nKey_2491: "เปอร์เซ็นต์ของรายการย่อย",
  i18nKey_2492: "การบำรุงรักษาตามรายการ",
  i18nKey_2493: "สถิติแบบแยกรายการ",
  i18nKey_2494: "การใช้พลังงานสะสมตามรายการ (กิโลวัตต์ชั่วโมง)",
  i18nKey_2495: "ข้อมูลมิเตอร์แบบระบุรายการ",
  i18nKey_2496: "ดัชนีการวิเคราะห์:",
  i18nKey_2497: "การวิเคราะห์",
  i18nKey_2498: "การหลั่งไหลของผู้โดยสาร",
  i18nKey_2499: "ตำแหน่งพาร์ทิชัน",
  i18nKey_2500: "พาร์ทิชัน",
  i18nKey_2501: "รหัสการจัดเตรียม",
  i18nKey_2502: "สถานการณ์ทางเบี่ยง",
  i18nKey_2503: "ต้องระบุหมวดหมู่",
  i18nKey_2504: "การจัดหมวดหมู่",
  i18nKey_2505: "นาที",
  i18nKey_2506: "ไม่ใช่พื้นที่สาธารณะ",
  i18nKey_2507: "วันที่ไม่ทำงาน",
  i18nKey_2508: "แบบไม่จอดรถ",
  i18nKey_2509: "ไม่จำเป็นต้องใช้",
  i18nKey_2510: "การปลดปล่อยทั้งหมด",
  i18nKey_2511: "กำลังการผลิต (กิโลวัตต์ชั่วโมง)",
  i18nKey_2512: "เยี่ยมชมแหล่งที่มา",
  i18nKey_2513: "สถานะผู้เยี่ยมชม",
  i18nKey_2514: "การนัดหมายของลูกค้า",
  i18nKey_2515: "ข้อมูลการเชิญผู้เยี่ยมชมแสดงเนื้อหา",
  i18nKey_2516: "คำเชิญลูกค้า",
  i18nKey_2517: "สถิติการเชิญผู้เยี่ยมชม (เกือบ 30 วัน)",
  i18nKey_2518: "ชื่อผู้เยี่ยมชมต้องไม่เป็นอักษรพิเศษ",
  i18nKey_2519: "ชื่อผู้เยี่ยมชมต้องไม่ว่างเปล่า",
  i18nKey_2520: "ชื่อผู้เยี่ยมชม",
  i18nKey_2521: "ข้อมูลผู้เยี่ยมชมต้องไม่เป็นอักษรพิเศษ",
  i18nKey_2522: "การเข้าถึงของผู้เยี่ยมชม",
  i18nKey_2523: "จำนวนผู้เยี่ยมชม",
  i18nKey_2524: "พนักงานดูแลผู้เยี่ยมชม ไม่มีอำนาจในการดำเนินการนี้",
  i18nKey_2525: "สถิติผู้เยี่ยมชม (โดยสวนสาธารณะ)",
  i18nKey_2526: "จำนวนผู้เยี่ยมชม - อันดับ บริษัท",
  i18nKey_2527: "จำนวนผู้เยี่ยมชม (คน)",
  i18nKey_2528: "จำนวนผู้เยี่ยมชม",
  i18nKey_2529: "จำนวนผู้เยี่ยมชม",
  i18nKey_2530: "การจัดการผู้เยี่ยมชม",
  i18nKey_2531: "โทรศัพท์ของลูกค้าต้องไม่ว่างเปล่า",
  i18nKey_2532: "โทรศัพท์ของผู้เยี่ยมชม",
  i18nKey_2533: "เวลามาถึงของผู้เยี่ยมชม",
  i18nKey_2534: "การแจ้งเตือนเมื่อผู้เยี่ยมชมมาถึง",
  i18nKey_2535: "เหตุผลในการเยี่ยมชม",
  i18nKey_2536: "ผู้เยี่ยมชม",
  i18nKey_2537: "ข้อมูลที่อยู่อาศัย",
  i18nKey_2538: "ประเภทอสังหาริมทรัพย์",
  i18nKey_2539: "ที่อยู่บ้าน",
  i18nKey_2540: "รหัสบ้าน",
  i18nKey_2541: "ไฟฟ้าในห้อง",
  i18nKey_2542: "ผู้เช่าเครื่องมือ",
  i18nKey_2543: "ตำแหน่งห้อง",
  i18nKey_2544: "จำนวนห้องพัก",
  i18nKey_2545: "ชื่อห้อง",
  i18nKey_2546: "หมายเลขห้อง",
  i18nKey_2547: "อสังหาริมทรัพย์",
  i18nKey_2548: "เขตดับเพลิง",
  i18nKey_2549: "กลับไปที่การเข้าสู่ระบบ",
  i18nKey_2550: "กลับ",
  i18nKey_2551: "บัตรประจำตัวองค์กร",
  i18nKey_2552: "ตัวแทนทางกฎหมาย",
  i18nKey_2553: "สถานที่",
  i18nKey_2554: "จำนวนครั้งที่เกิดขึ้น",
  i18nKey_2555: "เวลาเกิด",
  i18nKey_2556: "จำนวนปัญหา (a)",
  i18nKey_2557: "ประเด็นปัญหา",
  i18nKey_2558: "ออก",
  i18nKey_2559: "รหัสคิวอาร์โค้ดหมดอายุแล้ว",
  i18nKey_2560: "การเข้าถึงรหัสคิวอาร์โค้ด",
  i18nKey_2561: "เวลารีเฟรชคิวอาร์โค้ดต้องอยู่ระหว่าง 1 ถึง 1439 นาที",
  i18nKey_2562: "เวลารีเฟรชคิิวอาร์โค้ด",
  i18nKey_2563: "คิวอาร์โค้ด",
  i18nKey_2564: "เปิดหนึ่งรอบและสองรอบ",
  i18nKey_2565: "ประเภทที่สอง",
  i18nKey_2566: "แผนกมัธยมศึกษา",
  i18nKey_2567: "ระดับ 2",
  i18nKey_2568: "สอง",
  i18nKey_2569: "ห้องสำหรับเด็ก",
  i18nKey_2570: "กำลังการผลิตสูงสุด (กิโลวัตต์ชั่วโมง)",
  i18nKey_2571: "กำลังไฟ (กิโลวัตต์)",
  i18nKey_2572: "ตัน",
  i18nKey_2573: "แลกเปลี่ยน",
  i18nKey_2574: "ชื่อวัตถุ",
  i18nKey_2575:
    "โมเดลภายนอกต้องเป็นตัวอักษรภาษาจีนและขีดล่างภายใน 50 อักษรเท่านั้น",
  i18nKey_2576: "โมเดลภายนอกต้องไม่ว่างเปล่า",
  i18nKey_2577: "โมเดลภายนอก",
  i18nKey_2578: "ขออภัยคุณไม่ได้รับอนุญาตใด ๆ โปรดติดต่อผู้ดูแลระบบ",
  i18nKey_2579: "การกำหนดค่าเบรกเกอร์",
  i18nKey_2580: "กฎการกำหนดค่าเวลาการเปลี่ยนเบรกเกอร์",
  i18nKey_2581: "ข้อความ",
  i18nKey_2582: "หมายเลขท่าเรือ",
  i18nKey_2583: "การอ่านจุดเวลา",
  i18nKey_2584: "การอ่าน (กิโลวัตต์ชั่วโมง)",
  i18nKey_2585: "การอ่าน",
  i18nKey_2586: "อ่านแผน",
  i18nKey_2587:
    "นักสู้จะไม่มีวันถอยหลังและไม่มีอันตรายของความกล้าหาญอยู่ข้างหน้า",
  i18nKey_2588: "แช่แข็ง",
  i18nKey_2589: "รหัสผ่านแบบไดนามิก",
  i18nKey_2590: "การใช้พลังงาน",
  i18nKey_2591: "ไม่สามารถโหลดภาพเคลื่อนไหว",
  i18nKey_2592: "ตะวันออกเฉียงเหนือ",
  i18nKey_2593: "สถานะการสั่งซื้อ",
  i18nKey_2594: "สถานะการชำระเงินในรายการสั่งซื้อ",
  i18nKey_2595: "ช่วงเวลาสั่งซื้อ",
  i18nKey_2596: "จำนวนการสั่งซื้อ",
  i18nKey_2597: "เลขที่คำสั่งซื้อ / ชื่อพื้นที่",
  i18nKey_2598: "เลขที่คำสั่งซื้อ / ชื่อห้องประชุม",
  i18nKey_2599: "เลขที่ใบสั่งซื้อ",
  i18nKey_2600: "รายได้จากการสั่งซื้อ",
  i18nKey_2601: "ยอดขายหน้าร้าน 5 อันดับแรก",
  i18nKey_2602: "นาฬิกาดิจิตอล",
  i18nKey_2603: "จำนวนลิฟต์ทั้งหมด",
  i18nKey_2604: "บันทึกการทำงานของลิฟต์",
  i18nKey_2605: "ข้อมูลลิฟต์",
  i18nKey_2606: "รูปภาพลิฟต์",
  i18nKey_2607: "สถิติลิฟต์",
  i18nKey_2608: "ชื่อลิฟต์:",
  i18nKey_2609: "ชื่อลิฟต์",
  i18nKey_2610: "ประเภทลิฟต์:",
  i18nKey_2611: "ประเภทลิฟต์",
  i18nKey_2612: "ที่อยู่ลิฟต์",
  i18nKey_2613: "รหัสลิฟต์:",
  i18nKey_2614: "ลิฟต์",
  i18nKey_2615: "การสูญเสียทั้งหมดของหม้อแปลง (กิโลวัตต์ชั่วโมง)",
  i18nKey_2616: "การสูญเสียพลังงาน",
  i18nKey_2617: "เครื่องใช้ไฟฟ้าชั้น 1",
  i18nKey_2618: "การใช้พลังงานไฟฟ้า (กิโลวัตต์ชั่วโมง)",
  i18nKey_2619: "หมายเลขเคเบิ้ลสแควร์",
  i18nKey_2620: "โทรศัพท์",
  i18nKey_2621: "ค่าไฟฟ้าสะสม",
  i18nKey_2622: "พ่อค้าที่ค้างชำระค่าไฟฟ้า",
  i18nKey_2623: "จำนวนการชาร์จไฟฟ้าที่ไม่ได้หัก",
  i18nKey_2624: "ปริมาณการชาร์จไฟฟ้าสะสม",
  i18nKey_2625: "ประเภทแบตเตอรี่",
  i18nKey_2626: "เกณฑ์การเตือนมิเตอร์ไฟฟ้า:",
  i18nKey_2627: "หมายเลขซีเรียลของมิเตอร์",
  i18nKey_2628: "ชื่อมิเตอร์ต้องไม่ว่างเปล่า",
  i18nKey_2629: "ชื่อมิเตอร์",
  i18nKey_2630: "รายการย่อยซ่อมบำรุงมิเตอร์ไฟฟ้าสำเร็จ!",
  i18nKey_2631: "บันทึกรายละเอียดของมิเตอร์ไฟฟ้า",
  i18nKey_2632: "หมายเลขมิเตอร์หรือรายการย่อยที่คิดเป็นต้องไม่ว่างเปล่า",
  i18nKey_2633: "หมายเลขมิเตอร์",
  i18nKey_2634: "ประเภทจุด",
  i18nKey_2635: "คลิกลองใหม่",
  i18nKey_2636:
    "อนุญาตให้แก้ไขได้ก็ต่อเมื่อคุณคลิกหยุดนโยบาย และจะมีผลภายในครึ่งชั่วโมงหลังจากที่คุณคลิกเปิดใช้งาน",
  i18nKey_2637: "คลิกเพื่อดู",
  i18nKey_2638: "ค่าธรรมเนียมการชำระเงินของบุคคลที่สาม (หยวน)",
  i18nKey_2639: "ผู้ใช้บุคคลที่สาม",
  i18nKey_2640: "ผู้เยี่ยมชมบุคคลที่สาม",
  i18nKey_2641: "รหัสบุคคลที่สาม",
  i18nKey_2642: "สัปดาห์แรก ...",
  i18nKey_2643: "สัปดาห์ $",
  i18nKey_2644: "เวลาเปิดของกฎแสง $ ในกลยุทธ์ $ ต้องไม่ว่างเปล่า",
  i18nKey_2645: "เวลาที่มีประสิทธิภาพของกลยุทธ์ $ th ต้องไม่ว่างเปล่า",
  i18nKey_2646:
    "การป้อนข้อมูลลองจิจูดและละติจูดของที่อยู่ต้องคั่นด้วยภาษาอังกฤษ ลูกน้ำ และต้องมีความยาวไม่เกิน 20 อักษร",
  i18nKey_2647: "ระบุละติจูดและลองจิจูด",
  i18nKey_2648: "การจัดการที่อยู่",
  i18nKey_2649:
    "ที่อยู่ไม่รองรับอักษรพิเศษ (อนุญาตให้ใช้อักษรจีนตัว อักษรและตัวเลข)",
  i18nKey_2650: "คำเตือนแรงดันไฟฟ้าต่ำ",
  i18nKey_2651: "เท่ากัน",
  i18nKey_2652: "ระดับ",
  i18nKey_2653: "เวลาเช็คอิน",
  i18nKey_2654: "สวิตช์ไฟ",
  i18nKey_2655: "จำนวนหลอดไฟ",
  i18nKey_2656: "สวิตช์เซ็นเซอร์แสง",
  i18nKey_2657: "เครื่องอ่านรหัส QR Barrier",
  i18nKey_2658: "พันธมิตรฯ",
  i18nKey_2659: "สถานการณ์มาถึง",
  i18nKey_2660: "จำนวนเงินในบัญชี",
  i18nKey_2661: "การหลั่งไหลของผู้เยี่ยมชม",
  i18nKey_2662: "นำเข้าเขตไฟ",
  i18nKey_2663:
    "การนำเข้าล้มเหลวมีข้อผิดพลาดในข้อมูลในแถว $ โปรดตรวจสอบและส่งการนำเข้าอีกครั้งในภายหลัง",
  i18nKey_2664: "เงื่อนไขการส่งออก",
  i18nKey_2665: "การส่งออกล้มเหลว รองรับข้อมูลการส่งออกสูงสุด 10,000",
  i18nKey_2666: "การส่งออกล้มเหลว ไม่มีข้อมูลที่จะส่งออก",
  i18nKey_2667:
    "การส่งออกล้มเหลว ไม่มีบันทึกการควบคุมการเข้าถึงในหน้าปัจจุบัน!",
  i18nKey_2668: "การส่งออกล้มเหลว ไม่มีบันทึกคำสั่งซื้อในหน้าปัจจุบัน!",
  i18nKey_2669: "การส่งออกล้มเหลว ไม่มีบันทึกการเดินทางในหน้าปัจจุบัน!",
  i18nKey_2670:
    "การส่งออกล้มเหลวไม่มีบันทึกการชำระค่าสาธารณูปโภคในหน้าปัจจุบัน",
  i18nKey_2671: "การส่งออกล้มเหลว ไม่มีบันทึกผู้เยี่ยมชมในหน้าปัจจุบัน",
  i18nKey_2672: "การส่งออกล้มเหลว",
  i18nKey_2673: "ส่งออกแทร็กสำเร็จ",
  i18nKey_2674: "ค่าแพ็คเกจของเดือน",
  i18nKey_2675: "การวิเคราะห์รายได้ของเดือน",
  i18nKey_2676: "การใช้พลังงานของเดือน (กิโลวัตต์ชั่วโมง)",
  i18nKey_2677: "การใช้พลังงานในพื้นที่สาธารณะของเดือน (kWh)",
  i18nKey_2678: "จำนวนการเตือนในเดือน (ครั้ง)",
  i18nKey_2679: "เดือนนี้",
  i18nKey_2680: "วันนั้น",
  i18nKey_2681: "การเตือนใหม่ในวันนั้น",
  i18nKey_2682: "การใช้พลังงานของวัน (กิโลวัตต์ - ชั่วโมง)",
  i18nKey_2683: "ค่าธรรมเนียมถูกระงับชั่วคราว",
  i18nKey_2684: "การใช้พลังงานในพื้นที่สาธารณะในวันนั้น (กิโลวัตต์ชั่วโมง)",
  i18nKey_2685: "จำนวนการเช็คอินในวันนั้น",
  i18nKey_2686: "วันนั้น",
  i18nKey_2687: "สถานะปัจจุบัน",
  i18nKey_2688: "ตอนนี้ยังไม่มีโปรแกรม กรุณาตั้งโปรแกรมก่อน!",
  i18nKey_2689: "การทำงานของพารามิเตอร์ในปัจจุบัน",
  i18nKey_2690: "ปัจจุบันย้ายไปที่",
  i18nKey_2691:
    "ไม่มีบันทึกคำสั่งซื้อในหน้าปัจจุบันและไม่จำเป็นต้องดำเนินการส่งออก!",
  i18nKey_2692: "หน้าปัจจุบันคือ",
  i18nKey_2693: "ไม่มีห้องในตำแหน่งปัจจุบัน โปรดเลือกห้อง!",
  i18nKey_2694: "ไม่มีการเพิ่มผู้อนุมัติในขณะนี้ โปรดเพิ่มผู้อนุมัติ",
  i18nKey_2695: "ทรัพยากรข้อมูลปัจจุบัน",
  i18nKey_2696: "เวลาปัจจุบัน",
  i18nKey_2697: "เบราว์เซอร์ปัจจุบันไม่รองรับ websocket",
  i18nKey_2698: "พลังปัจจุบัน",
  i18nKey_2699: "ทรัพยากรบริการปัจจุบัน",
  i18nKey_2700:
    "การตรวจสอบไฟล์นำเข้าปัจจุบันไม่ถูกต้อง โปรดแก้ไขในเครื่องแล้วนำเข้าอีกครั้ง!",
  i18nKey_2701: "อัตราการใช้พื้นที่จอดรถในปัจจุบัน",
  i18nKey_2702: "สาขาปัจจุบัน",
  i18nKey_2703: "ทรัพยากรปฏิบัติการในปัจจุบัน",
  i18nKey_2704: "ด้านล่างปัจจุบัน",
  i18nKey_2705: "เมนูทรัพยากรปัจจุบัน",
  i18nKey_2706: "เวลานั้น",
  i18nKey_2707: "หน่วย:",
  i18nKey_2708: "หน่วย",
  i18nKey_2709: "การใช้พลังงานต่อตารางเมตร",
  i18nKey_2710: "หน่วย: หยวน",
  i18nKey_2711: "หน่วย: คน",
  i18nKey_2712: "หน่วย: ครั้ง",
  i18nKey_2713: "หน่วย: กิโลวัตต์ - ชั่วโมง",
  i18nKey_2714: "หน่วย: ชั่วโมง",
  i18nKey_2715: "หน่วย: สำเนา",
  i18nKey_2716: "หน่วย: นาที",
  i18nKey_2717: "ราคาต่อหน่วย / ครั้ง",
  i18nKey_2718: "ราคาต่อหน่วย (หยวน / ชั่วโมง)",
  i18nKey_2719: "เวลาทัวร์เดี่ยว",
  i18nKey_2720: "ระยะเวลาสูงสุดและวันที่เข้าพักเดี่ยว",
  i18nKey_2721: "การหักเงินเดี่ยว",
  i18nKey_2722: "ที่จะต้องจ่าย",
  i18nKey_2723: "ที่จะได้รับ",
  i18nKey_2724: "ความสว่างขณะสแตนด์บาย",
  i18nKey_2725: "จำนวนสัญญาณเตือนที่รอดำเนินการ",
  i18nKey_2726: "ที่จะดำเนินการ",
  i18nKey_2727: "รอดำเนินการ",
  i18nKey_2728: "ที่จะติดตั้ง",
  i18nKey_2729: "แสดงถึงความผิดปกติ",
  i18nKey_2730: "แสดงถึงข้อมูลที่ผิดปกติ",
  i18nKey_2731: "มากกว่าหรือเท่ากับ",
  i18nKey_2732: "มากกว่า 400",
  i18nKey_2733: "มากกว่า 2",
  i18nKey_2734: "มากกว่า",
  i18nKey_2735: "ธุรกิจใหญ่",
  i18nKey_2736: "ตำแหน่งหน้าจอขนาดใหญ่",
  i18nKey_2737: "ชื่อจอขนาดใหญ่",
  i18nKey_2738: "การตั้งค่าการเล่นหน้าจอขนาดใหญ่",
  i18nKey_2739: "แน่นอน Dadao ต่อสู้ในการเป็นหุ้นส่วน",
  i18nKey_2740: "เปิดการแจ้งเตือน",
  i18nKey_2741: "การจัดประเภทบัตรเจาะ",
  i18nKey_2742: "การเช็คอินล้มเหลว",
  i18nKey_2743: "เช็คอินเรียบร้อย",
  i18nKey_2744: "บริษัทรถ 5 อันดับแรก",
  i18nKey_2745:
    "จาก $ ถึง $ ความล้มเหลวเกิดขึ้นซ้ำ ๆ เพียงครั้งเดียวเวลาสะสมคือ $ ชั่วโมงซึ่งอาจทำให้เสียตลอดทั้งปี",
  i18nKey_2746: "ห้องนอนที่สอง",
  i18nKey_2747:
    "การดำเนินการนี้จะลบไฟล์อย่างถาวร คุณต้องการดำเนินการต่อหรือไม่",
  i18nKey_2748: "เวลาสร้าง",
  i18nKey_2749: "ผู้สร้าง",
  i18nKey_2750: "การเลือกโหมดการสร้าง",
  i18nKey_2751: "สร้างระดับพื้นที่อย่างน้อยของอาคาร!",
  i18nKey_2752: "สร้างคะแนน",
  i18nKey_2753: "สร้างห้อง",
  i18nKey_2754: "สร้างฉาก",
  i18nKey_2755: "สร้าง",
  i18nKey_2756: "เงื่อนไขการทริกเกอร์:",
  i18nKey_2757: "ปริมาณการประมวลผล",
  i18nKey_2758: "ระยะเวลาดำเนินการ",
  i18nKey_2759: "การประมวลผล",
  i18nKey_2760: "กลไกการประมวลผล",
  i18nKey_2761: "ห้องเก็บของ",
  i18nKey_2762: "สถิติการจัดเก็บพลังงาน",
  i18nKey_2763: "ห้องครัว",
  i18nKey_2764: "เวลาเริ่มต้น",
  i18nKey_2765: "มีการละเมิด $",
  i18nKey_2766: "ความวุ่นวายในการเข้าและออก",
  i18nKey_2767: "เวลาจอดรถ",
  i18nKey_2768: "เวลาเล่น",
  i18nKey_2769: "วันผลิต",
  i18nKey_2770: "ประเภทการเติมเงิน",
  i18nKey_2771: "จำนวนการเติมเงินสะสม",
  i18nKey_2772: "บริษัทเติมเงิน",
  i18nKey_2773: "เติมเงิน",
  i18nKey_2774: "ค่าใช้จ่ายทั้งหมด",
  i18nKey_2775: "ความจุในการชาร์จ (กิโลวัตต์ชั่วโมง)",
  i18nKey_2776: "สถานะการชำระเงิน",
  i18nKey_2777: "สถิติการขับขี่",
  i18nKey_2778: "เวลาเที่ยว",
  i18nKey_2779: "เมือง",
  i18nKey_2780: "อุตสาหกรรมให้เช่า",
  i18nKey_2781: "ชื่อผู้เช่าต้องไม่ว่างเปล่า",
  i18nKey_2782: "ชื่อผู้เช่า",
  i18nKey_2783: "ผู้เช่าอุตสาหกรรม",
  i18nKey_2784: "ผู้เช่า",
  i18nKey_2785: "เวลาในการคืนเงินสำเร็จ",
  i18nKey_2786: "เฉิงตู",
  i18nKey_2787: "ความสำเร็จไม่ได้เกี่ยวกับความแข็งแกร่ง แต่จะอยู่ได้นานแค่ไหน",
  i18nKey_2788: "ชื่อเจ้าของ",
  i18nKey_2789: "ประเภทเจ้าของ",
  i18nKey_2790: "โทรศัพท์เจ้าของรถ",
  i18nKey_2791: "จำนวนที่จอดรถทั้งหมด",
  i18nKey_2792: "จำนวนที่จอดรถว่าง",
  i18nKey_2793: "สถิติการใช้พื้นที่จอดรถ",
  i18nKey_2794: "สถิติอัตราการใช้พื้นที่จอดรถ",
  i18nKey_2795: "สอบถามพื้นที่จอดรถ",
  i18nKey_2796: "ดัชนีป้ายทะเบียน",
  i18nKey_2797: "มีให้เลือกมากกว่า 6 ตัวเลือกสำหรับป้ายทะเบียนและสี",
  i18nKey_2798:
    "มีการแสดงตัวเลือกสำหรับป้ายทะเบียนและยี่ห้อมากกว่า 6 รายการให้คุณเลือกสิ่งที่ดีที่สุด",
  i18nKey_2799: "ไม่มีหมายเลขป้ายทะเบียน",
  i18nKey_2800: "ต้องระบุหมายเลขป้ายทะเบียน",
  i18nKey_2801: "หมายเลขป้ายทะเบียน",
  i18nKey_2802:
    "ป้ายทะเบียนมีมากกว่า 6 ตัวเลือก ซึ่งได้รับการแสดงสำหรับคุณตามการเลือกที่ดหมาะสมที่สุด",
  i18nKey_2803: "ดึงข้อมูลป้ายทะเบียน",
  i18nKey_2804: "มีให้เลือกมากกว่า 6 ป้ายทะเบียนยี่ห้อและสี",
  i18nKey_2805: "สถิติการจราจร",
  i18nKey_2806: "สีรถ",
  i18nKey_2807: "ข้อจำกัดของยานพาหนะ",
  i18nKey_2808: "จำนวนแพ็คเกจรถและค่าจอดรถ",
  i18nKey_2809: "จำนวนแพ็คเกจยานพาหนะ (รถยนต์)",
  i18nKey_2810: "รายละเอียดข้อมูลยานพาหนะ",
  i18nKey_2811: "อาณาเขตของยานพาหนะ",
  i18nKey_2812: "บันทึกการออกรถ",
  i18nKey_2813: "สีรถ",
  i18nKey_2814: "ใบรับรองรถ",
  i18nKey_2815: "ยี่ห้อยานพาหนะ",
  i18nKey_2816: "การวิเคราะห์ยานพาหนะ",
  i18nKey_2817: "ประเภทยานพาหนะเต็มเส้นทาง",
  i18nKey_2818: "การแจ้งเตือนการมาถึงของยานพาหนะ",
  i18nKey_2819: "ป้ายรถ",
  i18nKey_2820: "ยานพาหนะ",
  i18nKey_2821: "หมายเลขรถไฟ",
  i18nKey_2822: "ข้อจำกัดที่จอดรถ",
  i18nKey_2823: "ที่ตั้งคลัง:",
  i18nKey_2824: "ที่ตั้งคลัง",
  i18nKey_2825: "ชื่อคลัง:",
  i18nKey_2826: "ชื่อคลัง",
  i18nKey_2827: "สถิติที่จอดรถ",
  i18nKey_2828: "ลานจอดรถ",
  i18nKey_2829: "มากกว่า $ ครั้ง ไม่สามารถดำเนินการผ่านแบบรายเดือนได้",
  i18nKey_2830: "มากกว่า $ ครั้ง ไม่สามารถเข้าที่จอดรถได้",
  i18nKey_2831: "เกินขีดจำกัดการ export สูงสุด 2000",
  i18nKey_2832: "เกินขีดจำกัดการ export สูงสุด 10 รายการ",
  i18nKey_2833: "การอ่านที่ประสบความสำเร็จ",
  i18nKey_2834: "สำเนา",
  i18nKey_2835: "ต้องระบุวันที่อ่านมิเตอร์และค่ามิเตอร์พร้อมกัน",
  i18nKey_2836: "วันที่อ่านมิเตอร์",
  i18nKey_2837: "เครื่องอ่านมิเตอร์",
  i18nKey_2838: "ต้องไม่ว่างเปล่าระหว่างการอ่านมิเตอร์",
  i18nKey_2839: "ระหว่างการอ่านมิเตอร์",
  i18nKey_2840: "คัดลอกและบันทึก",
  i18nKey_2841: "คัดลอกและแสดงได้ดี",
  i18nKey_2842: "การนำเข้าค่าการอ่านมิเตอร์",
  i18nKey_2843: "จำนวนคนในสถานที่",
  i18nKey_2844: "ไอคอนฉาก",
  i18nKey_2845: "สวิตช์ล็อคฉาก:",
  i18nKey_2846: "จำนวนฉาก",
  i18nKey_2847: "ชื่อฉาก",
  i18nKey_2848: "ID ฉาก",
  i18nKey_2849: "ชื่อผู้จำหน่ายต้องไม่ว่างเปล่า",
  i18nKey_2850: "ชื่อการค้า",
  i18nKey_2851: "โรงงาน",
  i18nKey_2852: "เจ้าหน้าที่ประจำ",
  i18nKey_2853: "ธรรมดา",
  i18nKey_2854: "ชื่อผลิตภัณฑ์",
  i18nKey_2855: "ผลิตภัณฑ์",
  i18nKey_2856: "ลบการแจ้งเตือน",
  i18nKey_2857:
    "กรุณากรอกชื่อนามสกุลหรือหมายเลขโทรศัพท์มือถือของคุณสำหรับใช้ที่ลงทะเบียน",
  i18nKey_2858:
    "การค้นหาจำนวนข้อมูลอินเทอร์เฟซที่ไม่มีประสิทธิภาพเกิดข้อผิดพลาด โปรดลองอีกครั้งในภายหลัง",
  i18nKey_2859: "โปรดจำกัดช่วงเวลาการสืบค้นเป็น 90 วัน",
  i18nKey_2860: "รายการค้นหาล้มเหลว",
  i18nKey_2861: "ตรวจสอบราคาที่ลดแล้ว",
  i18nKey_2862: "ดูเกณฑ์คำเตือน",
  i18nKey_2863: "ดูส่วนลดในเวลาจำกัด",
  i18nKey_2864: "ดูภาพ",
  i18nKey_2865: "ดูวิดีโอ",
  i18nKey_2866: "ดูกล้อง",
  i18nKey_2867: "ดูอุปกรณ์",
  i18nKey_2868: "ดูแทร็ก",
  i18nKey_2869: "ตรวจสอบตารางเดินรถ",
  i18nKey_2870: "ดู",
  i18nKey_2871: "ชื่อนโยบายต้องไม่ว่างเปล่า",
  i18nKey_2872: "ชื่อนโยบายต้องไม่มีอักษรพิเศษ",
  i18nKey_2873: "ชื่อนโยบายต้องมีความยาวไม่เกิน 20 ตัวอักษร",
  i18nKey_2874: "ชื่อกลยุทธ์",
  i18nKey_2875: "การวัดอุณหภูมิ",
  i18nKey_2876: "เทียบเวลา",
  i18nKey_2877: "WC",
  i18nKey_2878: "ตัวดำเนินการ",
  i18nKey_2879: "การดำเนินการล้มเหลวโปรดลองอีกครั้งในภายหลัง",
  i18nKey_2880: "การดำเนินการล้มเหลว",
  i18nKey_2881: "ข้อเสนอแนะการดำเนินงาน",
  i18nKey_2882: "ร้านอาหาร",
  i18nKey_2883: "พารามิเตอร์",
  i18nKey_2884: "ข้อมูลอ้างอิงของช่วงเวลา",
  i18nKey_2885: "ยอดขายอาหาร 5 อันดับแรก",
  i18nKey_2886: "ความถี่ในการได้มา",
  i18nKey_2887: "สาขา:",
  i18nKey_2888: "สถานะการควบคุม",
  i18nKey_2889: "เวลาในการปรับใช้ต้องไม่ว่างเปล่า",
  i18nKey_2890: "เวลาในการปรับใช้ต้องมากกว่าหรือเท่ากับเวลาปัจจุบัน",
  i18nKey_2891: "ระยะเวลาควบคุม",
  i18nKey_2892: "กล้องควบคุม",
  i18nKey_2893: "ควบคุมการจับคู่",
  i18nKey_2894: "การควบคุมการปรับใช้",
  i18nKey_2895: "ไม่ทำซ้ำ",
  i18nKey_2896: "ไม่รองรับอักษรพิเศษรองรับเฉพาะภาษาจีน อังกฤษและตัวเลขเท่านั้น",
  i18nKey_2897: "ไม่จำกัดเวลา",
  i18nKey_2898: "ไม่จำกัด",
  i18nKey_2899: "การแจ้งเตือนที่ไม่แน่นอน",
  i18nKey_2900: "ไม่สามารถเปลี่ยนระดับได้ ระดับปัจจุบันคือ.....",
  i18nKey_2901: "ไม่สามารถส่งออกข้อมูลเปล่า",
  i18nKey_2902: "ไม่สามารถส่งออกข้อมูลมากกว่า 10,000 รายการ",
  i18nKey_2903: "ไม่สามารถเคลื่อนย้ายได้",
  i18nKey_2904: "ไม่สามารถเพิ่มเครือข่ายลูกได้",
  i18nKey_2905: "ไม่สามารถลบได้",
  i18nKey_2906: "ไม่สามารถแก้ไขได้",
  i18nKey_2907: "ไม่เท่ากับ",
  i18nKey_2908: "ช่วงเวลาบันทึกเสริม",
  i18nKey_2909: "การอ่านเสริม",
  i18nKey_2910: "BoYu Health Check-in_",
  i18nKey_2911: "ท่าเทียบเรือ",
  i18nKey_2912: "การตั้งค่าการเล่น:",
  i18nKey_2913: "เล่นโหมดเปลี่ยนหน้าต่าง",
  i18nKey_2914: "เล่น",
  i18nKey_2915: "วิลล่า",
  i18nKey_2916: "แบบฟอร์มเทมเพลต.xlsx",
  i18nKey_2917: "ด้านล่าง",
  i18nKey_2918: "การกรองแท็ก",
  i18nKey_2919: "ความจุหม้อแปลง (kVA)",
  i18nKey_2920: "เปลี่ยนเวลา",
  i18nKey_2921: "แก้ไขเกณฑ์คำเตือน",
  i18nKey_2922: "แก้ไข",
  i18nKey_2923: "การกำหนดหมายเลข",
  i18nKey_2924: "ต้องเป็นจำนวนเต็ม",
  i18nKey_2925: "ต้องเป็นทศนิยมจำนวนหนึ่งหรือสองตัว!",
  i18nKey_2926: "ในสัปดาห์นี้",
  i18nKey_2927: "ปริมาณการใช้ไฟฟ้าจริงในเดือนนี้ (กิโลวัตต์)",
  i18nKey_2928: "การใช้พลังงานสะสมในเดือนนี้",
  i18nKey_2929: "ภาพรวมการหลั่งไหลของผู้โดยสารในเดือนนี้",
  i18nKey_2930: "จองห้องประชุมเดือนนี้",
  i18nKey_2931: "เดือนนี้",
  i18nKey_2932: "พลังงานสำหรับรายการนี้",
  i18nKey_2933: "ปริมาณการใช้น้ำในช่วงนี้ (t)",
  i18nKey_2934: "การใช้พลังงานในช่วงนี้",
  i18nKey_2935: "การใช้ไฟฟ้าในปัจจุบัน (กิโลวัตต์ชั่วโมง)",
  i18nKey_2936: "การใช้ไฟฟ้าในปัจจุบัน",
  i18nKey_2937: "ไม่ได้คัดลอก / คัดลอกในช่วงนี้ (ก)",
  i18nKey_2938: "เวลาปัจจุบัน",
  i18nKey_2939: "การใช้พลังงานต่อตารางเมตรในช่วงนี้",
  i18nKey_2940: "ช่วงเวลานี้",
  i18nKey_2941: "ปีนี้",
  i18nKey_2942: "การใช้พลังงานในระดับนี้",
  i18nKey_2943: "อุณหภูมิในท้องถิ่น",
  i18nKey_2944: "ความชื้นในท้องถิ่น",
  i18nKey_2945: "สามารถอัปโหลดภาพถ่ายในเครื่องไม่เกิน 3M",
  i18nKey_2946: "เซิร์ฟเวอร์ภายใน",
  i18nKey_2947: "ท้องถิ่น",
  i18nKey_2948: "ยังมีตาราง $ ที่ไม่ได้ใส่ในครั้งนี้ คุณต้องการให้มีผลต่อไปไหม",
  i18nKey_2949: "เวลาอ่านมิเตอร์ต้องไม่ว่างเปล่า",
  i18nKey_2950: "วันที่อ่านมิเตอร์นี้",
  i18nKey_2951:
    "ปริมาณมิเตอร์จากการอ่านครั้งนี้มากกว่าการอ่านครั้งล่าสุดมาก คุณยืนยันการแก้ไขหรือไม่",
  i18nKey_2952: "ค่าการอ่านค่ามิเตอร์นี้ต้องไม่ว่างเปล่า",
  i18nKey_2953: "การอ่านมิเตอร์",
  i18nKey_2954: "จำนวนบริษัทที่สัมภาษณ์ทั้งหมด",
  i18nKey_2955: "การขยาย",
  i18nKey_2956:
    "ชื่อหมายเหตุต้องเป็นตัวอักษรภาษาจีนและขีดล่างภายใน 20 อักษรเท่านั้น",
  i18nKey_2957: "หมายเหตุชื่อ",
  i18nKey_2958: "อะไหล่ (ก)",
  i18nKey_2959: "ปักกิ่ง",
  i18nKey_2960: "ประเภทการซ่อม",
  i18nKey_2961: "ใบสั่งงานซ่อม",
  i18nKey_2962: "โทรหาตำรวจ",
  i18nKey_2963: "วันที่เสีย",
  i18nKey_2964: "ทิ้ง",
  i18nKey_2965: "รายงาน",
  i18nKey_2966: "วันที่รับประกัน",
  i18nKey_2967: "ทำความสะอาด",
  i18nKey_2968: "ราคาต่อหน่วยครึ่งวัน (หยวน / 4 ชั่วโมง)",
  i18nKey_2969: "รายละเอียดการสั่งซื้อพื้นที่สำนักงาน",
  i18nKey_2970: "โทรศัพท์สำนักงาน",
  i18nKey_2971: "เปลี่ยนหมายเลข",
  i18nKey_2972: "รถรับส่ง",
  i18nKey_2973: "เหตุผลในการเยี่ยมชม:",
  i18nKey_2974: "สีขาว",
  i18nKey_2975: "รายการที่อนุญาต",
  i18nKey_2976: "รายงานการสั่งซื้อรถบัส _",
  i18nKey_2977: "รายงานบันทึกการนั่งรถโดยสาร",
  i18nKey_2978: "ตารางเดินรถ",
  i18nKey_2979: "รายสัปดาห์",
  i18nKey_2980: "รายเดือน",
  i18nKey_2981: "โดยความเหมือน",
  i18nKey_2982: "ตามเวลา",
  i18nKey_2983: "ตรงเวลา",
  i18nKey_2984: "ตามวัน",
  i18nKey_2985: "แหล่งที่มาของปุ่ม",
  i18nKey_2986: "ตามปี",
  i18nKey_2987: "ดูตามรายการ",
  i18nKey_2988: "การติดตั้ง",
  i18nKey_2989: "สถานที่ติดตั้ง",
  i18nKey_2990: "เวลาติดตั้ง",
  i18nKey_2991: "ตัวติดตั้ง",
  i18nKey_2992: "จำนวนทรัพย์สิน RFID ที่ติดตั้ง",
  i18nKey_2993: "แนวโน้มเหตุการณ์ด้านความปลอดภัย",
  i18nKey_2994: "สถานการณ์ด้านความปลอดภัย",
  i18nKey_2995: "สัดส่วนประเภทของเหตุการณ์ด้านความปลอดภัย",
  i18nKey_2996: "เจ้าหน้าที่รักษาความปลอดภัย",
  i18nKey_2997: "ประเภท VCM",
  i18nKey_2998: "ประเภท URL",
  i18nKey_2999: "อัตราการใช้ RFID (ตามประเภท)",
  i18nKey_3000: "อัตราการใช้ RFID",
  i18nKey_3001: "หมายเลข RFID",
  i18nKey_3002: "หมายเลข PID",
  i18nKey_3003: "ทรัพยากรเมนู",
  i18nKey_3004: "เมนู",
  i18nKey_3005: "หมายเลขทางกายภาพ",
  i18nKey_3006: "ขีด จำกัด บน KPI",
  i18nKey_3007:
    "รหัสไอทีและรหัสธุรกิจต้องผสมกันระหว่างตัวอักษร ตัวเลขและขีดล่างเท่านั้น!",
  i18nKey_3008: "การเข้ารหัสไอที",
  i18nKey_3009: "ชื่อการจำแนกประเภทอุปกรณ์ IOC Class 1",
  i18nKey_3010: "รหัสการจำแนกประเภทอุปกรณ์ IOC Class I",
  i18nKey_3011: "ชื่อการจำแนกประเภทอุปกรณ์ IOC Class III",
  i18nKey_3012: "รหัสการจำแนกประเภทอุปกรณ์ IOC Class III",
  i18nKey_3013: "การเข้ารหัสแบบจำลองเชิงพื้นที่ IOC",
  i18nKey_3014: "ชื่อการจำแนกประเภทอุปกรณ์รองของ IOC",
  i18nKey_3015: "รหัสการจำแนกประเภทอุปกรณ์รองของ IOC",
  i18nKey_3016:
    "ข้อตกลงการให้บริการ IOC (Intelligence Operation Center หรือ Intelligent Operation Center)",
  i18nKey_3017: "ประเภทการประสานงาน GIS",
  i18nKey_3018: "ประสานงาน GIS",
  i18nKey_3019: "การซิงโครไนซ์ GIS",
  i18nKey_3020: "การซิงโครไนซ์ GIS",
  i18nKey_3021: "แผนภูมิ Echarts",
  i18nKey_3022: "กระแสเฟส C (A)",
  i18nKey_3023: "แรงดันไฟฟ้าเฟส B (V)",
  i18nKey_3024: "แผนที่แบนเนอร์",
  i18nKey_3025: "กระแสเฟส A (A)",
  i18nKey_3026: "การประมวลผลการวินิจฉัย AI",
  i18nKey_3027: "9 เดือน",
  i18nKey_3028:
    "ประกอบด้วยตัวอักษรตัวเลขและอักขระพิเศษอย่างน้อย 2 ตัวจาก 8-20 ตัว",
  i18nKey_3029: "8-20 หลัก ตัวเลข, ตัวอักษร, อักขระพิเศษบางตัว",
  i18nKey_3030: "ส่วนลด 6 เดือน",
  i18nKey_3031: "6 เดือน",
  i18nKey_3032: "ส่วนลด 3 เดือน",
  i18nKey_3033: "3 เดือน",
  i18nKey_3034: "1 ปีขึ้นไป",
  i18nKey_3035: "การอ่านค่า 0 จุด (กิโลวัตต์ชั่วโมง)",
  i18nKey_3036: "การอ่านค่า 0 จุด (กิโลวัตต์ชั่วโมง)",
  i18nKey_3037: "นโยบายความเป็นส่วนตัว",
  i18nKey_3038: "สัญญาการให้บริการ",
  i18nKey_3039: "* รองรับเฉพาะรูปภาพในรูปแบบ png, gif, jpg และมีขนาดไม่เกิน 3M",
  i18nKey_3040: "(การใช้พลังงานทั้งหมดกิโลวัตต์ชั่วโมง)",
  i18nKey_3041: "(ราคาแปลงอัตโนมัติ)",
  i18nKey_3042: "(หยวน)",
  i18nKey_3043: "(ปริมาณ)",
  i18nKey_3044: "(ตัวอย่าง: P85)",
  i18nKey_3045: "(สัดส่วนการสูญเสียกำลัง)",
  i18nKey_3046: "(จำเป็น)",
  i18nKey_3047: "ในโครงการทั้งหมดคุณพบไฟล์",
  i18nKey_3048: "มูลค่าจริง $ ปี",
  i18nKey_3049: "มูลค่าเป้าหมาย $ ปี",
  i18nKey_3050: "เปิด $ Loops ไม่สำเร็จ",
  i18nKey_3051: "เปิด $ Loops สำเร็จแล้ว",
  i18nKey_3052: "มกราคม",
  i18nKey_3053: "พฤษภาคม",
  i18nKey_3054: "เมษายน",
  i18nKey_3055: "ตุลาคม",
  i18nKey_3056: "พฤศจิกายน",
  i18nKey_3057: "ธันวาคม",
  i18nKey_3058: "มีนาคม",
  i18nKey_3059:
    "โปรดป้อนพิกัด GIS coordinates ที่ถูกต้อง เช่น (20.17N, 134.53W)",
  i18nKey_3060: "กรกฎาคม",
  i18nKey_3061: "มิถุนายน",
  i18nKey_3062: "กันยายน",
  i18nKey_3063: "กุมภาพันธ์",
  i18nKey_3064: "สิงหาคม",
  i18nKey_3065: "มีพิกัด GIS อยู่แล้ว กรุณาระบุใหม่",
  i18nKey_web_1: "ตัวอย่าง",
  i18nKey_web_2: "เลือกไฟล์",
  i18nKey_web_3: "อัปโหลดไฟล์ทั้งหมด",
  i18nKey_web_4: "คลิกที่ไฟล์เพื่อรับ download path",
  i18nKey_web_5: "แสดงผลแบบดาวเทียม",
  i18nKey_web_6: "แสดงผลแบบเมือง",
  i18nKey_web_7: "การจำลอง",
  i18nKey_web_8: "ตัวสร้างเหตุการณ์",
  i18nKey_web_9: "เวลา:",
  i18nKey_web_10: "ชื่อเหตุการณ์:",
  i18nKey_web_11: "คำอธิบายเหตุการณ์:",
  i18nKey_web_12: "ที่อยู่ของเหตุการณ์:",
  i18nKey_web_13: "อุปกรณ์เตือนภัย:",
  i18nKey_web_14: "แหล่งที่มาของเหตุการณ์:",
  i18nKey_web_15: "ระบบผู้เยี่ยมชม",
  i18nKey_web_16: "การควบคุมการเข้าถึง",
  i18nKey_web_17: "การดับเพลิง",
  i18nKey_web_18: "โลก",
  i18nKey_web_19: "ระดับเหตุการณ์:",
  i18nKey_web_20: "คำเตือนที่สำคัญ",
  i18nKey_web_21: "ประเภทเหตุการณ์:",
  i18nKey_web_22: "เหตุการณ์เพลิงไหม้",
  i18nKey_web_23: "เหตุการณ์ด้านความปลอดภัย",
  i18nKey_web_24: "สร้างเหตุการณ์",
  i18nKey_web_25: "บริษัท Vanke Technology",
  i18nKey_web_26: "สงวนลิขสิทธิ์",
  i18nKey_web_27: "ติดต่องานสนับสนุนด้านเทคนิค: 000-00000000",
  i18nKey_web_28: "อันดับแรก",
  i18nKey_web_29: "ตำแหน่งปัจจุบัน",
  i18nKey_web_30: "ทรัพย์สิน",
  i18nKey_web_31: "ข้อมูล",
  i18nKey_web_32: "ยกเลิกการปรับเปลี่ยนบุคลากร",
  i18nKey_web_33: "กำลังโหลดวิดีโอ",
  i18nKey_web_34: "กรุณารอสักครู่",
  i18nKey_web_35: "Video buffering",
  i18nKey_web_36: "ใช้เวลา",
  i18nKey_web_37: "กำจัด",
  i18nKey_web_38: "ยืนยัน",
  i18nKey_web_39:
    "หมายเหตุ: เหมาะสำหรับหน้าแสดงตารางที่ไม่มีส่วนประกอบซ้อนทับกันบนหน้าแสดงรายการ",
  i18nKey_web_40: "จำเป็นต้องผ่านในชื่อของคลาส",
  i18nKey_web_41: "เพื่อค้นหาสิ่งที่คุณต้องการ",
  i18nKey_web_42: "องค์ประกอบ",
  i18nKey_web_43: "ต้นแบบ",
  i18nKey_web_44: "ต้องตั้งค่าคุณสมบัติค่าเริ่มต้น",
  i18nKey_web_45: "ต้องกำหนดป้ายชื่อด้านนอกสุด",
  i18nKey_web_46: "การตัดสินผิดพลาด",
  i18nKey_web_47: "รายงาน",
  i18nKey_web_48: "เวลา",
  i18nKey_web_49: "สถานที่",
  i18nKey_web_50: "อาคารหลังหนึ่งใน Vanke Chengdu Park",
  i18nKey_web_51: "รายงานคู่มือการลาดตระเวนแบบวิดีโอ",
  i18nKey_web_52: "ระดับ",
  i18nKey_web_53: "คำเตือนฉุกเฉินสีแดง",
  i18nKey_web_54: "คำเตือนฉุกเฉินสีส้ม",
  i18nKey_web_55: "คำเตือนฉุกเฉินสีเหลือง",
  i18nKey_web_56: "คำเตือนฉุกเฉินสีน้ำเงิน",
  i18nKey_web_57: "คำอธิบาย",
  i18nKey_web_58: "อุบัติเหตุร้ายแรงโดยเฉพาะ",
  i18nKey_web_59: "อุบัติเหตุใหญ่",
  i18nKey_web_60: "อุบัติเหตุใหญ่",
  i18nKey_web_61: "อุบัติเหตุทั่วไป",
  i18nKey_web_62: "จำเป็น กรุณากรอกเนื้อหา",
  i18nKey_web_63: "กรุณากรอกชื่อหรือหมายเลขงาน",
  i18nKey_web_64: "ไม่มีข้อมูล",
  i18nKey_web_65: "ปรับเปลี่ยน",
  i18nKey_web_66: "งาน",
  i18nKey_web_67: "การใช้พลังงานสะสมตามรายการ",
  i18nKey_web_68: "เลือกกล้อง",
  i18nKey_web_69: "กล่องนิรภัย",
  i18nKey_web_70: "หน่วย:",
  i18nKey_web_71: "หน่วย: ชิ้น",
  i18nKey_web_72: "หน่วย: คน - เวลา",
  i18nKey_web_73: "ข้อมูลที่เกี่ยวข้องมีความผิดปกติและไม่สามารถตรวจสอบได้",
  i18nKey_web_74: "ดูรายละเอียด",
  i18nKey_web_75: "ประตูทิศเหนือ",
  i18nKey_web_76: "ประตูตะวันออก",
  i18nKey_web_77: "ความปลอดภัยของทรัพย์สิน",
  i18nKey_web_78: "สถิติแผนภูมิวงกลมเดี่ยว",
  i18nKey_web_79: "ย่อเล็กสุด",
  i18nKey_web_80: "ขยายใหญ่สุด",
  i18nKey_web_81:
    "โมเดลไม่ได้ติดตั้งใน park กรุณาติดต่อผู้ดูแลระบบเพื่อติดโมเดล!",
  i18nKey_web_82: "วันสุดท้าย",
  i18nKey_web_83: "เลือกอุปกรณ์ที่เกี่ยวข้อง",
  i18nKey_web_84: "รหัสอุปกรณ์",
  i18nKey_web_85: "การใช้พลังงานในพื้นที่สาธารณะ",
  i18nKey_web_86: "วันหลัง",
  i18nKey_web_87: "ปีที่แล้ว",
  i18nKey_web_88: "การใช้พลังงานทั้งหมด",
  i18nKey_web_89: "การใช้พลังงานในพื้นที่สาธารณะ",
  i18nKey_web_90: "พื้นที่สาธารณะ",
  i18nKey_web_91: "เปอร์เซ็นต์",
  i18nKey_web_92: "มากกว่า",
  i18nKey_web_93: "ช่วงเวลาเดียวกัน",
  i18nKey_web_94: "หน่วย",
  i18nKey_web_95: "ลบภาพ",
  i18nKey_web_96: "คำแนะนำ: สนับสนุนเท่านั้น",
  i18nKey_web_97: "อัปโหลดรูปภาพตามรูปแบบ ขนาดของรูปภาพที่อัปโหลดไม่เกิน 3",
  i18nKey_web_98: "ขนาดของรูปภาพ",
  i18nKey_web_99: "ประเภทของรูปภาพ",
  i18nKey_web_100: "เวลาสร้าง",
  i18nKey_web_101: "รูปภาพที่อัปโหลดต้องมีขนาดไม่เกิน 3",
  i18nKey_web_102: "กรุณาอัปโหลดอีกครั้ง",
  i18nKey_web_103: "ปริมาณ",
  i18nKey_web_104: "การอัดประจุรวมสะสม",
  i18nKey_web_105: "การคายประจุรวมสะสม",
  i18nKey_web_106: "กรุณากรอกชื่ออุปกรณ์",
  i18nKey_web_107: "รายการย่อยที่เลือก",
  i18nKey_web_108: "รอบการเฝ้าระวัง",
  i18nKey_web_109: "กรุณากรอกค่าขั้นต่ำ",
  i18nKey_web_110: "กรุณากรอกค่าสูงสุด",
  i18nKey_web_111: "บันทึกสำเร็จ!",
  i18nKey_web_112: "ดำเนินการ",
  i18nKey_web_113: "เลือกจอภาพที่เกี่ยวข้อง",
  i18nKey_web_114: "เลือก smoke sensor ที่เกี่ยวข้อง",
  i18nKey_web_115: "ประเภทธุรกิจ",
  i18nKey_web_116: "หมายเลขโทรศัพท์",
  i18nKey_web_117: "กรุณากรอกหมายเลขโทรศัพท์ของคุณ",
  i18nKey_web_118: "เพิ่ม",
  i18nKey_web_119:
    "ชื่อกลยุทธ์ประกอบด้วยอักขระน้อยกว่า 20 ตัว เส้นเชื่อม และขีดล่าง",
  i18nKey_web_120: "ระยะเวลาการตรวจสอบ",
  i18nKey_web_121: "การจราจรของมนุษย์",
  i18nKey_web_122: "ตรวจจับการสูบบุหรี่",
  i18nKey_web_123: "เกณฑ์การเฝ้าระวัง",
  i18nKey_web_124: "ตรวจสอบการเชื่อมต่อผู้ต้องสงสัย",
  i18nKey_web_125: "กรุณาเลือกจอภาพ",
  i18nKey_web_126: "ระบุจำนวนคน",
  i18nKey_web_127:
    "จำนวนคนกรอกได้เฉพาะจำนวนเต็มบวกที่มากกว่า 0 และน้อยกว่าหรือเท่ากับ 99999",
  i18nKey_web_128: "เกณฑ์การเตือนความเข้มข้นของควัน",
  i18nKey_web_129: "ควันที่เกี่ยวข้อง",
  i18nKey_web_130: "กรุณาเลือก sensor ควัน",
  i18nKey_web_131: "กรุณากรอกเกณฑ์การเตือนภัย",
  i18nKey_web_132:
    "เกณฑ์สามารถกรอกจำนวนเต็มบวกที่มากกว่า 0 และน้อยกว่าหรือเท่ากับ 5,000 เท่านั้น",
  i18nKey_web_133: "นี่คือคำใบ้",
  i18nKey_web_134: "คำแนะนำ",
  i18nKey_web_135: "ความเข้มข้น",
  i18nKey_web_136: "ควันปานกลาง",
  i18nKey_web_137: "บุหรี่",
  i18nKey_web_138: "ดาวน์โหลดรูปภาพ",
  i18nKey_web_139: "ดูการเตือนภัย",
  i18nKey_web_140: "ไม่มีข้อมูลที่มีสิทธิ์ชั่วคราว",
  i18nKey_web_141: "กรุณาใส่คำอธิบาย",
  i18nKey_web_142: "อัปโหลดวิดีโอลาดตระเวนแบบ Manual",
  i18nKey_web_143: "การจัดส่งใบสั่งงาน",
  i18nKey_web_144: "เลือกบุคลากร",
  i18nKey_web_145: "จัดส่งใบสั่งงาน",
  i18nKey_web_146: "ใส่ชื่อหรือหมายเลขงาน",
  i18nKey_web_147: "ชื่อการเตือนภัย",
  i18nKey_web_148: "สถานะการเตือนภัย",
  i18nKey_web_149: "ระดับคำเตือน",
  i18nKey_web_150: "เวลาเตือนภัย",
  i18nKey_web_151: "มีความรับผิดชอบ",
  i18nKey_web_152: "วางแผนเวลาเริ่มต้น",
  i18nKey_web_153: "กรุณาเลือกเวลาเริ่มต้นที่วางแผนไว้",
  i18nKey_web_154: "เวลาสิ้นสุดของแผน",
  i18nKey_web_155: "กรุณาเลือกเวลาสิ้นสุดของแผน",
  i18nKey_web_156: "ตำแหน่งการเตือนภัย",
  i18nKey_web_157: "ก่อนหน้า",
  i18nKey_web_158: "ส่งงาน",
  i18nKey_web_159: "ต้องเลือกบุคคล",
  i18nKey_web_160: "ตั๋วใหม่",
  i18nKey_web_161: "ประเภทใบสั่งงาน",
  i18nKey_web_162: "คำอธิบายรายละเอียดงาน",
  i18nKey_web_163: "กรุณากรอกคำอธิบายของงานโดยละเอียด",
  i18nKey_web_164: "อุปกรณ์ที่เกี่ยวข้อง",
  i18nKey_web_165: "กรุณาเลือกอุปกรณ์ที่เกี่ยวข้อง",
  i18nKey_web_166: "ระดับงาน",
  i18nKey_web_167: "กรุณาเลือกระดับงาน",
  i18nKey_web_168: "กรุณาเลือกผู้รับผิดชอบ",
  i18nKey_web_169: "กรุณาเลือกเวลาที่จะเกิดขึ้น",
  i18nKey_web_170: "กรุณาเลือกผู้รับมอบสิทธิ์ใบสั่งงาน",
  i18nKey_web_171: "ติดต่อไซต์",
  i18nKey_web_172: "กรุณาเลือกผู้ติดต่อในสถานที่",
  i18nKey_web_173: "สร้างสำเร็จ",
  i18nKey_web_174: "กรอกแบบฟอร์มด้วยตนเอง",
  i18nKey_web_175: "จำเป็นต้องกรอกคำอธิบายรายละเอียดงาน",
  i18nKey_web_176: "ความยาว",
  i18nKey_web_177: "ถึง",
  i18nKey_web_178: "อักขระ",
  i18nKey_web_179: "สิ่งอำนวยความสะดวกที่เกี่ยวข้อง",
  i18nKey_web_180: "สร้างตั๋วใหม่ไม่สำเร็จ",
  i18nKey_web_181: "ใบสั่งงาน",
  i18nKey_web_182: "การจัดส่งใบสั่งงานเสร็จสมบูรณ์",
  i18nKey_web_183: "ไม่มีข้อมูล",
  i18nKey_web_184: "ไม่มีอุปกรณ์ที่เหนือกว่าอุปกรณ์ชิ้นนี้",
  i18nKey_web_185: "กรุณาเลือกสถานที่",
  i18nKey_web_186: "กรุณากรอกผู้รับมอบสิทธิ์ใบสั่งงาน",
  i18nKey_web_187: "กรุณากรอกที่อยู่ติดต่อของไซต์",
  i18nKey_web_188: "ตัวเลือกที่ 1",
  i18nKey_web_189: "เค้กทองคำ",
  i18nKey_web_190: "ทางเลือกที่ 2",
  i18nKey_web_191: "ขนมคัสตาร์ดนมสองชั้น",
  i18nKey_web_192: "ตัวเลือก 3",
  i18nKey_web_193: "หอยทอด",
  i18nKey_web_194: "ทางเลือกที่ 4",
  i18nKey_web_195: "บะหมี่เครามังกร",
  i18nKey_web_196: "เวลาเริ่มต้นจริง",
  i18nKey_web_197: "เวลาสิ้นสุดจริง",
  i18nKey_web_198: "เวลาในการประมวลผลใบสั่งงาน",
  i18nKey_web_199: "รายละเอียดของผู้ตรวจสอบ",
  i18nKey_web_200: "ตำแหน่ง",
  i18nKey_web_201: "สถานการณ์ในงาน",
  i18nKey_web_202: "จำนวนใบสั่งงานทั้งหมด",
  i18nKey_web_203: "กำลังประมวลผล",
  i18nKey_web_204: "การเปลี่ยนแปลงของเหตุการณ์",
  i18nKey_web_205: "แจ้งบุคลากร อพยพบุคลากรในอาคาร",
  i18nKey_web_206: "กด 119, 120",
  i18nKey_web_207: "ติดต่อกับทีมฉุกเฉินและเริ่มปรับใช้การอพยพ",
  i18nKey_web_208:
    "เกิดสัญญาณเตือนบนอุปกรณ์ตรวจจับควันที่ชั้น 1 อาคาร 1, Chengdu Park",
  i18nKey_web_209: "อัปโหลดจากเครื่อง local",
  i18nKey_web_210: "เลือกจากคลังเจ้าหน้าที่",
  i18nKey_web_211: "อัปโหลดไปยังเซิร์ฟเวอร์",
  i18nKey_web_212: "บุคลากร",
  i18nKey_web_213: "ทะยาน",
  i18nKey_web_214: "เกาเจิ้งเว่ย",
  i18nKey_web_215: "เกาหลินเฟย",
  i18nKey_web_216: "หลี่จวงเย่",
  i18nKey_web_217: "เหยาหยุน",
  i18nKey_web_218: "avatar",
  i18nKey_web_219: "ป้าย",
  i18nKey_web_220: "กรอกชื่อหรือหมายเลข",
  i18nKey_web_221: "ควัน",
  i18nKey_web_222: "กลับไปที่ Layer อื่นๆ",
  i18nKey_web_223: "รหัส",
  i18nKey_web_224: "กลับสู่ Background",
  i18nKey_web_225: "Chengdu Vanke park ชั้นที่ 1",
  i18nKey_web_226: "เครื่องปรับอากาศหน่วยอากาศบริสุทธิ์ของท้องถิ่น",
  i18nKey_web_227: "ระดับ",
  i18nKey_web_228: "ระบบระบายความร้อน",
  i18nKey_web_229: "พื้นที่",
  i18nKey_web_230: "Chengdu Vanke Park",
  i18nKey_web_231: "ระบบการเป็นเจ้าของ",
  i18nKey_web_232: "ระบบปรับอากาศ",
  i18nKey_web_233: "หลินเซิง",
  i18nKey_web_234: "คลาสย่อย",
  i18nKey_web_235: "ตัวยึดคลาสย่อย",
  i18nKey_web_236: "ข้อความตัวอย่างของคำอธิบายสิ่งอำนวยความสะดวก",
  i18nKey_web_237: "งานใหม่",
  i18nKey_web_238: "รายละเอียดภารกิจ",
  i18nKey_web_239: "ของฉัน",
  i18nKey_web_240: "ยกเลิกการลิงก์",
  i18nKey_web_241: "เชื่อมต่อแล้ว",
  i18nKey_web_242: "กล้องที่เชื่อมโยงกับเหตุการณ์",
  i18nKey_web_243: "กล้องรอบข้าง",
  i18nKey_web_244: "กล้องที่เกี่ยวข้อง",
  i18nKey_web_245: "บันทึกการประมวลผล",
  i18nKey_web_246: "แจ้งพนักงานและอพยพบุคลากรในอาคาร",
  i18nKey_web_247: "รายละเอียดการแจ้งพนักงานและอพยพบุคลากรในอาคาร",
  i18nKey_web_248: "ผู้สร้าง:",
  i18nKey_web_249: "ใหญ่",
  i18nKey_web_250: "เล็ก:",
  i18nKey_web_251: "เวลาเกิดเหตุการณ์",
  i18nKey_web_252: "สถานที่เกิดเหตุการณ์",
  i18nKey_web_253: "ได้รับแล้ว",
  i18nKey_web_254: "กำหนดใหม่",
  i18nKey_web_255: "เวลาปิดงาน",
  i18nKey_web_256: "เวลามอบหมายงาน",
  i18nKey_web_257: "ผู้รับมอบหมายงาน",
  i18nKey_web_258: "Live feedback",
  i18nKey_web_259: "Task โน๊ต",
  i18nKey_web_260: "รายการ Task",
  i18nKey_web_261: "ดูเพิ่มเติม",
  i18nKey_web_262: "สถานการณ์ที่ครอบคลุมระดับชาติ",
  i18nKey_web_263: "การ์ดแสดงรายการสถิติ",
  i18nKey_web_264: "สถานการณ์ที่ครอบคลุม",
  i18nKey_web_265: "สถิติเหตุการณ์ด้านความปลอดภัย",
  i18nKey_web_266: "การแสดงสถานการณ์ที่ครอบคลุม",
  i18nKey_web_267: "กรุณาเลือกไม่น้อยกว่า",
  i18nKey_web_268: "กรุณากรอกชื่อมิเตอร์",
  i18nKey_web_269: "มิเตอร์ไฟฟ้า",
  i18nKey_web_270: "มิเตอร์น้ำ",
  i18nKey_web_271: "กรุณากรอกชื่อมิเตอร์",
  i18nKey_web_272: "การใช้พลังงานในปัจจุบัน",
  i18nKey_web_273: "เดือนต่อเดือน",
  i18nKey_web_274: "ปีต่อปี",
  i18nKey_web_275: "ไฟฟ้าของผู้เช่า",
  i18nKey_web_276: "ไฟฟ้าสำหรับแสงสว่าง",
  i18nKey_web_277: "การใช้พลังงานทั้งหมดในแต่ละวัน",
  i18nKey_web_278: "กรอกหมายเลข Event",
  i18nKey_web_279: "ยืนยันรหัสผ่าน",
  i18nKey_web_280: "รหัสผ่าน",
  i18nKey_web_281: "กรุณาเลือกผู้มอบหมายงาน",
  i18nKey_web_282: "เวลาเริ่มต้นต้องไม่เป็นค่าว่าง",
  i18nKey_web_283: "เวลาเริ่มสิ้นสุดต้องไม่เป็นค่าว่าง",
  i18nKey_web_284: "การโหลดเครือข่ายผิดปกติ",
  i18nKey_web_285: "เบอร์โทรศัพท์เคลื่อนที่",
  i18nKey_web_286: "กล่องจดหมาย",
  i18nKey_web_287: "อายุ",
  i18nKey_web_288: "อุตสาหกรรม",
  i18nKey_web_289: "ยินดีต้อนรับ",
  i18nKey_web_290: "หน้าจอต้อนรับ",
  i18nKey_web_291: "จำเป็นต้องกรอกเหตุผลในการควบคุม",
  i18nKey_web_292: "กรุณาเลือกตัวผลักดัน",
  i18nKey_web_293: "กรุณาเลือกหน้าจอต้อนรับการควบคุมการปรับใช้",
  i18nKey_web_294: "ข้อปลีกย่อยของบุคลากร",
  i18nKey_web_295: "ภาพ",
  i18nKey_web_296: "ตำแหน่งของบุคลากร",
  i18nKey_web_297: "ข้อมูลยานพาหนะ",
  i18nKey_web_298: "ข้อความแจ้งเตือน",
  i18nKey_web_299: "ข้ามไปที่มุมมองแทร็ก",
  i18nKey_web_300: "ที่ตั้งของ park",
  i18nKey_web_301: "ป้ายรูปภาพ",
  i18nKey_web_302: "ลบ basemap",
  i18nKey_web_303: "ครั้ง",
  i18nKey_web_304: "เครื่องหมาย",
  i18nKey_web_305: "ความคล้ายคลึงกัน:",
  i18nKey_web_306: "แสดงเฉพาะใบหน้าที่มีความคล้ายคลึงกันสูงซึ่งกรองโดยระบบ",
  i18nKey_web_307: "แหล่งข้อมูล:",
  i18nKey_web_308: "ไม่มีข้อมูลผู้ต้องสงสัย",
  i18nKey_web_309: "การดำเนินการนี้จะลบ face map โดยสมบูรณ์",
  i18nKey_web_310: "กรอกรายการ blacklist ของ park เพื่อปรับใช้การควบคุม",
  i18nKey_web_311: "การควบคุมการปรับใช้ blacklist ของ park",
  i18nKey_web_312: "ภาพบุคคล",
  i18nKey_web_313: "ข้อมูลใบหน้า",
  i18nKey_web_314: "ข้อมูลผู้ต้องสงสัย",
  i18nKey_web_315: "ยกเลิกการปรับใช้",
  i18nKey_web_316: "ยกเลิกการควบคุมการปรับใช้blacklistของ park",
  i18nKey_web_317: "สถานะอินเทอร์เฟซผิดปกติ",
  i18nKey_web_318: "บุคคลมีเครื่องหมายอย่างน้อยหนึ่งรายการ",
  i18nKey_web_319: "ไม่สามารถแก้ไขได้",
  i18nKey_web_320: "การแก้ไขล้มเหลว สถานะอินเทอร์เฟซผิดปกติ",
  i18nKey_web_321: "ไม่สามารถแทนที่แผนที่ฐาน",
  i18nKey_web_322: "บุคคลนี้เข้าิblacklist",
  i18nKey_web_323: "บุคคลนี้ยกเลิกการควบคุมิblacklist",
  i18nKey_web_324: "การดำเนินการล้มเหลว",
  i18nKey_web_325: "การทำงานล้มเหลว สถานะอินเทอร์เฟซผิดปกติ",
  i18nKey_web_326: "ระยะเวลาการติดตาม",
  i18nKey_web_327: "ถึง",
  i18nKey_web_328: "สถานะการติดตาม",
  i18nKey_web_329: "กรุณาเลือกสถานะการติดตาม",
  i18nKey_web_330: "กล้องติดตาม",
  i18nKey_web_331: "กรุณาเลือกกล้องติดตาม",
  i18nKey_web_332: "ลบข้อมูลเรียบร้อยแล้ว",
  i18nKey_web_333: "การดำเนินงานที่ประสบความสำเร็จ",
  i18nKey_web_334: "การโหลดเครือข่ายผิดปกติ กรุณาลองอีกครั้งในภายหลัง",
  i18nKey_web_335: "ไม่มีคำเตือน",
  i18nKey_web_336: "หมายเลขประจำตัว",
  i18nKey_web_337: "แหล่งข้อมูล",
  i18nKey_web_338: "โรเบิร์ต",
  i18nKey_web_339: "ตื่นนอนตอนตีสาม",
  i18nKey_web_340: "พบว่าดอกบีโกเนียนั้นไม่ได้กำลังนอนหลับ",
  i18nKey_web_341: "รายงานการประชุม วันพรุ่งนี้ 11:30 น",
  i18nKey_web_342: "รายงานการประชุม วันพรุ่งนี้ 14:30 น",
  i18nKey_web_343: "เบราว์เซอร์ปัจจุบันไม่รองรับ",
  i18nKey_web_344: "ถึงหน้าต่างการแจ้งเตือนแล้ว",
  i18nKey_web_345: "สถานะการแจ้งเตือน",
  i18nKey_web_346: "กล้องเตือนการมาถึง",
  i18nKey_web_347: "เวลาการต้อนรับ",
  i18nKey_web_348: "สถานะการต้อนรับ",
  i18nKey_web_349: "กรุณาเลือกสถานะการต้อนรับ",
  i18nKey_web_350: "หน้าจอต้อนรับการควบคุมการปรับใช้",
  i18nKey_web_351: "เข้าสู่หน้าจอต้อนรับ",
  i18nKey_web_352: "กรอกชื่อของหน้าจอต้อนรับ",
  i18nKey_web_353: "เลือกหน้าจอต้อนรับ",
  i18nKey_web_354: "การปรับเปลี่ยนข้อมูลยานพาหนะ",
  i18nKey_web_355: "เจ้าของรถ",
  i18nKey_web_356: "จำนวนคำที่กรอกต้องไม่เกิน 300",
  i18nKey_web_357: "ยกเลิกคำขอแก้ไขนี้",
  i18nKey_web_358: "รายละเอียดบันทึกการระงับ",
  i18nKey_web_359: "ซ้าย",
  i18nKey_web_360: "การจอดรถ",
  i18nKey_web_361: "เวลาจอดรถ",
  i18nKey_web_362: "ข้อมูลการจอดรถ",
  i18nKey_web_363: "พื้นที่ว่างในการจอดรถ",
  i18nKey_web_364: "เล่นบันทึก",
  i18nKey_web_365: "บันทึกการเช็คอิน",
  i18nKey_web_366: "บันทึกรายละเอียด",
  i18nKey_web_367: "ชื่อกล้อง",
  i18nKey_web_368: "คนเช็คอิน",
  i18nKey_web_369: "เวลาเช็คอิน",
  i18nKey_web_370: "ไม่เข้างาน",
  i18nKey_web_371: "กลุ่มลาดตระเวน XX",
  i18nKey_web_372: "เวลาเล่น",
  i18nKey_web_373: "ดูตอนนี้",
  i18nKey_web_374: "ที่จะดำเนินการ",
  i18nKey_web_375: "เส้นทางที่แน่นอน",
  i18nKey_web_376: "ออกจากพื้นที่ปลอดภัย",
  i18nKey_web_377: "เป็นก้าวแรก",
  i18nKey_web_378: "Yue Wanli Guanshan",
  i18nKey_web_379: "เอาชนะความยากลำบากนับพัน",
  i18nKey_web_380: "ไม่มีการถอยหลังของนักสู้",
  i18nKey_web_381: "ไม่มีอันตรายข้างหน้าของความกล้าหาญ",
  i18nKey_web_382: "ความสามัคคี",
  i18nKey_web_383: "ให้สิทธิ์",
  i18nKey_web_384: "มันไม่เกี่ยวกับความแข็งแรง",
  i18nKey_web_385: "ขึ้นอยู่กับระยะเวลาที่สามารถอยู่ได้",
  i18nKey_web_386: "เพื่อหาหนทางสู่ความสำเร็จ",
  i18nKey_web_387: "ไม่มีเหตุผลสำหรับความล้มเหลว",
  i18nKey_web_388: "หนทางอีกยาวไกล",
  i18nKey_web_389: "ทะเยอทะยาน",
  i18nKey_web_390: "ขวานที่ไม่มีความสุขเท่านั้น",
  i18nKey_web_391: "ไม่มีฟืนที่แยกไม่ออก",
  i18nKey_web_392: "เวลารายงานล่าสุด",
  i18nKey_web_393: "ชื่อทรัพย์สิน",
  i18nKey_web_394: "ปัจจุบัน",
  i18nKey_web_395: "เมื่อไหร่",
  i18nKey_web_396: "บน",
  i18nKey_web_397: "ไม่มีข้อมูลเพิ่มเติม",
  i18nKey_web_398: "สัปดาห์ที่ 1",
  i18nKey_web_399: "ไฟฟ้า",
  i18nKey_web_400: "สถิติพลังงานของอาคาร",
  i18nKey_web_401: "สถิติการใช้พลังงานของผู้เช่าเชิงพาณิชย์",
  i18nKey_web_402: "ในเวลาเดียวกัน",
  i18nKey_web_403: "การใช้พลังงานทั้งหมด",
  i18nKey_web_404: "ไม่มีข้อมูล",
  i18nKey_web_405: "วันที่สร้าง",
  i18nKey_web_406: "ลบ",
  i18nKey_web_407: "การโหลดเครือข่ายผิดปกติ กรุณาลองอีกครั้งในภายหลัง!",
  i18nKey_web_408: "ยกเลิกสำเร็จ!",
  i18nKey_web_409: "รหัสห้องประชุม",
  i18nKey_web_410: "กรุณากรอกรหัสห้องประชุม",
  i18nKey_web_411: "ปฏิบัติการสำเร็จ!",
  i18nKey_web_412: "การโหลดเครือข่ายผิดปกติ กรุณาลองอีกครั้งในภายหลัง!",
  i18nKey_web_413: "ลบสำเร็จ",
  i18nKey_web_414: "เลือกวันที่",
  i18nKey_web_415: "กรุณาเลือกวันที่",
  i18nKey_web_416: "รายชื่อห้องประชุม",
  i18nKey_web_417: "ในวันอาทิตย์",
  i18nKey_web_418: "คนจอง",
  i18nKey_web_419: "เปิดประตู",
  i18nKey_web_420: "ปิดประตู",
  i18nKey_web_421: "เวลาปลดล็อก",
  i18nKey_web_422:
    "การดำเนินการล้มเหลว และไม่มีบันทึกการเปิดประตูในหน้าปัจจุบัน!",
  i18nKey_web_423: "การกำหนดค่า",
  i18nKey_web_424: "วันที่",
  i18nKey_web_425: "ยังไม่มีข้อมูลที่จะส่งออก!",
  i18nKey_web_426: "เช็คอินสุขภาพ BoYu",
  i18nKey_web_427: "รายงานตำรวจเกี่ยวกับการแพร่ระบาด",
  i18nKey_web_428: "การเริ่มงานแต่ละวันสำหรับธุรกิจ",
  i18nKey_web_429: "แน่ใจหรือว่าจะลบ ไม่สามารถกู้คืนได้หลังจากลบ!",
  i18nKey_web_430: "กรุณากรอกชื่อบุคคล",
  i18nKey_web_431: "กรุณากรอกหมายเลขติดต่อในรูปแบบที่ถูกต้อง",
  i18nKey_web_432: "แก้ไขสมาชิกในทีม",
  i18nKey_web_433: "รองรับเฉพาะตัวพิมพ์และตัวเลขภาษาจีนและอังกฤษ",
  i18nKey_web_434: "กรุณาเลือกระดับสิ่งอำนวยความสะดวก",
  i18nKey_web_435: "การจำแนกประเภทสิ่งอำนวยความสะดวก",
  i18nKey_web_436: "กรุณากรอกบุคคลที่รับผิดชอบในการจัดการ",
  i18nKey_web_437: "กรุณากรอกคำอธิบายสิ่งอำนวยความสะดวก",
  i18nKey_web_438: "สถานะอุปกรณ์",
  i18nKey_web_439: "สัญญาณเตือนไฟไหม้อัตโนมัติและระบบเชื่อมโยง",
  i18nKey_web_440: "เครื่องแม่ข่ายสัญญาณเตือนไฟไหม้",
  i18nKey_web_441: "เครื่องตรวจจับควัน",
  i18nKey_web_442: "เครื่องตรวจจับไฟความร้อน",
  i18nKey_web_443: "ปุ่มเตือนภัยด้วยตนเอง",
  i18nKey_web_444: "ขีดจำกัดบน",
  i18nKey_web_445: "ความพร้อมใช้งาน",
  i18nKey_web_446: "อัตราความล้มเหลว",
  i18nKey_web_447: "จำนวนสัญญาณเตือนทั้งหมด",
  i18nKey_web_448: "จัดจำหน่าย",
  i18nKey_web_449: "จัดจำำหน่ายเรียบร้อยแล้ว",
  i18nKey_web_450: "สิ่งกีดขวาง",
  i18nKey_web_451: "ข้อผิดพลาดของเครือข่าย",
  i18nKey_web_452: "กล้อง 001",
  i18nKey_web_453: "เริ่มต้น",
  i18nKey_web_454: "ไม่มีเหตุการณ์ที่เกี่ยวข้อง",
  i18nKey_web_455: "ความสำเร็จของการตัดสินใจผิดพลาด",
  i18nKey_web_456: "คำอธิบายเหตุการณ์ต้องไม่เกิน 1,000 อักขระ",
  i18nKey_web_457:
    "สถานะเหตุการณ์อยู่ระหว่างรอดำเนินการและไม่สามารถรายงานได้ชั่วคราว",
  i18nKey_web_458: "สถานะเหตุการณ์กำลังประมวลผลและไม่สามารถรายงานได้ชั่วคราว",
  i18nKey_web_459:
    "สถานะเหตุการณ์อยู่ระหว่างรอดำเนินการและไม่สามารถรายงานได้ชั่วคราว",
  i18nKey_web_460: "กำลังเริ่มการติดตามใบหน้า",
  i18nKey_web_461: "การควบคุมการปรับใช้ใบหน้าใหม่",
  i18nKey_web_462: "ไลบรารีใหม่",
  i18nKey_web_463: "ลบไลบรารี",
  i18nKey_web_464: "มากกว่า",
  i18nKey_web_465: "ค้นหาตามชื่อ",
  i18nKey_web_466: "เลือกทั้งหมด",
  i18nKey_web_467: "คลิกที่โหนดเพื่อเลือก",
  i18nKey_web_468: "ขยายโหนดทั้งหมดตามค่าเริ่มต้น",
  i18nKey_web_469: "รองรับเท่านั้น",
  i18nKey_web_470: "จัดรูปแบบรูปภาพและไม่เกิน 3",
  i18nKey_web_471: "กรุณาพิมพ์ชื่อของคุณ",
  i18nKey_web_472: "ประเภทรายการ",
  i18nKey_web_473: "กรุณากรอกหมายเลขประจำตัว",
  i18nKey_web_474: "ชาย",
  i18nKey_web_475: "หญิง",
  i18nKey_web_476: "กรุณากรอกที่อยู่ลูกบ้าน",
  i18nKey_web_477: "ขยายส่วนทั้งหมดตามค่าเริ่มต้น",
  i18nKey_web_478: "กรุณาเลือกประเภทรายการ",
  i18nKey_web_479: "รองรับรูปภาพเท่านั้น",
  i18nKey_web_480: "รูปภาพต้องมีขนาดไม่เกิน 3",
  i18nKey_web_481: "เทมเพลตการนำเข้า blacklist ด้านความปลอดภัยสาธารณะ",
  i18nKey_web_482: "โต๊ะ",
  i18nKey_web_483: "ลบไม่สำเร็จ",
  i18nKey_web_484: "กรุณาเลือกวัตถุที่จะลบ",
  i18nKey_web_485: "ยืนยันที่จะลบ",
  i18nKey_web_486: "กรุณาอัปโหลดรูปภาพ",
  i18nKey_web_487: "กำลังปิดการติดตามใบหน้า",
  i18nKey_web_488: "บุคลากร:",
  i18nKey_web_489: "การตรวจสอบภาพหน้าจอ",
  i18nKey_web_490: "เวลาของระบบติดตาม",
  i18nKey_web_491: "จำนวนคำเตือน",
  i18nKey_web_492: "พื้นที่เซี่ยงไฮ้",
  i18nKey_web_493: "เซี่ยงไฮ้",
  i18nKey_web_494: "Jinqiao Wanchuang",
  i18nKey_web_495: "ชั้น",
  i18nKey_web_496: "B2",
  i18nKey_web_497: "ที่จอดรถ",
  i18nKey_web_498: "กรุณาใส่คำอธิบายของกลุ่มลาดตระเวน",
  i18nKey_web_499: "เวลาปิด",
  i18nKey_web_500: "จำนวนเหตุการณ์ทั้งหมด",
  i18nKey_web_501: "จำนวนเหตุการณ์ที่รอดำเนินการ",
  i18nKey_web_502: "จำนวนงานทั้งหมด",
  i18nKey_web_503: "จำนวนงานที่รอดำเนินการ",
  i18nKey_web_504: "ความปลอดภัย",
  i18nKey_web_505: "ประมวลผลเสมอ",
  i18nKey_web_506: "Security incident ทั้งหมด",
  i18nKey_web_507: "ส่งต่อ",
  i18nKey_web_508: "บริษัทที่เกี่ยวกับการขับขี่",
  i18nKey_web_509: "การจองของบริษัท",
  i18nKey_web_510: "ความถี่",
  i18nKey_web_511: "การขายหน้าร้าน",
  i18nKey_web_512: "การขายอาหาร",
  i18nKey_web_513: "สิ่งอำนวยความสะดวกที่ผิดปกติ",
  i18nKey_web_514: "ค้าง",
  i18nKey_web_515: "เวลาอัปโหลด",
  i18nKey_web_516: "เหตุการณ์",
  i18nKey_web_517: "งานนี้ได้สร้างการแจ้งเตือน",
  i18nKey_web_518: "ไม่สามารถแก้ไขได้!",
  i18nKey_web_519: "งานกำลังเริ่มต้นขึ้น",
  i18nKey_web_520: "กรุณาแก้ไขหลังจากหยุดชั่วคราว!",
  i18nKey_web_521: "ยืนยันที่จะลบ?",
  i18nKey_web_522: "เวลาเริ่มและหยุด",
  i18nKey_web_523: "เวลาลาดตระเวน",
  i18nKey_web_524: "ปัญหา",
  i18nKey_web_525: "ใช่",
  i18nKey_web_526: "ไม่",
  i18nKey_web_527: "ปิด",
  i18nKey_web_528: "ประวัติเส้นทางลาดตระเวน",
  i18nKey_web_529: "ประวัติการลาดตระเวนทั้งหมด",
  i18nKey_web_530: "การแจ้งเตือน",
  i18nKey_web_531: "ชื่อเหตุการณ์",
  i18nKey_web_532: "ประสิทธิภาพ",
  i18nKey_web_533: "พื้นที่จัดแสดง Vanke Wisdom",
  i18nKey_web_534: "การแจ้งเตือนเหตุการณ์สำคัญ",
  i18nKey_web_535: "park",
  i18nKey_web_536: "Tianfu Yuncheng",
  i18nKey_web_537: "สถานที่ตั้งโครงการตั้งอยู่ที่ Jinqiao Wanchuang Center",
  i18nKey_web_538: "โรงรถใต้ดิน กำหนดค่า 3 ชุด 250",
  i18nKey_web_539:
    "ระบบกักเก็บพลังงาน เต้ารับสำหรับเสียบปลั๊กไฟ ตั้งอยู่ที่ 10",
  i18nKey_web_540: "ภายใต้ สาย A 2",
  i18nKey_web_541: "หม้อแปลงไฟฟ้า (1000",
  i18nKey_web_542: "ข้างรถบัส 10",
  i18nKey_web_543: "สาย B 5",
  i18nKey_web_544: "และข้างรถบัส 6",
  i18nKey_web_545: "หม้อแปลงไฟฟ้า (2000",
  i18nKey_web_546: "ระบบบัสที่ใช้ในการส่งข้อมูล (ไซด์บัส)",
  i18nKey_web_547: "รายงานการอัดประจุและคายประจุทั้งหมด",
  i18nKey_web_548: "การอัดประจุ",
  i18nKey_web_549: "การคายประจุ",
  i18nKey_web_550: "ส่วนข้อความที่แสดงในกรอบข้อความ",
  i18nKey_web_551: "ขนาดกล่อง",
  i18nKey_web_552: "นิ้ว",
  i18nKey_web_553: "จำนวนระบบย่อย",
  i18nKey_web_554: "ประเภทผู้ใช้",
  i18nKey_web_555: "ความจุสูงสุด",
  i18nKey_web_556: "กำลังไฟสูงสุด",
  i18nKey_web_557: "ความจุหม้อแปลง",
  i18nKey_web_558: "การเข้าถึงระดับแรงดันไฟฟ้า",
  i18nKey_web_559: "การอัดประจุทั้งหมด",
  i18nKey_web_560: "การคายประจุทั้งหมด",
  i18nKey_web_561: "ยืนยันการดาวน์โหลด",
  i18nKey_web_562: "ออกรายงานหรือไม่",
  i18nKey_web_563: "เมตร",
  i18nKey_web_564: "จุดอ่าน",
  i18nKey_web_565: "พลังงานที่ใช้",
  i18nKey_web_566: "การใช้ไฟฟ้าของผู้เช่า",
  i18nKey_web_567: "การเปลี่ยนแปลงการใช้พลังงาน",
  i18nKey_web_568: "ค่าการใช้พลังงานที่แท้จริง",
  i18nKey_web_569: "ค่าการใช้พลังงานเป้าหมาย",
  i18nKey_web_570: "ค่าการใช้พลังงานที่คาดการณ์",
  i18nKey_web_571: "ค่าที่ทำนาย: 1",
  i18nKey_web_572:
    "ในมิติรายวัน การใช้พลังงานทั้งหมด การใช้พลังงานในพื้นที่สาธารณะและรายการย่อยระดับถัดไปของพื้นที่สาธารณะ ในมิติเดือนกุมภาพันธ์ การใช้พลังงานทั้งหมด การใช้พลังงานในพื้นที่สาธารณะ และรายการย่อยของพื้นที่สาธารณะ",
  i18nKey_web_573: "ค่าเป้าหมาย: 1",
  i18nKey_web_574:
    "ในมิติรายเดือน การใช้พลังงานทั้งหมดและการใช้พลังงานในพื้นที่สาธารณะ 2",
  i18nKey_web_575:
    "ในมิติประจำปี การใช้พลังงานทั้งหมดและการใช้พลังงานในพื้นที่สาธารณะ",
  i18nKey_web_576: "กรุณากรอกค่าเป้าหมาย",
  i18nKey_web_577: "กรุณากรอกข้อมูล",
  i18nKey_web_578: "ค่าจริงรายปี",
  i18nKey_web_579: "เดือน",
  i18nKey_web_580: "ค่าเป้าหมายรายปี",
  i18nKey_web_581: "เป้าหมายการใช้พลังงานในพื้นที่สาธารณะ",
  i18nKey_web_582: "การใช้พลังงานในพื้นที่สาธารณะ",
  i18nKey_web_583: "การใช้พลังงานต่อตารางเมตร",
  i18nKey_web_584: "พลังงานที่ลงรายละเอียด",
  i18nKey_web_585: "การแจ้งเตือนการใช้พลังงาน",
  i18nKey_web_586: "ผลลัพธ์ของการหาร",
  i18nKey_web_587: "การจัดอันดับการใช้พลังงานต่อตารางเมตรของผู้เช่า",
  i18nKey_web_588: "ไฟฟ้าพลังน้ำ",
  i18nKey_web_589: "การใช้พลังงานแบบรายวัน",
  i18nKey_web_590: "การใช้พลังงานแบบรายเดือน",
  i18nKey_web_591: "การใช้พลังงานในพื้นที่สาธารณะแบบรายวัน",
  i18nKey_web_592: "การใช้พลังงานในพื้นที่สาธารณะแบบรายเดือนเดือน",
  i18nKey_web_593: "จำนวนคนทั้งหมด",
  i18nKey_web_594: "ค่าการใช้พลังงานทั้งหมดของวันนี้",
  i18nKey_web_595: "การจัดอันดับการใช้พลังงานต่อตารางเมตรของผู้เช่า",
  i18nKey_web_596: "ภาพรวมทั่วโลก",
  i18nKey_web_597: "ภาพรวมของ park",
  i18nKey_web_598: "กรุณาเลือกเวลา",
  i18nKey_web_599: "ช่วงเดียวกันของปีที่แล้ว:",
  i18nKey_web_600: "เดือนที่แล้ว:",
  i18nKey_web_601: "เมื่อเทียบกับปีก่อน:",
  i18nKey_web_602: "เมื่อเทียบกับเดือนที่แล้ว:",
  i18nKey_web_603: "เดือนที่แล้ว",
  i18nKey_web_604: "กราฟการเปลี่ยนแปลงการใช้พลังงาน",
  i18nKey_web_605: "แนะนำ",
  i18nKey_web_606: "รหัสเครื่องมือ",
  i18nKey_web_607: "กรุณากรอกรหัสมิเตอร์",
  i18nKey_web_608: "กรุณากรอกรหัสมิเตอร์อัจฉริยะ",
  i18nKey_web_609: "กรุณากรอกประเภท",
  i18nKey_web_610: "ชื่อผู้ใช้",
  i18nKey_web_611: "การจ่ายบัตรจอดรถ",
  i18nKey_web_612: "การชำระเงินผ่านวีแชท",
  i18nKey_web_613: "ใบแจ้งหนี้การใช้ไฟฟ้า",
  i18nKey_web_614: "ค่าธรรมเนียมการใช้น้ำ",
  i18nKey_web_615: "ประเภทผู้ค้า",
  i18nKey_web_616: "ประเภทเครื่องมือ",
  i18nKey_web_617: "ชื่อธุรกิจ",
  i18nKey_web_618: "เวลาอัปเดตล่าสุด",
  i18nKey_web_619: "จำนวนที่อัดกระแสไฟฟ้า",
  i18nKey_web_620: "เวลาที่อัดกระแสไฟฟ้า",
  i18nKey_web_621: "หก",
  i18nKey_web_622: "ผู้ค้าแต่ละราย",
  i18nKey_web_623: "ส่งออกเรียบร้อยแล้ว",
  i18nKey_web_624: "จำนวนการอัดกระแสไฟฟ้าที่ยังไม่ได้ชำระ",
  i18nKey_web_625: "จำนวนใบแจ้งหนี้ที่ยังไม่ได้ชำระ",
  i18nKey_web_626: "ใบแจ้งหนี้การใช้ไฟฟ้ายังไม่ได้ชำระ",
  i18nKey_web_627: "ใบแจ้งหนี้การใช้น้ำยังไม่ได้ชำระ",
  i18nKey_web_628: "ภาพรวมของผู้ค้าที่ค้างชำระ",
  i18nKey_web_629: "การใช้พลังงานที่แท้จริง",
  i18nKey_web_630: "ปริมาณการใช้ไฟฟ้าทั้งหมดในใบแจ้งหนี้ คือ",
  i18nKey_web_631: "ปริมาณการใช้น้ำที่แท้จริง",
  i18nKey_web_632: "ปริมาณการใช้น้ำทั้งหมดในใบแจ้งหนี้ คือ",
  i18nKey_web_633: "ปริมาณการใช้น้ำ",
  i18nKey_web_634: "รายละเอียด",
  i18nKey_web_635: "เลขที่สัญญา",
  i18nKey_web_636: "กรุณาเลือกเลขที่สัญญา",
  i18nKey_web_637: "กรุณาใส่หน่วย",
  i18nKey_web_638: "จำนวนตามเกณฑ์",
  i18nKey_web_639: "กรุณากรอกจำนวนตามเกณฑ์",
  i18nKey_web_640: "ระยะเวลาของสัญญา",
  i18nKey_web_641: "จำนวนเกณฑ์มิเตอร์ไฟฟ้า",
  i18nKey_web_642: "จำนวนเกณฑ์มิเตอร์น้ำ",
  i18nKey_web_643: "เข้าสู่ระบบ",
  i18nKey_web_644: "จดทะเบียน",
  i18nKey_web_645: "รหัสยืนยัน",
  i18nKey_web_646: "กรุณากรอกหมายเลขโทรศัพท์",
  i18nKey_web_647: "หรือศูนย์ปฏิบัติการอัจฉริยะ",
  i18nKey_web_648: "สัญญาการให้บริการ",
  i18nKey_web_649: "กรุณาเลือกชื่อห้องประชุม",
  i18nKey_web_650: "กรุณากรอกบริษัทที่ได้รับการรับรอง",
  i18nKey_web_651: "กรุณาเลือกบริษัทที่ได้รับการรับรอง",
  i18nKey_web_652: "กรุณาเลือกวันที่จอง",
  i18nKey_web_653: "เวลาการจอง",
  i18nKey_web_654: "กรุณากรอกจำนวนสั่งซื้อ",
  i18nKey_web_655: "กรุณากรอกจำนวนที่ใช้จริง",
  i18nKey_web_656: "กรุณากรอกจำนวนมากที่สุด 2",
  i18nKey_web_657: "อักษรจีนหนึ่งตัวนับเป็นสองอักขระ",
  i18nKey_web_658: "กรุณาเลือกเวลาสิ้นสุดที่กำหนดไว้",
  i18nKey_web_659: "รหัสผ่านใหม่ต้องไม่เหมือนกับรหัสผ่านเก่า",
  i18nKey_web_660: "วันที่กำหนด",
  i18nKey_web_661: "สถานะการชำระเงิน",
  i18nKey_web_662: "เวลาชำระเงิน",
  i18nKey_web_663: "ปฏิเสธ",
  i18nKey_web_664: "โดย",
  i18nKey_web_665: "วีแชท",
  i18nKey_web_666: "การชำระเงินต่อเนื่อง",
  i18nKey_web_667: "ยกเลิกการชำระเงิน",
  i18nKey_web_668: "ชำระเงินสำเร็จ",
  i18nKey_web_669: "ชื่อ",
  i18nKey_web_670: "กรุณากรอกชื่อ",
  i18nKey_web_671: "ต้องระบุชื่อ",
  i18nKey_web_672: "ประเภท",
  i18nKey_web_673: "กรุณาเลือกประเภท",
  i18nKey_web_674: "การเลือกผู้เช่า",
  i18nKey_web_675: "กรุณากรอกธุรกิจ",
  i18nKey_web_676: "ชื่อผู้เช่า",
  i18nKey_web_677: "ช่วงเวลานี้",
  i18nKey_web_678: "การใช้ไฟฟ้า",
  i18nKey_web_679: "ช่วงเดียวกันของเดือนก่อน",
  i18nKey_web_680: "ช่วงเดียวกันของปีก่อน",
  i18nKey_web_681: "สถิติการใช้พลังงานของผู้เช่า",
  i18nKey_web_682: "ธุรกิจที่เลือก",
  i18nKey_web_683: "แผนการลาดตระเวน",
  i18nKey_web_684: "ปิดการลาดตระเวนแบบวิดีโอ",
  i18nKey_web_685: "หยุดการลาดตระเวนแบบวิดีโอชั่วคราว",
  i18nKey_web_686: "หยุดการลาดตระเวนแบบวิดีโอ",
  i18nKey_web_687: "ไม่มีข้อมูลกล้องในpark",
  i18nKey_web_688: "ไม่ได้รับการดูแลรักษา",
  i18nKey_web_689: "รอดำเนินการ",
  i18nKey_web_690: "ข้อมูล คงเหลือ",
  i18nKey_web_691: "ข้อผิดพลาด",
  i18nKey_web_692: "ประเภทรางวัล",
  i18nKey_web_693: "ความสำเร็จ",
  i18nKey_web_694: "ความล้มเหลว",
  i18nKey_web_695: "วัตถุที่เป็นรางวัล",
  i18nKey_web_696: "เวลารับรางวัล",
  i18nKey_web_697: "ชื่อวัตถุที่เป็นรางวัล",
  i18nKey_web_698: "รายการว่างเปล่าและไม่สามารถส่งออกได้",
  i18nKey_web_699: "ชื่อเครดิต",
  i18nKey_web_700: "กรุณากรอกชื่อจุด",
  i18nKey_web_701: "กรุณาเลือกชื่อจุด",
  i18nKey_web_702: "สามารถกรอกได้เฉพาะจำนวนเต็มบวกเท่านั้น",
  i18nKey_web_703: "ออกเรียบร้อยแล้ว",
  i18nKey_web_704: "ชื่อประเภทจุด",
  i18nKey_web_705: "ประเภทปัญหา",
  i18nKey_web_706: "กรุณาเลือกช่วงเวลาในการสร้าง",
  i18nKey_web_707: "รายจ่าย",
  i18nKey_web_708: "ด้านรายได้",
  i18nKey_web_709: "กรุณากรอกค่าใช้จ่าย",
  i18nKey_web_710: "ประเภทของการดำเนินการ",
  i18nKey_web_711: "รายได้",
  i18nKey_web_712: "จำนวนเต็ม",
  i18nKey_web_713: "ชั่วโมงการดำเนินการ",
  i18nKey_web_714: "กรุณาเลือกเวลารับรางวัล",
  i18nKey_web_715: "หมายเลขการดำเนินการ",
  i18nKey_web_716: "ผู้จ่ายเงิน",
  i18nKey_web_717: "จำนวนจุดที่มีการดำเนินการ",
  i18nKey_web_718: "สถานะการซื้อขาย",
  i18nKey_web_719: "การดำเนินการที่ประสบความสำเร็จ",
  i18nKey_web_720: "การดำเนินการที่ล้มเหลว",
  i18nKey_web_721: "กรุณากรอกรายได้",
  i18nKey_web_722: "กรุณาเลือกจุด",
  i18nKey_web_723: "รายละเอียดการดำเนินการ",
  i18nKey_web_724: "เริ่ม",
  i18nKey_web_725: "เวลาจอง",
  i18nKey_web_726: "กรุณาเลือกเวลาจอง",
  i18nKey_web_727: "กรุณาเลือกประเภทสถานที่",
  i18nKey_web_728: "หมายเลขโทรศัพท์ของผู้ใช้",
  i18nKey_web_729: "ห้องกิจกรรมที่ใช้ร่วมกัน",
  i18nKey_web_730: "การคืนเงิน",
  i18nKey_web_731: "การสมัคร",
  i18nKey_web_732: "รายงานใบสั่งซื้อของสำนักงานที่ใช้ร่วมกัน",
  i18nKey_web_733: "กรุณากรอกชื่อพื้นที่",
  i18nKey_web_734: "กรุณาเลือกประเภทพื้นที่",
  i18nKey_web_735: "บริษัท",
  i18nKey_web_736: "กรุณากรอกบริษัท",
  i18nKey_web_737: "วิธีการเข้าถึง",
  i18nKey_web_738: "เวลาขนส่ง",
  i18nKey_web_739: "กรุณาเลือกเวลาขนส่ง",
  i18nKey_web_740: "ปัด",
  i18nKey_web_741: "บันทึกผ่าน",
  i18nKey_web_742: "เลือกกล้อง",
  i18nKey_web_743: "เลือกช่วงเวลา",
  i18nKey_web_744: "Zhongkonan",
  i18nKey_web_745: "Huawei",
  i18nKey_web_746: "ทั้งหมด",
  i18nKey_web_747: "ป้ายข้อความเจ้าหน้าที่ค้นหาผิดปกติ",
  i18nKey_web_748: "กรุณาเลือกชั้นก่อน",
  i18nKey_web_749: "กรุณาเลือกแท็กบุคคล",
  i18nKey_web_750: "กรุณาเลือกกล้อง",
  i18nKey_web_751: "เวลาสืบค้น",
  i18nKey_web_752: "จำนวนคนทั้งหมด:",
  i18nKey_web_753: "การวิเคราะห์กลุ่มลูกค้า",
  i18nKey_web_754: "รายชื่อบุคคล",
  i18nKey_web_755: "ผู้ใช้ที่ลงทะเบียน",
  i18nKey_web_756: "สถิติผู้เยี่ยมชม",
  i18nKey_web_757: "ตาม park",
  i18nKey_web_758: "จำนวนคนทั้งหมดใน park",
  i18nKey_web_759: "ผู้เยี่ยมชมทั้งหมด",
  i18nKey_web_760: "คนทั้งหมด",
  i18nKey_web_761: "การเลือกย่อย",
  i18nKey_web_762: "การวิเคราะห์ปริมาณการใช้ไฟฟ้าแบบลงรายละเอียด",
  i18nKey_web_763: "กรุณากรอกหมายเลขซีเรียล",
  i18nKey_web_764: "บุคคลที่สาม",
  i18nKey_web_765: "กรุณากรอกบุคคลที่สาม",
  i18nKey_web_766: "ห้องประชุมของแพลตฟอร์มมอร์แกน",
  i18nKey_web_767: "กรุณาเข้าสู่ห้องประชุมของแพลตฟอร์มมอร์แกน",
  i18nKey_web_768: "กรุณาเลือกแหล่งข้อมูล",
  i18nKey_web_769: "กรุณากรอกมากที่สุด 1",
  i18nKey_web_770: "อักษรจีนหนึ่งตัวนับเป็นสองอักขระ",
  i18nKey_web_771: "และต้องไม่มีช่องว่าง!",
  i18nKey_web_772: "วิเคราะห์สถิติการใช้พลังงาน",
  i18nKey_web_773: "สถิติการใช้พลังงานรายการย่อย",
  i18nKey_web_774: "กรุณาเลือกรายการ",
  i18nKey_web_775: "เหตุการณ์",
  i18nKey_web_776: "กรุณากรอกเหตุการณ์",
  i18nKey_web_777: "การกำหนดนโยบาย",
  i18nKey_web_778: "กรุณาเลือกการตั้งค่านโยบาย",
  i18nKey_web_779: "นาทีต้องไม่น้อยกว่า 2 นาทีและต้องไม่เกิน 10 นาที",
  i18nKey_web_780: "ประเภทแหล่งข้อมูล",
  i18nKey_web_781: "กลยุทธ์สถานการณ์",
  i18nKey_web_782: "เมจิกคลับ",
  i18nKey_web_783: "สำนักงานผลิต Vanke",
  i18nKey_web_784: "เปิดใช้งานสำเร็จแล้ว",
  i18nKey_web_785: "ปิดใช้งานเรียบร้อยแล้ว",
  i18nKey_web_786: "กรุณาเลือกสถานะการประชุม",
  i18nKey_web_787: "ก่อนการประชุม: เริ่ม",
  i18nKey_web_788: "หลังจากเริ่มการประชุม: ยังไม่เริ่ม",
  i18nKey_web_789: "หลังจากเริ่มการประชุม: เริ่ม",
  i18nKey_web_790: "เวลาดำเนินการล่าสุด",
  i18nKey_web_791: "เวลาธุรกิจที่กำหนดไว้",
  i18nKey_web_792: "หลังการประชุม",
  i18nKey_web_793: "กลยุทธ์สถานการณ์ของห้องประชุมใหม่",
  i18nKey_web_794: "ปรับเปลี่ยนกลยุทธ์สถานการณ์ของห้องประชุม",
  i18nKey_web_795: "กรุณาใส่หมายเลขป้ายทะเบียน",
  i18nKey_web_796: "พื้นที่ว่างในการจอดรถ",
  i18nKey_web_797: "กรุณากรอกพื้นที่ว่างในการจอดรถ",
  i18nKey_web_798: "ไม่มีรูปภาพ",
  i18nKey_web_799: "การจัดการยานพาหนะ",
  i18nKey_web_800: "การสืบค้นข้อมูลยานพาหนะ",
  i18nKey_web_801: "เวลาเริ่มต้นและเวลาสิ้นสุดต้องไม่เกิน 30 วัน",
  i18nKey_web_802: "บันทึกการเข้าและออก",
  i18nKey_web_803: "สถิติการจอดรถ",
  i18nKey_web_804: "ซื้อบันทึกแบบแพ็คเกจ",
  i18nKey_web_805: "ประเภทการสืบค้น",
  i18nKey_web_806: "หมายเลขทะเบียน",
  i18nKey_web_807: "เลขที่การจอดรถ",
  i18nKey_web_808: "กรุณากรอกหมายเลขป้ายทะเบียน",
  i18nKey_web_809: "กรุณากรอกหมายเลขที่จอดรถ",
  i18nKey_web_810: "ผลัก",
  i18nKey_web_811: "สร้าง",
  i18nKey_web_812: "บันทึกรายการยานพาหนะ",
  i18nKey_web_813: "พื้นที่ว่างในการจอดรถทั้งหมด",
  i18nKey_web_814: "จำนวนกลุ่มยานพาหนะ",
  i18nKey_web_815: "ค่าจอดรถ",
  i18nKey_web_816: "ภายในไม่กี่ชั่วโมง",
  i18nKey_web_817: "บันทึกการขับรถออกไป",
  i18nKey_web_818: "บันทึกการขับรถเข้ามา",
  i18nKey_web_819: "ไม่มีที่จอดรถ",
  i18nKey_web_820: "เวลาออกเดินทาง",
  i18nKey_web_821: "ตั๋ว",
  i18nKey_web_822: "ราคาต่อหน่วย",
  i18nKey_web_823:
    "ตารางเดินรถบัสถูกเปิดใช้งานแล้ว กรุณาระงับการเปิดใช้งานก่อนดำเนินการ",
  i18nKey_web_824:
    "คุณต้องการลบตารางเดินรถบัสนี้หรือไม่? ไม่สามารถกู้คืนได้หลังจากลบแล้ว!",
  i18nKey_web_825: "ราคา",
  i18nKey_web_826: "พับ",
  i18nKey_web_827: "วันที่มีผล",
  i18nKey_web_828: "กรุณากรอกชื่อ business",
  i18nKey_web_829: "กรุณาเลือกช่วงเวลาสั่งซื้อ",
  i18nKey_web_830: "จำนวนเงินที่ชำระ",
  i18nKey_web_831: "รายงานใบสั่งรถบัส",
  i18nKey_web_832: "พันล้าน",
  i18nKey_web_833: "สิบล้าน",
  i18nKey_web_834: "ล้าน",
  i18nKey_web_835:
    "คุณยังไม่ได้ติดตั้งโปรแกรมเล่นวิดีโอ คุณต้องการดาวน์โหลดปลั๊กอินโปรแกรมเล่นวิดีโอตอนนี้หรือไม่ หลังจากดาวน์โหลดแล้วกรุณาติดตั้งด้วยตัวคุณเอง",
  i18nKey_web_836: "ไม่มีผลลัพธ์กลับมา",
  i18nKey_web_837: "ไม่พบผลลัพธ์",
  i18nKey_web_838: "ระดับ 7",
  i18nKey_web_839: "ระดับ 8",
  i18nKey_web_840: "ระดับ 9",
  i18nKey_web_841: "ระดับ10",
  i18nKey_web_842: "ระดับ 11",
  i18nKey_web_843: "ระดับ 12",
  i18nKey_web_844: "การนำเข้าข้อมูลสิ่งอำนวยความสะดวก",
  i18nKey_web_845: "ชื่ออุปกรณ์",
  i18nKey_web_846: "รหัสระบบต้นทาง",
  i18nKey_web_847: "คำอธิบายระบบต้นทาง",
  i18nKey_web_848: "รหัสอุปกรณ์ระบบต้นทาง",
  i18nKey_web_849: "ชื่ออุปกรณ์กลุ่ม 1",
  i18nKey_web_850: "รหัสการจำแนกประเภทอุปกรณ์กลุ่ม1",
  i18nKey_web_851: "ชื่อกลุ่มของอุปกรณ์รอง",
  i18nKey_web_852: "รหัสการจำแนกประเภทอุปกรณ์รอง",
  i18nKey_web_853: "ชื่อหมวดหมู่อุปกรณ์กลุ่ม 3",
  i18nKey_web_854: "รหัสการจำแนกประเภทอุปกรณ์ 3 ระดับ",
  i18nKey_web_855: "การเข้ารหัสโมเดลเชิงพื้นที่",
  i18nKey_web_856: "ขนาดไฟล์ต้องไม่เกิน 20",
  i18nKey_web_857: "อัปโหลดเรียบร้อยแล้ว",
  i18nKey_web_858: "หมายเลข batch คือ",
  i18nKey_web_859: "กรุณาซิงค์ค่าไปที่ตารางหลักในภายหลัง",
  i18nKey_web_860: "การอัปโหลดล้มเหลว!",
  i18nKey_web_861: "กรุณาอัปโหลดเวอร์ชันใหม่",
  i18nKey_web_862: "ไฟล์",
  i18nKey_web_863: "ส่งออกไม่สำเร็จ!",
  i18nKey_web_864: "หมายเลขแบทช์สำหรับการซิงค์ค่าให้ตรงกัน คือ",
  i18nKey_web_865: "ยืนยันการซิงค์ค่าให้ตรงกัน?",
  i18nKey_web_866: "ยืนยันที่จะซิงค์ค่าของข้อมูลสถานที่ทั้งหมดให้ตรงกันหรือไม่",
  i18nKey_web_867:
    "การซิงค์ค่าให้ตรงกันล้มเหลว ไม่มีข้อมูลสิ่งอำนวยความสะดวกให้ซิงค์ค่าให้ตรงกันชั่วคราว!",
  i18nKey_web_868: "การซิงค์ค่าให้ตรงกันสำเร็จ!",
  i18nKey_web_869: "การซิงค์ค่าให้ตรงกันล้มเหลว!",
  i18nKey_web_870: "ประเภทพิกัด",
  i18nKey_web_871: "พิกัด",
  i18nKey_web_872: "กรุณาเลือกผู้รับผิดชอบในการจัดการ",
  i18nKey_web_873: "ไฟล์แนบรองรับเวอร์ชันใหม่เท่านั้น",
  i18nKey_web_874: "รูปแบบ",
  i18nKey_web_875: "ไม่ได้เปิดใช้งาน",
  i18nKey_web_876: "ที่อยู่",
  i18nKey_web_877: "หมายเลขอุปกรณ์",
  i18nKey_web_878: "ไม่มีข้อมูล",
  i18nKey_web_879: "ยืนยันการลบ",
  i18nKey_web_880: "ขนาดภาพต้องไม่เกิน 3",
  i18nKey_web_881: "อัปโหลดได้เท่านั้น",
  i18nKey_web_882: "จัดรูปแบบรูปภาพ",
  i18nKey_web_883: "การจัดประเภทจะต้องเลือกถึงระดับที่ 3",
  i18nKey_web_884: "กรุณากรอกกฎหมาย",
  i18nKey_web_885: "ที่อยู่ เช่น 00-01-6",
  i18nKey_web_886: "หรือ 00",
  i18nKey_web_887: "พิกัด เช่น",
  i18nKey_web_888: "มีพิกัดอยู่แล้ว กรุณากรอกใหม่",
  i18nKey_web_889: "ความยาวของนามแฝงสิ่งอำนวยความสะดวกต้องไม่เกิน 16 อักขระ",
  i18nKey_web_890: "ความยาวของชื่อสิ่งอำนวยความสะดวกต้องไม่เกิน 64 อักขระ",
  i18nKey_web_891:
    "หมายเลขอุปกรณ์ระบบต้นทางต้องประกอบด้วยอักขระภาษาจีนและอังกฤษ และตัวเลข 50 ตัวเท่านั้น",
  i18nKey_web_892: "องค์ประกอบ",
  i18nKey_web_893:
    "โมเดลภายนอกต้องเป็นภาษาจีนและอังกฤษ ตัวเลขและขีดล่างภายใน 50 อักขระเท่านั้น",
  i18nKey_web_894: "ส่วนจำนวนเต็มของลองจิจูด คือ -180 ถึง 180",
  i18nKey_web_895: "ส่วนจำนวนเต็มของละติจูด คือ -90 ถึง 90",
  i18nKey_web_896:
    "คำอธิบายสิ่งอำนวยความสะดวกสามารถเขียนได้เฉพาะในภาษาจีนและภาษาอังกฤษ และตัวเลข",
  i18nKey_web_897: "ผู้ผลิตสามารถใช้ตัวเลขและภาษาจีนและอังกฤษเท่านั้น",
  i18nKey_web_898: "ผู้ให้บริการสามารถใช้ตัวเลขและภาษาจีนและอังกฤษเท่านั้น",
  i18nKey_web_899:
    "ผู้ให้บริการซ่อมบำรุงสามารถใช้ตัวเลขและภาษาจีนและอังกฤษเท่านั้น",
  i18nKey_web_900:
    "แบรนด์สามารถเขียนได้เฉพาะภาษาจีนและอังกฤษ และตัวเลขเท่านั้น",
  i18nKey_web_901: "รูปแบบที่รองรับ คือ",
  i18nKey_web_902: "ด้วย",
  i18nKey_web_903: "ระดับการจำแนกสิ่งอำนวยความสะดวก",
  i18nKey_web_904: "เพิ่มระดับ",
  i18nKey_web_905: "ความสำคัญของสิ่งอำนวยความสะดวก",
  i18nKey_web_906:
    "รหัสการจัดประเภทสถานที่ต้องประกอบด้วยตัวเลข ตัวอักษร และขีดล่างเท่านั้น",
  i18nKey_web_907: "กรุณากรอกระดับที่จะเพิ่ม",
  i18nKey_web_908:
    "กรุณากรอกข้อมูลให้ครบถ้วน คุณไม่สามารถเพิ่มข้อมูลข้ามระดับได้",
  i18nKey_web_909: "กรุณากรอกรหัสหมวดหมู่หรือชื่อหมวดหมู่ที่เกี่ยวข้อง",
  i18nKey_web_910: "รหัสหมวดหมู่และชื่อหมวดหมู่ต้องไม่เป็นค่าว่าง",
  i18nKey_web_911: "ดูรายละเอียดธุรกิจ",
  i18nKey_web_912: "กรุณากรอกรหัส park",
  i18nKey_web_913: "Microsoft Accor black",
  i18nKey_web_914: "วิธีการสร้าง",
  i18nKey_web_915: "การกรอกข้อมูลด้วยตนเอง",
  i18nKey_web_916: "นำเข้าจำนวนมาก",
  i18nKey_web_917: "กรุณากรอกชื่อบริษัทภาษาอังกฤษ",
  i18nKey_web_918: "เลขทะเบียนใบอนุญาตประกอบกิจการ",
  i18nKey_web_919: "รหัส social credit",
  i18nKey_web_920: "ลากไฟล์มาที่นี่หรือ",
  i18nKey_web_921: "คลิกเพื่ออัปโหลด",
  i18nKey_web_922: "รองรับเฉพาะไฟล์",
  i18nKey_web_923: "กรุณากรอกหมายเลขประจำตัวประชาชนในรูปแบบที่ถูกต้อง",
  i18nKey_web_924: "ความยาวสูงสุด คือ 50 อักขระ",
  i18nKey_web_925: "ธุรกิจใหม่",
  i18nKey_web_926: "เปลี่ยนแปลงข้อมูลธุรกิจ",
  i18nKey_web_927: "ไฟล์และคำต่อท้ายคือ",
  i18nKey_web_928: "รวม",
  i18nKey_web_929: "อุปสรรค ความสำเร็จ",
  i18nKey_web_930: "ข้อบังคับ ข้อผิดพลาด",
  i18nKey_web_931: "เชื่อมต่อกับหน้าจอขนาดใหญ่",
  i18nKey_web_932: "สามารถเชื่อมโยงหน้าจอขนาดใหญ่ได้",
  i18nKey_web_933: "ยกเลิกการเชื่อมต่อเรียบร้อยแล้ว",
  i18nKey_web_934: "เลือกหมวดหมู่",
  i18nKey_web_935: "หมวดหมู่ที่ 8",
  i18nKey_web_936: "หมวดหมู่ที่ 9",
  i18nKey_web_937: "กรุณาเลือกหมวดหมู่",
  i18nKey_web_938: "กรุณาเลือกหมวดหมู่ที่ 2",
  i18nKey_web_939: "กรุณาเลือกหมวดหมู่ที่ 3",
  i18nKey_web_940: "กรุณาเลือกหมวดหมู่ที่ 4",
  i18nKey_web_941: "กรุณาเลือกหมวดหมู่ที่ 5",
  i18nKey_web_942: "กรุณาเลือกหมวดหมู่ที่ 6",
  i18nKey_web_943: "เลือกสถานที่",
  i18nKey_web_944: "ไม่สามารถค้นหารายชื่อกลุ่ม",
  i18nKey_web_945: "อาคาร",
  i18nKey_web_946: "ต้องระบุ park",
  i18nKey_web_947: "ต้องระบุชั้น",
  i18nKey_web_948: "ชื่อไฟล์",
  i18nKey_web_949: "วิธีการสร้างสิ่งอำนวยความสะดวก",
  i18nKey_web_950: "กรุณากรอกหมายเลขพอร์ต",
  i18nKey_web_951: "กรุณากรอกชื่อผู้ใช้",
  i18nKey_web_952: "ต้องระบุที่อยู่!",
  i18nKey_web_953: "รูปแบบที่อยู่ไม่ถูกต้อง!",
  i18nKey_web_954: "ต้องระบุหมายเลขพอร์ต!",
  i18nKey_web_955: "รูปแบบหมายเลขพอร์ตไม่ถูกต้อง!",
  i18nKey_web_956: "กรุณากรอกชื่อขอบเขต",
  i18nKey_web_957: "กรุณากรอกรหัสขอบเขต",
  i18nKey_web_958: "การกำหนดค่าสำเร็จ",
  i18nKey_web_959: "เลือก Campus",
  i18nKey_web_960: "เลือกระบบย่อย",
  i18nKey_web_961: "นัดหมายเพื่อเยี่ยมชม",
  i18nKey_web_962: "กรุณาเลือกระบบย่อยของ park",
  i18nKey_web_963: "กรุณาเลือกบุคคลที่จะนัดหมาย",
  i18nKey_web_964: "กรุณาเลือกเวลาออกเดินทางโดยประมาณ",
  i18nKey_web_965: "กรุณาเลือกสถานะการนัดหมาย",
  i18nKey_web_966: "กรุณาเลือกเวลาเยี่ยมชม",
  i18nKey_web_967: "กรุณาเลือกเหตุผลในการเยี่ยมชม",
  i18nKey_web_968: "เลือกแผนก",
  i18nKey_web_969: "ชื่อกลุ่ม",
  i18nKey_web_970: "กรุณากรอกชื่อกลุ่ม",
  i18nKey_web_971: "รายละเอียดกลุ่ม",
  i18nKey_web_972: "ใส่ได้ 100 ตัวอักษร",
  i18nKey_web_973: "เพิ่มกลุ่ม",
  i18nKey_web_974: "จำเป็นต้องระบุชื่อกลุ่ม",
  i18nKey_web_975: "มีชื่อกลุ่มนี้แล้ว",
  i18nKey_web_976: "จำเป็นต้องระบุคำอธิบายกลุ่ม!",
  i18nKey_web_977: "แก้ไขข้อมูลกลุ่ม",
  i18nKey_web_978: "เลือกบ้านเกิด",
  i18nKey_web_979: "จังหวัด",
  i18nKey_web_980: "กรุณาเลือกจังหวัด",
  i18nKey_web_981: "ข้อมูลกลุ่ม",
  i18nKey_web_982: "ข้อมูลผู้ใช้",
  i18nKey_web_983: "แผนกผู้ใช้",
  i18nKey_web_984: "บทบาทของผู้ใช้",
  i18nKey_web_985: "กลุ่มผู้ใช้",
  i18nKey_web_986: "แก้ไขข้อมูลผู้ใช้",
  i18nKey_web_987: "สามารถใส่อักขระได้ 500 ตัวอักษร",
  i18nKey_web_988: "เพิ่มผู้ใช้",
  i18nKey_web_989: "โหลดเพิ่มเติม",
  i18nKey_web_990: "กรุณาเลือกผู้ใช้เพื่อเพิ่ม!",
  i18nKey_web_991: "ไม่มีอีกแล้ว",
  i18nKey_web_992: "เลือกยี่ห้อยานพาหนะ",
  i18nKey_web_993: "เลือกยานพาหนะ",
  i18nKey_web_994: "ผู้ใช้",
  i18nKey_web_995: "กลุ่ม",
  i18nKey_web_996: "ไม่มีผู้ใช้",
  i18nKey_web_997: "ไม่มีกลุ่ม",
  i18nKey_web_998: "ดูรายชื่อกลุ่ม",
  i18nKey_web_999: "กรุณากรอกชื่อผู้ใช้",
  i18nKey_web_1000: "ค้นหาหมายเลขโทรศัพท์",
  i18nKey_web_1001: "เพิ่มกลุ่ม",
  i18nKey_web_1002: "รายชื่อกลุ่ม",
  i18nKey_web_1003: "คุณแน่ใจหรือไม่ว่าจะลบ ?",
  i18nKey_web_1004: "ไม่มีรายการบทบาท",
  i18nKey_web_1005: "กรุณาเลือกผู้ใช้ที่จะลบ",
  i18nKey_web_1006: "กรุณาเลือกกลุ่มที่จะลบ",
  i18nKey_web_1007: "กรุณาเลือกผู้ใช้ที่จะเพิ่ม",
  i18nKey_web_1008: "กรุณาเลือกกลุ่มที่จะเพิ่ม",
  i18nKey_web_1009: "ไม่มีข้อมูลเพิ่มเติม",
  i18nKey_web_1010: "เลือกเส้นทางการจัดประเภท",
  i18nKey_web_1011: "ข้อมูลพื้นฐานของยานพาหนะ",
  i18nKey_web_1012: "รหัสการจำแนกประเภทยานพาหนะ",
  i18nKey_web_1013: "ป้ายทะเบียนรถ",
  i18nKey_web_1014: "เลขที่ใบขับขี่",
  i18nKey_web_1015: "ชื่อประเภทยานพาหนะ",
  i18nKey_web_1016: "รหัสการจำแนกผู้ปกครองของยานพาหนะ",
  i18nKey_web_1017: "รหัสเจ้าของ",
  i18nKey_web_1018: "เวลาเริ่มมีผลบังคับใช้ของป้าย",
  i18nKey_web_1019: "เวลาหมดอายุของป้าย",
  i18nKey_web_1020: "ตราสัญลักษณ์ที่ถูกต้อง",
  i18nKey_web_1021: "ปรับปรุงล่าสุดโดย",
  i18nKey_web_1022: "กรุณากรอกป้ายทะเบียน",
  i18nKey_web_1023: "กรุณาเลือกป้ายทะเบียน",
  i18nKey_web_1024: "กรุณากรอกสียานพาหนะ",
  i18nKey_web_1025: "กรุณากรอกหมายเลขใบอนุญาตขับขี่",
  i18nKey_web_1026: "กรุณากรอกเส้นทางแบบเต็มของประเภทยานพาหนะ",
  i18nKey_web_1027: "กรุณากรอกชื่อหมวดหมู่รถ",
  i18nKey_web_1028: "กรุณากรอกรหัสบุคคล",
  i18nKey_web_1029: "กรุณากรอกหน่วย",
  i18nKey_web_1030: "ไม่ถูกต้อง",
  i18nKey_web_1031: "จำเป็นต้องระบุหมายเลขป้ายทะเบียน!",
  i18nKey_web_1032: "จำเป็นต้องระบุสีรถ!",
  i18nKey_web_1033: "จำเป็นต้องระบุเส้นทางของประเภทรถทั้งหมด!",
  i18nKey_web_1034: "จำเป็นต้องระบุชื่อหมวดหมู่ยานพาหนะ!",
  i18nKey_web_1035: "จำเป็นต้องระบุชื่อเจ้าของ!",
  i18nKey_web_1036: "บริษัท ไม่สามารถเป็นโมฆะ!",
  i18nKey_web_1037: "จำเป็นต้องระบุป้ายรถ!",
  i18nKey_web_1038: "จำเป็นต้องระบุเวลาที่มีผลบังคับใช้!",
  i18nKey_web_1039: "จำเป็นต้องระบุเวลาหมดอายุ!",
  i18nKey_web_1040: "จำเป็นต้องระบุรหัสที่ถูกต้อง!",
  i18nKey_web_1041: "กรุณากรอกป้ายทะเบียนที่ถูกต้อง!",
  i18nKey_web_1042: "หมายเลขใบขับขี่หรือรหัสบุคลากรต้องไม่มีภาษาจีน!",
  i18nKey_web_1043: "สีน้ำเงิน",
  i18nKey_web_1044: "เวลาสิ้นสุดที่วางแผนไว้ควรมากกว่าเวลาเริ่มต้นที่วางแผนไว้",
  i18nKey_web_1045: "สิ่งอำนวยความสะดวกในชั้นเรียน",
  i18nKey_web_1046: "วันที่เริ่มต้น",
  i18nKey_web_1047: "วันที่สิ้นสุด",
  i18nKey_web_1048: "บันทึกการบำรุงรักษาสิ่งอำนวยความสะดวก",
  i18nKey_web_1049: "บันทึกการดำเนินการในอดีต",
  i18nKey_web_1050: "บันทึกการเตือนภัยสิ่งอำนวยความสะดวก",
  i18nKey_web_1051: "เวลาเริ่มต้นและเวลาสิ้นสุดการบำรุงรักษา",
  i18nKey_web_1052: "แผนกซ่อมบำรุง",
  i18nKey_web_1053: "Wang Xiaohu",
  i18nKey_web_1054: "Jinshajiang Road, Putuo District, Shanghai",
  i18nKey_web_1055: "ทำ",
  i18nKey_web_1056: "ดี",
  i18nKey_web_1057: "ฝ่ายวิศวกรรม",
  i18nKey_web_1058: "จำเป็นต้องระบุเวลาค้นหา! ! !",
  i18nKey_web_1059: "ฉันเลือกภายหลัง",
  i18nKey_web_1060: "ข้อหลัก:",
  i18nKey_web_1061: "สามัญ:",
  i18nKey_web_1062: "ป้อนชื่อเหตุการณ์หรือนามแฝงของอุปกรณ์",
  i18nKey_web_1063: "ส่ง SMS 2",
  i18nKey_web_1064: "เริ่มต้นกระบวนการ",
  i18nKey_web_1065: "คู่มือ",
  i18nKey_web_1066: "อัตโนมัติ",
  i18nKey_web_1067: "การตั้งอุณหภูมิ",
  i18nKey_web_1068: "อุณหภูมิที่ตั้งไว้",
  i18nKey_web_1069: "อุณหภูมิอากาศกลับ",
  i18nKey_web_1070: "ความแตกต่างของเครื่องกรองแรงดัน",
  i18nKey_web_1071: "การควบคุมวาล์วน้ำ",
  i18nKey_web_1072: "การตรวจสอบพลังงาน",
  i18nKey_web_1073: "คำสั่งพัดลม",
  i18nKey_web_1074: "ระดับการแจ้งเตือน",
  i18nKey_web_1075: "การทำงานของสัญญาณเตือน",
  i18nKey_web_1076: "ผู้ให้บริการอุปกรณ์",
  i18nKey_web_1077: "คำอธิบายอุปกรณ์",
  i18nKey_web_1078: "แบรนด์อุปกรณ์",
  i18nKey_web_1079: "โรงงานผลิตอุปกรณ์",
  i18nKey_web_1080: "บริการซ่อมบำรุงอุปกรณ์",
  i18nKey_web_1081: "เวลาเริ่มต้นสิ่งอำนวยความสะดวก",
  i18nKey_web_1082: "การหยุดทำงานของสิ่งอำนวยความสะดวก",
  i18nKey_web_1083: "เวลาทำงานนี้",
  i18nKey_web_1084: "วันที่ซ่อมบำรุง",
  i18nKey_web_1085: "ผู้ดูแลงานซ่อมบำรุง",
  i18nKey_web_1086: "เหตุผลในการซ่อมบำรุง",
  i18nKey_web_1087: "เนื้อหาการซ่อมบำรุง",
  i18nKey_web_1088: "ผลการซ่อมบำรุง",
  i18nKey_web_1089: "บันทึกการเตือนภัยของสิ่งอำนวยความสะดวก",
  i18nKey_web_1090: "บันทึกการซ่อมบำรุงสิ่งอำนวยความสะดวก",
  i18nKey_web_1091: "เปลี่ยนการตั้งค่า",
  i18nKey_web_1092: "เปิดไฟ",
  i18nKey_web_1093: "ปิดไฟ",
  i18nKey_web_1094: "โปรแกรมประยุกต์",
  i18nKey_web_1095:
    "ออกคำสั่งสำเร็จและสถานะจะอัปเดตในเวลาประมาณ 30 วินาที กรุณารอซักครู่",
  i18nKey_web_1096: "ลดแสง",
  i18nKey_web_1097: "คำแนะนำ: ความสว่างจะมีผล เมื่อไฟสว่างเท่านั้น",
  i18nKey_web_1098: "ลดแสงสำเร็จ",
  i18nKey_web_1099: "เหตุการณ์สำคัญ",
  i18nKey_web_1100: "เหตุการณ์ทั่วไป",
  i18nKey_web_1101: "ติดตามตอนนี้",
  i18nKey_web_1102: "ระบบควบคุมการเข้าถึง",
  i18nKey_web_1103: "ภาพวิดีโอ",
  i18nKey_web_1104: "รูปภาพที่เกี่ยวข้อง",
  i18nKey_web_1105: "อัปโหลด",
  i18nKey_web_1106: "วิดีโอที่เกี่ยวข้อง",
  i18nKey_web_1107: "การปรับใช้ที่เกี่ยวข้อง",
  i18nKey_web_1108: "เพิ่มการควบคุมการปรับใช้",
  i18nKey_web_1109: "อยู่นอกเหนือการควบคุม",
  i18nKey_web_1110: "รูปภาพการควบคุม",
  i18nKey_web_1111: "ระบบต้นทาง",
  i18nKey_web_1112: "สลายฝูงชน",
  i18nKey_web_1113: "เพื่อได้รับการยอมรับ",
  i18nKey_web_1114: "กลับไปที่พื้นหลังของกล้องที่เกี่ยวข้อง",
  i18nKey_web_1115: "กลับไปที่พื้นหลังของกล้องรอบข้าง",
  i18nKey_web_1116: "เพิ่มกล้องที่เกี่ยวข้อง กลับไปที่พื้นหลัง",
  i18nKey_web_1117: "ลบกล้องที่เกี่ยวข้อง กลับไปที่พื้นหลัง",
  i18nKey_web_1118: "คำแนะนำ: หลังจากกระจายงานแล้ว กิจกรรมจะปิดโดยอัตโนมัติ",
  i18nKey_web_1119: "การกระจายงาน",
  i18nKey_web_1120: "งานใหม่เสร็จสมบูรณ์",
  i18nKey_web_1121: "กระจายงาน",
  i18nKey_web_1122: "มอบหมายงาน",
  i18nKey_web_1123: "ระยะทาง",
  i18nKey_web_1124: "กรุณาเลือกบุคคลที่จะมอบหมายงาน",
  i18nKey_web_1125: "กรุณากรอกคำหลักเพื่อค้นหา",
  i18nKey_web_1126: "กรุณากรอกเนื้อหางาน",
  i18nKey_web_1127: "เพิ่มงานสำเร็จแล้ว",
  i18nKey_web_1128: "การหลอกลวงโดยผู้มีชื่อเสียงทางอินเทอร์เน็ต",
  i18nKey_web_1129: "Hyun Bin",
  i18nKey_web_1130: "กล้อง 002",
  i18nKey_web_1131: "กล้อง 003",
  i18nKey_web_1132: "กล้อง 004",
  i18nKey_web_1133: "กล้อง 005",
  i18nKey_web_1134: "รายงานเหตุการณ์โดยอัตโนมัติ",
  i18nKey_web_1135: "แบบ",
  i18nKey_web_1136: "Zhang San",
  i18nKey_web_1137: "ภาคเหนือ",
  i18nKey_web_1138: "ภาคใต้",
  i18nKey_web_1139: "ภาคตะวันตกตอนกลาง",
  i18nKey_web_1140: "เลือกหน้าจอต้อนรับ",
  i18nKey_web_1141: "กล้องที่ถูกเลือก",
  i18nKey_web_1142: "จำนวนกล้องออนไลน์",
  i18nKey_web_1143: "จำนวนกล้องทั้งหมด",
  i18nKey_web_1144: "หัวข้อ",
  i18nKey_web_1145: "ต้องกรอกชื่อเหตุการณ์",
  i18nKey_web_1146: "กรุณากรอกคำอธิบายเหตุการณ์",
  i18nKey_web_1147: "Vanke Chengdu Park",
  i18nKey_web_1148: "อาคาร 103",
  i18nKey_web_1149: "การรายงานเหตุการณ์ล้มเหลว",
  i18nKey_web_1150: "ภาพได้รับการบันทึกแล้วที่",
  i18nKey_web_1151: "การจับภาพล้มเหลว",
  i18nKey_web_1152: "รหัสข้อผิดพลาด:",
  i18nKey_web_1153: "จำนวนกลุ่มลาดตระเวน",
  i18nKey_web_1154: "กลุ่มลาดตระเวนใหม่",
  i18nKey_web_1155: "รายการลาดตระเวนแบบวิดีโอ",
  i18nKey_web_1156: "ประวัติลาดตระเวนแบบวิดีโอ",
  i18nKey_web_1157: "สิ้นสุด",
  i18nKey_web_1158: "หมวดหมู่อุปกรณ์",
  i18nKey_web_1159: "ผู้ดูแลเหตุการณ์",
  i18nKey_web_1160: "หมายเลขบัตรผู้ใช้",
  i18nKey_web_1161: "โทรศัพท์มือถือของผู้ดูแล",
  i18nKey_web_1162: "วิธีแก้ปัญหา",
  i18nKey_web_1163: "บัญชีผู้ใช้",
  i18nKey_web_1164: "ประเภทการดูแลรักษา",
  i18nKey_web_1165: "โทรศัพท์บ้านของผู้ดูแล",
  i18nKey_web_1166: "ประเภทการควบคุม",
  i18nKey_web_1167: "บันทึกเหตุการณ์",
  i18nKey_web_1168: "อัปโหลดได้สูงสุด 5 ภาพเท่านั้น",
  i18nKey_web_1169: "ขนาดภาพต้องไม่เกิน 2",
  i18nKey_web_1170: "จัดรูปแบบรูปภาพ",
  i18nKey_web_1171: "ชื่องานต้องไม่เกิน 50 อักขระ",
  i18nKey_web_1172: "การติดตามใบหน้า",
  i18nKey_web_1173: "วิถีของยานพาหนะ",
  i18nKey_web_1174: "เหตุผลในการปรับใช้งาน",
  i18nKey_web_1175:
    "กรุณากรอกเหตุผลในการ deployment โดยต้องไม่เกิน 150 ตัวอักษร",
  i18nKey_web_1176: "ชื่อ:",
  i18nKey_web_1177: "ระยะเวลา",
  i18nKey_web_1178: "ความคล้ายคลึงกัน",
  i18nKey_web_1179: "รูป",
  i18nKey_web_1180: "เพื่อกดหน้าต่างป๊อปอัป",
  i18nKey_web_1181: "กรุณากรอกประกาศคุณสมบัติ",
  i18nKey_web_1182: "แจ้งเจ้าหน้าที่",
  i18nKey_web_1183: "ชื่อจริง",
  i18nKey_web_1184: "กรุณากรอกชื่อหรือหมายเลขโทรศัพท์ของคุณ",
  i18nKey_web_1185: "เหตุผลในการปรับใช้งาน ต้องไม่เกิน 150 อักขระ",
  i18nKey_web_1186: "เลือกจากคลังรถ",
  i18nKey_web_1187: "การเลือกคลังยานพาหนะ",
  i18nKey_web_1188: "ดูคำเตือน",
  i18nKey_web_1189: "สถานที่:",
  i18nKey_web_1190: "แหล่งที่มา:",
  i18nKey_web_1191: "โปรแกรมเวลาเริ่มต้นและหยุดโดยอัตโนมัติ (0 หยุด",
  i18nKey_web_1192: "เริ่มต้น",
  i18nKey_web_1193: "โหมดควบคุมระบบปรับอากาศ (1 เริ่ม 2 หยุด 3 ควบคุมกำหนดการ)",
  i18nKey_web_1194: "เกินมูลค่าเป้าหมาย",
  i18nKey_web_1195: "กลางแจ้ง",
  i18nKey_web_1196: "ความชื้น",
  i18nKey_web_1197: "พื้นที่สิ่งแวดล้อม",
  i18nKey_web_1198: "จำนวนสัญญาณเตือนทั้งหมด",
  i18nKey_web_1199: "คาร์บอนไดออกไซด์",
  i18nKey_web_1200: "ฟอร์มาลดีไฮด์",
  i18nKey_web_1201: "กรอบ",
  i18nKey_web_1202: "ไอออนของออกซิเจนเชิงลบ",
  i18nKey_web_1203: "เสียงดัง",
  i18nKey_web_1204: "สารระเหยอินทรีย์",
  i18nKey_web_1205: "ดัชนีสิ่งแวดล้อม",
  i18nKey_web_1206: "สถิติโคมไฟถนน",
  i18nKey_web_1207: "ความคืบหน้าของงาน",
  i18nKey_web_1208: "ชื่อพื้นที่สิ่งแวดล้อม",
  i18nKey_web_1209: "ไม่ได้ตั้งค่า",
  i18nKey_web_1210: "ไม่ได้ใช้งาน",
  i18nKey_web_1211: "คำอธิบายใบสั่งงาน",
  i18nKey_web_1212: "กรุณากรอกคำอธิบายใบสั่งงาน",
  i18nKey_web_1213: "ส่งใบสั่งงานเสร็จสมบูรณ์",
  i18nKey_web_1214: "กรุณาเลือกบุคคลเพื่อมอบหมายตั๋ว",
  i18nKey_web_1215: "กรุณากรอกเนื้อหาตั๋ว",
  i18nKey_web_1216: "นายฉัน",
  i18nKey_web_1217: "ผู้ดำเนินการซ่อมแซม",
  i18nKey_web_1218: "เวลาอยู่บ้าน",
  i18nKey_web_1219: "กระจาย",
  i18nKey_web_1220: "ป้อนได้สูงสุด 100 อักขระ",
  i18nKey_web_1221: "ผู้รับผิดชอบในการสั่งงาน:",
  i18nKey_web_1222: "ค่าธรรมเนียมการดำเนินการ:",
  i18nKey_web_1223: "ระยะเวลาดำเนินการ:",
  i18nKey_web_1224: "กำลังดำเนินการ:",
  i18nKey_web_1225: "ชื่อการซ่อมแซม",
  i18nKey_web_1226: "สถานะการซ่อมแซม",
  i18nKey_web_1227: "ระดับการซ่อมแซม",
  i18nKey_web_1228: "เวลาการซ่อมแซม",
  i18nKey_web_1229: "สถานที่ซ่อมแซม",
  i18nKey_web_1230: "รวม",
  i18nKey_web_1231: "การใช้พลังงานพร้อมกัน",
  i18nKey_web_1232: "การใช้พลังงานต่อตารางเมตร",
  i18nKey_web_1233: "ปิดการใช้งาน",
  i18nKey_web_1234: "ทั้งวัน",
  i18nKey_web_1235: "ถ้วยตวง",
  i18nKey_web_1236: "หน่วย: ถ้วยตวง",
  i18nKey_web_1237: "วันนี้",
  i18nKey_web_1238: "พื้นที่",
  i18nKey_web_1239: "กรุณากรอกชั่วโมง",
  i18nKey_web_1240: "สิ่งอำนวยความสะดวกที่ชำระเงินแล้ว",
  i18nKey_web_1241: "กรุณากรอกราคาต่อหน่วย",
  i18nKey_web_1242: "ราคาต่อหน่วยครึ่งวัน (หยวน)",
  i18nKey_web_1243: "ชั่วโมง",
  i18nKey_web_1244: "กรุณากรอกราคาต่อหน่วยครึ่งวัน (หยวน)",
  i18nKey_web_1245: "กรุณาเลือกวันที่สิ้นสุด",
  i18nKey_web_1246: "ต้องไม่น้อยกว่า 4 ชั่วโมงต่อวัน!",
  i18nKey_web_1247: "การยกเลิกการคืนเงิน วงเงินต้องไม่เกิน 100",
  i18nKey_web_1248: "รูปแบบการขอคืนเงินออนไลน์กรอกไม่ถูกต้อง!",
  i18nKey_web_1249: "ค่าใช้จ่ายต่อวันหากถึง XX ชม",
  i18nKey_web_1250: "กรุณาเลือกเดือน",
  i18nKey_web_1251: "กรุณาเลือกบริษัท",
  i18nKey_web_1252: "โควต้าฟรี",
  i18nKey_web_1253: "แก้ไขเรียบร้อยแล้ว",
  i18nKey_web_1254: "ชื่อการประชุม",
  i18nKey_web_1255: "สถานที่นัดพบ",
  i18nKey_web_1256: "คนจอง",
  i18nKey_web_1257: "จำนวนผู้เข้าร่วมจริง",
  i18nKey_web_1258: "เลือกเดือน",
  i18nKey_web_1259: "ปริมาณการใช้ไฟฟ้าจริงต่อเดือน",
  i18nKey_web_1260: "สรุปข้อมูล",
  i18nKey_web_1261: "ภาพรวมการใช้พลังงาน",
  i18nKey_web_1262: "กดอัตโนมัติ",
  i18nKey_web_1263: "Dongli",
  i18nKey_web_1264: "Xili",
  i18nKey_web_1265: "Vankeli",
  i18nKey_web_1266: "หมายเลขเครื่องมือ",
  i18nKey_web_1267: "กรุณากรอกหมายเลขมิเตอร์",
  i18nKey_web_1268: "กรุณากรอกชื่อสวิตช์จ่ายไฟ",
  i18nKey_web_1269: "การจัดเตรียม",
  i18nKey_web_1270: "กรุณาเลือกประเภทมิเตอร์",
  i18nKey_web_1271: "ห้อง",
  i18nKey_web_1272: "มีผล",
  i18nKey_web_1273: "ปริมาณการใช้พลังงานล่าสุด",
  i18nKey_web_1274: "ปริมาณการใช้น้ำล่าสุด",
  i18nKey_web_1275: "จำเป็นต้องระบุวันที่อ่านมิเตอร์",
  i18nKey_web_1276: "รูปแบบของวันที่อ่านมิเตอร์ไม่ถูกต้อง ข้อกำหนดรูปแบบคือ:",
  i18nKey_web_1277:
    "ข้อมูลวันที่ของการอ่านมิเตอร์นี้ไม่ถูกต้อง กรุณากรอกข้อมูลวันที่ที่เหมาะสม",
  i18nKey_web_1278: "รูปแบบการอ่านของการอ่านมิเตอร์นี้ไม่ถูกต้อง",
  i18nKey_web_1279:
    "การอ่านค่าของการอ่านมิเตอร์นี้น้อยกว่าการอ่านค่าของการอ่านมิเตอร์ก่อนหน้า จะยืนยันการปรับเปลี่ยนหรือไม่",
  i18nKey_web_1280: "ครั้งนี้มี",
  i18nKey_web_1281: "ไม่ได้ป้อนตาราง",
  i18nKey_web_1282: "จะมีผลต่อไปหรือไม่",
  i18nKey_web_1283: "มีผลสำเร็จ",
  i18nKey_web_1284: "กรุณาเลือกเพื่อลบข้อมูล",
  i18nKey_web_1285:
    "รายละเอียดทั้งหมดของอุปกรณ์จะถูกลบหลังจากทำการลบ คุณแน่ใจหรือไม่ว่าจะลบ",
  i18nKey_web_1286: "เลือกแล้ว",
  i18nKey_web_1287: "เหรอ?",
  i18nKey_web_1288: "การลบล้มเหลว กรุณาลองอีกครั้งในภายหลัง",
  i18nKey_web_1289: "การซิงค์ค่าให้ตรงกัน",
  i18nKey_web_1290: "สัญญาณ 2",
  i18nKey_web_1291: "ระบบอำนวยความสะดวก 2",
  i18nKey_web_1292: "ป้ายกำกับการแจ้งเตือน",
  i18nKey_web_1293: "เปิดใช้งานการแจ้งเตือน",
  i18nKey_web_1294: "เร่งด่วน",
  i18nKey_web_1295: "สำคัญ",
  i18nKey_web_1296: "ทั่วไป",
  i18nKey_web_1297: "ไม่แน่นอน",
  i18nKey_web_1298: "เวลาที่มีผลบังคับใช้",
  i18nKey_web_1299: "เปิดใช้งานแผนการ",
  i18nKey_web_1300: "เขตมรณะ",
  i18nKey_web_1301: "กรุณากรอกค่าเขตมรณะ",
  i18nKey_web_1302: "กรุณากรอกคำอธิบายการแจ้งเตือน",
  i18nKey_web_1303: "สัญญาณ 1",
  i18nKey_web_1304: "การนำเข้าล้มเหลว เฉพาะการรองรับเท่านั้น",
  i18nKey_web_1305: "การนำเข้าล้มเหลว ไม่มีข้อมูลที่ถูกต้อง",
  i18nKey_web_1306: "นำเข้าล้มเหลว ข้อมูล point code ซ้ำกัน",
  i18nKey_web_1307:
    "ข้อมูลการดำเนินการนี้จะถูกลบอย่างถาวร คุณจะดำเนินการต่อหรือไม่?",
  i18nKey_web_1308: "จำเป็นต้องระบุ",
  i18nKey_web_1309: "ต้องประกอบด้วยตัวเลขและสัญลักษณ์เลขคณิต 4 ตัวเท่านั้น",
  i18nKey_web_1310: "คืนค่า",
  i18nKey_web_1311: "บันทึกล้มเหลว",
  i18nKey_web_1312: "หมายเลขสิ่งอำนวยความสะดวกของระบบต้นทาง",
  i18nKey_web_1313: "รหัส QR ของสิ่งอำนวยความสะดวก",
  i18nKey_web_1314: "ระดับความสำคัญ",
  i18nKey_web_1315: "จำนวนระดับการจำแนกประเภท",
  i18nKey_web_1316: "หมายเลขซีเรียลของสิ่งอำนวยความสะดวก",
  i18nKey_web_1317: "พิกัด - ระดับความสูง",
  i18nKey_web_1318: "ดูภาพบุคคลที่อัปโหลด",
  i18nKey_web_1319: "ดูแผนผังสิ่งอำนวยความสะดวกในการอัปโหลด",
  i18nKey_web_1320: "กรุณาเลือกเวอร์ชันเก่า",
  i18nKey_web_1321: "รุ่น",
  i18nKey_web_1322: "0 หน้าจอขนาดใหญ่ได้รับการเชื่อมต่อ",
  i18nKey_web_1323: "บันทึกไม่สำเร็จ!",
  i18nKey_web_1324: "ความยาวต้องไม่เกิน 50 อักขระ",
  i18nKey_web_1325: "เพิ่มขึ้น",
  i18nKey_web_1326: "เพิ่มไม่สำเร็จ",
  i18nKey_web_1327: "แน่ใจหรือไม่ว่าต้องการลบความสัมพันธ์?",
  i18nKey_web_1328: "ลบการเชื่อมโยงสำเร็จ",
  i18nKey_web_1329: "ยืนยันที่จะบันทึกหรือไม่?",
  i18nKey_web_1330: "ยืนยันที่จะยกเลิกหรือไม่?",
  i18nKey_web_1331: "เพิ่มไม่สำเร็จ!",
  i18nKey_web_1332: "กรุณาเลือกห้อง",
  i18nKey_web_1333: "รหัสเจ้าหน้าที่ระบบต้นทาง",
  i18nKey_web_1334: "ชื่อระบบต้นทาง",
  i18nKey_web_1335: "ตราสัญลักษณ์",
  i18nKey_web_1336: "รูปถ่าย",
  i18nKey_web_1337: "ที่อยู่จัดเก็บรูปภาพ",
  i18nKey_web_1338: "รหัสประเภทภาพถ่าย",
  i18nKey_web_1339: "รูปถ่ายบุคคล",
  i18nKey_web_1340: "ข้อมูลป้าย",
  i18nKey_web_1341: "มีประสิทธิภาพหรือไม่",
  i18nKey_web_1342: "กรุณาเลือกแผนก",
  i18nKey_web_1343: "หนังสือเดินทาง",
  i18nKey_web_1344: "เพิ่มเรียบร้อยแล้ว",
  i18nKey_web_1345: "ต้องระบุรูปภาพ",
  i18nKey_web_1346: "แก้ไขข้อมูลพื้นฐานของบุคลากรเรียบร้อยแล้ว",
  i18nKey_web_1347: "ตกลงเพื่อเปิด",
  i18nKey_web_1348: "ยืนยันเพื่อปิด",
  i18nKey_web_1349: "ผู้ใช้ทั้งหมด",
  i18nKey_web_1350: "ทุกกลุ่ม",
  i18nKey_web_1351: "ข้อมูลกลุ่ม",
  i18nKey_web_1352: "กรุณากรอกแผนกผู้ใช้",
  i18nKey_web_1353: "ตกลงเพื่อลบกลุ่ม",
  i18nKey_web_1354: "ตกลงเพื่อลบผู้ใช้ที่เลือก",
  i18nKey_web_1355: "กรุณาเลือกผู้ใช้ที่จะลบ",
  i18nKey_web_1356: "ฮ่องกงและมาเก๊า",
  i18nKey_web_1357: "เปิด",
  i18nKey_web_1358: "ทรัพยากร",
  i18nKey_web_1359: "ปัจจุบัน",
  i18nKey_web_1360: "ทรัพยากรเมนู",
  i18nKey_web_1361: "ลบบทบาทสำเร็จแล้ว",
  i18nKey_web_1362: "การลบบทบาทล้มเหลว กรุณาลองอีกครั้ง",
  i18nKey_web_1363: "กรุณาเลือกประเภท",
  i18nKey_web_1364: "สร้างบทบาทสำเร็จแล้ว",
  i18nKey_web_1365: "มีความผิดปกติของเครือข่าย กรุณาลองอีกครั้งในภายหลัง",
  i18nKey_web_1366: "แก้ไขบทบาทสำเร็จแล้ว",
  i18nKey_web_1367: "ปรับเปลี่ยนบทบาท",
  i18nKey_web_1368: "ผู้ดูแลระบบขั้นสูง",
  i18nKey_web_1369: "ผู้ดูแลแพลตฟอร์ม",
  i18nKey_web_1370: "ผู้ดูแล park",
  i18nKey_web_1371: "บทบาทของแพลตฟอร์ม",
  i18nKey_web_1372: "บทบาทปกติใน park",
  i18nKey_web_1373: "บทบาทความปลอดภัยของ park",
  i18nKey_web_1374: "บทบาททรัพย์สินของ park",
  i18nKey_web_1375: "บทบาทของการทำความสะอาดใน park",
  i18nKey_web_1376: "มีชื่อบทบาทอยู่แล้ว",
  i18nKey_web_1377: "คุณแน่ใจว่าต้องการลบหรือไม่?",
  i18nKey_web_1378: "ดำเนินการ",
  i18nKey_web_1379: "ท่าเทียบเรือ",
  i18nKey_web_1380: "ยกเครื่อง",
  i18nKey_web_1381: "ลิฟต์โดยสาร",
  i18nKey_web_1382: "ลิฟต์",
  i18nKey_web_1383: "การควบคุมแสง",
  i18nKey_web_1384: "ประเภทอุปกรณ์",
  i18nKey_web_1385: "ที่อยู่อุปกรณ์",
  i18nKey_web_1386: "กำลังโหลด",
  i18nKey_web_1387: "กำลังปรับปรุง",
  i18nKey_web_1388: "ป้อนชื่ออุปกรณ์",
  i18nKey_web_1389: "กำลังเล่น",
  i18nKey_web_1390: "ทุกหน้าจอขนาดใหญ่",
  i18nKey_web_1391: "สถานะที่เปิดใช้งาน",
  i18nKey_web_1392: "ลบนโยบาย",
  i18nKey_web_1393: "จำเป็นต้องระบุเวลาที่มีผลบังคับใช้ของแต่ละกลยุทธ์",
  i18nKey_web_1394: "กฎการส่องสว่าง",
  i18nKey_web_1395: "จำเป็นต้องระบุ",
  i18nKey_web_1396: "การเพิ่มล้มเหลว",
  i18nKey_web_1397: "แน่ใจไหมว่าต้องการลบแผนการนี้",
  i18nKey_web_1398: "ออกแผนการสำเร็จ",
  i18nKey_web_1399: "บันทึกการกำหนดค่าเบรกเกอร์สำเร็จ!",
  i18nKey_web_1400: "เปลี่ยนเรียบร้อยแล้ว",
  i18nKey_web_1401: "รายชื่อลูกค้า",
  i18nKey_web_1402: "หมายเลขบุคลากร",
  i18nKey_web_1403: "เข้าเยี่ยมชม parkในสัปดาห์ที่ผ่านมา",
  i18nKey_web_1404: "เข้าเยี่ยมชม park ในช่วงเช้าของสัปดาห์ที่ผ่านมา",
  i18nKey_web_1405: "วงจรของผู้ใช้",
  i18nKey_web_1406: "ใช้เวลาในการสั่งซื้อ",
  i18nKey_web_1407: "ใช้เวลาสั้นที่สุด",
  i18nKey_web_1408: "ใช้เวลานานที่สุด",
  i18nKey_web_1409: "ต้นทุนเวลา",
  i18nKey_web_1410: "ชิ้น",
  i18nKey_web_1411: "สิ่งอำนวยความสะดวก",
  i18nKey_web_1412: "จำนวนสิ่งอำนวยความสะดวก",
  i18nKey_web_1413: "เวลาในการประมวลผลเฉลี่ย",
  i18nKey_web_1414: "รหัสสิ่งอำนวยความสะดวก",
  i18nKey_web_1415: "เลือกมิติข้อมูลย่อย",
  i18nKey_web_1416: "กรุณาเลือกข้อมูลแบบละเอียด",
  i18nKey_web_1417: "ระดับรายการย่อย",
  i18nKey_web_1418: "กรุณาเลือกระดับ",
  i18nKey_web_1419: "การแจ้งเตือนที่สำคัญ",
  i18nKey_web_1420: "หมายเลขมิเตอร์:",
  i18nKey_web_1421: "จริงจัง",
  i18nKey_web_1422: "การใช้น้ำ",
  i18nKey_web_1423: "ปีที่แล้ว",
  i18nKey_web_1424: "การใช้พลังงาน",
  i18nKey_web_1425: "อ่านมิเตอร์อัตโนมัติ",
  i18nKey_web_1426: "การอ่านมิเตอร์ด้วยตนเอง",
  i18nKey_web_1427: "ความร้อนในอดีต",
  i18nKey_web_1428: "เต็มจอ",
  i18nKey_web_1429: "ตัวเลือกย่อย",
  i18nKey_web_1430: "การใช้พลังงานในเดือนนี้",
  i18nKey_web_1431: "ประเภทข้อยกเว้น",
  i18nKey_web_1432: "เวลาไฟฟ้า",
  i18nKey_web_1433: "เวลาสถิติ",
  i18nKey_web_1434: "ปริมาณการใช้ไฟฟ้าจริงในเดือนนี้",
  i18nKey_web_1435: "ปริมาณการใช้ไฟฟ้าจริงของเดือนที่แล้ว",
  i18nKey_web_1436: "รายละเอียดไฟฟ้า",
  i18nKey_web_1437: "รายละเอียดอุบัติเหตุ",
  i18nKey_web_1438: "จาก",
  i18nKey_web_1439: "ความล้มเหลวที่เกิดขึ้นซ้ำ และระยะเวลาสะสม",
  i18nKey_web_1440: "จำนวนชั่วโมง อาจทำให้เกิดของเสียได้ตลอดทั้งปี",
  i18nKey_web_1441: "สถานะการประมวลผล",
  i18nKey_web_1442: "การรักษาด้วยการวินิจฉัย",
  i18nKey_web_1443: "ชื่อข้อบกพร่อง",
  i18nKey_web_1444: "กรุณากรอกชื่อข้อบกพร่อง",
  i18nKey_web_1445: "ระดับความล้มเหลว",
  i18nKey_web_1446: "สถิติความล้มเหลว",
  i18nKey_web_1447: "จำนวนความล้มเหลว",
  i18nKey_web_1448: "จำนวนความล้มเหลวทั้งหมด",
  i18nKey_web_1449: "อัปโหลดใหม่",
  i18nKey_web_1450: "กรุณากรอกหมายเลขมิเตอร์",
  i18nKey_web_1451: "กรุณาเลือกหมวดหมู่",
  i18nKey_web_1452: "กรุณาเลือกระยะเวลาการอ่านมิเตอร์",
  i18nKey_web_1453: "กรุณาเลือกว่าผิดปกติหรือไม่",
  i18nKey_web_1454: "หมายเลขมิเตอร์",
  i18nKey_web_1455: "การอ่าน point",
  i18nKey_web_1456: "ปริมาณการใช้ไฟฟ้าปัจจุบัน",
  i18nKey_web_1457: "ชื่อแบรนด์",
  i18nKey_web_1458: "จำนวนความผิดปกติ",
  i18nKey_web_1459: "จำนวนข้อยกเว้นทั้งหมด",
  i18nKey_web_1460: "ข้อมูลสูญหาย",
  i18nKey_web_1461: "ตารางสรุป",
  i18nKey_web_1462: "สำรอง",
  i18nKey_web_1463: "มีอยู่",
  i18nKey_web_1464: "ข้อมูล, 0 ข้อมูลผิดปกติ กรุณายืนยันเพื่อส่ง",
  i18nKey_web_1465: "ข้อมูลนำเข้า",
  i18nKey_web_1466: "สถานะข้อมูล",
  i18nKey_web_1467: "กรุณาเลือกสถานะของข้อมูลที่ถูกต้อง",
  i18nKey_web_1468: "เวลาอ่านมิเตอร์นี้",
  i18nKey_web_1469: "ป้อนข้อมูลการอ่านมิเตอร์",
  i18nKey_web_1470: "คุณได้ทำการป้อนแล้ว",
  i18nKey_web_1471: "เมื่อเทียบกับข้อมูลก่อนหน้านี้ มี",
  i18nKey_web_1472: "ข้อมูลผิดปกติ",
  i18nKey_web_1473: "คุณแน่ใจหรือไม่ว่าจะส่ง",
  i18nKey_web_1474: "คุณได้นำเข้า",
  i18nKey_web_1475: "ข้อมูลบทความ",
  i18nKey_web_1476: "มี",
  i18nKey_web_1477: "ยังไม่ได้อ่านทั้งหมด",
  i18nKey_web_1478: "ไม่อ่านในฉบับนี้",
  i18nKey_web_1479: "อ่าน",
  i18nKey_web_1480: "ปริมาณการใช้น้ำในช่วงนี้",
  i18nKey_web_1481: "การส่งออกล้มเหลว ไม่มีข้อมูลในหน้าปัจจุบัน",
  i18nKey_web_1482: "Huanglong Vanke",
  i18nKey_web_1483: "ใช้",
  i18nKey_web_1484: "น้ำ",
  i18nKey_web_1485: "จำนวน",
  i18nKey_web_1486: "ปริมาณการใช้ไฟฟ้าทั้งหมด",
  i18nKey_web_1487: "การใช้งานทั้งหมด",
  i18nKey_web_1488: "กราฟข้อมูลจุด",
  i18nKey_web_1489: "พลังงานของห้องคอมพิวเตอร์",
  i18nKey_web_1490: "ในโครงการทั้งหมดในปีนี้ คุณพบทั้งหมดของ",
  i18nKey_web_1491: "ศักยภาพในการประหยัดพลังงาน",
  i18nKey_web_1492: "พื้นที่ทางทิศใต้",
  i18nKey_web_1493: "ห้องเครื่อง",
  i18nKey_web_1494: "เขตเหนือ",
  i18nKey_web_1495: "ตัวแปร",
  i18nKey_web_1496: "อัตราส่วนประสิทธิภาพการใช้พลังงาน",
  i18nKey_web_1497: "พลังงานแบบเรียลไทม์",
  i18nKey_web_1498: "หมายเลขหน่วย",
  i18nKey_web_1499: "ปริมาณการใช้รายเดือน",
  i18nKey_web_1500: "เลือกเวลาเริ่มต้น",
  i18nKey_web_1501: "เลือกเวลาสิ้นสุด",
  i18nKey_web_1502: "เลือกไอคอน",
  i18nKey_web_1503: "สวิตช์ล็อคสถานการณ์",
  i18nKey_web_1504: "เปิดสำเร็จแล้ว",
  i18nKey_web_1505: "ปิดสำเร็จแล้ว",
  i18nKey_web_1506: "การลบแบทช์",
  i18nKey_web_1507: "ยืนยันที่จะลบอพาร์ตเมนต์",
  i18nKey_web_1508: "ประเภทหน่วย",
  i18nKey_web_1509: "เบอร์ห้อง",
  i18nKey_web_1510: "อาคารและพื้นที่สาธารณะใหม่",
  i18nKey_web_1511: "กรุณาเข้าสู่อาคาร",
  i18nKey_web_1512: "เพิ่มหมายเลขห้อง",
  i18nKey_web_1513: "กรุณากรอกหมายเลขห้อง",
  i18nKey_web_1514: "กรุณาเลือกหน่วยอาคาร เพื่อเพิ่มหมายเลขห้อง",
  i18nKey_web_1515: "กรุณาเลือกห้องที่จะลบ",
  i18nKey_web_1516: "วิดีโอหยุดชั่วคราว",
  i18nKey_web_1517: "วิดีโอลาดตระเวนสิ้นสุด",
  i18nKey_web_1518: "หยุดเล่นชั่วคราว 1",
  i18nKey_web_1519: "หยุดชั่วคราว 1",
  i18nKey_web_1520: "ตัวจับเวลา",
  i18nKey_web_1521: "หยุดเล่นชั่วคราว 3",
  i18nKey_web_1522: "เล่น",
  i18nKey_web_1523: "กลุ่มลาดตระเวนจะเล่นในช่วงเวลาปกติ",
  i18nKey_web_1524: "กำลังเล่นอยู่ -",
  i18nKey_web_1525: "กลุ่มลาดตระเวน",
  i18nKey_web_1526: "กล้อง",
  i18nKey_web_1527: "เส้นทางลาดตระเวนใหม่",
  i18nKey_web_1528: "รายชื่อเส้นทางลาดตระเวน",
  i18nKey_web_1529: "วิดีโอแบบเรียลไทม์",
  i18nKey_web_1530: "เลือกวันที่และเวลาเริ่มต้น",
  i18nKey_web_1531: "เลือกวันที่และเวลาสิ้นสุด",
  i18nKey_web_1532: "เชื่อมโยงวิดีโอซึ่งเกิดขึ้นในอดีต",
  i18nKey_web_1533: "สกัดกั้นสำเร็จ",
  i18nKey_web_1534: "การจับภาพวิดีโอล้มเหลว",
  i18nKey_web_1535: "การตั้งค่าพื้นฐาน",
  i18nKey_web_1536: "ล่วงหน้า",
  i18nKey_web_1537: "ส่วนลดรายเดือน",
  i18nKey_web_1538: "ปีขึ้นไป",
  i18nKey_web_1539: "เดือน",
  i18nKey_web_1540: "สร้างสำเร็จ",
  i18nKey_web_1541: "กรุณาเลือกช่วงเวลา",
  i18nKey_web_1542: "เลือกธุรกิจ",
  i18nKey_web_1543: "ตอบแทนความสำเร็จ",
  i18nKey_web_1544: "ราคาเดิม",
  i18nKey_web_1545: "ผู้ดูแลระบบ",
  i18nKey_web_1546: "เวลาเริ่มต้นของการระงับ",
  i18nKey_web_1547: "เวลาสิ้นสุดของการระงับ",
  i18nKey_web_1548: "ยกเลิกการสมัครก่อน",
  i18nKey_web_1549: "สามารถยกเลิกการสมัครล่วงหน้าได้กี่วัน",
  i18nKey_web_1550: "กรุณากรอกละติจูดที่อยู่",
  i18nKey_web_1551: "สิ่งอำนวยความสะดวกเพิ่มเติม",
  i18nKey_web_1552: "เพิ่มสิ่งอำนวยความสะดวกเพิ่มเติม",
  i18nKey_web_1553:
    "ตัวอย่างเช่น 15 หยวนสำหรับชั่วโมงแรก (ฟรี 15 นาทีแรก) หลังจากนั้น 10 หยวนต่อชั่วโมง",
  i18nKey_web_1554: "ต่อยอด",
  i18nKey_web_1555: "park ใกล้เคียง",
  i18nKey_web_1556: "ตัวอย่าง: ผิงอัน",
  i18nKey_web_1557:
    "15 หยวนสำหรับชั่วโมงแรก (ฟรี 15 นาทีแรก) หลังจากนั้น 10 หยวนต่อชั่วโมง",
  i18nKey_web_1558: "เขตฝูเทียน เมืองเซินเจิ้น มณฑลกวางตุ้ง",
  i18nKey_web_1559: "เพิ่มพื้นที่สำนักงาน",
  i18nKey_web_1560: "ปรับเปลี่ยนพื้นที่สำนักงาน",
  i18nKey_web_1561: "รายละเอียดพื้นที่สำนักงาน",
  i18nKey_web_1562:
    "เส้นทางเมนูสิ่งอำนวยความสะดวกที่เพิ่มเติมใหม่: บริการขององค์กร",
  i18nKey_web_1563: "การจัดการห้องประชุม",
  i18nKey_web_1564: "บริการเสริม!",
  i18nKey_web_1565: "มีอยู่!",
  i18nKey_web_1566:
    "การป้อนข้อมูลละติจูดและลองจิจูดที่อยู่ต้องคั่นด้วยเครื่องหมายจุลภาค",
  i18nKey_web_1567: "และต้องไม่เกิน 20 อักขระ",
  i18nKey_web_1568: "กรุณากรอกหมายเลขสถานที่",
  i18nKey_web_1569: "กรุณาเลือกสิ่งที่ต้องอัปโหลด",
  i18nKey_web_1570: "รูป!",
  i18nKey_web_1571: "ไฟล์ภาพ!",
  i18nKey_web_1572: "เพิ่มตัวแจ้งเตือนข้อความใน Yuncheng เท่านั้น",
  i18nKey_web_1573: "ผู้ใช้ที่ลงทะเบียน",
  i18nKey_web_1574: "ข้อมูลข่าวสารจะแสดงใน Yuncheng",
  i18nKey_web_1575: "รายการข้อความ",
  i18nKey_web_1576: "หมายเลขโทรศัพท์:",
  i18nKey_web_1577: "ข้อมูลผู้สมัคร",
  i18nKey_web_1578: "กรุณากรอกชื่อผู้สมัคร",
  i18nKey_web_1579: "เวลาสมัคร",
  i18nKey_web_1580: "กรุณาเลือกเวลารับสมัคร",
  i18nKey_web_1581: "ปฏิเสธ",
  i18nKey_web_1582: "ชื่อผู้สมัคร",
  i18nKey_web_1583: "โทรศัพท์ผู้สมัคร",
  i18nKey_web_1584: "เวลาใช้งาน",
  i18nKey_web_1585: "ถูกปฏิเสธ",
  i18nKey_web_1586: "ผู้อนุมัติ",
  i18nKey_web_1587: "เวลาตรวจสอบ",
  i18nKey_web_1588: "การติดตั้งบัญชีดำ",
  i18nKey_web_1589: "คำเชิญแขก",
  i18nKey_web_1590: "ข้อมูลผู้เยี่ยมชม",
  i18nKey_web_1591: "กรุณากรอกชื่อผู้เยี่ยมชม",
  i18nKey_web_1592: "กรุณากรอกหน่วยงาน",
  i18nKey_web_1593: "กรุณาเลือกผู้เยี่ยมชม",
  i18nKey_web_1594: "เซี่ยงไฮ้",
  i18nKey_web_1595: "วันที่:",
  i18nKey_web_1596: "สถิติของสัดส่วนพนักงาน",
  i18nKey_web_1597: "จำนวนการจัดอันดับบุคลากร",
  i18nKey_web_1598: "สองวันก่อน",
  i18nKey_web_1599: "กรุณาเลือกวันที่อัปเดต",
  i18nKey_web_1600: "โปรดเลือกสถานะอุปกรณ์",
  i18nKey_web_1601: "เยี่ยมชม park",
  i18nKey_web_1602: "กลยุทธ์ที่มีอยู่",
  i18nKey_web_1603: "กลยุทธ์ใหม่",
  i18nKey_web_1604: "กรุณาเลือกตำแหน่งพื้นที่",
  i18nKey_web_1605: "สถานะกลยุทธ์",
  i18nKey_web_1606: "กรุณาเลือกสถานะกลยุทธ์",
  i18nKey_web_1607: "ยังไม่เปิด",
  i18nKey_web_1608: "ที่อยู่ผู้สัมภาษณ์",
  i18nKey_web_1609: "กรุณากรอกโทรศัพท์ของแขก",
  i18nKey_web_1610: "ระยะเวลาการอนุญาต",
  i18nKey_web_1611: "เหตุผลของการเยี่ยมชม",
  i18nKey_web_1612: "บัตรผ่าน",
  i18nKey_web_1613: "ประเภทบัตรผ่าน",
  i18nKey_web_1614: "กรุณาเลือกชื่อผู้ผลิต",
  i18nKey_web_1615: "ต้องการลบหรือไม่?",
  i18nKey_web_1616: "กรุณากรอกชื่อโฮสต์",
  i18nKey_web_1617: "ข้อมูลการป้องกันและควบคุมการแพร่ระบาด",
  i18nKey_web_1618: "คำเชิญสำเร็จ",
  i18nKey_web_1619: "บันทึกผู้เยี่ยมชม",
  i18nKey_web_1620: "คำเชิญแขก",
  i18nKey_web_1621: "สถิติการเชิญผู้เยี่ยมชม",
  i18nKey_web_1622: "เกือบ 30 วัน",
  i18nKey_web_1623: "การจัดอันดับคำเชิญผู้เยี่ยมชม park",
  i18nKey_web_1624: "สถิติผู้เยี่ยมชม",
  i18nKey_web_1625: "ประวัติศาสตร์ของ park เชิญชวนเสมอ",
  i18nKey_web_1626: "คำเชิญในอดีต",
  i18nKey_web_1627: "หน่วย",
  i18nKey_web_1628: "สัดส่วนปัจจุบัน",
  i18nKey_web_1629: "สัดส่วนในช่วงเดียวกันของปีที่ผ่านมา",
  i18nKey_web_1630: "การกำหนดค่ารายการย่อยไฟฟ้า",
  i18nKey_web_1631:
    "หมายเหตุ: สัดส่วนการใช้พลังงานของระดับปัจจุบันและระดับสุดท้ายเกิน",
  i18nKey_web_1632:
    "พบความผิดปกติ การใช้พลังงานทั้งหมดของระดับนี้มาจากตู้ขาเข้าแรงดันต่ำ",
  i18nKey_web_1633: "แผนภาพกระแสของการใช้พลังงานแบบระบุรายการ",
  i18nKey_web_1634: "กรุณาเลือกระยะเวลาการใช้งานของสิ่งอำนวยความสะดวก",
  i18nKey_web_1635: "ตัวย่อ",
  i18nKey_web_1636: "กรุณากรอกชื่อผู้รับผิดชอบ",
  i18nKey_web_1637: "ความเป็นเจ้าของ",
  i18nKey_web_1638: "กรุณากรอกหมายเลขอุปกรณ์",
  i18nKey_web_1639: "การใช้",
  i18nKey_web_1640: "ในการบำรุงรักษา",
  i18nKey_web_1641: "ต้องการลบหรือไม่",
  i18nKey_web_1642: "กรุณากรอกชื่อหรือหมายเลขโทรศัพท์ของคุณ",
  i18nKey_web_1643: "เส้นทางรถเมล์",
  i18nKey_web_1644: "เวลาที่เหลือ",
  i18nKey_web_1645: "การชำระเงินล้มเหลว",
  i18nKey_web_1646: "ไม่มีบันทึกการเดินทาง ไม่ต้องดำเนินการส่งออก!",
  i18nKey_web_1647: "รายงาน?",
  i18nKey_web_1648: "ราคาแพ็คเกจ",
  i18nKey_web_1649: "เวลาชำระเงิน",
  i18nKey_web_1650: "วันที่มีผลบังคับใช้",
  i18nKey_web_1651: "วันหมดอายุ",
  i18nKey_web_1652: "กรุณาเลือกเวลาปรากฏตัว",
  i18nKey_web_1653: "สี",
  i18nKey_web_1654: "การชำระเงินชั่วคราว",
  i18nKey_web_1655: "รายการสำหรับสืบค้นข้อมูล",
  i18nKey_web_1656: "ปักกิ่ง",
  i18nKey_web_1657: "เทียนจิน",
  i18nKey_web_1658: "เซี่ยงไฮ้",
  i18nKey_web_1659: "ฉงชิ่ง",
  i18nKey_web_1660: "Xinjiang",
  i18nKey_web_1661: "Jiangsu",
  i18nKey_web_1662: "เจ้อเจียง",
  i18nKey_web_1663: "Heilongjiang",
  i18nKey_web_1664: "Hunan",
  i18nKey_web_1665: "Anhui",
  i18nKey_web_1666: "Shandong",
  i18nKey_web_1667: "Jiangxi",
  i18nKey_web_1668: "Heibei",
  i18nKey_web_1669: "Henan",
  i18nKey_web_1670: "Yunnan",
  i18nKey_web_1671: "Liaoning",
  i18nKey_web_1672: "หูเป่ย",
  i18nKey_web_1673: "Guangxi",
  i18nKey_web_1674: "Gansu",
  i18nKey_web_1675: "Shanxi",
  i18nKey_web_1676: "Inner Mongolia",
  i18nKey_web_1677: "Shaanxi",
  i18nKey_web_1678: "Jilin",
  i18nKey_web_1679: "Fujian",
  i18nKey_web_1680: "Guizhou",
  i18nKey_web_1681: "กวางตุ้ง",
  i18nKey_web_1682: "Qinghai",
  i18nKey_web_1683: "Tibet",
  i18nKey_web_1684: "Sichuan",
  i18nKey_web_1685: "Ningxia",
  i18nKey_web_1686: "Hainan",
  i18nKey_web_1687: "Taiwan",
  i18nKey_web_1688: "Hong Kong",
  i18nKey_web_1689: "Macau",
  i18nKey_web_1690: "มากกว่า 8",
  i18nKey_web_1691: "มากกว่า 12",
  i18nKey_web_1692: "มากกว่า 24",
  i18nKey_web_1693: "ผลรวมทั้งสิ้น",
  i18nKey_web_1694: "มากกว่า 1200",
  i18nKey_web_1695: "มากกว่า 1600",
  i18nKey_web_1696: "ค่าจอดรถสะสม",
  i18nKey_web_1697: "คุณสมบัติพื้นฐาน",
  i18nKey_web_1698: "ปรากฏตัว",
  i18nKey_web_1699: "การละเมิด",
  i18nKey_web_1700: "การระงับการชำระเงินสะสมชั่วคราว",
  i18nKey_web_1701: "หยวน คุณสามารถแนะนำบัตรรายเดือนให้เขาได้",
  i18nKey_web_1702: "วิธีการ",
  i18nKey_web_1703:
    "มีค่าล่วงเวลามากขึ้น 20 คืน ยอดสะสมรวมเป็น 10 วันในเดือนที่ผ่านมา",
  i18nKey_web_1704: "หลังจากออกจาก park",
  i18nKey_web_1705:
    "ฉันเริ่มทำงานก่อนเวลาและยอดสะสมรวม 10 วันในเดือนที่ผ่านมา ณ เวลา 8.00 น",
  i18nKey_web_1706: "ก่อนเข้า park",
  i18nKey_web_1707: "เร็วที่สุดในเดือนที่ผ่านมา",
  i18nKey_web_1708: "เข้าไปใน park",
  i18nKey_web_1709: "ล่าสุด",
  i18nKey_web_1710: "ออกจาก park",
  i18nKey_web_1711: "เมื่อเดือนก่อน",
  i18nKey_web_1712: "เมื่อเดือนกันยายนที่ผ่านมา",
  i18nKey_web_1713: "จำนวนคนปัจจุบัน",
  i18nKey_web_1714: "ผู้เช่า",
  i18nKey_web_1715: "ประเภทของข้อมูล",
  i18nKey_web_1716: "แน่นอน",
  i18nKey_web_1717: "ชุด",
  i18nKey_web_1718: "ลบเรียบร้อยแล้ว",
  i18nKey_web_1719: "การลบออกล้มเหลว",
  i18nKey_web_1720: "แก้ไขไม่สำเร็จ!",
  i18nKey_web_1721: "ดาวน์โหลดล้มเหลว",
  i18nKey_web_1722: "อัตราส่วนอายุ",
  i18nKey_web_1723: "Shenzhen Vanke Yuncheng",
  i18nKey_web_1724: "การจัดเตรียมสำนักงานการผลิต",
  i18nKey_web_1725: "ออกแบบส่วนที่อยู่ร่วมกัน",
  i18nKey_web_1726: "ส่วน Yunchuang 2",
  i18nKey_web_1727: "ผู้อาศัยใน Park - ค่างวด",
  i18nKey_web_1728: "ผู้อาศัยใน Park โซน 1",
  i18nKey_web_1729: "ร้านค้าเชิงพาณิชย์ - ค่างวด",
  i18nKey_web_1730: "ตงลี่โซน",
  i18nKey_web_1731: "Xili-Zone",
  i18nKey_web_1732: "Shenzhen Yuncheng",
  i18nKey_web_1733: "หย่งชิงฟาง",
  i18nKey_web_1734: "ชั้น",
  i18nKey_web_1735: "พื้นที่",
  i18nKey_web_1736: "ความคล่องตัวของผู้โดยสาร",
  i18nKey_web_1737: "เครื่องมือค้นหา",
  i18nKey_web_1738: "สถิติความคล่องตัวของผู้โดยสาร",
  i18nKey_web_1739: "กรุณากรอกตั๋ว",
  i18nKey_web_1740:
    "หากมีจุดแวะพักหลายจุด กรุณากรอกในรูปแบบต่อไปนี้: Vanke Yuncheng",
  i18nKey_web_1741: "ศูนย์เป่าอัน",
  i18nKey_web_1742: "อ่าวด้านหน้า",
  i18nKey_web_1743: "สนามบินเป่าอัน",
  i18nKey_web_1744: "กรุณาเลือกวันที่ที่ถูกต้อง",
  i18nKey_web_1745: "ราคาแปลงอัตโนมัติ",
  i18nKey_web_1746: "กรุณากรอกน้อยกว่า 1",
  i18nKey_web_1747: "ราคาระหว่าง",
  i18nKey_web_1748: "กรุณาเลือกตารางเวลารถบัสที่จะใช้",
  i18nKey_web_1749: "แก้ไขแพ็คเกจ",
  i18nKey_web_1750: "ตรวจสอบเมนูชุด",
  i18nKey_web_1751: "เช่น",
  i18nKey_web_1752: "กรุณากรอกอุปกรณ์สำหรับสแกน",
  i18nKey_web_1753: "ตัวอย่างเช่น รถบัส จำกัดที่ 50 คน",
  i18nKey_web_1754: "การเข้ารหัส",
  i18nKey_web_1755: "การปรับเปลี่ยนให้ตรงกัน",
  i18nKey_web_1756: "ส่งข้อความ",
  i18nKey_web_1757: "การตรวจสอบการควบคุมการเข้าถึง",
  i18nKey_web_1758: "เข้าถึงอาคารได้ทั้งหมด",
  i18nKey_web_1759: "เปิด",
  i18nKey_web_1760: "การควบคุมการเข้าถึง",
  i18nKey_web_1761: "การควบคุมการปรับใช้งานจริง",
  i18nKey_web_1762: "ข่าวเพิ่มเติม",
  i18nKey_web_1763: "อัปโหลดรูปภาพ",
  i18nKey_web_1764: "เพิ่มการควบคุมใบหน้า",
  i18nKey_web_1765: "การควบคุมการปรับใช้ยานพาหนะใหม่",
  i18nKey_web_1766: "ปลดอาวุธ",
  i18nKey_web_1767: "คำเตือนสิ่งอำนวยความสะดวกสำหรับการขนส่ง",
  i18nKey_web_1768: "การบุกรุกที่ผิดกฎหมาย",
  i18nKey_web_1769: "การเรียกหัวหน้าการรักษาความปลอดภัย",
  i18nKey_web_1770: "วางสาย",
  i18nKey_web_1771: "สายเข้า",
  i18nKey_web_1772: "โทร",
  i18nKey_web_1773: "ที่ใช้กันทั่วไป",
  i18nKey_web_1774: "ผู้ติดต่อ",
  i18nKey_web_1775: "ภาพการแจ้งเตือน",
  i18nKey_web_1776: "ตัวจำลองการแจ้งเตือน",
  i18nKey_web_1777: "อัปโหลดรายการ",
  i18nKey_web_1778: "ดูวิดีโอที่เกี่ยวข้อง",
  i18nKey_web_1779: "อัปโหลดได้แค่ 10",
  i18nKey_web_1780: "รูปภาพภายใน",
  i18nKey_web_1781: "รูปแบบวิดีโอ",
  i18nKey_web_1782: "การคัดเลือกบุคลากร",
  i18nKey_web_1783: "แผนที่การแสดงภาพของบุคลากร",
  i18nKey_web_1784: "ไม่มีบุคลากร",
  i18nKey_web_1785: "ส่ง",
  i18nKey_web_1786: "ต้องระบุเนื้อหาที่ส่ง SMS",
  i18nKey_web_1787: "ส่ง SMS เรียบร้อยแล้ว",
  i18nKey_web_1788: "ผู้ที่ส่ง SMS ไม่สำเร็จ",
  i18nKey_web_1789: "กรุณาทราบว่าบุคลากรข้างต้นไม่สามารถรับ SMS ได้",
  i18nKey_web_1790:
    "คำเตือนของ Vanke Smart Park Operation Center, Vanke Smart Park Operation Center เตือนคุณว่า:",
  i18nKey_web_1791: "ขอบคุณ!",
  i18nKey_web_1792: "ต้องระบุผู้รับ SMS",
  i18nKey_web_1793: "การส่งคืนอุณหภูมิส่วนตัวของบุคลากร",
  i18nKey_web_1794: "สมุดที่อยู่",
  i18nKey_web_1795: "ค้นหาผู้ติดต่อ",
  i18nKey_web_1796: "กำลังโทร",
  i18nKey_web_1797: "การสนทนาทางวิดีโอ",
  i18nKey_web_1798: "อาคารจอดรถ",
  i18nKey_web_1799: "การจัดการไฟถนน",
  i18nKey_web_1800: "ชื่อโคมไฟถนน",
  i18nKey_web_1801: "สถานะไฟถนน",
  i18nKey_web_1802: "เปิดประตู",
  i18nKey_web_1803: "ปิดประตู",
  i18nKey_web_1804: "ข้อแม้",
  i18nKey_web_1805: "บุคลากรเป้าหมายที่น่าสงสัยปรากฏตัวใน",
  i18nKey_web_1806: "ส่งคนไปดู",
  i18nKey_web_1807: "ตำแหน่งอุปกรณ์",
  i18nKey_web_1808: "รหัสมิเตอร์",
  i18nKey_web_1809: "ตำแหน่งมิเตอร์",
  i18nKey_web_1810: "จำนวนการแจ้งเตือน",
  i18nKey_web_1811: "อัตราการสูญเสียไฟฟ้า",
  i18nKey_web_1812: "ชื่อหมวดหมู่",
  i18nKey_web_1813: "กรุณากรอกชื่อหมวดหมู่",
  i18nKey_web_1814: "จำเป็นต้องระบุชื่อหมวดหมู่",
  i18nKey_web_1815: "ห้าม",
  i18nKey_web_1816: "กรุณากรอกหมายเลขโทรศัพท์หรือชื่อของคุณเพื่อค้นหา",
  i18nKey_web_1817: "กรุณาเลือกหมายเลขกุญแจ!",
  i18nKey_web_1818: "ปิด",
  i18nKey_web_1819: "การนำเข้าไฟล์",
  i18nKey_web_1820: "อันดับแรก การนำเข้าล้มเหลว",
  i18nKey_web_1821:
    "มีข้อผิดพลาดในข้อมูลแถว กรุณาตรวจสอบและส่งอีกครั้งเพื่อนำเข้าในภายหลัง",
  i18nKey_web_1822: "กรุณากรอกจำนวนบวก",
  i18nKey_web_1823: "เก็บทศนิยมไม่เกินสองตำแหน่ง",
  i18nKey_web_1824: "กรุณาเลือกหมวดหมู่เครื่องมือ",
  i18nKey_web_1825: "ไม่มีห้องในตำแหน่งปัจจุบัน",
  i18nKey_web_1826: "สถิติข้อมูลไฟฟ้า",
  i18nKey_web_1827: "กิโลวัตต์",
  i18nKey_web_1828: "สถิติการใช้น้ำ",
  i18nKey_web_1829: "ลูกบาศก์เมตร",
  i18nKey_web_1830: "รูปแบบหมายเลขโทรศัพท์ไม่ถูกต้อง",
  i18nKey_web_1831: "การกำหนดค่าใบรับรอง",
  i18nKey_web_1832: "ป้ายทะเบียนรถ:",
  i18nKey_web_1833: "เกิน",
  i18nKey_web_1834: "เวลา, ไม่สามารถสมัครบัตรรายเดือนได้",
  i18nKey_web_1835: "ครั้ง, ไม่สามารถเข้าที่จอดรถได้",
  i18nKey_web_1836: "จำเป็นต้องระบุหมายเลขป้ายทะเบียน",
  i18nKey_web_1837: "จำเป็นต้องระบุหมายเลขโทรศัพท์",
  i18nKey_web_1838: "จำเป็นต้องระบุหมายเหตุ",
  i18nKey_web_1839: "เปิดประตูพร้อมกัน",
  i18nKey_web_1840: "คำแนะนำ: ความล่าช้าของภาพวิดีโออยู่ที่ประมาณ 5-10",
  i18nKey_web_1841: "กรุณารอสักครู่",
  i18nKey_web_1842: "เวลาทำการ:",
  i18nKey_web_1843: "บันได",
  i18nKey_web_1844: "ความเร็วมอเตอร์:",
  i18nKey_web_1845: "อุณหภูมิแกน:",
  i18nKey_web_1846: "ระยะทางของเชือกลาก:",
  i18nKey_web_1847: "การควบคุมระดับเสียง",
  i18nKey_web_1848: "วงจรไฟทั้งหมด:",
  i18nKey_web_1849: "A,",
  i18nKey_web_1850: "สวิตซ์",
  i18nKey_web_1851: "ไม่",
  i18nKey_web_1852: "แน่นอนสำหรับทุกคน",
  i18nKey_web_1853: "วนซ้ำหรือไม่",
  i18nKey_web_1854: "แน่ใจว่าจะ",
  i18nKey_web_1855: "นี้",
  i18nKey_web_1856: "วนซ้ำ",
  i18nKey_web_1857: "การเปลี่ยนแปลงล้มเหลว กรุณาลองอีกครั้งในภายหลัง",
  i18nKey_web_1858: "เปิดสำเร็จแล้ว",
  i18nKey_web_1859: "ปิดเรียบร้อยแล้ว",
  i18nKey_web_1860: "การเปลี่ยนแปลงล้มเหลว",
  i18nKey_web_1861: "ยืนยันที่จะยกเลิกหรือไม่?",
  i18nKey_web_1862: "สองต่อหนึ่งรอบ",
  i18nKey_web_1863: "ชื่อเกตเวย์ 2",
  i18nKey_web_1864: "การตรวจจับความล่าช้าของการส่องแสงสลัว",
  i18nKey_web_1865: "กรุณาเลือกเวลาเปิดทำการ",
  i18nKey_web_1866: "กรุณากรอกข้อมูล",
  i18nKey_web_1867: "กรุณากรอกจุด",
  i18nKey_web_1868: "กรุณากรอกโทรศัพท์เพื่อค้นหารายการที่ตรงกัน",
  i18nKey_web_1869: "กรุณาเลือกการแจ้งเตือน",
  i18nKey_web_1870: "เพิ่มแล้ว",
  i18nKey_web_1871: "ปริมาณการใช้ไฟฟ้าจริงในปัจจุบัน",
  i18nKey_web_1872: "ปริมาณการใช้ไฟฟ้าจริงเมื่อสัปดาห์ที่แล้ว",
  i18nKey_web_1873: "ที่ประสบความสำเร็จ",
  i18nKey_web_1874: "ชื่ออุปกรณ์, ธุรกิจ",
  i18nKey_web_1875: "ไฟล์นำเข้า",
  i18nKey_web_1876: "จำเป็นต้องระบุข้อมูลการอ่านมิเตอร์",
  i18nKey_web_1877: "หมายเลขมิเตอร์",
  i18nKey_web_1878: "เวลาอ่านมิเตอร์ล่าสุด",
  i18nKey_web_1879: "สถิติข้อมูลไฟฟ้า",
  i18nKey_web_1880: "สถิติการใช้น้ำ",
  i18nKey_web_1881: "รายละเอียดการอ่านมิเตอร์อัตโนมัติ",
  i18nKey_web_1882: "ไม่มีข้อมูล",
  i18nKey_web_1883: "ข้อมูลผิดปกติ",
  i18nKey_web_1884: "แต่งหน้า",
  i18nKey_web_1885: "พลังงานที่ใช้",
  i18nKey_web_1886: "เฟสแรงดันไฟฟ้า",
  i18nKey_web_1887: "เฟสปัจจุบัน",
  i18nKey_web_1888: "เลือกรายการย่อย",
  i18nKey_web_1889: "กรุณาเลือกวันที่อ่านมิเตอร์",
  i18nKey_web_1890: "กรุณากรอกการอ่านมิเตอร์",
  i18nKey_web_1891: "อุปกรณ์:",
  i18nKey_web_1892: "การอัพเดทล้มเหลว",
  i18nKey_web_1893: "เลือกอุปกรณ์",
  i18nKey_web_1894: "ดง",
  i18nKey_web_1895: "ห้อง",
  i18nKey_web_1896: "เทมเพลตฟอร์ม",
  i18nKey_web_1897: "กรุณากรอกข้อมูลที่จำเป็น",
  i18nKey_web_1898: "กรุณากรอกหมายเลขโทรศัพท์มือถือตามกฎหมาย",
  i18nKey_web_1899: "บริเวณที่จอดรถ",
  i18nKey_web_1900: "แก้",
  i18nKey_web_1901: "เพิ่มอุปกรณ์ในพื้นที่สาธารณะ",
  i18nKey_web_1902: "อัปเดตข้อมูลอุปกรณ์เรียบร้อยแล้ว",
  i18nKey_web_1903: "ยืนยันการลบอุปกรณ์",
  i18nKey_web_1904: "เพิ่มอุปกรณ์สำเร็จ",
  i18nKey_web_1905: "2 ห้องและ 1 ห้องนั่งเล่น",
  i18nKey_web_1906: "ตั้งค่าใหม่สำเร็จ",
  i18nKey_web_1907:
    "ไม่สามารถกู้คืนได้หลังจากลบแล้ว ต้องการดำเนินการต่อหรือไม่",
  i18nKey_web_1908: "ภาพรวมพนักงาน",
  i18nKey_web_1909: "แนวโน้มการเปรียบเทียบบุคลากร",
  i18nKey_web_1910: "เกือบสองสัปดาห์",
  i18nKey_web_1911: "12 สัปดาห์ที่ผ่านมา",
  i18nKey_web_1912: "12 เดือนที่ผ่านมา",
  i18nKey_web_1913: "ภาพวิดีโอล่าช้าประมาณ 5",
  i18nKey_web_1914: "กรุณารอ",
  i18nKey_web_1915: "กำลังโหลดกล้อง",
  i18nKey_web_1916: "รับทรัพยากรกล้องไม่สำเร็จ",
  i18nKey_web_1917: "ข้อมูลพื้นฐาน",
  i18nKey_web_1918: "ค่าเริ่มต้น 00 วันถัดไป",
  i18nKey_web_1919: "รีเฟรช",
  i18nKey_web_1920: "หลังจากเลือกฟิลด์แล้ว ฟิลด์จะแสดงในแอปเพล็ต",
  i18nKey_web_1921: "ปรากฏแก่ผู้ใช้",
  i18nKey_web_1922: "วิธีการแจ้ง",
  i18nKey_web_1923: "Yuncheng",
  i18nKey_web_1924: "ข่าว",
  i18nKey_web_1925: "ข้อมูลผู้ให้สัมภาษณ์",
  i18nKey_web_1926: "ชื่อผู้ให้สัมภาษณ์",
  i18nKey_web_1927: "ที่อยู่ของผู้ให้สัมภาษณ์",
  i18nKey_web_1928: "ข้อมูลการเข้าถึง",
  i18nKey_web_1929: "เหตุผลการเยี่ยมชม",
  i18nKey_web_1930: "การจัดเก็บข้อมูลที่แสดงร่วมกัน",
  i18nKey_web_1931: "หลังจากเปิดแล้ว ผู้ใช้สามารถป้อนจำนวนผู้สัญจรได้",
  i18nKey_web_1932: "ผู้เยี่ยมชมดูบันทึกการลงทะเบียน",
  i18nKey_web_1933: "การยกเว้นจากการอนุมัติ",
  i18nKey_web_1934:
    "หลังจากเปิดแล้ว ผู้เยี่ยมชมจะได้รับสิทธิ์ในการเข้าถึงโดยตรงโดยไม่ต้องผ่านกระบวนการอนุมัติ",
  i18nKey_web_1935: "การนำทาง",
  i18nKey_web_1936:
    "หลังจากเปิดแล้ว ผู้เข้าชมสามารถดูข้อมูลการนำทางของ park ได้",
  i18nKey_web_1937: "ไม่มีผู้เยี่ยมชม",
  i18nKey_web_1938: "จำนวนผู้เยี่ยมชม",
  i18nKey_web_1939: "จำนวนบริษัทที่สัมภาษณ์ทั้งหมด",
  i18nKey_web_1940: "กรุณาเข้าสู่เซิร์ฟเวอร์",
  i18nKey_web_1941: "หรือชื่อโดเมน",
  i18nKey_web_1942: "เพิ่มเรียบร้อยแล้ว!",
  i18nKey_web_1943: "เบราว์เซอร์ของคุณไม่รองรับ",
  i18nKey_web_1944: "จำนวนเงินที่ระงับชั่วคราว",
  i18nKey_web_1945: "จำนวนเงินที่ชำระแบบแพ็คเกจ",
  i18nKey_web_1946: "กรุณากรอกยี่ห้อรถ",
  i18nKey_web_1947: "ออดี้",
  i18nKey_web_1948: "เบนซ์",
  i18nKey_web_1949: "บีเอ็มดับเบิลยู",
  i18nKey_web_1950: "ฮอนด้า",
  i18nKey_web_1951: "โตโยต้า",
  i18nKey_web_1952: "สาธารณะ",
  i18nKey_web_1953: "ฟอร์ด",
  i18nKey_web_1954: "บิวอิค",
  i18nKey_web_1955: "การชำระเงินในเดือนที่ผ่านมา",
  i18nKey_web_1956: "การชำระเงินสะสม",
  i18nKey_web_1957: "ตัวเลือกมากกว่า 6 ป้ายทะเบียน",
  i18nKey_web_1958: "การแสดงผลที่ดีที่สุดสำหรับคุณ",
  i18nKey_web_1959: "ตัวเลือกมากกว่า 6 แบรนด์",
  i18nKey_web_1960: "ตัวเลือกมากกว่า 6 สี",
  i18nKey_web_1961: "ตัวเลือกมากกว่า 6 สำหรับป้ายทะเบียนและยี่ห้อ",
  i18nKey_web_1962: "ตัวเลือกมากกว่า 6 สำหรับป้ายทะเบียนและสี",
  i18nKey_web_1963: "ตัวเลือกมากกว่า 6 สำหรับแบรนด์และสี",
  i18nKey_web_1964: "ตัวเลือกมากกว่า 6 สำหรับป้ายทะเบียนยี่ห้อและสี",
  i18nKey_web_1965: "จำนวนแขกที่กลับมา",
  i18nKey_web_1966: "คน",
  i18nKey_web_1967: "จำนวนของ repeat customer",
  i18nKey_web_1968: "คน",
  i18nKey_web_1969: "สัมภาษณ์โดยตรง",
  i18nKey_web_1970: "จุดเริ่ม",
  i18nKey_web_1971: "เกือบหนึ่งสัปดาห์",
  i18nKey_web_1972: "เริ่ม",
  i18nKey_web_1973: "ช่วงวันที่ที่เลือกมากกว่า",
  i18nKey_web_1974: "วัน, กรุณาลดตัวเลือกและลองอีกครั้ง",
  i18nKey_web_1975: "การเลือกเวลา",
  i18nKey_web_1976: "กรุณาเลือกเวลากรอง",
  i18nKey_web_1977: "รหัสผ่านไม่ถูกต้อง กรุณากรอกใหม่",
  i18nKey_web_1978: "เกิดข้อผิดพลาดในการ Interface รายละเอียดบุคลากร",
  i18nKey_web_1979: "Passenger flow",
  i18nKey_web_1980: "ปี",
  i18nKey_web_1981: "วันที่เริ่มต้น",
  i18nKey_web_1982: "วัน",
  i18nKey_web_1983: "รายละเอียดปฏิทิน",
  i18nKey_web_1984: "สำเนา",
  i18nKey_web_1985: "อุปกรณ์ที่ไม่ได้มอบหมาย",
  i18nKey_web_1986: "คัดลอกเรียบร้อยแล้ว",
  i18nKey_web_1987: "กรุณากรอกชื่อผู้เช่า",
  i18nKey_web_1988: "กรุณาเลือกสถานะร้านค้า",
  i18nKey_web_1989: "รวม",
  i18nKey_web_1990: "วันปีใหม่",
  i18nKey_web_1991: "เมื่อเทียบกับปีที่แล้ว",
  i18nKey_web_1992: "ไม่มีอุปกรณ์",
  i18nKey_web_1993: "ลบห้อง",
  i18nKey_web_1994: "ตกลงเพื่อลบห้อง",
  i18nKey_web_1995: "สวิตช์สถานการณ์",
  i18nKey_web_1996: "แน่ใจหรือไม่ว่าจะลบสถานการณ์เหล่านี้ ?",
  i18nKey_web_1997: "คลิก ตกลง เพื่อลบฉาก",
  i18nKey_web_1998: "กรุณาเลือกชื่อพื้นที่",
  i18nKey_web_1999: "เลือกบริษัท",
  i18nKey_web_2000: "กรุณาเลือกบริษัทที่จะเพิ่ม",
  i18nKey_web_2001: "กรุณาเลือกบริษัทที่เหมาะสม",
  i18nKey_web_2002: "กรุณาเลือกเหตุการณ์",
  i18nKey_web_2003: "กรุณาเลือกวันที่เริ่มต้น",
  i18nKey_web_2004: "จำเป็นต้องระบุเหตุการณ์",
  i18nKey_web_2005: "กรุณาเลือกวันหยุด",
  i18nKey_web_2006: "ระดับ 1 หัวข้อ",
  i18nKey_web_2007: "หัวข้อรอง 1",
  i18nKey_web_2008: "ระดับ 3 หัวข้อ 1",
  i18nKey_web_2009: "หัวข้อรอง 2",
  i18nKey_web_2010: "ระดับ 3 หัวข้อ 2",
  i18nKey_web_2011: "สร้าง",

  i18nKey_web_2012: "อัปเดตสิทธิ์การเข้าถึง",
  i18nKey_web_2013:
    "หลังจากเปลี่ยนที่อยู่บริษัทแล้ว โปรดอัปเดตการอนุญาตการเข้าถึง มิฉะนั้นจะส่งผลต่อการเข้าถึงของพนักงาน",
  i18nKey_web_2014: "อัปเดตสิทธิ์การเข้าถึง?",
  i18nKey_web_2015: "อัปเดตสิทธิ์การเข้าถึงสำเร็จ!",
  i18nKey_web_2016: "นัดคน",
  i18nKey_web_2017: "มีความรับผิดชอบ",
  i18nKey_web_2018: "ใบสั่งงานทางธุรกิจ",
  i18nKey_web_2019: "รายงานใบสั่งงาน",
  i18nKey_web_2020: "ปีที่แล้ว",
  i18nKey_web_2021: "เหตุการณ์",
  i18nKey_web_2022: "จำนวนการสั่งซื้อ",
  i18nKey_web_2023: "สัดส่วนของเหตุการณ์",
  i18nKey_web_2024: "สัดส่วนของการสั่งซื้อ",
  i18nKey_web_2025: "ทั้งหมด",
  i18nKey_web_2026: "หมายเหตุ",
  i18nKey_web_2027: "รายละเอียดการสั่งซื้อ",
  i18nKey_web_2028: "สอบถาม",
  i18nKey_web_2029: "รีเซ็ต",
  i18nKey_web_2030: "ไม่มีข้อมูลที่ตรงตามเงื่อนไขการสืบค้น",
  i18nKey_web_2031: "เวลารายงาน",
  i18nKey_web_2032: "เคล็ดลับ",
  i18nKey_web_2033: "การใช้พลังงานต่อตร.ม",
  i18nKey_web_2034: "การใช้พลังงาน",
  i18nKey_web_2035: "หนาว",
  i18nKey_web_2036: "ปริมาณการใช้ไฟฟ้าปัจจุบัน",
  i18nKey_web_2037: "ปริมาณการใช้น้ำปัจจุบัน",
  i18nKey_web_2038: "ปริมาณการใช้ความเย็นปัจจุบัน",
  i18nKey_web_2039: "ปริมาณการใช้ไฟฟ้าเมื่อเทียบกับครั้งที่แล้ว",
  i18nKey_web_2040: "ปริมาณการใช้น้ำเมื่อเทียบกับครั้งที่แล้ว",
  i18nKey_web_2041: "ปริมาณการใช้ความเย็นเมื่อเทียบกับครั้งที่แล้ว",
  i18nKey_web_2042: "ปริมาณการใช้ไฟฟ้าช่วงเดียวกันของปีที่แล้ว",
  i18nKey_web_2043: "ปริมาณการใช้น้ำช่วงเดียวกันของปีที่แล้ว",
  i18nKey_web_2044: "ปริมาณการใช้ความเย็นช่วงเดียวกันของปีที่แล้ว",
  i18nKey_web_2045: "สรุป",
  i18nKey_web_2046: "อันดับไฟฟ้า",
  i18nKey_web_2047: "อันดับน้ำ",
  i18nKey_web_2048: "อันดับความเย็น",
  i18nKey_web_2049: "ไม่มีข้อมูลที่จะส่งออก",
  i18nKey_web_2050: "สัปดาห์",
  i18nKey_web_2051: "ไฟฟ้า",
  i18nKey_web_2052: "การใช้น้ำ",
  i18nKey_web_2053: "ปริมาณการใช้ความเย็น",
  i18nKey_web_2054: "วัน",
  i18nKey_web_2055: "เดือน",
  i18nKey_web_2056: "แผนภูมิอีชาร์ต",
  i18nKey_web_2057: "การวิเคราะห์แนวโน้มความผิดพลาด",
  i18nKey_web_2058: "ส่งออกข้อมูลสำเร็จ",
  i18nKey_web_2059: "ส่งออกข้อมูลไม่สำเร็จ",
  i18nKey_web_2060: "หมื่น",
  i18nKey_web_2061: "เหตุการณ์ (s)",
  i18nKey_web_2062: "ส่งออก",
  i18nKey_web_2063: "นำเข้า",
  i18nKey_web_2064: "คำที่สำคัญ",
  i18nKey_web_2065: "กรุณากรอก",
  i18nKey_web_2066: "หมายเลขมิเตอร์ ชื่ออุปกรณ์ ชื่อร้านค้า/ผู้เช่า",
  i18nKey_web_2067: "ตำแหน่ง",
  i18nKey_web_2068: "เลือก",
  i18nKey_web_2069: "กรุณาเลือกสถานที่",
  i18nKey_web_2070: "รายการย่อย",
  i18nKey_web_2071: "ระยะเวลาอ่านมิเตอร์",
  i18nKey_web_2072: "กรุณาเลือกเวลา",
  i18nKey_web_2073: "การเดือนคงค้าง",
  i18nKey_web_2074: "ไม่ได้กรอกข้อมูล",
  i18nKey_web_2075: "ยืนยัน",
  i18nKey_web_2076: "ตกลง",
  i18nKey_web_2077: "ผิดปกติ",
  i18nKey_web_2078: "ปกติ",
  i18nKey_web_2079: "ผิดปกติ",
  i18nKey_web_2080: "S/N",
  i18nKey_web_2081: "ยืนยัน",
  i18nKey_web_2082: "การอ่านมิเตอร์ปัจจุบัน",
  i18nKey_web_2083: "ระยะเวลาอ่านมิเตอร์",
  i18nKey_web_2084: "ความถี่ในการรวบรวม",
  i18nKey_web_2085: "วัน",
  i18nKey_web_2086: "ชื่อร้านค้า/ผู้เช่า",
  i18nKey_web_2087: "ผู้ขายเลขที่",
  i18nKey_web_2088: "ชื่ออุปกรณ์",
  i18nKey_web_2089: "รายละเอียด",
  i18nKey_web_2090: "กลับ",
  i18nKey_web_2091: "ดำเนินงาน",
  i18nKey_web_2092: "รายละเอียดการอ่านมิเตอร์แบบแมนนวล",
  i18nKey_web_2093: "ไม่สามารถเป็นโมฆะระหว่างการอ่านมิเตอร์ได้",
  i18nKey_web_2094: "ไม่ใช่หน้า ที่ของเดือน",
  i18nKey_web_2095: "ระยะเวลาการอ่านมิเตอร์ไม่สามารถเป็นค่าว่างได้",
  i18nKey_web_2096: "นำเข้าการอ่านมิเตอร์",
  i18nKey_web_2097: "เมตรไม่อ่าน(s)",
  i18nKey_web_2098: "เมตรไม่อ่าน/อ่านในเดือนนี้(s)",
  i18nKey_web_2099: "ข้อยกเว้นข้อมูล(s)",
  i18nKey_web_3000: "ส่งเรียบร้อย",
  i18nKey_web_3001: "กำลังส่งออก โปรดรอสักครู่...",
  i18nKey_web_3002: "รีเฟรช",
  i18nKey_web_3003: "ปริมาณความเย็น",
  i18nKey_web_3004: "กรุณาใส่เลขมิเตอร์ไฟ ชื่ออุปกรณ์ ร้านค้า/ผู้เช่า",
  i18nKey_web_3005: "ปี",
  i18nKey_web_3006: "กำหนดตัวเอง",
  i18nKey_web_3007: "กรุณาใส่เลขมิเตอร์น้ำ ชื่อร้านค้า/ผู้เช่า",
  i18nKey_web_3008: "กรุณาใส่เลขมิเตอร์วัดปริมาณความเย็น,ชื่อร้านค้า/ผู้เช่า",
  i18nKey_web_3009: "การอ่านมิเตอร์ (kWh)",
  i18nKey_web_3010: "มาตรวัดน้ำเลขที่",
  i18nKey_web_3011: "หมายเลขมิเตอร์น้ำ",
  i18nKey_web_3012: "ประเมินค่า",
  i18nKey_web_3013: "หมายเลขเครื่องวัดปริมาณความเย็น",
  i18nKey_web_3014: "หมายเลขเครื่องวัดปริมาณความเย็น No",
  i18nKey_web_3015: "ปริมาณความเย็น",
  i18nKey_web_3016: "จำนวนข้อยกเว้นทั้งหมด(s)",
  i18nKey_web_3017: "ข้อมูลที่สูญหาย(s)",
  i18nKey_web_3018: "ข้อยกเว้นข้อมูล(s)",
  i18nKey_web_3019: "มิเตอร์หลัก(s)",
  i18nKey_web_3020: "ออนไลน์(s)",
  i18nKey_web_3021: "ออฟไลน์(s)",
  i18nKey_web_3022: "สแตนด์บาย(s)",
  i18nKey_web_3023: "การอ่านมิเตอร์แบบอัตโนมัติแบบรายละเอียด",
  i18nKey_web_3024: "สถานการณ์ทั่วไป",
  i18nKey_web_3025: "การแจ้งเตือนงาน ",
  i18nKey_web_3026: "กล้องที่เกี่ยวข้อง",
  i18nKey_web_3027: "การวิเคราะห์ความผิดพลาดของลิฟต์",
  i18nKey_web_3028: "ตำแหน่งลิฟต์",
  i18nKey_web_3029: "สถานะลิฟต์",
  i18nKey_web_3030: "ไฟไหม้ฉุกเฉินกลับ",
  i18nKey_web_3031: "ป้องกันไฟ",
  i18nKey_web_3032: "งดให้บริการ",
  i18nKey_web_3033: "อยู่ระหว่างการซ่อมแซม",
  i18nKey_web_3034: "ล็อคลิฟต์",
  i18nKey_web_3035: "กำลังขึ้น",
  i18nKey_web_3036: "กำลังลง",
  i18nKey_web_3037: "อยู่ระหว่างดำเนินการ",
  i18nKey_web_3038: "ชื่อลิฟต์",
  i18nKey_web_3039: "คำอธิบายเหตุการณ์",
  i18nKey_web_3040: "สถานะเหตุการณ์",
  i18nKey_web_3041: "สถานที่เกิดเหตุ",
  i18nKey_web_3042: "เวลาที่เกิดเหตุ",
  i18nKey_web_3043: "Subsystem",
  i18nKey_web_3044: "รายละเอียดกิจกรรม",
  i18nKey_web_3045: "พรอมต์: วิดีโอล่าช้าประมาณ 5-10 วินาที โปรดรอสักครู่",
  i18nKey_web_3046: "กำลังโหลดกล้อง โปรดรอสักครู่...",
  i18nKey_web_3047: "เวลาปัจจุบัน::",
  i18nKey_web_3048: "ไม่",
  i18nKey_web_3049: "รูปภาพวิดีโอจะแสดงใน 5-10 วินาที โปรดรอสักครู่",
  i18nKey_web_3050: "ป้อนนามแฝงกล้อง",
  i18nKey_web_3051: "ดูภาพ",
  i18nKey_web_3052: "ไม่สามารถเชื่อมต่อกล้องเกิน 3 ตัว!",
  i18nKey_web_3053: "กล้อง",
  i18nKey_web_3054: "รายการแจ้งเตือน",
  i18nKey_web_3055: "รายการสั่งซื้อ",
  i18nKey_web_3056: "เรียบร้อย",
  i18nKey_web_3057: "ยกเลิก",
  i18nKey_web_3058: "สถานะใบงาน",
  i18nKey_web_3059: "เชื่อมต่อกล้องได้สูงสุด 3 ตัว",
  i18nKey_web_3060: "เวลาที่แก้ไข",
  i18nKey_web_3061: "แก้ไข",
  i18nKey_web_3062: "ลิฟต์",
  i18nKey_web_3063: "ลิฟต์ขนของ",
  i18nKey_web_3064: "ลิฟต์โดยสาร",
  i18nKey_web_3065: "อื่นๆ",
  i18nKey_web_3066: "กรุณากรอกชื่อลิฟต์",
  i18nKey_web_3067: "สถานที่ทั้งหมด",
  i18nKey_web_3068: "กรุณาเลือกสถานะลิฟต์",
  i18nKey_web_3069: "ชั้นปัจจุบัน",
  i18nKey_web_3070: "พบข้อผิดพลาด",
  i18nKey_web_3071: "อัพเดทเวลา",
  i18nKey_web_3072: "สัญญาณเตือนฉุกเฉิน",
  i18nKey_web_3073: "สัญญาณเตือนที่สำคัญ",
  i18nKey_web_3074: "การแจ้งเตือนปกติ",
  i18nKey_web_3075: "ทันเหตุการณ์",
  i18nKey_web_3076: "กรุณาเลือกระดับการเตือน",
  i18nKey_web_3077: "รอดำเนินการ",
  i18nKey_web_3078: "กรุณาเลือกสถานะเหตุการณ์",
  i18nKey_web_3079: "กรุณาเลือกสถานะการสั่ง",
  i18nKey_web_3080: "เลขที่ใบสั่งซื้อ",
  i18nKey_web_3081: "กรุณาใส่เลขที่ใบงาน",
  i18nKey_web_3082: "กรุณาใส่ผู้รับผิดชอบ",
  i18nKey_web_3083: "กระจายเวลา",
  i18nKey_web_3084: "กรุณาใส่เวลาแจกจ่าย",
  i18nKey_web_3085: "ผู้มอบหมายงาน",
  i18nKey_web_3086: "สถานะ",
  i18nKey_web_3087: "ระงับ",
  i18nKey_web_3088: "Canceled",
  i18nKey_web_3089: "เหตุการณ์ที่/ลำดับการแจ้งเตือน",
  i18nKey_web_3090: "ประเภทเหตุการณ์/ประเภทรายงาน",
  i18nKey_web_3091: "เวลาการแจ้งเตือน/เวลารายงาน",
  i18nKey_web_3092: "รายละเอียดระบบที่เกี่ยวข้อง",
  i18nKey_web_3093: "หมายเลขคำสั่งซื้อที่เกี่ยวข้อง",
  i18nKey_web_3094: "คำอธิบายที่สมบูรณ์",
  i18nKey_web_3095: "รายละเอียดรูปภาพ",
  i18nKey_web_3096: "สถานการณ์ความปลอดภัย",
  i18nKey_web_3097: "เหตุการณ์สำคัญ",
  i18nKey_web_3098: "การจำแนกอุปกรณ์",
  i18nKey_web_3099: "โปรดเลือกการจัดประเภทอุปกรณ์",
  i18nKey_web_3100: "การจัดประเภทสิ่งอำนวยความสะดวก",
  i18nKey_web_3101: "A",
  i18nKey_web_3102: "ออนไลน์",
  i18nKey_web_3103: "ออฟไลน์",
  i18nKey_web_3104: "ระดับสิ่งอำนวยความสะดวก",
  i18nKey_web_3105: "ระบบสิ่งอำนวยความสะดวก",
  i18nKey_web_3106: "คำอธิบายข้อบกพร่อง",
  i18nKey_web_3107: "เวลา",
  i18nKey_web_3108: "นาที",
  i18nKey_web_3109: "วินาที",
  i18nKey_web_3110: "ดู",
  i18nKey_web_3111: "ใบสั่งงานสถานที่",
  i18nKey_web_3112: "การเลือก",
  i18nKey_web_3113: "ประเภทของงาน",
  i18nKey_web_3114: "หมายเลขงาน",
  i18nKey_web_3115: "โทรศัพท์",
  i18nKey_web_3116: "ไม่สามารถส่งออกข้อมูลเปล่าได้",
  i18nKey_web_3117:
    "คุณไม่สามารถส่งออกข้อมูลได้มากกว่า 200 ข้อกรุณาพิมพ์เงื่อนไขของคุณก่อน",
  i18nKey_web_3118: "ทรัพย์สิน",
  i18nKey_web_3119: "รายการสร้างใหม่สำเร็จ",
  i18nKey_web_3120: "รวม",
  i18nKey_web_3121: "เหตุการณ์ ที่เกิดขึ้น",
  i18nKey_web_3122: "ปรับเทียบข้อมูล",
  i18nKey_web_3123: "โปรดเลือก",
  i18nKey_web_3124: "ตึกพวกนั้น",
  i18nKey_web_3125: "ฤดูกาล",
  i18nKey_web_3126: "7 วัน",
  i18nKey_web_3127: "30 วัน",
  i18nKey_web_3128: "ทั้งหมด",
  i18nKey_web_3129: "มิเตอร์ไฟฟ้ารายละเอียด",
  i18nKey_web_3130: "รายงาน",
  i18nKey_web_3131: "กราฟ",
  i18nKey_web_3132: "อ่านค่า",
  i18nKey_web_3133: "พลังงานที่ใช้（kWh ）",
  i18nKey_web_3134: "ปริมาณความเย็น（kWh ）",
  i18nKey_web_3135: "ด้วยความเย็นสถิติ （kW ）",
  i18nKey_web_3136: "รายละเอียดตาราง",
  i18nKey_web_3137: "หมายเลขซีเรียลของเครื่องมือ",
  i18nKey_web_3138: "สถิติการใช้น้ำ （m³ ）",
  i18nKey_web_3139: "ขนาดความเย็น",
  i18nKey_web_3140: "เหตุการณ์ของธุรกิจ",
  i18nKey_web_3141: "เหตุการณ์ของสิ่งอำนวยความสะดวก",
  i18nKey_web_3142: "รายงานเหตุการณ",
  i18nKey_web_3143: "วิธีการกด",
  i18nKey_web_3144: "เลือกวันที่และเวลา",
  i18nKey_web_3145: "ประเภทเหตุการณ์",
  i18nKey_web_3146: "กรุณากรอกเนื้อหา",
  i18nKey_web_3147: "กรุณาเลือกประเภทเหตุการณ์",
  i18nKey_web_3148: "โปรดเลือกเวลาเริ่มต้น",
  i18nKey_web_3149: "โปรดเลือกเวลาสิ้นสุด",
  i18nKey_web_3150: "ส่งใบสั่งงานเรียบร้อยแล้ว",
  i18nKey_web_3151: "MQDCappงาน",
  i18nKey_web_3152: "ผลักดันพนักงาน",
  i18nKey_web_3153: "กรุณาใส่ชื่อ และเบอร์มือถือ",
  i18nKey_web_3154: "ตัวจำลอง",
  i18nKey_web_3155: "การทำความสะอาด",
  i18nKey_web_3156: "ทรัพย์สิน",
  i18nKey_web_3157: "ความปลอดภัย",
  i18nKey_web_3158: "ข้อความแจ้ง",
  i18nKey_web_3159: "ส่งออก",
  i18nKey_web_3160: "เวลาลงทะเบียน",
  i18nKey_web_3161: "ยืนยันที่จะลบหรือไม่?",
  i18nKey_web_3162: "โปรดป้อนชื่อของเครื่องวัดความเย็น",
  i18nKey_web_3163: "พารามิเตอร์การทำงานของอุปกรณ์",
  i18nKey_web_3164: "ประวัติพารามิเตอร์ของอุปกรณ์",
  i18nKey_web_3165: "การไหล",
  security_4_gis: "กำหนด",
  security_12_gis: "ยกเลิก",
  i18nKey_web_3166: "โปรดป้อนภูมิภาคหรือชื่ออุปกรณ์",
  i18nKey_web_3167: "การเข้า (ครั้ง)",
  i18nKey_web_3168: "การออก (ครั้ง)",
  i18nKey_web_3169: "ทางเข้า",
  i18nKey_web_3170: "กลาง",
  i18nKey_web_3171: "ทางออก",
  i18nKey_web_3172: "บริเวณทางแยก",
  i18nKey_web_3173: "เข้าไปดูตึก",
  i18nKey_web_3174: "กรุณาพิมพ์ตึกสำหรับการเยี่ยมชม",
  i18nKey_web_3175: "ห้องน้ำ",
  i18nKey_web_3176: "เลือกห้องน้ำก่อนนะคะ",
  i18nKey_web_3177: "ประเภทรายงาน",
  i18nKey_web_3178: "โปรดเลือกประเภทของรายงาน",
  i18nKey_web_3179: "ชื่อห้องน้ำ",
  i18nKey_web_3180: "รายงาน",
  i18nKey_web_3181: "ศูนย์รายงาน",
  i18nKey_web_3182: "แจ้งตำรวจ",
  i18nKey_web_3183: "ส่งออกมาก ที่สุด 10,000 บันทึกใน แต่ละครั้ง",
  i18nKey_web_3184: "สถิติของรถ",
  i18nKey_web_3185: "ลิฟท์มาถึงแล้ว",
  i18nKey_web_3186: "ลิฟต์ปิดแล้ว",
  i18nKey_web_3187: "ลิฟต์ปิดแล้ว",
  i18nKey_web_3188: "ลิฟท์",
  security_0_gis:
    "ไม่มีหน้าที่คุณเยี่ยมชม กรุณาตรวจสอบลิงก์ใน Address bar และเยี่ยมชมอีกครั้ง",
  security_1_gis: "ขออภัยคุณไม่ได้รับอนุญาต กรุณาติดต่อผู้ดูแลระบบ",
  security_2_gis: "การบัฟเฟอร์วิดีโอ",
  security_3_gis: "ไม่สามารถรับทรัพยากรวิดีโอได้",
  security_5_gis:
    "หมายเหตุ: เหมาะสำหรับหน้าแสดงตารางที่ไม่มีส่วนประกอบซ้อนทับกันบนหน้าแสดงรายการ",
  security_6_gis: "จำเป็นต้องผ่านในชื่อของคลาส",
  security_7_gis: "เพื่อค้นหาสิ่งที่คุณต้องการ",
  security_8_gis: "องค์ประกอบ",
  security_9_gis: "ต้นแบบ",
  security_10_gis: "จำเป็นต้องตั้งค่าคุณสมบัติค่าเริ่มต้น",
  security_11_gis: "จำเป็นต้องกำหนดป้ายชื่อด้านนอกสุด",
  security_13_gis: "ยืนยัน",
  security_14_gis: "ป้อนคำหลักเพื่อกรองผลลัพธ์",
  security_15_gis: "เลือกกล้อง",
  security_16_gis: "กล้องวิดีโอ",
  security_17_gis: "ความร้อนของพนักงาน",
  security_18_gis: "เจ้าหน้าที่รักษาความปลอดภัย",
  security_19_gis: "กล่องนิรภัย",
  security_20_gis: "ข้อยกเว้นข้อมูล",
  security_21_gis: "ไม่มีข้อมูลที่ตรงตามเงื่อนไขการสืบค้น",
  security_22_gis: "แก้ไข",
  security_23_gis: "วันสุดท้าย",
  security_24_gis: "คำเตือนผู้เยี่ยมชมบัญชีดำที่สำคัญ",
  security_25_gis: "คลิกเพื่อดู",
  security_26_gis: "เลือกอุปกรณ์ที่เกี่ยวข้อง",
  security_27_gis: "ค้นหา",
  security_28_gis: "ชื่ออุปกรณ์",
  security_29_gis: "รหัสอุปกรณ์",
  security_30_gis: "บันทึกการออกของยานพาหนะ",
  security_31_gis: "",
  security_32_gis: "แนวโน้มเหตุการณ์ไฟไหม้",
  security_33_gis: "ยอดเรียกเก็บรวมสะสม",
  security_34_gis: "การจำหน่ายรวมสะสม",
  security_35_gis: "รายละเอียดของผู้ตรวจสอบ",
  security_36_gis: "ชื่อ",
  security_37_gis: "หมายเลขงาน",
  security_38_gis: "โทรศัพท์",
  security_39_gis: "ตำแหน่ง",
  security_40_gis: "สถานการณ์ในงาน",
  security_41_gis: "จำนวนใบสั่งงานทั้งหมด",
  security_42_gis: "กำลังประมวลผล",
  security_43_gis: "ที่จะดำเนินการ",
  security_44_gis: "บันทึก",
  security_45_gis: "แนวโน้มโดยรวม - ระดับชาติ",
  security_46_gis: "รายละเอียดภารกิจ",
  security_47_gis: "มีความรับผิดชอบ",
  security_48_gis: "สถานะ",
  security_49_gis: "กระบวนการ",
  security_50_gis: "การกำหนดค่าการ์ดสถิติ",
  security_51_gis: "หน้าปัจจุบันคือ",
  security_52_gis: "กรุณาเลือกไม่เกิน",
  security_53_gis: "การ์ดสถิติ",
  security_54_gis: "เล่นบันทึก",
  security_55_gis: "เวลาในการบันทึก",
  security_56_gis: "ส่งออก",
  security_57_gis: "รายละเอียดของบันทึก",
  security_58_gis: "ชื่อกล้อง",
  security_59_gis: "เวลาในคน",
  security_60_gis: "เวลา",
  security_61_gis: "ไม่เข้างาน",
  security_62_gis: "กลุ่มลาดตระเวน XX",
  security_63_gis: "เวลาเล่น",
  security_64_gis: "เวลาสิ้นสุด",
  security_65_gis: "เวลา",
  security_66_gis: "ดูตอนนี้",
  security_67_gis: "สร้าง",
  security_68_gis: "รายการการแจ้งเตือน",
  security_69_gis: "ตามเวลา",
  security_70_gis: "โดยความเหมือน",
  security_71_gis: "กล้อง 001",
  security_72_gis: "กล้องควบคุมผ้า",
  security_73_gis: "ยินดีต้อนรับสู่การใช้งาน",
  security_74_gis: "กรุณาลงชื่อเข้าใช้บัญชีของคุณหลังจากเปลี่ยนรหัสผ่าน",
  security_75_gis: "หมายเลขโทรศัพท์",
  security_76_gis: "กรุณากรอกหมายเลขโทรศัพท์",
  security_77_gis: "รหัสยืนยัน",
  security_78_gis: "กรุณากรอกรหัสยืนยัน",
  security_79_gis: "วินาที",
  security_80_gis: "รับรหัสยืนยัน",
  security_81_gis: "รหัสผ่าน",
  security_82_gis: "ตัวเลขและตัวอักษรรวมกัน",
  security_83_gis: "submit",
  security_84_gis: "กลับไปที่การเข้าสู่ระบบ",
  security_85_gis: "กรุณากรอกหมายเลขโทรศัพท์ที่ถูกต้อง",
  security_86_gis: "กรุณากรอกรหัสยืนยันที่ถูกต้อง",
  security_87_gis: "กรุณากรอกรหัสผ่าน",
  security_88_gis: "จำเป็นต้องระบุรหัสผ่าน",
  security_89_gis: "ความปลอดภัยของรหัสผ่านไม่ตรงกัน",
  security_90_gis: "อย่างน้อย 8",
  security_91_gis: "รวมตัวเลข",
  security_92_gis: "ตัวพิมพ์ใหญ่และตัวพิมพ์เล็ก",
  security_93_gis: "อักขระพิเศษอย่างน้อยสามประเภท",
  security_94_gis: "กรุณากรอกหมายเลขโทรศัพท์",
  security_95_gis: "กรุณากรอกหมายเลขโทรศัพท์ที่ถูกต้อง",
  security_96_gis: "เปลี่ยนรหัสผ่านเรียบร้อยแล้ว กรุณาเข้าสู่ระบบอีกครั้ง",
  security_97_gis:
    "คุณเปลี่ยนรหัสผ่านสำเร็จแล้ว กรุณาลงชื่อเข้าใช้แพลตฟอร์มอีกครั้ง",
  security_98_gis: "ข้ามไปโดยอัตโนมัติที่",
  security_99_gis: "หน้าเข้าสู่ระบบ",
  security_100_gis: "ไปที่",
  security_101_gis: "ขอแสดงความยินดี การลงทะเบียนสำเร็จแล้ว",
  security_102_gis: "กรุณาติดต่อผู้ดูแลระบบเพื่อขอรับสิทธิ์บริการที่เกี่ยวข้อง",
  security_103_gis: "กรุณารีเซ็ตรหัสผ่านของคุณ",
  security_104_gis: "ใช้การยืนยันหมายเลขโทรศัพท์มือถือ",
  security_105_gis: "ใช้การยืนยันบัญชีโดเมน",
  security_106_gis: "บัญชีโดเมน",
  security_107_gis: "กรุณากรอกบัญชีโดเมน",
  security_108_gis: "รหัสผ่านเก่า",
  security_109_gis: "กรุณากรอกรหัสผ่านเก่า",
  security_110_gis: "รหัสผ่านใหม่",
  security_111_gis: "กรุณากรอกรหัสผ่านใหม่",
  security_112_gis: "ยืนยันรหัสผ่านใหม่",
  security_113_gis: "กรุณากรอกรหัสผ่านใหม่อีกครั้ง",
  security_114_gis: "ยืนยันการเปลี่ยนแปลง",
  security_115_gis: "กรุณากรอกหมายเลขโทรศัพท์",
  security_116_gis: "กรุณากรอกรหัสผ่านเดิม",
  security_117_gis: "รหัสผ่านใหม่ต้องไม่เหมือนกับรหัสผ่านเดิม",
  security_118_gis: "กรุณากรอกรหัสผ่านอีกครั้ง",
  security_119_gis: "รหัสผ่านทั้งสองไม่สอดคล้องกัน",
  security_120_gis:
    "รหัสผ่านต้องมีความยาว 8-20 หลัก และประกอบด้วยตัวเลขและตัวอักษร",
  security_121_gis: "กรุณากรอกหมายเลขโทรศัพท์มือถือหรือบัญชีโดเมนของคุณ",
  security_122_gis: "กรุณากรอกชื่อหรือหมายเลขงาน",
  security_123_gis: "การเลือกกลุ่มบุคลากร",
  security_124_gis: "ประเภทของงาน",
  security_125_gis: "ดัชนีคุณภาพอากาศแบบเรียลไทม์",
  security_126_gis: "ยอดเยี่ยม",
  security_127_gis: "ดี",
  security_128_gis: "มลภาวะทางแสง",
  security_129_gis: "มีมลภาวะปานกลาง",
  security_130_gis: "มลภาวะหนัก",
  security_131_gis: "มลภาวะที่ร้ายแรง",
  security_132_gis: "เคล็ดลับ",
  security_133_gis: "งาน",
  security_134_gis: "พันล้าน",
  security_135_gis: "สิบล้าน",
  security_136_gis: "ล้าน",
  security_137_gis: "หมื่น",
  security_138_gis: "ย่อ",
  security_139_gis: "ขยาย",
  security_140_gis: "อัปโหลดภาพ",
  security_141_gis: "ลบภาพ",
  security_142_gis: "คำแนะนำ: สนับสนุนเท่านั้น",
  security_143_gis: "อัพโหลดรูปภาพในรูปแบบขนาดของรูปภาพที่อัพโหลดไม่เกิน 3",
  security_144_gis: "ขนาดของรูปภาพ",
  security_145_gis: "ประเภทรูปภาพ",
  security_146_gis: "เวลาสร้าง",
  security_147_gis: "ข้อความแจ้ง",
  security_148_gis: "รูปแบบภาพที่อัปโหลดไม่ถูกต้อง กรุณาอัปโหลดอีกครั้ง",
  security_149_gis:
    "ชื่อของรูปภาพที่อัปโหลดไม่ได้เป็นมาตรฐาน อนุญาตให้ใช้เฉพาะตัวเลข ตัวอักษร ขีดล่าง และเส้นแนวนอนเท่านั้น",
  security_150_gis: "อัปโหลดรูปภาพต้องมีขนาดไม่เกิน 3",
  security_151_gis: "กรุณาอัปโหลดอีกครั้ง",
  security_152_gis: "การอัปโหลดล้มเหลว",
  security_153_gis: "การใช้พลังงานในพื้นที่สาธารณะ",
  security_154_gis: "การใช้พลังงานในพื้นที่ย่อย",
  security_155_gis: "วันก่อน",
  security_156_gis: "วันหลัง",
  security_157_gis: "ปีที่แล้ว",
  security_158_gis: "เดือนที่แล้ว",
  security_159_gis: "เดือน",
  security_160_gis: "ปีที่แล้ว",
  security_161_gis: "ปี",
  security_162_gis: "การใช้พลังงานทั้งหมด",
  security_163_gis: "การใช้พลังงานในพื้นที่สาธารณะ",
  security_164_gis: "พื้นที่สาธารณะ",
  security_165_gis: "ค่าทำนาย",
  security_166_gis: "ค่าเป้าหมาย",
  security_167_gis: "เปอร์เซ็นต์",
  security_168_gis: "เมื่อเทียบ",
  security_169_gis: "ช่วงเวลาเดียวกัน",
  security_170_gis: "อัตราส่วนลูกโซ่",
  security_171_gis: "เทียบไตรมาสนี้กับไตรมาสเดียวกันของปีที่แล้ว",
  security_172_gis: "ดูรายละเอียด",
  security_173_gis: "ยอดจำหน่ายทั้งหมดเมื่อวานนี้",
  security_174_gis: "แสดงถึงความผิดปกติ",
  security_175_gis: "ยอดเรียกเก็บเมื่อวานนี้",
  security_176_gis: "บันทึกเมื่อวานนี้",
  security_177_gis: "หยวน",
  security_178_gis: "สถิติการจัดเก็บพลังงาน",
  security_179_gis: "ทั้งหมด",
  security_180_gis: "ประตูทิศเหนือ",
  security_181_gis: "ประตูทิศตะวันออก",
  security_182_gis: "วัน",
  security_183_gis: "สัปดาห์",
  security_184_gis: "ปริมาณ",
  security_185_gis: "การตั้งค่าสวิตซ์",
  security_186_gis: "เปิดไฟ",
  security_187_gis: "ปิดไฟ",
  security_188_gis: "การแสดงความสว่าง",
  security_189_gis: "ใบสมัคร",
  security_190_gis: "ออฟไลน์",
  security_191_gis:
    "ออกคำสั่งสำเร็จและสถานะจะอัปเดตในเวลาประมาณ 30 วินาที กรุณารอซักครู่",
  security_192_gis: "สว่างเล็กน้อย",
  security_193_gis: "สว่างปานกลาง",
  security_194_gis: "สว่างสูง",
  security_195_gis: "เลือกหน้าจอต้อนรับแล้ว",
  security_196_gis: "เลือกทั้งหมด",
  security_197_gis: "ขยับขึ้น",
  security_198_gis: "ขยับลง",
  security_199_gis: "ย้ายออก",
  security_200_gis: "กล้องที่เลือก",
  security_201_gis: "บันทึกภาพไปแล้วที่",
  security_202_gis: "การจับภาพล้มเหลว",
  security_203_gis: "รหัสข้อผิดพลาด:",
  security_204_gis: "หมายเลขป้ายทะเบียน",
  security_205_gis: "ประเภทเจ้าของ",
  security_206_gis: "เจ้าของรถ",
  security_207_gis: "ชื่อเจ้าของ",
  security_208_gis: "ระยะเวลาควบคุม",
  security_209_gis: "ถึง",
  security_210_gis: "วิถีของยานพาหนะ",
  security_211_gis: "ดู",
  security_212_gis: "เหตุการณ์ที่เกี่ยวข้อง",
  security_213_gis: "แก้ไข",
  security_214_gis: "ทำให้ใช้งานได้อีกครั้ง",
  security_215_gis: "งานนี้ได้สร้างการแจ้งเตือน",
  security_216_gis: "ไม่สามารถแก้ไขได้!",
  security_217_gis: "งานกำลังเริ่มต้นขึ้น",
  security_218_gis: "กรุณาแก้ไขหลังจากหยุดชั่วคราว!",
  security_219_gis: "ไม่ได้เริ่มต้น",
  security_220_gis: "เปิดใช้งานแล้ว",
  security_221_gis: "หยุดแล้ว",
  security_222_gis: "เริ่มต้น",
  security_223_gis: "หยุด",
  security_224_gis: "ดูคำเตือน",
  security_225_gis: "การตัดสินใจผิดพลาด",
  security_226_gis: "รายงานเหตุการณ์",
  security_227_gis: "เวลา:",
  security_228_gis: "สถานที่:",
  security_229_gis: "แหล่งที่มา:",
  security_230_gis: "ระดับ",
  security_231_gis: "กรุณาเลือกระดับ",
  security_232_gis: "ชนิดของ",
  security_233_gis: "กรุณาเลือกประเภท",
  security_234_gis: "คำอธิบาย",
  security_235_gis: "รายงาน",
  security_236_gis:
    "ยืนยันว่าการแจ้งเตือนนี้เป็นการวินิจฉัยที่ไม่ถูกต้อง ข้อมูลการเตือนนี้ไม่ถูกต้อง",
  security_237_gis: "รายงานคู่มือการลาดตระเวนแบบวิดีโอ",
  security_238_gis: "อุณหภูมิ",
  security_239_gis: "ความชื้น",
  security_240_gis: "ฟอร์มาลดีไฮด์",
  security_241_gis: "CO2",
  security_242_gis: "ไอออนของออกซิเจนเชิงลบ",
  security_243_gis: "ส่วนละเอียด",
  security_244_gis: "เสียงรบกวน",
  security_245_gis: "สารระเหยอินทรีย์",
  security_246_gis: "กลางแจ้ง",
  security_247_gis: "ในร่ม",
  security_248_gis: "พื้นที่สิ่งแวดล้อม",
  security_249_gis: "บทความ",
  security_250_gis: "กรอบ",
  security_251_gis: "ดัชนีสิ่งแวดล้อม",
  security_252_gis: "สถิติไฟถนน",
  security_253_gis: "สถิติทรัพย์สิน",
  security_254_gis: "อัตราการใช้งาน",
  security_255_gis: "ความคืบหน้าของงาน",
  security_256_gis: "ชื่อหัวข้อพื้นที่สิ่งแวดล้อม",
  security_257_gis: "จำเป็นต้องระบุเวลาในการปรับใช้",
  security_258_gis: "เวลาในการปรับใช้ต้องมากกว่าหรือเท่ากับเวลาปัจจุบัน",
  security_259_gis: "จำเป็นต้องระบุชื่องาน",
  security_260_gis: "กรุณาอัปโหลดภาพ",
  security_261_gis: "กรุณาเลือกบุคลากร",
  security_262_gis: "บุคคลนั้นไม่มีภาพและไม่สามารถนำไปใช้งานได้",
  security_263_gis: "กรุณาเลือกไลบรารีใบหน้าที่เกี่ยวข้อง",
  security_264_gis: "จำเป็นต้องระบุคำอธิบาย",
  security_265_gis: "กรุณาเลือกประเภทการปรับใช้งาน",
  security_266_gis: "กรุณาเลือกกล้องสำหรับติดตั้ง",
  security_267_gis: "สร้างสำเร็จแล้ว",
  security_268_gis: "แก้ไขเรียบร้อยแล้ว",
  security_269_gis: "ยืนยันที่จะยกเลิกหรือไม่?",
  security_270_gis: "ประกาศจากผู้บริหารอาคาร",
  security_271_gis: "ยึดมั่นในหลักการพื้นฐานในการดำเนินงาน",
  security_272_gis: "การแข่งขันในการเป็นพันธมิตรกัน",
  security_273_gis: "ออกจากพื้นที่ปลอดภัย",
  security_274_gis: "เป็นก้าวแรก",
  security_275_gis: "Cross thousands miles of mountains",
  security_276_gis: "Overcome thousands of difficulties.",
  security_277_gis: "ไม่มีการถอยหลังของนักสู้",
  security_278_gis: "ไม่มีอันตรายข้างหน้าของความกล้าหาญ",
  security_279_gis: "ความสามัคคี",
  security_280_gis: "Go all out.",
  security_281_gis: "มันไม่เกี่ยวกับความแข็งแรง",
  security_282_gis: "ขึ้นอยู่กับระยะเวลาที่สามารถอยู่ได้",
  security_283_gis: "เพื่อหาหนทางสู่ความสำเร็จ",
  security_284_gis: "ไม่มีเหตุผลสำหรับความล้มเหลว",
  security_285_gis: "หนทางอีกยาวไกล",
  security_286_gis: "Determined to win",
  security_287_gis: "มติมหาชน ร่วมสร้างสรรค์ แบ่งปัน แบ่งปัน",
  security_288_gis: "ขวานที่ไม่มีความคมท่านั้น",
  security_289_gis: "ไม่มีฟืนที่แยกไม่ออก",
  security_290_gis: "เบราว์เซอร์ปัจจุบันไม่รองรับ",
  security_291_gis:
    "ข้อมูลควบคุมยานพาหนะถูกลบไปแล้ว ไม่รองรับการแก้ไขหรือจัดเรียงใหม่ กรุณาสร้างการควบคุมการปรับใช้ใหม่",
  security_292_gis: "ต้องระบุหมายเลขป้ายทะเบียน",
  security_293_gis: "ไลบรารีใหม่",
  security_294_gis: "ลบไลบรารี",
  security_295_gis: "บัญชีดำด้านความปลอดภัยสาธารณะ",
  security_296_gis: "อื่น ๆ",
  security_297_gis: "",
  security_298_gis: "",
  security_299_gis: "บัญชีดำ",
  security_300_gis: "ชั่วคราว",
  security_301_gis: "สัมภาษณ์",
  security_302_gis: "อื่น ๆ",
  security_303_gis: "งานที่ติดตาม",
  security_304_gis: "กรุณากรอกชื่องานหรือบุคคล",
  security_305_gis: "เวลาที่ติดตาม",
  security_306_gis: "จำนวนการแจ้งเตือน",
  security_307_gis: "กรุณากรอกจำนวนการแจ้งเตือนขั้นต่ำ",
  security_308_gis: "กรุณากรอกจำนวนการแจ้งเตือนสูงสุด",
  security_309_gis: "สอบถาม",
  security_310_gis: "รีเซ็ต",
  security_311_gis: "เวลาเปิดทำการ",
  security_312_gis: "จำนวนคำเตือน",
  security_313_gis: "ดูแทร็ก",
  security_314_gis: "ประเทศจีน",
  security_315_gis: "พื้นที่เซี่ยงไฮ้",
  security_316_gis: "เซี่ยงไฮ้",
  security_317_gis: "Jinqiao Wanchuang",
  security_318_gis: "F",
  security_319_gis: "B2",
  security_320_gis: "ที่จอดรถ",
  security_321_gis: "บุคลากรย้ายไป",
  security_322_gis:
    "จำนวนการแจ้งเตือนสูงสุดต้องไม่น้อยกว่าหรือเท่ากับจำนวนการแจ้งเตือนขั้นต่ำ",
  security_323_gis: "เหตุการณ์",
  security_324_gis: "รีเฟรช",
  security_325_gis: "เปิด",
  security_326_gis: "ปิด",
  security_327_gis: "เวลาในการประมวลผลเหตุการณ์",
  security_328_gis: "เวลาในการประมวลผลเฉลี่ย:",
  security_329_gis: "ใช้เวลาสั้นที่สุด",
  security_330_gis: "ใช้เวลานานที่สุด",
  security_331_gis: "อันดับ",
  security_332_gis: "ต้นทุนเวลา",
  security_333_gis: "นาที",
  security_334_gis: "นาที",
  security_335_gis: "วัน",
  security_336_gis: "เวลา",
  security_337_gis: "ความปลอดภัยสาธารณะ",
  security_338_gis: "park เป็นเจ้าของ",
  security_339_gis: "ชิ้น",
  security_340_gis: "ปริมาณรวม",
  security_341_gis: "เมื่อเทียบกับเมื่อวาน",
  security_342_gis: "เมื่อวานนี้",
  security_343_gis: "เมื่อเทียบกับสัปดาห์ที่แล้ว",
  security_344_gis: "อาทิตย์ที่แล้ว",
  security_345_gis: "เมื่อเทียบกับเดือนที่แล้ว",
  security_346_gis: "เยี่ยมชมแหล่งที่มา",
  security_347_gis: "ความล้มเหลวและการเพิ่มประสิทธิภาพการดำเนินงาน",
  security_348_gis: "ประเภทข้อบกพร่อง",
  security_349_gis: "ประเภท",
  security_350_gis: "หมายเลขความผิดพลาด",
  security_351_gis: "ศักยภาพในการประหยัดพลังงาน",
  security_352_gis: "สถิติการจำแนกประเภทการแจ้งเตือน",
  security_353_gis: "ประเภทการแจ้งเตือน",
  security_354_gis: "แหล่งที่มาของการแจ้งเตือน",
  security_355_gis: "ระดับการแจ้งเตือน",
  security_356_gis: "จำนวนครั้งที่เกิดขึ้น",
  security_357_gis: "ปริมาณการประมวลผล",
  security_358_gis: "เวลาในการประมวลผลเฉลี่ย",
  security_359_gis: "สถิติการลาดตระเวนแบบวิดีโอ",
  security_360_gis: "กรุณาเข้าสู่ระบบบัญชีของคุณ",
  security_361_gis: "เข้าสู่ระบบ",
  security_362_gis: "จดทะเบียน",
  security_363_gis: "เข้าสู่ระบบบัญชี",
  security_364_gis: "สแกนรหัสเข้าสู่ระบบ",
  security_365_gis: "หมายเลขบัญชี",
  security_366_gis: "กรุณากรอกบัญชีหรือหมายเลขโทรศัพท์มือถือ",
  security_367_gis: "จำบัญชีปัจจุบัน",
  security_368_gis: "ลืมรหัสผ่าน",
  security_369_gis: "เข้าสู่ระบบทันที",
  security_370_gis: "รหัส QR หมดอายุแล้ว",
  security_371_gis: "ดาวน์โหลด",
  security_372_gis: "Yuncheng",
  security_373_gis: "สแกนรหัส QR",
  security_374_gis: "สแกนเพื่อดาวน์โหลด Yuncheng",
  security_375_gis: "เวอร์ชัน Android",
  security_376_gis: "กรุณากรอกบัญชีหรือหมายเลขโทรศัพท์มือถือ",
  security_377_gis: "แพลตฟอร์ม กรุณาลงทะเบียน",
  security_378_gis:
    "การผสมระหว่างตัวอักษร ตัวเลข และอักขระพิเศษอย่างน้อย 2 ประเภท",
  security_379_gis: "ชื่อเล่น",
  security_380_gis: "กรุณากรอกชื่อเล่น",
  security_381_gis: "สมัครเลย",
  security_382_gis: "ความยาวรหัสผ่านคือ 8",
  security_383_gis: "อักขระบิต",
  security_384_gis: "รหัสผ่านต้องมีตัวอักษร ตัวเลข และสัญลักษณ์อย่างน้อย 2 ตัว",
  security_385_gis: "ชื่อเล่นต้องมีความยาวไม่เกิน 12 อักขระ",
  security_386_gis: "การลงทะเบียนไม่สำเร็จ",
  security_387_gis: "ผู้ดำเนินการ",
  security_388_gis: "กรุณาเลือกผู้ดำเนินการ",
  security_389_gis: "เวลาปฏิบัติการ",
  security_390_gis: "กรุณาเลือกวันที่เริ่มต้น",
  security_391_gis: "วันที่สิ้นสุด",
  security_392_gis: "ฟังก์ชัน",
  security_393_gis: "กรุณาเลือกฟังก์ชัน",
  security_394_gis: "กรุณาเลือกการดำเนินการ",
  security_395_gis: "S/N",
  security_396_gis: "เนื้อหา",
  security_397_gis: "Microsoft Yahei",
  security_398_gis: "วิดีโอแบบเรียลไทม์",
  security_399_gis: "ดูกล้อง",
  security_400_gis: "เกิด",
  security_401_gis: "หมายเลขประจำตัว:",
  security_402_gis: "ภูมิลำเนา:",
  security_403_gis: "แหล่งข้อมูล:",
  security_404_gis: "ปิดเครื่อง",
  security_405_gis: "ลบงานการปรับใช้แล้ว",
  security_406_gis:
    "ดูวิดีโอย้อนหลัง วิดีโอเรียลไทม์ รูปภาพ ฯลฯ ที่เกี่ยวข้องกับการแจ้งเตือนใน 'รายละเอียดการแจ้งเตือน' และดูข้อมูลตำแหน่งบนแผนที่",
  security_407_gis:
    "แถบการทำงานทางลัด 'รายละเอียดการแจ้งเตือน' คลิกเพื่อข้ามไปที่ 'ติดตามประวัติ' เพื่อดูประวัติทั้งหมด",
  security_408_gis:
    "แจกจ่ายงานตามใบสั่งงานการแจ้งเตือนไปยังการคณะการรักษาความปลอดภัยการลาดตระเวน",
  security_409_gis: "การรักษาความปลอดภัยลาดตระเวนอยู่ในระหว่างการเดินทาง",
  security_410_gis:
    "ดูข้อมูลโดยละเอียดของงานและการแจ้งเตือนที่เกี่ยวข้อง: วิดีโอย้อนหลัง วิดีโอเรียลไทม์ รูปภาพ ฯลฯ",
  security_411_gis:
    "เจ้าหน้าที่รักษาความปลอดภัยลาดตระเวนไปยังจุดเกิดเหตุ เพื่อตรวจสอบตามจุดที่มีข้อมูลตำแหน่งการแจ้งเตือน",
  security_412_gis:
    "จัดการงานตามใบสั่งงาน อัปโหลดภาพถ่ายในสถานที่ และป้อนข้อมูลข้อความของผลลัพธ์การประมวลผล",
  security_413_gis:
    "จัดเก็บวิดีโอย้อนหลัง การแจ้งเตือน รูปภาพของการแจ้งเตือน ตลอดจนข้อความและรูปภาพของข้อเสนอแนะเกี่ยวกับงานที่ได้รับ และบันทึกข้อมูลและเก็บถาวรระยะยาวเพื่อตรวจสอบในภายหลัง",
  security_414_gis: "รูปภาพการแจ้งเตือน",
  security_415_gis: "ไม่มีรูปภาพ",
  security_416_gis: "จับคู่กัน",
  security_417_gis: "เวลาเกิด",
  security_418_gis: "ระดับเหตุการณ์",
  security_419_gis: "สถานที่เกิดขึ้น",
  security_420_gis: "ไม่มีข้อมูลสถานที่",
  security_421_gis: "รหัสเหตุการณ์",
  security_422_gis: "ไม่มีข้อมูลอุปกรณ์",
  security_423_gis: "แหล่งที่มาของเหตุการณ์",
  security_424_gis: "หมวดอุปกรณ์",
  security_425_gis: "ระยะเวลา",
  security_426_gis: "หมายเลขอุปกรณ์",
  security_427_gis: "โปรเซสเซอร์",
  security_428_gis: "คำอธิบายเหตุการณ์",
  security_429_gis: "โทรศัพท์มือถือของผู้ดูแล",
  security_430_gis: "การแจกจ่ายงาน",
  security_431_gis: "รวม",
  security_432_gis: "งาน",
  security_433_gis: "เพิ่ม",
  security_434_gis: "",
  security_435_gis: "กลุ่ม",
  security_436_gis: "การครอบตัดรูปภาพ",
  security_437_gis: "",
  security_438_gis: "",
  security_439_gis: "หมายเลขการแจ้งเตือน",
  security_440_gis: "สถานะการแจ้งเตือน",
  security_441_gis: "ระดับคำเตือน",
  security_442_gis: "สถานที่",
  security_443_gis: "ผู้ดูแลการแจ้งเตือน",
  security_444_gis: "ชื่อการแจ้งเตือน",
  security_445_gis: "หมายเลขบัตรผู้ใช้",
  security_446_gis: "เวลาปิด",
  security_447_gis: "วิธีการ",
  security_448_gis: "บัญชีผู้ใช้",
  security_449_gis: "ประเภทการดูแลรักษา",
  security_450_gis: "โทรศัพท์บ้านของผู้ดูแล",
  security_451_gis: "หมายเลขทรัพย์สิน",
  security_452_gis: "คำอธิบายการแจ้งเตือน",
  security_453_gis: "การเปลี่ยนแปลงของเหตุการณ์",
  security_454_gis: "แจ้งบุคลากร อพยพบุคลากรในอาคาร",
  security_455_gis: "กด 119, 120",
  security_456_gis: "ติดต่อกับทีมฉุกเฉินและเริ่มปรับใช้การอพยพ",
  security_457_gis:
    "เกิดสัญญาณเตือนบนอุปกรณ์ตรวจจับควันที่ชั้น 1 อาคาร 1, Chengdu Park",
  security_458_gis: "ภาพ",
  security_459_gis: "ผู้สร้าง:",
  security_460_gis: "ใหญ่",
  security_461_gis: "เล็ก:",
  security_462_gis: "วิดีโอ",
  security_463_gis: "เสร็จสมบูรณ์",
  security_464_gis: "กรุณาเลือกกล้อง",
  security_465_gis: "ดูการตรวจสอบตามเวลาจริง",
  security_466_gis: "ข่าวเพิ่มเติม",
  security_467_gis: "รูปถ่าย",
  security_468_gis: "เล่นแบบเต็มหน้าจอ",
  security_469_gis: "วิดีโอย้อนหลัง",
  security_470_gis: "สิ้นสุดการเล่นวิดีโอย้อนหลัง",
  security_471_gis: "ควบคุมการจับคู่",
  security_472_gis: "ดาวน์โหลดรูปภาพ",
  security_473_gis: "ดูการแจ้งเตือน",
  security_474_gis: "งานใหม่",
  security_475_gis: "งานอื่น ๆ",
  security_476_gis: "สถานที่",
  security_477_gis: "แหล่งที่มา",
  security_478_gis: "กรุณากรอกคำอธิบาย",
  security_479_gis: "อัปโหลดวิดีโอลาดตระเวนด้วยตนเอง",
  security_480_gis: "Robert",
  security_481_gis: "ตื่น 03.00 น",
  security_482_gis: "",
  security_483_gis: "รายงานการประชุม วันพรุ่งนี้ 11:30 น",
  security_484_gis: "รายงานการประชุม วันพรุ่งนี้ 14:30 น",
  security_485_gis: "รายการงาน",
  security_486_gis: "การมอบหมายใหม่",
  security_487_gis: "ดูเพิ่มเติม",
  security_488_gis: "การแจ้งเตือน",
  security_489_gis: "เจ้าหน้าที่",
  security_490_gis: "ผู้เยี่ยมชม",
  security_491_gis: "ความปลอดภัยของทรัพย์สิน",
  security_492_gis: "สถิติแผนภูมิวงกลมเดี่ยว",
  security_493_gis: "คน",
  security_494_gis: "การลบออกจำนวนมาก",
  security_495_gis: "แบบ",
  security_496_gis: "จางซาน",
  security_497_gis: "ภาคเหนือ",
  security_498_gis: "ภาคใต้",
  security_499_gis: "ภาคตะวันตกตอนกลาง",
  security_500_gis: "การจัดการไฟถนน",
  security_501_gis: "ชื่อโคมไฟถนน",
  security_502_gis: "สถานะไฟถนน",
  security_503_gis: "เปิดประตู",
  security_504_gis: "ปิดประตู",
  security_505_gis: "ปลุก",
  security_506_gis: "ความคล้ายคลึงกัน:",
  security_507_gis: "บุคลากรเป้าหมายที่น่าสงสัยปรากฏตัวใน",
  security_508_gis: "พื้นที่",
  security_509_gis: "ส่งคนไปดู",
  security_510_gis: "จำนวนกล้องออนไลน์",
  security_511_gis: "ป้อนชื่อแฝงของกล้อง",
  security_512_gis: "เช็คอินออนไลน์",
  security_513_gis: "เช็คอินเรียบร้อย",
  security_514_gis: "การเช็คอินล้มเหลว",
  security_515_gis: "กรุณาเลือกสถานะ",
  security_516_gis: "หัวข้อ",
  security_517_gis: "ต้องป้อนชื่อกิจกรรม",
  security_518_gis: "กรุณากรอกคำอธิบายเหตุการณ์",
  security_519_gis: "Vanke Chengdu Park",
  security_520_gis: "อาคาร 103",
  security_521_gis: "รายงานเหตุการณ์เรียบร้อยแล้ว",
  security_522_gis: "การรายงานเหตุการณ์ล้มเหลว",
  security_523_gis: "จำนวนกลุ่มลาดตระเวน",
  security_524_gis: "กลุ่มลาดตระเวนใหม่",
  security_525_gis: "รายการลาดตระเวนแบบวิดีโอ",
  security_526_gis: "ประวัติลาดตระเวนแบบวิดีโอ",
  security_527_gis: "โหลดแล้ว",
  security_528_gis: "เลือกป้ายกำกับ",
  security_529_gis: "กรุณากรอกเนื้อหา",
  security_530_gis: "แนะนำลายเซ็น",
  security_531_gis: "การวิเคราะห์กลุ่มลูกค้า",
  security_532_gis: "รายชื่อลูกค้า",
  security_533_gis: "หมายเลขบุคลากร",
  security_534_gis: "ประเภทบุคคล",
  security_535_gis: "เยี่ยมชมสวนในสัปดาห์ที่ผ่านมา",
  security_536_gis: "เยี่ยมชมสวนในช่วงเช้าของสัปดาห์ที่ผ่านมา",
  security_537_gis: "วงจรของผู้ใช้",
  security_538_gis: "ใช้เวลาในการสั่งซื้อ",
  security_539_gis: "บริษัท",
  security_540_gis: "กำลังปิดการติดตามใบหน้า",
  security_541_gis: "บุคลากร:",
  security_542_gis: "กำลังติดตาม",
  security_543_gis: "ปิดการติดตาม",
  security_544_gis: "การตรวจสอบภาพหน้าจอ",
  security_545_gis:
    "กำลังติดตามงาน การข้ามไปที่หน้าจะทำให้งานการติดตามหยุดลง แน่ใจหรือไม่ว่าต้องข้ามไปที่หน้านี้?",
  security_546_gis:
    "คุณสามารถติดตามงานได้ครั้งละหนึ่งงาน คุณแน่ใจหรือไม่ว่าต้องละทิ้งงานการติดตามปัจจุบันและเริ่มงานการติดตามใหม่?",
  security_547_gis: "ปิดข้อยกเว้นการติดตาม",
  security_548_gis: "สถิติการแจ้งเตือน",
  security_549_gis: "ครั้ง",
  security_550_gis: "การจัดอันดับอุปกรณ์การแจ้งเตือน",
  security_551_gis: "บันทึกการประมวลผล",
  security_552_gis: "ไม่มีข้อมูลที่มีสิทธิ์ชั่วคราว",
  security_553_gis: "แจ้งพนักงานและอพยพบุคลากรในอาคาร",
  security_554_gis: "แจ้งพนักงานและอพยพส่วนปลีกย่อยของบุคลากรในอาคาร",
  security_555_gis: "หมายเลขงาน",
  security_556_gis: "เวลาเกิดการแจ้งเตือน",
  security_557_gis: "ตำแหน่งของการแจ้งเตือน",
  security_558_gis: "เจ้าของงาน",
  security_559_gis: "สถานะงาน",
  security_560_gis: "ที่จะได้รับ",
  security_561_gis: "ได้รับ",
  security_562_gis: "ประมวลผล",
  security_563_gis: "เวลาปิดงาน",
  security_564_gis: "เวลามอบหมายงาน",
  security_565_gis: "ผู้มอบหมายงาน",
  security_566_gis: "ข้อเสนอแนะสด",
  security_567_gis: "บันทึกงาน",
  security_568_gis: "ภาพสด",
  security_569_gis: "วิดีโอสด",
  security_570_gis: "ชื่อวัตถุ",
  security_571_gis: "เวลาอัปโหลด",
  security_572_gis: "ผู้อัปโหลด",
  security_573_gis: "ขั้นตอนต่อไป",
  security_574_gis: "ส่งต่อสำเร็จ",
  security_575_gis: "การส่งต่อล้มเหลว",
  security_576_gis: "เมตร",
  security_577_gis: "หน่วย",
  security_578_gis: "สถิติประเภทอุปกรณ์ที่ผิดพลาด",
  security_579_gis: "อุปกรณ์",
  security_580_gis: "ปริมาณอุปกรณ์",
  security_581_gis: "จำนวนอุปกรณ์ล้มเหลว",
  security_582_gis: "จำนวนอุปกรณ์ที่ล้มเหลว",
  security_583_gis: "วันนี้",
  security_584_gis: "เมื่อวานนี้",
  security_585_gis: "เกือบหนึ่งเดือน",
  security_586_gis: "เกือบหนึ่งปี",
  security_587_gis: "บัตรผ่าน",
  security_588_gis: "คน - เวลา",
  security_589_gis: "เวลารถ",
  security_590_gis: "ชื่อทาสก์",
  security_591_gis: "กรุณากรอกชื่องาน",
  security_592_gis: "สถานะการควบคุม",
  security_593_gis: "กรุณาเลือกสถานะการปรับใช้",
  security_594_gis: "กรุณาเลือก park ก่อน",
  security_595_gis: "งานกำลังเริ่ม กรุณาอย่าลบ",
  security_596_gis: "ยืนยันที่จะลบหรือไม่?",
  security_597_gis: "ลบสำเร็จแล้ว",
  security_598_gis: "ข้อผิดพลาดของเครือข่าย",
  security_599_gis: "การดำเนินงานที่ประสบความสำเร็จ",
  security_600_gis: "สิ้นสุดการลาดตระเวนแบบวิดีโอ",
  security_601_gis: "วิดีโอหยุดชั่วคราว",
  security_602_gis: "ลบ",
  security_603_gis: "ชื่อเส้นทางลาดตระเวน",
  security_604_gis: "กรุณากรอกชื่อเส้นทางลาดตระเวน",
  security_605_gis: "คำอธิบายเส้นทางลาดตระเวน",
  security_606_gis: "กรุณากรอกคำอธิบายเส้นทางลาดตระเวน",
  security_607_gis: "จำนวนกล้อง",
  security_608_gis: "เวลาลาดตระเวน",
  security_609_gis: "ไม่ว่าจะลบข้อมูล",
  security_610_gis: "กรุณาเลือกเพื่อลบข้อมูล",
  security_611_gis: "ไม่ว่าจะลบข้อมูลที่ตรวจสอบ",
  security_612_gis: "ในสัปดาห์นี้",
  security_613_gis: "เดือนนี้",
  security_614_gis: "ดำเนินการ",
  security_615_gis: "เส้นทาง",
  security_616_gis: "ปัญหา",
  security_617_gis: "สร้าง",
  security_618_gis: "เวลาดำเนินการ",
  security_619_gis: "ทำซ้ำวิธี",
  security_620_gis: "ชื่อแผนการลาดตระเวน",
  security_621_gis: "เตือน",
  security_622_gis: "เปิดใช้งาน",
  security_623_gis: "ปิดใช้งาน",
  security_624_gis: "ไม่ว่าจะวนซ้ำ",
  security_625_gis: "ใช่",
  security_626_gis: "ไม่",
  security_627_gis: "เปิดใช้งาน",
  security_628_gis: "กรุณาเลือก park",
  security_629_gis: "ป้อนชื่อหรือหมายเลข",
  security_630_gis: "ควัน",
  security_631_gis: "การควบคุมการเข้าถึง",
  security_632_gis: "กลับมาอีกชั้น",
  security_633_gis: "รหัส",
  security_634_gis: "การกลับสู่พื้นหลัง",
  security_635_gis: "กรุณาเลือก",
  security_636_gis: "จัดการกับ",
  security_637_gis: "กรุณาเลือกวิธีการประมวลผล",
  security_638_gis: "ป้องกันเหตุการณ์ด้านความปลอดภัยของอุปกรณ์",
  security_639_gis: "คำเตือนสิ่งอำนวยความสะดวกการขนส่ง",
  security_640_gis: "เลเยอร์เพิ่มเติม",
  security_641_gis: "ข้อความ",
  security_642_gis: "การถ่ายทอดเหตุการณ์",
  security_643_gis: "บันทึก",
  security_644_gis: "ดาวน์โหลดภาพขนาดใหญ่ HD",
  security_645_gis: "องค์กร",
  security_646_gis: "คนรอบข้าง",
  security_647_gis: "ไปที่การควบคุมการปรับใช้",
  security_648_gis: "ภาพใบหน้า",
  security_649_gis: "วิธีการกด",
  security_650_gis: "เนื้อหาการกด",
  security_651_gis: "คุณแน่ใจหรือไม่ว่าจะกด?",
  security_652_gis: "กดสำเร็จ",
  security_653_gis: "เซิร์ฟเวอร์ผิดพลาด!",
  security_654_gis: "วิธีการจัดจำหน่าย",
  security_655_gis: "ผู้ส่งของ",
  security_656_gis: "จำนวนการจัดจำหน่ายไม่เกิน 10 ท่าน!",
  security_657_gis: "การจัดการเหตุการณ์",
  security_658_gis: "ส่งงานเรียบร้อยแล้ว",
  security_659_gis: "ถ่ายภาพ",
  security_660_gis: "ไม่มีภาพเตือน",
  security_661_gis: "ไม่มีภาพถ่าย",
  security_662_gis: "ควบคุมรูปภาพ",
  security_663_gis: "จำนวนงาน",
  security_664_gis: "การแจ้งเตือนที่เกี่ยวข้อง",
  security_665_gis: "เวลาสร้าง",
  security_666_gis: "แผนที่",
  security_667_gis: "รวม",
  security_668_gis: "ปิด",
  security_669_gis: "กรุณากรอกหมายเลขการแจ้งเตือน",
  security_670_gis: "กรุณาเลือกระดับการแจ้งเตือน",
  security_671_gis: "กรุณาเลือกสถานะการแจ้งเตือน",
  security_672_gis: "กรุณาเลือกประเภทการแจ้งเตือน",
  security_673_gis: "ระบบต้นทาง",
  security_674_gis: "รายละเอียด",
  security_675_gis: "เกรด",
  security_676_gis: "ไม่สามารถส่งออกข้อมูลเปล่าได้",
  security_677_gis: "รายการเหตุเพลิงไหม้",
  security_678_gis: "เลือกแล้ว",
  security_679_gis: "กรุณากรอกคำอธิบายงาน",
  security_680_gis:
    "คำแนะนำ: หลังจากประมวลผลงานการจัดส่งแล้ว กิจกรรมจะปิดโดยอัตโนมัติ",
  security_681_gis: "ก่อนหน้า",
  security_682_gis: "งานใหม่เสร็จสมบูรณ์",
  security_683_gis: "การกระจายงาน",
  security_684_gis: "เลือกบุคลากร",
  security_685_gis: "มอบหมายงาน",
  security_686_gis: "ระยะทาง",
  security_687_gis: "กรุณาเลือกบุคคลที่จะมอบหมายงาน",
  security_688_gis: "กรุณากรอกคำหลักเพื่อค้นหา",
  security_689_gis: "กรุณากรอกเนื้อหางาน",
  security_690_gis: "เพิ่มงานสำเร็จแล้ว",
  security_691_gis: "การดำเนินการล้มเหลว",
  security_692_gis: "ข้อมูลพื้นฐาน",
  security_693_gis: "ไม่ว่าจะเริ่ม",
  security_694_gis: "แจ้งเจ้าหน้าที่",
  security_695_gis: "พนักงานใหม่",
  security_696_gis: "เตือนกล้อง",
  security_697_gis:
    "หลังจากเปิด มันจะถูกควบคุม หากคุณต้องการปิด สามารถปิดได้ด้วยตนเอง",
  security_698_gis:
    "ไม่มีการควบคุมอีกต่อไปหลังจากปิด หากคุณต้องการเปิดใช้งาน คุณสามารถเปิดใหม่ได้ด้วยตนเอง",
  security_699_gis: "ความสำเร็จ",
  security_700_gis: "กรุณากรอกชื่องานและเลือกกล้อง",
  security_701_gis: "ไม่มีการติดตามการเคลื่อนไหวของบุคคลนั้นในช่วงเวลานี้",
  security_702_gis: "ส่งออกแทร็กสำเร็จ",
  security_703_gis: "ติดตาม",
  security_704_gis: "กรุณากรอกชื่อหรือหมายเลขโทรศัพท์ของคุณ",
  security_705_gis: "ดูการติดตาม",
  security_706_gis: "ช่วงเวลาการติดตาม",
  security_707_gis: "เวลาเริ่มต้น",
  security_708_gis: "ยกเลิกการปรับเปลี่ยนบุคลากร",
  security_709_gis:
    "คุณยังไม่ได้ติดตั้งโปรแกรมเล่นวิดีโอ คุณต้องการดาวน์โหลดปลั๊กอินโปรแกรมเล่นวิดีโอตอนนี้หรือไม่ หลังจากดาวน์โหลดแล้วกรุณาติดตั้งด้วยตัวคุณเอง",
  security_710_gis: "ไม่มีผลลัพธ์กลับมา",
  security_711_gis: "ไม่มีผลลัพธ์",
  security_712_gis: "จำนวนเครื่องบินลาดตระเวน",
  security_713_gis: "เล่น",
  security_714_gis: "หยุด",
  security_715_gis: "เปิดเผย",
  security_716_gis: "จบ",
  security_717_gis: "หมายเลขกล้อง",
  security_718_gis: "ชื่อแผนภูมิ",
  security_719_gis: "ยอดเรียกเก็บทั้งหมด",
  security_720_gis: "ยอดชำระทั้งหมด",
  security_721_gis: "ประหยัดค่าใช้จ่าย",
  security_722_gis: "เกินมูลค่าเป้าหมาย",
  security_723_gis: "สถานการณ์ด้านความปลอดภัย",
  security_724_gis: "ปักกิ่ง",
  security_725_gis: "เฉิงตู",
  security_726_gis: "กว่างโจว",
  security_727_gis: "เซินเจิ้น",
  security_728_gis: "จำนวนการแจ้งเตือนทั้งหมด",
  security_729_gis: "เหตุการณ์สำคัญ",
  security_730_gis: "ที่จะดำเนินการ",
  security_731_gis: "เริ่มการติดตามใบหน้าไม่สำเร็จ",
  security_732_gis: "ดัชนีการวิเคราะห์",
  security_733_gis: "พนักงานองค์กร",
  security_734_gis: "กลับ",
  security_735_gis: "ค้นหาตามชื่อ",
  security_736_gis: "รายการของการทุจริต",
  security_737_gis: "เพิ่มไปที่",
  security_738_gis: "นำเข้า",
  security_739_gis: "ดาวน์โหลดเทมเพลต",
  security_740_gis: "เลือกโหนด เมื่อคุณคลิกโหนด",
  security_741_gis: "ขยายโหนดทั้งหมดตามค่าเริ่มต้น",
  security_742_gis: "เพิ่มใบหน้า",
  security_743_gis: "รองรับเท่านั้น",
  security_744_gis: "จัดรูปแบบรูปภาพและไม่เกิน 3",
  security_745_gis: "กรุณาพิมพ์ชื่อของคุณ",
  security_746_gis: "ประเภทรายการ",
  security_747_gis: "เลขประจำตัว",
  security_748_gis: "กรุณากรอกหมายเลขประจำตัว",
  security_749_gis: "เพศ",
  security_750_gis: "ชาย",
  security_751_gis: "หญิง",
  security_752_gis: "ที่อยู่อาศัย",
  security_753_gis: "กรุณากรอกที่อยู่อาศัย",
  security_754_gis: "ขยายส่วนทั้งหมดตามค่าเริ่มต้น",
  security_755_gis: "กรุณาเลือกประเภทรายการ",
  security_756_gis: "กรุณากรอกหมายเลขประจำตัวที่ถูกต้อง",
  security_757_gis: "รองรับรูปภาพเท่านั้น",
  security_758_gis: "รูปภาพต้องมีขนาดไม่เกิน 3",
  security_759_gis: "เทมเพลตการนำเข้าบัญชีดำด้านความปลอดภัยสาธารณะ",
  security_760_gis: "นำเข้าสำเร็จ",
  security_761_gis: "การนำเข้าล้มเหลว",
  security_762_gis: "ตาราง",
  security_763_gis: "ลบไม่สำเร็จ",
  security_764_gis: "กรุณาเลือกวัตถุที่จะลบ",
  security_765_gis: "ยืนยันที่จะลบ",
  security_766_gis: "กรุณาอัปโหลดรูปภาพ",
  security_767_gis: "ดำเนินการสำเร็จ!",
  security_768_gis: "แนวโน้มสถิติการแจ้งเตือน",
  security_769_gis: "แนวโน้มทั่วไป",
  security_770_gis: "เครื่องตรวจจับควัน",
  security_771_gis: "การแจ้งเตือนอุณหภูมิ",
  security_772_gis: "การแจ้งเตือนด้วยตนเอง",
  security_773_gis: "สัญญาณเตือนไฟไหม้ไม่ได้รับการประมวลผล",
  security_774_gis: "สัญญาณเตือนไฟไหม้จริง",
  security_775_gis: "รายงานเท็จ",
  security_776_gis: "สถิติอุปกรณ์",
  security_777_gis: "สัปดาห์ล่าสุด",
  security_778_gis: "ช่วงครึ่งปีที่ผ่านมา",
  security_779_gis: "ไม่มีสถิติ",
  security_780_gis: "โฮสต์การสื่อสาร",
  security_781_gis: "ออฟไลน์ (สถานี)",
  security_782_gis: "เปิดใช้งาน (หน่วย)",
  security_783_gis: "ปริมาณรวม (ชุด)",
  security_784_gis: "อุปกรณ์ตรวจจับควัน",
  security_785_gis: "ความล้มเหลว (หน่วย)",
  security_786_gis: "ไม่มีข้อบกพร่อง (ชุด)",
  security_787_gis: "อุปกรณ์ตรวจจับอุณหภูมิ",
  security_788_gis: "อาทิตย์ที่แล้ว",
  security_789_gis: "เดือนที่แล้ว",
  security_790_gis: "ครึ่งปีแรก",
  security_791_gis: "สถิติเหตุการณ์ด้านความปลอดภัย",
  security_792_gis: "แนวโน้มการแจ้งเตือนไฟไหม้",
  security_793_gis: "เปอร์เซ็นต์ของประเภทการแจ้งเตือนด้านความปลอดภัย",
  security_794_gis: "สถิติสถานะเหตุการณ์",
  security_795_gis: "แนวโน้มการแจ้งเตือนด้านความปลอดภัย",
  security_796_gis: "สถิติสถานะงาน",
  security_797_gis: "จำนวนการแจ้งเตือนที่รอดำเนินการ",
  security_798_gis: "จำนวนงานทั้งหมด",
  security_799_gis: "จำนวนงานที่รอดำเนินการ",
  security_800_gis: "อาทิตย์ที่แล้ว",
  security_801_gis: "เดือนที่แล้ว",
  security_802_gis: "สามเดือนที่แล้ว",
  security_803_gis: "ความปลอดภัย",
  security_804_gis: "ดับเพลิง",
  security_805_gis: "ไม่มีข้อมูล",
  security_806_gis: "จำนวนการประมวลผลทั้งหมด",
  security_807_gis: "การแจ้งเตือนความปลอดภัยทั้งหมด",
  security_808_gis: "สัญญาณเตือนภัย",
  security_809_gis: "สัญญาณเตือนไฟไหม้",
  security_810_gis: "เปิดการติดตาม",
  security_811_gis: "เปิดการติดตามสำเร็จแล้ว",
  security_812_gis: "การตรวจสอบตามเวลาจริงและการอัปโหลดด้วยตนเอง",
  security_813_gis: "เล่นโหมดเปลี่ยนหน้าต่าง",
  security_814_gis: "แทนที่รายการที่เลือก",
  security_815_gis: "แทนที่เร็วที่สุด",
  security_816_gis: "อัปโหลดภาพหน้าจอไม่สำเร็จ! กรุณาลองอีกครั้ง",
  security_817_gis: "เวลาทัวร์เดี่ยว",
  security_818_gis: "แผนใหม่",
  security_819_gis: "กำหนดการลาดตระเวน",
  security_820_gis: "สถิติในอดีต",
  security_821_gis: "เจ้าหน้าที่ลาดตระเวน",
  security_822_gis: "กรุณากรอกผู้ลาดตระเวน",
  security_823_gis: "เวลาเริ่มและหยุด",
  security_824_gis: "ไม่ว่าจะทำกระบวนการทั้งหมด",
  security_825_gis: "Vanke Park",
  security_826_gis: "North China Park",
  security_827_gis: "กรุณาเลือกภูมิภาค",
  security_828_gis: "กรุณาเลือกเมือง",
  security_829_gis: "กรุณาเลือก park",
  security_830_gis: "กรุณาเลือกช่วงเวลา",
  security_831_gis: "การเฝ้าระวังการควบคุมการเข้าถึง",
  security_832_gis: "เข้าถึงอาคารได้ทั้งหมด",
  security_833_gis: "เปิด",
  security_834_gis: "ส่งข้อความ",
  security_835_gis: "การควบคุมการเข้าถึง",
  security_836_gis: "อัปโหลดรูปภาพ",
  security_837_gis: "อัปโหลด",
  security_838_gis: "การปรับใช้ที่เกี่ยวข้อง",
  security_839_gis: "เพิ่มการควบคุมการปรับใช้",
  security_840_gis: "เพิ่มการควบคุมใบหน้า",
  security_841_gis: "การควบคุมการปรับใช้ยานพาหนะใหม่",
  security_842_gis: "การควบคุมการทำให้ใช้งานได้จริง",
  security_843_gis: "ติดตามตอนนี้",
  security_844_gis: "ข้อความแสดงคำเตือน",
  security_845_gis: "การแจ้งเตือนที่ไม่แน่นอน",
  security_846_gis: "คำเตือนทั่วไป",
  security_847_gis: "คำเตือนที่สำคัญ",
  security_848_gis: "การแจ้งเตือนฉุกเฉิน",
  security_849_gis: "สายเข้า",
  security_850_gis: "โทร",
  security_851_gis: "ที่ใช้กันทั่วไป",
  security_852_gis: "บุคคลที่ติดต่อ",
  security_853_gis: "ปลดอาวุธ",
  security_854_gis: "ผลักดันเหตุการณ์",
  security_855_gis: "ต้องระบุประเภท",
  security_856_gis: "การเรียกหัวหน้าการรักษาความปลอดภัย",
  security_857_gis: "วางสาย",
  security_858_gis: "การคัดเลือกบุคลากร",
  security_859_gis: "แผนที่การแสดงภาพของบุคลากร",
  security_860_gis: "ไม่มีบุคลากร",
  security_861_gis: "ส่ง",
  security_862_gis: "ต้องระบุเนื้อหาที่ส่ง SMS",
  security_863_gis: "ส่ง SMS เรียบร้อยแล้ว",
  security_864_gis: "ผู้ที่ส่ง SMS ไม่สำเร็จ",
  security_865_gis: "กรุณาทราบว่าบุคลากรข้างต้นไม่สามารถรับ SMS ได้",
  security_866_gis:
    "(คำเตือนของ Vanke Smart Park Operation Center) Vanke Smart Park Operation Center เตือนคุณว่า:",
  security_867_gis: "ขอบคุณ!",
  security_868_gis: "ต้องระบุผู้รับ SMS",
  security_869_gis: "การส่งคืนอุณหภูมิส่วนตัวของบุคลากร",
  security_870_gis: "การทำความสะอาด",
  security_871_gis: "ทรัพย์สิน",
  security_872_gis: "ความปลอดภัย",
  security_873_gis: "เพียงเลือก",
  security_874_gis: "ค้นหาเนื้อหา",
  security_875_gis: "เอาออกไป",
  security_876_gis: "ตัวจำลอง",
  security_877_gis: "ผลักดันพนักงาน",
  security_878_gis: "กรุณากรอกหมายเลขงาน",
  security_879_gis: "กรุณากรอกเจ้าของงาน",
  security_880_gis: "กรุณาเลือกสถานะงาน",
  security_881_gis: "กรุณากรอกผู้รับมอบหมายงาน",
  security_882_gis: "ผลลัพธ์ของกระบวนการ",
  security_883_gis: "ส่งต่อ",
  security_884_gis: "เหตุการณ์ของธุรกิจ",
  security_885_gis: "เหตุการณ์ของสิ่งอำนวยความสะดวก",
  security_886_gis: "กรุณาเลือกอย่างน้อยหนึ่งคน",
  security_887_gis: "ห้ามใส่อักขระพิเศษ",
  security_888_gis: "ยินดีต้อนรับ",
  security_889_gis: "การควบคุมการปรับใช้",
  security_890_gis:
    "ไม่สามารถรับข้อมูลผู้ใช้ที่ลงทะเบียนได้ กรุณาติดต่อผู้ดูแลระบบ!",
  security_891_gis:
    "ไม่สามารถรับข้อมูลรูปภาพของผู้ใช้ที่ลงทะเบียน กรุณาติดต่อผู้ดูแลระบบ!",
  security_892_gis: "จำเป็นต้องระบุเหตุผลในการควบคุม",
  security_893_gis: "อัปโหลดแล้ว",
  security_894_gis:
    "จำเป็นต้องระบุชื่อของรูปภาพ และชื่อต้องประกอบด้วยอักขระจีน ตัวอักษร หรือตัวเลข ที่มีความยาวไม่เกิน 32 อักขระเท่านั้น",
  security_895_gis: "กรุณาเลือกผู้แจ้ง",
  security_896_gis: "ไม่ได้เปิดใช้งาน",
  security_897_gis: "สิ้นสุด",
  security_898_gis: "ปิดเรียบร้อยแล้ว",
  security_899_gis: "เปิดใช้งานสำเร็จแล้ว",
  security_900_gis: "ปิดใช้งานเรียบร้อยแล้ว",
  security_901_gis: "ยืนยันการปิดใช้งาน",
  security_902_gis: "หมายเลขประจำตัว",
  security_903_gis: "แหล่งข้อมูล",
  security_904_gis: "การโหลดเครือข่ายผิดปกติ กรุณาลองอีกครั้งในภายหลัง",
  security_905_gis: "การโหลดเครือข่ายผิดปกติ",
  security_906_gis: "ไม่มีคำเตือน",
  security_907_gis: "ภาพหน้าจอล้มเหลว",
  security_908_gis: "เส้นทางลาดตระเวนใหม่",
  security_909_gis: "รายชื่อเส้นทางลาดตระเวน",
  security_910_gis: "แผนการลาดตระเวน",
  security_911_gis: "เส้นทางลาดตระเวน",
  security_912_gis: "ปิดการลาดตระเวนแบบวิดีโอ",
  security_913_gis: "ไม่ว่าจะหยุดการลาดตระเวนแบบวิดีโอชั่วคราว",
  security_914_gis: "ไม่ว่าจะหยุดการลาดตระเวนแบบวิดีโอ",
  security_915_gis: "คำขอรับทรัพยากรวิดีโอล้มเหลว",
  security_916_gis: "ไม่สามารถอ่านวิดีโอจากไฟล์เซิร์ฟเวอร์ได้",
  security_917_gis: "ความคล้ายคลึงกัน",
  security_918_gis: "กล้องที่เกี่ยวข้อง",
  security_919_gis: "ของฉัน",
  security_920_gis: "เชื่อมต่อแล้ว",
  security_921_gis: "เชื่อมต่อ",
  security_922_gis: "กล้องที่เชื่อมโยงกับเหตุการณ์",
  security_923_gis: "กล้องรอบข้าง",
  security_924_gis: "กล้องที่เกี่ยวข้อง",
  security_925_gis: "เลือกวันที่และเวลาเริ่มต้น",
  security_926_gis: "เลือกวันที่และเวลาสิ้นสุด",
  security_927_gis: "การจับภาพวิดีโอ",
  security_928_gis: "เวลาสิ้นสุดต้องมากกว่าเวลาเริ่มต้น",
  security_929_gis: "การเล่นวิดีโอ",
  security_930_gis: "เล่นซ้ำ",
  security_931_gis: "กรุณาเลือกเวลา",
  security_932_gis: "ระยะเวลาไม่ควรน้อยกว่า 30 วินาที",
  security_933_gis: "ห้ามเกินหนึ่งชั่วโมง",
  security_934_gis: "ยับยั้งสำเร็จ",
  security_935_gis:
    "วิดีโอถูกยับยั้งสำเร็จแล้ว กรุณาไปที่วิดีโอที่เกี่ยวข้องในรายละเอียดเหตุการณ์ทางด้านซ้ายเพื่อดู",
  security_936_gis: "การจับภาพวิดีโอล้มเหลว",
  security_937_gis: "ความล้มเหลว",
  security_938_gis: "ข้อหลัก",
  security_939_gis: "สามัญ",
  security_940_gis:
    "ป้อนชื่อหัวข้อเหตุการณ์ หรือชื่ออุปกรณ์ หรือหมายเลขเหตุการณ์",
  security_941_gis:
    "ป้อนชื่อการแจ้งเตือน หรือนามแฝงสิ่งอำนวยความสะดวก หรือหมายเลขการแจ้งเตือน",
  security_942_gis: "กำลังเริ่มการติดตามใบหน้า",
  security_943_gis: "การติดตามใบหน้าใหม่",
  security_944_gis: "การควบคุมการปรับใช้ใบหน้าใหม่",
  security_945_gis: "ประเภทเหตุการณ์",
  security_946_gis: "กรุณาเลือกประเภทเหตุการณ์",
  security_947_gis: "ระดับเหตุการณ์",
  security_948_gis: "กรุณาเลือกระดับเหตุการณ์",
  security_949_gis: "ติดตาม",
  security_950_gis: "การติดตามใบหน้า",
  security_951_gis: "รายการที่อนุญาต",
  security_952_gis: "ไม่สามารถกู้คืนข้อมูลได้หลังการลบ ยืนยันการลบหรือไม่?",
  security_953_gis: "ลบข้อมูลเรียบร้อยแล้ว",
  security_954_gis: "การโหลดเครือข่ายผิดปกติ กรุณาลองอีกครั้งในภายหลัง",
  security_955_gis: "ระยะเวลาการติดตาม",
  security_956_gis: "สถานะการติดตาม",
  security_957_gis: "กรุณาเลือกสถานะการติดตาม",
  security_958_gis: "กล้องติดตาม",
  security_959_gis: "กรุณาเลือกกล้องติดตาม",
  security_960_gis: "ไม่ว่าจะลบการแจ้งเตือนและงานที่สร้างโดยงานการปรับใช้",
  security_961_gis: "ใบหน้าของมนุษย์",
  security_962_gis: "กรุณากรองงานที่เกี่ยวข้อง",
  security_963_gis: "ระยะเวลาการแจ้งเตือน",
  security_964_gis: "ถึงหน้าต่างเตือนความจำแล้ว",
  security_965_gis: "สถานะการแจ้งเตือน",
  security_966_gis: "กล้องเตือนการมาถึง",
  security_967_gis: "เวลาต้อนรับ",
  security_968_gis: "สถานะการต้อนรับ",
  security_969_gis: "กรุณาเลือกสถานะการต้อนรับ",
  security_970_gis: "เข้าสู่หน้าจอต้อนรับ",
  security_971_gis: "มุมมองการแจ้งเตือน",
  security_972_gis:
    "ตรวจสอบว่าการแจ้งเตือนนี้เป็นการวินิจฉัยที่ไม่ถูกต้อง การแจ้งเตือนนี้ไม่ถูกต้อง",
  security_973_gis: "อาคารหลังหนึ่งใน Vanke Chengdu Park",
  security_974_gis: "คำเตือนฉุกเฉินสีแดง",
  security_975_gis: "คำเตือนฉุกเฉินสีส้ม",
  security_976_gis: "คำเตือนฉุกเฉินสีเหลือง",
  security_977_gis: "คำเตือนฉุกเฉินสีน้ำเงิน",
  security_978_gis: "อุบัติเหตุร้ายแรงโดยเฉพาะ",
  security_979_gis: "อุบัติเหตุใหญ่",
  security_980_gis: "อุบัติเหตุใหญ่",
  security_981_gis: "อุบัติเหตุทั่วไป",
  security_982_gis: "จำเป็นต้องระบุ กรุณากรอกเนื้อหา",
  security_983_gis: "ภาพ",
  security_984_gis: "อัปโหลดไฟล์ในเครื่อง",
  security_985_gis: "เลือกจากคลังเจ้าหน้าที่",
  security_986_gis: "เกณฑ์การแจ้งเตือน",
  security_987_gis: "รายงานเหตุการณ์โดยอัตโนมัติ",
  security_988_gis: "แท็กบุคคล",
  security_989_gis: "บุคลากร",
  security_990_gis: "ชื่อบุคคล",
  security_991_gis: "ประเภทการควบคุม",
  security_992_gis: "บันทึกเหตุการณ์",
  security_993_gis: "ป้าย",
  security_994_gis: "จำเป็นต้องระบุเวลา",
  security_995_gis: "อัปโหลดได้สูงสุด 5 ภาพ",
  security_996_gis: "ขนาดภาพต้องไม่เกิน 2",
  security_997_gis: "อัปโหลดได้เท่านั้น",
  security_998_gis: "จัดรูปแบบรูปภาพ",
  security_999_gis: "ชื่องานต้องไม่เกิน 50 อักขระ",
  security_1000_gis: "เหตุเพลิงไหม้",
  security_1001_gis: "เหตุการณ์ด้านความปลอดภัย",
  security_1002_gis: "การติดตามใบหน้า",
  security_1003_gis: "เลือกจากคลังรถ",
  security_1004_gis: "เลือกยานพาหนะ",
  security_1005_gis: "กรุณากรอกหมายเลขป้ายทะเบียน",
  security_1006_gis: "ป้ายรถ",
  security_1007_gis: "กรุณากรอกหมายเหตุ",
  security_1008_gis: "การเลือกคลังยานพาหนะ",
  security_1009_gis: "คนติดตาม",
  security_1010_gis: "เปิดใช้งานการควบคุมการปรับใช้แล้ว และไม่สามารถแก้ไขได้",
  security_1011_gis: "การปรับใช้ถูกหยุด และไม่สามารถแก้ไขได้",
  security_1012_gis: "ไม่มีเหตุการณ์ที่เกี่ยวข้อง",
  security_1013_gis: "พนักงานที่เป็น Guest ไม่มีอำนาจในการดำเนินการนี้",
  security_1014_gis: "ไม่มีบุคลากรติดตาม",
  security_1015_gis: "การโหลดเครือข่ายผิดปกติ กรุณาลองอีกครั้งในภายหลัง",
  security_1016_gis: "ความสำเร็จของการตัดสินใจผิดพลาด",
  security_1017_gis: "กรุณาเลือกระดับเหตุการณ์",
  security_1018_gis: "กรุณากรอกรายละเอียดเหตุการณ์",
  security_1019_gis: "คำอธิบายเหตุการณ์ต้องไม่เกิน 1,000 อักขระ",
  security_1020_gis:
    "สถานะเหตุการณ์อยู่ระหว่างรอดำเนินการ และไม่สามารถรายงานได้ชั่วคราว",
  security_1021_gis:
    "สถานะเหตุการณ์กำลังประมวลผล และไม่สามารถรายงานได้ชั่วคราว",
  security_1022_gis:
    "สถานะเหตุการณ์อยู่ระหว่างรอดำเนินการและไม่สามารถรายงานได้ชั่วคราว",
  security_1023_gis: "กรุณากรอกเวลาลาดตระเวน",
  security_1024_gis: "หมายเหตุ",
  security_1025_gis: "กรุณากรอกคำอธิบายของกลุ่มลาดตระเวน",
  security_1026_gis: "จำเป็นต้องระบุชื่อการลาดตระเวน",
  security_1027_gis: "ชื่อลาดตระเวนต้องมีความยาวไม่เกิน 200 อักขระ",
  security_1028_gis: "เวลาไม่สามารถเป็น 0 ได้",
  security_1029_gis: "เวลาต้องเป็นจำนวนเต็มบวกที่ไม่ใช่ศูนย์",
  security_1030_gis: "กรุณาเลือกกล้องอย่างน้อยหนึ่งตัว",
  security_1031_gis: "สร้างสำเร็จแล้ว",
  security_1032_gis: "ดูตัวอย่าง",
  security_1033_gis: "เวลาสถิติ:",
  security_1034_gis: "กรุณาเลือกช่วงวันที่",
  security_1035_gis: "การลาดตระเวนตามแผนเท่านั้นที่มีส่วนร่วมในสถิติ",
  security_1036_gis: "การนำไปใช้งาน (ครั้ง)",
  security_1037_gis: "กล้อง",
  security_1038_gis: "อัตราการเจาะวิดีโอ",
  security_1039_gis: "เวลาดำเนินการทั้งหมด (นาที)",
  security_1040_gis: "เส้นทาง ",
  security_1041_gis: "เหตุการณ์การแจ้งเตือนและสถานะการติดตาม",
  security_1042_gis: "แสดงจำนวนการแจ้งเตือนการลาดตระเวนแบบวิดีโอเท่านั้น",
  security_1043_gis: "ประวัติแผนการลาดตระเวน",
  security_1044_gis: "ประวัติเส้นทางลาดตระเวน",
  security_1045_gis: "ประวัติการลาดตระเวนทั้งหมด",
  security_1046_gis: "ชื่อเหตุการณ์",
  security_1047_gis: "สถานะเหตุการณ์",
  security_1048_gis: "ช่วง",
  security_1049_gis: "กล้องจับภาพ",
  security_1050_gis: "การติดตามในอดีต",
  security_1051_gis:
    "บุคคลนั้นกำลังถูกติดตาม การข้ามหน้าจะทำให้งานการติดตามหยุดลง แน่ใจหรือไม่ว่าต้องการข้ามหน้า?",
  security_1052_gis: "เลือกสถานที่",
  security_1053_gis: "ประเทศ",
  security_1054_gis: "กรุณาเลือกประเทศ",
  security_1055_gis: "กรุณาเลือกภูมิภาค",
  security_1056_gis: "เมือง",
  security_1057_gis: "กรุณาเลือกเมือง",
  security_1058_gis: "park",
  security_1059_gis: "อาคาร",
  security_1060_gis: "กรุณาเลือกอาคาร",
  security_1061_gis: "ชั้น",
  security_1062_gis: "กรุณาเลือกชั้น",
  security_1063_gis: "พื้นที่ชั้น",
  security_1064_gis: "กรุณาเลือกพื้นที่ชั้น",
  security_1065_gis: "ตำแหน่งห้อง",
  security_1066_gis: "กรุณาเลือกตำแหน่งห้อง",
  security_1067_gis: "ตำแหน่ง",
  security_1068_gis: "จำเป็นต้องระบุ park",
  security_1069_gis: "จำเป็นต้องระบุชั้น",
  security_1070_gis: "หยุดเล่นชั่วคราว 1",
  security_1071_gis: "หยุดชั่วคราว 1",
  security_1072_gis: "ตัวจับเวลา",
  security_1073_gis: "หยุดเล่นชั่วคราว 3",
  security_1074_gis: "เล่น",
  security_1075_gis: "กลุ่มลาดตระเวนจะปฏิบัติงานในช่วงเวลาปกติ",
  security_1076_gis: "กำลังเล่นเป็นครั้งแรก",
  security_1077_gis: "กลุ่มลาดตระเวน",
  security_1078_gis: "กล้องถ่ายรูป",
  security_1079_gis: "ลาดตระเวน",
  security_1080_gis: "ภาพวิดีโอ",
  security_1081_gis: "ตัวจำลองการแจ้งเตือน",
  security_1082_gis: "วิดีโอที่เกี่ยวข้อง",
  security_1083_gis: "รายการอัปโหลด",
  security_1084_gis: "ดูวิดีโอที่เกี่ยวข้อง",
  security_1085_gis: "อัพโหลดได้แค่ 10",
  security_1086_gis: "รูปภาพภายใน",
  security_1087_gis: "ด้วย",
  security_1088_gis: "ฟอร์แมตวิดีโอ",
  security_1089_gis: "เสร็จสมบูรณ์",
  security_1090_gis: "ข้อมูลพื้นฐาน",
  security_1091_gis: "เวลาแจ้งเตือน",
  security_1092_gis: "สถานที่เตือน",
  security_1093_gis: "ข้อมูลที่เกี่ยวข้องกับการแจ้งเตือน",
  security_1094_gis: "ชื่อบริษัท",
  security_1095_gis: "ข้อมูลสถานที่",
  security_1096_gis: "เชื่อมโยงรูปภาพและวิดีโอ",
  security_1097_gis: "ถ่ายภาพขนาดใหญ่",
  security_1098_gis: "บุคคลที่ได้รับแจ้ง",
  security_1099_gis: "การแจ้งเตือนเหตุการณ์",
  security_1100_gis: "การจัดการการแจ้งเตือน",
  security_1101_gis: "กรุณาเลือกสถานะการประมวลผล",
  security_1102_gis: "หมายเหตุข้อมูล",
  security_1103_gis: "ส่งข้อมูลเรียบร้อยแล้ว",
  security_1104_gis: "การส่งล้มเหลว",
  security_1105_gis: "ส่งเรียบร้อยแล้ว",
  security_1106_gis: "การใช้พลังงานทั้งหมด",
  security_1107_gis: "ไฟฟ้า",
  security_1108_gis: "การใช้พลังงาน",
  security_1109_gis: "ไฟฟ้าอื่น ๆ",
  security_1110_gis: "การใช้พลังงานทั้งหมดในแต่ละวัน",
  security_1111_gis:
    "เกิดข้อผิดพลาดในอุปกรณ์ที่คุณเลือกในขณะนี้ กรุณาจัดเตรียมให้มีคนจัดการก่อน!",
  security_1112_gis:
    "หน้าจอขนาดใหญ่ที่คุณเลือกในขณะนี้ออฟไลน์ และไม่สามารถเข้าถึงคำสั่งได้!",
  security_1113_gis: "ตอนนี้ยังไม่มีโปรแกรม กรุณาตั้งโปรแกรมก่อน!",
  security_1114_gis: "ไม่สามารถแก้ไขได้",
  security_1115_gis: "กำจัดการแจ้งเตือนสำเร็จ",
  security_1116_gis: "คำเตือนสิ่งอำนวยความสะดวกการถ่ายโอนสำเร็จ",
  security_1117_gis: "คำเตือนสถานที่ถ่ายโอนล้มเหลว",
  security_1118_gis: "กรุณาเลือกตัวผลักดัน",
  security_1119_gis: "ส่งงาน",
  security_1120_gis: "แผนฉุกเฉิน",
  security_1121_gis: "การติดตามวิถี",
  security_1122_gis: "เหตุการณ์ในอดีต",
  security_1123_gis: "อื่น ๆ",
  security_1124_gis: "ไม่มีกิจกรรมนี้หรือถูกลบไปแล้ว",
  security_1125_gis: "เหตุผลในการปรับใช้",
  security_1126_gis: "กรุณากรอกเหตุผลในการทำให้ใช้งานได้ไม่เกิน 150 อักขระ",
  security_1127_gis: "ผู้ใช้ที่ลงทะเบียน",
  security_1128_gis: "ระยะเวลา",
  security_1129_gis: "รูป",
  security_1130_gis: "ไม่ว่าจะดันหน้าต่างป๊อปอัป",
  security_1131_gis: "กรุณากรอกประกาศทรัพย์สิน",
  security_1132_gis: "ชื่อ",
  security_1133_gis: "เบอร์โทรศัพท์",
  security_1134_gis: "การอัพโหลดล้มเหลว!",
  security_1135_gis: "เหตุผลในการทำให้ใช้งานได้ต้องไม่เกิน 150 อักขระ",
  security_1136_gis: "การแจ้งเตือน",
  security_1137_gis: "ชื่อแผน",
  security_1138_gis: "กรุณากรอกชื่อแผน",
  security_1139_gis: "กรุณากรอกข้อมูล",
  security_1140_gis: "กรุณากรอกคะแนน",
  security_1141_gis: "กรุณาเลือกวิธีการทำซ้ำ",
  security_1142_gis: "เพิ่มการแจ้งเตือน",
  security_1143_gis: "กรุณากรอกหมายเลขโทรศัพท์เพื่อค้นหารายการที่ตรงกัน",
  security_1144_gis: "กรุณาเลือกการแจ้งเตือน",
  security_1145_gis: "เพิ่มเส้นทางแล้ว",
  security_1146_gis: "กรุณาเพิ่มเส้นทาง",
  security_1147_gis: "ลบ",
  security_1148_gis: "รายการเส้นทาง",
  security_1149_gis: "เส้นทางใหม่",
  security_1150_gis: "เพิ่มแล้ว",
  security_1151_gis: "ในวันจันทร์",
  security_1152_gis: "ในวันอังคาร",
  security_1153_gis: "ในวันพุธ",
  security_1154_gis: "วันพฤหัสบดี",
  security_1155_gis: "วันศุกร์",
  security_1156_gis: "วันเสาร์",
  security_1157_gis: "วันอาทิตย์",
  security_1158_gis: "รายละเอียดแผน",
  security_1159_gis: "แผนใหม่",
  security_1160_gis: "ปรับเปลี่ยนแผน",
  security_1161_gis: "แก้ไขแผนลาดตระเวนเรียบร้อยแล้ว",
  security_1162_gis: "แผนการลาดตระเวนใหม่ที่ประสบความสำเร็จ",
  security_1163_gis: "จำเป็นต้องระบุเวลา หรือเวลาต้องไม่เป็น 0",
  security_1164_gis: "เวลาต้องเป็นตัวเลข",
  security_1165_gis: "คำเตือนที่สำคัญ",
  security_1166_gis: "แขวน",
  security_1167_gis: "ไม่มีข้อมูลอุปกรณ์ที่เกี่ยวข้องใน park",
  security_1168_gis: "ไม่มีข้อมูลเจ้าหน้าที่รักษาความปลอดภัยใน park",
  security_1169_gis: "ถูกระงับ",
  security_1170_gis: "แจ้งเหตุการณ์",
  security_1171_gis: "อัตราเวลาในการประมวลผล",
  security_1172_gis: "ชื่อเหตุการณ์",
  security_1173_gis: "รายงานเหตุการณ์",
  security_1174_gis: "หมายเลขเหตุการณ์",
  security_1175_gis: "จัดจำหน่าย",
  security_1176_gis: "การจัดส่งใบสั่งงาน",
  security_1177_gis: "สถานะตั๋ว",
  security_1178_gis: "วางแผนเวลาเริ่มต้น",
  security_1179_gis: "กรุณาเลือกเวลาเริ่มต้นที่วางแผนไว้",
  security_1180_gis: "เวลาสิ้นสุดของแผน",
  security_1181_gis: "กรุณาเลือกเวลาสิ้นสุดของแผน",
  security_1182_gis: "ตำแหน่งการเตือนภัย",

  security_1183_gis: "รายละเอียดการสั่งซื้อ",
  security_1184_gis: "เวลาเริ่มต้นจริง",
  security_1185_gis: "เวลาสิ้นสุดจริง",
  security_1186_gis: "เวลาในการประมวลผลใบสั่งงาน",
  security_1187_gis: "อุปกรณ์ที่เกี่ยวข้อง",
  security_1188_gis: "คำอธิบายใบสั่งงาน",
  security_1189_gis: "คำอธิบายข้อบกพร่อง",
  security_1190_gis: "รายละเอียดระบบที่เกี่ยวข้อง",
  security_1191_gis: "หมายเลขคำสั่งซื้อที่เกี่ยวข้อง",
  security_1192_gis: "คำอธิบายที่สมบูรณ์",
  security_1193_gis: "รายละเอียดรูปภาพ",
  security_1194_gis: "ชื่อเหตุการณ์",
  security_1195_gis: "โปรดระบุชื่อ",
  security_1196_gis: "เหตุการณ์เลขที่",
  security_1197_gis: "โปรดเลือกสถานะเหตุการณ์",
  security_1198_gis: "เลขที่ใบสั่งงาน",
  security_1199_gis: "กรุณากรอกหมายเลขตั๋ว",
  security_1200_gis: "เวลาจำหน่าย",
  security_1201_gis: "โปรดเลือกเวลาจัดส่ง",
  security_1202_gis: "เจ้าของใบสั่งงาน",
  security_1203_gis: "กรุณาใส่ชื่อผู้รับผิดชอบตั๋ว",
  security_1204_gis: "ผู้รับผิดชอบ",
  security_1205_gis: "ใบสั่งงานทางธุรกิจ",
  security_1206_gis: "ใบสั่งงานสถานที่",
  security_1207_gis: "รายงานใบสั่งงาน",
  security_1208_gis: "ชื่อนาฬิกาปลุก",
  security_1209_gis: "ระดับสิ่งอำนวยความสะดวก",
  security_1210_gis: "ระบบสิ่งอำนวยความสะดวก",
  security_1211_gis: "ระบบย่อย",
  security_1212_gis: "รายละเอียดกิจกรรม",
  security_1213_gis: "เหตุการณ์ที่/ลำดับการแจ้งเตือน",
  security_1214_gis: "ประเภทเหตุการณ์/ประเภทรายงาน",
  security_1215_gis: "เวลาการแจ้งเตือน/เวลารายงาน",
  security_1216_gis: "ผู้ให้บริการ",
  security_1217_gis: "คลาสย่อย",
  security_1218_gis: "ใบสั่งงาน",
  security_1219_gis:
    "คุณไม่สามารถส่งออกข้อมูลมากกว่า 20,000 ข้อความได้โปรดพิมพ์เงื่อนไขก่อน",
  security_1220_gis: "โปรดป้อนชื่อเรื่องของจดหมาย",
  security_1221_gis: "กรุณาพิมพ์หมายเลขโทรศัพท์",
  security_1222_gis: "รายงานสถานะ",
  security_1223_gis: "กรุณาพิมพ์สถานะ",
  security_1224_gis: "ระดับความสำคัญของงาน",
  security_1225_gis: "โปรดเลือกระดับของจดหมาย",
  security_1226_gis: "ประเภทรายงาน",
  security_1227_gis: "โปรดเลือกประเภทของรายงาน",
  security_1228_gis: "คำอธิบาย",
  security_1229_gis: "เวลาแจ้งข่าว",
  security_1230_gis: "โปรดป้อนหมายเลขเหตุการณ์",
  security_1231_gis: "เหตุการณ์นั้น ยังไม่มีรายงาน",
  security_1232_gis: "เหตุการณ์ ที่เกิดขึ้น",
  security_1233_gis: "ความคล่องตัวของผู้โดยสารการแจ้งเตือน",
  security_1234_gis: "ออนไลน์",
  security_1235_gis: "ผิดปกติ",
  security_1236_gis: "เลือกวันที่และเวลา",
  security_1237_gis: "ประเภทเหตุการณ์",
  security_1238_gis: "กรุณาเลือกประเภทเหตุการณ์",
  security_1239_gis: "โปรดเลือกเวลาเริ่มต้น",
  security_1240_gis: "โปรดเลือกเวลาสิ้นสุด",
  security_1241_gis: "ส่งใบสั่งงานเรียบร้อยแล้ว",
  security_1242_gis: "MQDCappงาน",
  security_1243_gis: "กรุณาใส่ชื่อ และเบอร์มือถือ",
  security_1244_gis: "โปรดป้อนชื่อเหตุการณ์ ที่เกิดขึ้น",
  security_1245_gis: "โปรดป้อนตำแหน่ง ที่เกิดขึ้น",
  security_1246_gis: "กรุณาใส่กระบวนการ",
  security_1247_gis: "กรุณาเลือกวันที่",
  security_1248_gis: "ปีที่แล้ว",
  i18nKey_cockpit_1: "หมื่น",
  i18nKey_cockpit_2: "พันล้าน",
  i18nKey_cockpit_3: "สิบล้าน",
  i18nKey_cockpit_4: "ล้าน",
  i18nKey_cockpit_5: "นาที",
  i18nKey_cockpit_6: "นาที",
  i18nKey_cockpit_7: "วัน",
  i18nKey_cockpit_8: "เวลา",
  i18nKey_cockpit_9: "ชั่วโมง",
  i18nKey_cockpit_10: "ในสัปดาห์นี้",
  i18nKey_cockpit_11: "เกือบสามเดือน",
  i18nKey_cockpit_12: "เดือนนี้",
  i18nKey_cockpit_13: "ยืนยัน",
  i18nKey_cockpit_14: "ในการปรับเปลี่ยน",
  i18nKey_cockpit_15: "เคล็ดลับ",
  i18nKey_cockpit_16: "งาน",
  i18nKey_cockpit_17: "ยกเลิก",
  i18nKey_cockpit_18: "ทั้งหมด",
  i18nKey_cockpit_19: "ชนิดของ",
  i18nKey_cockpit_20: "แผนที่",
  i18nKey_cockpit_21: "การกำหนดค่าเลเยอร์",
  i18nKey_cockpit_22: "ชั้น",
  i18nKey_cockpit_23: "การทำงานของแผนที่",
  i18nKey_cockpit_24: "การกำหนดค่าทั่วไป",
  i18nKey_cockpit_25: "บันทึก",
  i18nKey_cockpit_26: "การกำหนดค่าสำเร็จ",
  i18nKey_cockpit_27: "การกำหนดค่าล้มเหลว",
  i18nKey_cockpit_28: "คุณยังไม่ได้ทำการเปลี่ยนแปลงใด ๆ",
  i18nKey_cockpit_29: "เร่งด่วน",
  i18nKey_cockpit_30: "สถานการณ์จริง",
  i18nKey_cockpit_31: "รายละเอียด",
  i18nKey_cockpit_32: "ดูรายละเอียด",
  i18nKey_cockpit_33: "ปิดประตู",
  i18nKey_cockpit_34: "เวลาการไหล",
  i18nKey_cockpit_35: "การกำหนดค่า",
  i18nKey_cockpit_36: "ติดตั้ง",
  i18nKey_cockpit_37: "เต็มจอ",
  i18nKey_cockpit_38: "วันที่มีแดด",
  i18nKey_cockpit_39: "วันที่มีแดด",
  i18nKey_cockpit_40: "ฝน",
  i18nKey_cockpit_41: "หมอก",
  i18nKey_cockpit_42: "หิมะ",
  i18nKey_cockpit_43: "ปริมาณน้ำฝน",
  i18nKey_cockpit_44: "มีหมอกหนา",
  i18nKey_cockpit_45: "หิมะตก",
  i18nKey_cockpit_46: "สถานการณ์โดยรวม -2",
  i18nKey_cockpit_47: "เวอร์ชัน",
  i18nKey_cockpit_48: "สถานการณ์โดยรวม",
  i18nKey_cockpit_49: "ท่าทางการรักษาความปลอดภัย",
  i18nKey_cockpit_50: "สถิติการลาดตระเวนแบบวิดีโอ",
  i18nKey_cockpit_51: "คำเตือนการลาดตระเวน",
  i18nKey_cockpit_52: "สถานะการเตือนความปลอดภัย",
  i18nKey_cockpit_53: "สถิติการเตือนอุปกรณ์",
  i18nKey_cockpit_54: "สถิติการเตือนภัย",
  i18nKey_cockpit_55: "แนวโน้มการแจ้งเตือนด้านความปลอดภัย",
  i18nKey_cockpit_56: "แนวโน้มใบสั่งงานด้านความปลอดภัย",
  i18nKey_cockpit_57: "สถิติอุปกรณ์",
  i18nKey_cockpit_58: "ศูนย์เหตุการณ์",
  i18nKey_cockpit_59: "แนวโน้มการใช้พลังงาน",
  i18nKey_cockpit_60: "รายละเอียดไฟฟ้า",
  i18nKey_cockpit_61: "สถิติการใช้ไฟฟ้า",
  i18nKey_cockpit_62: "ประสิทธิภาพ",
  i18nKey_cockpit_63: "การวินิจฉัย",
  i18nKey_cockpit_64: "พลังงานแบบระบุรายการ",
  i18nKey_cockpit_65: "สัญญาณเตือนการใช้พลังงาน",
  i18nKey_cockpit_66: "การเฝ้าระวังการใช้พลังงาน",
  i18nKey_cockpit_67: "การเฝ้าระวังสิ่งแวดล้อม",
  i18nKey_cockpit_68: "การจัดอันดับพลังงานของอุปกรณ์",
  i18nKey_cockpit_69: "การจัดอันดับการใช้พลังงานในภูมิภาคต่อตารางเมตร",
  i18nKey_cockpit_70: "คู่ค้า",
  i18nKey_cockpit_71: "การจัดอันดับการใช้พลังงานของผู้เช่าต่อตารางเมตร",
  i18nKey_cockpit_72: "ไฟฟ้าพลังน้ำ",
  i18nKey_cockpit_73: "สถานการณ์ของสิ่งอำนวยความสะดวก",
  i18nKey_cockpit_74: "สถิติความล้มเหลว",
  i18nKey_cockpit_75: "สถิติสถานะเหตุการณ์ของสิ่งอำนวยความสะดวก",
  i18nKey_cockpit_76: "งานสิ่งอำนวยความสะดวก",
  i18nKey_cockpit_77: "สถานการณ์ที่เหนือกว่า",
  i18nKey_cockpit_78: "โปรไฟล์การจราจรของบุคลากร",
  i18nKey_cockpit_79: "สถิติการเข้าชมของพนักงาน",
  i18nKey_cockpit_80: "สถิติการเข้าชมของผู้เยี่ยมชม",
  i18nKey_cockpit_81: "สถิติการเข้าชม",
  i18nKey_cockpit_82: "สถิติการเข้าชม",
  i18nKey_cockpit_83: "สถิติการจอดรถ",
  i18nKey_cockpit_84: "ค่าจอดรถชั่วคราว",
  i18nKey_cockpit_85: "เวลาจอดรถ",
  i18nKey_cockpit_86: "ธุรกิจอัจฉริยะ",
  i18nKey_cockpit_87: "เพื่อแนวโน้มกระแสผู้เยี่ยมชม",
  i18nKey_cockpit_88: "ถิ่นที่อยู่",
  i18nKey_cockpit_89: "พื้นที่ยอดนิยม",
  i18nKey_cockpit_90: "สถิติเหตุการณ์ทางธุรกิจ",
  i18nKey_cockpit_91: "ความคล่องตัวของผู้โดยสาร",
  i18nKey_cockpit_92: "จำนวนคนในสถานที่",
  i18nKey_cockpit_93: "อายุ เพศ",
  i18nKey_cockpit_94: "ลูกค้าซ้ำ",
  i18nKey_cockpit_95: "สถิติความคล่องตัวของผู้โดยสารในช่วงเทศกาล",
  i18nKey_cockpit_96: "ไม่พบในส่วนประกอบ",
  i18nKey_cockpit_97: "วิธี",
  i18nKey_cockpit_98: "สถานการณ์ที่ครอบคลุม",
  i18nKey_cockpit_99: "รายงานการดำเนินงาน",
  i18nKey_cockpit_100: "ข้อผิดพลาดของพารามิเตอร์",
  i18nKey_cockpit_101: "ควรจะเป็น",
  i18nKey_cockpit_102: "ที่จริง",
  i18nKey_cockpit_103: "ไม่มีข้อมูลที่มีสิทธิ์ชั่วคราว",
  i18nKey_cockpit_104: "ซูม",
  i18nKey_cockpit_105: "ซูมล้อเมาส์",
  i18nKey_cockpit_106: "มือถือ",
  i18nKey_cockpit_107: "หมุน",
  i18nKey_cockpit_108: "หมุนแผนที่ด้วยปุ่มซ้ายของเมาส์",
  i18nKey_cockpit_109: "คลิกขวา เพื่อย้ายแผนที่",
  i18nKey_cockpit_110: "ปุ่มเมาส์ขวา เพื่อหมุนแผนที่",
  i18nKey_cockpit_111: "คลิกซ้าย เพื่อย้ายแผนที่",
  i18nKey_cockpit_112: "รูปภาพที่เป็นรูปแบบ",
  i18nKey_cockpit_113: "ภาพสถานการณ์โดยรวม:",
  i18nKey_cockpit_114: "ภาพสถานการณ์สินค้า:",
  i18nKey_cockpit_115: "ขนาดภาพต้องไม่เกิน 20",
  i18nKey_cockpit_116: "การอัปโหลดล้มเหลว",
  i18nKey_cockpit_117: "อัปโหลดได้เท่านั้น",
  i18nKey_cockpit_118: "จัดรูปแบบรูปภาพ",
  i18nKey_cockpit_119: "การอัปโหลดล้มเหลว!",
  i18nKey_cockpit_120: "กลับ",
  i18nKey_cockpit_121: "สลับสถานการณ์",
  i18nKey_cockpit_122: "โรมมิ่งอัตโนมัติ",
  i18nKey_cockpit_123: "โรมมิ่งต่อไป",
  i18nKey_cockpit_124: "หยุด",
  i18nKey_cockpit_125: "ออกจากการเล่น",
  i18nKey_cockpit_126: "การตั้งค่าแผนภูมิ",
  i18nKey_cockpit_127: "วัน",
  i18nKey_cockpit_128: "ตอนกลางคืน",
  i18nKey_cockpit_129: "โหมดแมนนวล",
  i18nKey_cockpit_130: "โหมดอัตโนมัติ",
  i18nKey_cockpit_131: "เลือกแผนภูมิ",
  i18nKey_cockpit_132: "กรุณาเลือกไม่เกิน",
  i18nKey_cockpit_133: "บัตร",
  i18nKey_cockpit_134: "กรุณาเลือกไม่น้อยกว่า",
  i18nKey_cockpit_135: "ชุดแผนภูมิเรียบร้อยแล้ว",
  i18nKey_cockpit_136: "เลือกสถานการณ์",
  i18nKey_cockpit_137: "ถนนบินเหอ",
  i18nKey_cockpit_138: "ถนนหยวนเหอ",
  i18nKey_cockpit_139: "หย่งชิงฟาง",
  i18nKey_cockpit_140: "ประหยัดพลังงาน",
  i18nKey_cockpit_141: "สถิติมิเตอร์",
  i18nKey_cockpit_142: "จำนวนสิ่งอำนวยความสะดวก",
  i18nKey_cockpit_143: "",
  i18nKey_cockpit_144: "อัตรามิเตอร์ออนไลน์",
  i18nKey_cockpit_145: "ศักยภาพในการประหยัดพลังงานในเดือนนี้",
  i18nKey_cockpit_146: "สิ่งอำนวยความสะดวกที่ผิดปกติ",
  i18nKey_cockpit_147: "สถิติของสิ่งอำนวยความสะดวก",
  i18nKey_cockpit_148: "อัตราออนไลน์ของสิ่งอำนวยความสะดวก",
  i18nKey_cockpit_149: "สิ่งอำนวยความสะดวกที่ผิดปกติในเดือนนี้",
  i18nKey_cockpit_150: "ชิ้น",
  i18nKey_cockpit_151: "สำคัญ",
  i18nKey_cockpit_152: "การจัดการข้อยกเว้นของสิ่งอำนวยความสะดวกในเดือนนี้",
  i18nKey_cockpit_153: "อัตราการประมวลผล",
  i18nKey_cockpit_154: "เวลาในการประมวลผลเฉลี่ย",
  i18nKey_cockpit_155: "การจัดการทรัพย์สิน",
  i18nKey_cockpit_156:
    "หมายเหตุ: ระบบจะรีเฟรชข้อมูลใน 1 ชั่วโมงและรีเฟรชการรับส่งข้อมูลภายใน 10 นาที",
  i18nKey_cockpit_157: "ความปลอดภัยที่ครอบคลุม",
  i18nKey_cockpit_158: "เข้าถึงได้ง่าย",
  i18nKey_cockpit_159: "การจัดการสิ่งอำนวยความสะดวก",
  i18nKey_cockpit_160: "รองรับบริการ",
  i18nKey_cockpit_161: "เหตุการณ์ที่แจ้งเตือน",
  i18nKey_cockpit_162: "ลาดตระเวนแบบวิดีโอ",
  i18nKey_cockpit_163: "บัญชีดำ",
  i18nKey_cockpit_164: "คำเตือนเหตุการณ์ในสัปดาห์นี้",
  i18nKey_cockpit_165: "ผลรวมทั้งสิ้น",
  i18nKey_cockpit_166: "การประมวลผลเหตุการณ์การแจ้งเตือนในสัปดาห์นี้",
  i18nKey_cockpit_167: "ประมวลผล",
  i18nKey_cockpit_168: "การลาดตระเวนแบบวิดีโอในสัปดาห์นี้",
  i18nKey_cockpit_169: "ครั้ง",
  i18nKey_cockpit_170: "อัตราเวลา",
  i18nKey_cockpit_171: "จำนวนเหตุการณ์ที่ขึ้นบัญชีดำในสัปดาห์นี้",
  i18nKey_cockpit_172: "ห้องประชุมที่ใช้งานร่วมกัน",
  i18nKey_cockpit_173: "จอดรถโดยสาร",
  i18nKey_cockpit_174: "คำสั่งจอด",
  i18nKey_cockpit_175: "จองห้องประชุมเดือนนี้",
  i18nKey_cockpit_176: "รายได้",
  i18nKey_cockpit_177: "หยวน",
  i18nKey_cockpit_178: "ห้องประชุมใช้เดือนนี้",
  i18nKey_cockpit_179: "ใช้เวลา",
  i18nKey_cockpit_180: "อัตราการใช้งาน",
  i18nKey_cockpit_181: "บริษัทบริการห้องประชุม",
  i18nKey_cockpit_182: "บ้าน",
  i18nKey_cockpit_183: "สั่งอาหารใน park เดือนนี้",
  i18nKey_cockpit_184: "จำนวนคน",
  i18nKey_cockpit_185: "นั่งรถโดยสารในเดือนนี้",
  i18nKey_cockpit_186: "สำนักงานการผลิต",
  i18nKey_cockpit_187: "ครั้งของบุคคล",
  i18nKey_cockpit_188: "Port Apartment",
  i18nKey_cockpit_189: "อัตราการใช้รถโดยสารในเดือนนี้",
  i18nKey_cockpit_190: "สถิติความคล่องตัวของผู้คน",
  i18nKey_cockpit_191: "การควบคุมการเข้าถึง",
  i18nKey_cockpit_192: "การจราจรในห้างสรรพสินค้า",
  i18nKey_cockpit_193: "ผู้คนหลั่งไหลมาที่ park ในวันนี้",
  i18nKey_cockpit_194: "คน",
  i18nKey_cockpit_195: "จ่ายค่าจอดรถวันนี้",
  i18nKey_cockpit_196: "หยุด",
  i18nKey_cockpit_197: "บัตรแพ็คเกจ",
  i18nKey_cockpit_198: "ที่จอดรถวันนี้",
  i18nKey_cockpit_199: "ยานพาหนะ",
  i18nKey_cockpit_200: "หมายเลขรถไฟ",
  i18nKey_cockpit_201: "อัตราการใช้ที่จอดรถในสัปดาห์นี้",
  i18nKey_cockpit_202: "บัตรรายเดือน",
  i18nKey_cockpit_203: "park",
  i18nKey_cockpit_204: "สถิติการแจ้งเตือน",
  i18nKey_cockpit_205: "การแจ้งเตือนฉุกเฉิน",
  i18nKey_cockpit_206: "คำเตือนที่สำคัญ",
  i18nKey_cockpit_207: "คำเตือนทั่วไป",
  i18nKey_cockpit_208: "จำนวนคำสั่งงานทั้งหมด",
  i18nKey_cockpit_209: "ที่จะได้รับ",
  i18nKey_cockpit_210: "ได้รับ",
  i18nKey_cockpit_211: "เหตุการณ์ทางธุรกิจ",
  i18nKey_cockpit_212: "ป้อนชื่อการแจ้งเตือน",
  i18nKey_cockpit_213: "เกรด",
  i18nKey_cockpit_214: "สถานะ",
  i18nKey_cockpit_215: "ดูเพิ่มเติม",
  i18nKey_cockpit_216: "เหตุการณ์ของสิ่งอำนวยความสะดวก",
  i18nKey_cockpit_217: "ทั่วไป",
  i18nKey_cockpit_218: "ภาพเรียลไทม์",
  i18nKey_cockpit_219: "ถึง",
  i18nKey_cockpit_220: "วันที่เริ่มต้น",
  i18nKey_cockpit_221: "วันที่สิ้นสุด",
  i18nKey_cockpit_222: "วัน",
  i18nKey_cockpit_223: "วันนี้",
  i18nKey_cockpit_224: "สัปดาห์",
  i18nKey_cockpit_225: "เดือน",
  i18nKey_cockpit_226: "ปี",
  i18nKey_cockpit_227: "ปีนี้",
  i18nKey_cockpit_228: "ปัจจุบัน",
  i18nKey_cockpit_229: "ดำเนินการ",
  i18nKey_cockpit_230: "ปริมาณรวม",
  i18nKey_cockpit_231: "การเปลี่ยนแปลงของเหตุการณ์",
  i18nKey_cockpit_232: "ไม่มีข้อมูล",
  i18nKey_cockpit_233: "เบราว์เซอร์ปัจจุบันไม่รองรับ",
  i18nKey_cockpit_234: "สแกนโค้ดเพื่อรายงาน",
  i18nKey_cockpit_235: "ที่จะดำเนินการ",
  i18nKey_cockpit_236: "ภูเขาไฟ",
  i18nKey_cockpit_237: "ความสูง 120",
  i18nKey_cockpit_240: "ชื่ออุปกรณ์",
  i18nKey_cockpit_241: "Commerce",
  i18nKey_cockpit_242: "ทางหนีไฟ",
  i18nKey_cockpit_243: "การประมวลผลใบสั่งงาน",
  i18nKey_cockpit_244: "ติดตามตอนนี้",
  i18nKey_cockpit_245: "ระดับ",
  i18nKey_cockpit_246: "สถิติของสิ่งอำนวยความสะดวกล้มเหลว",
  i18nKey_cockpit_247: "ไม่มีข้อมูลที่ตรงตามเงื่อนไขการสืบค้น",
  i18nKey_cockpit_248: "ชื่อสถานที่",
  i18nKey_cockpit_249: "จำนวนสิ่งอำนวยความสะดวก",
  i18nKey_cockpit_250: "จำนวนความล้มเหลวของสิ่งอำนวยความสะดวก",
  i18nKey_cockpit_251: "จำนวนสิ่งอำนวยความสะดวกที่ล้มเหลว",
  i18nKey_cockpit_252: "การเกิดขึ้นสะสม",
  i18nKey_cockpit_253: "การประมวลผลสะสม",
  i18nKey_cockpit_254: "ชิ้น",
  i18nKey_cockpit_255: "นาที",
  i18nKey_cockpit_256: "ภาพรวมของงานสิ่งอำนวยความสะดวก",
  i18nKey_cockpit_257: "กำลังประมวลผล",
  i18nKey_cockpit_258: "ปิด",
  i18nKey_cockpit_259: "ปริมาณการใช้ไฟฟ้าโดยเฉลี่ย",
  i18nKey_cockpit_260: "รวม",
  i18nKey_cockpit_261: "ภาพรวมไฟฟ้า",
  i18nKey_cockpit_262: "ไฟฟ้าทั้งหมด",
  i18nKey_cockpit_263: "กว่าเมื่อวาน",
  i18nKey_cockpit_264: "เมื่อวานนี้",
  i18nKey_cockpit_265: "เมื่อเทียบกับสัปดาห์ที่แล้ว",
  i18nKey_cockpit_266: "อาทิตย์ที่แล้ว",
  i18nKey_cockpit_267: "เมื่อเทียบกับเดือนที่แล้ว",
  i18nKey_cockpit_268: "เดือนที่แล้ว",
  i18nKey_cockpit_269: "การใช้พลังงานทั้งหมด",
  i18nKey_cockpit_270: "การใช้พลังงานสะสมตามรายการ",
  i18nKey_cockpit_271: "ผู้เช่าไฟฟ้า",
  i18nKey_cockpit_272: "ไฟฟ้าในพื้นที่สาธารณะ",
  i18nKey_cockpit_273: "ปริมาณการใช้ไฟฟ้าทั้งหมด",
  i18nKey_cockpit_274: "สถิติของใบสั่งงาน",
  i18nKey_cockpit_275: "เหตุการณ์การแจ้งเตือนการลาดตระเวน",
  i18nKey_cockpit_276: "อัตราการดำเนินการเสร็จสิ้น",
  i18nKey_cockpit_277: "อัตราเวลาในการประมวลผล",
  i18nKey_cockpit_278: "ภาพรวม",
  i18nKey_cockpit_279: "ถูกระงับ",
  i18nKey_cockpit_280: "ยกเลิก",
  i18nKey_cockpit_281: "แนวโน้มเหตุการณ์การแจ้งเตือน",
  i18nKey_cockpit_282: "สถิติการจำแนกประเภทการแจ้งเตือน",
  i18nKey_cockpit_283: "จำนวนครั้งที่เกิดขึ้น",
  i18nKey_cockpit_284: "ปริมาณการประมวลผล",
  i18nKey_cockpit_285: "เวลาในการประมวลผลเฉลี่ย (นาที)",
  i18nKey_cockpit_286: "ประเภทการแจ้งเตือน",
  i18nKey_cockpit_287: "แหล่งที่มาของการแจ้งเตือน",
  i18nKey_cockpit_288: "ระดับการแจ้งเตือน",
  i18nKey_cockpit_289: "เวลาประมวลผลเหตุการณ์บัญชีดำ",
  i18nKey_cockpit_290: "ใช้เวลาสั้นที่สุด",
  i18nKey_cockpit_291: "อันดับ",
  i18nKey_cockpit_292: "เหตุการณ์",
  i18nKey_cockpit_293: "ต้นทุนเวลา",
  i18nKey_cockpit_294: "ใช้เวลานานที่สุด",
  i18nKey_cockpit_295: "ภาพรวมของปัญหา",
  i18nKey_cockpit_296: "กล้อง",
  i18nKey_cockpit_297: "อัตราการเจาะวิดีโอ",
  i18nKey_cockpit_298: "เวลาดำเนินการทั้งหมด",
  i18nKey_cockpit_299: "เส้นทาง",
  i18nKey_cockpit_300: "",
  i18nKey_cockpit_301: "สถิติการขับขี่",
  i18nKey_cockpit_302: "รถบัสก่อน",
  i18nKey_cockpit_303: "รถบัสในช่วงดึก",
  i18nKey_cockpit_304: "ผู้ใช้บัตรแพ็คเกจ",
  i18nKey_cockpit_305: "คน)",
  i18nKey_cockpit_306: "ผู้ใช้บัตรแพคเกจ (คน)",
  i18nKey_cockpit_307: "องค์กรต่างประเทศ",
  i18nKey_cockpit_308: "ชื่อบริษัท",
  i18nKey_cockpit_309: "จำนวนผู้โดยสาร",
  i18nKey_cockpit_310: "ขายอาหาร",
  i18nKey_cockpit_311: "ชื่ออาหาร",
  i18nKey_cockpit_312: "จำนวนการสั่งซื้อ",
  i18nKey_cockpit_313: "ส่วนแบ่ง",
  i18nKey_cockpit_314: "การจองห้องประชุม",
  i18nKey_cockpit_315: "การจอง",
  i18nKey_cockpit_316: "ยกเลิกการสมัคร",
  i18nKey_cockpit_317: "ครั้ง",
  i18nKey_cockpit_318: "หยวน)",
  i18nKey_cockpit_319: "การใช้ห้องประชุม",
  i18nKey_cockpit_320: "ห้องประชุมทั้งหมด",
  i18nKey_cockpit_321: "รายได้จากการสั่งซื้อ",
  i18nKey_cockpit_322: "ฝ่ายขาย",
  i18nKey_cockpit_323: "",
  i18nKey_cockpit_324: "การจองขององค์กร",
  i18nKey_cockpit_325: "จำนวนการจอง",
  i18nKey_cockpit_326: "อัตราการเข้าพักของรถรับส่ง",
  i18nKey_cockpit_327: "การขายหน้าร้าน",
  i18nKey_cockpit_328: "ชื่อร้าน",
  i18nKey_cockpit_329: "สถิติการจราจรในการควบคุมการเข้าถึง",
  i18nKey_cockpit_330: "คน）",
  i18nKey_cockpit_331: "วิธีการควบคุมการเข้าถึง",
  i18nKey_cockpit_332: "ความนิยมในห้างสรรพสินค้า",
  i18nKey_cockpit_333: "การจราจรใน park",
  i18nKey_cockpit_334: "",
  i18nKey_cockpit_335: "",
  i18nKey_cockpit_336: "การเข้าถึงของพนักงาน",
  i18nKey_cockpit_337: "ความร้อน",
  i18nKey_cockpit_338: "ภาพรวมของการไหลของผู้โดยสาร",
  i18nKey_cockpit_339: "ความคล่องตัวของผู้โดยสารในวันธรรมดา",
  i18nKey_cockpit_340: "ความคล่องตัวของผู้โดยสารในช่วงสุดสัปดาห์",
  i18nKey_cockpit_341: "สถิติความคล่องตัวของผู้โดยสารในช่วงเวลา",
  i18nKey_cockpit_342: "ความคล่องตัวของผู้โดยสารวันนี้",
  i18nKey_cockpit_343: "จำนวนผู้โดยสารเฉลี่ยในเดือนที่ผ่านมา",
  i18nKey_cockpit_344: "ความร้อนในภูมิภาค",
  i18nKey_cockpit_345: "พื้นที่",
  i18nKey_cockpit_346: "ผู้คนหลั่งไหลเข้ามาใน park",
  i18nKey_cockpit_347: "หยุดเวลา",
  i18nKey_cockpit_348: "จำนวนเงินที่เรียกเก็บ",
  i18nKey_cockpit_349: "ความคล่องตัวของคนในห้องน้ำ",

  i18nKey_cockpit_350: "คำสั่งโครงการ",
  i18nKey_cockpit_351: "สถิติการใช้น้ำ",
  i18nKey_cockpit_352: "สถิติการทำความเย็น",
  i18nKey_cockpit_353: "คำสั่งงานโครงการ",
  i18nKey_cockpit_354: "จำนวนสั่งงานทั้งหมด (ชิ้น)",
  i18nKey_cockpit_355: "สถานะกระบวนการงานโครงการ",
  i18nKey_cockpit_356: "เรียบร้อย",
  i18nKey_cockpit_357: "อัตราการดำเนินการตามกำหนดเวลาของงานโครงการ",
  i18nKey_cockpit_358: "อัตราเวลาการยอมรับ",
  i18nKey_cockpit_359: "อัตราเวลาในการเสร็จ",
  i18nKey_cockpit_360: "สถิติการหมดเวลาของคำสั่ง",
  i18nKey_cockpit_361: "ยังไม่เสร็จ (หมดเวลา)",
  i18nKey_cockpit_362: "ไม่ยอมรับ (หมดเวลา)",
  i18nKey_cockpit_363: "สถิติการจัดหมวดหมู่คำสั่ง",
  i18nKey_cockpit_364: "ใหม่ (คำสั่ง)",
  i18nKey_cockpit_365: "เสร็จสิ้น (หมดเวลา)",
  i18nKey_cockpit_366: "ยอมรับแล้ว (หมดเวลา)",
  i18nKey_cockpit_367: "ประเภทคำสั่ง",
  i18nKey_cockpit_368: "เกินขีดจำกัดการส่งออกสูงสุด 2,000",
  i18nKey_cockpit_369: "เนื้อหาที่ส่งออกเป็นค่าว่าง",
  i18nKey_cockpit_370: "ส่งออกไม่สำเร็จ",
  i18nKey_cockpit_371: "จำนวนคำสั่ง",
  i18nKey_cockpit_372: "เทรนด์คำสั่ง",
  i18nKey_cockpit_373: "รายละเอียดการจัดประเภทคำสั่ง",
  i18nKey_cockpit_374: "โปรดเลือก",
  i18nKey_cockpit_375: "รายงานเหตุผล",
  i18nKey_cockpit_376: "ที่ตั้ง",
  i18nKey_cockpit_377: "กระจายเวลา",
  i18nKey_cockpit_378: "ผู้ดำเนินการ",
  i18nKey_cockpit_379: "ส่งออก",
  i18nKey_cockpit_380: "ระยะเวลาการส่งออก",
  i18nKey_cockpit_381: "โปรดเลือกช่วงเวลาที่จะส่งออก",
  i18nKey_cockpit_382: "เลขที่ใบสั่งซื้อ",
  i18nKey_cockpit_383: "โปรดเลือกเวลาที่เกิด",
  i18nKey_cockpit_384: "ไม่มีข้อมูลที่จะส่งออกในช่วงเวลานั้น",
  i18nKey_cockpit_385: "ทันเหตุการณ์",
  i18nKey_cockpit_386: "ปริมาณความเย็นรวม",
  i18nKey_cockpit_387: "ผู้เช่าใช้ปริมาณความเย็น",
  i18nKey_cockpit_389: "เจ้าหน้าที่",
  i18nKey_cockpit_390: "สินทรัพย์",
  i18nKey_cockpit_391: "ความปลอดภัย",
  i18nKey_cockpit_392: "เสียง",
  i18nKey_cockpit_393: "เครื่องปรับอากาศไฟฟ้า",
  i18nKey_cockpit_394: "การใช้พลังงาน",
  i18nKey_cockpit_395: "แสง และซ็อกเก็ต",
  i18nKey_cockpit_396: "ใช้งานในเชิงพาณิชย์",
  i18nKey_cockpit_397: "เตือนภัย",
  i18nKey_cockpit_398: "เตือนภัยการจราจร",
  i18nKey_cockpit_399: "สัญญาณเตือนไฟไหม้",
  i18nKey_components_1: "หมื่น",
  i18nKey_components_2: "สวัสดี",
  i18nKey_components_3: "โลก",
  i18nKey_components_4: "จำนวนกล้องออนไลน์",
  i18nKey_components_5: "ป้อนชื่อแฝงของกล้อง",
  i18nKey_components_6: "เลือกกล้อง",
  i18nKey_components_7: "กำหนด",
  i18nKey_components_8: "ชั้น",
  i18nKey_components_9: "เลเยอร์อื่น",
  i18nKey_components_10: "โทรศัพท์",
  i18nKey_components_11: "ข้อความ",
  i18nKey_components_12: "ดูกล้อง",
  i18nKey_components_13: "เคล็ดลับ",
  i18nKey_components_14: "เวลาเริ่มต้น",
  i18nKey_components_15: "เวลาสิ้นสุด",
  i18nKey_components_16: "เลือกวันที่และเวลาเริ่มต้น",
  i18nKey_components_17: "เลือกวันที่และเวลาสิ้นสุด",
  i18nKey_components_18: "การจับภาพวิดีโอ",
  i18nKey_components_19: "เวลาสิ้นสุดต้องมากกว่าเวลาเริ่มต้น",
  i18nKey_components_20: "submit",
  i18nKey_components_21: "ยกเลิก",
  i18nKey_components_22: "วิดีโอแบบเรียลไทม์",
  i18nKey_components_23: "วิดีโอย้อนหลัง",
  i18nKey_components_24: "การเล่นวิดีโอ",
  i18nKey_components_25: "เล่นซ้ำ",
  i18nKey_components_26: "หยุด",
  i18nKey_components_27: "กรุณาเลือกเวลา",
  i18nKey_components_28: "ระยะเวลาไม่ควรน้อยกว่า 30 วินาที",
  i18nKey_components_29: "ห้ามเกินหนึ่งชั่วโมง",
  i18nKey_components_30: "ยับยั้งสำเร็จ",
  i18nKey_components_31:
    "วิดีโอถูกยับยั้งสำเร็จแล้ว กรุณาไปที่วิดีโอที่เกี่ยวข้องในรายละเอียดเหตุการณ์ทางด้านซ้ายเพื่อดู",
  i18nKey_components_32: "การจับภาพวิดีโอล้มเหลว",
  i18nKey_components_33: "Microsoft Accor black",
  i18nKey_components_34: "กล้องวิดีโอ",
  i18nKey_components_35: "เวลา",
  i18nKey_components_36: "วัน",
  i18nKey_components_37: "สัปดาห์",
  i18nKey_components_38: "เดือน",
  i18nKey_components_39: "ปี",
  i18nKey_components_40: "อายุ",
  i18nKey_components_41: "สถิติอุปกรณ์",
  i18nKey_components_42: "สถิติเวลา",
  i18nKey_components_43: "จำนวนอุปกรณ์ทั้งหมด",
  i18nKey_components_44: "อัตราอุปกรณ์ออนไลน์",
  i18nKey_components_45: "ช่อง",
  i18nKey_components_46: "สถิติการแจ้งเตือนอุปกรณ์",
  i18nKey_components_47: "จำนวนการแจ้งเตือนทั้งหมด",
  i18nKey_components_48: "ไม่ได้รับการดูแลรักษา",
  i18nKey_components_49: "อัตราการปิด",
  i18nKey_components_50: "เร่งด่วน",
  i18nKey_components_51: "จริงจัง",
  i18nKey_components_52: "ทั่วไป",
  i18nKey_components_53: "พื้นที่ยอดนิยม",
  i18nKey_components_54: "ความคล่องตัวของผู้โดยสาร",
  i18nKey_components_55: "จำนวนคนในสถานที่",
  i18nKey_components_56: "จำนวนคนเข้า",
  i18nKey_components_57: "จำนวนคนออก",
  i18nKey_components_58: "จำนวนคนปัจจุบัน",
  i18nKey_components_59: "คน",
  i18nKey_components_60: "คำเตือนการลาดตระเวน",
  i18nKey_components_61: "การเกิดขึ้นสะสม",
  i18nKey_components_62: "การประมวลผลสะสม",
  i18nKey_components_63: "Pcs",
  i18nKey_components_64: "หน่วย",
  i18nKey_components_65: "สถิติการใช้พลังงาน",
  i18nKey_components_66: "",
  i18nKey_components_67: "park",
  i18nKey_components_68: "",
  i18nKey_components_69: "สถิติการแจ้งเตือนด้านความปลอดภัย",
  i18nKey_components_70: "",
  i18nKey_components_71: "คำเตือนที่สำคัญ",
  i18nKey_components_72: "นาฬิกาปลุกธรรมดา",
  i18nKey_components_73: "การแจ้งเตือนฉุกเฉิน",
  i18nKey_components_74: "สถานะการแจ้งเตือนด้านความปลอดภัย",
  i18nKey_components_75: "ที่จะดำเนินการ",
  i18nKey_components_76: "กำลังประมวลผล",
  i18nKey_components_77: "ถูกระงับ",
  i18nKey_components_78: "ปิด",
  i18nKey_components_79: "ยกเลิก",
  i18nKey_components_80: "จำนวนเหตุการณ์ทั้งหมด",
  i18nKey_components_81: "แนวโน้มการแจ้งเตือนด้านความปลอดภัย",
  i18nKey_components_82: "จำนวนการแจ้งเตือน",
  i18nKey_components_83: "จำนวนการประมวลผลการแจ้งเตือน",
  i18nKey_components_84: "แนวโน้มใบสั่งงานด้านความปลอดภัย",
  i18nKey_components_85: "",
  i18nKey_components_86: "จำนวนครั้งที่เกิดขึ้น",
  i18nKey_components_87: "หมายเลขการประมวลผล",
  i18nKey_components_88: "สถิติการลาดตระเวนแบบวิดีโอ",
  i18nKey_components_89: "เวลาดำเนินการ",
  i18nKey_components_90: "ครั้ง",
  i18nKey_components_91: "อัตราการเล่นวิดีโอ",
  i18nKey_components_92: "เส้นทาง",
  i18nKey_components_93: "",
  i18nKey_components_94: "กล้อง",
  i18nKey_components_95: "",
  i18nKey_components_96: "เวลาดำเนินการทั้งหมด",
  i18nKey_components_97: "นาที",
  i18nKey_components_98: "งาน",
  i18nKey_components_99: "ย่อเล็กสุด",
  i18nKey_components_100: "ขยายใหญ่สุด",
  i18nKey_components_101: "กำลังบัฟเฟอร์วิดีโอ",
  i18nKey_components_102: "ไม่สามารถรับทรัพยากรวิดีโอได้",
  i18nKey_components_103: "ยกเลิกภาพหน้าจอ",
  i18nKey_components_104: "การครอบตัดรูปภาพ",
  i18nKey_components_105: "ยกเลิก",
  i18nKey_components_106: "ยกเลิก",
  i18nKey_components_107: "ยืนยัน",
  i18nKey_components_108: "ชาย",
  i18nKey_components_109: "เมื่อเทียบ",
  i18nKey_components_110: "QoQ",
  i18nKey_components_111: "จำนวนคน",
  i18nKey_components_112: "ปีต่อปี",
  i18nKey_components_113: "ช่วงเดียวกันของปีที่แล้ว",
  i18nKey_components_114: "หญิง",
  i18nKey_components_115: "เมื่อวานนี้",
  i18nKey_components_116: "อาทิตย์ที่แล้ว",
  i18nKey_components_117: "เดือนที่แล้ว",
  i18nKey_components_118: "ปีที่แล้ว",
  i18nKey_components_119: "ออนไลน์",
  i18nKey_components_120: "ปกติ",
  i18nKey_components_121: "ผิดปกติ",
  i18nKey_components_122: "",
  i18nKey_components_123: "ออฟไลน์",
  i18nKey_components_124: "ปริมาณรวม",
  i18nKey_components_125: "ไฟฟ้า",
  i18nKey_components_126: "การใช้น้ำ",
  i18nKey_components_127: "อันดับ",
  i18nKey_components_128: "การใช้พลังงานต่อตารางเมตร",
  i18nKey_components_129: "เมื่อเทียบกับเมื่อวาน",
  i18nKey_components_130: "ปีที่แล้ว",
  i18nKey_components_131: "อุปกรณ์",
  i18nKey_components_132: "พื้นที่",
  i18nKey_components_133: "ผู้ค้า",
  i18nKey_components_134: "ผู้เช่า",
  i18nKey_components_135: "การใช้พลังงาน",
  i18nKey_components_136: "ประเภทประหยัดพลังงาน",
  i18nKey_components_137: "ประเภท",
  i18nKey_components_138: "มาตรการวินิจฉัย",
  i18nKey_components_139: "ศักยภาพในการประหยัดพลังงาน",
  i18nKey_components_140: "ศักยภาพในการประหยัดพลังงานสะสม",
  i18nKey_components_141: "ไม่มีข้อมูลที่ตรงตามเงื่อนไขการสืบค้น",
  i18nKey_components_142: "พื้นที่ทั้งหมด",
  i18nKey_components_143: "จำนวนบริษัททั้งหมด",
  i18nKey_components_144: "การใช้พลังงานเมื่อวานนี้",
  i18nKey_components_145: "ปริมาณการใช้ไฟฟ้าเมื่อสัปดาห์ที่แล้ว",
  i18nKey_components_146: "ปริมาณการใช้ไฟฟ้าในเดือนที่แล้ว",
  i18nKey_components_147: "ความคล่องตัวของผู้โดยสาร (เวลาต่อคน)",
  i18nKey_components_148: "ประสิทธิภาพ",
  i18nKey_components_149: "การวินิจฉัย",
  i18nKey_components_150: "การใช้พลังงานในพื้นที่สาธารณะ",
  i18nKey_components_151: "การใช้พลังงานของผู้เช่า",
  i18nKey_components_152: "การใช้พลังงานทั้งหมด",
  i18nKey_components_153: "หน่วย:",
  i18nKey_components_154: "ท้องถิ่น",
  i18nKey_components_155: "ในร่ม",
  i18nKey_components_156: "อุณหภูมิในท้องถิ่น",
  i18nKey_components_157: "ความชื้นในท้องถิ่น",
  i18nKey_components_158: "อุณหภูมิห้อง",
  i18nKey_components_159: "ความชื้นในร่ม",
  i18nKey_components_160: "ปริมาณการใช้น้ำของผู้เช่า",
  i18nKey_components_161: "รายละเอียดไฟฟ้า",
  i18nKey_components_162: "พลังงานที่ใช้",
  i18nKey_components_163: "ไฟฟ้าทั้งหมด",
  i18nKey_components_164: "สถิติการใช้ไฟฟ้า",
  i18nKey_components_165: "ผู้เช่าไฟฟ้า",
  i18nKey_components_166: "ไฟฟ้าในพื้นที่สาธารณะ",
  i18nKey_components_167: "การตรวจสอบสิ่งแวดล้อม",
  i18nKey_components_168: "การแจ้งเตือนการใช้พลังงาน",
  i18nKey_components_169: "อุปกรณ์ผิดปกติ",
  i18nKey_components_170: "การใช้พลังงานผิดปกติ",
  i18nKey_components_171: "ข้อมูลผิดปกติ",
  i18nKey_components_172: "การตรวจสอบการใช้พลังงาน",
  i18nKey_components_173: "พื้นที่สาธารณะ",
  i18nKey_components_174: "YoY",
  i18nKey_components_175: "พลังงานแบบระบุรายการ",
  i18nKey_components_176: "การใช้พลังงานสะสมตามรายการ",
  i18nKey_components_177: "อันดับพลังงานของอุปกรณ์",
  i18nKey_components_178: "การจัดอันดับการใช้พลังงานในภูมิภาคต่อตารางเมตร",
  i18nKey_components_179: "ไฟฟ้าพลังน้ำ",
  i18nKey_components_180: "สถิติสถานะเหตุการณ์ของสิ่งอำนวยความสะดวก",
  i18nKey_components_181: "รายงานสิ่งอำนวยความสะดวก",
  i18nKey_components_182: "เวลาในการประมวลผลเฉลี่ย",
  i18nKey_components_183: "สถิติความล้มเหลว",
  i18nKey_components_184: "จำนวนความล้มเหลว",
  i18nKey_components_185: "อัตราความล้มเหลว",
  i18nKey_components_186: "สถิติการเข้าชม",
  i18nKey_components_187: "การควบคุมการเข้าถึงของจำนวนคน",
  i18nKey_components_188: "สถิติการเข้าชมของพนักงาน",
  i18nKey_components_189: "ผู้คนที่เข้าถึงทั้งหมด",
  i18nKey_components_190: "ค่าธรรมเนียมการระงับชั่วคราว",
  i18nKey_components_191: "หยุดเวลา",
  i18nKey_components_192: "จำนวนเงินที่เรียกเก็บ",
  i18nKey_components_193: "หยวน",
  i18nKey_components_194: "สถิติการจอดรถ",
  i18nKey_components_195: "การเข้าชมทั้งหมด",
  i18nKey_components_196: "จำนวนพื้นที่ในการจอดรถ",
  i18nKey_components_197: "อัตราการใช้พื้นที่จอดรถ",
  i18nKey_components_198: "เวลาจอดรถ",
  i18nKey_components_199: "ปริมาณ",
  i18nKey_components_200: "",
  i18nKey_components_201: "ภาพรวมการเข้าถึงบุคลากร",
  i18nKey_components_202: "สถิติการเข้าชม",
  i18nKey_components_203: "ขับรถเข้ามา",
  i18nKey_components_204: "ขับออกไป",
  i18nKey_components_205: "สถิติการเข้าชม",
  i18nKey_components_206: "สถิติเหตุการณ์ทางธุรกิจ",
  i18nKey_components_207: "ประเภท",
  i18nKey_components_208: "คำอธิบายเหตุการณ์",
  i18nKey_components_209: "เวลา",
  i18nKey_components_210: "ลูกค้าซ้ำ",
  i18nKey_components_211: "จำนวนลูกค้าซ้ำทั้งหมด (คน)",
  i18nKey_components_212: "ครั้งแรก (คน)",
  i18nKey_components_213: "อาทิตย์ที่แล้ว",
  i18nKey_components_214: "เดือนที่แล้ว",
  i18nKey_components_215: "เพื่อแนวโน้มกระแสผู้เยี่ยมชม",
  i18nKey_components_216: "จำนวนผู้เยี่ยมชมทั้งหมด (คน-เวลา)",
  i18nKey_components_217: "ความคล่องตัวของผู้โดยสารในปัจจุบัน",
  i18nKey_components_218: "ไม่มีที่จอดรถ",
  i18nKey_components_219: "สถานะไม่ได้ใช้งาน",
  i18nKey_components_220: "สถานะการใช้งาน",
  i18nKey_components_221: "ใช้",
  i18nKey_components_222: "เข้ามา! ! ! !",
  i18nKey_components_223: "ดำเนินการตามวิธีการจัดการ",
  i18nKey_components_224: "ข้อมูลบางส่วน",
  i18nKey_components_225: "คลิกอาคาร",
  i18nKey_components_226: "มีการนำวิถีวิถีใหม่มาใช้",
  i18nKey_components_227: "ล้างแทร็ก",
  i18nKey_components_228: "",
  i18nKey_components_229: "คริสตัล",
  i18nKey_components_230: "อาคาร",
  i18nKey_components_231: "สถานการณ์จริง",
  i18nKey_components_232: "มุมมองถนน",
  i18nKey_components_233: "มุมมองหลัก",
  i18nKey_components_234: "ดู",
  i18nKey_components_235: "มุมมองด้านบน",
  i18nKey_components_236: "ตั้งเป็นมุมมองหลัก",
  i18nKey_components_237: "รีเซ็ตมุมมองหลัก",
  i18nKey_components_238: "กู้คืนแล้ว",
  i18nKey_components_239: "สถิติความคล่องตัวของผู้โดยสารในช่วงเทศกาล",
  i18nKey_components_240: "จำนวนผู้โดยสารทั้งหมด",
  i18nKey_components_241: "เครื่องมือค้นหา",
  i18nKey_components_242: "สถานะผู้อยู่อาศัย",
  i18nKey_components_243: "ระยะเวลาเข้าพักโดยเฉลี่ย",
  i18nKey_components_244: "บริการออนไลน์ถูกระงับ กรุณารอเวลากู้คืน",

  i18nKey_components_245: "ฤดูกาล",
  i18nKey_components_246: "วันนี้",
  i18nKey_components_247: "7 วัน",
  i18nKey_components_248: "30 วัน",
  i18nKey_components_249: "โปรดเลือก",
  i18nKey_components_250: "กล้องวงจรปิดความคมชัดสูง",
  i18nKey_components_251: "ระบบจดจำใบหน้า",
  i18nKey_components_252: "เครื่องปรับอากาศไฟฟ้า",
  i18nKey_components_253: "การใช้พลังงาน",
  i18nKey_components_254: "แสง และซ็อกเก็ต",
  i18nKey_components_255: "ใช้งานในเชิงพาณิชย์",
  i18nKey_components_256: "เจ้าหน้าที่",
  i18nKey_components_257: "ปัด",

  i18nKey_components_258: "จำนวนการแจ้งเตือนทั้งหมด\n(Pcs)",
  i18nKey_components_259: "บัญชีผู้เช่าอันดับพลังงานต่อตารางเมตร",
  i18nKey_layout_1:
    "ข้อมูลการเข้าสู่ระบบของคุณหมดอายุแล้ว กรุณาเข้าสู่ระบบอีกครั้ง",
  i18nKey_layout_2: "แนวโน้มโดยรวม",
  i18nKey_layout_3: "การแสดงแนวโน้มโดยรวม",
  i18nKey_layout_4: "สถานการณ์โดยรวม",
  i18nKey_layout_5: "ท่าทางการรักษาความปลอดภัย",
  i18nKey_layout_6: "สถานการณ์การใช้พลังงาน",
  i18nKey_layout_7: "สถานการณ์สิ่งอำนวยความสะดวก",
  i18nKey_layout_8: "สถานการณ์ที่เหนือกว่า",
  i18nKey_layout_9: "สร้างข้อมูลพื้นฐานเชิงพื้นที่",
  i18nKey_layout_10: "แก้ไขข้อมูลพื้นฐานเชิงพื้นที่",
  i18nKey_layout_11: "รายละเอียดการแจ้งเตือนด้านความปลอดภัย",
  i18nKey_layout_12: "ข้อมูลพื้นฐานใหม่",
  i18nKey_layout_13: "รายละเอียดข้อมูลพื้นฐาน",
  i18nKey_layout_14: "แก้ไขข้อมูลพื้นฐาน",
  i18nKey_layout_15: "ข้อมูลพื้นฐานในอดีต",
  i18nKey_layout_16: "การติดตามใบหน้า",
  i18nKey_layout_17: "แทร็กใหม่",
  i18nKey_layout_18: "การควบคุมการปรับใช้ใบหน้าใหม่",
  i18nKey_layout_19: "ปรับเปลี่ยนการควบคุมการปรับใช้",
  i18nKey_layout_20: "ทำให้ใช้งานได้อีกครั้ง",
  i18nKey_layout_21: "ดูรายละเอียด",
  i18nKey_layout_22: "การควบคุมการปรับใช้ยานพาหนะใหม่",
  i18nKey_layout_23: "รายละเอียดการติดตั้งยานพาหนะ",
  i18nKey_layout_24: "รายละเอียดข้อมูลพื้นฐานของยานพาหนะ",
  i18nKey_layout_25: "การปรับเปลี่ยนข้อมูลพื้นฐานของยานพาหนะ",
  i18nKey_layout_26: "การสร้างข้อมูลพื้นฐานของยานพาหนะ",
  i18nKey_layout_27: "การกำหนดค่า",
  i18nKey_layout_28: "ข้อมูลพื้นฐานของบุคลากรใหม่",
  i18nKey_layout_29: "แก้ไขข้อมูลพื้นฐานของบุคลากร",
  i18nKey_layout_30: "ดูข้อมูลบุคลากรพื้นฐาน",
  i18nKey_layout_31: "รายละเอียดการจัดเก็บพลังงาน",
  i18nKey_layout_32: "แก้ไขกฎ",
  i18nKey_layout_33: "เพิ่มกฎ",
  i18nKey_layout_34: "รายละเอียดการใช้พลังงาน",
  i18nKey_layout_35: "ภาพสิ่งอำนวยความสะดวก",
  i18nKey_layout_36: "กลยุทธ์ใหม่",
  i18nKey_layout_37: "ปรับเปลี่ยนกลยุทธ์",
  i18nKey_layout_38: "รายละเอียดกลยุทธ์",
  i18nKey_layout_39: "ติดตามวิถี",
  i18nKey_layout_40: "เหตุการณ์ในอดีต",
  i18nKey_layout_41: "รายละเอียดเหตุการณ์",
  i18nKey_layout_42: "เส้นทางลาดตระเวนใหม่",
  i18nKey_layout_43: "การปรับเปลี่ยนเส้นทางลาดตระเวน",
  i18nKey_layout_44: "รายชื่อเส้นทางลาดตระเวน",
  i18nKey_layout_45: "รายการแผนการลาดตระเวน",
  i18nKey_layout_46: "สถิติการลาดตระเวนแบบวิดีโอ",
  i18nKey_layout_47: "ประวัติลาดตระเวนแบบวิดีโอ",
  i18nKey_layout_48: "รายละเอียดเส้นทางลาดตระเวน",
  i18nKey_layout_49: "แผนใหม่",
  i18nKey_layout_50: "ปรับเปลี่ยนแผน",
  i18nKey_layout_51: "รายละเอียดแผน",
  i18nKey_layout_52: "การจัดการที่อยู่",
  i18nKey_layout_53: "รายละเอียดคลังใบหน้า",
  i18nKey_layout_54: "รายละเอียดการติดตามใบหน้า",
  i18nKey_layout_55: "การจัดการเหตุการณ์การติดตามใบหน้า",
  i18nKey_layout_56: "สร้าง",
  i18nKey_layout_57: "แก้ไข",
  i18nKey_layout_58: "แสดงหน้าจอต้อนรับ",
  i18nKey_layout_59: "รายการการแจ้งเตือน",
  i18nKey_layout_60: "ดู",
  i18nKey_layout_61: "เพิ่ม",
  i18nKey_layout_62: "แก้ไข",
  i18nKey_layout_63: "การกำหนดค่าราคาต่อหน่วย",
  i18nKey_layout_64: "การกำหนดค่าความเสียหายของหม้อแปลง (line loss)",
  i18nKey_layout_65: "รายละเอียดเทมเพลตชั้น",
  i18nKey_layout_66: "รายละเอียดเทมเพลตสถานการณ์",
  i18nKey_layout_67: "รายละเอียดสถานการณ์",
  i18nKey_layout_68: "กล้องที่เกี่ยวข้อง",
  i18nKey_layout_69: "รายละเอียดยานพาหนะ",
  i18nKey_layout_70: "รายละเอียดบุคลากร",
  i18nKey_layout_71: "รายการเหตุการณ์",
  i18nKey_layout_72: "ข้อสังเกต",
  i18nKey_layout_73: "ศูนย์ส่วนบุคคล",
  i18nKey_layout_74: "เปลี่ยนรหัสผ่าน",
  i18nKey_layout_75: "ตัดออกจาก",
  i18nKey_layout_76: "เคล็ดลับ",
  i18nKey_layout_77: "แน่ใจหรือไม่ว่าต้องการออก ?",
  i18nKey_layout_78: "โหมดพาโนรามา",
  i18nKey_layout_79: "พาโนรามา",
  i18nKey_layout_80: "โหมด",
  i18nKey_layout_81: "ประเทศจีน",
  i18nKey_layout_82: "ปี",
  i18nKey_layout_83: "เดือน",
  i18nKey_layout_84: "วัน",
  i18nKey_layout_85: "ยกเลิก",
  i18nKey_layout_86: "ยืนยัน",
  i18nKey_layout_87: "รหัสผ่านเก่า",
  i18nKey_layout_88: "รหัสผ่านใหม่",
  i18nKey_layout_89: "การยืนยันคีย์ใหม่",
  i18nKey_layout_90: "กรุณากรอกรหัสผ่านปัจจุบัน",
  i18nKey_layout_91: "กรุณากรอกรหัสผ่านใหม่",
  i18nKey_layout_92: "กรุณากรอกรหัสผ่านใหม่อีกครั้ง",
  i18nKey_layout_93: "รหัสผ่านใหม่ต้องไม่เหมือนกับรหัสผ่านเดิม",
  i18nKey_layout_94: "ไม่ได้กรอกรหัสผ่านใหม่ก่อนและหลัง",
  i18nKey_layout_95: "กรุณากรอกรหัสผ่าน",
  i18nKey_layout_96: "จำเป็นต้องระบุรหัสผ่าน",
  i18nKey_layout_97: "ความปลอดภัยของรหัสผ่านไม่ตรงกัน",
  i18nKey_layout_98: "อย่างน้อย 8",
  i18nKey_layout_99: "รวมตัวเลข",
  i18nKey_layout_100: "อักษรตัวพิมพ์ใหญ่และตัวพิมพ์เล็ก",
  i18nKey_layout_101: "อักขระพิเศษอย่างน้อยสามประเภท",
  i18nKey_layout_102: "หลังจากเปลี่ยนรหัสผ่าน คุณต้องเข้าสู่ระบบอีกครั้ง",
  i18nKey_layout_103: "แก้ไขเรียบร้อยแล้ว",
  i18nKey_layout_104: "ไปที่ล็อกอิน",
  i18nKey_layout_105: "ตัวจำลอง",
  i18nKey_layout_106: "ชื่อ",
  i18nKey_layout_107: "โทรศัพท์มือถือ",
  i18nKey_layout_108: "ชื่อเล่น",
  i18nKey_layout_109: "เพศ",
  i18nKey_layout_110: "อัปโหลดตัวจำลอง",
  i18nKey_layout_111: "กรุณากรอกชื่อเล่น",
  i18nKey_layout_112: "ชาย",
  i18nKey_layout_113: "หญิง",
  i18nKey_layout_114: "ขนาดภาพต้องไม่เกิน 1",
  i18nKey_layout_115: "อัปโหลดได้เท่านั้น",
  i18nKey_layout_116: "จัดรูปแบบรูปภาพ",
  i18nKey_layout_117: "การอัปโหลดล้มเหลว",
  i18nKey_layout_118: "บันทึก",
  i18nKey_layout_119: "โกลด์",
  i18nKey_layout_120: "ระนาบ",
  i18nKey_enterprise_1: "หัวข้อ",
  i18nKey_enterprise_2: "ตัวหนา",
  i18nKey_enterprise_3: "ขนาดตัวอักษร",
  i18nKey_enterprise_4: "แบบอักษร",
  i18nKey_enterprise_5: "ตัวเอียง",
  i18nKey_enterprise_6: "ขีดล่าง",
  i18nKey_enterprise_7: "ขีดทับ",
  i18nKey_enterprise_8: "สีข้อความ",
  i18nKey_enterprise_9: "สีพื้นหลัง",
  i18nKey_enterprise_10: "แทรกลิงค์",
  i18nKey_enterprise_11: "รายการ",
  i18nKey_enterprise_12: "การจัดตำแหน่ง",
  i18nKey_enterprise_13: "แทรกตาราง",
  i18nKey_enterprise_14: "ใส่รหัส",
  i18nKey_enterprise_15: "ถอน",
  i18nKey_enterprise_16: "ทำซ้ำ",
  i18nKey_enterprise_17: "อัปโหลดรูปภาพไม่สำเร็จ",
  i18nKey_enterprise_18: "การจำลอง",
  i18nKey_enterprise_19: "ตัวสร้างเหตุการณ์",
  i18nKey_enterprise_20: "เวลา:",
  i18nKey_enterprise_21: "ชื่อเหตุการณ์:",
  i18nKey_enterprise_22: "คำอธิบายเหตุการณ์:",
  i18nKey_enterprise_23: "ที่อยู่ของเหตุการณ์:",
  i18nKey_enterprise_24: "อุปกรณ์เตือนภัย:",
  i18nKey_enterprise_25: "แหล่งที่มาของเหตุการณ์:",
  i18nKey_enterprise_26: "ระบบผู้เยี่ยมชม",
  i18nKey_enterprise_27: "การควบคุมการเข้าถึง",
  i18nKey_enterprise_28: "ดับเพลิง",
  i18nKey_enterprise_29: "ปริมณฑล",
  i18nKey_enterprise_30: "ระดับเหตุการณ์:",
  i18nKey_enterprise_31: "คำเตือนที่สำคัญ",
  i18nKey_enterprise_32: "ประเภทกิจกรรม:",
  i18nKey_enterprise_33: "เหตุการณ์เพลิงไหม้",
  i18nKey_enterprise_34: "เหตุการณ์ด้านความปลอดภัย",
  i18nKey_enterprise_35: "สร้างเหตุการณ์",
  i18nKey_enterprise_36: "บริษัท Vanke Technology",
  i18nKey_enterprise_37: "สงวนลิขสิทธิ์",
  i18nKey_enterprise_38: "ติดต่องานสนับสนุนด้านเทคนิค: 000-00000000",
  i18nKey_enterprise_39: "",
  i18nKey_enterprise_40: "ตำแหน่งปัจจุบัน",
  i18nKey_enterprise_41: "ทรัพย์สิน",
  i18nKey_enterprise_42: "ข้อมูล",
  i18nKey_enterprise_43: "ยกเลิกการปรับเปลี่ยนบุคลากร",
  i18nKey_enterprise_44: "กำลังบัฟเฟอร์วิดีโอ",
  i18nKey_enterprise_45: "การตัดสินใจผิดพลาด",
  i18nKey_enterprise_46: "รายงาน",
  i18nKey_enterprise_47: "เวลา",
  i18nKey_enterprise_48: "สถานที่",
  i18nKey_enterprise_49: "อาคารหลังหนึ่งใน Vanke Chengdu Park",
  i18nKey_enterprise_50: "รายงานคู่มือการลาดตระเวนแบบวิดีโอ",
  i18nKey_enterprise_51: "ระดับ",
  i18nKey_enterprise_52: "คำเตือนฉุกเฉินสีแดง",
  i18nKey_enterprise_53: "คำเตือนฉุกเฉินสีส้ม",
  i18nKey_enterprise_54: "คำเตือนฉุกเฉินสีเหลือง",
  i18nKey_enterprise_55: "คำเตือนฉุกเฉินสีน้ำเงิน",
  i18nKey_enterprise_56: "คำอธิบาย",
  i18nKey_enterprise_57: "โดยเฉพาะอุบัติเหตุร้ายแรง",
  i18nKey_enterprise_58: "อุบัติเหตุร้ายแรง",
  i18nKey_enterprise_59: "อุบัติเหตุขนาดใหญ่",
  i18nKey_enterprise_60: "อุบัติเหตุธรรมดา",
  i18nKey_enterprise_61: "จำเป็นต้องระบุ กรุณากรอกเนื้อหา",
  i18nKey_enterprise_62:
    "หมายเหตุ: เหมาะสำหรับหน้าแสดงตารางที่ไม่มีส่วนประกอบซ้อนทับกันบนหน้าแสดงรายการ",
  i18nKey_enterprise_63: "จำเป็นต้องผ่านในชื่อของคลาส",
  i18nKey_enterprise_64: "เพื่อค้นหาสิ่งที่คุณต้องการ",
  i18nKey_enterprise_65: "องค์ประกอบ",
  i18nKey_enterprise_66: "ต้นแบบ",
  i18nKey_enterprise_67: "จำเป็นต้องตั้งค่าคุณสมบัติค่าเริ่มต้น",
  i18nKey_enterprise_68: "จำเป็นต้องกำหนดป้ายชื่อด้านนอกสุด",
  i18nKey_enterprise_69: "ยืนยัน",
  i18nKey_enterprise_70: "กรุณากรอกชื่อหรือหมายเลขงาน",
  i18nKey_enterprise_71: "งาน",
  i18nKey_enterprise_72: "เลือกกล้อง",
  i18nKey_enterprise_73: "กล่องนิรภัย",
  i18nKey_enterprise_74: "หน่วย:",
  i18nKey_enterprise_75: "หน่วย: ชิ้น",
  i18nKey_enterprise_76: "หน่วย: คน - เวลา",
  i18nKey_enterprise_77:
    "ข้อมูลที่เกี่ยวข้องมีความผิดปกติและไม่สามารถตรวจสอบได้",
  i18nKey_enterprise_78: "ดูรายละเอียด",
  i18nKey_enterprise_79: "ประตูทิศเหนือ",
  i18nKey_enterprise_80: "ประตูทิศตะวันออก",
  i18nKey_enterprise_81: "ความปลอดภัยของทรัพย์สิน",
  i18nKey_enterprise_82: "สถิติแผนภูมิวงกลมเดี่ยว",
  i18nKey_enterprise_83: "ย่อเล็กสุด",
  i18nKey_enterprise_84: "ขยายใหญ่สุด",
  i18nKey_enterprise_85:
    "โมเดลไม่ได้ติดตั้งในที่จอดรถ กรุณาติดต่อผู้ดูแลระบบเพื่อติดโมเดล!",
  i18nKey_enterprise_86: "วันสุดท้าย",
  i18nKey_enterprise_87: "เลือกอุปกรณ์ที่เกี่ยวข้อง",
  i18nKey_enterprise_88: "รหัสอุปกรณ์",
  i18nKey_enterprise_89: "การใช้พลังงานในพื้นที่สาธารณะ",
  i18nKey_enterprise_90: "วันถัดไป",
  i18nKey_enterprise_91: "ปีที่แล้ว",
  i18nKey_enterprise_92: "การใช้พลังงานทั้งหมด",
  i18nKey_enterprise_93: "การใช้พลังงานในพื้นที่สาธารณะ",
  i18nKey_enterprise_94: "พื้นที่สาธารณะ",
  i18nKey_enterprise_95: "เปอร์เซ็นต์",
  i18nKey_enterprise_96: "เปรียบเทียบ",
  i18nKey_enterprise_97: "ช่วงเวลาเดียวกัน",
  i18nKey_enterprise_98: "หน่วย",
  i18nKey_enterprise_99: "ลบภาพ",
  i18nKey_enterprise_100: "คำแนะนำ: สนับสนุนเท่านั้น",
  i18nKey_enterprise_101:
    "อัปโหลดรูปภาพตามรูปแบบ ขนาดของรูปภาพที่อัปโหลดไม่เกิน 3",
  i18nKey_enterprise_102: "ขนาดของรูปภาพ",
  i18nKey_enterprise_103: "ประเภทของรูปภาพ",
  i18nKey_enterprise_104: "เวลาสร้าง",
  i18nKey_enterprise_105: "รูปภาพที่อัปโหลดต้องมีขนาดไม่เกิน 3",
  i18nKey_enterprise_106: "กรุณาอัปโหลดอีกครั้ง",
  i18nKey_enterprise_107: "ปริมาณ",
  i18nKey_enterprise_108: "การอัดประจุรวมสะสม",
  i18nKey_enterprise_109: "การคายประจุรวมสะสม",
  i18nKey_enterprise_110: "รอบการเฝ้าระวัง",
  i18nKey_enterprise_111: "กรุณากรอกค่าขั้นต่ำ",
  i18nKey_enterprise_112: "กรุณากรอกค่าสูงสุด",
  i18nKey_enterprise_113: "บันทึกสำเร็จ!",
  i18nKey_enterprise_114: "ไม่มีข้อมูล",
  i18nKey_enterprise_115: "เสร็จสมบูรณ์",
  i18nKey_enterprise_116: "เลือกจอภาพที่เกี่ยวข้อง",
  i18nKey_enterprise_117: "เลือกควันที่เกี่ยวข้อง",
  i18nKey_enterprise_118: "ประเภทธุรกิจ",
  i18nKey_enterprise_119: "หมายเลขโทรศัพท์",
  i18nKey_enterprise_120: "กรุณากรอกหมายเลขโทรศัพท์ของคุณ",
  i18nKey_enterprise_121: "เพิ่ม",
  i18nKey_enterprise_122:
    "ชื่อกลยุทธ์ประกอบด้วยอักขระขีดกลางและขีดล่างน้อยกว่า 20 ตัว",
  i18nKey_enterprise_123: "ระยะเวลาการตรวจสอบ",
  i18nKey_enterprise_124: "การจราจรของมนุษย์",
  i18nKey_enterprise_125: "การเฝ้าระวังควัน",
  i18nKey_enterprise_126: "เกณฑ์การเฝ้าระวัง",
  i18nKey_enterprise_127: "การเฝ้าระวังความสัมพันธ์",
  i18nKey_enterprise_128: "กรุณาเลือกจอภาพ",
  i18nKey_enterprise_129: "จำนวนคน",
  i18nKey_enterprise_130:
    "ระบุจำนวนคนได้เฉพาะจำนวนเต็มบวกที่มากกว่า 0 และน้อยกว่าหรือเท่ากับ 99999",
  i18nKey_enterprise_131: "เกณฑ์การเตือนความเข้มข้นของควัน",
  i18nKey_enterprise_132: "เชื่อมต่อเซ็นเซอร์ตรวจจับควัน",
  i18nKey_enterprise_133: "กรุณาเลือกเซ็นเซอร์ตรวจจับควัน",
  i18nKey_enterprise_134: "กรุณาระบุเกณฑ์การเตือนภัย",
  i18nKey_enterprise_135:
    "เกณฑ์สามารถป้อนจำนวนเต็มบวกที่มากกว่า 0 และน้อยกว่าหรือเท่ากับ 5,000 เท่านั้น",
  i18nKey_enterprise_136: "นี่คือเคล็ดลับ",
  i18nKey_enterprise_137: "คำแนะนำ",
  i18nKey_enterprise_138: "ความเข้มข้น",
  i18nKey_enterprise_139: "ควันปานกลาง",
  i18nKey_enterprise_140: "บุหรี่",
  i18nKey_enterprise_141: "ดาวน์โหลดรูปภาพ",
  i18nKey_enterprise_142: "ดูการเตือนภัย",
  i18nKey_enterprise_143: "ไม่มีข้อมูลที่มีสิทธิ์ชั่วคราว",
  i18nKey_enterprise_144: "กรุณากรอกคำอธิบาย",
  i18nKey_enterprise_145: "อัปโหลดการลาดตระเวนแบบวิดิโอด้วยตนเอง",
  i18nKey_enterprise_146: "การจัดส่งใบสั่งงาน",
  i18nKey_enterprise_147: "เลือกบุคลากร",
  i18nKey_enterprise_148: "จัดส่งใบสั่งงาน",
  i18nKey_enterprise_149: "ใส่ชื่อหรือหมายเลขงาน",
  i18nKey_enterprise_150: "ชื่อการเตือนภัย",
  i18nKey_enterprise_151: "สถานะการเตือนภัย",
  i18nKey_enterprise_152: "ระดับคำเตือน",
  i18nKey_enterprise_153: "เวลาเตือนภัย",
  i18nKey_enterprise_154: "มีความรับผิดชอบ",
  i18nKey_enterprise_155: "วางแผนเวลาเริ่มต้น",
  i18nKey_enterprise_156: "กรุณาเลือกเวลาเริ่มต้นที่วางแผนไว้",
  i18nKey_enterprise_157: "เวลาสิ้นสุดของแผน",
  i18nKey_enterprise_158: "กรุณาเลือกเวลาสิ้นสุดของแผน",
  i18nKey_enterprise_159: "ตำแหน่งการเตือนภัย",
  i18nKey_enterprise_160: "ก่อนหน้า",
  i18nKey_enterprise_161: "ส่งงาน",
  i18nKey_enterprise_162: "ต้องเลือกบุคคล",
  i18nKey_enterprise_163: "สร้างใบสั่งงาน",
  i18nKey_enterprise_164: "ประเภทใบสั่งงาน",
  i18nKey_enterprise_165: "คำอธิบายรายละเอียดงาน",
  i18nKey_enterprise_166: "กรุณากรอกคำอธิบายของงานโดยละเอียด",
  i18nKey_enterprise_167: "อุปกรณ์ที่เกี่ยวข้อง",
  i18nKey_enterprise_168: "กรุณาเลือกอุปกรณ์ที่เกี่ยวข้อง",
  i18nKey_enterprise_169: "ระดับงาน",
  i18nKey_enterprise_170: "กรุณาเลือกระดับงาน",
  i18nKey_enterprise_171: "กรุณาเลือกผู้รับผิดชอบ",
  i18nKey_enterprise_172: "กรุณาเลือกเวลาที่จะเกิดขึ้น",
  i18nKey_enterprise_173: "กรุณาเลือกผู้รับมอบสิทธิ์ใบสั่งงาน",
  i18nKey_enterprise_174: "ติดต่อไซต์",
  i18nKey_enterprise_175: "กรุณาเลือกผู้ติดต่อในสถานที่",
  i18nKey_enterprise_176: "กรุณากรอกชื่ออุปกรณ์",
  i18nKey_enterprise_177: "สร้างสำเร็ว",
  i18nKey_enterprise_178: "กรอกแบบฟอร์มด้วยตนเอง",
  i18nKey_enterprise_179: "จำเป็นต้องกรอกคำอธิบายรายละเอียดงาน",
  i18nKey_enterprise_180: "ความยาว",
  i18nKey_enterprise_181: "ถึง",
  i18nKey_enterprise_182: "อักขระ",
  i18nKey_enterprise_183: "สร้างตั๋วใหม่ไม่สำเร็จ",
  i18nKey_enterprise_184: "ใบสั่งงาน",
  i18nKey_enterprise_185: "การจัดส่งใบสั่งงานเสร็จสมบูรณ์",
  i18nKey_enterprise_186: "ไม่มีข้อมูล",
  i18nKey_enterprise_187: "ไม่มีอุปกรณ์ที่เหนือกว่าอุปกรณ์ชิ้นนี้",
  i18nKey_enterprise_188: "กรุณาเลือกสถานที่",
  i18nKey_enterprise_189: "กรุณากรอกผู้รับมอบสิทธิ์ใบสั่งงาน",
  i18nKey_enterprise_190: "กรุณากรอกที่อยู่ติดต่อของไซต์",
  i18nKey_enterprise_191: "ตัวเลือกที่ 1",
  i18nKey_enterprise_192: "Sponge Cake",
  i18nKey_enterprise_193: "ทางเลือกที่ 2",
  i18nKey_enterprise_194: "ขนมคัสตาร์ดนมสองชั้น",
  i18nKey_enterprise_195: "ตัวเลือก 3",
  i18nKey_enterprise_196: "หอยทอด",
  i18nKey_enterprise_197: "ทางเลือกที่ 4",
  i18nKey_enterprise_198: "บะหมี่เครามังกร",
  i18nKey_enterprise_199: "เวลาเริ่มต้นจริง",
  i18nKey_enterprise_200: "เวลาสิ้นสุดจริง",
  i18nKey_enterprise_201: "เวลาในการประมวลผลใบสั่งงาน",
  i18nKey_enterprise_202: "รายละเอียดของผู้ตรวจสอบ",
  i18nKey_enterprise_203: "ตำแหน่ง",
  i18nKey_enterprise_204: "สถานการณ์ในงาน",
  i18nKey_enterprise_205: "จำนวนใบสั่งงานทั้งหมด",
  i18nKey_enterprise_206: "กำลังประมวลผล",
  i18nKey_enterprise_207: "การเปลี่ยนแปลงของเหตุการณ์",
  i18nKey_enterprise_208: "แจ้งบุคลากร อพยพบุคลากรในอาคาร",
  i18nKey_enterprise_209: "กด 119, 120",
  i18nKey_enterprise_210: "ติดต่อกับทีมฉุกเฉินและเริ่มปรับใช้การอพยพ",
  i18nKey_enterprise_211:
    "เกิดสัญญาณเตือนบนอุปกรณ์ตรวจจับควันที่ชั้น 1 อาคาร 1, Chengdu Park",
  i18nKey_enterprise_212: "อัปโหลดในเครื่อง",
  i18nKey_enterprise_213: "เลือกจากคลังเจ้าหน้าที่",
  i18nKey_enterprise_214: "อัปโหลดไปยังเซิร์ฟเวอร์",
  i18nKey_enterprise_215: "บุคลากร",
  i18nKey_enterprise_216: "Gao Fei",
  i18nKey_enterprise_217: "Gao Zhengwei",
  i18nKey_enterprise_218: "Gao Linfei",
  i18nKey_enterprise_219: "Li Chuangye",
  i18nKey_enterprise_220: "Yao Yun",
  i18nKey_enterprise_221: "Avatar",
  i18nKey_enterprise_222: "ป้าย",
  i18nKey_enterprise_223: "ป้อนชื่อหรือหมายเลข",
  i18nKey_enterprise_224: "ควัน",
  i18nKey_enterprise_225: "กลับมาอีกชั้น",
  i18nKey_enterprise_226: "รหัส",
  i18nKey_enterprise_227: "การส่งคืนพื้นหลัง",
  i18nKey_enterprise_228: "Chengdu Vanke Park - อาคาร 1 F1",
  i18nKey_enterprise_229: "เครื่องปรับอากาศหน่วยอากาศบริสุทธิ์ของท้องถิ่น",
  i18nKey_enterprise_230: "ระดับ",
  i18nKey_enterprise_231: "ระบบระบายความร้อน",
  i18nKey_enterprise_232: "พื้นที่",
  i18nKey_enterprise_233: "Chengdu Vanke Park",
  i18nKey_enterprise_234: "ระบบการเป็นเจ้าของ",
  i18nKey_enterprise_235: "ระบบปรับอากาศ",
  i18nKey_enterprise_236: "Lin Sheng",
  i18nKey_enterprise_237: "คลาสย่อย",
  i18nKey_enterprise_238: "ข้อความตัวอย่างของคลาสย่อย",
  i18nKey_enterprise_239: "ข้อความตัวอย่างของคำอธิบายสิ่งอำนวยความสะดวก",
  i18nKey_enterprise_240: "งานใหม่",
  i18nKey_enterprise_241: "รายละเอียดงาน",
  i18nKey_enterprise_242: "Minei",
  i18nKey_enterprise_243: "ยกเลิกการลิงก์",
  i18nKey_enterprise_244: "เชื่อมต่อแล้ว",
  i18nKey_enterprise_245: "กล้องที่เชื่อมโยงกับเหตุการณ์",
  i18nKey_enterprise_246: "กล้องรอบข้าง",
  i18nKey_enterprise_247: "กล้องที่เกี่ยวข้อง",
  i18nKey_enterprise_248: "บันทึกการประมวลผล",
  i18nKey_enterprise_249: "แจ้งพนักงานและอพยพบุคลากรในอาคาร",
  i18nKey_enterprise_250: "แจ้งพนักงานและอพยพส่วนปลีกย่อยของบุคลากรในอาคาร",
  i18nKey_enterprise_251: "เวลาเกิดเหตุการณ์",
  i18nKey_enterprise_252: "สถานที่เกิดเหตุการณ์",
  i18nKey_enterprise_253: "ได้รับ",
  i18nKey_enterprise_254: "กำหนดใหม่",
  i18nKey_enterprise_255: "เวลาปิดงาน",
  i18nKey_enterprise_256: "เวลามอบหมายงาน",
  i18nKey_enterprise_257: "ผู้รับมอบหมายงาน",
  i18nKey_enterprise_258: "ข้อเสนอแนะแบบถ่ายทอดสด",
  i18nKey_enterprise_259: "บันทึกงาน",
  i18nKey_enterprise_260: "ผู้สร้าง:",
  i18nKey_enterprise_261: "ใหญ่",
  i18nKey_enterprise_262: "เล็ก:",
  i18nKey_enterprise_263: "รายการงาน",
  i18nKey_enterprise_264: "ดูเพิ่มเติม",
  i18nKey_enterprise_265: "สถานการณ์ที่ครอบคลุม - ระดับชาติ",
  i18nKey_enterprise_266: "บัตรแสดงรายการสถิติ",
  i18nKey_enterprise_267: "สถานการณ์ที่ครอบคลุม",
  i18nKey_enterprise_268: "สถิติเหตุการณ์ด้านความปลอดภัย",
  i18nKey_enterprise_269: "การแสดงสถานการณ์ที่ครอบคลุม",
  i18nKey_enterprise_270: "กรุณาเลือกไม่น้อยกว่า",
  i18nKey_enterprise_271: "กรุณากรอกชื่อมิเตอร์",
  i18nKey_enterprise_272: "มิเตอร์ไฟฟ้า",
  i18nKey_enterprise_273: "มิเตอร์น้ำ",
  i18nKey_enterprise_274: "กรุณากรอกชื่อมิเตอร์",
  i18nKey_enterprise_275: "การใช้พลังงานในปัจจุบัน",
  i18nKey_enterprise_276: "QoQ ในเดือนนี้",
  i18nKey_enterprise_277: "YoY ในเดือนนี้",
  i18nKey_enterprise_278: "ผู้เช่าไฟฟ้า",
  i18nKey_enterprise_279: "ไฟฟ้าสำหรับแสงสว่าง",
  i18nKey_enterprise_280: "การใช้พลังงานทั้งหมดในแต่ละวัน",
  i18nKey_enterprise_281: "ป้อนหมายเลขเหตุการณ์",
  i18nKey_enterprise_282: "กรุณาเลือกผู้มอบหมายงาน",
  i18nKey_enterprise_283: "จำเป็นต้องกรอกเวลาเริ่มต้นที่วางแผนไว้",
  i18nKey_enterprise_284: "จำเป็นต้องกรอกเวลาสิ้นสุดที่วางแผนไว้",
  i18nKey_enterprise_285: "การโหลดเครือข่ายผิดปกติ",
  i18nKey_enterprise_286: "ยืนยันรหัสผ่าน",
  i18nKey_enterprise_287: "รหัสผ่าน",
  i18nKey_enterprise_288: "เบอร์โทรศัพท์",
  i18nKey_enterprise_289: "กล่องจดหมาย",
  i18nKey_enterprise_290: "อายุ",
  i18nKey_enterprise_291: "อุตสาหกรรม",
  i18nKey_enterprise_292: "รายละเอียดบุคลากร",
  i18nKey_enterprise_293: "ภาพ",
  i18nKey_enterprise_294: "ตำแหน่งที่ตั้งของบุคลากร",
  i18nKey_enterprise_295: "ข้อมูลยานพาหนะ",
  i18nKey_enterprise_296: "การแจ้งเตือนข้อความ",
  i18nKey_enterprise_297: "ข้ามไปที่มุมมองแทร็ก",
  i18nKey_enterprise_298: "ป้ายรูปภาพ",
  i18nKey_enterprise_299: "ลบแผนที่ฐาน",
  i18nKey_enterprise_300: "ครั้ง",
  i18nKey_enterprise_301: "เครื่องหมาย",
  i18nKey_enterprise_302: "ความคล้ายคลึงกัน:",
  i18nKey_enterprise_303:
    "แสดงเฉพาะใบหน้าที่มีความคล้ายคลึงกันสูงซึ่งกรองโดยระบบ",
  i18nKey_enterprise_304: "แหล่งข้อมูล:",
  i18nKey_enterprise_305: "ไม่มีข้อมูลผู้ต้องสงสัย",
  i18nKey_enterprise_306: "การดำเนินการนี้จะลบแผนที่ใบหน้าโดยสมบูรณ์",
  i18nKey_enterprise_307: "เข้าสู่บัญชีดำของ park เพื่อปรับใช้การควบคุม",
  i18nKey_enterprise_308: "การควบคุมการปรับใช้บัญชีดำของ park",
  i18nKey_enterprise_309: "ภาพบุคคล",
  i18nKey_enterprise_310: "ข้อมูลใบหน้า",
  i18nKey_enterprise_311: "ข้อมูลผู้ต้องสงสัย",
  i18nKey_enterprise_312: "ยกเลิกการปรับใช้",
  i18nKey_enterprise_313: "ยกเลิกการควบคุมการปรับใช้บัญชีดำของ park",
  i18nKey_enterprise_314: "สถานะอินเทอร์เฟซผิดปกติ",
  i18nKey_enterprise_315: "บุคคลมีเครื่องหมายอย่างน้อยหนึ่งรายการ",
  i18nKey_enterprise_316: "ไม่สามารถแก้ไข",
  i18nKey_enterprise_317: "การแก้ไขล้มเหลว สถานะอินเทอร์เฟซผิดปกติ",
  i18nKey_enterprise_318: "ไม่สามารถแทนที่แผนที่ฐาน",
  i18nKey_enterprise_319: "บุคคลนี้เข้าบัญชีดำ",
  i18nKey_enterprise_320: "บุคคลนี้ยกเลิกการควบคุมบัญชีดำ",
  i18nKey_enterprise_321: "การดำเนินการล้มเหลว",
  i18nKey_enterprise_322: "การทำงานล้มเหลว สถานะอินเทอร์เฟซผิดปกติ",
  i18nKey_enterprise_323: "ที่ตั้ง park",
  i18nKey_enterprise_324: "ยินดีต้อนรับ",
  i18nKey_enterprise_325: "หน้าจอต้อนรับ",
  i18nKey_enterprise_326: "จำเป็นต้องกรอกเหตุผลในการควบคุม",
  i18nKey_enterprise_327: "กรุณาเลือกผู้ที่",
  i18nKey_enterprise_328: "กรุณาเลือกหน้าจอต้อนรับการควบคุมการปรับใช้",
  i18nKey_enterprise_329: "ระยะเวลาการติดตาม",
  i18nKey_enterprise_330: "ถึง",
  i18nKey_enterprise_331: "สถานะการติดตาม",
  i18nKey_enterprise_332: "กรุณาเลือกสถานะการติดตาม",
  i18nKey_enterprise_333: "กล้องติดตาม",
  i18nKey_enterprise_334: "กรุณาเลือกกล้องติดตาม",
  i18nKey_enterprise_335: "ลบข้อมูลเรียบร้อยแล้ว",
  i18nKey_enterprise_336: "การดำเนินงานที่ประสบความสำเร็จ",
  i18nKey_enterprise_337: "การโหลดเครือข่ายผิดปกติกรุณาลองอีกครั้งในภายหลัง",
  i18nKey_enterprise_338: "ไม่มีคำเตือน",
  i18nKey_enterprise_339: "หมายเลขประจำตัว",
  i18nKey_enterprise_340: "แหล่งข้อมูล",
  i18nKey_enterprise_341: "โรเบิร์ต",
  i18nKey_enterprise_342: "ตื่นนอนตอนตีสาม",
  i18nKey_enterprise_343: "พบว่าดอกบีโกเนียนั้นไม่ได้กำลังนอนหลับ",
  i18nKey_enterprise_344: "รายงานการประชุม วันพรุ่งนี้ 11:30 น",
  i18nKey_enterprise_345: "รายงานการประชุม วันพรุ่งนี้ 14:30 น",
  i18nKey_enterprise_346: "เบราว์เซอร์ปัจจุบันไม่รองรับ",
  i18nKey_enterprise_347: "ถึงหน้าต่างการแจ้งเตือนแล้ว",
  i18nKey_enterprise_348: "สถานะการแจ้งเตือน",
  i18nKey_enterprise_349: "กล้องเตือนการมาถึง",
  i18nKey_enterprise_350: "เวลาการต้อนรับ",
  i18nKey_enterprise_351: "สถานะการต้อนรับ",
  i18nKey_enterprise_352: "กรุณาเลือกสถานะการต้อนรับ",
  i18nKey_enterprise_353: "หน้าจอต้อนรับการควบคุมการปรับใช้",
  i18nKey_enterprise_354: "เข้าสู่หน้าจอต้อนรับ",
  i18nKey_enterprise_355: "ป้อนชื่อของหน้าจอต้อนรับ",
  i18nKey_enterprise_356: "เลือกหน้าจอต้อนรับ",
  i18nKey_enterprise_357: "การปรับเปลี่ยนข้อมูลยานพาหนะ",
  i18nKey_enterprise_358: "เจ้าของรถ",
  i18nKey_enterprise_359: "จำนวนคำที่ป้อนต้องไม่เกิน 300",
  i18nKey_enterprise_360: "ยกเลิกคำขอแก้ไขนี้",
  i18nKey_enterprise_361: "รายละเอียดบันทึกการระงับ",
  i18nKey_enterprise_362: "ซ้าย",
  i18nKey_enterprise_363: "การจอดรถ",
  i18nKey_enterprise_364: "เวลาจอดรถ",
  i18nKey_enterprise_365: "ข้อมูลการจอดรถ",
  i18nKey_enterprise_366: "พื้นที่ว่างในการจอดรถ",
  i18nKey_enterprise_367: "เล่นบันทึก",
  i18nKey_enterprise_368: "บันทึกการเช็คอิน",
  i18nKey_enterprise_369: "บันทึกรายละเอียด",
  i18nKey_enterprise_370: "ชื่อกล้อง",
  i18nKey_enterprise_371: "คนเช็คอิน",
  i18nKey_enterprise_372: "เวลาเช็คอิน",
  i18nKey_enterprise_373: "ไม่เข้างาน",
  i18nKey_enterprise_374: "กลุ่มลาดตระเวน XX",
  i18nKey_enterprise_375: "เวลาเล่น",
  i18nKey_enterprise_376: "ดูเลย",
  i18nKey_enterprise_377: "ที่จะดำเนินการ",
  i18nKey_enterprise_378: "ยึดมั่นในหลักการพื้นฐานในการดำเนินงาน",
  i18nKey_enterprise_379: "ออกจากพื้นที่ปลอดภัย",
  i18nKey_enterprise_380: "เป็นก้าวแรก",
  i18nKey_enterprise_381: "Cross ten thousands miles of mountains",
  i18nKey_enterprise_382: "เอาชนะความยากลำบาก",
  i18nKey_enterprise_383: "ไม่มีการถอยหลังของนักสู้",
  i18nKey_enterprise_384: "ไม่มีอันตรายข้างหน้าของความกล้าหาญ",
  i18nKey_enterprise_385: "ความสามัคคี",
  i18nKey_enterprise_386: "ออกไปให้หมด",
  i18nKey_enterprise_387: "มันไม่เกี่ยวกับความแข็งแรง",
  i18nKey_enterprise_388: "ขึ้นอยู่กับระยะเวลาที่สามารถอยู่ได้",
  i18nKey_enterprise_389: "เพื่อหาหนทางสู่ความสำเร็จ",
  i18nKey_enterprise_390: "ไม่มีเหตุผลสำหรับความล้มเหลว",
  i18nKey_enterprise_391: "หนทางอีกยาวไกล",
  i18nKey_enterprise_392: "Determined to win",
  i18nKey_enterprise_393: "Only blunt axe",
  i18nKey_enterprise_394: "ไม่มีฟืนที่แยกไม่ออก",
  i18nKey_enterprise_395: "มูลค่าเดิมของทรัพย์สิน",
  i18nKey_enterprise_396: "เวลารายงานล่าสุด",
  i18nKey_enterprise_397: "อัตราการใช้ (ตามประเภท)",
  i18nKey_enterprise_398: "การติดตั้ง",
  i18nKey_enterprise_399: "จำนวนทรัพย์สิน",
  i18nKey_enterprise_400: "มูลค่าเดิมทั้งหมดของทรัพย์สิน",
  i18nKey_enterprise_401: "ชื่อสินทรัพย์",
  i18nKey_enterprise_402: "การใช้พลังงานทั้งหมด",
  i18nKey_enterprise_403: "ไม่มีข้อมูล",
  i18nKey_enterprise_404: "การตั้งค่าเงินมัดจำ",
  i18nKey_enterprise_405: "วันที่ชำระเงินมัดจำ",
  i18nKey_enterprise_406: "กรุณาเลือกวันที่ชำระเงินมัดจำ",
  i18nKey_enterprise_407: "วันที่คืนเงิน",
  i18nKey_enterprise_408: "กรุณาเลือกวันที่คืนเงิน",
  i18nKey_enterprise_409: "ผู้สมัคร",
  i18nKey_enterprise_410: "วันที่ชำระเงินสำเร็จ",
  i18nKey_enterprise_411: "วันที่ขอคืนเงิน",
  i18nKey_enterprise_412: "จำนวนเงินมัดจำ",
  i18nKey_enterprise_413: "จำนวนเงินคืน",
  i18nKey_enterprise_414: "ภายใต้การทบทวน",
  i18nKey_enterprise_415: "ขอคืนเงิน",
  i18nKey_enterprise_416: "คืนเงินมัดจำ",
  i18nKey_enterprise_417: "ดำเนินการสำเร็จ!",
  i18nKey_enterprise_418: "การโหลดเครือข่ายผิดปกติ กรุณาลองอีกครั้งในภายหลัง!",
  i18nKey_enterprise_419: "ตั้งค่าเงินมัดจำเรียบร้อยแล้ว!",
  i18nKey_enterprise_420: "การคืนเงิน",
  i18nKey_enterprise_421: "ผ่านการตรวจสอบ",
  i18nKey_enterprise_422:
    "ทั้งลูกค้าและผู้ดูแลระบบสามารถเริ่มการคืนเงินมัดจำได้",
  i18nKey_enterprise_423: "ผู้ดูแลระบบเริ่มการคืนเงินมัดจำ",
  i18nKey_enterprise_424: "วันที่สร้าง",
  i18nKey_enterprise_425: "ลบ",
  i18nKey_enterprise_426: "การโหลดเครือข่ายผิดปกติ กรุณาลองอีกครั้งในภายหลัง!",
  i18nKey_enterprise_427: "ยกเลิกความสำเร็จ!",
  i18nKey_enterprise_428: "ชื่อพื้นที่ park",
  i18nKey_enterprise_429: "กรุณากรอกชื่อพื้นที่ park",
  i18nKey_enterprise_430: "รหัสห้องประชุม",
  i18nKey_enterprise_431: "กรุณากรอกรหัสห้องประชุม",
  i18nKey_enterprise_432: "ลบสำเร็จ!",
  i18nKey_enterprise_433: "กรุณากรอกชื่อพื้นที่",
  i18nKey_enterprise_434: "ดาวน์โหลดรหัส QR",
  i18nKey_enterprise_435: "เลือกวันที่",
  i18nKey_enterprise_436: "กรุณาเลือกวันที่",
  i18nKey_enterprise_437: "ไม่มีกำหนดเวลา",
  i18nKey_enterprise_438: "รายการพื้นที่ park",
  i18nKey_enterprise_439: "ในวันอาทิตย์",
  i18nKey_enterprise_440: "ผู้จอง",
  i18nKey_enterprise_441: "เปิดประตู",
  i18nKey_enterprise_442: "ปิดประตู",
  i18nKey_enterprise_443: "เวลาปลดล็อก",
  i18nKey_enterprise_444:
    "การดำเนินการล้มเหลว และไม่มีบันทึกการเปิดประตูในหน้าปัจจุบัน!",
  i18nKey_enterprise_445: "การกำหนดค่า",
  i18nKey_enterprise_446: "การกำหนดค่าหม้อแปลง",
  i18nKey_enterprise_447: "ยังไม่มีหม้อแปลง!",
  i18nKey_enterprise_448: "เพิ่มหม้อแปลง",
  i18nKey_enterprise_449:
    "หลังจากการปรับเปลี่ยน unit price ราคาที่เปลี่ยนแปลงจะมีผลในวันถัดไป",
  i18nKey_enterprise_450: "ชื่อหม้อแปลง",
  i18nKey_enterprise_451: "ส่วนแบ่งทั้งหมด",
  i18nKey_enterprise_452: "ไม่ว่าจะเปิด / ปิด",
  i18nKey_enterprise_453: "แก้ไขบันทึก",
  i18nKey_enterprise_454: "ไม่มีบันทึกการแก้ไข",
  i18nKey_enterprise_455: "เปลี่ยนเวลา",
  i18nKey_enterprise_456: "แก้ไขเนื้อหา",
  i18nKey_enterprise_457: "ตัวดำเนินการ",
  i18nKey_enterprise_458: "กรุณากรอกข้อมูลหม้อแปลงไฟฟ้า",
  i18nKey_enterprise_459: "การกำหนดค่าราคาต่อหน่วย",
  i18nKey_enterprise_460: "ขณะนี้ยังไม่มีการกำหนดราคาต่อหน่วย!",
  i18nKey_enterprise_461: "กำหนดราคาต่อหน่วย",
  i18nKey_enterprise_462: "ค่าต้องเป็นจำนวนบวก โดยมีทศนิยมไม่เกินสองตำแหน่ง",
  i18nKey_enterprise_463: "ปริมาณส่วนแบ่งการบริโภค",
  i18nKey_enterprise_464: "จำนวนเงินที่แก้ไข (หยวน)",
  i18nKey_enterprise_465: "การกำหนดค่าการสูญเสียของหม้อแปลง (การสูญเสียสาย)",
  i18nKey_enterprise_466: "ผู้ค้า",
  i18nKey_enterprise_467: "ประเภทผู้เช่า",
  i18nKey_enterprise_468: "กรุณาเลือกธุรกิจ",
  i18nKey_enterprise_469: "กรุณาเลือกหรือระบุธุรกิจ",
  i18nKey_enterprise_470: "ชื่อผู้เช่า",
  i18nKey_enterprise_471: "การใช้พลังงาน",
  i18nKey_enterprise_472: "ปริมาณการบริโภค",
  i18nKey_enterprise_473: "การใช้พลังงานร่วมกัน",
  i18nKey_enterprise_474: "เปอร์เซ็นต์ส่วนแบ่ง",
  i18nKey_enterprise_475: "รายละเอียดการจัดจำหน่าย",
  i18nKey_enterprise_476: "ปิดการใช้งาน",
  i18nKey_enterprise_477: "การจัดการการแบ่งส่วน",
  i18nKey_enterprise_478: "วันที่",
  i18nKey_enterprise_479: "ยังไม่มีข้อมูลที่จะส่งออก!",
  i18nKey_enterprise_480: "เช็คอินสุขภาพ BoYu",
  i18nKey_enterprise_481: "เวลาเริ่มงานประจำวันของธุรกิจ",
  i18nKey_enterprise_482: "รายงานตำรวจเกี่ยวกับการแพร่ระบาด",
  i18nKey_enterprise_483:
    "ไม่สามารถกู้คืนได้หลังจากการลบ ต้องการดำเนินการต่อหรือไม่",
  i18nKey_enterprise_484: "กรุณากรอกชื่อบุคคล",
  i18nKey_enterprise_485: "กรุณากรอกหมายเลขติดต่อในรูปแบบที่ถูกต้อง",
  i18nKey_enterprise_486: "แก้ไขสมาชิกในทีม",
  i18nKey_enterprise_487: "รองรับเฉพาะตัวอักษรภาษาจีนและภาษาอังกฤษ และตัวเลข",
  i18nKey_enterprise_488: "การเข้ารหัสกลุ่ม",
  i18nKey_enterprise_489: "กรุณากรอกรหัสกลุ่ม",
  i18nKey_enterprise_490: "ชื่อพจนานุกรม",
  i18nKey_enterprise_491: "กรุณากรอกชื่อพจนานุกรม",
  i18nKey_enterprise_492: "หมายเลขพจนานุกรม",
  i18nKey_enterprise_493: "กรุณากรอกหมายเลขพจนานุกรม",
  i18nKey_enterprise_494: "เนื้อหาพจนานุกรม",
  i18nKey_enterprise_495: "จัดเรียง",
  i18nKey_enterprise_496: "ขยายฟิลด์",
  i18nKey_enterprise_497: "Vanzhihui",
  i18nKey_enterprise_498: "สำนักงานผลิต Vanke",
  i18nKey_enterprise_499: "พจนานุกรมข้อมูลใหม่",
  i18nKey_enterprise_500: "รายละเอียดพจนานุกรมข้อมูล",
  i18nKey_enterprise_501: "แก้ไขพจนานุกรมข้อมูล",
  i18nKey_enterprise_502: "กรุณากรอกหมายเลขซีเรียล",
  i18nKey_enterprise_503: "กรุณากรอกเนื้อหาพจนานุกรม",
  i18nKey_enterprise_504: "กรุณากรอกคำสั่งซื้อ",
  i18nKey_enterprise_505: "กรุณากรอกฟิลด์ส่วนขยาย",
  i18nKey_enterprise_506: "ไม่สามารถใส่อีโมติคอนได้",
  i18nKey_enterprise_507: "กรุณาเลือกระดับสิ่งอำนวยความสะดวก",
  i18nKey_enterprise_508: "การจำแนกประเภทสิ่งอำนวยความสะดวก",
  i18nKey_enterprise_509: "กรุณากรอกบุคคลที่รับผิดชอบในการจัดการ",
  i18nKey_enterprise_510: "กรุณากรอกคำอธิบายสิ่งอำนวยความสะดวก",
  i18nKey_enterprise_511: "สถานะอุปกรณ์",
  i18nKey_enterprise_512: "สัญญาณเตือนไฟไหม้อัตโนมัติและระบบที่เชื่อมโยง",
  i18nKey_enterprise_513: "เครื่องแม่ข่ายสัญญาณเตือนไฟไหม้",
  i18nKey_enterprise_514: "เครื่องตรวจจับควัน",
  i18nKey_enterprise_515: "เครื่องตรวจจับไฟความร้อน",
  i18nKey_enterprise_516: "ปุ่มเตือนภัยด้วยตนเอง",
  i18nKey_enterprise_517: "ขีดจำกัดบน",
  i18nKey_enterprise_518: "ความพร้อมใช้งาน",
  i18nKey_enterprise_519: "อัตราความล้มเหลว",
  i18nKey_enterprise_520: "จำนวนสัญญาณเตือนทั้งหมด",
  i18nKey_enterprise_521: "สิ่งกีดขวาง",
  i18nKey_enterprise_522: "แจกจ่าย",
  i18nKey_enterprise_523: "แจกจ่ายสำเร็จ!",
  i18nKey_enterprise_524: "คำอธิบายเหตุการณ์ต้องไม่เกิน 1,000 อักขระ",
  i18nKey_enterprise_525: "ข้อผิดพลาดของเครือข่าย",
  i18nKey_enterprise_526: "กล้อง 001",
  i18nKey_enterprise_527: "เริ่มต้น",
  i18nKey_enterprise_528: "ไม่มีเหตุการณ์ที่เกี่ยวข้อง",
  i18nKey_enterprise_529: "ความสำเร็จของการตัดสินใจผิดพลาด",
  i18nKey_enterprise_530:
    "สถานะเหตุการณ์อยู่ระหว่างรอดำเนินการและไม่สามารถรายงานได้ชั่วคราว",
  i18nKey_enterprise_531:
    "สถานะเหตุการณ์กำลังประมวลผลและไม่สามารถรายงานได้ชั่วคราว",
  i18nKey_enterprise_532:
    "สถานะเหตุการณ์อยู่ระหว่างรอดำเนินการและไม่สามารถรายงานได้ชั่วคราว",
  i18nKey_enterprise_533: "กำลังเริ่มการติดตามใบหน้า",
  i18nKey_enterprise_534: "การควบคุมการปรับใช้ใบหน้าใหม่",
  i18nKey_enterprise_535: "ค้นหาตามชื่อ",
  i18nKey_enterprise_536: "เลือกทั้งหมด",
  i18nKey_enterprise_537: "เลือกโหนดเมื่อคุณคลิกโหนด",
  i18nKey_enterprise_538: "ขยายโหนดทั้งหมดตามค่าเริ่มต้น",
  i18nKey_enterprise_539: "รองรับเท่านั้น",
  i18nKey_enterprise_540: "จัดรูปแบบรูปภาพและไม่เกิน 3",
  i18nKey_enterprise_541: "กรุณาพิมพ์ชื่อของคุณ",
  i18nKey_enterprise_542: "ประเภทรายการ",
  i18nKey_enterprise_543: "กรุณากรอกหมายเลขประจำตัว",
  i18nKey_enterprise_544: "ชาย",
  i18nKey_enterprise_545: "หญิง",
  i18nKey_enterprise_546: "กรุณากรอกที่อยู่อาศัย",
  i18nKey_enterprise_547: "ขยายส่วนทั้งหมดตามค่าเริ่มต้น",
  i18nKey_enterprise_548: "กรุณาเลือกประเภทรายการ",
  i18nKey_enterprise_549: "รองรับเฉพาะรูปภาพ",
  i18nKey_enterprise_550: "รูปภาพต้องมีขนาดไม่เกิน 3",
  i18nKey_enterprise_551: "เทมเพลตการนำเข้าบัญชีดำด้านความปลอดภัยสาธารณะ",
  i18nKey_enterprise_552: "มิเตอร์",
  i18nKey_enterprise_553: "ลบไม่สำเร็จ",
  i18nKey_enterprise_554: "กรุณาเลือกวัตถุที่จะลบ",
  i18nKey_enterprise_555: "ยืนยันที่จะลบ",
  i18nKey_enterprise_556: "กรุณาอัปโหลดรูปภาพ",
  i18nKey_enterprise_557: "ไลบรารีใหม่",
  i18nKey_enterprise_558: "ลบไลบรารี",
  i18nKey_enterprise_559: "มากกว่า",
  i18nKey_enterprise_560: "กำลังปิดการติดตามใบหน้า",
  i18nKey_enterprise_561: "บุคลากร:",
  i18nKey_enterprise_562: "การตรวจสอบภาพหน้าจอ",
  i18nKey_enterprise_563: "เวลาของระบบติดตาม",
  i18nKey_enterprise_564: "จำนวนคำเตือน",
  i18nKey_enterprise_565: "พื้นที่เซี่ยงไฮ้",
  i18nKey_enterprise_566: "เซี่ยงไฮ้",
  i18nKey_enterprise_567: "Jinqiao Wanchuang",
  i18nKey_enterprise_568: "ชั้น",
  i18nKey_enterprise_569: "B2",
  i18nKey_enterprise_570: "ที่จอดรถ",
  i18nKey_enterprise_571: "กรุณากรอกคำอธิบายของกลุ่มลาดตระเวน",
  i18nKey_enterprise_572: "แขวน",
  i18nKey_enterprise_573: "จำนวนเหตุการณ์ทั้งหมด",
  i18nKey_enterprise_574: "จำนวนเหตุการณ์ที่รอดำเนินการ",
  i18nKey_enterprise_575: "จำนวนงานทั้งหมด",
  i18nKey_enterprise_576: "จำนวนงานที่รอดำเนินการ",
  i18nKey_enterprise_577: "ความปลอดภัย",
  i18nKey_enterprise_578: "ประมวลผลเสมอ",
  i18nKey_enterprise_579: "เหตุการณ์ความปลอดภัยทั้งหมด",
  i18nKey_enterprise_580: "บริษัทที่เกี่ยวกับการขับขี่",
  i18nKey_enterprise_581: "การจองของบริษัท",
  i18nKey_enterprise_582: "ความถี่",
  i18nKey_enterprise_583: "การขายหน้าร้าน",
  i18nKey_enterprise_584: "การขายอาหาร",
  i18nKey_enterprise_585: "สิ่งอำนวยความสะดวกที่ผิดปกติ",
  i18nKey_enterprise_586: "ส่งต่อ",
  i18nKey_enterprise_587: "เวลาอัปโหลด",
  i18nKey_enterprise_588: "เหตุการณ์",
  i18nKey_enterprise_589: "เวลาปิด",
  i18nKey_enterprise_590: "งานนี้ได้สร้างการแจ้งเตือน",
  i18nKey_enterprise_591: "ไม่สามารถแก้ไขได้!",
  i18nKey_enterprise_592: "งานกำลังเริ่มต้นขึ้น",
  i18nKey_enterprise_593: "กรุณาแก้ไขหลังจากหยุดชั่วคราว!",
  i18nKey_enterprise_594: "ยืนยันที่จะลบหรือไม่?",
  i18nKey_enterprise_595: "เวลาเริ่มและหยุด",
  i18nKey_enterprise_596: "เวลาลาดตระเวน",
  i18nKey_enterprise_597: "ปัญหา",
  i18nKey_enterprise_598: "ใช่",
  i18nKey_enterprise_599: "ไม่",
  i18nKey_enterprise_600: "ปิด",
  i18nKey_enterprise_601: "ประวัติเส้นทางลาดตระเวน",
  i18nKey_enterprise_602: "ประวัติการลาดตระเวนทั้งหมด",
  i18nKey_enterprise_603: "การแจ้งเตือน",
  i18nKey_enterprise_604: "ชื่อเหตุการณ์",
  i18nKey_enterprise_605: "ประสิทธิภาพ",
  i18nKey_enterprise_606: "พื้นที่จัดแสดง Vanke Wisdom",
  i18nKey_enterprise_607: "การแจ้งเตือนเหตุการณ์สำคัญ",
  i18nKey_enterprise_608: "park",
  i18nKey_enterprise_609: "Tianfu Yuncheng",
  i18nKey_enterprise_610:
    "สถานที่ตั้งโครงการตั้งอยู่ที่ Jinqiao Wanchuang Center",
  i18nKey_enterprise_611: "โรงรถใต้ดิน กำหนดค่า 3 ชุด 250",
  i18nKey_enterprise_612:
    "ระบบกักเก็บพลังงาน เต้ารับสำหรับเสียบปลั๊กไฟ ตั้งอยู่ที่ 10",
  i18nKey_enterprise_613: "ภายใต้ สาย A 2",
  i18nKey_enterprise_614: "หม้อแปลงไฟฟ้า 1000",
  i18nKey_enterprise_615: "ข้างรถบัส 10",
  i18nKey_enterprise_616: "สาย B 5",
  i18nKey_enterprise_617: "และข้างรถบัส 6",
  i18nKey_enterprise_618: "หม้อแปลงไฟฟ้า 2000",
  i18nKey_enterprise_619: "ข้างรถบัส",
  i18nKey_enterprise_620: "รายงานการอัดประจุและคายประจุทั้งหมด",
  i18nKey_enterprise_621: "การอัดประจุ",
  i18nKey_enterprise_622: "การคายประจุ",
  i18nKey_enterprise_623: "ส่วนข้อความที่แสดงในกรอบข้อความ",
  i18nKey_enterprise_624: "ขนาดกล่อง",
  i18nKey_enterprise_625: "นิ้ว",
  i18nKey_enterprise_626: "จำนวนระบบย่อย",
  i18nKey_enterprise_627: "ประเภทผู้ใช้",
  i18nKey_enterprise_628: "ความจุสูงสุด",
  i18nKey_enterprise_629: "กำลังไฟสูงสุด",
  i18nKey_enterprise_630: "ความจุหม้อแปลง",
  i18nKey_enterprise_631: "การเข้าถึงระดับแรงดันไฟฟ้า",
  i18nKey_enterprise_632: "การอัดประจุทั้งหมด",
  i18nKey_enterprise_633: "การคายประจุทั้งหมด",
  i18nKey_enterprise_634: "ยืนยันการดาวน์โหลด",
  i18nKey_enterprise_635: "รายงาน?",
  i18nKey_enterprise_636: "เมตร",
  i18nKey_enterprise_637: "จุดอ่าน",
  i18nKey_enterprise_638: "พลังงานที่ใช้",
  i18nKey_enterprise_639: "การใช้ไฟฟ้าของผู้เช่า",
  i18nKey_enterprise_640: "การเปลี่ยนแปลงการใช้พลังงาน",
  i18nKey_enterprise_641: "ค่าการใช้พลังงานที่แท้จริง",
  i18nKey_enterprise_642: "ค่าการใช้พลังงานเป้าหมาย",
  i18nKey_enterprise_643: "ค่าการใช้พลังงานที่คาดการณ์",
  i18nKey_enterprise_644: "ค่าที่ทำนาย: 1",
  i18nKey_enterprise_645:
    "ในมิติรายวัน การใช้พลังงานทั้งหมด การใช้พลังงานในพื้นที่สาธารณะและรายการย่อยระดับถัดไปของพื้นที่สาธารณะ ในมิติเดือนกุมภาพันธ์ การใช้พลังงานทั้งหมด การใช้พลังงานในพื้นที่สาธารณะ และรายการย่อยของพื้นที่สาธารณะ",
  i18nKey_enterprise_646: "ค่าเป้าหมาย: 1",
  i18nKey_enterprise_647:
    "ในมิติรายเดือน การใช้พลังงานทั้งหมดและการใช้พลังงานในพื้นที่สาธารณะ 2",
  i18nKey_enterprise_648:
    "ในมิติประจำปี การใช้พลังงานทั้งหมดและการใช้พลังงานในพื้นที่สาธารณะ",
  i18nKey_enterprise_649: "กรุณากรอกค่าเป้าหมาย",
  i18nKey_enterprise_650: "กรุณากรอกค่า",
  i18nKey_enterprise_651: "ค่าจริงรายปี",
  i18nKey_enterprise_652: "เดือน",
  i18nKey_enterprise_653: "ค่าเป้าหมายรายปี",
  i18nKey_enterprise_654: "เป้าหมายการใช้พลังงานในพื้นที่สาธารณะ",
  i18nKey_enterprise_655: "การใช้พลังงานในพื้นที่สาธารณะ",
  i18nKey_enterprise_656: "การใช้พลังงานต่อตารางเมตร",
  i18nKey_enterprise_657: "ภาพรวมของ park",
  i18nKey_enterprise_658: "กรุณาเลือกเวลา",
  i18nKey_enterprise_659: "ช่วงเดียวกันของปีที่แล้ว:",
  i18nKey_enterprise_660: "เดือนที่แล้ว:",
  i18nKey_enterprise_661: "เมื่อเทียบกับปีก่อน:",
  i18nKey_enterprise_662: "เมื่อเทียบกับเดือนที่แล้ว:",
  i18nKey_enterprise_663: "เดือนที่แล้ว",
  i18nKey_enterprise_664: "กราฟการเปลี่ยนแปลงการใช้พลังงาน",
  i18nKey_enterprise_665: "แนะนำ",
  i18nKey_enterprise_666: "เปลี่ยนแปลง",
  i18nKey_enterprise_667: "ประเภทเครื่องมือ",
  i18nKey_enterprise_668: "กรุณาเลือกประเภทมิเตอร์",
  i18nKey_enterprise_669: "รายการย่อยของเครื่องมือ",
  i18nKey_enterprise_670: "กรุณาเลือกรายการย่อยของเครื่องมือ",
  i18nKey_enterprise_671: "สถานะเครื่องมือ",
  i18nKey_enterprise_672: "หมายเลขมิเตอร์อัจฉริยะ",
  i18nKey_enterprise_673: "หมายเลขอุปกรณ์",
  i18nKey_enterprise_674: "เลือกขอบเขต",
  i18nKey_enterprise_675: "สิ้นสุด",
  i18nKey_enterprise_676: "ห้อง",
  i18nKey_enterprise_677: "กรุณาเลือกเครื่องมือก่อน",
  i18nKey_enterprise_678: "การบำรุงรักษามิเตอร์น้ำและไฟฟ้า",
  i18nKey_enterprise_679: "ยังไม่เปิด",
  i18nKey_enterprise_680: "เลขที่สัญญา",
  i18nKey_enterprise_681: "หมายเลขซีเรียลของเครื่องมือ",
  i18nKey_enterprise_682: "จำนวนสวิตช์ทั้งหมด",
  i18nKey_enterprise_683: "ด้านล่างของมิเตอร์",
  i18nKey_enterprise_684: "ช่วงเวลาที่ถูกต้อง",
  i18nKey_enterprise_685: "เคเบิ้ลสแควร์",
  i18nKey_enterprise_686: "ข้อมูลสถานที่",
  i18nKey_enterprise_687: "ร้านค้า",
  i18nKey_enterprise_688: "หมายเลขหน่วย",
  i18nKey_enterprise_689: "ข้อมูลผู้เช่า",
  i18nKey_enterprise_690: "สถานการณ์การอ่าน",
  i18nKey_enterprise_691:
    "ด้านล่างของมิเตอร์จะเปลี่ยนไปพร้อมกับการอ่านมิเตอร์ซึ่งเป็นการอ่านล่าสุด",
  i18nKey_enterprise_692: "การอ่านค่าปัจจุบัน",
  i18nKey_enterprise_693: "เวลาอ่านมิเตอร์ล่าสุด",
  i18nKey_enterprise_694: "log การเปลี่ยนแปลง",
  i18nKey_enterprise_695: "เวลาเปลี่ยนแปลงล่าสุด",
  i18nKey_enterprise_696: "หมายเลขมิเตอร์เดิมก่อนเปลี่ยนแปลง",
  i18nKey_enterprise_697: "การอ่านล่าสุด",
  i18nKey_enterprise_698: "ด้านล่างของมิเตอร์ก่อนเปลี่ยน",
  i18nKey_enterprise_699: "เวลาอ่านมิเตอร์ล่าสุด",
  i18nKey_enterprise_700: "การอ่านด้านล่างปัจจุบัน",
  i18nKey_enterprise_701:
    "หลังจากการลบ ความสัมพันธ์ของเครื่องมือจะถูกยกเลิกด้วย คุณต้องการลบหรือไม่?",
  i18nKey_enterprise_702: "กรุณาเลือกหมวดหมู่เครื่องมือ",
  i18nKey_enterprise_703: "ข้อมูลเครื่องมือ",
  i18nKey_enterprise_704: "กรุณาเลือกหมายเลขมิเตอร์",
  i18nKey_enterprise_705: "กรุณาเลือกหมายเลขมิเตอร์ก่อน",
  i18nKey_enterprise_706: "ค่าต้องเป็นบวกและจำนวนเต็ม",
  i18nKey_enterprise_707: "กรุณากรอกหมายเลขซีเรียลของเครื่องมือ",
  i18nKey_enterprise_708: "กรุณากรอกจำนวนสวิตช์ทั้งหมด",
  i18nKey_enterprise_709:
    "เมื่อกรอกข้อมูลด้านล่างของแบบฟอร์มแล้วจะไม่อนุญาตให้ทำการเปลี่ยนแปลงใด ๆ",
  i18nKey_enterprise_710: "กรุณากรอกด้านล่างของมิเตอร์",
  i18nKey_enterprise_711: "กรุณาเลือกช่วงเวลาที่ใช้ได้",
  i18nKey_enterprise_712: "กรุณากรอก ownership admin",
  i18nKey_enterprise_713: "กรุณากรอกเคเบิ้ลสแควร์",
  i18nKey_enterprise_714: "ownership",
  i18nKey_enterprise_715: "กรุณากรอก ownership",
  i18nKey_enterprise_716: "กรุณาค้นหาหรือเลือกรหัสอุปกรณ์",
  i18nKey_enterprise_717: "กรุณาเลือกรหัสอุปกรณ์ก่อน",
  i18nKey_enterprise_718: "กรุณาเข้าสู่ร้านค้า",
  i18nKey_enterprise_719: "กรุณาเลือกหมายเลขสัญญา",
  i18nKey_enterprise_720: "กรุณาค้นหาหรือเลือก",
  i18nKey_enterprise_721: "เลือกอุปกรณ์",
  i18nKey_enterprise_722: "เลือกเครื่องมือ",
  i18nKey_enterprise_723: "เครื่องมือนี้ไม่มีรายการย่อยของเครื่องมือ",
  i18nKey_enterprise_724: "แก้ไขสำเร็จ",
  i18nKey_enterprise_725: "รหัสเครื่องมือ",
  i18nKey_enterprise_726: "กรุณากรอกรหัสมิเตอร์",
  i18nKey_enterprise_727: "กรุณากรอกรหัสมิเตอร์อัจฉริยะ",
  i18nKey_enterprise_728: "กรุณากรอกประเภท",
  i18nKey_enterprise_729: "ชื่อผู้ใช้",
  i18nKey_enterprise_730: "ยอดชำระ",
  i18nKey_enterprise_731: "การจ่ายเงินบัตร park",
  i18nKey_enterprise_732: "การชำระเงินด้วยวีแชท",
  i18nKey_enterprise_733: "ใบแจ้งหนี้การใช้ไฟฟ้า",
  i18nKey_enterprise_734: "ค่าน้ำประปา",
  i18nKey_enterprise_735: "เริ่ียลไทม์",
  i18nKey_enterprise_736: "ปัญหาของวัน",
  i18nKey_enterprise_737: "เวลาจริง: การซิงค์ค่าให้ตรงกันทันที",
  i18nKey_enterprise_738: "ข้อมูล การชำระเงินของผู้ค้า หรือ",
  i18nKey_enterprise_739:
    "การชำระมัดจำล่วงหน้า จำนวนเงินที่เหลือจะเปลี่ยนแปลงทันที",
  i18nKey_enterprise_740: "จำนวนเงินที่เหลือที่ตรวจสอบโดยผู้ค้า",
  i18nKey_enterprise_741: "ปัญหารายวัน: อัพเดทวันละครั้งสำหรับ",
  i18nKey_enterprise_742:
    "ข้อมูลเมื่อวานนี้ สถิติของผู้ค้าที่มียอดเงินคงเหลือน้อยกว่าขั้นต่ำและไม่สามารถชำระบิลได้",
  i18nKey_enterprise_743: "ชื่อผู้ค้า",
  i18nKey_enterprise_744: "ประเภทผู้ค้า",
  i18nKey_enterprise_745: "ช่วงจำนวนเงิน",
  i18nKey_enterprise_746: "ยอดเงินมัดจำล่วงหน้า",
  i18nKey_enterprise_747: "จำนวนใบแจ้งหนี้ที่ยังไม่ได้ชำระ",
  i18nKey_enterprise_748: "6",
  i18nKey_enterprise_749: "ผู้ค้าแต่ละราย",
  i18nKey_enterprise_750: "ส่งออกเรียบร้อยแล้ว",
  i18nKey_enterprise_751: "ปิดเครื่อง",
  i18nKey_enterprise_752: "คืนค่า",
  i18nKey_enterprise_753: "เวลาของใบสั่งงาน",
  i18nKey_enterprise_754: "กรุณาเลือกเวลาออกตั๋ว",
  i18nKey_enterprise_755: "ช่วงของการค้างชำระ",
  i18nKey_enterprise_756: "กรุณากรอกหมายเลขหน่วย",
  i18nKey_enterprise_757: "เวลาค้าง",
  i18nKey_enterprise_758: "จำนวนเงินค้างชำระ",
  i18nKey_enterprise_759: "เลขที่สัญญา",
  i18nKey_enterprise_760: "หมายเลขเครื่องมือ",
  i18nKey_enterprise_761: "การประมวลผลล้มเหลว",
  i18nKey_enterprise_762: "ปิดเครื่องระยะไกล",
  i18nKey_enterprise_763: "ปิดเครื่องอีกครั้ง",
  i18nKey_enterprise_764: "รายละเอียดใบสั่งงาน",
  i18nKey_enterprise_765: "การกู้คืนด้วยตนเอง",
  i18nKey_enterprise_766: "จำนวนเงินชำระ",
  i18nKey_enterprise_767: "เวลาที่ชำระ",
  i18nKey_enterprise_768: "ดำเนินการต่อ",
  i18nKey_enterprise_769: "การปิดเสร็จสมบูรณ์",
  i18nKey_enterprise_770: "การกู้คืนไฟฟ้าขัดข้อง",
  i18nKey_enterprise_771:
    "หมายเหตุ:ใบสั่งงานสำหรับการปิดด้วยตนเองและการปิดระยะไกลจำเป็นต้องออกคำสั่งในการกู้คืนการปิดเครื่องก่อน!",
  i18nKey_enterprise_772:
    "หลังจากกู้คืนการปิดระยะไกลและการปิดด้วยตนเองแล้ว คุณต้องคลิกและคลิก 'ปิดเสร็จสมบูรณ์'",
  i18nKey_enterprise_773: "เพื่อให้การประมวลผลใบสั่งงานเสร็จสมบูรณ์ในที่สุด",
  i18nKey_enterprise_774: "ป้อนจำนวนเงินสูงสุดที่ค้างชำระไม่ถูกต้อง",
  i18nKey_enterprise_775: "ป้อนจำนวนเงินค้างชำระขั้นต่ำไม่ถูกต้อง",
  i18nKey_enterprise_776: "ป้อนช่วงจำนวนเงินค้างชำระไม่ถูกต้อง",
  i18nKey_enterprise_777: "ปิดตั๋วเรียบร้อย",
  i18nKey_enterprise_778: "สร้างตั๋วการกู้คืนสำเร็จแล้ว",
  i18nKey_enterprise_779: "ปิดเครื่องระยะไกลสำเร็จแล้ว",
  i18nKey_enterprise_780: "ใบสั่งงานเสร็จสมบูรณ์",
  i18nKey_enterprise_781: "รายละเอียดตั๋ว",
  i18nKey_enterprise_782: "สถิติตั้งแต่ต้นจนถึงเมื่อวานนี้",
  i18nKey_enterprise_783: "จำนวนการเติมเงินขึ้นอยู่กับการมาถึง",
  i18nKey_enterprise_784: "ค่าไฟ - เติมเงิน Yuncheng",
  i18nKey_enterprise_785: "ค่าน้ำ - เติมเงิน Yuncheng",
  i18nKey_enterprise_786: "ค่าไฟ",
  i18nKey_enterprise_787: "Prestore",
  i18nKey_enterprise_788: "ค่าน้ำประปา",
  i18nKey_enterprise_789: "จำนวนการชำระเงินที่ยังไม่ได้หัก",
  i18nKey_enterprise_790: "ใบแจ้งหนี้การใช้ไฟฟ้ายังไม่ถูกหัก",
  i18nKey_enterprise_791: "ใบแจ้งหนี้การใช้น้ำยังไม่ได้ถูกหัก",
  i18nKey_enterprise_792: "ภาพรวมของร้านค้าที่ค้างชำระ",
  i18nKey_enterprise_793: "การใช้พลังงานจริง",
  i18nKey_enterprise_794: "ปริมาณการใช้ไฟฟ้าทั้งหมดในใบแจ้งหนี้คือ",
  i18nKey_enterprise_795: "ปริมาณการใช้น้ำจริง",
  i18nKey_enterprise_796: "ปริมาณการใช้น้ำทั้งหมดในใบแจ้งหนี้คือ",
  i18nKey_enterprise_797:
    "สถิติการใช้ใบแจ้งหนี้ตั้งแต่เริ่มต้นจนถึงเมื่อวานนี้",
  i18nKey_enterprise_798: "ปริมาณการใช้น้ำ",
  i18nKey_enterprise_799: "แบบรายละเอียด",
  i18nKey_enterprise_800: "กรุณาเลือกหมายเลขสัญญา",
  i18nKey_enterprise_801: "กรุณากรอกหน่วย",
  i18nKey_enterprise_802: "จำนวนเงินตามเกณฑ์",
  i18nKey_enterprise_803: "ระยะเวลาของสัญญา",
  i18nKey_enterprise_804: "เกณฑ์การปิดเครื่อง (หยวน)",
  i18nKey_enterprise_805: "เกณฑ์มิเตอร์ (หยวน)",
  i18nKey_enterprise_806: "เกณฑ์มิเตอร์วัดน้ำ (หยวน)",
  i18nKey_enterprise_807:
    "หลังจากลบการตั้งค่าการแจ้งเตือนนี้ จะไม่มีการสร้างใบสั่งงานใหม่ แน่ใจหรือไม่ว่าต้องการลบ?",
  i18nKey_enterprise_808: "สวัสดีและยินดีต้อนรับเข้าสู่",
  i18nKey_enterprise_809: "Yuncheng",
  i18nKey_enterprise_810: "สแกนเพื่อดาวน์โหลด Yuncheng",
  i18nKey_enterprise_811: "สัญญาการให้บริการ",
  i18nKey_enterprise_812: "นโยบายความเป็นส่วนตัว",
  i18nKey_enterprise_813: "ยินดีต้อนรับสู่การใช้งาน",
  i18nKey_enterprise_814:
    "แพลตฟอร์ม กรุณาลงชื่อเข้าใช้บัญชีของคุณหลังจากเปลี่ยนรหัสผ่าน",
  i18nKey_enterprise_815: "ตัวเลข ตัวอักษร อักขระพิเศษบางตัว",
  i18nKey_enterprise_816: "ความปลอดภัยของรหัสผ่านไม่ตรงกัน",
  i18nKey_enterprise_817: "อย่างน้อย 8 ตัว",
  i18nKey_enterprise_818: "รวมตัวเลข",
  i18nKey_enterprise_819: "อักษรตัวพิมพ์ใหญ่และตัวพิมพ์เล็ก",
  i18nKey_enterprise_820: "อักขระพิเศษอย่าง 3 ประเภท",
  i18nKey_enterprise_821: "กรุณากรอกหมายเลขโทรศัพท์!",
  i18nKey_enterprise_822: "กรุณากรอกหมายเลขโทรศัพท์ที่ถูกต้อง!",
  i18nKey_enterprise_823: "โดยอัตโนมัติจะข้ามไปที่",
  i18nKey_enterprise_824: "หน้าเข้าสู่ระบบ",
  i18nKey_enterprise_825: "ไปที่",
  i18nKey_enterprise_826: "กรุณาลงทะเบียนแพลตฟอร์ม",
  i18nKey_enterprise_827: "เข้าสู่ระบบ",
  i18nKey_enterprise_828: "จดทะเบียน",
  i18nKey_enterprise_829: "รหัสยืนยัน",
  i18nKey_enterprise_830:
    "ประกอบด้วยอย่างน้อย 2 ประเภท ได้แก่ ตัวอักษร ตัวเลข และอักขระพิเศษ",
  i18nKey_enterprise_831: "กรุณากรอกหมายเลขโทรศัพท์",
  i18nKey_enterprise_832: "ความยาวรหัสผ่านคือ 8 ตัวอักษร",
  i18nKey_enterprise_833: "อักขระ Bit",
  i18nKey_enterprise_834: "แพลตฟอร์ม กรุณารีเซ็ตรหัสผ่านของคุณ",
  i18nKey_enterprise_835: "หรือศูนย์ปฏิบัติการอัจฉริยะ",
  i18nKey_enterprise_836: "สัญญาการให้บริการ",
  i18nKey_enterprise_837: "ตัวหนา",
  i18nKey_enterprise_838: "สี",
  i18nKey_enterprise_839: "เพิ่มลิงค์",
  i18nKey_enterprise_840: "ขนาดตัวอักษร",
  i18nKey_enterprise_841: "ตัวยก",
  i18nKey_enterprise_842: "ตัวห้อย",
  i18nKey_enterprise_843: "ใบเสนอราคา",
  i18nKey_enterprise_844: "เยื้อง",
  i18nKey_enterprise_845: "การจัดตำแหน่งข้อความ",
  i18nKey_enterprise_846: "การจัดทิศทางข้อความ",
  i18nKey_enterprise_847: "บล็อกรหัส",
  i18nKey_enterprise_848: "สูตร",
  i18nKey_enterprise_849: "ล้างรูปแบบตัวอักษร",
  i18nKey_enterprise_850: "ไฟล์",
  i18nKey_enterprise_851: "ออกจากการประชุมแล้ว",
  i18nKey_enterprise_852: "กรุณากรอกที่นี่",
  i18nKey_enterprise_853: "เยื้องขวา",
  i18nKey_enterprise_854: "เยื้องซ้าย",
  i18nKey_enterprise_855: "ขนาดภาพต้องไม่เกิน 3",
  i18nKey_enterprise_856: "อัปโหลดได้เท่านั้น",
  i18nKey_enterprise_857: "จัดรูปแบบรูปภาพ",
  i18nKey_enterprise_858: "กรุณากรอกข้อมูลการย้ายเข้าด้านล่าง",
  i18nKey_enterprise_859: "กรุณากรอกข้อมูลการย้ายออกด้านล่าง",
  i18nKey_enterprise_860: "กรุณาเลือกชื่อห้องประชุม",
  i18nKey_enterprise_861: "กรุณากรอกบริษัทที่ได้รับการรับรองแล้ว",
  i18nKey_enterprise_862: "กรุณาเลือกบริษัทที่ได้รับการรับรองแล้ว",
  i18nKey_enterprise_863: "กรุณาเลือกวันที่จอง",
  i18nKey_enterprise_864: "เวลาจอง",
  i18nKey_enterprise_865: "วาระการประชุม",
  i18nKey_enterprise_866: "กรุณากรอกหัวข้อการประชุม",
  i18nKey_enterprise_867: "กรุณากรอกจำนวนสั่งซื้อ",
  i18nKey_enterprise_868: "กรุณากรอกจำนวนเงินจริง",
  i18nKey_enterprise_869: "กรุณากรอกมากที่สุด 2",
  i18nKey_enterprise_870: "หนึ่งตัวอักษรจีนนับเป็นสองตัวอักษร",
  i18nKey_enterprise_871: "ใส่ 0 เท่านั้น",
  i18nKey_enterprise_872: "และตัวอักษรจีนหนึ่งตัวคือ 2 ตัวอักษร",
  i18nKey_enterprise_873: "กรุณาเลือกเวลาสิ้นสุดที่กำหนดไว้",
  i18nKey_enterprise_874: "วันที่กำหนด",
  i18nKey_enterprise_875: "WeChat",
  i18nKey_enterprise_876: "การชำระเงินต่อเนื่อง",
  i18nKey_enterprise_877: "ยกเลิกการชำระเงิน",
  i18nKey_enterprise_878: "ชำระเงินสำเร็จ",
  i18nKey_enterprise_879: "สถานะการชำระเงิน",
  i18nKey_enterprise_880: "เวลาชำระเงิน",
  i18nKey_enterprise_881: "สถานะการสั่งซื้อ",
  i18nKey_enterprise_882: "ข้อมูลผู้เข้าร่วม",
  i18nKey_enterprise_883: "รอการชำระบัญชี",
  i18nKey_enterprise_884: "เลิกกิจการ",
  i18nKey_enterprise_885: "ถอน",
  i18nKey_enterprise_886: "การถอนล้มเหลว",
  i18nKey_enterprise_887: "ปฏิเสธ",
  i18nKey_enterprise_888: "ผ่าน",
  i18nKey_enterprise_889: "ชื่อ",
  i18nKey_enterprise_890: "กรุณากรอกชื่อ",
  i18nKey_enterprise_891: "หมวดหมู่",
  i18nKey_enterprise_892: "กรุณาเลือกหมวดหมู่",
  i18nKey_enterprise_893: "จำเป็นต้องระบุชื่อ",
  i18nKey_enterprise_894: "ไม่มีข้อมูลกล้องใน park",
  i18nKey_enterprise_895: "แผนการลาดตระเวน",
  i18nKey_enterprise_896: "ปิดการลาดตระเวนแบบวิดีโอ",
  i18nKey_enterprise_897: "ไม่ว่าจะหยุดการลาดตระเวนแบบวิดีโอชั่วคราว",
  i18nKey_enterprise_898: "ไม่ว่าจะหยุดการลาดตระเวนแบบวิดีโอ",
  i18nKey_enterprise_899: "ไม่ได้รับการดูแลรักษา",
  i18nKey_enterprise_900: "รอดำเนินการ",
  i18nKey_enterprise_901: "เริ่ม",
  i18nKey_enterprise_902: "กรุณาเลือกประเภทพื้นที่",
  i18nKey_enterprise_903: "ห้องกิจกรรมที่ใช้ร่วมกัน",
  i18nKey_enterprise_904: "แก้ไขข้อมูลต้องปิดการใช้งานก่อน!",
  i18nKey_enterprise_905: "เวลาจอง",
  i18nKey_enterprise_906: "กรุณาเลือกเวลาจอง",
  i18nKey_enterprise_907: "หมายเลขโทรศัพท์ของผู้ใช้",
  i18nKey_enterprise_908: "การสมัคร",
  i18nKey_enterprise_909: "รายงายใบสั่งซื้อของสำนักงานที่ใช้ร่วมกัน",
  i18nKey_enterprise_910: "วิธีการเข้าถึง",
  i18nKey_enterprise_911: "เวลาขนส่ง",
  i18nKey_enterprise_912: "กรุณาเลือกเวลาขนส่ง",
  i18nKey_enterprise_913: "ปัด",
  i18nKey_enterprise_914: "บลูทูธ",
  i18nKey_enterprise_915: "จากระยะไกล",
  i18nKey_enterprise_916: "อินเตอร์คอม",
  i18nKey_enterprise_917: "เลือกกล้อง",
  i18nKey_enterprise_918: "เลือกช่วงเวลา",
  i18nKey_enterprise_919: "Zhongkonan",
  i18nKey_enterprise_920: "Huawei",
  i18nKey_enterprise_921: "ทั้งหมด",
  i18nKey_enterprise_922: "ป้ายเจ้าหน้าที่สำหรับสืบค้นผิดปกติ",
  i18nKey_enterprise_923: "กรุณาเลือกชั้นก่อน",
  i18nKey_enterprise_924: "รายชื่อบุคคล",
  i18nKey_enterprise_925: "การวิเคราะห์กลุ่มลูกค้า",
  i18nKey_enterprise_926: "กรุณาเลือกแท็บบุคคล",
  i18nKey_enterprise_927: "กรุณาเลือกกล้อง",
  i18nKey_enterprise_928: "เวลาสืบค้น",
  i18nKey_enterprise_929: "บริษัท",
  i18nKey_enterprise_930: "กรุณากรอกบริษัท",
  i18nKey_enterprise_931: "จำนวนคนทั้งหมด:",
  i18nKey_enterprise_932: "ผู้ใช้ที่ลงทะเบียนแล้ว",
  i18nKey_enterprise_933: "สถิติผู้เยี่ยมชม",
  i18nKey_enterprise_934: "ตาม park",
  i18nKey_enterprise_935: "จำนวนคนทั้งหมดใน park",
  i18nKey_enterprise_936: "ผู้เยี่ยมชมทั้งหมด",
  i18nKey_enterprise_937: "คนทั้งหมด",
  i18nKey_enterprise_938: "ประเภทบริการ",
  i18nKey_enterprise_939: "กรุณาเลือกประเภทบริการ",
  i18nKey_enterprise_940: "เวลาสมัคร",
  i18nKey_enterprise_941: "กรุณาเลือกเวลารับสมัคร",
  i18nKey_enterprise_942: "หมายเลขโทรศัพท์มือถือของผู้สมัคร",
  i18nKey_enterprise_943: "กรุณากรอกหมายเลขโทรศัพท์มือถือของผู้สมัคร",
  i18nKey_enterprise_944: "บริษัทของผู้สมัคร",
  i18nKey_enterprise_945: "กรุณากรอก บริษัทของผู้สมัคร",
  i18nKey_enterprise_946: "รายละเอียดสินค้า",
  i18nKey_enterprise_947: "หมายเลขบริการ",
  i18nKey_enterprise_948: "บุคคลที่สาม",
  i18nKey_enterprise_949: "กรุณากรอกบุคคลที่สาม",
  i18nKey_enterprise_950: "ห้องประชุมแบบแพลตฟอร์ม Morgan",
  i18nKey_enterprise_951: "กรุณาเข้าสู่ห้องประชุมแบบแพลตฟอร์ม Morgan",
  i18nKey_enterprise_952: "กรุณาเลือกแหล่งข้อมูล",
  i18nKey_enterprise_953: "กรุณากรอกมากที่สุด 1",
  i18nKey_enterprise_954: "อักษรจีนหนึ่งตัวนับเป็นสองอักขระ",
  i18nKey_enterprise_955: "และไม่มีช่องว่าง!",
  i18nKey_enterprise_956: "สถานการณ์",
  i18nKey_enterprise_957: "กรุณากรอกสถานการณ์",
  i18nKey_enterprise_958: "การกำหนดนโยบาย",
  i18nKey_enterprise_959: "กรุณาเลือกการตั้งค่านโยบาย",
  i18nKey_enterprise_960: "นาทีต้องไม่น้อยกว่า 2 นาทีและต้องไม่เกิน 10 นาที",
  i18nKey_enterprise_961: "ประเภทแหล่งข้อมูล",
  i18nKey_enterprise_962: "กลยุทธ์สถานการณ์",
  i18nKey_enterprise_963: "เปิดใช้งานสำเร็จแล้ว",
  i18nKey_enterprise_964: "ปิดใช้งานเรียบร้อยแล้ว",
  i18nKey_enterprise_965: "กรุณาเลือกสถานะการประชุม",
  i18nKey_enterprise_966: "ก่อนการประชุม: เริ่มประชุม",
  i18nKey_enterprise_967: "หลังจากเริ่มการประชุม: ยังไม่เริ่มประชุม",
  i18nKey_enterprise_968: "หลังจากเริ่มการประชุม: เริ่มประชุม",
  i18nKey_enterprise_969: "เวลาดำเนินการล่าสุด",
  i18nKey_enterprise_970: "เวลาธุรกิจที่กำหนดไว้",
  i18nKey_enterprise_971: "หลังการประชุม",
  i18nKey_enterprise_972: "เพิ่มกลยุทธ์สถานการณ์จำลองห้องประชุม",
  i18nKey_enterprise_973: "ปรับเปลี่ยนกลยุทธ์ scenario ของห้องประชุม",
  i18nKey_enterprise_974: "กรุณากรอกหมายเลขป้ายทะเบียน",
  i18nKey_enterprise_975: "พื้นที่ว่างในการจอดรถ",
  i18nKey_enterprise_976: "กรุณากรอกพื้นที่ว่างในการจอดรถ",
  i18nKey_enterprise_977: "ไม่มีรูปภาพ",
  i18nKey_enterprise_978: "การจัดการยานพาหนะ",
  i18nKey_enterprise_979: "การสืบค้นข้อมูลยานพาหนะ",
  i18nKey_enterprise_980:
    "ความแตกต่างระหว่างเวลาเริ่มต้นและเวลาสิ้นสุดต้องไม่เกิน 30 วัน",
  i18nKey_enterprise_981: "บันทึกการเข้าและออก",
  i18nKey_enterprise_982: "พื้นที่ว่างในการจอดรถทั้งหมด",
  i18nKey_enterprise_983: "จำนวนแพ็คเกจรถ",
  i18nKey_enterprise_984: "ค่าจอดรถ",
  i18nKey_enterprise_985: "ภายในไม่กี่ชั่วโมง",
  i18nKey_enterprise_986: "บันทึกการขับรถออกไป",
  i18nKey_enterprise_987: "บันทึกการขับรถเข้ามา",
  i18nKey_enterprise_988: "ไม่มีที่จอดรถ",
  i18nKey_enterprise_989: "ประเภทการสืบค้น",
  i18nKey_enterprise_990: "หมายเลขทะเบียนรถ",
  i18nKey_enterprise_991: "เลขที่การจอดรถ",
  i18nKey_enterprise_992: "กรุณากรอกหมายเลขป้ายทะเบียน",
  i18nKey_enterprise_993: "กรุณากรอกหมายเลขที่จอดรถ",
  i18nKey_enterprise_994: "ผลัก",
  i18nKey_enterprise_995: "กุญแจ",
  i18nKey_enterprise_996: "เวลาออกเดินทาง",
  i18nKey_enterprise_997: "ตั๋ว",
  i18nKey_enterprise_998: "ราคาต่อหน่วย",
  i18nKey_enterprise_999: "ไม่ว่าจะเป็นค่าบริการหลายขั้นตอน",
  i18nKey_enterprise_1000:
    "ตารางเดินรถบัสถูกเปิดใช้งานแล้ว กรุณาระงับการเปิดใช้งานก่อนดำเนินการ",
  i18nKey_enterprise_1001:
    "คุณต้องการลบตารางเดินรถบัสนี้หรือไม่? ไม่สามารถกู้คืนได้หลังจากลบแล้ว!",
  i18nKey_enterprise_1002: "ราคา",
  i18nKey_enterprise_1003: "ส่วนลด",
  i18nKey_enterprise_1004: "วันที่มีผลบังคับใช้",
  i18nKey_enterprise_1005: "กรุณากรอกชื่อธุรกิจ",
  i18nKey_enterprise_1006: "กรุณาเลือกช่วงเวลาสั่งซื้อ",
  i18nKey_enterprise_1007: "รายงานใบสั่งรถบัส",
  i18nKey_enterprise_1008: "พันล้าน",
  i18nKey_enterprise_1009: "สิบล้าน",
  i18nKey_enterprise_1010: "ล้าน",
  i18nKey_enterprise_1011:
    "คุณยังไม่ได้ติดตั้งโปรแกรมเล่นวิดีโอ คุณต้องการดาวน์โหลดปลั๊กอินโปรแกรมเล่นวิดีโอตอนนี้หรือไม่ หลังจากดาวน์โหลดแล้วกรุณาติดตั้งด้วยตัวคุณเอง",
  i18nKey_enterprise_1012: "ไม่มีผลลัพธ์กลับมา",
  i18nKey_enterprise_1013: "ไม่มีผลลัพธ์",
  i18nKey_enterprise_1014: "ระดับ 7",
  i18nKey_enterprise_1015: "ระดับ 8",
  i18nKey_enterprise_1016: "ระดับ 9",
  i18nKey_enterprise_1017: "ระดับ10",
  i18nKey_enterprise_1018: "ระดับ 11",
  i18nKey_enterprise_1019: "ระดับ 12",
  i18nKey_enterprise_1020: "หมายเลขระบบใบสมัคร",
  i18nKey_enterprise_1021: "กรุณาเลือกหมายเลขระบบใบสมัคร",
  i18nKey_enterprise_1022: "ประเภทพิกัด",
  i18nKey_enterprise_1023: "พิกัด",
  i18nKey_enterprise_1024: "กรุณาเลือกผู้รับผิดชอบในการจัดการ",
  i18nKey_enterprise_1025: "ไฟล์แนบรองรับเวอร์ชันใหม่เท่านั้น",
  i18nKey_enterprise_1026: "รูปแบบ",
  i18nKey_enterprise_1027: "ไม่ได้เปิดใช้งาน",
  i18nKey_enterprise_1028: "ที่อยู่",
  i18nKey_enterprise_1029: "ไม่มีข้อมูล",
  i18nKey_enterprise_1030: "ยืนยันการลบ",
  i18nKey_enterprise_1031: "ขนาดไฟล์ต้องไม่เกิน 20",
  i18nKey_enterprise_1032: "การจัดประเภทจะต้องเลือกถึงระดับที่สาม",
  i18nKey_enterprise_1033: "กรุณากรอกกฎหมาย",
  i18nKey_enterprise_1034: "ที่อยู่ เช่น 00-01-6",
  i18nKey_enterprise_1035: "หรือ 00",
  i18nKey_enterprise_1036: "พิกัด เช่น",
  i18nKey_enterprise_1037: "มีพิกัดอยู่แล้ว กรุณากรอกใหม่",
  i18nKey_enterprise_1038:
    "หมายเลขอุปกรณ์ระบบต้นทางต้องประกอบด้วยอักขระภาษาจีนและอังกฤษ และตัวเลข 50 ตัวเท่านั้น",
  i18nKey_enterprise_1039: "องค์ประกอบ",
  i18nKey_enterprise_1040:
    "โมเดลภายนอกต้องเป็นภาษาจีน อังกฤษตัวเลขและขีดล่างเท่านั้น และไม่เกิน 50 อักษร",
  i18nKey_enterprise_1041: "ค่าลองจิจูด คือ -180 ถึง 180",
  i18nKey_enterprise_1042: "ค่าละติจูด คือ -90 ถึง 90",
  i18nKey_enterprise_1043:
    "คำอธิบายสิ่งอำนวยความสะดวกสามารถเขียนได้เฉพาะในภาษาจีน ภาษาอังกฤษและตัวเลข",
  i18nKey_enterprise_1044: "ผู้ผลิตสามารถใช้ตัวเลขและภาษาจีนและอังกฤษเท่านั้น",
  i18nKey_enterprise_1045:
    "ผู้ให้บริการสามารถใช้ตัวเลขและภาษาจีนและอังกฤษเท่านั้น",
  i18nKey_enterprise_1046:
    "ผู้ให้บริการซ่อมบำรุงสามารถใช้ตัวเลขและภาษาจีนและอังกฤษเท่านั้น",
  i18nKey_enterprise_1047:
    "แบรนด์สามารถเขียนได้เฉพาะภาษาจีนและอังกฤษ และตัวเลขเท่านั้น",
  i18nKey_enterprise_1048: "รูปแบบที่รองรับ คือ",
  i18nKey_enterprise_1049: "และ",
  i18nKey_enterprise_1050: "การนำเข้าข้อมูลสิ่งอำนวยความสะดวก",
  i18nKey_enterprise_1051: "ชื่ออุปกรณ์",
  i18nKey_enterprise_1052: "รหัสระบบต้นทาง",
  i18nKey_enterprise_1053: "คำอธิบายระบบต้นทาง",
  i18nKey_enterprise_1054: "รหัสอุปกรณ์ระบบต้นทาง",
  i18nKey_enterprise_1055: "ชื่ออุปกรณ์กลุ่ม 1",
  i18nKey_enterprise_1056: "รหัสการจำแนกประเภทอุปกรณ์กลุ่ม1",
  i18nKey_enterprise_1057: "ชื่อกลุ่มของอุปกรณ์รอง",
  i18nKey_enterprise_1058: "รหัสการจำแนกประเภทอุปกรณ์รอง",
  i18nKey_enterprise_1059: "ชื่อหมวดหมู่อุปกรณ์กลุ่ม 3",
  i18nKey_enterprise_1060: "รหัสการจำแนกประเภทอุปกรณ์ 3 ระดับ",
  i18nKey_enterprise_1061: "การเข้ารหัสโมเดลเชิงพื้นที่",
  i18nKey_enterprise_1062: "อัปโหลดเรียบร้อยแล้ว",
  i18nKey_enterprise_1063: "หมายเลขแบทช์คือ",
  i18nKey_enterprise_1064: "กรุณาซิงค์ค่าให้ตรงกับตารางหลักในภายหลัง",
  i18nKey_enterprise_1065: "การอัพโหลดล้มเหลว!",
  i18nKey_enterprise_1066: "กรุณาอัปโหลดเวอร์ชันใหม่",
  i18nKey_enterprise_1067: "ส่งออกไม่สำเร็จ!",
  i18nKey_enterprise_1068: "หมายเลขแบทช์สำหรับการซิงค์ค่าให้ตรงกัน คือ",
  i18nKey_enterprise_1069: "ยืนยันการซิงค์ค่าให้ตรงกัน?",
  i18nKey_enterprise_1070:
    "ยืนยันที่จะซิงค์ค่าของข้อมูลสถานที่ทั้งหมดให้ตรงกันหรือไม่",
  i18nKey_enterprise_1071:
    "การซิงค์ค่าให้ตรงกันล้มเหลว ไม่มีข้อมูลสิ่งอำนวยความสะดวกให้ซิงค์ค่าให้ตรงกันชั่วคราว!",
  i18nKey_enterprise_1072: "การซิงค์ค่าให้ตรงกันสำเร็จ!",
  i18nKey_enterprise_1073: "การซิงค์ค่าให้ตรงกันล้มเหลว!",
  i18nKey_enterprise_1074: "ระดับการจำแนกประเภทสิ่งอำนวยความสะดวก",
  i18nKey_enterprise_1075: "เพิ่มระดับ",
  i18nKey_enterprise_1076: "ความสำคัญของสิ่งอำนวยความสะดวก",
  i18nKey_enterprise_1077:
    "รหัสการจำแนกประเภทสิ่งอำนวยความสะดวกต้องประกอบด้วยตัวเลข ตัวอักษร และขีดล่างเท่านั้น",
  i18nKey_enterprise_1078: "กรุณากรอกระดับที่จะเพิ่ม",
  i18nKey_enterprise_1079:
    "กรุณากรอกข้อมูลให้ครบถ้วน คุณไม่สามารถเพิ่มข้อมูลข้ามระดับได้",
  i18nKey_enterprise_1080: "กรุณากรอกรหัสหมวดหมู่หรือชื่อหมวดหมู่ที่เกี่ยวข้อง",
  i18nKey_enterprise_1081: "จำเป็นต้องระบุรหัสหมวดหมู่และชื่อหมวดหมู่",
  i18nKey_enterprise_1082: "กรุณากรอกรหัส park",
  i18nKey_enterprise_1083: "Microsoft Accor black",
  i18nKey_enterprise_1084: "ดูองค์กร",
  i18nKey_enterprise_1085: "วิธีการสร้าง",
  i18nKey_enterprise_1086: "การป้อนข้อมูลด้วยตนเอง",
  i18nKey_enterprise_1087: "การนำเข้าแบทช์",
  i18nKey_enterprise_1088: "กรุณากรอกชื่อภาษาอังกฤษของบริษัท",
  i18nKey_enterprise_1089: "เลขทะเบียนใบอนุญาตประกอบกิจการ",
  i18nKey_enterprise_1090: "รหัสเครดิตทางสังคม",
  i18nKey_enterprise_1091: "ลากไฟล์มาที่นี่หรือ",
  i18nKey_enterprise_1092: "คลิกอัปโหลด",
  i18nKey_enterprise_1093: "รองรับเฉพาะไฟล์เท่านั้น",
  i18nKey_enterprise_1094: "กรุณากรอกหมายเลขประจำตัวประชาชนในรูปแบบที่ถูกต้อง",
  i18nKey_enterprise_1095: "ความยาวสูงสุด คือ 50 อักขระ",
  i18nKey_enterprise_1096: "ธุรกิจใหม่",
  i18nKey_enterprise_1097: "ปรับเปลี่ยนองค์กร",
  i18nKey_enterprise_1098: "ไฟล์และคำต่อท้ายคือ",
  i18nKey_enterprise_1099: "รวม",
  i18nKey_enterprise_1100: ", ความสำเร็จ",
  i18nKey_enterprise_1101: ", ข้อผิดพลาด",
  i18nKey_enterprise_1102: "เชื่อมต่อกับหน้าจอขนาดใหญ่",
  i18nKey_enterprise_1103: "สามารถเชื่อมโยงหน้าจอขนาดใหญ่ได้",
  i18nKey_enterprise_1104: "ยกเลิกการเชื่อมต่อเรียบร้อยแล้ว",
  i18nKey_enterprise_1105: "เลือกหมวดหมู่",
  i18nKey_enterprise_1106: "หมวดหมู่ที่ 8",
  i18nKey_enterprise_1107: "หมวดหมู่ที่ 9",
  i18nKey_enterprise_1108: "กรุณาเลือกหมวดหมู่",
  i18nKey_enterprise_1109: "กรุณาเลือกหมวดหมู่ที่ 2",
  i18nKey_enterprise_1110: "กรุณาเลือกหมวดหมู่ที่ 3",
  i18nKey_enterprise_1111: "กรุณาเลือกหมวดหมู่ที่ 4",
  i18nKey_enterprise_1112: "กรุณาเลือกหมวดหมู่ที่ 5",
  i18nKey_enterprise_1113: "กรุณาเลือกหมวดหมู่ที่ 6",
  i18nKey_enterprise_1114: "เลือกสถานที่",
  i18nKey_enterprise_1115: "ไม่สามารถค้นหารายชื่อกลุ่ม",
  i18nKey_enterprise_1116: "อาคาร",
  i18nKey_enterprise_1117: "ต้องระบุ park",
  i18nKey_enterprise_1118: "ต้องระบุชั้น",
  i18nKey_enterprise_1119: "ชื่อไฟล์",
  i18nKey_enterprise_1120: "วิธีการสร้างสิ่งอำนวยความสะดวก",
  i18nKey_enterprise_1121: "กรุณากรอกหมายเลขพอร์ต",
  i18nKey_enterprise_1122: "กรุณากรอกชื่อผู้ใช้",
  i18nKey_enterprise_1123: "ต้องระบุที่อยู่!",
  i18nKey_enterprise_1124: "รูปแบบที่อยู่ไม่ถูกต้อง!",
  i18nKey_enterprise_1125: "ต้องระบุหมายเลขพอร์ต!",
  i18nKey_enterprise_1126: "รูปแบบหมายเลขพอร์ตไม่ถูกต้อง!",
  i18nKey_enterprise_1127: "กรุณากรอกชื่อขอบเขต",
  i18nKey_enterprise_1128: "กรุณากรอกรหัสขอบเขต",
  i18nKey_enterprise_1129: "การกำหนดค่าสำเร็จ",
  i18nKey_enterprise_1130: "เลือกวิทยาเขต",
  i18nKey_enterprise_1131: "เลือกระบบย่อย",
  i18nKey_enterprise_1132: "นัดเยี่ยมชม",
  i18nKey_enterprise_1133: "กรุณาเลือกระบบย่อยของ park",
  i18nKey_enterprise_1134: "กรุณาเลือกบุคคลที่จะนัดหมาย",
  i18nKey_enterprise_1135: "กรุณาเลือกเวลาออกเดินทางโดยประมาณ",
  i18nKey_enterprise_1136: "กรุณาเลือกสถานะการนัดหมาย",
  i18nKey_enterprise_1137: "กรุณาเลือกเวลาเยี่ยมชม",
  i18nKey_enterprise_1138: "กรุณาเลือกเหตุผลในการเยี่ยมชม",
  i18nKey_enterprise_1139: "เลือกบ้านเกิด",
  i18nKey_enterprise_1140: "จังหวัด",
  i18nKey_enterprise_1141: "กรุณาเลือกจังหวัด",
  i18nKey_enterprise_1142: "เลือกแผนก",
  i18nKey_enterprise_1143: "ชื่อกลุ่ม",
  i18nKey_enterprise_1144: "กรุณากรอกชื่อกลุ่ม",
  i18nKey_enterprise_1145: "รายละเอียดกลุ่ม",
  i18nKey_enterprise_1146: "ใส่ได้ 100 ตัวอักษร",
  i18nKey_enterprise_1147: "เพิ่มกลุ่ม",
  i18nKey_enterprise_1148: "จำเป็นต้องระบุชื่อกลุ่ม",
  i18nKey_enterprise_1149: "มีชื่อกลุ่มนี้แล้ว",
  i18nKey_enterprise_1150: "จำเป็นต้องระบุคำอธิบายกลุ่ม!",
  i18nKey_enterprise_1151: "แก้ไขข้อมูลกลุ่ม",
  i18nKey_enterprise_1152: "ข้อมูลกลุ่ม",
  i18nKey_enterprise_1153: "ข้อมูลผู้ใช้",
  i18nKey_enterprise_1154: "แผนกผู้ใช้",
  i18nKey_enterprise_1155: "บทบาทของผู้ใช้",
  i18nKey_enterprise_1156: "กลุ่มผู้ใช้",
  i18nKey_enterprise_1157: "แก้ไขข้อมูลผู้ใช้",
  i18nKey_enterprise_1158: "สามารถใส่อักขระได้ 500 ตัว",
  i18nKey_enterprise_1159: "เพิ่มผู้ใช้",
  i18nKey_enterprise_1160: "โหลดเพิ่มเติม",
  i18nKey_enterprise_1161: "กรุณาเลือกผู้ใช้เพื่อเพิ่ม!",
  i18nKey_enterprise_1162: "ไม่มีอีกแล้ว",
  i18nKey_enterprise_1163: "ผู้ใช้",
  i18nKey_enterprise_1164: "กลุ่ม",
  i18nKey_enterprise_1165: "ไม่มีผู้ใช้",
  i18nKey_enterprise_1166: "ไม่มีกลุ่ม",
  i18nKey_enterprise_1167: "ดูรายชื่อกลุ่ม",
  i18nKey_enterprise_1168: "กรุณากรอกชื่อผู้ใช้",
  i18nKey_enterprise_1169: "ค้นหาหมายเลขโทรศัพท์",
  i18nKey_enterprise_1170: "เพิ่มกลุ่ม",
  i18nKey_enterprise_1171: "รายชื่อกลุ่ม",
  i18nKey_enterprise_1172: "คุณแน่ใจหรือไม่ว่าจะลบ ?",
  i18nKey_enterprise_1173: "ไม่มีรายการบทบาท",
  i18nKey_enterprise_1174: "กรุณาเลือกผู้ใช้ที่จะลบ",
  i18nKey_enterprise_1175: "กรุณาเลือกกลุ่มที่จะลบ",
  i18nKey_enterprise_1176: "กรุณาเลือกผู้ใช้ที่จะเพิ่ม",
  i18nKey_enterprise_1177: "กรุณาเลือกกลุ่มที่จะเพิ่ม",
  i18nKey_enterprise_1178: "ไม่มีข้อมูลเพิ่มเติม",
  i18nKey_enterprise_1179: "เลือกยี่ห้อยานพาหนะ",
  i18nKey_enterprise_1180: "เลือกยานพาหนะ",
  i18nKey_enterprise_1181: "เลือกเส้นทางการจัดประเภท",
  i18nKey_enterprise_1182: "ข้อมูลพื้นฐานของยานพาหนะ",
  i18nKey_enterprise_1183: "รหัสการจำแนกประเภทยานพาหนะ",
  i18nKey_enterprise_1184: "ป้ายทะเบียนรถ",
  i18nKey_enterprise_1185: "เลขที่ใบขับขี่",
  i18nKey_enterprise_1186: "ชื่อประเภทยานพาหนะ",
  i18nKey_enterprise_1187: "รหัสการจำแนกผู้ปกครองของยานพาหนะ",
  i18nKey_enterprise_1188: "รหัสเจ้าของ",
  i18nKey_enterprise_1189: "เวลาเริ่มมีผลบังคับใช้ของป้าย",
  i18nKey_enterprise_1190: "เวลาหมดอายุของป้าย",
  i18nKey_enterprise_1191: "ตราสัญลักษณ์ที่ถูกต้อง",
  i18nKey_enterprise_1192: "ปรับปรุงล่าสุดโดย",
  i18nKey_enterprise_1193: "กรุณากรอกป้ายทะเบียน",
  i18nKey_enterprise_1194: "กรุณาเลือกป้ายทะเบียน",
  i18nKey_enterprise_1195: "กรุณากรอกสียานพาหนะ",
  i18nKey_enterprise_1196: "กรุณากรอกหมายเลขใบอนุญาตขับขี่",
  i18nKey_enterprise_1197: "กรุณากรอกเส้นทางแบบเต็มของประเภทยานพาหนะ",
  i18nKey_enterprise_1198: "กรุณากรอกชื่อหมวดหมู่รถ",
  i18nKey_enterprise_1199: "กรุณากรอกรหัสบุคคล",
  i18nKey_enterprise_1200: "กรุณากรอกหน่วย",
  i18nKey_enterprise_1201: "ไม่ถูกต้อง",
  i18nKey_enterprise_1202: "จำเป็นต้องระบุหมายเลขป้ายทะเบียน!",
  i18nKey_enterprise_1203: "จำเป็นต้องระบุสีรถ!",
  i18nKey_enterprise_1204: "จำเป็นต้องระบุเส้นทางของประเภทยานพาหนะทั้งหมด!",
  i18nKey_enterprise_1205: "จำเป็นต้องระบุชื่อหมวดหมู่ยานพาหนะ!",
  i18nKey_enterprise_1206: "จำเป็นต้องระบุชื่อเจ้าของ!",
  i18nKey_enterprise_1207: "จำเป็นต้องระบุความเป็นเจ้าของ!",
  i18nKey_enterprise_1208: "จำเป็นต้องระบุป้ายรถ!",
  i18nKey_enterprise_1209: "จำเป็นต้องระบุเวลาที่มีผลบังคับใช้!",
  i18nKey_enterprise_1210: "จำเป็นต้องระบุเวลาหมดอายุ!",
  i18nKey_enterprise_1211: "จำเป็นต้องระบุรหัสที่ถูกต้อง!",
  i18nKey_enterprise_1212: "กรุณากรอกป้ายทะเบียนที่ถูกต้อง!",
  i18nKey_enterprise_1213: "หมายเลขใบขับขี่หรือรหัสบุคลากรต้องไม่มีภาษาจีน!",
  i18nKey_enterprise_1214: "สีน้ำเงิน",
  i18nKey_enterprise_1215: "เวลาสิ้นสุดของแผน 222",
  i18nKey_enterprise_1216: "เวลาสิ้นสุดควรมากกว่าเวลาเริ่มต้น",
  i18nKey_enterprise_1217: "ฉันเลือกภายหลัง",
  i18nKey_enterprise_1218: "วันที่เริ่มต้น",
  i18nKey_enterprise_1219: "วันที่สิ้นสุด",
  i18nKey_enterprise_1220: "บันทึกการบำรุงรักษาสิ่งอำนวยความสะดวก",
  i18nKey_enterprise_1221: "บันทึกการดำเนินการในอดีต",
  i18nKey_enterprise_1222: "บันทึกการเตือนภัยสิ่งอำนวยความสะดวก",
  i18nKey_enterprise_1223: "เวลาเริ่มต้นและเวลาสิ้นสุดการบำรุงรักษา",
  i18nKey_enterprise_1224: "แผนกซ่อมบำรุง",
  i18nKey_enterprise_1225: "หวังเสี่ยวหู่",
  i18nKey_enterprise_1226: "ถนนจินซาเจียง เขตผู่โถว เซี่ยงไฮ้",
  i18nKey_enterprise_1227: "Long",
  i18nKey_enterprise_1228: "ดี",
  i18nKey_enterprise_1229: "ฝ่ายวิศวกรรม",
  i18nKey_enterprise_1230: "จำเป็นต้องระบุเวลาค้นหา!",
  i18nKey_enterprise_1231: "สิ่งอำนวยความสะดวกในชั้นเรียน",
  i18nKey_enterprise_1232: "ข้อหลัก:",
  i18nKey_enterprise_1233: "สามัญ:",
  i18nKey_enterprise_1234: "ส่ง SMS 2",
  i18nKey_enterprise_1235: "วิธีการเริ่มต้น",
  i18nKey_enterprise_1236: "คู่มือ",
  i18nKey_enterprise_1237: "อัตโนมัติ",
  i18nKey_enterprise_1238: "การตั้งอุณหภูมิ",
  i18nKey_enterprise_1239: "อุณหภูมิที่ตั้งไว้",
  i18nKey_enterprise_1240: "อุณหภูมิอากาศกลับ",
  i18nKey_enterprise_1241: "ความแตกต่างของเครื่องกรองแรงดัน",
  i18nKey_enterprise_1242: "การควบคุมวาล์วน้ำ",
  i18nKey_enterprise_1243: "การตรวจสอบพลังงาน",
  i18nKey_enterprise_1244: "คำสั่งพัดลม",
  i18nKey_enterprise_1245: "ระดับการแจ้งเตือน",
  i18nKey_enterprise_1246: "การทำงานของสัญญาณเตือน",
  i18nKey_enterprise_1247: "ผู้ให้บริการอุปกรณ์",
  i18nKey_enterprise_1248: "คำอธิบายอุปกรณ์",
  i18nKey_enterprise_1249: "แบรนด์อุปกรณ์",
  i18nKey_enterprise_1250: "โรงงานผลิตอุปกรณ์",
  i18nKey_enterprise_1251: "บริการซ่อมบำรุงอุปกรณ์",
  i18nKey_enterprise_1252: "เวลาเริ่มต้นสิ่งอำนวยความสะดวก",
  i18nKey_enterprise_1253: "การหยุดทำงานของสิ่งอำนวยความสะดวก",
  i18nKey_enterprise_1254: "ระยะเวลาการทำงานในครั้งนี้",
  i18nKey_enterprise_1255: "วันที่ซ่อมบำรุง",
  i18nKey_enterprise_1256: "ผู้ดูแลงานซ่อมบำรุง",
  i18nKey_enterprise_1257: "เหตุผลในการซ่อมบำรุง",
  i18nKey_enterprise_1258: "เนื้อหาการซ่อมบำรุง",
  i18nKey_enterprise_1259: "ผลการซ่อมบำรุง",
  i18nKey_enterprise_1260: "บันทึกการเตือนภัยสิ่งอำนวยความสะดวก",
  i18nKey_enterprise_1261: "บันทึกการบำรุงรักษาสิ่งอำนวยความสะดวก",
  i18nKey_enterprise_1262: "เปลี่ยนการตั้งค่า",
  i18nKey_enterprise_1263: "เปิดไฟ",
  i18nKey_enterprise_1264: "ปิดไฟ",
  i18nKey_enterprise_1265: "ใบสมัคร",
  i18nKey_enterprise_1266:
    "ออกคำสั่งสำเร็จและสถานะจะอัปเดตในเวลาประมาณ 30 วินาที กรุณารอซักครู่",
  i18nKey_enterprise_1267: "ลดแสง",
  i18nKey_enterprise_1268: "คำแนะนำ: ความสว่างจะมีผล เมื่อไฟสว่างเท่านั้น",
  i18nKey_enterprise_1269: "เหตุการณ์สำคัญ",
  i18nKey_enterprise_1270: "เหตุการณ์ทั่วไป",
  i18nKey_enterprise_1271: "ติดตามตอนนี้",
  i18nKey_enterprise_1272: "ระบบควบคุมการเข้าถึง",
  i18nKey_enterprise_1273: "ภาพวิดีโอ",
  i18nKey_enterprise_1274: "รูปภาพที่เกี่ยวข้อง",
  i18nKey_enterprise_1275: "อัปโหลด",
  i18nKey_enterprise_1276: "วิดีโอที่เกี่ยวข้อง",
  i18nKey_enterprise_1277: "การปรับใช้ที่เกี่ยวข้อง",
  i18nKey_enterprise_1278: "เพิ่มการควบคุมการปรับใช้",
  i18nKey_enterprise_1279: "นอกเหนือความสามารถในการควบคุม",
  i18nKey_enterprise_1280: "รูปภาพการควบคุม",
  i18nKey_enterprise_1281: "ระบบต้นทาง",
  i18nKey_enterprise_1282: "สลายฝูงชน",
  i18nKey_enterprise_1283: "เพื่อได้รับการยอมรับ",
  i18nKey_enterprise_1284: "กลับไปที่พื้นหลังของกล้องที่เกี่ยวข้อง",
  i18nKey_enterprise_1285: "กลับไปที่พื้นหลังของกล้องรอบข้าง",
  i18nKey_enterprise_1286: "เพิ่มกล้องที่เกี่ยวข้อง กลับไปที่พื้นหลัง",
  i18nKey_enterprise_1287: "ลบกล้องที่เกี่ยวข้อง กลับไปที่พื้นหลัง",
  i18nKey_enterprise_1288:
    "คำแนะนำ: เหตุการณ์จะปิดโดยอัตโนมัติ หลังจากประมวลผลงานที่จัดส่งแล้ว",
  i18nKey_enterprise_1289: "การแจกจ่ายงาน",
  i18nKey_enterprise_1290: "งานใหม่เสร็จสมบูรณ์",
  i18nKey_enterprise_1291: "การกระจายงาน",
  i18nKey_enterprise_1292: "มอบหมายงาน",
  i18nKey_enterprise_1293: "ระยะทาง",
  i18nKey_enterprise_1294: "กรุณาเลือกบุคคลที่จะมอบหมายงาน",
  i18nKey_enterprise_1295: "กรุณากรอกคำหลักเพื่อค้นหา",
  i18nKey_enterprise_1296: "กรุณากรอกเนื้อหางาน",
  i18nKey_enterprise_1297: "เพิ่มงานสำเร็จแล้ว",
  i18nKey_enterprise_1298: "การหลอกลวงโดยผู้มีชื่อเสียงทางอินเทอร์เน็ต",
  i18nKey_enterprise_1299: "Hyun Bin",
  i18nKey_enterprise_1300: "กล้อง 002",
  i18nKey_enterprise_1301: "กล้อง 003",
  i18nKey_enterprise_1302: "กล้อง 004",
  i18nKey_enterprise_1303: "กล้อง 005",
  i18nKey_enterprise_1304: "รายงานเหตุการณ์โดยอัตโนมัติ",
  i18nKey_enterprise_1305: "แบบ",
  i18nKey_enterprise_1306: "Zhang San",
  i18nKey_enterprise_1307: "ภาคเหนือ",
  i18nKey_enterprise_1308: "ภาคใต้",
  i18nKey_enterprise_1309: "ภาคตะวันตกตอนกลาง",
  i18nKey_enterprise_1310: "เลือกหน้าจอต้อนรับ",
  i18nKey_enterprise_1311: "กล้องที่เลือก",
  i18nKey_enterprise_1312: "จำนวนกล้องออนไลน์",
  i18nKey_enterprise_1313: "จำนวนกล้องทั้งหมด",
  i18nKey_enterprise_1314: "ป้อนชื่อกล้อง",
  i18nKey_enterprise_1315: "ต้องป้อนชื่อเหตุการณ์",
  i18nKey_enterprise_1316: "กรุณากรอกคำอธิบายเหตุการณ์",
  i18nKey_enterprise_1317: "Vanke Chengdu Park",
  i18nKey_enterprise_1318: "อาคาร 103",
  i18nKey_enterprise_1319: "การรายงานเหตุการณ์ล้มเหลว",
  i18nKey_enterprise_1320: "ภาพได้รับการบันทึกแล้วที่",
  i18nKey_enterprise_1321: "การจับภาพล้มเหลว",
  i18nKey_enterprise_1322: "รหัสข้อผิดพลาด:",
  i18nKey_enterprise_1323: "จำนวนกลุ่มลาดตระเวน",
  i18nKey_enterprise_1324: "กลุ่มลาดตระเวนใหม่",
  i18nKey_enterprise_1325: "รายการวิดีโอลาดตระเวน",
  i18nKey_enterprise_1326: "ประวัติวิดีโอลาดตระเวน",
  i18nKey_enterprise_1327: "สิ้นสุด",
  i18nKey_enterprise_1328: "หมวดหมู่อุปกรณ์",
  i18nKey_enterprise_1329: "ผู้ดูแลเหตุการณ์",
  i18nKey_enterprise_1330: "หมายเลขบัตรผู้ใช้",
  i18nKey_enterprise_1331: "โทรศัพท์มือถือของผู้ดูแล",
  i18nKey_enterprise_1332: "วิธีแก้ไข",
  i18nKey_enterprise_1333: "บัญชีผู้ใช้",
  i18nKey_enterprise_1334: "ประเภทการดูแลรักษา",
  i18nKey_enterprise_1335: "โทรศัพท์บ้านของผู้ดูแล",
  i18nKey_enterprise_1336: "ประเภทการควบคุม",
  i18nKey_enterprise_1337: "บันทึกเหตุการณ์",
  i18nKey_enterprise_1338: "อัปโหลดได้สูงสุด 5 ภาพเท่านั้น",
  i18nKey_enterprise_1339: "ขนาดภาพต้องไม่เกิน 2",
  i18nKey_enterprise_1340: "จัดรูปแบบรูปภาพ",
  i18nKey_enterprise_1341: "ชื่องานต้องไม่เกิน 50 อักขระ",
  i18nKey_enterprise_1342: "การติดตามใบหน้า",
  i18nKey_enterprise_1343: "เหตุผลในการปรับใช้งาน",
  i18nKey_enterprise_1344:
    "กรุณากรอกเหตุผลในการปรับใช้งาน ต้องไม่เกิน 150 อักขระ",
  i18nKey_enterprise_1345: "ชื่อ:",
  i18nKey_enterprise_1346: "ระยะเวลา",
  i18nKey_enterprise_1347: "ความคล้ายคลึงกัน",
  i18nKey_enterprise_1348: "รูป",
  i18nKey_enterprise_1349: "เพื่อกดหน้าต่างป๊อปอัป",
  i18nKey_enterprise_1350: "กรุณากรอกประกาศคุณสมบัติ",
  i18nKey_enterprise_1351: "แจ้งเจ้าหน้าที่",
  i18nKey_enterprise_1352: "ชื่อ",
  i18nKey_enterprise_1353: "กรุณากรอกชื่อหรือหมายเลขโทรศัพท์ของคุณ",
  i18nKey_enterprise_1354: "เหตุผลในการปรับใช้งาน ต้องไม่เกิน 150 อักษร",
  i18nKey_enterprise_1355: "เลือกจากคลังรถ",
  i18nKey_enterprise_1356: "การเลือกคลังยานพาหนะ",
  i18nKey_enterprise_1357: "วิถีของยานพาหนะ",
  i18nKey_enterprise_1358: "ดูคำเตือน",
  i18nKey_enterprise_1359: "สถานที่:",
  i18nKey_enterprise_1360: "ที่มา:",
  i18nKey_enterprise_1361: "เกินมูลค่าเป้าหมาย",
  i18nKey_enterprise_1362: "จำนวนสัญญาณเตือนทั้งหมด",
  i18nKey_enterprise_1363: "กลางแจ้ง",
  i18nKey_enterprise_1364: "ความชื้น",
  i18nKey_enterprise_1365: "พื้นที่สิ่งแวดล้อม",
  i18nKey_enterprise_1366: "คาร์บอนไดออกไซด์",
  i18nKey_enterprise_1367: "ฟอร์มาลดีไฮด์",
  i18nKey_enterprise_1368: "กรอบ",
  i18nKey_enterprise_1369: "ไอออนของออกซิเจนเชิงลบ",
  i18nKey_enterprise_1370: "เสียงดัง",
  i18nKey_enterprise_1371: "สารระเหยอินทรีย์",
  i18nKey_enterprise_1372: "ดัชนีสิ่งแวดล้อม",
  i18nKey_enterprise_1373: "สถิติโคมไฟถนน",
  i18nKey_enterprise_1374: "ความคืบหน้าของงาน",
  i18nKey_enterprise_1375: "ชื่อเรื่องพื้นที่สิ่งแวดล้อม",
  i18nKey_enterprise_1376: "ไม่ได้ตั้งค่า",
  i18nKey_enterprise_1377: "ไม่ได้ใช้งาน",
  i18nKey_enterprise_1378: "คำอธิบายใบสั่งงาน",
  i18nKey_enterprise_1379: "กรุณากรอกคำอธิบายใบสั่งงาน",
  i18nKey_enterprise_1380: "ส่งใบสั่งงานเสร็จสมบูรณ์",
  i18nKey_enterprise_1381: "กรุณาเลือกบุคคลเพื่อมอบหมายตั๋ว",
  i18nKey_enterprise_1382: "กรุณากรอกเนื้อหาตั๋ว",
  i18nKey_enterprise_1383: "นายหลี่",
  i18nKey_enterprise_1384: "ผู้รับผิดชอบในการสั่งงาน:",
  i18nKey_enterprise_1385: "ค่าธรรมเนียมการดำเนินการ:",
  i18nKey_enterprise_1386: "ระยะเวลาดำเนินการ:",
  i18nKey_enterprise_1387: "กำลังดำเนินการ:",
  i18nKey_enterprise_1388: "ผู้ดำเนินการซ่อมแซม",
  i18nKey_enterprise_1389: "เวลาอยู่บ้าน",
  i18nKey_enterprise_1390: "กระจาย",
  i18nKey_enterprise_1391: "ป้อนได้สูงสุด 100 อักขระ",
  i18nKey_enterprise_1392: "ชื่อการซ่อมแซม",
  i18nKey_enterprise_1393: "สถานะการซ่อมแซม",
  i18nKey_enterprise_1394: "ระดับการซ่อมแซม",
  i18nKey_enterprise_1395: "เวลาการซ่อมแซม",
  i18nKey_enterprise_1396: "สถานที่ซ่อมแซม",
  i18nKey_enterprise_1397:
    "และร้านค้าทั้งหมดภายใต้โหนดก็ถูกลบออกจากรายการเช่าเช่นกัน",
  i18nKey_enterprise_1398: "ทั้งวัน",
  i18nKey_enterprise_1399: "ถ้วยตวง",
  i18nKey_enterprise_1400: "หน่วย: ถ้วยตวง",
  i18nKey_enterprise_1401: "วันนี้",
  i18nKey_enterprise_1402: "กรุณากรอกจำนวนเงินมัดจำ",
  i18nKey_enterprise_1403:
    "เมื่อจำนวนเงินมัดจำเท่ากับ 0 หยวน ทางเข้าฝากใต้ห้องประชุมปัจจุบันจะถูกปิด",
  i18nKey_enterprise_1404: "หน่วยเงินมัดจำ",
  i18nKey_enterprise_1405: "กฎการคืนเงินมัดจำ",
  i18nKey_enterprise_1406: "คำอธิบายเงินมัดจำ",
  i18nKey_enterprise_1407: "กรุณากรอกรายละเอียดของเงินมัดจำ",
  i18nKey_enterprise_1408: "กรุณายืนยันจำนวนเงินคืน: 0 หยวน",
  i18nKey_enterprise_1409: "ยกเลิก",
  i18nKey_enterprise_1410: "ยกเลิก",
  i18nKey_enterprise_1411: "ยืนยัน",
  i18nKey_enterprise_1412: "ยืนยัน",
  i18nKey_enterprise_1413: "จำนวนเงินมัดจำสูงสุด คือ 9999",
  i18nKey_enterprise_1414: "กรุณาเลือกประเภทผู้ใช้",
  i18nKey_enterprise_1415: "กรุณาเลือกกฎการคืนเงินมัดจำ",
  i18nKey_enterprise_1416: "กรุณากรอกจำนวนเงินคืน",
  i18nKey_enterprise_1417: "จำนวนเงินคืนต้องไม่เกินจำนวนเงินมัดจำ!",
  i18nKey_enterprise_1418: "คืนเงินมัดจำ",
  i18nKey_enterprise_1419: "ชำระเงินแล้ว",
  i18nKey_enterprise_1420: "พื้นที่",
  i18nKey_enterprise_1421: "กรุณากรอกชั่วโมง",
  i18nKey_enterprise_1422: "สิ่งอำนวยความสะดวกที่ชำระเงินแล้ว",
  i18nKey_enterprise_1423: "กรุณากรอกราคาต่อหน่วย",
  i18nKey_enterprise_1424: "ราคาต่อหน่วยครึ่งวัน (CNY",
  i18nKey_enterprise_1425: "ชั่วโมง)",
  i18nKey_enterprise_1426: "กรุณากรอกราคาต่อหน่วยครึ่งวัน (หยวน)",
  i18nKey_enterprise_1427: "กรุณาเลือกวันที่สิ้นสุด",
  i18nKey_enterprise_1428: "คืนเงิน",
  i18nKey_enterprise_1429: "นโยบายการคืนเงินที่กำหนดเอง",
  i18nKey_enterprise_1430: "เงินคืนเต็มจำนวน",
  i18nKey_enterprise_1431: "ไม่มีการคืนเงิน",
  i18nKey_enterprise_1432: "ตรวจสอบการคืนเงิน",
  i18nKey_enterprise_1433: "นโยบายการคืนเงิน",
  i18nKey_enterprise_1434: "ก่อนเวลาจะเริ่ม",
  i18nKey_enterprise_1435: "เงินมัดจำ",
  i18nKey_enterprise_1436: "วันต้องไม่น้อยกว่า 4 ชั่วโมง!",
  i18nKey_enterprise_1437: "กรุณากำหนดค่าตัวเลือกการคืนเงิน!",
  i18nKey_enterprise_1438:
    "เมื่อปรับแต่งการคืนเงิน ให้ป้อนนโยบายการคืนเงินที่สมบูรณ์อย่างน้อยหนึ่งข้อ!",
  i18nKey_enterprise_1439: "ระยะเวลาการคืนเงินในการยกเลิกต้องไม่เกิน 100",
  i18nKey_enterprise_1440: "รูปแบบการป้อนเงินคืนออนไลน์ไม่ถูกต้อง!",
  i18nKey_enterprise_1441: "กรุณากำหนดค่าตัวเลือกของเงินมัดจำ!",
  i18nKey_enterprise_1442: "กฎการยกเลิกไม่เกิน 5 ข้อ!",
  i18nKey_enterprise_1443: "รายละเอียดคำสั่งเงินมัดจำ",
  i18nKey_enterprise_1444: "ค่าใช้จ่ายต่อวันหากไม่เกิน XX ชั่วโมง",
  i18nKey_enterprise_1445: "กรุณาเลือกเดือน",
  i18nKey_enterprise_1446: "กรุณาเลือกบริษัท",
  i18nKey_enterprise_1447: "โควต้าฟรี",
  i18nKey_enterprise_1448: "แก้ไขเรียบร้อยแล้ว!",
  i18nKey_enterprise_1449: "ชื่อการประชุม",
  i18nKey_enterprise_1450: "สถานที่นัดพบ",
  i18nKey_enterprise_1451: "คนจอง",
  i18nKey_enterprise_1452: "จำนวนผู้เข้าร่วมจริง",
  i18nKey_enterprise_1453: "เลือกเดือน",
  i18nKey_enterprise_1454: "ปริมาณการใช้ไฟฟ้าจริงต่อเดือน",
  i18nKey_enterprise_1455: "สรุปข้อมูล",
  i18nKey_enterprise_1456: "ภาพรวมการใช้พลังงาน",
  i18nKey_enterprise_1457: "Dongli",
  i18nKey_enterprise_1458: "Dongli",
  i18nKey_enterprise_1459: "Vankeli",
  i18nKey_enterprise_1460: "กรุณากรอกหมายเลขมิเตอร์",
  i18nKey_enterprise_1461: "กรุณากรอกชื่อสวิตช์จ่ายไฟ",
  i18nKey_enterprise_1462: "การจัดเตรียม",
  i18nKey_enterprise_1463: "มีผล",
  i18nKey_enterprise_1464: "ปริมาณการใช้พลังงานล่าสุด",
  i18nKey_enterprise_1465: "ปริมาณการใช้น้ำล่าสุด",
  i18nKey_enterprise_1466: "จำเป็นต้องระบุวันที่อ่านมิเตอร์",
  i18nKey_enterprise_1467:
    "รูปแบบของวันที่อ่านมิเตอร์ไม่ถูกต้อง ข้อกำหนดรูปแบบคือ:",
  i18nKey_enterprise_1468:
    "ข้อมูลวันที่ของการอ่านมิเตอร์นี้ไม่ถูกต้อง กรุณากรอกข้อมูลวันที่ที่เหมาะสม",
  i18nKey_enterprise_1469: "รูปแบบการอ่านของการอ่านมิเตอร์นี้ไม่ถูกต้อง",
  i18nKey_enterprise_1470:
    "การอ่านค่าของการอ่านมิเตอร์นี้น้อยกว่าการอ่านค่าของการอ่านมิเตอร์ก่อนหน้า จะยืนยันการปรับเปลี่ยนหรือไม่",
  i18nKey_enterprise_1471: "มี",
  i18nKey_enterprise_1472: "ไม่ได้ป้อนตาราง",
  i18nKey_enterprise_1473: "จะมีผลต่อไปหรือไม่",
  i18nKey_enterprise_1474: "มีผลสำเร็จ",
  i18nKey_enterprise_1475: "กรุณาเลือกที่จะลบข้อมูล",
  i18nKey_enterprise_1476:
    "รายละเอียดทั้งหมดของอุปกรณ์จะถูกลบหลังจากทำการลบ คุณแน่ใจหรือไม่ว่าจะลบ",
  i18nKey_enterprise_1477: "เลือกแล้ว",
  i18nKey_enterprise_1478: "เหรอ?",
  i18nKey_enterprise_1479: "การลบล้มเหลว กรุณาลองอีกครั้งในภายหลัง",
  i18nKey_enterprise_1480:
    "ประเภทการแจ้งเตือนการมาถึง บทบาทเป็นทางเลือก ไม่ใช่",
  i18nKey_enterprise_1481: "ประเภทการแจ้งเตือนการมาถึง จำเป็นต้องระบุบทบาท",
  i18nKey_enterprise_1482: "การซิงค์ค่าให้ตรงกัน",
  i18nKey_enterprise_1483: "กด",
  i18nKey_enterprise_1484: "บริการ",
  i18nKey_enterprise_1485: "ส่งเรียบร้อย!",
  i18nKey_enterprise_1486: "การส่งล้มเหลว!",
  i18nKey_enterprise_1487: "สัญญาณ 2",
  i18nKey_enterprise_1488: "ระบบอำนวยความสะดวก 2",
  i18nKey_enterprise_1489: "ป้ายกำกับการแจ้งเตือน",
  i18nKey_enterprise_1490: "เปิดใช้งานการแจ้งเตือน",
  i18nKey_enterprise_1491: "เร่งด่วน",
  i18nKey_enterprise_1492: "สำคัญ",
  i18nKey_enterprise_1493: "ทั่วไป",
  i18nKey_enterprise_1494: "ไม่แน่นอน",
  i18nKey_enterprise_1495: "เวลาที่มีผลบังคับใช้",
  i18nKey_enterprise_1496: "เปิดใช้งานแผนการ",
  i18nKey_enterprise_1497: "เขตมรณะ",
  i18nKey_enterprise_1498: "กรุณากรอกค่าเขตมรณะ",
  i18nKey_enterprise_1499: "กรุณากรอกคำอธิบายการแจ้งเตือน",
  i18nKey_enterprise_1500: "สัญญาณ 1",
  i18nKey_enterprise_1501: "การนำเข้าล้มเหลว เฉพาะการรองรับเท่านั้น",
  i18nKey_enterprise_1502: "การนำเข้าล้มเหลว ไม่มีข้อมูลที่ถูกต้อง",
  i18nKey_enterprise_1503: "นำเข้าล้มเหลว ข้อมูลรหัสจุดซ้ำกัน",
  i18nKey_enterprise_1504:
    "ข้อมูลการดำเนินการนี้จะถูกลบอย่างถาวร คุณแน่ใจหรือไม่?",
  i18nKey_enterprise_1505: "จำเป็นต้องระบุ",
  i18nKey_enterprise_1506:
    "สามารถประกอบด้วยตัวเลขและสัญลักษณ์เลขคณิต 4 ตัวเท่านั้น",
  i18nKey_enterprise_1507: "การนำเข้าล้มเหลวรองรับเฉพาะเวอร์ชันใหม่เท่านั้น",
  i18nKey_enterprise_1508: "ยืนยันการลบหรือไม่?",
  i18nKey_enterprise_1509: "การซิงค์ค่าให้ตรงกัน",
  i18nKey_enterprise_1510: "บันทึกไม่สำเร็จ!",
  i18nKey_enterprise_1511: "หมายเลขสิ่งอำนวยความสะดวกของระบบต้นทาง",
  i18nKey_enterprise_1512: "รหัส QR ของสิ่งอำนวยความสะดวก",
  i18nKey_enterprise_1513: "ระดับความสำคัญ",
  i18nKey_enterprise_1514: "จำนวนระดับการจำแนกประเภท",
  i18nKey_enterprise_1515: "หมายเลขซีเรียลของสิ่งอำนวยความสะดวก",
  i18nKey_enterprise_1516: "พิกัด - ลองจิจูด",
  i18nKey_enterprise_1517: "พิกัด - ละติจูด",
  i18nKey_enterprise_1518: "พิกัด - ระดับความสูง",
  i18nKey_enterprise_1519: "ดูภาพบุคคลที่อัปโหลด",
  i18nKey_enterprise_1520: "ดูแผนผังสิ่งอำนวยความสะดวกในการอัปโหลด",
  i18nKey_enterprise_1521: "กรุณาเลือกเวอร์ชันเก่า",
  i18nKey_enterprise_1522: "รุ่น",
  i18nKey_enterprise_1523: "0 หน้าจอขนาดใหญ่ได้รับการเชื่อมต่อ",
  i18nKey_enterprise_1524: "ความยาวต้องไม่เกิน 50 อักขระ",
  i18nKey_enterprise_1525: "เพิ่มไม่สำเร็จ",
  i18nKey_enterprise_1526: "แน่ใจหรือไม่ว่าต้องการลบความสัมพันธ์?",
  i18nKey_enterprise_1527: "ลบการเชื่อมโยงสำเร็จ",
  i18nKey_enterprise_1528: "การเข้ารหัส",
  i18nKey_enterprise_1529: "ยืนยันที่จะบันทึกหรือไม่?",
  i18nKey_enterprise_1530: "ยืนยันที่จะยกเลิกหรือไม่?",
  i18nKey_enterprise_1531: "กรุณาส่งระดับปัจจุบัน",
  i18nKey_enterprise_1532: "กรอกรหัสรหัสธุรกิจและชื่อระดับพื้นที่ให้ครบถ้วน!",
  i18nKey_enterprise_1533:
    "รหัสและรหัสธุรกิจต้องเป็นตัวอักษร ตัวเลข และขีดล่างผสมกันเท่านั้น!",
  i18nKey_enterprise_1534: "เพิ่มเรียบร้อยแล้ว!",
  i18nKey_enterprise_1535: "เพิ่มไม่สำเร็จ!",
  i18nKey_enterprise_1536: "กรุณาเลือกห้อง",
  i18nKey_enterprise_1537: "เวลาหมดอายุของแท็กบุคคล",
  i18nKey_enterprise_1538: "ข้อมูลป้าย",
  i18nKey_enterprise_1539: "มีประสิทธิภาพหรือไม่",
  i18nKey_enterprise_1540: "รหัสเจ้าหน้าที่ระบบต้นทาง",
  i18nKey_enterprise_1541: "ชื่อระบบต้นทาง",
  i18nKey_enterprise_1542: "เวลาที่มีผลบังคับใช้ของป้ายพนักงาน",
  i18nKey_enterprise_1543: "รูปถ่าย",
  i18nKey_enterprise_1544: "ที่อยู่จัดเก็บรูปภาพ",
  i18nKey_enterprise_1545: "รหัสประเภทภาพถ่าย",
  i18nKey_enterprise_1546: "อัปโหลดในเครื่อง อัปโหลดรูปภาพไม่เกิน 3",
  i18nKey_enterprise_1547: "รูปถ่ายบุคคล",
  i18nKey_enterprise_1548: "กรุณาเลือกแผนก",
  i18nKey_enterprise_1549: "เลือกกล่อง",
  i18nKey_enterprise_1550: "หนังสือเดินทาง",
  i18nKey_enterprise_1551: "จำเป็นต้องระบุรูปภาพ",
  i18nKey_enterprise_1552: "แก้ไขข้อมูลพื้นฐานของบุคลากรเรียบร้อยแล้ว",
  i18nKey_enterprise_1553: "หมายเลขโทรศัพท์:",
  i18nKey_enterprise_1554: "เลข:",
  i18nKey_enterprise_1555: "ต้องลงทะเบียนหมายเลขโทรศัพท์มือถือใน Yuncheng",
  i18nKey_enterprise_1556: "เพิ่มเรียบร้อยแล้ว",
  i18nKey_enterprise_1557: "ยืนยันเพื่อเปิด",
  i18nKey_enterprise_1558: "ยืนยันเพื่อปิด",
  i18nKey_enterprise_1559: "รอบระยะเวลา",
  i18nKey_enterprise_1560: "ผู้ใช้ทั้งหมด",
  i18nKey_enterprise_1561: "ทุกกลุ่ม",
  i18nKey_enterprise_1562: "ข้อมูลกลุ่ม",
  i18nKey_enterprise_1563: "กรุณากรอกแผนกผู้ใช้",
  i18nKey_enterprise_1564: "ตกลงเพื่อลบกลุ่ม",
  i18nKey_enterprise_1565: "ตกลงเพื่อลบผู้ใช้ที่เลือก",
  i18nKey_enterprise_1566: "กรุณาเลือกผู้ใช้ที่จะลบ",
  i18nKey_enterprise_1567: "เปิด",
  i18nKey_enterprise_1568: "ทรัพยากร",
  i18nKey_enterprise_1569: "ปัจจุบัน",
  i18nKey_enterprise_1570: "ทรัพยากรเมนู",
  i18nKey_enterprise_1571: "ลบบทบาทสำเร็จแล้ว",
  i18nKey_enterprise_1572: "การลบบทบาทล้มเหลว กรุณาลองอีกครั้ง",
  i18nKey_enterprise_1573: "กรุณาเลือกประเภท",
  i18nKey_enterprise_1574: "สร้างบทบาทสำเร็จแล้ว",
  i18nKey_enterprise_1575:
    "พบความผิดปกติของเครือข่าย กรุณาลองอีกครั้งในภายหลัง",
  i18nKey_enterprise_1576: "แก้ไขบทบาทสำเร็จแล้ว",
  i18nKey_enterprise_1577: "ปรับเปลี่ยนบทบาท",
  i18nKey_enterprise_1578: "ผู้ดูแลระบบขั้นสูง",
  i18nKey_enterprise_1579: "ผู้ดูแลแพลตฟอร์ม",
  i18nKey_enterprise_1580: "ผู้ดูแล park",
  i18nKey_enterprise_1581: "บทบาทของแพลตฟอร์ม",
  i18nKey_enterprise_1582: "บทบาทปกติใน park",
  i18nKey_enterprise_1583: "บทบาทความปลอดภัยของ park",
  i18nKey_enterprise_1584: "บทบาททรัพย์สินของ park",
  i18nKey_enterprise_1585: "บทบาทของการทำความสะอาดใน park",
  i18nKey_enterprise_1586: "มีชื่อบทบาทอยู่แล้ว",
  i18nKey_enterprise_1587: "โรงเรียนตำรวจฮ่องกงและมาเก๊า",
  i18nKey_enterprise_1588: "คุณแน่ใจว่าต้องการลบหรือไม่?",
  i18nKey_enterprise_1589: "การใช้พลังงานรายเดือน",
  i18nKey_enterprise_1590: "กิโลวัตต์",
  i18nKey_enterprise_1591: "ปริมาณการใช้พลังงานร่วมกันรายเดือน",
  i18nKey_enterprise_1592: "ปริมาณการใช้รายเดือน",
  i18nKey_enterprise_1593: "ปริมาณส่วนแบ่งรายเดือน",
  i18nKey_enterprise_1594: "พลังงานที่ใช้ร่วมกัน",
  i18nKey_enterprise_1595: "อัตราส่วนที่ใช้ร่วมกัน",
  i18nKey_enterprise_1596: "จำนวนที่ใช้ร่วมกัน",
  i18nKey_enterprise_1597: "ปริมาณการใช้พลังงานจริง",
  i18nKey_enterprise_1598: "ปริมาณการใช้จริง",
  i18nKey_enterprise_1599: "ดำเนินการ",
  i18nKey_enterprise_1600: "ลิฟต์เริ่ม - หยุด",
  i18nKey_enterprise_1601: "ยกเครื่อง",
  i18nKey_enterprise_1602: "ลิฟต์โดยสาร",
  i18nKey_enterprise_1603: "ลิฟต์",
  i18nKey_enterprise_1604: "การควบคุมแสง",
  i18nKey_enterprise_1605: "ประเภทอุปกรณ์",
  i18nKey_enterprise_1606: "ที่อยู่อุปกรณ์",
  i18nKey_enterprise_1607: "กำลังโหลด",
  i18nKey_enterprise_1608: "กำลังปรับปรุง",
  i18nKey_enterprise_1609: "ป้อนชื่ออุปกรณ์",
  i18nKey_enterprise_1610: "กำลังเล่น",
  i18nKey_enterprise_1611: "ทุกหน้าจอขนาดใหญ่",
  i18nKey_enterprise_1612: "การเพิ่มล้มเหลว",
  i18nKey_enterprise_1613: "แน่ใจหรือไม่ว่าต้องการลบแผนการนี้?",
  i18nKey_enterprise_1614: "ออกแผนการสำเร็จ",
  i18nKey_enterprise_1615: "สถานะที่เปิดใช้งาน",
  i18nKey_enterprise_1616: "ลบนโยบาย",
  i18nKey_enterprise_1617: "จำเป็นต้องระบุเวลาที่มีผลบังคับใช้ของแต่ละกลยุทธ์",
  i18nKey_enterprise_1618: "กฎการส่องสว่าง",
  i18nKey_enterprise_1619: "จำเป็นต้องระบุ",
  i18nKey_enterprise_1620: "บันทึกการกำหนดค่าเบรกเกอร์สำเร็จ!",
  i18nKey_enterprise_1621: "เปลี่ยนเรียบร้อยแล้ว",
  i18nKey_enterprise_1622: "รายชื่อลูกค้า",
  i18nKey_enterprise_1623: "หมายเลขบุคลากร",
  i18nKey_enterprise_1624: "เข้าเยี่ยมชม parkในสัปดาห์ที่ผ่านมา",
  i18nKey_enterprise_1625: "เข้าเยี่ยมชม park ในช่วงเช้าของสัปดาห์ที่ผ่านมา",
  i18nKey_enterprise_1626: "วงจรของผู้ใช้",
  i18nKey_enterprise_1627: "ใช้เวลาในการสั่งซื้อ",
  i18nKey_enterprise_1628: "ใช้เวลาสั้นที่สุด",
  i18nKey_enterprise_1629: "ใช้เวลานานที่สุด",
  i18nKey_enterprise_1630: "ต้นทุนเวลา",
  i18nKey_enterprise_1631: "ชิ้น",
  i18nKey_enterprise_1632: "สิ่งอำนวยความสะดวก",
  i18nKey_enterprise_1633: "จำนวนสิ่งอำนวยความสะดวก",
  i18nKey_enterprise_1634: "เวลาในการประมวลผลเฉลี่ย",
  i18nKey_enterprise_1635: "รหัสสิ่งอำนวยความสะดวก",
  i18nKey_enterprise_1636: "แยกมิติ",
  i18nKey_enterprise_1637: "กรุณาเลือกมิติข้อมูล",
  i18nKey_enterprise_1638: "แยกการบำรุงรักษา",
  i18nKey_enterprise_1639: "กรุณาเลือกข้อมูลการจัดจำหน่าย",
  i18nKey_enterprise_1640: "บำรุงรักษามิเตอร์เรียบร้อยแล้ว!",
  i18nKey_enterprise_1641: "รายการย่อย",
  i18nKey_enterprise_1642: "กรุณาเลือกรายการ",
  i18nKey_enterprise_1643: "ประเภทข้อยกเว้น",
  i18nKey_enterprise_1644: "กรุณาเลือกระดับ",
  i18nKey_enterprise_1645: "ปริมาณการใช้ไฟฟ้าจริงในวันนี้",
  i18nKey_enterprise_1646: "ปริมาณการใช้ไฟฟ้าจริงเมื่อสัปดาห์ที่แล้ว",
  i18nKey_enterprise_1647: "รายละเอียดไฟฟ้า",
  i18nKey_enterprise_1648: "การแจ้งเตือนที่สำคัญ",
  i18nKey_enterprise_1649: "บันทึกล้มเหลว",
  i18nKey_enterprise_1650: "เวลาไฟฟ้า",
  i18nKey_enterprise_1651: "เวลาสถิติ",
  i18nKey_enterprise_1652: "ปริมาณการใช้ไฟฟ้าจริงในเดือนนี้",
  i18nKey_enterprise_1653: "ปริมาณการใช้ไฟฟ้าจริงของเดือนที่แล้ว",
  i18nKey_enterprise_1654: "รายละเอียดอุบัติเหตุ",
  i18nKey_enterprise_1655: "จาก",
  i18nKey_enterprise_1656: "ความล้มเหลวที่เกิดขึ้นซ้ำ และระยะเวลาสะสม",
  i18nKey_enterprise_1657: "จำนวนชั่วโมง อาจทำให้เกิดของเสียได้ตลอดทั้งปี",
  i18nKey_enterprise_1658: "สถานะการประมวลผล",
  i18nKey_enterprise_1659: "การรักษาด้วยการวินิจฉัย",
  i18nKey_enterprise_1660: "ชื่อข้อบกพร่อง",
  i18nKey_enterprise_1661: "กรุณากรอกชื่อข้อบกพร่อง",
  i18nKey_enterprise_1662: "ระดับความล้มเหลว",
  i18nKey_enterprise_1663: "สถิติความล้มเหลว",
  i18nKey_enterprise_1664: "จำนวนความล้มเหลว",
  i18nKey_enterprise_1665: "จำนวนความล้มเหลวทั้งหมด",
  i18nKey_enterprise_1666: "Huanglong Vanke",
  i18nKey_enterprise_1667: "ใช้",
  i18nKey_enterprise_1668: "ไฟฟ้า",
  i18nKey_enterprise_1669: "น้ำ",
  i18nKey_enterprise_1670: "จำนวน",
  i18nKey_enterprise_1671: "ปริมาณการใช้ไฟฟ้าทั้งหมด",
  i18nKey_enterprise_1672: "ใช้เสมอ",
  i18nKey_enterprise_1673: "รายงานอุปกรณ์ข้อมูลที่สำคัญ",
  i18nKey_enterprise_1674: "พลังงานของห้องคอมพิวเตอร์",
  i18nKey_enterprise_1675: "พลังงานสูงสุด",
  i18nKey_enterprise_1676: "แผนภาพวงแหวน",
  i18nKey_enterprise_1677: "ในโครงการทั้งหมดในปีนี้ คุณพบทั้งหมดของ",
  i18nKey_enterprise_1678: "ศักยภาพในการประหยัดพลังงาน",
  i18nKey_enterprise_1679: "พื้นที่ทางทิศใต้",
  i18nKey_enterprise_1680: "ห้องเครื่อง",
  i18nKey_enterprise_1681: "เขตเหนือ",
  i18nKey_enterprise_1682: "ปัจจุบัน",
  i18nKey_enterprise_1683: "ตัวแปร",
  i18nKey_enterprise_1684: "อัตราส่วนประสิทธิภาพการใช้พลังงาน",
  i18nKey_enterprise_1685: "พลังงานแบบเรียลไทม์",
  i18nKey_enterprise_1686: "จำนวนเงินที่เติม (หยวน)",
  i18nKey_enterprise_1687: "จำนวนเงินที่ใช้ (หยวน)",
  i18nKey_enterprise_1688: "การบริโภครายเดือน",
  i18nKey_enterprise_1689: "จำนวนเงินที่เติมรายเดือน",
  i18nKey_enterprise_1690: "จำนวนเงินที่ใช้รายเดือน",
  i18nKey_enterprise_1691: "จำนวนเงินคงเหลือรายเดือน",
  i18nKey_enterprise_1692:
    "คำเตือน: หลังจากปิดใบสั่งงานแล้ว จะไม่สามารถดำเนินการใด ๆ กับใบสั่งงานได้อีก คุณต้องการปิดหรือไม่?",
  i18nKey_enterprise_1693: "หลังจากคลิกตกลง เพื่อสร้างตั๋วการกู้คืน",
  i18nKey_enterprise_1694:
    "คุณสามารถตรวจสอบความคืบหน้าได้ในรายการใบสั่งงานกู้คืนระบบไฟฟ้าขัดข้อง!",
  i18nKey_enterprise_1695:
    "คำเตือน: คุณสามารถเลือกที่จะกู้คืนคำสั่งปิดเครื่องได้ด้วยตนเอง",
  i18nKey_enterprise_1696:
    "จะไม่มีการสร้างใบสั่งงานปิดเครื่องภายในสองสามวัน และใบสั่งงานปิดเครื่องจะถูกสร้างต่อไปหลังจากไม่ได้เลือกเกณฑ์การปิดเครื่อง",
  i18nKey_enterprise_1697: "หมายเลขโต๊ะ",
  i18nKey_enterprise_1698: "ปิดการประมวลผล",
  i18nKey_enterprise_1699: "ไฟฟ้าขัดข้อง",
  i18nKey_enterprise_1700: "ปิดเครื่อง",
  i18nKey_enterprise_1701: "เวลาที่สมบูรณ์",
  i18nKey_enterprise_1702:
    "คุณสามารถลองปิดเครื่องอีกครั้ง หรือปิดตั๋วแล้วไปที่ไซต์เพื่อดำเนินการ",
  i18nKey_enterprise_1703: "วิธีการปิด",
  i18nKey_enterprise_1704: "ปิดไซต์ด้วยตนเอง",
  i18nKey_enterprise_1705: "การปิดระยะไกล",
  i18nKey_enterprise_1706: "การกู้คืนไฟฟ้าขัดข้อง",
  i18nKey_enterprise_1707: "การกู้คืนล้มเหลว",
  i18nKey_enterprise_1708: "กู้คืนแล้ว",
  i18nKey_enterprise_1709:
    "คุณสามารถลองกลับมาทำงานอีกครั้ง หรือปิดตั๋วแล้วไปที่ไซต์เพื่อดำเนินการ",
  i18nKey_enterprise_1710:
    "คำเตือน: มิเตอร์บางตัวในสัญญานี้ไม่สามารถปิดจากระยะไกลได้ และจำเป็นต้องไปที่ไซต์เพื่อดำเนินการต่อ",
  i18nKey_enterprise_1711: "การประมวลผลเสร็จสมบูรณ์",
  i18nKey_enterprise_1712: "เลือกผู้ค้า:",
  i18nKey_enterprise_1713: "เลือกยี่ห้อ:",
  i18nKey_enterprise_1714: "เกณฑ์มิเตอร์",
  i18nKey_enterprise_1715: "เกณฑ์มิเตอร์น้ำ",
  i18nKey_enterprise_1716: "เกณฑ์การปิดเครื่อง",
  i18nKey_enterprise_1717: "ยังไม่มีข้อมูลสัญญา กรุณาเลือกผู้ค้าและแบรนด์ก่อน",
  i18nKey_enterprise_1718:
    "หลังจากกำหนดเกณฑ์การปิดเครื่องของมิเตอร์แล้ว คำสั่งการประมวลผลจะถูกสร้างขึ้นเมื่อถึงเกณฑ์",
  i18nKey_enterprise_1719: "ไม่ว่าจะเริ่มปิดเครื่อง",
  i18nKey_enterprise_1720: "เปิดใช้งานการปิดเครื่อง",
  i18nKey_enterprise_1721: "ไม่ได้เปิดใช้งานการปิดเครื่อง",
  i18nKey_enterprise_1722: "เลือกเวลาเริ่มต้น",
  i18nKey_enterprise_1723: "เลือกเวลาสิ้นสุด",
  i18nKey_enterprise_1724: "เลือกไอคอน",
  i18nKey_enterprise_1725: "สวิตช์ล็อคสถานการณ์",
  i18nKey_enterprise_1726: "เปิดสำเร็จแล้ว",
  i18nKey_enterprise_1727: "ปิดสำเร็จแล้ว",
  i18nKey_enterprise_1728: "การลบแบทช์",
  i18nKey_enterprise_1729: "ตัวอย่างรูปแบบชื่อบ้าน:",
  i18nKey_enterprise_1730: "ห้อง 2 หอประชุม",
  i18nKey_enterprise_1731: "ยืนยันที่จะลบอพาร์ตเมนต์",
  i18nKey_enterprise_1732: "ประเภทหน่วย",
  i18nKey_enterprise_1733: "ความสำเร็จ",
  i18nKey_enterprise_1734: "เบอร์ห้อง",
  i18nKey_enterprise_1735: "อาคารและพื้นที่สาธารณะใหม่",
  i18nKey_enterprise_1736: "กรุณาเข้าสู่อาคาร",
  i18nKey_enterprise_1737: "เพิ่มหมายเลขห้อง",
  i18nKey_enterprise_1738: "กรุณากรอกหมายเลขห้อง",
  i18nKey_enterprise_1739: "กรุณาเลือกหน่วยอาคาร เพื่อเพิ่มหมายเลขห้อง",
  i18nKey_enterprise_1740: "กรุณาเลือกห้องที่จะลบ",
  i18nKey_enterprise_1741: "หยุดเล่นชั่วคราว 1",
  i18nKey_enterprise_1742: "หยุดชั่วคราว 1",
  i18nKey_enterprise_1743: "ตัวจับเวลา",
  i18nKey_enterprise_1744: "หยุดเล่นชั่วคราว 3",
  i18nKey_enterprise_1745: "เล่น",
  i18nKey_enterprise_1746: "กลุ่มลาดตระเวนจะเล่นในช่วงเวลาปกติ",
  i18nKey_enterprise_1747: "กำลังเล่นอยู่ ---",
  i18nKey_enterprise_1748: "กลุ่มลาดตระเวน",
  i18nKey_enterprise_1749: "กล้องถ่ายรูป",
  i18nKey_enterprise_1750: "วิดีโอหยุดชั่วคราว",
  i18nKey_enterprise_1751: "สิ้นสุดการลาดตระเวนแบบวิดีโอ",
  i18nKey_enterprise_1752: "เส้นทางลาดตระเวนใหม่",
  i18nKey_enterprise_1753: "รายชื่อเส้นทางลาดตระเวน",
  i18nKey_enterprise_1754: "วิดีโอแบบเรียลไทม์",
  i18nKey_enterprise_1755: "เลือกวันที่และเวลาเริ่มต้น",
  i18nKey_enterprise_1756: "เลือกวันที่และเวลาสิ้นสุด",
  i18nKey_enterprise_1757: "เชื่อมโยงวิดีโอซึ่งเกิดขึ้นในอดีต",
  i18nKey_enterprise_1758: "สกัดกั้นสำเร็จ",
  i18nKey_enterprise_1759: "การจับภาพวิดีโอล้มเหลว",
  i18nKey_enterprise_1760: "กรุณาเลือกประเภทสถานี",
  i18nKey_enterprise_1761: "การตั้งค่าพื้นฐาน",
  i18nKey_enterprise_1762: "ล่วงหน้า",
  i18nKey_enterprise_1763: "ส่วนลดรายเดือน",
  i18nKey_enterprise_1764: "ปีขึ้นไป",
  i18nKey_enterprise_1765: "เดือน",
  i18nKey_enterprise_1766: "สร้างสำเร็จ!",
  i18nKey_enterprise_1767: "ราคาเดิม",
  i18nKey_enterprise_1768: "ผู้ดูแลระบบ",
  i18nKey_enterprise_1769: "เวลาเริ่มต้นของการระงับ",
  i18nKey_enterprise_1770: "เวลาสิ้นสุดของการระงับ",
  i18nKey_enterprise_1771: "ยกเลิกการสมัครก่อนเวลา",
  i18nKey_enterprise_1772: "สามารถยกเลิกการสมัครล่วงหน้าได้กี่วัน",
  i18nKey_enterprise_1773: "กรุณากรอกละติจูดที่อยู่",
  i18nKey_enterprise_1774: "สิ่งอำนวยความสะดวกเพิ่มเติม",
  i18nKey_enterprise_1775: "เพิ่มสิ่งอำนวยความสะดวกเพิ่มเติม",
  i18nKey_enterprise_1776:
    "ตัวอย่างเช่น 15 หยวนสำหรับชั่วโมงแรก (ฟรี 15 นาทีแรก) หลังจากนั้น 10 หยวนต่อชั่วโมง",
  i18nKey_enterprise_1777: "ต่อยอด",
  i18nKey_enterprise_1778: "park ใกล้ ๆ",
  i18nKey_enterprise_1779: "ตัวอย่าง: ผิงอัน",
  i18nKey_enterprise_1780:
    "15 หยวนสำหรับชั่วโมงแรก (ฟรี 15 นาทีแรก) หลังจากนั้น 10 หยวนต่อชั่วโมง",
  i18nKey_enterprise_1781: "เขตฝูเทียน เมืองเซินเจิ้น มณฑลกวางตุ้ง",
  i18nKey_enterprise_1782: "เพิ่มพื้นที่สำนักงาน",
  i18nKey_enterprise_1783: "ปรับเปลี่ยนพื้นที่สำนักงาน",
  i18nKey_enterprise_1784: "รายละเอียดพื้นที่สำนักงาน",
  i18nKey_enterprise_1785:
    "เส้นทางเมนูสิ่งอำนวยความสะดวกที่เพิ่มเติมใหม่: บริการขององค์กร",
  i18nKey_enterprise_1786: "การจัดการห้องประชุม",
  i18nKey_enterprise_1787: "บริการเสริม!",
  i18nKey_enterprise_1788: "มีอยู่!",
  i18nKey_enterprise_1789:
    "การป้อนข้อมูลละติจูดและลองจิจูดที่อยู่ต้องคั่นด้วยเครื่องหมายจุลภาค",
  i18nKey_enterprise_1790: "และต้องไม่เกิน 20 อักขระ",
  i18nKey_enterprise_1791: "กรุณากรอกหมายเลขสถานที่",
  i18nKey_enterprise_1792: "กรุณาเลือกสิ่งที่ต้องอัปโหลด",
  i18nKey_enterprise_1793: "รูป!",
  i18nKey_enterprise_1794: "ไฟล์ภาพ!",
  i18nKey_enterprise_1795: "การติดตั้งบัญชีดำ",
  i18nKey_enterprise_1796: "คำเชิญแขก",
  i18nKey_enterprise_1797: "ข้อมูลผู้เยี่ยมชม",
  i18nKey_enterprise_1798: "กรุณากรอกชื่อผู้เยี่ยมชม",
  i18nKey_enterprise_1799: "กรุณากรอกหน่วยงาน",
  i18nKey_enterprise_1800: "กรุณาเลือกผู้เยี่ยมชม",
  i18nKey_enterprise_1801: "เซี่ยงไฮ้",
  i18nKey_enterprise_1802: "วันที่:",
  i18nKey_enterprise_1803: "สถิติสัดส่วนพนักงาน",
  i18nKey_enterprise_1804: "จำนวนการจัดอันดับบุคลากร",
  i18nKey_enterprise_1805: "สองวันก่อน",
  i18nKey_enterprise_1806: "กรุณาเลือกวันที่อัปเดต",
  i18nKey_enterprise_1807: "ประเภทสถานที่",
  i18nKey_enterprise_1808: "กรุณาเลือกประเภทสถานที่",
  i18nKey_enterprise_1809: "กรุณาเลือกสถานะอุปกรณ์",
  i18nKey_enterprise_1810: "เยี่ยมชม park",
  i18nKey_enterprise_1811: "บัตรผ่าน",
  i18nKey_enterprise_1812: "ที่อยู่ผู้สัมภาษณ์",
  i18nKey_enterprise_1813: "กรุณากรอกโทรศัพท์ของแขก",
  i18nKey_enterprise_1814: "ระยะเวลาการอนุญาต",
  i18nKey_enterprise_1815: "เหตุผลการเยี่ยมชม",
  i18nKey_enterprise_1816: "ประเภทบัตรผ่าน",
  i18nKey_enterprise_1817: "กรุณาเลือกชื่อผู้ผลิต",
  i18nKey_enterprise_1818: "ลบหรือไม่?",
  i18nKey_enterprise_1819: "ส่งออกตาราง",
  i18nKey_enterprise_1820: "ข้อมูลการป้องกันและควบคุมการแพร่ระบาด",
  i18nKey_enterprise_1821: "คำเชิญสำเร็จ",
  i18nKey_enterprise_1822: "คำเชิญแขก",
  i18nKey_enterprise_1823: "สถิติการเชิญผู้เยี่ยมชม",
  i18nKey_enterprise_1824: "เกือบ 30 วัน",
  i18nKey_enterprise_1825: "การจัดอันดับคำเชิญผู้เยี่ยมชมสวน",
  i18nKey_enterprise_1826: "สถิติผู้เยี่ยมชม",
  i18nKey_enterprise_1827: "ประวัติการผู้รับเชิญมาในของ park ",
  i18nKey_enterprise_1828: "ประวัติการเชิญ",
  i18nKey_enterprise_1829: "กรุณากรอกหมายเลขอุปกรณ์",
  i18nKey_enterprise_1830: "การใช้",
  i18nKey_enterprise_1831: "สำรอง",
  i18nKey_enterprise_1832: "ในการบำรุงรักษา",
  i18nKey_enterprise_1833: "บันทึกบัตรผ่าน",
  i18nKey_enterprise_1834: "ลบหรือไม่",
  i18nKey_enterprise_1835: "เพิ่มเส้นทาง",
  i18nKey_enterprise_1836: "ชื่อเส้นทาง",
  i18nKey_enterprise_1837: "กรุณากรอกชื่อเส้นทาง",
  i18nKey_enterprise_1838: "ไม่ว่าจะเป็นการรับรององค์กร",
  i18nKey_enterprise_1839: "ไม่ว่าจะเป็นผู้เช่า park",
  i18nKey_enterprise_1840: "กรุณากรอกชื่อหรือหมายเลขโทรศัพท์ของคุณ",
  i18nKey_enterprise_1841: "เส้นทางรถเมล์",
  i18nKey_enterprise_1842: "เวลาที่เหลือ",
  i18nKey_enterprise_1843: "การชำระเงินล้มเหลว",
  i18nKey_enterprise_1844: "ไม่มีบันทึกยานพาหนะ ไม่ต้องดำเนินการ export",
  i18nKey_enterprise_1845: "ออกรายงานหรือไม่",
  i18nKey_enterprise_1846: "การชำระเงินชั่วคราว",
  i18nKey_enterprise_1847: "รายการการสืบค้น",
  i18nKey_enterprise_1848: "ปักกิ่ง",
  i18nKey_enterprise_1849: "เทียนจิน",
  i18nKey_enterprise_1850: "เซี่ยงไฮ้",
  i18nKey_enterprise_1851: "ฉงชิ่ง",
  i18nKey_enterprise_1852: "Xinjiang",
  i18nKey_enterprise_1853: "Jiangsu",
  i18nKey_enterprise_1854: "เจ้อเจียง",
  i18nKey_enterprise_1855: "Heilongjiang",
  i18nKey_enterprise_1856: "Hunan",
  i18nKey_enterprise_1857: "Anhui",
  i18nKey_enterprise_1858: "Shandong",
  i18nKey_enterprise_1859: "Jiangxi",
  i18nKey_enterprise_1860: "Hebei",
  i18nKey_enterprise_1861: "Henan",
  i18nKey_enterprise_1862: "Yunnan",
  i18nKey_enterprise_1863: "Liaoning",
  i18nKey_enterprise_1864: "หูเป่ย",
  i18nKey_enterprise_1865: "Guangxi",
  i18nKey_enterprise_1866: "Gansu",
  i18nKey_enterprise_1867: "Shanxi",
  i18nKey_enterprise_1868: "Inner Mongolian",
  i18nKey_enterprise_1869: "Shaanxi",
  i18nKey_enterprise_1870: "Jilin",
  i18nKey_enterprise_1871: "Fujian",
  i18nKey_enterprise_1872: "Guizhou",
  i18nKey_enterprise_1873: "กวางตุ้ง",
  i18nKey_enterprise_1874: "Qinghai",
  i18nKey_enterprise_1875: "Tibet",
  i18nKey_enterprise_1876: "Sichuan",
  i18nKey_enterprise_1877: "Ningxia",
  i18nKey_enterprise_1878: "Hainan",
  i18nKey_enterprise_1879: "Taiwan",
  i18nKey_enterprise_1880: "Hong Kong",
  i18nKey_enterprise_1881: "Macau",
  i18nKey_enterprise_1882: "มากกว่า 8",
  i18nKey_enterprise_1883: "มากกว่า 12",
  i18nKey_enterprise_1884: "มากกว่า 24",
  i18nKey_enterprise_1885: "ผลรวมทั้งสิ้น",
  i18nKey_enterprise_1886: "มากกว่า 1200",
  i18nKey_enterprise_1887: "มากกว่า 1600",
  i18nKey_enterprise_1888: "น้อยกว่า 5",
  i18nKey_enterprise_1889: "ค่าจอดรถสะสม",
  i18nKey_enterprise_1890: "คุณสมบัติพื้นฐาน",
  i18nKey_enterprise_1891: "ป้ายที่ตั้งไว้ล่วงหน้า",
  i18nKey_enterprise_1892: "5 ครั้งในสัปดาห์ที่ผ่านมา",
  i18nKey_enterprise_1893: "21 ครั้งในเดือนที่ผ่านมา",
  i18nKey_enterprise_1894: "การเข้าพักนานที่สุดในสัปดาห์ที่ผ่านมาคือ 5 ชั่วโมง",
  i18nKey_enterprise_1895: "เข้าที่จอดรถนัมแดมุนบ่อยๆ",
  i18nKey_enterprise_1896: "ช่วงเวลาเข้าและออก",
  i18nKey_enterprise_1897: "ใช้แพ็กเกจ 200",
  i18nKey_enterprise_1898: "ซื้อแพ็คเกจสองครั้งในปีที่ผ่านมา",
  i18nKey_enterprise_1899: "การใช้งานสะสม",
  i18nKey_enterprise_1900: "ข้างบน",
  i18nKey_enterprise_1901:
    "ปริมาณการใช้งานของแพลตฟอร์มอีคอมเมิร์ซ คือ 500 หยวน",
  i18nKey_enterprise_1902: "วิธีการ",
  i18nKey_enterprise_1903: "เมื่อเดือนก่อน",
  i18nKey_enterprise_1904: "เมื่อเดือนกันยายนที่ผ่านมา",
  i18nKey_enterprise_1905: "จำนวนคนปัจจุบัน",
  i18nKey_enterprise_1906: "อัตราส่วนอายุ",
  i18nKey_enterprise_1907: "Shenzhen Yuncheng",
  i18nKey_enterprise_1908: "Yongqingfang",
  i18nKey_enterprise_1909: "ชั้น",
  i18nKey_enterprise_1910: "พื้นที่",
  i18nKey_enterprise_1911: "กรุณากรอกตั๋ว",
  i18nKey_enterprise_1912:
    "หากมีจุดแวะพักหลายจุดกรุณากรอกรูปแบบต่อไปนี้: Vanke Yuncheng",
  i18nKey_enterprise_1913: "ศูนย์เป่าอัน",
  i18nKey_enterprise_1914: "อ่าวด้านหน้า",
  i18nKey_enterprise_1915: "สนามบินเป่าอัน",
  i18nKey_enterprise_1916: "เงื่อนไขการขับขี่",
  i18nKey_enterprise_1917: "แผนที่เส้นทาง",
  i18nKey_enterprise_1918: "คุณต้องการการรับรองจากบริษัทหรือไม่",
  i18nKey_enterprise_1919: "เป็นผู้เช่าจอดเรือหรือไม่?",
  i18nKey_enterprise_1920: "แก้ไขเส้นทาง",
  i18nKey_enterprise_1921: "ดูเส้นทาง",
  i18nKey_enterprise_1922: "เช่น",
  i18nKey_enterprise_1923: "กรุณากรอกอุปกรณ์สแกน",
  i18nKey_enterprise_1924: "กรุณาเลือกการเรียกเก็บเงินหลายรายการ",
  i18nKey_enterprise_1925: "ตัวอย่างเช่น รถบัสจำกัด ไว้ที่ 50 คน",
  i18nKey_enterprise_1926: "กรุณาเลือกว่าจะเรียกเก็บเงินหลายงวด",
  i18nKey_enterprise_1927: "กรุณาเลือกวันที่ที่ถูกต้อง",
  i18nKey_enterprise_1928: "ราคาแปลงอัตโนมัติ",
  i18nKey_enterprise_1929: "กรุณากรอกน้อยกว่า 1",
  i18nKey_enterprise_1930: "ราคาระหว่าง",
  i18nKey_enterprise_1931: "กรุณาเลือกตารางเวลารถบัสที่จะใช้",
  i18nKey_enterprise_1932: "แก้ไขแพ็คเกจ",
  i18nKey_enterprise_1933: "ตรวจสอบเมนูชุด",
  i18nKey_enterprise_1934: "ส่งข้อความ",
  i18nKey_enterprise_1935: "การตรวจสอบการควบคุมการเข้าถึง",
  i18nKey_enterprise_1936: "เข้าถึงอาคารได้ทั้งหมด",
  i18nKey_enterprise_1937: "เปิด",
  i18nKey_enterprise_1938: "การควบคุมการเข้าถึง",
  i18nKey_enterprise_1939: "การควบคุมการปรับใช้งานจริง",
  i18nKey_enterprise_1940: "ข่าวเพิ่มเติม",
  i18nKey_enterprise_1941: "อัปโหลดรูปภาพ",
  i18nKey_enterprise_1942: "เพิ่มการควบคุมใบหน้า",
  i18nKey_enterprise_1943: "การควบคุมการปรับใช้ยานพาหนะใหม่",
  i18nKey_enterprise_1944: "ปลดอาวุธ",
  i18nKey_enterprise_1945: "คำเตือนสิ่งอำนวยความสะดวกการขนส่ง",
  i18nKey_enterprise_1946: "การบุกรุกที่ผิดกฎหมาย",
  i18nKey_enterprise_1947: "สายเข้า",
  i18nKey_enterprise_1948: "โทร",
  i18nKey_enterprise_1949: "ที่ใช้กันทั่วไป",
  i18nKey_enterprise_1950: "ผู้ติดต่อ",
  i18nKey_enterprise_1951: "เรียกหัวหน้าการรักษาความปลอดภัย",
  i18nKey_enterprise_1952: "วางสาย",
  i18nKey_enterprise_1953: "ภาพการแจ้งเตือน",
  i18nKey_enterprise_1954: "ตัวจำลองการแจ้งเตือน",
  i18nKey_enterprise_1955: "อัปโหลดรายการ",
  i18nKey_enterprise_1956: "ดูวิดีโอที่เกี่ยวข้อง",
  i18nKey_enterprise_1957: "อัปโหลดได้แค่ 10",
  i18nKey_enterprise_1958: "รูปภาพภายใน",
  i18nKey_enterprise_1959: "รูปแบบวิดีโอ",
  i18nKey_enterprise_1960: "การคัดเลือกบุคลากร",
  i18nKey_enterprise_1961: "แผนที่การแสดงภาพของบุคลากร",
  i18nKey_enterprise_1962: "ไม่มีบุคลากร",
  i18nKey_enterprise_1963: "ส่ง",
  i18nKey_enterprise_1964: "ต้องระบุเนื้อหาที่ส่ง SMS",
  i18nKey_enterprise_1965: "ส่ง SMS เรียบร้อยแล้ว",
  i18nKey_enterprise_1966: "ผู้ที่ส่ง SMS ไม่สำเร็จ",
  i18nKey_enterprise_1967: "โปรดทราบบุคลากรข้างต้นไม่สามารถรับ SMS ได้",
  i18nKey_enterprise_1968:
    "แจ้งเตือนจาก Vanke Smart Park Operation Center, Vanke Smart Park Operation Center ขอแจ้งว่า:",
  i18nKey_enterprise_1969: "ขอบคุณ!",
  i18nKey_enterprise_1970: "ต้องระบุผู้รับ SMS",
  i18nKey_enterprise_1971: "การส่งคืนอุณหภูมิส่วนตัวของบุคลากร",
  i18nKey_enterprise_1972: "สมุดที่อยู่",
  i18nKey_enterprise_1973: "ค้นหาผู้ติดต่อ",
  i18nKey_enterprise_1974: "กำลังโทร",
  i18nKey_enterprise_1975: "การสนทนาทางวิดีโอ",
  i18nKey_enterprise_1976: "การจัดการไฟถนน",
  i18nKey_enterprise_1977: "ชื่อโคมไฟถนน",
  i18nKey_enterprise_1978: "สถานะไฟถนน",
  i18nKey_enterprise_1979: "อาคารจอดรถ",
  i18nKey_enterprise_1980: "เปิดประตู",
  i18nKey_enterprise_1981: "ปิดประตู",
  i18nKey_enterprise_1982: "ข้อแม้",
  i18nKey_enterprise_1983: "บุคลากรเป้าหมายที่น่าสงสัยปรากฏตัวใน",
  i18nKey_enterprise_1984: "ส่งคนไปดู",
  i18nKey_enterprise_1985: "ชื่อหมวดหมู่",
  i18nKey_enterprise_1986: "กรุณากรอกชื่อหมวดหมู่",
  i18nKey_enterprise_1987: "จำเป็นต้องระบุชื่อหมวดหมู่",
  i18nKey_enterprise_1988: "ประเภทพื้นที่ใน park",
  i18nKey_enterprise_1989: "ข้อมูลพื้นที่ใน park",
  i18nKey_enterprise_1990: "วิธีการเปิดประตู",
  i18nKey_enterprise_1991: "พนักงานบริการพื้นที่",
  i18nKey_enterprise_1992: "คำอธิบายพื้นที่",
  i18nKey_enterprise_1993: "แผนที่ของพื้นที่",
  i18nKey_enterprise_1994: "รหัสผ่านเพื่อเปิดประตู",
  i18nKey_enterprise_1995: "รหัส QR เพื่อเปิดประตู",
  i18nKey_enterprise_1996: "เปิดประตู",
  i18nKey_enterprise_1997: "หมายเลขพื้นที่",
  i18nKey_enterprise_1998: "แพลตฟอร์มรหัส QR",
  i18nKey_enterprise_1999: "กรุณาเลือกแพลตฟอร์มรหัส QR",
  i18nKey_enterprise_2000: "ดาวน์โหลดรหัส QR",
  i18nKey_enterprise_2001: "แพลตฟอร์ม",
  i18nKey_enterprise_2002: "แอพเพล็ต",
  i18nKey_enterprise_2003: "กรุณาเลือกประเภทรหัส QR",
  i18nKey_enterprise_2004: "กรุณาเลือกประเภทพื้นที่ park",
  i18nKey_enterprise_2005: "กรุณาเลือกรหัสผ่านออนไลน์",
  i18nKey_enterprise_2006: "กรุณาเลือกประเภทล็อคประตู",
  i18nKey_enterprise_2007: "ประเภทล็อคประตู",
  i18nKey_enterprise_2008: "ประเภทพื้นที่ park ใหม่",
  i18nKey_enterprise_2009: "กรุณากรอกคำอธิบายพื้นที่",
  i18nKey_enterprise_2010: "กรุณาเลือกหมายเลขล็อค!",
  i18nKey_enterprise_2011: "พื้นที่ park ใหม่",
  i18nKey_enterprise_2012: "ปรับเปลี่ยนพื้นที่ของวิทยาเขต",
  i18nKey_enterprise_2013: "รายละเอียดพื้นที่ park",
  i18nKey_enterprise_2014: "ปิด",
  i18nKey_enterprise_2015: "การใช้น้ำ",
  i18nKey_enterprise_2016: "กรุณากรอกจำนวนบวก",
  i18nKey_enterprise_2017: "เก็บทศนิยมไม่เกินสองตำแหน่ง",
  i18nKey_enterprise_2018: "ไม่มีห้องในตำแหน่งปัจจุบัน",
  i18nKey_enterprise_2019: "การนำเข้าไฟล์",
  i18nKey_enterprise_2020: "อันดับแรก การนำเข้าล้มเหลว",
  i18nKey_enterprise_2021:
    "มีข้อผิดพลาดในข้อมูลแถวกรุณาตรวจสอบและส่งอีกครั้ง เพื่อนำเข้าในภายหลัง",
  i18nKey_enterprise_2022: "สถิติข้อมูลไฟฟ้า",
  i18nKey_enterprise_2023: "วันที่มีผลบังคับใช้",
  i18nKey_enterprise_2024: "สถิติการใช้น้ำ",
  i18nKey_enterprise_2025: "ลูกบาศก์เมตร",
  i18nKey_enterprise_2026:
    "หมายเลขโทรศัพท์มือถือของผู้ให้บริการต้องลงทะเบียนใน Yuncheng",
  i18nKey_enterprise_2027: "รูปแบบหมายเลขโทรศัพท์ไม่ถูกต้อง",
  i18nKey_enterprise_2028: "กรุณากรอกชื่อที่จอดรถ",
  i18nKey_enterprise_2029: "เกิน",
  i18nKey_enterprise_2030: "เวลา, ไม่สามารถสมัครบัตรรายเดือนได้",
  i18nKey_enterprise_2031: "ครั้ง, ไม่สามารถเข้าที่จอดรถได้",
  i18nKey_enterprise_2032: "เปิดประตูพร้อมกัน",
  i18nKey_enterprise_2033: "คำแนะนำ: ความล่าช้าของภาพวิดีโออยู่ที่ประมาณ 5-10",
  i18nKey_enterprise_2034: "กรุณารอ",
  i18nKey_enterprise_2035: "เวลาทำการ:",
  i18nKey_enterprise_2036: "บันได",
  i18nKey_enterprise_2037: "ความเร็วมอเตอร์:",
  i18nKey_enterprise_2038: "อุณหภูมิแกน:",
  i18nKey_enterprise_2039: "ระยะทางของเชือกลาก:",
  i18nKey_enterprise_2040: "การควบคุมระดับเสียง",
  i18nKey_enterprise_2041: "วงจรไฟทั้งหมด:",
  i18nKey_enterprise_2042: "",
  i18nKey_enterprise_2043: "สวิตซ์",
  i18nKey_enterprise_2044: "ไม่",
  i18nKey_enterprise_2045: "แน่นอนสำหรับทุกคน",
  i18nKey_enterprise_2046: "วนซ้ำ?",
  i18nKey_enterprise_2047: "แน่ใจว่าจะ",
  i18nKey_enterprise_2048: "นี้",
  i18nKey_enterprise_2049: "วนซ้ำ",
  i18nKey_enterprise_2050: "การเปลี่ยนแปลงล้มเหลว กรุณาลองอีกครั้งในภายหลัง",
  i18nKey_enterprise_2051: "เปิดสำเร็จแล้ว",
  i18nKey_enterprise_2052: "ปิดเรียบร้อยแล้ว",
  i18nKey_enterprise_2053: "การเปลี่ยนแปลงล้มเหลว",
  i18nKey_enterprise_2054: "สองต่อหนึ่งรอบ",
  i18nKey_enterprise_2055: "การตรวจจับความล่าช้าของการส่องแสงสลัว",
  i18nKey_enterprise_2056: "ชื่อเกตเวย์ 2",
  i18nKey_enterprise_2057: "กรุณาเลือกเวลาเปิดทำการ",
  i18nKey_enterprise_2058: "กรุณากรอกข้อมูล",
  i18nKey_enterprise_2059: "กรุณากรอกจุด",
  i18nKey_enterprise_2060: "กรุณากรอกโทรศัพท์เพื่อค้นหารายการที่ตรงกัน",
  i18nKey_enterprise_2061: "กรุณาเลือกการแจ้งเตือน",
  i18nKey_enterprise_2062: "เพิ่มแล้ว",
  i18nKey_enterprise_2063: "กรุณากรอกข้อมูลเครื่องมือใหม่",
  i18nKey_enterprise_2064: "ส่งการเปลี่ยนแปลง",
  i18nKey_enterprise_2065: "การเปลี่ยนแปลงสำเร็จแล้ว!",
  i18nKey_enterprise_2066: "ดาวน์โหลดเทมเพลตมิเตอร์",
  i18nKey_enterprise_2067: "ดาวน์โหลดเทมเพลตมิเตอร์น้ำ",
  i18nKey_enterprise_2068: "กรุณากรอกหมวดเครื่องมือ",
  i18nKey_enterprise_2069: "กรุณากรอกหมายเลขตารางมิเตอร์",
  i18nKey_enterprise_2070: "ตัวเลขคือ",
  i18nKey_enterprise_2071: "ประกอบด้วยตัวเลข!",
  i18nKey_enterprise_2072: "กรุณากรอกระยะเวลาให้ถูกต้อง โดยมีรูปแบบดังนี้",
  i18nKey_enterprise_2073: "กรุณากรอกสถานะ",
  i18nKey_enterprise_2074:
    "มีการตรวจสอบไฟล์ที่นำเข้าปัจจุบันว่าไม่ถูกต้อง กรุณาแก้ไขในเครื่องและนำเข้าใหม่!",
  i18nKey_enterprise_2075: "เครื่องมือที่มีอยู่ครอบคลุมหรือไม่?",
  i18nKey_enterprise_2076: "อุปกรณ์:",
  i18nKey_enterprise_2077: "การอัพเดทล้มเหลว",
  i18nKey_enterprise_2078: "อาคาร",
  i18nKey_enterprise_2079: "ห้อง",
  i18nKey_enterprise_2080: "เทมเพลตฟอร์ม",
  i18nKey_enterprise_2081: "กรุณากรอกข้อมูลที่จำเป็น",
  i18nKey_enterprise_2082: "กรุณากรอกหมายเลขโทรศัพท์มือถือตามกฎหมาย",
  i18nKey_enterprise_2083: "บ้าน",
  i18nKey_enterprise_2084: "2 ห้องและ 1 ห้องนั่งเล่น",
  i18nKey_enterprise_2085: "รีเซ็ตสำเร็จ",
  i18nKey_enterprise_2086: "บริเวณที่จอดรถ",
  i18nKey_enterprise_2087: "แก้",
  i18nKey_enterprise_2088: "เพิ่มอุปกรณ์ในพื้นที่สาธารณะ",
  i18nKey_enterprise_2089: "อัปเดตข้อมูลอุปกรณ์เรียบร้อยแล้ว",
  i18nKey_enterprise_2090: "ยืนยันการลบอุปกรณ์",
  i18nKey_enterprise_2091: "เพิ่มอุปกรณ์สำเร็จ",
  i18nKey_enterprise_2092:
    "ไม่สามารถกู้คืนได้หลังจากลบ แน่ใจหรือไม่ว่าต้องการลบ?",
  i18nKey_enterprise_2093: "ภาพรวมพนักงาน",
  i18nKey_enterprise_2094: "แนวโน้มการเปรียบเทียบบุคลากร",
  i18nKey_enterprise_2095: "เกือบสองสัปดาห์",
  i18nKey_enterprise_2096: "12 สัปดาห์ที่ผ่านมา",
  i18nKey_enterprise_2097: "12 เดือนล่าสุด",
  i18nKey_enterprise_2098: "ภาพวิดีโอล่าช้าประมาณ 5",
  i18nKey_enterprise_2099: "กรุณารอ",
  i18nKey_enterprise_2100: "กำลังโหลดวิดีโอ",
  i18nKey_enterprise_2101: "กรุณารอ",
  i18nKey_enterprise_2102: "ความล้มเหลว",
  i18nKey_enterprise_2103: "กรุณาเข้าสู่เซิร์ฟเวอร์",
  i18nKey_enterprise_2104: "หรือชื่อโดเมน",
  i18nKey_enterprise_2105: "เบราว์เซอร์ของคุณไม่รองรับ",
  i18nKey_enterprise_2106: "จำนวนเงินที่ระงับชั่วคราว",
  i18nKey_enterprise_2107: "จำนวนเงินที่ชำระแบบแพ็คเกจ",
  i18nKey_enterprise_2108: "กรุณากรอกยี่ห้อรถ",
  i18nKey_enterprise_2109: "ออดี้",
  i18nKey_enterprise_2110: "เบนซ์",
  i18nKey_enterprise_2111: "บีเอ็มดับเบิลยู",
  i18nKey_enterprise_2112: "ฮอนด้า",
  i18nKey_enterprise_2113: "โตโยต้า",
  i18nKey_enterprise_2114: "สาธารณะ",
  i18nKey_enterprise_2115: "ฟอร์ด",
  i18nKey_enterprise_2116: "บูอิค",
  i18nKey_enterprise_2117: "การชำระเงินในเดือนที่ผ่านมา",
  i18nKey_enterprise_2118: "การชำระเงินสะสม",
  i18nKey_enterprise_2119: "มากกว่า 6 ตัวเลือกสำหรับป้ายทะเบียน",
  i18nKey_enterprise_2120: "การแสดงผลที่ดีที่สุดสำหรับคุณ",
  i18nKey_enterprise_2121: "แบรนด์มากกว่า 6 ตัวเลือก",
  i18nKey_enterprise_2122: "มีให้เลือกมากกว่า 6 สี",
  i18nKey_enterprise_2123: "มากกว่า 6 ตัวเลือกสำหรับป้ายทะเบียนและยี่ห้อ",
  i18nKey_enterprise_2124: "ป้ายทะเบียนและสีมากกว่า 6 ตัวเลือก",
  i18nKey_enterprise_2125: "มากกว่า 6 ตัวเลือกสำหรับแบรนด์และสี",
  i18nKey_enterprise_2126: "ป้ายทะเบียนยี่ห้อและสีมากกว่า 6 แบบ",
  i18nKey_enterprise_2127: "จำนวนแขกที่กลับมา",
  i18nKey_enterprise_2128: "คน)",
  i18nKey_enterprise_2129: "จำนวนการเยี่ยมชมของลูกค้าซ้ำ",
  i18nKey_enterprise_2130: "คน)",
  i18nKey_enterprise_2131: "สัมภาษณ์โดยตรง",
  i18nKey_enterprise_2132: "การเลือกเวลา",
  i18nKey_enterprise_2133: "กรุณาเลือกเวลากรอง",
  i18nKey_enterprise_2134: "จุดเริ่ม",
  i18nKey_enterprise_2135: "เกือบหนึ่งสัปดาห์",
  i18nKey_enterprise_2136: "การไหลของผู้โดยสาร",
  i18nKey_enterprise_2137: "ปี",
  i18nKey_enterprise_2138: "วันที่เริ่มต้น",
  i18nKey_enterprise_2139: "วัน",
  i18nKey_enterprise_2140: "ชื่อเทศกาล",
  i18nKey_enterprise_2141: "รายละเอียดปฏิทิน",
  i18nKey_enterprise_2142: "ผู้เช่า",
  i18nKey_enterprise_2143: "ลบเรียบร้อยแล้ว",
  i18nKey_enterprise_2144: "การนำออกล้มเหลว",
  i18nKey_enterprise_2145: "แก้ไขไม่สำเร็จ!",
  i18nKey_enterprise_2146: "อุปกรณ์ที่ไม่ได้มอบหมาย",
  i18nKey_enterprise_2147: "ดาวน์โหลดล้มเหลว",
  i18nKey_enterprise_2148: "กรุณากรอกชื่อผู้เช่า",
  i18nKey_enterprise_2149: "กรุณาเลือกสถานะร้านค้า",
  i18nKey_enterprise_2150: "ไม่มีอุปกรณ์",
  i18nKey_enterprise_2151: "จำนวนห้องพัก:",
  i18nKey_enterprise_2152: "ลบห้อง",
  i18nKey_enterprise_2153: "ตกลงเพื่อลบห้อง",
  i18nKey_enterprise_2154: "จำนวนฉาก:",
  i18nKey_enterprise_2155: "สวิตช์สถานการณ์",
  i18nKey_enterprise_2156: "แน่ใจหรือไม่ว่าจะลบสถานการณ์เหล่านี้ ?",
  i18nKey_enterprise_2157: "ตกลงเพื่อลบฉาก",
  i18nKey_enterprise_2158: "กรุณาเลือกชื่อพื้นที่",
  i18nKey_enterprise_2159: "กรุณาเลือกวันที่เริ่มต้น",
  i18nKey_enterprise_2160: "จำเป็นต้องระบุชื่อเหตุการณ์",
  i18nKey_enterprise_2161: "กรุณากรอกชื่อเทศกาล",
  i18nKey_enterprise_2162: "หัวข้อระดับ 1",
  i18nKey_enterprise_2163: "หัวข้อรอง 1",
  i18nKey_enterprise_2164: "ระดับ 3 หัวข้อ 1",
  i18nKey_enterprise_2165: "หัวข้อรอง 2",
  i18nKey_enterprise_2166: "ระดับ 3 หัวข้อ 2",
  i18nKey_enterprise_2167: "ขนาดความเย็น",
  i18nKey_enterprise_2168: "กำลังดาวน์โหลดแบบฟอร์มความเย็น",
  i18nKey_enterprise_2169: "ตัวเลขเป็นตัวเลขขนาดใหญ่!",
  i18nKey_enterprise_2170: "ชื่อธุรกิจ/ผู้เช่า",
  i18nKey_enterprise_2171: "ชื่อธุรกิจ/ผู้เช่า ",
  i18nKey_3066: "เวลาออก",
  i18nKey_3067: "การกำหนดค่าสูตร",
  i18nKey_3068: "การกำหนดค่ารายการย่อย",
  i18nKey_3069: "การกำหนดค่าสาขา",
  i18nKey_3070: "การกำหนดค่าพื้นที่อาคาร",
  i18nKey_3071: "การกำหนดค่าผู้เช่า",
  i18nKey_3072: "โรงงานทำความเย็น",
  i18nKey_3073: "เครื่องยนต์ระบายความร้อน",
  i18nKey_3074: "คูลลิ่งทาวเวอร์",
  i18nKey_3075: "คูลลิ่งปั๊ม",
  i18nKey_3076: "โรงไฟฟ้าพลังความร้อน",
  i18nKey_3077: "เครื่องทำความร้อน",
  i18nKey_3078: "ปั๊มความร้อน",
  i18nKey_3079: "ขั้วไฟฟ้ากระแสสลับ",
  i18nKey_3080: "กล่องไฟ",
  i18nKey_3081: "VMC",
  i18nKey_3082: "เทอร์มินัลอื่นๆ",
  i18nKey_3083: "บันไดเลื่อน",
  i18nKey_3084: "ลิฟท์โดยสาร",
  i18nKey_3085: "ลิฟต์ท่องเที่ยว",
  i18nKey_3086: "พัดลม",
  i18nKey_3087: "โบลเวอร์",
  i18nKey_3088: "พัดลมดูดอากาศ",
  i18nKey_3089: "เครื่องดูดควัน",
  i18nKey_3090: "เครื่องช่วยหายใจ",
  i18nKey_3091: "ปั๊ม",
  i18nKey_3092: "ปั๊มน้ำภายใน",
  i18nKey_3093: "ปั๊มน้ำเสีย",
  i18nKey_3094: "ระบบน้ำรีเคลม",
  i18nKey_3095: "ไฟฉุกเฉิน",
  i18nKey_3096: "ไฟแสงสว่างสาธารณะ",
  i18nKey_3097: "น้ำท่วมแจ้งน้ำท่วม",
  i18nKey_3098: "แสงแนวนอน",
  i18nKey_3099: "แท่นชาร์จ",
  i18nKey_3100: "ที่จอดรถเครื่องกล",
  i18nKey_3101: "ชัตเตอร์ไฟ",
  i18nKey_3102: "ห้อง HV/LV",
  i18nKey_3103: "ห้องปรับอากาศ",
  i18nKey_3104: "ห้อง LV",
  i18nKey_3105: "กาใช้พลังงานของอพาร์ตเมนต์",
  i18nKey_3106: "ร้านค้าหลัก",
  i18nKey_3107: "ร้านค้าหลัก ชั้น 2",
  i18nKey_3108: "เลือกอุปกรณ์",
  i18nKey_3109: "หมายเลขมิเตอร์",
  i18nKey_3110: "กรุณาเลือกหมายเลขมิเตอร์",
  i18nKey_3111: "กรุณาใส่หมายเลขมิเตอร์",
  i18nKey_3112: "ปริมาณการใช้พลังงานไฟฟ้า",
  i18nKey_3113: "ปริมาณการใช้น้ำของผู้เช่า",
  i18nKey_3114: "ปริมาณการใช้น้ำสาธารณะ",
  i18nKey_3115: "ชื่อ: มาตรวัดน้ำ",
  i18nKey_3116: "การใช้ความเย็นของผู้เช่า",
  i18nKey_3117: "เพิ่มรายการ",
  i18nKey_3118: "ชื่อสินค้า",
  i18nKey_3119: "เพิ่มรายการของระดับใหม่",
  i18nKey_3120: "ชื่อย่ออุปกรณ์",
  i18nKey_3121: "ความเป็นเจ้าของอุปกรณ์",
  i18nKey_3122: "กรุณาใส่ความเป็นเจ้าของอุปกรณ์",
  i18nKey_3123: "วันหมดอายุของอุปกรณ์",
  i18nKey_3124: "กรุณาใส่ยี่ห้ออุปกรณ์",
  i18nKey_3125: "ช่วงไฟฟ้า",
  i18nKey_3126: "กรุณาเลือกช่วงการใช้ไฟฟ้า",
  i18nKey_3127: "การกำหนดค่าพื้นที่อาคาร",
  i18nKey_3128: "สอบถามข้อมูลผู้เช่า",
  i18nKey_3129: "กรุณาใส่ชื่อผู้เช่าหรือชื่อแบรนด์",
  i18nKey_3130: "ชื่อผู้เช่า",
  i18nKey_3131: "ค้นหามิเตอร์",
  i18nKey_3132: "กรุณาใส่เลขมิเตอร์, เลขมิเตอร์อัจฉริยะ, ชื่อมิเตอร์",
  i18nKey_3133: "จำนวนที่จอดรถ",
  i18nKey_3134: "รถปัจจุบัน",
  i18nKey_3135: "ชุดรถ",
  i18nKey_3136: "ที่จอดรถ",
  i18nKey_3137: "จักรยานสีขาว",
  i18nKey_3138: "ยานพาหนะอื่นๆ",
  i18nKey_3139: "รถที่ไม่ใช่ชุดที่จอดรถและรายชื่อรถสีขาว",
  i18nKey_3140: "ประเภทของป้ายทะเบียนรถ",
  i18nKey_3141: "กรุณาเลือกประเภทของป้ายทะเบียนรถ",
  i18nKey_3142: "สถิติเหตุการณ์",
  i18nKey_3143: "งาน",
  i18nKey_3144: "บัญชีผู้เช่า",
  i18nKey_3145: "ตารางอัตโนมัติ",
  i18nKey_3146: "ปัจจุบันไม่มีส่วนย่อย",
  i18nKey_3147: "สร้างโดยใช้แม่แบบ",
  i18nKey_3148: "ตัวอย่างแม่แบบ",
  i18nKey_3149: "ประเภทของตัวเลขที่มีขนาดใหญ่กว่า",
  i18nKey_3150: "เลือกการตั้งค่ามาตรวัด",
  i18nKey_3151: "เลือกการตั้งค่าย่อย",
  i18nKey_3152: "เพิ่มหรือลบเครื่องมือในระดับถัดไปคุณต้องด้วยตนเองเลือกอุปกรณ์ที่คุณต้องการดึงเพื่อปรับปรุงรายการย่อยขั้นสูง",
  i18nKey_3153: "บันทึกย่อสุดท้ายคือการปรับแต่งโดยการเลือกมาตรวัด เมื่อมีเครื่องวัดการจัดสรรโดยการเลือกเครื่องวัด เมื่อมีการเพิ่มหรือลบเครื่องมือในระดับถัดไปและระดับที่สูงขึ้นจะถูกกำหนดค่าผ่านส่วนประกอบระบบจะปรับปรุงโดยอัตโนมัติ",
  i18nKey_3154: "ค่าเป็นชนิดของตัวเลข",
  i18nKey_3155: "ประเภทของตัวเลข",
  i18nKey_3156: "กรุณาเลือกชื่อย่อย",
  i18nKey_3157: "สูตรการใช้พลังงาน",
  i18nKey_3158: "สูตรต่อเมตร",
  i18nKey_3159: "เลือกส่วน",
  i18nKey_3160: "บันทึกล้มเหลวโปรดระบุพื้นที่",
  i18nKey_3161: "หมายเลขเครื่องวัดไฟฟ้าไม่สามารถว่างเปล่า",
  i18nKey_3162: "แยก ID ไม่สามารถว่างเปล่า",
  i18nKey_3163: "ตำแหน่งเครื่องมือวัด",
  i18nKey_3164: "ย่อย",
  i18nKey_3165: "ล้มเหลวในการบันทึกหมายเลขเครื่องวัดไฟฟ้าไม่สามารถว่างเปล่า",
  i18nKey_3166: "กรุณาใส่ชื่ออุปกรณ์หลัก",
  i18nKey_3167: "กรุณาป้อนชื่อย่อของอุปกรณ์ระดับแรก",
  i18nKey_3168: "ดาวน์โหลดแม่แบบนำเข้า",
  i18nKey_3169: "ปรับแต่งแม่แบบนำเข้าสาขา",
  i18nKey_3170: "ไฟฟ้าเชิงพาณิชย์",
  i18nKey_3171: "การใช้ไฟฟ้าในครัวเรือน",
  i18nKey_3172: "ไฟฟ้าเพื่อการเกษตร",
  i18nKey_3173: "ไฟฟ้าอุตสาหกรรม",
  i18nKey_3174: "เมือง 10kv ไฟฟ้า",
  i18nKey_3175: "ดึงลงอุปกรณ์",
};
