export default {
  module_fillPositionArr(state, positionArr) {
    state.basePosition.positionArr = positionArr;
  },
  module_fillUser(state, userInfo) {
    state.baseUser._info = userInfo;
  },
  module_fillAllPosLeafnodeCodeArr(state, arr) {
    state.navigation.allPosLeafnodeCodeArr = arr;
  },
  fillErrStatus(state, code) {
    state.errStatus = {code};
  },
  increaseZIndex(state) {
    state.zIndex++;
  },
  showLoading(state) {
    state.loading = true;
  },
  hideLoading(state) {
    state.loading = false;
  },
  fillUser(state, userInfo) {
    state.userInfo = userInfo;
  },
  fillIsBIMMap(state, isBIMMap) {
    state.isBIMMap = isBIMMap;
  },
  fillBIMType(state, BIMType) {
    state.BIMType = BIMType;
  },
  fillLeftNav(state, navList) {
    state.leftNavList = navList;
  },
  fillBtnAuth(state, obj) {
    state.btnAuthObj = obj;
  },
  fillRoutePath(state, path) {
    state.routePath = path;
  },
  fillRouteName(state, name) {
    state.routeName = name;
  },
  fillNowToXHRTimestamp(state) {
    state.XHRTimestamp = Date.now();
  },
  fillToFromRoute(state, toFromRoute) {
    Object.assign(state, {toFromRoute});
  }
};