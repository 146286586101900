/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2020-02-12 16:07:58
 * @LastEditTime: 2021-01-07 15:45:25
 * @LastEditors: Please set LastEditors
 */
const meetingManage = () =>
  import(/* webpackChunkName: "meetingManage" */ "@/pages/companyService/meetingManage"); // 会议室管理
const meetingReserve = () =>
  import(/* webpackChunkName: "meetingReserve" */ "@/pages/companyService/meetingReserve"); // 会议室预订视图
const discountManage = () =>
  import(/* webpackChunkName: "discountManage" */ "@/pages/companyService/discountManage"); // 优惠管理
const openDoorRecord = () =>
  import(/* webpackChunkName: "openDoorRecord" */ "@/pages/companyService/openDoorRecord"); // 开门记录
const priceManage = () =>
  import(/* webpackChunkName: "priceManage" */ "@/pages/companyService/priceManage"); // 价格管理
const addPriceManage = () =>
  import(/* webpackChunkName: "addPriceManage" */ "@/pages/companyService/components/addPriceManage"); // 价格管理配置页面
const freeChargeManage = () =>
  import(/* webpackChunkName: "freeChargeManage" */ "@/pages/companyService/freeChargeManage"); // 免额管理
const freeChargeNewOrModify = () =>
  import(/* webpackChunkName: "freeChargeNewOrModify" */ "@/pages/companyService/components/freeChargeNewOrModify"); // 免额管理新增，修改
const payModeConfig = () =>
  import(/* webpackChunkName: "payModeConfig" */ "@/pages/companyService/payModeConfig"); // 付费方式配置
const handCopyTableManagement = () =>
  import(/* webpackChunkName: "handCopyTableManagement" */ "@/pages/companyService/handCopyTableManagementPage/handCopyTableManagement"); // 手工抄表管理--企业服务 handCopyTableManage
const meetRoomOrder = () =>
  import(/* webpackChunkName: "meetRoomOrder" */ "@/pages/meetRoom/meetRoomOrder"); // 会议室订单管理
const pointOrder = () =>
  import(/* webpackChunkName: "pointOrder" */ "@/pages/meetRoom/pointOrder"); // 积分订单
const vanMeetRoomOrder = () =>
  import(/* webpackChunkName: "vanMeetRoomOrder" */ "@/pages/vanMeetRoom/vanMeetRoomOrder"); // 万智汇会议室订单管理
const vanMeetRoomManager = () =>
  import(/* webpackChunkName: "vanMeetRoomOrder" */ "@/pages/vanMeetRoom/vanMeetRoomManager"); // 万智汇会议室资源维护
const vanMeetRoomSeverManager = () =>
  import(/* webpackChunkName: "vanMeetRoomOrder" */ "@/pages/vanMeetRoom/vanMeetRoomSeverManager"); // 万智汇会议室设备管理
const valueAddedServices = () =>
  import(/* webpackChunkName: "valueAddedServices" */ "@/pages/meetRoom/valueAddedServices"); // 增值服务
const hydropowerConfigure = () =>
  import(/* webpackChunkName: "hydropowerConfigure" */ "@/pages/hydropowerMgr/hydropowerConfigure"); // 水电表维护
const hydropowerConfigureAdd = () =>
  import(/* webpackChunkName: "hydropowerConfigureAdd" */ "@/pages/hydropowerMgr/hydropowerConfigureAdd"); // 水电表维护-新增/编辑
const hydropowerOutageOrder = () =>
  import(/* webpackChunkName: "hydropowerOutageOrder" */ "@/pages/hydropowerMgr/hydropowerOutageOrder"); // 断电\恢复工单
const hydropowerDetail = () =>
  import(/* webpackChunkName: "hydropowerDetail" */ "@/pages/hydropowerMgr/hydropowerDetail"); // 水电明细
const hydropowerOverview = () =>
  import(/* webpackChunkName: "hydropowerOverview" */ "@/pages/hydropowerMgr/hydropowerOverview"); // 水电账单概览
const hydropowerCostOrder = () =>
  import(/* webpackChunkName: "hydropowerCostOrder" */ "@/pages/hydropowerMgr/hydropowerCostOrder"); // 水电缴费订单
const hydropowerThreshold = () =>
  import(/* webpackChunkName: "hydropowerThreshold" */ "@/pages/hydropowerMgr/hydropowerThreshold"); // 水电阈值
const wiseBusOrder = () =>
  import(/* webpackChunkName: "wiseBusOrder" */ "@/pages/wiseBus/wiseBusOrder"); // 智慧巴士订单页
const busShifts = () =>
  import(/* webpackChunkName: "busShifts" */ "@/pages/wiseBus/busShifts"); // 智慧巴士乘车班次
const suitManage = () =>
  import(/* webpackChunkName: "suitManage" */ "@/pages/wiseBus/suitManage"); // 智慧巴士套餐管理
const shareOfficeManage = () =>
  import(/* webpackChunkName: "shareOfficeManage" */ "@/pages/shareOffice/shareOfficeManage"); // 共享办公管理
const shareOfficeOrder = () =>
  import(/* webpackChunkName: "shareOfficeOrder" */ "@/pages/shareOffice/shareOfficeOrder"); // 共享办公订单
const limitedTimeDiscount = () =>
  import(/* webpackChunkName: "limitedTimeDiscount" */ "@/pages/shareOffice/limitedTimeDiscount"); // 限时折扣
const dataStatistics = () =>
  import(/* webpackChunkName: "dataStatistics" */ "@/pages/companyService/handCopyTableManagementPage/dataStatistics"); // 手工抄表数据统计
const electricityAbnormal = () =>
  import(/* webpackChunkName: "electricityAbnormal" */ "@/pages/greenEnergySaving/electricityAbnormal/electricityAbnormal"); // 用电异常监控
const busRideRecordPage = () =>
  import(/* webpackChunkName: "busRideRecordPage" */ "@/pages/vehicleManagement/busManagePage/rideRecordPage"); // 云城巴士后台-乘车记录
const busEquipManagementPage = () =>
  import(/* webpackChunkName: "busEquipManagementPage" */ "@/pages/vehicleManagement/busManagePage/busEquipManagementPage"); // 云城巴士后台-设备管理
const busLinePage = () =>
  import(/* webpackChunkName: "busEquipManagementPage" */ "@/pages/vehicleManagement/busManagePage/busLinePage"); // 云城巴士后台-线路管理
const companyPunchExport = () =>
  import(/* webpackChunkName: "companyPunchExport" */ "@/pages/diseaseManage/companyPunchExport"); // 疫情监控/企业每日打卡
const boyuPunchExport = () =>
  import(/* webpackChunkName: "boyuPunchExport" */ "@/pages/diseaseManage/boyuPunchExport"); // 疫情监控/泊寓健康打卡
const diseaseRecordInfo = () =>
  import(/* webpackChunkName: "diseaseRecordInfo" */ "@/pages/diseaseManage/diseaseRecordInfo"); // 疫情监控/疫情上报记录
const diseaseStaffPreserve = () =>
  import(/* webpackChunkName: "diseaseStaffPreserve" */ "@/pages/diseaseManage/diseaseStaffPreserve"); // 疫情监控/疫情小组管理
const meetRoomIn = () =>
  import(/* webpackChunkName: "meetIn" */ "@/pages/meetInOut/meetIn"); // 会议室迁入
const meetRoomOut = () =>
  import(/* webpackChunkName: "meetOut" */ "@/pages/meetInOut/meetOut"); // 会议室迁出
const serviceApplyManage = () =>
  import(/* webpackChunkName: "serviceApplyManage" */ "@/pages/thirdServices/serviceApplyManage"); // 服务申请管理
const serviceTypeSetting = () =>
  import(/* webpackChunkName: "serviceTypeSetting" */ "@/pages/thirdServices/serviceTypeSetting"); // 服务类型设置
const depositManage = () =>
  import(/* webpackChunkName: "depositManage" */ "@/pages/companyService/depositManage"); // 押金管理
const enterpriseSeting = () =>
  import(/* webpackChunkName: "depositManage" */ "@/pages/enterpriseSearch/enterpriseSeting"); // 企业服务数据字典-增删改查
const hydropowerBillManagement = () =>
  import(/* webpackChunkName: "hydropowerBillManagement" */ "@/pages/hydropowerMgr/hydropowerBillManagement"); // 企业服务-水电管理-账单管理
const costShare = () =>
  import(/* webpackChunkName: "depositManage" */ "@/pages/costShare/index"); // 水电管理-分摊管理
const configUnitPrice = () =>
  import(/* webpackChunkName: "depositManage" */ "@/pages/costShare/configUnitPrice"); // 水电管理-分摊管理-单价配置
const configTransformer = () =>
  import(/* webpackChunkName: "depositManage" */ "@/pages/costShare/configTransformer"); // 水电管理-分摊管理-变压器配置
const articleRelease = () =>
  import(/* webpackChunkName: "articleRelease" */ "@/pages/articleRelease/articleReleaseOrder"); // 物品放行-列表
const articleReleaseNew = () =>
import(/* webpackChunkName: "articleReleaseNew" */ "@/pages/articleRelease/articleReleaseOrderNew"); // 物品放行-列表
export default [
  {
    path: "/depositManage", // 押金管理
    name: "depositManage",
    authKey: "enterpriseDepositManage",
    component: depositManage
  },
  {
    path: "/enterpriseSeting", // 企业服务数据字典
    name: "enterpriseSeting",
    authKey: "enterpriseSetingManager",
    component: enterpriseSeting
  },
  {
    path: "/serviceApplyManage", // 第三方表单服务/服务申请管理
    name: "serviceApplyManage",
    authKey: "enterpriseServiceApplyManage",
    component: serviceApplyManage
  },
  {
    path: "/serviceTypeSetting", // 第三方表单服务/服务类型设置
    name: "serviceTypeSetting",
    authKey: "enterpriseServiceTypeSetting",
    component: serviceTypeSetting
  },
  {
    path: "/companyPunchExport", // 疫情监控/企业每日打卡
    name: "companyPunchExport",
    authKey: "enterpriseCompanyPunchExport",
    component: companyPunchExport
  },
  {
    path: "/boyuPunchExport", // 疫情监控/泊寓健康打卡
    name: "boyuPunchExport",
    authKey: "enterpriseBoyuPunchExport",
    component: boyuPunchExport
  },
  {
    path: "/diseaseRecordInfo", // 疫情监控/疫情上报记录
    name: "diseaseRecordInfo",
    authKey: "enterpriseDiseaseRecordInfo",
    component: diseaseRecordInfo
  },
  {
    path: "/diseaseStaffPreserve", // 疫情监控/疫情小组管理
    name: "diseaseStaffPreserve",
    authKey: "enterpriseDiseaseStaffPreserve",
    component: diseaseStaffPreserve
  },
  {
    path: "/busShifts", // 智慧巴士乘车班次
    name: "busShifts",
    authKey: "BusShifts",
    component: busShifts
  },
  {
    path: "/limitedTimeDiscount", // 限时折扣
    name: "limitedTimeDiscount",
    authKey: "LimitedTimeDiscount",
    component: limitedTimeDiscount
  },
  {
    path: "/shareOfficeOrder", // 共享办公订单
    name: "shareOfficeOrder",
    authKey: "ShareOfficeOrder",
    component: shareOfficeOrder
  },
  {
    path: "/shareOfficeManage", // 共享办公管理
    name: "shareOfficeManage",
    authKey: "ShareOfficeManage",
    component: shareOfficeManage
  },
  {
    path: "/suitManage", // 智慧巴士套餐管理
    name: "suitManage",
    authKey: "SuitManage",
    component: suitManage
  },
  {
    path: "/wiseBusOrder", // 智慧巴士订单页
    name: "wiseBusOrder",
    authKey: "BusOrder",
    component: wiseBusOrder
  },
  {
    path: "/meetingReserve", // 会议室预订视图
    name: "meetingReserve",
    authKey: "MeetingReserve",
    component: meetingReserve
  },
  {
    path: "/meetingManage", // 会议室管理
    name: "meetingManage",
    authKey: "MeetingManage",
    component: meetingManage
  },
  {
    path: "/discountManage", // 优惠管理
    name: "discountManage",
    authKey: "DiscountManage",
    component: discountManage
  },
  {
    path: "/openDoorRecord", // 开门记录
    name: "openDoorRecord",
    authKey: "OpenDoorRecord",
    component: openDoorRecord
  },
  {
    path: "/priceManage", // 价格管理
    authKey: "PriceManage",
    component: { template: "<router-view />" },
    children: [
      {
        path: "",
        name: "priceManage",
        component: priceManage
      },
      {
        path: "addPriceManage", // 价格管理配置页面
        name: "addPriceManage",
        authKey: "addPriceManage",
        meta: {
          title: "配置"
        },
        component: addPriceManage
      },
      {
        path: "checkPriceManage", // 价格管理查看页面
        name: "checkPriceManage",
        authKey: "checkPriceManage",
        meta: {
          title: "查看"
        },
        component: addPriceManage
      }
    ]
  },
  {
    path: "/freeChargeManage", // 免额管理
    authKey: "FreeChargeManage",
    component: { template: "<router-view />" },
    children: [
      {
        path: "", // 免额管理
        name: "freeChargeManage",
        component: freeChargeManage
      },
      {
        path: "addFreeCharge", // 新增免额管理
        name: "addFreeCharge",
        authKey: "addFreeCharge",
        meta: {
          title: "新增"
        },
        component: freeChargeNewOrModify
      },
      {
        path: "modifyFreeCharge", // 修改免额管理
        name: "modifyFreeCharge",
        authKey: "modifyFreeCharge",
        meta: {
          title: "修改"
        },
        component: freeChargeNewOrModify
      },
      {
        path: "checkFreeCharge", // 查看免额管理
        name: "checkFreeCharge",
        authKey: "checkFreeCharge",
        meta: {
          title: "查看"
        },
        component: freeChargeNewOrModify
      }
    ]
  },
  {
    path: "/payModeConfig", // 付费方式配置
    name: "payModeConfig",
    authKey: "PayModeConfig",
    component: payModeConfig
  },
  {
    path: "/handCopyTableManagement", // 手工抄表管理--企业服务 handCopyTableManage
    name: "handCopyTableManagement",
    authKey: "HandCopyTableManagement",
    component: handCopyTableManagement
  },
  {
    path: "/meetRoomOrder", // 会议室订单管理
    name: "meetRoomOrder",
    authKey: "MeetRoomOrder",
    component: meetRoomOrder
  },
  {
    path: "/pointOrder", // 积分订单
    name: "pointOrder",
    authKey: "pointOrder",
    component: pointOrder
  },
  {
    path: "/vanMeetRoomOrder", // 万智汇会议室订单管理
    name: "vanMeetRoomOrder",
    authKey: "VanMeetRoomOrder",
    component: vanMeetRoomOrder
  },
  {
    path: "/vanMeetRoomManager", // 万智汇会议室资源维护
    name: "vanMeetRoomManager",
    authKey: "VanMeetRoomManager",
    component: vanMeetRoomManager
  },
  {
    path: "/vanMeetRoomSeverManager", // 万智汇会议室设备管理
    name: "vanMeetRoomSeverManager",
    authKey: "vanMeetRoomSeverManager",
    component: vanMeetRoomSeverManager
  },
  {
    path: "/meetRoomIn", // 会议室迁入
    name: "meetRoomIn",
    authKey: "meetRoomIn",
    component: meetRoomIn
  },
  {
    path: "/meetRoomOut", // 会议室迁出
    name: "meetRoomOut",
    authKey: "meetRoomOut",
    component: meetRoomOut
  },
  {
    path: "/valueAddedServices", // 增值服务
    name: "valueAddedServices",
    authKey: "ValueAddedServices",
    component: valueAddedServices
  },
  {
    path: "/hydropowerOutageOrder", // 断电\恢复工单
    name: "hydropowerOutageOrder",
    component: hydropowerOutageOrder
  },
  {
    path: "/hydropowerDetail/:arrearType?/:meterType?", // 水电明细
    name: "hydropowerDetail",
    component: hydropowerDetail
  },
  {
    path: "/hydropowerOverview", // 水电账单概览
    name: "hydropowerOverview",
    component: hydropowerOverview
  },
  {
    path: "/hydropowerCostOrder", // 水电缴费订单
    name: "hydropowerCostOrder",
    authKey: "HydropowerCostOrder",
    component: hydropowerCostOrder
  },
  {
    path: "/hydropowerThreshold", // 水电阈值
    name: "hydropowerThreshold",
    authKey: "HydropowerThreshold",
    component: hydropowerThreshold
  },
  {
    path: "/hydropowerBillManagement", //
    name: "hydropowerBillManagement",
    authKey: "hydropowerBillManagement",
    component: hydropowerBillManagement
  },
  {
    path: "/vehicle/busRideRecordPage", // 云城巴士后台-乘车记录
    name: "busRideRecordPage",
    authKey: "BusRideRecord",
    component: busRideRecordPage
  },
  {
    path: "/vehicle/busEquipManagementPage", // 云城巴士后台-设备管理
    name: "busEquipManagementPage",
    authKey: "BusEquipManagement",
    component: busEquipManagementPage
  },
  {
    path: "/vehicle/busLinePage", // 云城巴士后台-路线管理
    name: "busLinePage",
    authKey: "BusEquipManagement",
    component: busLinePage
  },
  {
    path: "/electricityAbnormal", // 手动用电异常监控
    name: "electricityAbnormal",
    authKey: "ElectricityAbnormal",
    component: electricityAbnormal
  },
  {
    path: "/dataStatistics", // 手工抄表数据统计
    name: "dataStatistics",
    authKey: "DataStatistics",
    component: dataStatistics
  },
  {
    path: "/hydropowerConfigure", // 水电表维护
    authKey: "hydropowerConfigure",
    component: { template: "<router-view />" },
    children: [
      {
        path: "",
        name: "hydropowerConfigure",
        component: hydropowerConfigure
      },
      {
        path: "add", // 新增
        name: "hydropowerConfigureAdd",
        component: hydropowerConfigureAdd
      },
      {
        path: "edit", // 编辑
        name: "hydropowerConfigureEdit",
        component: hydropowerConfigureAdd
      }
    ]
  },
  {
    path: "/costShare", // 分摊管理
    authKey: "costShare",
    component: { template: "<router-view />" },
    children: [
      {
        path: "", // 分摊管理
        name: "costShare",
        component: costShare
      },
      {
        path: "configUnitPrice", // 单价配置
        name: "configUnitPrice",
        component: configUnitPrice
      },
      {
        path: "configTransformer", // 变压器配置
        name: "configTransformer",
        component: configTransformer
      }
    ]
  },
  {
    path: "/articleRelease", // SaaS物品放行
    name: "articleRelease",
    authKey: "articleRelease",
    component: articleRelease
  },
  {
    path: "/articleReleaseNew", // 科创委物品放行
    name: "articleReleaseNew",
    authKey: "articleReleaseNew",
    component: articleReleaseNew
  }
];
