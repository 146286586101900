import {
  RES_CODE_UNLOGIN,
  RES_CODE_NOAUTH,
  RES_CODE_REPASSWORD
} from "@/common/utils/constant";
import VUE from "vue";
import store from "@/store";
import router from "@/router";

const reLogin = () => {
  if (location.pathname.indexOf("/login") === -1) {
    sessionStorage.removeItem("IOC_WEB_SESSION");
    router.push({ path: "/login" });
  }
};
let alertSwi = true;
export default (code) => {
  switch (code) {
    case RES_CODE_UNLOGIN:
      reLogin();
      break;
    case RES_CODE_NOAUTH:
      router.push({ path: "/noCascadeMenu" });
      break;
    case RES_CODE_REPASSWORD:
      if (!alertSwi) return;
      alertSwi = false;
      const FILTER_PATH = [
        "/login",
        "/pwRedirect"
      ];
      !FILTER_PATH.includes(store.state.routePath) && (new VUE()).$alert("您已成功修改密码，需要您重新登录", "", {
        confirmButtonText: "确定",
        callback: () => {
          reLogin();
          alertSwi = true;
        }
      });
      break;

    case 404:
      router.replace({ path: "/404" });
      break;
    default:
      break;
  }
};