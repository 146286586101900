export default {
  namespaced: true,
  state: {
    locationLevel: 1,
    locationCode: {
      country: "",
      area: "",
      city: "",
      park: ""
    },
    selectLocation: null, // 从页面更改顶部层级选项
    selectLevel: null, // 页面更改到第几层级
    selectName: null,
    parkObj: null,
    parkName: null
  },
  mutations: {
    increment(state, data) {
      state.locationLevel = data.locationLevel;
      state.locationCode = data.locationCode;
    },
    parkValue(state, data) {
      state.parkObj = data;
    },
    selectLoc(state, data) {
      state.selectLevel = data.selectLevel;
      state.selectLocation = data.selectLocation;
    },
    parkName(state, data) {
      if (typeof data === "object") {
        if (data.name === undefined) {
          data.name = "";
        }
        // sessionStorage.setItem("carParkName", data.name);
        state.parkName = data.name || null;
      } else {
        if (data === undefined) {
          data = "";
        }
        // sessionStorage.setItem("parkName", data);
        state.parkName = data || null;
      }
    }
  }
};
