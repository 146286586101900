export default {
  namespaced: true,
  state: {
    _info: {}
  },
  mutations: {
  },
  getters: {
    getItem: (state) => (attr) => {
      return attr in state._info ? state._info[attr] : "";
    },
    getDecItem: (state) => (attr) => {
      if (!attr) return "";
      let item = state._info[attr] || state._info[`${attr}Enc`] || "";
      return item ? PowerFn.decrypt(item) : "";
    }
  }
};