
export default {
  namespaced: true,
  state: {
    isReadyPlay: false,
    clientId: "",
    isOnlieCamera: false, // 摄像头是否在线
    isEndPatrol: false, // 结束巡逻
    stopVideoPlay: false, // 停止播放
    isOnPlaying: false, // 是否开启了巡逻
    isFirstEntry: true, // 是否第一次进来
    videoPlayStatus: 1, // 当前播放状态 0暂停 1播放 2结束
    parkIntenetType: "internet", // 园区网络类型
    randomOnlineStatus: 0, // 在线打卡状态
    flvUrlObj: {
      remCurrentTime: 0, // 记录成功拿到流地址后开始计时
      isSuccessGetUrl: true // 是否成功拿到地址
    },
    reportObj: {
      taskHeaderId: "", // 当前巡逻组的taskHeaderId
      patroIndex: 0 // 当前巡逻下标
    },
    patroPlansTotal: 0, // 视频巡逻计划总数
    patroPlansListData: [], // 视频巡逻计划列表
    initPatroPlansListData: [],
    patrolGroupListData: [],
    cameraListData: [],
    cameraPatroTime: 0, // 当前摄像头巡逻时间
    percentage: 0, // 当前环形百分比
    cameraPatroTimeTotal: 0, // 当前摄像头巡逻时间总和
    currentPatroPlayIndex: 0, // 记录当前是哪个视频组
    groupActiveIndex: -1, // 当前组巡逻高亮下标
    isShowIconForVideo: { // 是否展示截图和上报图标
      berichten: true,
      wabei: true,
      onlineBtn: true
    },
    planAccount: 0,
    isShowClickBtn: false, // 在线打卡按钮
    isCurrentTab: 0, // 当前是显示0视频巡逻计划 or 视频巡逻路线1
    videoStatus: "living", // 当前是结束还是暂停的状态
    successGetFlvUrl: false, // 成功拿到流地址
    isRepeatPatroPlan: true // 是否循环播放当前计划
  },
  mutations: {
    isReadyPlayFn(state, data) {
      state.isReadyPlay = data;
    },
    getClientId(state, data) {
      state.clientId = data;
    },
    isOnlieCameraFn(state, data) {
      state.isOnlieCamera = data;
    },
    isEndPatrolFn(state, data) {
      state.isEndPatrol = data;
    },
    isStopVideoFn(state, data) {
      state.stopVideoPlay = data;
    },
    remCurrentTimeFn(state, data) {
      state.flvUrlObj.isSuccessGetUrl = data.isSuccessGetUrl;
      state.flvUrlObj.remCurrentTime = data.remCurrentTime;
    },
    remTaskHeaderIdFn(state, data) {
      state.reportObj.taskHeaderId = data.taskHeaderId;
      state.reportObj.patroIndex = data.patroIndex;
    },
    isOnPlayingFn(state, data) {
      state.isOnPlaying = data;
    },
    isFirstEntryFn(state, data) {
      state.isFirstEntry = data;
    },
    videoPlayStatusFn(state, data) {
      state.videoPlayStatus = data;
    },
    parkIntenetTypeFn(state, data) {
      state.parkIntenetType = data;
    },
    randomOnlineStatusFn(state, data) {
      state.randomOnlineStatus = data;
    },
    getPatroPlansTotal(state, data) {
      state.patroPlansTotal = data;
    },
    getPatroPlansList(state, data) {
      state.patroPlansListData = data;
    },
    getInitPatroPlansListData(state, data) {
      state.initPatroPlansListData = data;
    },
    getPatrolGroupList(state, data) {
      state.patrolGroupListData = data;
    },
    getCameraList(state, data) {
      state.cameraListData = data;
    },
    getCameraPatroTime(state, data) {
      state.cameraPatroTime = data;
    },
    changePercentage(state, data) {
      state.percentage = data;
    },
    changeCameraPatroTime(state, data) {
      state.cameraPatroTime = data;
    },
    getCameraPatroTimeTotal(state, data) {
      state.cameraPatroTimeTotal = data;
    },
    changeCurrentPatroPlayIndex(state, data) {
      state.currentPatroPlayIndex = data;
    },
    changeGroupActiveIndex(state, data) {
      state.groupActiveIndex = data;
    },
    isShowIconForVideoFn(state, data) {
      state.isShowIconForVideo = data;
    },
    changePlanAccount(state, data) {
      state.planAccount = data;
    },
    isShowClickBtnFn(state, data) {
      state.isShowClickBtn = data;
    },
    isCurrentTabFn(state, data) {
      state.isCurrentTab = data;
    },
    resetPatrolData(state) {
      state.percentage = 0;
      state.isOnPlaying = false;
      state.currentPatroPlayIndex = 0;
      state.isShowClickBtn = false;
      state.flvUrlObj.isSuccessGetUrl = true;
    },
    changeVideoStatus(state, data) {
      state.videoStatus = data;
    },
    changeSuccessGetFlvUrl(state, data) {
      state.successGetFlvUrl = data;
    },
    getRepeatPatroPlanStatus(state, data) {
      state.isRepeatPatroPlan = data;
    }
  }
};
