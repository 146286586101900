export default {
  namespaced: true,
  state: {
    popShow: false
  },
  mutations: {
    setPopShow(state, status) {
      state.popShow = status;
    }
  },
  actions: {
    setPopShowAction(context, status) {
      context.commit("setPopShow", status);
    }
  }
};