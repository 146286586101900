
import Ajax from "@/common/utils/common";
import { RES_CODE_SUCCESS } from "@/common/utils/constant";
const SAFETYMGMT = Ajax.getRegistry("SAFETYMGMT");
const ADMINMGMT = Ajax.getRegistry("ADMINMGMT");

export default {
  namespaced: true,
  state: {
    treeData: [],
    faceListData: [],
    totalNum: 0,
    picUrl: "",
    trackMsg: []
  },
  getters: {

  },
  mutations: {
    treeDataChange(state, {data}) {
      state.treeData = data.parks;
    //   state.treeProps.defaultExpandKeys = [data.parks[0].code];
    },
    FaceListDataChange(state, {data}) {
      state.faceListData = data.list;
      state.totalNum = data.rowCount;
    },
    getUrl(state, data) {
      state.picUrl = data;
    },
    clearTrackMsg(state) {
      state.trackMsg = [];
    },
    getFaceTrackChange(state, {data}) {
      state.trackMsg = data || [];
    },
    clearPic(state, data = "") {
      state.picUrl = data;
    }
  },
  actions: {
    getTreeData({commit}, {path, params}) {
      return Ajax.axiosData(path, "POST", params, SAFETYMGMT).then(
        ({ data }) => {
          commit("treeDataChange", data);
          return false;
        }
      ).catch(() => {
        return false;
      });
    },
    getFaceListData({commit}, {path, params}) {
      return Ajax.axiosData(path, "POST", params, SAFETYMGMT).then(
        ({ data }) => {
          if (data.code === RES_CODE_SUCCESS) {
            commit("FaceListDataChange", data);
          } else {
            commit("FaceListDataChange", {data: {list: [], rowCount: 0}});
          }
          return false;
        }
      ).catch(() => {
        return false;
      });
    },
    getFaceTrackData({commit}, {path, params}) {
      return Ajax.axiosData(path, "POST", params, SAFETYMGMT).then(
        ({ data }) => {
          if (data.code === RES_CODE_SUCCESS) {
            commit("getFaceTrackChange", data);
          }
          return false;
        }
      ).catch(() => {
        return false;
      });
    },
    getFaceTrackDataFromPersonmgmt({commit}, {path, params}) {
      return Ajax.axiosData(path, "POST", params, ADMINMGMT).then(
        ({ data }) => {
          if (data.code === RES_CODE_SUCCESS) {
            commit("getFaceTrackChange", data);
          }
          return false;
        }
      ).catch(() => {
        return false;
      });
    }
  }
};