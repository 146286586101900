export default {
  namespaced: true,
  state: {
    webSocketChange: "",
    eventTotal: {
      highCount: 0,
      normalCount: 0
    },
    leftNavPath: "",
    allPosLeafnodeCodeArr: []
  },
  mutations: {
    webSocketChange(state, data) {
      state.webSocketChange = data.webSocketChange;
    },
    eventTotal(state, data) {
      state.eventTotal = data;
    },
    leftNavPathChange(state, data) {
      state.leftNavPath = data;
    }
  }
};
