export default {
  i18nKey_1: '最后修改时间',
  i18nKey_2: '自动抄表详情列表',
  i18nKey_3: '子系统',
  i18nKey_4: '资产类型',
  i18nKey_5: '状态',
  i18nKey_6: '注册时间',
  i18nKey_7: '重置',
  i18nKey_8: '正常',
  i18nKey_9: '账号名/收分手机号',
  i18nKey_10: '账号名',
  i18nKey_11: '展示方式',
  i18nKey_12: '云类型',
  i18nKey_13: '园区名称',
  i18nKey_14: '园区编码',
  i18nKey_15: '已通过',
  i18nKey_16: '已取消删除',
  i18nKey_17: '已启用',
  i18nKey_18: '已禁用',
  i18nKey_19: '已解冻',
  i18nKey_20: '已冻结',
  i18nKey_21:
    '选择vip到达提醒类型，角色为不填选项。非vip到达提醒类型，角色为必填选项。',
  i18nKey_22: '选择',
  i18nKey_23: '序号',
  i18nKey_24: '修改失败',
  i18nKey_25: '修改混合云配置',
  i18nKey_26: '修改成功',
  i18nKey_27: '修改',
  i18nKey_28: '信号类型',
  i18nKey_29: '信号标签',
  i18nKey_30: '新建混合云配置',
  i18nKey_31: '新建',
  i18nKey_32: '详情',
  i18nKey_33: '下载模板',
  i18nKey_34: '物业公告',
  i18nKey_35: '无符合查询条件的数据',
  i18nKey_36: '文件格式不正确，只支持excel文件',
  i18nKey_37: '文本',
  i18nKey_38: '位置',
  i18nKey_39: '未通过',
  i18nKey_40: '未启用',
  i18nKey_41: '网络异常，请稍候重试。',
  i18nKey_42: '推送目标',
  i18nKey_43: '推送的告警类型',
  i18nKey_44: '推送APP服务',
  i18nKey_45: '条',
  i18nKey_46: '添加时间',
  i18nKey_47: '添加',
  i18nKey_48: '提示',
  i18nKey_49: '提交失败',
  i18nKey_50: '提交成功',
  i18nKey_51: '提交',
  i18nKey_52: '搜索',
  i18nKey_53: '死区只能由数字及四则运算符号组成',
  i18nKey_54: '私有云',
  i18nKey_55:
    '说明："市电"，第一层级，本级：取"高压计量柜"数据；下级累计：取"高压出线柜"数据； 电损率超出 >5%为异常。',
  i18nKey_56: '数据',
  i18nKey_57: '授权',
  i18nKey_58: '收分账号名',
  i18nKey_59: '收分手机号格式错误',
  i18nKey_60: '收分手机号',
  i18nKey_61: '剩余',
  i18nKey_62: '设施子类',
  i18nKey_63: '设施系统',
  i18nKey_64: '设施名称不能为空',
  i18nKey_65: '设施名称',
  i18nKey_66: '设施分类修改',
  i18nKey_67: '设施分类名称',
  i18nKey_68: '设施分类编码',
  i18nKey_69: '设施等级',
  i18nKey_70: '设施别名不能为空',
  i18nKey_71: '设施编号',
  i18nKey_72: '设备分类详情',
  i18nKey_73: '商户',
  i18nKey_74: '删除失败！',
  i18nKey_75: '删除成功',
  i18nKey_76: '删除',
  i18nKey_77: '确定要取消吗？',
  i18nKey_78: '确定删除？',
  i18nKey_79: '确定',
  i18nKey_80: '全部',
  i18nKey_81: '权限',
  i18nKey_82: '取消',
  i18nKey_83: '区块链账号',
  i18nKey_84: '请至少选择一个告警类型',
  i18nKey_85: '请选择状态',
  i18nKey_86: '请选择注册时间范围',
  i18nKey_87: '请选择园区',
  i18nKey_88: '请选择应用系统',
  i18nKey_89: '请选择需要修改的设施分类',
  i18nKey_90: '请选择信号类型',
  i18nKey_91: '请选择位置',
  i18nKey_92: '请选择推送目标',
  i18nKey_93: '请选择设施系统',
  i18nKey_94: '请选择设施分类',
  i18nKey_95: '请选择权限',
  i18nKey_96: '请选择类型',
  i18nKey_97: '请选择积分类型',
  i18nKey_98: '请选择告警类型',
  i18nKey_99: '请选择分类',
  i18nKey_100: '请选择发行时间范围',
  i18nKey_101: '请输入账号名/收分手机号',
  i18nKey_102: '请输入园区名称',
  i18nKey_103: '请输入手机号搜索',
  i18nKey_104: '请输入收分账号名',
  i18nKey_105: '请输入收分手机号',
  i18nKey_106: '请输入设施名称',
  i18nKey_107: '请输入设施编号',
  i18nKey_108: '请输入联系人姓名',
  i18nKey_109: '请输入联系人手机号',
  i18nKey_110: '请输入联系人/手机号码',
  i18nKey_111: '请输入积分钱包',
  i18nKey_112: '请输入公司名称/地址',
  i18nKey_113: '请输入公司名称',
  i18nKey_114: '请输入公司地址',
  i18nKey_115: '请输入当前账号登录密码',
  i18nKey_116: '请输入备注',
  i18nKey_117: '请点击确定，下载文件修改后再次导入。',
  i18nKey_118: '请按要求填写',
  i18nKey_119: '启用标识',
  i18nKey_120: '启用/禁用',
  i18nKey_121: '启用',
  i18nKey_122: '目标类型',
  i18nKey_123: '联系人姓名',
  i18nKey_124: '联系人手机号格式错误',
  i18nKey_125: '联系人手机号',
  i18nKey_126: '联系人/手机号码',
  i18nKey_127: '类型',
  i18nKey_128: '卡片',
  i18nKey_129: '经纬度不能只填写一个',
  i18nKey_130: '解冻',
  i18nKey_131: '角色类型',
  i18nKey_132: '角色',
  i18nKey_133: '积分钱包',
  i18nKey_134: '积分类型',
  i18nKey_135: '积分奖励',
  i18nKey_136: '积分价值（元/分）',
  i18nKey_137: '公有云',
  i18nKey_138: '公司信息',
  i18nKey_139: '公司名称/地址',
  i18nKey_140: '公司名称',
  i18nKey_141: '公司地址',
  i18nKey_142: '个人',
  i18nKey_143: '告警名称只能由字母、数字、中文组成',
  i18nKey_144: '告警名称不能为空',
  i18nKey_145: '告警描述只能由字母、数字、中文组成',
  i18nKey_146: '告警描述不能为空',
  i18nKey_147: '告警类型',
  i18nKey_148: '告警标签只能由字母、数字组成',
  i18nKey_149: '告警标签不能为空',
  i18nKey_150: '分项维度',
  i18nKey_151: '分析指标',
  i18nKey_152: '分期名称',
  i18nKey_153: '发行总数量',
  i18nKey_154: '发行总价值（元）',
  i18nKey_155: '发行时间',
  i18nKey_156: '发行人',
  i18nKey_157: '发行记录',
  i18nKey_158: '冻结',
  i18nKey_159: '导入失败，只支持新版excel文件',
  i18nKey_160: '导入失败',
  i18nKey_161: '导入成功',
  i18nKey_162: '导入',
  i18nKey_163: '导出失败，支持最大导出数据10000条',
  i18nKey_164: '导出',
  i18nKey_165: '待审核',
  i18nKey_166: '错误',
  i18nKey_167: '此操作将永久删除该文件，是否继续？',
  i18nKey_168: '创建失败',
  i18nKey_169: '创建设施分类',
  i18nKey_170: '创建成功',
  i18nKey_171: '成功导入',
  i18nKey_172: '查询',
  i18nKey_173: '操作',
  i18nKey_174: '编辑',
  i18nKey_175: '编号',
  i18nKey_176: '备注',
  i18nKey_177: '保存成功',
  i18nKey_178: '保存',
  i18nKey_179: 'VCN配置',
  i18nKey_180: 'VCM配置',
  i18nKey_181: 'L只能由数字及四则运算符号组成',
  i18nKey_182: 'L不能为空',
  i18nKey_183: 'LL只能由数字及四则运算符号组成',
  i18nKey_184: 'H只能由数字及四则运算符号组成',
  i18nKey_185: 'H不能为空',
  i18nKey_186: 'HH只能由数字及四则运算符号组成',
  i18nKey_187: '昨天节省费用',
  i18nKey_188: '昨天放电总量',
  i18nKey_189: '昨天充电总量',
  i18nKey_190: '昨天',
  i18nKey_191: '昨日用能',
  i18nKey_192: '昨日每平方米能耗',
  i18nKey_193: '昨日回头客',
  i18nKey_194: '昨日',
  i18nKey_195: '最早入园时间',
  i18nKey_196: '最新执行时间',
  i18nKey_197: '最新执行场景情况',
  i18nKey_198: '最小值',
  i18nKey_199: '最晚出园时间',
  i18nKey_200: '最晚$离开园区',
  i18nKey_201: '最少起租(小时)',
  i18nKey_202: '最少起租',
  i18nKey_203: '最近一周',
  i18nKey_204: '最近一个月',
  i18nKey_205: '最近一次停车时长（小时）',
  i18nKey_206: '最近一次开门',
  i18nKey_207: '最近一次缴费时间',
  i18nKey_208: '最近违停时间',
  i18nKey_209: '最近三个月',
  i18nKey_210: '最近更新日期：2019年12月07日',
  i18nKey_211: '最近到访时间',
  i18nKey_212: '最近到访',
  i18nKey_213: '最近30天该车位无停驶记录',
  i18nKey_214: '最后一次更新时间',
  i18nKey_215: '最后通讯时间',
  i18nKey_216: '最后上传时间',
  i18nKey_217: '最后更新时间',
  i18nKey_218: '最多只能选择4个摄像头',
  i18nKey_219: '最多只能选5个',
  i18nKey_220: '最多只能添加5个消息推送人',
  i18nKey_221: '最多只能上传4张图片',
  i18nKey_222: '最多只能上传1张图片！',
  i18nKey_223: '最多添加5个审批人',
  i18nKey_224: '最多可选五个',
  i18nKey_225: '最多可创建4条策略',
  i18nKey_226: '最多关联3个摄像头',
  i18nKey_227: '最多30字',
  i18nKey_228: '最大值',
  i18nKey_229: '最大告警数不能小于或等于最小告警数',
  i18nKey_230: '租约详细信息',
  i18nKey_231: '租约起止日期',
  i18nKey_232: '租约起始日期',
  i18nKey_233: '租约结束日期',
  i18nKey_234: '租户水耗',
  i18nKey_235: '租户能耗',
  i18nKey_236: '租户',
  i18nKey_237: '总用电',
  i18nKey_238: '总水能耗（t）',
  i18nKey_239: '总数',
  i18nKey_240: '总事件数',
  i18nKey_241: '总能耗占比',
  i18nKey_242: '总能耗目标',
  i18nKey_243: '总能耗(上月)',
  i18nKey_244: '总能耗(本年)',
  i18nKey_245: '总能耗',
  i18nKey_246: '总开关安数',
  i18nKey_247: '总开关：',
  i18nKey_248: '总节省费用',
  i18nKey_249: '总价值(元)',
  i18nKey_250: '总计数量',
  i18nKey_251: '总计',
  i18nKey_252: '总充电、放电图表',
  i18nKey_253: '总表(个)',
  i18nKey_254: '棕色',
  i18nKey_255: '综合客流',
  i18nKey_256: '字符位数不能超过10位！',
  i18nKey_257: '自主创建',
  i18nKey_258: '自动执行策略',
  i18nKey_259: '自动补录',
  i18nKey_260: '自定义时间范围最多只能添加3个',
  i18nKey_261: '自定义金额范围最多只能添加3个',
  i18nKey_262: '自定义次数范围最多只能添加3个',
  i18nKey_263: '自定义',
  i18nKey_264: '紫色',
  i18nKey_265: '子公区能耗',
  i18nKey_266: '子分项能耗占比',
  i18nKey_267: '子等级名称',
  i18nKey_268: '资产总原值(元)',
  i18nKey_269: '资产总数统计（按园区）',
  i18nKey_270: '资产总数统计（按类型）',
  i18nKey_271: '资产总数',
  i18nKey_272: '资产原值统计（按园区）',
  i18nKey_273: '资产原值统计（按类型）',
  i18nKey_274: '资产原值(元)',
  i18nKey_275: '资产原值(USD)',
  i18nKey_276: '资产详情',
  i18nKey_277: '资产位置信息',
  i18nKey_278: '资产统计',
  i18nKey_279: '资产描述',
  i18nKey_280: '资产类型',
  i18nKey_281: '资产基本信息',
  i18nKey_282: '资产轨迹',
  i18nKey_283: '资产编号',
  i18nKey_284: '资产RFID信息',
  i18nKey_285: '咨询电话',
  i18nKey_286: '追踪中',
  i18nKey_287: '追踪任务',
  i18nKey_288: '追踪',
  i18nKey_289: '状态不能为空',
  i18nKey_290: '状态：',
  i18nKey_291: '状态',
  i18nKey_292: '转设施告警失败',
  i18nKey_293: '转设施告警成功',
  i18nKey_294: '转入退款',
  i18nKey_295: '转化情况',
  i18nKey_296: '转化率',
  i18nKey_297: '转发失败',
  i18nKey_298: '转发成功',
  i18nKey_299: '抓拍图片',
  i18nKey_300: '抓拍',
  i18nKey_301: '柱状图',
  i18nKey_302:
    '注意：某一天实际电能耗对比上周平均电能耗上升或下降达到设定的阈值，则定义为用电异常',
  i18nKey_303: '注册时间',
  i18nKey_304: '注册失败',
  i18nKey_305: '注：您确定要删除吗？该操作将无法恢复',
  i18nKey_306: '注：您确定要删除吗？该操作将不可恢复！',
  i18nKey_307:
    '注：建议输入总能耗和公区能耗目标值，系统可自动计算其他值，月目标值生成后鼠标移上去可编辑。',
  i18nKey_308: '主卧',
  i18nKey_309: '主题',
  i18nKey_310: '主楼层',
  i18nKey_311: '周一',
  i18nKey_312: '周五',
  i18nKey_313: '周同比',
  i18nKey_314: '周四',
  i18nKey_315: '周三',
  i18nKey_316: '周日',
  i18nKey_317: '周末客流',
  i18nKey_318: '周六',
  i18nKey_319: '周二',
  i18nKey_320: '周',
  i18nKey_321: '重要警告',
  i18nKey_322: '重要级别',
  i18nKey_323: '重新委派',
  i18nKey_324: '重新登录',
  i18nKey_325: '重新布控',
  i18nKey_326: '重复类型',
  i18nKey_327: '重复方式',
  i18nKey_328: '重度污染',
  i18nKey_329: '重点地区到',
  i18nKey_330: '重大事件',
  i18nKey_331: '重大',
  i18nKey_332: '种',
  i18nKey_333: '终点站',
  i18nKey_334: '终点人数',
  i18nKey_335: '终点不能与起点相同',
  i18nKey_336: '终点不能为空',
  i18nKey_337: '终点',
  i18nKey_338: '中亮',
  i18nKey_339: '中国',
  i18nKey_340: '中度污染',
  i18nKey_341: '智能水表编号',
  i18nKey_342: '智能化系统',
  i18nKey_343: '智能电表编号',
  i18nKey_344: '智能表编码不能为空',
  i18nKey_345: '智能表编码',
  i18nKey_346: '制造商只能由中英文、数字及_组成',
  i18nKey_347: '制造商长度不能超过50个字符',
  i18nKey_348: '制造商',
  i18nKey_349: '至少选择一个设备作为执行动作',
  i18nKey_350: '至少勾选一个时间段',
  i18nKey_351: '至今',
  i18nKey_352: '指令下发周期',
  i18nKey_353: '指定天数',
  i18nKey_354: '指定时间',
  i18nKey_355: '指定企业',
  i18nKey_356: '指定次数',
  i18nKey_357: '指标名称',
  i18nKey_358: '指标分析',
  i18nKey_359: '职位信息输入有特殊字符',
  i18nKey_360: '职位信息',
  i18nKey_361: '执行总时长（分钟）',
  i18nKey_362: '执行时间',
  i18nKey_363: '执行动作：',
  i18nKey_364: '执行（次）',
  i18nKey_365: '执行',
  i18nKey_366: '只支持中英文大小写输入',
  i18nKey_367: '只支持中英文大小写',
  i18nKey_368: '只支持中英文、数字、空格及标点符号输入，可换行',
  i18nKey_369: '只有不快的斧,没有劈不开的柴。',
  i18nKey_370: '只能输入正数，且最大10位整数位和2位小数位',
  i18nKey_371: '只能上传jpg/png格式图片',
  i18nKey_372: '只能上传jpg, jpeg, png的图片文件！',
  i18nKey_373: '只能上传excel文件，并且后缀是.xlsx',
  i18nKey_374: '支路用能统计',
  i18nKey_375: '支路用电分析',
  i18nKey_376: '支路选择',
  i18nKey_377: '支路能耗流向图_',
  i18nKey_378: '支路能耗报警数(次)',
  i18nKey_379: '支付完成时间',
  i18nKey_380: '支付渠道',
  i18nKey_381: '支付开始时间',
  i18nKey_382: '支付金额(元)',
  i18nKey_383: '支付方式',
  i18nKey_384: '支付单号',
  i18nKey_385: '支付宝',
  i18nKey_386: '支出/收入方',
  i18nKey_387: '正在加载视频组件，请稍候',
  i18nKey_388: '正在导出，请稍候',
  i18nKey_389: '正常',
  i18nKey_390: '诊断措施(种)',
  i18nKey_391: '诊断措施',
  i18nKey_392: '诊断',
  i18nKey_393: '折线图',
  i18nKey_394: '折扣系数须为0-1之间可保留1-2位的小数',
  i18nKey_395: '折扣系数',
  i18nKey_396: '折扣设置',
  i18nKey_397: '折扣描述',
  i18nKey_398: '折扣',
  i18nKey_399: '折后价详情',
  i18nKey_400: '折后价',
  i18nKey_401: '照片生成时间',
  i18nKey_402: '照片类型描述',
  i18nKey_403: '照片大小',
  i18nKey_404: '照片不能为空，请上传图片',
  i18nKey_405: '照片URL',
  i18nKey_406: '照明模式',
  i18nKey_407: '照明及插座用电',
  i18nKey_408: '照明方案下发',
  i18nKey_409: '照明方案管理',
  i18nKey_410: '账号登录',
  i18nKey_411: '账号',
  i18nKey_412: '账单消耗总用电量为 $kWh',
  i18nKey_413: '账单消耗统计从最初开始到今日',
  i18nKey_414: '长度为15或者18位字符',
  i18nKey_415: '张卡片',
  i18nKey_416: '占用车位',
  i18nKey_417: '展开',
  i18nKey_418: '粘性',
  i18nKey_419: '增值服务详情',
  i18nKey_420: '责任管理人',
  i18nKey_421: '早班车',
  i18nKey_422: '暂无园区地图',
  i18nKey_423: '暂无人员轨迹',
  i18nKey_424: '暂无可导出的数据',
  i18nKey_425: '暂停歇业时间',
  i18nKey_426: '暂停',
  i18nKey_427: '暂不启用',
  i18nKey_428: '在线网关',
  i18nKey_429: '在线密码',
  i18nKey_430: '在线打卡',
  i18nKey_431: '在线(个)',
  i18nKey_432: '在线',
  i18nKey_433: '在岗',
  i18nKey_434: '运营者手机号需注册云城app',
  i18nKey_435: '运维信息',
  i18nKey_436: '运行中',
  i18nKey_437: '运行策略',
  i18nKey_438: '运行参数',
  i18nKey_439: '允许来访时段',
  i18nKey_440: '允许来访期限',
  i18nKey_441: '云类型',
  i18nKey_442: '云城支付',
  i18nKey_443: '云城APP消息',
  i18nKey_444: '云城APP',
  i18nKey_445: '越万里关山,闯千百难关。',
  i18nKey_446: '月预测值',
  i18nKey_447: '月消耗金额(元)',
  i18nKey_448: '月实际电能耗(kWh)',
  i18nKey_449: '月剩余金额(元)',
  i18nKey_450: '月卡限制',
  i18nKey_451: '月卡审核人',
  i18nKey_452: '月卡到期日期',
  i18nKey_453: '月卡车',
  i18nKey_454: '月卡',
  i18nKey_455: '月耗水量（吨）',
  i18nKey_456: '月耗电量（kWh）',
  i18nKey_457: '月充值金额(元)',
  i18nKey_458: '月',
  i18nKey_459: '远程开门时间',
  i18nKey_460: '远程开门成功',
  i18nKey_461: '远程开门',
  i18nKey_462: '源系统人员编码输入有特殊字符',
  i18nKey_463: '源系统名称输入有特殊字符',
  i18nKey_464: '源数据编码输入有特殊字符',
  i18nKey_465: '源数据编码',
  i18nKey_466: '员工',
  i18nKey_467: '园区总平米必须大于0',
  i18nKey_468: '园区总平米',
  i18nKey_469: '园区自有',
  i18nKey_470: '园区无人员热力数据',
  i18nKey_471: '园区无对应设备数据',
  i18nKey_472: '园区无保洁人员数据',
  i18nKey_473: '园区无安保人员数据',
  i18nKey_474: '园区内部企业',
  i18nKey_475: '园区黑名单',
  i18nKey_476: '园区访客',
  i18nKey_477: '园区订餐',
  i18nKey_478: '园区的企业员工',
  i18nKey_479: '园区车辆颜色排行',
  i18nKey_480: '园区车辆品牌排行',
  i18nKey_481: '园区编码',
  i18nKey_482: '园区保洁',
  i18nKey_483: '园区巴士',
  i18nKey_484: '元',
  i18nKey_485: '阈值类型',
  i18nKey_486: '阈值不正确！请核实清楚再确认',
  i18nKey_487: '阈值',
  i18nKey_488: '域账号',
  i18nKey_489: '预约状态',
  i18nKey_490: '预览',
  i18nKey_491: '预警值最小为0，最大为999的整数',
  i18nKey_492: '预警值状态：',
  i18nKey_493: '预计离去',
  i18nKey_494: '预计离开时间',
  i18nKey_495: '预计来访时间',
  i18nKey_496: '预付费',
  i18nKey_497: '预订止时间',
  i18nKey_498: '预订日期',
  i18nKey_499: '预订人',
  i18nKey_500: '预订',
  i18nKey_501: '预测值',
  i18nKey_502: '预测能耗值(kWh)',
  i18nKey_503: '有效性标识',
  i18nKey_504: '有效',
  i18nKey_505: '由重点地区返回：',
  i18nKey_506: '优惠套餐：',
  i18nKey_507: '优惠企业',
  i18nKey_508: '优惠策略',
  i18nKey_509: '优化建议',
  i18nKey_510: '优',
  i18nKey_511: '用水数据统计（t）',
  i18nKey_512: '用水数据统计m³ 立方米',
  i18nKey_513: '用水类型',
  i18nKey_514: '用时(分钟)',
  i18nKey_515: '用能趋势',
  i18nKey_516: '用能(kWh)',
  i18nKey_517: '用能',
  i18nKey_518: '用量',
  i18nKey_519: '用户信息确认',
  i18nKey_520: '用户/群组',
  i18nKey_521: '用电预警设置',
  i18nKey_522: '用电异常百分比',
  i18nKey_523: '用电异常',
  i18nKey_524: '用电统计',
  i18nKey_525: '用电数据统计（kWh）',
  i18nKey_526: '用电数据统计kWh 千瓦小时',
  i18nKey_527: '用电排行',
  i18nKey_528: '用电量（kWh ）',
  i18nKey_529: '用电类型',
  i18nKey_530: '用电概况',
  i18nKey_531: '用电负荷',
  i18nKey_532: '用电分项',
  i18nKey_533: '用电',
  i18nKey_534: '应用系统编码',
  i18nKey_535: '应用系统编号不能为空',
  i18nKey_536: '应用系统',
  i18nKey_537: '应用户型成功',
  i18nKey_538: '应用户型',
  i18nKey_539: '应用场景成功',
  i18nKey_540: '应用场景',
  i18nKey_541: '营业执照',
  i18nKey_542: '营业时间不能少于一小时！',
  i18nKey_543: '营业时间',
  i18nKey_544: '营业开始时间',
  i18nKey_545: '营业结束时间',
  i18nKey_546: '营收',
  i18nKey_547: '英文姓名',
  i18nKey_548: '隐私政策',
  i18nKey_549: '银色',
  i18nKey_550: '银联支付',
  i18nKey_551: '音量控制：',
  i18nKey_552: '因涉及到客户隐私，需进行身份验证',
  i18nKey_553: '疫情上报记录',
  i18nKey_554: '疫情防控记录',
  i18nKey_555: '异常总数(个)',
  i18nKey_556: '异常',
  i18nKey_557: '以图搜人',
  i18nKey_558: '已支付',
  i18nKey_559: '已有记录存在，是否覆盖',
  i18nKey_560: '已有',
  i18nKey_561: '已邀请',
  i18nKey_562: '已选支路',
  i18nKey_563: '已选择设备',
  i18nKey_564: '已选择上传文件：',
  i18nKey_565: '已选摄像机',
  i18nKey_566: '已选商/租户',
  i18nKey_567: '已选建筑',
  i18nKey_568: '已选',
  i18nKey_569: '已完成',
  i18nKey_570: '已退款',
  i18nKey_571: '已停止',
  i18nKey_572: '已添加路线',
  i18nKey_573: '已添加过此表！',
  i18nKey_574: '已提交',
  i18nKey_575: '已锁定',
  i18nKey_576: '已使用',
  i18nKey_577: '已审核',
  i18nKey_578: '已取消',
  i18nKey_579: '已启动布控不能删除',
  i18nKey_580: '已启动',
  i18nKey_581: '已配网设备不能删除',
  i18nKey_582: '已配网',
  i18nKey_583: '已开启 $ 个',
  i18nKey_584: '已开启',
  i18nKey_585: '已结账',
  i18nKey_586: '已结束',
  i18nKey_587: '已交付',
  i18nKey_588: '已核销',
  i18nKey_589: '已过期',
  i18nKey_590: '已关联摄像头不能超出三个',
  i18nKey_591: '已关联摄像头0个',
  i18nKey_592: '已关联摄像头',
  i18nKey_593: '已关闭 $ 个',
  i18nKey_594: '已到访人数(人)',
  i18nKey_595: '已到达',
  i18nKey_596: '已存在',
  i18nKey_597: '已处理',
  i18nKey_598: '已出账单未抵扣金额(元)',
  i18nKey_599: '已出账单未抵扣',
  i18nKey_600: '已出账单金额累计',
  i18nKey_601: '已超时',
  i18nKey_602: '移动失败',
  i18nKey_603: '移动端',
  i18nKey_604: '移动成功',
  i18nKey_605:
    '移除后的设备将恢复到“待分配设备”中，如有需要可再次添加。是否将该设备从该区域移除？',
  i18nKey_606: '移出',
  i18nKey_607: '仪表所属',
  i18nKey_608: '仪表品牌',
  i18nKey_609: '仪表名称',
  i18nKey_610: '仪表类别',
  i18nKey_611: '仪表代码不能为空',
  i18nKey_612: '仪表代码/编号/名称',
  i18nKey_613: '仪表表号/楼栋单元',
  i18nKey_614: '仪表表号',
  i18nKey_615: '一码通',
  i18nKey_616: '一类',
  i18nKey_617: '一键重置',
  i18nKey_618: '一键停用',
  i18nKey_619: '一键开启',
  i18nKey_620: '一级部门',
  i18nKey_621: '一级',
  i18nKey_622: '一般告警',
  i18nKey_623: '一',
  i18nKey_624: '业主',
  i18nKey_625: '业务事件',
  i18nKey_626: '业务编码',
  i18nKey_627: '业态名称不能为空',
  i18nKey_628: '业态名称',
  i18nKey_629: '业态',
  i18nKey_630: '要为成功找方法,不为失败找理由。',
  i18nKey_631: '邀约信息',
  i18nKey_632: '邀约时间',
  i18nKey_633: '邀约访客',
  i18nKey_634: '邀约对象信息',
  i18nKey_635: '邀请时间',
  i18nKey_636: '颜色超过6个选项 已为您按最优显示',
  i18nKey_637: '严重污染',
  i18nKey_638: '严重告警阈值',
  i18nKey_639: '循环时间',
  i18nKey_640: '循环',
  i18nKey_641: '巡逻组名称',
  i18nKey_642: '巡逻问题概况',
  i18nKey_643: '巡逻人',
  i18nKey_644: '巡逻名称不能为空',
  i18nKey_645: '巡逻名称不能超过200字符',
  i18nKey_646: '巡逻路线说明',
  i18nKey_647: '巡逻路线名称',
  i18nKey_648: '巡逻路线',
  i18nKey_649: '巡逻计划名称',
  i18nKey_650: '巡逻计划表',
  i18nKey_651: '巡逻告警',
  i18nKey_652: '巡逻',
  i18nKey_653: '选择消防分区',
  i18nKey_654: '选择替换设备',
  i18nKey_655: '选择时间',
  i18nKey_656: '选择路灯',
  i18nKey_657: '选择积分类型',
  i18nKey_658: '选择户型',
  i18nKey_659: '选择管理部门',
  i18nKey_660: '选择关联',
  i18nKey_661: '选择的位置没有客流数据，请重新选择区域后再试',
  i18nKey_662: '选择的日期范围大于 190 天，请减少选择再重试',
  i18nKey_663: '选择层级至少要到园区',
  i18nKey_664: '选择标签',
  i18nKey_665: '玄关',
  i18nKey_666: '序列号只能由中英文和数字组成',
  i18nKey_667: '序列号长度不能超过100个字符',
  i18nKey_668: '序列号',
  i18nKey_669: '修改增值服务',
  i18nKey_670: '修改预警阈值成功',
  i18nKey_671: '修改巡逻计划成功',
  i18nKey_672: '修改信息需先停用价格！',
  i18nKey_673: '修改信息需先停用工位！',
  i18nKey_674: '修改限时折扣',
  i18nKey_675: '修改限行车辆',
  i18nKey_676: '修改设备',
  i18nKey_677: '修改前读数',
  i18nKey_678: '修改密码成功，请重新登录',
  i18nKey_679: '修改空间基础数据失败',
  i18nKey_680: '修改空间基础数据成功',
  i18nKey_681: '修改计划',
  i18nKey_682: '修改混合云配置成功',
  i18nKey_683: '修改混合云的数据',
  i18nKey_684: '修改会议室资源维护',
  i18nKey_685: '修改会议室订单',
  i18nKey_686: '修改会议室',
  i18nKey_687: '修改乘车班次',
  i18nKey_688: '修改备注名',
  i18nKey_689: '修改VCM配置',
  i18nKey_690: '休息',
  i18nKey_691: '休眠亮度',
  i18nKey_692: '休眠',
  i18nKey_693: '姓名输入有特殊字符，请重新输入',
  i18nKey_694: '姓名或手机号',
  i18nKey_695: '姓名',
  i18nKey_696: '性别不能为空',
  i18nKey_697: '性别',
  i18nKey_698: '型号',
  i18nKey_699: '星期一',
  i18nKey_700: '星期五',
  i18nKey_701: '星期天',
  i18nKey_702: '星期四',
  i18nKey_703: '星期三',
  i18nKey_704: '星期六',
  i18nKey_705: '星期二',
  i18nKey_706: '新增子类',
  i18nKey_707: '新增子级分类',
  i18nKey_708: '新增增值服务',
  i18nKey_709: '新增预警阈值成功',
  i18nKey_710: '新增预警阈值',
  i18nKey_711: '新增一级业态',
  i18nKey_712: '新增小组人员',
  i18nKey_713: '新增消防分区',
  i18nKey_714: '新增位置',
  i18nKey_715: '新增失败，请稍后再试',
  i18nKey_716: '新增设备',
  i18nKey_717: '新增配置',
  i18nKey_718: '新增角色',
  i18nKey_719: '新增加附加设施菜单路径：企业服务 -> 会议室管理 -> 增值服务！',
  i18nKey_720: '新增计划',
  i18nKey_721: '新增会议室资源维护',
  i18nKey_722: '新增会议室订单',
  i18nKey_723: '新增会议室',
  i18nKey_724: '新增户型',
  i18nKey_725: '新增附加设施',
  i18nKey_726: '新增房屋',
  i18nKey_727: '新增成功',
  i18nKey_728: '新增产品',
  i18nKey_729: '新增策略生效时间（最多可创建4条）',
  i18nKey_730: '新增标签信息',
  i18nKey_731: '新密码不可与旧密码相同',
  i18nKey_732: '新密码',
  i18nKey_733: '新客户数',
  i18nKey_734: '新客户比例',
  i18nKey_735: '新建巡逻计划成功',
  i18nKey_736: '新建限行车辆',
  i18nKey_737: '新建人脸追踪',
  i18nKey_738: '新建路线',
  i18nKey_739: '新建空间基础数据失败',
  i18nKey_740: '新建空间基础数据成功',
  i18nKey_741: '新建计划',
  i18nKey_742: '新建混合云配置成功',
  i18nKey_743: '新建工单成功',
  i18nKey_744: '新建布控',
  i18nKey_745: '新建VCM配置',
  i18nKey_746: '校验结果',
  i18nKey_747: '小于或等于',
  i18nKey_748: '小于',
  i18nKey_749: '小时取消，退款',
  i18nKey_750: '小时取消',
  i18nKey_751: '小时/天不能低于4小时！',
  i18nKey_752: '小时/天',
  i18nKey_753: '小时',
  i18nKey_754: '销量',
  i18nKey_755: '消息推送信息展示在云城app消息列表中',
  i18nKey_756: '消警成功',
  i18nKey_757: '消耗水量',
  i18nKey_758: '消耗金额',
  i18nKey_759: '消耗电量',
  i18nKey_760: '消费一般，持续关注中',
  i18nKey_761: '消费能力',
  i18nKey_762: '消费类型',
  i18nKey_763: '消费购买',
  i18nKey_764: '消防系统/火灾自动报警及联动系统/手动报警按钮',
  i18nKey_765: '消防系统/火灾自动报警及联动系统/火灾报警主机',
  i18nKey_766: '消防系统/火灾自动报警及联动系统/感烟火灾探测器',
  i18nKey_767: '消防系统/火灾自动报警及联动系统/感温火灾探测器',
  i18nKey_768: '消防系统',
  i18nKey_769: '消防事件趋势',
  i18nKey_770: '消防分区名称',
  i18nKey_771: '消防分区导入模板',
  i18nKey_772: '消防分区id',
  i18nKey_773: '消防分区',
  i18nKey_774:
    '项目选址在金桥万创中心B1，B2地下车库，配置三套，250kW/924.9kWh储能系统，网点分别位于10kV甲线下2#变压器（1000kVA）400V母线侧，10kV乙线下5#变压器（1000kVA）400V母线侧及6#变压器（2000kVA）400V母线侧',
  i18nKey_775: '项目名称',
  i18nKey_776: '项目介绍',
  i18nKey_777: '详细地址',
  i18nKey_778: '箱体尺寸(寸)',
  i18nKey_779: '相邻区域不能与设施位置相同',
  i18nKey_780: '相邻区域',
  i18nKey_781: '线下支付',
  i18nKey_782: '线下付款人员',
  i18nKey_783: '线下付款',
  i18nKey_784: '线上退款输入格式不正确!',
  i18nKey_785: '线上退款',
  i18nKey_786: '线上取消',
  i18nKey_787: '限制条件',
  i18nKey_788: '限时折扣上架中，请下架后操作！',
  i18nKey_789: '限行时间段',
  i18nKey_790: '限行车辆',
  i18nKey_791: '现场图片',
  i18nKey_792: '现场视频',
  i18nKey_793: '显示价格',
  i18nKey_794: '显示',
  i18nKey_795: '下载模板(公区)',
  i18nKey_796: '下载模板(房间)',
  i18nKey_797: '下载模板',
  i18nKey_798: '下载表格模板',
  i18nKey_799: '下载',
  i18nKey_800: '下移',
  i18nKey_801: '下一步',
  i18nKey_802: '下午',
  i18nKey_803: '下降',
  i18nKey_804: '下架',
  i18nKey_805: '下级累计',
  i18nKey_806: '下行',
  i18nKey_807: '下个小时建议运行参数',
  i18nKey_808: '下发指令成功',
  i18nKey_809: '下发方案',
  i18nKey_810: '下单时间',
  i18nKey_811: '下单人',
  i18nKey_812: '系统自动拒绝',
  i18nKey_813: '系统预测其他',
  i18nKey_814: '系统预测常驻人员',
  i18nKey_815: '系统管理员',
  i18nKey_816: '系统编码只能为50个字符以内的中文、字母及下划线',
  i18nKey_817: '系统编码不能为空',
  i18nKey_818: '系统2',
  i18nKey_819: '系统1',
  i18nKey_820: '系统',
  i18nKey_821: '洗手间监测策略配置',
  i18nKey_822: '西南',
  i18nKey_823: '误报',
  i18nKey_824: '物业人员',
  i18nKey_825: '物业',
  i18nKey_826: '五类',
  i18nKey_827: '五级部门',
  i18nKey_828: '五级',
  i18nKey_829: '五',
  i18nKey_830: '无节目',
  i18nKey_831: '无记录',
  i18nKey_832: '无关联事件',
  i18nKey_833: '无关联摄像头',
  i18nKey_834: '无符合查询条件的数据',
  i18nKey_835: '无法获取行内数据',
  i18nKey_836: '无此上级设备',
  i18nKey_837: '无',
  i18nKey_838: '文件仅支持excel',
  i18nKey_839: '文件大小不能超过20M',
  i18nKey_840: '温度',
  i18nKey_841: '位置全路径',
  i18nKey_842: '位置ID',
  i18nKey_843: '位置',
  i18nKey_844: '未知',
  i18nKey_845: '未设置消息推送人，请添加消息推送人！',
  i18nKey_846: '未启动',
  i18nKey_847: '未配网',
  i18nKey_848: '未录入',
  i18nKey_849: '未离',
  i18nKey_850: '未开始',
  i18nKey_851: '未核销',
  i18nKey_852: '未抵扣金额',
  i18nKey_853: '未到访人数(人)',
  i18nKey_854: '未出现过违停现象',
  i18nKey_855: '未抄总数(个)',
  i18nKey_856: '纬度整数部分为0-90',
  i18nKey_857: '纬度',
  i18nKey_858: '维修内容',
  i18nKey_859: '维修结果',
  i18nKey_860: '维护责任人',
  i18nKey_861: '维护',
  i18nKey_862: '维保状态',
  i18nKey_863: '维保商只能由中英文、数字及_组成',
  i18nKey_864: '维保商长度不能超过50个字符',
  i18nKey_865: '维保商',
  i18nKey_866: '违停证据',
  i18nKey_867: '违停原因',
  i18nKey_868: '违停详细信息',
  i18nKey_869: '违停时间',
  i18nKey_870: '违停地点',
  i18nKey_871: '违停次数',
  i18nKey_872: '微亮',
  i18nKey_873: '忘记密码',
  i18nKey_874: '网络加载异常，请稍后重新操作',
  i18nKey_875: '网络加载异常，请稍后重试',
  i18nKey_876: '网关名称',
  i18nKey_877: '网关不在线，下发方案失败',
  i18nKey_878: '万科园区',
  i18nKey_879: '万科西城区园区',
  i18nKey_880: '万科海定区园区',
  i18nKey_881: '万科东城区园区',
  i18nKey_882: '万科朝阳区园区',
  i18nKey_883: '万科滨海新区园区',
  i18nKey_884: '万',
  i18nKey_885: '晚班车',
  i18nKey_886: '外部位置名称',
  i18nKey_887: '外部位置编码',
  i18nKey_888: '外部企业',
  i18nKey_889: '退款状态',
  i18nKey_890: '退款已申请',
  i18nKey_891: '退款失败',
  i18nKey_892: '退款处理中',
  i18nKey_893: '退款处理时间',
  i18nKey_894: '退款成功时间',
  i18nKey_895: '退款成功',
  i18nKey_896: '退回',
  i18nKey_897: '退订',
  i18nKey_898: '推荐标签',
  i18nKey_899: '团结协作,全力以赴。',
  i18nKey_900: '途径点不能与终点相同',
  i18nKey_901: '途径点不能与起点相同',
  i18nKey_902: '途径',
  i18nKey_903: '图片大小不能超过3M！',
  i18nKey_904: '图片大小不能超过3M',
  i18nKey_905: '图片查看',
  i18nKey_906: '图片裁剪',
  i18nKey_907: '图片',
  i18nKey_908: '图表，报表',
  i18nKey_909: '图表',
  i18nKey_910: '统一设置额度',
  i18nKey_911: '统计周期（天）',
  i18nKey_912: '统计详细',
  i18nKey_913: '统计图名称',
  i18nKey_914: '统计天数只能是正整数',
  i18nKey_915: '统计时间：',
  i18nKey_916: '统计卡片配置',
  i18nKey_917: '统计从最初开始到昨天,充值金额以到账为准',
  i18nKey_918: '同意',
  i18nKey_919: '同一天只能设置一种取消规则！',
  i18nKey_920: '同时选择的摄像头不能超过4个',
  i18nKey_921: '同期(kWh)',
  i18nKey_922: '同行人信息设置',
  i18nKey_923: '同行人数',
  i18nKey_924: '同比同期',
  i18nKey_925: '同比时间',
  i18nKey_926: '同比',
  i18nKey_927: '通行楼层',
  i18nKey_928: '通行',
  i18nKey_929: '停止',
  i18nKey_930: '停用',
  i18nKey_931: '停留时长只能是正整数',
  i18nKey_932: '停留时长（小时）',
  i18nKey_933: '停靠楼层',
  i18nKey_934: '停靠',
  i18nKey_935: '停放时间',
  i18nKey_936: '停车时长',
  i18nKey_937: '停车费用(24小时内)',
  i18nKey_938: '停车场收费模式',
  i18nKey_939: '停车场名称',
  i18nKey_940: '停车场地址',
  i18nKey_941: '停车场不能为空',
  i18nKey_942: '停车策略配置',
  i18nKey_943: '调试设备',
  i18nKey_944: '添加子类',
  i18nKey_945: '添加消息推送人仅限云城app注册用户使用，',
  i18nKey_946: '添加线下付款人员',
  i18nKey_947: '添加限时折扣',
  i18nKey_948: '添加提醒人',
  i18nKey_949: '添加套餐',
  i18nKey_950: '添加适用企业',
  i18nKey_951: '添加人脸',
  i18nKey_952: '添加亮灯规则',
  i18nKey_953: '添加公司',
  i18nKey_954: '添加乘车路线',
  i18nKey_955: '添加乘车班次',
  i18nKey_956: '天推送活动',
  i18nKey_957: '天前',
  i18nKey_958: '天',
  i18nKey_959: '替换最早',
  i18nKey_960: '替换选中',
  i18nKey_961: '替换关系',
  i18nKey_962: '替换底图',
  i18nKey_963: '体温',
  i18nKey_964: '提醒人',
  i18nKey_965: '提醒',
  i18nKey_966: '提示阈值',
  i18nKey_967: '提示文字',
  i18nKey_968: '提示告警',
  i18nKey_969: '提示：只支持jpg，png格式的图片上传，上传图片大小不超过3M',
  i18nKey_970: '提示：因涉及客户隐私，需输入密码二次确认',
  i18nKey_971: '提示：结束时间需大于开始时间',
  i18nKey_972: '提示: 视频画面延迟约5~10秒,请耐心等待',
  i18nKey_973: '提前预订',
  i18nKey_974: '提前天数限制为1-30天范围内',
  i18nKey_975: '特殊',
  i18nKey_976: '套餐数量',
  i18nKey_977: '套餐使用次数',
  i18nKey_978: '套餐启用中，请暂停后操作！',
  i18nKey_979: '套餐名称',
  i18nKey_980: '套餐类型',
  i18nKey_981: '套餐卡用户',
  i18nKey_982: '套餐卡计次数',
  i18nKey_983: '套餐卡办理',
  i18nKey_984: '套餐卡',
  i18nKey_985: '套餐缴费金额(元)',
  i18nKey_986: '套餐缴费',
  i18nKey_987: '锁定',
  i18nKey_988: '所有权（业主）',
  i18nKey_989: '所有企业',
  i18nKey_990: '所选时间段不能为空',
  i18nKey_991: '所选时间段不能超过两天',
  i18nKey_992: '所选年份必须处于开始日期和结束日期之间的年份',
  i18nKey_993: '所属园区',
  i18nKey_994: '所属企业',
  i18nKey_995: '所属目录不能选择自己',
  i18nKey_996: '所属目录不能为空',
  i18nKey_997: '所属目录',
  i18nKey_998: '所属类型',
  i18nKey_999: '所属公司输入有特殊字符，请重新输入',
  i18nKey_1000: '所属公司输入有特殊字符',
  i18nKey_1001: '所属公司不能为空',
  i18nKey_1002: '所属公司',
  i18nKey_1003: '所属分项',
  i18nKey_1004: '所属单位',
  i18nKey_1005: '所属部门',
  i18nKey_1006: '搜索历史',
  i18nKey_1007: '四类',
  i18nKey_1008: '四级部门',
  i18nKey_1009: '四级',
  i18nKey_1010: '四',
  i18nKey_1011: '私有云',
  i18nKey_1012: '私人',
  i18nKey_1013: '说明：添加的审批人将作为物业管理的审批人员，最多添加5个审批人',
  i18nKey_1014:
    '说明：本级占上一级能耗比超出>10%为异常。总能耗本级用能取低压进线柜P11、P21、P31、P41；',
  i18nKey_1015: '水费已出账单金额累计',
  i18nKey_1016: '水费欠费商户',
  i18nKey_1017: '水费充值未抵扣金额',
  i18nKey_1018: '水费充值金额累计',
  i18nKey_1019: '水电账单概览',
  i18nKey_1020: '水电明细详情',
  i18nKey_1021: '水电明细列表',
  i18nKey_1022: '水电缴费订单',
  i18nKey_1023: '水电表详情数据查询',
  i18nKey_1024: '水表预警阈值：',
  i18nKey_1025: '水表序号',
  i18nKey_1026: '水表编号',
  i18nKey_1027: '刷新',
  i18nKey_1028: '刷卡通行',
  i18nKey_1029: '数值在0-60',
  i18nKey_1030: '数值在0-120',
  i18nKey_1031: '数据资源',
  i18nKey_1032: '数据源',
  i18nKey_1033: '数据异常监控详情',
  i18nKey_1034: '数据异常(个)',
  i18nKey_1035: '数据异常',
  i18nKey_1036: '数据未加载成功，请重试',
  i18nKey_1037: '数据时间',
  i18nKey_1038: '数据删除之后不能再找回，确认删除吗？',
  i18nKey_1039: '数据删除后不能再恢复，确认删除吗？',
  i18nKey_1040: '数据来源',
  i18nKey_1041: '数据汇总_',
  i18nKey_1042: '数据丢失(个)',
  i18nKey_1043: '输入长度不允许超过50个字符',
  i18nKey_1044: '输入长度不允许超过30个字符（允许字母、-和数字）',
  i18nKey_1045: '输入长度不允许超过30个字符（允许汉字、字母、-和数字）',
  i18nKey_1046: '输入长度不允许超过2000个字符',
  i18nKey_1047: '输入长度不允许超过100个字符',
  i18nKey_1048: '输入事件标题或设备名称',
  i18nKey_1049: '输入摄像机别名',
  i18nKey_1050: '输入关键字进行过滤',
  i18nKey_1051: '输入工位编号后按回车键',
  i18nKey_1052: '输入的数字不能超过最大值30',
  i18nKey_1053: '输入的数字不能超过最大值24',
  i18nKey_1054: '输入的数字不能超过营业时间',
  i18nKey_1055: '舒适度',
  i18nKey_1056: '书房',
  i18nKey_1057: '授权发行',
  i18nKey_1058: '授权成功',
  i18nKey_1059: '授权',
  i18nKey_1060: '受访者电话：',
  i18nKey_1061: '受访者：',
  i18nKey_1062: '受访公司信息',
  i18nKey_1063: '受访公司不能为空',
  i18nKey_1064: '受访公司：',
  i18nKey_1065: '受访公司',
  i18nKey_1066: '受访地址不能为空',
  i18nKey_1067: '首次入园时间',
  i18nKey_1068: '手续费',
  i18nKey_1069: '手机号需注册云城app',
  i18nKey_1070: '手机号',
  i18nKey_1071: '手工录入数据',
  i18nKey_1072: '手工抄表详情',
  i18nKey_1073: '手动创建',
  i18nKey_1074: '手动触发',
  i18nKey_1075: '手动编辑',
  i18nKey_1076: '手动：',
  i18nKey_1077: '收起',
  i18nKey_1078: '收分账号名',
  i18nKey_1079: '收分账号',
  i18nKey_1080: '收分手机号',
  i18nKey_1081: '收费须知',
  i18nKey_1082: '收费设施和免费设施中不能有重复的选项！',
  i18nKey_1083: '收费设施',
  i18nKey_1084: '收费金额',
  i18nKey_1085: '释义',
  i18nKey_1086: '室内温度',
  i18nKey_1087: '室内湿度',
  i18nKey_1088: '室内',
  i18nKey_1089: '适用企业',
  i18nKey_1090: '是否异常',
  i18nKey_1091: '是否循环播放',
  i18nKey_1092: '是否修改',
  i18nKey_1093: '是否完成全程打卡',
  i18nKey_1094: '是否删除数据',
  i18nKey_1095: '是否删除勾选数据',
  i18nKey_1096: '是否确认下载统计图图片？',
  i18nKey_1097: '是否确认下载excel报表吗',
  i18nKey_1098: '是否确认删除该会议室及价格信息？删除后将不可恢复！',
  i18nKey_1099: '是否确认删除该工位？删除后将不可恢复！',
  i18nKey_1100: '是否启用月卡限制',
  i18nKey_1101: '是否启用提醒策略',
  i18nKey_1102: '是否启用车场限制',
  i18nKey_1103: '是否启用',
  i18nKey_1104: '是否离开过',
  i18nKey_1105: '是否补录',
  i18nKey_1106: '视频巡逻统计',
  i18nKey_1107: '视频巡逻',
  i18nKey_1108: '视频截取成功，请至左侧事件详情的关联视频下查看',
  i18nKey_1109: '视频截取',
  i18nKey_1110: '视频回放',
  i18nKey_1111: '视频画面延迟约5-10秒，请耐心等待',
  i18nKey_1112: '视频辅助',
  i18nKey_1113: '视频打卡率',
  i18nKey_1114: '视频',
  i18nKey_1115: '事件状态统计',
  i18nKey_1116: '事件状态',
  i18nKey_1117: '事件上报成功',
  i18nKey_1118: '事件上报',
  i18nKey_1119: '事件描述不能超过1000个字符',
  i18nKey_1120: '事件描述',
  i18nKey_1121: '事件类型',
  i18nKey_1122: '事件级别',
  i18nKey_1123: '事件等级',
  i18nKey_1124: '事件处理时长',
  i18nKey_1125: '事件处理',
  i18nKey_1126: '事件超时处理配置',
  i18nKey_1127: '事件标题',
  i18nKey_1128: '事件编号',
  i18nKey_1129: '始于2019年10月1日',
  i18nKey_1130: '始于2019年10月01日',
  i18nKey_1131: '使用状态',
  i18nKey_1132: '使用域账号验证',
  i18nKey_1133: '使用手机号验证',
  i18nKey_1134: '使用时长',
  i18nKey_1135: '使用率(%)',
  i18nKey_1136: '使用率',
  i18nKey_1137: '使用地址：',
  i18nKey_1138: '实时视频__',
  i18nKey_1139: '实时空气质量指数',
  i18nKey_1140: '实时功率(kWh)',
  i18nKey_1141: '实时抄读电表',
  i18nKey_1142: '实际值',
  i18nKey_1143: '实付金额',
  i18nKey_1144: '时长请勿小于30秒',
  i18nKey_1145: '时长请勿超过一小时',
  i18nKey_1146: '时间至少设定一个',
  i18nKey_1147: '时间设定',
  i18nKey_1148: '时间区间不能超过6个月',
  i18nKey_1149: '时间间隔',
  i18nKey_1150: '时间范围',
  i18nKey_1151: '时间段',
  i18nKey_1152: '时间点',
  i18nKey_1153: '时间不能为空',
  i18nKey_1154: '时间必须为不为零正整数',
  i18nKey_1155: '时段客流统计',
  i18nKey_1156: '时',
  i18nKey_1157: '失信名单',
  i18nKey_1158: '失效时间',
  i18nKey_1159: '失效',
  i18nKey_1160: '剩余可发行总数',
  i18nKey_1161: '剩余金额小于阈值',
  i18nKey_1162: '剩余金额',
  i18nKey_1163: '剩余度数',
  i18nKey_1164: '剩余车位数',
  i18nKey_1165: '剩余车位',
  i18nKey_1166: '省份简称',
  i18nKey_1167: '生效时间',
  i18nKey_1168: '生效日期：2019年12月07日',
  i18nKey_1169: '生效历史',
  i18nKey_1170: '生成二维码',
  i18nKey_1171: '生成安防告警',
  i18nKey_1172: '升降类型',
  i18nKey_1173: '深圳',
  i18nKey_1174: '深蓝',
  i18nKey_1175: '深灰',
  i18nKey_1176: '身份证号和护照号必须填一项',
  i18nKey_1177: '身份证号',
  i18nKey_1178: '身份证：',
  i18nKey_1179: '身份验证',
  i18nKey_1180: '申请状态',
  i18nKey_1181: '申请退款时间',
  i18nKey_1182: '申请人所属公司',
  i18nKey_1183: '申请取消',
  i18nKey_1184: '申请货梯',
  i18nKey_1185: '摄像头监控',
  i18nKey_1186: '摄像头加载中,请稍后...',
  i18nKey_1187: '摄像头个数',
  i18nKey_1188: '摄像头（个）',
  i18nKey_1189: '摄像头',
  i18nKey_1190: '摄像机号',
  i18nKey_1191: '摄像机',
  i18nKey_1192: '设置预警值（天）：',
  i18nKey_1193: '设置预警值',
  i18nKey_1194: '设置营业时间可以有效排除关闭时间时的客流信息',
  i18nKey_1195: '设置消息推送人：',
  i18nKey_1196: '设置为主楼层',
  i18nKey_1197: '设置成功',
  i18nKey_1198: '设施总数',
  i18nKey_1199: '设施子类',
  i18nKey_1200: '设施资产编号',
  i18nKey_1201: '设施状态',
  i18nKey_1202: '设施运行数据',
  i18nKey_1203: '设施有效期',
  i18nKey_1204: '设施位置',
  i18nKey_1205: '设施拓扑关系',
  i18nKey_1206: '设施统计',
  i18nKey_1207: '设施数量统计',
  i18nKey_1208: '设施数据预览',
  i18nKey_1209: '设施数据同步',
  i18nKey_1210: '设施数据导出',
  i18nKey_1211: '设施事件统计',
  i18nKey_1212: '设施事件告警统计（按类型）',
  i18nKey_1213: '设施事件告警统计（按级别）',
  i18nKey_1214: '设施事件告警处置率统计',
  i18nKey_1215: '设施事件告警',
  i18nKey_1216: '设施事件',
  i18nKey_1217: '设施示意图数据',
  i18nKey_1218: '设施示意图',
  i18nKey_1219: '设施名称长度不能超过50个字符',
  i18nKey_1220: '设施名称不能为空',
  i18nKey_1221: '设施名称',
  i18nKey_1222: '设施描述只能由中英文、数字及_组成',
  i18nKey_1223: '设施描述长度不能超过200个字符',
  i18nKey_1224: '设施描述',
  i18nKey_1225: '设施可用率统计',
  i18nKey_1226: '设施可用率',
  i18nKey_1227: '设施基础数据信息',
  i18nKey_1228: '设施基础数据编码',
  i18nKey_1229: '设施画像状态',
  i18nKey_1230: '设施画像数据',
  i18nKey_1231: '设施画像启用时，名称不能为空',
  i18nKey_1232: '设施画像名称',
  i18nKey_1233: '设施画像',
  i18nKey_1234: '设施故障次数',
  i18nKey_1235: '设施工单',
  i18nKey_1236: '设施告警统计',
  i18nKey_1237: '设施别名',
  i18nKey_1238: '设施报修概况',
  i18nKey_1239: '设施报修',
  i18nKey_1240: '设计容量',
  i18nKey_1241: '设备用能排名',
  i18nKey_1242: '设备异常监控详情',
  i18nKey_1243: '设备异常告警-新风机故障！',
  i18nKey_1244: '设备异常',
  i18nKey_1245: '设备信息',
  i18nKey_1246: '设备通行记录',
  i18nKey_1247: '设备数据导入模板',
  i18nKey_1248: '设备数',
  i18nKey_1249: '设备排序',
  i18nKey_1250: '设备名称',
  i18nKey_1251: '设备健康',
  i18nKey_1252: '设备别名',
  i18nKey_1253: '设备备注名称',
  i18nKey_1254: '设备IP',
  i18nKey_1255: '设备ID',
  i18nKey_1256: '设备',
  i18nKey_1257: '上周实际电能耗（kWh）',
  i18nKey_1258: '上周',
  i18nKey_1259: '上月用能',
  i18nKey_1260: '上月同期',
  i18nKey_1261: '上月实际电能耗（KWh）',
  i18nKey_1262: '上月平均值',
  i18nKey_1263: '上月每平方米能耗',
  i18nKey_1264: '上月累计用能',
  i18nKey_1265: '上月',
  i18nKey_1266: '上移',
  i18nKey_1267: '上一年用能',
  i18nKey_1268: '上一年同期用电量',
  i18nKey_1269: '上一年同期',
  i18nKey_1270: '上一年每平方米能耗',
  i18nKey_1271: '上一年',
  i18nKey_1272: '上下架',
  i18nKey_1273: '上午',
  i18nKey_1274: '上升',
  i18nKey_1275: '上架',
  i18nKey_1276: '上行',
  i18nKey_1277: '上海金桥万创中心系统2',
  i18nKey_1278: '上海金桥万创中心系统1',
  i18nKey_1279: '上次位置',
  i18nKey_1280: '上次上报时间',
  i18nKey_1281: '上次抄表日期',
  i18nKey_1282: '上次抄表读数',
  i18nKey_1283: '上传文件失败',
  i18nKey_1284: '上传图片格式不正确，请重新上传',
  i18nKey_1285: '上传图片不能大于3M, 请重新上传',
  i18nKey_1286: '上传图片',
  i18nKey_1287: '上传示意图',
  i18nKey_1288: '上传失败, 请重新上传',
  i18nKey_1289: '上传失败',
  i18nKey_1290: '上传人',
  i18nKey_1291: '上传画像',
  i18nKey_1292: '上传房屋信息表格',
  i18nKey_1293: '上传的图片名称不规范，只允许包含数字、字母、下划线、横线',
  i18nKey_1294: '上报时间',
  i18nKey_1295: '上报人电话',
  i18nKey_1296: '上报人',
  i18nKey_1297: '商租户用能分析',
  i18nKey_1298: '商业积分',
  i18nKey_1299: '商务',
  i18nKey_1300: '商铺状态',
  i18nKey_1301: '商铺位置',
  i18nKey_1302: '商铺号',
  i18nKey_1303: '商品描述',
  i18nKey_1304: '商户名称：',
  i18nKey_1305: '商场客流',
  i18nKey_1306: '商/租户选择',
  i18nKey_1307: '商/租户名称"',
  i18nKey_1308: '商/租户每平方米能耗排名',
  i18nKey_1309: '删除信息需先停用价格！',
  i18nKey_1310: '删除信息需先停用工位！',
  i18nKey_1311: '删除后该设备的所有明细都将删除，确定要删除?',
  i18nKey_1312:
    '删除后该节点下的内容也将一并删除，建议您先移动后再删，确定删除吗？',
  i18nKey_1313: '删除后不可恢复，确定删除吗？',
  i18nKey_1314: '删除关联失败',
  i18nKey_1315: '删除该条预警阈值后，将默认阈值为0，确定要删除吗？',
  i18nKey_1316: '删除成功 并且租约列表中也一并删除该节点下的所有店铺',
  i18nKey_1317: '删除',
  i18nKey_1318: '扫描下载云城APP',
  i18nKey_1319: '扫描二维码',
  i18nKey_1320: '扫码设备',
  i18nKey_1321: '扫码登录',
  i18nKey_1322: '三元锂',
  i18nKey_1323: '三类',
  i18nKey_1324: '三级部门',
  i18nKey_1325: '三级',
  i18nKey_1326: '三',
  i18nKey_1327: '若无身份证号码，护照号为必填',
  i18nKey_1328: '入园类型',
  i18nKey_1329:
    '如有多个停靠站，请按如下格式录入：万科云城 - 宝安中心 - 前海湾 - 宝安机场',
  i18nKey_1330: '容纳人数',
  i18nKey_1331: '日同比',
  i18nKey_1332: '日',
  i18nKey_1333: '任重道远,志在必得。',
  i18nKey_1334: '任务状态统计',
  i18nKey_1335: '任务状态',
  i18nKey_1336:
    '任务正在追踪中，跳转页面会导致追踪任务停止，确定需要跳转页面吗？',
  i18nKey_1337: '任务责任人',
  i18nKey_1338: '任务委派人',
  i18nKey_1339: '任务数量',
  i18nKey_1340: '任务启动中，请勿删除',
  i18nKey_1341: '任务名称不能为空',
  i18nKey_1342: '任务名称',
  i18nKey_1343: '任务编号',
  i18nKey_1344: '认证企业',
  i18nKey_1345: '人员总览(当天)',
  i18nKey_1346: '人员追踪',
  i18nKey_1347: '人员占比统计(当天)',
  i18nKey_1348: '人员英文姓名输入有特殊字符',
  i18nKey_1349: '人员移动到',
  i18nKey_1350: '人员姓名输入有特殊字符',
  i18nKey_1351: '人员姓名不能为空',
  i18nKey_1352: '人员姓名',
  i18nKey_1353: '人员信息',
  i18nKey_1354: '人员图片',
  i18nKey_1355: '人员统计（按园区）',
  i18nKey_1356: '人员统计（按类型）',
  i18nKey_1357: '人员统计',
  i18nKey_1358: '人员数排行(昨日/前日)',
  i18nKey_1359: '人员手机号不能为空',
  i18nKey_1360: '人员热力',
  i18nKey_1361: '人员类型',
  i18nKey_1362: '人员库选择',
  i18nKey_1363: '人员基础数据信息',
  i18nKey_1364: '人员基础数据创建成功',
  i18nKey_1365: '人员基础数据编码',
  i18nKey_1366: '人员基础数据编号输入有特殊字符，请重新输入',
  i18nKey_1367: '人员基础数据编号',
  i18nKey_1368: '人员轨迹',
  i18nKey_1369: '人员管理',
  i18nKey_1370: '人员对比趋势(近两周)',
  i18nKey_1371: '人员标签',
  i18nKey_1372: '人员ID',
  i18nKey_1373: '人员(最近12周)',
  i18nKey_1374: '人员(最近12个月)',
  i18nKey_1375: '人数概览',
  i18nKey_1376: '人流统计',
  i18nKey_1377: '人脸追踪启动失败',
  i18nKey_1378: '人脸追踪',
  i18nKey_1379: '人脸通行',
  i18nKey_1380: '人脸ID',
  i18nKey_1381: '人脸',
  i18nKey_1382: '人次',
  i18nKey_1383: '人',
  i18nKey_1384: '热门区域客流总数排行',
  i18nKey_1385: '热力蔓延范围',
  i18nKey_1386: '热力点',
  i18nKey_1387: '确认修改',
  i18nKey_1388: '确认新密码',
  i18nKey_1389: '确认添加该用户',
  i18nKey_1390: '确认放弃？',
  i18nKey_1391: '确认此次告警为误判，此次告警信息失效',
  i18nKey_1392: '确定重置此房屋吗',
  i18nKey_1393: '确定一键重置吗',
  i18nKey_1394: '确定要设置为主楼层吗？',
  i18nKey_1395: '确定要删除此地址？',
  i18nKey_1396: '确定要全部开启$个回路吗',
  i18nKey_1397: '确定要全部关闭$个回路吗',
  i18nKey_1398: '确定要开启这$个回路吗?',
  i18nKey_1399: '确定要关闭这$个回路吗?',
  i18nKey_1400: '确定删除设备?',
  i18nKey_1401: '确定删除该用户',
  i18nKey_1402: '确定删除房间?',
  i18nKey_1403: '确定批量删除这些设备吗？',
  i18nKey_1404: '确定批量删除这些户型吗？',
  i18nKey_1405: '确定解除黑名单吗',
  i18nKey_1406: '确定此次告警为误判，此次告警失效',
  i18nKey_1407: '全天 ( 24小时 )',
  i18nKey_1408: '全屏播放',
  i18nKey_1409: '全控开关',
  i18nKey_1410: '全部开启',
  i18nKey_1411: '全部会议室',
  i18nKey_1412: '全部关闭',
  i18nKey_1413: '权责月不能为空',
  i18nKey_1414: '权责月',
  i18nKey_1415: '权限资源',
  i18nKey_1416: '去年同期',
  i18nKey_1417: '取消退款额度不能超过100%！',
  i18nKey_1418: '取消规则不能超过10条！',
  i18nKey_1419: '区域热度',
  i18nKey_1420: '区域名称',
  i18nKey_1421: '区域每平方米能耗排名',
  i18nKey_1422: '区域类型不能为空',
  i18nKey_1423: '区域类型',
  i18nKey_1424: '区域客流',
  i18nKey_1425: '区域到访排行',
  i18nKey_1426: '区域',
  i18nKey_1427: '区块',
  i18nKey_1428: '请至少选择一个摄像头',
  i18nKey_1429: '请至少选择一个人员',
  i18nKey_1430: '请至少添加一位人员保存',
  i18nKey_1431: '请再次输入新密码',
  i18nKey_1432: '请再次输入密码',
  i18nKey_1433: '请选择租约日期范围',
  i18nKey_1434: '请选择资产类型',
  i18nKey_1435: '请选择主题',
  i18nKey_1436: '请选择重要级别',
  i18nKey_1437: '请选择重复方式',
  i18nKey_1438: '请选择照明模式',
  i18nKey_1439: '请选择责任管理人',
  i18nKey_1440: '请选择暂停歇业时间',
  i18nKey_1441: '请选择允许来访时段',
  i18nKey_1442: '请选择预订起始时间',
  i18nKey_1443: '请选择预订结束时间',
  i18nKey_1444: '请选择优惠策略',
  i18nKey_1445: '请选择应用系统',
  i18nKey_1446: '请选择营业开始时间',
  i18nKey_1447: '请选择营业结束时间',
  i18nKey_1448: '请选择异常类型',
  i18nKey_1449: '请选择仪表所属',
  i18nKey_1450: '请选择仪表品牌',
  i18nKey_1451: '请选择一级分类',
  i18nKey_1452: '请选择一级部门',
  i18nKey_1453: '请选择业态',
  i18nKey_1454: '请选择要删除的车辆',
  i18nKey_1455: '请选择需要同步的设施',
  i18nKey_1456: '请选择需要上传的文件',
  i18nKey_1457: '请选择需要上传的会议室首图！',
  i18nKey_1458: '请选择需要上传的工位首图！',
  i18nKey_1459: '请选择需要上传的Banner图！',
  i18nKey_1460: '请选择需要删除的策略',
  i18nKey_1461: '请选择休眠亮度',
  i18nKey_1462: '请选择下单时间',
  i18nKey_1463: '请选择五级分类',
  i18nKey_1464: '请选择五级部门',
  i18nKey_1465: '请选择位置',
  i18nKey_1466: '请选择违停时间',
  i18nKey_1467: '请选择通行方式',
  i18nKey_1468: '请选择停车场',
  i18nKey_1469: '请选择套餐类型',
  i18nKey_1470: '请选择四级分类',
  i18nKey_1471: '请选择四级部门',
  i18nKey_1472: '请选择输入实付金额',
  i18nKey_1473: '请选择输入订单金额',
  i18nKey_1474: '请选择授权起止时间',
  i18nKey_1475: '请选择是否为调试设备',
  i18nKey_1476: '请选择是否上架',
  i18nKey_1477: '请选择事件状态',
  i18nKey_1478: '请选择事件类型',
  i18nKey_1479: '请选择事件级别',
  i18nKey_1480: '请选择事件等级',
  i18nKey_1481: '请选择时间段',
  i18nKey_1482: '请选择时间',
  i18nKey_1483: '请选择升降类型',
  i18nKey_1484: '请选择申请状态',
  i18nKey_1485: '请选择摄像头',
  i18nKey_1486: '请选择设施状态',
  i18nKey_1487: '请选择设施位置',
  i18nKey_1488: '请选择设施画像状态',
  i18nKey_1489: '请选择设施画像名称',
  i18nKey_1490: '请选择设备',
  i18nKey_1491: '请选择商铺状态',
  i18nKey_1492: '请选择商铺位置',
  i18nKey_1493: '请选择商户',
  i18nKey_1494: '请选择删除数据',
  i18nKey_1495: '请选择筛选条件',
  i18nKey_1496: '请选择扫码设备',
  i18nKey_1497: '请选择三级分类',
  i18nKey_1498: '请选择三级部门',
  i18nKey_1499: '请选择日期范围',
  i18nKey_1500: '请选择任务状态',
  i18nKey_1501: '请选择人员类型',
  i18nKey_1502: '请选择人员',
  i18nKey_1503: '请选择权责月',
  i18nKey_1504: '请选择区域类型',
  i18nKey_1505: '请选择区域',
  i18nKey_1506: '请选择欠费商户类型',
  i18nKey_1507: '请选择启用状态',
  i18nKey_1508: '请选择品牌',
  i18nKey_1509: '请选择派发时间',
  i18nKey_1510: '请选择年龄范围',
  i18nKey_1511: '请选择年份',
  i18nKey_1512: '请选择门锁编号',
  i18nKey_1513: '请选择路线',
  i18nKey_1514: '请选择楼宇',
  i18nKey_1515: '请选择楼栋单元',
  i18nKey_1516: '请选择楼栋',
  i18nKey_1517: '请选择楼层空间',
  i18nKey_1518: '请选择楼层',
  i18nKey_1519: '请选择六级分类',
  i18nKey_1520: '请选择类型',
  i18nKey_1521: '请选择来源系统编码',
  i18nKey_1522: '请选择开始时间',
  i18nKey_1523: '请选择开始日期',
  i18nKey_1524: '请选择进车位时间',
  i18nKey_1525: '请选择进场时间',
  i18nKey_1526: '请选择结束时间',
  i18nKey_1527: '请选择角色所属类型',
  i18nKey_1528: '请选择角色类型',
  i18nKey_1529: '请选择计费类型',
  i18nKey_1530: '请选择级别',
  i18nKey_1531: '请选择货梯',
  i18nKey_1532: '请选择活动状态',
  i18nKey_1533: '请选择回路！',
  i18nKey_1534: '请选择后果',
  i18nKey_1535: '请选择合同',
  i18nKey_1536: '请选择国家',
  i18nKey_1537: '请选择关联的人脸库',
  i18nKey_1538: '请选择故障等级',
  i18nKey_1539: '请选择公区类型',
  i18nKey_1540: '请选择工位状态',
  i18nKey_1541: '请选择工位类型！',
  i18nKey_1542: '请选择工单状态',
  i18nKey_1543: '请选择感应亮度',
  i18nKey_1544: '请选择该房屋的户型',
  i18nKey_1545: '请选择付款方式',
  i18nKey_1546: '请选择分项维度',
  i18nKey_1547: '请选择分期',
  i18nKey_1548: '请选择访客状态',
  i18nKey_1549: '请选择房间位置',
  i18nKey_1550: '请选择发起邀请时间范围',
  i18nKey_1551: '请选择发行类型',
  i18nKey_1552: '请选择二级分类',
  i18nKey_1553: '请选择二级部门',
  i18nKey_1554: '请选择订单状态',
  i18nKey_1555: '请选择点位类型',
  i18nKey_1556: '请选择地理位置',
  i18nKey_1557: '请选择到园区',
  i18nKey_1558: '请选择到大区',
  i18nKey_1559: '请选择到城市',
  i18nKey_1560: '请选择单位',
  i18nKey_1561: '请选择处理机制',
  i18nKey_1562: '请选择初始化时长',
  i18nKey_1563: '请选择出车位时间',
  i18nKey_1564: '请选择充值类型',
  i18nKey_1565: '请选择乘车时间',
  i18nKey_1566: '请选择城市',
  i18nKey_1567: '请选择车主类型',
  i18nKey_1568: '请选择车辆标签',
  i18nKey_1569: '请选择车场名称',
  i18nKey_1570: '请选择车场',
  i18nKey_1571: '请选择场景图标',
  i18nKey_1572: '请选择场景策略',
  i18nKey_1573: '请选择场景',
  i18nKey_1574: '请选择产品',
  i18nKey_1575: '请选择策略关联的园区',
  i18nKey_1576: '请选择策略关联的路灯',
  i18nKey_1577: '请选择布控状态',
  i18nKey_1578: '请选择布控摄像头',
  i18nKey_1579: '请选择布控类型',
  i18nKey_1580: '请选择不少于 1 张卡片',
  i18nKey_1581: '请选择不多于 $张卡片',
  i18nKey_1582: '请选择不多于',
  i18nKey_1583: '请选择巴士班次',
  i18nKey_1584: '请选择安装时间',
  i18nKey_1585: '请选择GIS坐标类型',
  i18nKey_1586: '请选择',
  i18nKey_1587: '请先选择会议室名称',
  i18nKey_1588: '请先选择关联数据',
  i18nKey_1589: '请先保存修改',
  i18nKey_1590: '请勿重复添加人员',
  i18nKey_1591: '请勿重复添加',
  i18nKey_1592: '请勿输入特殊字符！',
  i18nKey_1593: '请勿输入特殊字符',
  i18nKey_1594: '请勿超过6个',
  i18nKey_1595: '请完整填写设备信息',
  i18nKey_1596: '请完整填写必填字段',
  i18nKey_1597: '请填写事件描述',
  i18nKey_1598: '请填写认证企业',
  i18nKey_1599: '请填写车牌号码',
  i18nKey_1600: '请填写车辆颜色',
  i18nKey_1601: '请填写车辆品牌',
  i18nKey_1602: '请填写策略名称',
  i18nKey_1603: '请添加适用企业！',
  i18nKey_1604: '请添加人员标签',
  i18nKey_1605: '请添加路线',
  i18nKey_1606: '请搜索或选择公司',
  i18nKey_1607: '请输入最小告警次数',
  i18nKey_1608: '请输入最少起租',
  i18nKey_1609: '请输入最大告警次数',
  i18nKey_1610: '请输入最2 ~ 50个字符,一个汉字算两个字符。',
  i18nKey_1611: '请输入最2 ~ 10个字符,一个汉字算两个字符。"',
  i18nKey_1612: '请输入最1 ~ 50个字符,一个汉字算两个字符,且不能有空格！',
  i18nKey_1613: '请输入总开关安数',
  i18nKey_1614: '请输入自定义二维码刷新时间',
  i18nKey_1615: '请输入资产描述',
  i18nKey_1616: '请输入资产编号',
  i18nKey_1617: '请输入咨询电话',
  i18nKey_1618: '请输入终点站',
  i18nKey_1619: '请输入指令下发周期',
  i18nKey_1620: '请输入支路名称',
  i18nKey_1621: '请输入支付单号',
  i18nKey_1622: '请输入支出/收入方',
  i18nKey_1623: '请输入正整数',
  i18nKey_1624: '请输入正数(最多保留两位小数)!',
  i18nKey_1625: '请输入正确格式的手机号搜索',
  i18nKey_1626: '请输入正确格式的电话号码',
  i18nKey_1627: '请输入正确的验证码',
  i18nKey_1628: '请输入正确的姓名和手机号',
  i18nKey_1629: '请输入正确的手机号码',
  i18nKey_1630: '请输入正确的手机号',
  i18nKey_1631: '请输入正确的身份证号',
  i18nKey_1632: '请输入正确的护照号',
  i18nKey_1633: '请输入正确的电话号码',
  i18nKey_1634: '请输入正确的次数',
  i18nKey_1635: '请输入正确的车牌号或者手机号',
  i18nKey_1636: '请输入正确的车牌号',
  i18nKey_1637: '请输入正确的备注名',
  i18nKey_1638: '请输入正确的办公电话',
  i18nKey_1639: '请输入账号或手机号',
  i18nKey_1640: '请输入占比',
  i18nKey_1641: '请输入原始密码',
  i18nKey_1642: '请输入园区名称',
  i18nKey_1643: '请输入域账号',
  i18nKey_1644: '请输入预警值',
  i18nKey_1645: '请输入预定起始时间',
  i18nKey_1646: '请输入预订人名称',
  i18nKey_1647: '请输入仪表品牌',
  i18nKey_1648: '请输入仪表名称',
  i18nKey_1649: '请输入仪表号,设备名称，商/租户名称',
  i18nKey_1650: '请输入仪表代码/房间号/楼层',
  i18nKey_1651: '请输入仪表代码/编号/名称',
  i18nKey_1652: '请输入仪表表号/楼栋单元',
  i18nKey_1653: '请输入仪表表号',
  i18nKey_1654: '请输入业态名称',
  i18nKey_1655: '请输入验证码',
  i18nKey_1656: '请输入巡巡逻路线说明',
  i18nKey_1657: '请输入巡逻时间',
  i18nKey_1658: '请输入巡逻人',
  i18nKey_1659: '请输入巡逻路线说明',
  i18nKey_1660: '请输入巡逻路线名称',
  i18nKey_1661: '请输入需统计的天数',
  i18nKey_1662: '请输入需监测的总天数',
  i18nKey_1663: '请输入新密码',
  i18nKey_1664: '请输入小于10的数字',
  i18nKey_1665: '请输入小于1~20000之间的价格',
  i18nKey_1666: '请输入小时/天',
  i18nKey_1667: '请输入消防分区名称',
  i18nKey_1668: '请输入详细地址（精确到省市区门牌号）',
  i18nKey_1669: '请输入详细地址',
  i18nKey_1670: '请输入显示价格',
  i18nKey_1671: '请输入违停次数',
  i18nKey_1672: '请输入完整姓名或手机号',
  i18nKey_1673: '请输入外部位置名称',
  i18nKey_1674: '请输入停车场名称',
  i18nKey_1675: '请输入停车场地址',
  i18nKey_1676: '请输入套餐数量',
  i18nKey_1677: '请输入套餐使用次数',
  i18nKey_1678: '请输入套餐名称',
  i18nKey_1679: '请输入所属公司',
  i18nKey_1680: '请输入搜索词',
  i18nKey_1681: '请输入数字值',
  i18nKey_1682: '请输入数字',
  i18nKey_1683: '请输入数量',
  i18nKey_1684: '请输入受访公司',
  i18nKey_1685: '请输入受访地址',
  i18nKey_1686: '请输入手���精确搜索匹配',
  i18nKey_1687: '请输入手机号搜索',
  i18nKey_1688: '请输入手机号码搜索',
  i18nKey_1689: '请输入手机号码或域账号',
  i18nKey_1690: '请输入手机号',
  i18nKey_1691: '请输入收分账号名',
  i18nKey_1692: '请输入收分手机号',
  i18nKey_1693: '请输入收费须知',
  i18nKey_1694: '请输入事件编号',
  i18nKey_1695: '请输入设施别名',
  i18nKey_1696: '请输入设施编号',
  i18nKey_1697: '请输入设备别名',
  i18nKey_1698: '请输入上报人电话',
  i18nKey_1699: '请输入上报人',
  i18nKey_1700: '请输入商铺号',
  i18nKey_1701: '请输入商家名称',
  i18nKey_1702: '请输入商/租户名称',
  i18nKey_1703: '请输入容纳人数，需为整数',
  i18nKey_1704: '请输入容纳人数',
  i18nKey_1705: '请输入任务责任人',
  i18nKey_1706: '请输入任务委派人',
  i18nKey_1707: '请输入任务名称',
  i18nKey_1708: '请输入任务描述',
  i18nKey_1709: '请输入任务或人员名称',
  i18nKey_1710: '请输入任务编号',
  i18nKey_1711: '请输入人员基础数据编号',
  i18nKey_1712: '请输入热力范围',
  i18nKey_1713: '请输入区块信息',
  i18nKey_1714: '请输入起点站',
  i18nKey_1715: '请输入企业中文名',
  i18nKey_1716: '请输入企业邮箱后缀',
  i18nKey_1717: '请输入企业网址',
  i18nKey_1718: '请输入企业名称',
  i18nKey_1719: '请输入企业联系电话',
  i18nKey_1720: '请输入企业介绍',
  i18nKey_1721: '请输入票/单价',
  i18nKey_1722: '请输入批次号',
  i18nKey_1723: '请输入昵称或电话搜索',
  i18nKey_1724: '请输入昵称',
  i18nKey_1725: '请输入内容',
  i18nKey_1726: '请输入摩根平台会议室ID',
  i18nKey_1727: '请输入模式名称',
  i18nKey_1728: '请输入面积，需为整数',
  i18nKey_1729: '请输入面积',
  i18nKey_1730: '请输入密码',
  i18nKey_1731: '请输入门禁名称',
  i18nKey_1732: '请输入每天需分析的有效停留时长',
  i18nKey_1733: '请输入路线描述',
  i18nKey_1734: '请输入路灯名称或地址',
  i18nKey_1735: '请输入量程',
  i18nKey_1736: '请输入联系电话',
  i18nKey_1737: '请输入累计可租时长',
  i18nKey_1738: '请输入空间名称/工位编号',
  i18nKey_1739: '请输入可预订人名称',
  i18nKey_1740: '请输入距离范围',
  i18nKey_1741: '请输入旧密码',
  i18nKey_1742: '请输入金额',
  i18nKey_1743: '请输入接待人姓名/手机号',
  i18nKey_1744: '请输入角色名称',
  i18nKey_1745: '请输入角色描述',
  i18nKey_1746: '请输入奖励数量',
  i18nKey_1747: '请输入奖励对象/收分账号',
  i18nKey_1748: '请输入建筑名称',
  i18nKey_1749: '请输入简易路线',
  i18nKey_1750: '请输入价格',
  i18nKey_1751: '请输入计量表名称',
  i18nKey_1752: '请输入计量表编号',
  i18nKey_1753: '请输入计划名称',
  i18nKey_1754: '请输入积分类型名称',
  i18nKey_1755: '请输入积分价值',
  i18nKey_1756: '请输入活动名称',
  i18nKey_1757: '请输入活动',
  i18nKey_1758: '请输入会议室名称/预定企业/会议状态',
  i18nKey_1759: '请输入会议室名称',
  i18nKey_1760: '请输入会议室描述',
  i18nKey_1761: '请输入户型名称',
  i18nKey_1762: '请输入核载人数',
  i18nKey_1763: '请输入合法的MAC地址，如00-01-6c-06-A6-29或00:01:6c:06:A6:29',
  i18nKey_1764: '请输入管理员姓名',
  i18nKey_1765: '请输入公区名称',
  i18nKey_1766: '请输入工作人员手机号码',
  i18nKey_1767: '请输入工作人员名称',
  i18nKey_1768: '请输入工位数量',
  i18nKey_1769: '请输入工位简介',
  i18nKey_1770: '请输入工位价格',
  i18nKey_1771: '请输入工商执照注册号',
  i18nKey_1772: '请输入工单责任人',
  i18nKey_1773: '请输入工单编号',
  i18nKey_1774: '请输入感应后保持亮度时长',
  i18nKey_1775: '请输入附加设施数量',
  i18nKey_1776: '请输入附加设施名称',
  i18nKey_1777: '请输入服务器IP或域名',
  i18nKey_1778: '请输入分项名称',
  i18nKey_1779: '请输入房间名称',
  i18nKey_1780: '请输入法人身份证',
  i18nKey_1781: '请输入法定代表人',
  i18nKey_1782: '请输入发行数量',
  i18nKey_1783: '请输入额度，需为整数',
  i18nKey_1784: '请输入额度',
  i18nKey_1785: '请输入多少分钟',
  i18nKey_1786: '请输入订单编号/空间名称',
  i18nKey_1787: '请输入订单编号/会议室名称',
  i18nKey_1788: '请输入订单编号',
  i18nKey_1789: '请输入电缆平方数',
  i18nKey_1790: '请输入电话搜索',
  i18nKey_1791: '请输入电表表号,设备名称,商/租户名称',
  i18nKey_1792: '请输入电表编号',
  i18nKey_1793: '请输入第三方ID',
  i18nKey_1794: '请输入地址经纬度',
  i18nKey_1795: '请输入地址',
  i18nKey_1796: '请输入当前账号登录密码',
  i18nKey_1797: '请输入单价(元/小时)',
  i18nKey_1798: '请输入处理人',
  i18nKey_1799: '请输入承租方',
  i18nKey_1800: '请输入车主姓名',
  i18nKey_1801: '请输入抄表人',
  i18nKey_1802: '请输入场景名称',
  i18nKey_1803: '请输入场景的名称，不超过十个字',
  i18nKey_1804: '请输入策略名称',
  i18nKey_1805: '请输入表底',
  i18nKey_1806: '请输入倍率',
  i18nKey_1807: '请输入半天单价（元/4小时）',
  i18nKey_1808: '请输入班次编号',
  i18nKey_1809: '请输入安装位置',
  i18nKey_1810: '请输入0-1之间的小数',
  i18nKey_1811: '请输入$表号,设备名称,商/租户名称',
  i18nKey_1812: '请输入',
  i18nKey_1813: '请上传新版excel文件',
  i18nKey_1814: '请上传图像',
  i18nKey_1815:
    '请确保您拥有权限访问这些信息，您所有的操作将被记录，超过30分钟没有操作，将需要重新进行验证',
  i18nKey_1816: '请求关联列表异常',
  i18nKey_1817: '请您联系管理员获取相关服务权限。',
  i18nKey_1818: '请将当前层级的IT编码、业务编码和空间层级名称填写完整！',
  i18nKey_1819: '请检查地址栏链接后，重新访问',
  i18nKey_1820: '请登录您的账号',
  i18nKey_1821: '请登录',
  i18nKey_1822: '请把相同的电表公式整合在一起',
  i18nKey_1823: '清除',
  i18nKey_1824: '轻度污染',
  i18nKey_1825: '青色',
  i18nKey_1826: '切换至全览',
  i18nKey_1827: '切换至默认比例',
  i18nKey_1828: '切换图像',
  i18nKey_1829: '切换列表',
  i18nKey_1830: '欠费商户',
  i18nKey_1831: '前一天',
  i18nKey_1832: '前往IOC登录页',
  i18nKey_1833: '起始时间不能大于等于结束时间',
  i18nKey_1834: '起始或者结束时长不能为空',
  i18nKey_1835: '起始或者结束金额不能为空',
  i18nKey_1836: '起始或者结束次数不能为空',
  i18nKey_1837: '起点站',
  i18nKey_1838: '起点人数',
  i18nKey_1839: '起点不能为空',
  i18nKey_1840: '启用日期',
  i18nKey_1841: '启用',
  i18nKey_1842: '企业中文名',
  i18nKey_1843: '企业员工',
  i18nKey_1844: '企业邮箱后缀',
  i18nKey_1845: '企业英文名',
  i18nKey_1846: '企业详细地址',
  i18nKey_1847: '企业网址',
  i18nKey_1848: '企业商户',
  i18nKey_1849: '企业能耗排名',
  i18nKey_1850: '企业名称',
  i18nKey_1851: '企业每日打卡_',
  i18nKey_1852: '企业联系号码',
  i18nKey_1853: '企业联系电话',
  i18nKey_1854: '企业类型',
  i18nKey_1855: '企业介绍',
  i18nKey_1856: '企业挂账',
  i18nKey_1857: '企业访客',
  i18nKey_1858: '企业法人',
  i18nKey_1859: '企业地址',
  i18nKey_1860: '企业logo',
  i18nKey_1861: '企业',
  i18nKey_1862: '其子分项能耗占比',
  i18nKey_1863: '其它',
  i18nKey_1864: '其他用电',
  i18nKey_1865: '其他信息',
  i18nKey_1866: '其他',
  i18nKey_1867: '七类',
  i18nKey_1868: '普通事件',
  i18nKey_1869: '普通',
  i18nKey_1870: '平日客流',
  i18nKey_1871: '平均转化时间',
  i18nKey_1872: '平均驻留时长',
  i18nKey_1873: '平均值',
  i18nKey_1874: '平均每天节省费用',
  i18nKey_1875: '平均每个企业最多发行数',
  i18nKey_1876: '平均处理时长：',
  i18nKey_1877: '平均处理时长(分钟)',
  i18nKey_1878: '平层',
  i18nKey_1879: '品牌只能由中英文、数字及_组成',
  i18nKey_1880: '品牌长度不能超过100个字符',
  i18nKey_1881: '品牌和颜色超过6个选项 已为您按最优显示',
  i18nKey_1882: '品牌分析',
  i18nKey_1883: '品牌超过6个选项 已为您按最优显示',
  i18nKey_1884: '品牌：',
  i18nKey_1885: '品牌',
  i18nKey_1886: '频率',
  i18nKey_1887: '频次',
  i18nKey_1888: '票/单价',
  i18nKey_1889: '批量修改基于导出数据的模板',
  i18nKey_1890: '批量修改',
  i18nKey_1891: '批量创建',
  i18nKey_1892: '配置详情',
  i18nKey_1893: '配电开关名称',
  i18nKey_1894: '派发时间',
  i18nKey_1895: '派发成功',
  i18nKey_1896: '排名',
  i18nKey_1897: '女性',
  i18nKey_1898: '您有较多的待查询任务，请等待任务执行完毕后再进行查询。',
  i18nKey_1899: '您已成功修改密码，请重新登录平台。',
  i18nKey_1900: '您要暂停所选套餐吗？',
  i18nKey_1901: '您要暂停所选免额吗？',
  i18nKey_1902: '您要暂停所选会议室吗？',
  i18nKey_1903: '您要暂停所选工位吗？',
  i18nKey_1904: '您要暂停所选乘车班次吗？',
  i18nKey_1905: '您要下架所选限时折扣吗？',
  i18nKey_1906: '您要上架所选限时折扣吗？',
  i18nKey_1907: '您要启用所选套餐吗？',
  i18nKey_1908: '您要启用所选免额吗？',
  i18nKey_1909: '您要启用所选会议室吗？',
  i18nKey_1910: '您要启用所选工位吗？',
  i18nKey_1911: '您要启用所选乘车班次吗？',
  i18nKey_1912: '您想清空所有查询历史吗？',
  i18nKey_1913: '您设置的时间存在重叠',
  i18nKey_1914: '您确认要执行吗？',
  i18nKey_1915: '您确认要停用吗？',
  i18nKey_1916: '您确认要删除吗？',
  i18nKey_1917: '您确认要启用吗？',
  i18nKey_1918: '您确定要删除限时折扣吗？',
  i18nKey_1919: '您确定要删除套餐吗？',
  i18nKey_1920: '您没有选中任何数据项！',
  i18nKey_1921: '您没有选中任何数据项',
  i18nKey_1922: '您好，欢迎来到IOC！',
  i18nKey_1923: '您还没有任何公司的访客数据，您可以',
  i18nKey_1924: '您访问的页面不存在',
  i18nKey_1925: '您的浏览器不支持HTML5',
  i18nKey_1926: '您当前选中设备存在故障，请先安排人处理！',
  i18nKey_1927: '您当前选中大屏已离线，指令不可达！',
  i18nKey_1928: '年龄范围',
  i18nKey_1929: '年度',
  i18nKey_1930: '年',
  i18nKey_1931: '昵称不能超过12个字符长度',
  i18nKey_1932: '昵称',
  i18nKey_1933: '你已选择套餐缴费',
  i18nKey_1934: '你已选择临停缴费',
  i18nKey_1935: '你已录入$/$,与上期数据对比，有$条数据异常($),确定提交吗',
  i18nKey_1936: '你已导入$条数据,有$条数据异常,确定提交吗',
  i18nKey_1937: '你所编辑的策略还未保存，确认放弃？',
  i18nKey_1938: '你确定需要删除该策略吗？删除后，需要手动控制灯开关。',
  i18nKey_1939: '能效比COP(kW/kW)',
  i18nKey_1940: '能效AI诊断',
  i18nKey_1941: '能耗统计',
  i18nKey_1942: '能耗浪费',
  i18nKey_1943: '能耗监测',
  i18nKey_1944: '能耗对比',
  i18nKey_1945: '能耗(kWh)',
  i18nKey_1946: '内部企业',
  i18nKey_1947: '男性',
  i18nKey_1948: '目标值设置',
  i18nKey_1949: '目标值',
  i18nKey_1950: '默认（次日00:00刷新）',
  i18nKey_1951: '默认',
  i18nKey_1952: '摩根平台会议室ID',
  i18nKey_1953: '模式名称',
  i18nKey_1954: '模式',
  i18nKey_1955: '模糊查询',
  i18nKey_1956: '模板下载',
  i18nKey_1957: '明细',
  i18nKey_1958: '秒后重发',
  i18nKey_1959: '秒后会自动跳转到IOC登录页',
  i18nKey_1960: '秒',
  i18nKey_1961: '描述不能为空',
  i18nKey_1962: '面试',
  i18nKey_1963: '面积(m²)',
  i18nKey_1964: '免费设施',
  i18nKey_1965: '免费额度(小时/月)',
  i18nKey_1966: '免额启用中，不允许修改！',
  i18nKey_1967: '密码状态',
  i18nKey_1968: '密码长度为8~20位字符!',
  i18nKey_1969: '密码长度必须是8到20位且包含数字和字母',
  i18nKey_1970: '密码输入错误次数超过5次，10秒后将退出登录',
  i18nKey_1971:
    '密码强度不符合,至少8位,包括数字,大、小写字母,特殊字符中的至少三类',
  i18nKey_1972: '密码错误，请重试',
  i18nKey_1973: '密码不能包含空格',
  i18nKey_1974: '密码必须是8到20位的数字和字母',
  i18nKey_1975: '密码必须包括字母、数字、符号中至少2种',
  i18nKey_1976: '米',
  i18nKey_1977: '门锁编号',
  i18nKey_1978: '门禁通行限次',
  i18nKey_1979: '门禁通行方式',
  i18nKey_1980: '门禁名称',
  i18nKey_1981: '门禁二维码读头',
  i18nKey_1982: '门禁PAD',
  i18nKey_1983: '每条策略最多可添加6种亮灯规则',
  i18nKey_1984: '每平方米能耗(t/m²)',
  i18nKey_1985: '每平方米能耗(kWh/m²)',
  i18nKey_1986: '每平方米能耗',
  i18nKey_1987:
    '每次只能追踪一个任务，确定需要放弃当前追踪任务并开启新的追踪任务吗？',
  i18nKey_1988: '没有数据，请从下方空间树选中楼栋',
  i18nKey_1989: '没有数据!',
  i18nKey_1990: '没有会议安排的时段',
  i18nKey_1991: '没有查找到该用户',
  i18nKey_1992: '绿色节能',
  i18nKey_1993: '绿色',
  i18nKey_1994: '轮巡机数量',
  i18nKey_1995: '路线描述',
  i18nKey_1996: '路线列表',
  i18nKey_1997: '路线（条）',
  i18nKey_1998: '路线',
  i18nKey_1999: '路灯选择',
  i18nKey_2000: '楼宇自控',
  i18nKey_2001: '楼栋单元',
  i18nKey_2002: '楼栋/楼层/房间',
  i18nKey_2003: '楼栋',
  i18nKey_2004: '楼层空间',
  i18nKey_2005: '楼层',
  i18nKey_2006: '六类',
  i18nKey_2007: '六级',
  i18nKey_2008: '临停收费',
  i18nKey_2009: '临停缴费金额(元)',
  i18nKey_2010: '临停车次',
  i18nKey_2011: '临停车',
  i18nKey_2012: '临停',
  i18nKey_2013: '临时访客',
  i18nKey_2014: '临时',
  i18nKey_2015: '量程',
  i18nKey_2016: '辆',
  i18nKey_2017: '亮度显示',
  i18nKey_2018: '亮度设置',
  i18nKey_2019: '亮度',
  i18nKey_2020: '亮灯规则',
  i18nKey_2021: '两次输入的密码不一致',
  i18nKey_2022: '两充两放',
  i18nKey_2023: '良',
  i18nKey_2024: '联系电话',
  i18nKey_2025: '联排',
  i18nKey_2026:
    '例子：平安XXXXXX停车场,首小时15元（前15分钟免费），此后每小时10元 ，60封顶。广东省深圳市福田区XXXXX',
  i18nKey_2027: '例如：首小时15元（前15分钟免费），此后每小时10元 ，60封顶。',
  i18nKey_2028: '立即注册',
  i18nKey_2029: '立即登录',
  i18nKey_2030: '历史诊断',
  i18nKey_2031: '历史统计',
  i18nKey_2032: '历史视频播放结束',
  i18nKey_2033: '历史视频',
  i18nKey_2034: '历史曲线',
  i18nKey_2035: '历史轨迹',
  i18nKey_2036: '历史版本',
  i18nKey_2037: '离线(个)',
  i18nKey_2038: '离线',
  i18nKey_2039: '离开舒适区,是前进的第一步。',
  i18nKey_2040: '冷机出水温度',
  i18nKey_2041: '累计消费金额',
  i18nKey_2042: '累计停车缴费(元)',
  i18nKey_2043: '累计临停缴费$元，可以推荐月卡给他哦',
  i18nKey_2044: '累计可租时长',
  i18nKey_2045: '累计可租时常不能小于最小起租！',
  i18nKey_2046: '累计缴费>¥400',
  i18nKey_2047: '累计到访次数',
  i18nKey_2048: '累计到访',
  i18nKey_2049: '累积节能潜力始于2019年10月01日至今',
  i18nKey_2050: '累积节能潜力(kWh)',
  i18nKey_2051: '累积节能潜力',
  i18nKey_2052: '类型不能为空',
  i18nKey_2053: '类型',
  i18nKey_2054: '类别不能为空',
  i18nKey_2055: '蓝色',
  i18nKey_2056: '来自省市',
  i18nKey_2057: '来源系统设备编号',
  i18nKey_2058: '来源系统',
  i18nKey_2059: '来源',
  i18nKey_2060: '来访原因',
  i18nKey_2061: '来访时段的开始时间不能大于或等于结束时间',
  i18nKey_2062: '来访类型',
  i18nKey_2063: '空闲状态',
  i18nKey_2064: '空调用电',
  i18nKey_2065: '空气质量令人满意，基本无空气污染',
  i18nKey_2066: '空间位置',
  i18nKey_2067: '空间名称/工位编号',
  i18nKey_2068: '空间名称',
  i18nKey_2069: '空间类型',
  i18nKey_2070: '空间基础数据业务编码',
  i18nKey_2071: '空间基础数据修改',
  i18nKey_2072: '空间基础数据详情',
  i18nKey_2073: '空间基础数据位置',
  i18nKey_2074: '空间基础数据创建',
  i18nKey_2075: '空间基础数据编码',
  i18nKey_2076: '空间层级名称',
  i18nKey_2077: '空间层级',
  i18nKey_2078: '客厅',
  i18nKey_2079: '客流总数（人次）',
  i18nKey_2080: '客流性别占比',
  i18nKey_2081: '可用率范围',
  i18nKey_2082: '可选单位',
  i18nKey_2083: '可提前预定0到30',
  i18nKey_2084: '开锁类型',
  i18nKey_2085: '开始时间和结束时间相距不能小于30分钟',
  i18nKey_2086: '开始时间和结束时间相差不能超过7天',
  i18nKey_2087: '开始时间不能为空',
  i18nKey_2088: '开始时间不能大于结束时间',
  i18nKey_2089: '开始时间',
  i18nKey_2090: '开始安装',
  i18nKey_2091: '开启追踪成功',
  i18nKey_2092: '开启追踪',
  i18nKey_2093: '开启所选',
  i18nKey_2094: '开启时间',
  i18nKey_2095: '开启门禁',
  i18nKey_2096: '开启后智慧门禁按照设定时间频率更新',
  i18nKey_2097: '开启后可以通行',
  i18nKey_2098: '开启后访客可以查看自己的历史登记记录',
  i18nKey_2099: '开启后，移动端用户可以使用该功能，关闭后则不显示。',
  i18nKey_2100: '开启后，移动端用户可使用该功能，关闭后则不可使用',
  i18nKey_2101: '开门状态',
  i18nKey_2102: '开门频次',
  i18nKey_2103: '开门记录',
  i18nKey_2104: '开会模式',
  i18nKey_2105: '开/关',
  i18nKey_2106: '开',
  i18nKey_2107: '具体空间位置编码',
  i18nKey_2108: '拒绝',
  i18nKey_2109: '居住地址',
  i18nKey_2110: '旧密码',
  i18nKey_2111: '经度整数部分为0-180',
  i18nKey_2112: '经度',
  i18nKey_2113:
    '京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领',
  i18nKey_2114: '禁止进入车场',
  i18nKey_2115: '禁止办理月卡',
  i18nKey_2116: '禁用失败',
  i18nKey_2117: '近一月入场次数',
  i18nKey_2118: '近一月平均客流',
  i18nKey_2119: '近一月到访天数',
  i18nKey_2120: '近一月',
  i18nKey_2121: '近一年',
  i18nKey_2122: '近一个月最早$进入园区',
  i18nKey_2123: '近一个月未离场',
  i18nKey_2124: '近一个月未到访',
  i18nKey_2125: '近一个月回头客到访次数',
  i18nKey_2126: '近一个月大于400',
  i18nKey_2127: '近7天',
  i18nKey_2128: '近30天离开车场次数',
  i18nKey_2129: '近30天进入车场次数',
  i18nKey_2130: '近24小时重大事件告警',
  i18nKey_2131: '近24小时',
  i18nKey_2132: '进行中',
  i18nKey_2133: '进车位时间',
  i18nKey_2134: '进场时间',
  i18nKey_2135: '紧急告警',
  i18nKey_2136: '仅一次',
  i18nKey_2137: '仅显示视频巡逻告警数量',
  i18nKey_2138: '仅计划内的巡逻参与统计',
  i18nKey_2139: '金融',
  i18nKey_2140: '金额最大长度为6位数',
  i18nKey_2141: '金额只能为整数或2位数以内的小数',
  i18nKey_2142: '今日通行人流',
  i18nKey_2143: '今日通行车流',
  i18nKey_2144: '今日实际电能耗（kWh）',
  i18nKey_2145: '今日客流',
  i18nKey_2146: '今日开门频次排行',
  i18nKey_2147: '今日回头客数',
  i18nKey_2148: '解除黑名单成功',
  i18nKey_2149: '解除黑名单',
  i18nKey_2150: '截图失败',
  i18nKey_2151: '结束时长不能小于起始时长',
  i18nKey_2152: '结束时间需要大于开始时间',
  i18nKey_2153: '结束时间不能为空',
  i18nKey_2154: '结束时间',
  i18nKey_2155: '结束模式',
  i18nKey_2156: '结束金额不能小于起始金额',
  i18nKey_2157: '结束次数不能小于起始次数',
  i18nKey_2158: '节省费用(元)',
  i18nKey_2159: '节省费用',
  i18nKey_2160: '节日名称不允许为空',
  i18nKey_2161: '节日',
  i18nKey_2162: '节能潜力(kWh)',
  i18nKey_2163: '节能潜力',
  i18nKey_2164: '节能类型(种)',
  i18nKey_2165: '节能类型',
  i18nKey_2166: '节目名称：',
  i18nKey_2167: '接入电压等级(V)',
  i18nKey_2168: '接口请求失败',
  i18nKey_2169: '接待人姓名',
  i18nKey_2170: '接待人信息',
  i18nKey_2171: '接待人电话',
  i18nKey_2172: '接驳车满座率',
  i18nKey_2173: '较昨天',
  i18nKey_2174: '较昨日',
  i18nKey_2175: '较上周',
  i18nKey_2176: '较上月环比',
  i18nKey_2177: '较上月',
  i18nKey_2178: '较上一年',
  i18nKey_2179: '较上年实际值',
  i18nKey_2180: '较去年同比',
  i18nKey_2181: '较前一月环比',
  i18nKey_2182: '较前一天',
  i18nKey_2183: '缴费明细',
  i18nKey_2184: '缴费类型',
  i18nKey_2185: '角色状态',
  i18nKey_2186: '角色名称',
  i18nKey_2187: '角色描述',
  i18nKey_2188: '角色列表',
  i18nKey_2189: '角色价格名称',
  i18nKey_2190: '奖励数量不能小于零',
  i18nKey_2191: '奖励数量不能为零',
  i18nKey_2192: '奖励数量不能带有小数',
  i18nKey_2193: '奖励数量',
  i18nKey_2194: '奖励积分数量(个)',
  i18nKey_2195: '奖励积分名称',
  i18nKey_2196: '奖励发起人',
  i18nKey_2197: '奖励对象选择',
  i18nKey_2198: '奖励对象类型',
  i18nKey_2199: '奖励对象/收分账号',
  i18nKey_2200: '奖励',
  i18nKey_2201: '将文件拖到此处，或 点击上传',
  i18nKey_2202: '建筑选择',
  i18nKey_2203: '建筑电器系统',
  i18nKey_2204: '建议值',
  i18nKey_2205: '建议执行人',
  i18nKey_2206: '建议运行参数',
  i18nKey_2207: '简易路线',
  i18nKey_2208: '检修中',
  i18nKey_2209: '监控摄像',
  i18nKey_2210: '监控截图',
  i18nKey_2211: '监控：',
  i18nKey_2212: '监测周期（天）',
  i18nKey_2213: '监测天数只能是正整数',
  i18nKey_2214: '监测',
  i18nKey_2215: '间隔时间范围在1-60之内',
  i18nKey_2216: '间隔时间(秒)',
  i18nKey_2217: '驾驶证号输入有特殊字符',
  i18nKey_2218: '驾驶证号',
  i18nKey_2219: '驾驶证：',
  i18nKey_2220: '价格设置',
  i18nKey_2221: '价格(/剩余次数)',
  i18nKey_2222: '加班比较多，近一个月累计10天晚上20:00后离开园区',
  i18nKey_2223: '继电器状态',
  i18nKey_2224: '记住当前账号',
  i18nKey_2225: '计量表名称',
  i18nKey_2226: '计量表简称',
  i18nKey_2227: '计量表编号',
  i18nKey_2228: '计量表',
  i18nKey_2229: '计划详情',
  i18nKey_2230: '计划名称',
  i18nKey_2231: '计费类型',
  i18nKey_2232: '籍贯',
  i18nKey_2233: '基本信息',
  i18nKey_2234: '积分总价值(元)',
  i18nKey_2235: '积分总价值',
  i18nKey_2236: '积分有效期(开始)',
  i18nKey_2237: '积分有效期(结束)',
  i18nKey_2238: '积分有效期',
  i18nKey_2239: '积分钱包',
  i18nKey_2240: '积分价值(元/分)',
  i18nKey_2241: '机械表',
  i18nKey_2242: '机房功率（kWh）',
  i18nKey_2243: '获取验证码',
  i18nKey_2244: '获取视频资源失败',
  i18nKey_2245: '获取时段',
  i18nKey_2246: '获取摄像头资源失败...',
  i18nKey_2247: '获取设备信息失败！',
  i18nKey_2248: '获取空间数据失败！',
  i18nKey_2249: '获取地址经纬度',
  i18nKey_2250: '获取table列表异常',
  i18nKey_2251: '货梯选择',
  i18nKey_2252: '活动状态',
  i18nKey_2253: '活动详细信息',
  i18nKey_2254: '活动名称',
  i18nKey_2255: '活动工位的最少起租需不小于30天！',
  i18nKey_2256: '活动工位',
  i18nKey_2257: '活动不能为空',
  i18nKey_2258: '活动',
  i18nKey_2259: '会员姓名',
  i18nKey_2260: '会员编码',
  i18nKey_2261: '会议状态',
  i18nKey_2262: '会议信息',
  i18nKey_2263: '会议室详情',
  i18nKey_2264: '会议室首图',
  i18nKey_2265: '会议室使用',
  i18nKey_2266: '会议室名称不支持特殊字符（允许汉字、字母和数字）',
  i18nKey_2267: '会议室名称',
  i18nKey_2268: '会议室描述',
  i18nKey_2269: '会议室服务人员',
  i18nKey_2270: '会议室订单详情',
  i18nKey_2271: '会议开始前：未启动',
  i18nKey_2272: '会议开始前：',
  i18nKey_2273: '会议开始前',
  i18nKey_2274: '会议结束后：',
  i18nKey_2275: '回头客到访人数',
  i18nKey_2276: '回头客比例',
  i18nKey_2277: '回头客',
  i18nKey_2278: '回路状态',
  i18nKey_2279: '回路编号/服务区域',
  i18nKey_2280: '回路编号',
  i18nKey_2281: '回放',
  i18nKey_2282: '灰色',
  i18nKey_2283: '黄色',
  i18nKey_2284: '环境监测设备',
  i18nKey_2285: '环境监测',
  i18nKey_2286: '环境报警策略配置',
  i18nKey_2287: '环比用能',
  i18nKey_2288: '环比同期用电量',
  i18nKey_2289: '环比同期',
  i18nKey_2290: '环比时间',
  i18nKey_2291: '环比每平方米能耗',
  i18nKey_2292: '环比',
  i18nKey_2293: '欢迎使用IOC平台，请重置密码',
  i18nKey_2294: '欢迎使用IOC平台，请修改密码后登录您的账号',
  i18nKey_2295: '欢迎使用IOC平台，请您注册',
  i18nKey_2296: '华中',
  i18nKey_2297: '华西',
  i18nKey_2298: '华南',
  i18nKey_2299: '华东',
  i18nKey_2300: '华北园区',
  i18nKey_2301: '华北',
  i18nKey_2302: '划线',
  i18nKey_2303: '护照号',
  i18nKey_2304: '户型名称格式示例：A1-3房2厅',
  i18nKey_2305: '户型名称',
  i18nKey_2306: '户数',
  i18nKey_2307: '互联网',
  i18nKey_2308: '弧形图',
  i18nKey_2309: '后一天月预测值',
  i18nKey_2310: '后果',
  i18nKey_2311: '后付费',
  i18nKey_2312: '红色',
  i18nKey_2313: '很早投入工作了，近一个月累计10天早上8:00前进入园区',
  i18nKey_2314: '黑色',
  i18nKey_2315: '黑名单事件数',
  i18nKey_2316: '黑名单事件',
  i18nKey_2317: '黑名单概况',
  i18nKey_2318: '黑名单',
  i18nKey_2319: '核载人数',
  i18nKey_2320: '合同期：',
  i18nKey_2321: '合同编号：',
  i18nKey_2322: '合同',
  i18nKey_2323: '合伙奋斗。',
  i18nKey_2324: '合并',
  i18nKey_2325: '耗水量',
  i18nKey_2326: '耗时最长Top3',
  i18nKey_2327: '耗时最短Top3',
  i18nKey_2328: '耗电量（度）',
  i18nKey_2329: '耗电量',
  i18nKey_2330: '豪车',
  i18nKey_2331: '行为特征',
  i18nKey_2332: '行驶证：',
  i18nKey_2333: '国家',
  i18nKey_2334: '国籍',
  i18nKey_2335: '轨迹时间段',
  i18nKey_2336: '轨迹导出成功',
  i18nKey_2337: '轨迹查看',
  i18nKey_2338: '轨迹',
  i18nKey_2339: '规则',
  i18nKey_2340: '广州',
  i18nKey_2341: '管理责任人只能为20个字符以内的中文、字母及下划线',
  i18nKey_2342: '管理责任人',
  i18nKey_2343: '管理员主动退款',
  i18nKey_2344: '管理员姓名',
  i18nKey_2345: '管理所属权',
  i18nKey_2346: '管理审批人',
  i18nKey_2347: '管理部门',
  i18nKey_2348: '关联异常',
  i18nKey_2349: '关联事件',
  i18nKey_2350: '关联摄像头成功',
  i18nKey_2351: '关联摄像头',
  i18nKey_2352: '关联设施事件',
  i18nKey_2353: '关联历史视频__',
  i18nKey_2354: '关联成功',
  i18nKey_2355: '关联车牌号',
  i18nKey_2356: '关联',
  i18nKey_2357: '关键词',
  i18nKey_2358: '关闭追踪异常',
  i18nKey_2359: '关闭追踪',
  i18nKey_2360: '关闭所选',
  i18nKey_2361: '关闭告警',
  i18nKey_2362: '关闭',
  i18nKey_2363: '关',
  i18nKey_2364: '挂账人',
  i18nKey_2365: '故障总频率',
  i18nKey_2366: '故障详情',
  i18nKey_2367: '故障设施数',
  i18nKey_2368: '故障设施类型统计',
  i18nKey_2369: '故障描述',
  i18nKey_2370: '故障来源',
  i18nKey_2371: '故障',
  i18nKey_2372: '固定工位的最少起租需不小于3个月！',
  i18nKey_2373: '固定工位',
  i18nKey_2374: '勾选字段后，该字段在小程序,APP的用户可见',
  i18nKey_2375: '供应商',
  i18nKey_2376: '供配电系统',
  i18nKey_2377: '供配电',
  i18nKey_2378: '共有照明回路 $ 个',
  i18nKey_2379: '共享会议室',
  i18nKey_2380: '共享办公订单报表_',
  i18nKey_2381: '共识、共创、共担、共享。',
  i18nKey_2382: '共开门 $次',
  i18nKey_2383: '共$条，成功$条，错误$条',
  i18nKey_2384: '恭喜您，注册成功！',
  i18nKey_2385: '公有云',
  i18nKey_2386: '公司预订Top5',
  i18nKey_2387: '公区用电',
  i18nKey_2388: '公区仪表',
  i18nKey_2389: '公区能耗排名',
  i18nKey_2390: '公区能耗目标值不能大于总能耗目标值',
  i18nKey_2391: '公区能耗(上月)',
  i18nKey_2392: '公区名字',
  i18nKey_2393: '公区类型',
  i18nKey_2394: '公区',
  i18nKey_2395: '公共区域不可删除',
  i18nKey_2396: '公共配置',
  i18nKey_2397: '公安黑名单',
  i18nKey_2398: '公安',
  i18nKey_2399: '工作日',
  i18nKey_2400: '工作人员名称',
  i18nKey_2401: '工作人员电话',
  i18nKey_2402: '工作人员',
  i18nKey_2403: '工作单位',
  i18nKey_2404: '工种',
  i18nKey_2405: '工位状态',
  i18nKey_2406: '工位数量',
  i18nKey_2407: '工位首图',
  i18nKey_2408: '工位类型',
  i18nKey_2409: '工位简介',
  i18nKey_2410: '工位价格的单位需跟最少起租的单位保持一致！',
  i18nKey_2411: '工位价格',
  i18nKey_2412: '工位编号的个数需与工位数量一致！',
  i18nKey_2413: '工位编号',
  i18nKey_2414: '工商执照注册号/统一社会信用代码',
  i18nKey_2415: '工号',
  i18nKey_2416: '工单状态',
  i18nKey_2417: '工单责任人',
  i18nKey_2418: '工单委派人',
  i18nKey_2419: '工单派发成功',
  i18nKey_2420: '工单编号',
  i18nKey_2421: '更新时间为昨日统计时间',
  i18nKey_2422: '更新时间',
  i18nKey_2423: '更新失败！',
  i18nKey_2424: '更新成功',
  i18nKey_2425: '更多图层',
  i18nKey_2426: '更多任务',
  i18nKey_2427: '给排水',
  i18nKey_2428: '各类人群可正常活动',
  i18nKey_2429: '个人积分',
  i18nKey_2430: '个人',
  i18nKey_2431: '个/每个企业',
  i18nKey_2432: '个',
  i18nKey_2433: '告警阈值',
  i18nKey_2434: '告警事件及跟进状态',
  i18nKey_2435: '告警事件关联设备不存在',
  i18nKey_2436: '告警事件概况',
  i18nKey_2437: '告警事件',
  i18nKey_2438: '告警描述',
  i18nKey_2439: '告警列表',
  i18nKey_2440: '告警类型',
  i18nKey_2441: '告警来源',
  i18nKey_2442: '告警分类统计',
  i18nKey_2443: '告警次数',
  i18nKey_2444: '告警查看',
  i18nKey_2445: '高亮',
  i18nKey_2446: '高级',
  i18nKey_2447: '感应微亮',
  i18nKey_2448: '感应亮度',
  i18nKey_2449: '感应开关',
  i18nKey_2450: '感应后保持亮度时长',
  i18nKey_2451: '感应点亮时间',
  i18nKey_2452: '该事件状态为挂起，暂时不能上报',
  i18nKey_2453: '该事件状态为待处理，暂时不能上报',
  i18nKey_2454: '该事件状态为处理中，暂时不能上报',
  i18nKey_2455: '该任务有未处理事件，暂时无法上报',
  i18nKey_2456: '该人员在这个时间段没有行动轨迹',
  i18nKey_2457: '该人员没有图像，不能进行布控',
  i18nKey_2458: '该会议室已被停用，无法执行',
  i18nKey_2459: '该会议室下没有可预订的日期',
  i18nKey_2460: '该地区暂无停车场',
  i18nKey_2461: '该乘车班次已启用，请先暂停后再操作！',
  i18nKey_2462: '该场景已被停用，无法执行',
  i18nKey_2463: '该布控已停止，不能修改',
  i18nKey_2464: '该布控已启动，不能修改',
  i18nKey_2465: '该布控车辆数据已被删除，不支持修改或重新布控，请重新创建布控',
  i18nKey_2466: '附件仅支持新版excel、PDF、jpg、rar、zip格式',
  i18nKey_2467: '附件',
  i18nKey_2468: '附加设施数量',
  i18nKey_2469: '附加设施名称已存在！',
  i18nKey_2470: '附加设施名称',
  i18nKey_2471: '负荷情况',
  i18nKey_2472: '付款方式',
  i18nKey_2473: '付费模式',
  i18nKey_2474: '付费方式',
  i18nKey_2475: '辅助视频设置',
  i18nKey_2476: '服务资源',
  i18nKey_2477: '服务商只能由中英文、数字及_组成',
  i18nKey_2478: '服务商长度不能超过50个字符',
  i18nKey_2479: '服务商',
  i18nKey_2480: '服务区域 不能为空',
  i18nKey_2481: '服务区域',
  i18nKey_2482: '服务器信息',
  i18nKey_2483: '服务厂商',
  i18nKey_2484: '份数',
  i18nKey_2485: '份',
  i18nKey_2486: '粉色',
  i18nKey_2487: '分钟不能为空',
  i18nKey_2488: '分钟不能低于2分钟且不能大于10分钟,且不能有空格！',
  i18nKey_2489: '分钟',
  i18nKey_2490: '分项占比不能大于1',
  i18nKey_2491: '分项占比',
  i18nKey_2492: '分项维护',
  i18nKey_2493: '分项统计',
  i18nKey_2494: '分项累计能耗(kWh)',
  i18nKey_2495: '分项电表数据',
  i18nKey_2496: '分析指标：',
  i18nKey_2497: '分析',
  i18nKey_2498: '分时段客流',
  i18nKey_2499: '分区位置',
  i18nKey_2500: '分区',
  i18nKey_2501: '分期编码',
  i18nKey_2502: '分流情况',
  i18nKey_2503: '分类不能为空',
  i18nKey_2504: '分类',
  i18nKey_2505: '分',
  i18nKey_2506: '非公区',
  i18nKey_2507: '非工作日',
  i18nKey_2508: '非车位模型',
  i18nKey_2509: '非必填',
  i18nKey_2510: '放电总量',
  i18nKey_2511: '放电量(kWh)',
  i18nKey_2512: '访问来源',
  i18nKey_2513: '访客状态',
  i18nKey_2514: '访客预约',
  i18nKey_2515: '访客邀约信息录入显示内容',
  i18nKey_2516: '访客邀约',
  i18nKey_2517: '访客邀请统计(近30天)',
  i18nKey_2518: '访客姓名不能为特殊字符',
  i18nKey_2519: '访客姓名不能为空',
  i18nKey_2520: '访客姓名',
  i18nKey_2521: '访客信息不能为特殊字符',
  i18nKey_2522: '访客通行方式',
  i18nKey_2523: '访客数量',
  i18nKey_2524: '访客人员无该操作权限',
  i18nKey_2525: '访客人数统计(按园区)',
  i18nKey_2526: '访客人数-公司排行',
  i18nKey_2527: '访客人数(人)',
  i18nKey_2528: '访客人数',
  i18nKey_2529: '访客来访人数',
  i18nKey_2530: '访客管理',
  i18nKey_2531: '访客电话不能为空',
  i18nKey_2532: '访客电话',
  i18nKey_2533: '访客到达时间',
  i18nKey_2534: '访客达到提醒',
  i18nKey_2535: '访客拜访事由',
  i18nKey_2536: '访客',
  i18nKey_2537: '房屋信息',
  i18nKey_2538: '房屋类型',
  i18nKey_2539: '房屋地址',
  i18nKey_2540: '房屋ID',
  i18nKey_2541: '房间用电',
  i18nKey_2542: '房间仪表',
  i18nKey_2543: '房间位置',
  i18nKey_2544: '房间数',
  i18nKey_2545: '房间名称',
  i18nKey_2546: '房间号',
  i18nKey_2547: '房地产',
  i18nKey_2548: '防火分区',
  i18nKey_2549: '返回登录',
  i18nKey_2550: '返回',
  i18nKey_2551: '法人身份证',
  i18nKey_2552: '法定代表人',
  i18nKey_2553: '发生位置',
  i18nKey_2554: '发生数量',
  i18nKey_2555: '发生时间',
  i18nKey_2556: '发行数量(个)',
  i18nKey_2557: '发行积分',
  i18nKey_2558: '发行',
  i18nKey_2559: '二维码已失效',
  i18nKey_2560: '二维码通行',
  i18nKey_2561: '二维码刷新时间必须在1至1439分钟之间',
  i18nKey_2562: '二维码刷新时间',
  i18nKey_2563: '二维码',
  i18nKey_2564: '二亮一循环亮',
  i18nKey_2565: '二类',
  i18nKey_2566: '二级部门',
  i18nKey_2567: '二级',
  i18nKey_2568: '二',
  i18nKey_2569: '儿童房',
  i18nKey_2570: '额定容量(kWh)',
  i18nKey_2571: '额定功率(kW)',
  i18nKey_2572: '吨',
  i18nKey_2573: '兑换',
  i18nKey_2574: '对象名称',
  i18nKey_2575: '对外型号只能为50个字符以内的中文、字母及下划线',
  i18nKey_2576: '对外型号不能为空',
  i18nKey_2577: '对外型号',
  i18nKey_2578: '对不起，您暂无任何权限，请联系管理员',
  i18nKey_2579: '断路器配置',
  i18nKey_2580: '断路器开关时间配置规则',
  i18nKey_2581: '短信',
  i18nKey_2582: '端口号',
  i18nKey_2583: '读数时间点',
  i18nKey_2584: '读数(kWh)',
  i18nKey_2585: '读数',
  i18nKey_2586: '读取方案',
  i18nKey_2587: '斗士身后无退路,勇气前方无险途。',
  i18nKey_2588: '冻结',
  i18nKey_2589: '动态密码',
  i18nKey_2590: '动力用电',
  i18nKey_2591: '动画加载失败',
  i18nKey_2592: '东北',
  i18nKey_2593: '订单状态',
  i18nKey_2594: '订单支付状态',
  i18nKey_2595: '订单时间范围',
  i18nKey_2596: '订单金额',
  i18nKey_2597: '订单编号/空间名称',
  i18nKey_2598: '订单编号/会议室名称',
  i18nKey_2599: '订单编号',
  i18nKey_2600: '订餐营收',
  i18nKey_2601: '店铺销量Top5',
  i18nKey_2602: '电子表',
  i18nKey_2603: '电梯总数',
  i18nKey_2604: '电梯运行记录',
  i18nKey_2605: '电梯信息',
  i18nKey_2606: '电梯图片',
  i18nKey_2607: '电梯统计',
  i18nKey_2608: '电梯名称：',
  i18nKey_2609: '电梯名称',
  i18nKey_2610: '电梯类型：',
  i18nKey_2611: '电梯类型',
  i18nKey_2612: '电梯地址',
  i18nKey_2613: '电梯ID：',
  i18nKey_2614: '电梯',
  i18nKey_2615: '电损变压器总耗损(kWh)',
  i18nKey_2616: '电损',
  i18nKey_2617: '电器一级分类',
  i18nKey_2618: '电能耗（kWh）',
  i18nKey_2619: '电缆平方数',
  i18nKey_2620: '电话',
  i18nKey_2621: '电费已出账单金额累计',
  i18nKey_2622: '电费欠费商户',
  i18nKey_2623: '电费充值未抵扣金额',
  i18nKey_2624: '电费充值金额累计',
  i18nKey_2625: '电池类型',
  i18nKey_2626: '电表预警阈值：',
  i18nKey_2627: '电表序号',
  i18nKey_2628: '电表名称不能为空',
  i18nKey_2629: '电表名称',
  i18nKey_2630: '电表分项维护成功！',
  i18nKey_2631: '电表度数详细记录',
  i18nKey_2632: '电表编号或者分项占比不能为空',
  i18nKey_2633: '电表编号',
  i18nKey_2634: '点位类型',
  i18nKey_2635: '点击重试',
  i18nKey_2636: '点击停止时才允许修改，点击启用后策略半小时内生效',
  i18nKey_2637: '点击查看',
  i18nKey_2638: '第三方支付服务费(元)',
  i18nKey_2639: '第三方用户',
  i18nKey_2640: '第三方访客',
  i18nKey_2641: '第三方ID',
  i18nKey_2642: '第....周',
  i18nKey_2643: '第$周',
  i18nKey_2644: '第$个策略中亮灯规则$的开启时间不能为空',
  i18nKey_2645: '第$个策略的生效时间不能为空',
  i18nKey_2646: '地址经纬度输入需用英文逗号分隔,且不能大于20个字符',
  i18nKey_2647: '地址经纬度',
  i18nKey_2648: '地址管理',
  i18nKey_2649: '地址不支持特殊字符（允许汉字、字母和数字）',
  i18nKey_2650: '低电压告警',
  i18nKey_2651: '等于',
  i18nKey_2652: '等级',
  i18nKey_2653: '登记时间',
  i18nKey_2654: '灯控开关',
  i18nKey_2655: '灯具数量',
  i18nKey_2656: '灯感应开关',
  i18nKey_2657: '道闸二维码读头',
  i18nKey_2658: '道闸PAD',
  i18nKey_2659: '到账情况',
  i18nKey_2660: '到账金额',
  i18nKey_2661: '到访客流',
  i18nKey_2662: '导入消防分区',
  i18nKey_2663: '导入失败，第$行数据有错误，请检查，稍后再次提交导入',
  i18nKey_2664: '导出条件',
  i18nKey_2665: '导出失败，支持最大导出数据10000条',
  i18nKey_2666: '导出失败，没有可以导出的数据',
  i18nKey_2667: '导出失败，当前页面无门禁记录！',
  i18nKey_2668: '导出失败，当前页面无订单记录！',
  i18nKey_2669: '导出失败，当前页面无乘车记录！',
  i18nKey_2670: '导出失败，当前页面没有水电缴费记录',
  i18nKey_2671: '导出失败，当前页面没有访客记录',
  i18nKey_2672: '导出失败',
  i18nKey_2673: '导出轨迹成功',
  i18nKey_2674: '当月套餐收费',
  i18nKey_2675: '当月收入分析',
  i18nKey_2676: '当月能耗(kWh)',
  i18nKey_2677: '当月公区能耗(kWh)',
  i18nKey_2678: '当月报警数（次）',
  i18nKey_2679: '当月',
  i18nKey_2680: '当天',
  i18nKey_2681: '当日新增告警',
  i18nKey_2682: '当日能耗(kWh)',
  i18nKey_2683: '当日临停收费',
  i18nKey_2684: '当日公区能耗(kWh)',
  i18nKey_2685: '当日打卡人数',
  i18nKey_2686: '当日',
  i18nKey_2687: '当前状态',
  i18nKey_2688: '当前暂无节目，请先设置节目！',
  i18nKey_2689: '当前运行参数',
  i18nKey_2690: '当前移动到',
  i18nKey_2691: '当前页面无订单记录，不需要执行导出操作！',
  i18nKey_2692: '当前页面为',
  i18nKey_2693: '当前位置没有房间,请选择房间!',
  i18nKey_2694: '当前未添加审批人，请添加审批人',
  i18nKey_2695: '当前数据资源',
  i18nKey_2696: '当前时间',
  i18nKey_2697: '当前浏览器不支持websocket',
  i18nKey_2698: '当前功率',
  i18nKey_2699: '当前服务资源',
  i18nKey_2700: '当前导入文件校验有误，请在本地修改后重新导入！',
  i18nKey_2701: '当前车位使用率',
  i18nKey_2702: '当前场内',
  i18nKey_2703: '当前操作资源',
  i18nKey_2704: '当前表底',
  i18nKey_2705: '当前Menu菜单资源',
  i18nKey_2706: '当年',
  i18nKey_2707: '单元：',
  i18nKey_2708: '单元',
  i18nKey_2709: '单位每平方米用能',
  i18nKey_2710: '单位：元',
  i18nKey_2711: '单位：人',
  i18nKey_2712: '单位：次',
  i18nKey_2713: '单位:kWh',
  i18nKey_2714: '单位: 小时',
  i18nKey_2715: '单位: 份',
  i18nKey_2716: '单位: 分钟',
  i18nKey_2717: '单价/次数',
  i18nKey_2718: '单价(元/小时)',
  i18nKey_2719: '单个轮巡时间',
  i18nKey_2720: '单次停留最长时长及日期',
  i18nKey_2721: '单次扣费',
  i18nKey_2722: '待支付',
  i18nKey_2723: '待接收',
  i18nKey_2724: '待机亮度',
  i18nKey_2725: '待处理告警数',
  i18nKey_2726: '待处理',
  i18nKey_2727: '待操作',
  i18nKey_2728: '待安装',
  i18nKey_2729: '代表异常',
  i18nKey_2730: '代表数据异常',
  i18nKey_2731: '大于或等于',
  i18nKey_2732: '大于400',
  i18nKey_2733: '大于2',
  i18nKey_2734: '大于',
  i18nKey_2735: '大企业',
  i18nKey_2736: '大屏位置',
  i18nKey_2737: '大屏名称',
  i18nKey_2738: '大屏播放设置',
  i18nKey_2739: '大道当然,合伙奋斗。',
  i18nKey_2740: '打开告警',
  i18nKey_2741: '打卡一级分类',
  i18nKey_2742: '打卡失败',
  i18nKey_2743: '打卡成功',
  i18nKey_2744: '搭乘公司Top5',
  i18nKey_2745: '从$到$，该故障重复发生了1次，累积时长$小时，可造成全年浪费',
  i18nKey_2746: '次卧',
  i18nKey_2747: '此操作将永久删除该文件，是否继续？',
  i18nKey_2748: '创建时间',
  i18nKey_2749: '创建人',
  i18nKey_2750: '创建模式选择',
  i18nKey_2751: '创建空间层级应至少到楼栋！',
  i18nKey_2752: '创建积分',
  i18nKey_2753: '创建房间',
  i18nKey_2754: '创建场景',
  i18nKey_2755: '创建',
  i18nKey_2756: '触发条件：',
  i18nKey_2757: '处理数量',
  i18nKey_2758: '处理时间',
  i18nKey_2759: '处理人',
  i18nKey_2760: '处理机制',
  i18nKey_2761: '储物间',
  i18nKey_2762: '储能统计',
  i18nKey_2763: '厨房',
  i18nKey_2764: '初始化时长',
  i18nKey_2765: '出现过$次违停',
  i18nKey_2766: '出入口繁忙程度',
  i18nKey_2767: '出车位时间',
  i18nKey_2768: '出场时间',
  i18nKey_2769: '出厂日期',
  i18nKey_2770: '充值类型',
  i18nKey_2771: '充值金额累计',
  i18nKey_2772: '充值公司',
  i18nKey_2773: '充值',
  i18nKey_2774: '充电总量',
  i18nKey_2775: '充电量(kWh)',
  i18nKey_2776: '乘车支付状态',
  i18nKey_2777: '乘车统计',
  i18nKey_2778: '乘车时间',
  i18nKey_2779: '城市',
  i18nKey_2780: '承租行业',
  i18nKey_2781: '承租方名称不允许为空',
  i18nKey_2782: '承租方名称',
  i18nKey_2783: '承租方行业',
  i18nKey_2784: '承租方',
  i18nKey_2785: '成功退款时间',
  i18nKey_2786: '成都',
  i18nKey_2787: '成大事不在于力量的大小,而在于能坚持多久。',
  i18nKey_2788: '车主姓名',
  i18nKey_2789: '车主类型',
  i18nKey_2790: '车主电话',
  i18nKey_2791: '车位总数',
  i18nKey_2792: '车位占用数',
  i18nKey_2793: '车位使用统计',
  i18nKey_2794: '车位使用率统计',
  i18nKey_2795: '车位查询',
  i18nKey_2796: '车牌指数',
  i18nKey_2797: '车牌和颜色超过6个选项 已为您按最优显示',
  i18nKey_2798: '车牌和品牌超过6个选项 已为您按最优显示',
  i18nKey_2799: '车牌号能不能为空',
  i18nKey_2800: '车牌号码不能为空',
  i18nKey_2801: '车牌号码',
  i18nKey_2802: '车牌超过6个选项 已为您按最优显示',
  i18nKey_2803: '车牌查询',
  i18nKey_2804: '车牌，品牌和颜色超过6个选项 已为您按最优显示',
  i18nKey_2805: '车流统计',
  i18nKey_2806: '车辆颜色',
  i18nKey_2807: '车辆限行',
  i18nKey_2808: '车辆套餐数量和停车费用',
  i18nKey_2809: '车辆套餐数量(辆)',
  i18nKey_2810: '车辆数据信息',
  i18nKey_2811: '车辆属地',
  i18nKey_2812: '车辆驶出记录',
  i18nKey_2813: '车辆色系',
  i18nKey_2814: '车辆认证',
  i18nKey_2815: '车辆品牌',
  i18nKey_2816: '车辆分析',
  i18nKey_2817: '车辆分类全路径',
  i18nKey_2818: '车辆到达提醒',
  i18nKey_2819: '车辆标签',
  i18nKey_2820: '车辆',
  i18nKey_2821: '车次',
  i18nKey_2822: '车场限制',
  i18nKey_2823: '车场位置：',
  i18nKey_2824: '车场位置',
  i18nKey_2825: '车场名称：',
  i18nKey_2826: '车场名称',
  i18nKey_2827: '车场车位统计',
  i18nKey_2828: '车场',
  i18nKey_2829: '超过$次，不能办理月卡',
  i18nKey_2830: '超过 $ 次，不能进入车场',
  i18nKey_2831: '超出2000条最大导出限制',
  i18nKey_2832: '超出10条最大导出限制',
  i18nKey_2833: '抄读成功',
  i18nKey_2834: '抄读',
  i18nKey_2835: '抄表日期和抄表读数必须同时填写',
  i18nKey_2836: '抄表日期',
  i18nKey_2837: '抄表人',
  i18nKey_2838: '抄表期间不能为空',
  i18nKey_2839: '抄表期间',
  i18nKey_2840: '抄表明细记录',
  i18nKey_2841: '抄表明细',
  i18nKey_2842: '抄表导入',
  i18nKey_2843: '场内人数',
  i18nKey_2844: '场景图标',
  i18nKey_2845: '场景锁开关：',
  i18nKey_2846: '场景数',
  i18nKey_2847: '场景名称',
  i18nKey_2848: '场景ID',
  i18nKey_2849: '厂商名称不能为空',
  i18nKey_2850: '厂商名称',
  i18nKey_2851: '厂家',
  i18nKey_2852: '常驻人员',
  i18nKey_2853: '常规',
  i18nKey_2854: '产品名',
  i18nKey_2855: '产品',
  i18nKey_2856: '拆除告警',
  i18nKey_2857: '查询注册用户请输入完整姓名或手机号',
  i18nKey_2858: '查询未生效个数接口数据有误，请稍后再试',
  i18nKey_2859: '查询时间范围请限定在90天内',
  i18nKey_2860: '查询列表失败',
  i18nKey_2861: '查看折后价',
  i18nKey_2862: '查看预警阈值',
  i18nKey_2863: '查看限时折扣',
  i18nKey_2864: '查看图片',
  i18nKey_2865: '查看视频',
  i18nKey_2866: '查看摄像头',
  i18nKey_2867: '查看设备',
  i18nKey_2868: '查看轨迹',
  i18nKey_2869: '查看乘车班次',
  i18nKey_2870: '查看',
  i18nKey_2871: '策略名称不能为空',
  i18nKey_2872: '策略名称不能含有特殊字符',
  i18nKey_2873: '策略名称不能超过20个字符',
  i18nKey_2874: '策略名称',
  i18nKey_2875: '测量温度',
  i18nKey_2876: '测量时间',
  i18nKey_2877: '厕所',
  i18nKey_2878: '操作者',
  i18nKey_2879: '操作失败，请稍后再试',
  i18nKey_2880: '操作失败',
  i18nKey_2881: '操作建议',
  i18nKey_2882: '餐厅',
  i18nKey_2883: '参数',
  i18nKey_2884: '参考数据期间',
  i18nKey_2885: '菜品销量Top5',
  i18nKey_2886: '采集频率',
  i18nKey_2887: '部门：',
  i18nKey_2888: '布控状态',
  i18nKey_2889: '布控时间不能为空',
  i18nKey_2890: '布控时间必须大于等于当前时间',
  i18nKey_2891: '布控时段',
  i18nKey_2892: '布控摄像头',
  i18nKey_2893: '布控匹配',
  i18nKey_2894: '布控',
  i18nKey_2895: '不重复',
  i18nKey_2896: '不支持输入特殊字符，仅支持中英文数字',
  i18nKey_2897: '不限期限',
  i18nKey_2898: '不限次数',
  i18nKey_2899: '不确定告警',
  i18nKey_2900: '不能更改层级，当前层级为',
  i18nKey_2901: '不能导出空数据',
  i18nKey_2902: '不能导出超过10000条数据',
  i18nKey_2903: '不可移动',
  i18nKey_2904: '不可添加子级',
  i18nKey_2905: '不可删除',
  i18nKey_2906: '不可编辑',
  i18nKey_2907: '不等于',
  i18nKey_2908: '补录期间',
  i18nKey_2909: '补录读数',
  i18nKey_2910: '泊寓健康打卡_',
  i18nKey_2911: '泊梯中',
  i18nKey_2912: '播放设置：',
  i18nKey_2913: '播放窗口替换模式',
  i18nKey_2914: '播放',
  i18nKey_2915: '别墅',
  i18nKey_2916: '表格模板.xlsx',
  i18nKey_2917: '表底',
  i18nKey_2918: '标签筛选',
  i18nKey_2919: '变压器容量(kVA)',
  i18nKey_2920: '变更时间',
  i18nKey_2921: '编辑预警阈值',
  i18nKey_2922: '编辑',
  i18nKey_2923: '编号',
  i18nKey_2924: '必须为整数',
  i18nKey_2925: '必须为数字或两位有效小数！',
  i18nKey_2926: '本周',
  i18nKey_2927: '本月实际电能耗（KWh）',
  i18nKey_2928: '本月累计用能',
  i18nKey_2929: '本月客流概况',
  i18nKey_2930: '本月会议室预订',
  i18nKey_2931: '本月',
  i18nKey_2932: '本项用能',
  i18nKey_2933: '本期用水量（t）',
  i18nKey_2934: '本期用能',
  i18nKey_2935: '本期用电量（kWh）',
  i18nKey_2936: '本期用电量',
  i18nKey_2937: '本期未抄/已抄(个)',
  i18nKey_2938: '本期时间',
  i18nKey_2939: '本期单位平方米耗能',
  i18nKey_2940: '本期',
  i18nKey_2941: '本年',
  i18nKey_2942: '本级能耗',
  i18nKey_2943: '本地温度',
  i18nKey_2944: '本地湿度',
  i18nKey_2945: '本地上传，上传照片不超过3M',
  i18nKey_2946: '本地服务器',
  i18nKey_2947: '本地',
  i18nKey_2948: '本次还有{number}个表未录入, 是否要继续生效?',
  i18nKey_2949: '本次抄表时间不能为空',
  i18nKey_2950: '本次抄表日期',
  i18nKey_2951: '本次抄表读数用量远大于上次抄表读数用量，是否确认修改',
  i18nKey_2952: '本次抄表读数不能为空',
  i18nKey_2953: '本次抄表读数',
  i18nKey_2954: '被访公司总数(人)',
  i18nKey_2955: '倍率',
  i18nKey_2956: '备注名只能为20个字符以内的中文、字母及下划线',
  i18nKey_2957: '备注名',
  i18nKey_2958: '备用(个)',
  i18nKey_2959: '北京',
  i18nKey_2960: '报修类型',
  i18nKey_2961: '报修工单',
  i18nKey_2962: '报警',
  i18nKey_2963: '报废日期',
  i18nKey_2964: '报废',
  i18nKey_2965: '报表',
  i18nKey_2966: '保修日期',
  i18nKey_2967: '保洁',
  i18nKey_2968: '半天单价（元/4小时）',
  i18nKey_2969: '办公空间订单详情',
  i18nKey_2970: '办公电话',
  i18nKey_2971: '班次编号',
  i18nKey_2972: '班车',
  i18nKey_2973: '拜访事由：',
  i18nKey_2974: '白色',
  i18nKey_2975: '白名单',
  i18nKey_2976: '巴士订单报表_',
  i18nKey_2977: '巴士乘车记录报表',
  i18nKey_2978: '巴士班次',
  i18nKey_2979: '按周',
  i18nKey_2980: '按月',
  i18nKey_2981: '按相似度',
  i18nKey_2982: '按时间',
  i18nKey_2983: '按时',
  i18nKey_2984: '按日',
  i18nKey_2985: '按钮资源',
  i18nKey_2986: '按年',
  i18nKey_2987: '按分项查看',
  i18nKey_2988: '安装中',
  i18nKey_2989: '安装位置',
  i18nKey_2990: '安装时间',
  i18nKey_2991: '安装工',
  i18nKey_2992: '安装RFID资产数',
  i18nKey_2993: '安全事件趋势',
  i18nKey_2994: '安防态势',
  i18nKey_2995: '安防事件类型占比',
  i18nKey_2996: '安保人员',
  i18nKey_2997: 'VCM类型',
  i18nKey_2998: 'URL类型',
  i18nKey_2999: 'RFID使用率（按类型）',
  i18nKey_3000: 'RFID使用率',
  i18nKey_3001: 'RFID编号',
  i18nKey_3002: 'PID编号',
  i18nKey_3003: 'Menu资源',
  i18nKey_3004: 'MENU',
  i18nKey_3005: 'MAC地址',
  i18nKey_3006: 'KPI上限',
  i18nKey_3007: 'IT编码和业务编码只能为字母、数字和下划线的组合！',
  i18nKey_3008: 'IT编码',
  i18nKey_3009: 'IOC一级设备分类名称',
  i18nKey_3010: 'IOC一级设备分类编码',
  i18nKey_3011: 'IOC三级设备分类名称',
  i18nKey_3012: 'IOC三级设备分类编码',
  i18nKey_3013: 'IOC空间模型编码',
  i18nKey_3014: 'IOC二级设备分类名称',
  i18nKey_3015: 'IOC二级设备分类编码',
  i18nKey_3016: 'IOC(Intelligence Operation Center或智慧运营中心)服务协议',
  i18nKey_3017: 'GIS坐标类型',
  i18nKey_3018: 'GIS坐标',
  i18nKey_3019: 'GIS同步中',
  i18nKey_3020: 'GIS同步',
  i18nKey_3021: 'Echarts图表',
  i18nKey_3022: 'C相电流（A）',
  i18nKey_3023: 'B相电压（V）',
  i18nKey_3024: 'Banner图',
  i18nKey_3025: 'A相电流（A）',
  i18nKey_3026: 'AI诊断处理',
  i18nKey_3027: '9个月',
  i18nKey_3028: '8~20位字母、数字和特殊字符中至少2种的组合',
  i18nKey_3029: '8~20位数字、字母、部分特殊字符',
  i18nKey_3030: '6个月折扣',
  i18nKey_3031: '6个月',
  i18nKey_3032: '3个月折扣',
  i18nKey_3033: '3个月',
  i18nKey_3034: '1年及以上',
  i18nKey_3035: '0点读数（kWh）',
  i18nKey_3036: '0点读数(kWh)',
  i18nKey_3037: '《隐私政策》',
  i18nKey_3038: '《服务协议》',
  i18nKey_3039: '*只支持png,gif,jpg格式图片且不大于3M',
  i18nKey_3040: '(总能耗kWh)',
  i18nKey_3041: '（自动换算价格）',
  i18nKey_3042: '(元)',
  i18nKey_3043: '(数量)',
  i18nKey_3044: '(例如:P85)',
  i18nKey_3045: '(电损占比)',
  i18nKey_3046: '（必填）',
  i18nKey_3047: '$年在整个项目中，已累计为您发现了共计',
  i18nKey_3048: '$年实际值',
  i18nKey_3049: '$年目标值',
  i18nKey_3050: '$个回路开启失败',
  i18nKey_3051: '$个回路开启成功',
  i18nKey_3052: '一月',
  i18nKey_3053: '五月',
  i18nKey_3054: '四月',
  i18nKey_3055: '十月',
  i18nKey_3056: '十一月',
  i18nKey_3057: '十二月',
  i18nKey_3058: '三月',
  i18nKey_3059: '请输入合法的GIS坐标，如(20.17N, 134.53W)',
  i18nKey_3060: '七月',
  i18nKey_3061: '六月',
  i18nKey_3062: '九月',
  i18nKey_3063: '二月',
  i18nKey_3064: '八月',
  i18nKey_3065: 'GIS坐标已存在，请重新输入',
};
