export default {
  namespaced: true,
  state: {
    machineCode: ""
  },
  mutations: {
    getMachinePortrait(state, machineCode) {
      console.log(machineCode);
      state.machineCode = machineCode;
    }
  }
};
