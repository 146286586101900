export default {
  namespaced: true,
  state: {
    mapZoomInfoObj: {}
  },
  mutations: {
    setMapZoomInfoObjFunc(state, mapZoomInfoObj) {
      state.mapZoomInfoObj[mapZoomInfoObj.parkCode] = mapZoomInfoObj;
    }
  }
};
