export default {
  namespaced: true,
  state: {
    websocketStatus: false, // 页面websocket的启用
    faceTrackingInfo: null, // websocket推送的人脸追踪信息
    faceTrackingControlInfo: null // 点击人脸追踪时查询到的页面信息
  },
  mutations: {
    changeWebsocketStatus(state, data) {
      state.websocketStatus = data.websocketStatus;
    },
    getFaceTrackingInfo(state, data) {
      state.faceTrackingInfo = data.faceTrackingInfo;
    },
    getFaceTrackingControlInfo(state, data) {
      state.faceTrackingControlInfo = data.faceTrackingControlInfo;
    }
  }
};
