export default {
  namespaced: true,
  state: {
    perimeterInfo: {}
  },
  mutations: {
    perimeterInfoChange(state, data) {
      state.perimeterInfo = data;
    }
  }
};
