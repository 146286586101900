export default {
  namespaced: true,
  state: {
    countChartsArray: []
  },
  mutations: {
    getCountOfCharts(state, countChartsArray) {
      state.countChartsArray = countChartsArray;
    }
  }
};
