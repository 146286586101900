export default {
  i18nKey_1: "วันที่แก้ไขล่าสุด",
  i18nKey_2: "รายการ การอ่านมิเตอร์แบบอัตโนมัติโดยรายละเอียด",
  i18nKey_3: "ระบบย่อย",
  i18nKey_4: "ประเภทสินทรัพย์",
  i18nKey_5: "สถานะ",
  i18nKey_6: "เวลาลงทะเบียน",
  i18nKey_7: "ตั้งค่าใหม่",
  i18nKey_8: "ปกติ",
  i18nKey_9: "ชื่อบัญชี / หมายเลขโทรศัพท์มือถือที่ได้รับ",
  i18nKey_10: "ชื่อบัญชี",
  i18nKey_11: "โหมดการแสดงผล",
  i18nKey_12: "ประเภทคลาวด์",
  i18nKey_13: "ชื่อสวนสาธารณะ",
  i18nKey_14: "รหัสสวนสาธารณะ",
  i18nKey_15: "ผ่าน",
  i18nKey_16: "ยกเลิกการลบ",
  i18nKey_17: "เปิดใช้งาน",
  i18nKey_18: "ปิดการใช้งาน",
  i18nKey_19: "ทำให้ละลาย",
  i18nKey_20: "แช่แข็ง",
  i18nKey_21: "บทบาทเป็นตัวเลือกที่ไม่ได้ โปรดเลือกประเภทการแจ้งเตือนการมาถึงของ VIP หรือประเภทการแจ้งเตือนการมาถึงที่ไม่ใช่วีไอพี",
  i18nKey_22: "เลือก",
  i18nKey_23: "S/N",
  i18nKey_24: "ไม่สามารถแก้ไขได้",
  i18nKey_25: "แก้ไขการกำหนดค่าระบบคลาวด์แบบไฮบริด",
  i18nKey_26: "แก้ไขเรียบร้อยแล้ว",
  i18nKey_27: "แก้ไข",
  i18nKey_28: "ประเภทสัญญาณ",
  i18nKey_29: "ฉลากสัญญาณ",
  i18nKey_30: "การกำหนดค่าระบบคลาวด์แบบไฮบริดใหม่",
  i18nKey_31: "ใหม่",
  i18nKey_32: "รายละเอียด",
  i18nKey_33: "ดาวน์โหลดเทมเพลต",
  i18nKey_34: "การแจ้งเรื่องทรัพย์สิน",
  i18nKey_35: "ไม่มีข้อมูลที่ตรงตามเงื่อนไขการสืบค้น",
  i18nKey_36: "รูปแบบไฟล์ไม่ถูกต้อง รองรับเฉพาะไฟล์ excel เท่านั้น",
  i18nKey_37: "ข้อความ",
  i18nKey_38: "ตำแหน่ง",
  i18nKey_39: "ไม่ผ่าน",
  i18nKey_40: "ไม่ได้เปิดใช้งาน",
  i18nKey_41: "เครือข่ายผิดปกติ โปรดลองอีกครั้งในภายหลัง",
  i18nKey_42: "ผลักดันเป้าหมาย",
  i18nKey_43: "กดประเภทการแจ้งเตือน",
  i18nKey_44: "กดบริการ APP",
  i18nKey_45: "บทความ",
  i18nKey_46: "เพิ่มเวลา",
  i18nKey_47: "เพิ่ม",
  i18nKey_48: "เคล็ดลับ",
  i18nKey_49: "การส่งล้มเหลว",
  i18nKey_50: "ส่งเรียบร้อยแล้ว",
  i18nKey_51: "ส่ง",
  i18nKey_52: "ค้นหา",
  i18nKey_53: "โซนอันตรายต้องประกอบด้วยตัวเลขและเลขคณิตสี่ตัวเท่านั้น",
  i18nKey_54: "คลาวด์ส่วนตัว",
  i18nKey_55: "หมายเหตุ: 'ตัวหลัก' ระดับแรกใช้ข้อมูลของ 'ตู้วัดแรงดันไฟฟ้าสูง' ส่วนระดับล่าง: ใช้ข้อมูลของ 'ตู้เต้าเสียบไฟฟ้าแรงสูง' ที่มีอัตราการสูญเสียพลังงานเกิน หากมากกว่า 5% ถือว่าผิดปกติ",
  i18nKey_56: "ข้อมูล",
  i18nKey_57: "การอนุญาต",
  i18nKey_58: "ชื่อบัญชีรวบรวม",
  i18nKey_59: "รูปแบบหมายเลขโทรศัพท์ไม่ถูกต้อง",
  i18nKey_60: "รวบรวมหมายเลขโทรศัพท์",
  i18nKey_61: "ที่เหลืออยู่",
  i18nKey_62: "หมวดย่อยสถานที่",
  i18nKey_63: "ระบบสถานที่",
  i18nKey_64: "ชื่อสถานที่ต้องไม่ว่างเปล่า",
  i18nKey_65: "ชื่อสถานที่",
  i18nKey_66: "การปรับเปลี่ยนการจัดประเภทสถานที่",
  i18nKey_67: "ชื่อหมวดสถานที่",
  i18nKey_68: "รหัสการจัดประเภทสถานที่",
  i18nKey_69: "ระดับสถานที่",
  i18nKey_70: "นามสมมติของสถานที่ต้องไม่ว่างเปล่า",
  i18nKey_71: "หมายเลขสถานที่",
  i18nKey_72: "รายละเอียดการจำแนกประเภทอุปกรณ์",
  i18nKey_73: "พ่อค้า",
  i18nKey_74: "ลบไม่สำเร็จ!",
  i18nKey_75: "ลบสำเร็จแล้ว",
  i18nKey_76: "ลบ",
  i18nKey_77: "แน่ใจไหมว่าต้องการยกเลิก",
  i18nKey_78: "ยืนยันการลบ?",
  i18nKey_79: "กำหนด",
  i18nKey_80: "ทั้งหมด",
  i18nKey_81: "ผู้มีอำนาจ",
  i18nKey_82: "ยกเลิก",
  i18nKey_83: "บัญชี Blockchain",
  i18nKey_84: "โปรดเลือกประเภทการเตือนอย่างน้อยหนึ่งประเภท",
  i18nKey_85: "กรุณาเลือกสถานะ",
  i18nKey_86: "โปรดเลือกช่วงเวลาการลงทะเบียน",
  i18nKey_87: "กรุณาเลือกสวนสาธารณะ",
  i18nKey_88: "กรุณาเลือกระบบการสมัคร",
  i18nKey_89: "โปรดเลือกประเภทสถานที่ที่จะแก้ไข",
  i18nKey_90: "โปรดเลือกประเภทสัญญาณ",
  i18nKey_91: "โปรดเลือกสถานที่",
  i18nKey_92: "โปรดเลือกเป้าหมายการผลักดัน",
  i18nKey_93: "โปรดเลือกระบบสถานที่",
  i18nKey_94: "โปรดเลือกหมวดหมู่สถานที่",
  i18nKey_95: "โปรดเลือกการอนุญาต",
  i18nKey_96: "กรุณาเลือกประเภท",
  i18nKey_97: "โปรดเลือกประเภทหัวข้อ",
  i18nKey_98: "โปรดเลือกประเภทการเตือนภัย",
  i18nKey_99: "โปรดเลือกประเภท",
  i18nKey_100: "โปรดเลือกช่วงเวลาเผยแพร่",
  i18nKey_101: "กรุณากรอกชื่อบัญชีของคุณ / หมายเลขโทรศัพท์ที่รับ",
  i18nKey_102: "กรุณากรอกชื่อสวนสาธารณะ",
  i18nKey_103: "กรุณากรอกหมายเลขโทรศัพท์ของคุณ เพื่อสะดวกในการค้นหา",
  i18nKey_104: "กรุณากรอกชื่อบัญชีเครดิต",
  i18nKey_105: "กรุณากรอกหมายเลขโทรศัพท์มือถือของคุณ",
  i18nKey_106: "กรุณากรอกชื่อสถานที่",
  i18nKey_107: "กรุณากรอกหมายเลขสถานที่",
  i18nKey_108: "กรุณากรอกชื่อผู้ติดต่อ",
  i18nKey_109: "กรุณากรอกหมายเลขโทรศัพท์ติดต่อ",
  i18nKey_110: "กรุณากรอกผู้ติดต่อ / หมายเลขโทรศัพท์",
  i18nKey_111: "กรุณาใส่แต้มลงในกระเป๋าเงิน",
  i18nKey_112: "กรุณากรอกชื่อบริษัท / ที่อยู่",
  i18nKey_113: "กรุณากรอกชื่อบริษัท",
  i18nKey_114: "โปรดกรอกที่อยู่บริษัท",
  i18nKey_115: "กรุณากรอกรหัสผ่านเข้าสู่ระบบบัญชีปัจจุบัน",
  i18nKey_116: "กรุณาใส่หมายเหตุ",
  i18nKey_117: "กรุณาคลิกตกลงดาวน์โหลดไฟล์แก้ไขและนำเข้าอีกครั้ง",
  i18nKey_118: "กรุณากรอกข้อมูลตามความจำเป็น",
  i18nKey_119: "เปิดใช้งานโลโก้",
  i18nKey_120: "เปิด/ปิด",
  i18nKey_121: "เปิดใช้งาน",
  i18nKey_122: "ประเภทเป้าหมาย",
  i18nKey_123: "ชื่อผู้ติดต่อ",
  i18nKey_124: "รูปแบบหมายเลขโทรศัพท์ใช้สำหรับติดต่อไม่ถูกต้อง",
  i18nKey_125: "หมายเลขโทรศัพท์ติดต่อ",
  i18nKey_126: "เบอร์ติดต่อ / มือถือ",
  i18nKey_127: "ชนิดของ",
  i18nKey_128: "บัตร",
  i18nKey_129: "ไม่สามารถกรอกลองจิจูดและละติจูดได้",
  i18nKey_130: "ลดถอยลง",
  i18nKey_131: "ประเภทบทบาท",
  i18nKey_132: "ตัวละคร",
  i18nKey_133: "แต้มกระเป๋าเงิน",
  i18nKey_134: "ประเภทคะแนน",
  i18nKey_135: "รางวัลคะแนน",
  i18nKey_136: "มูลค่าคะแนน (หยวน / นาที)",
  i18nKey_137: "คลาวด์สาธารณะ",
  i18nKey_138: "ข้อมูล บริษัท",
  i18nKey_139: "ชื่อ บริษัท / ที่อยู่",
  i18nKey_140: "ชื่อ บริษัท",
  i18nKey_141: "ที่อยู่ บริษัท",
  i18nKey_142: "ส่วนตัว",
  i18nKey_143: "ชื่อปลุกต้องประกอบด้วยตัวเลขและภาษาจีนเท่านั้น",
  i18nKey_144: "ชื่อการเตือนต้องไม่ว่างเปล่า",
  i18nKey_145: "คำอธิบายการเตือนต้องประกอบด้วยตัวอักษรตัวเลขและภาษาจีนเท่านั้น",
  i18nKey_146: "คำอธิบายการเตือนต้องไม่ว่างเปล่า",
  i18nKey_147: "ประเภทการเตือนภัย",
  i18nKey_148: "ป้ายเตือนต้องประกอบด้วยตัวอักษรและตัวเลขเท่านั้น",
  i18nKey_149: "ป้ายกำกับการเตือนต้องไม่ว่างเปล่า",
  i18nKey_150: "ขนาดการระบุรายการ",
  i18nKey_151: "ดัชนีการวิเคราะห์",
  i18nKey_152: "ชื่อเวที",
  i18nKey_153: "ปริมาณที่ส่งออกทั้งหมด",
  i18nKey_154: "มูลค่ารวมของการส่งออก (หยวน)",
  i18nKey_155: "เผยแพร่เวลา",
  i18nKey_156: "ผู้ส่งออก",
  i18nKey_157: "บันทึกการเปิดตัว",
  i18nKey_158: "แช่แข็ง",
  i18nKey_159: "การนำเข้าล้มเหลวรองรับเฉพาะไฟล์ excel เวอร์ชันใหม่เท่านั้น",
  i18nKey_160: "การนำเข้าล้มเหลว",
  i18nKey_161: "นำเข้าสำเร็จ",
  i18nKey_162: "นำเข้า",
  i18nKey_163: "การส่งออกล้มเหลว รองรับข้อมูลการส่งออกสูงสุด 10,000",
  i18nKey_164: "ส่งออก",
  i18nKey_165: "รอดำเนินการ",
  i18nKey_166: "ข้อผิดพลาด",
  i18nKey_167: "การดำเนินการนี้จะลบไฟล์อย่างถาวร คุณต้องการดำเนินการต่อหรือไม่",
  i18nKey_168: "การสร้างล้มเหลว",
  i18nKey_169: "สร้างการจัดประเภทสถานที่",
  i18nKey_170: "สร้างสำเร็จแล้ว",
  i18nKey_171: "นำเข้าสำเร็จ",
  i18nKey_172: "สอบถาม",
  i18nKey_173: "ปฏิบัติการ",
  i18nKey_174: "แก้ไข",
  i18nKey_175: "การกำหนดหมายเลข",
  i18nKey_176: "หมายเหตุ",
  i18nKey_177: "บันทึกสำเร็จแล้ว",
  i18nKey_178: "บันทึก",
  i18nKey_179: "การกำหนดค่า VCN",
  i18nKey_180: "การกำหนดค่า VCM",
  i18nKey_181: "L สามารถประกอบด้วยตัวเลขและสัญลักษณ์ทางคณิตสี่ตัวเท่านั้น",
  i18nKey_182: "L ต้องไม่ว่างเปล่า",
  i18nKey_183: "LL สามารถประกอบด้วยตัวเลขและสัญลักษณ์ทางคณิตสี่ตัวเท่านั้น",
  i18nKey_184: "H สามารถประกอบด้วยตัวเลขและสัญลักษณ์ทางคณิตสี่ตัวเท่านั้น",
  i18nKey_185: "H ต้องไม่ว่างเปล่า",
  i18nKey_186: "HH สามารถประกอบด้วยตัวเลขและสัญลักษณ์ทางคณิตสี่ตัวเท่านั้น",
  i18nKey_187: "บันทึกเมื่อวานนี้",
  i18nKey_188: "ถูกจำหน่ายไปทั้งหมดเมื่อวานนี้",
  i18nKey_189: "ยอดเรียกเก็บของเมื่อวาน",
  i18nKey_190: "เมื่อวานนี้",
  i18nKey_191: "พลังงานที่ใช้ไปเมื่อวานนี้",
  i18nKey_192: "การใช้พลังงานต่อตารางเมตรในส่วนของเมื่อวาน",
  i18nKey_193: "ลูกค้าคนเดิมเหมือนเมื่อวาน",
  i18nKey_194: "เมื่อวานนี้",
  i18nKey_195: "เวลาเข้าชมเร็วที่สุด",
  i18nKey_196: "เวลาดำเนินการล่าสุด",
  i18nKey_197: "สถานการณ์การดำเนินการล่าสุด",
  i18nKey_198: "ขั้นต่ำ",
  i18nKey_199: "เวลาออกจากสวนสาธารณะล่าสุด",
  i18nKey_200: "ออกจากสวนสาธารณะที่ล่าสุด",
  i18nKey_201: "ค่าเช่าขั้นต่ำ (ชั่วโมง)",
  i18nKey_202: "ค่าเช่าขั้นต่ำ",
  i18nKey_203: "อาทิตย์ที่แล้ว",
  i18nKey_204: "เดือนที่แล้ว",
  i18nKey_205: "เวลาจอดรถล่าสุด (ชั่วโมง)",
  i18nKey_206: "ช่วงเวลาเปิดล่าสุด",
  i18nKey_207: "เวลาชำระเงินล่าสุด",
  i18nKey_208: "เวลาหยุดล่าสุด",
  i18nKey_209: "ในช่วงสามเดือนล่าสุด",
  i18nKey_210: "วันที่ปรับปรุงข้อมูลล่าสุด: 07 ธันวาคม 2562",
  i18nKey_211: "ครั้งล่าสุด",
  i18nKey_212: "การเยี่ยมชมครั้งล่าสุด",
  i18nKey_213: "ไม่มีบันทึกการหยุดสำหรับพื้นที่จอดรถในช่วง 30 วันที่ผ่านมา",
  i18nKey_214: "เวลาอัปเดตล่าสุด",
  i18nKey_215: "เวลาสื่อสารล่าสุด",
  i18nKey_216: "เวลาอัปโหลดล่าสุด",
  i18nKey_217: "เวลาอัปเดตล่าสุด",
  i18nKey_218: "สามารถเลือกกล้องได้สูงสุด 4 ตัวเท่านั้น",
  i18nKey_219: "คุณสามารถเลือกได้ 5 เท่านั้น",
  i18nKey_220: "คุณสามารถเพิ่มข้อความได้สูงสุด 5 ข้อความ",
  i18nKey_221: "อัปโหลดได้สูงสุด 4 ภาพ",
  i18nKey_222: "อัพโหลดได้ 1 ภาพเท่านั้น!",
  i18nKey_223: "เพิ่มผู้อนุมัติได้สูงสุด 5 คน",
  i18nKey_224: "มากกว่าห้าคน",
  i18nKey_225: "สามารถสร้างได้สูงสุด 4 กลยุทธ์",
  i18nKey_226: "เชื่อมต่อกล้องได้สูงสุด 3 ตัว",
  i18nKey_227: "มากถึง 30 คำ",
  i18nKey_228: "สูงสุด",
  i18nKey_229: "จำนวนการเตือนสูงสุดต้องไม่น้อยกว่าหรือเท่ากับจำนวนการเตือนขั้นต่ำ",
  i18nKey_230: "รายละเอียดการเช่า",
  i18nKey_231: "ระบุการเช่าวันที่เริ่มต้นจนถึงสิ้นสุด",
  i18nKey_232: "วันที่เริ่มต้นการเช่า",
  i18nKey_233: "วันที่สิ้นสุดสัญญาเช่า",
  i18nKey_234: "ปริมาณการใช้น้ำของผู้เช่า",
  i18nKey_235: "การใช้พลังงานของผู้เช่า",
  i18nKey_236: "ผู้เช่า",
  i18nKey_237: "ไฟฟ้าทั้งหมด",
  i18nKey_238: "การใช้พลังงานน้ำทั้งหมด (t)",
  i18nKey_239: "ผลรวม",
  i18nKey_240: "จำนวนเหตุการณ์ทั้งหมด",
  i18nKey_241: "สัดส่วนการใช้พลังงานทั้งหมด",
  i18nKey_242: "เป้าหมายในการใช้พลังงานทั้งหมด",
  i18nKey_243: "การใช้พลังงานทั้งหมด (เดือนที่แล้ว)",
  i18nKey_244: "การใช้พลังงานทั้งหมด (ปีนี้)",
  i18nKey_245: "การใช้พลังงานทั้งหมด",
  i18nKey_246: "สวิตช์แอมแปร์ทั้งหมด",
  i18nKey_247: "สวิตช์หลัก:",
  i18nKey_248: "เงินออมทั้งหมด",
  i18nKey_249: "มูลค่ารวม (หยวน)",
  i18nKey_250: "ปริมาณรวม",
  i18nKey_251: "รวม",
  i18nKey_252: "ตารางค่าธรรมเนียมและการชำระทั้งหมด",
  i18nKey_253: "ตารางรวม ",
  i18nKey_254: "สีน้ำตาล",
  i18nKey_255: "การหลั่งไหลของผู้โดยสารที่กินวงกว้าง",
  i18nKey_256: "จำนวนอักษรต้องไม่เกิน 10 ตัว!",
  i18nKey_257: "สร้างขึ้นเอง",
  i18nKey_258: "การดำเนินการด้านกลยุทธ์โดยอัตโนมัติ",
  i18nKey_259: "บันทึกซ้ำโดยอัตโนมัติ",
  i18nKey_260: "คุณสามารถเพิ่มช่วงเวลาที่กำหนดเองได้สูงสุด 3 ช่วง",
  i18nKey_261: "คุณสามารถเพิ่มช่วงจำนวนที่กำหนดเองได้สูงสุด 3 ช่วง",
  i18nKey_262: "คุณสามารถเพิ่มเวลาที่กำหนดเองได้สูงสุด 3 ครั้งเท่านั้น",
  i18nKey_263: "ปรับแต่ง",
  i18nKey_264: "สีม่วง",
  i18nKey_265: "การใช้พลังงานในพื้นที่ย่อย",
  i18nKey_266: "การใช้พลังงานของรายการย่อย",
  i18nKey_267: "ชื่อระดับย่อย",
  i18nKey_268: "มูลค่าทรัพย์สินเดิมทั้งหมด (หยวน)",
  i18nKey_269: "สถิติทางสินทรัพย์ทั้งหมด (โดยสวนสาธารณะ)",
  i18nKey_270: "สถิติทางสินทรัพย์รวม (ตามประเภท)",
  i18nKey_271: "สินทรัพย์รวม",
  i18nKey_272: "สถิติมูลค่าเดิมของสินทรัพย์ (โดยสวนสาธารณะ)",
  i18nKey_273: "สถิติมูลค่าเดิมของสินทรัพย์ (ตามประเภท)",
  i18nKey_274: "มูลค่าเดิมของสินทรัพย์ (หยวน)",
  i18nKey_275: "มูลค่าเดิมของสินทรัพย์ (USD)",
  i18nKey_276: "รายละเอียดสินทรัพย์",
  i18nKey_277: "ข้อมูลตำแหน่งของสินทรัพย์",
  i18nKey_278: "สถิติสินทรัพย์",
  i18nKey_279: "คำอธิบายเนื้อหา",
  i18nKey_280: "ประเภทสินทรัพย์",
  i18nKey_281: "ข้อมูลพื้นฐานของสินทรัพย์",
  i18nKey_282: "วิถีทางของสินทรัพย์",
  i18nKey_283: "หมายเลขสินทรัพย์",
  i18nKey_284: "ข้อมูล RFID ของสินทรัพย์",
  i18nKey_285: "สายด่วนสนับสนุน",
  i18nKey_286: "กำลังติดตาม",
  i18nKey_287: "งานติดตาม",
  i18nKey_288: "ติดตาม",
  i18nKey_289: "สถานะต้องไม่ว่างเปล่า",
  i18nKey_290: "สถานะ:",
  i18nKey_291: "สถานะ",
  i18nKey_292: "คำเตือนการถ่ายโอนสถานที่ล้มเหลว",
  i18nKey_293: "คำเตือนการถ่ายโอนสถานที่สำเร็จ",
  i18nKey_294: "ถ่ายโอนเพื่อคืนเงิน",
  i18nKey_295: "สถานการณ์การแปลง",
  i18nKey_296: "อัตราการแปลง",
  i18nKey_297: "การส่งต่อล้มเหลว",
  i18nKey_298: "ส่งต่อสำเร็จ",
  i18nKey_299: "ถ่ายภาพ",
  i18nKey_300: "กดกล้องถ่ายรูป",
  i18nKey_301: "กราฟแสดงค่าของสถิติความถี่",
  i18nKey_302: "หมายเหตุ: ปริมาณการใช้ไฟฟ้าจริงในวันใดวันหนึ่งเทียบกับปริมาณการใช้ไฟฟ้าเฉลี่ยของสัปดาห์ที่แล้วมีการเพิ่มขึ้นหรือลดลงจนถึงเกณฑ์ที่ตั้งไว้ซึ่งหมายถึงการใช้ไฟฟ้าที่ผิดปกติ",
  i18nKey_303: "เวลาลงทะเบียน",
  i18nKey_304: "การลงทะเบียนไม่สำเร็จ",
  i18nKey_305: "หมายเหตุ: แน่ใจไหมว่าต้องการลบ หารการดำเนินการแล้วจะไม่สามรถเรียกคืนได้",
  i18nKey_306: "หมายเหตุ: แน่ใจไหมว่าต้องการลบ การดำเนินการนี้จะไม่สามารถกู้คืนได้!",
  i18nKey_307: "หมายเหตุ: ขอแนะนำให้ป้อนค่าเป้าหมายของการใช้พลังงานทั้งหมดและการใช้พลังงานในพื้นที่สาธารณะ ระบบจึงจะสามารถคำนวณค่าอื่น ๆ โดยอัตโนมัติได้หลังจากสร้างค่าเป้าหมายรายเดือนแล้ว สามารถแก้ไขได้โดยเลื่อนเมาส์",
  i18nKey_308: "ห้องนอนใหญ่",
  i18nKey_309: "ธีม",
  i18nKey_310: "ชั้นหลัก",
  i18nKey_311: "วันจันทร์",
  i18nKey_312: "วันศุกร์",
  i18nKey_313: "รายสัปดาห์",
  i18nKey_314: "วันพฤหัสบดี",
  i18nKey_315: "วันพุธ",
  i18nKey_316: "วันอาทิตย์",
  i18nKey_317: "การหลั่งไหลของผู้คนในช่วงสุดสัปดาห์",
  i18nKey_318: "วันเสาร์",
  i18nKey_319: "วันอังคาร",
  i18nKey_320: "สัปดาห์",
  i18nKey_321: "คำเตือนที่สำคัญ",
  i18nKey_322: "ระดับความสำคัญ",
  i18nKey_323: "การมอบหมายใหม่",
  i18nKey_324: "ลงทะเบียนใหม่",
  i18nKey_325: "ทำให้ใช้งานได้อีกครั้ง",
  i18nKey_326: "ประเภทการทำซ้ำ",
  i18nKey_327: "ทำวิธีการซ้ำ",
  i18nKey_328: "มลภาวะรุนแรง",
  i18nKey_329: "พื้นที่สำคัญ",
  i18nKey_330: "เหตุการณ์สำคัญ",
  i18nKey_331: "รายใหญ่",
  i18nKey_332: "สายพันธุ์",
  i18nKey_333: "ปัจจัย",
  i18nKey_334: "จำนวนผู้เข้าสำเร็จ",
  i18nKey_335: "จุดสิ้นสุดต้องไม่เหมือนกับจุดเริ่มต้น",
  i18nKey_336: "จุดสิ้นสุดต้องไม่ว่างเปล่า",
  i18nKey_337: "จบ",
  i18nKey_338: "Zhongliang",
  i18nKey_339: "ประเทศจีน",
  i18nKey_340: "มลพิษปานกลาง",
  i18nKey_341: "หมายเลขมาตรวัดน้ำอัจฉริยะ",
  i18nKey_342: "ระบบอัจฉริยะ",
  i18nKey_343: "หมายเลขมิเตอร์อัจฉริยะ",
  i18nKey_344: "รหัสตารางอัจฉริยะต้องไม่ว่างเปล่า",
  i18nKey_345: "การเข้ารหัสตารางอัจฉริยะ",
  i18nKey_346: "ผู้ผลิตต้องกรอกชื่อด้วยภาษาจีน ภาษาอังกฤษ ตัวเลขและ _ เท่านั้น",
  i18nKey_347: "ความยาวของชื่อผู้ผลิตต้องไม่เกิน 50 อักษร",
  i18nKey_348: "ผู้ผลิต",
  i18nKey_349: "เลือกอุปกรณ์อย่างน้อยหนึ่งเครื่องเป็นตัวดำเนินการ",
  i18nKey_350: "ตรวจสอบอย่างน้อยหนึ่งช่วงเวลา",
  i18nKey_351: "ระบุวันที่",
  i18nKey_352: "รอบการออกคำสั่ง",
  i18nKey_353: "ระบุจำนวนวัน",
  i18nKey_354: "เวลาที่กำหนด",
  i18nKey_355: "บริษัทที่กำหนด",
  i18nKey_356: "เวลาที่กำหนด",
  i18nKey_357: "ชื่อตัวบ่งชี้",
  i18nKey_358: "ตัวบ่งชี้ในการวิเคราะห์",
  i18nKey_359: "มีการป้อนอักษรพิเศษในข้อมูลตำแหน่ง",
  i18nKey_360: "ข้อมูลงาน",
  i18nKey_361: "เวลาดำเนินการทั้งหมด (นาที)",
  i18nKey_362: "เวลาดำเนินการ",
  i18nKey_363: "ดำเนินการ:",
  i18nKey_364: "การนำไปใช้งาน (ครั้ง)",
  i18nKey_365: "ดำเนินการ",
  i18nKey_366: "รองรับการป้อนข้อมูลกรณีภาษาจีนและอังกฤษเท่านั้น",
  i18nKey_367: "รองรับเฉพาะกรณีภาษาจีนและภาษาอังกฤษ",
  i18nKey_368: "รองรับการใส่ช่องว่าง เครื่องหมายวรรคตอน การป้อนตัวเลข ภาษาจีนและภาษาอังกฤษเท่านั้น",
  i18nKey_369: "ฝืนจะไม่สามารถผ่าออกได้ หากไม่ได้ขวานที่เหมาะสม",
  i18nKey_370: "ป้อนได้เฉพาะตัวเลขบวกและค่าสูงสุดคือ 10 หลักและทศนิยม 2 ตำแหน่ง",
  i18nKey_371: "อัปโหลดรูปภาพในรูปแบบ jpg / png เท่านั้น",
  i18nKey_372: "สามารถอัพโหลดไฟล์รูปภาพ jpg, jpeg, png ได้เท่านั้น!",
  i18nKey_373: "สามารถอัปโหลดได้เฉพาะไฟล์ excel และไฟล์ที่ต่อท้ายคือ. xlsx",
  i18nKey_374: "สถิติการใช้พลังงานในแต่ละสาขา",
  i18nKey_375: "การวิเคราะห์การใช้พลังงานของสาขา",
  i18nKey_376: "การเลือกสาขา",
  i18nKey_377: "แผนภาพการไหลของวงจรสาขาในการใช้พลังงาน _",
  i18nKey_378: "จำนวนการเตือนการใช้พลังงานของสาขา (ครั้ง)",
  i18nKey_379: "เวลาเสร็จสิ้นการชำระเงิน",
  i18nKey_380: "ช่องทางการชำระเงิน",
  i18nKey_381: "เวลาเริ่มต้นการชำระเงิน",
  i18nKey_382: "จำนวนเงินที่ชำระ (หยวน)",
  i18nKey_383: "วิธีการชำระเงิน",
  i18nKey_384: "หมายเลขการชำระเงิน",
  i18nKey_385: "Alipay",
  i18nKey_386: "รายจ่าย / รายได้",
  i18nKey_387: "โปรดรอสักครู่กำลังโหลดส่วนประกอบวิดีโอ",
  i18nKey_388: "โปรดรอสักครู่ กำลังส่งออก",
  i18nKey_389: "ปกติ",
  i18nKey_390: "มาตรการวินิจฉัย (สายพันธุ์)",
  i18nKey_391: "มาตรการวินิจฉัย",
  i18nKey_392: "การวินิจฉัย",
  i18nKey_393: "แผนภูมิเส้น",
  i18nKey_394: "อัตราส่วนลดต้องอยู่ระหว่าง 0-1 ถึง 1-2 ของทศนิยมถึงสามารถจองได้",
  i18nKey_395: "ปัจจัยด้านส่วนลด",
  i18nKey_396: "การตั้งค่าส่วนลด",
  i18nKey_397: "คำอธิบายส่วนลด",
  i18nKey_398: "ส่วนลด",
  i18nKey_399: "รายละเอียดราคาลด",
  i18nKey_400: "ราคาหลังหักส่วนลด",
  i18nKey_401: "เวลาในการสร้างภาพ",
  i18nKey_402: "คำอธิบายประเภทรูปภาพ",
  i18nKey_403: "ขนาดรูปภาพ",
  i18nKey_404: "รูปภาพต้องไม่ว่างเปล่าโปรดอัปโหลดรูปภาพ",
  i18nKey_405: "URL ของรูปภาพ",
  i18nKey_406: "โหมดแสงสว่าง",
  i18nKey_407: "ไฟฟ้าสำหรับแสงสว่างและซ็อกเก็ต",
  i18nKey_408: "ออกแผนแสงสว่าง",
  i18nKey_409: "การจัดการโซลูชันแสงสว่าง",
  i18nKey_410: "เข้าสู้ระบบบัญชี",
  i18nKey_411: "หมายเลขบัญชี",
  i18nKey_412: "ปริมาณการใช้ไฟฟ้าทั้งหมดของบิลคือ $ kWh",
  i18nKey_413: "สถิติการใช้บิลตั้งแต่ต้นจนถึงวันนี้",
  i18nKey_414: "ความยาว 15 หรือ 18 อักษร",
  i18nKey_415: "การ์ด",
  i18nKey_416: "ครอบครองพื้นที่จอดรถ",
  i18nKey_417: "แฉ",
  i18nKey_418: "ความหนืด",
  i18nKey_419: "รายละเอียดบริการเสริม",
  i18nKey_420: "ผู้จัดการที่รับผิดชอบ",
  i18nKey_421: "รถไฟตอนต้น",
  i18nKey_422: "ไม่มีแผนที่อุทยาน",
  i18nKey_423: "ไม่มีบุคลากรติดตาม",
  i18nKey_424: "ไม่มีข้อมูลที่จะส่งออก",
  i18nKey_425: "เวลาระงับ",
  i18nKey_426: "หมดเวลา",
  i18nKey_427: "ไม่ได้เปิดใช้งาน",
  i18nKey_428: "เกตเวย์ออนไลน์",
  i18nKey_429: "รหัสผ่านออนไลน์",
  i18nKey_430: "เช็คอินออนไลน์",
  i18nKey_431: "ออนไลน์ (ก)",
  i18nKey_432: "ออนไลน์",
  i18nKey_433: "กำลังปฏิบัติหน้าที่",
  i18nKey_434: "หมายเลขโทรศัพท์มือถือของผู้ให้บริการต้องลงทะเบียนในแอป Yuncheng",
  i18nKey_435: "ข้อมูลการดำเนินงานและการบำรุงรักษา",
  i18nKey_436: "วิ่ง",
  i18nKey_437: "กลยุทธ์การดำเนินงาน",
  i18nKey_438: "พารามิเตอร์การทำงาน",
  i18nKey_439: "ระยะเวลาการเยี่ยมชมที่อนุญาต",
  i18nKey_440: "ระยะเวลาการเยี่ยมชมที่อนุญาต",
  i18nKey_441: "ประเภทคลาวด์",
  i18nKey_442: "การชำระเงินของ Yuncheng",
  i18nKey_443: "ข่าวแอพ Yuncheng",
  i18nKey_444: "แอพ Yuncheng",
  i18nKey_445: "ผ่านไปหลายพันไมล์และเอาชนะความยากลำบากนับพัน",
  i18nKey_446: "พยากรณ์รายเดือน",
  i18nKey_447: "ปริมาณการบริโภคต่อเดือน (หยวน)",
  i18nKey_448: "ปริมาณการใช้ไฟฟ้าจริงต่อเดือน (กิโลวัตต์ชั่วโมง)",
  i18nKey_449: "จำนวนเงินที่เหลือต่อเดือน (หยวน)",
  i18nKey_450: "วงเงินบัตรรายเดือน",
  i18nKey_451: "ผู้ตรวจสอบบัตรรายเดือน",
  i18nKey_452: "วันหมดอายุของบัตรรายเดือน",
  i18nKey_453: "รถบรรทุกดวงจันทร์",
  i18nKey_454: "บัตรรายเดือน",
  i18nKey_455: "ปริมาณการใช้น้ำรายเดือน (ตัน)",
  i18nKey_456: "การใช้พลังงานรายเดือน (กิโลวัตต์ชั่วโมง)",
  i18nKey_457: "จำนวนเงินที่เติมรายเดือน (หยวน)",
  i18nKey_458: "เดือน",
  i18nKey_459: "เวลาเปิดรีโมท",
  i18nKey_460: "ประตูรีโมทเปิดสำเร็จ",
  i18nKey_461: "เปิดประตูจากระยะไกล",
  i18nKey_462: "การป้อนรหัสบุคลากรของระบบต้นทางมีอักษรพิเศษ",
  i18nKey_463: "ชื่อระบบต้นทางมีอักษรพิเศษ",
  i18nKey_464: "อินพุตการเข้ารหัสข้อมูลต้นทางมีอักษรพิเศษ",
  i18nKey_465: "การเข้ารหัสข้อมูลต้นทาง",
  i18nKey_466: "เจ้าหน้าที่",
  i18nKey_467: "ตารางเมตรทั้งหมดของสวนสาธารณะต้องมากกว่า 0",
  i18nKey_468: "ตารางเมตรรวมของสวนสาธารณะ",
  i18nKey_469: "ปาร์คเป็นเจ้าของ",
  i18nKey_470: "ไม่มีข้อมูลความร้อนของบุคลากรในสวนสาธารณะ",
  i18nKey_471: "ไม่มีข้อมูลอุปกรณ์ที่เกี่ยวข้องในสวนสาธารณะ",
  i18nKey_472: "ไม่มีข้อมูลพนักงานทำความสะอาดในสวนสาธารณะ",
  i18nKey_473: "ไม่มีข้อมูลเจ้าหน้าที่รักษาความปลอดภัยในสวนสาธารณะ",
  i18nKey_474: "วิสาหกิจในสวนสาธารณะ",
  i18nKey_475: "บัญชีดำของอุทยาน",
  i18nKey_476: "ผู้เยี่ยมชมสวน",
  i18nKey_477: "คำสั่งจอด",
  i18nKey_478: "พนักงานองค์กรในสวนสาธารณะ",
  i18nKey_479: "อันดับสีของรถจอด",
  i18nKey_480: "การจัดอันดับแบรนด์รถจอด",
  i18nKey_481: "รหัสสวนสาธารณะ",
  i18nKey_482: "การทำความสะอาดสวนสาธารณะ",
  i18nKey_483: "รถบัสจอด",
  i18nKey_484: "หยวน",
  i18nKey_485: "ประเภทเกณฑ์",
  i18nKey_486: "เกณฑ์ไม่ถูกต้อง! โปรดตรวจสอบและยืนยัน",
  i18nKey_487: "เกณฑ์",
  i18nKey_488: "บัญชีโดเมน",
  i18nKey_489: "สถานะการจอง",
  i18nKey_490: "ดูตัวอย่าง",
  i18nKey_491: "ค่าคำเตือนต่ำสุดคือ 0 และค่าสูงสุดคือจำนวนเต็ม 999",
  i18nKey_492: "สถานะค่าคำเตือน:",
  i18nKey_493: "คาดว่าจะออก",
  i18nKey_494: "เวลาออกเดินทางโดยประมาณ",
  i18nKey_495: "เวลาเยี่ยมชมโดยประมาณ",
  i18nKey_496: "จ่ายล่วงหน้า",
  i18nKey_497: "หมดเขตจอง",
  i18nKey_498: "วันที่จอง",
  i18nKey_499: "บุ๊คเกอร์",
  i18nKey_500: "การจอง",
  i18nKey_501: "ค่าทำนาย",
  i18nKey_502: "ค่าการใช้พลังงานที่คาดการณ์ไว้ (กิโลวัตต์ชั่วโมง)",
  i18nKey_503: "ตัวบ่งชี้ความถูกต้อง",
  i18nKey_504: "มีประสิทธิภาพ",
  i18nKey_505: "กลับสู่พื้นที่สำคัญ:",
  i18nKey_506: "แพ็คเกจพิเศษ:",
  i18nKey_507: "องค์กรพิเศษ",
  i18nKey_508: "กลยุทธ์พิเศษ",
  i18nKey_509: "คำแนะนำในการเพิ่มประสิทธิภาพ",
  i18nKey_510: "ยอดเยี่ยม",
  i18nKey_511: "สถิติการใช้น้ำ (t)",
  i18nKey_512: "สถิติปริมาณการใช้น้ำเป็นหน่วยลูกบาศก์เมตร(m³)",
  i18nKey_513: "ประเภทน้ำ",
  i18nKey_514: "เวลา (นาที)",
  i18nKey_515: "แนวโน้มการใช้พลังงาน",
  i18nKey_516: "การใช้พลังงาน (กิโลวัตต์/ชั่วโมง)",
  i18nKey_517: "ใช้พลังงาน",
  i18nKey_518: "ปริมาณ",
  i18nKey_519: "การยืนยันข้อมูลผู้ใช้",
  i18nKey_520: "ผู้ใช้ / กลุ่ม",
  i18nKey_521: "การตั้งค่าคำเตือนไฟฟ้า",
  i18nKey_522: "เปอร์เซ็นต์ไฟฟ้าผิดปกติ",
  i18nKey_523: "การใช้พลังงานผิดปกติ",
  i18nKey_524: "สถิติการใช้ไฟฟ้า",
  i18nKey_525: "สถิติการใช้พลังงาน (กิโลวัตต์-ชั่วโมง)",
  i18nKey_526: "สถิติการใช้พลังงาน (กิโลวัตต์-ชั่วโมง)",
  i18nKey_527: "อันดับไฟฟ้า",
  i18nKey_528: "การใช้พลังงาน (กิโลวัตต์-ชั่วโมง)",
  i18nKey_529: "ประเภทไฟฟ้า",
  i18nKey_530: "รายละเอียดไฟฟ้า",
  i18nKey_531: "โหลดไฟฟ้า",
  i18nKey_532: "ไฟฟ้าเสีย",
  i18nKey_533: "ไฟฟ้า",
  i18nKey_534: "รหัสของระบบแอปพลิเคชัน",
  i18nKey_535: "หมายเลขระบบแอปพลิเคชันต้องไม่ว่างเปล่า",
  i18nKey_536: "ระบบปฏิบัติการ",
  i18nKey_537: "ความสำเร็จที่มุ่งเน้นผู้ใช้",
  i18nKey_538: "มุ่งเน้นผู้ใช้",
  i18nKey_539: "สถานการณ์การใช้งานที่ประสบความสำเร็จ",
  i18nKey_540: "สถานการณ์การใช้งาน",
  i18nKey_541: "ใบอนุญาตประกอบธุรกิจ",
  i18nKey_542: "เวลาทำการต้องไม่น้อยกว่าหนึ่งชั่วโมง!",
  i18nKey_543: "เวลาทำการ",
  i18nKey_544: "เวลาเริ่มต้นธุรกิจ",
  i18nKey_545: "เวลาสิ้นสุดของธุรกิจ",
  i18nKey_546: "รายได้",
  i18nKey_547: "ชื่อภาษาอังกฤษ",
  i18nKey_548: "นโยบายความเป็นส่วนตัว",
  i18nKey_549: "เงิน",
  i18nKey_550: "การชำระเงิน UnionPay",
  i18nKey_551: "การควบคุมระดับเสียง:",
  i18nKey_552: "เนื่องจากความเป็นส่วนตัวของลูกค้ามีส่วนเกี่ยวข้องจึงจำเป็นต้องมีการยืนยันตัวตน",
  i18nKey_553: "บันทึกรายงานการแพร่ระบาด",
  i18nKey_554: "บันทึกการป้องกันและควบคุมการแพร่ระบาด",
  i18nKey_555: "จำนวนข้อยกเว้นทั้งหมด (A)",
  i18nKey_556: "ผิดปกติ",
  i18nKey_557: "ค้นหาผู้คนด้วยรูปภาพ",
  i18nKey_558: "จ่าย",
  i18nKey_559: "มีบันทึกอยู่แล้วไม่ว่าจะเขียนทับ",
  i18nKey_560: "เรียบร้อยแล้ว",
  i18nKey_561: "เชิญ",
  i18nKey_562: "สาขาที่เลือก",
  i18nKey_563: "เลือกอุปกรณ์แล้ว",
  i18nKey_564: "ไฟล์ที่เลือกสำหรับการอัปโหลด:",
  i18nKey_565: "กล้องที่เลือก",
  i18nKey_566: "การเลือกธุรกิจ / ผู้เช่าที่เลือก",
  i18nKey_567: "อาคารที่เลือก",
  i18nKey_568: "เลือกแล้ว",
  i18nKey_569: "เสร็จสมบูรณ์",
  i18nKey_570: "คืนเงินเรียบร้อยแล้ว",
  i18nKey_571: "หยุด",
  i18nKey_572: "เพิ่มเส้นทาง",
  i18nKey_573: "เพิ่มตารางนี้!",
  i18nKey_574: "ส่งแล้ว",
  i18nKey_575: "ล็อคแล้ว",
  i18nKey_576: "ใช้แล้ว",
  i18nKey_577: "ตรวจสอบแล้ว",
  i18nKey_578: "ยกเลิก",
  i18nKey_579: "ไม่สามารถลบการควบคุมการปรับใช้ที่เปิดใช้งานได้",
  i18nKey_580: "เปิดใช้งานแล้ว",
  i18nKey_581: "ไม่สามารถลบเครือข่ายอุปกรณ์ที่กำหนดค่าไว้ได้",
  i18nKey_582: "ติดตั้งแล้ว",
  i18nKey_583: "$ เปิด",
  i18nKey_584: "เปิด",
  i18nKey_585: "ตรวจสอบแล้ว",
  i18nKey_586: "เกิน",
  i18nKey_587: "จ่าย",
  i18nKey_588: "เขียนออก",
  i18nKey_589: "หมดอายุ",
  i18nKey_590: "กล้องที่เชื่อมต่อต้องไม่เกินสามตัว",
  i18nKey_591: "ไม่มีการเชื่อมต่อกล้อง",
  i18nKey_592: "การเชื่อมต่อกล้องแล้ว",
  i18nKey_593: "$ ปิด",
  i18nKey_594: "จำนวนผู้เยี่ยมชม (คน)",
  i18nKey_595: "มาถึงแล้ว",
  i18nKey_596: "มีอยู่",
  i18nKey_597: "ประมวลผล",
  i18nKey_598: "จำนวนตั๋วเงินที่ออกโดยไม่ได้หัก (หยวน)",
  i18nKey_599: "บิลยังไม่ถูกหัก",
  i18nKey_600: "ยอดบิลสะสม",
  i18nKey_601: "หมดเวลา",
  i18nKey_602: "การย้ายล้มเหลว",
  i18nKey_603: "มือถือ",
  i18nKey_604: "ย้ายสำเร็จ",
  i18nKey_605: "อุปกรณ์ที่นำออกจะถูกคืนค่าเป็น 'อุปกรณ์ที่จะจัดสรร' และสามารถเพิ่มได้อีกครั้งหากจำเป็น คุณต้องการนำอุปกรณ์ออกจากพื้นที่นี้หรือไม่",
  i18nKey_606: "ย้ายออก",
  i18nKey_607: "เครื่องที่เป็นของ",
  i18nKey_608: "แบรนด์เครื่องมือ",
  i18nKey_609: "ชื่อเครื่องมือ",
  i18nKey_610: "หมวดเครื่องมือ",
  i18nKey_611: "รหัสเครื่องต้องไม่ว่างเปล่า",
  i18nKey_612: "รหัสเครื่องมือ / หมายเลข / ชื่อ",
  i18nKey_613: "หมายเลขมิเตอร์ / หน่วยอาคาร",
  i18nKey_614: "หมายเลขมิเตอร์",
  i18nKey_615: "รหัสเดียว",
  i18nKey_616: "ประเภทหนึ่ง",
  i18nKey_617: "รีเซ็ตปุ่มเดียว",
  i18nKey_618: "ปิดใช้งานด้วยการคลิกครั้งเดียว",
  i18nKey_619: "หนึ่งปุ่มเพื่อเปิด",
  i18nKey_620: "แผนกแรก",
  i18nKey_621: "ระดับแรก",
  i18nKey_622: "คำเตือนทั่วไป",
  i18nKey_623: "หนึ่ง",
  i18nKey_624: "เจ้าของ",
  i18nKey_625: "งานธุรกิจ",
  i18nKey_626: "รหัสธุรกิจ",
  i18nKey_627: "ชื่อรูปแบบต้องไม่ว่างเปล่า",
  i18nKey_628: "ชื่อธุรกิจ",
  i18nKey_629: "รูปแบบ",
  i18nKey_630: "ค้นหาหนทางสู่ความสำเร็จ ไม่ใช่เหตุผลของความล้มเหลว",
  i18nKey_631: "ข้อมูลคำเชิญ",
  i18nKey_632: "เวลาเชิญ",
  i18nKey_633: "เชิญชวนผู้เยี่ยมชม",
  i18nKey_634: "ข้อมูลในการเชิญ",
  i18nKey_635: "เวลาเชิญ",
  i18nKey_636: "มีการแสดงสีให้คุณเลือกมากกว่า 6 อย่าง",
  i18nKey_637: "มลภาวะที่ร้ายแรง",
  i18nKey_638: "เกณฑ์การเตือนภัยที่สำคัญ",
  i18nKey_639: "วงจรของ",
  i18nKey_640: "วงจร",
  i18nKey_641: "ชื่อกลุ่มตรวจตรา",
  i18nKey_642: "ภาพรวมของปัญหาการตรวจตรา",
  i18nKey_643: "เจ้าหน้าที่ตรวจตรา",
  i18nKey_644: "ต้องระบุชื่อการตรวจตรา",
  i18nKey_645: "ชื่อการตรวจตราต้องมีความยาวไม่เกิน 200 อักษร",
  i18nKey_646: "คำอธิบายเส้นทางการตรวจตรา",
  i18nKey_647: "ชื่อเส้นทางตรวจตรา",
  i18nKey_648: "เส้นทางตรวจตรา",
  i18nKey_649: "ชื่อแผนการตรวจตรา",
  i18nKey_650: "กำหนดการตรวจตรา",
  i18nKey_651: "คำเตือนการตรวจตรา",
  i18nKey_652: "การตรวจตรา",
  i18nKey_653: "การเลือกโซนอัคคีภัย",
  i18nKey_654: "เลือกอุปกรณ์ทดแทน",
  i18nKey_655: "ระยะเวลาคัดเลือก",
  i18nKey_656: "เลือกไฟถนน",
  i18nKey_657: "เลือกประเภทสถานที่",
  i18nKey_658: "เลือกประเภทบ้าน",
  i18nKey_659: "เลือกแผนกการจัดการ",
  i18nKey_660: "เลือกการเชื่อมโยง",
  i18nKey_661: "ไม่มีข้อมูลการจราจรในตำแหน่งที่เลือกโปรดเลือกพื้นที่ใหม่และลองอีกครั้ง",
  i18nKey_662: "ช่วงจำวันที่เลือกมากกว่า 190 วันโปรดลดจำนวนวันและลองอีกครั้ง",
  i18nKey_663: "เลือกระดับอย่างน้อยไปยังสวนสาธารณะ",
  i18nKey_664: "เลือกป้ายกำกับ",
  i18nKey_665: "ทางเข้า",
  i18nKey_666: "หมายเลขซีเรียลต้องประกอบด้วยอักษรจีน ภาษาอังกฤษและตัวเลขเท่านั้น",
  i18nKey_667: "ความยาวของหมายเลขซีเรียลต้องไม่เกิน 100 อักษร",
  i18nKey_668: "หมายเลขซีเรียล",
  i18nKey_669: "ปรับเปลี่ยนบริการเสริม",
  i18nKey_670: "แก้ไขการเตือนล่วงหน้าสำเร็จแล้ว",
  i18nKey_671: "แก้ไขแผนตรวจตราเรียบร้อยแล้ว",
  i18nKey_672: "ต้องปิดราคาก่อนเพื่อแก้ไขข้อมูล!",
  i18nKey_673: "หากต้องการแก้ไขข้อมูลคุณต้องหยุดตำแหน่งก่อน!",
  i18nKey_674: "การแก้ไขส่วนลดมีเวลาจำกัด",
  i18nKey_675: "การจำกัดการปรับเปลี่ยนยานพาหนะ",
  i18nKey_676: "ปรับเปลี่ยนอุปกรณ์",
  i18nKey_677: "อ่านก่อนแก้ไข",
  i18nKey_678: "เปลี่ยนรหัสผ่านเรียบร้อยแล้วโปรดเข้าสู่ระบบอีกครั้ง",
  i18nKey_679: "แก้ไขข้อมูลพื้นฐานไม่สำเร็จ",
  i18nKey_680: "แก้ไขข้อมูลพื้นฐานของพื้นที่สำเร็จแล้ว",
  i18nKey_681: "ปรับเปลี่ยนแผน",
  i18nKey_682: "แก้ไขการกำหนดค่าคลาวด์แบบไฮบริดสำเร็จ",
  i18nKey_683: "แก้ไขข้อมูลของคลาวด์แบบไฮบริด",
  i18nKey_684: "แก้ไขการบำรุงรักษาทรัพยากรห้องประชุม",
  i18nKey_685: "แก้ไขลำดับห้องประชุม",
  i18nKey_686: "ปรับเปลี่ยนห้องประชุม",
  i18nKey_687: "แก้ไขตารางเดินรถ",
  i18nKey_688: "แก้ไขชื่อหมายเหตุ",
  i18nKey_689: "แก้ไขการกำหนดค่า VCM",
  i18nKey_690: "พักผ่อน",
  i18nKey_691: "ความสว่างของการนอนหลับ",
  i18nKey_692: "เฉยๆ",
  i18nKey_693: "มีอักษรพิเศษในชื่อกรุณากรอกใหม่",
  i18nKey_694: "ชื่อหรือหมายเลขโทรศัพท์",
  i18nKey_695: "ชื่อ",
  i18nKey_696: "ต้องระบุเพศ",
  i18nKey_697: "เพศ",
  i18nKey_698: "แบบ",
  i18nKey_699: "วันจันทร์",
  i18nKey_700: "วันศุกร์",
  i18nKey_701: "วันอาทิตย์",
  i18nKey_702: "วันพฤหัสบดี",
  i18nKey_703: "วันพุธ",
  i18nKey_704: "วันเสาร์",
  i18nKey_705: "วันอังคาร",
  i18nKey_706: "หมวดหมู่ย่อยใหม่",
  i18nKey_707: "เพิ่มหมวดหมู่ย่อย",
  i18nKey_708: "เพิ่มบริการเสริม",
  i18nKey_709: "เพิ่มเกณฑ์การเตือนภัยล่วงหน้าเรียบร้อยแล้ว",
  i18nKey_710: "เกณฑ์การเตือนใหม่",
  i18nKey_711: "รูปแบบใหม่ระดับแรก",
  i18nKey_712: "เพิ่มสมาชิกในทีม",
  i18nKey_713: "เพิ่มโซนไฟ",
  i18nKey_714: "เพิ่มสถานที่",
  i18nKey_715: "การเพิ่มล้มเหลวโปรดลองอีกครั้งในภายหลัง",
  i18nKey_716: "อุปกรณ์ใหม่",
  i18nKey_717: "การกำหนดค่าใหม่",
  i18nKey_718: "บทบาทใหม่",
  i18nKey_719: "เส้นทางเมนูสถานที่ที่เพิ่มเข้ามาใหม่: บริการขององค์กร -> การจัดการห้องประชุม -> บริการเสริม!",
  i18nKey_720: "แผนใหม่",
  i18nKey_721: "เพิ่มการบำรุงรักษาทรัพยากรห้องประชุม",
  i18nKey_722: "เพิ่มลำดับห้องประชุม",
  i18nKey_723: "เพิ่มห้องประชุม",
  i18nKey_724: "อพาร์ทเมนท์ใหม่",
  i18nKey_725: "เพิ่มสถานที่เพิ่มเติม",
  i18nKey_726: "บ้านใหม่",
  i18nKey_727: "เพิ่มเรียบร้อยแล้ว",
  i18nKey_728: "ผลิตภัณฑ์ใหม่",
  i18nKey_729: "ช่วงผลเวลาของนโยบายใหม่ (สามารถสร้างได้สูงสุด 4 รายการ)",
  i18nKey_730: "ข้อมูลฉลากใหม่",
  i18nKey_731: "รหัสผ่านใหม่ต้องไม่เหมือนกับรหัสผ่านเดิม",
  i18nKey_732: "รหัสผ่านใหม่",
  i18nKey_733: "จำนวนลูกค้าใหม่",
  i18nKey_734: "อัตราส่วนลูกค้าใหม่",
  i18nKey_735: "แผนการตรวจตราใหม่ที่ประสบความสำเร็จ",
  i18nKey_736: "รถใหม่ที่ถูกจำกัด",
  i18nKey_737: "การติดตามใบหน้าใหม่",
  i18nKey_738: "เส้นทางใหม่",
  i18nKey_739: "สร้างข้อมูลพื้นฐานของพื้นที่ใหม่ไม่สำเร็จ",
  i18nKey_740: "สร้างข้อมูลพื้นฐานของพื้นที่ใหม่สำเร็จแล้ว",
  i18nKey_741: "แผนใหม่",
  i18nKey_742: "การกำหนดค่าคลาวด์แบบไฮบริดใหม่สำเร็จแล้ว",
  i18nKey_743: "สร้างตั๋วใหม่สำเร็จ",
  i18nKey_744: "การควบคุมการปรับใช้ใหม่",
  i18nKey_745: "การกำหนดค่า VCM ใหม่",
  i18nKey_746: "ตรวจสอบผลลัพธ์",
  i18nKey_747: "น้อยกว่าหรือเท่ากับ",
  i18nKey_748: "น้อยกว่า",
  i18nKey_749: "ยกเลิกชั่วโมง คืนเงิน",
  i18nKey_750: "ชั่วโมงในการยกเลิก",
  i18nKey_751: "ชั่วโมง / วันต้องไม่น้อยกว่า 4 ชั่วโมง!",
  i18nKey_752: "ชั่วโมง / วัน",
  i18nKey_753: "ชั่วโมง",
  i18nKey_754: "ฝ่ายขาย",
  i18nKey_755: "ข้อความจะมีการส่งข้อมูลที่แสดงผลของรายการข้อความของแอพเมืองคลาวด์",
  i18nKey_756: "ขจัดสัญญาณเตือนสำเร็จ",
  i18nKey_757: "ปริมาณการใช้น้ำ",
  i18nKey_758: "ปริมาณการบริโภค",
  i18nKey_759: "การใช้พลังงาน",
  i18nKey_760: "การบริโภคทั่วไปยังคงเป็นเรื่องน่าสนใจ",
  i18nKey_761: "การใช้จ่ายพลังงาน",
  i18nKey_762: "ประเภทการบริโภค",
  i18nKey_763: "การซื้อของผู้บริโภค",
  i18nKey_764: "ระบบดับเพลิง / สัญญาณเตือนไฟอัตโนมัติและระบบเชื่อมโยง / ปุ่มเตือนด้วยตนเอง",
  i18nKey_765: "ระบบดับเพลิง / สัญญาณเตือนอัคคีภัยอัตโนมัติและระบบเชื่อมโยง / สัญญาณเตือนไฟไหม้ให้เจ้าของบ้าน",
  i18nKey_766: "ระบบดับเพลิง / สัญญาณเตือนไฟไหม้อัตโนมัติและระบบเชื่อมโยง / เครื่องตรวจจับควันไฟ",
  i18nKey_767: "ระบบดับเพลิง / สัญญาณเตือนอัคคีภัยอัตโนมัติและระบบเชื่อมโยง / เครื่องตรวจจับความร้อน",
  i18nKey_768: "ระบบดับเพลิง",
  i18nKey_769: "แนวโน้มเหตุการณ์ไฟไหม้",
  i18nKey_770: "ชื่อตำบลที่เกิดไฟไหม้",
  i18nKey_771: "เทมเพลตสำหรับกรอกการนำเข้าเกี่ยวกับเรื่องไฟไหม้",
  i18nKey_772: "รหัสโซนของการเกิดไฟไหม้",
  i18nKey_773: "กองดับเพลิง",
  i18nKey_774: "พื้นที่โครงการตั้งอยู่ในโรงรถใต้ดินชั้น B1 และ B2 ของ Jinqiao Wanchuang Center ซึ่งติดตั้งระบบกักเก็บพลังงาน 250kW / 924.9kWh 3 ชุด เต้ารับตั้งอยู่ที่ด้านข้างของบัสบาร์ 400V ของหม้อแปลง 10kV สาย A 2 # (1000kVA) และหม้อแปลง 10kV สาย B # 5 # (1000kVA) 400V ด้านบัสและหม้อแปลง 6 # (2000kVA) 400V",
  i18nKey_775: "ชื่อโครงการ",
  i18nKey_776: "แนะนำโครงการ",
  i18nKey_777: "ที่อยู่",
  i18nKey_778: "ขนาดกล่อง (นิ้ว)",
  i18nKey_779: "พื้นที่ที่อยู่ติดกันไม่สามารถอยู่ในตำแหน่งเดียวกับสถานที่",
  i18nKey_780: "พื้นที่ติดกัน",
  i18nKey_781: "การชำระเงินแบบออฟไลน์",
  i18nKey_782: "พนักงานชำระเงินออฟไลน์",
  i18nKey_783: "การชำระเงินแบบออฟไลน์",
  i18nKey_784: "รูปแบบการป้อนเงินคืนออนไลน์ไม่ถูกต้อง!",
  i18nKey_785: "คืนเงินออนไลน์",
  i18nKey_786: "ยกเลิกออนไลน์",
  i18nKey_787: "ปัจจัยจำกัด",
  i18nKey_788: "มีช่วงเวลาจำกัดในการลดราคากับสิ่งของที่ตั้งอยู่บนชั้นวาง โปรดซื้อหลังจากที่คุณหยิบออกจากชั้นวาง!",
  i18nKey_789: "ช่วงเวลาจำกัด",
  i18nKey_790: "ยานพาหนะที่ถูก จำกัด",
  i18nKey_791: "ภาพสด",
  i18nKey_792: "วิดีโอสด",
  i18nKey_793: "ราคาที่แสดง",
  i18nKey_794: "แสดง",
  i18nKey_795: "ดาวน์โหลดเทมเพลต (พื้นที่สาธารณะ)",
  i18nKey_796: "ดาวน์โหลดเทมเพลต (ห้อง)",
  i18nKey_797: "ดาวน์โหลดเทมเพลต",
  i18nKey_798: "ดาวน์โหลดเทมเพลตฟอร์ม",
  i18nKey_799: "ดาวน์โหลด",
  i18nKey_800: "เคลื่อนที่ลงมาด้านล่าง",
  i18nKey_801: "ขั้นตอนต่อไป",
  i18nKey_802: "ในช่วงบ่าย",
  i18nKey_803: "ลดลง",
  i18nKey_804: "ชั้นวางปิด",
  i18nKey_805: "ระดับล่างเพิ่มขึ้น",
  i18nKey_806: "ลง",
  i18nKey_807: "แนะนำการทำงานของพารามิเตอร์สำหรับชั่วโมงถัดไป",
  i18nKey_808: "ออกคำสั่งเรียบร้อยแล้ว",
  i18nKey_809: "แผนการส่งออก",
  i18nKey_810: "เวลาสั่งซื้อ",
  i18nKey_811: "ผู้สั่งซื้อ",
  i18nKey_812: "ระบบจะปฏิเสธโดยอัตโนมัติ",
  i18nKey_813: "ระบบคาดการณ์อื่น ๆ",
  i18nKey_814: "ระบบคาดการณ์บุคลากรแบบถาวร",
  i18nKey_815: "ผู้ดูแลระบบ",
  i18nKey_816: "รหัสระบบต้องเป็นภาษาจีนตัว อักษรและขีดล่างภายใน 50 อักษรเท่านั้น",
  i18nKey_817: "รหัสระบบต้องไม่ว่างเปล่า",
  i18nKey_818: "ระบบ 2",
  i18nKey_819: "ระบบ 1",
  i18nKey_820: "ระบบ",
  i18nKey_821: "การกำหนดค่ากลยุทธ์ของการตรวจสอบห้องน้ำ",
  i18nKey_822: "ตะวันตกเฉียงใต้",
  i18nKey_823: "บวกเท็จ",
  i18nKey_824: "พนักงานด้านสินทรัพย์",
  i18nKey_825: "สินทรัพย์",
  i18nKey_826: "ห้าหมวดหมู่",
  i18nKey_827: "แผนกระดับ 5",
  i18nKey_828: "ระดับ 5",
  i18nKey_829: "5",
  i18nKey_830: "ไม่แสดง",
  i18nKey_831: "ไม่มีบันทึก",
  i18nKey_832: "เหตุการณ์ที่ไม่เกี่ยวข้อง",
  i18nKey_833: "กล้องที่ไม่ได้เชื่อมต่อ",
  i18nKey_834: "ไม่มีข้อมูลที่ตรงตามเงื่อนไขการสืบค้น",
  i18nKey_835: "ไม่สามารถรับข้อมูลในบรรทัด",
  i18nKey_836: "ไม่มีอุปกรณ์ที่เหนือกว่า",
  i18nKey_837: "ไม่",
  i18nKey_838: "ไฟล์รองรับเฉพาะ excel",
  i18nKey_839: "ขนาดไฟล์ต้องไม่เกิน 20M",
  i18nKey_840: "อุณหภูมิ",
  i18nKey_841: "ตำแหน่งเส้นทางแบบสมบูรณ์",
  i18nKey_842: "รหัสสถานที่",
  i18nKey_843: "ตำแหน่ง",
  i18nKey_844: "ไม่ทราบ",
  i18nKey_845: "ไม่ได้ตั้งค่าตัวเร่งโปรดเพิ่มตัวดัน!",
  i18nKey_846: "ไม่ได้เริ่มต้น",
  i18nKey_847: "ไม่มีอุปกรณ์",
  i18nKey_848: "ไม่ได้ป้อน",
  i18nKey_849: "ไม่แยกจากกัน",
  i18nKey_850: "ยังไม่เริ่ม",
  i18nKey_851: "ไม่ตัดบัญชี",
  i18nKey_852: "จำนวนเงินที่ไม่ได้หัก",
  i18nKey_853: "จำนวนผู้ไม่มาเยี่ยม (คน)",
  i18nKey_854: "ไม่มีการละเมิดใด ๆ เกิดขึ้น",
  i18nKey_855: "ไม่ได้คัดลอกจำนวนทั้งหมด (a)",
  i18nKey_856: "ส่วนจำนวนของละติจูดคือ 0-90",
  i18nKey_857: "ละติจูด",
  i18nKey_858: "ซ่อมแซมเนื้อหา",
  i18nKey_859: "ผลการซ่อม",
  i18nKey_860: "คนซ่อมบำรุง",
  i18nKey_861: "รักษา",
  i18nKey_862: "สถานะการบำรุงรักษา",
  i18nKey_863: "ธุรกิจซ่อมบำรุงต้องประกอบด้วยภาษาจีนและภาษาอังกฤษตัวเลขและ _ เท่านั้น",
  i18nKey_864: "ความยาวของชื่อผู้ให้บริการบำรุงรักษาต้องไม่เกิน 50 อักษร",
  i18nKey_865: "ผู้ดูแล",
  i18nKey_866: "หลักฐานการละเมิด",
  i18nKey_867: "เหตุผลในการละเมิด",
  i18nKey_868: "รายละเอียดการละเมิด",
  i18nKey_869: "หยุดเวลา",
  i18nKey_870: "สถานที่จอดรถ",
  i18nKey_871: "จำนวนการละเมิด",
  i18nKey_872: "สว่างเล็กน้อย",
  i18nKey_873: "ลืมรหัสผ่าน",
  i18nKey_874: "การโหลดเครือข่ายผิดปกติ โปรดลองอีกครั้งในภายหลัง",
  i18nKey_875: "การโหลดเครือข่ายผิดปกติ โปรดลองอีกครั้งในภายหลัง",
  i18nKey_876: "ชื่อเกตเวย์",
  i18nKey_877: "เกตเวย์ไม่ออนไลน์และการส่วนของการจัดส่งแผนล้มเหลว",
  i18nKey_878: "สวน Vanke",
  i18nKey_879: "สวนสาธารณะ Vanke Xicheng",
  i18nKey_880: "สวนสาธารณะ Vanke Haiding District",
  i18nKey_881: "อำเภอ Vanke Dongcheng",
  i18nKey_882: "สวนสาธารณะ Vanke Chaoyang",
  i18nKey_883: "Vanke Binhai New Area Park",
  i18nKey_884: "Van",
  i18nKey_885: "รถบัสมาสาย",
  i18nKey_886: "ชื่อสถานที่ภายนอก",
  i18nKey_887: "รหัสตำแหน่งภายนอก",
  i18nKey_888: "บริษัท ภายนอก",
  i18nKey_889: "สถานะการคืนเงิน",
  i18nKey_890: "ขอเงินคืน",
  i18nKey_891: "การคืนเงินล้มเหลว",
  i18nKey_892: "การดำเนินการคืนเงิน",
  i18nKey_893: "ระยะเวลาดำเนินการคืนเงิน",
  i18nKey_894: "เวลาคืนเงินสำเร็จ",
  i18nKey_895: "คืนเงินเรียบร้อย",
  i18nKey_896: "กลับ",
  i18nKey_897: "ยกเลิกการสมัคร",
  i18nKey_898: "แนะนำลายเซ็น",
  i18nKey_899: "นำความสามัคคีและความร่วมมือออกไปทั้งหมด",
  i18nKey_900: "จุดเป้าหมายไม่สามารถเหมือนกับจุดสิ้นสุด",
  i18nKey_901: "จุดเป้าหมายไม่สามารถเหมือนกับจุดเริ่มต้น",
  i18nKey_902: "ทาง",
  i18nKey_903: "ขนาดภาพต้องไม่เกิน 3 เมตร!",
  i18nKey_904: "ขนาดภาพต้องไม่เกิน 3 เมตร",
  i18nKey_905: "มุมมองภาพ",
  i18nKey_906: "การตัดรูปภาพ",
  i18nKey_907: "ภาพ",
  i18nKey_908: "แผนภูมิรายงาน",
  i18nKey_909: "แผนภูมิ",
  i18nKey_910: "โควต้าการตั้งค่าแบบรวม",
  i18nKey_911: "ระยะเวลาทางสถิติ (วัน)",
  i18nKey_912: "สถิติโดยละเอียด",
  i18nKey_913: "ชื่อแผนภูมิ",
  i18nKey_914: "วันทางสถิติสามารถเป็นจำนวนเต็มบวกเท่านั้น",
  i18nKey_915: "เวลาสถิติ:",
  i18nKey_916: "การกำหนดค่าบัตรสถิติ",
  i18nKey_917: "สถิติตั้งการเริ่มต้นตั้งแต่เมื่อวานนี้ จำนวนเงินที่เติมจะขึ้นอยู่กับการมาถึงของบัญชี",
  i18nKey_918: "ตกลง",
  i18nKey_919: "สามารถตั้งกฎการยกเลิกได้เพียงข้อเดียวในวันเดียวกัน!",
  i18nKey_920: "ไม่สามารถเลือกกล้องมากกว่า 4 ตัวในเวลาเดียวกัน",
  i18nKey_921: "ช่วงเวลาเดียวกัน (กิโลวัตต์ - ชั่วโมง)",
  i18nKey_922: "การตั้งค่าข้อมูลที่แสดงร่วม",
  i18nKey_923: "จำนวนเพื่อน",
  i18nKey_924: "ปีต่อปี",
  i18nKey_925: "ปีต่อปี",
  i18nKey_926: "YoY",
  i18nKey_927: "ชั้นทางเข้า",
  i18nKey_928: "ผ่าน",
  i18nKey_929: "หยุด",
  i18nKey_930: "ปิดใช้งาน",
  i18nKey_931: "ระยะเวลาการเข้าพักต้องเป็นจำนวนเต็มบวกเท่านั้น",
  i18nKey_932: "ระยะเวลาเข้าพัก (ชั่วโมง)",
  i18nKey_933: "ชั้นจอดรถ",
  i18nKey_934: "เชื่อมต่อ",
  i18nKey_935: "เวลาจอดรถ",
  i18nKey_936: "เวลาจอดรถ",
  i18nKey_937: "ค่าจอดรถ (ภายใน 24 ชั่วโมง)",
  i18nKey_938: "โหมดการชาร์จที่จอดรถ",
  i18nKey_939: "ชื่อที่จอดรถ",
  i18nKey_940: "ที่อยู่ลานจอดรถ",
  i18nKey_941: "ที่จอดรถต้องไม่ว่างเปล่า",
  i18nKey_942: "การกำหนดค่ากลยุทธ์การจอดรถ",
  i18nKey_943: "อุปกรณ์แก้จุดบกพร่อง",
  i18nKey_944: "เพิ่มคลาสย่อย",
  i18nKey_945: "การเพิ่มตัวผลักดันข่าวสารสามารถใช้ได้โดยผู้ใช้ที่ลงทะเบียนของแอป Yuncheng เท่านั้น",
  i18nKey_946: "เพิ่มพนักงานชำระเงินออฟไลน์",
  i18nKey_947: "เพิ่มส่วนลดในเวลาจำกัด",
  i18nKey_948: "เพิ่มการแจ้งเตือน",
  i18nKey_949: "เพิ่มแพ็คเกจ",
  i18nKey_950: "เพิ่มองค์กรที่เกี่ยวข้อง",
  i18nKey_951: "เพิ่มใบหน้า",
  i18nKey_952: "เพิ่มกฎการจัดแสง",
  i18nKey_953: "เพิ่ม บริษัท",
  i18nKey_954: "เพิ่มเส้นทางการเดินทาง",
  i18nKey_955: "เพิ่มรถ",
  i18nKey_956: "วันผลักดันเหตุการณ์",
  i18nKey_957: "วันที่ผ่านมา",
  i18nKey_958: "วัน",
  i18nKey_959: "การแทนที่ที่เร็วที่สุด",
  i18nKey_960: "แทนที่รายการที่เลือก",
  i18nKey_961: "ความสัมพันธ์ทดแทน",
  i18nKey_962: "แทนที่แผนที่ฐาน",
  i18nKey_963: "อุณหภูมิร่างกาย",
  i18nKey_964: "การแจ้งเตือน",
  i18nKey_965: "เตือน",
  i18nKey_966: "เกณฑ์แบบรวดเร็ว",
  i18nKey_967: "ข้อความแจ้งแบบรวดเร็ว",
  i18nKey_968: "พร้อมรับคำเตือน",
  i18nKey_969: "เคล็ดลับ: รองรับเฉพาะการอัปโหลดรูปภาพในรูปแบบ jpg, png, ขนาดภาพที่อัปโหลดไม่เกิน 3 เมตร",
  i18nKey_970: "คำเตือน: เนื่องจากความเป็นส่วนตัวของลูกค้า กรุณากรอกรหัสผ่านเพื่อยืนยันครั้งที่สอง",
  i18nKey_971: "เคล็ดลับ: เวลาสิ้นสุดต้องมากกว่าเวลาเริ่มต้น",
  i18nKey_972: "เคล็ดลับ: ความล่าช้าของภาพวิดีโออยู่ที่ประมาณ 5 ~ 10 วินาทีโปรดอดทนรอ",
  i18nKey_973: "จองล่วงหน้า",
  i18nKey_974: "จำนวนวันล่วงหน้า จำกัด ภายใน 1-30 วัน",
  i18nKey_975: "พิเศษ",
  i18nKey_976: "จำนวนแพ็คเกจ",
  i18nKey_977: "การใช้งานแพ็คเกจ",
  i18nKey_978: "กำลังเปิดใช้งานแพ็กเกจ โปรดหยุดและใช้งาน!",
  i18nKey_979: "ชื่อแพ็กเกจ",
  i18nKey_980: "ประเภทแพ็คเกจ",
  i18nKey_981: "ผู้ใช้บัตรแพ็คเกจ",
  i18nKey_982: "จำนวนบัตรแพคเกจ",
  i18nKey_983: "การประมวลผลบัตรแพ็คเกจ",
  i18nKey_984: "การ์ดแพ็คเกจ",
  i18nKey_985: "จำนวนเงินที่ชำระในแพ็คเกจ (หยวน)",
  i18nKey_986: "การชำระเงินแบบแพ็คเกจ",
  i18nKey_987: "ล็อค",
  i18nKey_988: "ความเป็นเจ้าของ (เจ้าของ)",
  i18nKey_989: "บริษัท ทั้งหมด",
  i18nKey_990: "ช่วงเวลาที่เลือกต้องไม่ว่างเปล่า",
  i18nKey_991: "ช่วงเวลาที่เลือกต้องไม่เกินสองวัน",
  i18nKey_992: "ปีที่เลือกต้องอยู่ระหว่างวันที่เริ่มต้นและวันที่สิ้นสุด",
  i18nKey_993: "เจ้าของลานจอดรถ",
  i18nKey_994: "บริษัท ในเครือ",
  i18nKey_995: "คุณไม่สามารถเลือกผู้ควบคุมของคุณเอง",
  i18nKey_996: "ผู้ควบคุมที่เป็นเจ้าของต้องไม่ว่างเปล่า",
  i18nKey_997: "ผู้ควบคุมที่เป็นเจ้าของ",
  i18nKey_998: "เป็นของ",
  i18nKey_999: "บริษัท ของคุณได้ป้อนอักษรพิเศษกรุณากรอกอีกครั้ง",
  i18nKey_1000: "อักษรพิเศษที่บริษัทป้อน",
  i18nKey_1001: "บริษัทที่มีเจ้าของต้องไม่ว่างเปล่า",
  i18nKey_1002: "บริษัท",
  i18nKey_1003: "รายการย่อย",
  i18nKey_1004: "สังกัด",
  i18nKey_1005: "สาขา",
  i18nKey_1006: "ประวัติการค้นหา",
  i18nKey_1007: "สี่ประเภท",
  i18nKey_1008: "แผนกระดับ 4",
  i18nKey_1009: "ระดับ 4",
  i18nKey_1010: "4",
  i18nKey_1011: "คลาวด์ส่วนตัว",
  i18nKey_1012: "เอกชน",
  i18nKey_1013: "หมายเหตุ: ผู้อนุมัติที่เพิ่มเข้ามาจะทำหน้าที่เป็นผู้อนุมัติการจัดการทรัพย์สินและสามารถเพิ่มผู้อนุมัติได้สูงสุด 5 คน",
  i18nKey_1014: "หมายเหตุ: อัตราส่วนการใช้พลังงานของระดับนี้ถึงระดับบนเกิน> 10% ซึ่งผิดปกติ การใช้พลังงานทั้งหมดของระดับนี้คือตู้ขาเข้าแรงดันต่ำ P11, P21, P31, P41;",
  i18nKey_1015: "ยอดสะสมค่าน้ำ",
  i18nKey_1016: "พ่อค้าที่ค้างค่าน้ำ",
  i18nKey_1017: "ปริมาณการเติมน้ำที่ไม่ได้หัก",
  i18nKey_1018: "ปริมาณการเติมน้ำสะสม",
  i18nKey_1019: "ภาพรวมของค่าสาธารณูปโภค",
  i18nKey_1020: "รายละเอียดไฟฟ้าพลังน้ำ",
  i18nKey_1021: "รายการรายละเอียดไฟฟ้าพลังน้ำ",
  i18nKey_1022: "ใบสั่งจ่ายบิลค่าสาธารณูปโภค",
  i18nKey_1023: "แบบสอบถามข้อมูลรายละเอียดมาตรวัดน้ำและไฟฟ้า",
  i18nKey_1024: "เกณฑ์การเตือนมาตรวัดน้ำ:",
  i18nKey_1025: "หมายเลขซีเรียลของมาตรวัดน้ำ",
  i18nKey_1026: "เลขที่มาตรวัดน้ำ",
  i18nKey_1027: "ทำให้มีชีวิตชีวา",
  i18nKey_1028: "ระบายสีเส้นการ์ตูน",
  i18nKey_1029: "ค่าคือ 0-60",
  i18nKey_1030: "ค่าคือ 0-120",
  i18nKey_1031: "ทรัพยากรข้อมูล",
  i18nKey_1032: "แหล่งข้อมูล",
  i18nKey_1033: "รายละเอียดการตรวจสอบความผิดปกติของข้อมูล",
  i18nKey_1034: "ข้อมูลผิดปกติ (a)",
  i18nKey_1035: "ข้อมูลผิดปกติ",
  i18nKey_1036: "โหลดข้อมูลไม่สำเร็จ โปรดลองอีกครั้ง",
  i18nKey_1037: "เวลาข้อมูล",
  i18nKey_1038: "ไม่สามารถดึงข้อมูลกลับได้หลังจากการลบ ยืนยันการลบ?",
  i18nKey_1039: "ไม่สามารถกู้คืนข้อมูลได้หลังจากการลบ ยืนยันการลบ?",
  i18nKey_1040: "แหล่งข้อมูล",
  i18nKey_1041: "สรุปข้อมูล _",
  i18nKey_1042: "ข้อมูลสูญหาย (a)",
  i18nKey_1043: "ไม่อนุญาตให้ป้อนความยาวเกิน 50 อักษร",
  i18nKey_1044: "ไม่อนุญาตให้ป้อนความยาวเกิน 30 อักษร (อนุญาตให้ใช้ตัวอักษร - และตัวเลข)",
  i18nKey_1045: "ไม่อนุญาตให้ป้อนความยาวเกิน 30 อักษร (อนุญาตให้ใช้ตัวอักษรจีน ตัวอักษร - และตัวเลขได้)",
  i18nKey_1046: "ไม่อนุญาตป้อนความยาวให้เกิน 2,000 อักษร",
  i18nKey_1047: "ไม่อนุญาตให้ป้อนความยาวเกิน 100 อักษร",
  i18nKey_1048: "ป้อนชื่อเหตุการณ์หรือชื่ออุปกรณ์",
  i18nKey_1049: "ป้อนชื่อแทนกล้อง",
  i18nKey_1050: "ป้อนคำหลักเพื่อกรอง",
  i18nKey_1051: "ป้อนหมายเลขสถานีและกด Enter",
  i18nKey_1052: "ตัวเลขที่ป้อนต้องไม่เกินค่าสูงสุด 30",
  i18nKey_1053: "ตัวเลขที่ป้อนต้องไม่เกินค่าสูงสุด 24",
  i18nKey_1054: "หมายเลขที่ป้อนต้องไม่เกินเวลาทำการ",
  i18nKey_1055: "ความสบายใจ",
  i18nKey_1056: "ศึกษา",
  i18nKey_1057: "การออกใบอนุญาต",
  i18nKey_1058: "การอนุมัติสำเร็จแล้ว",
  i18nKey_1059: "การอนุญาต",
  i18nKey_1060: "หมายเลขโทรศัพท์ของผู้ให้สัมภาษณ์:",
  i18nKey_1061: "ผู้ตอบ:",
  i18nKey_1062: "ข้อมูล บริษัท ที่สัมภาษณ์",
  i18nKey_1063: "บริษัท ที่สัมภาษณ์ต้องไม่ว่างเปล่า",
  i18nKey_1064: "บริษัท ที่สัมภาษณ์:",
  i18nKey_1065: "บริษัท ที่สัมภาษณ์",
  i18nKey_1066: "ที่อยู่ที่ให้สัมภาษณ์ต้องไม่ว่างเปล่า",
  i18nKey_1067: "เวลาเข้าครั้งแรก",
  i18nKey_1068: "ค่าธรรมเนียมการจัดการ",
  i18nKey_1069: "ต้องลงทะเบียนหมายเลขโทรศัพท์มือถือในแอป Yuncheng",
  i18nKey_1070: "หมายเลขโทรศัพท์",
  i18nKey_1071: "ป้อนข้อมูลด้วยตนเอง",
  i18nKey_1072: "รายละเอียดการอ่านมิเตอร์ด้วยตนเอง",
  i18nKey_1073: "สร้างด้วยตนเอง",
  i18nKey_1074: "สิ่งกระตุ้นด้วยตนเอง",
  i18nKey_1075: "การแก้ไขด้วยตนเอง",
  i18nKey_1076: "คู่มือ:",
  i18nKey_1077: "เอาออกไป",
  i18nKey_1078: "ชื่อบัญชีรวบรวม",
  i18nKey_1079: "บัญชีเก็บเงิน",
  i18nKey_1080: "รวบรวมหมายเลขโทรศัพท์",
  i18nKey_1081: "แจ้งข้อหา",
  i18nKey_1082: "ไม่มีตัวเลือกที่ซ้ำกันสำหรับสถานที่แบบชำระเงินและสถานที่ฟรี!",
  i18nKey_1083: "ค่าผ่านทางสำหรับสถานที่",
  i18nKey_1084: "จำนวนเงินที่เรียกเก็บ",
  i18nKey_1085: "ถอดความ",
  i18nKey_1086: "อุณหภูมิห้อง",
  i18nKey_1087: "ความชื้นในร่ม",
  i18nKey_1088: "ในร่ม",
  i18nKey_1089: "องค์กรที่เกี่ยวข้อง",
  i18nKey_1090: "ผิดปกติหรือเปล่า",
  i18nKey_1091: "ไม่ว่าจะวนซ้ำ",
  i18nKey_1092: "ไม่ว่าจะแก้ไข",
  i18nKey_1093: "ไม่ว่าจะทำตามกระบวนการทั้งหมดหรือไม่",
  i18nKey_1094: "ไม่ว่าจะลบข้อมูล",
  i18nKey_1095: "จะลบข้อมูลที่ตรวจสอบหรือไม่",
  i18nKey_1096: "แน่ใจไหมว่าต้องการดาวน์โหลดภาพแผนภูมิ",
  i18nKey_1097: "คุณแน่ใจหรือไม่ว่าจะดาวน์โหลดรายงาน excel",
  i18nKey_1098: "คุณแน่ใจหรือไม่ว่าจะลบข้อมูลห้องประชุมและราคา เพราะไม่สามารถกู้คืนได้หลังจากลบ!",
  i18nKey_1099: "แน่ใจหรือว่าลบสถานี ไม่สามารถกู้คืนได้หลังจากลบ!",
  i18nKey_1100: "เปิดใช้วงเงินบัตรรายเดือนหรือไม่",
  i18nKey_1101: "เปิดใช้งานนโยบายการแจ้งเตือนหรือไม่",
  i18nKey_1102: "เปิดใช้งานข้อจำกัดที่จอดรถหรือไม่",
  i18nKey_1103: "ไม่ว่าจะเปิดใช้งาน",
  i18nKey_1104: "คุณออกไปแล้ว",
  i18nKey_1105: "ไม่ว่าจะแต่งหน้า",
  i18nKey_1106: "สถิติการตรวจตราวิดีโอ",
  i18nKey_1107: "ตรวจตราวิดีโอ",
  i18nKey_1108: "วิดีโอถูกดักจับสำเร็จแล้ว โปรดไปที่รายละเอียดกิจกรรมทางวิดีโอที่เกี่ยวข้องทางด้านซ้ายเพื่อดู",
  i18nKey_1109: "การจับภาพวิดีโอ",
  i18nKey_1110: "การเล่นวิดีโอ",
  i18nKey_1111: "ความล่าช้าของภาพวิดีโอประมาณ 5-10 วินาทีโปรดอดใจรอ",
  i18nKey_1112: "ตัวช่วยเหลือวิดีโอ",
  i18nKey_1113: "อัตราการเจาะวิดีโอ",
  i18nKey_1114: "วิดีโอ",
  i18nKey_1115: "สถิติสถานะเหตุการณ์",
  i18nKey_1116: "สถานะเหตุการณ์",
  i18nKey_1117: "รายงานเหตุการณ์เรียบร้อยแล้ว",
  i18nKey_1118: "รายงานเหตุการณ์",
  i18nKey_1119: "คำอธิบายเหตุการณ์ต้องไม่เกิน 1,000 อักษร",
  i18nKey_1120: "คำอธิบายเหตุการณ์",
  i18nKey_1121: "ประเภทเหตุการณ์",
  i18nKey_1122: "ระดับเหตุการณ์",
  i18nKey_1123: "ระดับเหตุการณ์",
  i18nKey_1124: "เวลาประมวลผลเหตุการณ์",
  i18nKey_1125: "การจัดการเหตุการณ์",
  i18nKey_1126: "การกำหนดค่าการประมวลผลการหมดเวลาของเหตุการณ์",
  i18nKey_1127: "ชื่อกิจกรรม",
  i18nKey_1128: "หมายเลขเหตุการณ์",
  i18nKey_1129: "ตั้งแต่วันที่ 1 ตุลาคม 2019",
  i18nKey_1130: "ตั้งแต่วันที่ 1 ตุลาคม 2562",
  i18nKey_1131: "สถานะการใช้งาน",
  i18nKey_1132: "ใช้การยืนยันบัญชีโดเมน",
  i18nKey_1133: "ใช้การยืนยันหมายเลขโทรศัพท์มือถือ",
  i18nKey_1134: "ใช้เวลา",
  i18nKey_1135: "อัตราการใช้ (%)",
  i18nKey_1136: "อัตราการใช้งาน",
  i18nKey_1137: "ใช้ที่อยู่:",
  i18nKey_1138: "วิดีโอเรียลไทม์ __",
  i18nKey_1139: "ดัชนีคุณภาพอากาศแบบเรียลไทม์",
  i18nKey_1140: "กำลังไฟฟ้าแบบเรียลไทม์ (กิโลวัตต์ชั่วโมง)",
  i18nKey_1141: "การอ่านมิเตอร์แบบเรียลไทม์",
  i18nKey_1142: "มูลค่าที่แท้จริง",
  i18nKey_1143: "จำนวนเงินที่จ่ายจริง",
  i18nKey_1144: "ระยะเวลาไม่ควรน้อยกว่า 30 วินาที",
  i18nKey_1145: "อย่าเกินหนึ่งชั่วโมง",
  i18nKey_1146: "ตั้งค่าอย่างน้อยหนึ่งครั้ง",
  i18nKey_1147: "ตั้งเวลา",
  i18nKey_1148: "ช่วงเวลาต้องไม่เกิน 6 เดือน",
  i18nKey_1149: "ช่วงเวลา",
  i18nKey_1150: "เวลาที่จำกัด",
  i18nKey_1151: "งวด",
  i18nKey_1152: "เจาะจงเวลา",
  i18nKey_1153: "เวลาต้องไม่ว่างเปล่า",
  i18nKey_1154: "เวลาต้องเป็นจำนวนเต็มบวกที่ไม่ใช่ศูนย์",
  i18nKey_1155: "สถิติการหลั่งไหลของผู้โดยสารตามเวลา",
  i18nKey_1156: "เวลา",
  i18nKey_1157: "รายชื่อไม่น่าไว้ใจ",
  i18nKey_1158: "เวลาหมดอายุ",
  i18nKey_1159: "ความล้มเหลว",
  i18nKey_1160: "ที่เหลือทั้งหมดที่สามารถสั่งออกได้",
  i18nKey_1161: "จำนวนเงินที่เหลือน้อยกว่าเกณฑ์",
  i18nKey_1162: "สมดุล",
  i18nKey_1163: "ลำดับที่เหลือ",
  i18nKey_1164: "ที่จอดรถที่เหลือ",
  i18nKey_1165: "ที่จอดรถที่เหลือ",
  i18nKey_1166: "ชื่อย่อจังหวัด",
  i18nKey_1167: "เวลาที่มีประสิทธิภาพ",
  i18nKey_1168: "วันที่มีผลใช้บังคับ: 07 ธันวาคม 2562",
  i18nKey_1169: "เรื่องราวที่มีประสิทธิภาพ",
  i18nKey_1170: "สร้างรหัส QR",
  i18nKey_1171: "สร้างสัญญาณเตือนความปลอดภัย",
  i18nKey_1172: "ประเภทลิฟท์",
  i18nKey_1173: "เซินเจิ้น",
  i18nKey_1174: "น้ำเงินเข้ม",
  i18nKey_1175: "สีเทาเข้ม",
  i18nKey_1176: "ต้องกรอกหมายเลขประจำตัวประชาชนและหมายเลขหนังสือเดินทาง",
  i18nKey_1177: "เลขประจำตัว",
  i18nKey_1178: "บัตรประชาชน:",
  i18nKey_1179: "การรับรองความถูกต้อง",
  i18nKey_1180: "สถานะการสมัคร",
  i18nKey_1181: "เวลาคืนเงิน",
  i18nKey_1182: "บริษัทของผู้สมัคร",
  i18nKey_1183: "ใช้สำหรับการยกเลิก",
  i18nKey_1184: "สมัครลิฟต์ขนส่งสินค้า",
  i18nKey_1185: "การตรวจสอบกล้อง",
  i18nKey_1186: "กำลังโหลดกล้องโปรดรอสักครู่ ...",
  i18nKey_1187: "จำนวนกล้อง",
  i18nKey_1188: "กล้อง (a)",
  i18nKey_1189: "กล้อง",
  i18nKey_1190: "หมายเลขกล้อง",
  i18nKey_1191: "กล้องวิดีโอ",
  i18nKey_1192: "ตั้งค่าคำเตือน (วัน):",
  i18nKey_1193: "ตั้งค่าคำเตือน",
  i18nKey_1194: "การตั้งเวลาทำการสามารถยกเว้นข้อมูลการหลั่งไหลของผู้โดยสารในช่วงเวลาปิดทำการได้อย่างมีประสิทธิภาพ",
  i18nKey_1195: "ตั้งค่าตัวกดข้อความ:",
  i18nKey_1196: "ตั้งเป็นชั้นหลัก",
  i18nKey_1197: "ตั้งค่าเรียบร้อยแล้ว",
  i18nKey_1198: "จำนวนสถานที่ทั้งหมด",
  i18nKey_1199: "หมวดย่อยสถานที่",
  i18nKey_1200: "หมายเลขสินทรัพย์สถานที่",
  i18nKey_1201: "สถานะสถานที่",
  i18nKey_1202: "ข้อมูลการดำเนินงานของสถานที่",
  i18nKey_1203: "ระยะเวลาการใช้งานสถานที่",
  i18nKey_1204: "ที่ตั้งสถานที่",
  i18nKey_1205: "โทโพโลยีสถานที่",
  i18nKey_1206: "สถิติสถานที่",
  i18nKey_1207: "สถิติจำนวนสถานที่",
  i18nKey_1208: "ดูตัวอย่างข้อมูลสถานที่",
  i18nKey_1209: "การประสานข้อมูลสถานที่",
  i18nKey_1210: "การส่งออกข้อมูลสถานที่",
  i18nKey_1211: "สถิติเหตุการณ์สถานที่",
  i18nKey_1212: "สถิติการแจ้งเตือนเหตุการณ์สถานที่ (ตามประเภท)",
  i18nKey_1213: "สถิติการแจ้งเตือนเหตุการณ์สถานที่ (ตามระดับ)",
  i18nKey_1214: "สถิติของอัตราการจัดการสัญญาณเตือนเหตุการณ์สถานที่",
  i18nKey_1215: "การแจ้งเตือนเหตุการณ์สถานที่",
  i18nKey_1216: "เหตุการณ์สถานที่",
  i18nKey_1217: "ข้อมูลแผนผังสถานที่",
  i18nKey_1218: "แผนภาพสถานที่",
  i18nKey_1219: "ความยาวของชื่อสถานที่ต้องไม่เกิน 50 อักษร",
  i18nKey_1220: "ชื่อสถานที่ต้องไม่ว่างเปล่า",
  i18nKey_1221: "ชื่อสถานที่",
  i18nKey_1222: "คำอธิบายสถานที่ประกอบด้วยภาษาจีนและอักษรอังกฤษ ตัวเลขและ _ เท่านั้น",
  i18nKey_1223: "ความยาวของคำอธิบายสถานที่ต้องไม่เกิน 200 อักษร",
  i18nKey_1224: "คำอธิบายสถานที่",
  i18nKey_1225: "สถิติความพร้อมใช้งานของสถานที่",
  i18nKey_1226: "ความพร้อมของสถานที่",
  i18nKey_1227: "โครงสร้างพื้นฐานข้อมูลข้อมูลพื้นฐาน",
  i18nKey_1228: "การเข้ารหัสข้อมูลพื้นฐานของสถานที่",
  i18nKey_1229: "สถานะภาพสถานที่",
  i18nKey_1230: "ข้อมูลภาพสถานที่",
  i18nKey_1231: "เมื่อเปิดใช้งานภาพสถานที่ ชื่อจะต้องไม่ว่างเปล่า",
  i18nKey_1232: "ชื่อภาพสถานที่",
  i18nKey_1233: "ภาพสถานที่",
  i18nKey_1234: "จำนวนความล้มเหลวของสถานที่",
  i18nKey_1235: "ใบสั่งงานสถานที่",
  i18nKey_1236: "สถิติการเตือนภัยสถานที่",
  i18nKey_1237: "นามแฝงของสถานที่",
  i18nKey_1238: "ภาพรวมของการซ่อมแซมสถานที่",
  i18nKey_1239: "ซ่อมแซมสถานที่",
  i18nKey_1240: "ความสามารถในการออกแบบ",
  i18nKey_1241: "อันดับพลังงานของอุปกรณ์",
  i18nKey_1242: "รายละเอียดการตรวจสอบความผิดปกติของอุปกรณ์",
  i18nKey_1243: "อุปกรณ์แจ้งเตือนใหม่ผิดปกติ มีพัดลมดับ!",
  i18nKey_1244: "อุปกรณ์ผิดปกติ",
  i18nKey_1245: "ข้อมูลอุปกรณ์",
  i18nKey_1246: "บันทึกการส่งอุปกรณ์",
  i18nKey_1247: "เทมเพลตการนำเข้าข้อมูลอุปกรณ์",
  i18nKey_1248: "จำนวนอุปกรณ์",
  i18nKey_1249: "การจัดเรียงอุปกรณ์",
  i18nKey_1250: "ชื่ออุปกรณ์",
  i18nKey_1251: "สุขภาพของอุปกรณ์",
  i18nKey_1252: "นามแฝงของอุปกรณ์",
  i18nKey_1253: "ชื่อข้อสังเกตอุปกรณ์",
  i18nKey_1254: "IP ของอุปกรณ์",
  i18nKey_1255: "ID อุปกรณ์",
  i18nKey_1256: "อุปกรณ์",
  i18nKey_1257: "ปริมาณการใช้ไฟฟ้าจริงของสัปดาห์ที่แล้ว (กิโลวัตต์ - ชั่วโมง)",
  i18nKey_1258: "อาทิตย์ที่แล้ว",
  i18nKey_1259: "พลังงานที่ใช้เมื่อเดือนที่แล้ว",
  i18nKey_1260: "ช่วงเดียวกันของเดือนที่แล้ว",
  i18nKey_1261: "ปริมาณการใช้ไฟฟ้าจริงของเดือนที่แล้ว (KWh)",
  i18nKey_1262: "ค่าเฉลี่ยของเดือนที่แล้ว",
  i18nKey_1263: "การใช้พลังงานต่อตารางเมตรเมื่อเดือนที่แล้ว",
  i18nKey_1264: "การใช้พลังงานสะสมเมื่อเดือนที่แล้ว",
  i18nKey_1265: "เดือนที่แล้ว",
  i18nKey_1266: "ขยับขึ้น",
  i18nKey_1267: "พลังงานที่ใช้ในปีที่แล้ว",
  i18nKey_1268: "ปริมาณการใช้ไฟฟ้าในช่วงเดียวกันของปีก่อน",
  i18nKey_1269: "ช่วงเดียวกันของปีที่แล้ว",
  i18nKey_1270: "การใช้พลังงานต่อตารางเมตรเมื่อปีที่แล้ว",
  i18nKey_1271: "ปีที่แล้ว",
  i18nKey_1272: "ทิ้งลงไป",
  i18nKey_1273: "ตอนเช้า",
  i18nKey_1274: "ลุกขึ้น",
  i18nKey_1275: "วางบนหิ้ง",
  i18nKey_1276: "ขึ้น",
  i18nKey_1277: "Shanghai Jinqiao Wanchuang Center System 2",
  i18nKey_1278: "Shanghai Jinqiao Wanchuang Center System 1",
  i18nKey_1279: "ตำแหน่งสุดท้าย",
  i18nKey_1280: "เวลาที่รายงานล่าสุด",
  i18nKey_1281: "วันที่อ่านมิเตอร์ล่าสุด",
  i18nKey_1282: "การอ่านมิเตอร์ล่าสุด",
  i18nKey_1283: "อัปโหลดไฟล์ไม่สำเร็จ",
  i18nKey_1284: "รูปแบบภาพที่อัปโหลดไม่ถูกต้อง โปรดอัปโหลดอีกครั้ง",
  i18nKey_1285: "การอัปโหลดรูปภาพต้องมีขนาดไม่เกิน 3M โปรดอัปโหลดใหม่",
  i18nKey_1286: "อัปโหลดภาพ",
  i18nKey_1287: "อัปโหลดไดอะแกรม",
  i18nKey_1288: "อัปโหลดไม่สำเร็จ โปรดอัปโหลดอีกครั้ง",
  i18nKey_1289: "การอัพโหลดล้มเหลว",
  i18nKey_1290: "ผู้อัปโหลด",
  i18nKey_1291: "อัปโหลดรูปภาพ",
  i18nKey_1292: "อัปโหลดแบบฟอร์มข้อมูลที่อยู่อาศัย",
  i18nKey_1293: "ชื่อของรูปภาพที่อัปโหลดไม่เป็นมาตรฐาน อนุญาตให้ใช้เฉพาะตัวเลข ตัวอักษร ขีดล่างและเส้นแนวนอนเท่านั้น",
  i18nKey_1294: "เวลาในการรายงาน",
  i18nKey_1295: "โทรศัพท์ผู้สื่อข่าว",
  i18nKey_1296: "ผู้สื่อข่าว",
  i18nKey_1297: "การวิเคราะห์การใช้พลังงานของผู้เช่าเชิงพาณิชย์",
  i18nKey_1298: "เป้าหมายธุรกิจ",
  i18nKey_1299: "ธุรกิจ",
  i18nKey_1300: "สถานะร้านค้า",
  i18nKey_1301: "ที่ตั้งร้าน",
  i18nKey_1302: "เบอร์ร้าน",
  i18nKey_1303: "รายละเอียดสินค้า",
  i18nKey_1304: "ชื่อธุรกิจ:",
  i18nKey_1305: "การจราจรในห้างสรรพสินค้า",
  i18nKey_1306: "การเลือกผู้ค้า / ผู้เช่า",
  i18nKey_1307: "ชื่อธุรกิจ / ผู้เช่า ",
  i18nKey_1308: "การจัดอันดับการใช้พลังงานต่อตารางเมตรของธุรกิจผู้เช่า/ธุรกิจ",
  i18nKey_1309: "ต้องปิดราคาก่อนจึงจะลบข้อมูลได้!",
  i18nKey_1310: "ในการลบข้อมูลคุณต้องหยุดสถานีก่อน!",
  i18nKey_1311: "รายละเอียดทั้งหมดของอุปกรณ์จะถูกลบออกหลังจากทำการลบ คุณแน่ใจหรือว่าต้องการลบ?",
  i18nKey_1312: "คุณแน่ใจหรือว่าต้องการลบ? เนื้อหาภายใต้โหนดนี้จะถูกลบหลังจากการลบ ขอแนะนำให้คุณย้ายข้อมูลก่อนแล้วจึงลบ",
  i18nKey_1313: "ไม่สามารถกู้คืนได้หลังจากลบ คุณแน่ใจหรือไม่ว่าจะลบ?",
  i18nKey_1314: "ลบการเชื่อมโยงไม่สำเร็จ",
  i18nKey_1315: "หลังจากลบ thi",
  i18nKey_1316: "การลบสำเร็จและร้านค้าทั้งหมดภายใต้โหนดจะถูกลบออกจากรายการเช่าด้วย",
  i18nKey_1317: "ลบ",
  i18nKey_1318: "สแกนเพื่อดาวน์โหลดแอพ Yuncheng",
  i18nKey_1319: "สแกนรหัส QR",
  i18nKey_1320: "สแกนรหัสอุปกรณ์",
  i18nKey_1321: "สแกนรหัสเข้าสู่ระบบ",
  i18nKey_1322: "ประกอบไปด้วยลิเธียม",
  i18nKey_1323: "สามประเภท",
  i18nKey_1324: "แผนกตติยภูมิ",
  i18nKey_1325: "ระดับ 3",
  i18nKey_1326: "สาม",
  i18nKey_1327: "หากไม่มีหมายเลขประจำตัวต้องใช้หมายเลขหนังสือเดินทาง",
  i18nKey_1328: "ประเภทการรับเข้า",
  i18nKey_1329: "หากมีจุดแวะพักหลายจุดกรุณากรอกรูปแบบต่อไปนี้: Vanke Yuncheng-Bao'an Center-Qianhai Bay-Bao'an Airport",
  i18nKey_1330: "ความจุ",
  i18nKey_1331: "YoY",
  i18nKey_1332: "วัน",
  i18nKey_1333: "มีหนทางอีกยาวไกลและความทะเยอทะยานจะต้องได้รับชัยชนะ",
  i18nKey_1334: "สถิติสถานะงาน",
  i18nKey_1335: "สถานะงาน",
  i18nKey_1336: "กำลังติดตามงาน การข้ามไปที่หน้าอื่นจะทำให้งานการติดตามหยุดลง แน่ใจไหมว่าต้องข้ามไปที่หน้านี้",
  i18nKey_1337: "เจ้าของงาน",
  i18nKey_1338: "ผู้มอบหมายงาน",
  i18nKey_1339: "จำนวนงาน",
  i18nKey_1340: "งานกำลังเริ่มโปรดอย่าลบ",
  i18nKey_1341: "ชื่องานต้องไม่ว่างเปล่า",
  i18nKey_1342: "ชื่อภารกิจ",
  i18nKey_1343: "หมายเลขงาน",
  i18nKey_1344: "บริษัทที่ได้รับการรับรอง",
  i18nKey_1345: "ภาพรวมบุคลากร (วัน)",
  i18nKey_1346: "การติดตามบุคลากร",
  i18nKey_1347: "สถิติสัดส่วนบุคลากร (ในวันนั้น)",
  i18nKey_1348: "การป้อนชื่อภาษาอังกฤษของบุคลากรด้วยอักษรพิเศษ",
  i18nKey_1349: "บุคลากรย้ายไป",
  i18nKey_1350: "ป้อนชื่อบุคคลด้วยอักษรพิเศษ",
  i18nKey_1351: "ชื่อบุคลากรต้องไม่ว่างเปล่า",
  i18nKey_1352: "ชื่อบุคคล",
  i18nKey_1353: "ข้อมูลบุคลากร",
  i18nKey_1354: "ภาพพนักงาน",
  i18nKey_1355: "สถิติบุคลากร (โดยสวนสาธารณะ)",
  i18nKey_1356: "สถิติบุคลากร (ตามประเภท)",
  i18nKey_1357: "สถิติผู้คน",
  i18nKey_1358: "การจัดอันดับบุคลากร (เมื่อวาน / วันก่อนหน้า)",
  i18nKey_1359: "ต้องระบุหมายเลขโทรศัพท์มือถือของบุคลากร",
  i18nKey_1360: "อุณหภูมิของพนักงาน",
  i18nKey_1361: "ประเภทบุคคล",
  i18nKey_1362: "การเลือกกลุ่มบุคลากร",
  i18nKey_1363: "ข้อมูลรายละเอียดพื้นฐานของบุคลากร",
  i18nKey_1364: "สร้างข้อมูลพื้นฐานของบุคลากรสำเร็จแล้ว",
  i18nKey_1365: "การเข้ารหัสข้อมูลพื้นฐานของบุคลากร",
  i18nKey_1366: "มีตักอักษรพิเศษในการป้อนหมายเลขข้อมูลพื้นฐานของบุคลากร กรุณากรอกใหม่",
  i18nKey_1367: "หมายเลขข้อมูลพื้นฐานของบุคลากร",
  i18nKey_1368: "ติดตามผู้คน",
  i18nKey_1369: "การบริหารงานบุคคล",
  i18nKey_1370: "แนวโน้มการเปรียบเทียบบุคลากร (ใกล้สองสัปดาห์)",
  i18nKey_1371: "แท็กบุคคล",
  i18nKey_1372: "รหัสบุคคล",
  i18nKey_1373: "บุคลากร (12 สัปดาห์ล่าสุด)",
  i18nKey_1374: "บุคลากร (12 เดือนล่าสุด)",
  i18nKey_1375: "ภาพรวมของผู้คน",
  i18nKey_1376: "สถิติการหลั่งไหลของผู้คน",
  i18nKey_1377: "เริ่มการติดตามใบหน้าไม่สำเร็จ",
  i18nKey_1378: "การติดตามใบหน้า",
  i18nKey_1379: "ใบหน้าผ่าน",
  i18nKey_1380: "Face ID",
  i18nKey_1381: "ใบหน้าของมนุษย์",
  i18nKey_1382: "ครั้งบุคคล",
  i18nKey_1383: "คน",
  i18nKey_1384: "การจัดอันดับจำนวนผู้โดยสารทั้งหมดในพื้นที่ยอดนิยม",
  i18nKey_1385: "ช่วงการแพร่กระจายความร้อน",
  i18nKey_1386: "จุดร้อน",
  i18nKey_1387: "ยืนยันการเปลี่ยนแปลง",
  i18nKey_1388: "ยืนยันรหัสผ่านใหม่",
  i18nKey_1389: "ยืนยันเพื่อเพิ่มผู้ใช้",
  i18nKey_1390: "ยืนยันที่จะยอมแพ้?",
  i18nKey_1391: "ยืนยันว่าสัญญาณเตือนนี้เป็นการพิจารณาที่ไม่ถูกต้อง ข้อมูลการเตือนนี้ไม่ถูกต้อง",
  i18nKey_1392: "แน่ใจหรือว่าจะรีเซ็ตบ้านหลังนี้",
  i18nKey_1393: "คุณแน่ใจหรือที่จะรีเซ็ตด้วยคลิกเดียว",
  i18nKey_1394: "แน่ใจหรือว่าต้องการตั้งพื้นหลัก",
  i18nKey_1395: "แน่ใจไหมว่าต้องการลบที่อยู่นี้",
  i18nKey_1396: "แน่ใจไหมว่าต้องการเปิดวงจร $ ทั้งหมด",
  i18nKey_1397: "แน่ใจไหมว่าต้องการปิดวงจร $ ทั้งหมด",
  i18nKey_1398: "แน่ใจไหมว่าต้องการเปิดลูป $ นี้",
  i18nKey_1399: "แน่ใจไหมว่าต้องการปิดลูป $ เหล่านี้",
  i18nKey_1400: "แน่ใจหรือไม่ว่าจะลบอุปกรณ์",
  i18nKey_1401: "ลบผู้ใช้นี้แน่นอน",
  i18nKey_1402: "แน่ใจไหมว่าจะลบห้อง",
  i18nKey_1403: "คุณแน่ใจหรือไม่ว่าจะลบอุปกรณ์จำนวนมากเหล่านี้",
  i18nKey_1404: "คุณแน่ใจหรือไม่ว่าจะลบประเภทอพาร์ทเมนต์จำนวนมากเหล่านี้",
  i18nKey_1405: "คุณแน่ใจหรือไม่ว่าจะลบบัญชีดำ",
  i18nKey_1406: "ตรวจสอบว่าสัญญาณเตือนนี้เป็นผลบวกลวง",
  i18nKey_1407: "ตลอดทั้งวัน (24 ชั่วโมง)",
  i18nKey_1408: "เล่นแบบเต็มหน้าจอ",
  i18nKey_1409: "สวิตช์ควบคุมแบบเต็ม",
  i18nKey_1410: "เปิดทั้งหมด",
  i18nKey_1411: "ห้องประชุมทั้งหมด",
  i18nKey_1412: "ปิดทั้งหมด",
  i18nKey_1413: "เดือนที่รับผิดชอบต้องไม่ว่างเปล่า",
  i18nKey_1414: "เดือนแห่งอำนาจ",
  i18nKey_1415: "การอนุญาตของทรัพยากร",
  i18nKey_1416: "เวลาเดียวกันของปีที่แล้ว",
  i18nKey_1417: "ขีดจำกัดการขอคืนเงินต้องไม่เกิน 100%!",
  i18nKey_1418: "กฎการยกเลิกไม่เกิน 10 ข้อ!",
  i18nKey_1419: "ความร้อนในภูมิภาค",
  i18nKey_1420: "ชื่อพื้นที่",
  i18nKey_1421: "การจัดอันดับการใช้พลังงานในภูมิภาคต่อตารางเมตร",
  i18nKey_1422: "ประเภทพื้นที่ต้องไม่ว่างเปล่า",
  i18nKey_1423: "ประเภทพื้นที่",
  i18nKey_1424: "การหลั่งไหลของผู้โดยสารในภูมิภาค",
  i18nKey_1425: "การจัดอันดับการเยี่ยมชมระดับภูมิภาค",
  i18nKey_1426: "พื้นที่",
  i18nKey_1427: "บล็อก",
  i18nKey_1428: "โปรดเลือกกล้องอย่างน้อยหนึ่งตัว",
  i18nKey_1429: "โปรดเลือกคนอย่างน้อยหนึ่งคน",
  i18nKey_1430: "โปรดเพิ่มอย่างน้อยหนึ่งคนเพื่อบันทึก",
  i18nKey_1431: "กรุณากรอกรหัสผ่านใหม่อีกครั้ง",
  i18nKey_1432: "กรุณากรอกรหัสผ่านอีกครั้ง",
  i18nKey_1433: "โปรดเลือกช่วงวันที่เช่า",
  i18nKey_1434: "โปรดเลือกประเภทเนื้อหา",
  i18nKey_1435: "โปรดเลือกธีม",
  i18nKey_1436: "โปรดเลือกระดับที่สำคัญ",
  i18nKey_1437: "โปรดเลือกวิธีการทำซ้ำ",
  i18nKey_1438: "โปรดเลือกโหมดแสงสว่าง",
  i18nKey_1439: "โปรดเลือกผู้จัดการที่รับผิดชอบ",
  i18nKey_1440: "โปรดเลือกเวลาที่จะระงับ",
  i18nKey_1441: "โปรดเลือกช่วงเวลาการเยี่ยมชมที่อนุญาต",
  i18nKey_1442: "โปรดเลือกเวลาการเริ่มต้นการจอง",
  i18nKey_1443: "โปรดเลือกเวลาสิ้นสุดของการจอง",
  i18nKey_1444: "โปรดเลือกกลยุทธ์พิเศษ",
  i18nKey_1445: "กรุณาเลือกระบบการสมัคร",
  i18nKey_1446: "โปรดเลือกเวลาเริ่มต้นธุรกิจ",
  i18nKey_1447: "กรุณาเลือกเวลาปิดทำการ",
  i18nKey_1448: "โปรดเลือกประเภทข้อยกเว้น",
  i18nKey_1449: "โปรดเลือกเครื่องมือที่เป็นของ",
  i18nKey_1450: "โปรดเลือกยี่ห้อเครื่องมือ",
  i18nKey_1451: "โปรดเลือกหมวดหมู่",
  i18nKey_1452: "โปรดเลือกแผนก",
  i18nKey_1453: "โปรดเลือกรูปแบบ",
  i18nKey_1454: "โปรดเลือกรถที่จะทำการลบ",
  i18nKey_1455: "โปรดเลือกสถานที่ที่จะเชื่อมต่อ",
  i18nKey_1456: "โปรดเลือกไฟล์ที่จะอัปโหลด",
  i18nKey_1457: "โปรดเลือกภาพแรกของห้องประชุมที่จะอัปโหลด!",
  i18nKey_1458: "โปรดเลือกภาพแรกของสถานที่ทำงานที่จะอัปโหลด!",
  i18nKey_1459: "โปรดเลือกรูปภาพแบนเนอร์เพื่ออัปโหลด!",
  i18nKey_1460: "โปรดเลือกกลยุทธ์ที่จะลบ",
  i18nKey_1461: "โปรดเลือกความสว่างของการนอนหลับ",
  i18nKey_1462: "กรุณาเลือกเวลาสั่งซื้อ",
  i18nKey_1463: "โปรดเลือกการจัดประเภทมีให้เลือกห้าระดับ",
  i18nKey_1464: "โปรดเลือกแผนกมีให้เลือกห้าระดับ",
  i18nKey_1465: "โปรดเลือกสถานที่",
  i18nKey_1466: "โปรดเลือกเวลาหยุด",
  i18nKey_1467: "โปรดเลือกทาง",
  i18nKey_1468: "กรุณาเลือกที่จอดรถ",
  i18nKey_1469: "กรุณาเลือกประเภทแพ็กเกจ",
  i18nKey_1470: "โปรดเลือกระดับสี่",
  i18nKey_1471: "โปรดเลือกแผนกระดับสี่",
  i18nKey_1472: "กรุณาเลือกการกรอกจำนวนเงินที่ชำระจริง",
  i18nKey_1473: "กรุณาป้อนจำนวนการสั่งซื้อ",
  i18nKey_1474: "โปรดเลือกเวลาเริ่มต้นและเวลาสิ้นสุดในการให้สิทธิ์",
  i18nKey_1475: "โปรดเลือกว่าเป็นอุปกรณ์ดีบักหรือไม่",
  i18nKey_1476: "โปรดเลือกว่าจะแสดงรายการหรือไม่",
  i18nKey_1477: "โปรดเลือกสถานะเหตุการณ์",
  i18nKey_1478: "โปรดเลือกประเภทเหตุการณ์",
  i18nKey_1479: "โปรดเลือกระดับเหตุการณ์",
  i18nKey_1480: "โปรดเลือกระดับเหตุการณ์",
  i18nKey_1481: "โปรดเลือกช่วงเวลา",
  i18nKey_1482: "กรุณาเลือกเวลา",
  i18nKey_1483: "โปรดเลือกประเภทลิฟต์",
  i18nKey_1484: "กรุณาเลือกสถานะการสมัคร",
  i18nKey_1485: "โปรดเลือกกล้อง",
  i18nKey_1486: "โปรดเลือกสถานะสิ่งอำนวยความสะดวก",
  i18nKey_1487: "โปรดเลือกสถานที่ตั้ง",
  i18nKey_1488: "โปรดเลือกสถานะภาพสถานที่",
  i18nKey_1489: "โปรดเลือกชื่อภาพสถานที่",
  i18nKey_1490: "กรุณาเลือกอุปกรณ์",
  i18nKey_1491: "โปรดเลือกสถานะร้านค้า",
  i18nKey_1492: "กรุณาเลือกที่ตั้งร้าน",
  i18nKey_1493: "โปรดเลือกผู้ขาย",
  i18nKey_1494: "โปรดเลือกที่จะลบข้อมูล",
  i18nKey_1495: "โปรดเลือกเกณฑ์การกรอง",
  i18nKey_1496: "โปรดเลือกอุปกรณ์สแกนรหัส",
  i18nKey_1497: "โปรดเลือกการจัดประเภทระดับสาม",
  i18nKey_1498: "โปรดเลือกแผนกระดับสาม",
  i18nKey_1499: "โปรดเลือกช่วงวันที่",
  i18nKey_1500: "โปรดเลือกสถานะงาน",
  i18nKey_1501: "กรุณาเลือกประเภทบุคลากร",
  i18nKey_1502: "กรุณาเลือกบุคลากร",
  i18nKey_1503: "กรุณาเลือกเดือนที่รับผิดชอบ",
  i18nKey_1504: "โปรดเลือกประเภทพื้นที่",
  i18nKey_1505: "โปรดเลือกภูมิภาค",
  i18nKey_1506: "โปรดเลือกประเภทของผู้ขายที่ค้างชำระ",
  i18nKey_1507: "โปรดเลือกสถานะที่เปิดใช้งาน",
  i18nKey_1508: "โปรดเลือกยี่ห้อ",
  i18nKey_1509: "โปรดเลือกเวลาจัดส่ง",
  i18nKey_1510: "โปรดเลือกช่วงอายุ",
  i18nKey_1511: "โปรดเลือกปี",
  i18nKey_1512: "กรุณาเลือกหมายเลขที่ต้องการล็อค",
  i18nKey_1513: "โปรดเลือกเส้นทาง",
  i18nKey_1514: "โปรดเลือกอาคาร",
  i18nKey_1515: "โปรดเลือกหน่วยอาคาร",
  i18nKey_1516: "โปรดเลือกอาคาร",
  i18nKey_1517: "โปรดเลือกพื้นที่ชั้น",
  i18nKey_1518: "กรุณาเลือกชั้น",
  i18nKey_1519: "โปรดเลือกการจัดประเภทระดับ ุ6",
  i18nKey_1520: "กรุณาเลือกประเภท",
  i18nKey_1521: "โปรดเลือกรหัสระบบต้นทาง",
  i18nKey_1522: "โปรดเลือกเวลาเริ่มต้น",
  i18nKey_1523: "โปรดเลือกวันที่เริ่มต้น",
  i18nKey_1524: "กรุณาเลือกเวลาจอดรถ",
  i18nKey_1525: "โปรดเลือกเวลาเข้า",
  i18nKey_1526: "โปรดเลือกเวลาสิ้นสุด",
  i18nKey_1527: "โปรดเลือกประเภทของบทบาท",
  i18nKey_1528: "โปรดเลือกประเภทบทบาท",
  i18nKey_1529: "โปรดเลือกประเภทการเรียกเก็บเงิน",
  i18nKey_1530: "โปรดเลือกระดับ",
  i18nKey_1531: "โปรดเลือกลิฟต์ขนส่ง",
  i18nKey_1532: "โปรดเลือกสถานะกิจกรรม",
  i18nKey_1533: "โปรดเลือกวงจร!",
  i18nKey_1534: "โปรดเลือกผลลัพธ์",
  i18nKey_1535: "โปรดเลือกสัญญา",
  i18nKey_1536: "โปรดเลือกประเทศ",
  i18nKey_1537: "โปรดเลือกใบหน้าที่เกี่ยวข้องกับห้องสมุด",
  i18nKey_1538: "โปรดเลือกระดับความผิดปกติ",
  i18nKey_1539: "โปรดเลือกประเภทพื้นที่สาธารณะ",
  i18nKey_1540: "โปรดเลือกสถานะสถานี",
  i18nKey_1541: "โปรดเลือกประเภทสถานที่ทำงาน!",
  i18nKey_1542: "โปรดเลือกสถานะตั๋ว",
  i18nKey_1543: "โปรดเลือกการตรวจจับความสว่าง",
  i18nKey_1544: "กรุณาเลือกประเภทของบ้าน",
  i18nKey_1545: "โปรดเลือกวิธีการชำระเงิน",
  i18nKey_1546: "โปรดเลือกแผนกย่อย",
  i18nKey_1547: "กรุณาเลือกงวดผ่อน",
  i18nKey_1548: "โปรดเลือกสถานะผู้เยี่ยมชม",
  i18nKey_1549: "โปรดเลือกตำแหน่งห้อง",
  i18nKey_1550: "โปรดเลือกช่วงเวลาสำหรับคำเชิญ",
  i18nKey_1551: "โปรดเลือกประเภทปัญหา",
  i18nKey_1552: "โปรดเลือกการจัดการประเภทรอง",
  i18nKey_1553: "โปรดเลือกแผนกมัธยมศึกษา",
  i18nKey_1554: "โปรดเลือกสถานะการสั่งซื้อ",
  i18nKey_1555: "โปรดเลือกประเภทเป้าหมาย",
  i18nKey_1556: "โปรดเลือกสถานที่",
  i18nKey_1557: "โปรดเลือกที่่จอดรถ",
  i18nKey_1558: "กรุณาเลือกภูมิภาค",
  i18nKey_1559: "โปรดเลือกเมือง",
  i18nKey_1560: "โปรดเลือกหน่วย",
  i18nKey_1561: "โปรดเลือกกลไกการจัดการ",
  i18nKey_1562: "โปรดเลือกเวลาเริ่มต้น",
  i18nKey_1563: "กรุณาเลือกเวลาจอดรถ",
  i18nKey_1564: "กรุณาเลือกประเภทการเติมเงิน",
  i18nKey_1565: "โปรดเลือกเวลาเดินทาง",
  i18nKey_1566: "โปรดเลือกเมือง",
  i18nKey_1567: "โปรดเลือกประเภทเจ้าของ",
  i18nKey_1568: "โปรดเลือกแท็กที่ติดตามรถ",
  i18nKey_1569: "โปรดเลือกชื่อคลัง",
  i18nKey_1570: "กรุณาเลือกที่จอดรถ",
  i18nKey_1571: "โปรดเลือกไอคอนฉาก",
  i18nKey_1572: "โปรดเลือกกลยุทธ์สถานการณ์",
  i18nKey_1573: "โปรดเลือกสัญลักษณ์ของฉาก",
  i18nKey_1574: "โปรดเลือกผลิตภัณฑ์",
  i18nKey_1575: "โปรดเลือกกลยุทธ์ที่เกี่ยวข้องกับการจอดรถ",
  i18nKey_1576: "โปรดเลือกกลยุทธ์ที่เกี่ยวข้องไฟถนน",
  i18nKey_1577: "โปรดเลือกสถานะการปรับใช้",
  i18nKey_1578: "โปรดเลือกกล้องสำหรับการปรับใช้",
  i18nKey_1579: "โปรดเลือกประเภทการปรับใช้",
  i18nKey_1580: "โปรดเลือกอย่างน้อย 1 ใบ",
  i18nKey_1581: "โปรดเลือกบัตรไม่เกิน $",
  i18nKey_1582: "โปรดเลือกไม่เกิน",
  i18nKey_1583: "กรุณาเลือกตารางเดินรถ",
  i18nKey_1584: "กรุณาเลือกเวลาในการติดตั้ง",
  i18nKey_1585: "โปรดเลือกประเภทพิกัด GIS",
  i18nKey_1586: "โปรดเลือก",
  i18nKey_1587: "โปรดเลือกชื่อห้องประชุมก่อน",
  i18nKey_1588: "โปรดเลือกข้อมูลที่เกี่ยวข้องก่อน",
  i18nKey_1589: "โปรดบันทึกการเปลี่ยนแปลงก่อน",
  i18nKey_1590: "อย่าเพิ่มคนซ้ำ ๆ",
  i18nKey_1591: "อย่าเติมซ้ำ ๆ",
  i18nKey_1592: "ห้ามใส่อักษรพิเศษ!",
  i18nKey_1593: "ห้ามใส่อักษรพิเศษ",
  i18nKey_1594: "ไม่เกิน 6",
  i18nKey_1595: "กรุณากรอกข้อมูลอุปกรณ์",
  i18nKey_1596: "กรุณากรอกข้อมูลในช่องที่จำเป็น",
  i18nKey_1597: "กรุณากรอกรายละเอียดกิจกรรม",
  i18nKey_1598: "โปรดกรอก บริษัท ที่ได้รับการรับรอง",
  i18nKey_1599: "กรุณากรอกหมายเลขป้ายทะเบียน",
  i18nKey_1600: "กรุณากรอกสีรถ",
  i18nKey_1601: "กรุณากรอกยี่ห้อรถ",
  i18nKey_1602: "กรุณากรอกชื่อกลยุทธ์",
  i18nKey_1603: "โปรดเพิ่ม บริษัท ที่เกี่ยวข้อง!",
  i18nKey_1604: "โปรดเพิ่มแท็กบุคคล",
  i18nKey_1605: "กรุณาเพิ่มเส้นทาง",
  i18nKey_1606: "โปรดค้นหาหรือเลือก บริษัท",
  i18nKey_1607: "กรุณากรอกจำนวนการเตือนขั้นต่ำ",
  i18nKey_1608: "กรุณากรอกค่าเช่าขั้นต่ำ",
  i18nKey_1609: "กรุณากรอกจำนวนการเตือนสูงสุด",
  i18nKey_1610: "กรุณากรอกอักษรไม่เกิน 2 ~ 50 ตัว อักษรจีนหนึ่งตัวนับเป็นสองอักษร",
  i18nKey_1611: "กรุณากรอกอักษรไม่เกิน 2 ~ 10 ตัวอักษรจีนหนึ่งตัวนับเป็นสองอักษร",
  i18nKey_1612: "กรุณากรอกอักษรไม่เกิน 1 ~ 50 ตัวอักษรจีนหนึ่งตัวนับเป็นสองอักษรและไม่อนุญาตให้เว้นวรรค!",
  i18nKey_1613: "กรุณากรอกค่าแอมแปร์สวิตช์ทั้งหมด",
  i18nKey_1614: "กรุณากรอกการกำหนดเวลารีเฟรช QR โค้ด",
  i18nKey_1615: "กรุณากรอกคำอธิบายเนื้อหา",
  i18nKey_1616: "กรุณากรอกหมายเลขสินทรัพย์",
  i18nKey_1617: "กรุณาใส่หมายเลขโทรศัพท์เพื่อขอคำปรึกษา",
  i18nKey_1618: "กรุณาเข้าสู่เทอร์มินัล",
  i18nKey_1619: "กรุณาเข้าสู่รอบการออกคำสั่ง",
  i18nKey_1620: "กรุณากรอกชื่อสาขา",
  i18nKey_1621: "กรุณากรอกหมายเลขคำสั่งชำระเงิน",
  i18nKey_1622: "กรุณากรอกรายจ่าย / รายรับ",
  i18nKey_1623: "กรุณากรอกจำนวนเต็มบวก",
  i18nKey_1624: "กรุณากรอกจำนวนบวก (ทศนิยมไม่เกินสองตำแหน่ง)!",
  i18nKey_1625: "กรุณากรอกหมายเลขโทรศัพท์มือถือรูปแบบที่ถูกต้องเพื่อค้นหา",
  i18nKey_1626: "กรุณากรอกหมายเลขโทรศัพท์ในรูปแบบที่ถูกต้อง",
  i18nKey_1627: "กรุณากรอกรหัสยืนยันที่ถูกต้อง",
  i18nKey_1628: "กรุณากรอกชื่อและหมายเลขโทรศัพท์ที่ถูกต้อง",
  i18nKey_1629: "กรุณากรอกหมายเลขโทรศัพท์ที่ถูกต้อง",
  i18nKey_1630: "กรุณากรอกหมายเลขโทรศัพท์ที่ถูกต้อง",
  i18nKey_1631: "กรุณากรอกหมายเลขประจำตัวที่ถูกต้อง",
  i18nKey_1632: "กรุณากรอกหมายเลขหนังสือเดินทางที่ถูกต้อง",
  i18nKey_1633: "กรุณากรอกหมายเลขโทรศัพท์ที่ถูกต้อง",
  i18nKey_1634: "กรุณากรอกจำนวนครั้งที่ถูกต้อง",
  i18nKey_1635: "กรุณากรอกหมายเลขป้ายทะเบียนหรือหมายเลขโทรศัพท์มือถือที่ถูกต้อง",
  i18nKey_1636: "กรุณากรอกหมายเลขป้ายทะเบียนที่ถูกต้อง",
  i18nKey_1637: "กรุณากรอกชื่อข้อสังเกตที่ถูกต้อง",
  i18nKey_1638: "กรุณาใส่เบอร์โทรศัพท์สำนักงานที่ถูกต้อง",
  i18nKey_1639: "กรุณากรอกบัญชีหรือหมายเลขโทรศัพท์มือถือ",
  i18nKey_1640: "กรุณากรอกเปอร์เซ็นต์",
  i18nKey_1641: "กรุณากรอกรหัสผ่านเดิม",
  i18nKey_1642: "กรุณากรอกชื่อที่ี่จอดรถ",
  i18nKey_1643: "กรุณากรอกบัญชีโดเมน",
  i18nKey_1644: "กรุณากรอกค่าคำเตือน",
  i18nKey_1645: "กรุณากรอกเวลาเริ่มต้นที่กำหนดไว้",
  i18nKey_1646: "กรุณากรอกชื่อผู้จอง",
  i18nKey_1647: "กรุณากรอกยี่ห้อเครื่องมือ",
  i18nKey_1648: "กรุณากรอกชื่อมิเตอร์",
  i18nKey_1649: "กรุณากรอกหมายเลขมิเตอร์ ชื่ออุปกรณ์ ชื่อธุรกิจ / ชื่อผู้เช่า",
  i18nKey_1650: "กรุณากรอกรหัสมิเตอร์ / เลขที่ห้อง / ชั้น",
  i18nKey_1651: "กรุณากรอกรหัสมิเตอร์ / หมายเลข / ชื่อ",
  i18nKey_1652: "กรุณากรอกหมายเลขมิเตอร์ / หน่วยอาคาร",
  i18nKey_1653: "กรุณากรอกหมายเลขมิเตอร์",
  i18nKey_1654: "กรุณากรอกชื่อธุรกิจ",
  i18nKey_1655: "กรุณากรอกรหัสยืนยัน",
  i18nKey_1656: "กรุณากรอกคำอธิบายเส้นทางตรวจตรา",
  i18nKey_1657: "กรุณากรอกเวลาตรวจตรา",
  i18nKey_1658: "กรุณาใส่ผู้ตรวจตรา",
  i18nKey_1659: "กรุณากรอกคำอธิบายเส้นทางตรวจตรา",
  i18nKey_1660: "กรุณากรอกชื่อเส้นทางตรวจตรา",
  i18nKey_1661: "กรุณากรอกจำนวนวันที่จะนับ",
  i18nKey_1662: "กรุณากรอกจำนวนวันทั้งหมดที่จะตรวจสอบ",
  i18nKey_1663: "กรุณากรอกรหัสผ่านใหม่",
  i18nKey_1664: "กรุณากรอกตัวเลขที่น้อยกว่า 10",
  i18nKey_1665: "กรุณากรอกราคาที่น้อยกว่า 1 ~ 20000",
  i18nKey_1666: "กรุณากรอกชั่วโมง / วัน",
  i18nKey_1667: "กรุณากรอกชื่อเขตเพลิงไหม้",
  i18nKey_1668: "กรุณากรอกที่อยู่โดยละเอียด (ให้ถูกต้องตามหมายเลขจังหวัด เมืองและอำเภอ)",
  i18nKey_1669: "กรุณากรอกที่อยู่โดยละเอียด",
  i18nKey_1670: "กรุณากรอกราคาที่แสดง",
  i18nKey_1671: "กรุณากรอกจำนวนการละเมิด",
  i18nKey_1672: "กรุณากรอกชื่อนามสกุลหรือหมายเลขโทรศัพท์ของคุณ",
  i18nKey_1673: "กรุณากรอกชื่อสถานที่ภายนอก",
  i18nKey_1674: "กรุณากรอกชื่อที่จอดรถ",
  i18nKey_1675: "กรุณากรอกที่อยู่ที่จอดรถ",
  i18nKey_1676: "กรุณากรอกจำนวนแพ็คเกจ",
  i18nKey_1677: "กรุณากรอกจำนวนครั้งที่ใช้แพ็กเกจ",
  i18nKey_1678: "กรุณากรอกชื่อแพ็กเกจ",
  i18nKey_1679: "กรุณาเข้าสู่ บริษัท",
  i18nKey_1680: "กรุณาใส่คำค้นหา",
  i18nKey_1681: "กรุณากรอกค่าตัวเลข",
  i18nKey_1682: "กรุณากรอกตัวเลข",
  i18nKey_1683: "กรุณากรอกจำนวน",
  i18nKey_1684: "กรุณากรอก บริษัท ที่สัมภาษณ์",
  i18nKey_1685: "กรุณากรอกที่อยู่ที่สัมภาษณ์",
  i18nKey_1686: "กรุณากรอกโทรศัพท์เพื่อค้นหาคู่",
  i18nKey_1687: "กรุณากรอกหมายเลขโทรศัพท์ของคุณเพื่อค้นหา",
  i18nKey_1688: "กรุณากรอกหมายเลขโทรศัพท์มือถือของคุณเพื่อค้นหา",
  i18nKey_1689: "กรุณากรอกหมายเลขโทรศัพท์มือถือหรือบัญชีโดเมนของคุณ",
  i18nKey_1690: "กรุณากรอกหมายเลขโทรศัพท์",
  i18nKey_1691: "กรุณากรอกชื่อบัญชีเครดิต",
  i18nKey_1692: "กรุณากรอกหมายเลขโทรศัพท์มือถือของคุณ",
  i18nKey_1693: "กรุณากรอกคำแนะนำการเรียกเก็บเงิน",
  i18nKey_1694: "กรุณากรอกหมายเลขเหตุการณ์",
  i18nKey_1695: "กรุณากรอกนามแฝงสถานที่",
  i18nKey_1696: "กรุณากรอกหมายเลขสถานที่",
  i18nKey_1697: "กรุณากรอกนามแฝงอุปกรณ์",
  i18nKey_1698: "กรุณากรอกหมายเลขโทรศัพท์ของผู้สื่อข่าว",
  i18nKey_1699: "กรุณาชื่อนักข่าว",
  i18nKey_1700: "กรุณาใส่หมายเลขร้านค้า",
  i18nKey_1701: "กรุณากรอกชื่อธุรกิจ",
  i18nKey_1702: "กรุณากรอกชื่อธุรกิจ / ชื่อผู้เช่า",
  i18nKey_1703: "กรุณากรอกความจุต้องเป็นจำนวนเต็ม",
  i18nKey_1704: "กรุณากรอกความจุ",
  i18nKey_1705: "กรุณากรอกเจ้าของงาน",
  i18nKey_1706: "กรุณากรอกผู้รับมอบหมายงาน",
  i18nKey_1707: "กรุณากรอกชื่องาน",
  i18nKey_1708: "กรุณากรอกคำอธิบายงาน",
  i18nKey_1709: "กรุณากรอกชื่องานหรือบุคคล",
  i18nKey_1710: "กรุณากรอกหมายเลขงาน",
  i18nKey_1711: "กรุณากรอกหมายเลขข้อมูลพื้นฐานของบุคลากร",
  i18nKey_1712: "กรุณากรอกช่วงความร้อน",
  i18nKey_1713: "กรุณากรอกข้อมูลที่ต้องการบล็อก",
  i18nKey_1714: "กรุณากรอกจุดเริ่มต้น",
  i18nKey_1715: "กรุณากรอกชื่อจีนของ บริษัท",
  i18nKey_1716: "กรุณากรอกคำต่อท้ายอีเมลธุรกิจ",
  i18nKey_1717: "กรุณาเข้าสู่เว็บไซต์ของ บริษัท",
  i18nKey_1718: "กรุณากรอกชื่อ บริษัท",
  i18nKey_1719: "กรุณากรอกหมายเลขโทรศัพท์ของ บริษัท",
  i18nKey_1720: "กรุณากรอกข้อมูลแนะนำ บริษัท",
  i18nKey_1721: "กรุณากรอกตั๋ว / ราคาต่อหน่วย",
  i18nKey_1722: "กรุณากรอกหมายเลขแบทช์",
  i18nKey_1723: "กรุณากรอกชื่อเล่นหรือค้นหาหมายเลขโทรศัพท์",
  i18nKey_1724: "กรุณากรอกชื่อเล่น",
  i18nKey_1725: "กรุณาใส่เนื้อหา",
  i18nKey_1726: "กรุณากรอก ID ห้องประชุมของแพลตฟอร์ม Morgan",
  i18nKey_1727: "กรุณากรอกชื่อรูปแบบ",
  i18nKey_1728: "กรุณาใส่พื้นที่ให้เป็นจำนวนเต็ม",
  i18nKey_1729: "กรุณาใส่พื้นที่",
  i18nKey_1730: "กรุณาใส่รหัสผ่าน",
  i18nKey_1731: "กรุณากรอกชื่อประตู",
  i18nKey_1732: "กรุณากรอกระยะเวลาการเข้าพักที่มีประสิทธิผลเพื่อวิเคราะห์ต่อวัน",
  i18nKey_1733: "กรุณากรอกคำอธิบายเส้นทาง",
  i18nKey_1734: "กรุณากรอกชื่อหรือที่อยู่ไฟถนน",
  i18nKey_1735: "กรุณากรอกช่วงระยะ",
  i18nKey_1736: "กรุณาพิมพ์หมายเลขโทรศัพท์ของคุณ",
  i18nKey_1737: "กรุณากรอกเวลาเช่าสะสม",
  i18nKey_1738: "กรุณากรอกชื่อพื้นที่ / หมายเลขสถานีงาน",
  i18nKey_1739: "กรุณากรอกชื่อผู้ที่จองได้",
  i18nKey_1740: "กรุณากรอกช่วงระยะทาง",
  i18nKey_1741: "กรุณากรอกรหัสผ่านเดิม",
  i18nKey_1742: "กรุณากรอกจำนวนเงิน",
  i18nKey_1743: "กรุณากรอกชื่อ / หมายเลขโทรศัพท์ของโฮสต์",
  i18nKey_1744: "กรุณากรอกชื่อบทบาท",
  i18nKey_1745: "กรุณากรอกคำอธิบายบทบาท",
  i18nKey_1746: "กรุณากรอกจำนวนรางวัล",
  i18nKey_1747: "กรุณากรอกรางวัล / หมายเลขบัญชี",
  i18nKey_1748: "กรุณากรอกชื่ออาคาร",
  i18nKey_1749: "กรุณากรอกเส้นทางง่ายๆ",
  i18nKey_1750: "กรุณากรอกราคา",
  i18nKey_1751: "กรุณากรอกชื่อมิเตอร์",
  i18nKey_1752: "กรุณากรอกหมายเลขมิเตอร์",
  i18nKey_1753: "กรุณากรอกชื่อแผน",
  i18nKey_1754: "กรุณากรอกชื่อประเภทเป้าหมาย",
  i18nKey_1755: "กรุณากรอกค่าของคะแนน",
  i18nKey_1756: "กรุณากรอกชื่อกิจกรรม",
  i18nKey_1757: "กรุณากรอกเหตุการณ์",
  i18nKey_1758: "กรุณากรอกชื่อห้องประชุม / บริษัท ที่จอง / สถานะการประชุม",
  i18nKey_1759: "กรุณากรอกชื่อห้องประชุม",
  i18nKey_1760: "กรุณากรอกรายละเอียดของห้องประชุม",
  i18nKey_1761: "กรุณากรอกชื่อบ้าน",
  i18nKey_1762: "กรุณากรอกจำนวนคน",
  i18nKey_1763: "กรุณากรอกที่อยู่ MAC ที่ถูกต้องเช่น 00-01-6c-06-A6-29 หรือ 00: 01: 6c: 06: A6: 29",
  i18nKey_1764: "กรุณากรอกชื่อผู้ดูแลระบบ",
  i18nKey_1765: "กรุณากรอกชื่อพื้นที่สาธารณะ",
  i18nKey_1766: "กรุณากรอกหมายเลขโทรศัพท์ของเจ้าหน้าที่",
  i18nKey_1767: "กรุณากรอกชื่อพนักงาน",
  i18nKey_1768: "กรุณากรอกจำนวนสถานี",
  i18nKey_1769: "กรุณากรอกโปรไฟล์",
  i18nKey_1770: "กรุณากรอกราคา",
  i18nKey_1771: "กรุณากรอกหมายเลขทะเบียนใบอนุญาตประกอบธุรกิจ",
  i18nKey_1772: "กรุณาใส่ชื่อผู้รับผิดชอบตั๋ว",
  i18nKey_1773: "กรุณากรอกหมายเลขตั๋ว",
  i18nKey_1774: "กรุณากรอกระยะเวลาในการรักษาความสว่างหลังจากการชักนำ",
  i18nKey_1775: "กรุณากรอกจำนวนสถานที่เพิ่มเติม",
  i18nKey_1776: "กรุณากรอกชื่อของสถานที่เพิ่มเติม",
  i18nKey_1777: "กรุณากรอก IP ของเซิร์ฟเวอร์หรือชื่อโดเมน",
  i18nKey_1778: "กรุณากรอกชื่อรายการ",
  i18nKey_1779: "กรุณากรอกชื่อห้อง",
  i18nKey_1780: "กรุณากรอกรหัสบุคคลตามกฎหมาย",
  i18nKey_1781: "กรุณากรอกตัวแทนทางกฎหมาย",
  i18nKey_1782: "กรุณากรอกจำนวนที่ออก",
  i18nKey_1783: "กรุณากรอกโควต้าซึ่งต้องเป็นจำนวนเต็ม",
  i18nKey_1784: "กรุณากรอกจำนวนเงิน",
  i18nKey_1785: "กรุณากรอกจำนวนนาที",
  i18nKey_1786: "กรุณากรอกหมายเลขคำสั่งซื้อ / ชื่อช่องว่าง",
  i18nKey_1787: "กรุณากรอกหมายเลขคำสั่งซื้อ / ชื่อห้องประชุม",
  i18nKey_1788: "กรุณากรอกหมายเลขคำสั่งซื้อ",
  i18nKey_1789: "กรุณากรอกหมายเลขเคเบิลกว้าง ๆ",
  i18nKey_1790: "กรุณากรอกการค้นหาโทรศัพท์",
  i18nKey_1791: "กรุณากรอกหมายเลขมิเตอร์ ชื่ออุปกรณ์ชื่อผู้ขาย / ผู้เช่า",
  i18nKey_1792: "กรุณากรอกหมายเลขมิเตอร์",
  i18nKey_1793: "กรุณากรอกรหัสบุคคลที่สาม",
  i18nKey_1794: "กรุณากรอกที่อยู่ละติจูดและลองจิจูด",
  i18nKey_1795: "กรุณากรอกที่อยู่",
  i18nKey_1796: "กรุณากรอกรหัสผ่านเข้าสู่ระบบของบัญชีปัจจุบัน",
  i18nKey_1797: "กรุณากรอกราคาต่อหน่วย (หยวน / ชั่วโมง)",
  i18nKey_1798: "กรุณาใส่กระบวนการ",
  i18nKey_1799: "กรุณากรอกผู้เช่า",
  i18nKey_1800: "กรุณากรอกชื่อเจ้าของ",
  i18nKey_1801: "กรุณากรอกเครื่องอ่านมิเตอร์",
  i18nKey_1802: "กรุณากรอกชื่อฉาก",
  i18nKey_1803: "กรุณาใส่ชื่อฉากไม่เกินสิบตัวอักษร",
  i18nKey_1804: "กรุณากรอกชื่อกลยุทธ์",
  i18nKey_1805: "กรุณากรอกด้านล่างของแบบฟอร์ม",
  i18nKey_1806: "กรุณากรอกการขยาย",
  i18nKey_1807: "กรุณากรอกราคาต่อหน่วยครึ่งวัน (หยวน / 4 ชั่วโมง)",
  i18nKey_1808: "กรุณากรอกเปลี่ยนหมายเลข",
  i18nKey_1809: "กรุณากรอกตำแหน่งการติดตั้ง",
  i18nKey_1810: "กรุณากรอกทศนิยมระหว่าง 0-1",
  i18nKey_1811: "กรุณาใส่หมายเลขตาราง $ ชื่ออุปกรณ์ ชื่อธุรกิจ / ชื่อผู้เช่า",
  i18nKey_1812: "กรุณาใส่",
  i18nKey_1813: "โปรดอัปโหลดไฟล์ excel เวอร์ชันใหม่",
  i18nKey_1814: "โปรดอัปโหลดภาพ",
  i18nKey_1815: "โปรดตรวจสอบให้แน่ใจว่าคุณได้รับอนุญาตให้เข้าถึงข้อมูลนี้ การดำเนินการทั้งหมดของคุณจะถูกบันทึกไว้ หากคุณไม่ได้ใช้งานนานกว่า 30 นาที คุณจะต้องทำการยืนยันอีกครั้ง",
  i18nKey_1816: "ขอยกเว้นรายการการเชื่อมโยง",
  i18nKey_1817: "โปรดติดต่อผู้ดูแลระบบเพื่อขอรับสิทธิ์บริการที่เกี่ยวข้อง",
  i18nKey_1818: "กรุณากรอกรหัสไอที รหัสธุรกิจและชื่อระดับพื้นที่ของระดับปัจจุบันให้ครบถ้วน!",
  i18nKey_1819: "โปรดตรวจสอบลิงก์ในแถบที่อยู่และไปเข้าชมครั้ง",
  i18nKey_1820: "กรุณาเข้าสู่ระบบบัญชีของคุณ",
  i18nKey_1821: "กรุณาเข้าสู่ระบบ",
  i18nKey_1822: "โปรดรวมสูตรเดียวกัน",
  i18nKey_1823: "ชัดเจน",
  i18nKey_1824: "มลพิษทางแสง",
  i18nKey_1825: "สีฟ้า",
  i18nKey_1826: "เปลี่ยนเป็นมุมมองแบบเต็ม",
  i18nKey_1827: "เปลี่ยนเป็นมาตราส่วนเริ่มต้น",
  i18nKey_1828: "สลับรูปภาพ",
  i18nKey_1829: "สลับรายการ",
  i18nKey_1830: "พ่อค้าที่ค้างชำระ",
  i18nKey_1831: "วันก่อน",
  i18nKey_1832: "ไปที่หน้าเข้าสู่ระบบ IOC",
  i18nKey_1833: "เวลาเริ่มต้นต้องไม่มากกว่าหรือเท่ากับเวลาสิ้นสุด",
  i18nKey_1834: "ระยะเวลาเริ่มต้นหรือสิ้นสุดต้องไม่ว่างเปล่า",
  i18nKey_1835: "จำนวนเริ่มต้นหรือสิ้นสุดต้องไม่ว่างเปล่า",
  i18nKey_1836: "เวลาเริ่มต้นหรือสิ้นสุดต้องไม่ว่างเปล่า",
  i18nKey_1837: "จุดเริ่ม",
  i18nKey_1838: "หมายเลขเริ่มต้น",
  i18nKey_1839: "จุดเริ่มต้นต้องไม่ว่างเปล่า",
  i18nKey_1840: "วันที่เปิดใช้งาน",
  i18nKey_1841: "เปิดใช้งาน",
  i18nKey_1842: "ชื่อองค์กรภาษาจีน",
  i18nKey_1843: "พนักงานองค์กร",
  i18nKey_1844: "คำต่อท้ายกล่องจดหมายขององค์กร",
  i18nKey_1845: "ชื่อภาษาอังกฤษขององค์กร",
  i18nKey_1846: "ที่อยู่องค์กร",
  i18nKey_1847: "URL ขององค์กร",
  i18nKey_1848: "ผู้ค้าขององค์กร",
  i18nKey_1849: "การจัดอันดับการใช้พลังงานขององค์กร",
  i18nKey_1850: "ชื่อ บริษัท",
  i18nKey_1851: "เช็คอินธุรกิจรายวัน _",
  i18nKey_1852: "เบอร์ติดต่อ บริษัท",
  i18nKey_1853: "เบอร์ติดต่อองค์กร",
  i18nKey_1854: "ประเภทขององค์กร",
  i18nKey_1855: "ประวัติ บริษัท",
  i18nKey_1856: "บัญชีธุรกิจ",
  i18nKey_1857: "ผู้เยี่ยมชมองค์กร",
  i18nKey_1858: "องค์กร",
  i18nKey_1859: "ที่อยู่บริษัท",
  i18nKey_1860: "โลโก้องค์กร",
  i18nKey_1861: "องค์กร",
  i18nKey_1862: "การใช้พลังงานของรายการย่อย",
  i18nKey_1863: "อื่น ๆ",
  i18nKey_1864: "ไฟฟ้าอื่น ๆ",
  i18nKey_1865: "ข้อมูลอื่น ๆ",
  i18nKey_1866: "อื่น ๆ",
  i18nKey_1867: "เจ็ดหมวดหมู่",
  i18nKey_1868: "เหตุการณ์ธรรมดา",
  i18nKey_1869: "ธรรมดา",
  i18nKey_1870: "การหลั่งไหลของผู้โดยสารในวันธรรมดา",
  i18nKey_1871: "เวลาเฉลี่ยของการสนทนา",
  i18nKey_1872: "ระยะเวลาเข้าพักโดยเฉลี่ย",
  i18nKey_1873: "ค่าเฉลี่ย",
  i18nKey_1874: "เงินออมเฉลี่ยต่อวัน",
  i18nKey_1875: "จำนวนปัญหาสูงสุดต่อบริษัท",
  i18nKey_1876: "เวลาในการประมวลผลเฉลี่ย:",
  i18nKey_1877: "เวลาในการประมวลผลเฉลี่ย (นาที)",
  i18nKey_1878: "การปรับระดับ",
  i18nKey_1879: "แบรนด์ต้องประกอบด้วยอักษรจีนและอักษรอังกฤษตัวเลขและ _ เท่านั้น",
  i18nKey_1880: "ความยาวของตราสินค้าต้องไม่เกิน 100 อักษร",
  i18nKey_1881: "มีมากกว่า 6 ตัวเลือกของตราสินค้าและสีที่สอดคล้องที่ดีที่สุด",
  i18nKey_1882: "การวิเคราะห์แบรนด์",
  i18nKey_1883: "มีมากกว่า 6 ตัวเลือกของตราสินค้าและสีที่สอดคล้องที่ดีที่สุด",
  i18nKey_1884: "ยี่ห้อ:",
  i18nKey_1885: "ยี่ห้อ",
  i18nKey_1886: "ความถี่",
  i18nKey_1887: "ความถี่",
  i18nKey_1888: "ตั๋ว / ราคาต่อหน่วย",
  i18nKey_1889: "แบทช์แก้ไขเทมเพลตตามข้อมูลที่ส่งออก",
  i18nKey_1890: "แก้ไขแบทช์",
  i18nKey_1891: "การสร้างแบทช์",
  i18nKey_1892: "รายละเอียดการกำหนดค่า",
  i18nKey_1893: "ชื่อสวิตช์การแจกจ่าย",
  i18nKey_1894: "เวลาจำหน่าย",
  i18nKey_1895: "แจกจ่ายเรียบร้อยแล้ว",
  i18nKey_1896: "อันดับ",
  i18nKey_1897: "หญิง",
  i18nKey_1898: "คุณมีงานมากมายที่ต้องสอบถามโปรดรอให้งานถูกดำเนินการก่อนที่จะสอบถาม",
  i18nKey_1899: "คุณเปลี่ยนรหัสผ่านสำเร็จแล้วโปรดลงชื่อเข้าใช้แพลตฟอร์มอีกครั้ง",
  i18nKey_1900: "คุณต้องการระงับแพ็กเกจที่เลือกหรือไม่?",
  i18nKey_1901: "คุณต้องการระงับค่าเบี้ยเลี้ยงที่เลือกไว้หรือไม่?",
  i18nKey_1902: "คุณต้องการระงับห้องประชุมที่เลือกหรือไม่",
  i18nKey_1903: "คุณต้องการระงับสถานีที่เลือกหรือไม่",
  i18nKey_1904: "คุณต้องการระงับตารางเดินรถที่เลือกไว้หรือไม่?",
  i18nKey_1905: "คุณต้องการลบส่วนลดแบบจำกัดเวลาที่เลือกไว้หรือไม่?",
  i18nKey_1906: "คุณต้องการแสดงรายการส่วนลดเวลาจำกัดที่เลือกไว้หรือไม่?",
  i18nKey_1907: "คุณต้องการเปิดใช้งานแพ็คเกจที่เลือกหรือไม่?",
  i18nKey_1908: "คุณต้องการเปิดใช้งานค่าเบี้ยเลี้ยงที่เลือกไว้หรือไม่?",
  i18nKey_1909: "คุณต้องการเปิดใช้งานห้องประชุมที่เลือกหรือไม่",
  i18nKey_1910: "คุณต้องการเปิดใช้งานสถานีที่เลือกหรือไม่?",
  i18nKey_1911: "คุณต้องการเปิดใช้งานตารางเวลารถบัสที่เลือกไว้หรือไม่?",
  i18nKey_1912: "คุณต้องการล้างประวัติการสืบค้นทั้งหมดหรือไม่",
  i18nKey_1913: "มีการเหลื่อมในเวลาที่คุณกำหนด",
  i18nKey_1914: "แน่ใจไหมว่าต้องการดำเนินการ",
  i18nKey_1915: "แน่ใจไหมว่าต้องการปิดใช้งาน",
  i18nKey_1916: "คุณแน่ใจหรือว่าต้องการลบ?",
  i18nKey_1917: "แน่ใจไหมว่าต้องการเปิดใช้งาน",
  i18nKey_1918: "แน่ใจไหมว่าต้องการลบส่วนลดแบบจำกัดเวลา",
  i18nKey_1919: "แน่ใจไหมว่าต้องการลบแพ็กเกจ",
  i18nKey_1920: "คุณยังไม่ได้เลือกรายการข้อมูลใด ๆ !",
  i18nKey_1921: "คุณยังไม่ได้เลือกรายการข้อมูลใด ๆ",
  i18nKey_1922: "สวัสดีและยินดีต้อนรับสู่ IOC!",
  i18nKey_1923: "คุณยังไม่มีข้อมูลผู้เยี่ยมชมของบริษัท คุณสามารถทำได้",
  i18nKey_1924: "ไม่มีหน้าที่คุณการเยี่ยมชม",
  i18nKey_1925: "เบราว์เซอร์ของคุณไม่รองรับ HTML5",
  i18nKey_1926: "เกิดข้อผิดพลาดในอุปกรณ์ที่คุณเลือกในขณะนี้โปรดจัดการก่อน!",
  i18nKey_1927: "หน้าจอขนาดใหญ่ที่คุณเลือกในขณะนี้ออฟไลน์และไม่สามารถเข้าถึงคำสั่งได้!",
  i18nKey_1928: "ช่วงอายุ",
  i18nKey_1929: "ปี",
  i18nKey_1930: "ปี",
  i18nKey_1931: "ชื่อเล่นต้องมีความยาวไม่เกิน 12 อักษร",
  i18nKey_1932: "ชื่อเล่น",
  i18nKey_1933: "คุณได้เลือกแพ็คเกจที่จะจ่าย",
  i18nKey_1934: "คุณได้เลือกที่จะระงับการชำระเงิน",
  i18nKey_1935: "คุณได้ป้อน $ / $ เมื่อเทียบกับข้อมูลในช่วงก่อนหน้านี้มีข้อมูล $ ผิดปกติ ($) คุณแน่ใจหรือไม่ว่าจะส่ง",
  i18nKey_1936: "คุณได้นำเข้าข้อมูล $ มีข้อมูล $ ผิดปกติคุณแน่ใจหรือไม่ว่าจะส่ง",
  i18nKey_1937: "กลยุทธ์ที่คุณแก้ไข ยังไม่ได้รับการบันทึกคุณจะยอมแพ้หรือไม่?",
  i18nKey_1938: "แน่ใจไหมว่าต้องการลบนโยบายนี้ หลังจากลบคุณต้องควบคุมสวิตช์ไฟด้วยตนเอง",
  i18nKey_1939: "อัตราส่วนประสิทธิภาพการใช้พลังงาน COP (กิโลวัตต์ / กิโลวัตต์)",
  i18nKey_1940: "การวินิจฉัย ประสิทธิภาพการใช้พลังงาน AI",
  i18nKey_1941: "สถิติการใช้พลังงาน",
  i18nKey_1942: "การเสียพลังงาน",
  i18nKey_1943: "การตรวจสอบการใช้พลังงาน",
  i18nKey_1944: "การเปรียบเทียบการใช้พลังงาน",
  i18nKey_1945: "การใช้พลังงาน (กิโลวัตต์ชั่วโมง)",
  i18nKey_1946: "ธุรกิจภายใน",
  i18nKey_1947: "ชาย",
  i18nKey_1948: "การตั้งค่าเป้าหมาย",
  i18nKey_1949: "มูลค่าเป้าหมาย",
  i18nKey_1950: "ค่าเริ่มต้น (รีเฟรชเวลา 00:00 น. ของวันถัดไป)",
  i18nKey_1951: "ค่าเริ่มต้น",
  i18nKey_1952: "รหัสห้องประชุม Morgan Platform",
  i18nKey_1953: "ชื่อรูปแบบ",
  i18nKey_1954: "โหมด",
  i18nKey_1955: "ข้อความค้นหาที่ไม่ชัดเจน",
  i18nKey_1956: "ดาวน์โหลดเทมเพลต",
  i18nKey_1957: "รายละเอียด",
  i18nKey_1958: "ส่งใหม่ในไม่กี่วินาที",
  i18nKey_1959: "หลังจากนี้ไม่กี่วินาทีระบบจะข้ามไปยังหน้าล็อกอิน IOC โดยอัตโนมัติ",
  i18nKey_1960: "วินาที",
  i18nKey_1961: "คำอธิบายต้องไม่ว่างเปล่า",
  i18nKey_1962: "สัมภาษณ์",
  i18nKey_1963: "พื้นที่ (ตร.ม. )",
  i18nKey_1964: "สถานที่ฟรี",
  i18nKey_1965: "โควต้าฟรี (ชั่วโมง / เดือน)",
  i18nKey_1966: "การอนุญาตกำลังเปิดใช้และไม่อนุญาตให้แก้ไข!",
  i18nKey_1967: "สถานะรหัสผ่าน",
  i18nKey_1968: "รหัสผ่านยาว 8-20 ตัวอักษร!",
  i18nKey_1969: "รหัสผ่านต้องมีความยาว 8-20 หลักและประกอบด้วยตัวเลขและตัวอักษร",
  i18nKey_1970: "หากป้อนรหัสผ่านไม่ถูกต้องเกิน 5 ครั้งคุณจะออกจากระบบหลังจาก 10 วินาที",
  i18nKey_1971: "ความปลอดภัยของรหัสผ่านไม่ตรงกันอย่างน้อย 8 หลัก รวมถึงอย่างน้อยสามประเภทตัวต้องมีอักษรตัวพิมพ์ใหญ่และตัวพิมพ์เล็กและอักขระพิเศษ",
  i18nKey_1972: "รหัสผ่านไม่ถูกต้อง โปรดลองอีกครั้ง",
  i18nKey_1973: "รหัสผ่านต้องไม่มีช่องว่าง",
  i18nKey_1974: "รหัสผ่านต้องมี 8-20 หลักและตัวอักษร",
  i18nKey_1975: "รหัสผ่านต้องมีตัวอักษรตัวเลขและสัญลักษณ์อย่างน้อย 2 ตัว",
  i18nKey_1976: "เมตร",
  i18nKey_1977: "หมายเลขล็อคประตู",
  i18nKey_1978: "ขีด จำกัด การควบคุมการเข้าถึง",
  i18nKey_1979: "วิธีการควบคุมการเข้าถึง",
  i18nKey_1980: "ชื่อประตู",
  i18nKey_1981: "การควบคุมการเข้าถึงเครื่องอ่านรหัส QR",
  i18nKey_1982: "การควบคุมการเข้าถึง PAD",
  i18nKey_1983: "สามารถเพิ่มกฎการจัดแสงได้ถึง 6 กฎในแต่ละกลยุทธ์",
  i18nKey_1984: "การใช้พลังงานต่อตารางเมตร (t / m²)",
  i18nKey_1985: "การใช้พลังงานต่อตารางเมตร (กิโลวัตต์ชั่วโมง / ตร.ม. )",
  i18nKey_1986: "การใช้พลังงานต่อตารางเมตร",
  i18nKey_1987: "คุณสามารถติดตามงานได้ครั้งละหนึ่งงานเท่านั้น คุณแน่ใจหรือไม่ว่าคุณจำเป็นต้องละทิ้งงานการติดตามปัจจุบันและเริ่มงานการติดตามใหม่",
  i18nKey_1988: "ไม่มีข้อมูลโปรดเลือกสิ่งปลูกสร้างจากต้นไม้อวกาศด้านล่าง",
  i18nKey_1989: "ไม่มีข้อมูล!",
  i18nKey_1990: "ไม่มีกำหนดการประชุม",
  i18nKey_1991: "ไม่พบผู้ใช้",
  i18nKey_1992: "energy saving",
  i18nKey_1993: "เขียว",
  i18nKey_1994: "จำนวนเครื่องบินตรวจตรา",
  i18nKey_1995: "คำอธิบายเส้นทาง",
  i18nKey_1996: "รายการเส้นทาง",
  i18nKey_1997: "เส้นทาง (บทความ)",
  i18nKey_1998: "เส้นทาง",
  i18nKey_1999: "การเลือกโคมไฟถนน",
  i18nKey_2000: "การสร้างระบบอัตโนมัติ",
  i18nKey_2001: "หน่วยอาคาร",
  i18nKey_2002: "อาคาร / ชั้น / ห้อง",
  i18nKey_2003: "อาคาร",
  i18nKey_2004: "พื้นที่ชั้น",
  i18nKey_2005: "ชั้น",
  i18nKey_2006: "หกหมวดหมู่",
  i18nKey_2007: "ระดับ 6",
  i18nKey_2008: "ค่าธรรมเนียมการระงับชั่วคราว",
  i18nKey_2009: "จำนวนเงินที่ระงับชั่วคราว (หยวน)",
  i18nKey_2010: "หยุดเวลา",
  i18nKey_2011: "ที่จอดรถโปร",
  i18nKey_2012: "หยุด",
  i18nKey_2013: "ผู้เยี่ยมชมชั่วคราว",
  i18nKey_2014: "ชั่วคราว",
  i18nKey_2015: "แนว",
  i18nKey_2016: "ยานพาหนะ",
  i18nKey_2017: "การแสดงความสว่าง",
  i18nKey_2018: "การตั้งค่าความสว่าง",
  i18nKey_2019: "ความสว่าง",
  i18nKey_2020: "กฎการส่องสว่าง",
  i18nKey_2021: "รหัสผ่านสองรหัสที่ป้อนไม่สอดคล้องกัน",
  i18nKey_2022: "การชาร์จสองครั้งและการปลดปล่อยสองครั้ง",
  i18nKey_2023: "ดี",
  i18nKey_2024: "เบอร์ติดต่อ",
  i18nKey_2025: "ทาวน์เฮาส์",
  i18nKey_2026: "ตัวอย่าง: ที่จอดรถ Ping An XXXXXX ชั่วโมงแรก 15 หยวน (ฟรี 15 นาทีแรก) 10 หยวนต่อชั่วโมงหลังจากนั้น 60 ต่อยอด เขต Futian เมืองเซินเจิ้นมณฑลกวางตุ้ง XXXXX",
  i18nKey_2027: "ตัวอย่างเช่น 15 หยวนสำหรับชั่วโมงแรก (ฟรี 15 นาทีแรก) 10 หยวนต่อชั่วโมงหลังจากนั้น 60 ต่อยอด",
  i18nKey_2028: "สมัครเลย",
  i18nKey_2029: "เข้าสู่ระบบทันที",
  i18nKey_2030: "การวินิจฉัยทางประวัติศาสตร์",
  i18nKey_2031: "สถิติในอดีต",
  i18nKey_2032: "สิ้นสุดการเล่นวิดีโอย้อนหลัง",
  i18nKey_2033: "วิดีโอประวัติศาสตร์",
  i18nKey_2034: "เส้นโค้งประวัติศาสตร์",
  i18nKey_2035: "ติดตามประวัติศาสตร์",
  i18nKey_2036: "รุ่นประวัติศาสตร์",
  i18nKey_2037: "ออฟไลน์ ",
  i18nKey_2038: "ออฟไลน์",
  i18nKey_2039: "การออกจากจุดที่สบายคือก้าวแรก",
  i18nKey_2040: "อุณหภูมิเวลาอากาศเย็นออกของเครื่องทำความเย็น",
  i18nKey_2041: "ปริมาณการบริโภคสะสม",
  i18nKey_2042: "ค่าจอดรถสะสม (CNY)",
  i18nKey_2043: "การระงับการชำระเงินสะสมชั่วคราว $ หยวน คุณสามารถแนะนำบัตรรายเดือนให้เขา",
  i18nKey_2044: "ระยะเวลาเช่าสะสม",
  i18nKey_2045: "ค่าเช่าสะสมมักไม่ต่ำกว่าค่าเช่าขั้นต่ำ!",
  i18nKey_2046: "ยอดชำระสะสม> 400 เยน",
  i18nKey_2047: "การเข้าชมสะสม",
  i18nKey_2048: "การเข้าชมสะสม",
  i18nKey_2049: "ศักยภาพการประหยัดพลังงานสะสมเริ่มตั้งแต่วันที่ 1 ตุลาคม 2019 ถึงปัจจุบัน",
  i18nKey_2050: "ศักยภาพการประหยัดพลังงานสะสม (กิโลวัตต์ชั่วโมง)",
  i18nKey_2051: "ศักยภาพในการประหยัดพลังงานสะสม",
  i18nKey_2052: "ประเภทต้องไม่ว่างเปล่า",
  i18nKey_2053: "ชนิดของ",
  i18nKey_2054: "ต้องระบุหมวดหมู่",
  i18nKey_2055: "สีฟ้า",
  i18nKey_2056: "จากจังหวัด",
  i18nKey_2057: "หมายเลขอุปกรณ์ระบบต้นทาง",
  i18nKey_2058: "ระบบต้นทาง",
  i18nKey_2059: "แหล่งที่มา",
  i18nKey_2060: "เหตุผลในการเยี่ยมชม",
  i18nKey_2061: "เวลาเริ่มต้นของช่วงเวลาการเยี่ยมชมต้องไม่มากกว่าหรือเท่ากับเวลาสิ้นสุด",
  i18nKey_2062: "ประเภทการเยี่ยมชม",
  i18nKey_2063: "สถานะไม่ได้ใช้งาน",
  i18nKey_2064: "เครื่องปรับอากาศไฟฟ้า",
  i18nKey_2065: "สภาพอากาศน่าพอใจ โดยทั่วไปไม่มีมลพิษทางอากาศ",
  i18nKey_2066: "ตำแหน่งเชิงพื้นที่",
  i18nKey_2067: "ชื่อพื้นที่ / หมายเลขสถานีงาน",
  i18nKey_2068: "ชื่อพื้นที่",
  i18nKey_2069: "ประเภทพื้นที่",
  i18nKey_2070: "การเข้ารหัสบริการข้อมูลพื้นฐานของ Space",
  i18nKey_2071: "การปรับเปลี่ยนข้อมูลพื้นฐานเชิงพื้นที่",
  i18nKey_2072: "รายละเอียดข้อมูลพื้นฐานเชิงพื้นที่",
  i18nKey_2073: "ตำแหน่งข้อมูลพื้นฐานเชิงพื้นที่",
  i18nKey_2074: "การสร้างข้อมูลพื้นฐานเชิงพื้นที่",
  i18nKey_2075: "การเข้ารหัสข้อมูลพื้นฐานเชิงพื้นที่",
  i18nKey_2076: "ชื่อระดับพื้นที่",
  i18nKey_2077: "ลำดับชั้นเชิงพื้นที่",
  i18nKey_2078: "ห้องนั่งเล่น",
  i18nKey_2079: "จำนวนผู้โดยสารทั้งหมด (คนต่อเวลา)",
  i18nKey_2080: "อัตราส่วนเพศของการหลั่งไหลของผู้โดยสาร",
  i18nKey_2081: "ช่วงที่มีจำหน่าย",
  i18nKey_2082: "หน่วยเสริม",
  i18nKey_2083: "สามารถจองล่วงหน้าได้ตั้งแต่ 0 ถึง 30",
  i18nKey_2084: "ประเภทการปลดล็อก",
  i18nKey_2085: "เวลาเริ่มต้นและเวลาสิ้นสุดต้องห่างกันไม่น้อยกว่า 30 นาที",
  i18nKey_2086: "ความแตกต่างระหว่างเวลาเริ่มต้นและเวลาสิ้นสุดต้องไม่เกิน 7 วัน",
  i18nKey_2087: "เวลาเริ่มต้นต้องไม่ว่างเปล่า",
  i18nKey_2088: "เวลาเริ่มต้นต้องไม่มากกว่าเวลาสิ้นสุด",
  i18nKey_2089: "เวลาเริ่มต้น",
  i18nKey_2090: "เริ่มการติดตั้ง",
  i18nKey_2091: "เปิดการติดตามสำเร็จแล้ว",
  i18nKey_2092: "เปิดการติดตาม",
  i18nKey_2093: "เปิดสิ่งที่เลือก",
  i18nKey_2094: "เวลาเปิดทำการ",
  i18nKey_2095: "เปิดประตู",
  i18nKey_2096: "หลังจากเปิดประตูอัจฉริยะจะได้รับการอัปเดตเกี่ยวกับตามความถี่ของเวลาที่ตั้งไว้",
  i18nKey_2097: "ผ่านได้หลังจากเปิด",
  i18nKey_2098: "หลังจากเปิดให้เข้าชมสามารถดูบันทึกการลงทะเบียนทางประวัติศาสตร์ของตนเองได้",
  i18nKey_2099: "หลังจากเปิดใช้งานแล้วผู้ใช้มือถือจะสามารถใช้ฟังก์ชันนี้ได้และจะไม่แสดงเมื่อปิดอยู่",
  i18nKey_2100: "หลังจากเปิดใช้งานแล้วผู้ใช้อุปกรณ์เคลื่อนที่จะสามารถใช้ฟังก์ชันนี้ได้ แต่จะใช้ไม่ได้หลังจากปิดไปแล้ว",
  i18nKey_2101: "สถานะเปิดประตู",
  i18nKey_2102: "ความถี่ในการเปิดประตู",
  i18nKey_2103: "บันทึกการเปิดประตู",
  i18nKey_2104: "โหมดการประชุม",
  i18nKey_2105: "เปิด/ปิด",
  i18nKey_2106: "เปิด",
  i18nKey_2107: "การเข้ารหัสตำแหน่งเชิงพื้นที่เฉพาะ",
  i18nKey_2108: "ปฏิเสธ",
  i18nKey_2109: "ที่อยู่อาศัย",
  i18nKey_2110: "รหัสผ่านเก่า",
  i18nKey_2111: "ส่วนจำนวนเต็มของลองจิจูดคือ 0-180",
  i18nKey_2112: "ลองจิจูด",
  i18nKey_2113: "Beijing, Tianjin, Shanghai, Chongqing, Hebei, Henan, Yunnan, Liaoning, Heilongjiang, Hunan, Anhui, Shandong, Xinjiang, Jiangsu, Zhejiang, Jiangxi, Hubei, Guangxi, Gansu, Shanxi, Menggu, Shaanxi, Jilin, Fujian, Guangdong, Qinghai, Tibet, Sichuan, Ningxia, Hainan,",
  i18nKey_2114: "ห้ามเข้าที่จอดรถ",
  i18nKey_2115: "ข้อห้ามของบัตรรายเดือน",
  i18nKey_2116: "การปิดใช้งานล้มเหลว",
  i18nKey_2117: "จำนวนการรับสมัครในเดือนที่ผ่านมา",
  i18nKey_2118: "จำนวนผู้โดยสารเฉลี่ยในเดือนที่ผ่านมา",
  i18nKey_2119: "จำนวนวันที่เยี่ยมชมในเดือนที่ผ่านมา",
  i18nKey_2120: "เกือบหนึ่งเดือน",
  i18nKey_2121: "เกือบหนึ่งปี",
  i18nKey_2122: "เข้าสวนจอดรถอย่างน้อยที่สุดในเดือนที่ผ่านมา",
  i18nKey_2123: "ไม่เหลือเลยในเดือนที่ผ่านมา",
  i18nKey_2124: "ยังไม่ได้เข้าชมในเดือนที่ผ่านมา",
  i18nKey_2125: "จำนวนการเข้าชมของลูกค้าซ้ำในเดือนที่ผ่านมา",
  i18nKey_2126: "มากกว่า 400 ในเดือนที่ผ่านมา",
  i18nKey_2127: "เกือบ 7 วัน",
  i18nKey_2128: "จำนวนการออกจากที่จอดรถใน 30 วันที่ผ่านมา",
  i18nKey_2129: "จำนวนที่จอดรถใน 30 วันที่ผ่านมา",
  i18nKey_2130: "สัญญาณเตือนเหตุการณ์สำคัญใน 24 ชั่วโมงที่",
  i18nKey_2131: "เกือบ 24 ชั่วโมง",
  i18nKey_2132: "กำลังดำเนินการ",
  i18nKey_2133: "เวลาจอดรถ",
  i18nKey_2134: "เวลาเข้า",
  i18nKey_2135: "การแจ้งเตือนฉุกเฉิน",
  i18nKey_2136: "ครั้งเดียวเท่านั้น",
  i18nKey_2137: "แสดงสัญญาณการเตือนจำนวนการตรวจตราวิดีโอเท่านั้น",
  i18nKey_2138: "การแผนตรวจตราเท่านั้นที่มีส่วนร่วมในสถิติ",
  i18nKey_2139: "การเงิน",
  i18nKey_2140: "ความยาวสูงสุดของจำนวนคือ 6 หลัก",
  i18nKey_2141: "จำนวนต้องเป็นจำนวนเต็มหรือทศนิยมภายใน 2 หลักเท่านั้น",
  i18nKey_2142: "วันนี้คนเดินเท้าหลั่งไหล",
  i18nKey_2143: "กระแสการจราจรวันนี้",
  i18nKey_2144: "ปริมาณการใช้ไฟฟ้าจริงในปัจจุบัน (kWh)",
  i18nKey_2145: "การหลั่งของผู้โดยสารวันนี้",
  i18nKey_2146: "การจัดอันดับความถี่ในการเปิดประตูวันนี้",
  i18nKey_2147: "จำนวนลูกค้าที่ซื้อซ้ำในวันนี้",
  i18nKey_2148: "ลบบัญชีดำเรียบร้อยแล้ว",
  i18nKey_2149: "ลบบัญชีดำ",
  i18nKey_2150: "ภาพหน้าจอล้มเหลว",
  i18nKey_2151: "ระยะเวลาสิ้นสุดต้องไม่น้อยกว่าระยะเวลาเริ่มต้น",
  i18nKey_2152: "เวลาสิ้นสุดต้องมากกว่าเวลาเริ่มต้น",
  i18nKey_2153: "เวลาสิ้นสุดต้องไม่ว่างเปล่า",
  i18nKey_2154: "เวลาสิ้นสุด",
  i18nKey_2155: "โหมดสิ้นสุด",
  i18nKey_2156: "จำนวนเงินสิ้นสุดต้องไม่น้อยกว่าจำนวนเริ่มต้น",
  i18nKey_2157: "เวลาสิ้นสุดต้องไม่น้อยกว่าเวลาเริ่มต้น",
  i18nKey_2158: "ประหยัดค่าใช้จ่าย (หยวน)",
  i18nKey_2159: "ประหยัดค่าใช้จ่าย",
  i18nKey_2160: "ชื่อวันหยุดต้องไม่ว่างเปล่า",
  i18nKey_2161: "งานเทศกาล",
  i18nKey_2162: "ศักยภาพในการประหยัดพลังงาน (กิโลวัตต์ชั่วโมง)",
  i18nKey_2163: "ศักยภาพในการประหยัดพลังงาน",
  i18nKey_2164: "ประเภทประหยัดพลังงาน (สายพันธุ์)",
  i18nKey_2165: "ประเภทประหยัดพลังงาน",
  i18nKey_2166: "ชื่อโปรแกรม:",
  i18nKey_2167: "เข้าถึงระดับแรงดันไฟฟ้า (V)",
  i18nKey_2168: "คำขออินเทอร์เฟซล้มเหลว",
  i18nKey_2169: "ชื่อโฮสต์",
  i18nKey_2170: "ข้อมูลโฮสต์",
  i18nKey_2171: "โทรศัพท์ของพนักงานต้อนรับ",
  i18nKey_2172: "อัตราการพักของรถรับส่ง",
  i18nKey_2173: "กว่าเมื่อวาน",
  i18nKey_2174: "เมื่อเทียบกับเมื่อวาน",
  i18nKey_2175: "เมื่อเทียบกับสัปดาห์ที่แล้ว",
  i18nKey_2176: "เดือนต่อเดือน",
  i18nKey_2177: "เมื่อเทียบกับเดือนที่แล้ว",
  i18nKey_2178: "เมื่อเทียบกับปีที่แล้ว",
  i18nKey_2179: "เมื่อเทียบกับมูลค่าที่แท้จริงของปีก่อน",
  i18nKey_2180: "ปีต่อปี",
  i18nKey_2181: "เดือนต่อเดือน",
  i18nKey_2182: "วันก่อน",
  i18nKey_2183: "รายละเอียดการจ่ายเงิน",
  i18nKey_2184: "ประเภทการชำระเงิน",
  i18nKey_2185: "สถานะบทบาท",
  i18nKey_2186: "ชื่อบทบาท",
  i18nKey_2187: "คำอธิบายบทบาท",
  i18nKey_2188: "รายการบทบาท",
  i18nKey_2189: "ราคาของชื่อบทบาท",
  i18nKey_2190: "จำนวนรางวัลต้องไม่น้อยกว่าศูนย์",
  i18nKey_2191: "จำนวนรางวัลต้องไม่เป็นศูนย์",
  i18nKey_2192: "ปริมาณรางวัลต้องไม่มีทศนิยม",
  i18nKey_2193: "จำนวนรางวัล",
  i18nKey_2194: "จำนวนคะแนนสะสม (a)",
  i18nKey_2195: "ชื่อคะแนนรางวัล",
  i18nKey_2196: "รางวัลผู้สนับสนุน",
  i18nKey_2197: "การเลือกรางวัล",
  i18nKey_2198: "ประเภทรางวัล",
  i18nKey_2199: "รางวัลวัตถุ / หมายเลขบัญชี",
  i18nKey_2200: "รางวัล",
  i18nKey_2201: "ลากไฟล์มาที่นี่หรือคลิกอัปโหลด",
  i18nKey_2202: "การเลือกอาคาร",
  i18nKey_2203: "ระบบไฟฟ้าอาคาร",
  i18nKey_2204: "ค่าที่แนะนำ",
  i18nKey_2205: "ตัวดำเนินการที่แนะนำ",
  i18nKey_2206: "พารามิเตอร์การทำงานที่แนะนำ",
  i18nKey_2207: "เส้นทางง่าย",
  i18nKey_2208: "อยู่ระหว่างการซ่อมแซม",
  i18nKey_2209: "กล้องวงจรปิด",
  i18nKey_2210: "ตรวจสอบภาพหน้าจอ",
  i18nKey_2211: "มอนิเตอร์:",
  i18nKey_2212: "ระยะเวลาการตรวจสอบ (วัน)",
  i18nKey_2213: "จำนวนวันที่ตรวจสอบสามารถเป็นจำนวนเต็มบวกเท่านั้น",
  i18nKey_2214: "มอนิเตอร์",
  i18nKey_2215: "ช่วงระยะเวลาอยู่ระหว่าง 1-60",
  i18nKey_2216: "ช่วงเวลา (วินาที)",
  i18nKey_2217: "หมายเลขใบขับขี่มีอักษรพิเศษ",
  i18nKey_2218: "เลขที่ใบขับขี่",
  i18nKey_2219: "ใบอนุญาตขับรถ:",
  i18nKey_2220: "การตั้งราคา",
  i18nKey_2221: "ราคา (/ ครั้งที่เหลือ)",
  i18nKey_2222: "มีการทำงานล่วงเวลาจำนวนมาก โดยออกจากที่จอดรถหลัง 20.00 น. ในตอนเย็นเป็นเวลา 10 วันในเดือนที่ผ่านมา",
  i18nKey_2223: "สถานะถ่ายถอด",
  i18nKey_2224: "จำบัญชีปัจจุบัน",
  i18nKey_2225: "ชื่อมิเตอร์",
  i18nKey_2226: "อักษรย่อเมตร",
  i18nKey_2227: "หมายเลขมิเตอร์",
  i18nKey_2228: "เมตร",
  i18nKey_2229: "รายละเอียดแผน",
  i18nKey_2230: "ชื่อแผน",
  i18nKey_2231: "ประเภทการเรียกเก็บเงิน",
  i18nKey_2232: "บ้านเกิด",
  i18nKey_2233: "ข้อมูลพื้นฐาน",
  i18nKey_2234: "มูลค่ารวมของคะแนน (หยวน)",
  i18nKey_2235: "มูลค่ารวมของคะแนน",
  i18nKey_2236: "ระยะเวลาการใช้คะแนน (เริ่มต้น)",
  i18nKey_2237: "ระยะเวลาที่ใช้ได้ของคะแนน (สิ้นสุด)",
  i18nKey_2238: "ระยะเวลาการใช้คะแนน",
  i18nKey_2239: "แต้มกระเป๋าเงิน",
  i18nKey_2240: "มูลค่าคะแนน (หยวน / นาที)",
  i18nKey_2241: "นาฬิกากลไก",
  i18nKey_2242: "กำลังห้องเครื่องยนต์ (กิโลวัตต์ชั่วโมง)",
  i18nKey_2243: "รับรหัสยืนยัน",
  i18nKey_2244: "ไม่สามารถรับแหล่งที่มาวิดีโอ",
  i18nKey_2245: "รับเวลา",
  i18nKey_2246: "ไม่สามารถรับทรัพยากรกล้อง ...",
  i18nKey_2247: "ไม่สามารถรับข้อมูลอุปกรณ์!",
  i18nKey_2248: "ไม่สามารถรับข้อมูลเชิงพื้นที่!",
  i18nKey_2249: "รับที่อยู่ละติจูดและลองจิจูด",
  i18nKey_2250: "รับข้อยกเว้นรายการตาราง",
  i18nKey_2251: "การเลือกลิฟต์ขนส่งสินค้า",
  i18nKey_2252: "สถานะใช้งาน",
  i18nKey_2253: "รายละเอียดกิจกรรม",
  i18nKey_2254: "ชื่อเหตุการณ์",
  i18nKey_2255: "เช่าสถานีจัดงานขั้นต่ำไม่น้อยกว่า 30 วัน!",
  i18nKey_2256: "สถานีกิจกรรม",
  i18nKey_2257: "กิจกรรมต้องไม่ว่างเปล่า",
  i18nKey_2258: "กิจกรรม",
  i18nKey_2259: "ชื่อสมาชิก",
  i18nKey_2260: "รหัสสมาชิก",
  i18nKey_2261: "สถานะการประชุม",
  i18nKey_2262: "ข้อมูลการประชุม",
  i18nKey_2263: "รายละเอียดห้องประชุม",
  i18nKey_2264: "ภาพแรกของห้องประชุม",
  i18nKey_2265: "การใช้ห้องประชุม",
  i18nKey_2266: "ชื่อห้องประชุมไม่รองรับอักขระพิเศษ (อนุญาตให้ใช้อักษรจีนตัวอักษรและตัวเลข)",
  i18nKey_2267: "ชื่อห้องประชุม",
  i18nKey_2268: "คำอธิบายห้องประชุม",
  i18nKey_2269: "พนักงานบริการห้องประชุม",
  i18nKey_2270: "รายละเอียดการสั่งซื้อของห้องประชุม",
  i18nKey_2271: "ก่อนการประชุม: ยังไม่เริ่ม",
  i18nKey_2272: "ก่อนการประชุม:",
  i18nKey_2273: "ก่อนการประชุม",
  i18nKey_2274: "หลังการประชุม:",
  i18nKey_2275: "จำนวนลูกค้าที่กลับมา",
  i18nKey_2276: "เปอร์เซ็นต์ของลูกค้าที่ซื้อซ้ำ",
  i18nKey_2277: "ลูกค้าซ้ำ",
  i18nKey_2278: "สถานะการวนซ้ำ",
  i18nKey_2279: "หมายเลขวงจร / พื้นที่ให้บริการ",
  i18nKey_2280: "หมายเลขวงจร",
  i18nKey_2281: "เล่นซ้ำ",
  i18nKey_2282: "สีเทา",
  i18nKey_2283: "สีเหลือง",
  i18nKey_2284: "อุปกรณ์ตรวจสอบสิ่งแวดล้อม",
  i18nKey_2285: "การตรวจสอบสิ่งแวดล้อม",
  i18nKey_2286: "การกำหนดค่านโยบายการเตือนภัยด้านสิ่งแวดล้อม",
  i18nKey_2287: "การใช้วงจรพลังงาน",
  i18nKey_2288: "ปริมาณการใช้ไฟฟ้าของวงจรช่วงเวลาเดียวกัน",
  i18nKey_2289: "ช่วงเวลาเดียวกัน",
  i18nKey_2290: "เวลาวงจร",
  i18nKey_2291: "การใช้พลังงานวงจรต่อตารางเมตร",
  i18nKey_2292: "อัตราส่วนวงจร",
  i18nKey_2293: "ยินดีต้อนรับสู่แพลตฟอร์ม IOC โปรดรีเซ็ตรหัสผ่านของคุณ",
  i18nKey_2294: "ยินดีต้อนรับสู่การใช้แพลตฟอร์ม IOC โปรดลงชื่อเข้าใช้บัญชีของคุณหลังจากเปลี่ยนรหัสผ่าน",
  i18nKey_2295: "ยินดีต้อนรับสู่แพลตฟอร์ม IOC โปรดลงทะเบียน",
  i18nKey_2296: "จีนตอนกลาง",
  i18nKey_2297: "West China",
  i18nKey_2298: "จีนตอนใต้",
  i18nKey_2299: "จีนตะวันออก",
  i18nKey_2300: "สวน North China",
  i18nKey_2301: "จีนตอนเหนือ",
  i18nKey_2302: "ขีดเส้นใต้",
  i18nKey_2303: "หมายเลขหนังสือเดินทาง",
  i18nKey_2304: "ตัวอย่างรูปแบบชื่อหน่วย: ห้อง A1-3 ห้องโถง 2",
  i18nKey_2305: "ชื่อบ้าน",
  i18nKey_2306: "จำนวนครัวเรือน",
  i18nKey_2307: "อินเตอร์เนต",
  i18nKey_2308: "แผนภูมิโค้ง",
  i18nKey_2309: "พยากรณ์เดือน",
  i18nKey_2310: "ผลลัพธ์",
  i18nKey_2311: "รายเดือน",
  i18nKey_2312: "สีแดง",
  i18nKey_2313: "ฉันเริ่มทำงานเร็วมากและเข้าที่จอดรถก่อน 8.00 น. เป็นเวลา 10 วันในวันจันทร์ที่ผ่านมา",
  i18nKey_2314: "สีดำ",
  i18nKey_2315: "จำนวนเหตุการณ์บัญชีดำ",
  i18nKey_2316: "เหตุการณ์บัญชีดำ",
  i18nKey_2317: "ภาพรวมบัญชีดำ",
  i18nKey_2318: "บัญชีดำ",
  i18nKey_2319: "จำนวนคนอนุมัติ",
  i18nKey_2320: "ระยะเวลาสัญญา:",
  i18nKey_2321: "เลขที่สัญญา:",
  i18nKey_2322: "สัญญา",
  i18nKey_2323: "การต่อสู้ในการเป็นหุ้นส่วน",
  i18nKey_2324: "ผสาน",
  i18nKey_2325: "การใช้น้ำ",
  i18nKey_2326: "สามอันดับ ที่กินเวลายาวนานที่สุด",
  i18nKey_2327: "สามอันดับ ที่ใช้เวลาสั้นที่สุด",
  i18nKey_2328: "การใช้พลังงาน (องศา)",
  i18nKey_2329: "การใช้พลังงาน",
  i18nKey_2330: "รถหรู",
  i18nKey_2331: "ลักษณะพฤติกรรม",
  i18nKey_2332: "ใบขับขี่:",
  i18nKey_2333: "ประเทศ",
  i18nKey_2334: "ประเทศที่คุณถือสัญชาติอยู่",
  i18nKey_2335: "ติดตามช่วงเวลา",
  i18nKey_2336: "ส่งออกสำเร็จแล้ว",
  i18nKey_2337: "ติดตามดู",
  i18nKey_2338: "ติดตาม",
  i18nKey_2339: "กฎ",
  i18nKey_2340: "กว่างโจว",
  i18nKey_2341: "หัวหน้าฝ่ายบริหารเป็นภาษาจีนตัวอักษรและขีดล่างไม่เกิน 20 ตัวอักษร",
  i18nKey_2342: "ผู้รับผิดชอบการจัดการ",
  i18nKey_2343: "ผู้ดูแลระบบคืนเงินในเชิงรุก",
  i18nKey_2344: "ชื่อผู้ดูแลระบบ",
  i18nKey_2345: "จัดการความเป็นเจ้าของ",
  i18nKey_2346: "ผู้อนุมัติการจัดการ",
  i18nKey_2347: "ฝ่ายบริหาร",
  i18nKey_2348: "ความสัมพันธ์ผิดปกติ",
  i18nKey_2349: "เหตุการณ์ที่เกี่ยวข้อง",
  i18nKey_2350: "กล้องที่เกี่ยวข้องสำเร็จแล้ว",
  i18nKey_2351: "กล้องที่เกี่ยวข้อง",
  i18nKey_2352: "สถานที่จัดกิจกรรมที่เกี่ยวข้อง",
  i18nKey_2353: "เชื่อมโยงข้อมูลที่ผ่านมาของวิดีโอ __",
  i18nKey_2354: "เชื่อมโยงเรียบร้อยแล้ว",
  i18nKey_2355: "หมายเลขป้ายทะเบียนที่เกี่ยวข้อง",
  i18nKey_2356: "ที่เกี่ยวข้อง",
  i18nKey_2357: "คำสำคัญ",
  i18nKey_2358: "ปิดข้อยกเว้นการติดตาม",
  i18nKey_2359: "ปิดการติดตาม",
  i18nKey_2360: "ปิดรายการที่เลือก",
  i18nKey_2361: "ปิดการแจ้งเตือน",
  i18nKey_2362: "ปิดตัวลง",
  i18nKey_2363: "ปิด",
  i18nKey_2364: "ผู้ถือบัญชี",
  i18nKey_2365: "รวมความถี่ของความล้มเหลว",
  i18nKey_2366: "รายละเอียดความล้มเหลว",
  i18nKey_2367: "จำนวนสถานที่ที่ล้มเหลว",
  i18nKey_2368: "สถิติของสถานที่ล้มเหลว",
  i18nKey_2369: "คำอธิบายข้อบกพร่อง",
  i18nKey_2370: "แหล่งที่มาของความล้มเหลว",
  i18nKey_2371: "ความผิดปกติ",
  i18nKey_2372: "ค่าเช่าขั้นต่ำสำหรับสถานีประจำไม่น้อยกว่า 3 เดือน!",
  i18nKey_2373: "สถานีประจำ",
  i18nKey_2374: "หลังจากตรวจสนามแล้ว สนามจะปรากฏในแอพเพล็ตและผู้ใช้ APP",
  i18nKey_2375: "ผู้ผลิต",
  i18nKey_2376: "ระบบจัดหาและจำหน่าย",
  i18nKey_2377: "แหล่งจ่ายไฟและการจัดจำหน่าย",
  i18nKey_2378: "รวมวงจรไฟ $",
  i18nKey_2379: "ห้องประชุมรวม",
  i18nKey_2380: "รายงานคำสั่งสำนักงานที่ใช้ร่วมกัน _",
  i18nKey_2381: "ฉันทามติร่วมสร้างสรรค์ แบ่งปัน แบ่งปัน",
  i18nKey_2382: "เปิด $ ครั้ง",
  i18nKey_2383: "รวมเป็น $ สำเร็จ $ ข้อผิดพลาด $",
  i18nKey_2384: "ขอแสดงความยินดีการลงทะเบียนสำเร็จ!",
  i18nKey_2385: "คลาวด์สาธารณะ",
  i18nKey_2386: "ยอดจอง บริษัท 5 อันดับ",
  i18nKey_2387: "ไฟฟ้าในพื้นที่สาธารณะ",
  i18nKey_2388: "มิเตอร์พื้นที่สาธารณะ",
  i18nKey_2389: "การจัดอันดับการใช้พลังงานของเขตสาธารณะ",
  i18nKey_2390: "ค่าเป้าหมายของการใช้พลังงานในพื้นที่สาธารณะต้องไม่เกินมูลค่าเป้าหมายของการใช้พลังงานทั้งหมด",
  i18nKey_2391: "การใช้พลังงานในพื้นที่สาธารณะ (เดือนที่แล้ว)",
  i18nKey_2392: "ชื่อตำบลสาธารณะ",
  i18nKey_2393: "ประเภทพื้นที่สาธารณะ",
  i18nKey_2394: "พื้นที่สาธารณะ",
  i18nKey_2395: "ไม่สามารถลบพื้นที่สาธารณะได้",
  i18nKey_2396: "การกำหนดค่าทั่วไป",
  i18nKey_2397: "บัญชีดำด้านความปลอดภัยสาธารณะ",
  i18nKey_2398: "ความปลอดภัยสาธารณะ",
  i18nKey_2399: "วันทำงาน",
  i18nKey_2400: "ชื่อพนักงาน",
  i18nKey_2401: "โทรศัพท์เจ้าหน้าที่",
  i18nKey_2402: "เจ้าหน้าที่",
  i18nKey_2403: "นายจ้าง",
  i18nKey_2404: "ประเภทของงาน",
  i18nKey_2405: "สถานะสถานี",
  i18nKey_2406: "จำนวนสถานี",
  i18nKey_2407: "ภาพแรกของสถานที่",
  i18nKey_2408: "ประเภทสถานี",
  i18nKey_2409: "บทนำ",
  i18nKey_2410: "ราคาต่อหน่วยของสถานที่ทำงานต้องสอดคล้องกับยูนิตที่มีค่าเช่าน้อยที่สุด!",
  i18nKey_2411: "ราคาสถานี",
  i18nKey_2412: "จำนวนหมายเลขสถานีต้องสอดคล้องกับจำนวนสถานี!",
  i18nKey_2413: "หมายเลขสถานี",
  i18nKey_2414: "หมายเลขทะเบียนใบอนุญาตธุรกิจ / รหัสเครดิตโซเชียลแบบรวม",
  i18nKey_2415: "หมายเลขงาน",
  i18nKey_2416: "สถานะตั๋ว",
  i18nKey_2417: "เจ้าของใบสั่งงาน",
  i18nKey_2418: "ผู้รับมอบสิทธิ์ใบสั่งงาน",
  i18nKey_2419: "ส่งใบสั่งงานเรียบร้อยแล้ว",
  i18nKey_2420: "เลขที่ใบสั่งงาน",
  i18nKey_2421: "เวลาอัปเดตคือเวลาทางสถิติของเมื่อวาน",
  i18nKey_2422: "เวลาอัปเดต",
  i18nKey_2423: "การอัพเดทล้มเหลว!",
  i18nKey_2424: "การอัปเดตเสร็จสมบูรณ์",
  i18nKey_2425: "เลเยอร์เพิ่มเติม",
  i18nKey_2426: "งานอื่น ๆ",
  i18nKey_2427: "การระบายน้ำ",
  i18nKey_2428: "กิจกรรมปกติสำหรับคนทุกประเภท",
  i18nKey_2429: "คะแนนส่วนบุคคล",
  i18nKey_2430: "ส่วนตัว",
  i18nKey_2431: "ต่อ บริษัท",
  i18nKey_2432: "A",
  i18nKey_2433: "เกณฑ์การเตือนภัย",
  i18nKey_2434: "เหตุการณ์การเตือนภัยและสถานะการติดตาม",
  i18nKey_2435: "ไม่มีอุปกรณ์ที่เกี่ยวข้องกับเหตุการณ์การเตือนภัย",
  i18nKey_2436: "ภาพรวมเหตุการณ์การเตือนภัย",
  i18nKey_2437: "การเตือนเหตุการณ์",
  i18nKey_2438: "คำอธิบายการเตือนภัย",
  i18nKey_2439: "รายการปลุก",
  i18nKey_2440: "ประเภทการเตือนภัย",
  i18nKey_2441: "แหล่งที่มาของการเตือนภัย",
  i18nKey_2442: "สถิติการจำแนกสัญญาณเตือน",
  i18nKey_2443: "จำนวนการเตือน",
  i18nKey_2444: "มุมมองการเตือนภัย",
  i18nKey_2445: "ไฮไลต์",
  i18nKey_2446: "ขั้นสูง",
  i18nKey_2447: "การเหนี่ยวนำสว่างสลัว",
  i18nKey_2448: "การตรวจจับความสว่าง",
  i18nKey_2449: "สวิตช์ประสาทสัมผัส",
  i18nKey_2450: "รักษาเวลาความสว่างหลังจากการเหนี่ยวนำ",
  i18nKey_2451: "การตรวจจับเวลาแสง",
  i18nKey_2452: "สถานะกิจกรรมอยู่ระหว่างรอดำเนินการและไม่สามารถรายงานได้ชั่วคราว",
  i18nKey_2453: "สถานะกิจกรรมอยู่ระหว่างรอดำเนินการและไม่สามารถรายงานได้ชั่วคราว",
  i18nKey_2454: "สถานะคือเหตุการณ์กำลังประมวลผลและไม่สามารถรายงานได้ชั่วคราว",
  i18nKey_2455: "งานรับผิดชอบมีเหตุการณ์ที่ยังไม่ได้ประมวลผลและไม่สามารถรายงานได้ชั่วคราว",
  i18nKey_2456: "บุคคลนี้ไม่มีการติดตามการเคลื่อนไหวในช่วงเวลานี้",
  i18nKey_2457: "บุคคลนี้ไม่มีภาพและไม่สามารถนำไปใช้งานได้",
  i18nKey_2458: "ห้องประชุมถูกปิดใช้งานและไม่สามารถดำเนินการได้",
  i18nKey_2459: "ไม่มีวันที่สำหรับห้องประชุมนี้",
  i18nKey_2460: "บริเวณนี้ไม่มีที่จอดรถ",
  i18nKey_2461: "เปิดใช้งานการขับขี่นี้แล้วโปรดหยุดชั่วคราวก่อนออกเดินทาง!",
  i18nKey_2462: "ฉากถูกปิดใช้งานและไม่สามารถดำเนินการได้",
  i18nKey_2463: "การปรับใช้ถูกหยุดและไม่สามารถแก้ไขได้",
  i18nKey_2464: "เปิดใช้งานการควบคุมการปรับใช้แล้วและไม่สามารถแก้ไขได้",
  i18nKey_2465: "ข้อมูลรถควบคุมถูกลบไปแล้วไม่รองรับการแก้ไขหรือจัดเรียงใหม่โปรดสร้างการควบคุมการปรับใช้ใหม่",
  i18nKey_2466: "ไฟล์แนบรองรับรูปแบบ excel, PDF, jpg, rar, zip เวอร์ชันใหม่เท่านั้น",
  i18nKey_2467: "ภาคผนวก",
  i18nKey_2468: "จำนวนสถานที่เพิ่มเติม",
  i18nKey_2469: "มีชื่อสถานที่เพิ่มเติมอยู่แล้ว!",
  i18nKey_2470: "ชื่อสถานที่เพิ่มเติม",
  i18nKey_2471: "โหลดเงื่อนไข",
  i18nKey_2472: "วิธีการชำระเงิน",
  i18nKey_2473: "รูปแบบการชำระเงิน",
  i18nKey_2474: "วิธีการชำระเงิน",
  i18nKey_2475: "การตั้งค่าวิดีโอรอง",
  i18nKey_2476: "ทรัพยากรบริการ",
  i18nKey_2477: "ผู้ให้บริการต้องประกอบด้วยภาษาจีนและภาษาอังกฤษเท่านั้นตัวเลขและ _",
  i18nKey_2478: "ความยาวของผู้ให้บริการต้องไม่เกิน 50 อักษร",
  i18nKey_2479: "ผู้ให้บริการ",
  i18nKey_2480: "พื้นที่ให้บริการต้องไม่ว่างเปล่า",
  i18nKey_2481: "พื้นที่ให้บริการ",
  i18nKey_2482: "ข้อมูลเซิร์ฟเวอร์",
  i18nKey_2483: "ผู้ให้บริการ",
  i18nKey_2484: "จำนวนสำเนา",
  i18nKey_2485: "แบ่งปัน",
  i18nKey_2486: "สีชมพู",
  i18nKey_2487: "นาทีต้องไม่ว่างเปล่า",
  i18nKey_2488: "นาทีต้องไม่น้อยกว่า 2 นาทีและต้องไม่เกิน 10 นาทีและต้องไม่มีช่องว่าง!",
  i18nKey_2489: "นาที",
  i18nKey_2490: "สัดส่วนของรายการย่อยต้องไม่เกิน 1",
  i18nKey_2491: "เปอร์เซ็นต์ของรายการย่อย",
  i18nKey_2492: "การบำรุงรักษาตามรายการ",
  i18nKey_2493: "สถิติแบบแยกรายการ",
  i18nKey_2494: "การใช้พลังงานสะสมตามรายการ (กิโลวัตต์ชั่วโมง)",
  i18nKey_2495: "ข้อมูลมิเตอร์แบบระบุรายการ",
  i18nKey_2496: "ดัชนีการวิเคราะห์:",
  i18nKey_2497: "การวิเคราะห์",
  i18nKey_2498: "การหลั่งไหลของผู้โดยสาร",
  i18nKey_2499: "ตำแหน่งพาร์ทิชัน",
  i18nKey_2500: "พาร์ทิชัน",
  i18nKey_2501: "รหัสการจัดเตรียม",
  i18nKey_2502: "สถานการณ์ทางเบี่ยง",
  i18nKey_2503: "ต้องระบุหมวดหมู่",
  i18nKey_2504: "การจัดหมวดหมู่",
  i18nKey_2505: "นาที",
  i18nKey_2506: "ไม่ใช่พื้นที่สาธารณะ",
  i18nKey_2507: "วันที่ไม่ทำงาน",
  i18nKey_2508: "แบบไม่จอดรถ",
  i18nKey_2509: "ไม่จำเป็นต้องใช้",
  i18nKey_2510: "การปลดปล่อยทั้งหมด",
  i18nKey_2511: "กำลังการผลิต (กิโลวัตต์ชั่วโมง)",
  i18nKey_2512: "เยี่ยมชมแหล่งที่มา",
  i18nKey_2513: "สถานะผู้เยี่ยมชม",
  i18nKey_2514: "การนัดหมายของลูกค้า",
  i18nKey_2515: "ข้อมูลการเชิญผู้เยี่ยมชมแสดงเนื้อหา",
  i18nKey_2516: "คำเชิญลูกค้า",
  i18nKey_2517: "สถิติการเชิญผู้เยี่ยมชม (เกือบ 30 วัน)",
  i18nKey_2518: "ชื่อผู้เยี่ยมชมต้องไม่เป็นอักษรพิเศษ",
  i18nKey_2519: "ชื่อผู้เยี่ยมชมต้องไม่ว่างเปล่า",
  i18nKey_2520: "ชื่อผู้เยี่ยมชม",
  i18nKey_2521: "ข้อมูลผู้เยี่ยมชมต้องไม่เป็นอักษรพิเศษ",
  i18nKey_2522: "การเข้าถึงของผู้เยี่ยมชม",
  i18nKey_2523: "จำนวนผู้เยี่ยมชม",
  i18nKey_2524: "พนักงานดูแลผู้เยี่ยมชม ไม่มีอำนาจในการดำเนินการนี้",
  i18nKey_2525: "สถิติผู้เยี่ยมชม (โดยสวนสาธารณะ)",
  i18nKey_2526: "จำนวนผู้เยี่ยมชม - อันดับ บริษัท",
  i18nKey_2527: "จำนวนผู้เยี่ยมชม (คน)",
  i18nKey_2528: "จำนวนผู้เยี่ยมชม",
  i18nKey_2529: "จำนวนผู้เยี่ยมชม",
  i18nKey_2530: "การจัดการผู้เยี่ยมชม",
  i18nKey_2531: "โทรศัพท์ของลูกค้าต้องไม่ว่างเปล่า",
  i18nKey_2532: "โทรศัพท์ของผู้เยี่ยมชม",
  i18nKey_2533: "เวลามาถึงของผู้เยี่ยมชม",
  i18nKey_2534: "การแจ้งเตือนเมื่อผู้เยี่ยมชมมาถึง",
  i18nKey_2535: "เหตุผลในการเยี่ยมชม",
  i18nKey_2536: "ผู้เยี่ยมชม",
  i18nKey_2537: "ข้อมูลที่อยู่อาศัย",
  i18nKey_2538: "ประเภทอสังหาริมทรัพย์",
  i18nKey_2539: "ที่อยู่บ้าน",
  i18nKey_2540: "รหัสบ้าน",
  i18nKey_2541: "ไฟฟ้าในห้อง",
  i18nKey_2542: "มิเตอร์ห้อง",
  i18nKey_2543: "ตำแหน่งห้อง",
  i18nKey_2544: "จำนวนห้องพัก",
  i18nKey_2545: "ชื่อห้อง",
  i18nKey_2546: "หมายเลขห้อง",
  i18nKey_2547: "อสังหาริมทรัพย์",
  i18nKey_2548: "เขตดับเพลิง",
  i18nKey_2549: "กลับไปที่การเข้าสู่ระบบ",
  i18nKey_2550: "กลับ",
  i18nKey_2551: "บัตรประจำตัวองค์กร",
  i18nKey_2552: "ตัวแทนทางกฎหมาย",
  i18nKey_2553: "สถานที่",
  i18nKey_2554: "จำนวนครั้งที่เกิดขึ้น",
  i18nKey_2555: "เวลาเกิด",
  i18nKey_2556: "จำนวนปัญหา (a)",
  i18nKey_2557: "ประเด็นปัญหา",
  i18nKey_2558: "ออก",
  i18nKey_2559: "รหัสคิวอาร์โค้ดหมดอายุแล้ว",
  i18nKey_2560: "การเข้าถึงรหัสคิวอาร์โค้ด",
  i18nKey_2561: "เวลารีเฟรชคิวอาร์โค้ดต้องอยู่ระหว่าง 1 ถึง 1439 นาที",
  i18nKey_2562: "เวลารีเฟรชคิิวอาร์โค้ด",
  i18nKey_2563: "คิวอาร์โค้ด",
  i18nKey_2564: "เปิดหนึ่งรอบและสองรอบ",
  i18nKey_2565: "ประเภทที่สอง",
  i18nKey_2566: "แผนกมัธยมศึกษา",
  i18nKey_2567: "ระดับ 2",
  i18nKey_2568: "สอง",
  i18nKey_2569: "ห้องสำหรับเด็ก",
  i18nKey_2570: "กำลังการผลิตสูงสุด (กิโลวัตต์ชั่วโมง)",
  i18nKey_2571: "กำลังไฟ (กิโลวัตต์)",
  i18nKey_2572: "ตัน",
  i18nKey_2573: "แลกเปลี่ยน",
  i18nKey_2574: "ชื่อวัตถุ",
  i18nKey_2575: "โมเดลภายนอกต้องเป็นตัวอักษรภาษาจีนและขีดล่างภายใน 50 อักษรเท่านั้น",
  i18nKey_2576: "โมเดลภายนอกต้องไม่ว่างเปล่า",
  i18nKey_2577: "โมเดลภายนอก",
  i18nKey_2578: "ขออภัยคุณไม่ได้รับอนุญาตใด ๆ โปรดติดต่อผู้ดูแลระบบ",
  i18nKey_2579: "การกำหนดค่าเบรกเกอร์",
  i18nKey_2580: "กฎการกำหนดค่าเวลาการเปลี่ยนเบรกเกอร์",
  i18nKey_2581: "ข้อความ",
  i18nKey_2582: "หมายเลขท่าเรือ",
  i18nKey_2583: "การอ่านจุดเวลา",
  i18nKey_2584: "การอ่าน (กิโลวัตต์ชั่วโมง)",
  i18nKey_2585: "การอ่าน",
  i18nKey_2586: "อ่านแผน",
  i18nKey_2587: "นักสู้จะไม่มีวันถอยหลังและไม่มีอันตรายของความกล้าหาญอยู่ข้างหน้า",
  i18nKey_2588: "แช่แข็ง",
  i18nKey_2589: "รหัสผ่านแบบไดนามิก",
  i18nKey_2590: "การใช้พลังงาน",
  i18nKey_2591: "ไม่สามารถโหลดภาพเคลื่อนไหว",
  i18nKey_2592: "ตะวันออกเฉียงเหนือ",
  i18nKey_2593: "สถานะการสั่งซื้อ",
  i18nKey_2594: "สถานะการชำระเงินในรายการสั่งซื้อ",
  i18nKey_2595: "ช่วงเวลาสั่งซื้อ",
  i18nKey_2596: "จำนวนการสั่งซื้อ",
  i18nKey_2597: "เลขที่คำสั่งซื้อ / ชื่อพื้นที่",
  i18nKey_2598: "เลขที่คำสั่งซื้อ / ชื่อห้องประชุม",
  i18nKey_2599: "เลขที่ใบสั่งซื้อ",
  i18nKey_2600: "รายได้จากการสั่งซื้อ",
  i18nKey_2601: "ยอดขายหน้าร้าน 5 อันดับแรก",
  i18nKey_2602: "นาฬิกาดิจิตอล",
  i18nKey_2603: "จำนวนลิฟต์ทั้งหมด",
  i18nKey_2604: "บันทึกการทำงานของลิฟต์",
  i18nKey_2605: "ข้อมูลลิฟต์",
  i18nKey_2606: "รูปภาพลิฟต์",
  i18nKey_2607: "สถิติลิฟต์",
  i18nKey_2608: "ชื่อลิฟต์:",
  i18nKey_2609: "ชื่อลิฟต์",
  i18nKey_2610: "ประเภทลิฟต์:",
  i18nKey_2611: "ประเภทลิฟต์",
  i18nKey_2612: "ที่อยู่ลิฟต์",
  i18nKey_2613: "รหัสลิฟต์:",
  i18nKey_2614: "ลิฟต์",
  i18nKey_2615: "การสูญเสียทั้งหมดของหม้อแปลง (กิโลวัตต์ชั่วโมง)",
  i18nKey_2616: "การสูญเสียพลังงาน",
  i18nKey_2617: "เครื่องใช้ไฟฟ้าชั้น 1",
  i18nKey_2618: "การใช้พลังงานไฟฟ้า (กิโลวัตต์ชั่วโมง)",
  i18nKey_2619: "หมายเลขเคเบิ้ลสแควร์",
  i18nKey_2620: "โทรศัพท์",
  i18nKey_2621: "ค่าไฟฟ้าสะสม",
  i18nKey_2622: "พ่อค้าที่ค้างชำระค่าไฟฟ้า",
  i18nKey_2623: "จำนวนการชาร์จไฟฟ้าที่ไม่ได้หัก",
  i18nKey_2624: "ปริมาณการชาร์จไฟฟ้าสะสม",
  i18nKey_2625: "ประเภทแบตเตอรี่",
  i18nKey_2626: "เกณฑ์การเตือนมิเตอร์ไฟฟ้า:",
  i18nKey_2627: "หมายเลขซีเรียลของมิเตอร์",
  i18nKey_2628: "ชื่อมิเตอร์ต้องไม่ว่างเปล่า",
  i18nKey_2629: "ชื่อมิเตอร์",
  i18nKey_2630: "รายการย่อยซ่อมบำรุงมิเตอร์ไฟฟ้าสำเร็จ!",
  i18nKey_2631: "บันทึกรายละเอียดของมิเตอร์ไฟฟ้า",
  i18nKey_2632: "หมายเลขมิเตอร์หรือรายการย่อยที่คิดเป็นต้องไม่ว่างเปล่า",
  i18nKey_2633: "หมายเลขมิเตอร์",
  i18nKey_2634: "ประเภทจุด",
  i18nKey_2635: "คลิกลองใหม่",
  i18nKey_2636: "อนุญาตให้แก้ไขได้ก็ต่อเมื่อคุณคลิกหยุดนโยบาย และจะมีผลภายในครึ่งชั่วโมงหลังจากที่คุณคลิกเปิดใช้งาน",
  i18nKey_2637: "คลิกเพื่อดู",
  i18nKey_2638: "ค่าธรรมเนียมการชำระเงินของบุคคลที่สาม (หยวน)",
  i18nKey_2639: "ผู้ใช้บุคคลที่สาม",
  i18nKey_2640: "ผู้เยี่ยมชมบุคคลที่สาม",
  i18nKey_2641: "รหัสบุคคลที่สาม",
  i18nKey_2642: "สัปดาห์แรก ...",
  i18nKey_2643: "สัปดาห์ $",
  i18nKey_2644: "เวลาเปิดของกฎแสง $ ในกลยุทธ์ $ ต้องไม่ว่างเปล่า",
  i18nKey_2645: "เวลาที่มีประสิทธิภาพของกลยุทธ์ $ th ต้องไม่ว่างเปล่า",
  i18nKey_2646: "การป้อนข้อมูลลองจิจูดและละติจูดของที่อยู่ต้องคั่นด้วยภาษาอังกฤษ ลูกน้ำ และต้องมีความยาวไม่เกิน 20 อักษร",
  i18nKey_2647: "ระบุละติจูดและลองจิจูด",
  i18nKey_2648: "การจัดการที่อยู่",
  i18nKey_2649: "ที่อยู่ไม่รองรับอักษรพิเศษ (อนุญาตให้ใช้อักษรจีนตัว อักษรและตัวเลข)",
  i18nKey_2650: "คำเตือนแรงดันไฟฟ้าต่ำ",
  i18nKey_2651: "เท่ากัน",
  i18nKey_2652: "ระดับ",
  i18nKey_2653: "เวลาเช็คอิน",
  i18nKey_2654: "สวิตช์ไฟ",
  i18nKey_2655: "จำนวนหลอดไฟ",
  i18nKey_2656: "สวิตช์เซ็นเซอร์แสง",
  i18nKey_2657: "เครื่องอ่านรหัส QR Barrier",
  i18nKey_2658: "พันธมิตรฯ",
  i18nKey_2659: "สถานการณ์มาถึง",
  i18nKey_2660: "จำนวนเงินในบัญชี",
  i18nKey_2661: "การหลั่งไหลของผู้เยี่ยมชม",
  i18nKey_2662: "นำเข้าเขตไฟ",
  i18nKey_2663: "การนำเข้าล้มเหลวมีข้อผิดพลาดในข้อมูลในแถว $ โปรดตรวจสอบและส่งการนำเข้าอีกครั้งในภายหลัง",
  i18nKey_2664: "เงื่อนไขการส่งออก",
  i18nKey_2665: "การส่งออกล้มเหลว รองรับข้อมูลการส่งออกสูงสุด 10,000",
  i18nKey_2666: "การส่งออกล้มเหลว ไม่มีข้อมูลที่จะส่งออก",
  i18nKey_2667: "การส่งออกล้มเหลว ไม่มีบันทึกการควบคุมการเข้าถึงในหน้าปัจจุบัน!",
  i18nKey_2668: "การส่งออกล้มเหลว ไม่มีบันทึกคำสั่งซื้อในหน้าปัจจุบัน!",
  i18nKey_2669: "การส่งออกล้มเหลว ไม่มีบันทึกการเดินทางในหน้าปัจจุบัน!",
  i18nKey_2670: "การส่งออกล้มเหลวไม่มีบันทึกการชำระค่าสาธารณูปโภคในหน้าปัจจุบัน",
  i18nKey_2671: "การส่งออกล้มเหลว ไม่มีบันทึกผู้เยี่ยมชมในหน้าปัจจุบัน",
  i18nKey_2672: "การส่งออกล้มเหลว",
  i18nKey_2673: "ส่งออกแทร็กสำเร็จ",
  i18nKey_2674: "ค่าแพ็คเกจของเดือน",
  i18nKey_2675: "การวิเคราะห์รายได้ของเดือน",
  i18nKey_2676: "การใช้พลังงานของเดือน (กิโลวัตต์ชั่วโมง)",
  i18nKey_2677: "การใช้พลังงานในพื้นที่สาธารณะของเดือน (kWh)",
  i18nKey_2678: "จำนวนการเตือนในเดือน (ครั้ง)",
  i18nKey_2679: "เดือนนี้",
  i18nKey_2680: "วันนั้น",
  i18nKey_2681: "การเตือนใหม่ในวันนั้น",
  i18nKey_2682: "การใช้พลังงานของวัน (กิโลวัตต์ - ชั่วโมง)",
  i18nKey_2683: "ค่าธรรมเนียมถูกระงับชั่วคราว",
  i18nKey_2684: "การใช้พลังงานในพื้นที่สาธารณะในวันนั้น (กิโลวัตต์ชั่วโมง)",
  i18nKey_2685: "จำนวนการเช็คอินในวันนั้น",
  i18nKey_2686: "วันนั้น",
  i18nKey_2687: "สถานะปัจจุบัน",
  i18nKey_2688: "ตอนนี้ยังไม่มีโปรแกรม กรุณาตั้งโปรแกรมก่อน!",
  i18nKey_2689: "การทำงานของพารามิเตอร์ในปัจจุบัน",
  i18nKey_2690: "ปัจจุบันย้ายไปที่",
  i18nKey_2691: "ไม่มีบันทึกคำสั่งซื้อในหน้าปัจจุบันและไม่จำเป็นต้องดำเนินการส่งออก!",
  i18nKey_2692: "หน้าปัจจุบันคือ",
  i18nKey_2693: "ไม่มีห้องในตำแหน่งปัจจุบัน โปรดเลือกห้อง!",
  i18nKey_2694: "ไม่มีการเพิ่มผู้อนุมัติในขณะนี้ โปรดเพิ่มผู้อนุมัติ",
  i18nKey_2695: "ทรัพยากรข้อมูลปัจจุบัน",
  i18nKey_2696: "เวลาปัจจุบัน",
  i18nKey_2697: "เบราว์เซอร์ปัจจุบันไม่รองรับ websocket",
  i18nKey_2698: "พลังปัจจุบัน",
  i18nKey_2699: "ทรัพยากรบริการปัจจุบัน",
  i18nKey_2700: "การตรวจสอบไฟล์นำเข้าปัจจุบันไม่ถูกต้อง โปรดแก้ไขในเครื่องแล้วนำเข้าอีกครั้ง!",
  i18nKey_2701: "อัตราการใช้พื้นที่จอดรถในปัจจุบัน",
  i18nKey_2702: "สาขาปัจจุบัน",
  i18nKey_2703: "ทรัพยากรปฏิบัติการในปัจจุบัน",
  i18nKey_2704: "ด้านล่างปัจจุบัน",
  i18nKey_2705: "เมนูทรัพยากรปัจจุบัน",
  i18nKey_2706: "เวลานั้น",
  i18nKey_2707: "หน่วย:",
  i18nKey_2708: "หน่วย",
  i18nKey_2709: "การใช้พลังงานต่อตารางเมตร",
  i18nKey_2710: "หน่วย: หยวน",
  i18nKey_2711: "หน่วย: คน",
  i18nKey_2712: "หน่วย: ครั้ง",
  i18nKey_2713: "หน่วย: กิโลวัตต์ - ชั่วโมง",
  i18nKey_2714: "หน่วย: ชั่วโมง",
  i18nKey_2715: "หน่วย: สำเนา",
  i18nKey_2716: "หน่วย: นาที",
  i18nKey_2717: "ราคาต่อหน่วย / ครั้ง",
  i18nKey_2718: "ราคาต่อหน่วย (หยวน / ชั่วโมง)",
  i18nKey_2719: "เวลาทัวร์เดี่ยว",
  i18nKey_2720: "ระยะเวลาสูงสุดและวันที่เข้าพักเดี่ยว",
  i18nKey_2721: "การหักเงินเดี่ยว",
  i18nKey_2722: "ที่จะต้องจ่าย",
  i18nKey_2723: "ที่จะได้รับ",
  i18nKey_2724: "ความสว่างขณะสแตนด์บาย",
  i18nKey_2725: "จำนวนสัญญาณเตือนที่รอดำเนินการ",
  i18nKey_2726: "ที่จะดำเนินการ",
  i18nKey_2727: "รอดำเนินการ",
  i18nKey_2728: "ที่จะติดตั้ง",
  i18nKey_2729: "แสดงถึงความผิดปกติ",
  i18nKey_2730: "แสดงถึงข้อมูลที่ผิดปกติ",
  i18nKey_2731: "มากกว่าหรือเท่ากับ",
  i18nKey_2732: "มากกว่า 400",
  i18nKey_2733: "มากกว่า 2",
  i18nKey_2734: "มากกว่า",
  i18nKey_2735: "ธุรกิจใหญ่",
  i18nKey_2736: "ตำแหน่งหน้าจอขนาดใหญ่",
  i18nKey_2737: "ชื่อจอขนาดใหญ่",
  i18nKey_2738: "การตั้งค่าการเล่นหน้าจอขนาดใหญ่",
  i18nKey_2739: "แน่นอน Dadao ต่อสู้ในการเป็นหุ้นส่วน",
  i18nKey_2740: "เปิดการแจ้งเตือน",
  i18nKey_2741: "การจัดประเภทบัตรเจาะ",
  i18nKey_2742: "การเช็คอินล้มเหลว",
  i18nKey_2743: "เช็คอินเรียบร้อย",
  i18nKey_2744: "บริษัทรถ 5 อันดับแรก",
  i18nKey_2745: "จาก $ ถึง $ ความล้มเหลวเกิดขึ้นซ้ำ ๆ เพียงครั้งเดียวเวลาสะสมคือ $ ชั่วโมงซึ่งอาจทำให้เสียตลอดทั้งปี",
  i18nKey_2746: "ห้องนอนที่สอง",
  i18nKey_2747: "การดำเนินการนี้จะลบไฟล์อย่างถาวร คุณต้องการดำเนินการต่อหรือไม่",
  i18nKey_2748: "เวลาสร้าง",
  i18nKey_2749: "ผู้สร้าง",
  i18nKey_2750: "การเลือกโหมดการสร้าง",
  i18nKey_2751: "สร้างระดับพื้นที่อย่างน้อยของอาคาร!",
  i18nKey_2752: "สร้างคะแนน",
  i18nKey_2753: "สร้างห้อง",
  i18nKey_2754: "สร้างฉาก",
  i18nKey_2755: "สร้าง",
  i18nKey_2756: "เงื่อนไขการทริกเกอร์:",
  i18nKey_2757: "ปริมาณการประมวลผล",
  i18nKey_2758: "ระยะเวลาดำเนินการ",
  i18nKey_2759: "การประมวลผล",
  i18nKey_2760: "กลไกการประมวลผล",
  i18nKey_2761: "ห้องเก็บของ",
  i18nKey_2762: "สถิติการจัดเก็บพลังงาน",
  i18nKey_2763: "ห้องครัว",
  i18nKey_2764: "เวลาเริ่มต้น",
  i18nKey_2765: "มีการละเมิด $",
  i18nKey_2766: "ความวุ่นวายในการเข้าและออก",
  i18nKey_2767: "เวลาจอดรถ",
  i18nKey_2768: "เวลาเล่น",
  i18nKey_2769: "วันผลิต",
  i18nKey_2770: "ประเภทการเติมเงิน",
  i18nKey_2771: "จำนวนการเติมเงินสะสม",
  i18nKey_2772: "บริษัทเติมเงิน",
  i18nKey_2773: "เติมเงิน",
  i18nKey_2774: "ค่าใช้จ่ายทั้งหมด",
  i18nKey_2775: "ความจุในการชาร์จ (กิโลวัตต์ชั่วโมง)",
  i18nKey_2776: "สถานะการชำระเงิน",
  i18nKey_2777: "สถิติการขับขี่",
  i18nKey_2778: "เวลาเที่ยว",
  i18nKey_2779: "เมือง",
  i18nKey_2780: "อุตสาหกรรมให้เช่า",
  i18nKey_2781: "ชื่อผู้เช่าต้องไม่ว่างเปล่า",
  i18nKey_2782: "ชื่อผู้เช่า",
  i18nKey_2783: "ผู้เช่าอุตสาหกรรม",
  i18nKey_2784: "ผู้เช่า",
  i18nKey_2785: "เวลาในการคืนเงินสำเร็จ",
  i18nKey_2786: "เฉิงตู",
  i18nKey_2787: "ความสำเร็จไม่ได้เกี่ยวกับความแข็งแกร่ง แต่จะอยู่ได้นานแค่ไหน",
  i18nKey_2788: "ชื่อเจ้าของ",
  i18nKey_2789: "ประเภทเจ้าของ",
  i18nKey_2790: "โทรศัพท์เจ้าของรถ",
  i18nKey_2791: "จำนวนที่จอดรถทั้งหมด",
  i18nKey_2792: "จำนวนที่จอดรถว่าง",
  i18nKey_2793: "สถิติการใช้พื้นที่จอดรถ",
  i18nKey_2794: "สถิติอัตราการใช้พื้นที่จอดรถ",
  i18nKey_2795: "สอบถามพื้นที่จอดรถ",
  i18nKey_2796: "ดัชนีป้ายทะเบียน",
  i18nKey_2797: "มีให้เลือกมากกว่า 6 ตัวเลือกสำหรับป้ายทะเบียนและสี",
  i18nKey_2798: "มีการแสดงตัวเลือกสำหรับป้ายทะเบียนและยี่ห้อมากกว่า 6 รายการให้คุณเลือกสิ่งที่ดีที่สุด",
  i18nKey_2799: "ไม่มีหมายเลขป้ายทะเบียน",
  i18nKey_2800: "ต้องระบุหมายเลขป้ายทะเบียน",
  i18nKey_2801: "หมายเลขป้ายทะเบียน",
  i18nKey_2802: "ป้ายทะเบียนมีมากกว่า 6 ตัวเลือก ซึ่งได้รับการแสดงสำหรับคุณตามการเลือกที่ดหมาะสมที่สุด",
  i18nKey_2803: "ดึงข้อมูลป้ายทะเบียน",
  i18nKey_2804: "มีให้เลือกมากกว่า 6 ป้ายทะเบียนยี่ห้อและสี",
  i18nKey_2805: "สถิติการจราจร",
  i18nKey_2806: "สีรถ",
  i18nKey_2807: "ข้อจำกัดของยานพาหนะ",
  i18nKey_2808: "จำนวนแพ็คเกจรถและค่าจอดรถ",
  i18nKey_2809: "จำนวนแพ็คเกจยานพาหนะ (รถยนต์)",
  i18nKey_2810: "รายละเอียดข้อมูลยานพาหนะ",
  i18nKey_2811: "อาณาเขตของยานพาหนะ",
  i18nKey_2812: "บันทึกการออกรถ",
  i18nKey_2813: "สีรถ",
  i18nKey_2814: "ใบรับรองรถ",
  i18nKey_2815: "ยี่ห้อยานพาหนะ",
  i18nKey_2816: "การวิเคราะห์ยานพาหนะ",
  i18nKey_2817: "ประเภทยานพาหนะเต็มเส้นทาง",
  i18nKey_2818: "การแจ้งเตือนการมาถึงของยานพาหนะ",
  i18nKey_2819: "ป้ายรถ",
  i18nKey_2820: "ยานพาหนะ",
  i18nKey_2821: "หมายเลขรถไฟ",
  i18nKey_2822: "ข้อจำกัดที่จอดรถ",
  i18nKey_2823: "ที่ตั้งคลัง:",
  i18nKey_2824: "ที่ตั้งคลัง",
  i18nKey_2825: "ชื่อคลัง:",
  i18nKey_2826: "ชื่อคลัง",
  i18nKey_2827: "สถิติที่จอดรถ",
  i18nKey_2828: "ลานจอดรถ",
  i18nKey_2829: "มากกว่า $ ครั้ง ไม่สามารถดำเนินการผ่านแบบรายเดือนได้",
  i18nKey_2830: "มากกว่า $ ครั้ง ไม่สามารถเข้าที่จอดรถได้",
  i18nKey_2831: "เกินขีดจำกัดการ export สูงสุด 2000",
  i18nKey_2832: "เกินขีดจำกัดการ export สูงสุด 10 รายการ",
  i18nKey_2833: "การอ่านที่ประสบความสำเร็จ",
  i18nKey_2834: "สำเนา",
  i18nKey_2835: "ต้องระบุวันที่อ่านมิเตอร์และค่ามิเตอร์พร้อมกัน",
  i18nKey_2836: "วันที่อ่านมิเตอร์",
  i18nKey_2837: "เครื่องอ่านมิเตอร์",
  i18nKey_2838: "ต้องไม่ว่างเปล่าระหว่างการอ่านมิเตอร์",
  i18nKey_2839: "ระหว่างการอ่านมิเตอร์",
  i18nKey_2840: "คัดลอกและบันทึก",
  i18nKey_2841: "คัดลอกและแสดงได้ดี",
  i18nKey_2842: "การนำเข้าค่าการอ่านมิเตอร์",
  i18nKey_2843: "จำนวนคนในสถานที่",
  i18nKey_2844: "ไอคอนฉาก",
  i18nKey_2845: "สวิตช์ล็อคฉาก:",
  i18nKey_2846: "จำนวนฉาก",
  i18nKey_2847: "ชื่อฉาก",
  i18nKey_2848: "ID ฉาก",
  i18nKey_2849: "ชื่อผู้จำหน่ายต้องไม่ว่างเปล่า",
  i18nKey_2850: "ชื่อการค้า",
  i18nKey_2851: "โรงงาน",
  i18nKey_2852: "เจ้าหน้าที่ประจำ",
  i18nKey_2853: "ธรรมดา",
  i18nKey_2854: "ชื่อผลิตภัณฑ์",
  i18nKey_2855: "ผลิตภัณฑ์",
  i18nKey_2856: "ลบการแจ้งเตือน",
  i18nKey_2857: "กรุณากรอกชื่อนามสกุลหรือหมายเลขโทรศัพท์มือถือของคุณสำหรับใช้ที่ลงทะเบียน",
  i18nKey_2858: "การค้นหาจำนวนข้อมูลอินเทอร์เฟซที่ไม่มีประสิทธิภาพเกิดข้อผิดพลาด โปรดลองอีกครั้งในภายหลัง",
  i18nKey_2859: "โปรดจำกัดช่วงเวลาการสืบค้นเป็น 90 วัน",
  i18nKey_2860: "รายการค้นหาล้มเหลว",
  i18nKey_2861: "ตรวจสอบราคาที่ลดแล้ว",
  i18nKey_2862: "ดูเกณฑ์คำเตือน",
  i18nKey_2863: "ดูส่วนลดในเวลาจำกัด",
  i18nKey_2864: "ดูภาพ",
  i18nKey_2865: "ดูวิดีโอ",
  i18nKey_2866: "ดูกล้อง",
  i18nKey_2867: "ดูอุปกรณ์",
  i18nKey_2868: "ดูแทร็ก",
  i18nKey_2869: "ตรวจสอบตารางเดินรถ",
  i18nKey_2870: "ดู",
  i18nKey_2871: "ชื่อนโยบายต้องไม่ว่างเปล่า",
  i18nKey_2872: "ชื่อนโยบายต้องไม่มีอักษรพิเศษ",
  i18nKey_2873: "ชื่อนโยบายต้องมีความยาวไม่เกิน 20 ตัวอักษร",
  i18nKey_2874: "ชื่อกลยุทธ์",
  i18nKey_2875: "การวัดอุณหภูมิ",
  i18nKey_2876: "เทียบเวลา",
  i18nKey_2877: "WC",
  i18nKey_2878: "ตัวดำเนินการ",
  i18nKey_2879: "การดำเนินการล้มเหลวโปรดลองอีกครั้งในภายหลัง",
  i18nKey_2880: "การดำเนินการล้มเหลว",
  i18nKey_2881: "ข้อเสนอแนะการดำเนินงาน",
  i18nKey_2882: "ร้านอาหาร",
  i18nKey_2883: "พารามิเตอร์",
  i18nKey_2884: "ข้อมูลอ้างอิงของช่วงเวลา",
  i18nKey_2885: "ยอดขายอาหาร 5 อันดับแรก",
  i18nKey_2886: "ความถี่ในการได้มา",
  i18nKey_2887: "สาขา:",
  i18nKey_2888: "สถานะการควบคุม",
  i18nKey_2889: "เวลาในการปรับใช้ต้องไม่ว่างเปล่า",
  i18nKey_2890: "เวลาในการปรับใช้ต้องมากกว่าหรือเท่ากับเวลาปัจจุบัน",
  i18nKey_2891: "ระยะเวลาควบคุม",
  i18nKey_2892: "กล้องควบคุม",
  i18nKey_2893: "ควบคุมการจับคู่",
  i18nKey_2894: "การควบคุมการปรับใช้",
  i18nKey_2895: "ไม่ทำซ้ำ",
  i18nKey_2896: "ไม่รองรับอักษรพิเศษรองรับเฉพาะภาษาจีน อังกฤษและตัวเลขเท่านั้น",
  i18nKey_2897: "ไม่จำกัดเวลา",
  i18nKey_2898: "ไม่จำกัด",
  i18nKey_2899: "การแจ้งเตือนที่ไม่แน่นอน",
  i18nKey_2900: "ไม่สามารถเปลี่ยนระดับได้ ระดับปัจจุบันคือ.....",
  i18nKey_2901: "ไม่สามารถส่งออกข้อมูลเปล่า",
  i18nKey_2902: "ไม่สามารถส่งออกข้อมูลมากกว่า 10,000 รายการ",
  i18nKey_2903: "ไม่สามารถเคลื่อนย้ายได้",
  i18nKey_2904: "ไม่สามารถเพิ่มเครือข่ายลูกได้",
  i18nKey_2905: "ไม่สามารถลบได้",
  i18nKey_2906: "ไม่สามารถแก้ไขได้",
  i18nKey_2907: "ไม่เท่ากับ",
  i18nKey_2908: "ช่วงเวลาบันทึกเสริม",
  i18nKey_2909: "การอ่านเสริม",
  i18nKey_2910: "BoYu Health Check-in_",
  i18nKey_2911: "ท่าเทียบเรือ",
  i18nKey_2912: "การตั้งค่าการเล่น:",
  i18nKey_2913: "เล่นโหมดเปลี่ยนหน้าต่าง",
  i18nKey_2914: "เล่น",
  i18nKey_2915: "วิลล่า",
  i18nKey_2916: "แบบฟอร์มเทมเพลต.xlsx",
  i18nKey_2917: "ด้านล่าง",
  i18nKey_2918: "การกรองแท็ก",
  i18nKey_2919: "ความจุหม้อแปลง (kVA)",
  i18nKey_2920: "เปลี่ยนเวลา",
  i18nKey_2921: "แก้ไขเกณฑ์คำเตือน",
  i18nKey_2922: "แก้ไข",
  i18nKey_2923: "การกำหนดหมายเลข",
  i18nKey_2924: "ต้องเป็นจำนวนเต็ม",
  i18nKey_2925: "ต้องเป็นทศนิยมจำนวนหนึ่งหรือสองตัว!",
  i18nKey_2926: "ในสัปดาห์นี้",
  i18nKey_2927: "ปริมาณการใช้ไฟฟ้าจริงในเดือนนี้ (กิโลวัตต์)",
  i18nKey_2928: "การใช้พลังงานสะสมในเดือนนี้",
  i18nKey_2929: "ภาพรวมการหลั่งไหลของผู้โดยสารในเดือนนี้",
  i18nKey_2930: "จองห้องประชุมเดือนนี้",
  i18nKey_2931: "เดือนนี้",
  i18nKey_2932: "พลังงานสำหรับรายการนี้",
  i18nKey_2933: "ปริมาณการใช้น้ำในช่วงนี้ (t)",
  i18nKey_2934: "การใช้พลังงานในช่วงนี้",
  i18nKey_2935: "การใช้ไฟฟ้าในปัจจุบัน (กิโลวัตต์ชั่วโมง)",
  i18nKey_2936: "การใช้ไฟฟ้าในปัจจุบัน",
  i18nKey_2937: "ไม่ได้คัดลอก / คัดลอกในช่วงนี้ (ก)",
  i18nKey_2938: "เวลาปัจจุบัน",
  i18nKey_2939: "การใช้พลังงานต่อตารางเมตรในช่วงนี้",
  i18nKey_2940: "ช่วงเวลานี้",
  i18nKey_2941: "ปีนี้",
  i18nKey_2942: "การใช้พลังงานในระดับนี้",
  i18nKey_2943: "อุณหภูมิในท้องถิ่น",
  i18nKey_2944: "ความชื้นในท้องถิ่น",
  i18nKey_2945: "สามารถอัปโหลดภาพถ่ายในเครื่องไม่เกิน 3M",
  i18nKey_2946: "เซิร์ฟเวอร์ภายใน",
  i18nKey_2947: "ท้องถิ่น",
  i18nKey_2948: "ยังมีตาราง {number} ที่ไม่ได้ใส่ในครั้งนี้ คุณต้องการให้มีผลต่อไปไหม",
  i18nKey_2949: "เวลาอ่านมิเตอร์ต้องไม่ว่างเปล่า",
  i18nKey_2950: "วันที่อ่านมิเตอร์นี้",
  i18nKey_2951: "ปริมาณมิเตอร์จากการอ่านครั้งนี้มากกว่าการอ่านครั้งล่าสุดมาก คุณยืนยันการแก้ไขหรือไม่",
  i18nKey_2952: "ค่าการอ่านค่ามิเตอร์นี้ต้องไม่ว่างเปล่า",
  i18nKey_2953: "การอ่านมิเตอร์",
  i18nKey_2954: "จำนวนบริษัทที่สัมภาษณ์ทั้งหมด (คน)",
  i18nKey_2955: "การขยาย",
  i18nKey_2956: "ชื่อหมายเหตุต้องเป็นตัวอักษรภาษาจีนและขีดล่างภายใน 20 อักษรเท่านั้น",
  i18nKey_2957: "หมายเหตุชื่อ",
  i18nKey_2958: "อะไหล่ (ก)",
  i18nKey_2959: "ปักกิ่ง",
  i18nKey_2960: "ประเภทการซ่อม",
  i18nKey_2961: "ใบสั่งงานซ่อม",
  i18nKey_2962: "โทรหาตำรวจ",
  i18nKey_2963: "วันที่เสีย",
  i18nKey_2964: "ทิ้ง",
  i18nKey_2965: "รายงาน",
  i18nKey_2966: "วันที่รับประกัน",
  i18nKey_2967: "ทำความสะอาด",
  i18nKey_2968: "ราคาต่อหน่วยครึ่งวัน (หยวน / 4 ชั่วโมง)",
  i18nKey_2969: "รายละเอียดการสั่งซื้อพื้นที่สำนักงาน",
  i18nKey_2970: "โทรศัพท์สำนักงาน",
  i18nKey_2971: "เปลี่ยนหมายเลข",
  i18nKey_2972: "รถรับส่ง",
  i18nKey_2973: "เหตุผลในการเยี่ยมชม:",
  i18nKey_2974: "สีขาว",
  i18nKey_2975: "รายการที่อนุญาต",
  i18nKey_2976: "รายงานการสั่งซื้อรถบัส _",
  i18nKey_2977: "รายงานบันทึกการนั่งรถโดยสาร",
  i18nKey_2978: "ตารางเดินรถ",
  i18nKey_2979: "รายสัปดาห์",
  i18nKey_2980: "รายเดือน",
  i18nKey_2981: "โดยความเหมือน",
  i18nKey_2982: "ตามเวลา",
  i18nKey_2983: "ตรงเวลา",
  i18nKey_2984: "ตามวัน",
  i18nKey_2985: "แหล่งที่มาของปุ่ม",
  i18nKey_2986: "ตามปี",
  i18nKey_2987: "ดูตามรายการ",
  i18nKey_2988: "การติดตั้ง",
  i18nKey_2989: "สถานที่ติดตั้ง",
  i18nKey_2990: "เวลาติดตั้ง",
  i18nKey_2991: "ตัวติดตั้ง",
  i18nKey_2992: "จำนวนทรัพย์สิน RFID ที่ติดตั้ง",
  i18nKey_2993: "แนวโน้มเหตุการณ์ด้านความปลอดภัย",
  i18nKey_2994: "สถานการณ์ด้านความปลอดภัย",
  i18nKey_2995: "สัดส่วนประเภทของเหตุการณ์ด้านความปลอดภัย",
  i18nKey_2996: "เจ้าหน้าที่รักษาความปลอดภัย",
  i18nKey_2997: "ประเภท VCM",
  i18nKey_2998: "ประเภท URL",
  i18nKey_2999: "อัตราการใช้ RFID (ตามประเภท)",
  i18nKey_3000: "อัตราการใช้ RFID",
  i18nKey_3001: "หมายเลข RFID",
  i18nKey_3002: "หมายเลข PID",
  i18nKey_3003: "ทรัพยากรเมนู",
  i18nKey_3004: "เมนู",
  i18nKey_3005: "หมายเลขทางกายภาพ",
  i18nKey_3006: "ขีด จำกัด บน KPI",
  i18nKey_3007: "รหัสไอทีและรหัสธุรกิจต้องผสมกันระหว่างตัวอักษร ตัวเลขและขีดล่างเท่านั้น!",
  i18nKey_3008: "การเข้ารหัสไอที",
  i18nKey_3009: "ชื่อการจำแนกประเภทอุปกรณ์ IOC Class 1",
  i18nKey_3010: "รหัสการจำแนกประเภทอุปกรณ์ IOC Class I",
  i18nKey_3011: "ชื่อการจำแนกประเภทอุปกรณ์ IOC Class III",
  i18nKey_3012: "รหัสการจำแนกประเภทอุปกรณ์ IOC Class III",
  i18nKey_3013: "การเข้ารหัสแบบจำลองเชิงพื้นที่ IOC",
  i18nKey_3014: "ชื่อการจำแนกประเภทอุปกรณ์รองของ IOC",
  i18nKey_3015: "รหัสการจำแนกประเภทอุปกรณ์รองของ IOC",
  i18nKey_3016: "ข้อตกลงการให้บริการ IOC (Intelligence Operation Center หรือ Intelligent Operation Center)",
  i18nKey_3017: "ประเภทการประสานงาน GIS",
  i18nKey_3018: "ประสานงาน GIS",
  i18nKey_3019: "การซิงโครไนซ์ GIS",
  i18nKey_3020: "การซิงโครไนซ์ GIS",
  i18nKey_3021: "แผนภูมิ Echarts",
  i18nKey_3022: "กระแสเฟส C (A)",
  i18nKey_3023: "แรงดันไฟฟ้าเฟส B (V)",
  i18nKey_3024: "แผนที่แบนเนอร์",
  i18nKey_3025: "กระแสเฟส A (A)",
  i18nKey_3026: "การประมวลผลการวินิจฉัย AI",
  i18nKey_3027: "9 เดือน",
  i18nKey_3028: "ประกอบด้วยตัวอักษรตัวเลขและอักขระพิเศษอย่างน้อย 2 ตัวจาก 8-20 ตัว",
  i18nKey_3029: "8-20 หลัก ตัวเลข, ตัวอักษร, อักขระพิเศษบางตัว",
  i18nKey_3030: "ส่วนลด 6 เดือน",
  i18nKey_3031: "6 เดือน",
  i18nKey_3032: "ส่วนลด 3 เดือน",
  i18nKey_3033: "3 เดือน",
  i18nKey_3034: "1 ปีขึ้นไป",
  i18nKey_3035: "การอ่านค่า 0 จุด (กิโลวัตต์ชั่วโมง)",
  i18nKey_3036: "การอ่านค่า 0 จุด (กิโลวัตต์ชั่วโมง)",
  i18nKey_3037: "นโยบายความเป็นส่วนตัว",
  i18nKey_3038: "สัญญาการให้บริการ",
  i18nKey_3039: "* รองรับเฉพาะรูปภาพในรูปแบบ png, gif, jpg และมีขนาดไม่เกิน 3M",
  i18nKey_3040: "(การใช้พลังงานทั้งหมดกิโลวัตต์ชั่วโมง)",
  i18nKey_3041: "(ราคาแปลงอัตโนมัติ)",
  i18nKey_3042: "(หยวน)",
  i18nKey_3043: "(ปริมาณ)",
  i18nKey_3044: "(ตัวอย่าง: P85)",
  i18nKey_3045: "(สัดส่วนการสูญเสียกำลัง)",
  i18nKey_3046: "(จำเป็น)",
  i18nKey_3047: "ในโครงการทั้งหมดคุณพบไฟล์",
  i18nKey_3048: "มูลค่าจริง $ ปี",
  i18nKey_3049: "มูลค่าเป้าหมาย $ ปี",
  i18nKey_3050: "เปิด $ Loops ไม่สำเร็จ",
  i18nKey_3051: "เปิด $ Loops สำเร็จแล้ว",
  i18nKey_3052: "มกราคม",
  i18nKey_3053: "พฤษภาคม",
  i18nKey_3054: "เมษายน",
  i18nKey_3055: "ตุลาคม",
  i18nKey_3056: "พฤศจิกายน",
  i18nKey_3057: "ธันวาคม",
  i18nKey_3058: "มีนาคม",
  i18nKey_3059: "โปรดป้อนพิกัด GIS coordinates ที่ถูกต้อง เช่น (20.17N, 134.53W)",
  i18nKey_3060: "กรกฎาคม",
  i18nKey_3061: "มิถุนายน",
  i18nKey_3062: "กันยายน",
  i18nKey_3063: "กุมภาพันธ์",
  i18nKey_3064: "สิงหาคม",
  i18nKey_3065: "มีพิกัด GIS อยู่แล้ว กรุณาระบุใหม่",
};
