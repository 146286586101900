import Vue from "vue";
// 页面内按钮权限控制
Vue.directive("read", function(el, binding) {
  // let permData = sessionStorage.getItem("permData");
  // if (permData) {
  //   let permission = JSON.parse(permData);
  //   // let permission = permData;
  //   let value = binding.value;
  //   for (var i = 0; i < permission.length; i++) {
  //     if (permission[i].code === value.page) {
  //       let page = permission[i].childs;
  //       let btnArr = [];
  //       for (var j = 0; j < page.length; j++) {
  //         btnArr.push(page[j].code);
  //       }
  //       if (btnArr.indexOf(value.button) === -1) {
  //         el.style.display = "none";
  //       }
  //     }
  //   }
  // }
});
