
import store from "@/store";
import comComponents from "comComponents";
let getRequest = comComponents.utils.getRequest;
export default getRequest({
  beforeRequest(config, {addPositionParams = true}) {
    let {data = {}} = config;
    let isDataObject = Object.prototype.toString.call(data) === "[object Object]";
    if (addPositionParams && isDataObject) {
      let {posCode = "", region = ""} = store.getters["basePosition/positionInfo"];
      if (posCode && region) {
        config.data = {posCode, region, ...data};
      }
    }
    store.commit("fillNowToXHRTimestamp");

    return config;
  }
});
