import api from "@/js/api.js";
const baseUrl = "/ioc/homelink";
// const baseUrl = "";

/**
 * @desc 获取单元or房号信息
 * @param {parkCode, ?spaceLevel, ?buildingUnitSpaceId}
 * @intro 仅传入parkCode时获取单元信息，否则获取房号信息
 */
export async function getSpaceList(data) {
  const config = {
    method: "get",
    url: `${baseUrl}/space/list`,
    data
  };
  const result = await api(config);
  return Promise.resolve(result);
};

/**
 * @desc 查询空间下支持tca属性的设备
 * @param {houseId, abilityTagKey}
 * @intro 房间id，abilityTagKey的值有trigger，condition，action
 */
export async function getSpaceEquipList(data) {
  const config = {
    method: "get",
    url: `${baseUrl}/equipment/space/tca/device`,
    data
  };
  const result = await api(config, {handleErr: false});
  return Promise.resolve(result);
};

/**
 * @desc 查询空间场景列表
 * @param {spaceId}
 * @intro 主键id
 */
export async function searchSpaceSceneList(data) {
  const config = {
    method: "get",
    url: `${baseUrl}/scene/space/list`,
    data
  };
  const result = await api(config, {handleErr: false});
  return Promise.resolve(result);
};

/**
 * @desc 创建空间场景
 * @param {spaceId}
 * @intro 空间id
 */
export async function addSpaceScene(data) {
  const config = {
    method: "post",
    url: `${baseUrl}/scene/create`,
    data
  };
  const result = await api(config, {handleErr: false});
  return Promise.resolve(result);
};

/**
 * @desc 空间场景开关
 * @param {enable, id, sceneId}
 * @intro 场景开关值, 主键id, 阿里场景id
 */
export async function switchSpaceScene(data) {
  const config = {
    method: "put",
    url: `${baseUrl}/scene/switch`,
    data
  };
  const result = await api(config, {handleErr: false});
  return Promise.resolve(result);
};

/**
 * @desc 获取设备列表
 * @param {spaceId} store中的id
 */
export async function getEquipList(data) {
  const config = {
    method: "get",
    url: `${baseUrl}/equipment/list`,
    data
  };
  const result = await api(config, {handleErr: false});
  return Promise.resolve(result);
}

/**
 * @desc 删除空间
 * @param {Number} data 删除空间的id
 */
export async function deleteSpace(data) {
  const config = {
    method: "delete",
    url: `${baseUrl}/space/delete?id=${data}`
  };
  const result = await api(config, {handleErr: false});
  return Promise.resolve(result);
}

/**
 * @desc 新增空间
 * @param {spaceLevel, parkCode, ?buildingUnitCn, ?buildingUnitSpaceId, ?houseCn}
 * @intro 有buildingUnitCn则添加单元，否则为添加房号
 */
export async function addSpace(data) {
  const config = {
    method: "post",
    url: `${baseUrl}/space/insert`,
    data
  };
  const result = await api(config, {handleErr: false});
  return Promise.resolve(result);
}

/**
 * @desc 获取添加设备列表
 * @param {pageNo, pageSize} 页码
 */
export async function getMoreEquipment(data) {
  const config = {
    method: "get",
    url: `${baseUrl}/product/info/product/category/list`,
    data
  };
  const result = await api(config);
  return Promise.resolve(result);
}

// 提交insert
export async function addMoreEquipment(data) {
  const config = {
    method: "post",
    url: `${baseUrl}/equipment/insert`,
    data
  };
  const result = await api(config, {handleErr: false});
  return Promise.resolve(result);
}

// 获取房屋模板列表
export async function getHouseTempList({parkCode = "", pageSize = 10, pageNum = 1}) {
  const config = {
    method: "post",
    url: `${baseUrl}/template/house/list?parkCode=${parkCode}`,
    data: {pageSize, pageNum}
  };
  const result = await api(config);
  return Promise.resolve(result);
}

// 删除房屋
export async function deleteHouseTemp(data) {
  const config = {
    method: "delete",
    url: `${baseUrl}/template/house`,
    data
  };
  const result = await api(config, {handleErr: false});
  return Promise.resolve(result);
}

// 获取房屋配置列表
export async function getSettingHouse({parkCode}) {
  const config = {
    method: "get",
    url: `${baseUrl}/house/list/tree/${parkCode}`,
    // url: "/house/list/tree",
    data: {}
  };
  const result = await api(config);
  return Promise.resolve(result);
}

/**
 * @description: 获取房屋树形数据（带房屋户型及子房间）
 * @param {string} parkCode 园区编码
 * @return:
 * @Date: 2019-09-10 20:21:06
 */
export async function getHouseTree({parkCode}) {
  const config = {
    method: "get",
    url: `${baseUrl}/house/list/tree/house/${parkCode}`
  };
  const result = await api(config, { handleErr: false });
  return Promise.resolve(result);
}

/**
 * @desc 新增或修改户型
 * @param {parkCode, templateName, ?id}
 * @intro 不传id就是新增，传id就是修改
 */
export async function changeHouseModel(data) {
  const config = {
    method: "post",
    url: `${baseUrl}/template/house`,
    data
  };
  const result = await api(config, {handleErr: false});
  return Promise.resolve(result);
}

/**
 * 查询设备(也可获取空间的房屋详情)
 * @param {*} data houseCode
 */
export async function getSpaceEquipmentDetail(data) {
  const config = {
    method: "get",
    url: `${baseUrl}/space/list/space/equipment`,
    data
  };
  const result = await api(config);
  return Promise.resolve(result);
}
/**
 * 查询产品TCA属性
 * @param {Object} data productKey-阿里产品key; tagKey-触发行为
 */
export async function getAbilityList(data) {
  const config = {
    method: "get",
    url: `${baseUrl}/scene/product/tca`,
    data
  };
  const result = await api(config);
  return Promise.resolve(result);
}

/**
 * 更新场景基本信息
 * @param {name, icon}
 */
export async function updateSpaceSceneInfo(data) {
  const config = {
    method: "put",
    url: `${baseUrl}/scene/update`,
    data
  };
  const result = await api(config, {handleErr: false});
  return Promise.resolve(result);
}

/**
 * 更新场景tca信息
 * @param {name, icon}
 */
export async function updateSpaceSceneTCAInfo(data) {
  const config = {
    method: "put",
    url: `${baseUrl}/scene/update/tca`,
    data
  };
  const result = await api(config, {handleErr: false});
  return Promise.resolve(result);
}

/**
 * 查询产品TCA属性
 * @param {Object} data productKey-产品key; identifier-标识
 */
export async function getAbilityDetail(data, type) {
  const config = {
    method: "get",
    url: `${baseUrl}/scene/product/tsl/${type}`,
    data
  };
  const result = await api(config);
  return Promise.resolve(result);
}

/**
 * @desc 查询模板房间列表和设备列表
 * @param {{houseId: number}} houseId 房屋的主键id
 */
export async function getHouseDetail({houseId}) {
  const config = {
    method: "get",
    url: `${baseUrl}/template/house/room/detail/${houseId}`,
    data: {}
  };
  const result = await api(config);
  return Promise.resolve(result);
}

/**
 * @desc 房屋模板下创建房间
 * @param {{roomName: string, templateId: number}} data 新建房间的名称，上级空间的主键id
 */
export async function addRoomTemp(data) {
  const config = {
    method: "post",
    url: `${baseUrl}/template/house/room`,
    data
  };
  const result = await api(config, {handleErr: false});
  return Promise.resolve(result);
}

/**
 * @desc 删除户型模板房间
 * @param {number} roomId 需要删除的房间id
 */
export async function deleteRoomTemp({roomId}) {
  const config = {
    method: "delete",
    url: `${baseUrl}/template/house/room/${roomId}`,
    data: {}
  };
  const result = await api(config, {handleErr: false});
  return Promise.resolve(result);
}

/**
 * @desc 添加模板房间设备及修改模板备注
 * @param {?productAlias, ?..., id} id 为必传字段,其余分场景
 */
export async function addRoomEquip(data) {
  const config = {
    method: "post",
    url: `${baseUrl}/template/house/room/product`,
    data
  };
  const result = await api(config, {handleErr: false});
  return Promise.resolve(result);
}

// 添加模板场景
export async function addTempScene({templateId, data}) {
  const config = {
    method: "post",
    url: `${baseUrl}/template/house/scene/${templateId}`,
    data
  };
  const result = await api(config, {handleErr: false});
  return Promise.resolve(result);
}

// 查询模板场景
export async function searchTempSceneList({templateId}) {
  const config = {
    method: "get",
    url: `${baseUrl}/template/house/scene/list`,
    data: {
      templateId
    }
  };
  const result = await api(config);
  return Promise.resolve(result);
}

/**
 * @desc 删除模板场景
 * @param {ids: [id]}  id
 */
export async function deleteTempScene(data) {
  const config = {
    method: "delete",
    url: `${baseUrl}/template/house/scene`,
    data
  };
  const result = await api(config, {handleErr: false});
  return Promise.resolve(result);
}

// 应用模板
export async function applyTemplate(data = {}) {
  const config = {
    method: "post",
    url: `${baseUrl}/template/house/apply`,
    data
  };
  const result = await api(config, {handleErr: false});
  return Promise.resolve(result);
}

/**
 * @desc 查询户型模板
 * @param {parkCode} parkCode 园区编码
 */
export async function getModelList(data) {
  const config = {
    method: "get",
    url: `${baseUrl}/template/house/list/all`,
    data
  };
  const result = await api(config);
  return Promise.resolve(result);
}

// 下载模板
export async function downTemplate() {
  const config = {
    method: "get",
    url: `${baseUrl}/template/download`,
    data: {},
    responseType: "blob"
  };
  const result = await api(config);
  return Promise.resolve(result);
}

/**
 * @desc 根据模板导入用户类型
 * @param {templateId, file} file为上传的excel文件
 */
export async function upLoadByTemplate(data) {
  const {templateId, file = ""} = data;
  const config = {
    method: "post",
    url: `${baseUrl}/house/import/${templateId}`,
    data: file
    // headers: {
    //   "Content-Type": "multipart/form-data"
    // }
  };
  const result = await api(config, {handleErr: false});
  return Promise.resolve(result);
}

/**
 * @desc 生成二维码
 * @param {houseId: number, name: string} 房屋主键id, 房屋名字
 */
export async function downQrCode({houseId = "", name}) {
  const config = {
    method: "get",
    url: encodeURI(`${baseUrl}/qrcode/house/deliver?houseId=${houseId}&imageName=${name}`)
  };
  const result = await api(config, {handleErr: false});
  return Promise.resolve(result);
}

/**
 * @desc 查询安装工
 * @param {phone} 安装工手机号
 */
export async function getOperator(data) {
  const config = {
    method: "get",
    url: `${baseUrl}/worker/search`,
    data
  };
  const result = await api(config);
  return Promise.resolve(result);
}

/**
 * @desc 删除空间下设备
 * @param {number} id 设备主键id
 */
export async function deleteSpaceEquip(id) {
  const config = {
    method: "delete",
    url: `${baseUrl}/equipment/delete?id=${id}`
  };
  const result = await api(config, {handleErr: false});
  return Promise.resolve(result);
}

/**
 * @desc 设备信息更新（备注，配网）
 */
export async function updateEquip(data) {
  const config = {
    method: "put",
    url: `${baseUrl}/equipment/update`,
    data
  };
  const result = await api(config, {handleErr: false});
  return Promise.resolve(result);
}

/**
 * @desc 查询模板里面可供选择的场景设备
 * @param {templateId}
 * @intro 模板id
 */
export async function getTemplateEquipList(data) {
  const config = {
    method: "get",
    url: `${baseUrl}/scene/templeate/product/${data.templateId}`,
    data: {
      type: data.type
    }
  };
  const result = await api(config, {handleErr: false});
  return Promise.resolve(result);
};

/**
 * @desc 添加空间设备
 * @param
 */
export async function spaceBatchInsertEquip(data) {
  const config = {
    method: "post",
    url: `${baseUrl}/equipment/insert/batch`,
    data
  };
  const result = await api(config, {handleErr: false});
  return Promise.resolve(result);
};

/**
 * @desc 修改模板场景详情
 */
export async function updateTemplateScene({id, data}) {
  const config = {
    method: "put",
    url: `${baseUrl}/template/house/scene/detail/${id}`,
    data
  };
  const result = await api(config, {handleErr: false});
  return Promise.resolve(result);
};

/**
 * @description: 空间管理中添加房间
 * @param {{ name: string, id: number }} data 新建房间名，创建房间的房屋空间主键id
 * @return:
 * @Date: 2019-09-04 14:12:05
 */
export async function createRoom(data) {
  const config = {
    method: "post",
    url: `${baseUrl}/room/add`,
    data
  };
  const result = await api(config, {handleErr: false});
  return Promise.resolve(result);
}

/**
 * @description: 删除空间管理中的房间
 * @param {number} roomId 需要删除的房间id
 * @return:
 * @Date: 2019-09-04 16:58:50
 */
export async function deleteSpaceRoom({roomId}) {
  const config = {
    method: "delete",
    url: `${baseUrl}/room/delete/${roomId}`
  };
  const result = await api(config, {handleErr: false});
  return Promise.resolve(result);
}

/**
 * @description: 创建停车场公区
 * @param {type}
 * @return:
 * @Date: 2019-09-04 19:49:08
 */
export async function createParking(data) {
  const config = {
    method: "post",
    url: `${baseUrl}/space/inset/public/area`,
    data
  };
  const result = await api(config, {handleErr: false});
  return Promise.resolve(result);
}

/**
 * @description: 删除户型模板中的产品
 * @param {{ids: [ number ]}} data 需要删除的产品id数组
 * @return:
 * @Date: 2019-09-04 20:49:17
 */
export async function deleteTempEquip(data) {
  const config = {
    method: "delete",
    url: `${baseUrl}/template/house/room/product`,
    data
  };
  const result = await api(config, {handleErr: false});
  return Promise.resolve(result);
}

/**
 * @description: 房屋应用场景
 * @param {{ id: number, ids: array }} 模板id，房间的主键id集合
 * @return:
 */
export async function applyTempScene(data) {
  const config = {
    method: "post",
    url: `${baseUrl}/template/house/apply/scene`,
    data
  };
  const result = await api(config, {handleErr: false});
  return Promise.resolve(result);
}

/**
 * @description: 房屋导入模板下载
 * @return:
 */
export async function houseTempDownload() {
  const config = {
    method: "get",
    url: `${baseUrl}/house/template/download`,
    data: {},
    responseType: "blob"
  };
  const result = await api(config, {handleErr: false});
  return Promise.resolve(result);
}

/**
 * @description: 批量添加公区设备
 * @param {[Object]} data 待添加的公区设备
 * @return:
 * @Date: 2019-09-05 16:39:20
 */
export async function batchAddPublicEquip(data) {
  const config = {
    method: "post",
    url: `${baseUrl}/equipment/insert/equipment/public/area/bacth`,
    data
  };
  const result = await api(config, {handleErr: false});
  return Promise.resolve(result);
}

/**
 * @description: 批量添加房屋模板中的产品
 * @param {[Object]} data 待添加的模板设备产品列表
 * @return:
 * @Date: 2019-09-05 18:01:08
 */
export async function batchAddTemplateEquip(data) {
  const config = {
    method: "post",
    url: `${baseUrl}/template/house/room/product/batch`,
    data
  };
  const result = await api(config, {handleErr: false});
  return Promise.resolve(result);
}

/**
 * @desc 删除空间场景
 * @param {sceneId}
 * @intro 场景id
 */
export async function deleteSpaceScene(data) {
  const config = {
    method: "delete",
    url: `${baseUrl}/scene/delete/batch`,
    data
  };
  const result = await api(config, {handleErr: false});
  return Promise.resolve(result);
}

/**
* @description: 分页查询安装工
* @param {{pageSize: number, pageNo: number}} data 分页数据
* @return:
* @Date: 2019-09-06 11:03:45
*/
export async function queryWorkers(data) {
  const config = {
    method: "post",
    url: `${baseUrl}/worker`,
    data
  };
  const result = await api(config, {handleErr: false});
  return Promise.resolve(result);
}

/**
 * @description: 给安装工 授权/取消授权 房屋
 * @param {{id: string, ids: [number]}} data 安装工id，及待授权房屋ids
 * @return:
 * @Date: 2019-09-06 11:03:45
 */
export async function bindSpaceToWorker(data) {
  const config = {
    method: "post",
    url: `${baseUrl}/space/user/add/user/space`,
    data
  };
  const result = await api(config, {handleErr: false});
  return Promise.resolve(result);
}

/**
 * @description: 根据模板手动创建房间
 * @param {Object} data 创建房间所需参数
 * @return:
 * @Date: 2019-09-07 15:00:51
 */
export async function createHouseByHand(data) {
  const config = {
    method: "post",
    url: `${baseUrl}/space/inset/house/apply/template`,
    data
  };
  const result = await api(config, {handleErr: false});
  return Promise.resolve(result);
}

/**
 * @description: 开始安装房屋里的设备
 * @param {{id: number, status: number}} data 开始安装的房屋id, 需要更改的状态
 * @return:
 * @Date: 2019-09-07 15:00:51
 */
export async function startInstall(data) {
  const config = {
    method: "post",
    url: `${baseUrl}/space/update/house/status`,
    data
  };
  const result = await api(config, {handleErr: false});
  return Promise.resolve(result);
}

/**
 * @description: 重置房屋的设备配网状态
 * @param {number} houseId 需要重置的房屋的id
 * @return:
 * @Date: 2019-09-07 17:49:11
 */
export async function resetHouse({houseId}) {
  const config = {
    method: "put",
    url: `${baseUrl}/space/space/reset?houseId=${houseId}`
  };
  const result = await api(config, {handleErr: false});
  return Promise.resolve(result);
}

/**
 * @description: 查询公区设备
 * @param {number} id 查询公区的主键id
 * @return:
 * @Date: 2019-09-12 13:48:29
 */
export async function getPublicEquip({id = ""}) {
  const config = {
    method: "get",
    url: `${baseUrl}/equipment/public/equipment?id=${id}`
  };
  const result = await api(config, { handleErr: false });
  return Promise.resolve(result);
}

/**
 * @description:删除公区设备
 * @param {number} id 删除公区设备的设备主键id
 * @return:
 * @Date: 2019-09-12 13:52:02
 */
export async function deletePublicEquip({ id = "" }) {
  const config = {
    method: "delete",
    url: `${baseUrl}/equipment/delete/public/equipment?id=${id}`
  };
  const result = await api(config, { handleErr: false });
  return Promise.resolve(result);
}