export default {
  namespaced: true,
  state: {
    ocxCutStatus: "",
    ocxPlayCleanUp: ""
  },
  mutations: {
    ocxCutVideoStatus(state, data) {
      state.ocxCutStatus = data.ocxCutStatus;
    },
    ocxPlayCleanUpStatus(state, data) {
      state.ocxPlayCleanUp = data.ocxPlayCleanUp;
    }
  }
};
