import singleSpaVue from "single-spa-vue";

import "@/init.js";
import { vueI18n } from "./I18N.js";

import store from "./store";
import App from "./App";
import router from "./router";

import Vue from "vue";
// Vue.config.productionTip = false;
// 富文本编辑器--start--
import VueQuillEditor from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
Vue.use(VueQuillEditor);
// 富文本编辑器--end--
const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    render: (h) => h(App),
    router,
    store,
    i18n: vueI18n
  }
});

// 打上gitcommit信息
if (!window.$WANRUI) {
  window.$WANRUI = {};
}
window.$WANRUI.IOCWEBENTERPRISE_GITCOMMIT = process.env.VUE_APP_GITCOMMIT;
window.$WANRUI.IOCWEBENTERPRISE_GITADDRESS = "https://git.vankeservice.com/ioc/ioc-web-enterprise.git";

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
