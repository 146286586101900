import commonUtils from "@/common/utils/common";
import { RES_CODE_SUCCESS } from "@/common/utils/constant";
const BASECOMMONURL = commonUtils.getRegistry("BASECOMMON");

export default {
  namespaced: true,
  state: {
    videoAssistConfigList: [],
    total: 0,
    saveVideoAssistConfigSuccess: "",
    alarmConfigList: []
  },
  getters: {},
  mutations: {
    getVideoAssistConfig(state, data) {
      state.videoAssistConfigList = data.list;
      state.total = data.rowCount;
    },
    findAlarmConfigList(state, data) {
      state.alarmConfigList = data;
    }
  },
  actions: {
    findAlarmConfigList({ commit }, params) {
      return commonUtils.axiosData(
        "/basAlarmConfig/findAlarmConfigList",
        "post",
        params,
        BASECOMMONURL
      ).then(({ data }) => {
        if (data.code === RES_CODE_SUCCESS) {
          data.data = data.data.map(item => {
            item.status = item.alarmStatusCode === "on";
            return item;
          });
          commit("findAlarmConfigList", data.data);
        }
      }).catch();
    },
    getVideoAssistConfig({ commit }, params) {
      return commonUtils.axiosData(
        "/basVideoAssistConfig/getVideoAssistConfig",
        "post",
        params,
        BASECOMMONURL
      ).then(({ data }) => {
        if (data.code === RES_CODE_SUCCESS) {
          data.data.list = data.data.list.map(item => {
            item.intervalTime = item.intervalTime || 5;
            return item;
          });
          commit("getVideoAssistConfig", data.data);
        }
        return false;
      }).catch(() => {
        return false;
      });
    },
    saveVideoAssistConfig({ commit }, params) {
      return commonUtils.axiosData(
        "/basVideoAssistConfig/saveVideoAssistConfig",
        "post",
        params,
        BASECOMMONURL
      ).then(res => {
        return res.data;
      }).catch(() => {
        return false;
      });
    },
    editMaintainPerson({ commit }, params) {
      commonUtils.axiosData(
        "/basAlarmConfig/editMaintainPerson",
        "post",
        params,
        BASECOMMONURL
      ).then(() => {
        return false;
      }).catch(() => {
        return false;
      });
    }
  }
};