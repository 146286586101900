import commonUtils from "@/common/utils/common";

const VIDEO_URL = commonUtils.getRegistry("VIDEO");
export default {
  namespaced: true,
  state: {
    patroPlansList: []
  },
  mutations: {},
  getters: {},
  actions: {
    keepBeatAlive({commit}, clientid) {
      let obj = {
        clientid
      };
      let res = commonUtils.axiosData(
        "/keepheartbeat",
        "get",
        obj,
        VIDEO_URL
      );
      return res;
    }
  }
};