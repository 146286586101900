import Ajax from "@/js/api.js";
export default {
  namespaced: true,
  state: {
    delRegPerLebelData: "",
    insertRegPerLebelData: "",
    imgLabelList: [],
    detailsList: [],
    rowCount: 0
  },
  getters: {
  },
  mutations: {
    delRegPerLebelChange(state, data) {
      state.delRegPerLebelData = data;
    },
    insertRegPerLebelChange(state, data) {
      state.insertRegPerLebelData = data;
    },
    pageQueryRegPerImgLabelChange(state, data) {
      state.imgLabelList = data.list;
      state.rowCount = data.rowCount;
    },
    pageQueryRegPerImgLabelDetailsChange(state, data) {
      state.detailsList = data;
    }
  },
  actions: {
    // 删除人员标记
    delRegPerLebel({ commit }, params) {
      return Ajax({
        url: "/ioc/personmgmt/registerperson/delRegPerLebel",
        method: "get",
        data: params
      }).then(data => {
        // commit("delRegPerLebelChange", data);
        console.log("成功", data);
        return true;
      }).catch(err => {
        console.log("失败", err);
        return false;
      });
    },
    // 保存人员标记
    insertRegPerLebel({ commit }, params) {
      return Ajax({
        url: "/ioc/personmgmt/registerperson/insertRegPerLebel",
        method: "post",
        data: params
      }).then(data => {
        // commit("insertRegPerLebelChange", data);
        console.log("成功", data);
        return true;
      }).catch(err => {
        console.log("失败", err);
        return false;
      });
    },
    // 主页分页
    pageQueryRegPerImgLabel({ commit }, {parkCode, pageNum, pageSize}) {
      Ajax({
        url: `/ioc/personmgmt/registerperson/pageQueryRegPerImgLabel?parkCode=${parkCode}&pageNum=${pageNum}&pageSize=${pageSize}`,
        method: "post",
        data: null
      }).then((data) => {
        console.log("--------------", data);
        commit("pageQueryRegPerImgLabelChange", data);
      }).catch(() => {
        commit("pageQueryRegPerImgLabelChange", []);
      });
    },
    // 详情页
    pageQueryRegPerImgLabelDetails({ commit }, {parkCode, pid}) {
      Ajax({
        url: `/ioc/personmgmt/registerperson/pageQueryRegPerImgLabelDetails?parkCode=${parkCode}&pid=${pid}`,
        method: "post",
        data: null
      }).then((data) => {
        commit("pageQueryRegPerImgLabelDetailsChange", data);
      }).catch(() => {
        commit("pageQueryRegPerImgLabelDetailsChange", []);
      });
    }
  }
};