// import "babel-polyfill";
import isMatch from "lodash/isMatch";
export default {
  parseSearch() {
    let obj = {};
    location.search &&
      location.search
        .slice(1)
        .split("&")
        .forEach(item => {
          let inArr = item.split("=");
          obj[inArr[0]] = inArr[1].slice(0);
        });

    return obj;
  },
  // 判断值的数据类型
  getType(v) {
    Object.prototype.toString
      .call(v)
      .slice(8, -1)
      .toLocaleLowerCase();
  },
  // 给递归的数据赋一些值
  fillIndexToRecursionData(
    list = [],
    {
      recursionKey = "children",
      fillIndexKey = "index",
      handleIndex = o => o,
      handleItem = o => o,
      handLeafNode = o => o
    } = {}
  ) {
    let fn = (arr, coorArr) => {
      for (let i = 0; i < arr.length; i++) {
        let item = arr[i];
        item = handleItem(item);
        let newArr = [...coorArr];
        newArr.push(i);
        item[fillIndexKey] = handleIndex(newArr);
        if (item[recursionKey] && item[recursionKey].length > 0) {
          fn(item[recursionKey], newArr);
        } else {
          item = handLeafNode(item);
          item.isRootNode = true;
        }
      }
    };
    fn(list, []);
    return list;
  },
  // getFirstLeafNode(list = [], { recursionKey = "children" } = {}) {
  //   if (!list || list.length === 0) return null;

  //   let fn = arr => {
  //     while (arr[recursionKey] && arr[recursionKey].length > 0) {
  //       return fn(arr.children[0]);
  //     }
  //     return {...arr};
  //   };
  //   return fn(list[0]);
  // },
  getFirstRootNodeCoor(list = [], { recursionKey = "children" } = {}) {
    if (!list || list.length === 0) return null;

    let fn = (arr, coorArr) => {
      if (arr[recursionKey] && arr[recursionKey].length > 0) {
        let newCoor = [...coorArr, 0];
        return fn(arr[recursionKey][0], newCoor);
      }
      return [...coorArr];
    };
    return fn(list[0], [0]);
  },
  // 根据坐标获取递归数据位置的值
  queryRecursionDataFromCoor(list = [], coorArr = [], {recursionKey = "children"} = {}) {
    if (list.length === 0 || !coorArr || coorArr.length === 0) return null;
    let len = coorArr.length;

    let inGetTarget = function(arr, i) {
      let coor = coorArr[i];
      if (i < len - 1) {
        if (arr[coor] && arr[coor][recursionKey]) {
          return inGetTarget(arr[coor][recursionKey], ++i);
        } else {
          console.error(`索引数组${JSON.stringify(coorArr)}，在当前数组中不存在`);
          return null;
        }
      } else {
        return arr[coor];
      }
    };

    return inGetTarget(list, 0);
  },
  // 根据坐标获取递归数据位置的值
  queryRecursionDataFromCoorDeep(list = [], coorArr = [], {recursionKey = "children"} = {}) {
    if (list.length === 0 || !coorArr || coorArr.length === 0) return null;
    let len = coorArr.length;
    let deepArr = [];
    let inGetTarget = function(arr, i) {
      let coor = coorArr[i];
      let newObj = {};
      if (i < len - 1) {
        if (arr[coor] && arr[coor][recursionKey]) {
          for (var attr in arr[coor]) {
            if (attr !== recursionKey) {
              newObj[attr] = arr[coor][attr];
            }
          }
          deepArr.push({...newObj});
          return inGetTarget(arr[coor][recursionKey], ++i);
        } else {
          console.error(`索引数组${JSON.stringify(coorArr)}，在当前数组中不存在`);
          return null;
        }
      } else {
        deepArr.push({...arr[coor]});
        return deepArr;
      }
    };

    return inGetTarget(list, 0);
  },

  // 根据属性获取递归数据位置的值
  queryRecursionData(list = [], target = {}, {recursionKey = "children"} = {}) {
    if (Object.keys(target).length === 0) return null;
    let notGetTarget = true;
    let result = null;
    let fn = arr => {
      if (notGetTarget && arr.length > 0) {
        for (let item of arr) {
          if (isMatch(item, target)) {
            notGetTarget = false;
            result = item;
            break;
          } else {
            if (item[recursionKey] && item[recursionKey].length > 0) {
              fn(item[recursionKey]);
            }
          }
        }
      }
    };

    fn(list);
    return result;
  },
  browserType() {
    let userAgent = navigator.userAgent; // 取得浏览器的userAgent字符串
    let isOpera = userAgent.indexOf("Opera") > -1;
    if (isOpera) {
      // 判断是否Opera浏览器
      return "Opera";
    }
    if (userAgent.indexOf("Firefox") > -1) {
      // 判断是否Firefox浏览器
      return "FF";
    }
    if (userAgent.indexOf("Chrome") > -1) {
      return "Chrome";
    }
    if (userAgent.indexOf("Safari") > -1) {
      // 判断是否Safari浏览器
      return "Safari";
    }
    if (
      userAgent.indexOf("compatible") > -1 &&
      userAgent.indexOf("MSIE") > -1 &&
      !isOpera
    ) {
      // 判断是否IE浏览器
      return "IE";
    }
  },
  downLoadFile(name, data, type = {type: ""}) {
    const blob = new Blob([data], type);
    if ("download" in document.createElement("a")) {
      const link = document.createElement("a");
      link.setAttribute("download", name);
      link.style.display = "none";
      link.href = URL.createObjectURL(blob);
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(link.href);
      document.body.removeChild(link);
    } else {
      navigator.msSaveBlob(blob, name);
    }
  },
  phoneCheck(phone) {
    return /^1[3456789]\d{9}$/.test(phone);
  },
  /**
   * 计算表格高度
   * @param { dom } outerDom 最外层元素
   * @param  { dom | number } innerDoms 需减掉高度的元素
   * @return { Number } 计算后的高度
   */
  calcTableHeight(outerDom, ...innerDoms) {
    const outerHeight = outerDom.offsetHeight;
    let [innerHeightSum, res] = [0, 0, 0];
    let innerHeightList = [];
    innerDoms.forEach(item => {
      if (typeof item === "number") {
        innerHeightList.push(item);
      } else {
        innerHeightList.push(item.offsetHeight);
      }
    });
    const reducer = (a, b) => a + b;
    innerHeightSum = innerHeightList.reduce(reducer, 0);
    res = outerHeight - innerHeightSum;
    return res;
  },
  // 转换大于10000的数值
  processData(val = 0, decimal0 = 2, decimal1) {
    val = Number(val);
    if (Math.abs(val) >= 10000) {
      return (val / 10000).toFixed(decimal0) + "万";
    } else if (val === 0) {
      return val;
    } else {
      const decimal = decimal1 !== null && decimal1 !== undefined ? decimal1 : decimal0;
      return val.toFixed(decimal).toLocaleString();
    }
  }
};
