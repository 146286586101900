export default {
  namespaced: true,
  state: {
    Image: ""
  },
  mutations: {
    getImage(state, Image) {
      console.log(Image);
      state.Image = Image;
    }
  }
};
