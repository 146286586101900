import noDataShowContent from "../noDataShowContent.vue";
import errorMessage from "../errorMessage.vue";
import "viewerjs/dist/viewer.css";
import Viewer from "v-viewer";
export default {
  install(Vue) {
    Vue.component("noDataShowContent", noDataShowContent); // noData没数据显示
    Vue.component("errorMessage", errorMessage); // 消息弹出框
    Vue.use(Viewer);
  }
};
