const CryptoJS = require("crypto-js");

// 密钥
const SECRET_KEY = "1B609BC66E90319B";

export default {
  /**
     * 加密
     * @param word
     * @returns {*}
     */
  encrypt(word) {
    let key = CryptoJS.enc.Utf8.parse(SECRET_KEY);
    let srcs = CryptoJS.enc.Utf8.parse(word);
    let encrypted = CryptoJS.AES.encrypt(srcs, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
    return encrypted.toString();
  },

  /**
     * 解密
     * @param word
     * @returns {*}
     */
  decrypt(word) {
    if (!word) {
      return "";
    } else {
      let key = CryptoJS.enc.Utf8.parse(SECRET_KEY);
      let decrypt = CryptoJS.AES.decrypt(word, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
      return CryptoJS.enc.Utf8.stringify(decrypt).toString();
    }
  }
};