export default {
  namespaced: true,
  state: {
    stateMapColRowPopup_ConfigListData: []
  },
  mutations: {
    setColRowPopupConfigListData(state, ConfigListData) {
      state.stateMapColRowPopup_ConfigListData = ConfigListData;
    }
  }
};
