export default {
  namespaced: true,
  state: {
    suspectId: ""
  },
  mutations: {
    setSuspectId(state, newVal) {
      state.suspectId = newVal;
    },
    clearSuspectId(state) {
      state.suspectId = "";
    }
  },
  actions: {
    setSuspectIdAction(context, newVal) {
      context.commit("setSuspectId", newVal);
    },
    clearSuspectIdAction(context) {
      context.commit("clearSuspectId");
    }
  }
};